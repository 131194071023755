import React, { useState, useEffect, useLayoutEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { isMobile, tidyDate } from '../../utils/helper'
import { MdAir } from 'react-icons/md'
import { FaPlane } from 'react-icons/fa'
import MainLayout from '../../layouts/MainLayout'


export default function ClubFlightsPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [midHeader, setMidHeader] = useState(document.getElementById("mid-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
    setMidHeader(document.getElementById("mid-header")?.clientHeight + document.getElementById("user-header")?.clientHeight)
  }, [document.getElementById("user-header")?.clientHeight, document.getElementById("mid-header")?.clientHeight])

  const [clubFlights, setClubFlights] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedFlightStatus, setSelectedFlightStatus] = useState("all")

  const fetchClubFlights = async () => {
    let endpoint = config.endpoint + "/bookings/club/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (response.status === 200) {
      setClubFlights(data.data?.reverse())
    }
  }

  useEffect(() => {
    fetchClubFlights()
  }, [isAuthorised, userRole])

  return (
    <MainLayout
      componentName={"Club Flights"}
      navSelected={"My Flights"}
      componentButtons={
        <select name="" id="" className='select-input h-11 px-2' style={{ width: "100%", maxWidth: 200, minWidth: 0 }}
          onChange={(e) => {
            setSelectedFlightStatus(e.target.value)
          }}
        >
          <option value="all">All Flights</option>
          <option value="pending">Pending</option>
          <option value="confirmed">Confirmed</option>
          <option value="completed">Completed</option>
          <option value="cancelled">Cancelled</option>
        </select>
      }
      centeredContents={false}
    >

      <div className="p-all" style={{ width: "100%", paddingBottom: 20, position: "sticky", top: midHeader, }}>
        <input type="text" className="text-input" style={{ width: "100%" }} placeholder="Search" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value) }} />
      </div>
      {clubFlights?.length > 0 ? clubFlights.map((booking, index) => {
        if (searchQuery.length > 0 && !JSON.stringify(booking).toLowerCase().includes(searchQuery.toLowerCase())) {
          return null
        }
        if (selectedFlightStatus !== "all" && booking?.booking_status !== selectedFlightStatus) {
          return null
        }

        let bgColor, bgColor1, fgColor, fgColor1

        if (booking?.payment_data?.is_paid == 1) {
          bgColor1 = "var(--green)"
          fgColor1 = "white"
        } else {
          bgColor1 = "var(--red)"
          fgColor1 = "white"
        }

        if (booking?.booking_status == "accepted") {
          bgColor = "var(--green)"
          fgColor = "white"
        } else if (booking?.booking_status == "rejected") {
          bgColor = "var(--red)"
          fgColor = "white"
        } else if (booking?.booking_status == "pending") {
          bgColor = "var(--yellow)"
          fgColor = "white"
        } else if (booking?.booking_status == "completed") {
          bgColor = "var(--primary)"
          fgColor = "white"
        } else if (booking?.booking_status == "cancelled") {
          bgColor = "var(--red)"
          fgColor = "white"
        }


        return (
          <div className="card cursor-pointer mb-4 row items-center justify-between rounded-md shadow-md p-4"
            onClick={() => {
              navigate("/club/flights/bookings/" + booking?.id)
            }}
          >
            <div>
              <p className="card-title">Flight #{booking?.id} - {booking?.user_data?.first_name + " " + booking?.user_data?.last_name}</p>
              <p>{tidyDate(booking?.flight_departure_datetime)} • {booking?.flight_type}</p>
            </div>
            <div className="row gap-4">
              <p style={{ backgroundColor: bgColor, color: fgColor, padding: "5px 20px", fontSize: 14, borderRadius: 25 }}>{booking?.booking_status?.toUpperCase()}</p>
              <p style={{ backgroundColor: bgColor1, color: fgColor1, padding: "5px 20px", fontSize: 14, borderRadius: 25 }}>{booking?.payment_data?.is_paid == 1 ? "PAID" : "PENDING PAYMENT"}</p>
            </div>
          </div>
        )
      })
        :
        <div className="card p-4 rounded-md">
          <FaPlane size={50} color={"gray"} style={{ transform: "rotate(315deg)" }} />
          <p className="card-title mt-4" style={{ color: "gray" }}>No flights found</p>
        </div>
      }
    </MainLayout>
  )
}
