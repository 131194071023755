import React from 'react'
import Header from '../components/Header'

export default function IndexPage() {
  return (
    <div>
      <Header />
      <div className="w-full bg-cyan-400 flex flex-col center items-center h-screen">
        <h1 className="text-6xl text-center text-white max-w-5xl mt-10 " style={{ lineHeight: 1.3 }}>A Management Suite that brings your club to greater heights</h1>
        <p className="text-center text-white text-xl">An all-in-one solution for to manage your flying club needs</p>
      </div>
    </div>
  )
}
