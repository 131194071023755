import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import MainLayout from '../../layouts/MainLayout'
import { tidyDate } from '../../utils/helper'
import { MdAnnouncement } from 'react-icons/md'

export default function ClubAnnouncementPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  useEffect(() => {
    fetchAnnouncements()
  }, [])

  const [announcementData, setAnnouncementData] = useState([])

  const fetchAnnouncements = async () => {
    let endpoint = `${config.endpoint}/announcements`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setAnnouncementData(data.data)
    }
  }


  return (
    <MainLayout
      navSelected={"Announcements"}
      componentName={"Club Announcements"}
      componentButtons={
        <>
          <button className="button-cta" onClick={() => navigate("/announcements/details/create")}>Create Announcement</button>
        </>
      }
    >
      {announcementData?.length > 0 ? (
        announcementData.map((announcement, index) => {
          let color = ""
          if (announcement.is_published == 1) {
            color = "green"
          } else {
            color = "red"
          }
          return (
            <div className="card rounded-md p-4 mb-2 flex flex-row items-center justify-between cursor-pointer" key={index}
              onClick={() => navigate(`/announcements/details/${announcement.id}`)}
            >
              <div>
                <h3 className='font-bold'>{announcement.title}</h3>
                <h3 className='text-sm'>{tidyDate(announcement.start_datetime)} to {announcement.is_forever == 1 ? "No end date" : tidyDate(announcement.end_datetime)}</h3>
              </div>
              <p style={{ fontSize:12, color:"white", fontWeight:"bold", borderRadius:25, padding:"5px 15px", backgroundColor:color}}>{announcement?.is_published == 1 ? "Published" : "Draft"}</p>
            </div>
          )
        })
      ) : (
        <div className="card p-4 rounded-md">
          <MdAnnouncement size={48} color={"gray"} />
          <p className='card-title mt-4' style={{ color: "gray" }}>No Announcements Found</p>
        </div>
      )}
    </MainLayout>
  )
}
