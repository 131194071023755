import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Switch from "react-switch"
import notify from '../../utils/IANotification'
import ModuleNavigation from '../../components/ModuleNavigation'

export default function ClubModulesLessonsDetailPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [subHeader, setSubHeader] = useState(document.getElementById("user-subheader")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
    setSubHeader(document.getElementById("user-subheader")?.clientHeight)
  }, [header])

  let { module_id, lesson_id } = useParams()

  const [moduleFormData, setModuleFormData] = useState({
    name: "",
    type: "text",
    url: "",
    content: "",
    module_id: module_id,
  })

  const createModule = async () => {
    let endpoint = config.endpoint + "/modules/club/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(moduleFormData)
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate(`/club/modules/${data.data}`)
      notify({ type: "success", message: "Module created successfully" })
    } else {
      notify({ type: "error", message: "Error creating module" })
    }
  }

  const updateModule = async () => {
    if (!module_id) return notify({ type: "error", message: "Error updating module" })

    let endpoint = config.endpoint + "/modules/club/id/" + module_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(moduleFormData)
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Module updated successfully" })
    } else {
      notify({ type: "error", message: "Error updating module" })
    }
  }


  const deleteModule = async () => {
    if (!module_id) return notify({ type: "error", message: "Error deleting module" })

    let endpoint = config.endpoint + "/modules/club/id/" + module_id
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Module deleted successfully" })
    } else {
      notify({ type: "error", message: "Error deleting module" })
    }
  }

  const fetchModuleById = async () => {
    if (!module_id) return notify({ type: "error", message: "Error fetching module" })
    let endpoint = config.endpoint + "/modules/id/" + module_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setModuleFormData(data.data)
    } else {
      notify({ type: "error", message: "Error fetching module" })
    }
  }

  useEffect(() => {
    if (module_id === "create") return
    fetchModuleById()
  }, [])


  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Dashboard"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll", zIndex: 5 }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header, zIndex: 5 }} id='user-subheader'>
            <div className="component-header">
              <h1>{module_id === "create" ? "Create New Module" : "Edit Module"}</h1>
            </div>
          </div>

          <div className="row" style={{ width: "100%" }}>
            {module_id !== "create" && <div style={{ height: `calc(100vh - ${header}px - ${subHeader}px)`, overflowY: "scroll", width: "300px", position: "fixed", zIndex: 0 }}>
              <ModuleNavigation selected={"lessons"} module_id={module_id} />
            </div>}

            <div className="reg-container p-4" style={{ width: "100%", marginLeft: module_id !== "create" ? "300px" : "0px" }}>
              <div className="input-container">
                <label htmlFor="name">Lesson Name</label>
                <input type="text" className='text-input' value={moduleFormData.name} onChange={(e) => setModuleFormData({ ...moduleFormData, name: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="type">Type</label>
                <select className='text-input' value={moduleFormData.type} onChange={(e) => setModuleFormData({ ...moduleFormData, type: e.target.value })}>
                  <option value="video">Video</option>
                  <option value="text">Text</option>
                  <option value="image">Image</option>
                  <option value="document">Document</option>
                  <option value="url">External URL</option>
                </select>
              </div>

              {moduleFormData.type === "url" && <div className="input-container">
                <label htmlFor="url">URL</label>
                <input type="text" className='text-input' value={moduleFormData.url} onChange={(e) => setModuleFormData({ ...moduleFormData, url: e.target.value })} />
              </div>}

              {moduleFormData.type === "text" && <div className="input-container">
                <label htmlFor="content">Content</label>
                <textarea className='text-input' value={moduleFormData.content} onChange={(e) => setModuleFormData({ ...moduleFormData, content: e.target.value })} />
              </div>}

              {moduleFormData.type === "image" && <div className="input-container">
                <label htmlFor="content">Image Upload</label>
                <input type="file" className='text-input' onChange={(e) => setModuleFormData({ ...moduleFormData, content: e.target.value })} />
              </div>}
              
              {moduleFormData.type === "document" && <div className="input-container">
                <label htmlFor="content">Document Upload</label>
                <input type="file" className='text-input' onChange={(e) => setModuleFormData({ ...moduleFormData, content: e.target.value })} />
              </div>}

              {moduleFormData.type === "video" && <div className="input-container">
                <label htmlFor="content">Video Upload</label>
                <input type="file" className='text-input' onChange={(e) => setModuleFormData({ ...moduleFormData, content: e.target.value })} />
              </div>}

              <div className="button-container">
                <button className="button" onClick={() => {
                  if (module_id === "create") {
                    createModule()
                  } else {
                    updateModule()
                  }
                }}>{module_id === "create" ? "Create Module" : "Update Module"}</button>

                {module_id !== "create" && <button className="delete-button" onClick={() => {
                  deleteModule()
                }}>Delete Module</button>}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
