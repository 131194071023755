import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Switch from "react-switch"
import FileUploadField from '../../components/FileUploadField'

export default function EventDetailPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  let { event_id } = useParams()

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [eventsData, setEventsData] = useState({
    name: "",
    description: "",
    start_datetime: "",
    end_datetime: "",
    location: "",
    is_published: 0,
  })


  const fetchEventById = async () => {
    if (!event_id) return
    let endpoint = config.endpoint + "/events/id/" + event_id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await response.json()
    if (data.status === 200) {
      setEventsData(data.data)
    }
  }

  const createEvent = async () => {
    let endpoint = config.endpoint + "/events/"
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(eventsData)
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate("/events/details/" + data.data.id)
      toast.success(data.message)
    } else {
      toast.error(data.message)
    }
  }

  const updateEvent = async () => {
    if (!event_id) return
    let endpoint = config.endpoint + "/events/id/" + event_id
    let response = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(eventsData)
    })
    let data = await response.json()
    if (data.status === 200) {
      toast.success(data.message)
    } else {
      toast.error(data.message)
    }
  }


  const deleteEvent = async () => {
    if (!event_id) return
    let endpoint = config.endpoint + "/events/id/" + event_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate("/events")
    } else {
      toast.error(data.message)
    }
  }

  useEffect(() => {
    fetchEventById()
  }, [])



  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Events"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header">
              <h1>{event_id === "create" ? "Create Event" : "Event Details"}</h1>
            </div>
          </div>
          <div className="container">
            <div className="card rounded-md p-4">
              <p className="card-title pb-4">Event Details</p>
              {event_id !== "create" &&
                <FileUploadField
                  upload_url={config.endpoint + "/events/header/upload/" + event_id}
                  text={"Upload Event Header"}
                  subtext={"Drag and drop an image here or click to upload"}
                  max_files={1}
                  onChange={(files) => { }}
                  item_index={1}
                />
              }
              <div className="row gap-4">
                <div className="input-container">
                  <label htmlFor="" className="input-label">Name</label>
                  <input type="text" className="text-input" placeholder='e.g. Group Flight to Port Dickson' value={eventsData.name} onChange={(e) => { setEventsData({ ...eventsData, name: e.target.value }) }} />
                </div>
                <div className="input-container">
                  <label htmlFor="" className="input-label">Published</label>
                  <Switch
                    checked={eventsData.is_published}
                    onChange={(checked) => { setEventsData({ ...eventsData, is_published: checked ? 1 : 0 }) }}
                  />
                </div>
              </div>
              <div className="row gap-4">
                <div className="input-container">
                  <label htmlFor="" className="input-label">Start Date</label>
                  <input type="datetime-local" className="text-input" value={eventsData.start_datetime} onChange={(e) => { setEventsData({ ...eventsData, start_datetime: e.target.value }) }} />
                </div>
                <div className="input-container">
                  <label htmlFor="" className="input-label">End Date</label>
                  <input type="datetime-local" className="text-input" value={eventsData.end_datetime} onChange={(e) => { setEventsData({ ...eventsData, end_datetime: e.target.value }) }} />
                </div>
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Location</label>
                <input type="text" className="text-input" placeholder='e.g. Port Dickson' value={eventsData.location} onChange={(e) => { setEventsData({ ...eventsData, location: e.target.value }) }} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Description</label>
                <textarea className="textarea-input" value={eventsData.description} onChange={(e) => { setEventsData({ ...eventsData, description: e.target.value }) }} />
              </div>

              <div className="column items-end">
                <div className="row gap-4">
                  {event_id !== "create" && <button className="button-delete"
                    onClick={() => {
                      deleteEvent()
                    }}
                  >Delete</button>}
                  <button className="button"
                    onClick={() => {
                      if (event_id === "create") {
                        createEvent()
                      } else {
                        updateEvent()
                      }
                    }}
                  >Save</button>
                </div>
              </div>
            </div>

            {/* Event Photos */}
            {event_id != "create" && <div className="card mt-4 rounded-md p-4">
              <p className="card-title pb-4">Event Photos</p>
              <FileUploadField
                upload_url={config.endpoint + "/events/photos/upload/" + event_id}
                text={"Upload Event Photos"}
                subtext={"Drag and drop an image here or click to upload"}
                max_files={5}
                onChange={(files) => {
                  fetchEventById()
                }}
                item_index={2}

              />
              <div className="row gap-2 items-center hide-scrollbars mt-10" style={{
                overflow: "scroll"
                // flexWrap: "wrap",
              }}>
                {eventsData?.media?.length > 0 ?
                  eventsData?.media?.map((media, index) => {
                    return (
                      <div className="column gap-4" key={index}>
                        <img src={`${config.endpoint}${media?.ext_file_path}`} alt="event" style={{ objectFit: "cover", minWidth: 150, minHeight: 150, borderRadius: 5, }} loading='lazy' />
                      </div>
                    )
                  })
                  :
                  <p>No photos uploaded</p>
                }
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
}
