import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData, logout } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'
import { isMobile } from '../../utils/helper'
import MainLayout from '../../layouts/MainLayout'
import { MdLogout, MdOutlineKey, MdOutlineLogout, MdPassword } from 'react-icons/md'
import ModalLayout from '../../layouts/ModalLayout'

export default function ProfilePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [userData, setUserData] = useState("")
  const [showPasswordModal, setShowPasswordModal] = useState(false)

  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: ""
  })


  const loadUserData = async () => {
    let endpoint = `${config.endpoint}/auth/user`
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setUserData(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const saveProfile = async () => {
    let endpoint = `${config.endpoint}/auth/user`
    let response = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(userData)
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ message: "Profile Updated", type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }

  }

  useEffect(() => {
    loadUserData()
  }, [])


  const changePassoword = async () => {
    if (!password.old_password || !password.new_password || !password.confirm_password) {
      notify({ message: "Please fill in all fields", type: "error" })
      return
    }

    if (password.new_password !== password.confirm_password) {
      notify({ message: "Passwords do not match", type: "error" })
      return
    }

    if (password.new_password.length < 8) {
      notify({ message: "Password must be at least 8 characters long", type: "error" })
      return
    }

    let endpoint = `${config.endpoint}/auth/change-password`
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(password)
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ message: "Password Changed", type: "success" })
      setShowPasswordModal(false)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }


  return (
    <MainLayout
      navSelected={"Profile"}
      componentName={"Edit Profile"}
      centeredContents={false}
    >

      <ModalLayout title={"Change Password"} show={showPasswordModal} setShow={setShowPasswordModal}>
        <div className="flex flex-col gap-4">
          <div className="input-container">
            <label htmlFor="" className="input-label">Old Password</label>
            <input type="password" className="text-input" value={password.old_password} onChange={(e) => setPassword({ ...password, old_password: e.target.value })} />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">New Password</label>
            <input type="password" className="text-input" value={password.new_password} onChange={(e) => setPassword({ ...password, new_password: e.target.value })} />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">Confirm Password</label>
            <input type="password" className="text-input" value={password.confirm_password} onChange={(e) => setPassword({ ...password, confirm_password: e.target.value })} />
          </div>
          <div className="flex flex-row justify-end">
            <button className="button"
              onClick={() => {
                changePassoword()
              }}
            >
              Change Password
            </button>
          </div>
        </div>
      </ModalLayout>

      <div className="flex-col flex items-center justify-center">
        <div className="max-w-[800px] w-full">
          <div className="card rounded-md shadow-md p-4">
            {/* <p className="font-bold text-xl">My Details</p> */}
            {/* <pre>{JSON.stringify(userData, null, 2)}</pre> */}
            {/* <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 alignStart`}> */}
            <p className="text-md font-bold">Name</p>
            <div className="input-container">
              <label htmlFor="" className="input-label">First name</label>
              <input type="text" className="text-input" value={userData.first_name} onChange={(e) => setUserData({ ...userData, first_name: e.target.value })} />
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Last name</label>
              <input type="text" className="text-input" value={userData.last_name} onChange={(e) => setUserData({ ...userData, last_name: e.target.value })} />
            </div>
            {/* </div> */}
          </div>

          <div className="card rounded-md shadow-md p-4 mt-2">
            <p className="text-md font-bold">Personal Information</p>
            <div className="input-container">
              <label htmlFor="" className="input-label">Email</label>
              <input type="text" className="text-input" value={userData.email} onChange={(e) => setUserData({ ...userData, email: e.target.value })} />
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Contact Number</label>
              <input type="text" className="text-input" value={userData.contact_number} onChange={(e) => setUserData({ ...userData, contact_number: e.target.value })} />
            </div>
            {/* <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 alignStart`}> */}
            <div className="input-container">
              <label htmlFor="" className="input-label">Date of Birth</label>
              <input type="date" className="text-input" value={userData?.date_of_birth?.split("T")?.[0]} placeholder='Date of Birth' onChange={(e) => setUserData({ ...userData, date_of_birth: e.target.value })} />
            </div>
          </div>

          <div className="card rounded-md shadow-md p-4 mt-2">
            <p className="text-md font-bold">License Related</p>
            <div className="input-container">
              <label htmlFor="" className="input-label">Next Medical</label>
              <input type="date" className="text-input" value={userData?.medical_date?.split("T")?.[0]} placeholder='Date of Birth' onChange={(e) => setUserData({ ...userData, medical_date: e.target.value })} />
            </div>

            <div className="input-container">
              <label htmlFor="" className="input-label">Certificate of Expiry</label>
              <input type="date" className="text-input" value={userData?.certificate_of_expiry_date?.split("T")?.[0]} placeholder='Date of Birth' onChange={(e) => setUserData({ ...userData, certificate_of_expiry_date: e.target.value })} />
            </div>
            {/* </div> */}

            {/* <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 alignStart`}> */}
            <div className="input-container">
              <label htmlFor="" className="input-label">License Number</label>
              <input type="text" className="text-input" value={userData.license_number} onChange={(e) => setUserData({ ...userData, license_number: e.target.value })} />
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">License Type</label>
              <select className="select-input h-11 px-2" value={userData.license_type} onChange={(e) => setUserData({ ...userData, license_type: e.target.value })}>
                <option value="none">Unclassified</option>
                <option value="student">Student License</option>
                <option value="private">Private License</option>
                <option value="commercial">Commercial Pilot License</option>
                <option value="airline_transport">Airline Transport Pilot License</option>
                <option value="multicrew">Multi-Crew Pilot License</option>
              </select>
            </div>



            {/* </div> */}




            <div className="input-container">
              <label htmlFor="" className="input-label">Your Bio</label>
              <textarea type="text" className="textarea-input" placeholder='I love flying and I have been flying since' value={userData.user_bio} onChange={(e) => setUserData({ ...userData, user_bio: e.target.value })} />
            </div>
            <p style={{ color: "gray", fontSize: 12 }}>{userData?.flying_hours} Flight hours • {userData?.flying_cycles} Cycles</p>
          </div>


          <div className="card rounded-md shadow-md p-4 mt-2">
            <p className="text-md font-bold">Account Matters</p>
            <button className='flex flex-row items-center gap-2 mt-2'
              onClick={() => {
                setShowPasswordModal(true)
              }}
            >
              <MdOutlineKey size={20} />
              Change Password</button>
            <button className='flex flex-row items-center gap-2 mt-2'
              onClick={() => {
                logout()
              }}
            >
              <MdOutlineLogout size={20} />
              Logout</button>
          </div>


          <div className="column alignEnd right mt-4">
            <button className="button"
              onClick={() => saveProfile()}
            >
              Save Profile
            </button>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
