import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'

export default function ClubModulesPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [moduleData, setModuleData] = useState([])

  const fetchModules = async () => {
    let endpoint = config.endpoint + "/modules/club/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let fetchResponse = await response.json()
    if (fetchResponse.status === 200) {
      setModuleData(fetchResponse?.data)
    } else {
      notify({ type: "error", message: "Something went wrong" })
    }
  }

  useEffect(() => {
    fetchModules()
  }, [])

  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Dashboard"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header">
              <h1>Club Training Modules</h1>
            </div>
          </div>
          <div className="container">
            <div className="inner-container">
              {moduleData.map((item, index) => {
                return (
                  <div className="card cursor-pointer" onClick={() => navigate(`/club/modules/${item.id}`)}>
                    <h3 className='card-title'>{item.name}</h3>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
