import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'
import FileUploadField from '../../components/FileUploadField'
import { isMobile } from '../../utils/helper'
import { MdClose } from 'react-icons/md'


export default function ClubAccountingTransactionFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [selectedFile, setSelectedFile] = useState()
  const [width, setWidth] = useState(window.innerWidth)


  const { transaction_id } = useParams()

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [formData, setFormData] = useState({
    name: "",
    amount: 0,
    description: "",
    type: "expense", // expense, income
    date: "",
  })


  const saveTransaction = async () => {
    let endpoint = config.endpoint + "/accounting/transaction"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData)
    })

    let result = await fetchResponse.json()
    if (result.status === 200) {
      navigate("/club/accounting/transaction/" + result.data.transaction_id)
      notify({ message: "Transaction saved successfully", type: "success" })
    } else {
      notify({ message: result.message, type: "error" })
    }
  }


  const editTransaction = async () => {
    if (transaction_id === "new") {
      return
    }
    let endpoint = config.endpoint + "/accounting/transaction"
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...formData,
        transaction_id: transaction_id
      })
    })

    let result = await fetchResponse.json()
    if (result.status === 200) {
      notify({ message: "Transaction updated successfully", type: "success" })
    } else {
      notify({ message: result.message, type: "error" })
    }
  }

  const deleteTransaction = async () => {
    if (transaction_id === "new") {
      return
    }
    let endpoint = config.endpoint + "/accounting/transaction/" + transaction_id
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })

    let result = await fetchResponse.json()
    if (result.status === 200) {
      navigate("/club/accounting")
    } else {
      notify({ message: result.message, type: "error" })
    }
  }

  const loadTransactionById = async () => {
    if (transaction_id === "new") {
      return
    }
    let endpoint = config.endpoint + "/accounting/transaction/id/" + transaction_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })

    let result = await fetchResponse.json()
    if (result.status === 200) {
      setFormData(result.data)
    } else {
      notify({ message: result.message, type: "error" })
    }
  }

  useEffect(() => {
    loadTransactionById()
  }, [])

  const MediaModal = () => {
    return (
      <div className="modal" style={{ width: isMobile(width) && "100%", display: "flex", alignItems: isMobile(width) ? "flex-start" : "center", justifyContent: isMobile(width) ? "flex-end" : "center" }}>
        <div className="modal-content" style={{ width: isMobile(width) && "100%", height: isMobile(width) && "100%", }}>
          <div className="modal-header row justify-between mb-4">
            <p className="modal-title">Preview</p>
            <button className="backgroundLess" onClick={() => {
              setSelectedFile()
            }}>
              <MdClose color='black' size={20} />
            </button>
          </div>
          <div className="column center items-center w-full">
            {(selectedFile?.mime_type?.includes("image") || selectedFile?.mime_type?.includes("text") || selectedFile?.mime_type?.includes("application")) &&
              <img src={`${config.endpoint}${selectedFile?.ext_file_path}`.replace("./public", "")} style={{ maxHeight: isMobile(width) ? "50%" : 400, height: isMobile(width) && "50%", maxWidth: "100%", objectFit: "cover", borderRadius: 10 }} alt="" />}
            {selectedFile?.mime_type?.includes("video") &&
              <video style={{ maxHeight: 400, objectFit: "cover", borderRadius: 10 }} controls>
                <source src={`${config.endpoint}${selectedFile?.ext_file_path}`.replace("./public", "")} type={selectedFile?.mime_type} />
              </video>}
            {selectedFile?.mime_type?.includes("audio") &&
              <audio controls>
                <source src={`${config.endpoint}${selectedFile?.ext_file_path}`.replace("./public", "")} type={selectedFile?.mime_type} />
                Your browser does not support the audio element.
              </audio>}
          </div>
          <a href={`${config.endpoint}${selectedFile?.ext_file_path}`.replace("./public", "")} download style={{ textDecoration: "none" }} id="downloadLink"></a>
          <div className="column center items-center w-full">
            <div className={`${isMobile(width) ? "column" : "row"} gap-4 center items-center mt-40 w-full`}>
              <button className="button" style={{ width: isMobile(width) && "100%" }} onClick={() => {
                setSelectedFile()
              }}>
                Close
              </button>
              <button className="button" style={{ width: isMobile(width) && "100%" }} onClick={() => {
                document.getElementById("downloadLink").click()
              }}>
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Accounting"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header">
              <h1>{transaction_id === "new" ? "Add" : "Edit"} Transactions</h1>
            </div>
          </div>
          {selectedFile && <MediaModal />}
          <div className="reg-container p-4">
            <div className="row gap-4 alignStart">

              {/* Details */}
              <div className="card rounded-md shadow-md p-4">
                <p className="card-title">Transaction</p>
                <div className="row gap-4">
                  <div className="input-container">
                    <label htmlFor="" className="text-xs font-semibold text-slate-500">Name</label>
                    <input type="text" className="text-input" placeholder="Name" value={formData.name} onChange={(e) => {
                      setFormData({
                        ...formData,
                        name: e.target.value
                      })
                    }} />
                  </div>
                  <div className="input-container">
                    <label htmlFor="" className="text-xs font-semibold text-slate-500">Amount ($)</label>
                    <input type="number" className="text-input" placeholder="Amount" value={formData.amount}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          amount: parseFloat(e.target.value)
                        })
                      }} />
                  </div>

                </div>
                <div className="row gap-4">
                  <div className="input-container">
                    <label htmlFor="" className="text-xs font-semibold text-slate-500">Type</label>
                    <select className="select-input h-11 px-2" value={formData.type} onChange={(e) => {
                      setFormData({
                        ...formData,
                        type: e.target.value
                      })
                    }}>
                      <option value="expense">Expense</option>
                      <option value="income">Income</option>
                    </select>
                  </div>
                  <div className="input-container">
                    <label htmlFor="" className="text-xs font-semibold text-slate-500">Date</label>
                    <input type="date" className="text-input" placeholder="Date" value={formData.date} onChange={(e) => {
                      setFormData({
                        ...formData,
                        date: e.target.value
                      })
                    }} />
                  </div>
                </div>

                <div className="input-container">
                  <label htmlFor="" className="text-xs font-semibold text-slate-500">Description</label>
                  <textarea type="text" className="textarea-input" placeholder="Description" value={formData.description} onChange={(e) => {
                    setFormData({
                      ...formData,
                      description: e.target.value
                    })
                  }} />
                </div>
                <div className="row gap-4 right items-center">
                  {transaction_id !== "new" && <button className="delete-button font-['Poppins']"
                    onClick={() => {
                      deleteTransaction()
                    }}
                  >Delete</button>}
                  <button className="button font-['Poppins']"
                    onClick={() => {
                      if (transaction_id === "new") {
                        saveTransaction()
                      } else {
                        editTransaction()
                      }
                    }}
                  >Save</button>
                </div>
              </div>

              {/* Documents */}
              {transaction_id !== "new" && <div className="" style={{ width: "500px" }}>
                <div className="card rounded-md shadow-md p-4">
                  <p className="card-title">Documents</p>
                  {transaction_id !== "new" && <FileUploadField
                    text={"Upload Document"}
                    subtext={"Maximum Upload Size: 1 GB | Maximum Number of Files: 5"}
                    max_file_size={"1 GB"}
                    upload_url={config.endpoint + "/accounting/transaction/upload/" + transaction_id}
                    max_files={5}
                    onChange={(files) => { }}
                  />}

                  <p style={{ fontSize: 12 }}>Attached Documents</p>
                  {formData?.documents?.length > 0 &&
                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 10, marginTop: 10 }}>
                      {formData?.documents?.map((file, index) => {
                        return (
                          <div style={{
                            maxWidth: 200,
                            backgroundColor: "gray",
                            display: "flex",
                            gap: 10,
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "5px 10px",
                            cursor: "pointer",
                            borderRadius: 5

                          }}
                            onClick={() => {
                              // window.open(`${config.endpoint}${file.ext_file_path}`, "_blank")
                              setSelectedFile(file)
                            }}
                          >
                            <p style={{
                              fontSize: 12,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              color: "white"
                            }}>{file.file_name}</p>
                          </div>
                        )
                      })}
                    </div>
                  }
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
