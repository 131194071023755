import React, { useEffect } from 'react'
import Header from '../components/Header'
import { useState } from 'react'
import config from '../app-config'
import notify from '../utils/IANotification'
import { ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router'

export default function AuthPage({ pageState }) {
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [contactNumber, setContactNumber] = useState("")
  const [organisationName, setOrganisationName] = useState("")

  let organisation_url = new URL(window.location.href).searchParams.get("organisation_url")
  let password_reset_token = new URL(window.location.href).searchParams.get("token")
  const [organisationData, setOrganisationData] = useState({})
  const navigate = useNavigate()




  const register_organisation = async () => {
    let endpoint = config.endpoint + "/auth/register"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        contact_number: contactNumber,
        password: password,
        organisation_name: organisationName
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      window.location.href = "/home"
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const register_member = async () => {
    let endpoint = config.endpoint + "/auth/register/member"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        contact_number: contactNumber,
        password: password,
        organisation_id: organisationData?.id
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      window.location.href = "/home"
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const login = async () => {
    let endpoint = config.endpoint + "/auth/login"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: user,
        password: password
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      window.location.href = "/home"
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const load_organisation = async () => {
    if (!organisation_url && pageState === "register") return navigate("/404")
    if (!organisation_url) return
    // if (pageState !== "register") return
    let endpoint = config.endpoint + "/organisation/enrolment/" + organisation_url
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      if (data.data.is_active == 0) {
        navigate("/404")
      }
      document.title = data.data?.organisation_data?.organisation_name
      setOrganisationData(data.data?.organisation_data)
    }
  }


  const send_reset_link = async () => {
    let endpoint = config.endpoint + "/auth/password/forgot"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: user
      })
    })
    let data = await fetchResponse.json()
    // skipped checks for security reasons
    notify({ message: "Link has been sent to your inbox", type: "success" })
  }


  useEffect(() => {
    load_organisation()
  }, [])


  const resetPassword = async () => {
    // alert(password_reset_token)
    if (!password_reset_token) return notify({ message: "Unable to reset password", type: "error" })
    let endpoint = config.endpoint + "/auth/password/reset"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        code: password_reset_token,
        password: password
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ message: "Password has been reset", type: "success" })
      window.location.href = "/auth/login"
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  // if (pageState === "reset_password" && !password_reset_token) {
  //   return window.location.href = "/auth/login"
  // }

  return (
    <div>
      <Header />
      <ToastContainer />
      <div style={{ flex: 1, display: "flex" }}>
        <div className='column justify-center items-center' style={{ width: "100%" }}>
          <div className="flex flex-col items-center justify-center min-w-96 p-4 bg-white rounded-md shadow m-8">
            <img src={organisationData?.file_data?.ext_file_path ? `${config.endpoint}${organisationData?.file_data?.ext_file_path}` : "/logo.png"} alt="logo" className="my-8" style={{ width: "150px", height: "50px", objectFit: "contain" }} />
            {pageState === "reset_password" && <>

              <h1 className="headerText text-slate-600" style={{ fontWeight: "500", fontSize: 30, marginBottom: 20 }}>Reset Password</h1>
              <div style={{ maxWidth: 400, width: "100%", padding: 10 }}>
                <div className="input-container">
                  <div className="flex flex-col w-full">
                    <label htmlFor="password" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>New Password</label>
                    <input type="password" placeholder="Password" className="text-input" style={{ marginBottom: 10 }} value={password} onChange={(e) => {
                      setPassword(e.target.value)
                    }} />
                  </div>
                </div>
                <div className="button-container">
                  <button className="button shadow-lg  shadow-blue-500/40" style={{ width: "100%" }}
                    onClick={() => {
                      resetPassword()
                    }}
                  >Reset Password</button>
                </div>
              </div>
            </>
            }

            {pageState === "forgot" && <>
              <h1 className="headerText text-slate-600" style={{ fontWeight: "500", fontSize: 30, marginBottom: 20 }}>Forgot Password</h1>
              <div style={{ maxWidth: 400, width: "100%", padding: 10 }}>
                <div className="input-container">
                  <div className="flex flex-col w-full">
                    <label htmlFor="user" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Email Address</label>
                    <input type="email" placeholder="e.g. johntan@gmail.com" className="text-input text-slate-500 w-full" style={{ marginBottom: 10 }} value={user} onChange={(e) => {
                      setUser(e.target.value?.toLowerCase())
                    }} />
                  </div>
                </div>
                <div className="button-container">
                  <button className="button shadow-lg shadow-blue-500/40 font-['Poppins']" style={{ width: "100%" }}
                    onClick={() => {
                      send_reset_link()
                    }}
                  >Send Reset Link</button>
                </div>
              </div>
            </>}

            {pageState === "login" && <>
              <h1 className="headerText text-slate-600" style={{ fontWeight: "500", fontSize: 30, marginBottom: 20 }}>Welcome Back</h1>
              {organisation_url && <>
                <p style={{ marginBottom: 10, fontSize: 12, color: "#8e8e8e" }}>You are logging into {organisationData?.organisation_name}</p>
              </>}
              <div style={{ maxWidth: 400, width: "100%", padding: 10 }}>
                <div className="input-container">
                  <div className="flex flex-col w-full">
                    <label htmlFor="user" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Phone Number, username or email address</label>
                    <input type="text" placeholder="johndoe@domain.com" className="text-input text-slate-500 w-full" style={{ marginBottom: 10 }} value={user} onChange={(e) => {
                      setUser(e.target.value?.toLowerCase())
                    }} />
                  </div>
                  <div className="flex flex-col w-full">
                    <label htmlFor="password" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Password</label>
                    <input type="password" placeholder="********" className="text-input text-slate-500" style={{ marginBottom: 10 }} value={password} onChange={(e) => {
                      setPassword(e.target.value)
                    }} />
                  </div>
                </div>

                <button
                  onClick={() => {
                    navigate("/auth/forgot-password")
                  }}
                >Forgot Password</button>

                <div className="button-container mt-5">
                  <button className="button shadow-lg shadow-blue-500/40 font-['Poppins']" style={{ width: "100%" }}
                    onClick={() => {
                      login()
                    }}
                  >Log In</button>
                </div>

                {/* <div>
                  <p style={{
                    backgroundColor: "white", position: "relative", translate: "transformY(-50%)", top: "50%", bottom: -17, textAlign: "center", fontSize: 12, fontWeight: "500", color: "#8e8e8e", padding: 10, width: "fit-content", margin: "auto"
                  }}>Or create an Account</p>
                  <div style={{ width: "100%", height: 0.5, backgroundColor: "black" }} />
                </div> */}

                {/* <div className="button-container" style={{ marginTop: 30 }}>
                  <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2" style={{ width: "100%", padding: "10px 20px" }}
                    onClick={() => {
                      window.location.href = "/auth/register"
                    }}
                  >Create an Account</button>
                </div> */}
              </div>
            </>}

            {pageState === "register_organisation" && <>
              <h1 className="headerText text-slate-600" style={{ fontWeight: "500", fontSize: 30, marginBottom: 20 }}>Welcome to AviClub</h1>
              <div style={{ maxWidth: 400, width: "100%", padding: 10 }}>
                <div className="input-container">
                  <div className="flex flex-col w-full">
                    <label htmlFor="organisationName" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Organisation Name</label>
                    <input type="name" placeholder="Organisation Name" className="text-input" style={{ width: "100%", marginBottom: 10 }} value={organisationName} onChange={(e) => {
                      setOrganisationName(e.target.value)
                    }} />
                  </div>
                  <div className="row gap-4 items-center" style={{ marginBottom: 10 }}>
                    <div className="flex flex-col w-1/2">
                      <label htmlFor="firstName" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>First Name</label>
                      <input type="name" placeholder="First Name" className="text-input" style={{ width: "100%" }} value={firstName} onChange={(e) => {
                        setFirstName(e.target.value)
                      }} />
                    </div>
                    <div className="flex flex-col w-1/2">
                      <label htmlFor="lastName" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Last Name</label>
                      <input type="name" placeholder="Last Name" className="text-input" style={{ width: "100%" }} value={lastName} onChange={(e) => {
                        setLastName(e.target.value)
                      }} />
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <label htmlFor="email" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Email Address</label>

                    <input type="email" placeholder="Email address" className="text-input" style={{ marginBottom: 10 }} value={email} onChange={(e) => {
                      setEmail(e.target.value?.toLowerCase())
                    }} />
                  </div>
                  <div className="flex flex-col w-full">
                    <label htmlFor="contactNumber" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Phone Number</label>
                    <input type="tel" placeholder="Phone number" className="text-input" style={{ marginBottom: 10 }} value={contactNumber} onChange={(e) => {
                      setContactNumber(e.target.value)
                    }} />
                  </div>
                  <div className="flex flex-col w-full">
                    <label htmlFor="password" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Password</label>
                    <input type="password" placeholder="Password" className="text-input" style={{ marginBottom: 10 }} value={password} onChange={(e) => {
                      setPassword(e.target.value)
                    }} />
                  </div>
                </div>
                <div className="button-container">
                  <button className="button shadow-lg  shadow-blue-500/40" style={{ width: "100%" }}
                    onClick={() => {
                      register_organisation()
                    }}
                  >Register</button>
                </div>

                <p className="text-center text-sm p-4 pb-0">By proceeding, you agree to our <span style={{ fontWeight: "bold" }} className="text-[var(--primary)]">Terms and Conditions</span> and <span style={{ fontWeight: "bold" }} className="text-[var(--primary)]">Privacy Policy</span></p>

                <div>
                  <p style={{
                    backgroundColor: "white", position: "relative", translate: "transformY(-50%)", top: "50%", bottom: -17, textAlign: "center", fontSize: 12, fontWeight: "500", color: "#8e8e8e", padding: 10, width: "fit-content", margin: "auto"
                  }}>Already have an account? Log In</p>
                  <div style={{ width: "100%", height: 0.5, backgroundColor: "black" }} />
                </div>

                <div className="button-container" style={{ marginTop: 30 }}>
                  <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2" style={{ width: "100%", padding: "10px 20px" }}
                    onClick={() => {
                      window.location.href = "/auth/login"
                    }}
                  >Log into your Account</button>
                </div>
              </div>

              <div style={{ marginTop: 30 }}>
                <p style={{
                  backgroundColor: "#ebeff3", position: "relative", translate: "transformY(-50%)", top: "50%", bottom: -17, textAlign: "center", fontSize: 12, fontWeight: "500", color: "#8e8e8e", padding: 10, width: "fit-content", margin: "auto"
                }}>Or create an Account</p>
                <div style={{ width: "100%", height: 0.5, backgroundColor: "black" }} />
              </div>
            </>}

            {pageState === "register" && <>
              {/* Change the Organisation Name */}
              <h1 className="headerText text-slate-600" style={{ fontWeight: "500", fontSize: 15, marginBottom: 5 }}>Welcome to</h1>
              <h1 className="headerText text-slate-600" style={{ fontWeight: "900", fontSize: 30, marginBottom: 20, maxWidth: 450, textAlign: "center" }}>{organisationData?.organisation_name}</h1>
              <div style={{ maxWidth: 400, width: "100%", padding: 10 }}>
                <div className="input-container">
                  {/* <div className="flex flex-col w-full">
                    <label htmlFor="organisationName" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Organisation Name</label>
                    <input type="name" placeholder="Organisation Name" className="text-input" style={{ width: "100%", marginBottom: 10 }} value={organisationName} onChange={(e) => {
                      setOrganisationName(e.target.value)
                    }} />
                  </div> */}

                  <div className="flex flex-col">
                    <label htmlFor="firstName" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>First Name</label>
                    <input type="name" placeholder="First Name" className="text-input" style={{ width: "100%", marginBottom: 10 }} value={firstName} onChange={(e) => {
                      setFirstName(e.target.value)
                    }} />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="lastName" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Last Name</label>
                    <input type="name" placeholder="Last Name" className="text-input" style={{ width: "100%", marginBottom: 10 }} value={lastName} onChange={(e) => {
                      setLastName(e.target.value)
                    }} />
                  </div>

                  <div className="flex flex-col w-full">
                    <label htmlFor="email" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Email Address</label>

                    <input type="email" placeholder="Email address" className="text-input" style={{ marginBottom: 10 }} value={email} onChange={(e) => {
                      setEmail(e.target.value)
                    }} />
                  </div>
                  <div className="flex flex-col w-full">
                    <label htmlFor="contactNumber" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Phone Number</label>
                    <input type="tel" placeholder="Phone number" className="text-input" style={{ marginBottom: 10 }} value={contactNumber} onChange={(e) => {
                      setContactNumber(e.target.value)
                    }} />
                  </div>
                  <div className="flex flex-col w-full">
                    <label htmlFor="password" className="text-xs text-slate-500 font-semibold" style={{ marginBottom: 10 }}>Password</label>
                    <input type="password" placeholder="Password" className="text-input" style={{ marginBottom: 10 }} value={password} onChange={(e) => {
                      setPassword(e.target.value)
                    }} />
                  </div>
                </div>
                <div className="button-container">
                  <button className="button shadow-lg  shadow-blue-500/40" style={{ width: "100%" }}
                    onClick={() => {
                      register_member()
                    }}
                  >Register</button>
                </div>

                <p className="text-center text-sm p-4 pb-0">By proceeding, you agree to our <span style={{ fontWeight: "bold" }} className="text-[var(--primary)]">Terms and Conditions</span> and <span style={{ fontWeight: "bold" }} className="text-[var(--primary)]">Privacy Policy</span></p>

                <div>
                  <p style={{
                    backgroundColor: "white", position: "relative", translate: "transformY(-50%)", top: "50%", bottom: -17, textAlign: "center", fontSize: 12, fontWeight: "500", color: "#8e8e8e", padding: 10, width: "fit-content", margin: "auto"
                  }}>Already have an account? Log In</p>
                  <div style={{ width: "100%", height: 0.5, backgroundColor: "black" }} />
                </div>

                <div className="button-container" style={{ marginTop: 30 }}>
                  <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2" style={{ width: "100%", padding: "10px 20px" }}
                    onClick={() => {
                      window.location.href = "/auth/login"
                    }}
                  >Log into your Account</button>
                </div>
              </div>
            </>}
          </div>
        </div>
      </div>
    </div>
  )
}

