import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { MdClose, MdUpload, MdUploadFile } from 'react-icons/md'
import "../../globals.css"
import notify from '../../utils/IANotification'

export default function DocumentSubmissionPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [documentData, setDocumentData] = useState({
    description: "",
    status: "pending", // pending, processing, approved, rejected
    internal_tags: [],
    internal_remarks: "",
  })
  const [filesToUpload, setFilesToUpload] = useState([])

  // if the user drag over the file input, change the style
  useEffect(() => {
    let fileInput = document.getElementById("file-input")
    let fileInputContainer = document.getElementById("file-input-container")
    fileInput.addEventListener("dragover", (e) => {
      // add a class hover
      e.preventDefault()
      e.stopPropagation()
      fileInputContainer.classList.add("hover")
    })
    fileInput.addEventListener("dragleave", (e) => {
      e.preventDefault()
      e.stopPropagation()
      fileInputContainer.classList.remove("hover")
    })

    fileInput.addEventListener("drop", (e) => {
      e.preventDefault()
      e.stopPropagation()
      fileInputContainer.classList.remove("hover")
      // get the files
      let files = e.dataTransfer.files
      // convert to array
      files = [...files]
      // check if the file is valid
      if (files.length > 0) {
        // set the file input to the files
        fileInput.files = files
        // call the function to upload the files
        setFilesToUpload(files)
      }
    })
  }, [])


  const submitDocuments = async () => {
    if (filesToUpload.length === 0) {
      // Show an error message or handle the case where no files are added
      notify({ message: "Please upload at least one file.", type: "error" });
      return;
    }
    let formData = new FormData()
    formData.append("description", documentData.description)
    formData.append("status", documentData.status)
    formData.append("internal_tags", documentData.internal_tags)
    formData.append("internal_remarks", documentData.internal_remarks)
    for (let i = 0; i < filesToUpload.length; i++) {
      formData.append("files", filesToUpload[i])
    }
    let endpoint = config.endpoint + "/documents/submit"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      body: formData,
      credentials: "include"
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      // success
      navigate("/documents")
    } else {
      // error
      notify({message: data.message, type: "error"})
    }
  }



  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Approval"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header">
              <h1>Submit Documents for Approval</h1>
            </div>
          </div>
          <div className="container">
            <div className="inner-container">
              {/* <div className="input-container" id='file-input'>
                <p className="input-label">File(s)</p>
                <input className="input" type="file" multiple accept="image/*,application/pdf,video/*,audio/*" style={{ display: "none" }} />
                <div className="file-input-container" id='file-input-container' style={{}}>
                  <MdUploadFile size={20} color={"black"} />
                  <p className="file-input-label">{filesToUpload.length > 0 ? `${filesToUpload.length} files attached` : "Upload File(s)"}</p>
                </div>
                {filesToUpload.length > 0 &&
                  <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 10 }}>
                    {filesToUpload.map((file, index) => {
                      return (
                        <div style={{
                          maxWidth: 200,
                          backgroundColor: "gray",
                          display: "flex",
                          gap: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "5px 10px",
                          cursor: "pointer",
                          borderRadius: 5
                        }}
                          onClick={() => {
                            let newFilesToUpload = filesToUpload
                            newFilesToUpload.splice(index, 1)
                            setFilesToUpload([...newFilesToUpload])
                          }}
                        >
                          <p style={{
                            fontSize: 12,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            color:"white"
                          }}>{file.name}</p>
                        </div>
                      )
                    })}
                  </div>
                }
              </div> */}
              <div className="input-container" id="file-input">
                <p className="input-label">File(s)</p>
                <label htmlFor="file-upload" className="cursor-pointer file-input-container">
                  <MdUploadFile size={24} color={"black"} />
                  <p className="file-input-label text-sm mt-2">
                    {filesToUpload.length > 0 ? `${filesToUpload.length} files attached` : "Upload File(s)"}
                  </p>
                </label>
                <input
                  id="file-upload"
                  className="input"
                  type="file"
                  multiple
                  accept="image/*,application/pdf,video/*,audio/*"
                  onChange={(e) => {
                    setFilesToUpload([...e.target.files]);
                  }}
                  style={{ display: "none" }}
                />
                {filesToUpload.length > 0 && (
                  <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 10 }}>
                    {filesToUpload.map((file, index) => (
                      <div
                        key={index}
                        style={{
                          maxWidth: 200,
                          backgroundColor: "gray",
                          display: "flex",
                          gap: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "5px 10px",
                          cursor: "pointer",
                          borderRadius: 5,
                        }}
                        onClick={() => {
                          let newFilesToUpload = [...filesToUpload];
                          newFilesToUpload.splice(index, 1);
                          setFilesToUpload(newFilesToUpload);
                        }}
                      >
                        <p
                          style={{
                            fontSize: 12,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            color: "white",
                          }}
                        >
                          {file.name}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="input-container">
                <p className="input-label">Remarks</p>
                <textarea className="textarea-input"
                  value={documentData.description}
                  onChange={(e) => setDocumentData({ ...documentData, description: e.target.value })}
                />
              </div>
              <div className="row right">
                <button className="button font-['Poppins']"
                  onClick={() => submitDocuments()}
                >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
