import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { currency, isMobile, tidyDate } from '../../utils/helper'
import notify from '../../utils/IANotification'
import { ToastContainer } from 'react-toastify'

export default function HomePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))

  const [selectedNav, setSelectedNav] = useState("upcoming") // upcoming, payment
  const [bookingData, setBookingData] = useState([])
  const [paymentData, setPaymentData] = useState([])


  const fetchUserUpcomingFlights = async () => {
    let endpoint = config.endpoint + "/bookings/user"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      let booking_data = data?.data?.booking_data
      let new_booking_data = []
      for (let i = 0; i < booking_data?.length; i++) {
        let booking = booking_data[i]
        if (booking?.booking_status === "cancelled" || booking?.booking_status === "completed") continue
        if (new Date(booking?.flight_departure_datetime) < new Date()) continue

        new_booking_data.push(booking)
      }

      // sort by date
      new_booking_data = new_booking_data.sort((a, b) => {
        return new Date(a?.flight_departure_datetime) - new Date(b?.flight_departure_datetime)
      })

      setBookingData(new_booking_data)
    }
  }

  const fetchUserPayments = async () => {
    let endpoint = config.endpoint + "/payments/user/unpaid"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setPaymentData(data?.data)
    }
  }

  const get_payment_link = async (payment_id) => {
    let endpoint = config.endpoint + "/payments/link/id/" + payment_id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 201) {
      window.location.href = data?.data
    } else {
      fetchUserPayments()
      notify({ type: "error", message: data?.message })
    }
  }

  useEffect(() => {
    fetchUserUpcomingFlights()
    fetchUserPayments()
  }, [])

  const datetime = () => {
    let dateObj = new Date()
    let hours = dateObj.getHours()
    let minutes = dateObj.getMinutes()
    let seconds = dateObj.getSeconds()
    let day = dateObj.getDay()
    let date = dateObj.getDate()
    let month = dateObj.getMonth()
    let year = dateObj.getFullYear()
    const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    return { date: `${date} ${MONTHS[month].slice(0, 3)}`, time: `${hours}:${minutes}:${seconds}`, day: DAYS[day], month, year }
  }

  return (
    <div style={{ width: "100vw", }}>
      <img src="/plane.png" style={{ position: "absolute", bottom: 10, left: 100, width: isMobile(window.innerWidth) ? "70%" : "30vw", minWidth: "200px", opacity: 0.5, zIndex: 1 }} />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Dashboard"} />
        <ToastContainer />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="container">
            <div className="inner-container">
              {/* <p style={{ fontWeight: "600", fontSize: 20 }}> */}
              <p className="font-medium text-2xl mt-8">
                Hello,&nbsp;
                <span 
                className="font-semibold"
                style={{
                  background: "linear-gradient(90deg, hsla(225, 40%, 54%, 1) 0%, hsla(284, 75%, 54%, 1) 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}>
                 {user_data?.first_name}
                </span>
                </p>
              <p className="mt-4 text-slate-600">It's {datetime().day}, {datetime().date} {datetime().year}</p>

              {/* <div className='row mt-20' style={{ alignItems: "flex-end", }}>
                <p style={{ backgroundColor: "white", borderTop: selectedNav === "upcoming" ? "3px solid purple" : "2px solid gray", padding: 10, paddingTop: selectedNav === "upcoming" ? 20 : 5, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                  className='cursor-pointer'
                  onClick={() => {
                    setSelectedNav("upcoming")
                  }}
                >Your Upcoming Flights</p>
                <p style={{ backgroundColor: "white", borderTop: selectedNav === "payment" ? "3px solid purple" : "2px solid gray", padding: 10, paddingTop: selectedNav === "payment" ? 20 : 5, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                  className='cursor-pointer'
                  onClick={() => {
                    setSelectedNav("payment")
                  }}
                >Payments Due</p>
              </div>
              <div className="card rounded-md shadow-md p-4">
                  {selectedNav === "upcoming"&&<>
                    <p className="card-title">Get Ready for your flight</p>
                  </>}

                  {selectedNav === "payment"&&<>
                    <p className="card-title">Be reminded to submit your payments</p>
                  </>}
              </div> */}

              <div className="card rounded-md shadow-md p-4 mt-8" style={{ borderRadius: 10 }}>
                <p className="card-title">Upcoming Flights</p>

                <div className="mt-4">
                  {bookingData?.length > 0 ? bookingData.map((booking, index) => {
                    return (
                      <>
                        <div className='row gap-4 items-center '>
                          <p style={{ color: "gray", letterSpacing: "1", whiteSpace: "nowrap" }}>{tidyDate(booking?.flight_departure_datetime)?.toUpperCase()}</p>
                          <div style={{ height: 1, backgroundColor: "gray", width: "100%" }} />
                        </div>
                        <div className="cursor-pointer mb-4"
                          onClick={() => {
                            navigate("/flights/bookings/" + booking?.id)
                          }}
                        >
                          <p className="card-title">Flight #{booking?.id}</p>
                          <p>{booking?.flight_type} • {booking?.booking_status}</p>
                        </div>
                      </>
                    )
                  }) :
                    <div className="row justify-center items-center" style={{}}>
                      <p style={{ color: "gray", fontWeight: "600" }}>No upcoming flights 🛩️</p>
                    </div>
                  }
                </div>
              </div>
              <div className="card mt-4 rounded-md shadow-md p-4" style={{ borderRadius: 10 }}>
                <p className="card-title">Payments Due</p>
                <div className="row justify-between">
                  <div className="row gap-4">
                    <p className="card-subtitle">Total Amount Due</p>
                    <p className="card-subtitle">
                      $ {paymentData.length > 0 ? (paymentData?.reduce((acc, curr) => {
                        return parseFloat(acc || 0) + parseFloat(curr?.amount || 0);
                      }, 0) / 100) : 0} SGD
                    </p>
                  </div>
                </div>
                <div className="mt-4">
                  {paymentData && paymentData.map((payment, index) => {
                    return (
                      <div className="row justify-between mb-4">
                        <div>
                          <p className="card-title">{currency((payment?.amount) / 100)} SGD </p>
                          <p>{payment?.remarks}</p>
                        </div>
                        <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2"
                          onClick={() => { get_payment_link(payment?.id) }}
                        >Pay</button>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
