export const airport_database = [
    {
        "iata": "AAA",
        "icao": "NTGA",
        "airportName": "Anaa Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Anaa",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.3526001",
        "longitude": "-145.5099945",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4921",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87360,
            "s_seats": 29845,
            "j_seats": 12224,
            "f_seats": 3969,
            "r_seats": 6359,
            "cargo_demand": 81632
        }
    },
    {
        "iata": "AAB",
        "icao": "YARY",
        "airportName": "Arrabury Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Arrabury",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.693023",
        "longitude": "141.047771",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3379",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1763,
            "s_seats": 2729,
            "j_seats": 1290,
            "f_seats": 128,
            "r_seats": 170,
            "cargo_demand": 26063
        }
    },
    {
        "iata": "AAC",
        "icao": "HEAR",
        "airportName": "El Arish International Airport",
        "location_country": "Egypt",
        "location_city": "El Arish",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.07329941",
        "longitude": "33.83580017",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9905",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176372,
            "s_seats": 35480,
            "j_seats": 30349,
            "f_seats": 3594,
            "r_seats": 3256,
            "cargo_demand": 110042
        }
    },
    {
        "iata": "AAE",
        "icao": "DABB",
        "airportName": "Rabah Bitat Airport",
        "location_country": "Algeria",
        "location_city": "Annaba",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.822201",
        "longitude": "7.809174",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7513",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81205,
            "s_seats": 29340,
            "j_seats": 9860,
            "f_seats": 10894,
            "r_seats": 3909,
            "cargo_demand": 481218
        }
    },
    {
        "iata": "AAF",
        "icao": "KAAF",
        "airportName": "Apalachicola Regional Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Apalachicola",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "29.72750092",
        "longitude": "-85.02749634",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5070",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5350",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5265",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1356,
            "s_seats": 2254,
            "j_seats": 1363,
            "f_seats": 83,
            "r_seats": 153,
            "cargo_demand": 75657
        }
    },
    {
        "iata": "AAG",
        "icao": "SSYA",
        "airportName": "Arapoti Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Arapoti",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-24.103901",
        "longitude": "-49.789101",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "4593",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3458,
            "s_seats": 2709,
            "j_seats": 1660,
            "f_seats": 419,
            "r_seats": 88,
            "cargo_demand": 39156
        }
    },
    {
        "iata": "AAH",
        "icao": "EDKA",
        "airportName": "Merzbruck Airport",
        "location_country": "Germany",
        "location_city": "North Rhine-Westphalia",
        "location_state": "Aachen",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "50.823055",
        "longitude": "6.186389",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3805",
                "runway_width": "59",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 3730,
            "s_seats": 2918,
            "j_seats": 1528,
            "f_seats": 412,
            "r_seats": 155,
            "cargo_demand": 68766
        }
    },
    {
        "iata": "AAI",
        "icao": "SWRA",
        "airportName": "Arraias Airport",
        "location_country": "Brazil",
        "location_city": "Tocantins",
        "location_state": "Arraias",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.025154",
        "longitude": "-46.884107",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4921",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3678,
            "s_seats": 1713,
            "j_seats": 1691,
            "f_seats": 435,
            "r_seats": 84,
            "cargo_demand": 54067
        }
    },
    {
        "iata": "AAL",
        "icao": "EKYT",
        "airportName": "Aalborg Airport",
        "location_country": "Denmark",
        "location_city": "Aalborg",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "57.09275891",
        "longitude": "9.849243164",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "8707",
                "runway_width": "148",
                "runway_material": "PEM"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "8363",
                "runway_width": "74",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 436465,
            "s_seats": 82525,
            "j_seats": 69880,
            "f_seats": 67841,
            "r_seats": 10000,
            "cargo_demand": 9141202
        }
    },
    {
        "iata": "AAM",
        "icao": "FAMD",
        "airportName": "Mala Mala Airport",
        "location_country": "South Africa",
        "location_city": "Mala Mala",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-24.81809998",
        "longitude": "31.54459953",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4145",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187487,
            "s_seats": 34468,
            "j_seats": 11341,
            "f_seats": 3634,
            "r_seats": 9223,
            "cargo_demand": 424214
        }
    },
    {
        "iata": "AAN",
        "icao": "OMAL",
        "airportName": "Al Ain International Airport",
        "location_country": "United Arab Emirates",
        "location_city": "Al Ain",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.26169968",
        "longitude": "55.60919952",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92830,
            "s_seats": 47136,
            "j_seats": 57185,
            "f_seats": 11907,
            "r_seats": 5666,
            "cargo_demand": 65684
        }
    },
    {
        "iata": "AAO",
        "icao": "SVAN",
        "airportName": "Anaco Airport",
        "location_country": "Venezuela",
        "location_city": "Anaco",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.430225372",
        "longitude": "-64.47072601",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4134",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175223,
            "s_seats": 36413,
            "j_seats": 24306,
            "f_seats": 16191,
            "r_seats": 3633,
            "cargo_demand": 185673
        }
    },
    {
        "iata": "AAQ",
        "icao": "URKA",
        "airportName": "Anapa Airport",
        "location_country": "Russia",
        "location_city": "Krasnodar Krai",
        "location_state": "Anapa",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.0021019",
        "longitude": "37.34730148",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8202",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 159834,
            "s_seats": 41803,
            "j_seats": 42817,
            "f_seats": 13821,
            "r_seats": 5117,
            "cargo_demand": 399510
        }
    },
    {
        "iata": "AAR",
        "icao": "EKAH",
        "airportName": "Aarhus Airport",
        "location_country": "Denmark",
        "location_city": "Aarhus",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "56.29999924",
        "longitude": "10.61900043",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "9111",
                "runway_width": "75",
                "runway_material": "PEM"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "8865",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 112858,
            "s_seats": 41135,
            "j_seats": 9947,
            "f_seats": 10440,
            "r_seats": 7918,
            "cargo_demand": 428531
        }
    },
    {
        "iata": "AAS",
        "icao": "DAAS",
        "airportName": "Apalapsili Airport",
        "location_country": "Indonesia",
        "location_city": "Apalapsili",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.17810059",
        "longitude": "5.32449007",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9498",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177772,
            "s_seats": 37954,
            "j_seats": 23161,
            "f_seats": 8240,
            "r_seats": 4926,
            "cargo_demand": 342750
        }
    },
    {
        "iata": "AAV",
        "icao": "RPMA",
        "airportName": "Allah Valley Airport",
        "location_country": "Philippines",
        "location_city": "Surallah",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.366819859",
        "longitude": "124.7509995",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4396",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116705,
            "s_seats": 46744,
            "j_seats": 53761,
            "f_seats": 9229,
            "r_seats": 4066,
            "cargo_demand": 474003
        }
    },
    {
        "iata": "AAX",
        "icao": "SBAX",
        "airportName": "Araxa Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Araxa",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-19.5632",
        "longitude": "-46.9604",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6234",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2791,
            "s_seats": 1589,
            "j_seats": 1633,
            "f_seats": 28,
            "r_seats": 12,
            "cargo_demand": 22383
        }
    },
    {
        "iata": "AAY",
        "icao": "OYGD",
        "airportName": "Al Ghaydah Airport",
        "location_country": "Yemen",
        "location_city": "Al Ghaydah",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.19169998",
        "longitude": "52.17499924",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157338,
            "s_seats": 32662,
            "j_seats": 41827,
            "f_seats": 18079,
            "r_seats": 6589,
            "cargo_demand": 431630
        }
    },
    {
        "iata": "ABA",
        "icao": "UNAA",
        "airportName": "Abakan International Airport",
        "location_country": "Russia",
        "location_city": "Republic of Khakassia",
        "location_state": "Abakan",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.740002",
        "longitude": "91.385002",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "10663",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02C/20C",
                "runway_length": "8202",
                "runway_width": "263",
                "runway_material": "GRS"
            },
            {
                "runway_name": "02L/20R",
                "runway_length": "4265",
                "runway_width": "92",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106958,
            "s_seats": 40974,
            "j_seats": 23079,
            "f_seats": 7147,
            "r_seats": 8772,
            "cargo_demand": 491684
        }
    },
    {
        "iata": "ABB",
        "icao": "DNAS",
        "airportName": "Asaba International Airport",
        "location_country": "Nigeria",
        "location_city": "Asaba",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.204167",
        "longitude": "6.665278",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "11155",
                "runway_width": "190",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181671,
            "s_seats": 25484,
            "j_seats": 56493,
            "f_seats": 4571,
            "r_seats": 6625,
            "cargo_demand": 348449
        }
    },
    {
        "iata": "ABC",
        "icao": "LEAB",
        "airportName": "Albacete Airport",
        "location_country": "Spain",
        "location_city": "Castilla-La Mancha",
        "location_state": "Albacete",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "38.948502",
        "longitude": "-1.86352",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8858",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151941,
            "s_seats": 35230,
            "j_seats": 29385,
            "f_seats": 9386,
            "r_seats": 8309,
            "cargo_demand": 65104
        }
    },
    {
        "iata": "ABD",
        "icao": "OIAA",
        "airportName": "Abadan International Airport",
        "location_country": "Iran",
        "location_city": "Abadan",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "30.367887",
        "longitude": "48.230075",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "7437",
                "runway_width": "115",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "10169",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 65408,
            "s_seats": 37006,
            "j_seats": 55157,
            "f_seats": 14097,
            "r_seats": 9562,
            "cargo_demand": 414243
        }
    },
    {
        "iata": "ABE",
        "icao": "KABE",
        "airportName": "Lehigh Valley International Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Allentown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.651773",
        "longitude": "-75.442797",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7600",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5797",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54145,
            "s_seats": 35742,
            "j_seats": 58491,
            "f_seats": 3566,
            "r_seats": 8234,
            "cargo_demand": 138816
        }
    },
    {
        "iata": "ABH",
        "icao": "YAPH",
        "airportName": "Alpha Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Alpha",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.646099",
        "longitude": "146.584",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4777",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72799,
            "s_seats": 28936,
            "j_seats": 52388,
            "f_seats": 1178,
            "r_seats": 6296,
            "cargo_demand": 231696
        }
    },
    {
        "iata": "ABI",
        "icao": "KABI",
        "airportName": "Abilene Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Abilene",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.41130066",
        "longitude": "-99.68190002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3678",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "7198",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "7202",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184781,
            "s_seats": 25132,
            "j_seats": 38294,
            "f_seats": 10777,
            "r_seats": 3014,
            "cargo_demand": 302890
        }
    },
    {
        "iata": "ABJ",
        "icao": "DIAP",
        "airportName": "Port Bouet Airport",
        "location_country": "Cote d'Ivoire",
        "location_city": "Abidjan",
        "location_state": "",
        "time_zone": "UTC+00:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.26139",
        "longitude": "-3.92629",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9843",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183749,
            "s_seats": 48299,
            "j_seats": 37717,
            "f_seats": 18447,
            "r_seats": 9519,
            "cargo_demand": 120077
        }
    },
    {
        "iata": "ABL",
        "icao": "PAFM",
        "airportName": "Ambler Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Ambler",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "67.1063",
        "longitude": "-157.856989",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2400",
                "runway_width": "60",
                "runway_material": "GVL"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 155466,
            "s_seats": 42853,
            "j_seats": 13141,
            "f_seats": 1506,
            "r_seats": 6947,
            "cargo_demand": 438222
        }
    },
    {
        "iata": "ABM",
        "icao": "YNPE",
        "airportName": "Northern Peninsula Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Bamaga",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.9508",
        "longitude": "142.459",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5462",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3277,
            "s_seats": 2544,
            "j_seats": 1883,
            "f_seats": 161,
            "r_seats": 136,
            "cargo_demand": 43862
        }
    },
    {
        "iata": "ABQ",
        "icao": "KABQ",
        "airportName": "Albuquerque International Sunport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Albuquerque",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "35.040199",
        "longitude": "-106.609001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CONC-G"
            },
            {
                "runway_name": "08/26",
                "runway_length": "13793",
                "runway_width": "150",
                "runway_material": "CONC-G"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "CONC-G"
            },
            {
                "runway_name": "17/35",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASPH-CONC-F"
            }
        ],
        "demand": {
            "y_seats": 376153,
            "s_seats": 79024,
            "j_seats": 133064,
            "f_seats": 33173,
            "r_seats": 10000,
            "cargo_demand": 25527522
        }
    },
    {
        "iata": "ABR",
        "icao": "KABR",
        "airportName": "Aberdeen Regional Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Aberdeen",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "45.44910049",
        "longitude": "-98.42179871",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6901",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113157,
            "s_seats": 45727,
            "j_seats": 10951,
            "f_seats": 10847,
            "r_seats": 6794,
            "cargo_demand": 226931
        }
    },
    {
        "iata": "ABS",
        "icao": "HEBL",
        "airportName": "Abu Simbel Airport",
        "location_country": "Egypt",
        "location_city": "Abu Simbel",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.37599945",
        "longitude": "31.61170006",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131484,
            "s_seats": 44581,
            "j_seats": 15068,
            "f_seats": 5077,
            "r_seats": 3684,
            "cargo_demand": 165755
        }
    },
    {
        "iata": "ABT",
        "icao": "OEBA",
        "airportName": "Al-Baha Domestic Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Al Bahah",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.29610062",
        "longitude": "41.63430023",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10991",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99677,
            "s_seats": 35288,
            "j_seats": 47257,
            "f_seats": 9571,
            "r_seats": 8594,
            "cargo_demand": 78150
        }
    },
    {
        "iata": "ABV",
        "icao": "DNAA",
        "airportName": "Nnamdi Azikiwe International Airport",
        "location_country": "Nigeria",
        "location_city": "Abuja",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "9.006790161",
        "longitude": "7.263169765",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "11842",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 323584,
            "s_seats": 87928,
            "j_seats": 99504,
            "f_seats": 49763,
            "r_seats": 10000,
            "cargo_demand": 18907098
        }
    },
    {
        "iata": "ABX",
        "icao": "YMAY",
        "airportName": "Albury Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Albury",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-36.06779861",
        "longitude": "146.9579926",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6234",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97554,
            "s_seats": 48775,
            "j_seats": 42199,
            "f_seats": 9168,
            "r_seats": 9808,
            "cargo_demand": 441361
        }
    },
    {
        "iata": "ABY",
        "icao": "KABY",
        "airportName": "Southwest Georgia Regional Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Albany",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.53549957",
        "longitude": "-84.19450378",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6601",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 60426,
            "s_seats": 32144,
            "j_seats": 54099,
            "f_seats": 18484,
            "r_seats": 8452,
            "cargo_demand": 55248
        }
    },
    {
        "iata": "ABZ",
        "icao": "EGPD",
        "airportName": "Aberdeen Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Aberdeen",
        "time_zone": "UTC+00:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "57.2019",
        "longitude": "-2.19778",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "1893",
                "runway_width": "151",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2165",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6407",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "853",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185863,
            "s_seats": 26389,
            "j_seats": 53981,
            "f_seats": 11579,
            "r_seats": 5786,
            "cargo_demand": 289612
        }
    },
    {
        "iata": "ACA",
        "icao": "MMAA",
        "airportName": "General Juan N. alvarez International Airport",
        "location_country": "Mexico",
        "location_city": "Guerrero",
        "location_state": "Acapulco",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "large_airport",
        "latitude": "16.75709915",
        "longitude": "-99.7539978",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5579",
                "runway_width": "115",
                "runway_material": "CON"
            },
            {
                "runway_name": "10/28",
                "runway_length": "10832",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 360202,
            "s_seats": 73040,
            "j_seats": 67211,
            "f_seats": 56497,
            "r_seats": 10000,
            "cargo_demand": 12065202
        }
    },
    {
        "iata": "ACB",
        "icao": "KACB",
        "airportName": "Antrim County Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Bellaire",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.988602",
        "longitude": "-85.198402",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1014,
            "s_seats": 2527,
            "j_seats": 1539,
            "f_seats": 282,
            "r_seats": 38,
            "cargo_demand": 97356
        }
    },
    {
        "iata": "ACC",
        "icao": "DGAA",
        "airportName": "Kotoka International Airport",
        "location_country": "Ghana",
        "location_city": "Accra",
        "location_state": "",
        "time_zone": "UTC+00:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "5.6051898",
        "longitude": "-0.166786",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "11165",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 496479,
            "s_seats": 96119,
            "j_seats": 66339,
            "f_seats": 55212,
            "r_seats": 10000,
            "cargo_demand": 23933558
        }
    },
    {
        "iata": "ACE",
        "icao": "GCRR",
        "airportName": "Lanzarote Airport",
        "location_country": "Spain",
        "location_city": "Canary Islands",
        "location_state": "Lanzarote",
        "time_zone": "UTC+00:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "28.945499",
        "longitude": "-13.6052",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165356,
            "s_seats": 86692,
            "j_seats": 33595,
            "f_seats": 43726,
            "r_seats": 10000,
            "cargo_demand": 27406252
        }
    },
    {
        "iata": "ACH",
        "icao": "LSZR",
        "airportName": "St. Gallen-Altenrhein Airport",
        "location_country": "Switzerland",
        "location_city": "Altenrhein",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.48500061",
        "longitude": "9.560770035",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "2657",
                "runway_width": "65",
                "runway_material": "GRASS"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 116761,
            "s_seats": 39911,
            "j_seats": 56876,
            "f_seats": 13363,
            "r_seats": 4636,
            "cargo_demand": 442749
        }
    },
    {
        "iata": "ACI",
        "icao": "EGJA",
        "airportName": "Alderney Airport",
        "location_country": "United Kingdom",
        "location_city": "Channel Islands",
        "location_state": "Alderney",
        "time_zone": "UTC+00:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "49.706104",
        "longitude": "-2.21472",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1630",
                "runway_width": "121",
                "runway_material": "GRE"
            },
            {
                "runway_name": "08/26",
                "runway_length": "2887",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2402",
                "runway_width": "121",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1829,
            "s_seats": 2916,
            "j_seats": 1693,
            "f_seats": 14,
            "r_seats": 45,
            "cargo_demand": 45728
        }
    },
    {
        "iata": "ACJ",
        "icao": "VCCA",
        "airportName": "Anuradhapura Airport",
        "location_country": "Sri Lanka",
        "location_city": "Anuradhapura",
        "location_state": "",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.301542",
        "longitude": "80.42848",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4876",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171231,
            "s_seats": 38802,
            "j_seats": 8957,
            "f_seats": 10462,
            "r_seats": 3363,
            "cargo_demand": 81808
        }
    },
    {
        "iata": "ACK",
        "icao": "KACK",
        "airportName": "Nantucket Memorial Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Nantucket",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.25310135",
        "longitude": "-70.06020355",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6303",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2696",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124744,
            "s_seats": 30090,
            "j_seats": 45135,
            "f_seats": 6517,
            "r_seats": 4553,
            "cargo_demand": 293379
        }
    },
    {
        "iata": "ACN",
        "icao": "MMCC",
        "airportName": "Ciudad Acuna International Airport",
        "location_country": "Mexico",
        "location_city": "Coahuila",
        "location_state": "Ciudad Acuna",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "29.332899",
        "longitude": "-101.098998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5910",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2080,
            "s_seats": 2893,
            "j_seats": 1464,
            "f_seats": 224,
            "r_seats": 184,
            "cargo_demand": 41046
        }
    },
    {
        "iata": "ACP",
        "icao": "OITM",
        "airportName": "Sahand Airport",
        "location_country": "Iran",
        "location_city": "Maragheh",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "small_airport",
        "latitude": "37.34799957",
        "longitude": "46.12789917",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9614",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2196,
            "s_seats": 2337,
            "j_seats": 1150,
            "f_seats": 250,
            "r_seats": 55,
            "cargo_demand": 14330
        }
    },
    {
        "iata": "ACS",
        "icao": "UNKS",
        "airportName": "Achinsk Airport",
        "location_country": "Russia",
        "location_city": "Krasnoyarsk Krai",
        "location_state": "Achinsk",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.269185",
        "longitude": "90.57511",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8038",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 157434,
            "s_seats": 41521,
            "j_seats": 8335,
            "f_seats": 12729,
            "r_seats": 5014,
            "cargo_demand": 303714
        }
    },
    {
        "iata": "ACT",
        "icao": "KACT",
        "airportName": "Waco Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Waco",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.61129951",
        "longitude": "-97.23049927",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6596",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5896",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180543,
            "s_seats": 26099,
            "j_seats": 12045,
            "f_seats": 12545,
            "r_seats": 3097,
            "cargo_demand": 96225
        }
    },
    {
        "iata": "ACV",
        "icao": "KACV",
        "airportName": "Arcata-Eureka Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Eureka / Arcata",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "40.978101",
        "longitude": "-124.109",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4499",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186738,
            "s_seats": 82906,
            "j_seats": 150458,
            "f_seats": 38796,
            "r_seats": 10000,
            "cargo_demand": 6371038
        }
    },
    {
        "iata": "ACY",
        "icao": "KACY",
        "airportName": "Atlantic City International Airport",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Atlantic City",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.45759964",
        "longitude": "-74.57720184",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6144",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13/31",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76309,
            "s_seats": 31909,
            "j_seats": 35947,
            "f_seats": 7339,
            "r_seats": 7866,
            "cargo_demand": 49872
        }
    },
    {
        "iata": "ACZ",
        "icao": "OIZB",
        "airportName": "Zabol Airport",
        "location_country": "Iran",
        "location_city": "Zabol",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "31.098301",
        "longitude": "61.5439",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9848",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165228,
            "s_seats": 38587,
            "j_seats": 20502,
            "f_seats": 18192,
            "r_seats": 3115,
            "cargo_demand": 377221
        }
    },
    {
        "iata": "ADA",
        "icao": "LTAF",
        "airportName": "Adana Sakirpasa Airport",
        "location_country": "Turkey",
        "location_city": "Adana",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.982201",
        "longitude": "35.280399",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9022",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 209206,
            "s_seats": 75115,
            "j_seats": 24550,
            "f_seats": 64887,
            "r_seats": 10000,
            "cargo_demand": 26989307
        }
    },
    {
        "iata": "ADB",
        "icao": "LTBJ",
        "airportName": "Adnan Menderes Airport",
        "location_country": "Turkey",
        "location_city": "Izmir",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "38.29240036",
        "longitude": "27.15699959",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "10630",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "10630",
                "runway_width": "147",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 190518,
            "s_seats": 98667,
            "j_seats": 88793,
            "f_seats": 42986,
            "r_seats": 10000,
            "cargo_demand": 11051901
        }
    },
    {
        "iata": "ADD",
        "icao": "HAAB",
        "airportName": "Bole International Airport",
        "location_country": "Ethiopia",
        "location_city": "Addis Ababa",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "8.977890015",
        "longitude": "38.79930115",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "12139",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "12467",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 364987,
            "s_seats": 79034,
            "j_seats": 57744,
            "f_seats": 69320,
            "r_seats": 10000,
            "cargo_demand": 3749048
        }
    },
    {
        "iata": "ADE",
        "icao": "OYAA",
        "airportName": "Aden International Airport",
        "location_country": "Yemen",
        "location_city": "Aden",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.8295",
        "longitude": "45.028801",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57941,
            "s_seats": 31103,
            "j_seats": 45100,
            "f_seats": 5182,
            "r_seats": 3262,
            "cargo_demand": 357348
        }
    },
    {
        "iata": "ADF",
        "icao": "LTCP",
        "airportName": "Adiyaman Airport",
        "location_country": "Turkey",
        "location_city": "Adiyaman",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.73139954",
        "longitude": "38.46889877",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8212",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 192911,
            "s_seats": 48818,
            "j_seats": 35972,
            "f_seats": 6529,
            "r_seats": 6241,
            "cargo_demand": 382128
        }
    },
    {
        "iata": "ADG",
        "icao": "KADG",
        "airportName": "Lenawee County Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Adrian",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.86769867",
        "longitude": "-84.07730103",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "1810",
                "runway_width": "150",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2886,
            "s_seats": 2536,
            "j_seats": 1484,
            "f_seats": 187,
            "r_seats": 16,
            "cargo_demand": 1558
        }
    },
    {
        "iata": "ADH",
        "icao": "UEEA",
        "airportName": "Aldan Airport",
        "location_country": "Russia",
        "location_city": "Yakutia",
        "location_state": "Aldan",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "58.60279846",
        "longitude": "125.4089966",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4514",
                "runway_width": "111",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1583,
            "s_seats": 2815,
            "j_seats": 1519,
            "f_seats": 105,
            "r_seats": 141,
            "cargo_demand": 24999
        }
    },
    {
        "iata": "ADI",
        "icao": "FYAR",
        "airportName": "Arandis Airport",
        "location_country": "Namibia",
        "location_city": "Arandis",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-22.46220016",
        "longitude": "14.97999954",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6300",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149766,
            "s_seats": 25420,
            "j_seats": 13906,
            "f_seats": 12594,
            "r_seats": 9273,
            "cargo_demand": 425988
        }
    },
    {
        "iata": "ADJ",
        "icao": "OJAM",
        "airportName": "Amman Civil Airport",
        "location_country": "Jordan",
        "location_city": "Amman",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "31.97270012",
        "longitude": "35.99160004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10745",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90367,
            "s_seats": 48801,
            "j_seats": 35205,
            "f_seats": 19022,
            "r_seats": 3229,
            "cargo_demand": 52830
        }
    },
    {
        "iata": "ADK",
        "icao": "PADK",
        "airportName": "Adak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Adak Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "51.883564",
        "longitude": "-176.642783",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7790",
                "runway_width": "200",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7605",
                "runway_width": "200",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 188922,
            "s_seats": 44614,
            "j_seats": 56266,
            "f_seats": 4544,
            "r_seats": 3223,
            "cargo_demand": 360312
        }
    },
    {
        "iata": "ADL",
        "icao": "YPAD",
        "airportName": "Adelaide Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Adelaide",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "large_airport",
        "latitude": "-34.945",
        "longitude": "138.531006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5420",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 337239,
            "s_seats": 74846,
            "j_seats": 112552,
            "f_seats": 34183,
            "r_seats": 10000,
            "cargo_demand": 16296831
        }
    },
    {
        "iata": "ADM",
        "icao": "KADM",
        "airportName": "Ardmore Municipal Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Ardmore",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.30301",
        "longitude": "-97.0196342",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6761",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "08/26",
                "runway_length": "7225",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "9001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5350",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2269,
            "s_seats": 1894,
            "j_seats": 1488,
            "f_seats": 125,
            "r_seats": 11,
            "cargo_demand": 22825
        }
    },
    {
        "iata": "ADO",
        "icao": "YAMK",
        "airportName": "Andamooka Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Andamooka",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-30.438299",
        "longitude": "137.136993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2050",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1241,
            "s_seats": 2552,
            "j_seats": 1038,
            "f_seats": 97,
            "r_seats": 181,
            "cargo_demand": 79522
        }
    },
    {
        "iata": "ADP",
        "icao": "VCCG",
        "airportName": "Ampara Airport",
        "location_country": "Sri Lanka",
        "location_city": "Ampara",
        "location_state": "",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.336745",
        "longitude": "81.623869",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3600",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169323,
            "s_seats": 35901,
            "j_seats": 10377,
            "f_seats": 18967,
            "r_seats": 5141,
            "cargo_demand": 473802
        }
    },
    {
        "iata": "ADQ",
        "icao": "PADQ",
        "airportName": "Kodiak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kodiak",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "57.75",
        "longitude": "-152.4940033",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7542",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5399",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5013",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166586,
            "s_seats": 45356,
            "j_seats": 45879,
            "f_seats": 18893,
            "r_seats": 8636,
            "cargo_demand": 462813
        }
    },
    {
        "iata": "ADR",
        "icao": "KPHH",
        "airportName": "Robert F. Swinnie Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Andrews",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.4516983",
        "longitude": "-79.52619934",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3001",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2264,
            "s_seats": 2022,
            "j_seats": 1093,
            "f_seats": 134,
            "r_seats": 165,
            "cargo_demand": 7349
        }
    },
    {
        "iata": "ADS",
        "icao": "KADS",
        "airportName": "Addison Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Dallas",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.96860123",
        "longitude": "-96.83640289",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7202",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2831,
            "s_seats": 2226,
            "j_seats": 1635,
            "f_seats": 227,
            "r_seats": 59,
            "cargo_demand": 64008
        }
    },
    {
        "iata": "ADT",
        "icao": "KADH",
        "airportName": "Ada Municipal Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Ada",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.805214",
        "longitude": "-96.671988",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3103",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6203",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2310,
            "s_seats": 1809,
            "j_seats": 1193,
            "f_seats": 62,
            "r_seats": 33,
            "cargo_demand": 41523
        }
    },
    {
        "iata": "ADU",
        "icao": "OITL",
        "airportName": "Ardabil Airport",
        "location_country": "Iran",
        "location_city": "Ardabil",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "38.32569885",
        "longitude": "48.42440033",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "10823",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 56892,
            "s_seats": 34874,
            "j_seats": 16071,
            "f_seats": 4272,
            "r_seats": 3166,
            "cargo_demand": 98657
        }
    },
    {
        "iata": "ADW",
        "icao": "KADW",
        "airportName": "Andrews Field",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Camp Springs",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "38.810799",
        "longitude": "-76.866997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "9300",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "9755",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 482083,
            "s_seats": 87039,
            "j_seats": 146081,
            "f_seats": 40265,
            "r_seats": 10000,
            "cargo_demand": 29163865
        }
    },
    {
        "iata": "ADX",
        "icao": "EGQL",
        "airportName": "RAF Leuchars",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "St Andrews",
        "time_zone": "UTC+00:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "56.3728981",
        "longitude": "-2.868439913",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4803",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "8491",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57554,
            "s_seats": 26946,
            "j_seats": 43406,
            "f_seats": 6132,
            "r_seats": 5300,
            "cargo_demand": 79695
        }
    },
    {
        "iata": "ADY",
        "icao": "FAAL",
        "airportName": "Alldays Airport",
        "location_country": "South Africa",
        "location_city": "Alldays",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.67900085",
        "longitude": "29.05550003",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4757",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1980,
            "s_seats": 2866,
            "j_seats": 1951,
            "f_seats": 318,
            "r_seats": 41,
            "cargo_demand": 17839
        }
    },
    {
        "iata": "ADZ",
        "icao": "SKSP",
        "airportName": "Gustavo Rojas Pinilla International Airport",
        "location_country": "Colombia",
        "location_city": "San Andres Island",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.5836",
        "longitude": "-81.7112",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7808",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58512,
            "s_seats": 30421,
            "j_seats": 47031,
            "f_seats": 4218,
            "r_seats": 9980,
            "cargo_demand": 132727
        }
    },
    {
        "iata": "AEG",
        "icao": "WIME",
        "airportName": "Aek Godang Airport",
        "location_country": "Indonesia",
        "location_city": "Padang Sidempuan",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.4001",
        "longitude": "99.430496",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4580",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70807,
            "s_seats": 30203,
            "j_seats": 9090,
            "f_seats": 12246,
            "r_seats": 7912,
            "cargo_demand": 301032
        }
    },
    {
        "iata": "AEH",
        "icao": "FTTC",
        "airportName": "Abeche Airport",
        "location_country": "Chad",
        "location_city": "Abeche",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.847",
        "longitude": "20.844299",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9186",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57704,
            "s_seats": 43123,
            "j_seats": 26577,
            "f_seats": 17143,
            "r_seats": 4482,
            "cargo_demand": 202045
        }
    },
    {
        "iata": "AEL",
        "icao": "KAEL",
        "airportName": "Albert Lea Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Albert Lea",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.68149948",
        "longitude": "-93.36720276",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2899",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4501",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3059,
            "s_seats": 2610,
            "j_seats": 1589,
            "f_seats": 11,
            "r_seats": 141,
            "cargo_demand": 516
        }
    },
    {
        "iata": "AEO",
        "icao": "GQNA",
        "airportName": "Aioun el Atrouss Airport",
        "location_country": "Mauritania",
        "location_city": "Aioun el Atrouss",
        "location_state": "",
        "time_zone": "UTC+00:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "16.7112999",
        "longitude": "-9.637880325",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5245",
                "runway_width": "100",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 1724,
            "s_seats": 1828,
            "j_seats": 1588,
            "f_seats": 12,
            "r_seats": 136,
            "cargo_demand": 65638
        }
    },
    {
        "iata": "AEP",
        "icao": "SABE",
        "airportName": "Jorge Newbery Airpark",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.5592",
        "longitude": "-58.4156",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8858",
                "runway_width": "131",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 168406,
            "s_seats": 25854,
            "j_seats": 56370,
            "f_seats": 15690,
            "r_seats": 5841,
            "cargo_demand": 339326
        }
    },
    {
        "iata": "AER",
        "icao": "URSS",
        "airportName": "Sochi International Airport",
        "location_country": "Russia",
        "location_city": "Krasnodar Krai",
        "location_state": "Sochi",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.449902",
        "longitude": "39.9566",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7218",
                "runway_width": "161",
                "runway_material": "CON"
            },
            {
                "runway_name": "06/24",
                "runway_length": "9482",
                "runway_width": "164",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 107552,
            "s_seats": 82182,
            "j_seats": 31249,
            "f_seats": 56375,
            "r_seats": 10000,
            "cargo_demand": 12162597
        }
    },
    {
        "iata": "AES",
        "icao": "ENAL",
        "airportName": "\u00c5lesund Airport",
        "location_country": "Norway",
        "location_city": "\u00c5lesund",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "62.5625",
        "longitude": "6.119699955",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7592",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151047,
            "s_seats": 41414,
            "j_seats": 27106,
            "f_seats": 13827,
            "r_seats": 5351,
            "cargo_demand": 149731
        }
    },
    {
        "iata": "AET",
        "icao": "PFAL",
        "airportName": "Allakaket Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Allakaket",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "66.55180359",
        "longitude": "-152.621994",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 3896,
            "s_seats": 1535,
            "j_seats": 1914,
            "f_seats": 77,
            "r_seats": 167,
            "cargo_demand": 56841
        }
    },
    {
        "iata": "AEU",
        "icao": "OIBA",
        "airportName": "Abu Musa Airport",
        "location_country": "Iran",
        "location_city": "Abu Musa Island",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "25.8757",
        "longitude": "55.033001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9796",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112906,
            "s_seats": 26731,
            "j_seats": 15153,
            "f_seats": 16741,
            "r_seats": 4924,
            "cargo_demand": 285134
        }
    },
    {
        "iata": "AEX",
        "icao": "KAEX",
        "airportName": "Alexandria International Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Alexandria",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.3274",
        "longitude": "-92.549797",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9352",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194464,
            "s_seats": 31098,
            "j_seats": 32886,
            "f_seats": 12797,
            "r_seats": 6007,
            "cargo_demand": 343323
        }
    },
    {
        "iata": "AEY",
        "icao": "BIAR",
        "airportName": "Akureyri Airport",
        "location_country": "Iceland",
        "location_city": "Akureyri",
        "location_state": "",
        "time_zone": "UTC+00:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "65.66000366",
        "longitude": "-18.0727005",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152056,
            "s_seats": 29385,
            "j_seats": 49518,
            "f_seats": 5585,
            "r_seats": 4556,
            "cargo_demand": 95769
        }
    },
    {
        "iata": "AFA",
        "icao": "SAMR",
        "airportName": "San Rafael Airport",
        "location_country": "Argentina",
        "location_city": "Mendoza",
        "location_state": "San Rafael",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.588299",
        "longitude": "-68.4039",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6923",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121954,
            "s_seats": 45343,
            "j_seats": 56078,
            "f_seats": 17298,
            "r_seats": 7645,
            "cargo_demand": 384200
        }
    },
    {
        "iata": "AFD",
        "icao": "FAPA",
        "airportName": "Port Alfred Airport",
        "location_country": "South Africa",
        "location_city": "Port Alfred",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-33.55419922",
        "longitude": "26.87770081",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3608",
                "runway_width": "98",
                "runway_material": "Grass"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "5996",
                "runway_width": "98",
                "runway_material": "Grass"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "3936",
                "runway_width": "98",
                "runway_material": "Grass"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2624",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3509,
            "s_seats": 2511,
            "j_seats": 1786,
            "f_seats": 322,
            "r_seats": 184,
            "cargo_demand": 12142
        }
    },
    {
        "iata": "AFF",
        "icao": "KAFF",
        "airportName": "United States Air Force Academy Airfield",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Colorado Springs",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.96969986",
        "longitude": "-104.8130035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2153",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16C/34C",
                "runway_length": "4500",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "3500",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "4500",
                "runway_width": "40",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3027,
            "s_seats": 2348,
            "j_seats": 1123,
            "f_seats": 415,
            "r_seats": 172,
            "cargo_demand": 74543
        }
    },
    {
        "iata": "AFL",
        "icao": "SBAT",
        "airportName": "Alta Floresta Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Alta Floresta",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-9.866389",
        "longitude": "-56.106298",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "8202",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111676,
            "s_seats": 46998,
            "j_seats": 24202,
            "f_seats": 8231,
            "r_seats": 7848,
            "cargo_demand": 104604
        }
    },
    {
        "iata": "AFN",
        "icao": "KAFN",
        "airportName": "Jaffrey Airport-Silver Ranch",
        "location_country": "United States",
        "location_city": "New Hampshire",
        "location_state": "Jaffrey",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.80509949",
        "longitude": "-72.00299835",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "2982",
                "runway_width": "134",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1988,
            "s_seats": 2129,
            "j_seats": 1080,
            "f_seats": 153,
            "r_seats": 76,
            "cargo_demand": 38031
        }
    },
    {
        "iata": "AFO",
        "icao": "KAFO",
        "airportName": "Afton Municipal Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Afton",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.71120071",
        "longitude": "-110.9420013",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7023",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3979,
            "s_seats": 2617,
            "j_seats": 1956,
            "f_seats": 354,
            "r_seats": 186,
            "cargo_demand": 34087
        }
    },
    {
        "iata": "AFS",
        "icao": "UTSN",
        "airportName": "Zarafshan Airport",
        "location_country": "Uzbekistan",
        "location_city": "Zarafshan",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.61389923",
        "longitude": "64.23320007",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6098",
                "runway_width": "135",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1040,
            "s_seats": 1786,
            "j_seats": 1774,
            "f_seats": 67,
            "r_seats": 138,
            "cargo_demand": 63267
        }
    },
    {
        "iata": "AFT",
        "icao": "AGAF",
        "airportName": "Afutara Airport",
        "location_country": "Solomon Islands",
        "location_city": "Malaita",
        "location_state": "Afutara",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.191388889",
        "longitude": "160.9486111",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "1501",
                "runway_width": "131",
                "runway_material": "Coral grass"
            }
        ],
        "demand": {
            "y_seats": 2605,
            "s_seats": 2893,
            "j_seats": 1457,
            "f_seats": 176,
            "r_seats": 27,
            "cargo_demand": 72309
        }
    },
    {
        "iata": "AFW",
        "icao": "KAFW",
        "airportName": "Fort Worth Alliance Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Fort Worth",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "32.98759842",
        "longitude": "-97.31880188",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "11000",
                "runway_width": "150",
                "runway_material": "CONC-GRVD"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "11010",
                "runway_width": "150",
                "runway_material": "CONC-GRVD"
            }
        ],
        "demand": {
            "y_seats": 462038,
            "s_seats": 88653,
            "j_seats": 132582,
            "f_seats": 27125,
            "r_seats": 10000,
            "cargo_demand": 22173878
        }
    },
    {
        "iata": "AFY",
        "icao": "LTAH",
        "airportName": "Afyon Airport",
        "location_country": "Turkey",
        "location_city": "Afyon",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.726398",
        "longitude": "30.601101",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "12008",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 99653,
            "s_seats": 42019,
            "j_seats": 14407,
            "f_seats": 6489,
            "r_seats": 4307,
            "cargo_demand": 393656
        }
    },
    {
        "iata": "AFZ",
        "icao": "OIMS",
        "airportName": "Sabzevar Airport",
        "location_country": "Iran",
        "location_city": "Sabzevar",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "36.16809845",
        "longitude": "57.59519958",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10428",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135895,
            "s_seats": 49254,
            "j_seats": 43601,
            "f_seats": 3187,
            "r_seats": 5826,
            "cargo_demand": 378051
        }
    },
    {
        "iata": "AGA",
        "icao": "GMAD",
        "airportName": "Agadir-Al Massira Airport",
        "location_country": "Morocco",
        "location_city": "Agadir",
        "location_state": "",
        "time_zone": "UTC+00:00",
        "daylight_saving": "Mar-Oct1",
        "airport_size": "medium_airport",
        "latitude": "30.322478",
        "longitude": "-9.412003",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113415,
            "s_seats": 36204,
            "j_seats": 20747,
            "f_seats": 6147,
            "r_seats": 8482,
            "cargo_demand": 230714
        }
    },
    {
        "iata": "AGB",
        "icao": "EDMA",
        "airportName": "Augsburg Airport",
        "location_country": "Germany",
        "location_city": "Bavaria",
        "location_state": "Augsburg",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.425278",
        "longitude": "10.931667",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5230",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "3325",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 114363,
            "s_seats": 40636,
            "j_seats": 13053,
            "f_seats": 10207,
            "r_seats": 6442,
            "cargo_demand": 348305
        }
    },
    {
        "iata": "AGC",
        "icao": "KAGC",
        "airportName": "Allegheny County Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Pittsburgh",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.35440063",
        "longitude": "-79.93019867",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6501",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3825",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "H1/H1",
                "runway_length": "47",
                "runway_width": "45",
                "runway_material": "CONC-G"
            }
        ],
        "demand": {
            "y_seats": 124689,
            "s_seats": 31796,
            "j_seats": 39109,
            "f_seats": 2019,
            "r_seats": 4009,
            "cargo_demand": 421627
        }
    },
    {
        "iata": "AGE",
        "icao": "EDWG",
        "airportName": "Wangerooge Airfield",
        "location_country": "Germany",
        "location_city": "Lower Saxony",
        "location_state": "Wangerooge",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "53.78277969",
        "longitude": "7.913888931",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2789",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1051,
            "s_seats": 1571,
            "j_seats": 1754,
            "f_seats": 130,
            "r_seats": 41,
            "cargo_demand": 8132
        }
    },
    {
        "iata": "AGF",
        "icao": "LFBA",
        "airportName": "Agen La Garenne Airport",
        "location_country": "France",
        "location_city": "Aquitaine",
        "location_state": "Agen",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "44.17470169",
        "longitude": "0.590556026",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7103",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88088,
            "s_seats": 38041,
            "j_seats": 36111,
            "f_seats": 9854,
            "r_seats": 9278,
            "cargo_demand": 208387
        }
    },
    {
        "iata": "AGH",
        "icao": "ESTA",
        "airportName": "Angelholm-Helsingborg Airport",
        "location_country": "Sweden",
        "location_city": "Angelholm",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "56.29610062",
        "longitude": "12.84710026",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "613",
                "runway_width": "131",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6381",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185175,
            "s_seats": 26661,
            "j_seats": 40186,
            "f_seats": 9696,
            "r_seats": 5755,
            "cargo_demand": 147216
        }
    },
    {
        "iata": "AGJ",
        "icao": "RORA",
        "airportName": "Aguni Airport",
        "location_country": "Japan",
        "location_city": "Okinawa",
        "location_state": "Aguni",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "26.592755",
        "longitude": "127.240335",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2625",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1356,
            "s_seats": 2358,
            "j_seats": 1893,
            "f_seats": 224,
            "r_seats": 82,
            "cargo_demand": 27392
        }
    },
    {
        "iata": "AGO",
        "icao": "KAGO",
        "airportName": "Magnolia Municipal Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Magnolia",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.228001",
        "longitude": "-93.217002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4610",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3065,
            "s_seats": 1955,
            "j_seats": 1256,
            "f_seats": 399,
            "r_seats": 111,
            "cargo_demand": 43741
        }
    },
    {
        "iata": "AGP",
        "icao": "LEMG",
        "airportName": "Malaga Airport",
        "location_country": "Spain",
        "location_city": "Andalusia",
        "location_state": "Malaga",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "36.67490005",
        "longitude": "-4.499110222",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9022",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "10500",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 339517,
            "s_seats": 72359,
            "j_seats": 45716,
            "f_seats": 44919,
            "r_seats": 10000,
            "cargo_demand": 10174875
        }
    },
    {
        "iata": "AGR",
        "icao": "VIAG",
        "airportName": "Agra Airport",
        "location_country": "India",
        "location_city": "Uttar Pradesh",
        "location_state": "Agra",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.157683",
        "longitude": "77.960942",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9000",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5964",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 142288,
            "s_seats": 29964,
            "j_seats": 29208,
            "f_seats": 3898,
            "r_seats": 7436,
            "cargo_demand": 160637
        }
    },
    {
        "iata": "AGS",
        "icao": "KAGS",
        "airportName": "Augusta Regional Airport at Bush Field",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Augusta",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "33.36989975",
        "longitude": "-81.96450043",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6001",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 417791,
            "s_seats": 94002,
            "j_seats": 38992,
            "f_seats": 27021,
            "r_seats": 10000,
            "cargo_demand": 11647416
        }
    },
    {
        "iata": "AGT",
        "icao": "SGES",
        "airportName": "Guarani International Airport",
        "location_country": "Paraguay",
        "location_city": "Ciudad del Este",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Mar",
        "airport_size": "medium_airport",
        "latitude": "-25.454516",
        "longitude": "-54.842682",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11154",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80645,
            "s_seats": 42766,
            "j_seats": 49194,
            "f_seats": 7611,
            "r_seats": 6386,
            "cargo_demand": 192051
        }
    },
    {
        "iata": "AGU",
        "icao": "MMAS",
        "airportName": "International Airport of Aguascalientes",
        "location_country": "Mexico",
        "location_city": "Aguascalientes",
        "location_state": "Aguascalientes",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "21.705601",
        "longitude": "-102.318001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3478",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147705,
            "s_seats": 44410,
            "j_seats": 58354,
            "f_seats": 17674,
            "r_seats": 6609,
            "cargo_demand": 141258
        }
    },
    {
        "iata": "AGV",
        "icao": "SVAC",
        "airportName": "Oswaldo Guevara Mujica Airport",
        "location_country": "Venezuela",
        "location_city": "Acarigua",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.553375",
        "longitude": "-69.237869",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2461",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5990",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136470,
            "s_seats": 26547,
            "j_seats": 22347,
            "f_seats": 6092,
            "r_seats": 9855,
            "cargo_demand": 284686
        }
    },
    {
        "iata": "AGX",
        "icao": "VOAT",
        "airportName": "Agatti Aerodrome",
        "location_country": "India",
        "location_city": "Lakshadweep",
        "location_state": "Agatti Island",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.8237",
        "longitude": "72.176003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4235",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142509,
            "s_seats": 47431,
            "j_seats": 29134,
            "f_seats": 1744,
            "r_seats": 8968,
            "cargo_demand": 163053
        }
    },
    {
        "iata": "AGZ",
        "icao": "FAAG",
        "airportName": "Aggeneys Airport",
        "location_country": "South Africa",
        "location_city": "Aggeneys",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.28179932",
        "longitude": "18.81389999",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6824",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118911,
            "s_seats": 43790,
            "j_seats": 13156,
            "f_seats": 1725,
            "r_seats": 3881,
            "cargo_demand": 69815
        }
    },
    {
        "iata": "AHB",
        "icao": "OEAB",
        "airportName": "Abha Regional Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Abha",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.2404",
        "longitude": "42.656601",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10991",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95517,
            "s_seats": 46129,
            "j_seats": 31014,
            "f_seats": 19775,
            "r_seats": 4776,
            "cargo_demand": 129155
        }
    },
    {
        "iata": "AHC",
        "icao": "KAHC",
        "airportName": "Amedee Army Airfield",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Herlong",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.26620102",
        "longitude": "-120.1529999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7167",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2429,
            "s_seats": 2420,
            "j_seats": 1467,
            "f_seats": 454,
            "r_seats": 55,
            "cargo_demand": 62859
        }
    },
    {
        "iata": "AHE",
        "icao": "NTHE",
        "airportName": "Ahe Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Ahe",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.42809963",
        "longitude": "-146.2570038",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4068",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64687,
            "s_seats": 44983,
            "j_seats": 5767,
            "f_seats": 17832,
            "r_seats": 6732,
            "cargo_demand": 491598
        }
    },
    {
        "iata": "AHH",
        "icao": "KAHH",
        "airportName": "Amery Municipal Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Amery",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.28110123",
        "longitude": "-92.37539673",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2220,
            "s_seats": 2845,
            "j_seats": 1568,
            "f_seats": 487,
            "r_seats": 163,
            "cargo_demand": 25884
        }
    },
    {
        "iata": "AHI",
        "icao": "WAPA",
        "airportName": "Amahai Airport",
        "location_country": "Indonesia",
        "location_city": "Amahai",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.34800005",
        "longitude": "128.9259949",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2460",
                "runway_width": "75",
                "runway_material": "Coral penetration"
            }
        ],
        "demand": {
            "y_seats": 3148,
            "s_seats": 2479,
            "j_seats": 1913,
            "f_seats": 167,
            "r_seats": 70,
            "cargo_demand": 66028
        }
    },
    {
        "iata": "AHN",
        "icao": "KAHN",
        "airportName": "Athens Ben Epps Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Athens",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.94860077",
        "longitude": "-83.32630157",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5522",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 65658,
            "s_seats": 34893,
            "j_seats": 42756,
            "f_seats": 2620,
            "r_seats": 8210,
            "cargo_demand": 492501
        }
    },
    {
        "iata": "AHO",
        "icao": "LIEA",
        "airportName": "Alghero-Fertilia Airport",
        "location_country": "Italy",
        "location_city": "Sardinia",
        "location_state": "Alghero",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "40.632099",
        "longitude": "8.29077",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 93477,
            "s_seats": 43840,
            "j_seats": 35773,
            "f_seats": 18483,
            "r_seats": 6475,
            "cargo_demand": 470752
        }
    },
    {
        "iata": "AHU",
        "icao": "GMTA",
        "airportName": "Cherif Al Idrissi Airport",
        "location_country": "Morocco",
        "location_city": "Al Hoceima",
        "location_state": "",
        "time_zone": "UTC+00:00",
        "daylight_saving": "Mar-Oct1",
        "airport_size": "medium_airport",
        "latitude": "35.17710114",
        "longitude": "-3.839519978",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138984,
            "s_seats": 30465,
            "j_seats": 58225,
            "f_seats": 18667,
            "r_seats": 3527,
            "cargo_demand": 182169
        }
    },
    {
        "iata": "AIA",
        "icao": "KAIA",
        "airportName": "Alliance Municipal Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Alliance",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.05319977",
        "longitude": "-102.8040009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6200",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "9202",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6311",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2873,
            "s_seats": 2466,
            "j_seats": 1702,
            "f_seats": 477,
            "r_seats": 25,
            "cargo_demand": 24032
        }
    },
    {
        "iata": "AID",
        "icao": "KAID",
        "airportName": "Anderson Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Anderson",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.10860062",
        "longitude": "-85.61299896",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5400",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3400",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1481,
            "s_seats": 2490,
            "j_seats": 1335,
            "f_seats": 232,
            "r_seats": 60,
            "cargo_demand": 32321
        }
    },
    {
        "iata": "AIF",
        "icao": "SNAX",
        "airportName": "Assis Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Assis",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-22.64",
        "longitude": "-50.453056",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 3134,
            "s_seats": 1707,
            "j_seats": 1873,
            "f_seats": 313,
            "r_seats": 169,
            "cargo_demand": 24412
        }
    },
    {
        "iata": "AIG",
        "icao": "FEFY",
        "airportName": "Yalinga Airport",
        "location_country": "Central African Republic",
        "location_city": "Yalinga",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "6.519999981",
        "longitude": "23.26000023",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3920",
                "runway_width": "",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2995,
            "s_seats": 1759,
            "j_seats": 1840,
            "f_seats": 295,
            "r_seats": 156,
            "cargo_demand": 37884
        }
    },
    {
        "iata": "AIK",
        "icao": "KAIK",
        "airportName": "Aiken Municipal Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Aiken",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.649399",
        "longitude": "-81.684998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3800",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "07/25",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 2620,
            "s_seats": 2608,
            "j_seats": 1169,
            "f_seats": 421,
            "r_seats": 185,
            "cargo_demand": 97231
        }
    },
    {
        "iata": "AIN",
        "icao": "PAWI",
        "airportName": "Wainwright Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Fort Wainwright",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "70.63800049",
        "longitude": "-159.9949951",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4494",
                "runway_width": "90",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 101168,
            "s_seats": 46998,
            "j_seats": 45870,
            "f_seats": 10821,
            "r_seats": 3972,
            "cargo_demand": 263082
        }
    },
    {
        "iata": "AIO",
        "icao": "KAIO",
        "airportName": "Atlantic Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Atlantic",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.40729904",
        "longitude": "-95.04689789",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "CONC-E"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3911",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "1895",
                "runway_width": "185",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2686,
            "s_seats": 2970,
            "j_seats": 1935,
            "f_seats": 141,
            "r_seats": 107,
            "cargo_demand": 66878
        }
    },
    {
        "iata": "AIP",
        "icao": "VIAX",
        "airportName": "Adampur Airport",
        "location_country": "India",
        "location_city": "Punjab",
        "location_state": "Jalandhar",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.4338",
        "longitude": "75.758797",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9039",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1777,
            "s_seats": 1943,
            "j_seats": 1616,
            "f_seats": 307,
            "r_seats": 142,
            "cargo_demand": 45828
        }
    },
    {
        "iata": "AIR",
        "icao": "SSOU",
        "airportName": "Aripuani Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Aripuani",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-17.036942",
        "longitude": "-46.260277",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3937",
                "runway_width": "131",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1715,
            "s_seats": 2464,
            "j_seats": 1707,
            "f_seats": 73,
            "r_seats": 3,
            "cargo_demand": 62963
        }
    },
    {
        "iata": "AIT",
        "icao": "NCAI",
        "airportName": "Aitutaki Airport",
        "location_country": "Cook Islands",
        "location_city": "Aitutaki",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.83090019",
        "longitude": "-159.7640076",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4555",
                "runway_width": "180",
                "runway_material": "COR"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5920",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1034,
            "s_seats": 2448,
            "j_seats": 1102,
            "f_seats": 73,
            "r_seats": 197,
            "cargo_demand": 86389
        }
    },
    {
        "iata": "AIU",
        "icao": "NCAT",
        "airportName": "Enua Airport",
        "location_country": "Cook Islands",
        "location_city": "Atiu Island",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-19.96780014",
        "longitude": "-158.1190033",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3947",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3803,
            "s_seats": 2825,
            "j_seats": 1980,
            "f_seats": 386,
            "r_seats": 68,
            "cargo_demand": 74769
        }
    },
    {
        "iata": "AIV",
        "icao": "KAIV",
        "airportName": "George Downer Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Aliceville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.106499",
        "longitude": "-88.1978",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4970",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2627,
            "s_seats": 2115,
            "j_seats": 1697,
            "f_seats": 143,
            "r_seats": 71,
            "cargo_demand": 17825
        }
    },
    {
        "iata": "AIZ",
        "icao": "KAIZ",
        "airportName": "Lee C. Fine Memorial Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Lake Ozark",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.09600067",
        "longitude": "-92.54949951",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6497",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1245,
            "s_seats": 2141,
            "j_seats": 1892,
            "f_seats": 346,
            "r_seats": 142,
            "cargo_demand": 53440
        }
    },
    {
        "iata": "AJA",
        "icao": "LFKJ",
        "airportName": "Ajaccio Napoleon Bonaparte Airport",
        "location_country": "France",
        "location_city": "Corsica",
        "location_state": "Ajaccio",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "41.92359924",
        "longitude": "8.802920341",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7897",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109071,
            "s_seats": 41450,
            "j_seats": 37249,
            "f_seats": 11755,
            "r_seats": 7177,
            "cargo_demand": 486284
        }
    },
    {
        "iata": "AJF",
        "icao": "OESK",
        "airportName": "Al-Jawf Domestic Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Al Jawf",
        "location_state": "Sakakah",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.78510094",
        "longitude": "40.09999847",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "12011",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62504,
            "s_seats": 30999,
            "j_seats": 8227,
            "f_seats": 1960,
            "r_seats": 5670,
            "cargo_demand": 141187
        }
    },
    {
        "iata": "AJI",
        "icao": "LTCO",
        "airportName": "Agri Airport",
        "location_country": "Turkey",
        "location_city": "Agri",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.655642",
        "longitude": "43.025742",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87730,
            "s_seats": 42202,
            "j_seats": 25272,
            "f_seats": 13525,
            "r_seats": 4420,
            "cargo_demand": 57647
        }
    },
    {
        "iata": "AJK",
        "icao": "OIHR",
        "airportName": "Arak Airport",
        "location_country": "Iran",
        "location_city": "Arak",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "small_airport",
        "latitude": "34.13809967",
        "longitude": "49.84730148",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9811",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2787,
            "s_seats": 2716,
            "j_seats": 1498,
            "f_seats": 262,
            "r_seats": 34,
            "cargo_demand": 81536
        }
    },
    {
        "iata": "AJL",
        "icao": "VELP",
        "airportName": "Lengpui Airport",
        "location_country": "India",
        "location_city": "Mizoram",
        "location_state": "Aizawl",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.840599",
        "longitude": "92.619698",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139929,
            "s_seats": 49894,
            "j_seats": 14743,
            "f_seats": 11560,
            "r_seats": 6129,
            "cargo_demand": 312784
        }
    },
    {
        "iata": "AJN",
        "icao": "FMCV",
        "airportName": "Ouani Airport",
        "location_country": "Comoros",
        "location_city": "Anjouan",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.13169956",
        "longitude": "44.43030167",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4429",
                "runway_width": "92",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129681,
            "s_seats": 43884,
            "j_seats": 29858,
            "f_seats": 14606,
            "r_seats": 6242,
            "cargo_demand": 311843
        }
    },
    {
        "iata": "AJR",
        "icao": "ESNX",
        "airportName": "Arvidsjaur Airport",
        "location_country": "Sweden",
        "location_city": "Arvidsjaur",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "65.59030151",
        "longitude": "19.28190041",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8201",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69164,
            "s_seats": 45177,
            "j_seats": 49322,
            "f_seats": 16685,
            "r_seats": 9503,
            "cargo_demand": 391599
        }
    },
    {
        "iata": "AJU",
        "icao": "SBAR",
        "airportName": "Santa Maria Airport",
        "location_country": "Brazil",
        "location_city": "Sergipe",
        "location_state": "Aracaju",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-10.984",
        "longitude": "-37.070301",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61089,
            "s_seats": 40033,
            "j_seats": 34596,
            "f_seats": 10131,
            "r_seats": 5475,
            "cargo_demand": 116881
        }
    },
    {
        "iata": "AJY",
        "icao": "DRZA",
        "airportName": "Mano Dayak International Airport",
        "location_country": "Niger",
        "location_city": "Agadez",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.9659996",
        "longitude": "8.000109673",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9843",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79661,
            "s_seats": 32187,
            "j_seats": 14081,
            "f_seats": 7209,
            "r_seats": 8688,
            "cargo_demand": 164800
        }
    },
    {
        "iata": "AKA",
        "icao": "ZLAK",
        "airportName": "Ankang Wulipu Airport",
        "location_country": "China",
        "location_city": "Shaanxi",
        "location_state": "Ankang",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.75696",
        "longitude": "108.87338",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5249",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3934,
            "s_seats": 2989,
            "j_seats": 1868,
            "f_seats": 11,
            "r_seats": 94,
            "cargo_demand": 60169
        }
    },
    {
        "iata": "AKB",
        "icao": "PAAK",
        "airportName": "Atka Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Atka",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "52.22029877",
        "longitude": "-174.2059937",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASPH-E"
            }
        ],
        "demand": {
            "y_seats": 2792,
            "s_seats": 2055,
            "j_seats": 1803,
            "f_seats": 322,
            "r_seats": 72,
            "cargo_demand": 88485
        }
    },
    {
        "iata": "AKC",
        "icao": "KAKR",
        "airportName": "Akron Fulton International Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Akron",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.037498",
        "longitude": "-81.466904",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2336",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "6337",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109774,
            "s_seats": 30841,
            "j_seats": 35235,
            "f_seats": 17124,
            "r_seats": 8846,
            "cargo_demand": 162858
        }
    },
    {
        "iata": "AKD",
        "icao": "VAAK",
        "airportName": "Akola Airport",
        "location_country": "India",
        "location_city": "Maharashtra",
        "location_state": "Akola",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.698931",
        "longitude": "77.056883",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4600",
                "runway_width": "145",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 167918,
            "s_seats": 32345,
            "j_seats": 54771,
            "f_seats": 8546,
            "r_seats": 3177,
            "cargo_demand": 298853
        }
    },
    {
        "iata": "AKF",
        "icao": "HLKF",
        "airportName": "Kufra Airport",
        "location_country": "Libya",
        "location_city": "Kufra",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.17869949",
        "longitude": "23.31399918",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02L/20R",
                "runway_length": "12007",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "12007",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166669,
            "s_seats": 45804,
            "j_seats": 17094,
            "f_seats": 11753,
            "r_seats": 6165,
            "cargo_demand": 498341
        }
    },
    {
        "iata": "AKH",
        "icao": "OEPS",
        "airportName": "Prince Sultan Air Base",
        "location_country": "Saudi Arabia",
        "location_city": "Al-Kharj",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.06270027",
        "longitude": "47.58050156",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "13143",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69088,
            "s_seats": 35051,
            "j_seats": 30352,
            "f_seats": 16848,
            "r_seats": 7180,
            "cargo_demand": 481401
        }
    },
    {
        "iata": "AKI",
        "icao": "PFAK",
        "airportName": "Akiak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Akiak",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "60.9029007",
        "longitude": "-161.2310028",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3196",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 1313,
            "s_seats": 1803,
            "j_seats": 1886,
            "f_seats": 184,
            "r_seats": 180,
            "cargo_demand": 51598
        }
    },
    {
        "iata": "AKJ",
        "icao": "RJEC",
        "airportName": "Asahikawa Airport",
        "location_country": "Japan",
        "location_city": "Hokkaido",
        "location_state": "Asahikawa",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.670799",
        "longitude": "142.447006",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8200",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142130,
            "s_seats": 46123,
            "j_seats": 17849,
            "f_seats": 16553,
            "r_seats": 3470,
            "cargo_demand": 359417
        }
    },
    {
        "iata": "AKK",
        "icao": "PAKH",
        "airportName": "Akhiok Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Akhiok",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "56.93870163",
        "longitude": "-154.1829987",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3120",
                "runway_width": "50",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 3414,
            "s_seats": 2914,
            "j_seats": 1704,
            "f_seats": 472,
            "r_seats": 46,
            "cargo_demand": 97491
        }
    },
    {
        "iata": "AKL",
        "icao": "NZAA",
        "airportName": "Auckland Airport",
        "location_country": "New Zealand",
        "location_city": "Auckland",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "large_airport",
        "latitude": "-37.008099",
        "longitude": "174.792007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "10197",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "11926",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 103285,
            "s_seats": 85987,
            "j_seats": 61679,
            "f_seats": 53170,
            "r_seats": 10000,
            "cargo_demand": 20089996
        }
    },
    {
        "iata": "AKN",
        "icao": "PAKN",
        "airportName": "King Salmon Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "King Salmon",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "58.677845",
        "longitude": "-156.651965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8901",
                "runway_width": "150",
                "runway_material": "ASPH-F"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4018",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "NW/SE",
                "runway_length": "4000",
                "runway_width": "500",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 103504,
            "s_seats": 35387,
            "j_seats": 27329,
            "f_seats": 19366,
            "r_seats": 7903,
            "cargo_demand": 218423
        }
    },
    {
        "iata": "AKO",
        "icao": "KAKO",
        "airportName": "Colorado Plains Regional Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Akron",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.17559814",
        "longitude": "-103.2220001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1765,
            "s_seats": 1747,
            "j_seats": 1833,
            "f_seats": 387,
            "r_seats": 80,
            "cargo_demand": 71287
        }
    },
    {
        "iata": "AKP",
        "icao": "PAKP",
        "airportName": "Anaktuvuk Pass Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Anaktuvuk Pass",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "68.13359833",
        "longitude": "-151.7429962",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4800",
                "runway_width": "100",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 117879,
            "s_seats": 37255,
            "j_seats": 16730,
            "f_seats": 16561,
            "r_seats": 8762,
            "cargo_demand": 206772
        }
    },
    {
        "iata": "AKR",
        "icao": "DNAK",
        "airportName": "Akure Airport",
        "location_country": "Nigeria",
        "location_city": "Akure",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.246739864",
        "longitude": "5.301010132",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9195",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128040,
            "s_seats": 26207,
            "j_seats": 10274,
            "f_seats": 18293,
            "r_seats": 8063,
            "cargo_demand": 440188
        }
    },
    {
        "iata": "AKS",
        "icao": "AGGA",
        "airportName": "Auki Gwaunaru'u Airport",
        "location_country": "Solomon Islands",
        "location_city": "Malaita",
        "location_state": "Auki",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.70257",
        "longitude": "160.682007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3100",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1601,
            "s_seats": 2190,
            "j_seats": 1095,
            "f_seats": 356,
            "r_seats": 70,
            "cargo_demand": 82586
        }
    },
    {
        "iata": "AKT",
        "icao": "LCRA",
        "airportName": "RAF Akrotiri",
        "location_country": "United Kingdom",
        "location_city": "Akrotiri",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "34.590401",
        "longitude": "32.9879",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9002",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121520,
            "s_seats": 86501,
            "j_seats": 116090,
            "f_seats": 30863,
            "r_seats": 10000,
            "cargo_demand": 26512242
        }
    },
    {
        "iata": "AKV",
        "icao": "CYKO",
        "airportName": "Akulivik Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Akulivik",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "60.8185997",
        "longitude": "-78.14859772",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3510",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 143411,
            "s_seats": 45969,
            "j_seats": 10363,
            "f_seats": 9933,
            "r_seats": 9855,
            "cargo_demand": 418085
        }
    },
    {
        "iata": "AKW",
        "icao": "OIAG",
        "airportName": "Aghajari Airport",
        "location_country": "Iran",
        "location_city": "Aghajari",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "30.7444",
        "longitude": "49.6772",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6933",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134028,
            "s_seats": 28689,
            "j_seats": 21443,
            "f_seats": 14713,
            "r_seats": 7412,
            "cargo_demand": 96082
        }
    },
    {
        "iata": "AKX",
        "icao": "UATT",
        "airportName": "Aktobe Airport",
        "location_country": "Kazakhstan",
        "location_city": "Aktobe",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.2458",
        "longitude": "57.206699",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10160",
                "runway_width": "196",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 151369,
            "s_seats": 33732,
            "j_seats": 45763,
            "f_seats": 17256,
            "r_seats": 6964,
            "cargo_demand": 72288
        }
    },
    {
        "iata": "AKY",
        "icao": "VYSW",
        "airportName": "Sittwe Airport",
        "location_country": "Myanmar",
        "location_city": "Sittwe",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.13269997",
        "longitude": "92.87259674",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6001",
                "runway_width": "151",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 62669,
            "s_seats": 29289,
            "j_seats": 14711,
            "f_seats": 13120,
            "r_seats": 5008,
            "cargo_demand": 228267
        }
    },
    {
        "iata": "ALA",
        "icao": "UAAA",
        "airportName": "Almaty International Airport",
        "location_country": "Kazakhstan",
        "location_city": "Almaty",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.35210037",
        "longitude": "77.04049683",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "14764",
                "runway_width": "148",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "14436",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 340843,
            "s_seats": 79963,
            "j_seats": 32255,
            "f_seats": 22594,
            "r_seats": 10000,
            "cargo_demand": 24738036
        }
    },
    {
        "iata": "ALB",
        "icao": "KALB",
        "airportName": "Albany International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Albany",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.74829865",
        "longitude": "-73.80169678",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "7200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58750,
            "s_seats": 45670,
            "j_seats": 51112,
            "f_seats": 16105,
            "r_seats": 3826,
            "cargo_demand": 480896
        }
    },
    {
        "iata": "ALC",
        "icao": "LEAL",
        "airportName": "Alicante-Elche Airport",
        "location_country": "Spain",
        "location_city": "Valencia",
        "location_state": "Alicante",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "38.28219986",
        "longitude": "-0.558156013",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 492589,
            "s_seats": 78356,
            "j_seats": 56566,
            "f_seats": 24812,
            "r_seats": 10000,
            "cargo_demand": 27884602
        }
    },
    {
        "iata": "ALF",
        "icao": "ENAT",
        "airportName": "Alta Airport",
        "location_country": "Norway",
        "location_city": "Alta",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "69.97609711",
        "longitude": "23.37170029",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7165",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125639,
            "s_seats": 46868,
            "j_seats": 22697,
            "f_seats": 19908,
            "r_seats": 7255,
            "cargo_demand": 177785
        }
    },
    {
        "iata": "ALG",
        "icao": "DAAG",
        "airportName": "Houari Boumediene Airport",
        "location_country": "Algeria",
        "location_city": "Algiers",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.691002",
        "longitude": "3.21541",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11483",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180308,
            "s_seats": 80629,
            "j_seats": 61784,
            "f_seats": 60364,
            "r_seats": 10000,
            "cargo_demand": 7899958
        }
    },
    {
        "iata": "ALH",
        "icao": "YABA",
        "airportName": "Albany Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Albany",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.94329834",
        "longitude": "117.8089981",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3596",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52146,
            "s_seats": 31764,
            "j_seats": 34751,
            "f_seats": 2834,
            "r_seats": 5181,
            "cargo_demand": 124797
        }
    },
    {
        "iata": "ALI",
        "icao": "KALI",
        "airportName": "Alice International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Alice",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "27.74090004",
        "longitude": "-98.02690125",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4171",
                "runway_width": "130",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5997",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4490",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117665,
            "s_seats": 46976,
            "j_seats": 38737,
            "f_seats": 7510,
            "r_seats": 3593,
            "cargo_demand": 316148
        }
    },
    {
        "iata": "ALJ",
        "icao": "FAAB",
        "airportName": "Alexander Bay Airport",
        "location_country": "South Africa",
        "location_city": "Alexander Bay",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.575001",
        "longitude": "16.5333",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6063",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "5499",
                "runway_width": "118",
                "runway_material": "GVL"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4823",
                "runway_width": "115",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 158171,
            "s_seats": 45472,
            "j_seats": 37785,
            "f_seats": 18540,
            "r_seats": 4102,
            "cargo_demand": 196791
        }
    },
    {
        "iata": "ALL",
        "icao": "LIMG",
        "airportName": "Albenga Airport",
        "location_country": "Italy",
        "location_city": "Liguria",
        "location_state": "Albenga",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "44.050598",
        "longitude": "8.12743",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4688",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82332,
            "s_seats": 44209,
            "j_seats": 14190,
            "f_seats": 6607,
            "r_seats": 3750,
            "cargo_demand": 449668
        }
    },
    {
        "iata": "ALM",
        "icao": "KALM",
        "airportName": "Alamogordo-White Sands Regional Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Alamogordo",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.83990097",
        "longitude": "-105.9909973",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7006",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3512",
                "runway_width": "200",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 140127,
            "s_seats": 33703,
            "j_seats": 59492,
            "f_seats": 18861,
            "r_seats": 6028,
            "cargo_demand": 423583
        }
    },
    {
        "iata": "ALN",
        "icao": "KALN",
        "airportName": "St. Louis Regional Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Alton",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.89030075",
        "longitude": "-90.04599762",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8101",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190483,
            "s_seats": 44751,
            "j_seats": 46833,
            "f_seats": 6222,
            "r_seats": 4307,
            "cargo_demand": 363261
        }
    },
    {
        "iata": "ALO",
        "icao": "KALO",
        "airportName": "Waterloo Regional Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Waterloo",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.55709839",
        "longitude": "-92.40029907",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5403",
                "runway_width": "129",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8400",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6002",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 126457,
            "s_seats": 45217,
            "j_seats": 37866,
            "f_seats": 9773,
            "r_seats": 9002,
            "cargo_demand": 230122
        }
    },
    {
        "iata": "ALP",
        "icao": "OSAP",
        "airportName": "Aleppo International Airport",
        "location_country": "Syria",
        "location_city": "Aleppo",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "36.18069839",
        "longitude": "37.22439957",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9547",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 310444,
            "s_seats": 75117,
            "j_seats": 131292,
            "f_seats": 39922,
            "r_seats": 10000,
            "cargo_demand": 27694560
        }
    },
    {
        "iata": "ALQ",
        "icao": "SSLT",
        "airportName": "Alegrete Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Alegrete",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-29.81270027",
        "longitude": "-55.89339828",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3937",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1005,
            "s_seats": 1518,
            "j_seats": 1358,
            "f_seats": 22,
            "r_seats": 72,
            "cargo_demand": 16409
        }
    },
    {
        "iata": "ALR",
        "icao": "NZLX",
        "airportName": "Alexandra Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Alexandra",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-45.21170044",
        "longitude": "169.3730011",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2139",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "3937",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155832,
            "s_seats": 32719,
            "j_seats": 56028,
            "f_seats": 10362,
            "r_seats": 5430,
            "cargo_demand": 156259
        }
    },
    {
        "iata": "ALS",
        "icao": "KALS",
        "airportName": "San Luis Valley Regional Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Alamosa",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.434898",
        "longitude": "-105.866997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8519",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "3200",
                "runway_width": "100",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 158370,
            "s_seats": 33657,
            "j_seats": 34488,
            "f_seats": 9557,
            "r_seats": 7898,
            "cargo_demand": 336554
        }
    },
    {
        "iata": "ALW",
        "icao": "KALW",
        "airportName": "Walla Walla Regional Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Walla Walla",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.09489822",
        "longitude": "-118.288002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6526",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "4486",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5948",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 153279,
            "s_seats": 33933,
            "j_seats": 21013,
            "f_seats": 8252,
            "r_seats": 7808,
            "cargo_demand": 356667
        }
    },
    {
        "iata": "ALX",
        "icao": "KALX",
        "airportName": "Thomas C. Russell Field",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Alexander City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.91469955",
        "longitude": "-85.96299744",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5425",
                "runway_width": "96",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1252,
            "s_seats": 1802,
            "j_seats": 1778,
            "f_seats": 393,
            "r_seats": 43,
            "cargo_demand": 43583
        }
    },
    {
        "iata": "ALY",
        "icao": "HEAX",
        "airportName": "El Nouzha Airport",
        "location_country": "Egypt",
        "location_city": "Alexandria",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.192333",
        "longitude": "29.953081",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7221",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 147584,
            "s_seats": 29453,
            "j_seats": 13107,
            "f_seats": 7536,
            "r_seats": 4524,
            "cargo_demand": 321618
        }
    },
    {
        "iata": "AMA",
        "icao": "KAMA",
        "airportName": "Rick Husband Amarillo International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Amarillo",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "35.219398",
        "longitude": "-101.706001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "13502",
                "runway_width": "300",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7901",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 322662,
            "s_seats": 83352,
            "j_seats": 97212,
            "f_seats": 40424,
            "r_seats": 10000,
            "cargo_demand": 5167250
        }
    },
    {
        "iata": "AMB",
        "icao": "FMNE",
        "airportName": "Ambilobe Airport",
        "location_country": "Madagascar",
        "location_city": "Ambilobe",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.18840027",
        "longitude": "48.98799896",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2387,
            "s_seats": 2867,
            "j_seats": 1028,
            "f_seats": 396,
            "r_seats": 15,
            "cargo_demand": 5912
        }
    },
    {
        "iata": "AMD",
        "icao": "VAAH",
        "airportName": "Sardar Vallabhbhai Patel International Airport",
        "location_country": "India",
        "location_city": "Gujarat",
        "location_state": "Ahmedabad",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.07719994",
        "longitude": "72.63469696",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11447",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 56719,
            "s_seats": 34444,
            "j_seats": 39829,
            "f_seats": 11982,
            "r_seats": 5485,
            "cargo_demand": 161497
        }
    },
    {
        "iata": "AMH",
        "icao": "HAAM",
        "airportName": "Arba Minch Airport",
        "location_country": "Ethiopia",
        "location_city": "Arba Minch",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.039390087",
        "longitude": "37.59049988",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9170",
                "runway_width": "153",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82082,
            "s_seats": 41155,
            "j_seats": 31557,
            "f_seats": 11067,
            "r_seats": 3395,
            "cargo_demand": 398524
        }
    },
    {
        "iata": "AMJ",
        "icao": "SNAR",
        "airportName": "Almenara Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Almenara",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-16.18389",
        "longitude": "-40.66722",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3787,
            "s_seats": 1632,
            "j_seats": 1510,
            "f_seats": 394,
            "r_seats": 12,
            "cargo_demand": 78410
        }
    },
    {
        "iata": "AMM",
        "icao": "OJAI",
        "airportName": "Queen Alia International Airport",
        "location_country": "Jordan",
        "location_city": "Amman",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "31.72260094",
        "longitude": "35.99319839",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "12008",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "12008",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 207002,
            "s_seats": 89085,
            "j_seats": 159990,
            "f_seats": 56478,
            "r_seats": 10000,
            "cargo_demand": 2612909
        }
    },
    {
        "iata": "AMN",
        "icao": "KAMN",
        "airportName": "Gratiot Community Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Alma",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.32210159",
        "longitude": "-84.68800354",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3200",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2523,
            "s_seats": 2488,
            "j_seats": 1293,
            "f_seats": 328,
            "r_seats": 21,
            "cargo_demand": 70868
        }
    },
    {
        "iata": "AMQ",
        "icao": "WAPP",
        "airportName": "Pattimura Airport",
        "location_country": "Indonesia",
        "location_city": "Ambon",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "\" Ambon\"\"\"",
        "longitude": "-3.710259914",
        "region": "33",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141461,
            "s_seats": 35955,
            "j_seats": 33723,
            "f_seats": 4579,
            "r_seats": 5762,
            "cargo_demand": 406634
        }
    },
    {
        "iata": "AMS",
        "icao": "EHAM",
        "airportName": "Amsterdam Airport Schiphol",
        "location_country": "Netherlands",
        "location_city": "Amsterdam",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "52.308601",
        "longitude": "4.76389",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6627",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "11283",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "11329",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18C/36C",
                "runway_length": "10826",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "12467",
                "runway_width": "198",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 248803,
            "s_seats": 82701,
            "j_seats": 34593,
            "f_seats": 43734,
            "r_seats": 10000,
            "cargo_demand": 8717313
        }
    },
    {
        "iata": "AMW",
        "icao": "KAMW",
        "airportName": "Ames Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Ames",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.992001",
        "longitude": "-93.621803",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5700",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3492",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2823,
            "s_seats": 2673,
            "j_seats": 1446,
            "f_seats": 418,
            "r_seats": 42,
            "cargo_demand": 87183
        }
    },
    {
        "iata": "AMZ",
        "icao": "NZAR",
        "airportName": "Ardmore Airport",
        "location_country": "New Zealand",
        "location_city": "Ardmore",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-37.02970123",
        "longitude": "174.9730072",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4630",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03L/21R",
                "runway_length": "1700",
                "runway_width": "59",
                "runway_material": "GRS"
            },
            {
                "runway_name": "07/25",
                "runway_length": "1960",
                "runway_width": "59",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 73605,
            "s_seats": 34538,
            "j_seats": 51938,
            "f_seats": 3298,
            "r_seats": 8882,
            "cargo_demand": 199744
        }
    },
    {
        "iata": "ANB",
        "icao": "KANB",
        "airportName": "Anniston Regional Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Anniston",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.5882",
        "longitude": "-85.8581",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162430,
            "s_seats": 45794,
            "j_seats": 29794,
            "f_seats": 10641,
            "r_seats": 4204,
            "cargo_demand": 368657
        }
    },
    {
        "iata": "ANC",
        "icao": "PANC",
        "airportName": "Ted Stevens Anchorage International Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Anchorage",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "61.1744",
        "longitude": "-149.996002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "10600",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "10900",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "11584",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 431412,
            "s_seats": 86841,
            "j_seats": 163592,
            "f_seats": 23806,
            "r_seats": 10000,
            "cargo_demand": 29361521
        }
    },
    {
        "iata": "AND",
        "icao": "KAND",
        "airportName": "Anderson Regional Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Anderson",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.49459839",
        "longitude": "-82.70939636",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4995",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194750,
            "s_seats": 29054,
            "j_seats": 7281,
            "f_seats": 12341,
            "r_seats": 6559,
            "cargo_demand": 420373
        }
    },
    {
        "iata": "ANE",
        "icao": "LFJR",
        "airportName": "Angers - Loire Airport",
        "location_country": "France",
        "location_city": "Pays de la Loire",
        "location_state": "Angers",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.560299",
        "longitude": "-0.312222",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5906",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141838,
            "s_seats": 49650,
            "j_seats": 48356,
            "f_seats": 1330,
            "r_seats": 5145,
            "cargo_demand": 150386
        }
    },
    {
        "iata": "ANF",
        "icao": "SCFA",
        "airportName": "Cerro Moreno International Airport",
        "location_country": "Chile",
        "location_city": "Antofagasta",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-23.444501",
        "longitude": "-70.445099",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8527",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147539,
            "s_seats": 37781,
            "j_seats": 17534,
            "f_seats": 2469,
            "r_seats": 7737,
            "cargo_demand": 251139
        }
    },
    {
        "iata": "ANG",
        "icao": "LFBU",
        "airportName": "Angouleme - Cognac International Airport",
        "location_country": "France",
        "location_city": "Poitou-Charentes",
        "location_state": "Angouleme",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.729198",
        "longitude": "0.221456",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5938",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157290,
            "s_seats": 46456,
            "j_seats": 45743,
            "f_seats": 13021,
            "r_seats": 9499,
            "cargo_demand": 305965
        }
    },
    {
        "iata": "ANI",
        "icao": "PANI",
        "airportName": "Aniak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Aniak",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "61.58160019",
        "longitude": "-159.5429993",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05W/23W",
                "runway_length": "3000",
                "runway_width": "400",
                "runway_material": "WATER"
            },
            {
                "runway_name": "10/28",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASPH-F"
            }
        ],
        "demand": {
            "y_seats": 191613,
            "s_seats": 35257,
            "j_seats": 52478,
            "f_seats": 7139,
            "r_seats": 3655,
            "cargo_demand": 352651
        }
    },
    {
        "iata": "ANK",
        "icao": "LTAD",
        "airportName": "Etimesgut Air Base",
        "location_country": "Turkey",
        "location_city": "Ankara",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.94979858",
        "longitude": "32.68859863",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11L/29R",
                "runway_length": "9045",
                "runway_width": "138",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "4951",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190560,
            "s_seats": 46757,
            "j_seats": 37118,
            "f_seats": 18700,
            "r_seats": 8807,
            "cargo_demand": 416895
        }
    },
    {
        "iata": "ANM",
        "icao": "FMNH",
        "airportName": "Antsirabato Airport",
        "location_country": "Madagascar",
        "location_city": "Antalaha",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.9994",
        "longitude": "50.320202",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3914",
                "runway_width": "89",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148009,
            "s_seats": 36481,
            "j_seats": 42251,
            "f_seats": 14755,
            "r_seats": 4575,
            "cargo_demand": 414837
        }
    },
    {
        "iata": "ANN",
        "icao": "PANT",
        "airportName": "Annette Island Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Annette Island",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.037026",
        "longitude": "-131.572695",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5709",
                "runway_width": "150",
                "runway_material": "GVL"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7493",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190592,
            "s_seats": 37586,
            "j_seats": 34180,
            "f_seats": 1680,
            "r_seats": 3173,
            "cargo_demand": 457135
        }
    },
    {
        "iata": "ANP",
        "icao": "KANP",
        "airportName": "Lee Airport",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Annapolis",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.942902",
        "longitude": "-76.568398",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2500",
                "runway_width": "48",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3966,
            "s_seats": 2792,
            "j_seats": 1213,
            "f_seats": 95,
            "r_seats": 142,
            "cargo_demand": 85791
        }
    },
    {
        "iata": "ANQ",
        "icao": "KANQ",
        "airportName": "Tri-State Steuben County Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Angola",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.639702",
        "longitude": "-85.083504",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4540",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1072,
            "s_seats": 2492,
            "j_seats": 1808,
            "f_seats": 167,
            "r_seats": 60,
            "cargo_demand": 91907
        }
    },
    {
        "iata": "ANR",
        "icao": "EBAW",
        "airportName": "Antwerp International Airport",
        "location_country": "Belgium",
        "location_city": "Antwerp",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.1894",
        "longitude": "4.46028",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4954",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61716,
            "s_seats": 25129,
            "j_seats": 8869,
            "f_seats": 2456,
            "r_seats": 4127,
            "cargo_demand": 215944
        }
    },
    {
        "iata": "ANS",
        "icao": "SPHY",
        "airportName": "Andahuaylas Airport",
        "location_country": "Peru",
        "location_city": "Andahuaylas",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.70639992",
        "longitude": "-73.35040283",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8202",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1285,
            "s_seats": 1902,
            "j_seats": 1624,
            "f_seats": 355,
            "r_seats": 97,
            "cargo_demand": 46056
        }
    },
    {
        "iata": "ANU",
        "icao": "TAPA",
        "airportName": "V. C. Bird International Airport",
        "location_country": "Antigua and Barbuda",
        "location_city": "Antigua",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.1367",
        "longitude": "-61.792702",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9003",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100305,
            "s_seats": 45953,
            "j_seats": 5608,
            "f_seats": 6538,
            "r_seats": 9031,
            "cargo_demand": 448936
        }
    },
    {
        "iata": "ANV",
        "icao": "PANV",
        "airportName": "Anvik Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Anvik",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "62.646702",
        "longitude": "-160.190994",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "2960",
                "runway_width": "75",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 135242,
            "s_seats": 32491,
            "j_seats": 15426,
            "f_seats": 6359,
            "r_seats": 8948,
            "cargo_demand": 416640
        }
    },
    {
        "iata": "ANW",
        "icao": "KANW",
        "airportName": "Ainsworth Regional Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Ainsworth",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.579201",
        "longitude": "-99.992995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5501",
                "runway_width": "75",
                "runway_material": "ASPH-F"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6824",
                "runway_width": "110",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2954,
            "s_seats": 2665,
            "j_seats": 1634,
            "f_seats": 127,
            "r_seats": 94,
            "cargo_demand": 65559
        }
    },
    {
        "iata": "ANX",
        "icao": "ENAN",
        "airportName": "Andoya Airport",
        "location_country": "Norway",
        "location_city": "Andenes",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "69.29250336",
        "longitude": "16.14419937",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5486",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8097",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151190,
            "s_seats": 49297,
            "j_seats": 7911,
            "f_seats": 4593,
            "r_seats": 8913,
            "cargo_demand": 153903
        }
    },
    {
        "iata": "ANY",
        "icao": "KANY",
        "airportName": "Anthony Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Anthony",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.158501",
        "longitude": "-98.079597",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2200",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3598",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3423,
            "s_seats": 2567,
            "j_seats": 1170,
            "f_seats": 295,
            "r_seats": 93,
            "cargo_demand": 29870
        }
    },
    {
        "iata": "AOC",
        "icao": "EDAC",
        "airportName": "Leipzig-Altenburg Airport",
        "location_country": "Germany",
        "location_city": "Thuringia",
        "location_state": "Altenburg",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.981945",
        "longitude": "12.506389",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7333",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 61018,
            "s_seats": 46212,
            "j_seats": 48549,
            "f_seats": 8104,
            "r_seats": 6499,
            "cargo_demand": 499911
        }
    },
    {
        "iata": "AOE",
        "icao": "LTBY",
        "airportName": "Anadolu Airport",
        "location_country": "Turkey",
        "location_city": "Eskisehir",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.809898",
        "longitude": "30.5194",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8261",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62521,
            "s_seats": 39983,
            "j_seats": 48925,
            "f_seats": 3280,
            "r_seats": 4299,
            "cargo_demand": 188172
        }
    },
    {
        "iata": "AOH",
        "icao": "KAOH",
        "airportName": "Lima Allen County Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Lima",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.706902",
        "longitude": "-84.026703",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5149",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3994",
                "runway_width": "150",
                "runway_material": "COM"
            }
        ],
        "demand": {
            "y_seats": 1029,
            "s_seats": 2157,
            "j_seats": 1507,
            "f_seats": 363,
            "r_seats": 7,
            "cargo_demand": 4522
        }
    },
    {
        "iata": "AOI",
        "icao": "LIPY",
        "airportName": "Ancona Falconara Airport",
        "location_country": "Italy",
        "location_city": "Marche",
        "location_state": "Ancona",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.616299",
        "longitude": "13.3623",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9718",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81426,
            "s_seats": 45096,
            "j_seats": 59882,
            "f_seats": 10640,
            "r_seats": 6817,
            "cargo_demand": 213844
        }
    },
    {
        "iata": "AOJ",
        "icao": "RJSA",
        "airportName": "Aomori Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Aomori",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.73469925",
        "longitude": "140.6909943",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9846",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144486,
            "s_seats": 27947,
            "j_seats": 59332,
            "f_seats": 11050,
            "r_seats": 6535,
            "cargo_demand": 87394
        }
    },
    {
        "iata": "AOK",
        "icao": "LGKP",
        "airportName": "Karpathos Island National Airport",
        "location_country": "Greece",
        "location_city": "Karpathos",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "35.42139816",
        "longitude": "27.14599991",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7871",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154019,
            "s_seats": 42112,
            "j_seats": 45303,
            "f_seats": 8574,
            "r_seats": 4164,
            "cargo_demand": 242380
        }
    },
    {
        "iata": "AOL",
        "icao": "SARL",
        "airportName": "Paso de los Libres Airport",
        "location_country": "Argentina",
        "location_city": "Corrientes",
        "location_state": "Paso de los Libres",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.6894",
        "longitude": "-57.1521",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7415",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70857,
            "s_seats": 49142,
            "j_seats": 16085,
            "f_seats": 15864,
            "r_seats": 4400,
            "cargo_demand": 152934
        }
    },
    {
        "iata": "AOO",
        "icao": "KAOO",
        "airportName": "Altoona-Blair County Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Altoona",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.29639816",
        "longitude": "-78.31999969",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5466",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3668",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128441,
            "s_seats": 25857,
            "j_seats": 46206,
            "f_seats": 5513,
            "r_seats": 5553,
            "cargo_demand": 386481
        }
    },
    {
        "iata": "AOR",
        "icao": "WMKA",
        "airportName": "Sultan Abdul Halim Airport",
        "location_country": "Malaysia",
        "location_city": "Kedah",
        "location_state": "Alor Setar",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.189670086",
        "longitude": "100.3980026",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9005",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171828,
            "s_seats": 29685,
            "j_seats": 20614,
            "f_seats": 3877,
            "r_seats": 8440,
            "cargo_demand": 108951
        }
    },
    {
        "iata": "AOT",
        "icao": "LIMW",
        "airportName": "Aosta Valley Airport",
        "location_country": "Italy",
        "location_city": "Aosta Valley",
        "location_state": "Aosta",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.738499",
        "longitude": "7.36872",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4088",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95708,
            "s_seats": 35043,
            "j_seats": 20826,
            "f_seats": 8178,
            "r_seats": 5228,
            "cargo_demand": 460886
        }
    },
    {
        "iata": "APA",
        "icao": "KAPA",
        "airportName": "Centennial Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Denver",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.57009888",
        "longitude": "-104.848999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4800",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "10001",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "7001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137934,
            "s_seats": 28740,
            "j_seats": 37497,
            "f_seats": 13216,
            "r_seats": 4344,
            "cargo_demand": 427016
        }
    },
    {
        "iata": "APC",
        "icao": "KAPC",
        "airportName": "Napa County Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Napa",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.2132",
        "longitude": "-122.280998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5007",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "2510",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "5931",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3137,
            "s_seats": 2172,
            "j_seats": 1723,
            "f_seats": 461,
            "r_seats": 182,
            "cargo_demand": 84412
        }
    },
    {
        "iata": "APF",
        "icao": "KAPF",
        "airportName": "Naples Municipal Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Naples",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "26.15259933",
        "longitude": "-81.77529907",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5290",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "NE/SW",
                "runway_length": "1850",
                "runway_width": "100",
                "runway_material": "TURF"
            }
        ],
        "demand": {
            "y_seats": 86124,
            "s_seats": 35769,
            "j_seats": 43593,
            "f_seats": 17557,
            "r_seats": 7786,
            "cargo_demand": 269550
        }
    },
    {
        "iata": "APG",
        "icao": "KAPG",
        "airportName": "Phillips Army Airfield",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Aberdeen Proving Ground",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.466202",
        "longitude": "-76.1688",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7997",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "4849",
                "runway_width": "149",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5004",
                "runway_width": "149",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78682,
            "s_seats": 33219,
            "j_seats": 26092,
            "f_seats": 6360,
            "r_seats": 3971,
            "cargo_demand": 210608
        }
    },
    {
        "iata": "APH",
        "icao": "KAPH",
        "airportName": "A.P. Hill Army Airfield",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Fort A.P. Hill",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.068902",
        "longitude": "-77.318298",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2201",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2301,
            "s_seats": 1903,
            "j_seats": 1289,
            "f_seats": 391,
            "r_seats": 25,
            "cargo_demand": 50647
        }
    },
    {
        "iata": "API",
        "icao": "SKAP",
        "airportName": "Captain Luis F. Gomez Nino Air Base",
        "location_country": "Colombia",
        "location_city": "Apiay",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.07607",
        "longitude": "-73.5627",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "10/28",
                "runway_length": "8204",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 91400,
            "s_seats": 38486,
            "j_seats": 35723,
            "f_seats": 14931,
            "r_seats": 7100,
            "cargo_demand": 469390
        }
    },
    {
        "iata": "APK",
        "icao": "NTGD",
        "airportName": "Apataki Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Apataki",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.5736",
        "longitude": "-146.414993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2854",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3732,
            "s_seats": 2819,
            "j_seats": 1162,
            "f_seats": 347,
            "r_seats": 110,
            "cargo_demand": 36694
        }
    },
    {
        "iata": "APL",
        "icao": "FQNP",
        "airportName": "Nampula Airport",
        "location_country": "Mozambique",
        "location_city": "Nampula",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.10560036",
        "longitude": "39.28179932",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5135",
                "runway_width": "148",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 153900,
            "s_seats": 40530,
            "j_seats": 38634,
            "f_seats": 14325,
            "r_seats": 8169,
            "cargo_demand": 305168
        }
    },
    {
        "iata": "APN",
        "icao": "KAPN",
        "airportName": "Alpena County Regional Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Alpena",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "45.0780983",
        "longitude": "-83.56030273",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "07/25",
                "runway_length": "5031",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 154191,
            "s_seats": 40627,
            "j_seats": 45459,
            "f_seats": 18280,
            "r_seats": 6793,
            "cargo_demand": 361825
        }
    },
    {
        "iata": "APO",
        "icao": "SKLC",
        "airportName": "Antonio Roldan Betancourt Airport",
        "location_country": "Colombia",
        "location_city": "Apartado",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.81196",
        "longitude": "-76.7164",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7153",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70337,
            "s_seats": 44856,
            "j_seats": 55413,
            "f_seats": 8274,
            "r_seats": 5540,
            "cargo_demand": 280522
        }
    },
    {
        "iata": "APQ",
        "icao": "SNAL",
        "airportName": "Arapiraca Airport",
        "location_country": "Brazil",
        "location_city": "Alagoas",
        "location_state": "Arapiraca",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.775360107",
        "longitude": "-36.62919998",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3051",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1563,
            "s_seats": 1682,
            "j_seats": 1257,
            "f_seats": 242,
            "r_seats": 38,
            "cargo_demand": 75515
        }
    },
    {
        "iata": "APS",
        "icao": "SWNS",
        "airportName": "Anapolis Airport",
        "location_country": "Brazil",
        "location_city": "Goias",
        "location_state": "Anapolis",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-16.36230087",
        "longitude": "-48.92710114",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "6043",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1062,
            "s_seats": 2977,
            "j_seats": 1433,
            "f_seats": 16,
            "r_seats": 114,
            "cargo_demand": 63683
        }
    },
    {
        "iata": "APT",
        "icao": "KAPT",
        "airportName": "Marion County Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Jasper",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.060699",
        "longitude": "-85.585297",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3500",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2660,
            "s_seats": 1882,
            "j_seats": 1775,
            "f_seats": 214,
            "r_seats": 80,
            "cargo_demand": 71772
        }
    },
    {
        "iata": "APU",
        "icao": "SSAP",
        "airportName": "Apucarana Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Apucarana",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-23.609099",
        "longitude": "-51.384301",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1525,
            "s_seats": 1593,
            "j_seats": 1403,
            "f_seats": 241,
            "r_seats": 7,
            "cargo_demand": 53029
        }
    },
    {
        "iata": "APV",
        "icao": "KAPV",
        "airportName": "Apple Valley Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Apple Valley",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.57529831",
        "longitude": "-117.185997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4099",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6498",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1002,
            "s_seats": 2666,
            "j_seats": 1358,
            "f_seats": 213,
            "r_seats": 190,
            "cargo_demand": 57549
        }
    },
    {
        "iata": "APW",
        "icao": "NSFA",
        "airportName": "Faleolo International Airport",
        "location_country": "Samoa",
        "location_city": "Upolu Island",
        "location_state": "Apia",
        "time_zone": "UTC+13:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-13.82999992",
        "longitude": "-172.0079956",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 164518,
            "s_seats": 35968,
            "j_seats": 23571,
            "f_seats": 6239,
            "r_seats": 4195,
            "cargo_demand": 267252
        }
    },
    {
        "iata": "APX",
        "icao": "SSOG",
        "airportName": "Arapongas Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Arapongas",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-23.3529",
        "longitude": "-51.491699",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3937",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3431,
            "s_seats": 2104,
            "j_seats": 1509,
            "f_seats": 454,
            "r_seats": 179,
            "cargo_demand": 11103
        }
    },
    {
        "iata": "APY",
        "icao": "SNAI",
        "airportName": "Alto Parnaiba Airport",
        "location_country": "Brazil",
        "location_city": "Maranhio",
        "location_state": "Alto Parnaiba",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.083610535",
        "longitude": "-45.95055771",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4593",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2281,
            "s_seats": 1725,
            "j_seats": 1799,
            "f_seats": 167,
            "r_seats": 117,
            "cargo_demand": 40660
        }
    },
    {
        "iata": "APZ",
        "icao": "SAHZ",
        "airportName": "Zapala Airport",
        "location_country": "Argentina",
        "location_city": "Neuquen",
        "location_state": "Zapala",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.975498",
        "longitude": "-70.113602",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "7218",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189579,
            "s_seats": 39475,
            "j_seats": 38162,
            "f_seats": 16047,
            "r_seats": 7543,
            "cargo_demand": 213647
        }
    },
    {
        "iata": "AQA",
        "icao": "SBAQ",
        "airportName": "Araraquara Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Araraquara",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-21.812",
        "longitude": "-48.132999",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5907",
                "runway_width": "99",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174941,
            "s_seats": 49087,
            "j_seats": 19742,
            "f_seats": 4392,
            "r_seats": 6557,
            "cargo_demand": 195818
        }
    },
    {
        "iata": "AQB",
        "icao": "MGQC",
        "airportName": "Quiche Airport",
        "location_country": "Guatemala",
        "location_city": "Quiche",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "15.0122",
        "longitude": "-91.150597",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4090",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1950,
            "s_seats": 2540,
            "j_seats": 1078,
            "f_seats": 304,
            "r_seats": 10,
            "cargo_demand": 65624
        }
    },
    {
        "iata": "AQI",
        "icao": "OEPA",
        "airportName": "Al Qaisumah-Hafr Al Batin Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Qaisumah / Hafar Al-Batin",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.335199",
        "longitude": "46.125099",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101682,
            "s_seats": 38786,
            "j_seats": 24548,
            "f_seats": 1265,
            "r_seats": 6081,
            "cargo_demand": 81332
        }
    },
    {
        "iata": "AQJ",
        "icao": "OJAQ",
        "airportName": "King Hussein International Airport",
        "location_country": "Jordan",
        "location_city": "Aqaba",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "29.61160088",
        "longitude": "35.01810074",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9855",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189788,
            "s_seats": 36708,
            "j_seats": 40940,
            "f_seats": 9062,
            "r_seats": 3418,
            "cargo_demand": 175333
        }
    },
    {
        "iata": "AQP",
        "icao": "SPQU",
        "airportName": "Rodriguez Ballon International Airport",
        "location_country": "Peru",
        "location_city": "Arequipa",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.34110069",
        "longitude": "-71.58309937",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9777",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58113,
            "s_seats": 27383,
            "j_seats": 58866,
            "f_seats": 16710,
            "r_seats": 3347,
            "cargo_demand": 287436
        }
    },
    {
        "iata": "ARA",
        "icao": "KARA",
        "airportName": "Acadiana Regional Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "New Iberia",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.0378",
        "longitude": "-91.883904",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8002",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "16W/34W",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 58819,
            "s_seats": 29277,
            "j_seats": 20995,
            "f_seats": 8741,
            "r_seats": 7344,
            "cargo_demand": 476343
        }
    },
    {
        "iata": "ARB",
        "icao": "KARB",
        "airportName": "Ann Arbor Municipal Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Ann Arbor",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.22299957",
        "longitude": "-83.74559784",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3505",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2750",
                "runway_width": "110",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2228,
            "s_seats": 2048,
            "j_seats": 1054,
            "f_seats": 280,
            "r_seats": 188,
            "cargo_demand": 21870
        }
    },
    {
        "iata": "ARC",
        "icao": "PARC",
        "airportName": "Arctic Village Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Arctic Village",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "68.1147",
        "longitude": "-145.578995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4500",
                "runway_width": "75",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 172745,
            "s_seats": 35543,
            "j_seats": 40625,
            "f_seats": 4429,
            "r_seats": 6449,
            "cargo_demand": 469802
        }
    },
    {
        "iata": "ARD",
        "icao": "WATM",
        "airportName": "Alor Island Airport",
        "location_country": "Indonesia",
        "location_city": "Alor Island",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.132340431",
        "longitude": "124.5970001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4586",
                "runway_width": "90",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2369,
            "s_seats": 2821,
            "j_seats": 1452,
            "f_seats": 405,
            "r_seats": 161,
            "cargo_demand": 12290
        }
    },
    {
        "iata": "ARE",
        "icao": "TJAB",
        "airportName": "Antonio (Nery) Juarbe Pol Airport",
        "location_country": "United States",
        "location_city": "Puerto Rico",
        "location_state": "Arecibo",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.45000076",
        "longitude": "-66.6753006",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3975",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154387,
            "s_seats": 47013,
            "j_seats": 52194,
            "f_seats": 13617,
            "r_seats": 3105,
            "cargo_demand": 449421
        }
    },
    {
        "iata": "ARG",
        "icao": "KARG",
        "airportName": "Walnut Ridge Regional Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Walnut Ridge",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.124667",
        "longitude": "-90.925111",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5003",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1398,
            "s_seats": 2126,
            "j_seats": 1827,
            "f_seats": 21,
            "r_seats": 63,
            "cargo_demand": 16910
        }
    },
    {
        "iata": "ARH",
        "icao": "ULAA",
        "airportName": "Talagi Airport",
        "location_country": "Russia",
        "location_city": "Arkhangelsk Oblast",
        "location_state": "Arkhangelsk",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.60030365",
        "longitude": "40.71670151",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8202",
                "runway_width": "144",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 127026,
            "s_seats": 45764,
            "j_seats": 31491,
            "f_seats": 2136,
            "r_seats": 4204,
            "cargo_demand": 441081
        }
    },
    {
        "iata": "ARI",
        "icao": "SCAR",
        "airportName": "Chacalluta International Airport",
        "location_country": "Chile",
        "location_city": "Arica",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-18.348499",
        "longitude": "-70.338699",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7119",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79946,
            "s_seats": 40076,
            "j_seats": 6572,
            "f_seats": 4295,
            "r_seats": 8783,
            "cargo_demand": 381832
        }
    },
    {
        "iata": "ARK",
        "icao": "HTAR",
        "airportName": "Arusha Airport",
        "location_country": "Tanzania",
        "location_city": "Arusha",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.367789984",
        "longitude": "36.63330078",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5377",
                "runway_width": "105",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 196677,
            "s_seats": 35483,
            "j_seats": 40730,
            "f_seats": 19278,
            "r_seats": 6323,
            "cargo_demand": 381577
        }
    },
    {
        "iata": "ARM",
        "icao": "YARM",
        "airportName": "Armidale Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Armidale",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-30.52809906",
        "longitude": "151.6170044",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5702",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3661",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 131257,
            "s_seats": 35926,
            "j_seats": 34177,
            "f_seats": 11179,
            "r_seats": 8485,
            "cargo_demand": 88323
        }
    },
    {
        "iata": "ARN",
        "icao": "ESSA",
        "airportName": "Stockholm Arlanda Airport",
        "location_country": "Sweden",
        "location_city": "Stockholm",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "59.65190125",
        "longitude": "17.91860008",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "10830",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "8201",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 496135,
            "s_seats": 74715,
            "j_seats": 136565,
            "f_seats": 26599,
            "r_seats": 10000,
            "cargo_demand": 14614229
        }
    },
    {
        "iata": "ARR",
        "icao": "SAVR",
        "airportName": "Alto Rio Senguer Airport",
        "location_country": "Argentina",
        "location_city": "Chubut",
        "location_state": "Alto Rio Senguer",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-45.013599",
        "longitude": "-70.812202",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "4593",
                "runway_width": "131",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2389,
            "s_seats": 1975,
            "j_seats": 1049,
            "f_seats": 19,
            "r_seats": 61,
            "cargo_demand": 95280
        }
    },
    {
        "iata": "ART",
        "icao": "KART",
        "airportName": "Watertown International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Watertown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.9919014",
        "longitude": "-76.021698",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152123,
            "s_seats": 40580,
            "j_seats": 6887,
            "f_seats": 8830,
            "r_seats": 8701,
            "cargo_demand": 473388
        }
    },
    {
        "iata": "ARV",
        "icao": "KARV",
        "airportName": "Lakeland Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Minocqua / Woodruff",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.92789841",
        "longitude": "-89.73090363",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3602",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5150",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2809,
            "s_seats": 2555,
            "j_seats": 1821,
            "f_seats": 157,
            "r_seats": 0,
            "cargo_demand": 21593
        }
    },
    {
        "iata": "ARW",
        "icao": "LRAR",
        "airportName": "Arad International Airport",
        "location_country": "Romania",
        "location_city": "Arad",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.17660141",
        "longitude": "21.26199913",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 138532,
            "s_seats": 33370,
            "j_seats": 59144,
            "f_seats": 11765,
            "r_seats": 5207,
            "cargo_demand": 488783
        }
    },
    {
        "iata": "ARX",
        "icao": "SBAC",
        "airportName": "Aracati Airport",
        "location_country": "Brazil",
        "location_city": "Canoa Quebrada",
        "location_state": "Aracati",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.01",
        "longitude": "-0.01",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 88981,
            "s_seats": 37803,
            "j_seats": 20054,
            "f_seats": 9547,
            "r_seats": 9441,
            "cargo_demand": 208831
        }
    },
    {
        "iata": "ARY",
        "icao": "YARA",
        "airportName": "Ararat Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Ararat",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-37.309978",
        "longitude": "142.988688",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2165",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4068",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86882,
            "s_seats": 41533,
            "j_seats": 56781,
            "f_seats": 13944,
            "r_seats": 8776,
            "cargo_demand": 300369
        }
    },
    {
        "iata": "ASA",
        "icao": "HHSB",
        "airportName": "Assab International Airport",
        "location_country": "Eritrea",
        "location_city": "Assab",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.07180023",
        "longitude": "42.64500046",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "11531",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155516,
            "s_seats": 31591,
            "j_seats": 19007,
            "f_seats": 9927,
            "r_seats": 4214,
            "cargo_demand": 323764
        }
    },
    {
        "iata": "ASB",
        "icao": "UTAA",
        "airportName": "Ashgabat International Airport",
        "location_country": "Turkmenistan",
        "location_city": "Ashgabat",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.986801",
        "longitude": "58.361",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5907",
                "runway_width": "114",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "12467",
                "runway_width": "151",
                "runway_material": "CON"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "9806",
                "runway_width": "196",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 310872,
            "s_seats": 84024,
            "j_seats": 89081,
            "f_seats": 34613,
            "r_seats": 10000,
            "cargo_demand": 4165394
        }
    },
    {
        "iata": "ASD",
        "icao": "MYAF",
        "airportName": "Andros Town International Airport",
        "location_country": "Bahamas",
        "location_city": "Andros Island",
        "location_state": "Andros Town",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "24.69790077",
        "longitude": "-77.79560089",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4355",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 91413,
            "s_seats": 42327,
            "j_seats": 17142,
            "f_seats": 4376,
            "r_seats": 5403,
            "cargo_demand": 397661
        }
    },
    {
        "iata": "ASE",
        "icao": "KASE",
        "airportName": "Aspen-Pitkin County Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Aspen",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.22320175",
        "longitude": "-106.8690033",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "8006",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 85630,
            "s_seats": 28982,
            "j_seats": 53231,
            "f_seats": 15206,
            "r_seats": 8570,
            "cargo_demand": 379489
        }
    },
    {
        "iata": "ASF",
        "icao": "URWA",
        "airportName": "Narimanovo Airport",
        "location_country": "Russia",
        "location_city": "Astrakhan Oblast",
        "location_state": "Astrakhan",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.28329849",
        "longitude": "48.00630188",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6398",
                "runway_width": "328",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 152248,
            "s_seats": 35735,
            "j_seats": 50740,
            "f_seats": 15668,
            "r_seats": 6163,
            "cargo_demand": 278140
        }
    },
    {
        "iata": "ASG",
        "icao": "NZAS",
        "airportName": "Ashburton Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Ashburton",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "small_airport",
        "latitude": "-43.90330124",
        "longitude": "171.7969971",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2943",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "06/24",
                "runway_length": "4554",
                "runway_width": "295",
                "runway_material": "GRS"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3360",
                "runway_width": "295",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3543",
                "runway_width": "148",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1409,
            "s_seats": 1792,
            "j_seats": 1608,
            "f_seats": 409,
            "r_seats": 92,
            "cargo_demand": 85150
        }
    },
    {
        "iata": "ASH",
        "icao": "KASH",
        "airportName": "Nashua Airport",
        "location_country": "United States",
        "location_city": "New Hampshire",
        "location_state": "Nashua",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.7817",
        "longitude": "-71.514801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5501",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2488,
            "s_seats": 2739,
            "j_seats": 1334,
            "f_seats": 142,
            "r_seats": 181,
            "cargo_demand": 62329
        }
    },
    {
        "iata": "ASI",
        "icao": "FHAW",
        "airportName": "RAF Ascension Island",
        "location_country": "Ascension and Tristan da Cunha",
        "location_city": "British Overseas Territoryof Saint Helena",
        "location_state": "Ascension Island",
        "time_zone": "UTC+00:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.9696",
        "longitude": "-14.3937",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10019",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55448,
            "s_seats": 34966,
            "j_seats": 21653,
            "f_seats": 4958,
            "r_seats": 3757,
            "cargo_demand": 124430
        }
    },
    {
        "iata": "ASJ",
        "icao": "RJKA",
        "airportName": "Amami Airport",
        "location_country": "Japan",
        "location_city": "Satsunan Islands",
        "location_state": "Amami",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.43059921",
        "longitude": "129.7129974",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6560",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146757,
            "s_seats": 38397,
            "j_seats": 33521,
            "f_seats": 1719,
            "r_seats": 7054,
            "cargo_demand": 161501
        }
    },
    {
        "iata": "ASK",
        "icao": "DIYO",
        "airportName": "Yamoussoukro Airport",
        "location_country": "Cote d'Ivoire",
        "location_city": "Yamoussoukro",
        "location_state": "",
        "time_zone": "UTC+00:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.903170109",
        "longitude": "-5.365580082",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144473,
            "s_seats": 36198,
            "j_seats": 5644,
            "f_seats": 18789,
            "r_seats": 3256,
            "cargo_demand": 226335
        }
    },
    {
        "iata": "ASL",
        "icao": "KASL",
        "airportName": "Harrison County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Marshall",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.52050018",
        "longitude": "-94.30780029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3298",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2328,
            "s_seats": 2493,
            "j_seats": 1935,
            "f_seats": 163,
            "r_seats": 146,
            "cargo_demand": 58103
        }
    },
    {
        "iata": "ASM",
        "icao": "HHAS",
        "airportName": "Asmara International Airport",
        "location_country": "Eritrea",
        "location_city": "Asmara",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.29189968",
        "longitude": "38.91070175",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5951",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155379,
            "s_seats": 43463,
            "j_seats": 9393,
            "f_seats": 10139,
            "r_seats": 6768,
            "cargo_demand": 351814
        }
    },
    {
        "iata": "ASN",
        "icao": "KASN",
        "airportName": "Talladega Municipal Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Talladega",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.56990051",
        "longitude": "-86.05090332",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2703,
            "s_seats": 2294,
            "j_seats": 1182,
            "f_seats": 222,
            "r_seats": 101,
            "cargo_demand": 18714
        }
    },
    {
        "iata": "ASO",
        "icao": "HASO",
        "airportName": "Asosa Airport",
        "location_country": "Ethiopia",
        "location_city": "Asosa",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.01850033",
        "longitude": "34.5862999",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127905,
            "s_seats": 25449,
            "j_seats": 58489,
            "f_seats": 6122,
            "r_seats": 9686,
            "cargo_demand": 428034
        }
    },
    {
        "iata": "ASP",
        "icao": "YBAS",
        "airportName": "Alice Springs Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Alice Springs",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.80669975",
        "longitude": "133.9019928",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7999",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3717",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 91444,
            "s_seats": 46742,
            "j_seats": 38982,
            "f_seats": 1845,
            "r_seats": 7237,
            "cargo_demand": 329447
        }
    },
    {
        "iata": "ASR",
        "icao": "LTAU",
        "airportName": "Erkilet International Airport",
        "location_country": "Turkey",
        "location_city": "Kayseri",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.770401",
        "longitude": "35.49539948",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9841",
                "runway_width": "145",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 88014,
            "s_seats": 40292,
            "j_seats": 16550,
            "f_seats": 5303,
            "r_seats": 9582,
            "cargo_demand": 396580
        }
    },
    {
        "iata": "AST",
        "icao": "KAST",
        "airportName": "Astoria Regional Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Astoria",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.15800095",
        "longitude": "-123.8789978",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5796",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4996",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71441,
            "s_seats": 26462,
            "j_seats": 33893,
            "f_seats": 16083,
            "r_seats": 5896,
            "cargo_demand": 132051
        }
    },
    {
        "iata": "ASU",
        "icao": "SGAS",
        "airportName": "Silvio Pettirossi International Airport",
        "location_country": "Paraguay",
        "location_city": "Asuncion",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Mar",
        "airport_size": "medium_airport",
        "latitude": "-25.23999977",
        "longitude": "-57.52000046",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "10997",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186560,
            "s_seats": 25372,
            "j_seats": 46296,
            "f_seats": 11557,
            "r_seats": 9751,
            "cargo_demand": 397905
        }
    },
    {
        "iata": "ASV",
        "icao": "HKAM",
        "airportName": "Amboseli Airport",
        "location_country": "Kenya",
        "location_city": "Amboseli",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.645050049",
        "longitude": "37.25310135",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3284",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64202,
            "s_seats": 45416,
            "j_seats": 18635,
            "f_seats": 19179,
            "r_seats": 3944,
            "cargo_demand": 254130
        }
    },
    {
        "iata": "ASW",
        "icao": "HESN",
        "airportName": "Aswan International Airport",
        "location_country": "Egypt",
        "location_city": "Aswan",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.96439934",
        "longitude": "32.81999969",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "11161",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 133915,
            "s_seats": 47028,
            "j_seats": 13674,
            "f_seats": 9155,
            "r_seats": 8558,
            "cargo_demand": 372746
        }
    },
    {
        "iata": "ASX",
        "icao": "KASX",
        "airportName": "John F. Kennedy Memorial Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Ashland",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "46.54850006",
        "longitude": "-90.91899872",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5199",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3498",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3036,
            "s_seats": 2514,
            "j_seats": 1124,
            "f_seats": 350,
            "r_seats": 36,
            "cargo_demand": 80400
        }
    },
    {
        "iata": "ASY",
        "icao": "KASY",
        "airportName": "Ashley Municipal Airport",
        "location_country": "United States",
        "location_city": "North Dakota",
        "location_state": "Ashley",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "46.02389908",
        "longitude": "-99.3526001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2825",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4300",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3196,
            "s_seats": 1787,
            "j_seats": 1069,
            "f_seats": 229,
            "r_seats": 174,
            "cargo_demand": 4783
        }
    },
    {
        "iata": "ATA",
        "icao": "SPHZ",
        "airportName": "Comandante FAP German Arias Graziani Airport",
        "location_country": "Peru",
        "location_city": "Huaraz",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.347439766",
        "longitude": "-77.5983963",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "10007",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144730,
            "s_seats": 48495,
            "j_seats": 44762,
            "f_seats": 1586,
            "r_seats": 4483,
            "cargo_demand": 284621
        }
    },
    {
        "iata": "ATB",
        "icao": "HSAT",
        "airportName": "Atbara Airport",
        "location_country": "Sudan",
        "location_city": "Atbara",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "17.71034431",
        "longitude": "34.05701828",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5905",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2915,
            "s_seats": 2848,
            "j_seats": 1218,
            "f_seats": 175,
            "r_seats": 5,
            "cargo_demand": 79408
        }
    },
    {
        "iata": "ATC",
        "icao": "MYCA",
        "airportName": "Arthur's Town Airport",
        "location_country": "Bahamas",
        "location_city": "Cat Island",
        "location_state": "Arthur's Town",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "24.6294",
        "longitude": "-75.673797",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7015",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 163591,
            "s_seats": 35033,
            "j_seats": 8247,
            "f_seats": 7207,
            "r_seats": 4974,
            "cargo_demand": 270369
        }
    },
    {
        "iata": "ATD",
        "icao": "AGAT",
        "airportName": "Uru Harbour Airport",
        "location_country": "Solomon Islands",
        "location_city": "Malaita",
        "location_state": "Atoifi",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.87333",
        "longitude": "161.011002",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2769",
                "runway_width": "49",
                "runway_material": "Crushed coral"
            }
        ],
        "demand": {
            "y_seats": 1833,
            "s_seats": 2165,
            "j_seats": 1741,
            "f_seats": 257,
            "r_seats": 170,
            "cargo_demand": 53573
        }
    },
    {
        "iata": "ATF",
        "icao": "SEAM",
        "airportName": "Chachoan Airport",
        "location_country": "Ecuador",
        "location_city": "Ambato",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.21207",
        "longitude": "-78.5746",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6316",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188091,
            "s_seats": 33747,
            "j_seats": 52819,
            "f_seats": 3884,
            "r_seats": 8868,
            "cargo_demand": 319145
        }
    },
    {
        "iata": "ATH",
        "icao": "LGAV",
        "airportName": "Athens International Airport",
        "location_country": "Greece",
        "location_city": "Athens",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "37.93640137",
        "longitude": "23.94449997",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "12467",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 275106,
            "s_seats": 79376,
            "j_seats": 134451,
            "f_seats": 68010,
            "r_seats": 10000,
            "cargo_demand": 22895269
        }
    },
    {
        "iata": "ATI",
        "icao": "SUAG",
        "airportName": "Artigas International Airport",
        "location_country": "Uruguay",
        "location_city": "Artigas",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-30.40069962",
        "longitude": "-56.50790024",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4183",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2964,
            "s_seats": 2580,
            "j_seats": 1207,
            "f_seats": 428,
            "r_seats": 11,
            "cargo_demand": 40162
        }
    },
    {
        "iata": "ATJ",
        "icao": "FMME",
        "airportName": "Antsirabe Airport",
        "location_country": "Madagascar",
        "location_city": "Antsirabe",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-19.83922148",
        "longitude": "47.06371307",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4921",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3283,
            "s_seats": 2641,
            "j_seats": 1915,
            "f_seats": 112,
            "r_seats": 151,
            "cargo_demand": 21621
        }
    },
    {
        "iata": "ATK",
        "icao": "PATQ",
        "airportName": "Atqasuk Edward Burnell Sr. Memorial Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Atqasuk",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "70.46704",
        "longitude": "-157.436013",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4370",
                "runway_width": "90",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 84059,
            "s_seats": 49751,
            "j_seats": 53927,
            "f_seats": 11974,
            "r_seats": 9334,
            "cargo_demand": 366792
        }
    },
    {
        "iata": "ATL",
        "icao": "KATL",
        "airportName": "Hartsfield-Jackson Atlanta International Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Atlanta",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "33.6367",
        "longitude": "-84.428101",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "11890",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "9001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "10/28",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 318749,
            "s_seats": 83217,
            "j_seats": 160698,
            "f_seats": 61627,
            "r_seats": 10000,
            "cargo_demand": 8258018
        }
    },
    {
        "iata": "ATM",
        "icao": "SBHT",
        "airportName": "Altamira Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Altamira",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.253910065",
        "longitude": "-52.25400162",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "6572",
                "runway_width": "125",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104310,
            "s_seats": 45724,
            "j_seats": 31160,
            "f_seats": 13428,
            "r_seats": 5921,
            "cargo_demand": 128212
        }
    },
    {
        "iata": "ATO",
        "icao": "KUNI",
        "airportName": "Ohio University Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Athens / Albany",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.21099854",
        "longitude": "-82.23139954",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5600",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1194,
            "s_seats": 2962,
            "j_seats": 1236,
            "f_seats": 474,
            "r_seats": 186,
            "cargo_demand": 22398
        }
    },
    {
        "iata": "ATQ",
        "icao": "VIAR",
        "airportName": "Sri Guru Ram Dass Jee International Airport",
        "location_country": "India",
        "location_city": "Punjab",
        "location_state": "Amritsar",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "31.70960045",
        "longitude": "74.79730225",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "10791",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 375585,
            "s_seats": 71091,
            "j_seats": 76773,
            "f_seats": 47566,
            "r_seats": 10000,
            "cargo_demand": 27503911
        }
    },
    {
        "iata": "ATR",
        "icao": "GQPA",
        "airportName": "Atar International Airport",
        "location_country": "Mauritania",
        "location_city": "Atar",
        "location_state": "",
        "time_zone": "UTC+00:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.5067997",
        "longitude": "-13.04319954",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9855",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54906,
            "s_seats": 32056,
            "j_seats": 22992,
            "f_seats": 4638,
            "r_seats": 8251,
            "cargo_demand": 448312
        }
    },
    {
        "iata": "ATS",
        "icao": "KATS",
        "airportName": "Artesia Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Artesia",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.85250092",
        "longitude": "-104.4680023",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6301",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5390",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1411,
            "s_seats": 2251,
            "j_seats": 1635,
            "f_seats": 170,
            "r_seats": 102,
            "cargo_demand": 44095
        }
    },
    {
        "iata": "ATV",
        "icao": "FTTI",
        "airportName": "Ati Airport",
        "location_country": "Chad",
        "location_city": "Ati",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "13.23890018",
        "longitude": "18.31329918",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6025",
                "runway_width": "90",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 3780,
            "s_seats": 2852,
            "j_seats": 1252,
            "f_seats": 130,
            "r_seats": 135,
            "cargo_demand": 22139
        }
    },
    {
        "iata": "ATW",
        "icao": "KATW",
        "airportName": "Appleton International Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Appleton",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.2580986",
        "longitude": "-88.51909637",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8002",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6501",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2217,
            "s_seats": 2708,
            "j_seats": 1765,
            "f_seats": 459,
            "r_seats": 107,
            "cargo_demand": 60001
        }
    },
    {
        "iata": "ATY",
        "icao": "KATY",
        "airportName": "Watertown Regional Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Watertown",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.91400146",
        "longitude": "-97.15470123",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6900",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6895",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179802,
            "s_seats": 26648,
            "j_seats": 34505,
            "f_seats": 1494,
            "r_seats": 7436,
            "cargo_demand": 84981
        }
    },
    {
        "iata": "ATZ",
        "icao": "HEAT",
        "airportName": "Assiut Airport",
        "location_country": "Egypt",
        "location_city": "Assiut",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.050823",
        "longitude": "31.016303",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9905",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81132,
            "s_seats": 41489,
            "j_seats": 41870,
            "f_seats": 13290,
            "r_seats": 3649,
            "cargo_demand": 161879
        }
    },
    {
        "iata": "AUA",
        "icao": "TNCA",
        "airportName": "Queen Beatrix International Airport",
        "location_country": "Kingdom of the Netherlands",
        "location_city": "Aruba",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "12.5014",
        "longitude": "-70.015198",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9000",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175200,
            "s_seats": 83889,
            "j_seats": 36379,
            "f_seats": 22855,
            "r_seats": 10000,
            "cargo_demand": 10812654
        }
    },
    {
        "iata": "AUC",
        "icao": "SKUC",
        "airportName": "Santiago Perez Quiroz Airport",
        "location_country": "Colombia",
        "location_city": "Arauca",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.06888",
        "longitude": "-70.7369",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6890",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78551,
            "s_seats": 43103,
            "j_seats": 52260,
            "f_seats": 15901,
            "r_seats": 9692,
            "cargo_demand": 222934
        }
    },
    {
        "iata": "AUD",
        "icao": "YAGD",
        "airportName": "Augustus Downs Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Augustus Downs Station",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.51499939",
        "longitude": "139.878006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5498",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1410,
            "s_seats": 1756,
            "j_seats": 1638,
            "f_seats": 431,
            "r_seats": 69,
            "cargo_demand": 35472
        }
    },
    {
        "iata": "AUF",
        "icao": "LFLA",
        "airportName": "Auxerre - Branches Aerodrome",
        "location_country": "France",
        "location_city": "Burgundy",
        "location_state": "Auxerre",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.85020065",
        "longitude": "3.49710989",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5413",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92646,
            "s_seats": 43130,
            "j_seats": 56854,
            "f_seats": 9253,
            "r_seats": 3012,
            "cargo_demand": 77398
        }
    },
    {
        "iata": "AUG",
        "icao": "KAUG",
        "airportName": "Augusta State Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Augusta",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.3205986",
        "longitude": "-69.79730225",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2703",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110112,
            "s_seats": 27994,
            "j_seats": 53831,
            "f_seats": 8328,
            "r_seats": 3705,
            "cargo_demand": 360531
        }
    },
    {
        "iata": "AUH",
        "icao": "OMAA",
        "airportName": "Abu Dhabi International Airport",
        "location_country": "United Arab Emirates",
        "location_city": "Abu Dhabi",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "24.443764",
        "longitude": "54.651718",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "13452",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "13452",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122320,
            "s_seats": 71075,
            "j_seats": 133121,
            "f_seats": 26518,
            "r_seats": 10000,
            "cargo_demand": 15452518
        }
    },
    {
        "iata": "AUM",
        "icao": "KAUM",
        "airportName": "Austin Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Austin",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.66500092",
        "longitude": "-92.93340302",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5800",
                "runway_width": "100",
                "runway_material": "CONC-G"
            }
        ],
        "demand": {
            "y_seats": 2801,
            "s_seats": 2482,
            "j_seats": 1879,
            "f_seats": 205,
            "r_seats": 168,
            "cargo_demand": 28569
        }
    },
    {
        "iata": "AUN",
        "icao": "KAUN",
        "airportName": "Auburn Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Auburn",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.95479965",
        "longitude": "-121.0820007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3700",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1782,
            "s_seats": 2813,
            "j_seats": 1312,
            "f_seats": 417,
            "r_seats": 52,
            "cargo_demand": 34610
        }
    },
    {
        "iata": "AUO",
        "icao": "KAUO",
        "airportName": "Auburn University Regional Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Auburn",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.615101",
        "longitude": "-85.433998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5264",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2970,
            "s_seats": 2747,
            "j_seats": 1626,
            "f_seats": 491,
            "r_seats": 196,
            "cargo_demand": 39567
        }
    },
    {
        "iata": "AUQ",
        "icao": "NTMN",
        "airportName": "Atuona Airport",
        "location_country": "French Polynesia",
        "location_city": "Marquesas Islands",
        "location_state": "Atuona",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.768790245",
        "longitude": "-139.0110016",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3986",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163609,
            "s_seats": 25070,
            "j_seats": 34901,
            "f_seats": 3755,
            "r_seats": 4341,
            "cargo_demand": 217100
        }
    },
    {
        "iata": "AUR",
        "icao": "LFLW",
        "airportName": "Aurillac - Tronquieres Airport",
        "location_country": "France",
        "location_city": "Auvergne",
        "location_state": "Aurillac",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "44.89139938",
        "longitude": "2.421940088",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5577",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149718,
            "s_seats": 35451,
            "j_seats": 57176,
            "f_seats": 13754,
            "r_seats": 8581,
            "cargo_demand": 397443
        }
    },
    {
        "iata": "AUS",
        "icao": "KAUS",
        "airportName": "Austin-Bergstrom International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Austin",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "30.197535",
        "longitude": "-97.662015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "12248",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 303580,
            "s_seats": 73304,
            "j_seats": 163359,
            "f_seats": 33812,
            "r_seats": 10000,
            "cargo_demand": 6685015
        }
    },
    {
        "iata": "AUU",
        "icao": "YAUR",
        "airportName": "Aurukun Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Aurukun",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.354067",
        "longitude": "141.72065",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4140",
                "runway_width": "98",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 2876,
            "s_seats": 2045,
            "j_seats": 1567,
            "f_seats": 31,
            "r_seats": 33,
            "cargo_demand": 44590
        }
    },
    {
        "iata": "AUW",
        "icao": "KAUW",
        "airportName": "Wausau Downtown Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Wausau",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.92620087",
        "longitude": "-89.62660217",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3085",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4950",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12W/30W",
                "runway_length": "8000",
                "runway_width": "300",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 154134,
            "s_seats": 29287,
            "j_seats": 44603,
            "f_seats": 19089,
            "r_seats": 7384,
            "cargo_demand": 434643
        }
    },
    {
        "iata": "AUX",
        "icao": "SWGN",
        "airportName": "Araguaina Airport",
        "location_country": "Brazil",
        "location_city": "Tocantins",
        "location_state": "Araguaina",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.22787",
        "longitude": "-48.240501",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "5919",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186002,
            "s_seats": 41713,
            "j_seats": 27514,
            "f_seats": 13125,
            "r_seats": 5304,
            "cargo_demand": 227806
        }
    },
    {
        "iata": "AUY",
        "icao": "NVVA",
        "airportName": "Anatom Airport",
        "location_country": "Vanuatu",
        "location_city": "Tafea",
        "location_state": "Anatom Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.24920082",
        "longitude": "169.7709961",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2001",
                "runway_width": "75",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 2253,
            "s_seats": 2719,
            "j_seats": 1918,
            "f_seats": 411,
            "r_seats": 82,
            "cargo_demand": 25844
        }
    },
    {
        "iata": "AUZ",
        "icao": "KARR",
        "airportName": "Aurora Municipal Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Aurora",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.77190018",
        "longitude": "-88.47570038",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6501",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5502",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3199",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2077,
            "s_seats": 1592,
            "j_seats": 1774,
            "f_seats": 354,
            "r_seats": 51,
            "cargo_demand": 71692
        }
    },
    {
        "iata": "AVB",
        "icao": "LIPA",
        "airportName": "Aviano Air Base",
        "location_country": "Italy",
        "location_city": "Pordenone",
        "location_state": "Aviano",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.031898",
        "longitude": "12.596503",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8551",
                "runway_width": "144",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66352,
            "s_seats": 31472,
            "j_seats": 29268,
            "f_seats": 2573,
            "r_seats": 9891,
            "cargo_demand": 479348
        }
    },
    {
        "iata": "AVG",
        "icao": "YAUV",
        "airportName": "Auvergne Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Auvergne Station",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.69999981",
        "longitude": "130",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3789",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3903,
            "s_seats": 2696,
            "j_seats": 1737,
            "f_seats": 348,
            "r_seats": 174,
            "cargo_demand": 14245
        }
    },
    {
        "iata": "AVI",
        "icao": "MUCA",
        "airportName": "Maximo Gomez Airport",
        "location_country": "Cuba",
        "location_city": "Ciego de avila",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "22.02709961",
        "longitude": "-78.78959656",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "11588",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103483,
            "s_seats": 43748,
            "j_seats": 49344,
            "f_seats": 18996,
            "r_seats": 5956,
            "cargo_demand": 317019
        }
    },
    {
        "iata": "AVK",
        "icao": "ZMAH",
        "airportName": "Arvaikheer Airport",
        "location_country": "Mongolia",
        "location_city": "Arvaikheer",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.25030136",
        "longitude": "102.802002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7546",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 142812,
            "s_seats": 43975,
            "j_seats": 10968,
            "f_seats": 5206,
            "r_seats": 4217,
            "cargo_demand": 370078
        }
    },
    {
        "iata": "AVL",
        "icao": "KAVL",
        "airportName": "Asheville Regional Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Asheville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.436199",
        "longitude": "-82.541801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120300,
            "s_seats": 42042,
            "j_seats": 10323,
            "f_seats": 18734,
            "r_seats": 4719,
            "cargo_demand": 180416
        }
    },
    {
        "iata": "AVN",
        "icao": "LFMV",
        "airportName": "Avignon - Provence Airport",
        "location_country": "France",
        "location_city": "Provence-Alpes-Cote d'Azur",
        "location_state": "Avignon",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.90729904",
        "longitude": "4.901830196",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6168",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100331,
            "s_seats": 34416,
            "j_seats": 7478,
            "f_seats": 13471,
            "r_seats": 4826,
            "cargo_demand": 390378
        }
    },
    {
        "iata": "AVO",
        "icao": "KAVO",
        "airportName": "Avon Park Executive Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Avon Park",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "27.59119987",
        "longitude": "-81.52780151",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5364",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3825",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2841,
            "s_seats": 1864,
            "j_seats": 1108,
            "f_seats": 43,
            "r_seats": 73,
            "cargo_demand": 61182
        }
    },
    {
        "iata": "AVP",
        "icao": "KAVP",
        "airportName": "Wilkes-Barre International Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Wilkes-Barre / Scranton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.33850098",
        "longitude": "-75.7233963",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7501",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4300",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130683,
            "s_seats": 29240,
            "j_seats": 17564,
            "f_seats": 15034,
            "r_seats": 7898,
            "cargo_demand": 491212
        }
    },
    {
        "iata": "AVU",
        "icao": "AGGJ",
        "airportName": "Avu Avu Airport",
        "location_country": "Solomon Islands",
        "location_city": "Guadalcanal",
        "location_state": "Avu Avu",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.868333333",
        "longitude": "160.4105556",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2461",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3625,
            "s_seats": 2376,
            "j_seats": 1178,
            "f_seats": 218,
            "r_seats": 193,
            "cargo_demand": 8116
        }
    },
    {
        "iata": "AVV",
        "icao": "YMAV",
        "airportName": "Avalon Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Avalon",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-38.039398",
        "longitude": "144.468994",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "10000",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137088,
            "s_seats": 38460,
            "j_seats": 54856,
            "f_seats": 5401,
            "r_seats": 8051,
            "cargo_demand": 162032
        }
    },
    {
        "iata": "AVW",
        "icao": "KAVQ",
        "airportName": "Marana Regional Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Tucson",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.4095993",
        "longitude": "-111.2180023",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3893",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6901",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1360,
            "s_seats": 2816,
            "j_seats": 1936,
            "f_seats": 224,
            "r_seats": 173,
            "cargo_demand": 70983
        }
    },
    {
        "iata": "AVX",
        "icao": "KAVX",
        "airportName": "Catalina Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Avalon",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.4049",
        "longitude": "-118.416",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1141,
            "s_seats": 1927,
            "j_seats": 1513,
            "f_seats": 86,
            "r_seats": 192,
            "cargo_demand": 22618
        }
    },
    {
        "iata": "AWA",
        "icao": "HALA",
        "airportName": "Awasa Airport",
        "location_country": "Ethiopia",
        "location_city": "Awasa",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.095",
        "longitude": "38.398333",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 103213,
            "s_seats": 47969,
            "j_seats": 59106,
            "f_seats": 16488,
            "r_seats": 8514,
            "cargo_demand": 348389
        }
    },
    {
        "iata": "AWD",
        "icao": "NVVB",
        "airportName": "Aniwa Airport",
        "location_country": "Vanuatu",
        "location_city": "Tafea",
        "location_state": "Aniwa Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-19.2346",
        "longitude": "169.6009",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "2625",
                "runway_width": "82",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1480,
            "s_seats": 2347,
            "j_seats": 1720,
            "f_seats": 14,
            "r_seats": 134,
            "cargo_demand": 60667
        }
    },
    {
        "iata": "AWK",
        "icao": "PWAK",
        "airportName": "Wake Island Airfield",
        "location_country": "United States",
        "location_city": "Wake Island",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.282101",
        "longitude": "166.636002",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9843",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189676,
            "s_seats": 38216,
            "j_seats": 48397,
            "f_seats": 10149,
            "r_seats": 5220,
            "cargo_demand": 87827
        }
    },
    {
        "iata": "AWM",
        "icao": "KAWM",
        "airportName": "West Memphis Municipal Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "West Memphis",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.13510132",
        "longitude": "-90.23439789",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6003",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3911,
            "s_seats": 1750,
            "j_seats": 1739,
            "f_seats": 111,
            "r_seats": 107,
            "cargo_demand": 50267
        }
    },
    {
        "iata": "AWZ",
        "icao": "OIAW",
        "airportName": "Ahvaz International Airport",
        "location_country": "Iran",
        "location_city": "Ahvaz",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "31.3374",
        "longitude": "48.762001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "11149",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145878,
            "s_seats": 47936,
            "j_seats": 37778,
            "f_seats": 13388,
            "r_seats": 6809,
            "cargo_demand": 270086
        }
    },
    {
        "iata": "AXA",
        "icao": "TQPF",
        "airportName": "Clayton J. Lloyd International Airport",
        "location_country": "British Overseas Territory of Anguilla",
        "location_city": "The Valley",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.204773",
        "longitude": "-63.05383",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5462",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165734,
            "s_seats": 44646,
            "j_seats": 46828,
            "f_seats": 18433,
            "r_seats": 7368,
            "cargo_demand": 263195
        }
    },
    {
        "iata": "AXC",
        "icao": "YAMC",
        "airportName": "Aramac Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Aramac",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.9666996",
        "longitude": "145.2420044",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1269,
            "s_seats": 1678,
            "j_seats": 1313,
            "f_seats": 330,
            "r_seats": 195,
            "cargo_demand": 36099
        }
    },
    {
        "iata": "AXD",
        "icao": "LGAL",
        "airportName": "Alexandroupoli Airport",
        "location_country": "Greece",
        "location_city": "Alexandroupoli",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "40.8559",
        "longitude": "25.956301",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8471",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175397,
            "s_seats": 46312,
            "j_seats": 40875,
            "f_seats": 2363,
            "r_seats": 3071,
            "cargo_demand": 428038
        }
    },
    {
        "iata": "AXG",
        "icao": "KAXA",
        "airportName": "Algona Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Algona",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.07789993",
        "longitude": "-94.27200317",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3960",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2880",
                "runway_width": "160",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1261,
            "s_seats": 1939,
            "j_seats": 1839,
            "f_seats": 203,
            "r_seats": 33,
            "cargo_demand": 28181
        }
    },
    {
        "iata": "AXJ",
        "icao": "RJDA",
        "airportName": "Amakusa Airfield",
        "location_country": "Japan",
        "location_city": "Amakusa Islands",
        "location_state": "Amakusa",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.482498",
        "longitude": "130.158997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3281",
                "runway_width": "99",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3730,
            "s_seats": 2321,
            "j_seats": 1363,
            "f_seats": 20,
            "r_seats": 11,
            "cargo_demand": 77946
        }
    },
    {
        "iata": "AXK",
        "icao": "OYAT",
        "airportName": "Ataq Airport",
        "location_country": "Yemen",
        "location_city": "Ataq",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.55130005",
        "longitude": "46.82619858",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9482",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189507,
            "s_seats": 31807,
            "j_seats": 20365,
            "f_seats": 10863,
            "r_seats": 5817,
            "cargo_demand": 316251
        }
    },
    {
        "iata": "AXM",
        "icao": "SKAR",
        "airportName": "El Eden International Airport",
        "location_country": "Colombia",
        "location_city": "Armenia",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.45278",
        "longitude": "-75.7664",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7045",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144447,
            "s_seats": 27695,
            "j_seats": 42477,
            "f_seats": 2917,
            "r_seats": 9374,
            "cargo_demand": 368038
        }
    },
    {
        "iata": "AXN",
        "icao": "KAXN",
        "airportName": "Alexandria Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Alexandria",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "45.86629868",
        "longitude": "-95.3946991",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4099",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5100",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118044,
            "s_seats": 42213,
            "j_seats": 54249,
            "f_seats": 17405,
            "r_seats": 7754,
            "cargo_demand": 257807
        }
    },
    {
        "iata": "AXP",
        "icao": "MYAP",
        "airportName": "Spring Point Airport",
        "location_country": "Bahamas",
        "location_city": "Acklins Island",
        "location_state": "Spring Point",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "22.44179916",
        "longitude": "-73.97090149",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114858,
            "s_seats": 44167,
            "j_seats": 53452,
            "f_seats": 6008,
            "r_seats": 4682,
            "cargo_demand": 443888
        }
    },
    {
        "iata": "AXR",
        "icao": "NTGU",
        "airportName": "Arutua Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Arutua",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.2482996",
        "longitude": "-146.6170044",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4268",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165505,
            "s_seats": 31883,
            "j_seats": 15815,
            "f_seats": 14730,
            "r_seats": 5457,
            "cargo_demand": 47267
        }
    },
    {
        "iata": "AXS",
        "icao": "KAXS",
        "airportName": "Quartz Mountain Regional Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Altus",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.697952",
        "longitude": "-99.3385",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5501",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1059,
            "s_seats": 1904,
            "j_seats": 1090,
            "f_seats": 262,
            "r_seats": 121,
            "cargo_demand": 68367
        }
    },
    {
        "iata": "AXT",
        "icao": "RJSK",
        "airportName": "Akita Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Akita",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.61560059",
        "longitude": "140.2189941",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8200",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175721,
            "s_seats": 40029,
            "j_seats": 8217,
            "f_seats": 10835,
            "r_seats": 3410,
            "cargo_demand": 488063
        }
    },
    {
        "iata": "AXU",
        "icao": "HAAX",
        "airportName": "Axum Airport",
        "location_country": "Ethiopia",
        "location_city": "Axum",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.14680004",
        "longitude": "38.77280045",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142320,
            "s_seats": 41382,
            "j_seats": 13057,
            "f_seats": 13424,
            "r_seats": 3448,
            "cargo_demand": 197434
        }
    },
    {
        "iata": "AXV",
        "icao": "KAXV",
        "airportName": "Neil Armstrong Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Wapakoneta",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.49340057",
        "longitude": "-84.29889679",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2685",
                "runway_width": "80",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2100,
            "s_seats": 2453,
            "j_seats": 1565,
            "f_seats": 103,
            "r_seats": 150,
            "cargo_demand": 79764
        }
    },
    {
        "iata": "AXX",
        "icao": "KAXX",
        "airportName": "Angel Fire Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Angel Fire",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.42200089",
        "longitude": "-105.2900009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8900",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1369,
            "s_seats": 2093,
            "j_seats": 1801,
            "f_seats": 54,
            "r_seats": 149,
            "cargo_demand": 31701
        }
    },
    {
        "iata": "AYK",
        "icao": "UAUR",
        "airportName": "Arkalyk Airport",
        "location_country": "Kazakhstan",
        "location_city": "Arkalyk",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "50.3185997",
        "longitude": "66.95279694",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8185",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1640,
            "s_seats": 2230,
            "j_seats": 1347,
            "f_seats": 419,
            "r_seats": 12,
            "cargo_demand": 42391
        }
    },
    {
        "iata": "AYO",
        "icao": "SGAY",
        "airportName": "Juan de Ayolas Airport",
        "location_country": "Paraguay",
        "location_city": "Ayolas",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Mar",
        "airport_size": "medium_airport",
        "latitude": "-27.370554",
        "longitude": "-56.854064",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6070",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114106,
            "s_seats": 29927,
            "j_seats": 59339,
            "f_seats": 2670,
            "r_seats": 7710,
            "cargo_demand": 196707
        }
    },
    {
        "iata": "AYP",
        "icao": "SPHO",
        "airportName": "Coronel FAP Alfredo Mendivil Duarte Airport",
        "location_country": "Peru",
        "location_city": "Ayacucho",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-13.15480042",
        "longitude": "-74.20439911",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114659,
            "s_seats": 29399,
            "j_seats": 45059,
            "f_seats": 6051,
            "r_seats": 9122,
            "cargo_demand": 273853
        }
    },
    {
        "iata": "AYQ",
        "icao": "YAYE",
        "airportName": "Ayers Rock Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Yulara",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.1861",
        "longitude": "130.975998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8527",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50678,
            "s_seats": 43157,
            "j_seats": 25307,
            "f_seats": 14505,
            "r_seats": 8472,
            "cargo_demand": 175608
        }
    },
    {
        "iata": "AYR",
        "icao": "YAYR",
        "airportName": "Ayr Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Ayr",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-19.595832",
        "longitude": "147.324495",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4796",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3697",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3828,
            "s_seats": 2578,
            "j_seats": 1324,
            "f_seats": 167,
            "r_seats": 36,
            "cargo_demand": 3903
        }
    },
    {
        "iata": "AYS",
        "icao": "KAYS",
        "airportName": "Waycross-Ware County Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Waycross",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.24909973",
        "longitude": "-82.39550018",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5035",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3528",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1530,
            "s_seats": 2307,
            "j_seats": 1745,
            "f_seats": 85,
            "r_seats": 144,
            "cargo_demand": 85104
        }
    },
    {
        "iata": "AYT",
        "icao": "LTAI",
        "airportName": "Antalya Airport",
        "location_country": "Turkey",
        "location_city": "Antalya",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.898701",
        "longitude": "30.800501",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18C/36C",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "concrete"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "9811",
                "runway_width": "148",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 256533,
            "s_seats": 95528,
            "j_seats": 144072,
            "f_seats": 53790,
            "r_seats": 10000,
            "cargo_demand": 13389567
        }
    },
    {
        "iata": "AYX",
        "icao": "SPAY",
        "airportName": "Tnte. Gral. Gerardo Perez Pinedo Airport",
        "location_country": "Peru",
        "location_city": "Department of Ucayali",
        "location_state": "Atalaya Province",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-10.7291",
        "longitude": "-73.766502",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4935",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116500,
            "s_seats": 40411,
            "j_seats": 17037,
            "f_seats": 11031,
            "r_seats": 3868,
            "cargo_demand": 491999
        }
    },
    {
        "iata": "AZA",
        "icao": "KIWA",
        "airportName": "Phoenix-Mesa Gateway Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Phoenix",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.3078",
        "longitude": "-111.654999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12C/30C",
                "runway_length": "10201",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "9301",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "10401",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 142438,
            "s_seats": 45381,
            "j_seats": 51267,
            "f_seats": 7585,
            "r_seats": 9553,
            "cargo_demand": 414744
        }
    },
    {
        "iata": "AZD",
        "icao": "OIYY",
        "airportName": "Shahid Sadooghi Airport",
        "location_country": "Iran",
        "location_city": "Yazd",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "31.9048996",
        "longitude": "54.2765007",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "13446",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104996,
            "s_seats": 37984,
            "j_seats": 34478,
            "f_seats": 17060,
            "r_seats": 4459,
            "cargo_demand": 232401
        }
    },
    {
        "iata": "AZI",
        "icao": "OMAD",
        "airportName": "Al Bateen Executive Airport",
        "location_country": "United Arab Emirates",
        "location_city": "Abu Dhabi",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.428301",
        "longitude": "54.458099",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10499",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125452,
            "s_seats": 34812,
            "j_seats": 19591,
            "f_seats": 5107,
            "r_seats": 7851,
            "cargo_demand": 233096
        }
    },
    {
        "iata": "AZL",
        "icao": "SWTU",
        "airportName": "Fazenda Tucunare Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Sapezal",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-13.465528",
        "longitude": "-58.866935",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "4921",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2779,
            "s_seats": 1547,
            "j_seats": 1812,
            "f_seats": 370,
            "r_seats": 8,
            "cargo_demand": 94855
        }
    },
    {
        "iata": "AZO",
        "icao": "KAZO",
        "airportName": "Kalamazoo/Battle Creek International Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Kalamazoo",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.23490143",
        "longitude": "-85.55210114",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3436",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2800",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144612,
            "s_seats": 25614,
            "j_seats": 17494,
            "f_seats": 4938,
            "r_seats": 7278,
            "cargo_demand": 81208
        }
    },
    {
        "iata": "AZP",
        "icao": "MMJC",
        "airportName": "Jorge Jimenez Cantu National Airport",
        "location_country": "Mexico",
        "location_city": "State of Mexico",
        "location_state": "Ciudad Lopez Mateos",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "small_airport",
        "latitude": "19.5748",
        "longitude": "-99.288803",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4265",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2361,
            "s_seats": 2667,
            "j_seats": 1188,
            "f_seats": 63,
            "r_seats": 107,
            "cargo_demand": 58164
        }
    },
    {
        "iata": "AZR",
        "icao": "DAUA",
        "airportName": "Touat-Cheikh Sidi Mohamed Belkebir Airport",
        "location_country": "Algeria",
        "location_city": "Adrar",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.837601",
        "longitude": "-0.186414",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111591,
            "s_seats": 39415,
            "j_seats": 38554,
            "f_seats": 12220,
            "r_seats": 9731,
            "cargo_demand": 293915
        }
    },
    {
        "iata": "AZS",
        "icao": "MDCY",
        "airportName": "Samana El Catey International Airport",
        "location_country": "Dominican Republic",
        "location_city": "El Catey",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.2670002",
        "longitude": "-69.74199677",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 147195,
            "s_seats": 36250,
            "j_seats": 34042,
            "f_seats": 5683,
            "r_seats": 9839,
            "cargo_demand": 402471
        }
    },
    {
        "iata": "BAB",
        "icao": "KBAB",
        "airportName": "Beale Air Force Base",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Marysville",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "39.13610077",
        "longitude": "-121.4369965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "12000",
                "runway_width": "300",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 180941,
            "s_seats": 74196,
            "j_seats": 106367,
            "f_seats": 54397,
            "r_seats": 10000,
            "cargo_demand": 5322927
        }
    },
    {
        "iata": "BAD",
        "icao": "KBAD",
        "airportName": "Barksdale Air Force Base",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Bossier City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "32.50180054",
        "longitude": "-93.66269684",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "11756",
                "runway_width": "300",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 290546,
            "s_seats": 77464,
            "j_seats": 121611,
            "f_seats": 50114,
            "r_seats": 10000,
            "cargo_demand": 20320678
        }
    },
    {
        "iata": "BAE",
        "icao": "LFMR",
        "airportName": "Barcelonnette - Saint-Pons Airport",
        "location_country": "France",
        "location_city": "Alpes-de-Haute-Provence",
        "location_state": "Barcelonnette",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "44.387198",
        "longitude": "6.609186",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2625",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3385,
            "s_seats": 1598,
            "j_seats": 1027,
            "f_seats": 160,
            "r_seats": 158,
            "cargo_demand": 81568
        }
    },
    {
        "iata": "BAF",
        "icao": "KBAF",
        "airportName": "Westfield-Barnes Regional Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Westfield / Springfield",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.157799",
        "longitude": "-72.715599",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56976,
            "s_seats": 32100,
            "j_seats": 22928,
            "f_seats": 13728,
            "r_seats": 6589,
            "cargo_demand": 192828
        }
    },
    {
        "iata": "BAG",
        "icao": "RPUB",
        "airportName": "Loakan Airport",
        "location_country": "Philippines",
        "location_city": "Baguio",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.37509918",
        "longitude": "120.6200027",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5912",
                "runway_width": "115",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 93600,
            "s_seats": 37086,
            "j_seats": 58819,
            "f_seats": 14328,
            "r_seats": 4313,
            "cargo_demand": 257386
        }
    },
    {
        "iata": "BAH",
        "icao": "OBBI",
        "airportName": "Bahrain International Airport",
        "location_country": "Bahrain",
        "location_city": "Manama",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "26.267295",
        "longitude": "50.63764",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12L/30R",
                "runway_length": "12979",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "8302",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 470959,
            "s_seats": 80839,
            "j_seats": 91115,
            "f_seats": 69852,
            "r_seats": 10000,
            "cargo_demand": 13356567
        }
    },
    {
        "iata": "BAI",
        "icao": "MRBA",
        "airportName": "Buenos Aires Airport",
        "location_country": "Costa Rica",
        "location_city": "Buenos Aires",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.163949",
        "longitude": "-83.330171",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3248",
                "runway_width": "33",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 90442,
            "s_seats": 33163,
            "j_seats": 26093,
            "f_seats": 13061,
            "r_seats": 7999,
            "cargo_demand": 426536
        }
    },
    {
        "iata": "BAL",
        "icao": "LTCJ",
        "airportName": "Batman Airport",
        "location_country": "Turkey",
        "location_city": "Batman",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.92900085",
        "longitude": "41.11660004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 158496,
            "s_seats": 41937,
            "j_seats": 18346,
            "f_seats": 10074,
            "r_seats": 4626,
            "cargo_demand": 197538
        }
    },
    {
        "iata": "BAM",
        "icao": "KBAM",
        "airportName": "Battle Mountain Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Battle Mountain",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.59899902",
        "longitude": "-116.8740005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7299",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2437,
            "s_seats": 1688,
            "j_seats": 1232,
            "f_seats": 197,
            "r_seats": 82,
            "cargo_demand": 7222
        }
    },
    {
        "iata": "BAN",
        "icao": "FZVR",
        "airportName": "Basongo Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Basongo",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-4.315802",
        "longitude": "20.414891",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3230,
            "s_seats": 2771,
            "j_seats": 1050,
            "f_seats": 491,
            "r_seats": 121,
            "cargo_demand": 37853
        }
    },
    {
        "iata": "BAQ",
        "icao": "SKBQ",
        "airportName": "Ernesto Cortissoz International Airport",
        "location_country": "Colombia",
        "location_city": "Barranquilla",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.8896",
        "longitude": "-74.7808",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 150087,
            "s_seats": 31753,
            "j_seats": 17631,
            "f_seats": 9194,
            "r_seats": 9008,
            "cargo_demand": 345462
        }
    },
    {
        "iata": "BAS",
        "icao": "AGGE",
        "airportName": "Balalae Airport",
        "location_country": "Solomon Islands",
        "location_city": "Western",
        "location_state": "Balalae Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.990745",
        "longitude": "155.886656",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5413",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3524,
            "s_seats": 2275,
            "j_seats": 1431,
            "f_seats": 210,
            "r_seats": 125,
            "cargo_demand": 96317
        }
    },
    {
        "iata": "BAT",
        "icao": "SBBT",
        "airportName": "Chafei Amsei Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Barretos",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-20.58449936",
        "longitude": "-48.59410095",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137413,
            "s_seats": 41520,
            "j_seats": 16151,
            "f_seats": 10118,
            "r_seats": 6251,
            "cargo_demand": 459971
        }
    },
    {
        "iata": "BAX",
        "icao": "UNBB",
        "airportName": "Barnaul Airport",
        "location_country": "Russia",
        "location_city": "Altai Krai",
        "location_state": "Barnaul",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.36380005",
        "longitude": "83.53849792",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9350",
                "runway_width": "164",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 151793,
            "s_seats": 27463,
            "j_seats": 13487,
            "f_seats": 17504,
            "r_seats": 3058,
            "cargo_demand": 110447
        }
    },
    {
        "iata": "BAY",
        "icao": "LRBM",
        "airportName": "Baia Mare Airport",
        "location_country": "Romania",
        "location_city": "Baia Mare",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.660598",
        "longitude": "23.467252",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7054",
                "runway_width": "148",
                "runway_material": "ASPHALT"
            }
        ],
        "demand": {
            "y_seats": 128000,
            "s_seats": 38217,
            "j_seats": 27309,
            "f_seats": 11752,
            "r_seats": 6257,
            "cargo_demand": 271572
        }
    },
    {
        "iata": "BAZ",
        "icao": "SWBC",
        "airportName": "Barcelos Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Barcelos",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.981292",
        "longitude": "-62.919601",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3028,
            "s_seats": 2125,
            "j_seats": 1834,
            "f_seats": 44,
            "r_seats": 165,
            "cargo_demand": 31449
        }
    },
    {
        "iata": "BBA",
        "icao": "SCBA",
        "airportName": "Balmaceda Airport",
        "location_country": "Chile",
        "location_city": "Balmaceda",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-45.91609955",
        "longitude": "-71.6894989",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8205",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127510,
            "s_seats": 30370,
            "j_seats": 39795,
            "f_seats": 15522,
            "r_seats": 6250,
            "cargo_demand": 317996
        }
    },
    {
        "iata": "BBB",
        "icao": "KBBB",
        "airportName": "Benson Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Benson",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.33190155",
        "longitude": "-95.65059662",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1825,
            "s_seats": 2653,
            "j_seats": 1731,
            "f_seats": 201,
            "r_seats": 93,
            "cargo_demand": 97483
        }
    },
    {
        "iata": "BBC",
        "icao": "KBYY",
        "airportName": "Bay City Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Bay City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "28.973301",
        "longitude": "-95.863503",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5107",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2113,
            "s_seats": 1653,
            "j_seats": 1691,
            "f_seats": 415,
            "r_seats": 107,
            "cargo_demand": 18436
        }
    },
    {
        "iata": "BBD",
        "icao": "KBBD",
        "airportName": "Curtis Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Brady",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.17930031",
        "longitude": "-99.32389832",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3520",
                "runway_width": "110",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4604",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163584,
            "s_seats": 35975,
            "j_seats": 6243,
            "f_seats": 14361,
            "r_seats": 9024,
            "cargo_demand": 266450
        }
    },
    {
        "iata": "BBH",
        "icao": "EDBH",
        "airportName": "Stralsund-Barth Airport",
        "location_country": "Germany",
        "location_city": "Mecklenburg-Vorpommern",
        "location_state": "Stralsund / Barth",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "54.338253",
        "longitude": "12.710515",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "2953",
                "runway_width": "131",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2807,
            "s_seats": 1769,
            "j_seats": 1161,
            "f_seats": 271,
            "r_seats": 50,
            "cargo_demand": 98420
        }
    },
    {
        "iata": "BBI",
        "icao": "VEBS",
        "airportName": "Biju Patnaik International Airport",
        "location_country": "India",
        "location_city": "Odisha",
        "location_state": "Bhubaneswar",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.24440002",
        "longitude": "85.81780243",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4524",
                "runway_width": "150",
                "runway_material": "BIT"
            },
            {
                "runway_name": "14/32",
                "runway_length": "7359",
                "runway_width": "150",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 161062,
            "s_seats": 39234,
            "j_seats": 40983,
            "f_seats": 9488,
            "r_seats": 9531,
            "cargo_demand": 232734
        }
    },
    {
        "iata": "BBJ",
        "icao": "EDRB",
        "airportName": "Bitburg Airport",
        "location_country": "Germany",
        "location_city": "Rhineland-Palatinate",
        "location_state": "Bitburg",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "49.945278",
        "longitude": "6.565",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10026",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2257,
            "s_seats": 2601,
            "j_seats": 1636,
            "f_seats": 372,
            "r_seats": 30,
            "cargo_demand": 2910
        }
    },
    {
        "iata": "BBK",
        "icao": "FBKE",
        "airportName": "Kasane Airport",
        "location_country": "Botswana",
        "location_city": "Kasane",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.83289909",
        "longitude": "25.16239929",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138863,
            "s_seats": 26542,
            "j_seats": 51597,
            "f_seats": 4058,
            "r_seats": 7590,
            "cargo_demand": 223892
        }
    },
    {
        "iata": "BBL",
        "icao": "YLLE",
        "airportName": "Ballera Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Ballera gas plant",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.405633",
        "longitude": "141.809458",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1768,
            "s_seats": 1611,
            "j_seats": 1911,
            "f_seats": 347,
            "r_seats": 180,
            "cargo_demand": 67983
        }
    },
    {
        "iata": "BBM",
        "icao": "VDBG",
        "airportName": "Battambang Airport",
        "location_country": "Cambodia",
        "location_city": "Battambang",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.09560013",
        "longitude": "103.223999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5250",
                "runway_width": "112",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168509,
            "s_seats": 25280,
            "j_seats": 25411,
            "f_seats": 2339,
            "r_seats": 4383,
            "cargo_demand": 258933
        }
    },
    {
        "iata": "BBN",
        "icao": "WBGZ",
        "airportName": "Bario Airport",
        "location_country": "Malaysia",
        "location_city": "Sarawak",
        "location_state": "Bario",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.733890057",
        "longitude": "115.4789963",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2198",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 179818,
            "s_seats": 32519,
            "j_seats": 41488,
            "f_seats": 11678,
            "r_seats": 7592,
            "cargo_demand": 474373
        }
    },
    {
        "iata": "BBO",
        "icao": "HCMI",
        "airportName": "Berbera Airport",
        "location_country": "Somalia",
        "location_city": "Berbera",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.385035",
        "longitude": "44.936723",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "13582",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184784,
            "s_seats": 36832,
            "j_seats": 33066,
            "f_seats": 17899,
            "r_seats": 7737,
            "cargo_demand": 283355
        }
    },
    {
        "iata": "BBP",
        "icao": "EGHJ",
        "airportName": "Bembridge Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Bembridge",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "50.67810059",
        "longitude": "-1.109439969",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6027",
                "runway_width": "75",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 1272,
            "s_seats": 1684,
            "j_seats": 1437,
            "f_seats": 142,
            "r_seats": 143,
            "cargo_demand": 58514
        }
    },
    {
        "iata": "BBS",
        "icao": "EGLK",
        "airportName": "Blackbushe Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Yateley",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.32389832",
        "longitude": "-0.847500026",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4380",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125198,
            "s_seats": 25911,
            "j_seats": 43964,
            "f_seats": 2988,
            "r_seats": 6491,
            "cargo_demand": 180989
        }
    },
    {
        "iata": "BBT",
        "icao": "FEFT",
        "airportName": "Berberati Airport",
        "location_country": "Central African Republic",
        "location_city": "Berberati",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.221580029",
        "longitude": "15.78639984",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5446",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90600,
            "s_seats": 39472,
            "j_seats": 57260,
            "f_seats": 16058,
            "r_seats": 3362,
            "cargo_demand": 388316
        }
    },
    {
        "iata": "BBU",
        "icao": "LRBS",
        "airportName": "Aurel Vlaicu International Airport",
        "location_country": "Romania",
        "location_city": "Bucharest",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "44.503201",
        "longitude": "26.1021",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 147256,
            "s_seats": 47852,
            "j_seats": 5464,
            "f_seats": 16034,
            "r_seats": 7852,
            "cargo_demand": 318631
        }
    },
    {
        "iata": "BBW",
        "icao": "KBBW",
        "airportName": "Broken Bow Municipal Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Broken Bow",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.43650055",
        "longitude": "-99.64219666",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4203",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3904,
            "s_seats": 1860,
            "j_seats": 1476,
            "f_seats": 500,
            "r_seats": 26,
            "cargo_demand": 88504
        }
    },
    {
        "iata": "BBX",
        "icao": "KLOM",
        "airportName": "Wings Field",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Philadelphia",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.13750076",
        "longitude": "-75.26509857",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3700",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2388,
            "s_seats": 2464,
            "j_seats": 1631,
            "f_seats": 191,
            "r_seats": 11,
            "cargo_demand": 83143
        }
    },
    {
        "iata": "BCA",
        "icao": "MUBA",
        "airportName": "Gustavo Rizo Airport",
        "location_country": "Cuba",
        "location_city": "Baracoa",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "20.36529922",
        "longitude": "-74.5062027",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6070",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 63831,
            "s_seats": 32112,
            "j_seats": 12731,
            "f_seats": 10982,
            "r_seats": 7227,
            "cargo_demand": 216765
        }
    },
    {
        "iata": "BCB",
        "icao": "KBCB",
        "airportName": "Virginia Tech Montgomery Executive Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Blacksburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.20759964",
        "longitude": "-80.40779877",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2850",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4539",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1157,
            "s_seats": 2990,
            "j_seats": 1281,
            "f_seats": 183,
            "r_seats": 60,
            "cargo_demand": 6996
        }
    },
    {
        "iata": "BCD",
        "icao": "RPVB",
        "airportName": "Bacolod-Silay International Airport",
        "location_country": "Philippines",
        "location_city": "Bacolod",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.7764",
        "longitude": "123.014999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 67426,
            "s_seats": 36948,
            "j_seats": 54997,
            "f_seats": 4944,
            "r_seats": 6762,
            "cargo_demand": 487308
        }
    },
    {
        "iata": "BCE",
        "icao": "KBCE",
        "airportName": "Bryce Canyon Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Bryce Canyon",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.70640183",
        "longitude": "-112.1449966",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7395",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82742,
            "s_seats": 44550,
            "j_seats": 44444,
            "f_seats": 15302,
            "r_seats": 4891,
            "cargo_demand": 459961
        }
    },
    {
        "iata": "BCH",
        "icao": "WPEC",
        "airportName": "Baucau Airport",
        "location_country": "East Timor",
        "location_city": "Baucau",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.489029884",
        "longitude": "126.401001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8233",
                "runway_width": "182",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191541,
            "s_seats": 34498,
            "j_seats": 55822,
            "f_seats": 17341,
            "r_seats": 9714,
            "cargo_demand": 147371
        }
    },
    {
        "iata": "BCI",
        "icao": "YBAR",
        "airportName": "Barcaldine Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Barcaldine",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.56529999",
        "longitude": "145.3070068",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5591",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3104",
                "runway_width": "98",
                "runway_material": "CLA"
            }
        ],
        "demand": {
            "y_seats": 55159,
            "s_seats": 40826,
            "j_seats": 15341,
            "f_seats": 19748,
            "r_seats": 9596,
            "cargo_demand": 121656
        }
    },
    {
        "iata": "BCK",
        "icao": "YBWR",
        "airportName": "Bolwarra Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Bolwarra",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.38829994",
        "longitude": "144.1690063",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4265",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 2705,
            "s_seats": 2895,
            "j_seats": 1226,
            "f_seats": 58,
            "r_seats": 189,
            "cargo_demand": 10065
        }
    },
    {
        "iata": "BCL",
        "icao": "MRBC",
        "airportName": "Barra del Colorado Airport",
        "location_country": "Costa Rica",
        "location_city": "Barra del Colorado",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.76869965",
        "longitude": "-83.58560181",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3281",
                "runway_width": "39",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151079,
            "s_seats": 29720,
            "j_seats": 47366,
            "f_seats": 5090,
            "r_seats": 7158,
            "cargo_demand": 99367
        }
    },
    {
        "iata": "BCM",
        "icao": "LRBC",
        "airportName": "Bac\u0103u International Airport",
        "location_country": "Romania",
        "location_city": "Bac\u0103u",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.52190018",
        "longitude": "26.9102993",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8203",
                "runway_width": "262",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 173647,
            "s_seats": 33006,
            "j_seats": 19399,
            "f_seats": 12730,
            "r_seats": 3879,
            "cargo_demand": 258630
        }
    },
    {
        "iata": "BCN",
        "icao": "LEBL",
        "airportName": "Barcelona-El Prat Airport",
        "location_country": "Spain",
        "location_city": "Catalonia",
        "location_state": "Barcelona",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "41.2971",
        "longitude": "2.07846",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8294",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "10997",
                "runway_width": "196",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "8727",
                "runway_width": "196",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 107267,
            "s_seats": 82957,
            "j_seats": 168239,
            "f_seats": 25437,
            "r_seats": 10000,
            "cargo_demand": 29251534
        }
    },
    {
        "iata": "BCR",
        "icao": "SWNK",
        "airportName": "Novo Campo Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Boca do Acre",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.83456",
        "longitude": "-67.312401",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5249",
                "runway_width": "108",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3914,
            "s_seats": 1633,
            "j_seats": 1544,
            "f_seats": 259,
            "r_seats": 145,
            "cargo_demand": 49896
        }
    },
    {
        "iata": "BCT",
        "icao": "KBCT",
        "airportName": "Boca Raton Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Boca Raton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "26.37849998",
        "longitude": "-80.10769653",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6276",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1181,
            "s_seats": 2538,
            "j_seats": 1232,
            "f_seats": 104,
            "r_seats": 39,
            "cargo_demand": 76646
        }
    },
    {
        "iata": "BDA",
        "icao": "TXKF",
        "airportName": "L.F. Wade International Airport",
        "location_country": "British Overseas Territory of Bermuda",
        "location_city": "Hamilton",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.36399841",
        "longitude": "-64.67870331",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9705",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110640,
            "s_seats": 41721,
            "j_seats": 29596,
            "f_seats": 17664,
            "r_seats": 8671,
            "cargo_demand": 231352
        }
    },
    {
        "iata": "BDB",
        "icao": "YBUD",
        "airportName": "Bundaberg Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Bundaberg",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-24.90390015",
        "longitude": "152.3190002",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3701",
                "runway_width": "98",
                "runway_material": "CLA"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5030",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148904,
            "s_seats": 31154,
            "j_seats": 47826,
            "f_seats": 18850,
            "r_seats": 5863,
            "cargo_demand": 163560
        }
    },
    {
        "iata": "BDC",
        "icao": "SNBC",
        "airportName": "Barra do Corda Airport",
        "location_country": "Brazil",
        "location_city": "Maranhio",
        "location_state": "Barra do Corda",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.5025",
        "longitude": "-45.215833",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "5085",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3260,
            "s_seats": 2807,
            "j_seats": 1165,
            "f_seats": 190,
            "r_seats": 193,
            "cargo_demand": 5935
        }
    },
    {
        "iata": "BDD",
        "icao": "YBAU",
        "airportName": "Badu Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Badu Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.14999962",
        "longitude": "142.1734",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2788",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1040,
            "s_seats": 2816,
            "j_seats": 1377,
            "f_seats": 494,
            "r_seats": 134,
            "cargo_demand": 56589
        }
    },
    {
        "iata": "BDE",
        "icao": "KBDE",
        "airportName": "Baudette International Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Baudette",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "48.72840118",
        "longitude": "-94.61219788",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5499",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13W/31W",
                "runway_length": "6000",
                "runway_width": "1200",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 158561,
            "s_seats": 27460,
            "j_seats": 12113,
            "f_seats": 8107,
            "r_seats": 5788,
            "cargo_demand": 444569
        }
    },
    {
        "iata": "BDG",
        "icao": "KBDG",
        "airportName": "Blanding Municipal Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Blanding",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.58330154",
        "longitude": "-109.4830017",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5781",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1058,
            "s_seats": 1707,
            "j_seats": 1526,
            "f_seats": 17,
            "r_seats": 126,
            "cargo_demand": 13690
        }
    },
    {
        "iata": "BDH",
        "icao": "OIBL",
        "airportName": "Bandar Lengeh Airport",
        "location_country": "Iran",
        "location_city": "Bandar Lengeh",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "26.53199959",
        "longitude": "54.82479858",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8203",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161853,
            "s_seats": 35744,
            "j_seats": 35854,
            "f_seats": 6055,
            "r_seats": 7949,
            "cargo_demand": 319758
        }
    },
    {
        "iata": "BDJ",
        "icao": "WAOO",
        "airportName": "Syamsudin Noor Airport",
        "location_country": "Indonesia",
        "location_city": "Banjarmasin",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.44236",
        "longitude": "114.763",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1164,
            "s_seats": 2824,
            "j_seats": 1856,
            "f_seats": 333,
            "r_seats": 37,
            "cargo_demand": 74182
        }
    },
    {
        "iata": "BDK",
        "icao": "DIBU",
        "airportName": "Soko Airport",
        "location_country": "Ivory Coast",
        "location_city": "Bondoukou",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "8.017219543",
        "longitude": "-2.761940002",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2269,
            "s_seats": 1777,
            "j_seats": 1852,
            "f_seats": 27,
            "r_seats": 86,
            "cargo_demand": 52510
        }
    },
    {
        "iata": "BDL",
        "icao": "KBDL",
        "airportName": "Bradley International Airport",
        "location_country": "United States",
        "location_city": "Connecticut",
        "location_state": "Windsor Locks / Hartford",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "41.93889999",
        "longitude": "-72.68319702",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5145",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "9510",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6847",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184285,
            "s_seats": 83563,
            "j_seats": 31993,
            "f_seats": 38860,
            "r_seats": 10000,
            "cargo_demand": 11796998
        }
    },
    {
        "iata": "BDM",
        "icao": "LTBG",
        "airportName": "Bandirma Airport",
        "location_country": "Turkey",
        "location_city": "Bandirma",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.318001",
        "longitude": "27.977699",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9865",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153855,
            "s_seats": 30139,
            "j_seats": 43932,
            "f_seats": 5208,
            "r_seats": 5484,
            "cargo_demand": 98639
        }
    },
    {
        "iata": "BDN",
        "icao": "OPTH",
        "airportName": "Talhar Airport",
        "location_country": "Pakistan",
        "location_city": "Badin",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "24.84149933",
        "longitude": "68.83840179",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8968",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1263,
            "s_seats": 2323,
            "j_seats": 1199,
            "f_seats": 34,
            "r_seats": 37,
            "cargo_demand": 94932
        }
    },
    {
        "iata": "BDO",
        "icao": "WICC",
        "airportName": "Husein Sastranegara International Airport",
        "location_country": "Indonesia",
        "location_city": "Bandung",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.900629997",
        "longitude": "107.5759964",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7361",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67650,
            "s_seats": 44544,
            "j_seats": 26514,
            "f_seats": 5664,
            "r_seats": 3253,
            "cargo_demand": 378048
        }
    },
    {
        "iata": "BDP",
        "icao": "VNCG",
        "airportName": "Bhadrapur Airport",
        "location_country": "Nepal",
        "location_city": "Bhadrapur / Chandragadhi",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "26.57080078",
        "longitude": "88.07959747",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3965",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3334,
            "s_seats": 1522,
            "j_seats": 1415,
            "f_seats": 22,
            "r_seats": 37,
            "cargo_demand": 58657
        }
    },
    {
        "iata": "BDQ",
        "icao": "VABO",
        "airportName": "Vadodara Airport",
        "location_country": "India",
        "location_city": "Gujarat",
        "location_state": "Vadodara",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.336201",
        "longitude": "73.226303",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8100",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 84239,
            "s_seats": 49649,
            "j_seats": 30130,
            "f_seats": 7392,
            "r_seats": 9352,
            "cargo_demand": 393906
        }
    },
    {
        "iata": "BDR",
        "icao": "KBDR",
        "airportName": "Igor I. Sikorsky Memorial Airport",
        "location_country": "United States",
        "location_city": "Connecticut",
        "location_state": "Bridgeport",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.16350174",
        "longitude": "-73.12619781",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4677",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4761",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102523,
            "s_seats": 32230,
            "j_seats": 6281,
            "f_seats": 18189,
            "r_seats": 9311,
            "cargo_demand": 168507
        }
    },
    {
        "iata": "BDS",
        "icao": "LIBR",
        "airportName": "Brindisi - Salento Airport",
        "location_country": "Italy",
        "location_city": "Apulia",
        "location_state": "Brindisi",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "40.6576",
        "longitude": "17.947001",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5892",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "10000",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70994,
            "s_seats": 29680,
            "j_seats": 49208,
            "f_seats": 16951,
            "r_seats": 6551,
            "cargo_demand": 379291
        }
    },
    {
        "iata": "BDT",
        "icao": "FZFD",
        "airportName": "Gbadolite Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Gbadolite",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.25321",
        "longitude": "20.9753",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10499",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185939,
            "s_seats": 28685,
            "j_seats": 5797,
            "f_seats": 16446,
            "r_seats": 5803,
            "cargo_demand": 368007
        }
    },
    {
        "iata": "BDU",
        "icao": "ENDU",
        "airportName": "Bardufoss Airport",
        "location_country": "Norway",
        "location_city": "Bardufoss",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "69.05580139",
        "longitude": "18.54039955",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8015",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117783,
            "s_seats": 47315,
            "j_seats": 17111,
            "f_seats": 14282,
            "r_seats": 8165,
            "cargo_demand": 54583
        }
    },
    {
        "iata": "BDW",
        "icao": "YBDF",
        "airportName": "Bedford Downs Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Bedford Downs",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.2866993",
        "longitude": "127.4629974",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4002",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3027,
            "s_seats": 2761,
            "j_seats": 1008,
            "f_seats": 216,
            "r_seats": 189,
            "cargo_demand": 85494
        }
    },
    {
        "iata": "BEB",
        "icao": "EGPL",
        "airportName": "Benbecula Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Benbecula",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "57.48109818",
        "longitude": "-7.362780094",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6024",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4003",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 98174,
            "s_seats": 31787,
            "j_seats": 46218,
            "f_seats": 18915,
            "r_seats": 3760,
            "cargo_demand": 125634
        }
    },
    {
        "iata": "BEC",
        "icao": "KBEC",
        "airportName": "Beech Factory Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Wichita",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.69449997",
        "longitude": "-97.21499634",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8000",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1218,
            "s_seats": 1676,
            "j_seats": 1689,
            "f_seats": 184,
            "r_seats": 94,
            "cargo_demand": 95457
        }
    },
    {
        "iata": "BED",
        "icao": "KBED",
        "airportName": "Laurence G. Hanscom Field",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Bedford",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.47000122",
        "longitude": "-71.28900146",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5106",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "7001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129660,
            "s_seats": 31917,
            "j_seats": 43021,
            "f_seats": 16487,
            "r_seats": 6163,
            "cargo_demand": 200756
        }
    },
    {
        "iata": "BEE",
        "icao": "YBGB",
        "airportName": "Beagle Bay Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Beagle Bay",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.0165",
        "longitude": "122.6464",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 2501,
            "s_seats": 2712,
            "j_seats": 1888,
            "f_seats": 329,
            "r_seats": 49,
            "cargo_demand": 46981
        }
    },
    {
        "iata": "BEF",
        "icao": "MNBL",
        "airportName": "Bluefields Airport",
        "location_country": "Nicaragua",
        "location_city": "Bluefields",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.99100018",
        "longitude": "-83.77410126",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6625",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145424,
            "s_seats": 48113,
            "j_seats": 10054,
            "f_seats": 11637,
            "r_seats": 5222,
            "cargo_demand": 332696
        }
    },
    {
        "iata": "BEG",
        "icao": "LYBE",
        "airportName": "Belgrade Nikola Tesla Airport",
        "location_country": "Serbia",
        "location_city": "Belgrade",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "44.81840134",
        "longitude": "20.3090992",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 406455,
            "s_seats": 88491,
            "j_seats": 61923,
            "f_seats": 55860,
            "r_seats": 10000,
            "cargo_demand": 28085195
        }
    },
    {
        "iata": "BEH",
        "icao": "KBEH",
        "airportName": "Southwest Michigan Regional Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Benton Harbor",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.12860107",
        "longitude": "-86.42849731",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5109",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3662",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2498",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2806,
            "s_seats": 2255,
            "j_seats": 1431,
            "f_seats": 440,
            "r_seats": 174,
            "cargo_demand": 19013
        }
    },
    {
        "iata": "BEJ",
        "icao": "WALK",
        "airportName": "Kalimarau Airport",
        "location_country": "Indonesia",
        "location_city": "Tanjung Redeb",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.1555",
        "longitude": "117.431999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4625",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100265,
            "s_seats": 27949,
            "j_seats": 51471,
            "f_seats": 3424,
            "r_seats": 3936,
            "cargo_demand": 97419
        }
    },
    {
        "iata": "BEK",
        "icao": "VIBY",
        "airportName": "Bareilly Airport",
        "location_country": "India",
        "location_city": "Uttar Pradesh",
        "location_state": "Bareilly",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.42210007",
        "longitude": "79.45079803",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9000",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 191434,
            "s_seats": 27882,
            "j_seats": 21758,
            "f_seats": 9612,
            "r_seats": 6021,
            "cargo_demand": 393112
        }
    },
    {
        "iata": "BEL",
        "icao": "SBBE",
        "airportName": "Val de Cans International Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Belem",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-1.379279",
        "longitude": "-48.476207",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6004",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "6/24",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "7/20",
                "runway_length": "108",
                "runway_width": "108",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 377611,
            "s_seats": 75313,
            "j_seats": 97388,
            "f_seats": 48402,
            "r_seats": 10000,
            "cargo_demand": 22375481
        }
    },
    {
        "iata": "BEM",
        "icao": "GMMD",
        "airportName": "Beni Mellal Airport",
        "location_country": "Morocco",
        "location_city": "Beni Mellal",
        "location_state": "",
        "time_zone": "UTC+00:00",
        "daylight_saving": "Mar-Oct1",
        "airport_size": "small_airport",
        "latitude": "32.401895",
        "longitude": "-6.315905",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8180",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2988,
            "s_seats": 1634,
            "j_seats": 1708,
            "f_seats": 434,
            "r_seats": 115,
            "cargo_demand": 48866
        }
    },
    {
        "iata": "BEN",
        "icao": "HLLB",
        "airportName": "Benina International Airport",
        "location_country": "Libya",
        "location_city": "Benghazi",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.09680176",
        "longitude": "20.26950073",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15L/33R",
                "runway_length": "11732",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "11731",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138185,
            "s_seats": 35005,
            "j_seats": 44934,
            "f_seats": 1393,
            "r_seats": 8810,
            "cargo_demand": 165487
        }
    },
    {
        "iata": "BEO",
        "icao": "YLMQ",
        "airportName": "Lake Macquarie Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Newcastle",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-33.065975",
        "longitude": "151.646283",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2204",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 2269,
            "s_seats": 1979,
            "j_seats": 1159,
            "f_seats": 358,
            "r_seats": 5,
            "cargo_demand": 78049
        }
    },
    {
        "iata": "BEP",
        "icao": "VOBI",
        "airportName": "Bellary Airport",
        "location_country": "India",
        "location_city": "Karnataka",
        "location_state": "Bellary",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.16279984",
        "longitude": "76.88279724",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3630",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146933,
            "s_seats": 33683,
            "j_seats": 33699,
            "f_seats": 12843,
            "r_seats": 6470,
            "cargo_demand": 456495
        }
    },
    {
        "iata": "BEQ",
        "icao": "EGXH",
        "airportName": "RAF Honington",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Bury St Edmunds",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "52.34260178",
        "longitude": "0.772939026",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9012",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53280,
            "s_seats": 41205,
            "j_seats": 17566,
            "f_seats": 17777,
            "r_seats": 9808,
            "cargo_demand": 244480
        }
    },
    {
        "iata": "BER",
        "icao": "EDDB",
        "airportName": "Berlin Brandenburg Airport",
        "location_country": "Germany",
        "location_city": "Berlin",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "52.351389",
        "longitude": "13.493889",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "CONC"
            }
        ],
        "demand": {
            "y_seats": 313906,
            "s_seats": 77212,
            "j_seats": 92346,
            "f_seats": 49216,
            "r_seats": 10000,
            "cargo_demand": 7868459
        }
    },
    {
        "iata": "BES",
        "icao": "LFRB",
        "airportName": "Brest Bretagne Airport",
        "location_country": "France",
        "location_city": "Brittany",
        "location_state": "Brest",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.44789886",
        "longitude": "-4.418540001",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "2297",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73886,
            "s_seats": 27848,
            "j_seats": 55516,
            "f_seats": 8483,
            "r_seats": 6105,
            "cargo_demand": 148809
        }
    },
    {
        "iata": "BET",
        "icao": "PABE",
        "airportName": "Bethel Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Bethel",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "60.77980042",
        "longitude": "-161.8379974",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "6400",
                "runway_width": "150",
                "runway_material": "ASPH-F"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "12/30",
                "runway_length": "1860",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 163527,
            "s_seats": 27910,
            "j_seats": 22934,
            "f_seats": 1490,
            "r_seats": 6989,
            "cargo_demand": 180221
        }
    },
    {
        "iata": "BEU",
        "icao": "YBIE",
        "airportName": "Bedourie Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Bedourie",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-24.34609985",
        "longitude": "139.4600067",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109831,
            "s_seats": 44420,
            "j_seats": 6562,
            "f_seats": 16461,
            "r_seats": 6527,
            "cargo_demand": 374016
        }
    },
    {
        "iata": "BEV",
        "icao": "LLBS",
        "airportName": "Beersheba Airport",
        "location_country": "Israel",
        "location_city": "Beersheba",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "31.28700066",
        "longitude": "34.72299957",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3384",
                "runway_width": "69",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2146,
            "s_seats": 2537,
            "j_seats": 1106,
            "f_seats": 265,
            "r_seats": 84,
            "cargo_demand": 65945
        }
    },
    {
        "iata": "BEW",
        "icao": "FQBR",
        "airportName": "Beira Airport",
        "location_country": "Mozambique",
        "location_city": "Beira",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.79640007",
        "longitude": "34.9076004",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3018",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5660",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51402,
            "s_seats": 30189,
            "j_seats": 6797,
            "f_seats": 5115,
            "r_seats": 6844,
            "cargo_demand": 438555
        }
    },
    {
        "iata": "BEX",
        "icao": "EGUB",
        "airportName": "RAF Benson",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Benson",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.61640167",
        "longitude": "-1.095829964",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5981",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "4782",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58340,
            "s_seats": 47955,
            "j_seats": 11719,
            "f_seats": 17240,
            "r_seats": 9470,
            "cargo_demand": 292632
        }
    },
    {
        "iata": "BEY",
        "icao": "OLBA",
        "airportName": "Beirut-Rafic Hariri International Airport",
        "location_country": "Lebanon",
        "location_city": "Beirut",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "33.82089996",
        "longitude": "35.48839951",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "12467",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "16/34",
                "runway_length": "11138",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "10663",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 233575,
            "s_seats": 73232,
            "j_seats": 34342,
            "f_seats": 69828,
            "r_seats": 10000,
            "cargo_demand": 23679394
        }
    },
    {
        "iata": "BFD",
        "icao": "KBFD",
        "airportName": "Bradford Regional Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Bradford",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.80310059",
        "longitude": "-78.64009857",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6309",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186646,
            "s_seats": 25401,
            "j_seats": 41297,
            "f_seats": 16291,
            "r_seats": 9428,
            "cargo_demand": 106065
        }
    },
    {
        "iata": "BFE",
        "icao": "EDLI",
        "airportName": "Bielefeld Airport",
        "location_country": "Germany",
        "location_city": "North Rhine-Westphalia",
        "location_state": "Bielefeld",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "51.964722",
        "longitude": "8.544444",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4121",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1991,
            "s_seats": 2840,
            "j_seats": 1742,
            "f_seats": 241,
            "r_seats": 47,
            "cargo_demand": 71692
        }
    },
    {
        "iata": "BFF",
        "icao": "KBFF",
        "airportName": "Western Nebraska Regional Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Scottsbluff",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.87400055",
        "longitude": "-103.5960007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8279",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 96344,
            "s_seats": 29848,
            "j_seats": 42160,
            "f_seats": 5313,
            "r_seats": 8160,
            "cargo_demand": 399652
        }
    },
    {
        "iata": "BFH",
        "icao": "SBBI",
        "airportName": "Bacacheri Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Curitiba",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-25.4051",
        "longitude": "-49.231998",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4560",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176613,
            "s_seats": 39664,
            "j_seats": 33920,
            "f_seats": 19636,
            "r_seats": 9295,
            "cargo_demand": 339681
        }
    },
    {
        "iata": "BFI",
        "icao": "KBFI",
        "airportName": "Boeing Field/King County International Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Seattle",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.529999",
        "longitude": "-122.302002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "3709",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "10007",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114314,
            "s_seats": 25397,
            "j_seats": 27042,
            "f_seats": 19444,
            "r_seats": 7328,
            "cargo_demand": 226396
        }
    },
    {
        "iata": "BFK",
        "icao": "KBKF",
        "airportName": "Buckley Space Force Base",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Aurora",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.7016983",
        "longitude": "-104.7519989",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "11000",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 186781,
            "s_seats": 27388,
            "j_seats": 52890,
            "f_seats": 5561,
            "r_seats": 4060,
            "cargo_demand": 125290
        }
    },
    {
        "iata": "BFL",
        "icao": "KBFL",
        "airportName": "Meadows Field Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Bakersfield",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.433601",
        "longitude": "-119.056999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12L/30R",
                "runway_length": "10857",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "3700",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182378,
            "s_seats": 47825,
            "j_seats": 50173,
            "f_seats": 11220,
            "r_seats": 9307,
            "cargo_demand": 489800
        }
    },
    {
        "iata": "BFM",
        "icao": "KBFM",
        "airportName": "Mobile Downtown Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Mobile",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.62680054",
        "longitude": "-88.06809998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9618",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7800",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 125808,
            "s_seats": 45896,
            "j_seats": 17947,
            "f_seats": 11927,
            "r_seats": 4948,
            "cargo_demand": 269152
        }
    },
    {
        "iata": "BFN",
        "icao": "FABL",
        "airportName": "Bloemfontein Airport",
        "location_country": "South Africa",
        "location_city": "Bloemfontein",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.092699",
        "longitude": "26.302401",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8396",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7202",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99237,
            "s_seats": 31845,
            "j_seats": 5933,
            "f_seats": 17550,
            "r_seats": 8461,
            "cargo_demand": 240228
        }
    },
    {
        "iata": "BFO",
        "icao": "FVCZ",
        "airportName": "Buffalo Range Airport",
        "location_country": "Zimbabwe",
        "location_city": "Chiredzi",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.008101",
        "longitude": "31.5786",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5785",
                "runway_width": "98",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 185992,
            "s_seats": 39815,
            "j_seats": 16016,
            "f_seats": 11353,
            "r_seats": 5188,
            "cargo_demand": 150128
        }
    },
    {
        "iata": "BFP",
        "icao": "KBVI",
        "airportName": "Beaver County Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Beaver Falls",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.77249908",
        "longitude": "-80.3914032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4501",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171831,
            "s_seats": 44511,
            "j_seats": 42623,
            "f_seats": 15993,
            "r_seats": 8769,
            "cargo_demand": 438962
        }
    },
    {
        "iata": "BFR",
        "icao": "KBFR",
        "airportName": "Virgil I. Grissom Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Bedford",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.84000015",
        "longitude": "-86.44539642",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3089",
                "runway_width": "70",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4501",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3630,
            "s_seats": 1707,
            "j_seats": 1796,
            "f_seats": 404,
            "r_seats": 137,
            "cargo_demand": 50196
        }
    },
    {
        "iata": "BFS",
        "icao": "EGAA",
        "airportName": "Belfast International Airport",
        "location_country": "United Kingdom",
        "location_city": "Northern Ireland",
        "location_state": "Belfast",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "54.65750122",
        "longitude": "-6.215829849",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9121",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6204",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 337772,
            "s_seats": 83665,
            "j_seats": 129777,
            "f_seats": 41744,
            "r_seats": 10000,
            "cargo_demand": 20075742
        }
    },
    {
        "iata": "BFT",
        "icao": "KARW",
        "airportName": "Beaufort County Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Beaufort County",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.41220093",
        "longitude": "-80.63439941",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3434",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1786,
            "s_seats": 2420,
            "j_seats": 1670,
            "f_seats": 402,
            "r_seats": 2,
            "cargo_demand": 56098
        }
    },
    {
        "iata": "BFV",
        "icao": "VTUO",
        "airportName": "Buriram Airport",
        "location_country": "Thailand",
        "location_city": "Buriram",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.22949982",
        "longitude": "103.2529984",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170245,
            "s_seats": 45935,
            "j_seats": 26135,
            "f_seats": 3372,
            "r_seats": 8647,
            "cargo_demand": 270400
        }
    },
    {
        "iata": "BFW",
        "icao": "DAOS",
        "airportName": "Sidi Bel Abbes Airport",
        "location_country": "Algeria",
        "location_city": "Sidi Bel Abbes",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.17179871",
        "longitude": "-0.593275011",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4868",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3529,
            "s_seats": 1926,
            "j_seats": 1094,
            "f_seats": 154,
            "r_seats": 192,
            "cargo_demand": 38055
        }
    },
    {
        "iata": "BFX",
        "icao": "FKKU",
        "airportName": "Bafoussam Airport",
        "location_country": "Cameroon",
        "location_city": "Bafoussam",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.536920071",
        "longitude": "10.35459995",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "8203",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128378,
            "s_seats": 46584,
            "j_seats": 12465,
            "f_seats": 7051,
            "r_seats": 7860,
            "cargo_demand": 198936
        }
    },
    {
        "iata": "BGA",
        "icao": "SKBG",
        "airportName": "Palonegro International Airport",
        "location_country": "Colombia",
        "location_city": "Bucaramanga",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.1265",
        "longitude": "-73.1848",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7381",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64186,
            "s_seats": 27024,
            "j_seats": 34973,
            "f_seats": 6671,
            "r_seats": 5000,
            "cargo_demand": 493162
        }
    },
    {
        "iata": "BGC",
        "icao": "LPBG",
        "airportName": "BraganiCa Airport",
        "location_country": "Portugal",
        "location_city": "BraganiCa",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "41.8578",
        "longitude": "-6.70713",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5600",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54647,
            "s_seats": 33265,
            "j_seats": 8383,
            "f_seats": 19794,
            "r_seats": 3671,
            "cargo_demand": 297522
        }
    },
    {
        "iata": "BGD",
        "icao": "KBGD",
        "airportName": "Hutchinson County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Borger",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.70090103",
        "longitude": "-101.3939972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3898",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3659,
            "s_seats": 1655,
            "j_seats": 1989,
            "f_seats": 109,
            "r_seats": 43,
            "cargo_demand": 86441
        }
    },
    {
        "iata": "BGE",
        "icao": "KBGE",
        "airportName": "Decatur County Industrial Air Park",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Bainbridge",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "30.9715004",
        "longitude": "-84.63739777",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5502",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "5100",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5003",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2833,
            "s_seats": 2036,
            "j_seats": 1901,
            "f_seats": 405,
            "r_seats": 106,
            "cargo_demand": 73244
        }
    },
    {
        "iata": "BGF",
        "icao": "FEFF",
        "airportName": "Bangui M'Poko International Airport",
        "location_country": "Central African Republic",
        "location_city": "Bangui",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.398479939",
        "longitude": "18.51880074",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90967,
            "s_seats": 39460,
            "j_seats": 53101,
            "f_seats": 18072,
            "r_seats": 3768,
            "cargo_demand": 129303
        }
    },
    {
        "iata": "BGI",
        "icao": "TBPB",
        "airportName": "Grantley Adams International Airport",
        "location_country": "Barbados",
        "location_city": "Bridgetown",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.0746",
        "longitude": "-59.4925",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "11000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117264,
            "s_seats": 49629,
            "j_seats": 29743,
            "f_seats": 14322,
            "r_seats": 7468,
            "cargo_demand": 490426
        }
    },
    {
        "iata": "BGM",
        "icao": "KBGM",
        "airportName": "Greater Binghamton Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Binghamton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.20869827",
        "longitude": "-75.97979736",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7100",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 85344,
            "s_seats": 42034,
            "j_seats": 43099,
            "f_seats": 17367,
            "r_seats": 4876,
            "cargo_demand": 334363
        }
    },
    {
        "iata": "BGO",
        "icao": "ENBR",
        "airportName": "Bergen Airport",
        "location_country": "Norway",
        "location_city": "Bergen",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "60.29339981",
        "longitude": "5.218140125",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9810",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 246838,
            "s_seats": 87639,
            "j_seats": 85404,
            "f_seats": 53632,
            "r_seats": 10000,
            "cargo_demand": 17411056
        }
    },
    {
        "iata": "BGQ",
        "icao": "PAGQ",
        "airportName": "Big Lake Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Big Lake",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "61.53609848",
        "longitude": "-149.8139954",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2435",
                "runway_width": "70",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 1881,
            "s_seats": 1897,
            "j_seats": 1587,
            "f_seats": 200,
            "r_seats": 37,
            "cargo_demand": 88595
        }
    },
    {
        "iata": "BGR",
        "icao": "KBGR",
        "airportName": "Bangor International Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Bangor",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "44.80739975",
        "longitude": "-68.82810211",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "11440",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 430556,
            "s_seats": 71542,
            "j_seats": 150632,
            "f_seats": 69252,
            "r_seats": 10000,
            "cargo_demand": 22497009
        }
    },
    {
        "iata": "BGV",
        "icao": "SSBG",
        "airportName": "Bento GoniCalves Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Bento GoniCalves",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-29.147424",
        "longitude": "-51.540791",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3281",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2270,
            "s_seats": 2333,
            "j_seats": 1279,
            "f_seats": 326,
            "r_seats": 3,
            "cargo_demand": 37495
        }
    },
    {
        "iata": "BGW",
        "icao": "ORBI",
        "airportName": "Baghdad International Airport",
        "location_country": "Iraq",
        "location_city": "Baghdad",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "33.262501",
        "longitude": "44.2346",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15L/33R",
                "runway_length": "13124",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "10830",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 493183,
            "s_seats": 84684,
            "j_seats": 126137,
            "f_seats": 46342,
            "r_seats": 10000,
            "cargo_demand": 21373923
        }
    },
    {
        "iata": "BGX",
        "icao": "SBBG",
        "airportName": "Comandante Gustavo Kraemer Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Bage",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-31.390499",
        "longitude": "-54.112202",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3770",
                "runway_width": "148",
                "runway_material": "GRS"
            },
            {
                "runway_name": "6/24",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 94791,
            "s_seats": 35064,
            "j_seats": 21441,
            "f_seats": 12531,
            "r_seats": 9282,
            "cargo_demand": 358360
        }
    },
    {
        "iata": "BGY",
        "icao": "LIME",
        "airportName": "Il Caravaggio International Airport",
        "location_country": "Italy",
        "location_city": "Lombardy",
        "location_state": "Milan / Bergamo",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "45.673901",
        "longitude": "9.70417",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9636",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2552",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 363078,
            "s_seats": 70791,
            "j_seats": 55220,
            "f_seats": 64207,
            "r_seats": 10000,
            "cargo_demand": 18114798
        }
    },
    {
        "iata": "BGZ",
        "icao": "LPBR",
        "airportName": "Braga Airport",
        "location_country": "Portugal",
        "location_city": "Braga",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "41.58710098",
        "longitude": "-8.445139885",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3081",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95661,
            "s_seats": 36769,
            "j_seats": 49211,
            "f_seats": 4814,
            "r_seats": 4022,
            "cargo_demand": 208448
        }
    },
    {
        "iata": "BHA",
        "icao": "SESV",
        "airportName": "Los Perales Airport",
        "location_country": "Ecuador",
        "location_city": "Bahia de Caraquez",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.608111024",
        "longitude": "-80.40270233",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7227",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1907,
            "s_seats": 2723,
            "j_seats": 1104,
            "f_seats": 138,
            "r_seats": 176,
            "cargo_demand": 1880
        }
    },
    {
        "iata": "BHB",
        "icao": "KBHB",
        "airportName": "Hancock County-Bar Harbor Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Bar Harbor",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.45000076",
        "longitude": "-68.3615036",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5200",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3599",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3364",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135154,
            "s_seats": 44421,
            "j_seats": 37471,
            "f_seats": 18559,
            "r_seats": 8379,
            "cargo_demand": 153745
        }
    },
    {
        "iata": "BHD",
        "icao": "EGAC",
        "airportName": "George Best Belfast City Airport",
        "location_country": "United Kingdom",
        "location_city": "Northern Ireland",
        "location_state": "Belfast",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "54.618099",
        "longitude": "-5.8725",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6001",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187749,
            "s_seats": 41922,
            "j_seats": 23469,
            "f_seats": 4864,
            "r_seats": 5376,
            "cargo_demand": 211050
        }
    },
    {
        "iata": "BHE",
        "icao": "NZWB",
        "airportName": "Woodbourne Airport",
        "location_country": "New Zealand",
        "location_city": "Blenheim",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-41.5182991",
        "longitude": "173.8699951",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "4675",
                "runway_width": "197",
                "runway_material": "GRE"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "4675",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3878",
                "runway_width": "295",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 117201,
            "s_seats": 40956,
            "j_seats": 28565,
            "f_seats": 3274,
            "r_seats": 9380,
            "cargo_demand": 434946
        }
    },
    {
        "iata": "BHH",
        "icao": "OEBH",
        "airportName": "Bisha Domestic Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Bisha",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.9843998",
        "longitude": "42.6208992",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "10007",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150252,
            "s_seats": 35071,
            "j_seats": 42610,
            "f_seats": 3922,
            "r_seats": 8013,
            "cargo_demand": 227175
        }
    },
    {
        "iata": "BHI",
        "icao": "SAZB",
        "airportName": "Comandante Espora Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "Bahia Blanca",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.725",
        "longitude": "-62.1693",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "8579",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "4915",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "6/24",
                "runway_length": "6660",
                "runway_width": "108",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154626,
            "s_seats": 33389,
            "j_seats": 54761,
            "f_seats": 9502,
            "r_seats": 6815,
            "cargo_demand": 484670
        }
    },
    {
        "iata": "BHJ",
        "icao": "VABJ",
        "airportName": "Bhuj Airport / Bhuj Rudra Mata Air Force Base",
        "location_country": "India",
        "location_city": "Gujarat",
        "location_state": "Bhuj",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.28779984",
        "longitude": "69.67019653",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8205",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181040,
            "s_seats": 47857,
            "j_seats": 19138,
            "f_seats": 18337,
            "r_seats": 4694,
            "cargo_demand": 224593
        }
    },
    {
        "iata": "BHK",
        "icao": "UTSB",
        "airportName": "Bukhara International Airport",
        "location_country": "Uzbekistan",
        "location_city": "Bukhara",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.77500153",
        "longitude": "64.48329926",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9843",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51529,
            "s_seats": 41298,
            "j_seats": 57599,
            "f_seats": 18027,
            "r_seats": 3259,
            "cargo_demand": 268247
        }
    },
    {
        "iata": "BHM",
        "icao": "KBHM",
        "airportName": "Birmingham-Shuttlesworth International Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Birmingham",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "33.56290054",
        "longitude": "-86.75350189",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7100",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121402,
            "s_seats": 83268,
            "j_seats": 70146,
            "f_seats": 41029,
            "r_seats": 10000,
            "cargo_demand": 12492376
        }
    },
    {
        "iata": "BHN",
        "icao": "OYBN",
        "airportName": "Beihan Airport",
        "location_country": "Yemen",
        "location_city": "Beihan",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.78199959",
        "longitude": "45.7201004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5480",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100625,
            "s_seats": 32817,
            "j_seats": 7913,
            "f_seats": 8393,
            "r_seats": 6560,
            "cargo_demand": 285236
        }
    },
    {
        "iata": "BHO",
        "icao": "VABP",
        "airportName": "Raja Bhoj Airport",
        "location_country": "India",
        "location_city": "Madhya Pradesh",
        "location_state": "Bhopal",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.28750038",
        "longitude": "77.33740234",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3420",
                "runway_width": "148",
                "runway_material": "BIT"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6700",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151402,
            "s_seats": 27394,
            "j_seats": 17371,
            "f_seats": 7087,
            "r_seats": 9531,
            "cargo_demand": 412135
        }
    },
    {
        "iata": "BHQ",
        "icao": "YBHI",
        "airportName": "Broken Hill Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Broken Hill",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-32.00139999",
        "longitude": "141.4720001",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8251",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3281",
                "runway_width": "98",
                "runway_material": "SAN"
            }
        ],
        "demand": {
            "y_seats": 158283,
            "s_seats": 40219,
            "j_seats": 29734,
            "f_seats": 19602,
            "r_seats": 9023,
            "cargo_demand": 315381
        }
    },
    {
        "iata": "BHR",
        "icao": "VNBP",
        "airportName": "Bharatpur Airport",
        "location_country": "Nepal",
        "location_city": "Bharatpur",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.678101",
        "longitude": "84.429398",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3799",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1982,
            "s_seats": 2631,
            "j_seats": 1125,
            "f_seats": 77,
            "r_seats": 24,
            "cargo_demand": 36072
        }
    },
    {
        "iata": "BHS",
        "icao": "YBTH",
        "airportName": "Bathurst Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Bathurst",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-33.40940094",
        "longitude": "149.6519928",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4314",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5594",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105942,
            "s_seats": 25732,
            "j_seats": 32674,
            "f_seats": 17278,
            "r_seats": 8142,
            "cargo_demand": 57310
        }
    },
    {
        "iata": "BHU",
        "icao": "VABV",
        "airportName": "Bhavnagar Airport",
        "location_country": "India",
        "location_city": "Gujarat",
        "location_state": "Bhavnagar",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.75219917",
        "longitude": "72.18520355",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6300",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118799,
            "s_seats": 42871,
            "j_seats": 51389,
            "f_seats": 18843,
            "r_seats": 9775,
            "cargo_demand": 133095
        }
    },
    {
        "iata": "BHV",
        "icao": "OPBW",
        "airportName": "Bahawalpur Airport",
        "location_country": "Pakistan",
        "location_city": "Bahawalpur",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.34810066",
        "longitude": "71.71800232",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9345",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102241,
            "s_seats": 30564,
            "j_seats": 46623,
            "f_seats": 2639,
            "r_seats": 7857,
            "cargo_demand": 390660
        }
    },
    {
        "iata": "BHX",
        "icao": "EGBB",
        "airportName": "Birmingham Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Birmingham",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "52.45389938",
        "longitude": "-1.748029947",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "10013",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165291,
            "s_seats": 82267,
            "j_seats": 114683,
            "f_seats": 33132,
            "r_seats": 10000,
            "cargo_demand": 23665912
        }
    },
    {
        "iata": "BIA",
        "icao": "LFKB",
        "airportName": "Bastia - Poretta Airport",
        "location_country": "France",
        "location_city": "Corsica",
        "location_state": "Bastia",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "42.55270004",
        "longitude": "9.483730316",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8266",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125212,
            "s_seats": 38869,
            "j_seats": 7445,
            "f_seats": 8978,
            "r_seats": 9662,
            "cargo_demand": 161400
        }
    },
    {
        "iata": "BID",
        "icao": "KBID",
        "airportName": "Block Island State Airport",
        "location_country": "United States",
        "location_city": "Rhode Island",
        "location_state": "Block Island",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.16809845",
        "longitude": "-71.57779694",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2502",
                "runway_width": "100",
                "runway_material": "ASPH-E"
            }
        ],
        "demand": {
            "y_seats": 3868,
            "s_seats": 1599,
            "j_seats": 1562,
            "f_seats": 40,
            "r_seats": 58,
            "cargo_demand": 33382
        }
    },
    {
        "iata": "BIE",
        "icao": "KBIE",
        "airportName": "Beatrice Municipal Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Beatrice",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.30130005",
        "longitude": "-96.75409698",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4401",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5602",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 2053,
            "s_seats": 1607,
            "j_seats": 1721,
            "f_seats": 9,
            "r_seats": 52,
            "cargo_demand": 26922
        }
    },
    {
        "iata": "BIF",
        "icao": "KBIF",
        "airportName": "Biggs Army Airfield",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "El Paso",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.84950066",
        "longitude": "-106.3799973",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "13554",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 94099,
            "s_seats": 37798,
            "j_seats": 9061,
            "f_seats": 15522,
            "r_seats": 8750,
            "cargo_demand": 94399
        }
    },
    {
        "iata": "BIG",
        "icao": "PABI",
        "airportName": "Allen Army Airfield",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Delta Junction",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "63.99449921",
        "longitude": "-145.7220001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "07/25",
                "runway_length": "4679",
                "runway_width": "164",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "10/28",
                "runway_length": "6115",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 158327,
            "s_seats": 29392,
            "j_seats": 30768,
            "f_seats": 7855,
            "r_seats": 8477,
            "cargo_demand": 495538
        }
    },
    {
        "iata": "BIH",
        "icao": "KBIH",
        "airportName": "Eastern Sierra Regional Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Bishop",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.37310028",
        "longitude": "-118.3639984",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5566",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7498",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5600",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69297,
            "s_seats": 40733,
            "j_seats": 36112,
            "f_seats": 12136,
            "r_seats": 5230,
            "cargo_demand": 467047
        }
    },
    {
        "iata": "BIK",
        "icao": "WABB",
        "airportName": "Frans Kaisiepo Airport",
        "location_country": "Indonesia",
        "location_city": "Biak",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.190019965",
        "longitude": "136.1080017",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "11715",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193982,
            "s_seats": 26008,
            "j_seats": 29061,
            "f_seats": 11854,
            "r_seats": 9811,
            "cargo_demand": 317688
        }
    },
    {
        "iata": "BIL",
        "icao": "KBIL",
        "airportName": "Billings Logan International Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Billings",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "45.80770111",
        "longitude": "-108.5429993",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5501",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "10518",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "3801",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 404393,
            "s_seats": 94494,
            "j_seats": 93679,
            "f_seats": 49390,
            "r_seats": 10000,
            "cargo_demand": 14037504
        }
    },
    {
        "iata": "BIM",
        "icao": "MYBS",
        "airportName": "South Bimini Airport",
        "location_country": "Bahamas",
        "location_city": "Bimini",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "25.69989967",
        "longitude": "-79.26470184",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5409",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181714,
            "s_seats": 36269,
            "j_seats": 28516,
            "f_seats": 14411,
            "r_seats": 4075,
            "cargo_demand": 345110
        }
    },
    {
        "iata": "BIO",
        "icao": "LEBB",
        "airportName": "Bilbao Airport",
        "location_country": "Spain",
        "location_city": "Basque Country",
        "location_state": "Bilbao",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.30110168",
        "longitude": "-2.910609961",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184028,
            "s_seats": 46997,
            "j_seats": 16342,
            "f_seats": 19296,
            "r_seats": 4584,
            "cargo_demand": 149587
        }
    },
    {
        "iata": "BIQ",
        "icao": "LFBZ",
        "airportName": "Biarritz Pays Basque Airport",
        "location_country": "France",
        "location_city": "Aquitaine",
        "location_state": "Biarritz",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.4683333",
        "longitude": "-1.5311111",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7382",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76553,
            "s_seats": 45829,
            "j_seats": 57161,
            "f_seats": 4429,
            "r_seats": 3478,
            "cargo_demand": 211718
        }
    },
    {
        "iata": "BIR",
        "icao": "VNVT",
        "airportName": "Biratnagar Airport",
        "location_country": "Nepal",
        "location_city": "Biratnagar",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.48150063",
        "longitude": "87.26399994",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4937",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104686,
            "s_seats": 49473,
            "j_seats": 21875,
            "f_seats": 18942,
            "r_seats": 6780,
            "cargo_demand": 289546
        }
    },
    {
        "iata": "BIS",
        "icao": "KBIS",
        "airportName": "Bismarck Municipal Airport",
        "location_country": "United States",
        "location_city": "North Dakota",
        "location_state": "Bismarck",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.77270126",
        "longitude": "-100.7460022",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6600",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "8794",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150298,
            "s_seats": 35376,
            "j_seats": 9514,
            "f_seats": 3708,
            "r_seats": 5863,
            "cargo_demand": 200273
        }
    },
    {
        "iata": "BIU",
        "icao": "BIBD",
        "airportName": "Bildudalur Airport",
        "location_country": "Iceland",
        "location_city": "Bildudalur",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.64129639",
        "longitude": "-23.5461998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3084",
                "runway_width": "98",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 1609,
            "s_seats": 2196,
            "j_seats": 1515,
            "f_seats": 303,
            "r_seats": 102,
            "cargo_demand": 74530
        }
    },
    {
        "iata": "BIV",
        "icao": "FEFR",
        "airportName": "Bria Airport",
        "location_country": "Central African Republic",
        "location_city": "Bria",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "6.527780056",
        "longitude": "21.98940086",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5906",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2021,
            "s_seats": 2053,
            "j_seats": 1172,
            "f_seats": 246,
            "r_seats": 134,
            "cargo_demand": 8452
        }
    },
    {
        "iata": "BIX",
        "icao": "KBIX",
        "airportName": "Keesler Air Force Base",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Biloxi",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.41040039",
        "longitude": "-88.92440033",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7630",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 97387,
            "s_seats": 40507,
            "j_seats": 7357,
            "f_seats": 8613,
            "r_seats": 3680,
            "cargo_demand": 120852
        }
    },
    {
        "iata": "BIY",
        "icao": "FABE",
        "airportName": "Bhisho Airport",
        "location_country": "South Africa",
        "location_city": "Bhisho",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-32.89709854",
        "longitude": "27.27910042",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8202",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132071,
            "s_seats": 47580,
            "j_seats": 9860,
            "f_seats": 7908,
            "r_seats": 8863,
            "cargo_demand": 127093
        }
    },
    {
        "iata": "BJA",
        "icao": "DAAE",
        "airportName": "Soummam - Abane Ramdane Airport",
        "location_country": "Algeria",
        "location_city": "Bejai\u00afa",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.712793",
        "longitude": "5.07857",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171727,
            "s_seats": 99672,
            "j_seats": 75495,
            "f_seats": 29100,
            "r_seats": 10000,
            "cargo_demand": 26266277
        }
    },
    {
        "iata": "BJB",
        "icao": "OIMN",
        "airportName": "Bojnord Airport",
        "location_country": "Iran",
        "location_city": "Bojnord",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "37.49300003",
        "longitude": "57.30820084",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10582",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99296,
            "s_seats": 41652,
            "j_seats": 20898,
            "f_seats": 9094,
            "r_seats": 5878,
            "cargo_demand": 485984
        }
    },
    {
        "iata": "BJC",
        "icao": "KBJC",
        "airportName": "Rocky Mountain Metropolitan Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Denver",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.90879822",
        "longitude": "-105.1169968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3601",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "9000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "7004",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72047,
            "s_seats": 38371,
            "j_seats": 27545,
            "f_seats": 14040,
            "r_seats": 4212,
            "cargo_demand": 463803
        }
    },
    {
        "iata": "BJF",
        "icao": "ENBS",
        "airportName": "Biotsfjord Airport",
        "location_country": "Norway",
        "location_city": "Biotsfjord",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "70.60050201",
        "longitude": "29.69140053",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3281",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143295,
            "s_seats": 31618,
            "j_seats": 26875,
            "f_seats": 14080,
            "r_seats": 3055,
            "cargo_demand": 387589
        }
    },
    {
        "iata": "BJI",
        "icao": "KBJI",
        "airportName": "Bemidji Regional Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Bemidji",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.50939941",
        "longitude": "-94.93370056",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5699",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6598",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52473,
            "s_seats": 36293,
            "j_seats": 57518,
            "f_seats": 13120,
            "r_seats": 3966,
            "cargo_demand": 425815
        }
    },
    {
        "iata": "BJJ",
        "icao": "KBJJ",
        "airportName": "Wayne County Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Wooster",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.87480164",
        "longitude": "-81.88829803",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5191",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2139,
            "s_seats": 1967,
            "j_seats": 1997,
            "f_seats": 211,
            "r_seats": 51,
            "cargo_demand": 5727
        }
    },
    {
        "iata": "BJK",
        "icao": "WAPK",
        "airportName": "Benjina Airport",
        "location_country": "Indonesia",
        "location_city": "Benjina",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.06613",
        "longitude": "134.273971",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4265",
                "runway_width": "59",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2742,
            "s_seats": 2356,
            "j_seats": 1877,
            "f_seats": 500,
            "r_seats": 128,
            "cargo_demand": 3110
        }
    },
    {
        "iata": "BJL",
        "icao": "GBYD",
        "airportName": "Banjul International Airport",
        "location_country": "Gambia",
        "location_city": "Banjul",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.3380003",
        "longitude": "-16.6522007",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95073,
            "s_seats": 36496,
            "j_seats": 22368,
            "f_seats": 5751,
            "r_seats": 5027,
            "cargo_demand": 279418
        }
    },
    {
        "iata": "BJM",
        "icao": "HBBA",
        "airportName": "Bujumbura International Airport",
        "location_country": "Burundi",
        "location_city": "Bujumbura",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.32402",
        "longitude": "29.318501",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "17/35",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170557,
            "s_seats": 39008,
            "j_seats": 40268,
            "f_seats": 5541,
            "r_seats": 3837,
            "cargo_demand": 497321
        }
    },
    {
        "iata": "BJO",
        "icao": "SLBJ",
        "airportName": "Bermejo Airport",
        "location_country": "Bolivia",
        "location_city": "Bermejo",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.77330017",
        "longitude": "-64.31289673",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4921",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141747,
            "s_seats": 42669,
            "j_seats": 52455,
            "f_seats": 2915,
            "r_seats": 9522,
            "cargo_demand": 160684
        }
    },
    {
        "iata": "BJP",
        "icao": "SBBP",
        "airportName": "Arthur Siqueira-BraganiCa Paulista State Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "BraganiCa Paulista",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-22.978822",
        "longitude": "-46.537131",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3997",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1202,
            "s_seats": 2816,
            "j_seats": 1316,
            "f_seats": 218,
            "r_seats": 30,
            "cargo_demand": 82095
        }
    },
    {
        "iata": "BJR",
        "icao": "HABD",
        "airportName": "Bahir Dar Airport",
        "location_country": "Ethiopia",
        "location_city": "Bahir Dar",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.60809994",
        "longitude": "37.32160187",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 130936,
            "s_seats": 27425,
            "j_seats": 44128,
            "f_seats": 16250,
            "r_seats": 6417,
            "cargo_demand": 296367
        }
    },
    {
        "iata": "BJV",
        "icao": "LTFE",
        "airportName": "Milas-Bodrum Airport",
        "location_country": "Turkey",
        "location_city": "Milas / Bodrum",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.25059891",
        "longitude": "27.66430092",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "8203",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 457149,
            "s_seats": 73319,
            "j_seats": 148847,
            "f_seats": 44289,
            "r_seats": 10000,
            "cargo_demand": 21266026
        }
    },
    {
        "iata": "BJX",
        "icao": "MMLO",
        "airportName": "Del Bajio International Airport",
        "location_country": "Mexico",
        "location_city": "Guanajuato",
        "location_state": "Leon",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "20.9935",
        "longitude": "-101.481003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112815,
            "s_seats": 48456,
            "j_seats": 11947,
            "f_seats": 3662,
            "r_seats": 9754,
            "cargo_demand": 159003
        }
    },
    {
        "iata": "BJY",
        "icao": "LYBT",
        "airportName": "Batajnica Air Base",
        "location_country": "Serbia",
        "location_city": "Batajnica",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "44.935299",
        "longitude": "20.2575",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3232",
                "runway_width": "191",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "8181",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "7999",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177530,
            "s_seats": 45896,
            "j_seats": 20484,
            "f_seats": 13850,
            "r_seats": 9509,
            "cargo_demand": 156398
        }
    },
    {
        "iata": "BJZ",
        "icao": "LEBZ",
        "airportName": "Badajoz Airport",
        "location_country": "Spain",
        "location_city": "Extremadura",
        "location_state": "Badajoz",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "38.8913002",
        "longitude": "-6.82133007",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9350",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147863,
            "s_seats": 30539,
            "j_seats": 13226,
            "f_seats": 18553,
            "r_seats": 8776,
            "cargo_demand": 327725
        }
    },
    {
        "iata": "BKB",
        "icao": "VIBK",
        "airportName": "Nal Airport",
        "location_country": "India",
        "location_city": "Rajasthan",
        "location_state": "Bikaner",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.07060051",
        "longitude": "73.2071991",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8960",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187805,
            "s_seats": 38657,
            "j_seats": 42339,
            "f_seats": 1832,
            "r_seats": 8825,
            "cargo_demand": 402323
        }
    },
    {
        "iata": "BKC",
        "icao": "PABL",
        "airportName": "Buckland Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Buckland",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "65.981598",
        "longitude": "-161.149002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3200",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 147680,
            "s_seats": 43431,
            "j_seats": 11093,
            "f_seats": 12672,
            "r_seats": 7361,
            "cargo_demand": 200309
        }
    },
    {
        "iata": "BKD",
        "icao": "KBKD",
        "airportName": "Stephens County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Breckenridge",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.71900177",
        "longitude": "-98.89099884",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2399",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2400",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4998",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1059,
            "s_seats": 2156,
            "j_seats": 1537,
            "f_seats": 333,
            "r_seats": 102,
            "cargo_demand": 83137
        }
    },
    {
        "iata": "BKE",
        "icao": "KBKE",
        "airportName": "Baker City Municipal Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Baker City",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.83729935",
        "longitude": "-117.8089981",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3999",
                "runway_width": "140",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5095",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4359",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121727,
            "s_seats": 48393,
            "j_seats": 36237,
            "f_seats": 9984,
            "r_seats": 5519,
            "cargo_demand": 496059
        }
    },
    {
        "iata": "BKH",
        "icao": "PHBK",
        "airportName": "Barking Sands PMRF",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Kekaha",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.02280045",
        "longitude": "-159.7850037",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6006",
                "runway_width": "146",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 84783,
            "s_seats": 36755,
            "j_seats": 37408,
            "f_seats": 15731,
            "r_seats": 9291,
            "cargo_demand": 331241
        }
    },
    {
        "iata": "BKI",
        "icao": "WBKK",
        "airportName": "Kota Kinabalu International Airport",
        "location_country": "Malaysia",
        "location_city": "Sabah",
        "location_state": "Kota Kinabalu",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.937210083",
        "longitude": "116.0510025",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9800",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94800,
            "s_seats": 38176,
            "j_seats": 33496,
            "f_seats": 1019,
            "r_seats": 7412,
            "cargo_demand": 478054
        }
    },
    {
        "iata": "BKK",
        "icao": "VTBS",
        "airportName": "Suvarnabhumi Airport",
        "location_country": "Thailand",
        "location_city": "Bangkok",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "13.68109989",
        "longitude": "100.7470016",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "12139",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124680,
            "s_seats": 74062,
            "j_seats": 83015,
            "f_seats": 56569,
            "r_seats": 10000,
            "cargo_demand": 14677600
        }
    },
    {
        "iata": "BKL",
        "icao": "KBKL",
        "airportName": "Cleveland Burke Lakefront Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Cleveland",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.51750183",
        "longitude": "-81.6832962",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "6198",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "5197",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154480,
            "s_seats": 43749,
            "j_seats": 53031,
            "f_seats": 16216,
            "r_seats": 5687,
            "cargo_demand": 477553
        }
    },
    {
        "iata": "BKO",
        "icao": "GABS",
        "airportName": "Bamako-Senou International Airport",
        "location_country": "Mali",
        "location_city": "Bamako",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "12.5335",
        "longitude": "-7.94994",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10498",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 368928,
            "s_seats": 99518,
            "j_seats": 139153,
            "f_seats": 58889,
            "r_seats": 10000,
            "cargo_demand": 24523661
        }
    },
    {
        "iata": "BKP",
        "icao": "YBAW",
        "airportName": "Barkly Downs Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Barkly Downs",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.49583333",
        "longitude": "138.4747222",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4796",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3131,
            "s_seats": 1805,
            "j_seats": 1433,
            "f_seats": 443,
            "r_seats": 164,
            "cargo_demand": 47604
        }
    },
    {
        "iata": "BKQ",
        "icao": "YBCK",
        "airportName": "Blackall Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Blackall",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-24.42779922",
        "longitude": "145.4290009",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5538",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5259",
                "runway_width": "98",
                "runway_material": "CLA"
            }
        ],
        "demand": {
            "y_seats": 158299,
            "s_seats": 35037,
            "j_seats": 22477,
            "f_seats": 3086,
            "r_seats": 7863,
            "cargo_demand": 365254
        }
    },
    {
        "iata": "BKS",
        "icao": "WIPL",
        "airportName": "Fatmawati Soekarno Airport",
        "location_country": "Indonesia",
        "location_city": "Bengkulu",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.8637",
        "longitude": "102.338997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7345",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120241,
            "s_seats": 25145,
            "j_seats": 49437,
            "f_seats": 3542,
            "r_seats": 7982,
            "cargo_demand": 277303
        }
    },
    {
        "iata": "BKT",
        "icao": "KBKT",
        "airportName": "Blackstone Army Airfield",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Blackstone",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.07419968",
        "longitude": "-77.95749664",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4032",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "04/22",
                "runway_length": "4632",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1956,
            "s_seats": 1524,
            "j_seats": 1733,
            "f_seats": 91,
            "r_seats": 135,
            "cargo_demand": 29294
        }
    },
    {
        "iata": "BKW",
        "icao": "KBKW",
        "airportName": "Raleigh County Memorial Airport",
        "location_country": "United States",
        "location_city": "West Virginia",
        "location_state": "Beckley",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.78730011",
        "longitude": "-81.12419891",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6750",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176717,
            "s_seats": 31183,
            "j_seats": 14503,
            "f_seats": 19432,
            "r_seats": 9054,
            "cargo_demand": 341209
        }
    },
    {
        "iata": "BKX",
        "icao": "KBKX",
        "airportName": "Brookings Regional Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Brookings",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.304798",
        "longitude": "-96.816902",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5231",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3599",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2237,
            "s_seats": 2023,
            "j_seats": 1125,
            "f_seats": 44,
            "r_seats": 35,
            "cargo_demand": 57408
        }
    },
    {
        "iata": "BKY",
        "icao": "FZMA",
        "airportName": "Kavumu Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Bukavu",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.308979988",
        "longitude": "28.80879974",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80795,
            "s_seats": 38143,
            "j_seats": 19641,
            "f_seats": 3207,
            "r_seats": 4823,
            "cargo_demand": 207157
        }
    },
    {
        "iata": "BKZ",
        "icao": "HTBU",
        "airportName": "Bukoba Airport",
        "location_country": "Tanzania",
        "location_city": "Bukoba",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.332",
        "longitude": "31.8212",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4535",
                "runway_width": "93",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 2246,
            "s_seats": 1506,
            "j_seats": 1134,
            "f_seats": 388,
            "r_seats": 56,
            "cargo_demand": 10987
        }
    },
    {
        "iata": "BLA",
        "icao": "SVBC",
        "airportName": "General Jose Antonio Anzoategui International Airport",
        "location_country": "Venezuela",
        "location_city": "Barcelona",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "10.111111",
        "longitude": "-64.692222",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9186",
                "runway_width": "131",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "9842",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143459,
            "s_seats": 96737,
            "j_seats": 42535,
            "f_seats": 34127,
            "r_seats": 10000,
            "cargo_demand": 16321462
        }
    },
    {
        "iata": "BLB",
        "icao": "MPHO",
        "airportName": "Panama Pacifico International Airport",
        "location_country": "Panama",
        "location_city": "Panama Pacifico",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "8.91479",
        "longitude": "-79.599602",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8500",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2732,
            "s_seats": 2662,
            "j_seats": 1756,
            "f_seats": 265,
            "r_seats": 113,
            "cargo_demand": 25623
        }
    },
    {
        "iata": "BLE",
        "icao": "ESSD",
        "airportName": "Dala Airport",
        "location_country": "Sweden",
        "location_city": "BorlAnge",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "60.422001",
        "longitude": "15.5152",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7579",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 84379,
            "s_seats": 46531,
            "j_seats": 35820,
            "f_seats": 9895,
            "r_seats": 6815,
            "cargo_demand": 338043
        }
    },
    {
        "iata": "BLF",
        "icao": "KBLF",
        "airportName": "Mercer County Airport",
        "location_country": "United States",
        "location_city": "West Virginia",
        "location_state": "Bluefield",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.29579926",
        "longitude": "-81.20770264",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4742",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108495,
            "s_seats": 44308,
            "j_seats": 53236,
            "f_seats": 9054,
            "r_seats": 8296,
            "cargo_demand": 401931
        }
    },
    {
        "iata": "BLH",
        "icao": "KBLH",
        "airportName": "Blythe Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Blythe",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.61920166",
        "longitude": "-114.7170029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6543",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5800",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104411,
            "s_seats": 33926,
            "j_seats": 16135,
            "f_seats": 18744,
            "r_seats": 8547,
            "cargo_demand": 443143
        }
    },
    {
        "iata": "BLI",
        "icao": "KBLI",
        "airportName": "Bellingham International Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Bellingham",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "48.7928009",
        "longitude": "-122.538002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6701",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138334,
            "s_seats": 41389,
            "j_seats": 45762,
            "f_seats": 7496,
            "r_seats": 7245,
            "cargo_demand": 425889
        }
    },
    {
        "iata": "BLJ",
        "icao": "DABT",
        "airportName": "Mostepha Ben Boulaid Airport",
        "location_country": "Algeria",
        "location_city": "Batna",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.752102",
        "longitude": "6.30859",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 96056,
            "s_seats": 49824,
            "j_seats": 20715,
            "f_seats": 12882,
            "r_seats": 5751,
            "cargo_demand": 341624
        }
    },
    {
        "iata": "BLK",
        "icao": "EGNH",
        "airportName": "Blackpool Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Blackpool",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "53.77170181",
        "longitude": "-3.028609991",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2621",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "6132",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3274",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56729,
            "s_seats": 26924,
            "j_seats": 58650,
            "f_seats": 15388,
            "r_seats": 8783,
            "cargo_demand": 239981
        }
    },
    {
        "iata": "BLL",
        "icao": "EKBI",
        "airportName": "Billund Airport",
        "location_country": "Denmark",
        "location_city": "Billund",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "55.74029922",
        "longitude": "9.151780128",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10172",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192233,
            "s_seats": 80670,
            "j_seats": 115252,
            "f_seats": 20164,
            "r_seats": 10000,
            "cargo_demand": 25085276
        }
    },
    {
        "iata": "BLM",
        "icao": "KBLM",
        "airportName": "Monmouth Executive Airport",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Belmar / Farmingdale",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.18690109",
        "longitude": "-74.12490082",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3707",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "7300",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1051,
            "s_seats": 2860,
            "j_seats": 1412,
            "f_seats": 31,
            "r_seats": 21,
            "cargo_demand": 19796
        }
    },
    {
        "iata": "BLN",
        "icao": "YBLA",
        "airportName": "Benalla Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Benalla",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-36.551899",
        "longitude": "146.007004",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "3422",
                "runway_width": "60",
                "runway_material": "GRS"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "3422",
                "runway_width": "60",
                "runway_material": "PER"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "2356",
                "runway_width": "60",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "2356",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 172891,
            "s_seats": 44891,
            "j_seats": 5807,
            "f_seats": 6544,
            "r_seats": 9719,
            "cargo_demand": 118140
        }
    },
    {
        "iata": "BLO",
        "icao": "BIBL",
        "airportName": "Blonduos Airport",
        "location_country": "Iceland",
        "location_city": "Blonduos",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.64499664",
        "longitude": "-20.28750038",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3182",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1821,
            "s_seats": 1911,
            "j_seats": 1591,
            "f_seats": 331,
            "r_seats": 129,
            "cargo_demand": 55272
        }
    },
    {
        "iata": "BLQ",
        "icao": "LIPE",
        "airportName": "Bologna Guglielmo Marconi Airport",
        "location_country": "Italy",
        "location_city": "Emilia-Romagna",
        "location_state": "Bologna",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "44.5354",
        "longitude": "11.2887",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9186",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 481536,
            "s_seats": 95103,
            "j_seats": 26486,
            "f_seats": 52833,
            "r_seats": 10000,
            "cargo_demand": 14016828
        }
    },
    {
        "iata": "BLR",
        "icao": "VOBL",
        "airportName": "Kempegowda International Airport",
        "location_country": "India",
        "location_city": "Karnataka",
        "location_state": "Bangalore",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "13.1979",
        "longitude": "77.706299",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 333730,
            "s_seats": 84040,
            "j_seats": 104819,
            "f_seats": 52724,
            "r_seats": 10000,
            "cargo_demand": 12146466
        }
    },
    {
        "iata": "BLS",
        "icao": "YBLL",
        "airportName": "Bollon Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Bollon",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.05830002",
        "longitude": "147.4830017",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3500",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2313,
            "s_seats": 2228,
            "j_seats": 1978,
            "f_seats": 148,
            "r_seats": 149,
            "cargo_demand": 80160
        }
    },
    {
        "iata": "BLT",
        "icao": "YBTR",
        "airportName": "Blackwater Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Blackwater",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.60309982",
        "longitude": "148.8070068",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5023",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137217,
            "s_seats": 31344,
            "j_seats": 40576,
            "f_seats": 17276,
            "r_seats": 8340,
            "cargo_demand": 59623
        }
    },
    {
        "iata": "BLU",
        "icao": "KBLU",
        "airportName": "Blue Canyon-Nyack Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Emigrant Gap",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.27500153",
        "longitude": "-120.7099991",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3300",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3322,
            "s_seats": 1656,
            "j_seats": 1563,
            "f_seats": 311,
            "r_seats": 50,
            "cargo_demand": 21107
        }
    },
    {
        "iata": "BLV",
        "icao": "KBLV",
        "airportName": "MidAmerica St. Louis Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Belleville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "38.5452",
        "longitude": "-89.835197",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 349452,
            "s_seats": 93198,
            "j_seats": 40814,
            "f_seats": 54757,
            "r_seats": 10000,
            "cargo_demand": 5075373
        }
    },
    {
        "iata": "BLX",
        "icao": "LIDB",
        "airportName": "Belluno Airport",
        "location_country": "Italy",
        "location_city": "Veneto",
        "location_state": "Belluno",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "46.166549",
        "longitude": "12.250389",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2664",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3177,
            "s_seats": 2651,
            "j_seats": 1568,
            "f_seats": 480,
            "r_seats": 135,
            "cargo_demand": 72581
        }
    },
    {
        "iata": "BLY",
        "icao": "EIBT",
        "airportName": "Belmullet Aerodrome",
        "location_country": "Ireland",
        "location_city": "Belmullet",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "54.22280121",
        "longitude": "-10.03079987",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "1476",
                "runway_width": "59",
                "runway_material": "GRASS"
            }
        ],
        "demand": {
            "y_seats": 2790,
            "s_seats": 2416,
            "j_seats": 1597,
            "f_seats": 421,
            "r_seats": 97,
            "cargo_demand": 59520
        }
    },
    {
        "iata": "BLZ",
        "icao": "FWCL",
        "airportName": "Chileka International Airport",
        "location_country": "Malawi",
        "location_city": "Blantyre",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.67910004",
        "longitude": "34.97399902",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7628",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4501",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177331,
            "s_seats": 35092,
            "j_seats": 27450,
            "f_seats": 4810,
            "r_seats": 7828,
            "cargo_demand": 473433
        }
    },
    {
        "iata": "BMA",
        "icao": "ESSB",
        "airportName": "Stockholm Bromma Airport",
        "location_country": "Sweden",
        "location_city": "Stockholm",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "59.35440063",
        "longitude": "17.94169998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5472",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103339,
            "s_seats": 43656,
            "j_seats": 59327,
            "f_seats": 13104,
            "r_seats": 8854,
            "cargo_demand": 399904
        }
    },
    {
        "iata": "BMB",
        "icao": "FZFU",
        "airportName": "Bumba Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Bumba",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "2.18278",
        "longitude": "22.481701",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5578",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2440,
            "s_seats": 1972,
            "j_seats": 1226,
            "f_seats": 287,
            "r_seats": 95,
            "cargo_demand": 85794
        }
    },
    {
        "iata": "BMC",
        "icao": "KBMC",
        "airportName": "Brigham City Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Brigham City",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.552399",
        "longitude": "-112.061996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8900",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2134,
            "s_seats": 2774,
            "j_seats": 1185,
            "f_seats": 122,
            "r_seats": 179,
            "cargo_demand": 77155
        }
    },
    {
        "iata": "BME",
        "icao": "YBRM",
        "airportName": "Broome International Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Broome",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.94470024",
        "longitude": "122.2320023",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8064",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131029,
            "s_seats": 30876,
            "j_seats": 31744,
            "f_seats": 8188,
            "r_seats": 8936,
            "cargo_demand": 114380
        }
    },
    {
        "iata": "BMG",
        "icao": "KBMG",
        "airportName": "Monroe County Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Bloomington",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.14599991",
        "longitude": "-86.61669922",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3798",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63706,
            "s_seats": 43855,
            "j_seats": 38339,
            "f_seats": 6178,
            "r_seats": 8509,
            "cargo_demand": 473968
        }
    },
    {
        "iata": "BMI",
        "icao": "KBMI",
        "airportName": "Central Illinois Regional Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Bloomington",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "40.47710037",
        "longitude": "-88.91590118",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "03/21",
                "runway_length": "3723",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6525",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 303010,
            "s_seats": 89066,
            "j_seats": 91544,
            "f_seats": 29517,
            "r_seats": 10000,
            "cargo_demand": 6393971
        }
    },
    {
        "iata": "BMK",
        "icao": "EDWR",
        "airportName": "Borkum Airfield",
        "location_country": "Germany",
        "location_city": "Lower Saxony",
        "location_state": "Borkum",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "53.59639",
        "longitude": "6.709167",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2657",
                "runway_width": "131",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2854",
                "runway_width": "131",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3281",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3319,
            "s_seats": 2192,
            "j_seats": 1931,
            "f_seats": 49,
            "r_seats": 43,
            "cargo_demand": 41364
        }
    },
    {
        "iata": "BML",
        "icao": "KBML",
        "airportName": "Berlin Regional Airport",
        "location_country": "United States",
        "location_city": "New Hampshire",
        "location_state": "Berlin",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.57540131",
        "longitude": "-71.17590332",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5200",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3956,
            "s_seats": 1568,
            "j_seats": 1582,
            "f_seats": 354,
            "r_seats": 42,
            "cargo_demand": 31258
        }
    },
    {
        "iata": "BMM",
        "icao": "FOOB",
        "airportName": "Bitam Airport",
        "location_country": "Gabon",
        "location_city": "Bitam",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.075639963",
        "longitude": "11.4932003",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5726",
                "runway_width": "98",
                "runway_material": "LAT"
            }
        ],
        "demand": {
            "y_seats": 106558,
            "s_seats": 39809,
            "j_seats": 19875,
            "f_seats": 13477,
            "r_seats": 5558,
            "cargo_demand": 371911
        }
    },
    {
        "iata": "BMO",
        "icao": "VYBM",
        "airportName": "Bhamo Airport",
        "location_country": "Myanmar",
        "location_city": "Bhamo",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "24.2689991",
        "longitude": "97.24620056",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5502",
                "runway_width": "102",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 1572,
            "s_seats": 2253,
            "j_seats": 1005,
            "f_seats": 122,
            "r_seats": 25,
            "cargo_demand": 95598
        }
    },
    {
        "iata": "BMP",
        "icao": "YBPI",
        "airportName": "Brampton Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Brampton Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.804633",
        "longitude": "149.279931",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2709",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 2594,
            "s_seats": 2368,
            "j_seats": 1559,
            "f_seats": 163,
            "r_seats": 29,
            "cargo_demand": 95253
        }
    },
    {
        "iata": "BMS",
        "icao": "SNBU",
        "airportName": "Socrates Mariani Bittencourt Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Brumado",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.2554",
        "longitude": "-41.817501",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5709",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1122,
            "s_seats": 2237,
            "j_seats": 1145,
            "f_seats": 348,
            "r_seats": 88,
            "cargo_demand": 78828
        }
    },
    {
        "iata": "BMT",
        "icao": "KBMT",
        "airportName": "Beaumont Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Beaumont",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "30.070635",
        "longitude": "-94.215746",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2300",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1897,
            "s_seats": 2568,
            "j_seats": 1917,
            "f_seats": 232,
            "r_seats": 135,
            "cargo_demand": 59057
        }
    },
    {
        "iata": "BMU",
        "icao": "WADB",
        "airportName": "Sultan Muhammad Salahudin Airport",
        "location_country": "Indonesia",
        "location_city": "Bima",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.539649963",
        "longitude": "118.6869965",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5405",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3475,
            "s_seats": 1647,
            "j_seats": 1867,
            "f_seats": 258,
            "r_seats": 197,
            "cargo_demand": 85217
        }
    },
    {
        "iata": "BMV",
        "icao": "VVBM",
        "airportName": "Buon Ma Thuot Airport",
        "location_country": "Vietnam",
        "location_city": "Buon Ma Thuot",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.66829968",
        "longitude": "108.1200027",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59504,
            "s_seats": 48264,
            "j_seats": 8497,
            "f_seats": 5000,
            "r_seats": 6649,
            "cargo_demand": 497794
        }
    },
    {
        "iata": "BMW",
        "icao": "DATM",
        "airportName": "Bordj Mokhtar Airport",
        "location_country": "Algeria",
        "location_city": "Bordj Badji Mokhtar",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.3778",
        "longitude": "0.92698",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7372",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2237,
            "s_seats": 2239,
            "j_seats": 1894,
            "f_seats": 485,
            "r_seats": 34,
            "cargo_demand": 34654
        }
    },
    {
        "iata": "BMX",
        "icao": "PABM",
        "airportName": "Big Mountain Air Force Station",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Big Mountain",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "59.362274",
        "longitude": "-155.260148",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4200",
                "runway_width": "145",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 1722,
            "s_seats": 2601,
            "j_seats": 1323,
            "f_seats": 101,
            "r_seats": 156,
            "cargo_demand": 83944
        }
    },
    {
        "iata": "BMY",
        "icao": "NWWC",
        "airportName": "Ile Art - Waala Airport",
        "location_country": "New Caledonia",
        "location_city": "Belep Islands",
        "location_state": "Waala",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.72060013",
        "longitude": "163.6609955",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "1969",
                "runway_width": "66",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 113720,
            "s_seats": 38952,
            "j_seats": 33325,
            "f_seats": 17897,
            "r_seats": 4235,
            "cargo_demand": 480572
        }
    },
    {
        "iata": "BNA",
        "icao": "KBNA",
        "airportName": "Nashville International Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Nashville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "36.12450027",
        "longitude": "-86.67819977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02C/20C",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "02L/20R",
                "runway_length": "7703",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "11030",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 321180,
            "s_seats": 74792,
            "j_seats": 23045,
            "f_seats": 65321,
            "r_seats": 10000,
            "cargo_demand": 20791001
        }
    },
    {
        "iata": "BNC",
        "icao": "FZNP",
        "airportName": "Beni Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Beni",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.575",
        "longitude": "29.4739",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6600",
                "runway_width": "",
                "runway_material": "ASP/GVL"
            }
        ],
        "demand": {
            "y_seats": 1664,
            "s_seats": 1561,
            "j_seats": 1589,
            "f_seats": 185,
            "r_seats": 137,
            "cargo_demand": 61875
        }
    },
    {
        "iata": "BND",
        "icao": "OIKB",
        "airportName": "Bandar Abbas International Airport",
        "location_country": "Iran",
        "location_city": "Bandar Abbas",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "27.21829987",
        "longitude": "56.37779999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "11341",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "12008",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175774,
            "s_seats": 28362,
            "j_seats": 20119,
            "f_seats": 2710,
            "r_seats": 4022,
            "cargo_demand": 301938
        }
    },
    {
        "iata": "BNE",
        "icao": "YBBN",
        "airportName": "Brisbane Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Brisbane",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-27.38419914",
        "longitude": "153.1170044",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "10827",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "11680",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5577",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 316032,
            "s_seats": 91821,
            "j_seats": 149254,
            "f_seats": 33929,
            "r_seats": 10000,
            "cargo_demand": 4572340
        }
    },
    {
        "iata": "BNG",
        "icao": "KBNG",
        "airportName": "Banning Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Banning",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.922548",
        "longitude": "-116.850672",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1087,
            "s_seats": 2394,
            "j_seats": 1710,
            "f_seats": 8,
            "r_seats": 158,
            "cargo_demand": 41767
        }
    },
    {
        "iata": "BNI",
        "icao": "DNBE",
        "airportName": "Benin Airport",
        "location_country": "Nigeria",
        "location_city": "Benin City",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.316979885",
        "longitude": "5.599500179",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7870",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172118,
            "s_seats": 46745,
            "j_seats": 45778,
            "f_seats": 11149,
            "r_seats": 4254,
            "cargo_demand": 58777
        }
    },
    {
        "iata": "BNK",
        "icao": "YBNA",
        "airportName": "Ballina Byron Gateway Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Ballina",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-28.83390045",
        "longitude": "153.5619965",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6234",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157451,
            "s_seats": 42569,
            "j_seats": 12505,
            "f_seats": 19005,
            "r_seats": 6828,
            "cargo_demand": 233492
        }
    },
    {
        "iata": "BNL",
        "icao": "KBNL",
        "airportName": "Barnwell Regional Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Barnwell",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.25780106",
        "longitude": "-81.38829803",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5255",
                "runway_width": "70",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5119",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3962,
            "s_seats": 1687,
            "j_seats": 1648,
            "f_seats": 64,
            "r_seats": 197,
            "cargo_demand": 7901
        }
    },
    {
        "iata": "BNN",
        "icao": "ENBN",
        "airportName": "Bronnoysund Airport",
        "location_country": "Norway",
        "location_city": "Bronnoysund",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "65.46109772",
        "longitude": "12.21749973",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173947,
            "s_seats": 43445,
            "j_seats": 26981,
            "f_seats": 15164,
            "r_seats": 5816,
            "cargo_demand": 149303
        }
    },
    {
        "iata": "BNO",
        "icao": "KBNO",
        "airportName": "Burns Municipal Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Burns",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.59189987",
        "longitude": "-118.9550018",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4600",
                "runway_width": "60",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5100",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86665,
            "s_seats": 34018,
            "j_seats": 15418,
            "f_seats": 15764,
            "r_seats": 8826,
            "cargo_demand": 273697
        }
    },
    {
        "iata": "BNP",
        "icao": "OPBN",
        "airportName": "Bannu Airport",
        "location_country": "Pakistan",
        "location_city": "Bannu",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.9729",
        "longitude": "70.527901",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6001",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3778,
            "s_seats": 2896,
            "j_seats": 1415,
            "f_seats": 94,
            "r_seats": 167,
            "cargo_demand": 10390
        }
    },
    {
        "iata": "BNS",
        "icao": "SVBI",
        "airportName": "Barinas Airport",
        "location_country": "Venezuela",
        "location_city": "Barinas",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.615",
        "longitude": "-70.21416667",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3940",
                "runway_width": "130",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6560",
                "runway_width": "135",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174457,
            "s_seats": 46159,
            "j_seats": 55626,
            "f_seats": 19004,
            "r_seats": 4140,
            "cargo_demand": 477051
        }
    },
    {
        "iata": "BNU",
        "icao": "SSBL",
        "airportName": "Blumenau Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "Blumenau",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-26.83060074",
        "longitude": "-49.09030151",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4590",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3947,
            "s_seats": 2311,
            "j_seats": 1533,
            "f_seats": 68,
            "r_seats": 54,
            "cargo_demand": 19058
        }
    },
    {
        "iata": "BNW",
        "icao": "KBNW",
        "airportName": "Boone Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Boone",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.04959869",
        "longitude": "-93.84760284",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3298",
                "runway_width": "290",
                "runway_material": "GRE"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4807",
                "runway_width": "75",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 1687,
            "s_seats": 2551,
            "j_seats": 1387,
            "f_seats": 274,
            "r_seats": 143,
            "cargo_demand": 95020
        }
    },
    {
        "iata": "BNX",
        "icao": "LQBK",
        "airportName": "Banja Luka International Airport",
        "location_country": "Bosnia and Herzegovina",
        "location_city": "Banja Luka",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "44.94139862",
        "longitude": "17.29750061",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8213",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 107195,
            "s_seats": 28617,
            "j_seats": 55586,
            "f_seats": 18559,
            "r_seats": 9604,
            "cargo_demand": 69018
        }
    },
    {
        "iata": "BNY",
        "icao": "AGGB",
        "airportName": "Bellona/Anua Airport",
        "location_country": "Solomon Islands",
        "location_city": "Rennell and Bellona",
        "location_state": "Bellona Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.30222222",
        "longitude": "159.7983333",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2187",
                "runway_width": "59",
                "runway_material": "Coral sand"
            }
        ],
        "demand": {
            "y_seats": 3460,
            "s_seats": 1503,
            "j_seats": 1722,
            "f_seats": 76,
            "r_seats": 117,
            "cargo_demand": 59851
        }
    },
    {
        "iata": "BOB",
        "icao": "NTTB",
        "airportName": "Bora Bora Airport",
        "location_country": "French Polynesia",
        "location_city": "Bora Bora",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.44440079",
        "longitude": "-151.7510071",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4921",
                "runway_width": "72",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3000",
                "runway_width": "140",
                "runway_material": "COR"
            }
        ],
        "demand": {
            "y_seats": 173085,
            "s_seats": 27621,
            "j_seats": 37777,
            "f_seats": 1786,
            "r_seats": 3936,
            "cargo_demand": 399712
        }
    },
    {
        "iata": "BOC",
        "icao": "MPBO",
        "airportName": "\"Bocas del Toro \"\"Isla Colon\"\" International Airport\"",
        "location_country": "Panama",
        "location_city": "Bocas Town",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.34085",
        "longitude": "-82.250801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4921",
                "runway_width": "85",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 195589,
            "s_seats": 39154,
            "j_seats": 58287,
            "f_seats": 15361,
            "r_seats": 5877,
            "cargo_demand": 94038
        }
    },
    {
        "iata": "BOD",
        "icao": "LFBD",
        "airportName": "Bordeaux-Merignac Airport",
        "location_country": "France",
        "location_city": "Aquitaine",
        "location_state": "Bordeaux",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "44.8283",
        "longitude": "-0.715556",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "7923",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 369095,
            "s_seats": 92768,
            "j_seats": 130653,
            "f_seats": 54193,
            "r_seats": 10000,
            "cargo_demand": 20878559
        }
    },
    {
        "iata": "BOG",
        "icao": "SKBO",
        "airportName": "El Dorado International Airport",
        "location_country": "Colombia",
        "location_city": "Bogota",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "4.70159",
        "longitude": "-74.1469",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "12467",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "12467",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 445228,
            "s_seats": 79912,
            "j_seats": 70260,
            "f_seats": 68141,
            "r_seats": 10000,
            "cargo_demand": 9694562
        }
    },
    {
        "iata": "BOH",
        "icao": "EGHH",
        "airportName": "Bournemouth Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Bournemouth",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.779999",
        "longitude": "-1.8425",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7451",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97768,
            "s_seats": 28492,
            "j_seats": 28690,
            "f_seats": 4061,
            "r_seats": 7475,
            "cargo_demand": 449750
        }
    },
    {
        "iata": "BOI",
        "icao": "KBOI",
        "airportName": "Boise Air Terminal",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Boise",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "43.5644",
        "longitude": "-116.223",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "9763",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 285220,
            "s_seats": 77137,
            "j_seats": 133171,
            "f_seats": 46906,
            "r_seats": 10000,
            "cargo_demand": 11961467
        }
    },
    {
        "iata": "BOJ",
        "icao": "LBBG",
        "airportName": "Burgas Airport",
        "location_country": "Bulgaria",
        "location_city": "Burgas",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "42.56959915",
        "longitude": "27.51519966",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 257496,
            "s_seats": 91988,
            "j_seats": 24936,
            "f_seats": 61148,
            "r_seats": 10000,
            "cargo_demand": 9450975
        }
    },
    {
        "iata": "BOM",
        "icao": "VABB",
        "airportName": "Chhatrapati Shivaji International Airport",
        "location_country": "India",
        "location_city": "Maharashtra",
        "location_state": "Mumbai",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "19.08869934",
        "longitude": "72.86789703",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "11312",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "9419",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124284,
            "s_seats": 74901,
            "j_seats": 56417,
            "f_seats": 64420,
            "r_seats": 10000,
            "cargo_demand": 27959826
        }
    },
    {
        "iata": "BON",
        "icao": "TNCB",
        "airportName": "Flamingo International Airport",
        "location_country": "Caribbean Netherlands",
        "location_city": "Bonaire",
        "location_state": "Kralendijk",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "12.131",
        "longitude": "-68.268501",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9449",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 456996,
            "s_seats": 83951,
            "j_seats": 61520,
            "f_seats": 39419,
            "r_seats": 10000,
            "cargo_demand": 17703414
        }
    },
    {
        "iata": "BOO",
        "icao": "ENBO",
        "airportName": "Bodo Airport",
        "location_country": "Norway",
        "location_city": "Bodo",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "67.26920319",
        "longitude": "14.36530018",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "11136",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 399271,
            "s_seats": 88094,
            "j_seats": 142345,
            "f_seats": 61926,
            "r_seats": 10000,
            "cargo_demand": 27600104
        }
    },
    {
        "iata": "BOS",
        "icao": "KBOS",
        "airportName": "Logan International Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Boston",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "42.3643",
        "longitude": "-71.005203",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "7861",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "10005",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "15L/33R",
                "runway_length": "2557",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "10083",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 450339,
            "s_seats": 74467,
            "j_seats": 61779,
            "f_seats": 47029,
            "r_seats": 10000,
            "cargo_demand": 13707282
        }
    },
    {
        "iata": "BOU",
        "icao": "LFLD",
        "airportName": "Bourges Airport",
        "location_country": "France",
        "location_city": "Centre-Val de Loire",
        "location_state": "Bourges",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.05810165",
        "longitude": "2.370280027",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5085",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70489,
            "s_seats": 46058,
            "j_seats": 43841,
            "f_seats": 3964,
            "r_seats": 6764,
            "cargo_demand": 357969
        }
    },
    {
        "iata": "BOW",
        "icao": "KBOW",
        "airportName": "Bartow Municipal Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Bartow",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "27.943399",
        "longitude": "-81.783401",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "4400",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1647,
            "s_seats": 1850,
            "j_seats": 1183,
            "f_seats": 500,
            "r_seats": 170,
            "cargo_demand": 35760
        }
    },
    {
        "iata": "BOX",
        "icao": "YBRL",
        "airportName": "Borroloola Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Borroloola",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.07530022",
        "longitude": "136.302002",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3770",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3114,
            "s_seats": 2032,
            "j_seats": 1016,
            "f_seats": 185,
            "r_seats": 69,
            "cargo_demand": 11070
        }
    },
    {
        "iata": "BOY",
        "icao": "DFOO",
        "airportName": "Bobo Dioulasso Airport",
        "location_country": "Burkina Faso",
        "location_city": "Bobo-Dioulasso",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.16009998",
        "longitude": "-4.33096981",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10826",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99021,
            "s_seats": 27605,
            "j_seats": 15438,
            "f_seats": 1871,
            "r_seats": 3424,
            "cargo_demand": 206079
        }
    },
    {
        "iata": "BPC",
        "icao": "FKKV",
        "airportName": "Bamenda Airport",
        "location_country": "Cameroon",
        "location_city": "Bamenda",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.039239883",
        "longitude": "10.1225996",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113927,
            "s_seats": 43469,
            "j_seats": 54814,
            "f_seats": 14191,
            "r_seats": 7153,
            "cargo_demand": 309985
        }
    },
    {
        "iata": "BPG",
        "icao": "SBBW",
        "airportName": "Barra do GariCas Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Barra do GariCas",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-15.861417",
        "longitude": "-52.389008",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "5243",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62057,
            "s_seats": 32758,
            "j_seats": 8037,
            "f_seats": 15070,
            "r_seats": 4527,
            "cargo_demand": 473645
        }
    },
    {
        "iata": "BPH",
        "icao": "RPMF",
        "airportName": "Bislig Airport",
        "location_country": "Philippines",
        "location_city": "Bislig",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.195949554",
        "longitude": "126.3219986",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 95078,
            "s_seats": 49458,
            "j_seats": 30498,
            "f_seats": 5273,
            "r_seats": 7841,
            "cargo_demand": 256345
        }
    },
    {
        "iata": "BPI",
        "icao": "KBPI",
        "airportName": "Miley Memorial Field",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Big Piney",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.58509827",
        "longitude": "-110.1110001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3300",
                "runway_width": "140",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6803",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57628,
            "s_seats": 41389,
            "j_seats": 21270,
            "f_seats": 17793,
            "r_seats": 7988,
            "cargo_demand": 157112
        }
    },
    {
        "iata": "BPM",
        "icao": "VOHY",
        "airportName": "Begumpet Airport",
        "location_country": "India",
        "location_city": "Telangana",
        "location_state": "Hyderabad",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.4531002",
        "longitude": "78.46759796",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10600",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131923,
            "s_seats": 25658,
            "j_seats": 41617,
            "f_seats": 10250,
            "r_seats": 9160,
            "cargo_demand": 329674
        }
    },
    {
        "iata": "BPN",
        "icao": "WALL",
        "airportName": "Sultan Aji Muhammad Sulaiman Airport",
        "location_country": "Indonesia",
        "location_city": "Balikpapan",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.268270016",
        "longitude": "116.8939972",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8185",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3644,
            "s_seats": 2720,
            "j_seats": 1761,
            "f_seats": 41,
            "r_seats": 147,
            "cargo_demand": 12057
        }
    },
    {
        "iata": "BPS",
        "icao": "SBPS",
        "airportName": "Porto Seguro Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Porto Seguro",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.438601",
        "longitude": "-39.080898",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166678,
            "s_seats": 46668,
            "j_seats": 53937,
            "f_seats": 14399,
            "r_seats": 5689,
            "cargo_demand": 267242
        }
    },
    {
        "iata": "BPT",
        "icao": "KBPT",
        "airportName": "Jack Brooks Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Beaumont / Port Arthur",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "29.9508",
        "longitude": "-94.020699",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6750",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5070",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 145776,
            "s_seats": 31928,
            "j_seats": 30636,
            "f_seats": 3990,
            "r_seats": 7419,
            "cargo_demand": 159726
        }
    },
    {
        "iata": "BPX",
        "icao": "ZUBD",
        "airportName": "Qamdo Bamda Airport",
        "location_country": "China",
        "location_city": "Tibet Autonomous Region",
        "location_state": "Qamdo",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.55360031",
        "longitude": "97.10829926",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "18045",
                "runway_width": "148",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 125543,
            "s_seats": 35159,
            "j_seats": 13881,
            "f_seats": 11562,
            "r_seats": 3126,
            "cargo_demand": 184009
        }
    },
    {
        "iata": "BPY",
        "icao": "FMNQ",
        "airportName": "Besalampy Airport",
        "location_country": "Madagascar",
        "location_city": "Besalampy",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.74453",
        "longitude": "44.482484",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4046",
                "runway_width": "66",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 187484,
            "s_seats": 37378,
            "j_seats": 35511,
            "f_seats": 11140,
            "r_seats": 5506,
            "cargo_demand": 152590
        }
    },
    {
        "iata": "BQA",
        "icao": "RPUR",
        "airportName": "Dr. Juan C. Angara Airport",
        "location_country": "Philippines",
        "location_city": "Baler",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.729309",
        "longitude": "121.500034",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199946,
            "s_seats": 28481,
            "j_seats": 52522,
            "f_seats": 12688,
            "r_seats": 9364,
            "cargo_demand": 404420
        }
    },
    {
        "iata": "BQB",
        "icao": "YBLN",
        "airportName": "Busselton Margaret River Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Busselton",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-33.68842316",
        "longitude": "115.4015961",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3028,
            "s_seats": 2193,
            "j_seats": 1605,
            "f_seats": 368,
            "r_seats": 183,
            "cargo_demand": 44874
        }
    },
    {
        "iata": "BQH",
        "icao": "EGKB",
        "airportName": "London Biggin Hill Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "London / Biggin Hill",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.3307991",
        "longitude": "0.032499999",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5912",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2598",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160432,
            "s_seats": 33121,
            "j_seats": 25758,
            "f_seats": 15064,
            "r_seats": 8437,
            "cargo_demand": 70000
        }
    },
    {
        "iata": "BQK",
        "icao": "KBQK",
        "airportName": "Brunswick Golden Isles Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Brunswick",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.25880051",
        "longitude": "-81.46649933",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 57986,
            "s_seats": 25801,
            "j_seats": 24464,
            "f_seats": 3535,
            "r_seats": 5782,
            "cargo_demand": 336797
        }
    },
    {
        "iata": "BQL",
        "icao": "YBOU",
        "airportName": "Boulia Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Boulia",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.91329956",
        "longitude": "139.8999939",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4180",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199764,
            "s_seats": 49576,
            "j_seats": 27868,
            "f_seats": 9411,
            "r_seats": 3182,
            "cargo_demand": 142883
        }
    },
    {
        "iata": "BQN",
        "icao": "TJBQ",
        "airportName": "Rafael Hernandez Airport",
        "location_country": "United States",
        "location_city": "Puerto Rico",
        "location_state": "Aguadilla",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.49489975",
        "longitude": "-67.12940216",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11702",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80224,
            "s_seats": 47553,
            "j_seats": 52723,
            "f_seats": 9550,
            "r_seats": 8580,
            "cargo_demand": 376643
        }
    },
    {
        "iata": "BQQ",
        "icao": "SNBX",
        "airportName": "Barra Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Barra",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.08080006",
        "longitude": "-43.14749908",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1152,
            "s_seats": 2395,
            "j_seats": 1410,
            "f_seats": 71,
            "r_seats": 96,
            "cargo_demand": 35031
        }
    },
    {
        "iata": "BQS",
        "icao": "UHBB",
        "airportName": "Ignatyevo Airport",
        "location_country": "Russia",
        "location_city": "Amur Oblast",
        "location_state": "Blagoveshchensk",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.42539978",
        "longitude": "127.4120026",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9256",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142740,
            "s_seats": 28391,
            "j_seats": 56275,
            "f_seats": 6629,
            "r_seats": 6825,
            "cargo_demand": 45493
        }
    },
    {
        "iata": "BQT",
        "icao": "UMBB",
        "airportName": "Brest Airport",
        "location_country": "Belarus",
        "location_city": "Brest",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.108299",
        "longitude": "23.8981",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8596",
                "runway_width": "138",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159818,
            "s_seats": 44487,
            "j_seats": 26418,
            "f_seats": 10102,
            "r_seats": 8294,
            "cargo_demand": 426864
        }
    },
    {
        "iata": "BQU",
        "icao": "TVSB",
        "airportName": "J. F. Mitchell Airport",
        "location_country": "Saint Vincent and the Grenadines",
        "location_city": "Bequia",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.9884",
        "longitude": "-61.262001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89235,
            "s_seats": 34832,
            "j_seats": 52709,
            "f_seats": 18481,
            "r_seats": 9805,
            "cargo_demand": 333120
        }
    },
    {
        "iata": "BQW",
        "icao": "YBGO",
        "airportName": "Balgo Hill Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Balgo Hill",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.1483",
        "longitude": "127.973",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2270",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5282",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1316,
            "s_seats": 1717,
            "j_seats": 1311,
            "f_seats": 346,
            "r_seats": 128,
            "cargo_demand": 62371
        }
    },
    {
        "iata": "BRA",
        "icao": "SNBR",
        "airportName": "Barreiras Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Barreiras",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.07890034",
        "longitude": "-45.00899887",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "5249",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1597,
            "s_seats": 1697,
            "j_seats": 1700,
            "f_seats": 411,
            "r_seats": 115,
            "cargo_demand": 4744
        }
    },
    {
        "iata": "BRB",
        "icao": "SBRR",
        "airportName": "Barreirinhas Airport",
        "location_country": "Brazil",
        "location_city": "Maranhio",
        "location_state": "Barreirinhas",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.756628",
        "longitude": "-42.805666",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "Asph"
            }
        ],
        "demand": {
            "y_seats": 2908,
            "s_seats": 2141,
            "j_seats": 1619,
            "f_seats": 281,
            "r_seats": 4,
            "cargo_demand": 83426
        }
    },
    {
        "iata": "BRC",
        "icao": "SAZS",
        "airportName": "San Carlos de Bariloche Airport",
        "location_country": "Argentina",
        "location_city": "Rio Negro",
        "location_state": "San Carlos de Bariloche",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-41.151199",
        "longitude": "-71.157501",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7703",
                "runway_width": "157",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105083,
            "s_seats": 37983,
            "j_seats": 35134,
            "f_seats": 12828,
            "r_seats": 5543,
            "cargo_demand": 354141
        }
    },
    {
        "iata": "BRD",
        "icao": "KBRD",
        "airportName": "Brainerd Lakes Regional Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Brainerd",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.39830017",
        "longitude": "-94.13809967",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2800",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4080",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "CONC-E"
            }
        ],
        "demand": {
            "y_seats": 96268,
            "s_seats": 44582,
            "j_seats": 43374,
            "f_seats": 4726,
            "r_seats": 5664,
            "cargo_demand": 492325
        }
    },
    {
        "iata": "BRE",
        "icao": "EDDW",
        "airportName": "Bremen Airport",
        "location_country": "Germany",
        "location_city": "Bremen",
        "location_state": "Bremen",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "53.04750061",
        "longitude": "8.786669731",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2297",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "6693",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 390019,
            "s_seats": 80239,
            "j_seats": 96438,
            "f_seats": 46304,
            "r_seats": 10000,
            "cargo_demand": 29246097
        }
    },
    {
        "iata": "BRI",
        "icao": "LIBD",
        "airportName": "Bari Karol Wojty\u0142a Airport",
        "location_country": "Italy",
        "location_city": "Apulia",
        "location_state": "Bari",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "41.138901",
        "longitude": "16.760599",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "512",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 424408,
            "s_seats": 71153,
            "j_seats": 118322,
            "f_seats": 69304,
            "r_seats": 10000,
            "cargo_demand": 8685918
        }
    },
    {
        "iata": "BRK",
        "icao": "YBKE",
        "airportName": "Bourke Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Bourke",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-30.03919983",
        "longitude": "145.9519958",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6004",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3281",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 195340,
            "s_seats": 40959,
            "j_seats": 37951,
            "f_seats": 5482,
            "r_seats": 5133,
            "cargo_demand": 103959
        }
    },
    {
        "iata": "BRL",
        "icao": "KBRL",
        "airportName": "Southeast Iowa Regional Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Burlington",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.78319931",
        "longitude": "-91.12550354",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5350",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6702",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 54676,
            "s_seats": 32575,
            "j_seats": 19198,
            "f_seats": 5804,
            "r_seats": 3784,
            "cargo_demand": 457851
        }
    },
    {
        "iata": "BRM",
        "icao": "SVBM",
        "airportName": "Jacinto Lara International Airport",
        "location_country": "Venezuela",
        "location_city": "Barquisimeto",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.04274654",
        "longitude": "-69.35861969",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9350",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81657,
            "s_seats": 47555,
            "j_seats": 13641,
            "f_seats": 16721,
            "r_seats": 4337,
            "cargo_demand": 144837
        }
    },
    {
        "iata": "BRN",
        "icao": "LSZB",
        "airportName": "Bern Airport",
        "location_country": "Switzerland",
        "location_city": "Bern",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.91410065",
        "longitude": "7.497149944",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5676",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "2133",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "2125",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 165537,
            "s_seats": 47588,
            "j_seats": 39918,
            "f_seats": 13277,
            "r_seats": 7448,
            "cargo_demand": 273860
        }
    },
    {
        "iata": "BRO",
        "icao": "KBRO",
        "airportName": "Brownsville/South Padre Island International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Brownsville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "25.90679932",
        "longitude": "-97.42590332",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "7400",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175803,
            "s_seats": 28050,
            "j_seats": 41580,
            "f_seats": 17487,
            "r_seats": 9474,
            "cargo_demand": 59512
        }
    },
    {
        "iata": "BRP",
        "icao": "AYBR",
        "airportName": "Biaru Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Biaru",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.6693",
        "longitude": "146.7594",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "1982",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2372,
            "s_seats": 1878,
            "j_seats": 1820,
            "f_seats": 331,
            "r_seats": 171,
            "cargo_demand": 60006
        }
    },
    {
        "iata": "BRQ",
        "icao": "LKTB",
        "airportName": "Brno-Tu\u0159any Airport",
        "location_country": "Czech Republic",
        "location_city": "Brno",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.15129852",
        "longitude": "16.69440079",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2624",
                "runway_width": "98",
                "runway_material": ""
            },
            {
                "runway_name": "09/27",
                "runway_length": "8694",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 191935,
            "s_seats": 25443,
            "j_seats": 51539,
            "f_seats": 13449,
            "r_seats": 6497,
            "cargo_demand": 177335
        }
    },
    {
        "iata": "BRR",
        "icao": "EGPR",
        "airportName": "Barra Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Barra",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "57.0228",
        "longitude": "-7.44306",
        "region": "EU",
        "runway": [
            {
                "runway_name": "0/",
                "runway_length": "2621",
                "runway_width": "197",
                "runway_material": "SAND"
            },
            {
                "runway_name": "0/",
                "runway_length": "2231",
                "runway_width": "151",
                "runway_material": "SAND"
            },
            {
                "runway_name": "0/",
                "runway_length": "2776",
                "runway_width": "151",
                "runway_material": "SAND"
            }
        ],
        "demand": {
            "y_seats": 95000,
            "s_seats": 40938,
            "j_seats": 9962,
            "f_seats": 6217,
            "r_seats": 4869,
            "cargo_demand": 146718
        }
    },
    {
        "iata": "BRS",
        "icao": "EGGD",
        "airportName": "Bristol Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Bristol",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "51.382702",
        "longitude": "-2.71909",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6597",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 475790,
            "s_seats": 83792,
            "j_seats": 28218,
            "f_seats": 57852,
            "r_seats": 10000,
            "cargo_demand": 24436698
        }
    },
    {
        "iata": "BRT",
        "icao": "YBTI",
        "airportName": "Bathurst Island Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Bathurst Island",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.76920033",
        "longitude": "130.6199951",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4823",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178801,
            "s_seats": 43812,
            "j_seats": 12772,
            "f_seats": 7392,
            "r_seats": 8941,
            "cargo_demand": 103341
        }
    },
    {
        "iata": "BRU",
        "icao": "EBBR",
        "airportName": "Brussels Airport",
        "location_country": "Belgium",
        "location_city": "Brussels",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "50.90140152",
        "longitude": "4.48443985",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9800",
                "runway_width": "164",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "11936",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "10535",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 391472,
            "s_seats": 93474,
            "j_seats": 147717,
            "f_seats": 21427,
            "r_seats": 10000,
            "cargo_demand": 26477167
        }
    },
    {
        "iata": "BRW",
        "icao": "PABR",
        "airportName": "Wiley Post-Will Rogers Memorial Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Barrow",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "71.285402",
        "longitude": "-156.766008",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74428,
            "s_seats": 49786,
            "j_seats": 19359,
            "f_seats": 9265,
            "r_seats": 7894,
            "cargo_demand": 217946
        }
    },
    {
        "iata": "BRX",
        "icao": "MDBH",
        "airportName": "Maria Montez International Airport",
        "location_country": "Dominican Republic",
        "location_city": "Barahona",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.25149918",
        "longitude": "-71.12039948",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71817,
            "s_seats": 39035,
            "j_seats": 11705,
            "f_seats": 17854,
            "r_seats": 6966,
            "cargo_demand": 458981
        }
    },
    {
        "iata": "BRY",
        "icao": "KBRY",
        "airportName": "Samuels Field",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Bardstown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.81430054",
        "longitude": "-85.49960327",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5003",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1946,
            "s_seats": 2815,
            "j_seats": 1350,
            "f_seats": 355,
            "r_seats": 65,
            "cargo_demand": 10699
        }
    },
    {
        "iata": "BSA",
        "icao": "HCMF",
        "airportName": "Bender Qassim International Airport",
        "location_country": "Somalia",
        "location_city": "Bosaso",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "11.275235",
        "longitude": "49.139231",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3550",
                "runway_width": "115",
                "runway_material": "SAN"
            },
            {
                "runway_name": "09/27",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1680,
            "s_seats": 2559,
            "j_seats": 1316,
            "f_seats": 20,
            "r_seats": 7,
            "cargo_demand": 51531
        }
    },
    {
        "iata": "BSB",
        "icao": "SBBR",
        "airportName": "Brasilia International Airport",
        "location_country": "Brazil",
        "location_city": "Distrito Federal",
        "location_state": "Brasilia",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "large_airport",
        "latitude": "-15.869167",
        "longitude": "-47.920834",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11L/29R",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187812,
            "s_seats": 74538,
            "j_seats": 47692,
            "f_seats": 41577,
            "r_seats": 10000,
            "cargo_demand": 29721437
        }
    },
    {
        "iata": "BSC",
        "icao": "SKBS",
        "airportName": "Jose Celestino Mutis Airport",
        "location_country": "Colombia",
        "location_city": "Bahia Solano",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.20292",
        "longitude": "-77.3947",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3973",
                "runway_width": "78",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64309,
            "s_seats": 26409,
            "j_seats": 49878,
            "f_seats": 9000,
            "r_seats": 8052,
            "cargo_demand": 373586
        }
    },
    {
        "iata": "BSF",
        "icao": "PHSF",
        "airportName": "Bradshaw Army Airfield",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Camp Pohakuloa",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.760099",
        "longitude": "-155.554001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3695",
                "runway_width": "90",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55008,
            "s_seats": 46370,
            "j_seats": 9152,
            "f_seats": 14289,
            "r_seats": 9648,
            "cargo_demand": 276832
        }
    },
    {
        "iata": "BSG",
        "icao": "FGBT",
        "airportName": "Bata Airport",
        "location_country": "Equatorial Guinea",
        "location_city": "Bata",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.90547",
        "longitude": "9.80568",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10860",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04/22",
                "runway_length": "7001",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106183,
            "s_seats": 32183,
            "j_seats": 41272,
            "f_seats": 12135,
            "r_seats": 5244,
            "cargo_demand": 331095
        }
    },
    {
        "iata": "BSJ",
        "icao": "YBNS",
        "airportName": "Bairnsdale Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Bairnsdale",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-37.88750076",
        "longitude": "147.5679932",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3612",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2789",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108695,
            "s_seats": 38895,
            "j_seats": 8386,
            "f_seats": 3177,
            "r_seats": 6806,
            "cargo_demand": 355977
        }
    },
    {
        "iata": "BSK",
        "icao": "DAUB",
        "airportName": "Biskra Airport",
        "location_country": "Algeria",
        "location_city": "Biskra",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.793301",
        "longitude": "5.73823",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9469",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88808,
            "s_seats": 48864,
            "j_seats": 27862,
            "f_seats": 9949,
            "r_seats": 8339,
            "cargo_demand": 156448
        }
    },
    {
        "iata": "BSL",
        "icao": "LFSB",
        "airportName": "EuroAirport Basel-Mulhouse-Freiburg",
        "location_country": "Switzerland",
        "location_city": "Basel",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "47.59",
        "longitude": "7.529167",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5969",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "15/33",
                "runway_length": "12795",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 202390,
            "s_seats": 78903,
            "j_seats": 132917,
            "f_seats": 21318,
            "r_seats": 10000,
            "cargo_demand": 9454095
        }
    },
    {
        "iata": "BSM",
        "icao": "OINJ",
        "airportName": "Bishe Kola Air Base",
        "location_country": "Iran",
        "location_city": "Amol",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "small_airport",
        "latitude": "36.65510178",
        "longitude": "52.34960175",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3235",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2109,
            "s_seats": 2796,
            "j_seats": 1327,
            "f_seats": 299,
            "r_seats": 163,
            "cargo_demand": 88482
        }
    },
    {
        "iata": "BSO",
        "icao": "RPUO",
        "airportName": "Basco Airport",
        "location_country": "Philippines",
        "location_city": "Basco",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.4513",
        "longitude": "121.980003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4101",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 195025,
            "s_seats": 48960,
            "j_seats": 57523,
            "f_seats": 5266,
            "r_seats": 7601,
            "cargo_demand": 173677
        }
    },
    {
        "iata": "BSR",
        "icao": "ORMM",
        "airportName": "Basra International Airport",
        "location_country": "Iraq",
        "location_city": "Basra",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "30.549101",
        "longitude": "47.662102",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "13124",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 466533,
            "s_seats": 72106,
            "j_seats": 41843,
            "f_seats": 32664,
            "r_seats": 10000,
            "cargo_demand": 22209359
        }
    },
    {
        "iata": "BSS",
        "icao": "SNBS",
        "airportName": "Balsas Airport",
        "location_country": "Brazil",
        "location_city": "Maranhio",
        "location_state": "Balsas",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.526030064",
        "longitude": "-46.05329895",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3281",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2575,
            "s_seats": 1639,
            "j_seats": 1942,
            "f_seats": 5,
            "r_seats": 183,
            "cargo_demand": 3785
        }
    },
    {
        "iata": "BST",
        "icao": "OABT",
        "airportName": "Bost Airport",
        "location_country": "Afghanistan",
        "location_city": "Lashkar Gah (Bost)",
        "location_state": "",
        "time_zone": "UTC+04:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.5597",
        "longitude": "64.364998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7549",
                "runway_width": "101",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 2402,
            "s_seats": 1957,
            "j_seats": 1252,
            "f_seats": 122,
            "r_seats": 135,
            "cargo_demand": 19410
        }
    },
    {
        "iata": "BSU",
        "icao": "FZEN",
        "airportName": "Basankusu Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Basankusu",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "1.22472",
        "longitude": "19.7889",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4856",
                "runway_width": "",
                "runway_material": "Dirt"
            }
        ],
        "demand": {
            "y_seats": 3360,
            "s_seats": 2952,
            "j_seats": 1824,
            "f_seats": 94,
            "r_seats": 75,
            "cargo_demand": 30141
        }
    },
    {
        "iata": "BSX",
        "icao": "VYPN",
        "airportName": "Pathein Airport",
        "location_country": "Myanmar",
        "location_city": "Pathein",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "16.815201",
        "longitude": "94.7799",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4400",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1210,
            "s_seats": 2944,
            "j_seats": 1904,
            "f_seats": 435,
            "r_seats": 172,
            "cargo_demand": 65018
        }
    },
    {
        "iata": "BSY",
        "icao": "HCMD",
        "airportName": "Bardera Airport",
        "location_country": "Somalia",
        "location_city": "Bardera",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "2.330195",
        "longitude": "42.311802",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4017",
                "runway_width": "",
                "runway_material": "sand"
            }
        ],
        "demand": {
            "y_seats": 1654,
            "s_seats": 1517,
            "j_seats": 1496,
            "f_seats": 124,
            "r_seats": 5,
            "cargo_demand": 21222
        }
    },
    {
        "iata": "BTC",
        "icao": "VCCB",
        "airportName": "Batticaloa Airport",
        "location_country": "Sri Lanka",
        "location_city": "Batticaloa",
        "location_state": "",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.70576",
        "longitude": "81.678802",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3592",
                "runway_width": "40",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81356,
            "s_seats": 35773,
            "j_seats": 53453,
            "f_seats": 6044,
            "r_seats": 5981,
            "cargo_demand": 192626
        }
    },
    {
        "iata": "BTF",
        "icao": "KBTF",
        "airportName": "Skypark Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Bountiful",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.86940002",
        "longitude": "-111.927002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4700",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3747,
            "s_seats": 2152,
            "j_seats": 1256,
            "f_seats": 284,
            "r_seats": 33,
            "cargo_demand": 81318
        }
    },
    {
        "iata": "BTH",
        "icao": "WIDD",
        "airportName": "Hang Nadim Airport",
        "location_country": "Indonesia",
        "location_city": "Batam",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.121029973",
        "longitude": "104.1190033",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "13270",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152498,
            "s_seats": 40564,
            "j_seats": 41089,
            "f_seats": 6912,
            "r_seats": 9937,
            "cargo_demand": 317415
        }
    },
    {
        "iata": "BTI",
        "icao": "PABA",
        "airportName": "Barter Island LRRS Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Barter Island",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "70.134003",
        "longitude": "-143.582001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4820",
                "runway_width": "100",
                "runway_material": "GRAVEL-P"
            }
        ],
        "demand": {
            "y_seats": 170593,
            "s_seats": 34780,
            "j_seats": 15622,
            "f_seats": 16637,
            "r_seats": 8044,
            "cargo_demand": 294879
        }
    },
    {
        "iata": "BTJ",
        "icao": "WITT",
        "airportName": "Sultan Iskandar Muda International Airport",
        "location_country": "Indonesia",
        "location_city": "Banda Aceh",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.522872024",
        "longitude": "95.42063713",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89520,
            "s_seats": 37665,
            "j_seats": 27905,
            "f_seats": 17216,
            "r_seats": 5098,
            "cargo_demand": 148957
        }
    },
    {
        "iata": "BTK",
        "icao": "UIBB",
        "airportName": "Bratsk Airport",
        "location_country": "Russia",
        "location_city": "Irkutsk Oblast",
        "location_state": "Bratsk",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.37060165",
        "longitude": "101.697998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "10368",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 152931,
            "s_seats": 44195,
            "j_seats": 32369,
            "f_seats": 12347,
            "r_seats": 6209,
            "cargo_demand": 135553
        }
    },
    {
        "iata": "BTL",
        "icao": "KBTL",
        "airportName": "W. K. Kellogg Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Battle Creek",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.307301",
        "longitude": "-85.251503",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10003",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4018",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4835",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193545,
            "s_seats": 49381,
            "j_seats": 9457,
            "f_seats": 4834,
            "r_seats": 7622,
            "cargo_demand": 142178
        }
    },
    {
        "iata": "BTM",
        "icao": "KBTM",
        "airportName": "Bert Mooney Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Butte",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "45.95479965",
        "longitude": "-112.4970016",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5545",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5100",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "9001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89501,
            "s_seats": 46933,
            "j_seats": 46906,
            "f_seats": 1070,
            "r_seats": 7942,
            "cargo_demand": 347435
        }
    },
    {
        "iata": "BTN",
        "icao": "KBBP",
        "airportName": "Marlboro County Jetport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Bennettsville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.62170029",
        "longitude": "-79.73439789",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2749,
            "s_seats": 1798,
            "j_seats": 1415,
            "f_seats": 340,
            "r_seats": 64,
            "cargo_demand": 12290
        }
    },
    {
        "iata": "BTP",
        "icao": "KBTP",
        "airportName": "Butler County Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Butler",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.776901",
        "longitude": "-79.949699",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4005",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2531,
            "s_seats": 1734,
            "j_seats": 1058,
            "f_seats": 130,
            "r_seats": 81,
            "cargo_demand": 49187
        }
    },
    {
        "iata": "BTQ",
        "icao": "HRYI",
        "airportName": "Butare Airport",
        "location_country": "Rwanda",
        "location_city": "Butare",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.595829964",
        "longitude": "29.73670006",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4000",
                "runway_width": "50",
                "runway_material": "'asphalt'"
            }
        ],
        "demand": {
            "y_seats": 3731,
            "s_seats": 2942,
            "j_seats": 1693,
            "f_seats": 299,
            "r_seats": 22,
            "cargo_demand": 91490
        }
    },
    {
        "iata": "BTR",
        "icao": "KBTR",
        "airportName": "Baton Rouge Metropolitan Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Baton Rouge",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.533199",
        "longitude": "-91.149597",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "6900",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "3799",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7004",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159386,
            "s_seats": 29262,
            "j_seats": 41963,
            "f_seats": 17222,
            "r_seats": 5669,
            "cargo_demand": 331373
        }
    },
    {
        "iata": "BTS",
        "icao": "LZIB",
        "airportName": "M. R. \u0160tefanik Airport",
        "location_country": "Slovakia",
        "location_city": "Bratislava",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "48.17020035",
        "longitude": "17.21269989",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9515",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "10466",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 240410,
            "s_seats": 89145,
            "j_seats": 127240,
            "f_seats": 44543,
            "r_seats": 10000,
            "cargo_demand": 7112928
        }
    },
    {
        "iata": "BTT",
        "icao": "PABT",
        "airportName": "Bettles Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Bettles",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "66.91390228",
        "longitude": "-151.529007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5190",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 51596,
            "s_seats": 34982,
            "j_seats": 54748,
            "f_seats": 18044,
            "r_seats": 9632,
            "cargo_demand": 253931
        }
    },
    {
        "iata": "BTU",
        "icao": "WBGB",
        "airportName": "Bintulu Airport",
        "location_country": "Malaysia",
        "location_city": "Sarawak",
        "location_state": "Bintulu",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.123850107",
        "longitude": "113.0199966",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9006",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125476,
            "s_seats": 46088,
            "j_seats": 12480,
            "f_seats": 18365,
            "r_seats": 9753,
            "cargo_demand": 435767
        }
    },
    {
        "iata": "BTV",
        "icao": "KBTV",
        "airportName": "Burlington International Airport",
        "location_country": "United States",
        "location_city": "Vermont",
        "location_state": "Burlington",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.47190094",
        "longitude": "-73.15329742",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3611",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "8320",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68649,
            "s_seats": 29900,
            "j_seats": 18800,
            "f_seats": 12818,
            "r_seats": 9776,
            "cargo_demand": 355429
        }
    },
    {
        "iata": "BTW",
        "icao": "WAOC",
        "airportName": "Batu Licin Airport",
        "location_country": "Indonesia",
        "location_city": "Batu Licin",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.412410021",
        "longitude": "115.9950027",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5930",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3379,
            "s_seats": 2498,
            "j_seats": 1408,
            "f_seats": 356,
            "r_seats": 110,
            "cargo_demand": 72587
        }
    },
    {
        "iata": "BTX",
        "icao": "YBEO",
        "airportName": "Betoota Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Betoota",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.64170074",
        "longitude": "140.7830048",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3543",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 2334,
            "s_seats": 2691,
            "j_seats": 1391,
            "f_seats": 315,
            "r_seats": 62,
            "cargo_demand": 13646
        }
    },
    {
        "iata": "BTY",
        "icao": "KBTY",
        "airportName": "Beatty Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Beatty",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.86109924",
        "longitude": "-116.7870026",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5600",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3335,
            "s_seats": 1859,
            "j_seats": 1390,
            "f_seats": 132,
            "r_seats": 103,
            "cargo_demand": 79949
        }
    },
    {
        "iata": "BUA",
        "icao": "AYBK",
        "airportName": "Buka Island Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Buka Island",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.422319889",
        "longitude": "154.6730042",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5125",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180132,
            "s_seats": 38733,
            "j_seats": 27572,
            "f_seats": 1831,
            "r_seats": 5493,
            "cargo_demand": 360150
        }
    },
    {
        "iata": "BUB",
        "icao": "KBUB",
        "airportName": "Cram Field",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Burwell",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.77669907",
        "longitude": "-99.14969635",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3212",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1455,
            "s_seats": 2601,
            "j_seats": 1392,
            "f_seats": 425,
            "r_seats": 43,
            "cargo_demand": 24668
        }
    },
    {
        "iata": "BUC",
        "icao": "YBKT",
        "airportName": "Burketown Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Burketown",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.74860001",
        "longitude": "139.5339966",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4501",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2484",
                "runway_width": "98",
                "runway_material": "CLA"
            }
        ],
        "demand": {
            "y_seats": 2064,
            "s_seats": 1728,
            "j_seats": 1047,
            "f_seats": 93,
            "r_seats": 107,
            "cargo_demand": 26137
        }
    },
    {
        "iata": "BUD",
        "icao": "LHBP",
        "airportName": "Budapest Ferenc Liszt International Airport",
        "location_country": "Hungary",
        "location_city": "Budapest",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "47.42976",
        "longitude": "19.261093",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "12162",
                "runway_width": "149",
                "runway_material": "CON"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "9875",
                "runway_width": "149",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 262891,
            "s_seats": 82375,
            "j_seats": 59585,
            "f_seats": 62441,
            "r_seats": 10000,
            "cargo_demand": 28983204
        }
    },
    {
        "iata": "BUF",
        "icao": "KBUF",
        "airportName": "Buffalo Niagara International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Buffalo",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "42.94049835",
        "longitude": "-78.73220062",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8102",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "7161",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 378000,
            "s_seats": 84083,
            "j_seats": 81209,
            "f_seats": 57600,
            "r_seats": 10000,
            "cargo_demand": 27345624
        }
    },
    {
        "iata": "BUG",
        "icao": "FNBG",
        "airportName": "Benguela Airport",
        "location_country": "Angola",
        "location_city": "Benguela",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.609",
        "longitude": "13.4037",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5315",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198843,
            "s_seats": 29890,
            "j_seats": 7486,
            "f_seats": 1271,
            "r_seats": 7318,
            "cargo_demand": 180742
        }
    },
    {
        "iata": "BUI",
        "icao": "WAJB",
        "airportName": "Bokondini Airport",
        "location_country": "Indonesia",
        "location_city": "Bokondini",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.6822",
        "longitude": "138.6755",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2798",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2856,
            "s_seats": 1787,
            "j_seats": 1533,
            "f_seats": 150,
            "r_seats": 149,
            "cargo_demand": 1737
        }
    },
    {
        "iata": "BUJ",
        "icao": "DAAD",
        "airportName": "Bou Saada Airport",
        "location_country": "Algeria",
        "location_city": "Bou Sa\u00e2da",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.3325",
        "longitude": "4.20639",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7218",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114761,
            "s_seats": 40771,
            "j_seats": 5447,
            "f_seats": 17220,
            "r_seats": 4960,
            "cargo_demand": 314123
        }
    },
    {
        "iata": "BUM",
        "icao": "KBUM",
        "airportName": "Butler Memorial Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Butler",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.28979874",
        "longitude": "-94.34010315",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3127,
            "s_seats": 2174,
            "j_seats": 1798,
            "f_seats": 29,
            "r_seats": 80,
            "cargo_demand": 33203
        }
    },
    {
        "iata": "BUN",
        "icao": "SKBU",
        "airportName": "Gerardo Tobar Lopez Airport",
        "location_country": "Colombia",
        "location_city": "Buenaventura",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.81963",
        "longitude": "-76.9898",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3945",
                "runway_width": "76",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197231,
            "s_seats": 43027,
            "j_seats": 38625,
            "f_seats": 14819,
            "r_seats": 9916,
            "cargo_demand": 323770
        }
    },
    {
        "iata": "BUP",
        "icao": "VIBT",
        "airportName": "Bathinda Airport",
        "location_country": "India",
        "location_city": "Punjab",
        "location_state": "Bathinda",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.27009964",
        "longitude": "74.75579834",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106617,
            "s_seats": 33509,
            "j_seats": 48502,
            "f_seats": 10878,
            "r_seats": 7047,
            "cargo_demand": 321600
        }
    },
    {
        "iata": "BUQ",
        "icao": "FVBU",
        "airportName": "Joshua Mqabuko Nkomo International Airport",
        "location_country": "Zimbabwe",
        "location_city": "Bulawayo",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.017401",
        "longitude": "28.617901",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4419",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "8491",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176572,
            "s_seats": 39639,
            "j_seats": 33094,
            "f_seats": 18145,
            "r_seats": 9550,
            "cargo_demand": 469537
        }
    },
    {
        "iata": "BUR",
        "icao": "KBUR",
        "airportName": "Bob Hope Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Burbank",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.197703",
        "longitude": "-118.356378",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5801",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6886",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66794,
            "s_seats": 34240,
            "j_seats": 56624,
            "f_seats": 10920,
            "r_seats": 7582,
            "cargo_demand": 368016
        }
    },
    {
        "iata": "BUS",
        "icao": "UGSB",
        "airportName": "Batumi International Airport",
        "location_country": "Georgia",
        "location_city": "Batumi",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.61029816",
        "longitude": "41.59970093",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150326,
            "s_seats": 34558,
            "j_seats": 31692,
            "f_seats": 6545,
            "r_seats": 7245,
            "cargo_demand": 184667
        }
    },
    {
        "iata": "BUT",
        "icao": "VQBT",
        "airportName": "Bathpalathang Airport",
        "location_country": "Bhutan",
        "location_city": "Jakar",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.5622",
        "longitude": "90.7471",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3660,
            "s_seats": 2008,
            "j_seats": 1322,
            "f_seats": 83,
            "r_seats": 144,
            "cargo_demand": 35107
        }
    },
    {
        "iata": "BUU",
        "icao": "WIPI",
        "airportName": "Muara Bungo Airport",
        "location_country": "Indonesia",
        "location_city": "Jambi",
        "location_state": "Bungo Regency",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.1278",
        "longitude": "102.135",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4430",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3961,
            "s_seats": 2869,
            "j_seats": 1994,
            "f_seats": 172,
            "r_seats": 147,
            "cargo_demand": 27466
        }
    },
    {
        "iata": "BUW",
        "icao": "WAWB",
        "airportName": "Betoambari Airport",
        "location_country": "Indonesia",
        "location_city": "Baubau",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.486879826",
        "longitude": "122.5690002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2409,
            "s_seats": 2176,
            "j_seats": 1089,
            "f_seats": 349,
            "r_seats": 82,
            "cargo_demand": 97210
        }
    },
    {
        "iata": "BUX",
        "icao": "FZKA",
        "airportName": "Bunia Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Bunia",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.565719962",
        "longitude": "30.2208004",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6070",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 84358,
            "s_seats": 38229,
            "j_seats": 49083,
            "f_seats": 8085,
            "r_seats": 8796,
            "cargo_demand": 140796
        }
    },
    {
        "iata": "BUY",
        "icao": "YBUN",
        "airportName": "Bunbury Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Bunbury",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-33.37829971",
        "longitude": "115.677002",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3330",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2793,
            "s_seats": 1859,
            "j_seats": 1796,
            "f_seats": 95,
            "r_seats": 34,
            "cargo_demand": 31298
        }
    },
    {
        "iata": "BUZ",
        "icao": "OIBB",
        "airportName": "Bushehr Airport",
        "location_country": "Iran",
        "location_city": "Bushehr",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "28.94479942",
        "longitude": "50.83459854",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "14663",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "14664",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197516,
            "s_seats": 42945,
            "j_seats": 53553,
            "f_seats": 18175,
            "r_seats": 7627,
            "cargo_demand": 196635
        }
    },
    {
        "iata": "BVA",
        "icao": "LFOB",
        "airportName": "Beauvais-Tille Airport",
        "location_country": "France",
        "location_city": "Picardy",
        "location_state": "Beauvais",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.454399",
        "longitude": "2.11278",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3625",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7972",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 132534,
            "s_seats": 38499,
            "j_seats": 57735,
            "f_seats": 19418,
            "r_seats": 5873,
            "cargo_demand": 274867
        }
    },
    {
        "iata": "BVB",
        "icao": "SBBV",
        "airportName": "Boa Vista International Airport",
        "location_country": "Brazil",
        "location_city": "Roraima",
        "location_state": "Boa Vista",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.845855",
        "longitude": "-60.690944",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71090,
            "s_seats": 32459,
            "j_seats": 15470,
            "f_seats": 6391,
            "r_seats": 7415,
            "cargo_demand": 223751
        }
    },
    {
        "iata": "BVC",
        "icao": "GVBA",
        "airportName": "Aristides Pereira International Airport",
        "location_country": "Cape Verde",
        "location_city": "Boa Vista",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.1364994",
        "longitude": "-22.88890076",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191252,
            "s_seats": 38916,
            "j_seats": 58196,
            "f_seats": 19759,
            "r_seats": 5964,
            "cargo_demand": 193886
        }
    },
    {
        "iata": "BVE",
        "icao": "LFSL",
        "airportName": "Brive-Souillac Airport",
        "location_country": "France",
        "location_city": "Limousin",
        "location_state": "Brive-la-Gaillarde",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.039722",
        "longitude": "1.485556",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6890",
                "runway_width": "147",
                "runway_material": "asphalt"
            },
            {
                "runway_name": "DELETE/DELETE",
                "runway_length": "",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 74927,
            "s_seats": 29623,
            "j_seats": 24969,
            "f_seats": 2753,
            "r_seats": 7091,
            "cargo_demand": 423391
        }
    },
    {
        "iata": "BVG",
        "icao": "ENBV",
        "airportName": "Berleviog Airport",
        "location_country": "Norway",
        "location_city": "Berleviog",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "70.871399",
        "longitude": "29.034201",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3372",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 85129,
            "s_seats": 30870,
            "j_seats": 38573,
            "f_seats": 4696,
            "r_seats": 7809,
            "cargo_demand": 111332
        }
    },
    {
        "iata": "BVH",
        "icao": "SBVH",
        "airportName": "Vilhena Airport",
        "location_country": "Brazil",
        "location_city": "Rondonia",
        "location_state": "Vilhena",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.69439983",
        "longitude": "-60.09830093",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "8530",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86409,
            "s_seats": 42016,
            "j_seats": 36582,
            "f_seats": 5639,
            "r_seats": 6545,
            "cargo_demand": 157312
        }
    },
    {
        "iata": "BVI",
        "icao": "YBDV",
        "airportName": "Birdsville Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Birdsville",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.89749908",
        "longitude": "139.3480072",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3937",
                "runway_width": "59",
                "runway_material": "CLA"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5682",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145184,
            "s_seats": 42642,
            "j_seats": 25960,
            "f_seats": 11510,
            "r_seats": 6416,
            "cargo_demand": 443250
        }
    },
    {
        "iata": "BVM",
        "icao": "SNBL",
        "airportName": "Belmonte Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Belmonte",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.8717",
        "longitude": "-38.871899",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1926,
            "s_seats": 1503,
            "j_seats": 1521,
            "f_seats": 350,
            "r_seats": 110,
            "cargo_demand": 67494
        }
    },
    {
        "iata": "BVO",
        "icao": "KBVO",
        "airportName": "Bartlesville Municipal Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Bartlesville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.76250076",
        "longitude": "-96.01119995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6200",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 1382,
            "s_seats": 1999,
            "j_seats": 1800,
            "f_seats": 40,
            "r_seats": 40,
            "cargo_demand": 56292
        }
    },
    {
        "iata": "BVS",
        "icao": "SNVS",
        "airportName": "Breves Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Breves",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.636530042",
        "longitude": "-50.4435997",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "5249",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3420,
            "s_seats": 2448,
            "j_seats": 1105,
            "f_seats": 374,
            "r_seats": 134,
            "cargo_demand": 37661
        }
    },
    {
        "iata": "BVU",
        "icao": "PABG",
        "airportName": "Beluga Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Beluga",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "61.17219925",
        "longitude": "-151.0440063",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2400",
                "runway_width": "60",
                "runway_material": "GRVL"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 1484,
            "s_seats": 2834,
            "j_seats": 1693,
            "f_seats": 122,
            "r_seats": 101,
            "cargo_demand": 6951
        }
    },
    {
        "iata": "BVV",
        "icao": "UHSB",
        "airportName": "Burevestnik Airport",
        "location_country": "Russia",
        "location_city": "Sakhalin Oblast",
        "location_state": "Burevestnik",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.919998",
        "longitude": "147.621994",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7808",
                "runway_width": "",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 1634,
            "s_seats": 1870,
            "j_seats": 1361,
            "f_seats": 424,
            "r_seats": 14,
            "cargo_demand": 49615
        }
    },
    {
        "iata": "BVX",
        "icao": "KBVX",
        "airportName": "Batesville Regional Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Batesville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.7262001",
        "longitude": "-91.64730072",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3078",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2089,
            "s_seats": 1667,
            "j_seats": 1386,
            "f_seats": 155,
            "r_seats": 184,
            "cargo_demand": 19838
        }
    },
    {
        "iata": "BVY",
        "icao": "KBVY",
        "airportName": "Beverly Municipal Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Beverly",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.584202",
        "longitude": "-70.916496",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4634",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148109,
            "s_seats": 36855,
            "j_seats": 15282,
            "f_seats": 8382,
            "r_seats": 3251,
            "cargo_demand": 158458
        }
    },
    {
        "iata": "BVZ",
        "icao": "YBYS",
        "airportName": "Beverley Springs Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Beverley Springs",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.73329926",
        "longitude": "125.4329987",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3605",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 3789,
            "s_seats": 1658,
            "j_seats": 1577,
            "f_seats": 499,
            "r_seats": 179,
            "cargo_demand": 70751
        }
    },
    {
        "iata": "BWA",
        "icao": "VNBW",
        "airportName": "Gautam Buddha Airport",
        "location_country": "Nepal",
        "location_city": "Siddharthanagar (Bhairawa)",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.505328",
        "longitude": "83.412594",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4955",
                "runway_width": "110",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50828,
            "s_seats": 26780,
            "j_seats": 11340,
            "f_seats": 11798,
            "r_seats": 3351,
            "cargo_demand": 396609
        }
    },
    {
        "iata": "BWB",
        "icao": "YBWX",
        "airportName": "Barrow Island Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Barrow Island",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.86440086",
        "longitude": "115.4059982",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6234",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2152,
            "s_seats": 1560,
            "j_seats": 1932,
            "f_seats": 126,
            "r_seats": 137,
            "cargo_demand": 93940
        }
    },
    {
        "iata": "BWC",
        "icao": "KBWC",
        "airportName": "Brawley Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Brawley",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.99290085",
        "longitude": "-115.5169983",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4402",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3545,
            "s_seats": 1696,
            "j_seats": 1518,
            "f_seats": 495,
            "r_seats": 53,
            "cargo_demand": 37822
        }
    },
    {
        "iata": "BWD",
        "icao": "KBWD",
        "airportName": "Brownwood Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Brownwood",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.79360008",
        "longitude": "-98.95649719",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4596",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5599",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1853,
            "s_seats": 2227,
            "j_seats": 1258,
            "f_seats": 283,
            "r_seats": 190,
            "cargo_demand": 12752
        }
    },
    {
        "iata": "BWE",
        "icao": "EDVE",
        "airportName": "Braunschweig-Wolfsburg Airport",
        "location_country": "Germany",
        "location_city": "Lower Saxony",
        "location_state": "Braunschweig",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "52.319199",
        "longitude": "10.5561",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "2953",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 160112,
            "s_seats": 27300,
            "j_seats": 51813,
            "f_seats": 8786,
            "r_seats": 5136,
            "cargo_demand": 206026
        }
    },
    {
        "iata": "BWF",
        "icao": "EGNL",
        "airportName": "Barrow/Walney Island Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Barrow-in-Furness",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "54.1286111",
        "longitude": "-3.2675",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3438",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "1326",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3318",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89856,
            "s_seats": 28988,
            "j_seats": 15311,
            "f_seats": 15093,
            "r_seats": 4752,
            "cargo_demand": 318489
        }
    },
    {
        "iata": "BWG",
        "icao": "KBWG",
        "airportName": "Bowling Green-Warren County Regional Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Bowling Green",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.96450043",
        "longitude": "-86.41970062",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3955",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140157,
            "s_seats": 48557,
            "j_seats": 56527,
            "f_seats": 10522,
            "r_seats": 6917,
            "cargo_demand": 150902
        }
    },
    {
        "iata": "BWH",
        "icao": "WMKB",
        "airportName": "RMAF Butterworth",
        "location_country": "Malaysia",
        "location_city": "Penang",
        "location_state": "Butterworth",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.46592",
        "longitude": "100.390999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 85786,
            "s_seats": 46519,
            "j_seats": 39951,
            "f_seats": 1373,
            "r_seats": 4943,
            "cargo_demand": 119734
        }
    },
    {
        "iata": "BWI",
        "icao": "KBWI",
        "airportName": "Baltimore/Washington International Thurgood Marshall Airport",
        "location_country": "United States",
        "location_city": "Baltimore-Washington metropolitan area",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "39.1754",
        "longitude": "-76.668297",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "10502",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15L/33R",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "9501",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 368621,
            "s_seats": 74673,
            "j_seats": 122188,
            "f_seats": 64926,
            "r_seats": 10000,
            "cargo_demand": 1288702
        }
    },
    {
        "iata": "BWK",
        "icao": "LDSB",
        "airportName": "Bol Airport",
        "location_country": "Croatia",
        "location_city": "Bol",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.285702",
        "longitude": "16.679701",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4724",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114643,
            "s_seats": 43843,
            "j_seats": 14113,
            "f_seats": 19952,
            "r_seats": 5384,
            "cargo_demand": 119612
        }
    },
    {
        "iata": "BWL",
        "icao": "KBKN",
        "airportName": "Blackwell-Tonkawa Municipal Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Blackwell",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.74509811",
        "longitude": "-97.34960175",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3500",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3505,
            "s_seats": 1518,
            "j_seats": 1539,
            "f_seats": 460,
            "r_seats": 183,
            "cargo_demand": 3311
        }
    },
    {
        "iata": "BWN",
        "icao": "WBSB",
        "airportName": "Brunei International Airport",
        "location_country": "Brunei",
        "location_city": "Bandar Seri Begawan",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "4.944200039",
        "longitude": "114.9280014",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "12000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 379636,
            "s_seats": 95245,
            "j_seats": 90667,
            "f_seats": 23578,
            "r_seats": 10000,
            "cargo_demand": 106910
        }
    },
    {
        "iata": "BWQ",
        "icao": "YBRW",
        "airportName": "Brewarrina Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Brewarrina",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-29.97389984",
        "longitude": "146.8170013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4547",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 133560,
            "s_seats": 45865,
            "j_seats": 47923,
            "f_seats": 13748,
            "r_seats": 4206,
            "cargo_demand": 372324
        }
    },
    {
        "iata": "BWT",
        "icao": "YWYY",
        "airportName": "Burnie Airport",
        "location_country": "Australia",
        "location_city": "Tasmania",
        "location_state": "Burnie",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-40.99890137",
        "longitude": "145.7310028",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2516",
                "runway_width": "98",
                "runway_material": "paved"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5413",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193046,
            "s_seats": 38600,
            "j_seats": 29873,
            "f_seats": 18984,
            "r_seats": 7179,
            "cargo_demand": 359244
        }
    },
    {
        "iata": "BWU",
        "icao": "YSBK",
        "airportName": "Bankstown Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Bankstown",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-33.92440033",
        "longitude": "150.9880066",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11C/29C",
                "runway_length": "4644",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11L/29R",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "3419",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128227,
            "s_seats": 44595,
            "j_seats": 51214,
            "f_seats": 11440,
            "r_seats": 8394,
            "cargo_demand": 146628
        }
    },
    {
        "iata": "BWW",
        "icao": "MUBR",
        "airportName": "Las Brujas Airport",
        "location_country": "Cuba",
        "location_city": "Cayo Santa Maria",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "22.62129974",
        "longitude": "-79.14720154",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5905",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1906,
            "s_seats": 1604,
            "j_seats": 1142,
            "f_seats": 277,
            "r_seats": 159,
            "cargo_demand": 71131
        }
    },
    {
        "iata": "BXA",
        "icao": "KBXA",
        "airportName": "George R. Carr Memorial Air Field",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Bogalusa",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "30.81369972",
        "longitude": "-89.86499786",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3799",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1923,
            "s_seats": 2079,
            "j_seats": 1102,
            "f_seats": 212,
            "r_seats": 113,
            "cargo_demand": 29086
        }
    },
    {
        "iata": "BXB",
        "icao": "WASO",
        "airportName": "Babo Airport",
        "location_country": "Indonesia",
        "location_city": "Babo",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.532239914",
        "longitude": "133.4389954",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4280",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166715,
            "s_seats": 36059,
            "j_seats": 17201,
            "f_seats": 19804,
            "r_seats": 3143,
            "cargo_demand": 64445
        }
    },
    {
        "iata": "BXD",
        "icao": "WAKE",
        "airportName": "Bade Airport",
        "location_country": "Indonesia",
        "location_city": "Bade",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.175902",
        "longitude": "139.58333",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "1969",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2623,
            "s_seats": 2353,
            "j_seats": 1347,
            "f_seats": 499,
            "r_seats": 16,
            "cargo_demand": 12433
        }
    },
    {
        "iata": "BXE",
        "icao": "GOTB",
        "airportName": "Bakel Airport",
        "location_country": "Senegal",
        "location_city": "Bakel",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.84729958",
        "longitude": "-12.46829987",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5741",
                "runway_width": "98",
                "runway_material": "MAC"
            }
        ],
        "demand": {
            "y_seats": 148273,
            "s_seats": 32093,
            "j_seats": 40138,
            "f_seats": 7771,
            "r_seats": 3604,
            "cargo_demand": 479799
        }
    },
    {
        "iata": "BXG",
        "icao": "YBDG",
        "airportName": "Bendigo Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Bendigo",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-36.73939896",
        "longitude": "144.3300018",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2516",
                "runway_width": "60",
                "runway_material": "paved"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3724",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2594,
            "s_seats": 2402,
            "j_seats": 1092,
            "f_seats": 174,
            "r_seats": 192,
            "cargo_demand": 62188
        }
    },
    {
        "iata": "BXH",
        "icao": "UAAH",
        "airportName": "Balkhash Airport",
        "location_country": "Kazakhstan",
        "location_city": "Balkhash",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.8932991",
        "longitude": "75.00499725",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8208",
                "runway_width": "131",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 101840,
            "s_seats": 31025,
            "j_seats": 57015,
            "f_seats": 9437,
            "r_seats": 4797,
            "cargo_demand": 490513
        }
    },
    {
        "iata": "BXK",
        "icao": "KBXK",
        "airportName": "Buckeye Municipal Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Buckeye",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.422397",
        "longitude": "-112.686317",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3310",
                "runway_width": "300",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3400",
                "runway_width": "270",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5500",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1018,
            "s_seats": 2805,
            "j_seats": 1434,
            "f_seats": 294,
            "r_seats": 40,
            "cargo_demand": 32650
        }
    },
    {
        "iata": "BXN",
        "icao": "LTBV",
        "airportName": "Bodrum-Imsik Airport",
        "location_country": "Turkey",
        "location_city": "Bodrum",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.140099",
        "longitude": "27.669701",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5151",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3080,
            "s_seats": 1693,
            "j_seats": 1311,
            "f_seats": 461,
            "r_seats": 138,
            "cargo_demand": 21995
        }
    },
    {
        "iata": "BXO",
        "icao": "LSZC",
        "airportName": "Buochs Airport",
        "location_country": "Switzerland",
        "location_city": "Buochs",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "46.974444",
        "longitude": "8.396944",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6561",
                "runway_width": "130",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "2771",
                "runway_width": "130",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3948,
            "s_seats": 2999,
            "j_seats": 1268,
            "f_seats": 392,
            "r_seats": 24,
            "cargo_demand": 11948
        }
    },
    {
        "iata": "BXR",
        "icao": "OIKM",
        "airportName": "Bam Airport",
        "location_country": "Iran",
        "location_city": "Bam",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "29.08419991",
        "longitude": "58.45000076",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "11107",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122404,
            "s_seats": 44520,
            "j_seats": 32018,
            "f_seats": 7549,
            "r_seats": 8401,
            "cargo_demand": 316701
        }
    },
    {
        "iata": "BXT",
        "icao": "WRLC",
        "airportName": "PT Badak Bontang Airport",
        "location_country": "Indonesia",
        "location_city": "Bontang",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.119691",
        "longitude": "117.474998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3350",
                "runway_width": "75",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 2335,
            "s_seats": 1874,
            "j_seats": 1641,
            "f_seats": 490,
            "r_seats": 161,
            "cargo_demand": 4977
        }
    },
    {
        "iata": "BXU",
        "icao": "RPME",
        "airportName": "Bancasi Airport",
        "location_country": "Philippines",
        "location_city": "Butuan",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.9515",
        "longitude": "125.4788",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6450",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 61451,
            "s_seats": 37831,
            "j_seats": 23568,
            "f_seats": 4118,
            "r_seats": 6767,
            "cargo_demand": 182245
        }
    },
    {
        "iata": "BYC",
        "icao": "SLYA",
        "airportName": "Yacuiba Airport",
        "location_country": "Bolivia",
        "location_city": "Yacuiba",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.96089935",
        "longitude": "-63.65169907",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6890",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178174,
            "s_seats": 26858,
            "j_seats": 41616,
            "f_seats": 4731,
            "r_seats": 7948,
            "cargo_demand": 253520
        }
    },
    {
        "iata": "BYF",
        "icao": "LFAQ",
        "airportName": "Albert - Picardie Airport",
        "location_country": "France",
        "location_city": "Picardy",
        "location_state": "Albert",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "49.9715004",
        "longitude": "2.697659969",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "3281",
                "runway_width": "262",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1679,
            "s_seats": 2339,
            "j_seats": 1486,
            "f_seats": 76,
            "r_seats": 37,
            "cargo_demand": 4308
        }
    },
    {
        "iata": "BYG",
        "icao": "KBYG",
        "airportName": "Johnson County Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Buffalo",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.3810997",
        "longitude": "-106.7220001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6143",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3239,
            "s_seats": 1673,
            "j_seats": 1219,
            "f_seats": 237,
            "r_seats": 12,
            "cargo_demand": 81234
        }
    },
    {
        "iata": "BYH",
        "icao": "KBYH",
        "airportName": "Arkansas International Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Blytheville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.96429825",
        "longitude": "-89.94400024",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "11602",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 90877,
            "s_seats": 48855,
            "j_seats": 25442,
            "f_seats": 9721,
            "r_seats": 3147,
            "cargo_demand": 412923
        }
    },
    {
        "iata": "BYI",
        "icao": "KBYI",
        "airportName": "Burley Municipal Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Burley",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.54259872",
        "longitude": "-113.7720032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4094",
                "runway_width": "80",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "4067",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90283,
            "s_seats": 47069,
            "j_seats": 47552,
            "f_seats": 2446,
            "r_seats": 6144,
            "cargo_demand": 228747
        }
    },
    {
        "iata": "BYJ",
        "icao": "LPBJ",
        "airportName": "Beja Airport",
        "location_country": "Portugal",
        "location_city": "Beja",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "38.078899",
        "longitude": "-7.9324",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "11319",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "9682",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 182996,
            "s_seats": 30162,
            "j_seats": 31399,
            "f_seats": 8213,
            "r_seats": 6357,
            "cargo_demand": 150019
        }
    },
    {
        "iata": "BYK",
        "icao": "DIBK",
        "airportName": "Bouake Airport",
        "location_country": "Ivory Coast",
        "location_city": "Bouake",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.7388",
        "longitude": "-5.07367",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196895,
            "s_seats": 44024,
            "j_seats": 43989,
            "f_seats": 1643,
            "r_seats": 4794,
            "cargo_demand": 159277
        }
    },
    {
        "iata": "BYM",
        "icao": "MUBY",
        "airportName": "Carlos Manuel de Cespedes Airport",
        "location_country": "Cuba",
        "location_city": "Bayamo",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "20.39640045",
        "longitude": "-76.62139893",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6887",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155616,
            "s_seats": 49145,
            "j_seats": 44088,
            "f_seats": 5318,
            "r_seats": 5107,
            "cargo_demand": 229703
        }
    },
    {
        "iata": "BYN",
        "icao": "ZMBH",
        "airportName": "Bayankhongor Airport",
        "location_country": "Mongolia",
        "location_city": "Bayankhongor",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.16329956",
        "longitude": "100.7040024",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9186",
                "runway_width": "114",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "6889",
                "runway_width": "164",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 175854,
            "s_seats": 42469,
            "j_seats": 13422,
            "f_seats": 5257,
            "r_seats": 7196,
            "cargo_demand": 468952
        }
    },
    {
        "iata": "BYO",
        "icao": "SBDB",
        "airportName": "Bonito Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso do Sul",
        "location_state": "Bonito",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-21.2473",
        "longitude": "-56.4525",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2475,
            "s_seats": 1586,
            "j_seats": 1090,
            "f_seats": 50,
            "r_seats": 5,
            "cargo_demand": 51259
        }
    },
    {
        "iata": "BYP",
        "icao": "YBRY",
        "airportName": "Barimunya Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Barimunya",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.6739006",
        "longitude": "119.1660004",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6365",
                "runway_width": "99",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 1783,
            "s_seats": 2214,
            "j_seats": 1783,
            "f_seats": 69,
            "r_seats": 138,
            "cargo_demand": 29222
        }
    },
    {
        "iata": "BYQ",
        "icao": "WALV",
        "airportName": "Bunyu Airport",
        "location_country": "Indonesia",
        "location_city": "Bunyu",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "3.455719948",
        "longitude": "117.8669968",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3281",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3800,
            "s_seats": 2361,
            "j_seats": 1255,
            "f_seats": 324,
            "r_seats": 78,
            "cargo_demand": 72628
        }
    },
    {
        "iata": "BYR",
        "icao": "EKLS",
        "airportName": "Li\u00e6so Airport",
        "location_country": "Denmark",
        "location_city": "Li\u00e6so",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "57.27719879",
        "longitude": "11.00010014",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3045",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3895,
            "s_seats": 2069,
            "j_seats": 1509,
            "f_seats": 359,
            "r_seats": 81,
            "cargo_demand": 83671
        }
    },
    {
        "iata": "BYS",
        "icao": "KBYS",
        "airportName": "Bicycle Lake Army Airfield",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Barstow",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.2804985",
        "longitude": "-116.6299973",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9500",
                "runway_width": "175",
                "runway_material": "GRE"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5800",
                "runway_width": "175",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 161146,
            "s_seats": 39090,
            "j_seats": 56588,
            "f_seats": 17368,
            "r_seats": 3764,
            "cargo_demand": 393264
        }
    },
    {
        "iata": "BYT",
        "icao": "EIBN",
        "airportName": "Bantry Aerodrome",
        "location_country": "Ireland",
        "location_city": "Bantry",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "51.66859818",
        "longitude": "-9.48416996",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "1279",
                "runway_width": "49",
                "runway_material": "Bituminous"
            }
        ],
        "demand": {
            "y_seats": 2546,
            "s_seats": 2395,
            "j_seats": 1533,
            "f_seats": 92,
            "r_seats": 16,
            "cargo_demand": 45042
        }
    },
    {
        "iata": "BYU",
        "icao": "EDQD",
        "airportName": "Bindlacher Berg Airport",
        "location_country": "Germany",
        "location_city": "Bavaria",
        "location_state": "Bayreuth",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.985001",
        "longitude": "11.64",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3550",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123447,
            "s_seats": 31893,
            "j_seats": 46374,
            "f_seats": 9366,
            "r_seats": 6777,
            "cargo_demand": 337649
        }
    },
    {
        "iata": "BZA",
        "icao": "MNBZ",
        "airportName": "San Pedro Airport",
        "location_country": "Nicaragua",
        "location_city": "Bonanza",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "14.031524",
        "longitude": "-84.624311",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4583",
                "runway_width": "",
                "runway_material": "dirt"
            }
        ],
        "demand": {
            "y_seats": 2723,
            "s_seats": 2216,
            "j_seats": 1660,
            "f_seats": 188,
            "r_seats": 18,
            "cargo_demand": 95177
        }
    },
    {
        "iata": "BZC",
        "icao": "SBBZ",
        "airportName": "Umberto Modiano Airport",
        "location_country": "Brazil",
        "location_city": "Rio de Janeiro",
        "location_state": "ArmaiCio dos Buzios",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-22.770881",
        "longitude": "-41.96308",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51374,
            "s_seats": 45276,
            "j_seats": 28868,
            "f_seats": 18356,
            "r_seats": 7243,
            "cargo_demand": 434575
        }
    },
    {
        "iata": "BZD",
        "icao": "YBRN",
        "airportName": "Balranald Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Balranald",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-34.62360001",
        "longitude": "143.5780029",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2133",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3888",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194447,
            "s_seats": 37993,
            "j_seats": 22501,
            "f_seats": 2340,
            "r_seats": 8449,
            "cargo_demand": 48793
        }
    },
    {
        "iata": "BZE",
        "icao": "MZBZ",
        "airportName": "Philip S. W. Goldson International Airport",
        "location_country": "Belize",
        "location_city": "Belize City",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "17.53910065",
        "longitude": "-88.30819702",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9678",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 256405,
            "s_seats": 93689,
            "j_seats": 124196,
            "f_seats": 20559,
            "r_seats": 10000,
            "cargo_demand": 13755802
        }
    },
    {
        "iata": "BZG",
        "icao": "EPBY",
        "airportName": "Bydgoszcz Ignacy Jan Paderewski Airport",
        "location_country": "Poland",
        "location_city": "Bydgoszcz",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "53.096802",
        "longitude": "17.977699",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8202",
                "runway_width": "196",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 104980,
            "s_seats": 80442,
            "j_seats": 143729,
            "f_seats": 30763,
            "r_seats": 10000,
            "cargo_demand": 24309726
        }
    },
    {
        "iata": "BZI",
        "icao": "LTBF",
        "airportName": "Balikesir Airport (Merkez Airport)",
        "location_country": "Turkey",
        "location_city": "Balikesir",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.61930084",
        "longitude": "27.9260006",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9810",
                "runway_width": "144",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178881,
            "s_seats": 38813,
            "j_seats": 58806,
            "f_seats": 14846,
            "r_seats": 3761,
            "cargo_demand": 388919
        }
    },
    {
        "iata": "BZK",
        "icao": "UUBP",
        "airportName": "Bryansk International Airport",
        "location_country": "Russia",
        "location_city": "Bryansk Oblast",
        "location_state": "Bryansk",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.214199",
        "longitude": "34.176399",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7874",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 94757,
            "s_seats": 31359,
            "j_seats": 41018,
            "f_seats": 3266,
            "r_seats": 6556,
            "cargo_demand": 127398
        }
    },
    {
        "iata": "BZL",
        "icao": "VGBR",
        "airportName": "Barisal Airport",
        "location_country": "Bangladesh",
        "location_city": "Barisal",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.8010006",
        "longitude": "90.30120087",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5995",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157158,
            "s_seats": 45092,
            "j_seats": 27761,
            "f_seats": 7993,
            "r_seats": 3207,
            "cargo_demand": 225080
        }
    },
    {
        "iata": "BZN",
        "icao": "KBZN",
        "airportName": "Bozeman Yellowstone International Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Bozeman",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "45.77750015",
        "longitude": "-111.1529999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2650",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3197",
                "runway_width": "80",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "9003",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150092,
            "s_seats": 41371,
            "j_seats": 41512,
            "f_seats": 6126,
            "r_seats": 9432,
            "cargo_demand": 156354
        }
    },
    {
        "iata": "BZO",
        "icao": "LIPB",
        "airportName": "Bolzano Airport",
        "location_country": "Italy",
        "location_city": "Trentino-Alto Adige/Sudtirol",
        "location_state": "Bolzano",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.460201",
        "longitude": "11.3264",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4255",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "2290",
                "runway_width": "66",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 90564,
            "s_seats": 27273,
            "j_seats": 30917,
            "f_seats": 17013,
            "r_seats": 4824,
            "cargo_demand": 380445
        }
    },
    {
        "iata": "BZR",
        "icao": "LFMU",
        "airportName": "Beziers Cap d'Agde Airport",
        "location_country": "France",
        "location_city": "Languedoc-Roussillon",
        "location_state": "Beziers",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.32350159",
        "longitude": "3.353899956",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5971",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95955,
            "s_seats": 32998,
            "j_seats": 15416,
            "f_seats": 10749,
            "r_seats": 6923,
            "cargo_demand": 322883
        }
    },
    {
        "iata": "BZU",
        "icao": "FZKJ",
        "airportName": "Buta Zega Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Buta",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.818350077",
        "longitude": "24.79369926",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6890",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121202,
            "s_seats": 35331,
            "j_seats": 21140,
            "f_seats": 11176,
            "r_seats": 4392,
            "cargo_demand": 193352
        }
    },
    {
        "iata": "BZV",
        "icao": "FCBB",
        "airportName": "Maya-Maya Airport",
        "location_country": "Republic of the Congo",
        "location_city": "Brazzaville",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-4.251699924",
        "longitude": "15.25300026",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151471,
            "s_seats": 36136,
            "j_seats": 37961,
            "f_seats": 17870,
            "r_seats": 8946,
            "cargo_demand": 84321
        }
    },
    {
        "iata": "BZY",
        "icao": "LUBL",
        "airportName": "B\u0103lti International Airport",
        "location_country": "Moldova",
        "location_city": "B\u0103lti",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.843056",
        "longitude": "27.777222",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7245",
                "runway_width": "140",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 84831,
            "s_seats": 30711,
            "j_seats": 39415,
            "f_seats": 1161,
            "r_seats": 3298,
            "cargo_demand": 441914
        }
    },
    {
        "iata": "BZZ",
        "icao": "EGVN",
        "airportName": "RAF Brize Norton",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Carterton",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.75",
        "longitude": "-1.58362",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10007",
                "runway_width": "184",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78710,
            "s_seats": 27261,
            "j_seats": 57100,
            "f_seats": 14135,
            "r_seats": 5416,
            "cargo_demand": 452574
        }
    },
    {
        "iata": "CAB",
        "icao": "FNCA",
        "airportName": "Cabinda Airport",
        "location_country": "Angola",
        "location_city": "Cabinda",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.596990108",
        "longitude": "12.18840027",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8202",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123803,
            "s_seats": 38339,
            "j_seats": 9624,
            "f_seats": 4898,
            "r_seats": 6697,
            "cargo_demand": 252413
        }
    },
    {
        "iata": "CAC",
        "icao": "SBCA",
        "airportName": "Cascavel Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Cascavel",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-25.000323",
        "longitude": "-53.501208",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5299",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115617,
            "s_seats": 44129,
            "j_seats": 36896,
            "f_seats": 11475,
            "r_seats": 6323,
            "cargo_demand": 150600
        }
    },
    {
        "iata": "CAD",
        "icao": "KCAD",
        "airportName": "Wexford County Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Cadillac",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.275749",
        "longitude": "-85.421534",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2005",
                "runway_width": "150",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2036,
            "s_seats": 1989,
            "j_seats": 1486,
            "f_seats": 119,
            "r_seats": 32,
            "cargo_demand": 11959
        }
    },
    {
        "iata": "CAE",
        "icao": "KCAE",
        "airportName": "Columbia Metropolitan Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Columbia",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "33.93880081",
        "longitude": "-81.11949921",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "11/29",
                "runway_length": "8601",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175100,
            "s_seats": 74791,
            "j_seats": 94157,
            "f_seats": 55183,
            "r_seats": 10000,
            "cargo_demand": 25704713
        }
    },
    {
        "iata": "CAF",
        "icao": "SWCA",
        "airportName": "Carauari Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Carauari",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-4.871520042",
        "longitude": "-66.89749908",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "5463",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1760,
            "s_seats": 1888,
            "j_seats": 1480,
            "f_seats": 448,
            "r_seats": 59,
            "cargo_demand": 97103
        }
    },
    {
        "iata": "CAG",
        "icao": "LIEE",
        "airportName": "Cagliari Elmas Airport",
        "location_country": "Italy",
        "location_city": "Sardinia",
        "location_state": "Cagliari",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "39.251499",
        "longitude": "9.05428",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9196",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 440719,
            "s_seats": 97590,
            "j_seats": 95702,
            "f_seats": 26477,
            "r_seats": 10000,
            "cargo_demand": 3505828
        }
    },
    {
        "iata": "CAH",
        "icao": "VVCM",
        "airportName": "Ci\u00a0 Mau Airport",
        "location_country": "Vietnam",
        "location_city": "Ci\u00a0 Mau",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.177667",
        "longitude": "105.177778",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115241,
            "s_seats": 29866,
            "j_seats": 5681,
            "f_seats": 18491,
            "r_seats": 8860,
            "cargo_demand": 281180
        }
    },
    {
        "iata": "CAI",
        "icao": "HECA",
        "airportName": "Cairo International Airport",
        "location_country": "Egypt",
        "location_city": "Cairo",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "30.12190056",
        "longitude": "31.40559959",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05C/23C",
                "runway_length": "13120",
                "runway_width": "197",
                "runway_material": "Asphalt"
            },
            {
                "runway_name": "05L/23R",
                "runway_length": "10830",
                "runway_width": "197",
                "runway_material": "Asphalt"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "13124",
                "runway_width": "197",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 111404,
            "s_seats": 75370,
            "j_seats": 71641,
            "f_seats": 59925,
            "r_seats": 10000,
            "cargo_demand": 29157111
        }
    },
    {
        "iata": "CAJ",
        "icao": "SVCN",
        "airportName": "Canaima Airport",
        "location_country": "Venezuela",
        "location_city": "Canaima",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.231988907",
        "longitude": "-62.85443115",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7070",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155915,
            "s_seats": 46292,
            "j_seats": 8736,
            "f_seats": 9033,
            "r_seats": 8813,
            "cargo_demand": 465829
        }
    },
    {
        "iata": "CAK",
        "icao": "KCAK",
        "airportName": "Akron-Canton Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Akron / Canton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.91609955",
        "longitude": "-81.44219971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7601",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "7597",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5599",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103329,
            "s_seats": 44058,
            "j_seats": 5744,
            "f_seats": 15881,
            "r_seats": 4810,
            "cargo_demand": 245847
        }
    },
    {
        "iata": "CAL",
        "icao": "EGEC",
        "airportName": "Campbeltown Airport / RAF Machrihanish",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Campbeltown",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "55.43719864",
        "longitude": "-5.686389923",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5741",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 158779,
            "s_seats": 42364,
            "j_seats": 53304,
            "f_seats": 17813,
            "r_seats": 5549,
            "cargo_demand": 217346
        }
    },
    {
        "iata": "CAN",
        "icao": "ZGGG",
        "airportName": "Guangzhou Baiyun International Airport",
        "location_country": "China",
        "location_city": "Guangdong",
        "location_state": "Guangzhou",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "23.392401",
        "longitude": "113.299004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "02L/20R",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 250197,
            "s_seats": 77224,
            "j_seats": 55548,
            "f_seats": 53803,
            "r_seats": 10000,
            "cargo_demand": 25077143
        }
    },
    {
        "iata": "CAO",
        "icao": "KCAO",
        "airportName": "Clayton Municipal Airpark",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Clayton",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.44620132",
        "longitude": "-103.1669998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6300",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4100",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1828,
            "s_seats": 2297,
            "j_seats": 1555,
            "f_seats": 172,
            "r_seats": 74,
            "cargo_demand": 77965
        }
    },
    {
        "iata": "CAP",
        "icao": "MTCH",
        "airportName": "Hugo Chavez International Airport",
        "location_country": "Haiti",
        "location_city": "Cap-Hai\u00aftien",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "19.726734",
        "longitude": "-72.199576",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8701",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145414,
            "s_seats": 38303,
            "j_seats": 44213,
            "f_seats": 6980,
            "r_seats": 5583,
            "cargo_demand": 218783
        }
    },
    {
        "iata": "CAQ",
        "icao": "SKCU",
        "airportName": "Juan H. White Airport",
        "location_country": "Colombia",
        "location_city": "Caucasia",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.96847",
        "longitude": "-75.1985",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3794",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172866,
            "s_seats": 36745,
            "j_seats": 9366,
            "f_seats": 3261,
            "r_seats": 9139,
            "cargo_demand": 238144
        }
    },
    {
        "iata": "CAR",
        "icao": "KCAR",
        "airportName": "Caribou Municipal Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Caribou",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.87149811",
        "longitude": "-68.01789856",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4003",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3017",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101808,
            "s_seats": 35784,
            "j_seats": 19572,
            "f_seats": 3057,
            "r_seats": 4567,
            "cargo_demand": 466687
        }
    },
    {
        "iata": "CAT",
        "icao": "LPCS",
        "airportName": "Cascais Municipal Aerodrome",
        "location_country": "Portugal",
        "location_city": "Cascais",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "38.724998",
        "longitude": "-9.35523",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3969",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132423,
            "s_seats": 46779,
            "j_seats": 37300,
            "f_seats": 9154,
            "r_seats": 9356,
            "cargo_demand": 361432
        }
    },
    {
        "iata": "CAU",
        "icao": "SNRU",
        "airportName": "Caruaru Airport",
        "location_country": "Brazil",
        "location_city": "Pernambuco",
        "location_state": "Caruaru",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.282389641",
        "longitude": "-36.01350021",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3638,
            "s_seats": 2994,
            "j_seats": 1343,
            "f_seats": 259,
            "r_seats": 92,
            "cargo_demand": 24768
        }
    },
    {
        "iata": "CAW",
        "icao": "SBCP",
        "airportName": "Bartolomeu Lysandro Airport",
        "location_country": "Brazil",
        "location_city": "Rio de Janeiro",
        "location_state": "Campos dos Goytacazes",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-21.698299",
        "longitude": "-41.301701",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "5066",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128434,
            "s_seats": 26449,
            "j_seats": 47028,
            "f_seats": 4377,
            "r_seats": 4570,
            "cargo_demand": 104532
        }
    },
    {
        "iata": "CAX",
        "icao": "EGNC",
        "airportName": "Carlisle Lake District Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Carlisle",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "54.9375",
        "longitude": "-2.809170008",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2904",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "6000",
                "runway_width": "98",
                "runway_material": "Grooved ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4057",
                "runway_width": "150",
                "runway_material": "U"
            }
        ],
        "demand": {
            "y_seats": 166592,
            "s_seats": 41353,
            "j_seats": 48387,
            "f_seats": 5913,
            "r_seats": 7616,
            "cargo_demand": 120776
        }
    },
    {
        "iata": "CAY",
        "icao": "SOCA",
        "airportName": "Cayenne - Felix Eboue Airport",
        "location_country": "French Guiana",
        "location_city": "Cayenne",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.81981",
        "longitude": "-52.360401",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10486",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106691,
            "s_seats": 26979,
            "j_seats": 27197,
            "f_seats": 19428,
            "r_seats": 6190,
            "cargo_demand": 246654
        }
    },
    {
        "iata": "CAZ",
        "icao": "YCBA",
        "airportName": "Cobar Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Cobar",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-31.53829956",
        "longitude": "145.7940063",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4984",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4006",
                "runway_width": "98",
                "runway_material": "CLA"
            }
        ],
        "demand": {
            "y_seats": 182692,
            "s_seats": 27437,
            "j_seats": 39972,
            "f_seats": 19724,
            "r_seats": 6732,
            "cargo_demand": 456323
        }
    },
    {
        "iata": "CBB",
        "icao": "SLCB",
        "airportName": "Jorge Wilstermann International Airport",
        "location_country": "Bolivia",
        "location_city": "Cochabamba",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.42110062",
        "longitude": "-66.17710114",
        "region": "SA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8692",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "12460",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127729,
            "s_seats": 49450,
            "j_seats": 56109,
            "f_seats": 6257,
            "r_seats": 3550,
            "cargo_demand": 54307
        }
    },
    {
        "iata": "CBD",
        "icao": "VOCX",
        "airportName": "Car Nicobar Air Force Base",
        "location_country": "India",
        "location_city": "Andaman and Nicobar Islands",
        "location_state": "Car Nicobar",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.153056",
        "longitude": "92.819273",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8914",
                "runway_width": "140",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 137935,
            "s_seats": 34759,
            "j_seats": 19653,
            "f_seats": 12386,
            "r_seats": 3364,
            "cargo_demand": 166021
        }
    },
    {
        "iata": "CBE",
        "icao": "KCBE",
        "airportName": "Greater Cumberland Regional Airport",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Cumberland",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.61539841",
        "longitude": "-78.7609024",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5048",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2442",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2276,
            "s_seats": 1917,
            "j_seats": 1319,
            "f_seats": 469,
            "r_seats": 65,
            "cargo_demand": 32600
        }
    },
    {
        "iata": "CBF",
        "icao": "KCBF",
        "airportName": "Council Bluffs Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Council Bluffs",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.25920105",
        "longitude": "-95.76059723",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4100",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1037,
            "s_seats": 2627,
            "j_seats": 1894,
            "f_seats": 479,
            "r_seats": 11,
            "cargo_demand": 22760
        }
    },
    {
        "iata": "CBG",
        "icao": "EGSC",
        "airportName": "Cambridge Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Cambridge",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "52.205002",
        "longitude": "0.175",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6447",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05G/23G",
                "runway_length": "2949",
                "runway_width": "115",
                "runway_material": "GRS"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2293",
                "runway_width": "115",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 120360,
            "s_seats": 45043,
            "j_seats": 51246,
            "f_seats": 15815,
            "r_seats": 4506,
            "cargo_demand": 51879
        }
    },
    {
        "iata": "CBH",
        "icao": "DAOR",
        "airportName": "Boudghene Ben Ali Lotfi Airport",
        "location_country": "Algeria",
        "location_city": "Bechar",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.64570045",
        "longitude": "-2.269860029",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "12245",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "9840",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196994,
            "s_seats": 32893,
            "j_seats": 50846,
            "f_seats": 12149,
            "r_seats": 3022,
            "cargo_demand": 289102
        }
    },
    {
        "iata": "CBJ",
        "icao": "MDCR",
        "airportName": "Cabo Rojo Airport",
        "location_country": "Dominican Republic",
        "location_city": "Pedernales",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.92900085",
        "longitude": "-71.64479828",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4950",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61796,
            "s_seats": 45693,
            "j_seats": 27442,
            "f_seats": 19879,
            "r_seats": 3361,
            "cargo_demand": 259581
        }
    },
    {
        "iata": "CBK",
        "icao": "KCBK",
        "airportName": "Colby Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Colby",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.42750168",
        "longitude": "-101.0469971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2600",
                "runway_width": "80",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2660",
                "runway_width": "90",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5109",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3223,
            "s_seats": 2834,
            "j_seats": 1921,
            "f_seats": 433,
            "r_seats": 159,
            "cargo_demand": 41290
        }
    },
    {
        "iata": "CBL",
        "icao": "SVCB",
        "airportName": "Tomas de Heres Airport",
        "location_country": "Venezuela",
        "location_city": "Ciudad Bolivar",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.122160912",
        "longitude": "-63.53695679",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5527",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4605",
                "runway_width": "135",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 133358,
            "s_seats": 46009,
            "j_seats": 52576,
            "f_seats": 2831,
            "r_seats": 5765,
            "cargo_demand": 255244
        }
    },
    {
        "iata": "CBM",
        "icao": "KCBM",
        "airportName": "Columbus Air Force Base",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Columbus",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "33.64379883",
        "longitude": "-88.44380188",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13C/31C",
                "runway_length": "12000",
                "runway_width": "300",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "6300",
                "runway_width": "175",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 253987,
            "s_seats": 96502,
            "j_seats": 85227,
            "f_seats": 35761,
            "r_seats": 10000,
            "cargo_demand": 27690465
        }
    },
    {
        "iata": "CBN",
        "icao": "WICD",
        "airportName": "Penggung Airport",
        "location_country": "Indonesia",
        "location_city": "Cirebon",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.756140232",
        "longitude": "108.5400009",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4131",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1325,
            "s_seats": 2301,
            "j_seats": 1237,
            "f_seats": 281,
            "r_seats": 142,
            "cargo_demand": 60928
        }
    },
    {
        "iata": "CBO",
        "icao": "RPMC",
        "airportName": "Awang Airport",
        "location_country": "Philippines",
        "location_city": "Cotabato City",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.165239811",
        "longitude": "124.2099991",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6234",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162239,
            "s_seats": 29686,
            "j_seats": 29068,
            "f_seats": 3298,
            "r_seats": 3600,
            "cargo_demand": 421047
        }
    },
    {
        "iata": "CBP",
        "icao": "LPCO",
        "airportName": "Coimbra Airport",
        "location_country": "Portugal",
        "location_city": "Coimbra",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "40.158758",
        "longitude": "-8.470815",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3018",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 55524,
            "s_seats": 27027,
            "j_seats": 38524,
            "f_seats": 13488,
            "r_seats": 3116,
            "cargo_demand": 80134
        }
    },
    {
        "iata": "CBQ",
        "icao": "DNCA",
        "airportName": "Margaret Ekpo International Airport",
        "location_country": "Nigeria",
        "location_city": "Calabar",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.976019859",
        "longitude": "8.347200394",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8040",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199127,
            "s_seats": 27385,
            "j_seats": 56618,
            "f_seats": 2236,
            "r_seats": 6184,
            "cargo_demand": 254756
        }
    },
    {
        "iata": "CBR",
        "icao": "YSCB",
        "airportName": "Canberra Airport",
        "location_country": "Australia",
        "location_city": "Australian Capital Territory",
        "location_state": "Canberra",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "large_airport",
        "latitude": "-35.30690002",
        "longitude": "149.1950073",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5509",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "10771",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 236208,
            "s_seats": 83843,
            "j_seats": 26837,
            "f_seats": 67982,
            "r_seats": 10000,
            "cargo_demand": 21423989
        }
    },
    {
        "iata": "CBS",
        "icao": "SVON",
        "airportName": "Oro Negro Airport",
        "location_country": "Venezuela",
        "location_city": "Cabimas",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "10.33069992",
        "longitude": "-71.32250214",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6160",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2195,
            "s_seats": 2098,
            "j_seats": 1945,
            "f_seats": 422,
            "r_seats": 133,
            "cargo_demand": 77740
        }
    },
    {
        "iata": "CBT",
        "icao": "FNCT",
        "airportName": "Catumbela Airport",
        "location_country": "Angola",
        "location_city": "Catumbela",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.4792",
        "longitude": "13.4869",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "12139",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 164601,
            "s_seats": 39441,
            "j_seats": 24792,
            "f_seats": 12766,
            "r_seats": 4955,
            "cargo_demand": 109337
        }
    },
    {
        "iata": "CBV",
        "icao": "MGCB",
        "airportName": "Coban Airport",
        "location_country": "Guatemala",
        "location_city": "Coban",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.46899986",
        "longitude": "-90.40670013",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3340",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79934,
            "s_seats": 35648,
            "j_seats": 29184,
            "f_seats": 11657,
            "r_seats": 4192,
            "cargo_demand": 236058
        }
    },
    {
        "iata": "CBW",
        "icao": "SSKM",
        "airportName": "Campo Mourio Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Campo Mourio",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-24.00919914",
        "longitude": "-52.35680008",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2259,
            "s_seats": 2857,
            "j_seats": 1037,
            "f_seats": 373,
            "r_seats": 27,
            "cargo_demand": 39948
        }
    },
    {
        "iata": "CBX",
        "icao": "YCDO",
        "airportName": "Condobolin Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Condobolin",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-33.06439972",
        "longitude": "147.2089996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4501",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3947",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1483,
            "s_seats": 1646,
            "j_seats": 1566,
            "f_seats": 129,
            "r_seats": 63,
            "cargo_demand": 69939
        }
    },
    {
        "iata": "CCA",
        "icao": "SLCH",
        "airportName": "Chimore Airport",
        "location_country": "Bolivia",
        "location_city": "Cochabamba",
        "location_state": "Chimore",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.976834",
        "longitude": "-65.145568",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4801",
                "runway_width": "67",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1860,
            "s_seats": 1919,
            "j_seats": 1707,
            "f_seats": 83,
            "r_seats": 61,
            "cargo_demand": 41867
        }
    },
    {
        "iata": "CCB",
        "icao": "KCCB",
        "airportName": "Cable Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Upland",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.11159897",
        "longitude": "-117.6880035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3864",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1823,
            "s_seats": 1704,
            "j_seats": 1752,
            "f_seats": 222,
            "r_seats": 89,
            "cargo_demand": 23382
        }
    },
    {
        "iata": "CCC",
        "icao": "MUCC",
        "airportName": "Jardines del Rey Airport",
        "location_country": "Cuba",
        "location_city": "Cayo Coco",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "22.46100044",
        "longitude": "-78.32839966",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90406,
            "s_seats": 33611,
            "j_seats": 51326,
            "f_seats": 17096,
            "r_seats": 4042,
            "cargo_demand": 266148
        }
    },
    {
        "iata": "CCF",
        "icao": "LFMK",
        "airportName": "Carcassonne Airport",
        "location_country": "France",
        "location_city": "Languedoc-Roussillon",
        "location_state": "Carcassonne",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.2159996",
        "longitude": "2.306319952",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6726",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180754,
            "s_seats": 41555,
            "j_seats": 48522,
            "f_seats": 13874,
            "r_seats": 7684,
            "cargo_demand": 72272
        }
    },
    {
        "iata": "CCH",
        "icao": "SCCC",
        "airportName": "Chile Chico Airfield",
        "location_country": "Chile",
        "location_city": "Chile Chico",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-46.5831",
        "longitude": "-71.686323",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3937",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63275,
            "s_seats": 39298,
            "j_seats": 28226,
            "f_seats": 19663,
            "r_seats": 9130,
            "cargo_demand": 60493
        }
    },
    {
        "iata": "CCI",
        "icao": "SSCK",
        "airportName": "Concordia Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "Concordia",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-27.18059921",
        "longitude": "-52.05270004",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4856",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3262,
            "s_seats": 2538,
            "j_seats": 1491,
            "f_seats": 36,
            "r_seats": 143,
            "cargo_demand": 5507
        }
    },
    {
        "iata": "CCJ",
        "icao": "VOCL",
        "airportName": "Calicut International Airport",
        "location_country": "India",
        "location_city": "Kerala",
        "location_state": "Kozhikode (Calicut)",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "11.13679981",
        "longitude": "75.95529938",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9383",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 271040,
            "s_seats": 79730,
            "j_seats": 94252,
            "f_seats": 66147,
            "r_seats": 10000,
            "cargo_demand": 14664642
        }
    },
    {
        "iata": "CCK",
        "icao": "YPCC",
        "airportName": "Cocos (Keeling) Islands Airport",
        "location_country": "Australia",
        "location_city": "Cocos (Keeling) Islands",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.19134",
        "longitude": "96.833696",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7999",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123771,
            "s_seats": 29331,
            "j_seats": 21548,
            "f_seats": 18832,
            "r_seats": 3717,
            "cargo_demand": 467821
        }
    },
    {
        "iata": "CCL",
        "icao": "YCCA",
        "airportName": "Chinchilla Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Chinchilla",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.77499962",
        "longitude": "150.6170044",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1949",
                "runway_width": "60",
                "runway_material": "CLA"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3497",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134349,
            "s_seats": 43431,
            "j_seats": 39033,
            "f_seats": 6593,
            "r_seats": 4068,
            "cargo_demand": 274890
        }
    },
    {
        "iata": "CCM",
        "icao": "SBCM",
        "airportName": "Diomicio Freitas Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "Criciuma",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-28.72444344",
        "longitude": "-49.42139053",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "4882",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68773,
            "s_seats": 36846,
            "j_seats": 11508,
            "f_seats": 13589,
            "r_seats": 4644,
            "cargo_demand": 151634
        }
    },
    {
        "iata": "CCP",
        "icao": "SCIE",
        "airportName": "Carriel Sur International Airport",
        "location_country": "Chile",
        "location_city": "Concepcion",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-36.772701",
        "longitude": "-73.063103",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "6470",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58820,
            "s_seats": 44951,
            "j_seats": 37012,
            "f_seats": 12062,
            "r_seats": 3538,
            "cargo_demand": 125617
        }
    },
    {
        "iata": "CCR",
        "icao": "KCCR",
        "airportName": "Buchanan Field Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Concord",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.98970032",
        "longitude": "-122.0569992",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "2770",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "4602",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "2799",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1214,
            "s_seats": 1652,
            "j_seats": 1411,
            "f_seats": 68,
            "r_seats": 131,
            "cargo_demand": 7369
        }
    },
    {
        "iata": "CCS",
        "icao": "SVMI",
        "airportName": "Simon Bolivar International Airport",
        "location_country": "Venezuela",
        "location_city": "Caracas",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "10.601194",
        "longitude": "-66.991222",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9930",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166136,
            "s_seats": 92367,
            "j_seats": 89473,
            "f_seats": 30074,
            "r_seats": 10000,
            "cargo_demand": 14542901
        }
    },
    {
        "iata": "CCU",
        "icao": "VECC",
        "airportName": "Netaji Subhas Chandra Bose International Airport",
        "location_country": "India",
        "location_city": "West Bengal",
        "location_state": "Kolkata",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "22.65469933",
        "longitude": "88.44670105",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "7871",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "11900",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 470842,
            "s_seats": 86848,
            "j_seats": 51774,
            "f_seats": 39171,
            "r_seats": 10000,
            "cargo_demand": 25623577
        }
    },
    {
        "iata": "CCV",
        "icao": "NVSF",
        "airportName": "Craig Cove Airport",
        "location_country": "Vanuatu",
        "location_city": "Malampa",
        "location_state": "Ambrym Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.264999",
        "longitude": "167.923996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2674",
                "runway_width": "69",
                "runway_material": "Volcanic ash impregnated with bitumen"
            }
        ],
        "demand": {
            "y_seats": 3501,
            "s_seats": 1586,
            "j_seats": 1296,
            "f_seats": 287,
            "r_seats": 106,
            "cargo_demand": 28737
        }
    },
    {
        "iata": "CCW",
        "icao": "YCWL",
        "airportName": "Cowell Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Cowell",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-33.66669846",
        "longitude": "136.8919983",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3897",
                "runway_width": "",
                "runway_material": "N"
            },
            {
                "runway_name": "12/30",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4829",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3024,
            "s_seats": 2843,
            "j_seats": 1264,
            "f_seats": 467,
            "r_seats": 70,
            "cargo_demand": 74248
        }
    },
    {
        "iata": "CCX",
        "icao": "SWKC",
        "airportName": "Caceres Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Caceres",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-16.04360008",
        "longitude": "-57.62990189",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6070",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3727,
            "s_seats": 1528,
            "j_seats": 1846,
            "f_seats": 403,
            "r_seats": 71,
            "cargo_demand": 75196
        }
    },
    {
        "iata": "CCY",
        "icao": "KCCY",
        "airportName": "Northeast Iowa Regional Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Charles City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.07260132",
        "longitude": "-92.6108017",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2536",
                "runway_width": "160",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "1780",
                "runway_width": "170",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 193695,
            "s_seats": 44307,
            "j_seats": 34533,
            "f_seats": 1398,
            "r_seats": 3033,
            "cargo_demand": 197909
        }
    },
    {
        "iata": "CCZ",
        "icao": "MYBC",
        "airportName": "Chub Cay International Airport",
        "location_country": "Bahamas",
        "location_city": "Berry Islands",
        "location_state": "Chub Cay",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "25.41710091",
        "longitude": "-77.88089752",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5000",
                "runway_width": "78",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 156437,
            "s_seats": 47291,
            "j_seats": 54506,
            "f_seats": 6897,
            "r_seats": 5829,
            "cargo_demand": 338283
        }
    },
    {
        "iata": "CDA",
        "icao": "YCOO",
        "airportName": "Cooinda Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Cooinda",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.90330029",
        "longitude": "132.5319977",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4235",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 2297,
            "s_seats": 2408,
            "j_seats": 1629,
            "f_seats": 187,
            "r_seats": 52,
            "cargo_demand": 23689
        }
    },
    {
        "iata": "CDB",
        "icao": "PACD",
        "airportName": "Cold Bay Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Cold Bay",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "55.20610046",
        "longitude": "-162.7250061",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4235",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "10415",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177477,
            "s_seats": 26917,
            "j_seats": 45110,
            "f_seats": 9553,
            "r_seats": 9216,
            "cargo_demand": 380630
        }
    },
    {
        "iata": "CDC",
        "icao": "KCDC",
        "airportName": "Cedar City Regional Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Cedar City",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.70100021",
        "longitude": "-113.098999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8653",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "4822",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 107689,
            "s_seats": 30941,
            "j_seats": 39052,
            "f_seats": 1693,
            "r_seats": 6048,
            "cargo_demand": 454588
        }
    },
    {
        "iata": "CDE",
        "icao": "ZBCD",
        "airportName": "Chengde Puning Airport",
        "location_country": "China",
        "location_city": "Hebei",
        "location_state": "Chengde",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.1225",
        "longitude": "118.073889",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 174859,
            "s_seats": 44812,
            "j_seats": 10740,
            "f_seats": 5548,
            "r_seats": 4319,
            "cargo_demand": 158748
        }
    },
    {
        "iata": "CDG",
        "icao": "LFPG",
        "airportName": "Charles de Gaulle Airport",
        "location_country": "France",
        "location_city": "Ile-de-France",
        "location_state": "Paris",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "49.012798",
        "longitude": "2.55",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "13829",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "8858",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "8858",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "13780",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191948,
            "s_seats": 88381,
            "j_seats": 36274,
            "f_seats": 49291,
            "r_seats": 10000,
            "cargo_demand": 11222500
        }
    },
    {
        "iata": "CDH",
        "icao": "KCDH",
        "airportName": "Harrell Field",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Camden",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.62279892",
        "longitude": "-92.76339722",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6501",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2726,
            "s_seats": 1673,
            "j_seats": 1301,
            "f_seats": 370,
            "r_seats": 153,
            "cargo_demand": 7586
        }
    },
    {
        "iata": "CDI",
        "icao": "SNKI",
        "airportName": "Cachoeiro de Itapemirim Airport",
        "location_country": "Brazil",
        "location_city": "Espirito Santo",
        "location_state": "Cachoeiro de Itapemirim",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-20.83429909",
        "longitude": "-41.18560028",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3296,
            "s_seats": 2174,
            "j_seats": 1247,
            "f_seats": 344,
            "r_seats": 40,
            "cargo_demand": 95970
        }
    },
    {
        "iata": "CDJ",
        "icao": "SBAA",
        "airportName": "ConceiiCio do Araguaia Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "ConceiiCio do Araguaia",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.34835",
        "longitude": "-49.301498",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89861,
            "s_seats": 49726,
            "j_seats": 34268,
            "f_seats": 15820,
            "r_seats": 9020,
            "cargo_demand": 204108
        }
    },
    {
        "iata": "CDN",
        "icao": "KCDN",
        "airportName": "Woodward Field",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Camden",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.28359985",
        "longitude": "-80.56490326",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2998",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1475,
            "s_seats": 2307,
            "j_seats": 1272,
            "f_seats": 262,
            "r_seats": 198,
            "cargo_demand": 46039
        }
    },
    {
        "iata": "CDP",
        "icao": "VOCP",
        "airportName": "Kadapa Airport",
        "location_country": "India",
        "location_city": "Andhra Pradesh",
        "location_state": "Kadapa",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.51",
        "longitude": "78.772778",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6562",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120620,
            "s_seats": 48108,
            "j_seats": 13005,
            "f_seats": 7631,
            "r_seats": 8890,
            "cargo_demand": 296228
        }
    },
    {
        "iata": "CDQ",
        "icao": "YCRY",
        "airportName": "Croydon Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Croydon",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.22500038",
        "longitude": "142.2579956",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4799",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1874,
            "s_seats": 2356,
            "j_seats": 1193,
            "f_seats": 115,
            "r_seats": 148,
            "cargo_demand": 4988
        }
    },
    {
        "iata": "CDR",
        "icao": "KCDR",
        "airportName": "Chadron Municipal Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Chadron",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.83760071",
        "longitude": "-103.0950012",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6001",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4401",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 62878,
            "s_seats": 47471,
            "j_seats": 46635,
            "f_seats": 19796,
            "r_seats": 8688,
            "cargo_demand": 378811
        }
    },
    {
        "iata": "CDS",
        "icao": "KCDS",
        "airportName": "Childress Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Childress",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.43379974",
        "longitude": "-100.288002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4425",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4196",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5949",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129718,
            "s_seats": 33544,
            "j_seats": 34925,
            "f_seats": 12860,
            "r_seats": 6746,
            "cargo_demand": 424380
        }
    },
    {
        "iata": "CDU",
        "icao": "YSCN",
        "airportName": "Camden Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Camden",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-34.04029846",
        "longitude": "150.6869965",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4804",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2372",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 118629,
            "s_seats": 43620,
            "j_seats": 18279,
            "f_seats": 15223,
            "r_seats": 4084,
            "cargo_demand": 143217
        }
    },
    {
        "iata": "CDV",
        "icao": "PACV",
        "airportName": "Merle K. (Mudhole) Smith Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Cordova",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "60.491798",
        "longitude": "-145.477997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "1899",
                "runway_width": "30",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 164698,
            "s_seats": 40562,
            "j_seats": 19753,
            "f_seats": 19794,
            "r_seats": 3558,
            "cargo_demand": 419249
        }
    },
    {
        "iata": "CDW",
        "icao": "KCDW",
        "airportName": "Essex County Airport",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Caldwell",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.87519836",
        "longitude": "-74.28140259",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4553",
                "runway_width": "80",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3721",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 1208,
            "s_seats": 2986,
            "j_seats": 1569,
            "f_seats": 231,
            "r_seats": 74,
            "cargo_demand": 18997
        }
    },
    {
        "iata": "CEA",
        "icao": "KCEA",
        "airportName": "Cessna Aircraft Field",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Wichita",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.64860153",
        "longitude": "-97.25060272",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3873",
                "runway_width": "40",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1191,
            "s_seats": 2893,
            "j_seats": 1131,
            "f_seats": 494,
            "r_seats": 123,
            "cargo_demand": 38472
        }
    },
    {
        "iata": "CEB",
        "icao": "RPVM",
        "airportName": "Mactan-Cebu International Airport",
        "location_country": "Philippines",
        "location_city": "Lapu-Lapu",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "10.30749989",
        "longitude": "123.9789963",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 253450,
            "s_seats": 96717,
            "j_seats": 105970,
            "f_seats": 33308,
            "r_seats": 10000,
            "cargo_demand": 9937984
        }
    },
    {
        "iata": "CEC",
        "icao": "KCEC",
        "airportName": "Del Norte County Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Crescent City",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.78020096",
        "longitude": "-124.2369995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5002",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176644,
            "s_seats": 33489,
            "j_seats": 17052,
            "f_seats": 18246,
            "r_seats": 7539,
            "cargo_demand": 117214
        }
    },
    {
        "iata": "CED",
        "icao": "YCDU",
        "airportName": "Ceduna Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Ceduna",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-32.13059998",
        "longitude": "133.7100067",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5709",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3327",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 150014,
            "s_seats": 37311,
            "j_seats": 14045,
            "f_seats": 1460,
            "r_seats": 7885,
            "cargo_demand": 96604
        }
    },
    {
        "iata": "CEF",
        "icao": "KCEF",
        "airportName": "Westover Metropolitan Airport / Westover Air Reserve Base",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Springfield",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.19400024",
        "longitude": "-72.53479767",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11597",
                "runway_width": "301",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "7082",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53980,
            "s_seats": 44616,
            "j_seats": 45944,
            "f_seats": 13888,
            "r_seats": 6200,
            "cargo_demand": 201912
        }
    },
    {
        "iata": "CEG",
        "icao": "EGNR",
        "airportName": "Hawarden Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Chester",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "53.178101",
        "longitude": "-2.97778",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6702",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62752,
            "s_seats": 34247,
            "j_seats": 23176,
            "f_seats": 7619,
            "r_seats": 8236,
            "cargo_demand": 322978
        }
    },
    {
        "iata": "CEI",
        "icao": "VTCT",
        "airportName": "Chiang Rai International Airport",
        "location_country": "Thailand",
        "location_city": "Chiang Rai",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.952299",
        "longitude": "99.882896",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131061,
            "s_seats": 26945,
            "j_seats": 11097,
            "f_seats": 11024,
            "r_seats": 3561,
            "cargo_demand": 430825
        }
    },
    {
        "iata": "CEK",
        "icao": "USCC",
        "airportName": "Chelyabinsk Airport",
        "location_country": "Russia",
        "location_city": "Chelyabinsk Oblast",
        "location_state": "Chelyabinsk",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.305801",
        "longitude": "61.5033",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10499",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 79478,
            "s_seats": 40901,
            "j_seats": 58459,
            "f_seats": 15101,
            "r_seats": 8709,
            "cargo_demand": 482553
        }
    },
    {
        "iata": "CEL",
        "icao": "SSCN",
        "airportName": "Canela Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Canela",
        "time_zone": "UTC-03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-29.3701992",
        "longitude": "-50.83200073",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "4134",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1856,
            "s_seats": 1795,
            "j_seats": 1358,
            "f_seats": 74,
            "r_seats": 101,
            "cargo_demand": 73910
        }
    },
    {
        "iata": "CEN",
        "icao": "MMCN",
        "airportName": "Ciudad Obregon International Airport",
        "location_country": "Mexico",
        "location_city": "Sonora",
        "location_state": "Ciudad Obregon",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.39259911",
        "longitude": "-109.8330002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71611,
            "s_seats": 41293,
            "j_seats": 13759,
            "f_seats": 13995,
            "r_seats": 5139,
            "cargo_demand": 123579
        }
    },
    {
        "iata": "CEQ",
        "icao": "LFMD",
        "airportName": "Cannes - Mandelieu Airport",
        "location_country": "France",
        "location_city": "Provence-Alpes-Cote d'Azur",
        "location_state": "Cannes",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.542",
        "longitude": "6.95348",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2493",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5052",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "1804",
                "runway_width": "164",
                "runway_material": "grass"
            }
        ],
        "demand": {
            "y_seats": 75596,
            "s_seats": 44533,
            "j_seats": 25965,
            "f_seats": 17443,
            "r_seats": 6065,
            "cargo_demand": 309898
        }
    },
    {
        "iata": "CER",
        "icao": "LFRC",
        "airportName": "Cherbourg - Maupertus Airport",
        "location_country": "France",
        "location_city": "Lower Normandy",
        "location_state": "Cherbourg-Octeville",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.65010071",
        "longitude": "-1.470280051",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8005",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152879,
            "s_seats": 25281,
            "j_seats": 12053,
            "f_seats": 18887,
            "r_seats": 5934,
            "cargo_demand": 497896
        }
    },
    {
        "iata": "CES",
        "icao": "YCNK",
        "airportName": "Cessnock Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Cessnock",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-32.787498",
        "longitude": "151.341995",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3600",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2568,
            "s_seats": 2450,
            "j_seats": 1304,
            "f_seats": 108,
            "r_seats": 154,
            "cargo_demand": 37619
        }
    },
    {
        "iata": "CET",
        "icao": "LFOU",
        "airportName": "Cholet Le Pontreau Airport",
        "location_country": "France",
        "location_city": "Pays de la Loire",
        "location_state": "Cholet",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.08209991",
        "longitude": "-0.87706399",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4528",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71552,
            "s_seats": 46156,
            "j_seats": 56800,
            "f_seats": 17955,
            "r_seats": 6823,
            "cargo_demand": 229139
        }
    },
    {
        "iata": "CEU",
        "icao": "KCEU",
        "airportName": "Oconee County Regional Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Clemson",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.6719017",
        "longitude": "-82.8864975",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4399",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3806,
            "s_seats": 2487,
            "j_seats": 1456,
            "f_seats": 119,
            "r_seats": 17,
            "cargo_demand": 68558
        }
    },
    {
        "iata": "CEV",
        "icao": "KCEV",
        "airportName": "Mettel Field",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Connersville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.69850159",
        "longitude": "-85.12969971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2833",
                "runway_width": "100",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1893,
            "s_seats": 2913,
            "j_seats": 1208,
            "f_seats": 238,
            "r_seats": 166,
            "cargo_demand": 2383
        }
    },
    {
        "iata": "CEW",
        "icao": "KCEW",
        "airportName": "Bob Sikes Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Crestview",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.77879906",
        "longitude": "-86.52210236",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8005",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111625,
            "s_seats": 25059,
            "j_seats": 56242,
            "f_seats": 4647,
            "r_seats": 9761,
            "cargo_demand": 45918
        }
    },
    {
        "iata": "CEY",
        "icao": "KCEY",
        "airportName": "Murray-Calloway County Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Murray",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.66460037",
        "longitude": "-88.37280273",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6200",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3704,
            "s_seats": 2918,
            "j_seats": 1784,
            "f_seats": 5,
            "r_seats": 25,
            "cargo_demand": 84345
        }
    },
    {
        "iata": "CEZ",
        "icao": "KCEZ",
        "airportName": "Cortez Municipal Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Cortez",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.3030014",
        "longitude": "-108.6279984",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7205",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2600,
            "s_seats": 2229,
            "j_seats": 1531,
            "f_seats": 426,
            "r_seats": 87,
            "cargo_demand": 4796
        }
    },
    {
        "iata": "CFB",
        "icao": "SBCB",
        "airportName": "Cabo Frio International Airport",
        "location_country": "Brazil",
        "location_city": "Rio de Janeiro",
        "location_state": "Cabo Frio",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-22.92169952",
        "longitude": "-42.07429886",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8366",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1401,
            "s_seats": 2536,
            "j_seats": 1524,
            "f_seats": 76,
            "r_seats": 84,
            "cargo_demand": 53652
        }
    },
    {
        "iata": "CFC",
        "icao": "SBCD",
        "airportName": "CaiCador Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "CaiCador",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-26.78840065",
        "longitude": "-50.93980026",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "5331",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3747,
            "s_seats": 2846,
            "j_seats": 1239,
            "f_seats": 72,
            "r_seats": 52,
            "cargo_demand": 31762
        }
    },
    {
        "iata": "CFD",
        "icao": "KCFD",
        "airportName": "Coulter Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Bryan",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "30.71570015",
        "longitude": "-96.33139801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "17/35",
                "runway_length": "1330",
                "runway_width": "40",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3445,
            "s_seats": 2432,
            "j_seats": 1216,
            "f_seats": 439,
            "r_seats": 29,
            "cargo_demand": 85016
        }
    },
    {
        "iata": "CFE",
        "icao": "LFLC",
        "airportName": "Clermont-Ferrand Auvergne Airport",
        "location_country": "France",
        "location_city": "Auvergne",
        "location_state": "Clermont-Ferrand",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.7867012",
        "longitude": "3.169169903",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2313",
                "runway_width": "197",
                "runway_material": "Grass - Herbe"
            },
            {
                "runway_name": "08/26",
                "runway_length": "9885",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08L/26R",
                "runway_length": "2953",
                "runway_width": "161",
                "runway_material": "Grass - Herbe"
            }
        ],
        "demand": {
            "y_seats": 142006,
            "s_seats": 37004,
            "j_seats": 11245,
            "f_seats": 5533,
            "r_seats": 5771,
            "cargo_demand": 372574
        }
    },
    {
        "iata": "CFG",
        "icao": "MUCF",
        "airportName": "Jaime Gonzalez Airport",
        "location_country": "Cuba",
        "location_city": "Cienfuegos",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "22.14999962",
        "longitude": "-80.41419983",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196825,
            "s_seats": 36760,
            "j_seats": 21329,
            "f_seats": 9565,
            "r_seats": 8018,
            "cargo_demand": 234580
        }
    },
    {
        "iata": "CFI",
        "icao": "YCFD",
        "airportName": "Camfield Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Camfield Station",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.02169991",
        "longitude": "131.3269958",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3805",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3791,
            "s_seats": 2261,
            "j_seats": 1908,
            "f_seats": 241,
            "r_seats": 146,
            "cargo_demand": 10195
        }
    },
    {
        "iata": "CFK",
        "icao": "DAOI",
        "airportName": "Chlef International Airport",
        "location_country": "Algeria",
        "location_city": "Chlef",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.217166",
        "longitude": "1.342237",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5413",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175931,
            "s_seats": 32194,
            "j_seats": 51062,
            "f_seats": 18179,
            "r_seats": 6855,
            "cargo_demand": 49633
        }
    },
    {
        "iata": "CFN",
        "icao": "EIDL",
        "airportName": "Donegal Airport",
        "location_country": "Ireland",
        "location_city": "Donegal",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "55.0442009",
        "longitude": "-8.340999603",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4908",
                "runway_width": "98",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 56098,
            "s_seats": 30597,
            "j_seats": 19458,
            "f_seats": 2029,
            "r_seats": 9965,
            "cargo_demand": 469126
        }
    },
    {
        "iata": "CFR",
        "icao": "LFRK",
        "airportName": "Caen - Carpiquet Airport",
        "location_country": "France",
        "location_city": "Lower Normandy",
        "location_state": "Caen",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.1733017",
        "longitude": "-0.449999988",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3871",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6233",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78339,
            "s_seats": 35155,
            "j_seats": 54701,
            "f_seats": 8225,
            "r_seats": 7957,
            "cargo_demand": 379869
        }
    },
    {
        "iata": "CFS",
        "icao": "YCFS",
        "airportName": "Coffs Harbour Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Coffs Harbour",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-30.320601",
        "longitude": "153.115997",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6824",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2785",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113326,
            "s_seats": 25774,
            "j_seats": 55913,
            "f_seats": 16642,
            "r_seats": 8524,
            "cargo_demand": 145156
        }
    },
    {
        "iata": "CFT",
        "icao": "KCFT",
        "airportName": "Greenlee County Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Clifton / Morenci",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.957039",
        "longitude": "-109.211397",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4970",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1666,
            "s_seats": 2313,
            "j_seats": 1148,
            "f_seats": 342,
            "r_seats": 84,
            "cargo_demand": 77881
        }
    },
    {
        "iata": "CFU",
        "icao": "LGKR",
        "airportName": "Corfu International Airport",
        "location_country": "Greece",
        "location_city": "Corfu",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "39.60189819",
        "longitude": "19.9116993",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7792",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103712,
            "s_seats": 27329,
            "j_seats": 6094,
            "f_seats": 11376,
            "r_seats": 5519,
            "cargo_demand": 449307
        }
    },
    {
        "iata": "CFV",
        "icao": "KCFV",
        "airportName": "Coffeyville Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Coffeyville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.09400177",
        "longitude": "-95.57189941",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5872",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3502,
            "s_seats": 2197,
            "j_seats": 1558,
            "f_seats": 248,
            "r_seats": 179,
            "cargo_demand": 5548
        }
    },
    {
        "iata": "CGB",
        "icao": "SBCY",
        "airportName": "Marechal Rondon International Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Cuiaba",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-15.65289974",
        "longitude": "-56.11669922",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 93224,
            "s_seats": 33729,
            "j_seats": 10702,
            "f_seats": 12792,
            "r_seats": 4282,
            "cargo_demand": 400286
        }
    },
    {
        "iata": "CGD",
        "icao": "ZGCD",
        "airportName": "Changde Taohuayuan Airport",
        "location_country": "China",
        "location_city": "Hunan",
        "location_state": "Changde",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.91889954",
        "longitude": "111.6399994",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8366",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 151397,
            "s_seats": 38209,
            "j_seats": 56858,
            "f_seats": 18472,
            "r_seats": 4332,
            "cargo_demand": 347765
        }
    },
    {
        "iata": "CGE",
        "icao": "KCGE",
        "airportName": "Cambridge-Dorchester Airport",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Cambridge",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.53929901",
        "longitude": "-76.03040314",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4476",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3953,
            "s_seats": 2480,
            "j_seats": 1075,
            "f_seats": 20,
            "r_seats": 187,
            "cargo_demand": 71837
        }
    },
    {
        "iata": "CGF",
        "icao": "KCGF",
        "airportName": "Cuyahoga County Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Cleveland",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.56510162",
        "longitude": "-81.48639679",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5102",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76558,
            "s_seats": 25348,
            "j_seats": 6252,
            "f_seats": 6278,
            "r_seats": 7945,
            "cargo_demand": 74894
        }
    },
    {
        "iata": "CGH",
        "icao": "SBSP",
        "airportName": "Sio Paulo/Congonhas-Deputado Freitas Nobre Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Sio Paulo",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "large_airport",
        "latitude": "-23.62611008",
        "longitude": "-46.65638733",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "4708",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "6365",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 396234,
            "s_seats": 77260,
            "j_seats": 139546,
            "f_seats": 22216,
            "r_seats": 10000,
            "cargo_demand": 22281225
        }
    },
    {
        "iata": "CGI",
        "icao": "KCGI",
        "airportName": "Cape Girardeau Regional Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Cape Girardeau",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.22529984",
        "longitude": "-89.57080078",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3996",
                "runway_width": "100",
                "runway_material": "PEM"
            },
            {
                "runway_name": "10/28",
                "runway_length": "6499",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 121607,
            "s_seats": 26693,
            "j_seats": 52861,
            "f_seats": 15085,
            "r_seats": 6866,
            "cargo_demand": 151308
        }
    },
    {
        "iata": "CGK",
        "icao": "WIII",
        "airportName": "Soekarno-Hatta International Airport",
        "location_country": "Indonesia",
        "location_city": "Jakarta",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-6.12556982",
        "longitude": "106.6559982",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9843",
                "runway_width": "197",
                "runway_material": "CONC"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "11812",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "12008",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 402050,
            "s_seats": 95670,
            "j_seats": 162445,
            "f_seats": 56095,
            "r_seats": 10000,
            "cargo_demand": 28383859
        }
    },
    {
        "iata": "CGM",
        "icao": "RPMH",
        "airportName": "Camiguin Airport",
        "location_country": "Philippines",
        "location_city": "Mambajao",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.253894",
        "longitude": "124.709115",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3945",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156654,
            "s_seats": 43526,
            "j_seats": 36629,
            "f_seats": 12453,
            "r_seats": 6362,
            "cargo_demand": 187615
        }
    },
    {
        "iata": "CGN",
        "icao": "EDDK",
        "airportName": "Cologne Bonn Airport",
        "location_country": "Germany",
        "location_city": "North Rhine-Westphalia",
        "location_state": "Cologne / Bonn",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "50.86589813",
        "longitude": "7.142739773",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8067",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "12516",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "6112",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 207791,
            "s_seats": 97342,
            "j_seats": 86700,
            "f_seats": 66692,
            "r_seats": 10000,
            "cargo_demand": 1479942
        }
    },
    {
        "iata": "CGO",
        "icao": "ZHCC",
        "airportName": "Zhengzhou Xinzheng International Airport",
        "location_country": "China",
        "location_city": "Henan",
        "location_state": "Zhengzhou",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "34.5196991",
        "longitude": "113.8410034",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "11155",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 224898,
            "s_seats": 90526,
            "j_seats": 69118,
            "f_seats": 27339,
            "r_seats": 10000,
            "cargo_demand": 23261826
        }
    },
    {
        "iata": "CGP",
        "icao": "VGEG",
        "airportName": "Shah Amanat International Airport",
        "location_country": "Bangladesh",
        "location_city": "Chittagong",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.249599",
        "longitude": "91.813301",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9646",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 180126,
            "s_seats": 36775,
            "j_seats": 47795,
            "f_seats": 4020,
            "r_seats": 3126,
            "cargo_demand": 483570
        }
    },
    {
        "iata": "CGQ",
        "icao": "ZYCC",
        "airportName": "Changchun Longjia International Airport",
        "location_country": "China",
        "location_city": "Jilin",
        "location_state": "Changchun",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.99620056",
        "longitude": "125.6849976",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10500",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 71751,
            "s_seats": 33788,
            "j_seats": 21500,
            "f_seats": 5017,
            "r_seats": 7240,
            "cargo_demand": 499562
        }
    },
    {
        "iata": "CGR",
        "icao": "SBCG",
        "airportName": "Campo Grande International Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso do Sul",
        "location_state": "Campo Grande",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-20.46870041",
        "longitude": "-54.67250061",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "8530",
                "runway_width": "141",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 86110,
            "s_seats": 42182,
            "j_seats": 19001,
            "f_seats": 13162,
            "r_seats": 7743,
            "cargo_demand": 124259
        }
    },
    {
        "iata": "CGS",
        "icao": "KCGS",
        "airportName": "College Park Airport",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "College Park",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.98059845",
        "longitude": "-76.92230225",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2607",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2615,
            "s_seats": 1713,
            "j_seats": 1662,
            "f_seats": 468,
            "r_seats": 27,
            "cargo_demand": 76288
        }
    },
    {
        "iata": "CGV",
        "icao": "YCAG",
        "airportName": "Caiguna Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Caiguna",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-32.26499939",
        "longitude": "125.4929962",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4265",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1691,
            "s_seats": 2500,
            "j_seats": 1560,
            "f_seats": 5,
            "r_seats": 172,
            "cargo_demand": 43871
        }
    },
    {
        "iata": "CGZ",
        "icao": "KCGZ",
        "airportName": "Casa Grande Municipal Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Casa Grande",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.954899",
        "longitude": "-111.766998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5200",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3020,
            "s_seats": 1946,
            "j_seats": 1371,
            "f_seats": 426,
            "r_seats": 118,
            "cargo_demand": 98546
        }
    },
    {
        "iata": "CHA",
        "icao": "KCHA",
        "airportName": "Chattanooga Metropolitan Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Chattanooga",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "35.035301",
        "longitude": "-85.203796",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7400",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176411,
            "s_seats": 81901,
            "j_seats": 126826,
            "f_seats": 55575,
            "r_seats": 10000,
            "cargo_demand": 27350619
        }
    },
    {
        "iata": "CHC",
        "icao": "NZCH",
        "airportName": "Christchurch International Airport",
        "location_country": "New Zealand",
        "location_city": "Christchurch",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "large_airport",
        "latitude": "-43.48939896",
        "longitude": "172.5319977",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "1690",
                "runway_width": "229",
                "runway_material": "GRS"
            },
            {
                "runway_name": "02/20",
                "runway_length": "10787",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5587",
                "runway_width": "148",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 307590,
            "s_seats": 88636,
            "j_seats": 115313,
            "f_seats": 63420,
            "r_seats": 10000,
            "cargo_demand": 29301780
        }
    },
    {
        "iata": "CHF",
        "icao": "RKPE",
        "airportName": "Jinhae Airport",
        "location_country": "South Korea",
        "location_city": "Jinhae",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.140248",
        "longitude": "128.696229",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3766",
                "runway_width": "78",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1392,
            "s_seats": 2555,
            "j_seats": 1305,
            "f_seats": 360,
            "r_seats": 111,
            "cargo_demand": 97399
        }
    },
    {
        "iata": "CHH",
        "icao": "SPPY",
        "airportName": "Chachapoyas Airport",
        "location_country": "Peru",
        "location_city": "Chachapoyas",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.201809883",
        "longitude": "-77.85610199",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6496",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156470,
            "s_seats": 29064,
            "j_seats": 39333,
            "f_seats": 14672,
            "r_seats": 6036,
            "cargo_demand": 179878
        }
    },
    {
        "iata": "CHK",
        "icao": "KCHK",
        "airportName": "Chickasha Municipal Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Chickasha",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.09740067",
        "longitude": "-97.96769714",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2525",
                "runway_width": "100",
                "runway_material": "TURF-G"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5100",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2840",
                "runway_width": "145",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2296,
            "s_seats": 2391,
            "j_seats": 1612,
            "f_seats": 405,
            "r_seats": 37,
            "cargo_demand": 1375
        }
    },
    {
        "iata": "CHL",
        "icao": "KLLJ",
        "airportName": "Challis Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Challis",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.522999",
        "longitude": "-114.218002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4600",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2353,
            "s_seats": 1615,
            "j_seats": 1004,
            "f_seats": 450,
            "r_seats": 182,
            "cargo_demand": 33621
        }
    },
    {
        "iata": "CHM",
        "icao": "SPEO",
        "airportName": "Tnte. FAP Jaime Montreuil Morales Airport",
        "location_country": "Peru",
        "location_city": "Chimbote",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.149609566",
        "longitude": "-78.52380371",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155683,
            "s_seats": 36079,
            "j_seats": 27386,
            "f_seats": 10841,
            "r_seats": 8300,
            "cargo_demand": 117581
        }
    },
    {
        "iata": "CHN",
        "icao": "RKJU",
        "airportName": "Jeonju Airport",
        "location_country": "South Korea",
        "location_city": "Jeonju",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.87808",
        "longitude": "127.119112",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4800",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3417,
            "s_seats": 2213,
            "j_seats": 1022,
            "f_seats": 455,
            "r_seats": 5,
            "cargo_demand": 46382
        }
    },
    {
        "iata": "CHO",
        "icao": "KCHO",
        "airportName": "Charlottesville-Albemarle Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Charlottesville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.1385994",
        "longitude": "-78.45290375",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114865,
            "s_seats": 49094,
            "j_seats": 28957,
            "f_seats": 11047,
            "r_seats": 9425,
            "cargo_demand": 220773
        }
    },
    {
        "iata": "CHQ",
        "icao": "LGSA",
        "airportName": "Chania International Airport",
        "location_country": "Greece",
        "location_city": "Chania",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "35.53170013",
        "longitude": "24.14970016",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10982",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74397,
            "s_seats": 32129,
            "j_seats": 38709,
            "f_seats": 10137,
            "r_seats": 3348,
            "cargo_demand": 364292
        }
    },
    {
        "iata": "CHR",
        "icao": "LFLX",
        "airportName": "\"Ch\u00e2teauroux-Centre \"\"Marcel Dassault\"\" Airport\"",
        "location_country": "France",
        "location_city": "Centre-Val de Loire",
        "location_state": "Ch\u00e2teauroux",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.860278",
        "longitude": "1.721111",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 178586,
            "s_seats": 44231,
            "j_seats": 33079,
            "f_seats": 4125,
            "r_seats": 9208,
            "cargo_demand": 427265
        }
    },
    {
        "iata": "CHS",
        "icao": "KCHS",
        "airportName": "Charleston International Airport / Charleston Air Force Base",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Charleston",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "32.898602",
        "longitude": "-80.040497",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7004",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "9001",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 118928,
            "s_seats": 81141,
            "j_seats": 75737,
            "f_seats": 62507,
            "r_seats": 10000,
            "cargo_demand": 11614136
        }
    },
    {
        "iata": "CHT",
        "icao": "NZCI",
        "airportName": "Chatham Islands / Tuuta Airport",
        "location_country": "New Zealand",
        "location_city": "Chatham Islands",
        "location_state": "",
        "time_zone": "UTC+12:45",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-43.81000137",
        "longitude": "-176.4570007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4462",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194163,
            "s_seats": 30674,
            "j_seats": 55743,
            "f_seats": 4821,
            "r_seats": 5790,
            "cargo_demand": 111061
        }
    },
    {
        "iata": "CHV",
        "icao": "LPCH",
        "airportName": "Chaves Airport",
        "location_country": "Portugal",
        "location_city": "Chaves",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "41.722356",
        "longitude": "-7.463064",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "2812",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3624,
            "s_seats": 2081,
            "j_seats": 1809,
            "f_seats": 339,
            "r_seats": 112,
            "cargo_demand": 15962
        }
    },
    {
        "iata": "CHX",
        "icao": "MPCH",
        "airportName": "\"Changuinola \"\"Capitan Manuel Nino\"\" International Airport\"",
        "location_country": "Panama",
        "location_city": "Changuinola",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.458962",
        "longitude": "-82.515062",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3609",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112058,
            "s_seats": 30965,
            "j_seats": 17172,
            "f_seats": 16614,
            "r_seats": 5579,
            "cargo_demand": 294240
        }
    },
    {
        "iata": "CHY",
        "icao": "AGGC",
        "airportName": "Choiseul Bay Airport",
        "location_country": "Solomon Islands",
        "location_city": "Taro Island",
        "location_state": "Choiseul Bay",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.711944",
        "longitude": "156.396111",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2164",
                "runway_width": "82",
                "runway_material": "Sand grass"
            }
        ],
        "demand": {
            "y_seats": 1771,
            "s_seats": 2486,
            "j_seats": 1424,
            "f_seats": 94,
            "r_seats": 129,
            "cargo_demand": 79880
        }
    },
    {
        "iata": "CIA",
        "icao": "LIRA",
        "airportName": "Ciampino-G. B. Pastine International Airport",
        "location_country": "Italy",
        "location_city": "Lazio",
        "location_state": "Rome",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "41.7994",
        "longitude": "12.5949",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7226",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81700,
            "s_seats": 43658,
            "j_seats": 17232,
            "f_seats": 10282,
            "r_seats": 9533,
            "cargo_demand": 411963
        }
    },
    {
        "iata": "CIC",
        "icao": "KCIC",
        "airportName": "Chico Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Chico",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.79539871",
        "longitude": "-121.8580017",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "6724",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "3005",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3507,
            "s_seats": 2900,
            "j_seats": 1182,
            "f_seats": 309,
            "r_seats": 200,
            "cargo_demand": 44711
        }
    },
    {
        "iata": "CID",
        "icao": "KCID",
        "airportName": "The Eastern Iowa Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Cedar Rapids",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "41.884701",
        "longitude": "-91.7108",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8601",
                "runway_width": "150",
                "runway_material": "PER"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6200",
                "runway_width": "150",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 381842,
            "s_seats": 88619,
            "j_seats": 92367,
            "f_seats": 67923,
            "r_seats": 10000,
            "cargo_demand": 29015448
        }
    },
    {
        "iata": "CIE",
        "icao": "YCOI",
        "airportName": "Collie Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Collie",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-33.36669922",
        "longitude": "116.2170029",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3772",
                "runway_width": "",
                "runway_material": "XU"
            }
        ],
        "demand": {
            "y_seats": 1662,
            "s_seats": 2050,
            "j_seats": 1846,
            "f_seats": 242,
            "r_seats": 22,
            "cargo_demand": 16849
        }
    },
    {
        "iata": "CIG",
        "icao": "KCAG",
        "airportName": "Craig-Moffat Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Craig",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.49520111",
        "longitude": "-107.5220032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5600",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1544,
            "s_seats": 2050,
            "j_seats": 1650,
            "f_seats": 388,
            "r_seats": 60,
            "cargo_demand": 60885
        }
    },
    {
        "iata": "CII",
        "icao": "LTBD",
        "airportName": "Aydin Airport",
        "location_country": "Turkey",
        "location_city": "Aydin",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.81499863",
        "longitude": "27.89529991",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4708",
                "runway_width": "98",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 1370,
            "s_seats": 2618,
            "j_seats": 1598,
            "f_seats": 45,
            "r_seats": 45,
            "cargo_demand": 49834
        }
    },
    {
        "iata": "CIJ",
        "icao": "SLCO",
        "airportName": "Captain Anibal Arab Airport",
        "location_country": "Bolivia",
        "location_city": "Cobija",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.04039955",
        "longitude": "-68.78299713",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 65047,
            "s_seats": 32437,
            "j_seats": 36006,
            "f_seats": 15921,
            "r_seats": 3441,
            "cargo_demand": 99902
        }
    },
    {
        "iata": "CIK",
        "icao": "PACI",
        "airportName": "Chalkyitsik Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Chalkyitsik",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "66.64499664",
        "longitude": "-143.7400055",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4000",
                "runway_width": "90",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 3861,
            "s_seats": 2452,
            "j_seats": 1186,
            "f_seats": 65,
            "r_seats": 184,
            "cargo_demand": 29503
        }
    },
    {
        "iata": "CIN",
        "icao": "KCIN",
        "airportName": "Arthur N. Neu Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Carroll",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.0461998",
        "longitude": "-94.78900146",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2620",
                "runway_width": "125",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1354,
            "s_seats": 2455,
            "j_seats": 1720,
            "f_seats": 425,
            "r_seats": 146,
            "cargo_demand": 29668
        }
    },
    {
        "iata": "CIO",
        "icao": "SGCO",
        "airportName": "Teniente Coronel Carmelo Peralta Airport",
        "location_country": "Paraguay",
        "location_city": "Concepcion",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Mar",
        "airport_size": "small_airport",
        "latitude": "-23.442363",
        "longitude": "-57.427253",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6070",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1265,
            "s_seats": 1999,
            "j_seats": 1525,
            "f_seats": 243,
            "r_seats": 179,
            "cargo_demand": 57024
        }
    },
    {
        "iata": "CIP",
        "icao": "FLCP",
        "airportName": "Chipata Airport",
        "location_country": "Zambia",
        "location_city": "Chipata",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.55830002",
        "longitude": "32.58720016",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4823",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3942,
            "s_seats": 2949,
            "j_seats": 1395,
            "f_seats": 31,
            "r_seats": 127,
            "cargo_demand": 93260
        }
    },
    {
        "iata": "CIR",
        "icao": "KCIR",
        "airportName": "Cairo Regional Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Cairo",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.0644989",
        "longitude": "-89.21959686",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3201",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4003",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2974,
            "s_seats": 2433,
            "j_seats": 1405,
            "f_seats": 168,
            "r_seats": 159,
            "cargo_demand": 9675
        }
    },
    {
        "iata": "CIS",
        "icao": "PCIS",
        "airportName": "Canton Island Airport",
        "location_country": "Kiribati",
        "location_city": "Canton Island",
        "location_state": "",
        "time_zone": "UTC+13:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.76812005",
        "longitude": "-171.7100067",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6230",
                "runway_width": "140",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102037,
            "s_seats": 25856,
            "j_seats": 38231,
            "f_seats": 4589,
            "r_seats": 4956,
            "cargo_demand": 69727
        }
    },
    {
        "iata": "CIT",
        "icao": "UAII",
        "airportName": "Shymkent International Airport",
        "location_country": "Kazakhstan",
        "location_city": "Shymkent",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.36420059",
        "longitude": "69.47889709",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9186",
                "runway_width": "144",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 127784,
            "s_seats": 27846,
            "j_seats": 40307,
            "f_seats": 12324,
            "r_seats": 4570,
            "cargo_demand": 483653
        }
    },
    {
        "iata": "CIU",
        "icao": "KCIU",
        "airportName": "Chippewa County International Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Sault Ste Marie",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.25080109",
        "longitude": "-84.47239685",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7201",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 124054,
            "s_seats": 37700,
            "j_seats": 9407,
            "f_seats": 6701,
            "r_seats": 8687,
            "cargo_demand": 164741
        }
    },
    {
        "iata": "CIW",
        "icao": "TVSC",
        "airportName": "Canouan Airport",
        "location_country": "Saint Vincent and the Grenadines",
        "location_city": "Canouan",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.699",
        "longitude": "-61.3424",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5900",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86320,
            "s_seats": 35570,
            "j_seats": 56671,
            "f_seats": 1663,
            "r_seats": 4066,
            "cargo_demand": 432273
        }
    },
    {
        "iata": "CIX",
        "icao": "SPHI",
        "airportName": "FAP Captain Jose Abelardo Quinones Gonzalez International Airport",
        "location_country": "Peru",
        "location_city": "Chiclayo",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.787479877",
        "longitude": "-79.82810211",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8266",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110060,
            "s_seats": 38990,
            "j_seats": 37131,
            "f_seats": 3257,
            "r_seats": 8790,
            "cargo_demand": 413934
        }
    },
    {
        "iata": "CIY",
        "icao": "LICB",
        "airportName": "Comiso Airport",
        "location_country": "Italy",
        "location_city": "Sicily",
        "location_state": "Comiso",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "36.994601",
        "longitude": "14.607182",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8070",
                "runway_width": "148",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 111173,
            "s_seats": 35890,
            "j_seats": 14190,
            "f_seats": 18515,
            "r_seats": 9626,
            "cargo_demand": 266240
        }
    },
    {
        "iata": "CIZ",
        "icao": "SWKO",
        "airportName": "Coari Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Coari",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-4.134059906",
        "longitude": "-63.13259888",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5249",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3324,
            "s_seats": 2210,
            "j_seats": 1094,
            "f_seats": 148,
            "r_seats": 197,
            "cargo_demand": 28787
        }
    },
    {
        "iata": "CJA",
        "icao": "SPJR",
        "airportName": "Mayor General FAP Armando Revoredo Iglesias Airport",
        "location_country": "Peru",
        "location_city": "Cajamarca",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.139180183",
        "longitude": "-78.48940277",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8201",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179739,
            "s_seats": 29769,
            "j_seats": 12509,
            "f_seats": 19507,
            "r_seats": 4551,
            "cargo_demand": 190024
        }
    },
    {
        "iata": "CJB",
        "icao": "VOCB",
        "airportName": "Coimbatore International Airport",
        "location_country": "India",
        "location_city": "Tamil Nadu",
        "location_state": "Coimbatore",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.02999973",
        "longitude": "77.04340363",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8480",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79117,
            "s_seats": 49386,
            "j_seats": 35653,
            "f_seats": 9575,
            "r_seats": 7395,
            "cargo_demand": 275282
        }
    },
    {
        "iata": "CJC",
        "icao": "SCCF",
        "airportName": "El Loa Airport",
        "location_country": "Chile",
        "location_city": "Calama",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-22.49819946",
        "longitude": "-68.9036026",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9974",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 158942,
            "s_seats": 26932,
            "j_seats": 58339,
            "f_seats": 8613,
            "r_seats": 7236,
            "cargo_demand": 141279
        }
    },
    {
        "iata": "CJF",
        "icao": "YCWA",
        "airportName": "Coondewanna Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Coondewanna",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.9666996",
        "longitude": "118.8130035",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6398",
                "runway_width": "",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2667,
            "s_seats": 2576,
            "j_seats": 1715,
            "f_seats": 195,
            "r_seats": 103,
            "cargo_demand": 77347
        }
    },
    {
        "iata": "CJJ",
        "icao": "RKTU",
        "airportName": "Cheongju International Airport",
        "location_country": "South Korea",
        "location_city": "Cheongju",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.717008",
        "longitude": "127.498741",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "9000",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 232548,
            "s_seats": 77960,
            "j_seats": 40871,
            "f_seats": 64353,
            "r_seats": 10000,
            "cargo_demand": 6913377
        }
    },
    {
        "iata": "CJL",
        "icao": "OPCH",
        "airportName": "Chitral Airport",
        "location_country": "Pakistan",
        "location_city": "Chitral",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.886213",
        "longitude": "71.799922",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5741",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129596,
            "s_seats": 38897,
            "j_seats": 55607,
            "f_seats": 18251,
            "r_seats": 9684,
            "cargo_demand": 299693
        }
    },
    {
        "iata": "CJM",
        "icao": "VTSE",
        "airportName": "Chumphon Airport",
        "location_country": "Thailand",
        "location_city": "Chumphon",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.71119976",
        "longitude": "99.36170197",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52839,
            "s_seats": 28591,
            "j_seats": 16675,
            "f_seats": 1387,
            "r_seats": 4095,
            "cargo_demand": 370379
        }
    },
    {
        "iata": "CJN",
        "icao": "WI1A",
        "airportName": "Cijulang Nusawiru Airport",
        "location_country": "Indonesia",
        "location_city": "Cijulang",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.719895",
        "longitude": "108.488995",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4549",
                "runway_width": "94",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1150,
            "s_seats": 2885,
            "j_seats": 1461,
            "f_seats": 445,
            "r_seats": 70,
            "cargo_demand": 8476
        }
    },
    {
        "iata": "CJS",
        "icao": "MMCS",
        "airportName": "Abraham Gonzalez International Airport",
        "location_country": "Mexico",
        "location_city": "Chihuahua",
        "location_state": "Ciudad Juarez",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.63610077",
        "longitude": "-106.4290009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5741",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86119,
            "s_seats": 48460,
            "j_seats": 5907,
            "f_seats": 12315,
            "r_seats": 6266,
            "cargo_demand": 487294
        }
    },
    {
        "iata": "CJT",
        "icao": "MMCO",
        "airportName": "Copalar Airport",
        "location_country": "Mexico",
        "location_city": "Chiapas",
        "location_state": "Comitan",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "small_airport",
        "latitude": "16.176701",
        "longitude": "-92.050598",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5774",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2447,
            "s_seats": 2918,
            "j_seats": 1168,
            "f_seats": 53,
            "r_seats": 122,
            "cargo_demand": 84472
        }
    },
    {
        "iata": "CJU",
        "icao": "RKPC",
        "airportName": "Jeju International Airport",
        "location_country": "South Korea",
        "location_city": "Jeju",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "33.51129913",
        "longitude": "126.4929962",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10433",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6268",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 324150,
            "s_seats": 85254,
            "j_seats": 101601,
            "f_seats": 68653,
            "r_seats": 10000,
            "cargo_demand": 13461764
        }
    },
    {
        "iata": "CKA",
        "icao": "KCKA",
        "airportName": "Kegelman Air Force Auxiliary Field",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Cherokee",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.7439003",
        "longitude": "-98.12310028",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5500",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7946",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2823,
            "s_seats": 2613,
            "j_seats": 1799,
            "f_seats": 475,
            "r_seats": 71,
            "cargo_demand": 4798
        }
    },
    {
        "iata": "CKB",
        "icao": "KCKB",
        "airportName": "North Central West Virginia Airport",
        "location_country": "United States",
        "location_city": "West Virginia",
        "location_state": "Clarksburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.29660034",
        "longitude": "-80.22810364",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7800",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169671,
            "s_seats": 47783,
            "j_seats": 52366,
            "f_seats": 18062,
            "r_seats": 8095,
            "cargo_demand": 75177
        }
    },
    {
        "iata": "CKC",
        "icao": "UKKE",
        "airportName": "Cherkasy International Airport",
        "location_country": "Ukraine",
        "location_city": "Cherkasy",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.41559982",
        "longitude": "31.99530029",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "8180",
                "runway_width": "138",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148282,
            "s_seats": 47616,
            "j_seats": 30873,
            "f_seats": 15118,
            "r_seats": 6837,
            "cargo_demand": 455390
        }
    },
    {
        "iata": "CKG",
        "icao": "ZUCK",
        "airportName": "Chongqing Jiangbei International Airport",
        "location_country": "China",
        "location_city": "Chongqing",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "29.71920013",
        "longitude": "106.6419983",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02L/20R",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "03/21",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 461983,
            "s_seats": 95771,
            "j_seats": 21581,
            "f_seats": 42237,
            "r_seats": 10000,
            "cargo_demand": 6926035
        }
    },
    {
        "iata": "CKI",
        "icao": "YCKI",
        "airportName": "Croker Island Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Croker Island",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.16499996",
        "longitude": "132.4830017",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4701",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 1150,
            "s_seats": 1657,
            "j_seats": 1089,
            "f_seats": 273,
            "r_seats": 43,
            "cargo_demand": 18390
        }
    },
    {
        "iata": "CKK",
        "icao": "KCVK",
        "airportName": "Sharp County Regional Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Ash Flat",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.26490021",
        "longitude": "-91.56259918",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5156",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2851,
            "s_seats": 2414,
            "j_seats": 1884,
            "f_seats": 359,
            "r_seats": 49,
            "cargo_demand": 48612
        }
    },
    {
        "iata": "CKL",
        "icao": "UUMU",
        "airportName": "Chkalovsky Airport",
        "location_country": "Russia",
        "location_city": "Shchyolkovo (Moscow Oblast)",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.8783",
        "longitude": "38.061699",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12L/30R",
                "runway_length": "11811",
                "runway_width": "315",
                "runway_material": "concrete"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "9842",
                "runway_width": "190",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 144923,
            "s_seats": 42038,
            "j_seats": 55873,
            "f_seats": 4519,
            "r_seats": 6118,
            "cargo_demand": 142017
        }
    },
    {
        "iata": "CKM",
        "icao": "KCKM",
        "airportName": "Fletcher Field",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Clarksdale",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.29970169",
        "longitude": "-90.51229858",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5404",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2088,
            "s_seats": 1573,
            "j_seats": 1896,
            "f_seats": 337,
            "r_seats": 197,
            "cargo_demand": 69736
        }
    },
    {
        "iata": "CKN",
        "icao": "KCKN",
        "airportName": "Crookston Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Crookston",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "47.84170151",
        "longitude": "-96.62159729",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2089",
                "runway_width": "202",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4300",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2978",
                "runway_width": "202",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1122,
            "s_seats": 2280,
            "j_seats": 1141,
            "f_seats": 389,
            "r_seats": 132,
            "cargo_demand": 14954
        }
    },
    {
        "iata": "CKO",
        "icao": "SSCP",
        "airportName": "Cornelio Procopio Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Cornelio Procopio",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-23.15250015",
        "longitude": "-50.60250092",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2089,
            "s_seats": 2854,
            "j_seats": 1320,
            "f_seats": 445,
            "r_seats": 39,
            "cargo_demand": 59767
        }
    },
    {
        "iata": "CKS",
        "icao": "SBCJ",
        "airportName": "Carajas Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Parauapebas",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.115277767",
        "longitude": "-50.00138855",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109330,
            "s_seats": 32047,
            "j_seats": 24814,
            "f_seats": 2257,
            "r_seats": 4770,
            "cargo_demand": 200629
        }
    },
    {
        "iata": "CKT",
        "icao": "OIMC",
        "airportName": "Sarakhs Airport",
        "location_country": "Iran",
        "location_city": "Sarakhs",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "36.50120163",
        "longitude": "61.06489944",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "13076",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186487,
            "s_seats": 36830,
            "j_seats": 44558,
            "f_seats": 4233,
            "r_seats": 9337,
            "cargo_demand": 284551
        }
    },
    {
        "iata": "CKV",
        "icao": "KCKV",
        "airportName": "Clarksville-Montgomery County Regional Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Clarksville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.62189865",
        "longitude": "-87.41500092",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4004",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1080,
            "s_seats": 2073,
            "j_seats": 1801,
            "f_seats": 282,
            "r_seats": 178,
            "cargo_demand": 68833
        }
    },
    {
        "iata": "CKW",
        "icao": "YCHK",
        "airportName": "Graeme Rowley Aerodrome",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Christmas Creek mine",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.3543",
        "longitude": "119.6426",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1123,
            "s_seats": 1971,
            "j_seats": 1446,
            "f_seats": 125,
            "r_seats": 172,
            "cargo_demand": 77154
        }
    },
    {
        "iata": "CKY",
        "icao": "GUCY",
        "airportName": "Conakry International Airport",
        "location_country": "Guinea",
        "location_city": "Conakry",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.57689",
        "longitude": "-13.612",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10826",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165694,
            "s_seats": 37777,
            "j_seats": 32135,
            "f_seats": 16352,
            "r_seats": 4668,
            "cargo_demand": 185375
        }
    },
    {
        "iata": "CKZ",
        "icao": "LTBH",
        "airportName": "Canakkale Airport",
        "location_country": "Turkey",
        "location_city": "Canakkale",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.13769913",
        "longitude": "26.42679977",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7710",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 117847,
            "s_seats": 31952,
            "j_seats": 54184,
            "f_seats": 2017,
            "r_seats": 8272,
            "cargo_demand": 184988
        }
    },
    {
        "iata": "CLD",
        "icao": "KCRQ",
        "airportName": "McClellan-Palomar Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Carlsbad",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.12829971",
        "longitude": "-117.2799988",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4897",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97339,
            "s_seats": 34610,
            "j_seats": 32398,
            "f_seats": 6758,
            "r_seats": 3174,
            "cargo_demand": 459344
        }
    },
    {
        "iata": "CLE",
        "icao": "KCLE",
        "airportName": "Cleveland Hopkins International Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Cleveland",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "41.4117012",
        "longitude": "-81.84980011",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06C/24C",
                "runway_length": "7096",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "06L/24R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "8999",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "10/28",
                "runway_length": "6017",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 307315,
            "s_seats": 72691,
            "j_seats": 134838,
            "f_seats": 38184,
            "r_seats": 10000,
            "cargo_demand": 13607573
        }
    },
    {
        "iata": "CLH",
        "icao": "YCAH",
        "airportName": "Coolah Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Coolah",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-31.77330017",
        "longitude": "149.6100006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3523",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 2938,
            "s_seats": 2250,
            "j_seats": 1895,
            "f_seats": 226,
            "r_seats": 151,
            "cargo_demand": 14056
        }
    },
    {
        "iata": "CLI",
        "icao": "KCLI",
        "airportName": "Clintonville Municipal Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Clintonville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.61380005",
        "longitude": "-88.73130035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3299",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2000",
                "runway_width": "170",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4600",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3098,
            "s_seats": 1932,
            "j_seats": 1979,
            "f_seats": 391,
            "r_seats": 192,
            "cargo_demand": 63479
        }
    },
    {
        "iata": "CLJ",
        "icao": "LRCL",
        "airportName": "Cluj-Napoca International Airport",
        "location_country": "Romania",
        "location_city": "Cluj-Napoca",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.785198",
        "longitude": "23.686199",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6693",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "08/26",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 189767,
            "s_seats": 29191,
            "j_seats": 57028,
            "f_seats": 7497,
            "r_seats": 8680,
            "cargo_demand": 272294
        }
    },
    {
        "iata": "CLK",
        "icao": "KCLK",
        "airportName": "Clinton Regional Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Clinton",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.53829956",
        "longitude": "-98.93270111",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "1416",
                "runway_width": "245",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4300",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1934,
            "s_seats": 1522,
            "j_seats": 1978,
            "f_seats": 32,
            "r_seats": 168,
            "cargo_demand": 76161
        }
    },
    {
        "iata": "CLL",
        "icao": "KCLL",
        "airportName": "Easterwood Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "College Station",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.58860016",
        "longitude": "-96.36380005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5149",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5159",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 55200,
            "s_seats": 49382,
            "j_seats": 30371,
            "f_seats": 17945,
            "r_seats": 6446,
            "cargo_demand": 170883
        }
    },
    {
        "iata": "CLM",
        "icao": "KCLM",
        "airportName": "William R. Fairchild International Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Port Angeles",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "48.12020111",
        "longitude": "-123.5",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6347",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3245",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168337,
            "s_seats": 44900,
            "j_seats": 22529,
            "f_seats": 8277,
            "r_seats": 5212,
            "cargo_demand": 150511
        }
    },
    {
        "iata": "CLN",
        "icao": "SBCI",
        "airportName": "Carolina Airport",
        "location_country": "Brazil",
        "location_city": "Maranhio",
        "location_state": "Carolina",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.320439816",
        "longitude": "-47.45869827",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5906",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73198,
            "s_seats": 27693,
            "j_seats": 47272,
            "f_seats": 2112,
            "r_seats": 8003,
            "cargo_demand": 220931
        }
    },
    {
        "iata": "CLO",
        "icao": "SKCL",
        "airportName": "Alfonso Bonilla Aragon International Airport",
        "location_country": "Colombia",
        "location_city": "Cali",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.54322",
        "longitude": "-76.3816",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57033,
            "s_seats": 48528,
            "j_seats": 59572,
            "f_seats": 5610,
            "r_seats": 6237,
            "cargo_demand": 273438
        }
    },
    {
        "iata": "CLP",
        "icao": "PFCL",
        "airportName": "Clarks Point Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Clark's Point",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "58.83369827",
        "longitude": "-158.529007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3200",
                "runway_width": "60",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 2489,
            "s_seats": 2122,
            "j_seats": 1961,
            "f_seats": 109,
            "r_seats": 63,
            "cargo_demand": 84792
        }
    },
    {
        "iata": "CLQ",
        "icao": "MMIA",
        "airportName": "Licenciado Miguel de la Madrid Airport",
        "location_country": "Mexico",
        "location_city": "Colima",
        "location_state": "Colima City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "19.277",
        "longitude": "-103.577002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54781,
            "s_seats": 33266,
            "j_seats": 55234,
            "f_seats": 14459,
            "r_seats": 4202,
            "cargo_demand": 193916
        }
    },
    {
        "iata": "CLR",
        "icao": "KCLR",
        "airportName": "Cliff Hatfield Memorial Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Calipatria",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.13150024",
        "longitude": "-115.5210037",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3423",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2605,
            "s_seats": 2977,
            "j_seats": 1504,
            "f_seats": 399,
            "r_seats": 136,
            "cargo_demand": 54144
        }
    },
    {
        "iata": "CLS",
        "icao": "KCLS",
        "airportName": "Chehalis-Centralia Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Chehalis",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "46.67699814",
        "longitude": "-122.9830017",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1604,
            "s_seats": 1636,
            "j_seats": 1597,
            "f_seats": 160,
            "r_seats": 175,
            "cargo_demand": 97486
        }
    },
    {
        "iata": "CLT",
        "icao": "KCLT",
        "airportName": "Charlotte Douglas International Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Charlotte",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "35.2140007",
        "longitude": "-80.94309998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7502",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18C/36C",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CONC-F"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "8676",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 273158,
            "s_seats": 85775,
            "j_seats": 85294,
            "f_seats": 54801,
            "r_seats": 10000,
            "cargo_demand": 22725295
        }
    },
    {
        "iata": "CLU",
        "icao": "KBAK",
        "airportName": "Columbus Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Columbus",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.26190186",
        "longitude": "-85.89630127",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6400",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123715,
            "s_seats": 49714,
            "j_seats": 43941,
            "f_seats": 18613,
            "r_seats": 3535,
            "cargo_demand": 413881
        }
    },
    {
        "iata": "CLW",
        "icao": "KCLW",
        "airportName": "Clearwater Air Park",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Clearwater",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "27.97669983",
        "longitude": "-82.75869751",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3300",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2272,
            "s_seats": 1716,
            "j_seats": 1265,
            "f_seats": 233,
            "r_seats": 36,
            "cargo_demand": 1302
        }
    },
    {
        "iata": "CLX",
        "icao": "SATC",
        "airportName": "Clorinda Airport",
        "location_country": "Argentina",
        "location_city": "Formosa",
        "location_state": "Clorinda",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.3036",
        "longitude": "-57.7344",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2553,
            "s_seats": 2483,
            "j_seats": 1624,
            "f_seats": 192,
            "r_seats": 174,
            "cargo_demand": 94565
        }
    },
    {
        "iata": "CLY",
        "icao": "LFKC",
        "airportName": "Calvi - Sainte-Catherine Airport",
        "location_country": "France",
        "location_city": "Corsica",
        "location_state": "Calvi",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "42.5244444",
        "longitude": "8.7930556",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7579",
                "runway_width": "131",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 148002,
            "s_seats": 26791,
            "j_seats": 33720,
            "f_seats": 2420,
            "r_seats": 8894,
            "cargo_demand": 198587
        }
    },
    {
        "iata": "CLZ",
        "icao": "SVCL",
        "airportName": "Calabozo Airport",
        "location_country": "Venezuela",
        "location_city": "Calabozo",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.924655914",
        "longitude": "-67.41709137",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4790",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 93669,
            "s_seats": 38111,
            "j_seats": 8941,
            "f_seats": 9860,
            "r_seats": 8117,
            "cargo_demand": 449574
        }
    },
    {
        "iata": "CMA",
        "icao": "YCMU",
        "airportName": "Cunnamulla Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Cunnamulla",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.03000069",
        "longitude": "145.621994",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2999",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5686",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129021,
            "s_seats": 39651,
            "j_seats": 54231,
            "f_seats": 17772,
            "r_seats": 5193,
            "cargo_demand": 477530
        }
    },
    {
        "iata": "CMB",
        "icao": "VCBI",
        "airportName": "Bandaranaike International Airport",
        "location_country": "Sri Lanka",
        "location_city": "Colombo",
        "location_state": "",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "7.180759907",
        "longitude": "79.88410187",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10991",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 386283,
            "s_seats": 77946,
            "j_seats": 94324,
            "f_seats": 38193,
            "r_seats": 10000,
            "cargo_demand": 1271050
        }
    },
    {
        "iata": "CMC",
        "icao": "SNWC",
        "airportName": "Camocim Airport",
        "location_country": "Brazil",
        "location_city": "Ceara",
        "location_state": "Camocim",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.896179914",
        "longitude": "-40.85800171",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3117",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1351,
            "s_seats": 2386,
            "j_seats": 1059,
            "f_seats": 219,
            "r_seats": 188,
            "cargo_demand": 61134
        }
    },
    {
        "iata": "CMD",
        "icao": "YCTM",
        "airportName": "Cootamundra Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Cootamundra",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-34.62390137",
        "longitude": "148.0279999",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2805",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4682",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118525,
            "s_seats": 43383,
            "j_seats": 40784,
            "f_seats": 7644,
            "r_seats": 5126,
            "cargo_demand": 258324
        }
    },
    {
        "iata": "CME",
        "icao": "MMCE",
        "airportName": "Ciudad del Carmen International Airport",
        "location_country": "Mexico",
        "location_city": "Campeche",
        "location_state": "Ciudad del Carmen",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "18.65369987",
        "longitude": "-91.7990036",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76911,
            "s_seats": 31167,
            "j_seats": 47055,
            "f_seats": 19093,
            "r_seats": 9180,
            "cargo_demand": 265507
        }
    },
    {
        "iata": "CMF",
        "icao": "LFLB",
        "airportName": "Chambery-Savoie Airport",
        "location_country": "France",
        "location_city": "Rhone-Alpes",
        "location_state": "Chambery",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.63809967",
        "longitude": "5.88022995",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6628",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87622,
            "s_seats": 47254,
            "j_seats": 13225,
            "f_seats": 16034,
            "r_seats": 7473,
            "cargo_demand": 268164
        }
    },
    {
        "iata": "CMG",
        "icao": "SBCR",
        "airportName": "Corumba International Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso do Sul",
        "location_state": "Corumba",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-19.01194382",
        "longitude": "-57.67139053",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "5446",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156184,
            "s_seats": 32342,
            "j_seats": 28690,
            "f_seats": 16600,
            "r_seats": 6373,
            "cargo_demand": 452608
        }
    },
    {
        "iata": "CMH",
        "icao": "KCMH",
        "airportName": "John Glenn Columbus International Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Columbus",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "39.998001",
        "longitude": "-82.891899",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "10125",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 394229,
            "s_seats": 85663,
            "j_seats": 141936,
            "f_seats": 40861,
            "r_seats": 10000,
            "cargo_demand": 23207250
        }
    },
    {
        "iata": "CMI",
        "icao": "KCMI",
        "airportName": "University of Illinois Willard Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Champaign",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.03919983",
        "longitude": "-88.27809906",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "8100",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "3817",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5299",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 81874,
            "s_seats": 33587,
            "j_seats": 50649,
            "f_seats": 1251,
            "r_seats": 4904,
            "cargo_demand": 149342
        }
    },
    {
        "iata": "CMK",
        "icao": "FWCM",
        "airportName": "Club Makokola Airport",
        "location_country": "Malawi",
        "location_city": "Club Makokola",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.30690002",
        "longitude": "35.13249969",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3800",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3549,
            "s_seats": 2977,
            "j_seats": 1815,
            "f_seats": 222,
            "r_seats": 1,
            "cargo_demand": 63650
        }
    },
    {
        "iata": "CML",
        "icao": "YCMW",
        "airportName": "Camooweal Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Camooweal",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-19.9116993",
        "longitude": "138.125",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3500",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 1092,
            "s_seats": 1949,
            "j_seats": 1094,
            "f_seats": 58,
            "r_seats": 155,
            "cargo_demand": 15405
        }
    },
    {
        "iata": "CMN",
        "icao": "GMMN",
        "airportName": "Mohammed V International Airport",
        "location_country": "Morocco",
        "location_city": "Casablanca",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct1",
        "airport_size": "large_airport",
        "latitude": "33.36750031",
        "longitude": "-7.589970112",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "12205",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "12205",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 285570,
            "s_seats": 99188,
            "j_seats": 65655,
            "f_seats": 47575,
            "r_seats": 10000,
            "cargo_demand": 24348596
        }
    },
    {
        "iata": "CMP",
        "icao": "SNKE",
        "airportName": "Santana do Araguaia Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Santana do Araguaia",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.319970131",
        "longitude": "-50.32849884",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1825,
            "s_seats": 1510,
            "j_seats": 1009,
            "f_seats": 389,
            "r_seats": 178,
            "cargo_demand": 82488
        }
    },
    {
        "iata": "CMQ",
        "icao": "YCMT",
        "airportName": "Clermont Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Clermont",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.7730999",
        "longitude": "147.6210022",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3504",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4301",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 119561,
            "s_seats": 30029,
            "j_seats": 26414,
            "f_seats": 8334,
            "r_seats": 4710,
            "cargo_demand": 481659
        }
    },
    {
        "iata": "CMR",
        "icao": "LFGA",
        "airportName": "Colmar - Houssen Airport",
        "location_country": "France",
        "location_city": "Alsace",
        "location_state": "Colmar",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.10990143",
        "longitude": "7.35901022",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5282",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 84620,
            "s_seats": 39059,
            "j_seats": 5824,
            "f_seats": 19711,
            "r_seats": 3365,
            "cargo_demand": 250469
        }
    },
    {
        "iata": "CMU",
        "icao": "AYCH",
        "airportName": "Chimbu Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Kundiawa",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.024290085",
        "longitude": "144.970993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3330",
                "runway_width": "89",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136857,
            "s_seats": 37654,
            "j_seats": 7733,
            "f_seats": 19407,
            "r_seats": 7334,
            "cargo_demand": 244262
        }
    },
    {
        "iata": "CMV",
        "icao": "NZCX",
        "airportName": "Coromandel Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Coromandel",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "small_airport",
        "latitude": "-36.79169846",
        "longitude": "175.5090027",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2044",
                "runway_width": "39",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2253,
            "s_seats": 2829,
            "j_seats": 1986,
            "f_seats": 301,
            "r_seats": 133,
            "cargo_demand": 36877
        }
    },
    {
        "iata": "CMW",
        "icao": "MUCM",
        "airportName": "Ignacio Agramonte International Airport",
        "location_country": "Cuba",
        "location_city": "Camaguey",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "21.42029953",
        "longitude": "-77.84750366",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130942,
            "s_seats": 32901,
            "j_seats": 41288,
            "f_seats": 7001,
            "r_seats": 8717,
            "cargo_demand": 189625
        }
    },
    {
        "iata": "CMX",
        "icao": "KCMX",
        "airportName": "Houghton County Memorial Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Hancock",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.16839981",
        "longitude": "-88.4890976",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5196",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6501",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138249,
            "s_seats": 35259,
            "j_seats": 35926,
            "f_seats": 9651,
            "r_seats": 4491,
            "cargo_demand": 324099
        }
    },
    {
        "iata": "CMY",
        "icao": "KCMY",
        "airportName": "Sparta/Fort McCoy Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Sparta",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.958302",
        "longitude": "-90.7379",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4295",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4708",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1016,
            "s_seats": 2270,
            "j_seats": 1314,
            "f_seats": 204,
            "r_seats": 161,
            "cargo_demand": 45565
        }
    },
    {
        "iata": "CNA",
        "icao": "MMCA",
        "airportName": "Cananea National Airport",
        "location_country": "Mexico",
        "location_city": "Sonora",
        "location_state": "Cananea",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.06615",
        "longitude": "-110.097878",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5520",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2654,
            "s_seats": 2137,
            "j_seats": 1851,
            "f_seats": 444,
            "r_seats": 4,
            "cargo_demand": 16297
        }
    },
    {
        "iata": "CNB",
        "icao": "YCNM",
        "airportName": "Coonamble Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Coonamble",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-30.98329926",
        "longitude": "148.3760071",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5010",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "1903",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 93358,
            "s_seats": 40664,
            "j_seats": 14973,
            "f_seats": 1955,
            "r_seats": 3084,
            "cargo_demand": 47188
        }
    },
    {
        "iata": "CNC",
        "icao": "YCCT",
        "airportName": "Coconut Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Coconut (Poruma) Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.05000019",
        "longitude": "143.0700073",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2395",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3685,
            "s_seats": 2502,
            "j_seats": 1961,
            "f_seats": 0,
            "r_seats": 114,
            "cargo_demand": 94860
        }
    },
    {
        "iata": "CND",
        "icao": "LRCK",
        "airportName": "Mihail Kog\u0103lniceanu International Airport",
        "location_country": "Romania",
        "location_city": "Constanta",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "44.36220169",
        "longitude": "28.48830032",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 80097,
            "s_seats": 30364,
            "j_seats": 7481,
            "f_seats": 3756,
            "r_seats": 6516,
            "cargo_demand": 450499
        }
    },
    {
        "iata": "CNF",
        "icao": "SBCF",
        "airportName": "Tancredo Neves International Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Belo Horizonte",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "large_airport",
        "latitude": "-19.62444305",
        "longitude": "-43.9719429",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 297952,
            "s_seats": 93814,
            "j_seats": 133060,
            "f_seats": 64987,
            "r_seats": 10000,
            "cargo_demand": 20334164
        }
    },
    {
        "iata": "CNG",
        "icao": "LFBG",
        "airportName": "Cognac - Ch\u00e2teaubernard Air Base",
        "location_country": "France",
        "location_city": "Poitou-Charentes",
        "location_state": "Cognac",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.65829849",
        "longitude": "-0.317499995",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7949",
                "runway_width": "148",
                "runway_material": "PEM"
            },
            {
                "runway_name": "09/27",
                "runway_length": "6152",
                "runway_width": "197",
                "runway_material": "MAC"
            }
        ],
        "demand": {
            "y_seats": 130561,
            "s_seats": 38468,
            "j_seats": 28713,
            "f_seats": 11450,
            "r_seats": 7683,
            "cargo_demand": 86980
        }
    },
    {
        "iata": "CNH",
        "icao": "KCNH",
        "airportName": "Claremont Municipal Airport",
        "location_country": "United States",
        "location_city": "New Hampshire",
        "location_state": "Claremont",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.37039948",
        "longitude": "-72.36869812",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2010",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3100",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2307,
            "s_seats": 2465,
            "j_seats": 1469,
            "f_seats": 152,
            "r_seats": 146,
            "cargo_demand": 46331
        }
    },
    {
        "iata": "CNJ",
        "icao": "YCCY",
        "airportName": "Cloncurry Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Cloncurry",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.66860008",
        "longitude": "140.5039978",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3796",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110150,
            "s_seats": 44809,
            "j_seats": 21796,
            "f_seats": 17604,
            "r_seats": 5947,
            "cargo_demand": 435685
        }
    },
    {
        "iata": "CNK",
        "icao": "KCNK",
        "airportName": "Blosser Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Concordia",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.54930115",
        "longitude": "-97.65229797",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1665",
                "runway_width": "260",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2205",
                "runway_width": "300",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3600",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3428,
            "s_seats": 1925,
            "j_seats": 1219,
            "f_seats": 370,
            "r_seats": 109,
            "cargo_demand": 33854
        }
    },
    {
        "iata": "CNL",
        "icao": "EKSN",
        "airportName": "Sindal Airport",
        "location_country": "Denmark",
        "location_city": "Sindal",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "57.503502",
        "longitude": "10.2294",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3934",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67849,
            "s_seats": 44109,
            "j_seats": 47863,
            "f_seats": 1186,
            "r_seats": 4480,
            "cargo_demand": 282752
        }
    },
    {
        "iata": "CNM",
        "icao": "KCNM",
        "airportName": "Cavern City Air Terminal",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Carlsbad",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.33750153",
        "longitude": "-104.2630005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7854",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "5333",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "4615",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "5839",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88340,
            "s_seats": 41278,
            "j_seats": 15506,
            "f_seats": 6858,
            "r_seats": 5044,
            "cargo_demand": 92076
        }
    },
    {
        "iata": "CNO",
        "icao": "KCNO",
        "airportName": "Chino Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Chino",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.97470093",
        "longitude": "-117.637001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6023",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08L/26R",
                "runway_length": "4858",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2187,
            "s_seats": 2417,
            "j_seats": 1152,
            "f_seats": 55,
            "r_seats": 48,
            "cargo_demand": 14404
        }
    },
    {
        "iata": "CNP",
        "icao": "BGCO",
        "airportName": "Nerlerit Inaat Airport",
        "location_country": "Greenland",
        "location_city": "Ittoqqortoormiit",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "70.74310303",
        "longitude": "-22.65049934",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3281",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 2782,
            "s_seats": 2419,
            "j_seats": 1470,
            "f_seats": 43,
            "r_seats": 34,
            "cargo_demand": 51220
        }
    },
    {
        "iata": "CNQ",
        "icao": "SARC",
        "airportName": "Doctor Fernando Piragine Niveyro International Airport",
        "location_country": "Argentina",
        "location_city": "Corrientes",
        "location_state": "Corrientes",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.4455",
        "longitude": "-58.7619",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 74461,
            "s_seats": 48675,
            "j_seats": 10214,
            "f_seats": 8683,
            "r_seats": 8383,
            "cargo_demand": 410328
        }
    },
    {
        "iata": "CNR",
        "icao": "SCRA",
        "airportName": "Chanaral Airport",
        "location_country": "Chile",
        "location_city": "Chanaral",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-26.33250046",
        "longitude": "-70.6072998",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3985",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143244,
            "s_seats": 34546,
            "j_seats": 30765,
            "f_seats": 11720,
            "r_seats": 4265,
            "cargo_demand": 418428
        }
    },
    {
        "iata": "CNS",
        "icao": "YBCS",
        "airportName": "Cairns Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Cairns",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.88579941",
        "longitude": "145.7550049",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3035",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "10489",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73303,
            "s_seats": 32625,
            "j_seats": 29636,
            "f_seats": 11054,
            "r_seats": 3743,
            "cargo_demand": 280349
        }
    },
    {
        "iata": "CNU",
        "icao": "KCNU",
        "airportName": "Chanute Martin Johnson Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Chanute",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.66880035",
        "longitude": "-95.48509979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2200",
                "runway_width": "135",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4255",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170040,
            "s_seats": 33757,
            "j_seats": 27638,
            "f_seats": 16782,
            "r_seats": 7362,
            "cargo_demand": 145224
        }
    },
    {
        "iata": "CNV",
        "icao": "SNED",
        "airportName": "Canavieiras Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Canavieiras",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.66699982",
        "longitude": "-38.95470047",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4396",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2705,
            "s_seats": 2715,
            "j_seats": 1259,
            "f_seats": 15,
            "r_seats": 124,
            "cargo_demand": 70205
        }
    },
    {
        "iata": "CNW",
        "icao": "KCNW",
        "airportName": "TSTC Waco Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Waco",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.63780022",
        "longitude": "-97.07409668",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "8600",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "6292",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3080,
            "s_seats": 2400,
            "j_seats": 1893,
            "f_seats": 496,
            "r_seats": 76,
            "cargo_demand": 60891
        }
    },
    {
        "iata": "CNX",
        "icao": "VTCC",
        "airportName": "Chiang Mai International Airport",
        "location_country": "Thailand",
        "location_city": "Chiang Mai",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "18.76679993",
        "longitude": "98.96260071",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106871,
            "s_seats": 73239,
            "j_seats": 50796,
            "f_seats": 27414,
            "r_seats": 10000,
            "cargo_demand": 22243048
        }
    },
    {
        "iata": "CNY",
        "icao": "KCNY",
        "airportName": "Canyonlands Field",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Moab",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.75500107",
        "longitude": "-109.7549973",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7100",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3474,
            "s_seats": 2707,
            "j_seats": 1425,
            "f_seats": 461,
            "r_seats": 41,
            "cargo_demand": 34115
        }
    },
    {
        "iata": "COC",
        "icao": "SAAC",
        "airportName": "Comodoro Pierrestegui Airport",
        "location_country": "Argentina",
        "location_city": "Entre Rios",
        "location_state": "Concordia",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.2969",
        "longitude": "-57.9966",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "5249",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 65802,
            "s_seats": 48197,
            "j_seats": 27944,
            "f_seats": 14246,
            "r_seats": 3168,
            "cargo_demand": 110803
        }
    },
    {
        "iata": "COD",
        "icao": "KCOD",
        "airportName": "Yellowstone Regional Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Cody",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.52019882",
        "longitude": "-109.0240021",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8268",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68774,
            "s_seats": 31053,
            "j_seats": 8671,
            "f_seats": 17782,
            "r_seats": 6046,
            "cargo_demand": 311804
        }
    },
    {
        "iata": "COE",
        "icao": "KCOE",
        "airportName": "Coeur d'Alene Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Coeur d'Alene",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.77429962",
        "longitude": "-116.8199997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5400",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "7400",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102185,
            "s_seats": 45357,
            "j_seats": 17982,
            "f_seats": 15391,
            "r_seats": 6951,
            "cargo_demand": 326490
        }
    },
    {
        "iata": "COF",
        "icao": "KCOF",
        "airportName": "Patrick Space Force Base",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Cocoa Beach",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "28.23489952",
        "longitude": "-80.61009979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9023",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177213,
            "s_seats": 39571,
            "j_seats": 24250,
            "f_seats": 10222,
            "r_seats": 8653,
            "cargo_demand": 481981
        }
    },
    {
        "iata": "COH",
        "icao": "VECO",
        "airportName": "Cooch Behar Airport",
        "location_country": "India",
        "location_city": "West Bengal",
        "location_state": "Cooch Behar",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "26.33049965",
        "longitude": "89.46720123",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2979,
            "s_seats": 2822,
            "j_seats": 1736,
            "f_seats": 359,
            "r_seats": 5,
            "cargo_demand": 23161
        }
    },
    {
        "iata": "COI",
        "icao": "KCOI",
        "airportName": "Merritt Island Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Merritt Island",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "28.34160042",
        "longitude": "-80.6855011",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3601",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3534,
            "s_seats": 1992,
            "j_seats": 1592,
            "f_seats": 417,
            "r_seats": 110,
            "cargo_demand": 61074
        }
    },
    {
        "iata": "COJ",
        "icao": "YCBB",
        "airportName": "Coonabarabran Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Coonabarabran",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-31.33250046",
        "longitude": "149.2669983",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2129",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4987",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111902,
            "s_seats": 33426,
            "j_seats": 11022,
            "f_seats": 13688,
            "r_seats": 6886,
            "cargo_demand": 332407
        }
    },
    {
        "iata": "COK",
        "icao": "VOCI",
        "airportName": "Cochin International Airport",
        "location_country": "India",
        "location_city": "Kerala",
        "location_state": "Kochi (Cochin)",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "10.152",
        "longitude": "76.401901",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 424367,
            "s_seats": 96827,
            "j_seats": 135069,
            "f_seats": 51065,
            "r_seats": 10000,
            "cargo_demand": 7127547
        }
    },
    {
        "iata": "COM",
        "icao": "KCOM",
        "airportName": "Coleman Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Coleman",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.84110069",
        "longitude": "-99.4036026",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4503",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3585,
            "s_seats": 2762,
            "j_seats": 1335,
            "f_seats": 268,
            "r_seats": 179,
            "cargo_demand": 81520
        }
    },
    {
        "iata": "CON",
        "icao": "KCON",
        "airportName": "Concord Municipal Airport",
        "location_country": "United States",
        "location_city": "New Hampshire",
        "location_state": "Concord",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.20270157",
        "longitude": "-71.50229645",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3999",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3200",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6005",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157593,
            "s_seats": 32861,
            "j_seats": 47150,
            "f_seats": 9626,
            "r_seats": 4061,
            "cargo_demand": 147654
        }
    },
    {
        "iata": "COO",
        "icao": "DBBB",
        "airportName": "Cadjehoun Airport",
        "location_country": "Benin",
        "location_city": "Cotonou",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.357230186",
        "longitude": "2.384350061",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53092,
            "s_seats": 30642,
            "j_seats": 6917,
            "f_seats": 9824,
            "r_seats": 7825,
            "cargo_demand": 423449
        }
    },
    {
        "iata": "COQ",
        "icao": "ZMCD",
        "airportName": "Choibalsan Airport",
        "location_country": "Mongolia",
        "location_city": "Choibalsan",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.13570023",
        "longitude": "114.6460037",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8530",
                "runway_width": "131",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 61543,
            "s_seats": 25182,
            "j_seats": 40911,
            "f_seats": 14954,
            "r_seats": 6438,
            "cargo_demand": 307767
        }
    },
    {
        "iata": "COR",
        "icao": "SACO",
        "airportName": "Ingeniero Aeronautico Ambrosio L.V. Taravella International Airport",
        "location_country": "Argentina",
        "location_city": "Cordoba",
        "location_state": "Cordoba",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.323601",
        "longitude": "-64.208",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "05/23",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109879,
            "s_seats": 30521,
            "j_seats": 11511,
            "f_seats": 10350,
            "r_seats": 7417,
            "cargo_demand": 457802
        }
    },
    {
        "iata": "COS",
        "icao": "KCOS",
        "airportName": "Colorado Springs Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Colorado Springs",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "38.805801",
        "longitude": "-104.700996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8269",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "13501",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "11022",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128197,
            "s_seats": 90592,
            "j_seats": 37795,
            "f_seats": 26576,
            "r_seats": 10000,
            "cargo_demand": 24908903
        }
    },
    {
        "iata": "COT",
        "icao": "KCOT",
        "airportName": "Cotulla-La Salle County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Cotulla",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "28.45669937",
        "longitude": "-99.22029877",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5005",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3923,
            "s_seats": 1504,
            "j_seats": 1644,
            "f_seats": 35,
            "r_seats": 37,
            "cargo_demand": 7165
        }
    },
    {
        "iata": "COU",
        "icao": "KCOU",
        "airportName": "Columbia Regional Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Columbia",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.8181",
        "longitude": "-92.219597",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6501",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 150105,
            "s_seats": 45649,
            "j_seats": 25479,
            "f_seats": 10704,
            "r_seats": 9553,
            "cargo_demand": 315551
        }
    },
    {
        "iata": "COY",
        "icao": "YCWY",
        "airportName": "Coolawanyah Station Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Coolawanyah Station",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-21.7946",
        "longitude": "117.755",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1088,
            "s_seats": 2009,
            "j_seats": 1287,
            "f_seats": 384,
            "r_seats": 118,
            "cargo_demand": 89367
        }
    },
    {
        "iata": "COZ",
        "icao": "MDCZ",
        "airportName": "Constanza Airport",
        "location_country": "Dominican Republic",
        "location_city": "Constanza",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.907499",
        "longitude": "-70.721901",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6234",
                "runway_width": "75",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2624,
            "s_seats": 1602,
            "j_seats": 1034,
            "f_seats": 34,
            "r_seats": 168,
            "cargo_demand": 93258
        }
    },
    {
        "iata": "CPC",
        "icao": "SAZY",
        "airportName": "Aviador Carlos Campos Airport",
        "location_country": "Argentina",
        "location_city": "Neuquen",
        "location_state": "San Martin de los Andes",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-40.075401",
        "longitude": "-71.137299",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73316,
            "s_seats": 37678,
            "j_seats": 10274,
            "f_seats": 4953,
            "r_seats": 7913,
            "cargo_demand": 208265
        }
    },
    {
        "iata": "CPD",
        "icao": "YCBP",
        "airportName": "Coober Pedy Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Coober Pedy",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-29.04000092",
        "longitude": "134.720993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4685",
                "runway_width": "98",
                "runway_material": "PER"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2720",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 77734,
            "s_seats": 48660,
            "j_seats": 11024,
            "f_seats": 14910,
            "r_seats": 5231,
            "cargo_demand": 421025
        }
    },
    {
        "iata": "CPE",
        "icao": "MMCP",
        "airportName": "Ing. Alberto Acuna Ongay International Airport",
        "location_country": "Mexico",
        "location_city": "Campeche",
        "location_state": "Campeche",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "19.81679916",
        "longitude": "-90.50029755",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105629,
            "s_seats": 25546,
            "j_seats": 47056,
            "f_seats": 3193,
            "r_seats": 4138,
            "cargo_demand": 194572
        }
    },
    {
        "iata": "CPF",
        "icao": "WARC",
        "airportName": "Ngloram Airport",
        "location_country": "Indonesia",
        "location_city": "Cepu",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.194841667",
        "longitude": "111.5481667",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2953",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3497,
            "s_seats": 2742,
            "j_seats": 1189,
            "f_seats": 137,
            "r_seats": 122,
            "cargo_demand": 32998
        }
    },
    {
        "iata": "CPH",
        "icao": "EKCH",
        "airportName": "Copenhagen Airport/c/ Kastrup",
        "location_country": "Denmark",
        "location_city": "Copenhagen",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "55.61790085",
        "longitude": "12.65600014",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "10072",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 147377,
            "s_seats": 74762,
            "j_seats": 159743,
            "f_seats": 56004,
            "r_seats": 10000,
            "cargo_demand": 13942832
        }
    },
    {
        "iata": "CPM",
        "icao": "KCPM",
        "airportName": "Compton/Woodley Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Compton",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.88999939",
        "longitude": "-118.2440033",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "3322",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "3322",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1723,
            "s_seats": 2267,
            "j_seats": 1258,
            "f_seats": 499,
            "r_seats": 51,
            "cargo_demand": 16085
        }
    },
    {
        "iata": "CPO",
        "icao": "SCAT",
        "airportName": "Desierto de Atacama Airport",
        "location_country": "Chile",
        "location_city": "Copiapo",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-27.26119995",
        "longitude": "-70.77919769",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165487,
            "s_seats": 39211,
            "j_seats": 19418,
            "f_seats": 2514,
            "r_seats": 6537,
            "cargo_demand": 372624
        }
    },
    {
        "iata": "CPP",
        "icao": "SCKP",
        "airportName": "Coposa Airport",
        "location_country": "Chile",
        "location_city": "Pica",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.7505",
        "longitude": "-68.683502",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3393",
                "runway_width": "66",
                "runway_material": "BIT"
            },
            {
                "runway_name": "17/35",
                "runway_length": "10499",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2875,
            "s_seats": 2413,
            "j_seats": 1297,
            "f_seats": 176,
            "r_seats": 10,
            "cargo_demand": 13389
        }
    },
    {
        "iata": "CPR",
        "icao": "KCPR",
        "airportName": "Casper-Natrona County International Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Casper",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.908001",
        "longitude": "-106.463997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10164",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "8679",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6484",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7696",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190009,
            "s_seats": 40521,
            "j_seats": 57292,
            "f_seats": 7150,
            "r_seats": 9718,
            "cargo_demand": 192297
        }
    },
    {
        "iata": "CPS",
        "icao": "KCPS",
        "airportName": "St. Louis Downtown Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Cahokia (near St. Louis/MO)",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.5707016",
        "longitude": "-90.15619659",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2799",
                "runway_width": "75",
                "runway_material": "ASPH-F"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "3800",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "6997",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3598,
            "s_seats": 2456,
            "j_seats": 1118,
            "f_seats": 416,
            "r_seats": 13,
            "cargo_demand": 50650
        }
    },
    {
        "iata": "CPT",
        "icao": "FACT",
        "airportName": "Cape Town International Airport",
        "location_country": "South Africa",
        "location_city": "Cape Town",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-33.96480179",
        "longitude": "18.60169983",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10502",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5581",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 302118,
            "s_seats": 96505,
            "j_seats": 84403,
            "f_seats": 56594,
            "r_seats": 10000,
            "cargo_demand": 6434256
        }
    },
    {
        "iata": "CPV",
        "icao": "SBKG",
        "airportName": "Campina Grande Airport",
        "location_country": "Brazil",
        "location_city": "Paraiba",
        "location_state": "Campina Grande",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.26992",
        "longitude": "-35.8964",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5249",
                "runway_width": "138",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184577,
            "s_seats": 47581,
            "j_seats": 5562,
            "f_seats": 14674,
            "r_seats": 4175,
            "cargo_demand": 151535
        }
    },
    {
        "iata": "CPX",
        "icao": "TJCP",
        "airportName": "Benjamin Rivera Noriega Airport",
        "location_country": "United States",
        "location_city": "Puerto Rico",
        "location_state": "Culebra",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.313289",
        "longitude": "-65.304324",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2600",
                "runway_width": "50",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 1204,
            "s_seats": 2648,
            "j_seats": 1785,
            "f_seats": 497,
            "r_seats": 153,
            "cargo_demand": 23901
        }
    },
    {
        "iata": "CQD",
        "icao": "OIFS",
        "airportName": "Shahrekord Airport",
        "location_country": "Iran",
        "location_city": "Shahrekord",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "32.29719925",
        "longitude": "50.84220123",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "10819",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89227,
            "s_seats": 37714,
            "j_seats": 31467,
            "f_seats": 17640,
            "r_seats": 7842,
            "cargo_demand": 105919
        }
    },
    {
        "iata": "CQF",
        "icao": "LFAC",
        "airportName": "Calais-Dunkerque Airport",
        "location_country": "France",
        "location_city": "Nord-Pas-de-Calais",
        "location_state": "Calais / Dunkirk",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.96210098",
        "longitude": "1.954759955",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5036",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90104,
            "s_seats": 45559,
            "j_seats": 31845,
            "f_seats": 5992,
            "r_seats": 7663,
            "cargo_demand": 351993
        }
    },
    {
        "iata": "CQM",
        "icao": "LERL",
        "airportName": "Ciudad Real Central Airport",
        "location_country": "Spain",
        "location_city": "Castilla-La Mancha",
        "location_state": "Ciudad Real",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "38.856479",
        "longitude": "-3.969944",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 116326,
            "s_seats": 30907,
            "j_seats": 58223,
            "f_seats": 19809,
            "r_seats": 7776,
            "cargo_demand": 153339
        }
    },
    {
        "iata": "CQS",
        "icao": "SWCQ",
        "airportName": "Costa Marques Airport",
        "location_country": "Brazil",
        "location_city": "Rondonia",
        "location_state": "Costa Marques",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.42109966",
        "longitude": "-64.25160217",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "4905",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1005,
            "s_seats": 2743,
            "j_seats": 1325,
            "f_seats": 379,
            "r_seats": 44,
            "cargo_demand": 7103
        }
    },
    {
        "iata": "CRA",
        "icao": "LRCV",
        "airportName": "Craiova International Airport",
        "location_country": "Romania",
        "location_city": "Craiova",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "44.3181",
        "longitude": "23.888599",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8203",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 189681,
            "s_seats": 48763,
            "j_seats": 9299,
            "f_seats": 14764,
            "r_seats": 7487,
            "cargo_demand": 334526
        }
    },
    {
        "iata": "CRB",
        "icao": "YCBR",
        "airportName": "Collarenebri Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Collarenebri",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-29.52169991",
        "longitude": "148.5820007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2145",
                "runway_width": "",
                "runway_material": "N"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3996",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2964,
            "s_seats": 1870,
            "j_seats": 1967,
            "f_seats": 225,
            "r_seats": 190,
            "cargo_demand": 56071
        }
    },
    {
        "iata": "CRC",
        "icao": "SKGO",
        "airportName": "Santa Ana Airport",
        "location_country": "Colombia",
        "location_city": "Cartago",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.75818",
        "longitude": "-75.9557",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7218",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190426,
            "s_seats": 35261,
            "j_seats": 31962,
            "f_seats": 5929,
            "r_seats": 4793,
            "cargo_demand": 159705
        }
    },
    {
        "iata": "CRD",
        "icao": "SAVC",
        "airportName": "General Enrique Mosconi International Airport",
        "location_country": "Argentina",
        "location_city": "Chubut",
        "location_state": "Comodoro Rivadavia",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-45.7853",
        "longitude": "-67.4655",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "9219",
                "runway_width": "164",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 114025,
            "s_seats": 40847,
            "j_seats": 52191,
            "f_seats": 4993,
            "r_seats": 3440,
            "cargo_demand": 119343
        }
    },
    {
        "iata": "CRE",
        "icao": "KCRE",
        "airportName": "Grand Strand Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "North Myrtle Beach",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.81169891",
        "longitude": "-78.72389984",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5996",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139144,
            "s_seats": 46801,
            "j_seats": 31741,
            "f_seats": 14240,
            "r_seats": 6786,
            "cargo_demand": 91758
        }
    },
    {
        "iata": "CRG",
        "icao": "KCRG",
        "airportName": "Jacksonville Executive at Craig Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Jacksonville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.3362999",
        "longitude": "-81.51439667",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4004",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3998",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154972,
            "s_seats": 47686,
            "j_seats": 19316,
            "f_seats": 17596,
            "r_seats": 5233,
            "cargo_demand": 131242
        }
    },
    {
        "iata": "CRI",
        "icao": "MYCI",
        "airportName": "Colonel Hill Airport",
        "location_country": "Bahamas",
        "location_city": "Crooked Island",
        "location_state": "Colonel Hill",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "22.7456",
        "longitude": "-74.182404",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4061",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90741,
            "s_seats": 42771,
            "j_seats": 19464,
            "f_seats": 10894,
            "r_seats": 8739,
            "cargo_demand": 317942
        }
    },
    {
        "iata": "CRK",
        "icao": "RPLC",
        "airportName": "Clark International Airport",
        "location_country": "Philippines",
        "location_city": "Clark Special Economic Zone",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "15.186",
        "longitude": "120.559998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02L/20R",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "10499",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 463246,
            "s_seats": 75246,
            "j_seats": 75513,
            "f_seats": 37786,
            "r_seats": 10000,
            "cargo_demand": 18645915
        }
    },
    {
        "iata": "CRL",
        "icao": "EBCI",
        "airportName": "Brussels South Charleroi Airport",
        "location_country": "Belgium",
        "location_city": "Charleroi",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.459202",
        "longitude": "4.45382",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8366",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131814,
            "s_seats": 40336,
            "j_seats": 13830,
            "f_seats": 9415,
            "r_seats": 8718,
            "cargo_demand": 404270
        }
    },
    {
        "iata": "CRM",
        "icao": "RPVF",
        "airportName": "Catarman National Airport",
        "location_country": "Philippines",
        "location_city": "Catarman",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.5024004",
        "longitude": "124.6360016",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4429",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 56250,
            "s_seats": 33770,
            "j_seats": 9582,
            "f_seats": 16294,
            "r_seats": 4810,
            "cargo_demand": 412461
        }
    },
    {
        "iata": "CRO",
        "icao": "KCRO",
        "airportName": "Corcoran Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Corcoran",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.102502",
        "longitude": "-119.595001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3800",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1014,
            "s_seats": 2540,
            "j_seats": 1616,
            "f_seats": 239,
            "r_seats": 167,
            "cargo_demand": 53440
        }
    },
    {
        "iata": "CRP",
        "icao": "KCRP",
        "airportName": "Corpus Christi International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Corpus Christi",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "27.77039909",
        "longitude": "-97.50119781",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7508",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6080",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 231181,
            "s_seats": 96564,
            "j_seats": 84888,
            "f_seats": 60499,
            "r_seats": 10000,
            "cargo_demand": 11086005
        }
    },
    {
        "iata": "CRQ",
        "icao": "SBCV",
        "airportName": "Caravelas Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Caravelas",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.6523",
        "longitude": "-39.253101",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "5020",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128788,
            "s_seats": 41255,
            "j_seats": 41861,
            "f_seats": 15529,
            "r_seats": 5741,
            "cargo_demand": 441450
        }
    },
    {
        "iata": "CRR",
        "icao": "SANW",
        "airportName": "Ceres Airport",
        "location_country": "Argentina",
        "location_city": "Santa Fe",
        "location_state": "Ceres",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-29.872292",
        "longitude": "-61.927925",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "4429",
                "runway_width": "148",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1595,
            "s_seats": 2439,
            "j_seats": 1964,
            "f_seats": 267,
            "r_seats": 143,
            "cargo_demand": 8777
        }
    },
    {
        "iata": "CRS",
        "icao": "KCRS",
        "airportName": "C. David Campbell Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Corsicana",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.02809906",
        "longitude": "-96.40059662",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3200",
                "runway_width": "75",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4999",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3527,
            "s_seats": 2260,
            "j_seats": 1280,
            "f_seats": 146,
            "r_seats": 197,
            "cargo_demand": 48533
        }
    },
    {
        "iata": "CRT",
        "icao": "KCRT",
        "airportName": "Z. M. Jack Stell Field",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Crossett",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.17829895",
        "longitude": "-91.88020325",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5009",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1748,
            "s_seats": 1509,
            "j_seats": 1932,
            "f_seats": 7,
            "r_seats": 132,
            "cargo_demand": 30407
        }
    },
    {
        "iata": "CRU",
        "icao": "TGPZ",
        "airportName": "Lauriston Airport",
        "location_country": "Grenada",
        "location_city": "Carriacou",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "12.4761",
        "longitude": "-61.472801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2625",
                "runway_width": "",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 3049,
            "s_seats": 2859,
            "j_seats": 1547,
            "f_seats": 324,
            "r_seats": 67,
            "cargo_demand": 21594
        }
    },
    {
        "iata": "CRV",
        "icao": "LIBC",
        "airportName": "Crotone Airport",
        "location_country": "Italy",
        "location_city": "Calabria",
        "location_state": "Crotone",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "38.9972",
        "longitude": "17.0802",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122641,
            "s_seats": 46735,
            "j_seats": 10271,
            "f_seats": 17378,
            "r_seats": 6718,
            "cargo_demand": 70872
        }
    },
    {
        "iata": "CRW",
        "icao": "KCRW",
        "airportName": "Yeager Airport",
        "location_country": "United States",
        "location_city": "West Virginia",
        "location_state": "Charleston",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "38.37310028",
        "longitude": "-81.59320068",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6302",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4751",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 304466,
            "s_seats": 96725,
            "j_seats": 67988,
            "f_seats": 28034,
            "r_seats": 10000,
            "cargo_demand": 18954979
        }
    },
    {
        "iata": "CRX",
        "icao": "KCRX",
        "airportName": "Roscoe Turner Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Corinth",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.91500092",
        "longitude": "-88.60350037",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6500",
                "runway_width": "100",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 1650,
            "s_seats": 2624,
            "j_seats": 1143,
            "f_seats": 346,
            "r_seats": 44,
            "cargo_demand": 97354
        }
    },
    {
        "iata": "CRZ",
        "icao": "UTAV",
        "airportName": "Turkmenabat Airport",
        "location_country": "Turkmenistan",
        "location_city": "Turkmenabat",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.930662",
        "longitude": "63.563982",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "CONC"
            }
        ],
        "demand": {
            "y_seats": 120934,
            "s_seats": 28216,
            "j_seats": 33026,
            "f_seats": 13985,
            "r_seats": 6249,
            "cargo_demand": 391501
        }
    },
    {
        "iata": "CSB",
        "icao": "LRCS",
        "airportName": "Caransebe\u0219 Airport",
        "location_country": "Romania",
        "location_city": "Caransebe\u0219",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.41999817",
        "longitude": "22.25329971",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 72948,
            "s_seats": 46861,
            "j_seats": 9571,
            "f_seats": 13242,
            "r_seats": 5728,
            "cargo_demand": 287346
        }
    },
    {
        "iata": "CSF",
        "icao": "LFPC",
        "airportName": "Creil Air Base",
        "location_country": "France",
        "location_city": "Picardy",
        "location_state": "Creil",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.25350189",
        "longitude": "2.519140005",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7871",
                "runway_width": "164",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 141698,
            "s_seats": 43274,
            "j_seats": 16684,
            "f_seats": 4600,
            "r_seats": 3483,
            "cargo_demand": 89176
        }
    },
    {
        "iata": "CSG",
        "icao": "KCSG",
        "airportName": "Columbus Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Columbus",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.5163002",
        "longitude": "-84.93890381",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6997",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3997",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118585,
            "s_seats": 29929,
            "j_seats": 53854,
            "f_seats": 19914,
            "r_seats": 3827,
            "cargo_demand": 312546
        }
    },
    {
        "iata": "CSI",
        "icao": "YCAS",
        "airportName": "Casino Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Casino",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-28.88279915",
        "longitude": "153.0670013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3759,
            "s_seats": 1830,
            "j_seats": 1280,
            "f_seats": 32,
            "r_seats": 176,
            "cargo_demand": 78114
        }
    },
    {
        "iata": "CSK",
        "icao": "GOGS",
        "airportName": "Cap Skirring Airport",
        "location_country": "Senegal",
        "location_city": "Cap Skirring",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.39533",
        "longitude": "-16.748",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6573",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150873,
            "s_seats": 40648,
            "j_seats": 17973,
            "f_seats": 12674,
            "r_seats": 7713,
            "cargo_demand": 169268
        }
    },
    {
        "iata": "CSM",
        "icao": "KCSM",
        "airportName": "Clinton-Sherman Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Clinton",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.33980179",
        "longitude": "-99.20050049",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "5193",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "13503",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1817,
            "s_seats": 2166,
            "j_seats": 1508,
            "f_seats": 448,
            "r_seats": 54,
            "cargo_demand": 49665
        }
    },
    {
        "iata": "CSN",
        "icao": "KCXP",
        "airportName": "Carson Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Carson City",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.19219971",
        "longitude": "-119.7340012",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5906",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1830,
            "s_seats": 2830,
            "j_seats": 1957,
            "f_seats": 384,
            "r_seats": 197,
            "cargo_demand": 61829
        }
    },
    {
        "iata": "CSQ",
        "icao": "KCSQ",
        "airportName": "Creston Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Creston",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.02140045",
        "longitude": "-94.36329651",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "1692",
                "runway_width": "100",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4901",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1841,
            "s_seats": 2527,
            "j_seats": 1278,
            "f_seats": 418,
            "r_seats": 128,
            "cargo_demand": 38546
        }
    },
    {
        "iata": "CSS",
        "icao": "SSCL",
        "airportName": "Cassil\u00e2ndia Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso do Sul",
        "location_state": "Cassil\u00e2ndia",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-19.146861",
        "longitude": "-51.676941",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2953",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1225,
            "s_seats": 1757,
            "j_seats": 1989,
            "f_seats": 116,
            "r_seats": 22,
            "cargo_demand": 58023
        }
    },
    {
        "iata": "CSV",
        "icao": "KCSV",
        "airportName": "Crossville Memorial-Whitson Field",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Crossville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.95130157",
        "longitude": "-85.08499908",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5418",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185447,
            "s_seats": 46816,
            "j_seats": 24701,
            "f_seats": 6137,
            "r_seats": 4769,
            "cargo_demand": 376860
        }
    },
    {
        "iata": "CSX",
        "icao": "ZGHA",
        "airportName": "Changsha Huanghua International Airport",
        "location_country": "China",
        "location_city": "Hunan",
        "location_state": "Changsha",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "28.18919945",
        "longitude": "113.2200012",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106320,
            "s_seats": 81755,
            "j_seats": 90146,
            "f_seats": 58922,
            "r_seats": 10000,
            "cargo_demand": 24406891
        }
    },
    {
        "iata": "CSY",
        "icao": "UWKS",
        "airportName": "Cheboksary Airport",
        "location_country": "Russia",
        "location_city": "Chuvashia",
        "location_state": "Cheboksary",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.09030151",
        "longitude": "47.34730148",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8241",
                "runway_width": "161",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "4950",
                "runway_width": "270",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 132816,
            "s_seats": 27787,
            "j_seats": 59056,
            "f_seats": 17284,
            "r_seats": 6914,
            "cargo_demand": 181163
        }
    },
    {
        "iata": "CSZ",
        "icao": "SAZC",
        "airportName": "Brigadier Hector Eduardo Ruiz Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "Coronel Suarez",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-37.446098",
        "longitude": "-61.889301",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3415,
            "s_seats": 2791,
            "j_seats": 1620,
            "f_seats": 472,
            "r_seats": 36,
            "cargo_demand": 40165
        }
    },
    {
        "iata": "CTA",
        "icao": "LICC",
        "airportName": "Catania-Fontanarossa Airport",
        "location_country": "Italy",
        "location_city": "Sicily",
        "location_state": "Catania",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "37.466801",
        "longitude": "15.0664",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7989",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120859,
            "s_seats": 78442,
            "j_seats": 107820,
            "f_seats": 47857,
            "r_seats": 10000,
            "cargo_demand": 2531792
        }
    },
    {
        "iata": "CTB",
        "icao": "KCTB",
        "airportName": "Cut Bank Municipal Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Cut Bank",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "48.60839844",
        "longitude": "-112.3759995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5299",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5300",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139749,
            "s_seats": 30437,
            "j_seats": 7351,
            "f_seats": 10903,
            "r_seats": 8304,
            "cargo_demand": 114869
        }
    },
    {
        "iata": "CTC",
        "icao": "SANC",
        "airportName": "Coronel Felipe Varela International Airport",
        "location_country": "Argentina",
        "location_city": "Catamarca",
        "location_state": "Catamarca",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.59560013",
        "longitude": "-65.75170136",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "9186",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82395,
            "s_seats": 37584,
            "j_seats": 13479,
            "f_seats": 9260,
            "r_seats": 3402,
            "cargo_demand": 376324
        }
    },
    {
        "iata": "CTD",
        "icao": "MPCE",
        "airportName": "Chitre Alonso Valderrama Airport",
        "location_country": "Panama",
        "location_city": "Chitre",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.98784",
        "longitude": "-80.409837",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4921",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144860,
            "s_seats": 42398,
            "j_seats": 34564,
            "f_seats": 13845,
            "r_seats": 4412,
            "cargo_demand": 383629
        }
    },
    {
        "iata": "CTG",
        "icao": "SKCG",
        "airportName": "Rafael Nunez International Airport",
        "location_country": "Colombia",
        "location_city": "Cartagena",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.4424",
        "longitude": "-75.513",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128544,
            "s_seats": 25557,
            "j_seats": 40282,
            "f_seats": 3024,
            "r_seats": 4283,
            "cargo_demand": 104066
        }
    },
    {
        "iata": "CTH",
        "icao": "KMQS",
        "airportName": "Chester County G. O. Carlson Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Coatesville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.97900009",
        "longitude": "-75.8655014",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5400",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2126,
            "s_seats": 1747,
            "j_seats": 1000,
            "f_seats": 198,
            "r_seats": 13,
            "cargo_demand": 97575
        }
    },
    {
        "iata": "CTL",
        "icao": "YBCV",
        "airportName": "Charleville Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Charleville",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.41329956",
        "longitude": "146.2619934",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5000",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3501",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163681,
            "s_seats": 25593,
            "j_seats": 28544,
            "f_seats": 4304,
            "r_seats": 7794,
            "cargo_demand": 406523
        }
    },
    {
        "iata": "CTM",
        "icao": "MMCM",
        "airportName": "Chetumal International Airport",
        "location_country": "Mexico",
        "location_city": "Quintana Roo",
        "location_state": "Chetumal",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.50469971",
        "longitude": "-88.32679749",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7244",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124884,
            "s_seats": 27476,
            "j_seats": 7171,
            "f_seats": 2022,
            "r_seats": 9673,
            "cargo_demand": 380149
        }
    },
    {
        "iata": "CTN",
        "icao": "YCKN",
        "airportName": "Cooktown Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Cooktown",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.44470024",
        "longitude": "145.1840057",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5338",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162754,
            "s_seats": 42416,
            "j_seats": 43883,
            "f_seats": 3805,
            "r_seats": 6648,
            "cargo_demand": 101815
        }
    },
    {
        "iata": "CTP",
        "icao": "SNCP",
        "airportName": "Carutapera Airport",
        "location_country": "Brazil",
        "location_city": "Maranhio",
        "location_state": "Carutapera",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.225365",
        "longitude": "-46.019057",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "3166",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1816,
            "s_seats": 1573,
            "j_seats": 1150,
            "f_seats": 80,
            "r_seats": 56,
            "cargo_demand": 13455
        }
    },
    {
        "iata": "CTQ",
        "icao": "SSVP",
        "airportName": "Do Palmar Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Santa Vitoria",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-33.50222397",
        "longitude": "-53.3441658",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2953",
                "runway_width": "197",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1045,
            "s_seats": 2387,
            "j_seats": 1999,
            "f_seats": 175,
            "r_seats": 5,
            "cargo_demand": 71867
        }
    },
    {
        "iata": "CTS",
        "icao": "RJCC",
        "airportName": "New Chitose Airport",
        "location_country": "Japan",
        "location_city": "Hokkaido",
        "location_state": "Sapporo",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "42.7752",
        "longitude": "141.692001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "9840",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "9840",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 482773,
            "s_seats": 86254,
            "j_seats": 65236,
            "f_seats": 40563,
            "r_seats": 10000,
            "cargo_demand": 9986235
        }
    },
    {
        "iata": "CTT",
        "icao": "LFMQ",
        "airportName": "Le Castellet Airport",
        "location_country": "France",
        "location_city": "Provence-Alpes-Cote d'Azur",
        "location_state": "Le Castellet",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.25249863",
        "longitude": "5.785190105",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5741",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140313,
            "s_seats": 31892,
            "j_seats": 45495,
            "f_seats": 16999,
            "r_seats": 6136,
            "cargo_demand": 76785
        }
    },
    {
        "iata": "CTU",
        "icao": "ZUUU",
        "airportName": "Chengdu Shuangliu International Airport",
        "location_country": "China",
        "location_city": "Sichuan",
        "location_state": "Chengdu",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "30.57850075",
        "longitude": "103.9469986",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02L/20R",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "11811",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 392463,
            "s_seats": 99689,
            "j_seats": 69598,
            "f_seats": 31005,
            "r_seats": 10000,
            "cargo_demand": 3550551
        }
    },
    {
        "iata": "CTY",
        "icao": "KCTY",
        "airportName": "Cross City Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Cross City",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "29.63549995",
        "longitude": "-83.10479736",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5005",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2314,
            "s_seats": 2496,
            "j_seats": 1593,
            "f_seats": 335,
            "r_seats": 83,
            "cargo_demand": 80593
        }
    },
    {
        "iata": "CTZ",
        "icao": "KCTZ",
        "airportName": "Clinton-Sampson County Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Clinton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.9756012",
        "longitude": "-78.36460114",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4220",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3654,
            "s_seats": 2866,
            "j_seats": 1726,
            "f_seats": 303,
            "r_seats": 3,
            "cargo_demand": 18159
        }
    },
    {
        "iata": "CUA",
        "icao": "MMDA",
        "airportName": "Ciudad Constitucion Airport",
        "location_country": "Mexico",
        "location_city": "Baja California Sur",
        "location_state": "Ciudad Constitucion",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "25.053801",
        "longitude": "-111.614998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5249",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 165789,
            "s_seats": 27494,
            "j_seats": 58781,
            "f_seats": 12369,
            "r_seats": 4404,
            "cargo_demand": 136643
        }
    },
    {
        "iata": "CUB",
        "icao": "KCUB",
        "airportName": "Jim Hamilton-L.B. Owens Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Columbia",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.97050095",
        "longitude": "-80.99520111",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5002",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181657,
            "s_seats": 25161,
            "j_seats": 26639,
            "f_seats": 2096,
            "r_seats": 7364,
            "cargo_demand": 79309
        }
    },
    {
        "iata": "CUC",
        "icao": "SKCC",
        "airportName": "Camilo Daza International Airport",
        "location_country": "Colombia",
        "location_city": "Cucuta",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.92757",
        "longitude": "-72.5115",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6341",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7700",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160791,
            "s_seats": 49723,
            "j_seats": 40750,
            "f_seats": 6939,
            "r_seats": 8237,
            "cargo_demand": 304997
        }
    },
    {
        "iata": "CUD",
        "icao": "YCDR",
        "airportName": "Caloundra Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Caloundra",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.799999",
        "longitude": "153.100006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2608",
                "runway_width": "",
                "runway_material": "B"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2608",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 1289,
            "s_seats": 2385,
            "j_seats": 1495,
            "f_seats": 226,
            "r_seats": 134,
            "cargo_demand": 63206
        }
    },
    {
        "iata": "CUE",
        "icao": "SECU",
        "airportName": "Mariscal Lamar International Airport",
        "location_country": "Ecuador",
        "location_city": "Cuenca",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.88947",
        "longitude": "-78.984398",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6234",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143435,
            "s_seats": 32709,
            "j_seats": 57004,
            "f_seats": 2630,
            "r_seats": 6050,
            "cargo_demand": 184773
        }
    },
    {
        "iata": "CUF",
        "icao": "LIMZ",
        "airportName": "Cuneo International Airport",
        "location_country": "Italy",
        "location_city": "Piedmont",
        "location_state": "Cuneo",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "44.547001",
        "longitude": "7.62322",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6903",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174071,
            "s_seats": 26196,
            "j_seats": 30928,
            "f_seats": 7467,
            "r_seats": 5371,
            "cargo_demand": 227725
        }
    },
    {
        "iata": "CUG",
        "icao": "YCUA",
        "airportName": "Cudal Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Cudal",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-33.27830124",
        "longitude": "148.7630005",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4445",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 2698,
            "s_seats": 1834,
            "j_seats": 1081,
            "f_seats": 356,
            "r_seats": 192,
            "cargo_demand": 67688
        }
    },
    {
        "iata": "CUH",
        "icao": "KCUH",
        "airportName": "Cushing Municipal Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Cushing",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.950496",
        "longitude": "-96.772371",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2860",
                "runway_width": "80",
                "runway_material": "GRE"
            },
            {
                "runway_name": "08/26",
                "runway_length": "2700",
                "runway_width": "55",
                "runway_material": "GRE"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2500",
                "runway_width": "75",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5201",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3773,
            "s_seats": 2894,
            "j_seats": 1413,
            "f_seats": 280,
            "r_seats": 104,
            "cargo_demand": 28442
        }
    },
    {
        "iata": "CUL",
        "icao": "MMCL",
        "airportName": "Bachigualato Federal International Airport",
        "location_country": "Mexico",
        "location_city": "Sinaloa",
        "location_state": "Culiacan",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "large_airport",
        "latitude": "24.7645",
        "longitude": "-107.474998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 461946,
            "s_seats": 80592,
            "j_seats": 32595,
            "f_seats": 58752,
            "r_seats": 10000,
            "cargo_demand": 18225408
        }
    },
    {
        "iata": "CUM",
        "icao": "SVCU",
        "airportName": "Antonio Jose de Sucre Airport",
        "location_country": "Venezuela",
        "location_city": "Cumana",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.45033264",
        "longitude": "-64.13047028",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72004,
            "s_seats": 29115,
            "j_seats": 7502,
            "f_seats": 7580,
            "r_seats": 3291,
            "cargo_demand": 499454
        }
    },
    {
        "iata": "CUN",
        "icao": "MMUN",
        "airportName": "Cancun International Airport",
        "location_country": "Mexico",
        "location_city": "Quintana Roo",
        "location_state": "Cancun",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "21.03650093",
        "longitude": "-86.87709808",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12L/30R",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "11483",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 437019,
            "s_seats": 70986,
            "j_seats": 128298,
            "f_seats": 25863,
            "r_seats": 10000,
            "cargo_demand": 25124503
        }
    },
    {
        "iata": "CUP",
        "icao": "SVCP",
        "airportName": "General Jose Francisco Bermudez Airport",
        "location_country": "Venezuela",
        "location_city": "Carupano",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.66001415",
        "longitude": "-63.2616806",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6611",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 91867,
            "s_seats": 33957,
            "j_seats": 55716,
            "f_seats": 15082,
            "r_seats": 8371,
            "cargo_demand": 299446
        }
    },
    {
        "iata": "CUQ",
        "icao": "YCOE",
        "airportName": "Coen Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Coen",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-13.761133",
        "longitude": "143.113311",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4107",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186680,
            "s_seats": 47226,
            "j_seats": 46970,
            "f_seats": 5176,
            "r_seats": 7264,
            "cargo_demand": 257753
        }
    },
    {
        "iata": "CUR",
        "icao": "TNCC",
        "airportName": "CuraiCao International Airport",
        "location_country": "CuraiCao",
        "location_city": "Willemstad",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "12.1889",
        "longitude": "-68.959801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "11188",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 332757,
            "s_seats": 87951,
            "j_seats": 80987,
            "f_seats": 31146,
            "r_seats": 10000,
            "cargo_demand": 4045873
        }
    },
    {
        "iata": "CUT",
        "icao": "SAZW",
        "airportName": "Cutral Co Airport",
        "location_country": "Argentina",
        "location_city": "Neuquen",
        "location_state": "Cutral Co",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.93970108",
        "longitude": "-69.26460266",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "4052",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "7/25",
                "runway_length": "5558",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 84840,
            "s_seats": 40169,
            "j_seats": 51705,
            "f_seats": 17515,
            "r_seats": 7110,
            "cargo_demand": 114554
        }
    },
    {
        "iata": "CUU",
        "icao": "MMCU",
        "airportName": "General Roberto Fierro Villalobos International Airport",
        "location_country": "Mexico",
        "location_city": "Chihuahua",
        "location_state": "Chihuahua",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "28.70289993",
        "longitude": "-105.9649963",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "7885",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173590,
            "s_seats": 25976,
            "j_seats": 9986,
            "f_seats": 2123,
            "r_seats": 8962,
            "cargo_demand": 381287
        }
    },
    {
        "iata": "CUV",
        "icao": "SVCG",
        "airportName": "Casigua El Cubo Airport",
        "location_country": "Venezuela",
        "location_city": "Casigua El Cubo",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "8.75813961",
        "longitude": "-72.53630066",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3450",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1820,
            "s_seats": 2215,
            "j_seats": 1393,
            "f_seats": 5,
            "r_seats": 62,
            "cargo_demand": 32067
        }
    },
    {
        "iata": "CUY",
        "icao": "YCUE",
        "airportName": "Cue Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Cue",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.44669914",
        "longitude": "117.9179993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6499",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3270",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2054,
            "s_seats": 2812,
            "j_seats": 1880,
            "f_seats": 146,
            "r_seats": 4,
            "cargo_demand": 35049
        }
    },
    {
        "iata": "CUZ",
        "icao": "SPZO",
        "airportName": "Alejandro Velasco Astete International Airport",
        "location_country": "Peru",
        "location_city": "Cusco",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-13.53569984",
        "longitude": "-71.938797",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "11146",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 443372,
            "s_seats": 70986,
            "j_seats": 76575,
            "f_seats": 30024,
            "r_seats": 10000,
            "cargo_demand": 7798138
        }
    },
    {
        "iata": "CVC",
        "icao": "YCEE",
        "airportName": "Cleve Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Cleve",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-33.70970154",
        "longitude": "136.5050049",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4429",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2936",
                "runway_width": "59",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 84565,
            "s_seats": 34069,
            "j_seats": 44011,
            "f_seats": 2303,
            "r_seats": 9094,
            "cargo_demand": 334711
        }
    },
    {
        "iata": "CVE",
        "icao": "SKCV",
        "airportName": "Covenas Airport",
        "location_country": "Colombia",
        "location_city": "Covenas",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.40092",
        "longitude": "-75.6913",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4396",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108810,
            "s_seats": 25114,
            "j_seats": 41080,
            "f_seats": 8326,
            "r_seats": 4836,
            "cargo_demand": 174622
        }
    },
    {
        "iata": "CVF",
        "icao": "LFLJ",
        "airportName": "Courchevel Altiport",
        "location_country": "France",
        "location_city": "Rhone-Alpes",
        "location_state": "Courchevel",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "45.396702",
        "longitude": "6.63472",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "1725",
                "runway_width": "131",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 2159,
            "s_seats": 2762,
            "j_seats": 1551,
            "f_seats": 104,
            "r_seats": 177,
            "cargo_demand": 13657
        }
    },
    {
        "iata": "CVG",
        "icao": "KCVG",
        "airportName": "Cincinnati/Northern Kentucky International Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Hebron (near Cincinnati/OH)",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "39.048801",
        "longitude": "-84.667801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "12000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18C/36C",
                "runway_length": "11000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 386731,
            "s_seats": 84912,
            "j_seats": 27647,
            "f_seats": 47559,
            "r_seats": 10000,
            "cargo_demand": 19475701
        }
    },
    {
        "iata": "CVJ",
        "icao": "MMCB",
        "airportName": "General Mariano Matamoros Airport",
        "location_country": "Mexico",
        "location_city": "Morelos",
        "location_state": "Cuernavaca",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "18.834801",
        "longitude": "-99.261299",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9180",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 91241,
            "s_seats": 49016,
            "j_seats": 36753,
            "f_seats": 11587,
            "r_seats": 7588,
            "cargo_demand": 374315
        }
    },
    {
        "iata": "CVM",
        "icao": "MMCV",
        "airportName": "General Pedro J. Mendez International Airport",
        "location_country": "Mexico",
        "location_city": "Tamaulipas",
        "location_state": "Ciudad Victoria",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "23.70330048",
        "longitude": "-98.95649719",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4659",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181124,
            "s_seats": 29873,
            "j_seats": 9699,
            "f_seats": 11584,
            "r_seats": 4765,
            "cargo_demand": 288957
        }
    },
    {
        "iata": "CVN",
        "icao": "KCVN",
        "airportName": "Clovis Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Clovis",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.42509842",
        "longitude": "-103.0790024",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "2442",
                "runway_width": "75",
                "runway_material": "GRE"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5697",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1365,
            "s_seats": 2107,
            "j_seats": 1745,
            "f_seats": 335,
            "r_seats": 167,
            "cargo_demand": 87234
        }
    },
    {
        "iata": "CVO",
        "icao": "KCVO",
        "airportName": "Corvallis Municipal Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Corvallis",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.49720001",
        "longitude": "-123.2900009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3335",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5900",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184141,
            "s_seats": 34243,
            "j_seats": 43613,
            "f_seats": 1809,
            "r_seats": 7468,
            "cargo_demand": 220126
        }
    },
    {
        "iata": "CVQ",
        "icao": "YCAR",
        "airportName": "Carnarvon Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Carnarvon",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-24.880211",
        "longitude": "113.67174",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5509",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3740",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81369,
            "s_seats": 32824,
            "j_seats": 26062,
            "f_seats": 9212,
            "r_seats": 8053,
            "cargo_demand": 100811
        }
    },
    {
        "iata": "CVS",
        "icao": "KCVS",
        "airportName": "Cannon Air Force Base",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Clovis",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.382801",
        "longitude": "-103.321999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "8200",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 181846,
            "s_seats": 43019,
            "j_seats": 26836,
            "f_seats": 5369,
            "r_seats": 9101,
            "cargo_demand": 105421
        }
    },
    {
        "iata": "CVT",
        "icao": "EGBE",
        "airportName": "Coventry Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Coventry",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "52.36970139",
        "longitude": "-1.479719996",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5988",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172255,
            "s_seats": 44451,
            "j_seats": 42910,
            "f_seats": 17663,
            "r_seats": 8437,
            "cargo_demand": 455756
        }
    },
    {
        "iata": "CVU",
        "icao": "LPCR",
        "airportName": "Corvo Airport",
        "location_country": "Portugal",
        "location_city": "Azores",
        "location_state": "Corvo Island",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "39.671501",
        "longitude": "-31.1136",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2625",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3234,
            "s_seats": 2170,
            "j_seats": 1453,
            "f_seats": 76,
            "r_seats": 109,
            "cargo_demand": 57716
        }
    },
    {
        "iata": "CWA",
        "icao": "KCWA",
        "airportName": "Central Wisconsin Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Wausau",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.77759933",
        "longitude": "-89.66680145",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7645",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6501",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 69496,
            "s_seats": 46634,
            "j_seats": 46748,
            "f_seats": 1603,
            "r_seats": 8829,
            "cargo_demand": 66827
        }
    },
    {
        "iata": "CWB",
        "icao": "SBCT",
        "airportName": "Afonso Pena International Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Curitiba",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "large_airport",
        "latitude": "-25.5284996",
        "longitude": "-49.17580032",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5899",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "7277",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 392777,
            "s_seats": 93539,
            "j_seats": 41352,
            "f_seats": 52153,
            "r_seats": 10000,
            "cargo_demand": 2313588
        }
    },
    {
        "iata": "CWC",
        "icao": "UKLN",
        "airportName": "Chernivtsi International Airport",
        "location_country": "Ukraine",
        "location_city": "Chernivtsi",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.25930023",
        "longitude": "25.98080063",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7270",
                "runway_width": "138",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 85881,
            "s_seats": 45626,
            "j_seats": 39291,
            "f_seats": 16408,
            "r_seats": 8319,
            "cargo_demand": 121773
        }
    },
    {
        "iata": "CWF",
        "icao": "KCWF",
        "airportName": "Chennault International Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Lake Charles",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "30.2105999",
        "longitude": "-93.14320374",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "10701",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3183,
            "s_seats": 1723,
            "j_seats": 1566,
            "f_seats": 152,
            "r_seats": 156,
            "cargo_demand": 27308
        }
    },
    {
        "iata": "CWI",
        "icao": "KCWI",
        "airportName": "Clinton Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Clinton",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.83110046",
        "longitude": "-90.32910156",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5204",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3700",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3712,
            "s_seats": 2442,
            "j_seats": 1833,
            "f_seats": 151,
            "r_seats": 42,
            "cargo_demand": 17774
        }
    },
    {
        "iata": "CWJ",
        "icao": "ZPCW",
        "airportName": "Cangyuan Washan Airport",
        "location_country": "China",
        "location_city": "Nuoliang Township",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.273889",
        "longitude": "99.373611",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 111877,
            "s_seats": 36728,
            "j_seats": 17650,
            "f_seats": 1381,
            "r_seats": 4052,
            "cargo_demand": 475743
        }
    },
    {
        "iata": "CWL",
        "icao": "EGFF",
        "airportName": "Cardiff Airport",
        "location_country": "United Kingdom",
        "location_city": "Wales",
        "location_state": "Cardiff",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "51.39670181",
        "longitude": "-3.343329906",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7723",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 292943,
            "s_seats": 75362,
            "j_seats": 141444,
            "f_seats": 42720,
            "r_seats": 10000,
            "cargo_demand": 15083320
        }
    },
    {
        "iata": "CWR",
        "icao": "YCWI",
        "airportName": "Cowarie Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Cowarie",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-27.71170044",
        "longitude": "138.3280029",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4265",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 3466,
            "s_seats": 2152,
            "j_seats": 1962,
            "f_seats": 80,
            "r_seats": 37,
            "cargo_demand": 49932
        }
    },
    {
        "iata": "CWT",
        "icao": "YCWR",
        "airportName": "Cowra Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Cowra",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-33.84469986",
        "longitude": "148.6490021",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3825",
                "runway_width": "60",
                "runway_material": "GRE"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5348",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134958,
            "s_seats": 37046,
            "j_seats": 40455,
            "f_seats": 6036,
            "r_seats": 5189,
            "cargo_demand": 49223
        }
    },
    {
        "iata": "CWW",
        "icao": "YCOR",
        "airportName": "Corowa Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Corowa",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-35.99470139",
        "longitude": "146.3569946",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5994",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135701,
            "s_seats": 31713,
            "j_seats": 11560,
            "f_seats": 1733,
            "r_seats": 7463,
            "cargo_demand": 250178
        }
    },
    {
        "iata": "CXA",
        "icao": "SVCD",
        "airportName": "Caicara del Orinoco Airport",
        "location_country": "Venezuela",
        "location_city": "Caicara del Orinoco",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.625510216",
        "longitude": "-66.16280365",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4920",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113999,
            "s_seats": 25043,
            "j_seats": 37717,
            "f_seats": 14514,
            "r_seats": 4066,
            "cargo_demand": 260815
        }
    },
    {
        "iata": "CXB",
        "icao": "VGCB",
        "airportName": "Cox's Bazar Airport",
        "location_country": "Bangladesh",
        "location_city": "Cox's Bazar",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.45219994",
        "longitude": "91.96389771",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6790",
                "runway_width": "125",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167107,
            "s_seats": 44056,
            "j_seats": 14087,
            "f_seats": 18991,
            "r_seats": 6738,
            "cargo_demand": 337636
        }
    },
    {
        "iata": "CXF",
        "icao": "PACX",
        "airportName": "Coldfoot Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Coldfoot",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "67.25219727",
        "longitude": "-150.2039948",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 3034,
            "s_seats": 2678,
            "j_seats": 1353,
            "f_seats": 368,
            "r_seats": 98,
            "cargo_demand": 14636
        }
    },
    {
        "iata": "CXI",
        "icao": "PLCH",
        "airportName": "Cassidy International Airport",
        "location_country": "Kiribati",
        "location_city": "Christmas Island",
        "location_state": "",
        "time_zone": "UTC+14:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.98616004",
        "longitude": "-157.3500061",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6900",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182759,
            "s_seats": 44313,
            "j_seats": 51096,
            "f_seats": 4354,
            "r_seats": 3337,
            "cargo_demand": 446756
        }
    },
    {
        "iata": "CXJ",
        "icao": "SBCX",
        "airportName": "Caxias do Sul Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Caxias do Sul",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-29.19709969",
        "longitude": "-51.1875",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5479",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70099,
            "s_seats": 41685,
            "j_seats": 28367,
            "f_seats": 2720,
            "r_seats": 6308,
            "cargo_demand": 403611
        }
    },
    {
        "iata": "CXL",
        "icao": "KCXL",
        "airportName": "Calexico International Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Calexico",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.66949844",
        "longitude": "-115.5130005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4679",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2230,
            "s_seats": 2219,
            "j_seats": 1122,
            "f_seats": 213,
            "r_seats": 152,
            "cargo_demand": 13391
        }
    },
    {
        "iata": "CXN",
        "icao": "HCMC",
        "airportName": "Candala Airport",
        "location_country": "Somalia",
        "location_city": "Candala",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "11.494",
        "longitude": "49.9085",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3610",
                "runway_width": "215",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1317,
            "s_seats": 2500,
            "j_seats": 1074,
            "f_seats": 69,
            "r_seats": 85,
            "cargo_demand": 10368
        }
    },
    {
        "iata": "CXO",
        "icao": "KCXO",
        "airportName": "Lone Star Executive Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Houston",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.351801",
        "longitude": "-95.414497",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3974",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161116,
            "s_seats": 41148,
            "j_seats": 30651,
            "f_seats": 18153,
            "r_seats": 5682,
            "cargo_demand": 93495
        }
    },
    {
        "iata": "CXP",
        "icao": "WIHL",
        "airportName": "Tunggul Wulung Airport",
        "location_country": "Indonesia",
        "location_city": "Cilacap",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.645060062",
        "longitude": "109.0339966",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4570",
                "runway_width": "96",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199745,
            "s_seats": 48322,
            "j_seats": 46624,
            "f_seats": 5338,
            "r_seats": 8597,
            "cargo_demand": 208990
        }
    },
    {
        "iata": "CXQ",
        "icao": "YCRK",
        "airportName": "Christmas Creek Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Christmas Creek Station",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.8728",
        "longitude": "125.9338",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3608",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1307,
            "s_seats": 1585,
            "j_seats": 1947,
            "f_seats": 231,
            "r_seats": 4,
            "cargo_demand": 9250
        }
    },
    {
        "iata": "CXR",
        "icao": "VVCR",
        "airportName": "Cam Ranh International Airport",
        "location_country": "Vietnam",
        "location_city": "Cam Ranh",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.9982",
        "longitude": "109.219002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02L/20R",
                "runway_length": "10010",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 156078,
            "s_seats": 44680,
            "j_seats": 59768,
            "f_seats": 14930,
            "r_seats": 5207,
            "cargo_demand": 72297
        }
    },
    {
        "iata": "CXT",
        "icao": "YCHT",
        "airportName": "Charters Towers Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Charters Towers",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.04310036",
        "longitude": "146.272995",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3297",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "06/24",
                "runway_length": "5699",
                "runway_width": "100",
                "runway_material": "COP"
            }
        ],
        "demand": {
            "y_seats": 3190,
            "s_seats": 1681,
            "j_seats": 1430,
            "f_seats": 493,
            "r_seats": 135,
            "cargo_demand": 45831
        }
    },
    {
        "iata": "CYA",
        "icao": "MTCA",
        "airportName": "Antoine-Simon Airport",
        "location_country": "Haiti",
        "location_city": "Les Cayes",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "18.27109909",
        "longitude": "-73.78829956",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3220",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183680,
            "s_seats": 49179,
            "j_seats": 47244,
            "f_seats": 19543,
            "r_seats": 8879,
            "cargo_demand": 215102
        }
    },
    {
        "iata": "CYB",
        "icao": "MWCB",
        "airportName": "Charles Kirkconnell International Airport",
        "location_country": "British Overseas Territory of Cayman Islands",
        "location_city": "Cayman Brac",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.68700027",
        "longitude": "-79.88279724",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6000",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150892,
            "s_seats": 38616,
            "j_seats": 40434,
            "f_seats": 12210,
            "r_seats": 4052,
            "cargo_demand": 365884
        }
    },
    {
        "iata": "CYD",
        "icao": "MZMF",
        "airportName": "San Ignacio Town Airstrip",
        "location_country": "Belize",
        "location_city": "San Ignacio",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "17.104872",
        "longitude": "-89.101129",
        "region": "NA",
        "runway": [
            {
                "runway_name": "unknown/unknown",
                "runway_length": "2135",
                "runway_width": "31",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3191,
            "s_seats": 2948,
            "j_seats": 1352,
            "f_seats": 213,
            "r_seats": 168,
            "cargo_demand": 93860
        }
    },
    {
        "iata": "CYF",
        "icao": "PACK",
        "airportName": "Chefornak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Chefornak",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "60.136667",
        "longitude": "-164.279167",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3720",
                "runway_width": "150",
                "runway_material": "GVL"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2500",
                "runway_width": "28",
                "runway_material": "GRVL-DIRT-P"
            }
        ],
        "demand": {
            "y_seats": 2050,
            "s_seats": 2118,
            "j_seats": 1647,
            "f_seats": 90,
            "r_seats": 3,
            "cargo_demand": 77699
        }
    },
    {
        "iata": "CYG",
        "icao": "YCRG",
        "airportName": "Corryong Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Corryong",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-36.18280029",
        "longitude": "147.8880005",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4596",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194175,
            "s_seats": 28902,
            "j_seats": 7849,
            "f_seats": 12609,
            "r_seats": 3934,
            "cargo_demand": 144694
        }
    },
    {
        "iata": "CYI",
        "icao": "RCKU",
        "airportName": "Chiayi Airport",
        "location_country": "Taiwan",
        "location_city": "Chiayi",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.46179962",
        "longitude": "120.3929977",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "5307",
                "runway_width": "74",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 61799,
            "s_seats": 25270,
            "j_seats": 46418,
            "f_seats": 13749,
            "r_seats": 4402,
            "cargo_demand": 175968
        }
    },
    {
        "iata": "CYO",
        "icao": "MUCL",
        "airportName": "Vilo Acuna Airport",
        "location_country": "Cuba",
        "location_city": "Cayo Largo del Sur",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "21.61650085",
        "longitude": "-81.54599762",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9869",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92037,
            "s_seats": 43212,
            "j_seats": 8924,
            "f_seats": 1809,
            "r_seats": 9079,
            "cargo_demand": 265052
        }
    },
    {
        "iata": "CYP",
        "icao": "RPVC",
        "airportName": "Calbayog Airport",
        "location_country": "Philippines",
        "location_city": "Calbayog",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.07269955",
        "longitude": "124.5449982",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4843",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 114385,
            "s_seats": 36674,
            "j_seats": 50635,
            "f_seats": 10167,
            "r_seats": 6069,
            "cargo_demand": 176565
        }
    },
    {
        "iata": "CYR",
        "icao": "SUCA",
        "airportName": "Colonia Airport",
        "location_country": "Uruguay",
        "location_city": "Colonia del Sacramento",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-34.45640182",
        "longitude": "-57.77059937",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4495",
                "runway_width": "",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 2837,
            "s_seats": 2748,
            "j_seats": 1725,
            "f_seats": 18,
            "r_seats": 199,
            "cargo_demand": 42961
        }
    },
    {
        "iata": "CYS",
        "icao": "KCYS",
        "airportName": "Cheyenne Regional Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Cheyenne",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.15570068",
        "longitude": "-104.8119965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9267",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6691",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58840,
            "s_seats": 46119,
            "j_seats": 16768,
            "f_seats": 6861,
            "r_seats": 9562,
            "cargo_demand": 441482
        }
    },
    {
        "iata": "CYT",
        "icao": "PACY",
        "airportName": "Yakataga Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Yakataga",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "60.080974",
        "longitude": "-142.494541",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4350",
                "runway_width": "75",
                "runway_material": "TURF-F"
            }
        ],
        "demand": {
            "y_seats": 1604,
            "s_seats": 1757,
            "j_seats": 1635,
            "f_seats": 296,
            "r_seats": 192,
            "cargo_demand": 15358
        }
    },
    {
        "iata": "CYW",
        "icao": "MMCY",
        "airportName": "Captain Rogelio Castillo National Airport",
        "location_country": "Mexico",
        "location_city": "Guanajuato",
        "location_state": "Celaya",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "20.546",
        "longitude": "-100.887001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6284",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73964,
            "s_seats": 25016,
            "j_seats": 53246,
            "f_seats": 15550,
            "r_seats": 4975,
            "cargo_demand": 234110
        }
    },
    {
        "iata": "CYZ",
        "icao": "RPUY",
        "airportName": "Cauayan Airport",
        "location_country": "Philippines",
        "location_city": "Cauayan",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.92989922",
        "longitude": "121.7529984",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6890",
                "runway_width": "118",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 172096,
            "s_seats": 37384,
            "j_seats": 30020,
            "f_seats": 5135,
            "r_seats": 8801,
            "cargo_demand": 326103
        }
    },
    {
        "iata": "CZA",
        "icao": "MMCT",
        "airportName": "Chichen Itza International Airport",
        "location_country": "Mexico",
        "location_city": "Yucatan",
        "location_state": "Chichen Itza",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "20.6413002",
        "longitude": "-88.44619751",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 94435,
            "s_seats": 45930,
            "j_seats": 17805,
            "f_seats": 11678,
            "r_seats": 5547,
            "cargo_demand": 116418
        }
    },
    {
        "iata": "CZE",
        "icao": "SVCR",
        "airportName": "Jose Leonardo Chirino Airport",
        "location_country": "Venezuela",
        "location_city": "Coro",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.4149437",
        "longitude": "-69.68090057",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6761",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54419,
            "s_seats": 31877,
            "j_seats": 52979,
            "f_seats": 14870,
            "r_seats": 8759,
            "cargo_demand": 130544
        }
    },
    {
        "iata": "CZF",
        "icao": "PACZ",
        "airportName": "Cape Romanzof LRRS Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Cape Romanzof",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "61.78030014",
        "longitude": "-166.0390015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3990",
                "runway_width": "135",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 70182,
            "s_seats": 29626,
            "j_seats": 22689,
            "f_seats": 13315,
            "r_seats": 7040,
            "cargo_demand": 257972
        }
    },
    {
        "iata": "CZL",
        "icao": "DABC",
        "airportName": "Mohamed Boudiaf International Airport",
        "location_country": "Algeria",
        "location_city": "Constantine",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.27600098",
        "longitude": "6.620389938",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68662,
            "s_seats": 40142,
            "j_seats": 6475,
            "f_seats": 17629,
            "r_seats": 6992,
            "cargo_demand": 119930
        }
    },
    {
        "iata": "CZM",
        "icao": "MMCZ",
        "airportName": "Cozumel International Airport",
        "location_country": "Mexico",
        "location_city": "Quintana Roo",
        "location_state": "Cozumel",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.5223999",
        "longitude": "-86.92559814",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10165",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194219,
            "s_seats": 43309,
            "j_seats": 57716,
            "f_seats": 15150,
            "r_seats": 7532,
            "cargo_demand": 450177
        }
    },
    {
        "iata": "CZS",
        "icao": "SBCZ",
        "airportName": "Cruzeiro do Sul International Airport",
        "location_country": "Brazil",
        "location_city": "Acre",
        "location_state": "Cruzeiro do Sul",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.59991",
        "longitude": "-72.769501",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55355,
            "s_seats": 42189,
            "j_seats": 55409,
            "f_seats": 10460,
            "r_seats": 4627,
            "cargo_demand": 109002
        }
    },
    {
        "iata": "CZT",
        "icao": "KCZT",
        "airportName": "Dimmit County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Carrizo Springs",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "28.52219963",
        "longitude": "-99.82360077",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4997",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1849,
            "s_seats": 1841,
            "j_seats": 1533,
            "f_seats": 126,
            "r_seats": 182,
            "cargo_demand": 20433
        }
    },
    {
        "iata": "CZU",
        "icao": "SKCZ",
        "airportName": "Las Brujas Airport",
        "location_country": "Colombia",
        "location_city": "Corozal",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.33274",
        "longitude": "-75.2856",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4930",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71398,
            "s_seats": 26650,
            "j_seats": 45225,
            "f_seats": 16380,
            "r_seats": 3611,
            "cargo_demand": 493741
        }
    },
    {
        "iata": "CZW",
        "icao": "EPCH",
        "airportName": "Cz\u0119stochowa-Rudniki Airport",
        "location_country": "Poland",
        "location_city": "Cz\u0119stochowa",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "50.884998",
        "longitude": "19.2047",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6562",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3575,
            "s_seats": 1557,
            "j_seats": 1946,
            "f_seats": 312,
            "r_seats": 112,
            "cargo_demand": 26743
        }
    },
    {
        "iata": "CZY",
        "icao": "YUNY",
        "airportName": "Cluny Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Cluny",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.51670074",
        "longitude": "139.6170044",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3297",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1897,
            "s_seats": 2121,
            "j_seats": 1208,
            "f_seats": 394,
            "r_seats": 0,
            "cargo_demand": 77461
        }
    },
    {
        "iata": "DAA",
        "icao": "KDAA",
        "airportName": "Davison Army Airfield",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Fort Belvoir",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.71500015",
        "longitude": "-77.18099976",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5618",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144329,
            "s_seats": 33724,
            "j_seats": 41726,
            "f_seats": 1869,
            "r_seats": 6359,
            "cargo_demand": 146495
        }
    },
    {
        "iata": "DAB",
        "icao": "KDAB",
        "airportName": "Daytona Beach International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Daytona Beach",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "29.179899",
        "longitude": "-81.058098",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "10500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "3195",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 60677,
            "s_seats": 27880,
            "j_seats": 23283,
            "f_seats": 9931,
            "r_seats": 6128,
            "cargo_demand": 100602
        }
    },
    {
        "iata": "DAD",
        "icao": "VVDN",
        "airportName": "Da Nang International Airport",
        "location_country": "Vietnam",
        "location_city": "Da Nang",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "16.04389954",
        "longitude": "108.1989975",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "10000",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 238086,
            "s_seats": 82587,
            "j_seats": 64458,
            "f_seats": 69206,
            "r_seats": 10000,
            "cargo_demand": 27627184
        }
    },
    {
        "iata": "DAG",
        "icao": "KDAG",
        "airportName": "Barstow-Daggett Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Daggett",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.85369873",
        "longitude": "-116.7870026",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5119",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6400",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55320,
            "s_seats": 37393,
            "j_seats": 23145,
            "f_seats": 16212,
            "r_seats": 6964,
            "cargo_demand": 131139
        }
    },
    {
        "iata": "DAK",
        "icao": "HEDK",
        "airportName": "Dakhla Oasis Airport",
        "location_country": "Egypt",
        "location_city": "Dakhla Oasis",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "25.41160011",
        "longitude": "29.00309944",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "8166",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2909,
            "s_seats": 2153,
            "j_seats": 1287,
            "f_seats": 240,
            "r_seats": 111,
            "cargo_demand": 17360
        }
    },
    {
        "iata": "DAL",
        "icao": "KDAL",
        "airportName": "Dallas Love Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Dallas",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "32.847099",
        "longitude": "-96.851799",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "7752",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "8800",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6147",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179904,
            "s_seats": 97787,
            "j_seats": 144594,
            "f_seats": 49146,
            "r_seats": 10000,
            "cargo_demand": 14366773
        }
    },
    {
        "iata": "DAM",
        "icao": "OSDI",
        "airportName": "Damascus International Airport",
        "location_country": "Syria",
        "location_city": "Damascus",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "33.41149902",
        "longitude": "36.5155983",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 411865,
            "s_seats": 75866,
            "j_seats": 150072,
            "f_seats": 32923,
            "r_seats": 10000,
            "cargo_demand": 21647755
        }
    },
    {
        "iata": "DAN",
        "icao": "KDAN",
        "airportName": "Danville Regional Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Danville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.57289886",
        "longitude": "-79.33609772",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4020",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88563,
            "s_seats": 36366,
            "j_seats": 18744,
            "f_seats": 5479,
            "r_seats": 5709,
            "cargo_demand": 480476
        }
    },
    {
        "iata": "DAR",
        "icao": "HTDA",
        "airportName": "Julius Nyerere International Airport",
        "location_country": "Tanzania",
        "location_city": "Dar es Salaam",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-6.87811",
        "longitude": "39.202599",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9843",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3280",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 259639,
            "s_seats": 75149,
            "j_seats": 23943,
            "f_seats": 57966,
            "r_seats": 10000,
            "cargo_demand": 28246404
        }
    },
    {
        "iata": "DAU",
        "icao": "AYDU",
        "airportName": "Daru Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Daru",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.086759567",
        "longitude": "143.2079926",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90337,
            "s_seats": 30174,
            "j_seats": 25892,
            "f_seats": 8918,
            "r_seats": 9857,
            "cargo_demand": 368793
        }
    },
    {
        "iata": "DAV",
        "icao": "MPDA",
        "airportName": "Enrique Malek International Airport",
        "location_country": "Panama",
        "location_city": "David",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.391",
        "longitude": "-82.434998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179565,
            "s_seats": 34340,
            "j_seats": 38895,
            "f_seats": 3175,
            "r_seats": 7419,
            "cargo_demand": 362203
        }
    },
    {
        "iata": "DAY",
        "icao": "KDAY",
        "airportName": "Dayton International Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Dayton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "39.90240097",
        "longitude": "-84.2193985",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "10900",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "7001",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8502",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 393354,
            "s_seats": 75166,
            "j_seats": 102459,
            "f_seats": 43872,
            "r_seats": 10000,
            "cargo_demand": 20580790
        }
    },
    {
        "iata": "DBB",
        "icao": "HEAL",
        "airportName": "Al Alamain International Airport",
        "location_country": "Egypt",
        "location_city": "El Alamein",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.92449951",
        "longitude": "28.46139908",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "11479",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90510,
            "s_seats": 49978,
            "j_seats": 54030,
            "f_seats": 18192,
            "r_seats": 3459,
            "cargo_demand": 465456
        }
    },
    {
        "iata": "DBD",
        "icao": "VEDB",
        "airportName": "Dhanbad Airport",
        "location_country": "India",
        "location_city": "Jharkhand",
        "location_state": "Dhanbad",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.83399963",
        "longitude": "86.4253006",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3728",
                "runway_width": "93",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 119437,
            "s_seats": 27976,
            "j_seats": 53699,
            "f_seats": 18294,
            "r_seats": 8177,
            "cargo_demand": 431346
        }
    },
    {
        "iata": "DBN",
        "icao": "KDBN",
        "airportName": "W. H. 'Bud' Barron Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Dublin",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.5644",
        "longitude": "-82.985298",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5004",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1985,
            "s_seats": 2017,
            "j_seats": 1741,
            "f_seats": 32,
            "r_seats": 18,
            "cargo_demand": 31694
        }
    },
    {
        "iata": "DBO",
        "icao": "YSDU",
        "airportName": "Dubbo City Regional Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Dubbo",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-32.21670151",
        "longitude": "148.5749969",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5604",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3501",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196046,
            "s_seats": 39717,
            "j_seats": 39211,
            "f_seats": 2176,
            "r_seats": 5533,
            "cargo_demand": 382408
        }
    },
    {
        "iata": "DBQ",
        "icao": "KDBQ",
        "airportName": "Dubuque Regional Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Dubuque",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.402",
        "longitude": "-90.709503",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6502",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6327",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 140397,
            "s_seats": 42734,
            "j_seats": 37875,
            "f_seats": 12118,
            "r_seats": 3075,
            "cargo_demand": 156576
        }
    },
    {
        "iata": "DBR",
        "icao": "VE89",
        "airportName": "Darbhanga Airport",
        "location_country": "India",
        "location_city": "Bihar",
        "location_state": "Darbhanga",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "26.192801",
        "longitude": "85.916901",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3989,
            "s_seats": 2777,
            "j_seats": 1502,
            "f_seats": 471,
            "r_seats": 39,
            "cargo_demand": 29087
        }
    },
    {
        "iata": "DBV",
        "icao": "LDDU",
        "airportName": "Dubrovnik Airport",
        "location_country": "Croatia",
        "location_city": "Dubrovnik",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "42.56140137",
        "longitude": "18.26819992",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145307,
            "s_seats": 44436,
            "j_seats": 44930,
            "f_seats": 15043,
            "r_seats": 8491,
            "cargo_demand": 357691
        }
    },
    {
        "iata": "DBY",
        "icao": "YDAY",
        "airportName": "Dalby Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Dalby",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.15530014",
        "longitude": "151.2669983",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2999",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4160",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3104,
            "s_seats": 1951,
            "j_seats": 1417,
            "f_seats": 60,
            "r_seats": 143,
            "cargo_demand": 6448
        }
    },
    {
        "iata": "DCA",
        "icao": "KDCA",
        "airportName": "Ronald Reagan Washington National Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "D.C.)",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "38.8521",
        "longitude": "-77.037697",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6869",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04/22",
                "runway_length": "4911",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5204",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 430431,
            "s_seats": 90680,
            "j_seats": 84277,
            "f_seats": 66155,
            "r_seats": 10000,
            "cargo_demand": 19118421
        }
    },
    {
        "iata": "DCF",
        "icao": "TDCF",
        "airportName": "Canefield Airport",
        "location_country": "Dominica",
        "location_city": "Canefield",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.336693",
        "longitude": "-61.392108",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3130",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131295,
            "s_seats": 43708,
            "j_seats": 53983,
            "f_seats": 18577,
            "r_seats": 7389,
            "cargo_demand": 167516
        }
    },
    {
        "iata": "DCI",
        "icao": "LIED",
        "airportName": "Decimomannu Air Base",
        "location_country": "Italy",
        "location_city": "Sardinia",
        "location_state": "Decimomannu",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "39.354198",
        "longitude": "8.97248",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "9810",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "8565",
                "runway_width": "74",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62962,
            "s_seats": 43301,
            "j_seats": 40211,
            "f_seats": 7427,
            "r_seats": 9529,
            "cargo_demand": 491890
        }
    },
    {
        "iata": "DCM",
        "icao": "LFCK",
        "airportName": "Castres-Mazamet Airport",
        "location_country": "France",
        "location_city": "Midi-Pyrenees",
        "location_state": "Castres",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.55630112",
        "longitude": "2.28918004",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5988",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 139225,
            "s_seats": 26663,
            "j_seats": 55506,
            "f_seats": 19107,
            "r_seats": 9412,
            "cargo_demand": 191863
        }
    },
    {
        "iata": "DCN",
        "icao": "YCIN",
        "airportName": "RAAF Base Curtin",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Derby",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.58139992",
        "longitude": "123.8280029",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10003",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103985,
            "s_seats": 49998,
            "j_seats": 26841,
            "f_seats": 15458,
            "r_seats": 7730,
            "cargo_demand": 420863
        }
    },
    {
        "iata": "DCT",
        "icao": "MYRD",
        "airportName": "Duncan Town Airport",
        "location_country": "Bahamas",
        "location_city": "Ragged Island",
        "location_state": "Duncan Town",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "22.18180084",
        "longitude": "-75.72949982",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3800",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167593,
            "s_seats": 47182,
            "j_seats": 39812,
            "f_seats": 18513,
            "r_seats": 5968,
            "cargo_demand": 208925
        }
    },
    {
        "iata": "DCU",
        "icao": "KDCU",
        "airportName": "Pryor Field Regional Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Decatur",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.65269852",
        "longitude": "-86.94539642",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6107",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2840,
            "s_seats": 1981,
            "j_seats": 1430,
            "f_seats": 498,
            "r_seats": 197,
            "cargo_demand": 77583
        }
    },
    {
        "iata": "DCY",
        "icao": "ZUDC",
        "airportName": "Daocheng Yading Airport",
        "location_country": "China",
        "location_city": "Sichuan",
        "location_state": "Daocheng",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.323056",
        "longitude": "100.053333",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "13780",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 173194,
            "s_seats": 32225,
            "j_seats": 9877,
            "f_seats": 9627,
            "r_seats": 7819,
            "cargo_demand": 315772
        }
    },
    {
        "iata": "DDC",
        "icao": "KDDC",
        "airportName": "Dodge City Regional Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Dodge City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.76340103",
        "longitude": "-99.96559906",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4649",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6899",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92091,
            "s_seats": 49869,
            "j_seats": 6767,
            "f_seats": 5148,
            "r_seats": 6569,
            "cargo_demand": 260852
        }
    },
    {
        "iata": "DDD",
        "icao": "VRMU",
        "airportName": "Dhaalu Airport",
        "location_country": "Maldives",
        "location_city": "Dhaalu Atoll",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "2.666075",
        "longitude": "72.887118",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6099",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1710,
            "s_seats": 1781,
            "j_seats": 1082,
            "f_seats": 252,
            "r_seats": 95,
            "cargo_demand": 65264
        }
    },
    {
        "iata": "DDN",
        "icao": "YDLT",
        "airportName": "Delta Downs Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Delta Downs",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.99169922",
        "longitude": "141.3170013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3215",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3898,
            "s_seats": 2048,
            "j_seats": 1803,
            "f_seats": 265,
            "r_seats": 177,
            "cargo_demand": 45435
        }
    },
    {
        "iata": "DEA",
        "icao": "OPDG",
        "airportName": "Dera Ghazi Khan International Airport",
        "location_country": "Pakistan",
        "location_city": "Dera Ghazi Khan",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.96100044",
        "longitude": "70.48590088",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6499",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175676,
            "s_seats": 36268,
            "j_seats": 55787,
            "f_seats": 17993,
            "r_seats": 6661,
            "cargo_demand": 241353
        }
    },
    {
        "iata": "DEB",
        "icao": "LHDC",
        "airportName": "Debrecen International Airport",
        "location_country": "Hungary",
        "location_city": "Debrecen",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.48889923",
        "longitude": "21.61529922",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "8087",
                "runway_width": "230",
                "runway_material": "CON"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "8196",
                "runway_width": "131",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 103999,
            "s_seats": 30054,
            "j_seats": 57513,
            "f_seats": 17293,
            "r_seats": 7808,
            "cargo_demand": 170270
        }
    },
    {
        "iata": "DEC",
        "icao": "KDEC",
        "airportName": "Decatur Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Decatur",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.83459854",
        "longitude": "-88.86569977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8496",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6799",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5299",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127007,
            "s_seats": 44849,
            "j_seats": 12964,
            "f_seats": 19362,
            "r_seats": 3228,
            "cargo_demand": 106675
        }
    },
    {
        "iata": "DED",
        "icao": "VIDN",
        "airportName": "Jolly Grant Airport",
        "location_country": "India",
        "location_city": "Uttarakhand",
        "location_state": "Dehradun",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.189699",
        "longitude": "78.180298",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108637,
            "s_seats": 27606,
            "j_seats": 22207,
            "f_seats": 9239,
            "r_seats": 9816,
            "cargo_demand": 271551
        }
    },
    {
        "iata": "DEF",
        "icao": "OIAD",
        "airportName": "Dezful Airport",
        "location_country": "Iran",
        "location_city": "Dezful",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "32.434399",
        "longitude": "48.397598",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "11729",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "12641",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177871,
            "s_seats": 37145,
            "j_seats": 19881,
            "f_seats": 15112,
            "r_seats": 5231,
            "cargo_demand": 405618
        }
    },
    {
        "iata": "DEH",
        "icao": "KDEH",
        "airportName": "Decorah Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Decorah",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.27550125",
        "longitude": "-91.73940277",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2349,
            "s_seats": 1920,
            "j_seats": 1552,
            "f_seats": 14,
            "r_seats": 93,
            "cargo_demand": 83907
        }
    },
    {
        "iata": "DEL",
        "icao": "VIDP",
        "airportName": "Indira Gandhi International Airport",
        "location_country": "India",
        "location_city": "Delhi",
        "location_state": "",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "28.5665",
        "longitude": "77.103104",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9229",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "12500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "14534",
                "runway_width": "197",
                "runway_material": "paved"
            }
        ],
        "demand": {
            "y_seats": 248996,
            "s_seats": 72939,
            "j_seats": 64709,
            "f_seats": 32960,
            "r_seats": 10000,
            "cargo_demand": 16142239
        }
    },
    {
        "iata": "DEN",
        "icao": "KDEN",
        "airportName": "Denver International Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Denver",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "39.86169815",
        "longitude": "-104.6729965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "12000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "08/26",
                "runway_length": "12000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "12000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "16000",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "12000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "12000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 430817,
            "s_seats": 76793,
            "j_seats": 168375,
            "f_seats": 61583,
            "r_seats": 10000,
            "cargo_demand": 28691815
        }
    },
    {
        "iata": "DEP",
        "icao": "VEDZ",
        "airportName": "Daporijo Airport",
        "location_country": "India",
        "location_city": "Arunachal Pradesh",
        "location_state": "Daporijo",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.98550034",
        "longitude": "94.22280121",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3030",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1891,
            "s_seats": 2696,
            "j_seats": 1553,
            "f_seats": 267,
            "r_seats": 179,
            "cargo_demand": 88625
        }
    },
    {
        "iata": "DES",
        "icao": "FSDR",
        "airportName": "Desroches Airport",
        "location_country": "Seychelles",
        "location_city": "Desroches Island",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.6967",
        "longitude": "53.6558",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4531",
                "runway_width": "33",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1625,
            "s_seats": 1673,
            "j_seats": 1976,
            "f_seats": 87,
            "r_seats": 9,
            "cargo_demand": 58089
        }
    },
    {
        "iata": "DET",
        "icao": "KDET",
        "airportName": "Coleman A. Young International Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Detroit",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.40919876",
        "longitude": "-83.00990295",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4025",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5090",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121992,
            "s_seats": 39839,
            "j_seats": 58092,
            "f_seats": 15056,
            "r_seats": 5327,
            "cargo_demand": 80008
        }
    },
    {
        "iata": "DEZ",
        "icao": "OSDZ",
        "airportName": "Deir ez-Zor Airport",
        "location_country": "Syria",
        "location_city": "Deir ez-Zor",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "35.2854",
        "longitude": "40.175999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "11000",
                "runway_width": "148",
                "runway_material": "SAN"
            }
        ],
        "demand": {
            "y_seats": 190223,
            "s_seats": 48398,
            "j_seats": 52582,
            "f_seats": 19541,
            "r_seats": 4651,
            "cargo_demand": 342215
        }
    },
    {
        "iata": "DFI",
        "icao": "KDFI",
        "airportName": "Defiance Memorial Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Defiance",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.33750153",
        "longitude": "-84.42880249",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4197",
                "runway_width": "72",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3367,
            "s_seats": 1622,
            "j_seats": 1315,
            "f_seats": 92,
            "r_seats": 181,
            "cargo_demand": 1876
        }
    },
    {
        "iata": "DFW",
        "icao": "KDFW",
        "airportName": "Dallas/Fort Worth International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Dallas / Fort Worth",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "32.896801",
        "longitude": "-97.038002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "9000",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "9301",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17C/35C",
                "runway_length": "13401",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "8500",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "13401",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "13400",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "13400",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 370683,
            "s_seats": 88167,
            "j_seats": 31744,
            "f_seats": 35222,
            "r_seats": 10000,
            "cargo_demand": 6586220
        }
    },
    {
        "iata": "DGE",
        "icao": "YMDG",
        "airportName": "Mudgee Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Mudgee",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-32.5625",
        "longitude": "149.6109924",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5705",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3527",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 62646,
            "s_seats": 40431,
            "j_seats": 59788,
            "f_seats": 7736,
            "r_seats": 7437,
            "cargo_demand": 411931
        }
    },
    {
        "iata": "DGL",
        "icao": "KDGL",
        "airportName": "Douglas Municipal Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Douglas",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.34259987",
        "longitude": "-109.5059967",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5760",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4095",
                "runway_width": "100",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2346,
            "s_seats": 2630,
            "j_seats": 1024,
            "f_seats": 393,
            "r_seats": 17,
            "cargo_demand": 32330
        }
    },
    {
        "iata": "DGN",
        "icao": "KNDY",
        "airportName": "Naval Surface Warfare Center Dahlgren Division",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Dahlgren",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.33250046",
        "longitude": "-77.03720093",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4191",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3657,
            "s_seats": 2144,
            "j_seats": 1345,
            "f_seats": 355,
            "r_seats": 143,
            "cargo_demand": 6078
        }
    },
    {
        "iata": "DGO",
        "icao": "MMDO",
        "airportName": "General Guadalupe Victoria International Airport",
        "location_country": "Mexico",
        "location_city": "Durango",
        "location_state": "Durango",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "24.12420082",
        "longitude": "-104.5279999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9514",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148661,
            "s_seats": 42376,
            "j_seats": 10257,
            "f_seats": 19168,
            "r_seats": 5994,
            "cargo_demand": 409069
        }
    },
    {
        "iata": "DGT",
        "icao": "RPVD",
        "airportName": "Sibulan Airport (Dumaguete Airport)",
        "location_country": "Philippines",
        "location_city": "Dumaguete",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.333709717",
        "longitude": "123.3000031",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6136",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117151,
            "s_seats": 49515,
            "j_seats": 53753,
            "f_seats": 14529,
            "r_seats": 6330,
            "cargo_demand": 58358
        }
    },
    {
        "iata": "DGW",
        "icao": "KDGW",
        "airportName": "Converse County Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Douglas",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.79719925",
        "longitude": "-105.3860016",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4760",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6532",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2729,
            "s_seats": 2086,
            "j_seats": 1453,
            "f_seats": 148,
            "r_seats": 40,
            "cargo_demand": 98304
        }
    },
    {
        "iata": "DHA",
        "icao": "OEDR",
        "airportName": "King Abdulaziz Air Base",
        "location_country": "Saudi Arabia",
        "location_city": "Dhahran",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "26.2654",
        "longitude": "50.152",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "12008",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 466421,
            "s_seats": 71336,
            "j_seats": 127632,
            "f_seats": 37194,
            "r_seats": 10000,
            "cargo_demand": 2183159
        }
    },
    {
        "iata": "DHD",
        "icao": "YDRH",
        "airportName": "Durham Downs Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Durham Downs",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.07500076",
        "longitude": "141.8999939",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3031",
                "runway_width": "",
                "runway_material": "C"
            },
            {
                "runway_name": "07/25",
                "runway_length": "2687",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1958,
            "s_seats": 1878,
            "j_seats": 1457,
            "f_seats": 224,
            "r_seats": 28,
            "cargo_demand": 83748
        }
    },
    {
        "iata": "DHF",
        "icao": "OMAM",
        "airportName": "Al Dhafra Air Base",
        "location_country": "United Arab Emirates",
        "location_city": "Abu Dhabi",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.24819946",
        "longitude": "54.54769897",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "12012",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "12012",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162133,
            "s_seats": 44508,
            "j_seats": 59860,
            "f_seats": 12900,
            "r_seats": 4191,
            "cargo_demand": 184609
        }
    },
    {
        "iata": "DHI",
        "icao": "VNDH",
        "airportName": "Dhangadhi Airport",
        "location_country": "Nepal",
        "location_city": "Dhangadhi",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.75329971",
        "longitude": "80.58190155",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3430,
            "s_seats": 2195,
            "j_seats": 1271,
            "f_seats": 289,
            "r_seats": 144,
            "cargo_demand": 97778
        }
    },
    {
        "iata": "DHM",
        "icao": "VIGG",
        "airportName": "Gaggal Airport",
        "location_country": "India",
        "location_city": "Himachal Pradesh",
        "location_state": "Kangra",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.1651",
        "longitude": "76.263397",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4620",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172453,
            "s_seats": 48522,
            "j_seats": 10360,
            "f_seats": 18515,
            "r_seats": 6841,
            "cargo_demand": 132071
        }
    },
    {
        "iata": "DHN",
        "icao": "KDHN",
        "airportName": "Dothan Regional Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Dothan",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.32130051",
        "longitude": "-85.44960022",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8498",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136565,
            "s_seats": 43307,
            "j_seats": 41928,
            "f_seats": 4275,
            "r_seats": 7741,
            "cargo_demand": 299704
        }
    },
    {
        "iata": "DHR",
        "icao": "EHKD",
        "airportName": "De Kooy Airfield",
        "location_country": "Netherlands",
        "location_city": "Den Helder",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "52.92340088",
        "longitude": "4.780620098",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4183",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 140510,
            "s_seats": 34387,
            "j_seats": 38600,
            "f_seats": 5508,
            "r_seats": 9852,
            "cargo_demand": 59038
        }
    },
    {
        "iata": "DHT",
        "icao": "KDHT",
        "airportName": "Dalhart Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Dalhart",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.02259827",
        "longitude": "-102.5469971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5669",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6400",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146398,
            "s_seats": 41558,
            "j_seats": 56720,
            "f_seats": 2938,
            "r_seats": 6715,
            "cargo_demand": 430824
        }
    },
    {
        "iata": "DIA",
        "icao": "OTBD",
        "airportName": "Doha International Airport",
        "location_country": "Qatar",
        "location_city": "Doha",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.261101",
        "longitude": "51.565102",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "15000",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94717,
            "s_seats": 36751,
            "j_seats": 13904,
            "f_seats": 2724,
            "r_seats": 6895,
            "cargo_demand": 198946
        }
    },
    {
        "iata": "DIB",
        "icao": "VEMN",
        "airportName": "Dibrugarh Airport (Mohanbari Airport)",
        "location_country": "India",
        "location_city": "Assam",
        "location_state": "Dibrugarh",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.48390007",
        "longitude": "95.01689911",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6000",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94243,
            "s_seats": 39756,
            "j_seats": 54172,
            "f_seats": 2044,
            "r_seats": 5710,
            "cargo_demand": 55238
        }
    },
    {
        "iata": "DIE",
        "icao": "FMNA",
        "airportName": "Arrachart Airport",
        "location_country": "Madagascar",
        "location_city": "Antsiranana",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.3494",
        "longitude": "49.291698",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144299,
            "s_seats": 27250,
            "j_seats": 21958,
            "f_seats": 15677,
            "r_seats": 5909,
            "cargo_demand": 174824
        }
    },
    {
        "iata": "DIJ",
        "icao": "LFSD",
        "airportName": "Dijon Air Base",
        "location_country": "France",
        "location_city": "Burgundy",
        "location_state": "Dijon",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.268902",
        "longitude": "5.09",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5905",
                "runway_width": "118",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165334,
            "s_seats": 41720,
            "j_seats": 48722,
            "f_seats": 1625,
            "r_seats": 7080,
            "cargo_demand": 492592
        }
    },
    {
        "iata": "DIK",
        "icao": "KDIK",
        "airportName": "Dickinson Theodore Roosevelt Regional Airport",
        "location_country": "United States",
        "location_city": "North Dakota",
        "location_state": "Dickinson",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.79740143",
        "longitude": "-102.802002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4700",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6400",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50972,
            "s_seats": 25982,
            "j_seats": 25512,
            "f_seats": 3262,
            "r_seats": 8348,
            "cargo_demand": 401436
        }
    },
    {
        "iata": "DIL",
        "icao": "WPDL",
        "airportName": "Presidente Nicolau Lobato International Airport",
        "location_country": "East Timor",
        "location_city": "Dili",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.54640007",
        "longitude": "125.526001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6065",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67797,
            "s_seats": 40265,
            "j_seats": 10071,
            "f_seats": 6686,
            "r_seats": 3085,
            "cargo_demand": 171144
        }
    },
    {
        "iata": "DIN",
        "icao": "VVDB",
        "airportName": "Dien Bien Phu Airport",
        "location_country": "Vietnam",
        "location_city": "Dien Bien Phu",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.39749908",
        "longitude": "103.0080032",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6003",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 55601,
            "s_seats": 45399,
            "j_seats": 33248,
            "f_seats": 8144,
            "r_seats": 8675,
            "cargo_demand": 225783
        }
    },
    {
        "iata": "DIQ",
        "icao": "SNDV",
        "airportName": "Divinopolis Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Divinopolis",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-20.1807003",
        "longitude": "-44.8708992",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5052",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2948,
            "s_seats": 2524,
            "j_seats": 1076,
            "f_seats": 264,
            "r_seats": 123,
            "cargo_demand": 12358
        }
    },
    {
        "iata": "DIR",
        "icao": "HADR",
        "airportName": "Aba Tenna Dejazmach Yilma International Airport",
        "location_country": "Ethiopia",
        "location_city": "Dire Dawa",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.624699593",
        "longitude": "41.85419846",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "8791",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83139,
            "s_seats": 36964,
            "j_seats": 51630,
            "f_seats": 14235,
            "r_seats": 4039,
            "cargo_demand": 343167
        }
    },
    {
        "iata": "DIS",
        "icao": "FCPL",
        "airportName": "Dolisie Airport",
        "location_country": "Republic of the Congo",
        "location_city": "Dolisie",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-4.20635",
        "longitude": "12.6599",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6725",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163326,
            "s_seats": 40378,
            "j_seats": 43128,
            "f_seats": 13075,
            "r_seats": 4806,
            "cargo_demand": 72751
        }
    },
    {
        "iata": "DIY",
        "icao": "LTCC",
        "airportName": "Diyarbakir Airport",
        "location_country": "Turkey",
        "location_city": "Diyarbakir",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.89390183",
        "longitude": "40.20100021",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "11644",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 56070,
            "s_seats": 26788,
            "j_seats": 34184,
            "f_seats": 11370,
            "r_seats": 4757,
            "cargo_demand": 471633
        }
    },
    {
        "iata": "DJB",
        "icao": "WIPA",
        "airportName": "Sultan Thaha Airport",
        "location_country": "Indonesia",
        "location_city": "Jambi",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.63802",
        "longitude": "103.643997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3569,
            "s_seats": 2076,
            "j_seats": 1383,
            "f_seats": 353,
            "r_seats": 145,
            "cargo_demand": 54776
        }
    },
    {
        "iata": "DJE",
        "icao": "DTTJ",
        "airportName": "Djerba-Zarzis International Airport",
        "location_country": "Tunisia",
        "location_city": "Djerba",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.875",
        "longitude": "10.7755003",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94607,
            "s_seats": 45934,
            "j_seats": 57208,
            "f_seats": 16815,
            "r_seats": 4199,
            "cargo_demand": 425548
        }
    },
    {
        "iata": "DJG",
        "icao": "DAAJ",
        "airportName": "Tiska Djanet Airport",
        "location_country": "Algeria",
        "location_city": "Djanet",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.292801",
        "longitude": "9.45244",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104188,
            "s_seats": 40649,
            "j_seats": 32066,
            "f_seats": 9275,
            "r_seats": 7090,
            "cargo_demand": 139837
        }
    },
    {
        "iata": "DJJ",
        "icao": "WAJJ",
        "airportName": "Sentani Airport",
        "location_country": "Indonesia",
        "location_city": "Jayapura",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-2.57695",
        "longitude": "140.516006",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143334,
            "s_seats": 74288,
            "j_seats": 52669,
            "f_seats": 61836,
            "r_seats": 10000,
            "cargo_demand": 21099694
        }
    },
    {
        "iata": "DJM",
        "icao": "FCBD",
        "airportName": "Djambala Airport",
        "location_country": "Republic of the Congo",
        "location_city": "Djambala",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.516883",
        "longitude": "14.754403",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6725",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3573,
            "s_seats": 2788,
            "j_seats": 1377,
            "f_seats": 480,
            "r_seats": 49,
            "cargo_demand": 26769
        }
    },
    {
        "iata": "DJO",
        "icao": "DIDL",
        "airportName": "Daloa Airport",
        "location_country": "Ivory Coast",
        "location_city": "Daloa",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.792809963",
        "longitude": "-6.473189831",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 52740,
            "s_seats": 28556,
            "j_seats": 47050,
            "f_seats": 9811,
            "r_seats": 7846,
            "cargo_demand": 59247
        }
    },
    {
        "iata": "DKI",
        "icao": "YDKI",
        "airportName": "Dunk Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Dunk Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.94169998",
        "longitude": "146.1399994",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2673",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 3493,
            "s_seats": 1770,
            "j_seats": 1021,
            "f_seats": 253,
            "r_seats": 1,
            "cargo_demand": 69086
        }
    },
    {
        "iata": "DKK",
        "icao": "KDKK",
        "airportName": "Chautauqua County/Dunkirk Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Dunkirk",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.49330139",
        "longitude": "-79.27200317",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1197,
            "s_seats": 2619,
            "j_seats": 1136,
            "f_seats": 289,
            "r_seats": 12,
            "cargo_demand": 32174
        }
    },
    {
        "iata": "DKR",
        "icao": "GOOY",
        "airportName": "Leopold Sedar Senghor International Airport",
        "location_country": "Senegal",
        "location_city": "Dakar",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "14.7397",
        "longitude": "-17.4902",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4866",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "11450",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2959,
            "s_seats": 2212,
            "j_seats": 1329,
            "f_seats": 458,
            "r_seats": 71,
            "cargo_demand": 53403
        }
    },
    {
        "iata": "DKV",
        "icao": "YDVR",
        "airportName": "Docker River Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Docker River",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.86000061",
        "longitude": "129.0700073",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4406",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 2294,
            "s_seats": 2161,
            "j_seats": 1716,
            "f_seats": 326,
            "r_seats": 70,
            "cargo_demand": 78277
        }
    },
    {
        "iata": "DLA",
        "icao": "FKKD",
        "airportName": "Douala International Airport",
        "location_country": "Cameroon",
        "location_city": "Douala",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.006080151",
        "longitude": "9.719479561",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9350",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128390,
            "s_seats": 46285,
            "j_seats": 27354,
            "f_seats": 12043,
            "r_seats": 5390,
            "cargo_demand": 326182
        }
    },
    {
        "iata": "DLC",
        "icao": "ZYTL",
        "airportName": "Dalian Zhoushuizi International Airport",
        "location_country": "China",
        "location_city": "Liaoning",
        "location_state": "Dalian",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "38.965698",
        "longitude": "121.539001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 381619,
            "s_seats": 76827,
            "j_seats": 54253,
            "f_seats": 68021,
            "r_seats": 10000,
            "cargo_demand": 6470669
        }
    },
    {
        "iata": "DLE",
        "icao": "LFGJ",
        "airportName": "Dole-Jura Airport",
        "location_country": "France",
        "location_city": "Franche-Comte",
        "location_state": "Dole",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.042686",
        "longitude": "5.435063",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7318",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142470,
            "s_seats": 40486,
            "j_seats": 22099,
            "f_seats": 11258,
            "r_seats": 3218,
            "cargo_demand": 52183
        }
    },
    {
        "iata": "DLF",
        "icao": "KDLF",
        "airportName": "Laughlin Air Force Base",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Del Rio",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "29.359501",
        "longitude": "-100.778002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13C/31C",
                "runway_length": "8857",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "8311",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "6246",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173701,
            "s_seats": 90036,
            "j_seats": 85804,
            "f_seats": 40958,
            "r_seats": 10000,
            "cargo_demand": 29749440
        }
    },
    {
        "iata": "DLG",
        "icao": "PADL",
        "airportName": "Dillingham Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Dillingham",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "59.04470062",
        "longitude": "-158.5050049",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6400",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162989,
            "s_seats": 36132,
            "j_seats": 24515,
            "f_seats": 18228,
            "r_seats": 4900,
            "cargo_demand": 255555
        }
    },
    {
        "iata": "DLH",
        "icao": "KDLH",
        "airportName": "Duluth International Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Duluth",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "46.84209824",
        "longitude": "-92.19360352",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5699",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "10152",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 222513,
            "s_seats": 86420,
            "j_seats": 46464,
            "f_seats": 48687,
            "r_seats": 10000,
            "cargo_demand": 14277336
        }
    },
    {
        "iata": "DLI",
        "icao": "VVDL",
        "airportName": "Lien Khuong Airport",
        "location_country": "Vietnam",
        "location_city": "Da Lat",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.75",
        "longitude": "108.366997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10663",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113614,
            "s_seats": 47637,
            "j_seats": 29271,
            "f_seats": 15264,
            "r_seats": 4012,
            "cargo_demand": 478680
        }
    },
    {
        "iata": "DLK",
        "icao": "YDLK",
        "airportName": "Dulkaninna Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Dulkaninna",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-29.01329994",
        "longitude": "138.4810028",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "1968",
                "runway_width": "",
                "runway_material": "N"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3805",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3208,
            "s_seats": 2450,
            "j_seats": 1707,
            "f_seats": 441,
            "r_seats": 151,
            "cargo_demand": 49795
        }
    },
    {
        "iata": "DLL",
        "icao": "KDLC",
        "airportName": "Dillon County Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Dillon",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.44910049",
        "longitude": "-79.36859894",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2844,
            "s_seats": 2570,
            "j_seats": 1184,
            "f_seats": 354,
            "r_seats": 17,
            "cargo_demand": 76274
        }
    },
    {
        "iata": "DLM",
        "icao": "LTBS",
        "airportName": "Dalaman Airport",
        "location_country": "Turkey",
        "location_city": "Dalaman",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.71310043",
        "longitude": "28.79249954",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 468978,
            "s_seats": 92765,
            "j_seats": 90019,
            "f_seats": 42410,
            "r_seats": 10000,
            "cargo_demand": 29409191
        }
    },
    {
        "iata": "DLN",
        "icao": "KDLN",
        "airportName": "Dillon Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Dillon",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.25540161",
        "longitude": "-112.5530014",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3599",
                "runway_width": "60",
                "runway_material": "ASPH-F"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6500",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 2761,
            "s_seats": 2023,
            "j_seats": 1929,
            "f_seats": 352,
            "r_seats": 192,
            "cargo_demand": 17826
        }
    },
    {
        "iata": "DLS",
        "icao": "KDLS",
        "airportName": "Columbia Gorge Regional Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "The Dalles",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "45.620979",
        "longitude": "-121.170777",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4647",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5097",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81176,
            "s_seats": 28616,
            "j_seats": 48500,
            "f_seats": 9500,
            "r_seats": 7997,
            "cargo_demand": 494188
        }
    },
    {
        "iata": "DLV",
        "icao": "YDLV",
        "airportName": "Delissaville Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Delissaville",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.55000019",
        "longitude": "130.6849976",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2811",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1642,
            "s_seats": 2649,
            "j_seats": 1907,
            "f_seats": 318,
            "r_seats": 65,
            "cargo_demand": 29096
        }
    },
    {
        "iata": "DLY",
        "icao": "NVVD",
        "airportName": "Dillon's Bay Airport",
        "location_country": "Vanuatu",
        "location_city": "Tafea",
        "location_state": "Erromango",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.76939964",
        "longitude": "169.0010071",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2428",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1758,
            "s_seats": 2956,
            "j_seats": 1792,
            "f_seats": 183,
            "r_seats": 168,
            "cargo_demand": 98785
        }
    },
    {
        "iata": "DLZ",
        "icao": "ZMDZ",
        "airportName": "Dalanzadgad Airport",
        "location_country": "Mongolia",
        "location_city": "Dalanzadgad",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.59170151",
        "longitude": "104.4300003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7545",
                "runway_width": "164",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 66692,
            "s_seats": 49048,
            "j_seats": 41438,
            "f_seats": 15549,
            "r_seats": 8507,
            "cargo_demand": 203568
        }
    },
    {
        "iata": "DMA",
        "icao": "KDMA",
        "airportName": "Davis-Monthan Air Force Base",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Tucson",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.16650009",
        "longitude": "-110.8830032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "13643",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 198341,
            "s_seats": 39131,
            "j_seats": 53479,
            "f_seats": 18392,
            "r_seats": 8464,
            "cargo_demand": 265847
        }
    },
    {
        "iata": "DMB",
        "icao": "UADD",
        "airportName": "Taraz Airport",
        "location_country": "Kazakhstan",
        "location_city": "Taraz",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.85359955",
        "longitude": "71.3035965",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9514",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108492,
            "s_seats": 35617,
            "j_seats": 43435,
            "f_seats": 14896,
            "r_seats": 6664,
            "cargo_demand": 333346
        }
    },
    {
        "iata": "DMD",
        "icao": "YDMG",
        "airportName": "Doomadgee Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Doomadgee",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.9403",
        "longitude": "138.822006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5433",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3787,
            "s_seats": 1938,
            "j_seats": 1635,
            "f_seats": 300,
            "r_seats": 23,
            "cargo_demand": 33083
        }
    },
    {
        "iata": "DME",
        "icao": "UUDD",
        "airportName": "Domodedovo International Airport",
        "location_country": "Russia",
        "location_city": "Moscow",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "55.40879822",
        "longitude": "37.90629959",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14C/32C",
                "runway_length": "8531",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "12448",
                "runway_width": "174",
                "runway_material": "CON"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "11483",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 114595,
            "s_seats": 96642,
            "j_seats": 54478,
            "f_seats": 28526,
            "r_seats": 10000,
            "cargo_demand": 29046834
        }
    },
    {
        "iata": "DMK",
        "icao": "VTBD",
        "airportName": "Don Mueang International Airport",
        "location_country": "Thailand",
        "location_city": "Bangkok",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "13.91259956",
        "longitude": "100.6070023",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "12139",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 445765,
            "s_seats": 77610,
            "j_seats": 76335,
            "f_seats": 32638,
            "r_seats": 10000,
            "cargo_demand": 7250394
        }
    },
    {
        "iata": "DMM",
        "icao": "OEDF",
        "airportName": "King Fahd International Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Dammam",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "26.47120094",
        "longitude": "49.79790115",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "13124",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "13124",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 425547,
            "s_seats": 71296,
            "j_seats": 157760,
            "f_seats": 54136,
            "r_seats": 10000,
            "cargo_demand": 6924718
        }
    },
    {
        "iata": "DMN",
        "icao": "KDMN",
        "airportName": "Deming Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Deming",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.26229858",
        "longitude": "-107.7210007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5675",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6627",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104519,
            "s_seats": 44689,
            "j_seats": 10287,
            "f_seats": 19155,
            "r_seats": 9003,
            "cargo_demand": 112942
        }
    },
    {
        "iata": "DMO",
        "icao": "KDMO",
        "airportName": "Sedalia Regional Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Sedalia",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.70740128",
        "longitude": "-93.17590332",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3520",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2634,
            "s_seats": 2489,
            "j_seats": 1187,
            "f_seats": 198,
            "r_seats": 48,
            "cargo_demand": 17387
        }
    },
    {
        "iata": "DMT",
        "icao": "SWDM",
        "airportName": "Diamantino Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Diamantino",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-14.37689972",
        "longitude": "-56.40039825",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4856",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3700,
            "s_seats": 1665,
            "j_seats": 1650,
            "f_seats": 200,
            "r_seats": 165,
            "cargo_demand": 72264
        }
    },
    {
        "iata": "DMU",
        "icao": "VEMR",
        "airportName": "Dimapur Airport",
        "location_country": "India",
        "location_city": "Nagaland",
        "location_state": "Dimapur",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.88389969",
        "longitude": "93.77110291",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7513",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69050,
            "s_seats": 35624,
            "j_seats": 21200,
            "f_seats": 10352,
            "r_seats": 8603,
            "cargo_demand": 250668
        }
    },
    {
        "iata": "DNA",
        "icao": "RODN",
        "airportName": "Kadena Air Base",
        "location_country": "Japan",
        "location_city": "Okinawa",
        "location_state": "Kadena",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "26.351667",
        "longitude": "127.769444",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "12100",
                "runway_width": "300",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "12100",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 465733,
            "s_seats": 83067,
            "j_seats": 97355,
            "f_seats": 59754,
            "r_seats": 10000,
            "cargo_demand": 19308015
        }
    },
    {
        "iata": "DNB",
        "icao": "YDBR",
        "airportName": "Dunbar Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Dunbar",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.04999924",
        "longitude": "142.3999939",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3313",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1159,
            "s_seats": 1502,
            "j_seats": 1113,
            "f_seats": 385,
            "r_seats": 181,
            "cargo_demand": 83401
        }
    },
    {
        "iata": "DND",
        "icao": "EGPN",
        "airportName": "Dundee Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Dundee",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "56.45249939",
        "longitude": "-3.02583003",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 126622,
            "s_seats": 39308,
            "j_seats": 41740,
            "f_seats": 15990,
            "r_seats": 9309,
            "cargo_demand": 376130
        }
    },
    {
        "iata": "DNH",
        "icao": "ZLDH",
        "airportName": "Dunhuang Airport",
        "location_country": "China",
        "location_city": "Gansu",
        "location_state": "Dunhuang",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.16109848",
        "longitude": "94.80919647",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 183777,
            "s_seats": 37000,
            "j_seats": 28234,
            "f_seats": 9556,
            "r_seats": 4149,
            "cargo_demand": 493946
        }
    },
    {
        "iata": "DNK",
        "icao": "UKDD",
        "airportName": "Dnipro International Airport",
        "location_country": "Ukraine",
        "location_city": "Dnipro",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.35720062",
        "longitude": "35.1006012",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9320",
                "runway_width": "144",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 195030,
            "s_seats": 32355,
            "j_seats": 25511,
            "f_seats": 7367,
            "r_seats": 8050,
            "cargo_demand": 218397
        }
    },
    {
        "iata": "DNL",
        "icao": "KDNL",
        "airportName": "Daniel Field",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Augusta",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.46649933",
        "longitude": "-82.03939819",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4002",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3738",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196189,
            "s_seats": 29913,
            "j_seats": 19548,
            "f_seats": 16910,
            "r_seats": 3770,
            "cargo_demand": 348058
        }
    },
    {
        "iata": "DNN",
        "icao": "KDNN",
        "airportName": "Dalton Municipal Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Dalton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.72290039",
        "longitude": "-84.87020111",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5498",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3047,
            "s_seats": 1795,
            "j_seats": 1032,
            "f_seats": 250,
            "r_seats": 186,
            "cargo_demand": 76095
        }
    },
    {
        "iata": "DNO",
        "icao": "SWDN",
        "airportName": "Dianopolis Airport",
        "location_country": "Brazil",
        "location_city": "Tocantins",
        "location_state": "Dianopolis",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.59539986",
        "longitude": "-46.84669876",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4167",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1193,
            "s_seats": 1654,
            "j_seats": 1604,
            "f_seats": 495,
            "r_seats": 2,
            "cargo_demand": 65105
        }
    },
    {
        "iata": "DNP",
        "icao": "VNDG",
        "airportName": "Tribhuvannagar Airport",
        "location_country": "Nepal",
        "location_city": "Tribhuwannagar",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.11109924",
        "longitude": "82.29419708",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "2730",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1491,
            "s_seats": 2722,
            "j_seats": 1509,
            "f_seats": 499,
            "r_seats": 94,
            "cargo_demand": 82084
        }
    },
    {
        "iata": "DNQ",
        "icao": "YDLQ",
        "airportName": "Deniliquin Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Deniliquin",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-35.55939865",
        "longitude": "144.9459991",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3999",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "1969",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 160079,
            "s_seats": 45608,
            "j_seats": 6081,
            "f_seats": 13368,
            "r_seats": 9077,
            "cargo_demand": 331645
        }
    },
    {
        "iata": "DNR",
        "icao": "LFRD",
        "airportName": "Dinard-Pleurtuit-Saint-Malo Airport",
        "location_country": "France",
        "location_city": "Brittany",
        "location_state": "Dinard / Saint-Malo",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.58769989",
        "longitude": "-2.079960108",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4708",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122854,
            "s_seats": 40479,
            "j_seats": 39878,
            "f_seats": 11022,
            "r_seats": 3347,
            "cargo_demand": 127647
        }
    },
    {
        "iata": "DNS",
        "icao": "KDNS",
        "airportName": "Denison Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Denison",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.9864006",
        "longitude": "-95.38069916",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "1780",
                "runway_width": "175",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2025",
                "runway_width": "105",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2145,
            "s_seats": 1653,
            "j_seats": 1314,
            "f_seats": 498,
            "r_seats": 66,
            "cargo_demand": 17529
        }
    },
    {
        "iata": "DNV",
        "icao": "KDNV",
        "airportName": "Vermilion Regional Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Danville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.19919968",
        "longitude": "-87.59590149",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6002",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2500",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3999",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1646,
            "s_seats": 1601,
            "j_seats": 1349,
            "f_seats": 120,
            "r_seats": 182,
            "cargo_demand": 75840
        }
    },
    {
        "iata": "DNZ",
        "icao": "LTAY",
        "airportName": "Denizli Cardak Airport",
        "location_country": "Turkey",
        "location_city": "Denizli",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.78559875",
        "longitude": "29.70129967",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149180,
            "s_seats": 47674,
            "j_seats": 40871,
            "f_seats": 9286,
            "r_seats": 4903,
            "cargo_demand": 338679
        }
    },
    {
        "iata": "DOB",
        "icao": "WAPD",
        "airportName": "Dobo Airport",
        "location_country": "Indonesia",
        "location_city": "Dobo",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.772220135",
        "longitude": "134.2120056",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2625",
                "runway_width": "75",
                "runway_material": "Compacted sand"
            }
        ],
        "demand": {
            "y_seats": 2584,
            "s_seats": 2947,
            "j_seats": 1302,
            "f_seats": 443,
            "r_seats": 176,
            "cargo_demand": 85978
        }
    },
    {
        "iata": "DOD",
        "icao": "HTDO",
        "airportName": "Dodoma Airport",
        "location_country": "Tanzania",
        "location_city": "Dodoma",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.17044",
        "longitude": "35.752602",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6700",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191151,
            "s_seats": 32755,
            "j_seats": 42254,
            "f_seats": 2853,
            "r_seats": 6713,
            "cargo_demand": 102871
        }
    },
    {
        "iata": "DOG",
        "icao": "HSDN",
        "airportName": "Dongola Airport",
        "location_country": "Sudan",
        "location_city": "Dongola",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.15390015",
        "longitude": "30.43009949",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170395,
            "s_seats": 38398,
            "j_seats": 14531,
            "f_seats": 13969,
            "r_seats": 3638,
            "cargo_demand": 305575
        }
    },
    {
        "iata": "DOH",
        "icao": "OTHH",
        "airportName": "Hamad International Airport",
        "location_country": "Qatar",
        "location_city": "Doha",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "25.273056",
        "longitude": "51.608056",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "15912",
                "runway_width": "197",
                "runway_material": "Asphalt"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "13944",
                "runway_width": "197",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 189128,
            "s_seats": 80526,
            "j_seats": 58197,
            "f_seats": 63190,
            "r_seats": 10000,
            "cargo_demand": 16619600
        }
    },
    {
        "iata": "DOI",
        "icao": "AYDO",
        "airportName": "Doini Island Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Doini Island",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.7009",
        "longitude": "150.7218",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2051",
                "runway_width": "98",
                "runway_material": "Grassed grey sand"
            }
        ],
        "demand": {
            "y_seats": 1427,
            "s_seats": 2433,
            "j_seats": 1964,
            "f_seats": 93,
            "r_seats": 159,
            "cargo_demand": 7358
        }
    },
    {
        "iata": "DOL",
        "icao": "LFRG",
        "airportName": "Deauville - Saint-Gatien Airport",
        "location_country": "France",
        "location_city": "Lower Normandy",
        "location_state": "Deauville",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.365299",
        "longitude": "0.154306",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8366",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131976,
            "s_seats": 27966,
            "j_seats": 43958,
            "f_seats": 10781,
            "r_seats": 4253,
            "cargo_demand": 288365
        }
    },
    {
        "iata": "DOM",
        "icao": "TDPD",
        "airportName": "Douglas-Charles Airport",
        "location_country": "Dominica",
        "location_city": "St Andrew Parish",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.547",
        "longitude": "-61.299999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4777",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165958,
            "s_seats": 47422,
            "j_seats": 54371,
            "f_seats": 8607,
            "r_seats": 5047,
            "cargo_demand": 305053
        }
    },
    {
        "iata": "DOU",
        "icao": "SSDO",
        "airportName": "Dourados Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso do Sul",
        "location_state": "Dourados",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-22.2019",
        "longitude": "-54.926601",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "5282",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2354,
            "s_seats": 1900,
            "j_seats": 1491,
            "f_seats": 140,
            "r_seats": 65,
            "cargo_demand": 32425
        }
    },
    {
        "iata": "DOV",
        "icao": "KDOV",
        "airportName": "Dover Air Force Base",
        "location_country": "United States",
        "location_city": "Delaware",
        "location_state": "Dover",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "39.12950134",
        "longitude": "-75.46600342",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9602",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "14/32",
                "runway_length": "12903",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 382999,
            "s_seats": 80847,
            "j_seats": 147423,
            "f_seats": 38798,
            "r_seats": 10000,
            "cargo_demand": 28239596
        }
    },
    {
        "iata": "DOX",
        "icao": "YDRA",
        "airportName": "Dongara Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Dongara",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-29.298128",
        "longitude": "114.927334",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3637,
            "s_seats": 1709,
            "j_seats": 1207,
            "f_seats": 446,
            "r_seats": 54,
            "cargo_demand": 96847
        }
    },
    {
        "iata": "DPA",
        "icao": "KDPA",
        "airportName": "DuPage Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "West Chicago",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.90779877",
        "longitude": "-88.24859619",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02L/20R",
                "runway_length": "7570",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "5100",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4751",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3401",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153866,
            "s_seats": 34563,
            "j_seats": 19616,
            "f_seats": 13708,
            "r_seats": 6738,
            "cargo_demand": 221573
        }
    },
    {
        "iata": "DPB",
        "icao": "SCBI",
        "airportName": "Pampa Guanaco Airport",
        "location_country": "Chile",
        "location_city": "Magallanes Region",
        "location_state": "Tierra del Fuego Province",
        "time_zone": "UTC-04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-54.049977",
        "longitude": "-68.809193",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "2625",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1541,
            "s_seats": 2759,
            "j_seats": 1962,
            "f_seats": 82,
            "r_seats": 46,
            "cargo_demand": 26942
        }
    },
    {
        "iata": "DPE",
        "icao": "LFAB",
        "airportName": "Dieppe - Saint-Aubin Airport",
        "location_country": "France",
        "location_city": "Upper Normandy",
        "location_state": "Dieppe",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "49.88249969",
        "longitude": "1.085279942",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2690",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1515,
            "s_seats": 1594,
            "j_seats": 1577,
            "f_seats": 432,
            "r_seats": 115,
            "cargo_demand": 53639
        }
    },
    {
        "iata": "DPG",
        "icao": "KDPG",
        "airportName": "Michael Army Airfield",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Dugway",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.19940186",
        "longitude": "-112.9369965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "13125",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2208,
            "s_seats": 1891,
            "j_seats": 1126,
            "f_seats": 353,
            "r_seats": 69,
            "cargo_demand": 98667
        }
    },
    {
        "iata": "DPL",
        "icao": "RPMG",
        "airportName": "Dipolog Airport",
        "location_country": "Philippines",
        "location_city": "Dipolog",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.601983499",
        "longitude": "123.3418751",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6273",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 68075,
            "s_seats": 40505,
            "j_seats": 53123,
            "f_seats": 4342,
            "r_seats": 4097,
            "cargo_demand": 261098
        }
    },
    {
        "iata": "DPO",
        "icao": "YDPO",
        "airportName": "Devonport Airport",
        "location_country": "Australia",
        "location_city": "Tasmania",
        "location_state": "Devonport",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-41.16970062",
        "longitude": "146.4299927",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6030",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2887",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 138354,
            "s_seats": 29828,
            "j_seats": 11138,
            "f_seats": 17534,
            "r_seats": 4000,
            "cargo_demand": 70956
        }
    },
    {
        "iata": "DPS",
        "icao": "WADD",
        "airportName": "Ngurah Rai International Airport",
        "location_country": "Indonesia",
        "location_city": "Denpasar (Bali)",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-8.748169899",
        "longitude": "115.1669998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9790",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 315055,
            "s_seats": 70500,
            "j_seats": 116277,
            "f_seats": 21173,
            "r_seats": 10000,
            "cargo_demand": 257004
        }
    },
    {
        "iata": "DRA",
        "icao": "KDRA",
        "airportName": "Desert Rock Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Mercury",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.6194",
        "longitude": "-116.032997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7515",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155445,
            "s_seats": 41011,
            "j_seats": 40953,
            "f_seats": 7282,
            "r_seats": 9388,
            "cargo_demand": 435005
        }
    },
    {
        "iata": "DRB",
        "icao": "YDBY",
        "airportName": "Derby Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Derby",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.37000084",
        "longitude": "123.6610031",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3799",
                "runway_width": "59",
                "runway_material": "GVL"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5696",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94012,
            "s_seats": 25650,
            "j_seats": 35486,
            "f_seats": 14435,
            "r_seats": 9430,
            "cargo_demand": 156106
        }
    },
    {
        "iata": "DRD",
        "icao": "YDOR",
        "airportName": "Dorunda Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Dorunda",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.5537",
        "longitude": "141.8238",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4461",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1400,
            "s_seats": 2958,
            "j_seats": 1544,
            "f_seats": 107,
            "r_seats": 92,
            "cargo_demand": 60530
        }
    },
    {
        "iata": "DRE",
        "icao": "KDRM",
        "airportName": "Drummond Island Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Drummond Island",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "46.00930023",
        "longitude": "-83.74389648",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2500",
                "runway_width": "150",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3776,
            "s_seats": 2964,
            "j_seats": 1041,
            "f_seats": 213,
            "r_seats": 183,
            "cargo_demand": 33187
        }
    },
    {
        "iata": "DRG",
        "icao": "PADE",
        "airportName": "Deering Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Deering",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "66.069603",
        "longitude": "-162.766006",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3300",
                "runway_width": "75",
                "runway_material": "GVL"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2640",
                "runway_width": "75",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 70737,
            "s_seats": 46330,
            "j_seats": 16760,
            "f_seats": 13977,
            "r_seats": 6935,
            "cargo_demand": 378848
        }
    },
    {
        "iata": "DRI",
        "icao": "KDRI",
        "airportName": "Beauregard Regional Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "DeRidder",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.831699",
        "longitude": "-93.339897",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4501",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4220",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5495",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 61250,
            "s_seats": 36609,
            "j_seats": 39560,
            "f_seats": 9613,
            "r_seats": 3592,
            "cargo_demand": 132940
        }
    },
    {
        "iata": "DRK",
        "icao": "MRDK",
        "airportName": "Drake Bay Airport",
        "location_country": "Costa Rica",
        "location_city": "Drake Bay",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "8.71889019",
        "longitude": "-83.64170074",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2461",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1972,
            "s_seats": 2662,
            "j_seats": 1421,
            "f_seats": 216,
            "r_seats": 125,
            "cargo_demand": 73073
        }
    },
    {
        "iata": "DRN",
        "icao": "YDBI",
        "airportName": "Dirranbandi Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Dirranbandi",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.5916996",
        "longitude": "148.2169952",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3993",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114611,
            "s_seats": 27187,
            "j_seats": 26725,
            "f_seats": 3891,
            "r_seats": 4235,
            "cargo_demand": 194790
        }
    },
    {
        "iata": "DRO",
        "icao": "KDRO",
        "airportName": "Durango-La Plata County Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Durango",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.1515007",
        "longitude": "-107.7539978",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9201",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 1821,
            "s_seats": 1574,
            "j_seats": 1501,
            "f_seats": 291,
            "r_seats": 81,
            "cargo_demand": 20769
        }
    },
    {
        "iata": "DRR",
        "icao": "YDRI",
        "airportName": "Durrie Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Durrie",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.68499947",
        "longitude": "140.2279968",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3116",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1408,
            "s_seats": 2656,
            "j_seats": 1232,
            "f_seats": 293,
            "r_seats": 118,
            "cargo_demand": 79920
        }
    },
    {
        "iata": "DRS",
        "icao": "EDDC",
        "airportName": "Dresden Airport",
        "location_country": "Germany",
        "location_city": "Saxony",
        "location_state": "Dresden",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "51.13280106",
        "longitude": "13.76720047",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9350",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 381834,
            "s_seats": 81768,
            "j_seats": 138242,
            "f_seats": 22948,
            "r_seats": 10000,
            "cargo_demand": 3068678
        }
    },
    {
        "iata": "DRT",
        "icao": "KDRT",
        "airportName": "Del Rio International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Del Rio",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "29.37420082",
        "longitude": "-100.927002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161973,
            "s_seats": 44199,
            "j_seats": 36173,
            "f_seats": 10115,
            "r_seats": 5270,
            "cargo_demand": 176112
        }
    },
    {
        "iata": "DRV",
        "icao": "VRMD",
        "airportName": "Dharavandhoo Airport",
        "location_country": "Maldives",
        "location_city": "Baa Atoll",
        "location_state": "Dharavandhoo Island",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "5.1561",
        "longitude": "73.1302",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3901",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3390,
            "s_seats": 2693,
            "j_seats": 1459,
            "f_seats": 160,
            "r_seats": 70,
            "cargo_demand": 73333
        }
    },
    {
        "iata": "DRW",
        "icao": "YPDN",
        "airportName": "Darwin International Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Darwin",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.41469955",
        "longitude": "130.8769989",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "11004",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187078,
            "s_seats": 42917,
            "j_seats": 17623,
            "f_seats": 18381,
            "r_seats": 8742,
            "cargo_demand": 468385
        }
    },
    {
        "iata": "DRY",
        "icao": "YDRD",
        "airportName": "Drysdale River Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Drysdale River",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.7135704",
        "longitude": "126.3810968",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4589",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 3175,
            "s_seats": 2730,
            "j_seats": 1524,
            "f_seats": 167,
            "r_seats": 98,
            "cargo_demand": 78977
        }
    },
    {
        "iata": "DSA",
        "icao": "EGCN",
        "airportName": "Robin Hood Airport Doncaster Sheffield",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Doncaster",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "53.48053781",
        "longitude": "-1.010656357",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9495",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 298799,
            "s_seats": 80493,
            "j_seats": 26593,
            "f_seats": 49769,
            "r_seats": 10000,
            "cargo_demand": 13675765
        }
    },
    {
        "iata": "DSD",
        "icao": "TFFA",
        "airportName": "La Desirade Airport",
        "location_country": "Guadeloupe",
        "location_city": "La Desirade",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "16.296902",
        "longitude": "-61.0844",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "1985",
                "runway_width": "",
                "runway_material": "paved"
            }
        ],
        "demand": {
            "y_seats": 3586,
            "s_seats": 2204,
            "j_seats": 1171,
            "f_seats": 274,
            "r_seats": 3,
            "cargo_demand": 45662
        }
    },
    {
        "iata": "DSE",
        "icao": "HADC",
        "airportName": "Combolcha Airport",
        "location_country": "Ethiopia",
        "location_city": "Dessie / Kombolcha",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "11.08250046",
        "longitude": "39.71139908",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6561",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1021,
            "s_seats": 2561,
            "j_seats": 1062,
            "f_seats": 224,
            "r_seats": 145,
            "cargo_demand": 73170
        }
    },
    {
        "iata": "DSI",
        "icao": "KDTS",
        "airportName": "Destin Executive Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Destin",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "30.40010071",
        "longitude": "-86.47149658",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4999",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1385,
            "s_seats": 1547,
            "j_seats": 1555,
            "f_seats": 334,
            "r_seats": 11,
            "cargo_demand": 68791
        }
    },
    {
        "iata": "DSK",
        "icao": "OPDI",
        "airportName": "Dera Ismail Khan Airport",
        "location_country": "Pakistan",
        "location_city": "Dera Ismail Khan",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.90940094",
        "longitude": "70.89659882",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69855,
            "s_seats": 38848,
            "j_seats": 13898,
            "f_seats": 19815,
            "r_seats": 4807,
            "cargo_demand": 163505
        }
    },
    {
        "iata": "DSM",
        "icao": "KDSM",
        "airportName": "Des Moines International Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Des Moines",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.534",
        "longitude": "-93.663101",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9003",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "9001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191260,
            "s_seats": 42488,
            "j_seats": 38700,
            "f_seats": 12044,
            "r_seats": 6732,
            "cargo_demand": 323484
        }
    },
    {
        "iata": "DSS",
        "icao": "GOBD",
        "airportName": "Blaise Diagne International Airport",
        "location_country": "Senegal",
        "location_city": "Dakar",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "14.67",
        "longitude": "-17.073333",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "11483",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 242038,
            "s_seats": 76613,
            "j_seats": 148527,
            "f_seats": 27493,
            "r_seats": 10000,
            "cargo_demand": 25699007
        }
    },
    {
        "iata": "DSV",
        "icao": "KDSV",
        "airportName": "Dansville Municipal Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Dansville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.57089996",
        "longitude": "-77.71309662",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3365",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2443",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3501,
            "s_seats": 2776,
            "j_seats": 1032,
            "f_seats": 153,
            "r_seats": 55,
            "cargo_demand": 91264
        }
    },
    {
        "iata": "DTA",
        "icao": "KDTA",
        "airportName": "Delta Municipal Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Delta",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.38059998",
        "longitude": "-112.5080032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5935",
                "runway_width": "85",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5500",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2873,
            "s_seats": 1537,
            "j_seats": 1382,
            "f_seats": 146,
            "r_seats": 174,
            "cargo_demand": 10584
        }
    },
    {
        "iata": "DTB",
        "icao": "WIMN",
        "airportName": "Silangit Airport",
        "location_country": "Indonesia",
        "location_city": "Siborong-Borong",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "2.25973",
        "longitude": "98.991898",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7875",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3698,
            "s_seats": 2336,
            "j_seats": 1254,
            "f_seats": 457,
            "r_seats": 29,
            "cargo_demand": 69046
        }
    },
    {
        "iata": "DTD",
        "icao": "WALJ",
        "airportName": "Datadawai Airport",
        "location_country": "Indonesia",
        "location_city": "Datadawai",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.8106",
        "longitude": "114.5306",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2461",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2739,
            "s_seats": 2260,
            "j_seats": 1019,
            "f_seats": 39,
            "r_seats": 23,
            "cargo_demand": 90809
        }
    },
    {
        "iata": "DTE",
        "icao": "RPUD",
        "airportName": "Bagasbas Airport",
        "location_country": "Philippines",
        "location_city": "Daet",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.12919998",
        "longitude": "122.9800034",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3773",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72381,
            "s_seats": 43370,
            "j_seats": 53777,
            "f_seats": 17996,
            "r_seats": 9967,
            "cargo_demand": 117242
        }
    },
    {
        "iata": "DTI",
        "icao": "SNDT",
        "airportName": "Diamantina Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Diamantina",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-18.23200035",
        "longitude": "-43.65039825",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "5282",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3289,
            "s_seats": 1576,
            "j_seats": 1588,
            "f_seats": 410,
            "r_seats": 163,
            "cargo_demand": 6180
        }
    },
    {
        "iata": "DTL",
        "icao": "KDTL",
        "airportName": "Detroit Lakes Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Detroit Lakes",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "46.82519913",
        "longitude": "-95.88569641",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4500",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "1880",
                "runway_width": "250",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1108,
            "s_seats": 2401,
            "j_seats": 1583,
            "f_seats": 279,
            "r_seats": 162,
            "cargo_demand": 57860
        }
    },
    {
        "iata": "DTM",
        "icao": "EDLW",
        "airportName": "Dortmund Airport",
        "location_country": "Germany",
        "location_city": "North Rhine-Westphalia",
        "location_state": "Dortmund",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "51.5182991",
        "longitude": "7.612239838",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 237808,
            "s_seats": 91887,
            "j_seats": 68396,
            "f_seats": 47535,
            "r_seats": 10000,
            "cargo_demand": 1124477
        }
    },
    {
        "iata": "DTN",
        "icao": "KDTN",
        "airportName": "Shreveport Downtown Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Shreveport",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.54019928",
        "longitude": "-93.74500275",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3200",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5018",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3584,
            "s_seats": 1555,
            "j_seats": 1700,
            "f_seats": 141,
            "r_seats": 148,
            "cargo_demand": 19919
        }
    },
    {
        "iata": "DTW",
        "icao": "KDTW",
        "airportName": "Detroit Metropolitan Wayne County Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Detroit",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "42.21239853",
        "longitude": "-83.35340118",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "8501",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "10001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "04L/22R",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "12003",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "8708",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "8500",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 373799,
            "s_seats": 81272,
            "j_seats": 125358,
            "f_seats": 49255,
            "r_seats": 10000,
            "cargo_demand": 9420149
        }
    },
    {
        "iata": "DUA",
        "icao": "KDUA",
        "airportName": "Durant Regional Airport-Eaker Field",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Durant",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.94229889",
        "longitude": "-96.39450073",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3900",
                "runway_width": "150",
                "runway_material": "U"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3758,
            "s_seats": 1851,
            "j_seats": 1495,
            "f_seats": 89,
            "r_seats": 32,
            "cargo_demand": 40807
        }
    },
    {
        "iata": "DUB",
        "icao": "EIDW",
        "airportName": "Dublin Airport",
        "location_country": "Ireland",
        "location_city": "Dublin",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "53.421299",
        "longitude": "-6.27007",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10R/28L",
                "runway_length": "8652",
                "runway_width": "148",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4393",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6798",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 261836,
            "s_seats": 98812,
            "j_seats": 37766,
            "f_seats": 58306,
            "r_seats": 10000,
            "cargo_demand": 6833935
        }
    },
    {
        "iata": "DUC",
        "icao": "KDUC",
        "airportName": "Halliburton Field",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Duncan",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.47090149",
        "longitude": "-97.9598999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6650",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1272,
            "s_seats": 2149,
            "j_seats": 1787,
            "f_seats": 181,
            "r_seats": 137,
            "cargo_demand": 27284
        }
    },
    {
        "iata": "DUD",
        "icao": "NZDN",
        "airportName": "Dunedin International Airport",
        "location_country": "New Zealand",
        "location_city": "Dunedin",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-45.928101",
        "longitude": "170.197998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6234",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111938,
            "s_seats": 37146,
            "j_seats": 59799,
            "f_seats": 19690,
            "r_seats": 9354,
            "cargo_demand": 358299
        }
    },
    {
        "iata": "DUE",
        "icao": "FNDU",
        "airportName": "Dundo Airport",
        "location_country": "Angola",
        "location_city": "Dundo",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.400889874",
        "longitude": "20.81850052",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6468",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171013,
            "s_seats": 46880,
            "j_seats": 47913,
            "f_seats": 5478,
            "r_seats": 8920,
            "cargo_demand": 49225
        }
    },
    {
        "iata": "DUG",
        "icao": "KDUG",
        "airportName": "Bisbee Douglas International Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Bisbee / Douglas",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.46899986",
        "longitude": "-109.6039963",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7158",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7503",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7311",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106374,
            "s_seats": 45789,
            "j_seats": 36018,
            "f_seats": 9813,
            "r_seats": 7222,
            "cargo_demand": 275975
        }
    },
    {
        "iata": "DUJ",
        "icao": "KDUJ",
        "airportName": "DuBois Regional Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "DuBois",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.17829895",
        "longitude": "-78.8986969",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5504",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77981,
            "s_seats": 31284,
            "j_seats": 42402,
            "f_seats": 14264,
            "r_seats": 4659,
            "cargo_demand": 63236
        }
    },
    {
        "iata": "DUK",
        "icao": "FADK",
        "airportName": "Dukuduku Airport",
        "location_country": "South Africa",
        "location_city": "Mtubatuba",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.36840057",
        "longitude": "32.24810028",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4003",
                "runway_width": "151",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3758,
            "s_seats": 2564,
            "j_seats": 1455,
            "f_seats": 20,
            "r_seats": 188,
            "cargo_demand": 48767
        }
    },
    {
        "iata": "DUM",
        "icao": "WIBD",
        "airportName": "Pinang Kampai Airport",
        "location_country": "Indonesia",
        "location_city": "Dumai",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.60919",
        "longitude": "101.433998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142582,
            "s_seats": 28932,
            "j_seats": 18826,
            "f_seats": 4936,
            "r_seats": 4027,
            "cargo_demand": 397358
        }
    },
    {
        "iata": "DUR",
        "icao": "FALE",
        "airportName": "King Shaka International Airport",
        "location_country": "South Africa",
        "location_city": "Durban",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-29.61444444",
        "longitude": "31.11972222",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "12139",
                "runway_width": "197",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 187559,
            "s_seats": 88478,
            "j_seats": 53008,
            "f_seats": 43398,
            "r_seats": 10000,
            "cargo_demand": 22035960
        }
    },
    {
        "iata": "DUS",
        "icao": "EDDL",
        "airportName": "Dusseldorf Airport",
        "location_country": "Germany",
        "location_city": "North Rhine-Westphalia",
        "location_state": "Dusseldorf",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "51.289501",
        "longitude": "6.76678",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 326829,
            "s_seats": 91210,
            "j_seats": 136048,
            "f_seats": 35079,
            "r_seats": 10000,
            "cargo_demand": 5187444
        }
    },
    {
        "iata": "DUT",
        "icao": "PADU",
        "airportName": "Unalaska Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Unalaska",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "53.89881",
        "longitude": "-166.544996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3900",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189191,
            "s_seats": 37215,
            "j_seats": 37701,
            "f_seats": 1436,
            "r_seats": 5934,
            "cargo_demand": 248175
        }
    },
    {
        "iata": "DVL",
        "icao": "KDVL",
        "airportName": "Devils Lake Regional Airport",
        "location_country": "United States",
        "location_city": "North Dakota",
        "location_state": "Devils Lake",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "48.11420059",
        "longitude": "-98.90879822",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4313",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5509",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2927,
            "s_seats": 2440,
            "j_seats": 1782,
            "f_seats": 84,
            "r_seats": 65,
            "cargo_demand": 95877
        }
    },
    {
        "iata": "DVN",
        "icao": "KDVN",
        "airportName": "Davenport Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Davenport",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.61029816",
        "longitude": "-90.58830261",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4001",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5501",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2320,
            "s_seats": 2083,
            "j_seats": 1098,
            "f_seats": 74,
            "r_seats": 172,
            "cargo_demand": 78527
        }
    },
    {
        "iata": "DVO",
        "icao": "RPMD",
        "airportName": "Francisco Bangoy International Airport",
        "location_country": "Philippines",
        "location_city": "Davao City",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "7.12552",
        "longitude": "125.646004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127967,
            "s_seats": 99281,
            "j_seats": 133677,
            "f_seats": 35853,
            "r_seats": 10000,
            "cargo_demand": 1857855
        }
    },
    {
        "iata": "DVP",
        "icao": "YDPD",
        "airportName": "Davenport Downs Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Davenport Downs",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.14999962",
        "longitude": "141.1080017",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3297",
                "runway_width": "",
                "runway_material": "C"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2559",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1535,
            "s_seats": 2936,
            "j_seats": 1960,
            "f_seats": 179,
            "r_seats": 44,
            "cargo_demand": 85208
        }
    },
    {
        "iata": "DVT",
        "icao": "KDVT",
        "airportName": "Phoenix Deer Valley Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Phoenix",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.68830109",
        "longitude": "-112.0830002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "4500",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "8208",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1153,
            "s_seats": 1807,
            "j_seats": 1199,
            "f_seats": 264,
            "r_seats": 60,
            "cargo_demand": 96671
        }
    },
    {
        "iata": "DWA",
        "icao": "FWDW",
        "airportName": "Dwanga Airport",
        "location_country": "Malawi",
        "location_city": "Dwangwa",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.5183",
        "longitude": "34.131901",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3980",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 182488,
            "s_seats": 44981,
            "j_seats": 56990,
            "f_seats": 14419,
            "r_seats": 9204,
            "cargo_demand": 247607
        }
    },
    {
        "iata": "DWC",
        "icao": "OMDW",
        "airportName": "Al Maktoum International Airport",
        "location_country": "United Arab Emirates",
        "location_city": "Dubai",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "24.896356",
        "longitude": "55.161389",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "14764",
                "runway_width": "197",
                "runway_material": "Asphalt"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6030",
                "runway_width": "98",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 327709,
            "s_seats": 84435,
            "j_seats": 41089,
            "f_seats": 28142,
            "r_seats": 10000,
            "cargo_demand": 11935956
        }
    },
    {
        "iata": "DWH",
        "icao": "KDWH",
        "airportName": "David Wayne Hooks Memorial Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Houston",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "30.0618",
        "longitude": "-95.55280304",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "3987",
                "runway_width": "35",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "7009",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17W/35W",
                "runway_length": "2530",
                "runway_width": "100",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 1378,
            "s_seats": 2875,
            "j_seats": 1789,
            "f_seats": 354,
            "r_seats": 95,
            "cargo_demand": 93007
        }
    },
    {
        "iata": "DXB",
        "icao": "OMDB",
        "airportName": "Dubai International Airport",
        "location_country": "United Arab Emirates",
        "location_city": "Dubai",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "25.25279999",
        "longitude": "55.36439896",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12L/30R",
                "runway_length": "14275",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "14590",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170092,
            "s_seats": 85117,
            "j_seats": 119663,
            "f_seats": 49924,
            "r_seats": 10000,
            "cargo_demand": 9694110
        }
    },
    {
        "iata": "DXD",
        "icao": "YDIX",
        "airportName": "Dixie Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Dixie",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.11749443",
        "longitude": "143.3160496",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3182",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3749,
            "s_seats": 2485,
            "j_seats": 1258,
            "f_seats": 28,
            "r_seats": 75,
            "cargo_demand": 48751
        }
    },
    {
        "iata": "DXE",
        "icao": "KMBO",
        "airportName": "Bruce Campbell Field",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Madison",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.438702",
        "longitude": "-90.103104",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4444",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2580,
            "s_seats": 1953,
            "j_seats": 1763,
            "f_seats": 98,
            "r_seats": 120,
            "cargo_demand": 61336
        }
    },
    {
        "iata": "DXR",
        "icao": "KDXR",
        "airportName": "Danbury Municipal Airport",
        "location_country": "United States",
        "location_city": "Connecticut",
        "location_state": "Danbury",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.37149811",
        "longitude": "-73.48220062",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4422",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3135",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1854,
            "s_seats": 2338,
            "j_seats": 1825,
            "f_seats": 290,
            "r_seats": 11,
            "cargo_demand": 17622
        }
    },
    {
        "iata": "DYA",
        "icao": "YDYS",
        "airportName": "Dysart Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Dysart",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.62220001",
        "longitude": "148.3639984",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5085",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66247,
            "s_seats": 27281,
            "j_seats": 30844,
            "f_seats": 19073,
            "r_seats": 3425,
            "cargo_demand": 316062
        }
    },
    {
        "iata": "DYL",
        "icao": "KDYL",
        "airportName": "Doylestown Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Doylestown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.33300018",
        "longitude": "-75.12229919",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3004",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3235,
            "s_seats": 2986,
            "j_seats": 1173,
            "f_seats": 151,
            "r_seats": 26,
            "cargo_demand": 54604
        }
    },
    {
        "iata": "DYR",
        "icao": "UHMA",
        "airportName": "Ugolny Airport",
        "location_country": "Russia",
        "location_city": "Chukotka",
        "location_state": "Anadyr",
        "time_zone": "UTC+12:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.734902",
        "longitude": "177.740997",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "11483",
                "runway_width": "196",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 95590,
            "s_seats": 34054,
            "j_seats": 5941,
            "f_seats": 2840,
            "r_seats": 9315,
            "cargo_demand": 288571
        }
    },
    {
        "iata": "DYS",
        "icao": "KDYS",
        "airportName": "Dyess Air Force Base",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Abilene",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "32.42079926",
        "longitude": "-99.854599",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "13500",
                "runway_width": "300",
                "runway_material": "PEM"
            },
            {
                "runway_name": "161/341",
                "runway_length": "3500",
                "runway_width": "60",
                "runway_material": "GRE"
            },
            {
                "runway_name": "162/342",
                "runway_length": "3500",
                "runway_width": "60",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 321450,
            "s_seats": 81796,
            "j_seats": 169944,
            "f_seats": 46871,
            "r_seats": 10000,
            "cargo_demand": 20972998
        }
    },
    {
        "iata": "DYU",
        "icao": "UTDD",
        "airportName": "Dushanbe International Airport",
        "location_country": "Tajikistan",
        "location_city": "Dushanbe",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.54330063",
        "longitude": "68.82499695",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10170",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87586,
            "s_seats": 44900,
            "j_seats": 42587,
            "f_seats": 9971,
            "r_seats": 5401,
            "cargo_demand": 339284
        }
    },
    {
        "iata": "DZA",
        "icao": "FMCZ",
        "airportName": "Dzaoudzi-Pamandzi International Airport",
        "location_country": "Mayotte",
        "location_city": "Dzaoudzi",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.8046999",
        "longitude": "45.28110123",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6330",
                "runway_width": "92",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53967,
            "s_seats": 41497,
            "j_seats": 21173,
            "f_seats": 6733,
            "r_seats": 8762,
            "cargo_demand": 159236
        }
    },
    {
        "iata": "DZN",
        "icao": "UAKD",
        "airportName": "Zhezkazgan Airport",
        "location_country": "Kazakhstan",
        "location_city": "Zhezkazgan",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.708302",
        "longitude": "67.733299",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8530",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 171690,
            "s_seats": 26271,
            "j_seats": 26508,
            "f_seats": 16652,
            "r_seats": 9164,
            "cargo_demand": 120288
        }
    },
    {
        "iata": "DZO",
        "icao": "SUDU",
        "airportName": "Santa Bernardina International Airport",
        "location_country": "Uruguay",
        "location_city": "Durazno",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.35889816",
        "longitude": "-56.49919891",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7477",
                "runway_width": "148",
                "runway_material": "PEM"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4764",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182562,
            "s_seats": 47624,
            "j_seats": 34286,
            "f_seats": 15499,
            "r_seats": 8351,
            "cargo_demand": 302205
        }
    },
    {
        "iata": "EAA",
        "icao": "PAEG",
        "airportName": "Eagle Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Eagle",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "64.77639771",
        "longitude": "-141.151001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3600",
                "runway_width": "75",
                "runway_material": "GRVL-F"
            }
        ],
        "demand": {
            "y_seats": 3361,
            "s_seats": 1980,
            "j_seats": 1494,
            "f_seats": 333,
            "r_seats": 65,
            "cargo_demand": 95314
        }
    },
    {
        "iata": "EAE",
        "icao": "NVSE",
        "airportName": "Siwo Airport",
        "location_country": "Vanuatu",
        "location_city": "Shefa",
        "location_state": "Emae Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.09029961",
        "longitude": "168.3430023",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2307",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3655,
            "s_seats": 1607,
            "j_seats": 1741,
            "f_seats": 97,
            "r_seats": 200,
            "cargo_demand": 94764
        }
    },
    {
        "iata": "EAM",
        "icao": "OENG",
        "airportName": "Najran Domestic Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Najran",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.6114006",
        "longitude": "44.4192009",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10007",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169993,
            "s_seats": 37090,
            "j_seats": 18944,
            "f_seats": 5277,
            "r_seats": 7023,
            "cargo_demand": 196158
        }
    },
    {
        "iata": "EAN",
        "icao": "KEAN",
        "airportName": "Phifer Airfield",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Wheatland",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.0555",
        "longitude": "-104.929001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5900",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2494,
            "s_seats": 2242,
            "j_seats": 1745,
            "f_seats": 464,
            "r_seats": 159,
            "cargo_demand": 75314
        }
    },
    {
        "iata": "EAR",
        "icao": "KEAR",
        "airportName": "Kearney Regional Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Kearney",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.72700119",
        "longitude": "-99.00679779",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4498",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7094",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3341,
            "s_seats": 2055,
            "j_seats": 1146,
            "f_seats": 213,
            "r_seats": 35,
            "cargo_demand": 65687
        }
    },
    {
        "iata": "EAS",
        "icao": "LESO",
        "airportName": "San Sebastian Airport",
        "location_country": "Spain",
        "location_city": "Basque Country",
        "location_state": "San Sebastian",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.35649872",
        "longitude": "-1.790609956",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4780",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116267,
            "s_seats": 35835,
            "j_seats": 5419,
            "f_seats": 12593,
            "r_seats": 3574,
            "cargo_demand": 71305
        }
    },
    {
        "iata": "EAT",
        "icao": "KEAT",
        "airportName": "Pangborn Memorial Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Wenatchee",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.39889908",
        "longitude": "-120.2070007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4460",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57160,
            "s_seats": 44991,
            "j_seats": 36834,
            "f_seats": 16737,
            "r_seats": 9965,
            "cargo_demand": 83809
        }
    },
    {
        "iata": "EAU",
        "icao": "KEAU",
        "airportName": "Chippewa Valley Regional Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Eau Claire",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.86579895",
        "longitude": "-91.48429871",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8101",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4999",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 173386,
            "s_seats": 39824,
            "j_seats": 27618,
            "f_seats": 16524,
            "r_seats": 7477,
            "cargo_demand": 97222
        }
    },
    {
        "iata": "EBA",
        "icao": "LIRJ",
        "airportName": "Marina di Campo Airport",
        "location_country": "Italy",
        "location_city": "Tuscany",
        "location_state": "Elba Island",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "42.7603",
        "longitude": "10.2394",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3114",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57018,
            "s_seats": 49197,
            "j_seats": 37365,
            "f_seats": 13945,
            "r_seats": 8274,
            "cargo_demand": 260858
        }
    },
    {
        "iata": "EBB",
        "icao": "HUEN",
        "airportName": "Entebbe International Airport",
        "location_country": "Uganda",
        "location_city": "Entebbe",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "0.042386",
        "longitude": "32.443501",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7900",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "12000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166466,
            "s_seats": 76696,
            "j_seats": 157333,
            "f_seats": 46862,
            "r_seats": 10000,
            "cargo_demand": 6155525
        }
    },
    {
        "iata": "EBD",
        "icao": "HSOB",
        "airportName": "El Obeid Airport",
        "location_country": "Sudan",
        "location_city": "El Obeid",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.15320015",
        "longitude": "30.23270035",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174218,
            "s_seats": 41526,
            "j_seats": 18834,
            "f_seats": 9428,
            "r_seats": 5897,
            "cargo_demand": 423538
        }
    },
    {
        "iata": "EBG",
        "icao": "SKEB",
        "airportName": "El Bagre Airport",
        "location_country": "Colombia",
        "location_city": "El Bagre",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.59647",
        "longitude": "-74.8089",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4310",
                "runway_width": "40",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111233,
            "s_seats": 35105,
            "j_seats": 58087,
            "f_seats": 2995,
            "r_seats": 9660,
            "cargo_demand": 168544
        }
    },
    {
        "iata": "EBH",
        "icao": "DAOY",
        "airportName": "El Bayadh Airport",
        "location_country": "Algeria",
        "location_city": "El Bayadh",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.72166667",
        "longitude": "1.0925",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1470,
            "s_seats": 2680,
            "j_seats": 1824,
            "f_seats": 400,
            "r_seats": 188,
            "cargo_demand": 77348
        }
    },
    {
        "iata": "EBJ",
        "icao": "EKEB",
        "airportName": "Esbjerg Airport",
        "location_country": "Denmark",
        "location_city": "Esbjerg",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "55.525902",
        "longitude": "8.5534",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8527",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134060,
            "s_seats": 46580,
            "j_seats": 35715,
            "f_seats": 12190,
            "r_seats": 5753,
            "cargo_demand": 289272
        }
    },
    {
        "iata": "EBL",
        "icao": "ORER",
        "airportName": "Erbil International Airport",
        "location_country": "Iraq",
        "location_city": "Erbil",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.23759842",
        "longitude": "43.96319962",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "9197",
                "runway_width": "95",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15L/33R",
                "runway_length": "4085",
                "runway_width": "105",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "18/36",
                "runway_length": "15748",
                "runway_width": "246",
                "runway_material": "CONC"
            }
        ],
        "demand": {
            "y_seats": 159958,
            "s_seats": 30686,
            "j_seats": 29109,
            "f_seats": 16600,
            "r_seats": 3286,
            "cargo_demand": 390312
        }
    },
    {
        "iata": "EBM",
        "icao": "DTTR",
        "airportName": "El Borma Airport",
        "location_country": "Tunisia",
        "location_city": "El Borma",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.70429993",
        "longitude": "9.254619598",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 126861,
            "s_seats": 37562,
            "j_seats": 10238,
            "f_seats": 12962,
            "r_seats": 6318,
            "cargo_demand": 351261
        }
    },
    {
        "iata": "EBS",
        "icao": "KEBS",
        "airportName": "Webster City Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Webster City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.43659973",
        "longitude": "-93.86889648",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2655",
                "runway_width": "90",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4007",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1365,
            "s_seats": 2047,
            "j_seats": 1513,
            "f_seats": 83,
            "r_seats": 152,
            "cargo_demand": 33565
        }
    },
    {
        "iata": "EBU",
        "icao": "LFMH",
        "airportName": "Saint-etienne-Boutheon Airport",
        "location_country": "France",
        "location_city": "Rhone-Alpes",
        "location_state": "Saint-etienne",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.54059982",
        "longitude": "4.296390057",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71648,
            "s_seats": 32379,
            "j_seats": 22928,
            "f_seats": 1912,
            "r_seats": 7729,
            "cargo_demand": 87412
        }
    },
    {
        "iata": "ECG",
        "icao": "KECG",
        "airportName": "Elizabeth City Regional Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Elizabeth City",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.260601",
        "longitude": "-76.174599",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4518",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "10/28",
                "runway_length": "7219",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 68269,
            "s_seats": 44756,
            "j_seats": 9399,
            "f_seats": 17956,
            "r_seats": 9752,
            "cargo_demand": 192706
        }
    },
    {
        "iata": "ECH",
        "icao": "YECH",
        "airportName": "Echuca Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Echuca",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-36.15719986",
        "longitude": "144.7619934",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "1674",
                "runway_width": "59",
                "runway_material": "GVL"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3615",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59740,
            "s_seats": 46701,
            "j_seats": 8756,
            "f_seats": 6807,
            "r_seats": 5604,
            "cargo_demand": 297156
        }
    },
    {
        "iata": "ECN",
        "icao": "LCEN",
        "airportName": "Ercan International Airport",
        "location_country": "Northern Cyprus",
        "location_city": "Nicosia (Lefkosa)",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.15470123",
        "longitude": "33.49610138",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9039",
                "runway_width": "148",
                "runway_material": "concrete"
            },
            {
                "runway_name": "16/34",
                "runway_length": "906",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 197481,
            "s_seats": 47835,
            "j_seats": 10607,
            "f_seats": 15199,
            "r_seats": 8002,
            "cargo_demand": 445916
        }
    },
    {
        "iata": "ECP",
        "icao": "KECP",
        "airportName": "Northwest Florida Beaches International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Panama City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.357106",
        "longitude": "-85.795414",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "10000",
                "runway_width": "",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 182551,
            "s_seats": 40686,
            "j_seats": 6839,
            "f_seats": 19434,
            "r_seats": 6670,
            "cargo_demand": 458976
        }
    },
    {
        "iata": "ECS",
        "icao": "KECS",
        "airportName": "Mondell Field",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Newcastle",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.885399",
        "longitude": "-104.318001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2220",
                "runway_width": "50",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5300",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2666",
                "runway_width": "40",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2006,
            "s_seats": 2946,
            "j_seats": 1917,
            "f_seats": 380,
            "r_seats": 94,
            "cargo_demand": 10582
        }
    },
    {
        "iata": "EDB",
        "icao": "HSDB",
        "airportName": "El Debba Airport",
        "location_country": "Sudan",
        "location_city": "El Debba",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.0146",
        "longitude": "30.9595",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4595",
                "runway_width": "100",
                "runway_material": "graded earth"
            }
        ],
        "demand": {
            "y_seats": 3482,
            "s_seats": 2770,
            "j_seats": 1219,
            "f_seats": 111,
            "r_seats": 174,
            "cargo_demand": 93249
        }
    },
    {
        "iata": "EDE",
        "icao": "KEDE",
        "airportName": "Northeastern Regional Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Edenton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.02769852",
        "longitude": "-76.56710052",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "3757",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2890,
            "s_seats": 1588,
            "j_seats": 1586,
            "f_seats": 379,
            "r_seats": 41,
            "cargo_demand": 6432
        }
    },
    {
        "iata": "EDF",
        "icao": "PAED",
        "airportName": "Elmendorf Air Force Base",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Anchorage",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "61.25099945",
        "longitude": "-149.8070068",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10000",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166112,
            "s_seats": 33365,
            "j_seats": 17382,
            "f_seats": 14035,
            "r_seats": 5372,
            "cargo_demand": 462993
        }
    },
    {
        "iata": "EDI",
        "icao": "EGPH",
        "airportName": "Edinburgh Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Edinburgh",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "55.95000076",
        "longitude": "-3.372499943",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8386",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "2621",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5730",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 457406,
            "s_seats": 87797,
            "j_seats": 116164,
            "f_seats": 21215,
            "r_seats": 10000,
            "cargo_demand": 17520457
        }
    },
    {
        "iata": "EDK",
        "icao": "KEQA",
        "airportName": "Captain Jack Thomas/El Dorado Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "El Dorado",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.77410126",
        "longitude": "-96.81759644",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4205",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4200",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3758,
            "s_seats": 1792,
            "j_seats": 1399,
            "f_seats": 227,
            "r_seats": 102,
            "cargo_demand": 94725
        }
    },
    {
        "iata": "EDL",
        "icao": "HKEL",
        "airportName": "Eldoret International Airport",
        "location_country": "Kenya",
        "location_city": "Eldoret",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.404457986",
        "longitude": "35.23889923",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11480",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54007,
            "s_seats": 29667,
            "j_seats": 21073,
            "f_seats": 1128,
            "r_seats": 7291,
            "cargo_demand": 416398
        }
    },
    {
        "iata": "EDM",
        "icao": "LFRI",
        "airportName": "La Roche-sur-Yon Aerodrome",
        "location_country": "France",
        "location_city": "Pays de la Loire",
        "location_state": "La Roche-sur-Yon",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.70190048",
        "longitude": "-1.378630042",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5085",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97873,
            "s_seats": 27410,
            "j_seats": 58533,
            "f_seats": 10778,
            "r_seats": 9753,
            "cargo_demand": 382963
        }
    },
    {
        "iata": "EDO",
        "icao": "LTFD",
        "airportName": "Balikesir Koca Seyit Airport",
        "location_country": "Turkey",
        "location_city": "Edremit",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.55459976",
        "longitude": "27.01379967",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9842",
                "runway_width": "147",
                "runway_material": "CONCRETE"
            }
        ],
        "demand": {
            "y_seats": 146556,
            "s_seats": 49866,
            "j_seats": 15960,
            "f_seats": 6893,
            "r_seats": 5589,
            "cargo_demand": 330740
        }
    },
    {
        "iata": "EDR",
        "icao": "YPMP",
        "airportName": "Edward River Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Pormpuraaw",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.896451",
        "longitude": "141.60908",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4462",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3075,
            "s_seats": 1589,
            "j_seats": 1143,
            "f_seats": 18,
            "r_seats": 141,
            "cargo_demand": 91808
        }
    },
    {
        "iata": "EDW",
        "icao": "KEDW",
        "airportName": "Edwards Air Force Base",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Edwards",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "34.905399",
        "longitude": "-117.884003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "12000",
                "runway_width": "200",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "15024",
                "runway_width": "300",
                "runway_material": "CONC"
            },
            {
                "runway_name": "06/24",
                "runway_length": "8000",
                "runway_width": "50",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 213767,
            "s_seats": 79191,
            "j_seats": 81543,
            "f_seats": 35621,
            "r_seats": 10000,
            "cargo_demand": 18077577
        }
    },
    {
        "iata": "EED",
        "icao": "KEED",
        "airportName": "Needles Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Needles",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.7663002",
        "longitude": "-114.6230011",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4235",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5005",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146914,
            "s_seats": 39637,
            "j_seats": 38983,
            "f_seats": 12007,
            "r_seats": 4819,
            "cargo_demand": 433627
        }
    },
    {
        "iata": "EEK",
        "icao": "PAEE",
        "airportName": "Eek Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Eek",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "60.21367264",
        "longitude": "-162.0438843",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3243",
                "runway_width": "60",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 1286,
            "s_seats": 2243,
            "j_seats": 1756,
            "f_seats": 321,
            "r_seats": 153,
            "cargo_demand": 45256
        }
    },
    {
        "iata": "EEN",
        "icao": "KEEN",
        "airportName": "Dillant-Hopkins Airport",
        "location_country": "United States",
        "location_city": "New Hampshire",
        "location_state": "Keene",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.898399",
        "longitude": "-72.270798",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6201",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 91529,
            "s_seats": 44272,
            "j_seats": 31332,
            "f_seats": 10378,
            "r_seats": 8167,
            "cargo_demand": 478795
        }
    },
    {
        "iata": "EFD",
        "icao": "KEFD",
        "airportName": "Ellington Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Houston",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "29.6072998",
        "longitude": "-95.15879822",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "4609",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "9001",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 136869,
            "s_seats": 37823,
            "j_seats": 12784,
            "f_seats": 8103,
            "r_seats": 3829,
            "cargo_demand": 48067
        }
    },
    {
        "iata": "EFK",
        "icao": "KEFK",
        "airportName": "Newport State Airport",
        "location_country": "United States",
        "location_city": "Vermont",
        "location_state": "Newport",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.888802",
        "longitude": "-72.229202",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3431,
            "s_seats": 2478,
            "j_seats": 1230,
            "f_seats": 442,
            "r_seats": 196,
            "cargo_demand": 51445
        }
    },
    {
        "iata": "EFL",
        "icao": "LGKF",
        "airportName": "Cephalonia International Airport",
        "location_country": "Greece",
        "location_city": "Cephalonia",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "38.12009811",
        "longitude": "20.50049973",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7992",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52313,
            "s_seats": 30482,
            "j_seats": 11516,
            "f_seats": 17422,
            "r_seats": 4876,
            "cargo_demand": 373637
        }
    },
    {
        "iata": "EFW",
        "icao": "KEFW",
        "airportName": "Jefferson Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Jefferson",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.0102005",
        "longitude": "-94.34259796",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3200",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1715",
                "runway_width": "150",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2894,
            "s_seats": 1878,
            "j_seats": 1900,
            "f_seats": 35,
            "r_seats": 149,
            "cargo_demand": 57829
        }
    },
    {
        "iata": "EGA",
        "icao": "AYEN",
        "airportName": "Engati Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Engati",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.9179",
        "longitude": "146.1053",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "1312",
                "runway_width": "98",
                "runway_material": "Grassed brown silt clay"
            }
        ],
        "demand": {
            "y_seats": 2307,
            "s_seats": 2832,
            "j_seats": 1225,
            "f_seats": 352,
            "r_seats": 157,
            "cargo_demand": 8434
        }
    },
    {
        "iata": "EGC",
        "icao": "LFBE",
        "airportName": "Bergerac Dordogne Perigord Airport",
        "location_country": "France",
        "location_city": "Aquitaine",
        "location_state": "Bergerac",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "44.82529831",
        "longitude": "0.518611014",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7234",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 65862,
            "s_seats": 34108,
            "j_seats": 9594,
            "f_seats": 18971,
            "r_seats": 7089,
            "cargo_demand": 478792
        }
    },
    {
        "iata": "EGE",
        "icao": "KEGE",
        "airportName": "Eagle County Regional Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Eagle / Vail",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.64260101",
        "longitude": "-106.9179993",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "5013",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67643,
            "s_seats": 35107,
            "j_seats": 5698,
            "f_seats": 18461,
            "r_seats": 6738,
            "cargo_demand": 279484
        }
    },
    {
        "iata": "EGI",
        "icao": "KEGI",
        "airportName": "Duke Field",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Crestview",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.65040016",
        "longitude": "-86.52290344",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "180/360",
                "runway_length": "3500",
                "runway_width": "60",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 113152,
            "s_seats": 49714,
            "j_seats": 32127,
            "f_seats": 13571,
            "r_seats": 8152,
            "cargo_demand": 310655
        }
    },
    {
        "iata": "EGM",
        "icao": "AGGS",
        "airportName": "Seghe Airport",
        "location_country": "Solomon Islands",
        "location_city": "Western Province",
        "location_state": "Seghe",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.578889847",
        "longitude": "157.8760071",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3002",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1786,
            "s_seats": 2703,
            "j_seats": 1231,
            "f_seats": 415,
            "r_seats": 17,
            "cargo_demand": 99143
        }
    },
    {
        "iata": "EGN",
        "icao": "HSGN",
        "airportName": "Geneina Airport",
        "location_country": "Sudan",
        "location_city": "Geneina",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "13.48169994",
        "longitude": "22.46719933",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5305",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6194",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1771,
            "s_seats": 2678,
            "j_seats": 1930,
            "f_seats": 31,
            "r_seats": 183,
            "cargo_demand": 50397
        }
    },
    {
        "iata": "EGO",
        "icao": "UUOB",
        "airportName": "Belgorod International Airport",
        "location_country": "Russia",
        "location_city": "Belgorod Oblast",
        "location_state": "Belgorod",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.64379883",
        "longitude": "36.59009933",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8202",
                "runway_width": "138",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7930",
                "runway_width": "220",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 184322,
            "s_seats": 35256,
            "j_seats": 49583,
            "f_seats": 1483,
            "r_seats": 7524,
            "cargo_demand": 158833
        }
    },
    {
        "iata": "EGS",
        "icao": "BIEG",
        "airportName": "Egilssta\u00f0ir Airport",
        "location_country": "Iceland",
        "location_city": "Egilssta\u00f0ir",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "65.28330231",
        "longitude": "-14.40139961",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150466,
            "s_seats": 46538,
            "j_seats": 21210,
            "f_seats": 2823,
            "r_seats": 8790,
            "cargo_demand": 186170
        }
    },
    {
        "iata": "EGV",
        "icao": "KEGV",
        "airportName": "Eagle River Union Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Eagle River",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.93230057",
        "longitude": "-89.26830292",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5000",
                "runway_width": "76",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3400",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1993,
            "s_seats": 2224,
            "j_seats": 1703,
            "f_seats": 305,
            "r_seats": 187,
            "cargo_demand": 14728
        }
    },
    {
        "iata": "EGX",
        "icao": "PAII",
        "airportName": "Egegik Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Egegik",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "58.184386",
        "longitude": "-157.374873",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1500",
                "runway_width": "75",
                "runway_material": "GVL"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5600",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 72286,
            "s_seats": 26618,
            "j_seats": 42865,
            "f_seats": 17889,
            "r_seats": 5873,
            "cargo_demand": 428143
        }
    },
    {
        "iata": "EHL",
        "icao": "SAVB",
        "airportName": "El Bolson Airport",
        "location_country": "Argentina",
        "location_city": "Rio Negro",
        "location_state": "El Bolson",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-41.94319916",
        "longitude": "-71.53230286",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53361,
            "s_seats": 45281,
            "j_seats": 31042,
            "f_seats": 17431,
            "r_seats": 9753,
            "cargo_demand": 278164
        }
    },
    {
        "iata": "EHM",
        "icao": "PAEH",
        "airportName": "Cape Newenham LRRS Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Cape Newenham",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "58.64640045",
        "longitude": "-162.0630035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3950",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 110071,
            "s_seats": 49767,
            "j_seats": 27123,
            "f_seats": 6768,
            "r_seats": 8142,
            "cargo_demand": 163104
        }
    },
    {
        "iata": "EIB",
        "icao": "EDGE",
        "airportName": "Eisenach-Kindel Airport",
        "location_country": "Germany",
        "location_city": "Thuringia",
        "location_state": "Eisenach",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.991604",
        "longitude": "10.47973",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5643",
                "runway_width": "180",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 63851,
            "s_seats": 25038,
            "j_seats": 10285,
            "f_seats": 11485,
            "r_seats": 6354,
            "cargo_demand": 479255
        }
    },
    {
        "iata": "EIL",
        "icao": "PAEI",
        "airportName": "Eielson Air Force Base",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Fairbanks",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "64.66570282",
        "longitude": "-147.102005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "14530",
                "runway_width": "150",
                "runway_material": "CONC"
            }
        ],
        "demand": {
            "y_seats": 177670,
            "s_seats": 41191,
            "j_seats": 28214,
            "f_seats": 15721,
            "r_seats": 6224,
            "cargo_demand": 231830
        }
    },
    {
        "iata": "EIN",
        "icao": "EHEH",
        "airportName": "Eindhoven Airport",
        "location_country": "Netherlands",
        "location_city": "Eindhoven",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "51.45009995",
        "longitude": "5.374529839",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 348626,
            "s_seats": 94213,
            "j_seats": 116464,
            "f_seats": 61567,
            "r_seats": 10000,
            "cargo_demand": 7915049
        }
    },
    {
        "iata": "EIS",
        "icao": "TUPJ",
        "airportName": "Terrance B. Lettsome International Airport",
        "location_country": "British Overseas Territory of Virgin Islands",
        "location_city": "Tortola",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.445492",
        "longitude": "-64.541707",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4642",
                "runway_width": "89",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59810,
            "s_seats": 49934,
            "j_seats": 28525,
            "f_seats": 19884,
            "r_seats": 7163,
            "cargo_demand": 125135
        }
    },
    {
        "iata": "EIY",
        "icao": "LLEY",
        "airportName": "Ein Yahav Airfield",
        "location_country": "Israel",
        "location_city": "Ein Yahav",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "30.62170029",
        "longitude": "35.20330048",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3943",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2531,
            "s_seats": 2952,
            "j_seats": 1115,
            "f_seats": 264,
            "r_seats": 130,
            "cargo_demand": 90608
        }
    },
    {
        "iata": "EJA",
        "icao": "SKEJ",
        "airportName": "Yariguies Airport",
        "location_country": "Colombia",
        "location_city": "Barrancabermeja",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.02433",
        "longitude": "-73.8068",
        "region": "SA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5905",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196915,
            "s_seats": 26230,
            "j_seats": 41452,
            "f_seats": 10208,
            "r_seats": 5923,
            "cargo_demand": 162464
        }
    },
    {
        "iata": "EJH",
        "icao": "OEWJ",
        "airportName": "Al Wajh Domestic Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Al Wajh",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.198601",
        "longitude": "36.476398",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57599,
            "s_seats": 34317,
            "j_seats": 14495,
            "f_seats": 3855,
            "r_seats": 9551,
            "cargo_demand": 218278
        }
    },
    {
        "iata": "EKA",
        "icao": "KEKA",
        "airportName": "Murray Field",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Eureka",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.80339813",
        "longitude": "-124.112999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2028",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 183579,
            "s_seats": 30302,
            "j_seats": 45790,
            "f_seats": 2220,
            "r_seats": 9441,
            "cargo_demand": 62466
        }
    },
    {
        "iata": "EKI",
        "icao": "KEKM",
        "airportName": "Elkhart Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Elkhart",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.7193985",
        "longitude": "-86.00319672",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2500",
                "runway_width": "100",
                "runway_material": "GRE"
            },
            {
                "runway_name": "09/27",
                "runway_length": "6500",
                "runway_width": "120",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2805,
            "s_seats": 2775,
            "j_seats": 1861,
            "f_seats": 182,
            "r_seats": 154,
            "cargo_demand": 33142
        }
    },
    {
        "iata": "EKN",
        "icao": "KEKN",
        "airportName": "Elkins-Randolph County Airport",
        "location_country": "United States",
        "location_city": "West Virginia",
        "location_state": "Elkins",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.88940048",
        "longitude": "-79.85710144",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4501",
                "runway_width": "140",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4543",
                "runway_width": "140",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174660,
            "s_seats": 28958,
            "j_seats": 20103,
            "f_seats": 7627,
            "r_seats": 3264,
            "cargo_demand": 86381
        }
    },
    {
        "iata": "EKO",
        "icao": "KEKO",
        "airportName": "Elko Regional Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Elko",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.82490158",
        "longitude": "-115.7919998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7214",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2871",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89173,
            "s_seats": 44123,
            "j_seats": 45507,
            "f_seats": 2539,
            "r_seats": 9604,
            "cargo_demand": 141870
        }
    },
    {
        "iata": "EKT",
        "icao": "ESSU",
        "airportName": "Eskilstuna Airport",
        "location_country": "Sweden",
        "location_city": "Eskilstuna",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "59.35110092",
        "longitude": "16.70840073",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6187",
                "runway_width": "115",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 137931,
            "s_seats": 33595,
            "j_seats": 28569,
            "f_seats": 5688,
            "r_seats": 3620,
            "cargo_demand": 55393
        }
    },
    {
        "iata": "EKX",
        "icao": "KEKX",
        "airportName": "Elizabethtown Regional Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Elizabethtown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.686001",
        "longitude": "-85.925003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2776,
            "s_seats": 1876,
            "j_seats": 1127,
            "f_seats": 257,
            "r_seats": 18,
            "cargo_demand": 10265
        }
    },
    {
        "iata": "ELA",
        "icao": "KELA",
        "airportName": "Eagle Lake Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Eagle Lake",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "29.60059929",
        "longitude": "-96.32189941",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3801",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2217,
            "s_seats": 2789,
            "j_seats": 1658,
            "f_seats": 319,
            "r_seats": 194,
            "cargo_demand": 39507
        }
    },
    {
        "iata": "ELB",
        "icao": "SKBC",
        "airportName": "Las Flores Airport",
        "location_country": "Colombia",
        "location_city": "El Banco",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.04554",
        "longitude": "-73.9749",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3985",
                "runway_width": "93",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53544,
            "s_seats": 39347,
            "j_seats": 50521,
            "f_seats": 3375,
            "r_seats": 4326,
            "cargo_demand": 366226
        }
    },
    {
        "iata": "ELC",
        "icao": "YELD",
        "airportName": "Elcho Island Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Elcho Island",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.01939964",
        "longitude": "135.5709991",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4724",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134512,
            "s_seats": 42495,
            "j_seats": 10961,
            "f_seats": 12913,
            "r_seats": 8082,
            "cargo_demand": 223620
        }
    },
    {
        "iata": "ELD",
        "icao": "KELD",
        "airportName": "South Arkansas Regional Airport at Goodwin Field",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "El Dorado",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.22100067",
        "longitude": "-92.81330109",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6600",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5100",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3733",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 178732,
            "s_seats": 35548,
            "j_seats": 41065,
            "f_seats": 4722,
            "r_seats": 4310,
            "cargo_demand": 484567
        }
    },
    {
        "iata": "ELF",
        "icao": "HSFS",
        "airportName": "El Fasher Airport",
        "location_country": "Sudan",
        "location_city": "El Fasher",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.61489964",
        "longitude": "25.32460022",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9744",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5010",
                "runway_width": "148",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 108212,
            "s_seats": 28347,
            "j_seats": 51263,
            "f_seats": 15839,
            "r_seats": 9122,
            "cargo_demand": 131853
        }
    },
    {
        "iata": "ELG",
        "icao": "DAUE",
        "airportName": "El Golea Airport",
        "location_country": "Algeria",
        "location_city": "El Golea",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.571301",
        "longitude": "2.85959",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5906",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157589,
            "s_seats": 29884,
            "j_seats": 28960,
            "f_seats": 5690,
            "r_seats": 3608,
            "cargo_demand": 300992
        }
    },
    {
        "iata": "ELH",
        "icao": "MYEH",
        "airportName": "North Eleuthera Airport",
        "location_country": "Bahamas",
        "location_city": "Eleuthera Island",
        "location_state": "North Eleuthera",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "25.47489929",
        "longitude": "-76.6835022",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6020",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190723,
            "s_seats": 41727,
            "j_seats": 57019,
            "f_seats": 1234,
            "r_seats": 6789,
            "cargo_demand": 360120
        }
    },
    {
        "iata": "ELI",
        "icao": "PFEL",
        "airportName": "Elim Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Elim",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "64.61470032",
        "longitude": "-162.2720032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3401",
                "runway_width": "60",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 1983,
            "s_seats": 1968,
            "j_seats": 1956,
            "f_seats": 471,
            "r_seats": 42,
            "cargo_demand": 24558
        }
    },
    {
        "iata": "ELK",
        "icao": "KELK",
        "airportName": "Elk City Regional Business Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Elk City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.43080139",
        "longitude": "-99.39430237",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5400",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1294,
            "s_seats": 1950,
            "j_seats": 1009,
            "f_seats": 153,
            "r_seats": 114,
            "cargo_demand": 46992
        }
    },
    {
        "iata": "ELM",
        "icao": "KELM",
        "airportName": "Elmira/Corning Regional Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Elmira / Corning",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.15990067",
        "longitude": "-76.89160156",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2017",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "06/24",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5404",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152178,
            "s_seats": 26630,
            "j_seats": 31192,
            "f_seats": 7946,
            "r_seats": 4910,
            "cargo_demand": 108411
        }
    },
    {
        "iata": "ELN",
        "icao": "KELN",
        "airportName": "Bowers Field",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Ellensburg",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "47.03300095",
        "longitude": "-120.5309982",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5590",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4300",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2390,
            "s_seats": 2829,
            "j_seats": 1723,
            "f_seats": 309,
            "r_seats": 26,
            "cargo_demand": 11285
        }
    },
    {
        "iata": "ELO",
        "icao": "SATD",
        "airportName": "Eldorado Airport",
        "location_country": "Argentina",
        "location_city": "Misiones",
        "location_state": "Eldorado",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.39749908",
        "longitude": "-54.5746994",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "4695",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2036,
            "s_seats": 2763,
            "j_seats": 1788,
            "f_seats": 198,
            "r_seats": 39,
            "cargo_demand": 50872
        }
    },
    {
        "iata": "ELP",
        "icao": "KELP",
        "airportName": "El Paso International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "El Paso",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.80719948",
        "longitude": "-106.3779984",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "12020",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08L/26R",
                "runway_length": "5493",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "9025",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180098,
            "s_seats": 36933,
            "j_seats": 45413,
            "f_seats": 3744,
            "r_seats": 7242,
            "cargo_demand": 135001
        }
    },
    {
        "iata": "ELQ",
        "icao": "OEGS",
        "airportName": "Prince Nayef bin Abdulaziz Regional Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Buraidah",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.30279922",
        "longitude": "43.7743988",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "9843",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144548,
            "s_seats": 33506,
            "j_seats": 45749,
            "f_seats": 16600,
            "r_seats": 4017,
            "cargo_demand": 391870
        }
    },
    {
        "iata": "ELS",
        "icao": "FAEL",
        "airportName": "East London Airport",
        "location_country": "South Africa",
        "location_city": "East London",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.03559875",
        "longitude": "27.82589912",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6362",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58477,
            "s_seats": 32690,
            "j_seats": 40556,
            "f_seats": 12747,
            "r_seats": 3427,
            "cargo_demand": 475823
        }
    },
    {
        "iata": "ELT",
        "icao": "HETR",
        "airportName": "El Tor Airport",
        "location_country": "Egypt",
        "location_city": "El Tor",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.209",
        "longitude": "33.6455",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1481,
            "s_seats": 2598,
            "j_seats": 1119,
            "f_seats": 469,
            "r_seats": 18,
            "cargo_demand": 10905
        }
    },
    {
        "iata": "ELU",
        "icao": "DAUO",
        "airportName": "Guemar Airport",
        "location_country": "Algeria",
        "location_city": "El Oued",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.51139832",
        "longitude": "6.776790142",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124077,
            "s_seats": 33652,
            "j_seats": 43237,
            "f_seats": 8460,
            "r_seats": 8132,
            "cargo_demand": 418864
        }
    },
    {
        "iata": "ELY",
        "icao": "KELY",
        "airportName": "Ely Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Ely",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.29970169",
        "longitude": "-114.8420029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4814",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6018",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125480,
            "s_seats": 42376,
            "j_seats": 48661,
            "f_seats": 16931,
            "r_seats": 6123,
            "cargo_demand": 117205
        }
    },
    {
        "iata": "ELZ",
        "icao": "KELZ",
        "airportName": "Wellsville Municipal Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Wellsville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "\"Tarantine Field\"\"\"",
        "longitude": "42.10950089",
        "region": "2124",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5302",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1731,
            "s_seats": 2099,
            "j_seats": 1150,
            "f_seats": 271,
            "r_seats": 27,
            "cargo_demand": 41332
        }
    },
    {
        "iata": "EMA",
        "icao": "EGNX",
        "airportName": "East Midlands Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Nottingham / Leicester / Derby",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "52.83110046",
        "longitude": "-1.328060031",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9491",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194890,
            "s_seats": 74371,
            "j_seats": 100648,
            "f_seats": 43598,
            "r_seats": 10000,
            "cargo_demand": 5550902
        }
    },
    {
        "iata": "EMD",
        "icao": "YEML",
        "airportName": "Emerald Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Emerald",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.56749916",
        "longitude": "148.1790009",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6234",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3038",
                "runway_width": "59",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 58237,
            "s_seats": 44666,
            "j_seats": 30191,
            "f_seats": 10976,
            "r_seats": 8014,
            "cargo_demand": 462849
        }
    },
    {
        "iata": "EME",
        "icao": "EDWE",
        "airportName": "Emden Airport",
        "location_country": "Germany",
        "location_city": "Lower Saxony",
        "location_state": "Emden",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "53.391109",
        "longitude": "7.2275",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1380,
            "s_seats": 2095,
            "j_seats": 1818,
            "f_seats": 151,
            "r_seats": 8,
            "cargo_demand": 99242
        }
    },
    {
        "iata": "EMK",
        "icao": "PAEM",
        "airportName": "Emmonak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Emmonak",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "62.78609848",
        "longitude": "-164.4909973",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4601",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 90449,
            "s_seats": 45956,
            "j_seats": 24339,
            "f_seats": 4084,
            "r_seats": 4559,
            "cargo_demand": 177881
        }
    },
    {
        "iata": "EML",
        "icao": "LSME",
        "airportName": "Emmen Air Base",
        "location_country": "Switzerland",
        "location_city": "Emmen",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.092444",
        "longitude": "8.305184",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8208",
                "runway_width": "130",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68721,
            "s_seats": 33271,
            "j_seats": 40310,
            "f_seats": 15935,
            "r_seats": 7382,
            "cargo_demand": 326439
        }
    },
    {
        "iata": "EMM",
        "icao": "KEMM",
        "airportName": "Kemmerer Municipal Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Kemmerer",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.82410049",
        "longitude": "-110.5569992",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2850",
                "runway_width": "60",
                "runway_material": "CON"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3250",
                "runway_width": "60",
                "runway_material": "GVL"
            },
            {
                "runway_name": "16/34",
                "runway_length": "8200",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3506,
            "s_seats": 2655,
            "j_seats": 1562,
            "f_seats": 268,
            "r_seats": 107,
            "cargo_demand": 44662
        }
    },
    {
        "iata": "EMN",
        "icao": "GQNI",
        "airportName": "Nema Airport",
        "location_country": "Mauritania",
        "location_city": "Nema",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "16.622",
        "longitude": "-7.3166",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6890",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2923,
            "s_seats": 2811,
            "j_seats": 1083,
            "f_seats": 263,
            "r_seats": 139,
            "cargo_demand": 97491
        }
    },
    {
        "iata": "EMO",
        "icao": "AYEO",
        "airportName": "Emo Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Emo",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.1234",
        "longitude": "148.0415",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "1804",
                "runway_width": "98",
                "runway_material": "Grassed yellow clay"
            }
        ],
        "demand": {
            "y_seats": 1686,
            "s_seats": 2752,
            "j_seats": 1147,
            "f_seats": 82,
            "r_seats": 90,
            "cargo_demand": 22769
        }
    },
    {
        "iata": "EMP",
        "icao": "KEMP",
        "airportName": "Emporia Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Emporia",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.33209991",
        "longitude": "-96.19120026",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4999",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "3875",
                "runway_width": "298",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3059,
            "s_seats": 2418,
            "j_seats": 1945,
            "f_seats": 308,
            "r_seats": 55,
            "cargo_demand": 7143
        }
    },
    {
        "iata": "EMS",
        "icao": "AYEB",
        "airportName": "Embessa Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Embessa",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.447",
        "longitude": "148.7628",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "1640",
                "runway_width": "148",
                "runway_material": "Grassed black sand"
            }
        ],
        "demand": {
            "y_seats": 2583,
            "s_seats": 1600,
            "j_seats": 1702,
            "f_seats": 110,
            "r_seats": 24,
            "cargo_demand": 87273
        }
    },
    {
        "iata": "EMT",
        "icao": "KEMT",
        "airportName": "San Gabriel Valley Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "El Monte",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.086102",
        "longitude": "-118.035004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3995",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2074,
            "s_seats": 2493,
            "j_seats": 1640,
            "f_seats": 112,
            "r_seats": 61,
            "cargo_demand": 39037
        }
    },
    {
        "iata": "EMX",
        "icao": "SAVD",
        "airportName": "El Maiten Airport",
        "location_country": "Argentina",
        "location_city": "Chubut",
        "location_state": "El Maiten",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-42.02920151",
        "longitude": "-71.17250061",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3478",
                "runway_width": "98",
                "runway_material": "GRE"
            },
            {
                "runway_name": "2/20",
                "runway_length": "3150",
                "runway_width": "98",
                "runway_material": "GRE"
            },
            {
                "runway_name": "6/24",
                "runway_length": "4961",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2410,
            "s_seats": 1938,
            "j_seats": 1513,
            "f_seats": 258,
            "r_seats": 126,
            "cargo_demand": 8975
        }
    },
    {
        "iata": "ENA",
        "icao": "PAEN",
        "airportName": "Kenai Municipal Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kenai",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "60.57310104",
        "longitude": "-151.2449951",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "7576",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "2000",
                "runway_width": "60",
                "runway_material": "GVL"
            },
            {
                "runway_name": "01W/19W",
                "runway_length": "4600",
                "runway_width": "252",
                "runway_material": "WATER-E"
            }
        ],
        "demand": {
            "y_seats": 72900,
            "s_seats": 35226,
            "j_seats": 8957,
            "f_seats": 9750,
            "r_seats": 3968,
            "cargo_demand": 463704
        }
    },
    {
        "iata": "ENB",
        "icao": "YEEB",
        "airportName": "Eneabba Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Eneabba",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-29.83250046",
        "longitude": "115.2460022",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4429",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1173,
            "s_seats": 1580,
            "j_seats": 1623,
            "f_seats": 164,
            "r_seats": 110,
            "cargo_demand": 31758
        }
    },
    {
        "iata": "ENC",
        "icao": "LFSN",
        "airportName": "Nancy-Essey Airport",
        "location_country": "France",
        "location_city": "Lorraine",
        "location_state": "Nancy",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.69210052",
        "longitude": "6.230460167",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5249",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 75209,
            "s_seats": 46956,
            "j_seats": 33193,
            "f_seats": 8409,
            "r_seats": 3993,
            "cargo_demand": 325196
        }
    },
    {
        "iata": "END",
        "icao": "KEND",
        "airportName": "Vance Air Force Base",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Enid",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "36.33919907",
        "longitude": "-97.91649628",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4971",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17C/35C",
                "runway_length": "9202",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "5024",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "9202",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 193435,
            "s_seats": 87473,
            "j_seats": 116032,
            "f_seats": 35666,
            "r_seats": 10000,
            "cargo_demand": 14340271
        }
    },
    {
        "iata": "ENE",
        "icao": "WATE",
        "airportName": "H. Hasan Aroeboesman Airport",
        "location_country": "Indonesia",
        "location_city": "Ende",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.849289894",
        "longitude": "121.6610031",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5440",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3381,
            "s_seats": 2509,
            "j_seats": 1902,
            "f_seats": 431,
            "r_seats": 51,
            "cargo_demand": 45246
        }
    },
    {
        "iata": "ENF",
        "icao": "EFET",
        "airportName": "Enontekio Airport",
        "location_country": "Finland",
        "location_city": "Enontekio",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "68.36260223",
        "longitude": "23.42429924",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6565",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169222,
            "s_seats": 48404,
            "j_seats": 35511,
            "f_seats": 7778,
            "r_seats": 4101,
            "cargo_demand": 384358
        }
    },
    {
        "iata": "ENK",
        "icao": "EGAB",
        "airportName": "Enniskillen/St Angelo Airport",
        "location_country": "United Kingdom",
        "location_city": "Northern Ireland",
        "location_state": "Enniskillen",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "54.398899",
        "longitude": "-7.65167",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4350",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159093,
            "s_seats": 47250,
            "j_seats": 53510,
            "f_seats": 5769,
            "r_seats": 5131,
            "cargo_demand": 87750
        }
    },
    {
        "iata": "ENL",
        "icao": "KENL",
        "airportName": "Centralia Municipal Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Centralia",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.51509857",
        "longitude": "-89.0911026",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3300",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3551,
            "s_seats": 2306,
            "j_seats": 1047,
            "f_seats": 319,
            "r_seats": 81,
            "cargo_demand": 55270
        }
    },
    {
        "iata": "ENN",
        "icao": "PANN",
        "airportName": "Nenana Municipal Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Nenana",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "64.54730225",
        "longitude": "-149.0740051",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "4600",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "2520",
                "runway_width": "60",
                "runway_material": "GRS"
            },
            {
                "runway_name": "04W/22W",
                "runway_length": "3601",
                "runway_width": "100",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 89380,
            "s_seats": 47502,
            "j_seats": 11538,
            "f_seats": 13887,
            "r_seats": 6010,
            "cargo_demand": 466003
        }
    },
    {
        "iata": "ENO",
        "icao": "SGEN",
        "airportName": "Teniente Amin Ayub Gonzalez Airport",
        "location_country": "Paraguay",
        "location_city": "Encarnacion",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Mar",
        "airport_size": "small_airport",
        "latitude": "-27.227366",
        "longitude": "-55.837495",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/20",
                "runway_length": "7218",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1558,
            "s_seats": 1756,
            "j_seats": 1167,
            "f_seats": 461,
            "r_seats": 125,
            "cargo_demand": 13871
        }
    },
    {
        "iata": "ENS",
        "icao": "EHTW",
        "airportName": "Enschede Airport Twente",
        "location_country": "Netherlands",
        "location_city": "Enschede",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "52.275833",
        "longitude": "6.889167",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9801",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6558",
                "runway_width": "82",
                "runway_material": "sand and grass"
            }
        ],
        "demand": {
            "y_seats": 105274,
            "s_seats": 27058,
            "j_seats": 26521,
            "f_seats": 7576,
            "r_seats": 9766,
            "cargo_demand": 228425
        }
    },
    {
        "iata": "ENT",
        "icao": "PKMA",
        "airportName": "Enewetak Auxiliary Airfield",
        "location_country": "Marshall Islands",
        "location_city": "Enewetak",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "11.34070015",
        "longitude": "162.3280029",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7700",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1548,
            "s_seats": 2759,
            "j_seats": 1394,
            "f_seats": 420,
            "r_seats": 103,
            "cargo_demand": 8849
        }
    },
    {
        "iata": "ENU",
        "icao": "DNEN",
        "airportName": "Akanu Ibiam International Airport",
        "location_country": "Nigeria",
        "location_city": "Enugu",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.474269867",
        "longitude": "7.56196022",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7879",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143629,
            "s_seats": 31967,
            "j_seats": 48210,
            "f_seats": 9371,
            "r_seats": 8058,
            "cargo_demand": 134127
        }
    },
    {
        "iata": "ENV",
        "icao": "KENV",
        "airportName": "Wendover Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Wendover",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.71870041",
        "longitude": "-114.0309982",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147609,
            "s_seats": 46196,
            "j_seats": 9084,
            "f_seats": 8700,
            "r_seats": 6170,
            "cargo_demand": 435183
        }
    },
    {
        "iata": "ENW",
        "icao": "KENW",
        "airportName": "Kenosha Regional Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Kenosha",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.59569931",
        "longitude": "-87.92780304",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "5499",
                "runway_width": "100",
                "runway_material": "CONC-G"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "3302",
                "runway_width": "75",
                "runway_material": "ASPH-CONC-F"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4440",
                "runway_width": "100",
                "runway_material": "CONC-G"
            }
        ],
        "demand": {
            "y_seats": 192099,
            "s_seats": 45558,
            "j_seats": 22471,
            "f_seats": 5579,
            "r_seats": 6048,
            "cargo_demand": 427999
        }
    },
    {
        "iata": "EOH",
        "icao": "SKMD",
        "airportName": "Olaya Herrera Airport",
        "location_country": "Colombia",
        "location_city": "Medellin",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.220549",
        "longitude": "-75.590582",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8202",
                "runway_width": "125",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66781,
            "s_seats": 48448,
            "j_seats": 28327,
            "f_seats": 5567,
            "r_seats": 5441,
            "cargo_demand": 200927
        }
    },
    {
        "iata": "EOI",
        "icao": "EGED",
        "airportName": "Eday Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Eday",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "59.19060135",
        "longitude": "-2.772219896",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "1729",
                "runway_width": "59",
                "runway_material": "Graded Hardcore"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1896",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 125733,
            "s_seats": 46148,
            "j_seats": 34115,
            "f_seats": 11235,
            "r_seats": 3758,
            "cargo_demand": 258405
        }
    },
    {
        "iata": "EOK",
        "icao": "KEOK",
        "airportName": "Keokuk Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Keokuk",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.4598999",
        "longitude": "-91.42849731",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3576",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3310,
            "s_seats": 2925,
            "j_seats": 1528,
            "f_seats": 104,
            "r_seats": 141,
            "cargo_demand": 798
        }
    },
    {
        "iata": "EOR",
        "icao": "SVED",
        "airportName": "El Dorado Airport",
        "location_country": "Venezuela",
        "location_city": "El Dorado",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.71573",
        "longitude": "-61.638786",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4000",
                "runway_width": "125",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70397,
            "s_seats": 39311,
            "j_seats": 44556,
            "f_seats": 10573,
            "r_seats": 9876,
            "cargo_demand": 461904
        }
    },
    {
        "iata": "EOS",
        "icao": "KEOS",
        "airportName": "Neosho Hugh Robinson Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Neosho",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.81079865",
        "longitude": "-94.39170074",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3349,
            "s_seats": 2600,
            "j_seats": 1989,
            "f_seats": 30,
            "r_seats": 152,
            "cargo_demand": 81333
        }
    },
    {
        "iata": "EOZ",
        "icao": "SVEZ",
        "airportName": "Elorza Airport",
        "location_country": "Venezuela",
        "location_city": "Elorza",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.083333015",
        "longitude": "-69.53333282",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113037,
            "s_seats": 36979,
            "j_seats": 13879,
            "f_seats": 5368,
            "r_seats": 6920,
            "cargo_demand": 202309
        }
    },
    {
        "iata": "EPA",
        "icao": "SADP",
        "airportName": "El Palomar Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "El Palomar",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.6099",
        "longitude": "-58.6126",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6923",
                "runway_width": "147",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 100126,
            "s_seats": 27340,
            "j_seats": 31680,
            "f_seats": 18366,
            "r_seats": 7342,
            "cargo_demand": 323348
        }
    },
    {
        "iata": "EPH",
        "icao": "KEPH",
        "airportName": "Ephrata Municipal Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Ephrata",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "47.30759811",
        "longitude": "-119.5159988",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5500",
                "runway_width": "75",
                "runway_material": "ASPH-F"
            },
            {
                "runway_name": "04/22",
                "runway_length": "3467",
                "runway_width": "150",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3843",
                "runway_width": "60",
                "runway_material": "CON"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6630",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2089,
            "s_seats": 2477,
            "j_seats": 1888,
            "f_seats": 193,
            "r_seats": 181,
            "cargo_demand": 44733
        }
    },
    {
        "iata": "EPL",
        "icao": "LFSG",
        "airportName": "epinal - Mirecourt Airport",
        "location_country": "France",
        "location_city": "Lorraine",
        "location_state": "epinal / Mirecourt",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.325001",
        "longitude": "6.06998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 194713,
            "s_seats": 33910,
            "j_seats": 48908,
            "f_seats": 7071,
            "r_seats": 4079,
            "cargo_demand": 205985
        }
    },
    {
        "iata": "EPR",
        "icao": "YESP",
        "airportName": "Esperance Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Esperance",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.684399",
        "longitude": "121.822998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3865",
                "runway_width": "59",
                "runway_material": "GVL"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191968,
            "s_seats": 44426,
            "j_seats": 29942,
            "f_seats": 12944,
            "r_seats": 3205,
            "cargo_demand": 228823
        }
    },
    {
        "iata": "EPS",
        "icao": "MDAB",
        "airportName": "Arroyo Barril Airport",
        "location_country": "Dominican Republic",
        "location_city": "Samana",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "19.19860077",
        "longitude": "-69.42980194",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3830",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1346,
            "s_seats": 1684,
            "j_seats": 1873,
            "f_seats": 486,
            "r_seats": 79,
            "cargo_demand": 81916
        }
    },
    {
        "iata": "EPT",
        "icao": "AYEL",
        "airportName": "Eliptamin Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Eliptamin",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.0412",
        "longitude": "141.6779",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "1739",
                "runway_width": "98",
                "runway_material": "Grassed brown clay gravel"
            }
        ],
        "demand": {
            "y_seats": 2664,
            "s_seats": 1906,
            "j_seats": 1671,
            "f_seats": 340,
            "r_seats": 78,
            "cargo_demand": 47089
        }
    },
    {
        "iata": "EPU",
        "icao": "EEPU",
        "airportName": "PArnu Airport",
        "location_country": "Estonia",
        "location_city": "PArnu",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "58.41899872",
        "longitude": "24.4727993",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2621",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 103475,
            "s_seats": 27096,
            "j_seats": 46754,
            "f_seats": 14289,
            "r_seats": 8623,
            "cargo_demand": 152035
        }
    },
    {
        "iata": "EQS",
        "icao": "SAVE",
        "airportName": "Esquel Airport",
        "location_country": "Argentina",
        "location_city": "Chubut",
        "location_state": "Esquel",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-42.90800095",
        "longitude": "-71.13950348",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "7874",
                "runway_width": "131",
                "runway_material": "ASP"
            },
            {
                "runway_name": "8/26",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 60810,
            "s_seats": 40117,
            "j_seats": 58488,
            "f_seats": 3539,
            "r_seats": 4783,
            "cargo_demand": 443845
        }
    },
    {
        "iata": "ERB",
        "icao": "YERN",
        "airportName": "Pukatja Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Pukatja",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-26.26329994",
        "longitude": "132.1820068",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3838",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3037,
            "s_seats": 1569,
            "j_seats": 1573,
            "f_seats": 80,
            "r_seats": 146,
            "cargo_demand": 89453
        }
    },
    {
        "iata": "ERC",
        "icao": "LTCD",
        "airportName": "Erzincan Airport",
        "location_country": "Turkey",
        "location_city": "Erzincan",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.71020126",
        "longitude": "39.52700043",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5800",
                "runway_width": "137",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 118314,
            "s_seats": 33413,
            "j_seats": 10504,
            "f_seats": 12293,
            "r_seats": 5131,
            "cargo_demand": 86549
        }
    },
    {
        "iata": "ERF",
        "icao": "EDDE",
        "airportName": "Erfurt-Weimar Airport",
        "location_country": "Germany",
        "location_city": "Thuringia",
        "location_state": "Erfurt",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.97980118",
        "longitude": "10.95810032",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8530",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188148,
            "s_seats": 25759,
            "j_seats": 18161,
            "f_seats": 12197,
            "r_seats": 6101,
            "cargo_demand": 425107
        }
    },
    {
        "iata": "ERH",
        "icao": "GMFK",
        "airportName": "Moulay Ali Cherif Airport",
        "location_country": "Morocco",
        "location_city": "Errachidia",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct1",
        "airport_size": "medium_airport",
        "latitude": "31.94750023",
        "longitude": "-4.398330212",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188571,
            "s_seats": 32007,
            "j_seats": 36741,
            "f_seats": 6698,
            "r_seats": 5801,
            "cargo_demand": 69644
        }
    },
    {
        "iata": "ERI",
        "icao": "KERI",
        "airportName": "Erie International Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Erie",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "42.08312701",
        "longitude": "-80.17386675",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3508",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "8420",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 375812,
            "s_seats": 88850,
            "j_seats": 50394,
            "f_seats": 37074,
            "r_seats": 10000,
            "cargo_demand": 25880112
        }
    },
    {
        "iata": "ERL",
        "icao": "ZBER",
        "airportName": "Erenhot Saiwusu International Airport",
        "location_country": "China",
        "location_city": "Inner Mongolia",
        "location_state": "Erenhot",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.4225",
        "longitude": "112.096667",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 65893,
            "s_seats": 47735,
            "j_seats": 50169,
            "f_seats": 17574,
            "r_seats": 9273,
            "cargo_demand": 57185
        }
    },
    {
        "iata": "ERM",
        "icao": "SSER",
        "airportName": "Erechim Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Erechim",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-27.66189957",
        "longitude": "-52.2682991",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4199",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2950,
            "s_seats": 1660,
            "j_seats": 1603,
            "f_seats": 92,
            "r_seats": 45,
            "cargo_demand": 4476
        }
    },
    {
        "iata": "ERN",
        "icao": "SWEI",
        "airportName": "Eirunepe Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Eirunepe",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.639530182",
        "longitude": "-69.87979889",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7546",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2633,
            "s_seats": 1985,
            "j_seats": 1660,
            "f_seats": 95,
            "r_seats": 173,
            "cargo_demand": 36725
        }
    },
    {
        "iata": "ERQ",
        "icao": "YESE",
        "airportName": "Elrose Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Eloise Copper Mine",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.9764",
        "longitude": "141.0066",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6799",
                "runway_width": "269",
                "runway_material": "Dirt"
            }
        ],
        "demand": {
            "y_seats": 2320,
            "s_seats": 2968,
            "j_seats": 1337,
            "f_seats": 353,
            "r_seats": 178,
            "cargo_demand": 72580
        }
    },
    {
        "iata": "ERR",
        "icao": "KERR",
        "airportName": "Errol Airport",
        "location_country": "United States",
        "location_city": "New Hampshire",
        "location_state": "Errol",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.79249954",
        "longitude": "-71.16419983",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3680",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 2616,
            "s_seats": 2676,
            "j_seats": 1290,
            "f_seats": 337,
            "r_seats": 170,
            "cargo_demand": 72214
        }
    },
    {
        "iata": "ERS",
        "icao": "FYWE",
        "airportName": "Eros Airport",
        "location_country": "Namibia",
        "location_city": "Windhoek",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-22.61219978",
        "longitude": "17.08040047",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7381",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3297",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 60270,
            "s_seats": 40962,
            "j_seats": 48883,
            "f_seats": 6262,
            "r_seats": 4549,
            "cargo_demand": 491109
        }
    },
    {
        "iata": "ERV",
        "icao": "KERV",
        "airportName": "Kerrville Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Kerrville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "29.97669983",
        "longitude": "-99.08570099",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4047",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3527,
            "s_seats": 2043,
            "j_seats": 1225,
            "f_seats": 168,
            "r_seats": 60,
            "cargo_demand": 72465
        }
    },
    {
        "iata": "ERZ",
        "icao": "LTCE",
        "airportName": "Erzurum Airport",
        "location_country": "Turkey",
        "location_city": "Erzurum",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "39.95650101",
        "longitude": "41.17020035",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3051",
                "runway_width": "49",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08L/26R",
                "runway_length": "12499",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "12500",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 326958,
            "s_seats": 76390,
            "j_seats": 161822,
            "f_seats": 20136,
            "r_seats": 10000,
            "cargo_demand": 6791822
        }
    },
    {
        "iata": "ESB",
        "icao": "LTAC",
        "airportName": "Esenboga International Airport",
        "location_country": "Turkey",
        "location_city": "Ankara",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.12810135",
        "longitude": "32.99509811",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "12303",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "12303",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 298051,
            "s_seats": 75561,
            "j_seats": 149343,
            "f_seats": 51579,
            "r_seats": 10000,
            "cargo_demand": 21640679
        }
    },
    {
        "iata": "ESC",
        "icao": "KESC",
        "airportName": "Delta County Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Escanaba",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.72269821",
        "longitude": "-87.09369659",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6501",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1095,
            "s_seats": 2121,
            "j_seats": 1977,
            "f_seats": 332,
            "r_seats": 43,
            "cargo_demand": 40019
        }
    },
    {
        "iata": "ESD",
        "icao": "KORS",
        "airportName": "Orcas Island Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Eastsound",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "48.70819855",
        "longitude": "-122.9100037",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "2900",
                "runway_width": "60",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 3650,
            "s_seats": 2241,
            "j_seats": 1378,
            "f_seats": 400,
            "r_seats": 147,
            "cargo_demand": 50764
        }
    },
    {
        "iata": "ESE",
        "icao": "MMES",
        "airportName": "Ensenada Airport",
        "location_country": "Mexico",
        "location_city": "Baja California",
        "location_state": "Ensenada",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "small_airport",
        "latitude": "31.7953",
        "longitude": "-116.602997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4892",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1541,
            "s_seats": 2202,
            "j_seats": 1821,
            "f_seats": 456,
            "r_seats": 87,
            "cargo_demand": 42209
        }
    },
    {
        "iata": "ESF",
        "icao": "KESF",
        "airportName": "Esler Airfield",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Alexandria",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.39489937",
        "longitude": "-92.29579926",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5999",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5601",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142548,
            "s_seats": 25627,
            "j_seats": 58039,
            "f_seats": 14924,
            "r_seats": 3530,
            "cargo_demand": 451972
        }
    },
    {
        "iata": "ESG",
        "icao": "SGME",
        "airportName": "Dr. Luis Maria Argana International Airport",
        "location_country": "Paraguay",
        "location_city": "Mariscal Estigarribia",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Mar",
        "airport_size": "medium_airport",
        "latitude": "-22.04999924",
        "longitude": "-60.61999893",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "11483",
                "runway_width": "131",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 180421,
            "s_seats": 34194,
            "j_seats": 53077,
            "f_seats": 3231,
            "r_seats": 6368,
            "cargo_demand": 482635
        }
    },
    {
        "iata": "ESH",
        "icao": "EGKA",
        "airportName": "Shoreham Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Shoreham-by-Sea",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.835602",
        "longitude": "-0.297222",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3399",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "2877",
                "runway_width": "164",
                "runway_material": "GRE"
            },
            {
                "runway_name": "13/31",
                "runway_length": "1312",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 144167,
            "s_seats": 47036,
            "j_seats": 33153,
            "f_seats": 11345,
            "r_seats": 7360,
            "cargo_demand": 165962
        }
    },
    {
        "iata": "ESK",
        "icao": "LTBI",
        "airportName": "Eskisehir Airport",
        "location_country": "Turkey",
        "location_city": "Eskisehir",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.78409958",
        "longitude": "30.58209991",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10006",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192567,
            "s_seats": 43834,
            "j_seats": 35788,
            "f_seats": 6470,
            "r_seats": 5987,
            "cargo_demand": 68956
        }
    },
    {
        "iata": "ESL",
        "icao": "URWI",
        "airportName": "Elista Airport",
        "location_country": "Russia",
        "location_city": "Kalmykia",
        "location_state": "Elista",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.37390137",
        "longitude": "44.33089828",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "7555",
                "runway_width": "280",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 97718,
            "s_seats": 46261,
            "j_seats": 28172,
            "f_seats": 8186,
            "r_seats": 4603,
            "cargo_demand": 270859
        }
    },
    {
        "iata": "ESM",
        "icao": "SETN",
        "airportName": "Carlos Concha Torres International Airport",
        "location_country": "Ecuador",
        "location_city": "Esmeraldas",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.978519022",
        "longitude": "-79.62660217",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3975,
            "s_seats": 2145,
            "j_seats": 1248,
            "f_seats": 131,
            "r_seats": 27,
            "cargo_demand": 66789
        }
    },
    {
        "iata": "ESN",
        "icao": "KESN",
        "airportName": "Easton Airport",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Easton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.804199",
        "longitude": "-76.069",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4003",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1305,
            "s_seats": 2138,
            "j_seats": 1064,
            "f_seats": 103,
            "r_seats": 40,
            "cargo_demand": 57234
        }
    },
    {
        "iata": "ESR",
        "icao": "SCES",
        "airportName": "Ricardo Garcia Posada Airport",
        "location_country": "Chile",
        "location_city": "El Salvador",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-26.31110001",
        "longitude": "-69.76519775",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7546",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145535,
            "s_seats": 37505,
            "j_seats": 51217,
            "f_seats": 5411,
            "r_seats": 3871,
            "cargo_demand": 427802
        }
    },
    {
        "iata": "ESS",
        "icao": "EDLE",
        "airportName": "Essen/Mulheim Airport",
        "location_country": "Germany",
        "location_city": "North Rhine-Westphalia",
        "location_state": "Essen",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "51.40230179",
        "longitude": "6.937329769",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5095",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2003,
            "s_seats": 1929,
            "j_seats": 1703,
            "f_seats": 360,
            "r_seats": 143,
            "cargo_demand": 63992
        }
    },
    {
        "iata": "EST",
        "icao": "KEST",
        "airportName": "Estherville Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Estherville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.40739822",
        "longitude": "-94.74639893",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2985",
                "runway_width": "90",
                "runway_material": "GRE"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4797",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1085,
            "s_seats": 1900,
            "j_seats": 1025,
            "f_seats": 419,
            "r_seats": 75,
            "cargo_demand": 82093
        }
    },
    {
        "iata": "ESU",
        "icao": "GMMI",
        "airportName": "Essaouira-Mogador Airport",
        "location_country": "Morocco",
        "location_city": "Essaouira",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct1",
        "airport_size": "small_airport",
        "latitude": "31.397499",
        "longitude": "-9.68167",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8553",
                "runway_width": "148",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 2842,
            "s_seats": 1645,
            "j_seats": 1517,
            "f_seats": 94,
            "r_seats": 69,
            "cargo_demand": 90395
        }
    },
    {
        "iata": "ESW",
        "icao": "KESW",
        "airportName": "Easton State Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Easton",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "47.25419998",
        "longitude": "-121.185997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2640",
                "runway_width": "100",
                "runway_material": "TURF-F"
            }
        ],
        "demand": {
            "y_seats": 1201,
            "s_seats": 1710,
            "j_seats": 1684,
            "f_seats": 343,
            "r_seats": 79,
            "cargo_demand": 50989
        }
    },
    {
        "iata": "ETB",
        "icao": "KETB",
        "airportName": "West Bend Municipal Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "West Bend",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.42219925",
        "longitude": "-88.12789917",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3897",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4494",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3249,
            "s_seats": 1503,
            "j_seats": 1673,
            "f_seats": 16,
            "r_seats": 154,
            "cargo_demand": 14155
        }
    },
    {
        "iata": "ETD",
        "icao": "YEDA",
        "airportName": "Etadunna Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Etadunna",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-28.74080086",
        "longitude": "138.5890045",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3904",
                "runway_width": "",
                "runway_material": "L"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3805",
                "runway_width": "",
                "runway_material": "L"
            }
        ],
        "demand": {
            "y_seats": 3724,
            "s_seats": 1903,
            "j_seats": 1220,
            "f_seats": 16,
            "r_seats": 62,
            "cargo_demand": 32732
        }
    },
    {
        "iata": "ETM",
        "icao": "LLER",
        "airportName": "Ramon Airport",
        "location_country": "Israel",
        "location_city": "Eilat",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "29.723694",
        "longitude": "35.011416",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "11811",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 369799,
            "s_seats": 74669,
            "j_seats": 79986,
            "f_seats": 45184,
            "r_seats": 10000,
            "cargo_demand": 9384731
        }
    },
    {
        "iata": "ETN",
        "icao": "KETN",
        "airportName": "Eastland Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Eastland",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.41350174",
        "longitude": "-98.80979919",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4020",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3060,
            "s_seats": 2901,
            "j_seats": 1826,
            "f_seats": 249,
            "r_seats": 170,
            "cargo_demand": 36878
        }
    },
    {
        "iata": "ETR",
        "icao": "SERO",
        "airportName": "Santa Rosa International Airport",
        "location_country": "Ecuador",
        "location_city": "Santa Rosa",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.441986",
        "longitude": "-79.996957",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8625",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197749,
            "s_seats": 31184,
            "j_seats": 43538,
            "f_seats": 9217,
            "r_seats": 5559,
            "cargo_demand": 388384
        }
    },
    {
        "iata": "ETS",
        "icao": "KEDN",
        "airportName": "Enterprise Municipal Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Enterprise",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.29969978",
        "longitude": "-85.89990234",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5100",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3292,
            "s_seats": 2127,
            "j_seats": 1964,
            "f_seats": 140,
            "r_seats": 48,
            "cargo_demand": 7369
        }
    },
    {
        "iata": "ETZ",
        "icao": "LFJL",
        "airportName": "Metz-Nancy-Lorraine Airport",
        "location_country": "France",
        "location_city": "Lorraine",
        "location_state": "Metz / Nancy",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.98210144",
        "longitude": "6.251319885",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153015,
            "s_seats": 32660,
            "j_seats": 30657,
            "f_seats": 7972,
            "r_seats": 6744,
            "cargo_demand": 140137
        }
    },
    {
        "iata": "EUA",
        "icao": "NFTE",
        "airportName": "Eua Airport (Kaufana Airport)",
        "location_country": "Tonga",
        "location_city": "Eua",
        "location_state": "",
        "time_zone": "UTC+13:00",
        "daylight_saving": "Nov-Jan",
        "airport_size": "small_airport",
        "latitude": "-21.37829971",
        "longitude": "-174.9579926",
        "region": "OC",
        "runway": [
            {
                "runway_name": "34/16",
                "runway_length": "2650",
                "runway_width": "65",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3649,
            "s_seats": 2195,
            "j_seats": 1173,
            "f_seats": 24,
            "r_seats": 69,
            "cargo_demand": 21006
        }
    },
    {
        "iata": "EUC",
        "icao": "YECL",
        "airportName": "Eucla Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Eucla",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31.70000076",
        "longitude": "128.8829956",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4265",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1640",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1322,
            "s_seats": 1557,
            "j_seats": 1952,
            "f_seats": 263,
            "r_seats": 81,
            "cargo_demand": 44469
        }
    },
    {
        "iata": "EUF",
        "icao": "KEUF",
        "airportName": "Weedon Field",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Eufaula",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.95129967",
        "longitude": "-85.12889862",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2593,
            "s_seats": 2554,
            "j_seats": 1217,
            "f_seats": 360,
            "r_seats": 133,
            "cargo_demand": 27793
        }
    },
    {
        "iata": "EUG",
        "icao": "KEUG",
        "airportName": "Eugene Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Eugene",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.12459946",
        "longitude": "-123.211998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5228",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "8009",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170337,
            "s_seats": 40890,
            "j_seats": 13525,
            "f_seats": 2185,
            "r_seats": 3357,
            "cargo_demand": 467313
        }
    },
    {
        "iata": "EUN",
        "icao": "GMML",
        "airportName": "Hassan I Airport",
        "location_country": "Morocco",
        "location_city": "Laayoune",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct1",
        "airport_size": "medium_airport",
        "latitude": "27.151699",
        "longitude": "-13.2192",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8861",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04/22",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150171,
            "s_seats": 49222,
            "j_seats": 18665,
            "f_seats": 1128,
            "r_seats": 5024,
            "cargo_demand": 220043
        }
    },
    {
        "iata": "EUQ",
        "icao": "RPVS",
        "airportName": "Evelio Javier Airport",
        "location_country": "Philippines",
        "location_city": "San Jose de Buenavista",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.76599979",
        "longitude": "121.9329987",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 115417,
            "s_seats": 45861,
            "j_seats": 30575,
            "f_seats": 9771,
            "r_seats": 6502,
            "cargo_demand": 223060
        }
    },
    {
        "iata": "EUX",
        "icao": "TNCE",
        "airportName": "F. D. Roosevelt Airport",
        "location_country": "Caribbean Netherlands",
        "location_city": "Sint Eustatius",
        "location_state": "Oranjestad",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.49650002",
        "longitude": "-62.97940063",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4265",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139246,
            "s_seats": 48305,
            "j_seats": 18015,
            "f_seats": 17068,
            "r_seats": 7553,
            "cargo_demand": 465559
        }
    },
    {
        "iata": "EVE",
        "icao": "ENEV",
        "airportName": "Harstad/Narvik Airport",
        "location_country": "Norway",
        "location_city": "Harstad / Narvik",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "\" Evenes\"\"\"",
        "longitude": "68.49130249",
        "region": "84",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9236",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152317,
            "s_seats": 34383,
            "j_seats": 19931,
            "f_seats": 15312,
            "r_seats": 7000,
            "cargo_demand": 195488
        }
    },
    {
        "iata": "EVG",
        "icao": "ESND",
        "airportName": "Sveg Airport",
        "location_country": "Sweden",
        "location_city": "Sveg",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "62.047798",
        "longitude": "14.4229",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5579",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173366,
            "s_seats": 26040,
            "j_seats": 26996,
            "f_seats": 6516,
            "r_seats": 5143,
            "cargo_demand": 391912
        }
    },
    {
        "iata": "EVH",
        "icao": "YEVD",
        "airportName": "Evans Head Memorial Aerodrome",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Evans Head",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-29.09329987",
        "longitude": "153.4199982",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4275",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2954,
            "s_seats": 1970,
            "j_seats": 1907,
            "f_seats": 424,
            "r_seats": 69,
            "cargo_demand": 26772
        }
    },
    {
        "iata": "EVM",
        "icao": "KEVM",
        "airportName": "Eveleth-Virginia Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Eveleth",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "47.42509842",
        "longitude": "-92.49849701",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2685",
                "runway_width": "110",
                "runway_material": "GRS"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4215",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2506",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2648,
            "s_seats": 2918,
            "j_seats": 1572,
            "f_seats": 121,
            "r_seats": 32,
            "cargo_demand": 47968
        }
    },
    {
        "iata": "EVN",
        "icao": "UDYZ",
        "airportName": "Zvartnots International Airport",
        "location_country": "Armenia",
        "location_city": "Yerevan",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.14730072",
        "longitude": "44.39590073",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "12629",
                "runway_width": "184",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199483,
            "s_seats": 75928,
            "j_seats": 164450,
            "f_seats": 45073,
            "r_seats": 10000,
            "cargo_demand": 10981267
        }
    },
    {
        "iata": "EVV",
        "icao": "KEVV",
        "airportName": "Evansville Regional Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Evansville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.03699875",
        "longitude": "-87.53240204",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8021",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3500",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6286",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184637,
            "s_seats": 27066,
            "j_seats": 16310,
            "f_seats": 10891,
            "r_seats": 8219,
            "cargo_demand": 166736
        }
    },
    {
        "iata": "EVW",
        "icao": "KEVW",
        "airportName": "Evanston-Uinta County Burns Field",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Evanston",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.27479935",
        "longitude": "-111.0350037",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7300",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "7300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101777,
            "s_seats": 38147,
            "j_seats": 12223,
            "f_seats": 19355,
            "r_seats": 7584,
            "cargo_demand": 382431
        }
    },
    {
        "iata": "EVX",
        "icao": "LFOE",
        "airportName": "evreux-Fauville Air Base",
        "location_country": "France",
        "location_city": "Upper Normandy",
        "location_state": "evreux",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.02870178",
        "longitude": "1.219859958",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9826",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138821,
            "s_seats": 31597,
            "j_seats": 52646,
            "f_seats": 3023,
            "r_seats": 6833,
            "cargo_demand": 137025
        }
    },
    {
        "iata": "EWB",
        "icao": "KEWB",
        "airportName": "New Bedford Regional Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "New Bedford / Fall River",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.67610168",
        "longitude": "-70.95690155",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4997",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76702,
            "s_seats": 34759,
            "j_seats": 17763,
            "f_seats": 10640,
            "r_seats": 3852,
            "cargo_demand": 137211
        }
    },
    {
        "iata": "EWI",
        "icao": "WABT",
        "airportName": "Enarotali Airport",
        "location_country": "Indonesia",
        "location_city": "Enarotali",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.925899982",
        "longitude": "136.3769989",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3585",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2341,
            "s_seats": 1713,
            "j_seats": 1542,
            "f_seats": 170,
            "r_seats": 67,
            "cargo_demand": 48129
        }
    },
    {
        "iata": "EWK",
        "icao": "KEWK",
        "airportName": "Newton City/County Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Newton",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.05820084",
        "longitude": "-97.27449799",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3501",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7003",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2083,
            "s_seats": 1750,
            "j_seats": 1342,
            "f_seats": 279,
            "r_seats": 99,
            "cargo_demand": 98103
        }
    },
    {
        "iata": "EWN",
        "icao": "KEWN",
        "airportName": "Coastal Carolina Regional Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "New Bern",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.07300186",
        "longitude": "-77.04290009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6004",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4000",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 166970,
            "s_seats": 47574,
            "j_seats": 15610,
            "f_seats": 11054,
            "r_seats": 9005,
            "cargo_demand": 425389
        }
    },
    {
        "iata": "EWO",
        "icao": "FCOE",
        "airportName": "Ewo Airport",
        "location_country": "Republic of the Congo",
        "location_city": "Ewo",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.883000016",
        "longitude": "14.80000019",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6726",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1097,
            "s_seats": 2960,
            "j_seats": 1767,
            "f_seats": 128,
            "r_seats": 71,
            "cargo_demand": 12934
        }
    },
    {
        "iata": "EWR",
        "icao": "KEWR",
        "airportName": "Newark Liberty International Airport",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Newark",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "40.692501",
        "longitude": "-74.168701",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "11000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6800",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 262413,
            "s_seats": 84619,
            "j_seats": 20283,
            "f_seats": 42470,
            "r_seats": 10000,
            "cargo_demand": 28467025
        }
    },
    {
        "iata": "EXM",
        "icao": "YEXM",
        "airportName": "Exmouth Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Exmouth",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.0333004",
        "longitude": "114.0999985",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4215",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3717,
            "s_seats": 2889,
            "j_seats": 1794,
            "f_seats": 293,
            "r_seats": 102,
            "cargo_demand": 9029
        }
    },
    {
        "iata": "EXT",
        "icao": "EGTE",
        "airportName": "Exeter International Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Exeter",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "50.7344017",
        "longitude": "-3.413889885",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6834",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4393",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 399359,
            "s_seats": 97896,
            "j_seats": 108994,
            "f_seats": 35637,
            "r_seats": 10000,
            "cargo_demand": 17799091
        }
    },
    {
        "iata": "EYP",
        "icao": "SKYP",
        "airportName": "El Alcaravan Airport",
        "location_country": "Colombia",
        "location_city": "Yopal",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.31911",
        "longitude": "-72.384",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8448",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159869,
            "s_seats": 36280,
            "j_seats": 25473,
            "f_seats": 7889,
            "r_seats": 7642,
            "cargo_demand": 273520
        }
    },
    {
        "iata": "EYW",
        "icao": "KEYW",
        "airportName": "Key West International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Key West",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "24.556101",
        "longitude": "-81.759598",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4801",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134090,
            "s_seats": 41007,
            "j_seats": 36041,
            "f_seats": 1160,
            "r_seats": 6177,
            "cargo_demand": 334146
        }
    },
    {
        "iata": "EZE",
        "icao": "SAEZ",
        "airportName": "Ministro Pistarini International Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-34.8222",
        "longitude": "-58.5358",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10827",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "10187",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 372350,
            "s_seats": 94944,
            "j_seats": 115163,
            "f_seats": 55375,
            "r_seats": 10000,
            "cargo_demand": 15190213
        }
    },
    {
        "iata": "EZS",
        "icao": "LTCA",
        "airportName": "Elazig Airport",
        "location_country": "Turkey",
        "location_city": "Elazig",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.60689926",
        "longitude": "39.29140091",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CONC"
            },
            {
                "runway_name": "13/31",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 134253,
            "s_seats": 30595,
            "j_seats": 19691,
            "f_seats": 3083,
            "r_seats": 5007,
            "cargo_demand": 445259
        }
    },
    {
        "iata": "FAA",
        "icao": "GUFH",
        "airportName": "Faranah Airport",
        "location_country": "Guinea",
        "location_city": "Faranah",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "10.03549957",
        "longitude": "-10.76980019",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7595",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1733,
            "s_seats": 1607,
            "j_seats": 1413,
            "f_seats": 238,
            "r_seats": 81,
            "cargo_demand": 64723
        }
    },
    {
        "iata": "FAB",
        "icao": "EGLF",
        "airportName": "Farnborough Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Farnborough",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.2757988",
        "longitude": "-0.776332974",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8005",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67343,
            "s_seats": 26396,
            "j_seats": 33964,
            "f_seats": 5550,
            "r_seats": 4907,
            "cargo_demand": 119827
        }
    },
    {
        "iata": "FAC",
        "icao": "NTKF",
        "airportName": "Faaite Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Faaite",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.68670082",
        "longitude": "-145.3289948",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3890",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2297,
            "s_seats": 1847,
            "j_seats": 1018,
            "f_seats": 76,
            "r_seats": 27,
            "cargo_demand": 34506
        }
    },
    {
        "iata": "FAE",
        "icao": "EKVG",
        "airportName": "Vagar Airport",
        "location_country": "Faroe Islands",
        "location_city": "Vagar",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "62.063599",
        "longitude": "-7.27722",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5902",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134965,
            "s_seats": 25944,
            "j_seats": 35725,
            "f_seats": 18995,
            "r_seats": 4080,
            "cargo_demand": 182746
        }
    },
    {
        "iata": "FAF",
        "icao": "KFAF",
        "airportName": "Felker Army Airfield",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Fort Eustis",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.13249969",
        "longitude": "-76.6088028",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3020",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55046,
            "s_seats": 29974,
            "j_seats": 12717,
            "f_seats": 6226,
            "r_seats": 9537,
            "cargo_demand": 345894
        }
    },
    {
        "iata": "FAI",
        "icao": "PAFA",
        "airportName": "Fairbanks International Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Fairbanks",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "64.81510162",
        "longitude": "-147.8560028",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2900",
                "runway_width": "75",
                "runway_material": "GRVL"
            },
            {
                "runway_name": "02L/20R",
                "runway_length": "11800",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "6501",
                "runway_width": "100",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "02W/20W",
                "runway_length": "5400",
                "runway_width": "100",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 378807,
            "s_seats": 97171,
            "j_seats": 168285,
            "f_seats": 64592,
            "r_seats": 10000,
            "cargo_demand": 28926060
        }
    },
    {
        "iata": "FAM",
        "icao": "KFAM",
        "airportName": "Farmington Regional Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Farmington",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.76110077",
        "longitude": "-90.4285965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4221",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3715,
            "s_seats": 2048,
            "j_seats": 1085,
            "f_seats": 93,
            "r_seats": 86,
            "cargo_demand": 92690
        }
    },
    {
        "iata": "FAO",
        "icao": "LPFR",
        "airportName": "Faro Airport",
        "location_country": "Portugal",
        "location_city": "Faro",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "37.01440048",
        "longitude": "-7.965909958",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8169",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 255852,
            "s_seats": 93758,
            "j_seats": 31919,
            "f_seats": 31095,
            "r_seats": 10000,
            "cargo_demand": 22513744
        }
    },
    {
        "iata": "FAR",
        "icao": "KFAR",
        "airportName": "Hector International Airport",
        "location_country": "United States",
        "location_city": "North Dakota",
        "location_state": "Fargo",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.92070007",
        "longitude": "-96.81580353",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6300",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3800",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 199253,
            "s_seats": 31330,
            "j_seats": 41508,
            "f_seats": 7716,
            "r_seats": 9716,
            "cargo_demand": 162386
        }
    },
    {
        "iata": "FAT",
        "icao": "KFAT",
        "airportName": "Fresno Yosemite International Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Fresno",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.776199",
        "longitude": "-119.718002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11L/29R",
                "runway_length": "9217",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "7205",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74178,
            "s_seats": 44896,
            "j_seats": 51435,
            "f_seats": 9518,
            "r_seats": 4736,
            "cargo_demand": 484278
        }
    },
    {
        "iata": "FAV",
        "icao": "NTGF",
        "airportName": "Fakarava Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Fakarava",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.05410004",
        "longitude": "-145.6569977",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3871",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138813,
            "s_seats": 33724,
            "j_seats": 11316,
            "f_seats": 12250,
            "r_seats": 3833,
            "cargo_demand": 219796
        }
    },
    {
        "iata": "FAY",
        "icao": "KFAY",
        "airportName": "Fayetteville Regional Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Fayetteville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.991199",
        "longitude": "-78.880302",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7712",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4801",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155889,
            "s_seats": 29486,
            "j_seats": 51103,
            "f_seats": 6734,
            "r_seats": 6776,
            "cargo_demand": 499529
        }
    },
    {
        "iata": "FAZ",
        "icao": "OISF",
        "airportName": "Fasa Airport",
        "location_country": "Iran",
        "location_city": "Fasa",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "28.89179993",
        "longitude": "53.72330093",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6502",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92481,
            "s_seats": 39408,
            "j_seats": 29601,
            "f_seats": 13894,
            "r_seats": 5250,
            "cargo_demand": 216346
        }
    },
    {
        "iata": "FBA",
        "icao": "SWOB",
        "airportName": "Fonte Boa Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Fonte Boa",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.53260994",
        "longitude": "-66.08319855",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4167",
                "runway_width": "89",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2029,
            "s_seats": 2648,
            "j_seats": 1574,
            "f_seats": 309,
            "r_seats": 6,
            "cargo_demand": 7786
        }
    },
    {
        "iata": "FBE",
        "icao": "SSFB",
        "airportName": "Francisco Beltrio Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Francisco Beltrio",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-26.05920029",
        "longitude": "-53.06349945",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "4331",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2639,
            "s_seats": 2106,
            "j_seats": 1563,
            "f_seats": 273,
            "r_seats": 58,
            "cargo_demand": 4634
        }
    },
    {
        "iata": "FBG",
        "icao": "KFBG",
        "airportName": "Simmons Army Airfield",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Fort Bragg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.13180161",
        "longitude": "-78.93669891",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4650",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57176,
            "s_seats": 35827,
            "j_seats": 26662,
            "f_seats": 17744,
            "r_seats": 9208,
            "cargo_demand": 75735
        }
    },
    {
        "iata": "FBK",
        "icao": "PAFB",
        "airportName": "Ladd Army Airfield",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Fairbanks",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "64.83750153",
        "longitude": "-147.6139984",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8575",
                "runway_width": "150",
                "runway_material": "ASPH-CONC"
            }
        ],
        "demand": {
            "y_seats": 170602,
            "s_seats": 42055,
            "j_seats": 36146,
            "f_seats": 1973,
            "r_seats": 5468,
            "cargo_demand": 367563
        }
    },
    {
        "iata": "FBL",
        "icao": "KFBL",
        "airportName": "Faribault Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Faribault",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.32844",
        "longitude": "-93.312534",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4254",
                "runway_width": "72",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1930,
            "s_seats": 2260,
            "j_seats": 1383,
            "f_seats": 202,
            "r_seats": 25,
            "cargo_demand": 92242
        }
    },
    {
        "iata": "FBM",
        "icao": "FZQA",
        "airportName": "Lubumbashi International Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Lubumbashi",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.59130001",
        "longitude": "27.53089905",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10623",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143993,
            "s_seats": 49872,
            "j_seats": 41948,
            "f_seats": 4144,
            "r_seats": 9015,
            "cargo_demand": 56715
        }
    },
    {
        "iata": "FBR",
        "icao": "KFBR",
        "airportName": "Fort Bridger Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Fort Bridger",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.39189911",
        "longitude": "-110.4069977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6402",
                "runway_width": "80",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "4042",
                "runway_width": "50",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2630,
            "s_seats": 2326,
            "j_seats": 1509,
            "f_seats": 127,
            "r_seats": 31,
            "cargo_demand": 68673
        }
    },
    {
        "iata": "FBY",
        "icao": "KFBY",
        "airportName": "Fairbury Municipal Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Fairbury",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.18299866",
        "longitude": "-97.16929626",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2455",
                "runway_width": "150",
                "runway_material": "GRE"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3700",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3118,
            "s_seats": 1725,
            "j_seats": 1960,
            "f_seats": 348,
            "r_seats": 96,
            "cargo_demand": 30972
        }
    },
    {
        "iata": "FCA",
        "icao": "KGPI",
        "airportName": "Glacier Park International Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Kalispell",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "48.3105011",
        "longitude": "-114.2559967",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9007",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "3043",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3504",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170553,
            "s_seats": 41815,
            "j_seats": 33648,
            "f_seats": 14372,
            "r_seats": 3875,
            "cargo_demand": 286337
        }
    },
    {
        "iata": "FCB",
        "icao": "FAFB",
        "airportName": "Ficksburg Airport",
        "location_country": "South Africa",
        "location_city": "Ficksburg",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.82309914",
        "longitude": "27.90889931",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4593",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199750,
            "s_seats": 34667,
            "j_seats": 5371,
            "f_seats": 3763,
            "r_seats": 5307,
            "cargo_demand": 122097
        }
    },
    {
        "iata": "FCH",
        "icao": "KFCH",
        "airportName": "Fresno Chandler Executive Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Fresno",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.732399",
        "longitude": "-119.82",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3630",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "3006",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "3202",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3867,
            "s_seats": 1990,
            "j_seats": 1427,
            "f_seats": 288,
            "r_seats": 115,
            "cargo_demand": 1040
        }
    },
    {
        "iata": "FCM",
        "icao": "KFCM",
        "airportName": "Flying Cloud Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Eden Prairie",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.82720184",
        "longitude": "-93.45709991",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "3898",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2691",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3586,
            "s_seats": 1918,
            "j_seats": 1733,
            "f_seats": 453,
            "r_seats": 196,
            "cargo_demand": 51488
        }
    },
    {
        "iata": "FCN",
        "icao": "ETMN",
        "airportName": "Sea-Airport Cuxhaven/Nordholz",
        "location_country": "Germany",
        "location_city": "Lower Saxony",
        "location_state": "Cuxhaven",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "53.7677002",
        "longitude": "8.658499718",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8002",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 99397,
            "s_seats": 46137,
            "j_seats": 17539,
            "f_seats": 14818,
            "r_seats": 8138,
            "cargo_demand": 374179
        }
    },
    {
        "iata": "FCO",
        "icao": "LIRF",
        "airportName": "Leonardo da Vinci-Fiumicino Airport",
        "location_country": "Italy",
        "location_city": "Lazio",
        "location_state": "Rome",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "41.8002778",
        "longitude": "12.2388889",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10849",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16C/34C",
                "runway_length": "11818",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "12801",
                "runway_width": "196",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "12801",
                "runway_width": "196",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 448126,
            "s_seats": 86266,
            "j_seats": 100825,
            "f_seats": 35779,
            "r_seats": 10000,
            "cargo_demand": 2372922
        }
    },
    {
        "iata": "FCS",
        "icao": "KFCS",
        "airportName": "Butts Army Airfield",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Colorado Springs",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.67839813",
        "longitude": "-104.7570038",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4573",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129645,
            "s_seats": 37184,
            "j_seats": 27289,
            "f_seats": 13185,
            "r_seats": 5658,
            "cargo_demand": 459472
        }
    },
    {
        "iata": "FCY",
        "icao": "KFCY",
        "airportName": "Forrest City Municipal Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Forrest City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.94200134",
        "longitude": "-90.77500153",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3014",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1774,
            "s_seats": 2524,
            "j_seats": 1750,
            "f_seats": 484,
            "r_seats": 173,
            "cargo_demand": 94277
        }
    },
    {
        "iata": "FDE",
        "icao": "ENBL",
        "airportName": "Forde Airport/c/ Bringeland",
        "location_country": "Norway",
        "location_city": "Forde",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "61.39110184",
        "longitude": "5.756939888",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3084",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2119,
            "s_seats": 2512,
            "j_seats": 1013,
            "f_seats": 223,
            "r_seats": 14,
            "cargo_demand": 76343
        }
    },
    {
        "iata": "FDF",
        "icao": "TFFF",
        "airportName": "Martinique Aime Cesaire International Airport",
        "location_country": "Martinique",
        "location_city": "Fort-de-France",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "14.591",
        "longitude": "-61.003201",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10826",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 221323,
            "s_seats": 70811,
            "j_seats": 20056,
            "f_seats": 21392,
            "r_seats": 10000,
            "cargo_demand": 28976222
        }
    },
    {
        "iata": "FDH",
        "icao": "EDNY",
        "airportName": "Friedrichshafen Airport",
        "location_country": "Germany",
        "location_city": "Baden-Wurttemberg",
        "location_state": "Friedrichshafen",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.67129898",
        "longitude": "9.511489868",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7729",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170621,
            "s_seats": 32318,
            "j_seats": 6025,
            "f_seats": 19939,
            "r_seats": 3717,
            "cargo_demand": 158903
        }
    },
    {
        "iata": "FDK",
        "icao": "KFDK",
        "airportName": "Frederick Municipal Airport",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Frederick",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.41759872",
        "longitude": "-77.3742981",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5220",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3600",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3107,
            "s_seats": 2514,
            "j_seats": 1301,
            "f_seats": 148,
            "r_seats": 90,
            "cargo_demand": 9126
        }
    },
    {
        "iata": "FDR",
        "icao": "KFDR",
        "airportName": "Frederick Regional Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Frederick",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.35200119",
        "longitude": "-98.98390198",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4800",
                "runway_width": "60",
                "runway_material": "CON"
            },
            {
                "runway_name": "08/26",
                "runway_length": "4319",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4550",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6099",
                "runway_width": "150",
                "runway_material": "ASPH-F"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "3180",
                "runway_width": "50",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2412,
            "s_seats": 2848,
            "j_seats": 1293,
            "f_seats": 117,
            "r_seats": 167,
            "cargo_demand": 10595
        }
    },
    {
        "iata": "FDU",
        "icao": "FZBO",
        "airportName": "Bandundu Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Bandundu",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.31132",
        "longitude": "17.381701",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4528",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95608,
            "s_seats": 26643,
            "j_seats": 13047,
            "f_seats": 16598,
            "r_seats": 5330,
            "cargo_demand": 442125
        }
    },
    {
        "iata": "FDY",
        "icao": "KFDY",
        "airportName": "Findlay Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Findlay",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.01350021",
        "longitude": "-83.66870117",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5883",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 93686,
            "s_seats": 33180,
            "j_seats": 55305,
            "f_seats": 1710,
            "r_seats": 6370,
            "cargo_demand": 436870
        }
    },
    {
        "iata": "FEC",
        "icao": "SNJD",
        "airportName": "Feira de Santana Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Feira de Santana",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.2003",
        "longitude": "-38.906799",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2726,
            "s_seats": 2557,
            "j_seats": 1777,
            "f_seats": 461,
            "r_seats": 54,
            "cargo_demand": 10819
        }
    },
    {
        "iata": "FEG",
        "icao": "UTKF",
        "airportName": "Fergana International Airport",
        "location_country": "Uzbekistan",
        "location_city": "Fergana",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.35879898",
        "longitude": "71.74500275",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9383",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1890,
            "s_seats": 1936,
            "j_seats": 1874,
            "f_seats": 328,
            "r_seats": 41,
            "cargo_demand": 21356
        }
    },
    {
        "iata": "FEJ",
        "icao": "SNOU",
        "airportName": "Feijo Airport",
        "location_country": "Brazil",
        "location_city": "Acre",
        "location_state": "Feijo",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.141213",
        "longitude": "-70.340821",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "3937",
                "runway_width": "79",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3685,
            "s_seats": 1654,
            "j_seats": 1664,
            "f_seats": 266,
            "r_seats": 58,
            "cargo_demand": 67803
        }
    },
    {
        "iata": "FEN",
        "icao": "SBFN",
        "airportName": "Fernando de Noronha Airport",
        "location_country": "Brazil",
        "location_city": "Pernambuco",
        "location_state": "Fernando de Noronha",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.85493",
        "longitude": "-32.423302",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6053",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70685,
            "s_seats": 28120,
            "j_seats": 5692,
            "f_seats": 7925,
            "r_seats": 9944,
            "cargo_demand": 441143
        }
    },
    {
        "iata": "FEP",
        "icao": "KFEP",
        "airportName": "Albertus Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Freeport",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.24620056",
        "longitude": "-89.58200073",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5504",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2285",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2496",
                "runway_width": "150",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3006,
            "s_seats": 2303,
            "j_seats": 1770,
            "f_seats": 440,
            "r_seats": 69,
            "cargo_demand": 20383
        }
    },
    {
        "iata": "FET",
        "icao": "KFET",
        "airportName": "Fremont Municipal Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Fremont",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.44910049",
        "longitude": "-96.52020264",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2444",
                "runway_width": "50",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASPH-CONC-G"
            }
        ],
        "demand": {
            "y_seats": 3722,
            "s_seats": 1760,
            "j_seats": 1663,
            "f_seats": 428,
            "r_seats": 57,
            "cargo_demand": 70900
        }
    },
    {
        "iata": "FEZ",
        "icao": "GMFF",
        "airportName": "Fes-Sai\u00afss Airport",
        "location_country": "Morocco",
        "location_city": "Fez",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct1",
        "airport_size": "medium_airport",
        "latitude": "33.9272995",
        "longitude": "-4.97796011",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172702,
            "s_seats": 30414,
            "j_seats": 22746,
            "f_seats": 2925,
            "r_seats": 6739,
            "cargo_demand": 136662
        }
    },
    {
        "iata": "FFA",
        "icao": "KFFA",
        "airportName": "First Flight Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Kill Devil Hills",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.01819992",
        "longitude": "-75.6713028",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1888,
            "s_seats": 2927,
            "j_seats": 1389,
            "f_seats": 493,
            "r_seats": 2,
            "cargo_demand": 72486
        }
    },
    {
        "iata": "FFD",
        "icao": "EGVA",
        "airportName": "RAF Fairford",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Fairford",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.682201",
        "longitude": "-1.79003",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9994",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155500,
            "s_seats": 47569,
            "j_seats": 22246,
            "f_seats": 7325,
            "r_seats": 3620,
            "cargo_demand": 136777
        }
    },
    {
        "iata": "FFL",
        "icao": "KFFL",
        "airportName": "Fairfield Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Fairfield",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.05329895",
        "longitude": "-91.97889709",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2450",
                "runway_width": "165",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1263,
            "s_seats": 2045,
            "j_seats": 1280,
            "f_seats": 309,
            "r_seats": 126,
            "cargo_demand": 36767
        }
    },
    {
        "iata": "FFM",
        "icao": "KFFM",
        "airportName": "Fergus Falls Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Fergus Falls",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "46.28440094",
        "longitude": "-96.15670013",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5639",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3301",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1960,
            "s_seats": 1968,
            "j_seats": 1017,
            "f_seats": 484,
            "r_seats": 106,
            "cargo_demand": 36207
        }
    },
    {
        "iata": "FFO",
        "icao": "KFFO",
        "airportName": "Wright-Patterson Air Force Base",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Dayton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "39.8260994",
        "longitude": "-84.0483017",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "12601",
                "runway_width": "300",
                "runway_material": "PEM"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 263019,
            "s_seats": 95654,
            "j_seats": 135589,
            "f_seats": 66819,
            "r_seats": 10000,
            "cargo_demand": 6287220
        }
    },
    {
        "iata": "FFT",
        "icao": "KFFT",
        "airportName": "Capital City Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Frankfort",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.18249893",
        "longitude": "-84.90470123",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5005",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1737,
            "s_seats": 1547,
            "j_seats": 1637,
            "f_seats": 325,
            "r_seats": 87,
            "cargo_demand": 36628
        }
    },
    {
        "iata": "FFU",
        "icao": "SCFT",
        "airportName": "Futaleufu Airfield",
        "location_country": "Chile",
        "location_city": "Futaleufu",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "small_airport",
        "latitude": "-43.18920135",
        "longitude": "-71.85109711",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3110",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2795,
            "s_seats": 2150,
            "j_seats": 1360,
            "f_seats": 201,
            "r_seats": 141,
            "cargo_demand": 54616
        }
    },
    {
        "iata": "FGU",
        "icao": "NTGB",
        "airportName": "Fangatau Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Fangatau",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.81989956",
        "longitude": "-140.8869934",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3936",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117892,
            "s_seats": 32332,
            "j_seats": 5638,
            "f_seats": 14570,
            "r_seats": 7178,
            "cargo_demand": 77696
        }
    },
    {
        "iata": "FHU",
        "icao": "KFHU",
        "airportName": "Sierra Vista Municipal Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Sierra Vista",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.587383",
        "longitude": "-110.348225",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4285",
                "runway_width": "75",
                "runway_material": "PEM"
            },
            {
                "runway_name": "08/26",
                "runway_length": "12001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5366",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 74810,
            "s_seats": 41218,
            "j_seats": 29463,
            "f_seats": 11614,
            "r_seats": 5394,
            "cargo_demand": 168935
        }
    },
    {
        "iata": "FIE",
        "icao": "EGEF",
        "airportName": "Fair Isle Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Fair Isle",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "59.535801",
        "longitude": "-1.62806",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "1761",
                "runway_width": "75",
                "runway_material": "Gravel"
            }
        ],
        "demand": {
            "y_seats": 2861,
            "s_seats": 2836,
            "j_seats": 1838,
            "f_seats": 220,
            "r_seats": 38,
            "cargo_demand": 8592
        }
    },
    {
        "iata": "FIG",
        "icao": "GUFA",
        "airportName": "Fria Airport",
        "location_country": "Guinea",
        "location_city": "Fria",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "10.35060024",
        "longitude": "-13.56919956",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5249",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1289,
            "s_seats": 2468,
            "j_seats": 1307,
            "f_seats": 166,
            "r_seats": 42,
            "cargo_demand": 47980
        }
    },
    {
        "iata": "FIH",
        "icao": "FZAA",
        "airportName": "N'djili Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Kinshasa",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-4.38575",
        "longitude": "15.4446",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "15420",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 377433,
            "s_seats": 93361,
            "j_seats": 145082,
            "f_seats": 41628,
            "r_seats": 10000,
            "cargo_demand": 13975541
        }
    },
    {
        "iata": "FIL",
        "icao": "KFOM",
        "airportName": "Fillmore Municipal Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Fillmore",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.95830154",
        "longitude": "-112.362999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5050",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3761,
            "s_seats": 2826,
            "j_seats": 1376,
            "f_seats": 141,
            "r_seats": 123,
            "cargo_demand": 80307
        }
    },
    {
        "iata": "FIZ",
        "icao": "YFTZ",
        "airportName": "Fitzroy Crossing Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Fitzroy Crossing",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-18.18190002",
        "longitude": "125.5589981",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97687,
            "s_seats": 45985,
            "j_seats": 42651,
            "f_seats": 18616,
            "r_seats": 5688,
            "cargo_demand": 70933
        }
    },
    {
        "iata": "FJR",
        "icao": "OMFJ",
        "airportName": "Fujairah International Airport",
        "location_country": "United Arab Emirates",
        "location_city": "Fujairah",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.11219978",
        "longitude": "56.32400131",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "12303",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184291,
            "s_seats": 26219,
            "j_seats": 45512,
            "f_seats": 19242,
            "r_seats": 4026,
            "cargo_demand": 311917
        }
    },
    {
        "iata": "FKB",
        "icao": "EDSB",
        "airportName": "Karlsruhe/Baden-Baden Airport",
        "location_country": "Germany",
        "location_city": "Baden-Wurttemberg",
        "location_state": "Karlsruhe / Baden-Baden",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "48.77939987",
        "longitude": "8.080499649",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9787",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 393520,
            "s_seats": 92888,
            "j_seats": 148556,
            "f_seats": 22197,
            "r_seats": 10000,
            "cargo_demand": 11335106
        }
    },
    {
        "iata": "FKI",
        "icao": "FZIC",
        "airportName": "Bangoka International Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Kisangani",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.481638998",
        "longitude": "25.33799934",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149276,
            "s_seats": 32846,
            "j_seats": 27152,
            "f_seats": 7923,
            "r_seats": 3321,
            "cargo_demand": 443753
        }
    },
    {
        "iata": "FKJ",
        "icao": "RJNF",
        "airportName": "Fukui Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Fukui",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.14279938",
        "longitude": "136.223999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4329",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113711,
            "s_seats": 25634,
            "j_seats": 40082,
            "f_seats": 13001,
            "r_seats": 8206,
            "cargo_demand": 115937
        }
    },
    {
        "iata": "FKL",
        "icao": "KFKL",
        "airportName": "Venango Regional Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Franklin",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.37789917",
        "longitude": "-79.86039734",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3698",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148250,
            "s_seats": 36778,
            "j_seats": 25316,
            "f_seats": 1706,
            "r_seats": 8835,
            "cargo_demand": 489634
        }
    },
    {
        "iata": "FKN",
        "icao": "KFKN",
        "airportName": "Franklin Municipal-John Beverly Rose Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Franklin",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.698101",
        "longitude": "-76.903801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4977",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4098",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2745,
            "s_seats": 2752,
            "j_seats": 1585,
            "f_seats": 324,
            "r_seats": 31,
            "cargo_demand": 1183
        }
    },
    {
        "iata": "FKQ",
        "icao": "WASF",
        "airportName": "Fakfak Torea Airport",
        "location_country": "Indonesia",
        "location_city": "Fakfak",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.920190096",
        "longitude": "132.2669983",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3415",
                "runway_width": "65",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62520,
            "s_seats": 28422,
            "j_seats": 17853,
            "f_seats": 7770,
            "r_seats": 8202,
            "cargo_demand": 61081
        }
    },
    {
        "iata": "FKS",
        "icao": "RJSF",
        "airportName": "Fukushima Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Sukagawa",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.22740173",
        "longitude": "140.4309998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8202",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72901,
            "s_seats": 47074,
            "j_seats": 51516,
            "f_seats": 11343,
            "r_seats": 5222,
            "cargo_demand": 160994
        }
    },
    {
        "iata": "FLA",
        "icao": "SKFL",
        "airportName": "Gustavo Artunduaga Paredes Airport",
        "location_country": "Colombia",
        "location_city": "Florencia (Caqueta)",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.58919",
        "longitude": "-75.5644",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192432,
            "s_seats": 34094,
            "j_seats": 16110,
            "f_seats": 17572,
            "r_seats": 8636,
            "cargo_demand": 434354
        }
    },
    {
        "iata": "FLB",
        "icao": "SNQG",
        "airportName": "Cangapara Airport",
        "location_country": "Brazil",
        "location_city": "Piaui",
        "location_state": "Floriano",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.846389771",
        "longitude": "-43.07730103",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5906",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1396,
            "s_seats": 1732,
            "j_seats": 1632,
            "f_seats": 128,
            "r_seats": 116,
            "cargo_demand": 41027
        }
    },
    {
        "iata": "FLD",
        "icao": "KFLD",
        "airportName": "Fond du Lac County Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Fond du Lac",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.77119827",
        "longitude": "-88.48840332",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3602",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5941",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3856,
            "s_seats": 2474,
            "j_seats": 1171,
            "f_seats": 23,
            "r_seats": 137,
            "cargo_demand": 87896
        }
    },
    {
        "iata": "FLF",
        "icao": "EDXF",
        "airportName": "Flensburg-SchAferhaus Airport",
        "location_country": "Germany",
        "location_city": "Schleswig-Holstein",
        "location_state": "Flensburg",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "54.7733345",
        "longitude": "9.378889084",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2297",
                "runway_width": "148",
                "runway_material": "GRS"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4003",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11L/29R",
                "runway_length": "3937",
                "runway_width": "197",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3078,
            "s_seats": 2540,
            "j_seats": 1198,
            "f_seats": 79,
            "r_seats": 188,
            "cargo_demand": 80202
        }
    },
    {
        "iata": "FLG",
        "icao": "KFLG",
        "airportName": "Flagstaff Pulliam Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Flagstaff",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "35.1385",
        "longitude": "-111.670998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6999",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123426,
            "s_seats": 80853,
            "j_seats": 100816,
            "f_seats": 39169,
            "r_seats": 10000,
            "cargo_demand": 1294424
        }
    },
    {
        "iata": "FLL",
        "icao": "KFLL",
        "airportName": "Fort Lauderdale-Hollywood International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Fort Lauderdale / Hollywood",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "26.072599",
        "longitude": "-80.152702",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6930",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 435963,
            "s_seats": 81064,
            "j_seats": 31759,
            "f_seats": 56255,
            "r_seats": 10000,
            "cargo_demand": 10142202
        }
    },
    {
        "iata": "FLN",
        "icao": "SBFL",
        "airportName": "Hercilio Luz International Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "Florianopolis",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "large_airport",
        "latitude": "-27.67027855",
        "longitude": "-48.55250168",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "3/21",
                "runway_length": "4921",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 251022,
            "s_seats": 99962,
            "j_seats": 47071,
            "f_seats": 68350,
            "r_seats": 10000,
            "cargo_demand": 22448401
        }
    },
    {
        "iata": "FLO",
        "icao": "KFLO",
        "airportName": "Florence Regional Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Florence",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.1853981",
        "longitude": "-79.72389984",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5999",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "6499",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179387,
            "s_seats": 25658,
            "j_seats": 43753,
            "f_seats": 5025,
            "r_seats": 8935,
            "cargo_demand": 192259
        }
    },
    {
        "iata": "FLP",
        "icao": "KFLP",
        "airportName": "Marion County Regional Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Flippin",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.29090118",
        "longitude": "-92.59030151",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2489,
            "s_seats": 1758,
            "j_seats": 1720,
            "f_seats": 36,
            "r_seats": 196,
            "cargo_demand": 42337
        }
    },
    {
        "iata": "FLR",
        "icao": "LIRQ",
        "airportName": "Florence Airport",
        "location_country": "Italy",
        "location_city": "Tuscany",
        "location_state": "Florence",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.810001",
        "longitude": "11.2051",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5425",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179740,
            "s_seats": 36445,
            "j_seats": 21088,
            "f_seats": 9668,
            "r_seats": 8142,
            "cargo_demand": 95738
        }
    },
    {
        "iata": "FLS",
        "icao": "YFLI",
        "airportName": "Flinders Island Airport",
        "location_country": "Australia",
        "location_city": "Tasmania",
        "location_state": "Flinders Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-40.09170151",
        "longitude": "147.9929962",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3510",
                "runway_width": "",
                "runway_material": "B"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5643",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1336,
            "s_seats": 2931,
            "j_seats": 1764,
            "f_seats": 376,
            "r_seats": 179,
            "cargo_demand": 68191
        }
    },
    {
        "iata": "FLV",
        "icao": "KFLV",
        "airportName": "Sherman Army Airfield",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Fort Leavenworth",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.36830139",
        "longitude": "-94.91470337",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5905",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 1253,
            "s_seats": 1799,
            "j_seats": 1496,
            "f_seats": 371,
            "r_seats": 63,
            "cargo_demand": 16618
        }
    },
    {
        "iata": "FLW",
        "icao": "LPFL",
        "airportName": "Flores Airport",
        "location_country": "Portugal",
        "location_city": "Azores",
        "location_state": "Flores Island",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "39.45529938",
        "longitude": "-31.13139915",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50422,
            "s_seats": 34287,
            "j_seats": 28091,
            "f_seats": 10072,
            "r_seats": 7018,
            "cargo_demand": 460844
        }
    },
    {
        "iata": "FLX",
        "icao": "KFLX",
        "airportName": "Fallon Municipal Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Fallon",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.49909973",
        "longitude": "-118.7490005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5703",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4207",
                "runway_width": "100",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 3144,
            "s_seats": 2025,
            "j_seats": 1581,
            "f_seats": 58,
            "r_seats": 16,
            "cargo_demand": 60662
        }
    },
    {
        "iata": "FLY",
        "icao": "YFIL",
        "airportName": "Finley Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Finley",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-35.66669846",
        "longitude": "145.5500031",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2952",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 2646,
            "s_seats": 1836,
            "j_seats": 1675,
            "f_seats": 337,
            "r_seats": 186,
            "cargo_demand": 36262
        }
    },
    {
        "iata": "FLZ",
        "icao": "WIMS",
        "airportName": "Ferdinand Lumban Tobing Airport",
        "location_country": "Indonesia",
        "location_city": "Sibolga",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.557127",
        "longitude": "98.887145",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5655",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170498,
            "s_seats": 36626,
            "j_seats": 26200,
            "f_seats": 6637,
            "r_seats": 5005,
            "cargo_demand": 491402
        }
    },
    {
        "iata": "FMA",
        "icao": "SARF",
        "airportName": "Formosa International Airport",
        "location_country": "Argentina",
        "location_city": "Formosa",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.2127",
        "longitude": "-58.2281",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "5905",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135946,
            "s_seats": 29453,
            "j_seats": 23471,
            "f_seats": 1511,
            "r_seats": 9162,
            "cargo_demand": 454844
        }
    },
    {
        "iata": "FME",
        "icao": "KFME",
        "airportName": "Tipton Airport",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Fort Meade / Odenton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.08539963",
        "longitude": "-76.75939941",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90387,
            "s_seats": 33113,
            "j_seats": 58897,
            "f_seats": 14299,
            "r_seats": 8546,
            "cargo_demand": 280333
        }
    },
    {
        "iata": "FMH",
        "icao": "KFMH",
        "airportName": "Otis Air National Guard Base",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Falmouth",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.657899",
        "longitude": "-70.52163",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "14/32",
                "runway_length": "9500",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 1513,
            "s_seats": 2137,
            "j_seats": 1480,
            "f_seats": 416,
            "r_seats": 114,
            "cargo_demand": 3424
        }
    },
    {
        "iata": "FMI",
        "icao": "FZRF",
        "airportName": "Kalemie Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Kalemie",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.875559807",
        "longitude": "29.25",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5741",
                "runway_width": "90",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 119038,
            "s_seats": 41807,
            "j_seats": 51604,
            "f_seats": 8416,
            "r_seats": 5731,
            "cargo_demand": 114293
        }
    },
    {
        "iata": "FMM",
        "icao": "EDJA",
        "airportName": "Memmingen Airport",
        "location_country": "Germany",
        "location_city": "Bavaria",
        "location_state": "Memmingen",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.98880005",
        "longitude": "10.23950005",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9780",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74536,
            "s_seats": 37077,
            "j_seats": 58621,
            "f_seats": 19025,
            "r_seats": 6128,
            "cargo_demand": 116084
        }
    },
    {
        "iata": "FMN",
        "icao": "KFMN",
        "airportName": "Four Corners Regional Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Farmington",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.74119949",
        "longitude": "-108.2300034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "6704",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108003,
            "s_seats": 45845,
            "j_seats": 8612,
            "f_seats": 17926,
            "r_seats": 6603,
            "cargo_demand": 114224
        }
    },
    {
        "iata": "FMO",
        "icao": "EDDG",
        "airportName": "Munster Osnabruck International Airport",
        "location_country": "Germany",
        "location_city": "North Rhine-Westphalia",
        "location_state": "Munster / Osnabruck (LS)",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "52.13460159",
        "longitude": "7.684830189",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7119",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 440617,
            "s_seats": 73420,
            "j_seats": 33721,
            "f_seats": 46204,
            "r_seats": 10000,
            "cargo_demand": 18414253
        }
    },
    {
        "iata": "FMS",
        "icao": "KFSW",
        "airportName": "Fort Madison Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Fort Madison",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.659113",
        "longitude": "-91.327763",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4002",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1227,
            "s_seats": 1676,
            "j_seats": 1892,
            "f_seats": 195,
            "r_seats": 62,
            "cargo_demand": 68833
        }
    },
    {
        "iata": "FMY",
        "icao": "KFMY",
        "airportName": "Page Field",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Fort Myers",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "26.58659935",
        "longitude": "-81.86329651",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6406",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4912",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54883,
            "s_seats": 33199,
            "j_seats": 7663,
            "f_seats": 6139,
            "r_seats": 8153,
            "cargo_demand": 443323
        }
    },
    {
        "iata": "FNA",
        "icao": "GFLL",
        "airportName": "Lungi International Airport",
        "location_country": "Sierra Leone",
        "location_city": "Freetown",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "8.61644",
        "longitude": "-13.1955",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "10498",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 234188,
            "s_seats": 82321,
            "j_seats": 89931,
            "f_seats": 42413,
            "r_seats": 10000,
            "cargo_demand": 4499695
        }
    },
    {
        "iata": "FNC",
        "icao": "LPMA",
        "airportName": "Madeira Airport",
        "location_country": "Portugal",
        "location_city": "Madeira",
        "location_state": "Funchal",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "32.697899",
        "longitude": "-16.7745",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9110",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64823,
            "s_seats": 49432,
            "j_seats": 58627,
            "f_seats": 16532,
            "r_seats": 8400,
            "cargo_demand": 149798
        }
    },
    {
        "iata": "FNI",
        "icao": "LFTW",
        "airportName": "NImes-Ales-Camargue-Cevennes Airport",
        "location_country": "France",
        "location_city": "Languedoc-Roussillon",
        "location_state": "NImes",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.75740051",
        "longitude": "4.416349888",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8005",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 180639,
            "s_seats": 36970,
            "j_seats": 44168,
            "f_seats": 13243,
            "r_seats": 3971,
            "cargo_demand": 321420
        }
    },
    {
        "iata": "FNJ",
        "icao": "ZKPY",
        "airportName": "Pyongyang Sunan International Airport",
        "location_country": "North Korea",
        "location_city": "Pyongyang",
        "location_state": "",
        "time_zone": "UTC+08:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.224098",
        "longitude": "125.669998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "12475",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "11490",
                "runway_width": "230",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 164415,
            "s_seats": 44316,
            "j_seats": 13463,
            "f_seats": 8764,
            "r_seats": 5379,
            "cargo_demand": 318182
        }
    },
    {
        "iata": "FNL",
        "icao": "KFNL",
        "airportName": "Fort Collins-Loveland Municipal Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Fort Collins/Loveland",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.448763",
        "longitude": "-105.011244",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2273",
                "runway_width": "40",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "8500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3493,
            "s_seats": 1896,
            "j_seats": 1388,
            "f_seats": 54,
            "r_seats": 149,
            "cargo_demand": 54968
        }
    },
    {
        "iata": "FNT",
        "icao": "KFNT",
        "airportName": "Bishop International Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Flint",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.9654007",
        "longitude": "-83.74359894",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7201",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7849",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79155,
            "s_seats": 41410,
            "j_seats": 58984,
            "f_seats": 4762,
            "r_seats": 3952,
            "cargo_demand": 485548
        }
    },
    {
        "iata": "FNU",
        "icao": "LIER",
        "airportName": "Oristano-Fenosu Airport",
        "location_country": "Italy",
        "location_city": "Sardinia",
        "location_state": "Oristano",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "39.895308",
        "longitude": "8.642661",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3934",
                "runway_width": "98",
                "runway_material": "BITUMINOUS"
            }
        ],
        "demand": {
            "y_seats": 2441,
            "s_seats": 2295,
            "j_seats": 1834,
            "f_seats": 142,
            "r_seats": 23,
            "cargo_demand": 75728
        }
    },
    {
        "iata": "FOC",
        "icao": "ZSFZ",
        "airportName": "Fuzhou Changle International Airport",
        "location_country": "China",
        "location_city": "Fujian",
        "location_state": "Fuzhou",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "25.93510056",
        "longitude": "119.663002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "11841",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 347427,
            "s_seats": 88266,
            "j_seats": 92527,
            "f_seats": 30033,
            "r_seats": 10000,
            "cargo_demand": 25632026
        }
    },
    {
        "iata": "FOD",
        "icao": "KFOD",
        "airportName": "Fort Dodge Regional Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Fort Dodge",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.55149841",
        "longitude": "-94.19259644",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6548",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90655,
            "s_seats": 28507,
            "j_seats": 25653,
            "f_seats": 13990,
            "r_seats": 4037,
            "cargo_demand": 426058
        }
    },
    {
        "iata": "FOE",
        "icao": "KFOE",
        "airportName": "Topeka Regional Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Topeka",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.950901",
        "longitude": "-95.663597",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "12802",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 172212,
            "s_seats": 42742,
            "j_seats": 36456,
            "f_seats": 16751,
            "r_seats": 9208,
            "cargo_demand": 106670
        }
    },
    {
        "iata": "FOG",
        "icao": "LIBF",
        "airportName": "\"Foggia \"\"Gino Lisa\"\" Airport\"",
        "location_country": "Italy",
        "location_city": "Apulia",
        "location_state": "Foggia",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "41.432899",
        "longitude": "15.535",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4724",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162085,
            "s_seats": 32385,
            "j_seats": 48712,
            "f_seats": 10080,
            "r_seats": 3330,
            "cargo_demand": 152828
        }
    },
    {
        "iata": "FOK",
        "icao": "KFOK",
        "airportName": "Francis S. Gabreski Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Westhampton Beach",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.84370041",
        "longitude": "-72.63179779",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "06/24",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1838,
            "s_seats": 2980,
            "j_seats": 1326,
            "f_seats": 178,
            "r_seats": 180,
            "cargo_demand": 44713
        }
    },
    {
        "iata": "FOM",
        "icao": "FKKM",
        "airportName": "Foumban Nkounja Airport",
        "location_country": "Cameroon",
        "location_city": "Foumban",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.636919975",
        "longitude": "10.75080013",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6889",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144665,
            "s_seats": 41192,
            "j_seats": 54689,
            "f_seats": 4885,
            "r_seats": 5497,
            "cargo_demand": 375624
        }
    },
    {
        "iata": "FON",
        "icao": "MRAN",
        "airportName": "La Fortuna Arenal Airport",
        "location_country": "Costa Rica",
        "location_city": "La Fortuna",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "10.469311",
        "longitude": "-84.579073",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2625",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1379,
            "s_seats": 2956,
            "j_seats": 1287,
            "f_seats": 457,
            "r_seats": 195,
            "cargo_demand": 16097
        }
    },
    {
        "iata": "FOR",
        "icao": "SBFZ",
        "airportName": "Pinto Martins - Fortaleza International Airport",
        "location_country": "Brazil",
        "location_city": "Ceara",
        "location_state": "Fortaleza",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.77628",
        "longitude": "-38.5326",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8350",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144044,
            "s_seats": 37026,
            "j_seats": 12760,
            "f_seats": 13629,
            "r_seats": 8862,
            "cargo_demand": 178317
        }
    },
    {
        "iata": "FOS",
        "icao": "YFRT",
        "airportName": "Forrest Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Forrest",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-30.83810043",
        "longitude": "128.1150055",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4426",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4984",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173714,
            "s_seats": 29839,
            "j_seats": 34760,
            "f_seats": 4305,
            "r_seats": 7918,
            "cargo_demand": 404751
        }
    },
    {
        "iata": "FPO",
        "icao": "MYGF",
        "airportName": "Grand Bahama International Airport",
        "location_country": "Bahamas",
        "location_city": "Grand Bahama",
        "location_state": "Freeport",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "26.55870056",
        "longitude": "-78.69560242",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "11019",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89068,
            "s_seats": 31634,
            "j_seats": 42469,
            "f_seats": 14667,
            "r_seats": 7242,
            "cargo_demand": 449270
        }
    },
    {
        "iata": "FPR",
        "icao": "KFPR",
        "airportName": "St. Lucie County International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Fort Pierce",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "27.49510002",
        "longitude": "-80.36830139",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10R/28L",
                "runway_length": "6492",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4756",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101775,
            "s_seats": 48244,
            "j_seats": 5923,
            "f_seats": 3385,
            "r_seats": 8465,
            "cargo_demand": 219708
        }
    },
    {
        "iata": "FRA",
        "icao": "EDDF",
        "airportName": "Frankfurt Airport",
        "location_country": "Germany",
        "location_city": "Hessen",
        "location_state": "Frankfurt",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "50.033333",
        "longitude": "8.570556",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07C/25C",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 447412,
            "s_seats": 75935,
            "j_seats": 26209,
            "f_seats": 25866,
            "r_seats": 10000,
            "cargo_demand": 6571031
        }
    },
    {
        "iata": "FRB",
        "icao": "YFBS",
        "airportName": "Forbes Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Forbes",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-33.363602",
        "longitude": "147.934998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4029",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106921,
            "s_seats": 43847,
            "j_seats": 33894,
            "f_seats": 19082,
            "r_seats": 4354,
            "cargo_demand": 496441
        }
    },
    {
        "iata": "FRC",
        "icao": "SIMK",
        "airportName": "Franca Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Franca",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-20.592199",
        "longitude": "-47.3829",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3379,
            "s_seats": 1782,
            "j_seats": 1798,
            "f_seats": 254,
            "r_seats": 24,
            "cargo_demand": 94874
        }
    },
    {
        "iata": "FRD",
        "icao": "KFHR",
        "airportName": "Friday Harbor Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Friday Harbor",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "48.52199936",
        "longitude": "-123.0240021",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3402",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2484,
            "s_seats": 1740,
            "j_seats": 1716,
            "f_seats": 428,
            "r_seats": 13,
            "cargo_demand": 87112
        }
    },
    {
        "iata": "FRE",
        "icao": "AGGF",
        "airportName": "Fera Airport",
        "location_country": "Solomon Islands",
        "location_city": "Isabel Province",
        "location_state": "Fera Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.1075",
        "longitude": "159.576996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "1854",
                "runway_width": "82",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 2030,
            "s_seats": 1845,
            "j_seats": 1050,
            "f_seats": 90,
            "r_seats": 56,
            "cargo_demand": 12058
        }
    },
    {
        "iata": "FRG",
        "icao": "KFRG",
        "airportName": "Republic Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Farmingdale",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.72880173",
        "longitude": "-73.41339874",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5516",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6827",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63362,
            "s_seats": 49582,
            "j_seats": 32400,
            "f_seats": 18339,
            "r_seats": 9909,
            "cargo_demand": 412684
        }
    },
    {
        "iata": "FRH",
        "icao": "KFRH",
        "airportName": "French Lick Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "French Lick",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.50619888",
        "longitude": "-86.63690186",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3918,
            "s_seats": 2045,
            "j_seats": 1968,
            "f_seats": 462,
            "r_seats": 96,
            "cargo_demand": 5691
        }
    },
    {
        "iata": "FRI",
        "icao": "KFRI",
        "airportName": "Marshall Army Airfield",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Fort Riley",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.053021",
        "longitude": "-96.764202",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 63041,
            "s_seats": 46403,
            "j_seats": 14787,
            "f_seats": 8636,
            "r_seats": 5586,
            "cargo_demand": 431294
        }
    },
    {
        "iata": "FRL",
        "icao": "LIPK",
        "airportName": "Forl\u00ec International Airport",
        "location_country": "Italy",
        "location_city": "Emilia-Romagna",
        "location_state": "Forl\u00ec",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "44.194801",
        "longitude": "12.0701",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7907",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54323,
            "s_seats": 45410,
            "j_seats": 30228,
            "f_seats": 12394,
            "r_seats": 3130,
            "cargo_demand": 103646
        }
    },
    {
        "iata": "FRM",
        "icao": "KFRM",
        "airportName": "Fairmont Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Fairmont",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.64390183",
        "longitude": "-94.41560364",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3300",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5505",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2852,
            "s_seats": 2748,
            "j_seats": 1197,
            "f_seats": 91,
            "r_seats": 120,
            "cargo_demand": 20655
        }
    },
    {
        "iata": "FRO",
        "icao": "ENFL",
        "airportName": "Floro Airport",
        "location_country": "Norway",
        "location_city": "Floro",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "61.58359909",
        "longitude": "5.024720192",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4144",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171150,
            "s_seats": 37120,
            "j_seats": 42550,
            "f_seats": 6556,
            "r_seats": 5359,
            "cargo_demand": 389941
        }
    },
    {
        "iata": "FRQ",
        "icao": "AYFE",
        "airportName": "Feramin Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Feramin",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.208",
        "longitude": "141.6988",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2165",
                "runway_width": "98",
                "runway_material": "Grassed brown clay"
            }
        ],
        "demand": {
            "y_seats": 2622,
            "s_seats": 2309,
            "j_seats": 1652,
            "f_seats": 360,
            "r_seats": 106,
            "cargo_demand": 78781
        }
    },
    {
        "iata": "FRR",
        "icao": "KFRR",
        "airportName": "Front Royal-Warren County Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Front Royal",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.91749954",
        "longitude": "-78.25350189",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3007",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3561,
            "s_seats": 1675,
            "j_seats": 1331,
            "f_seats": 449,
            "r_seats": 2,
            "cargo_demand": 48960
        }
    },
    {
        "iata": "FRS",
        "icao": "MGTK",
        "airportName": "Mundo Maya International Airport",
        "location_country": "Guatemala",
        "location_city": "Flores",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.91379929",
        "longitude": "-89.86640167",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 86084,
            "s_seats": 43805,
            "j_seats": 52312,
            "f_seats": 1874,
            "r_seats": 6102,
            "cargo_demand": 314608
        }
    },
    {
        "iata": "FRT",
        "icao": "SCFI",
        "airportName": "Frutillar Airport",
        "location_country": "Chile",
        "location_city": "Frutillar",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "small_airport",
        "latitude": "-41.09500122",
        "longitude": "-73.12666321",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "1641",
                "runway_width": "49",
                "runway_material": "GRASS"
            }
        ],
        "demand": {
            "y_seats": 3667,
            "s_seats": 2465,
            "j_seats": 1176,
            "f_seats": 488,
            "r_seats": 33,
            "cargo_demand": 14150
        }
    },
    {
        "iata": "FRU",
        "icao": "UAFM",
        "airportName": "Manas International Airport",
        "location_country": "Kyrgyzstan",
        "location_city": "Bishkek",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.06129837",
        "longitude": "74.4776001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "13780",
                "runway_width": "180",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 365785,
            "s_seats": 81204,
            "j_seats": 140085,
            "f_seats": 21610,
            "r_seats": 10000,
            "cargo_demand": 14344494
        }
    },
    {
        "iata": "FRW",
        "icao": "FBFT",
        "airportName": "Francistown Airport",
        "location_country": "Botswana",
        "location_city": "Francistown",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.1595993",
        "longitude": "27.47450066",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7218",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "9843",
                "runway_width": "165",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4147",
                "runway_width": "72",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 151301,
            "s_seats": 34613,
            "j_seats": 42318,
            "f_seats": 9221,
            "r_seats": 6378,
            "cargo_demand": 137520
        }
    },
    {
        "iata": "FRY",
        "icao": "KIZG",
        "airportName": "Eastern Slopes Regional Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Fryeburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.99110031",
        "longitude": "-70.94789886",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4200",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3500,
            "s_seats": 2136,
            "j_seats": 1473,
            "f_seats": 24,
            "r_seats": 1,
            "cargo_demand": 78981
        }
    },
    {
        "iata": "FRZ",
        "icao": "ETHF",
        "airportName": "Fritzlar Air Base",
        "location_country": "Germany",
        "location_city": "Hesse",
        "location_state": "Fritzlar",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.1146",
        "longitude": "9.286",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3413",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 98781,
            "s_seats": 30148,
            "j_seats": 44060,
            "f_seats": 7255,
            "r_seats": 8962,
            "cargo_demand": 395806
        }
    },
    {
        "iata": "FSC",
        "icao": "LFKF",
        "airportName": "Figari-Sud Corse Airport",
        "location_country": "France",
        "location_city": "Corsica",
        "location_state": "Figari",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "41.500599",
        "longitude": "9.09778",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8136",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169686,
            "s_seats": 26574,
            "j_seats": 31147,
            "f_seats": 7271,
            "r_seats": 3365,
            "cargo_demand": 121484
        }
    },
    {
        "iata": "FSD",
        "icao": "KFSD",
        "airportName": "Sioux Falls Regional Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Sioux Falls",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.585463",
        "longitude": "-96.741152",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8999",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3152",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "15/33",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53051,
            "s_seats": 44025,
            "j_seats": 38274,
            "f_seats": 14533,
            "r_seats": 6874,
            "cargo_demand": 192712
        }
    },
    {
        "iata": "FSI",
        "icao": "KFSI",
        "airportName": "Henry Post Army Airfield (Fort Sill)",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Lawton",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.64979935",
        "longitude": "-98.40219879",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 156444,
            "s_seats": 39673,
            "j_seats": 17825,
            "f_seats": 12343,
            "r_seats": 9512,
            "cargo_demand": 391738
        }
    },
    {
        "iata": "FSK",
        "icao": "KFSK",
        "airportName": "Fort Scott Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Fort Scott",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.79840088",
        "longitude": "-94.76940155",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4403",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1867,
            "s_seats": 2715,
            "j_seats": 1342,
            "f_seats": 119,
            "r_seats": 74,
            "cargo_demand": 19872
        }
    },
    {
        "iata": "FSM",
        "icao": "KFSM",
        "airportName": "Fort Smith Regional Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Fort Smith",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "35.33660126",
        "longitude": "-94.36740112",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 334618,
            "s_seats": 86268,
            "j_seats": 140237,
            "f_seats": 31950,
            "r_seats": 10000,
            "cargo_demand": 16132128
        }
    },
    {
        "iata": "FSP",
        "icao": "LFVP",
        "airportName": "Saint-Pierre Airport",
        "location_country": "Saint Pierre and Miquelon",
        "location_city": "Saint-Pierre",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.76290131",
        "longitude": "-56.17309952",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5900",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80958,
            "s_seats": 28044,
            "j_seats": 5871,
            "f_seats": 5500,
            "r_seats": 3642,
            "cargo_demand": 267416
        }
    },
    {
        "iata": "FSS",
        "icao": "EGQK",
        "airportName": "RAF Kinloss",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Forres",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "57.6493988",
        "longitude": "-3.560640097",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7582",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90187,
            "s_seats": 39600,
            "j_seats": 52333,
            "f_seats": 18993,
            "r_seats": 9019,
            "cargo_demand": 53693
        }
    },
    {
        "iata": "FST",
        "icao": "KFST",
        "airportName": "Fort Stockton-Pecos County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Fort Stockton",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.91570091",
        "longitude": "-102.9160004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4399",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "5200",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3900",
                "runway_width": "100",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7507",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3981",
                "runway_width": "150",
                "runway_material": "TURF"
            }
        ],
        "demand": {
            "y_seats": 136853,
            "s_seats": 49667,
            "j_seats": 35834,
            "f_seats": 16936,
            "r_seats": 6972,
            "cargo_demand": 305281
        }
    },
    {
        "iata": "FSU",
        "icao": "KFSU",
        "airportName": "Fort Sumner Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Fort Sumner",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.48339844",
        "longitude": "-104.2170029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5800",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "5300",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3172,
            "s_seats": 2259,
            "j_seats": 1972,
            "f_seats": 244,
            "r_seats": 149,
            "cargo_demand": 63985
        }
    },
    {
        "iata": "FTA",
        "icao": "NVVF",
        "airportName": "Futuna Airport",
        "location_country": "Vanuatu",
        "location_city": "Tafea",
        "location_state": "Futuna Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-19.51639938",
        "longitude": "170.2319946",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2297",
                "runway_width": "115",
                "runway_material": "Coral"
            }
        ],
        "demand": {
            "y_seats": 2372,
            "s_seats": 2017,
            "j_seats": 1406,
            "f_seats": 29,
            "r_seats": 193,
            "cargo_demand": 32148
        }
    },
    {
        "iata": "FTI",
        "icao": "NSFQ",
        "airportName": "Fitiuta Airport",
        "location_country": "United States",
        "location_city": "American Samoa",
        "location_state": "Fitiuta",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.2172",
        "longitude": "-169.425003",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3190",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 100283,
            "s_seats": 37847,
            "j_seats": 13999,
            "f_seats": 14072,
            "r_seats": 9539,
            "cargo_demand": 307569
        }
    },
    {
        "iata": "FTK",
        "icao": "KFTK",
        "airportName": "Godman Army Airfield",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Fort Knox",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.90710068",
        "longitude": "-85.9720993",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "1900",
                "runway_width": "90",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4999",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5253",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5185",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162086,
            "s_seats": 33810,
            "j_seats": 10632,
            "f_seats": 6827,
            "r_seats": 5844,
            "cargo_demand": 312524
        }
    },
    {
        "iata": "FTU",
        "icao": "FMSD",
        "airportName": "Tolanaro Airport",
        "location_country": "Madagascar",
        "location_city": "Tolanaro",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.038099",
        "longitude": "46.9561",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5280",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 85758,
            "s_seats": 25966,
            "j_seats": 5790,
            "f_seats": 14231,
            "r_seats": 7138,
            "cargo_demand": 72600
        }
    },
    {
        "iata": "FTW",
        "icao": "KFTW",
        "airportName": "Fort Worth Meacham International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Fort Worth",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "32.819801",
        "longitude": "-97.362396",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3677",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7501",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4006",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125830,
            "s_seats": 80525,
            "j_seats": 104596,
            "f_seats": 54765,
            "r_seats": 10000,
            "cargo_demand": 29032262
        }
    },
    {
        "iata": "FTX",
        "icao": "FCOO",
        "airportName": "Owando Airport",
        "location_country": "Republic of the Congo",
        "location_city": "Owando",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.531350017",
        "longitude": "15.95009995",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157183,
            "s_seats": 25593,
            "j_seats": 5740,
            "f_seats": 13360,
            "r_seats": 4276,
            "cargo_demand": 460768
        }
    },
    {
        "iata": "FTY",
        "icao": "KFTY",
        "airportName": "Fulton County Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Atlanta",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.77909851",
        "longitude": "-84.52140045",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5796",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2801",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4157",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179175,
            "s_seats": 26532,
            "j_seats": 28231,
            "f_seats": 11005,
            "r_seats": 4467,
            "cargo_demand": 315268
        }
    },
    {
        "iata": "FUE",
        "icao": "GCFV",
        "airportName": "Fuerteventura Airport",
        "location_country": "Spain",
        "location_city": "Canary Islands",
        "location_state": "Fuerteventura",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "28.4527",
        "longitude": "-13.8638",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "11598",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 320268,
            "s_seats": 74133,
            "j_seats": 110723,
            "f_seats": 54665,
            "r_seats": 10000,
            "cargo_demand": 14610471
        }
    },
    {
        "iata": "FUJ",
        "icao": "RJFE",
        "airportName": "Fukue Airport",
        "location_country": "Japan",
        "location_city": "Goto\u008d Islands",
        "location_state": "Goto\u008d",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.66630173",
        "longitude": "128.8329926",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6561",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99763,
            "s_seats": 33804,
            "j_seats": 11582,
            "f_seats": 11029,
            "r_seats": 8266,
            "cargo_demand": 394398
        }
    },
    {
        "iata": "FUK",
        "icao": "RJFF",
        "airportName": "Fukuoka Airport",
        "location_country": "Japan",
        "location_city": "Kyushu",
        "location_state": "Fukuoka",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "33.58589935",
        "longitude": "130.451004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9186",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 265555,
            "s_seats": 73133,
            "j_seats": 140927,
            "f_seats": 65886,
            "r_seats": 10000,
            "cargo_demand": 19015174
        }
    },
    {
        "iata": "FUL",
        "icao": "KFUL",
        "airportName": "Fullerton Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Fullerton",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.87200165",
        "longitude": "-117.9800034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3121",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1949,
            "s_seats": 2104,
            "j_seats": 1055,
            "f_seats": 457,
            "r_seats": 196,
            "cargo_demand": 65133
        }
    },
    {
        "iata": "FUN",
        "icao": "NGFU",
        "airportName": "Funafuti International Airport",
        "location_country": "Tuvalu",
        "location_city": "Funafuti",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.525",
        "longitude": "179.195999",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5040",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 198364,
            "s_seats": 27760,
            "j_seats": 35800,
            "f_seats": 10585,
            "r_seats": 6293,
            "cargo_demand": 85554
        }
    },
    {
        "iata": "FUT",
        "icao": "NLWF",
        "airportName": "Pointe Vele Airport",
        "location_country": "Wallis and Futuna",
        "location_city": "Futuna Island",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.31140041",
        "longitude": "-178.0659943",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3609",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 99035,
            "s_seats": 29171,
            "j_seats": 49957,
            "f_seats": 15822,
            "r_seats": 3541,
            "cargo_demand": 171476
        }
    },
    {
        "iata": "FVR",
        "icao": "YFRV",
        "airportName": "Forrest River Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Forrest River Mission",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.1647",
        "longitude": "127.8401",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3855",
                "runway_width": "",
                "runway_material": "Dirt"
            }
        ],
        "demand": {
            "y_seats": 3834,
            "s_seats": 1990,
            "j_seats": 1914,
            "f_seats": 105,
            "r_seats": 105,
            "cargo_demand": 37138
        }
    },
    {
        "iata": "FWA",
        "icao": "KFWA",
        "airportName": "Fort Wayne International Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Fort Wayne",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "40.97850037",
        "longitude": "-85.19509888",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "12000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "PEM"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 386953,
            "s_seats": 97203,
            "j_seats": 119090,
            "f_seats": 68512,
            "r_seats": 10000,
            "cargo_demand": 14414564
        }
    },
    {
        "iata": "FWH",
        "icao": "KNFW",
        "airportName": "NAS Fort Worth JRB / Carswell Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Fort Worth",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.769199",
        "longitude": "-97.441498",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "12000",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 126091,
            "s_seats": 38362,
            "j_seats": 17258,
            "f_seats": 6606,
            "r_seats": 8307,
            "cargo_demand": 303904
        }
    },
    {
        "iata": "FWL",
        "icao": "PAFW",
        "airportName": "Farewell Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Farewell",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "62.509327",
        "longitude": "-153.892279",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4600",
                "runway_width": "30",
                "runway_material": "GRVL-P"
            }
        ],
        "demand": {
            "y_seats": 2042,
            "s_seats": 2474,
            "j_seats": 1500,
            "f_seats": 456,
            "r_seats": 104,
            "cargo_demand": 49240
        }
    },
    {
        "iata": "FXE",
        "icao": "KFXE",
        "airportName": "Fort Lauderdale Executive Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Fort Lauderdale",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "26.19729996",
        "longitude": "-80.17070007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6001",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 85378,
            "s_seats": 47170,
            "j_seats": 15479,
            "f_seats": 19167,
            "r_seats": 3269,
            "cargo_demand": 458287
        }
    },
    {
        "iata": "FXY",
        "icao": "KFXY",
        "airportName": "Forest City Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Forest City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.23469925",
        "longitude": "-93.62409973",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2708",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5806",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1188,
            "s_seats": 1844,
            "j_seats": 1094,
            "f_seats": 444,
            "r_seats": 54,
            "cargo_demand": 96071
        }
    },
    {
        "iata": "FYM",
        "icao": "KFYM",
        "airportName": "Fayetteville Municipal Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Fayetteville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.05970001",
        "longitude": "-86.56400299",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5900",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1028,
            "s_seats": 2614,
            "j_seats": 1162,
            "f_seats": 466,
            "r_seats": 3,
            "cargo_demand": 99128
        }
    },
    {
        "iata": "FYT",
        "icao": "FTTY",
        "airportName": "Faya-Largeau Airport",
        "location_country": "Chad",
        "location_city": "Faya-Largeau",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.91710091",
        "longitude": "19.11109924",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155031,
            "s_seats": 46078,
            "j_seats": 33299,
            "f_seats": 1669,
            "r_seats": 8840,
            "cargo_demand": 120989
        }
    },
    {
        "iata": "FYU",
        "icao": "PFYU",
        "airportName": "Fort Yukon Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Fort Yukon",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "66.57150269",
        "longitude": "-145.25",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5810",
                "runway_width": "150",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 115710,
            "s_seats": 34574,
            "j_seats": 16414,
            "f_seats": 10641,
            "r_seats": 9963,
            "cargo_demand": 452852
        }
    },
    {
        "iata": "FYV",
        "icao": "KFYV",
        "airportName": "Drake Field",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Fayetteville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.00510025",
        "longitude": "-94.17009735",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6006",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72632,
            "s_seats": 31252,
            "j_seats": 22657,
            "f_seats": 15920,
            "r_seats": 8026,
            "cargo_demand": 479051
        }
    },
    {
        "iata": "GAD",
        "icao": "KGAD",
        "airportName": "Northeast Alabama Regional Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Gadsden",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.972599",
        "longitude": "-86.088996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6802",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4806",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3437,
            "s_seats": 2208,
            "j_seats": 1211,
            "f_seats": 499,
            "r_seats": 186,
            "cargo_demand": 62655
        }
    },
    {
        "iata": "GAE",
        "icao": "DTTG",
        "airportName": "Gabes - Matmata International Airport",
        "location_country": "Tunisia",
        "location_city": "Gabes",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.87689972",
        "longitude": "10.10330009",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3702",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193588,
            "s_seats": 25007,
            "j_seats": 57188,
            "f_seats": 13133,
            "r_seats": 7703,
            "cargo_demand": 487014
        }
    },
    {
        "iata": "GAF",
        "icao": "DTTF",
        "airportName": "Gafsa - Ksar International Airport",
        "location_country": "Tunisia",
        "location_city": "Gafsa",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.42200089",
        "longitude": "8.822500229",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9514",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108454,
            "s_seats": 39834,
            "j_seats": 21584,
            "f_seats": 15276,
            "r_seats": 8795,
            "cargo_demand": 242103
        }
    },
    {
        "iata": "GAG",
        "icao": "KGAG",
        "airportName": "Gage Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Gage",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.29550171",
        "longitude": "-99.77639771",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5415",
                "runway_width": "100",
                "runway_material": "ASPH-P"
            }
        ],
        "demand": {
            "y_seats": 1013,
            "s_seats": 1583,
            "j_seats": 1997,
            "f_seats": 441,
            "r_seats": 128,
            "cargo_demand": 86135
        }
    },
    {
        "iata": "GAH",
        "icao": "YGAY",
        "airportName": "Gayndah Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Gayndah",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.61440086",
        "longitude": "151.6190033",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4150",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1608,
            "s_seats": 2895,
            "j_seats": 1203,
            "f_seats": 425,
            "r_seats": 120,
            "cargo_demand": 49974
        }
    },
    {
        "iata": "GAI",
        "icao": "KGAI",
        "airportName": "Montgomery County Airpark",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Gaithersburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.16830063",
        "longitude": "-77.16600037",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4202",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2283,
            "s_seats": 2414,
            "j_seats": 1170,
            "f_seats": 48,
            "r_seats": 173,
            "cargo_demand": 20928
        }
    },
    {
        "iata": "GAJ",
        "icao": "RJSC",
        "airportName": "Yamagata Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Yamagata",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.4119",
        "longitude": "140.371002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6560",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186386,
            "s_seats": 42996,
            "j_seats": 13774,
            "f_seats": 4145,
            "r_seats": 4101,
            "cargo_demand": 420683
        }
    },
    {
        "iata": "GAL",
        "icao": "PAGA",
        "airportName": "Edward G. Pitka Sr. Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Galena",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "64.73619843",
        "longitude": "-156.9369965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2786",
                "runway_width": "80",
                "runway_material": "GVL"
            },
            {
                "runway_name": "07/25",
                "runway_length": "7254",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 126533,
            "s_seats": 37826,
            "j_seats": 48141,
            "f_seats": 11059,
            "r_seats": 6658,
            "cargo_demand": 354704
        }
    },
    {
        "iata": "GAM",
        "icao": "PAGM",
        "airportName": "Gambell Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Gambell",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "63.76679993",
        "longitude": "-171.7330017",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4501",
                "runway_width": "96",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53680,
            "s_seats": 38145,
            "j_seats": 11782,
            "f_seats": 17283,
            "r_seats": 8979,
            "cargo_demand": 262913
        }
    },
    {
        "iata": "GAN",
        "icao": "VRMG",
        "airportName": "Gan International Airport",
        "location_country": "Maldives",
        "location_city": "Addu Atoll",
        "location_state": "Gan Island",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.693342",
        "longitude": "73.155602",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "12000",
                "runway_width": "165",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 180222,
            "s_seats": 35748,
            "j_seats": 11764,
            "f_seats": 5937,
            "r_seats": 8007,
            "cargo_demand": 114837
        }
    },
    {
        "iata": "GAO",
        "icao": "MUGT",
        "airportName": "Mariana Grajales Airport",
        "location_country": "Cuba",
        "location_city": "Guantanamo",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "20.08530045",
        "longitude": "-75.15830231",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8025",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109931,
            "s_seats": 41075,
            "j_seats": 5968,
            "f_seats": 13581,
            "r_seats": 8572,
            "cargo_demand": 151744
        }
    },
    {
        "iata": "GAQ",
        "icao": "GAGO",
        "airportName": "Gao International Airport",
        "location_country": "Mali",
        "location_city": "Gao",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.24839973",
        "longitude": "-0.005456",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186747,
            "s_seats": 28498,
            "j_seats": 23503,
            "f_seats": 10926,
            "r_seats": 5781,
            "cargo_demand": 76453
        }
    },
    {
        "iata": "GAS",
        "icao": "HKGA",
        "airportName": "Garissa Airport",
        "location_country": "Kenya",
        "location_city": "Garissa",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.46350801",
        "longitude": "39.64830017",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3556",
                "runway_width": "61",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 2152,
            "s_seats": 2206,
            "j_seats": 1953,
            "f_seats": 169,
            "r_seats": 113,
            "cargo_demand": 44306
        }
    },
    {
        "iata": "GAT",
        "icao": "LFNA",
        "airportName": "Gap-Tallard Airport",
        "location_country": "France",
        "location_city": "Provence-Alpes-Cote d'Azur",
        "location_state": "Gap",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "44.455002",
        "longitude": "6.03778",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02L/20R",
                "runway_length": "3100",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "2296",
                "runway_width": "262",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3549,
            "s_seats": 2488,
            "j_seats": 1055,
            "f_seats": 299,
            "r_seats": 124,
            "cargo_demand": 22806
        }
    },
    {
        "iata": "GAU",
        "icao": "VEGT",
        "airportName": "Lokpriya Gopinath Bordoloi International Airport",
        "location_country": "India",
        "location_city": "Assam",
        "location_state": "Guwahati",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.10610008",
        "longitude": "91.58589935",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142615,
            "s_seats": 45183,
            "j_seats": 51202,
            "f_seats": 18082,
            "r_seats": 7539,
            "cargo_demand": 417598
        }
    },
    {
        "iata": "GAY",
        "icao": "VEGY",
        "airportName": "Gaya Airport",
        "location_country": "India",
        "location_city": "Bihar",
        "location_state": "Gaya",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.74430084",
        "longitude": "84.95120239",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7500",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166225,
            "s_seats": 43708,
            "j_seats": 47740,
            "f_seats": 1430,
            "r_seats": 7417,
            "cargo_demand": 146920
        }
    },
    {
        "iata": "GBA",
        "icao": "EGBP",
        "airportName": "Cotswold Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Kemble",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "51.668095",
        "longitude": "-2.05694",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1148",
                "runway_width": "66",
                "runway_material": "GRS"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6591",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08L/26R",
                "runway_length": "1476",
                "runway_width": "66",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "1787",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1327,
            "s_seats": 2011,
            "j_seats": 1300,
            "f_seats": 245,
            "r_seats": 19,
            "cargo_demand": 29241
        }
    },
    {
        "iata": "GBD",
        "icao": "KGBD",
        "airportName": "Great Bend Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Great Bend",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.34429932",
        "longitude": "-98.85919952",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4698",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7850",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3972,
            "s_seats": 2655,
            "j_seats": 1739,
            "f_seats": 347,
            "r_seats": 64,
            "cargo_demand": 75025
        }
    },
    {
        "iata": "GBE",
        "icao": "FBSK",
        "airportName": "Sir Seretse Khama International Airport",
        "location_country": "Botswana",
        "location_city": "Gaborone",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-24.555201",
        "longitude": "25.9182",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 338341,
            "s_seats": 99330,
            "j_seats": 77173,
            "f_seats": 38802,
            "r_seats": 10000,
            "cargo_demand": 1091863
        }
    },
    {
        "iata": "GBG",
        "icao": "KGBG",
        "airportName": "Galesburg Municipal Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Galesburg",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.93799973",
        "longitude": "-90.43109894",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5793",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3601",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2931,
            "s_seats": 1655,
            "j_seats": 1960,
            "f_seats": 289,
            "r_seats": 13,
            "cargo_demand": 44722
        }
    },
    {
        "iata": "GBH",
        "icao": "PAGB",
        "airportName": "Galbraith Lake Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Galbraith Lake",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "68.47969818",
        "longitude": "-149.4900055",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5182",
                "runway_width": "150",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 3336,
            "s_seats": 1536,
            "j_seats": 1465,
            "f_seats": 329,
            "r_seats": 160,
            "cargo_demand": 27805
        }
    },
    {
        "iata": "GBJ",
        "icao": "TFFM",
        "airportName": "Marie-Galante Airport",
        "location_country": "Guadeloupe",
        "location_city": "Marie-Galante Island",
        "location_state": "Grand-Bourg",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.86870003",
        "longitude": "-61.27000046",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4068",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59526,
            "s_seats": 29011,
            "j_seats": 58748,
            "f_seats": 3918,
            "r_seats": 9608,
            "cargo_demand": 200362
        }
    },
    {
        "iata": "GBL",
        "icao": "YGBI",
        "airportName": "South Goulburn Island Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Goulburn Islands",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.64999962",
        "longitude": "133.3820038",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4527",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2988,
            "s_seats": 2794,
            "j_seats": 1131,
            "f_seats": 242,
            "r_seats": 14,
            "cargo_demand": 37254
        }
    },
    {
        "iata": "GBP",
        "icao": "YGAM",
        "airportName": "Gamboola Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Gamboola",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.54999924",
        "longitude": "143.6670074",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1804",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3175,
            "s_seats": 2075,
            "j_seats": 1266,
            "f_seats": 202,
            "r_seats": 185,
            "cargo_demand": 58409
        }
    },
    {
        "iata": "GBR",
        "icao": "KGBR",
        "airportName": "Walter J. Koladza Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Great Barrington",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.18420029",
        "longitude": "-73.40319824",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2579",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2080,
            "s_seats": 2090,
            "j_seats": 1911,
            "f_seats": 446,
            "r_seats": 118,
            "cargo_demand": 67702
        }
    },
    {
        "iata": "GBT",
        "icao": "OING",
        "airportName": "Gorgan Airport",
        "location_country": "Iran",
        "location_city": "Gorgan",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "36.90940094",
        "longitude": "54.40129852",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7540",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182273,
            "s_seats": 31102,
            "j_seats": 9835,
            "f_seats": 4749,
            "r_seats": 3757,
            "cargo_demand": 401035
        }
    },
    {
        "iata": "GBV",
        "icao": "YGIB",
        "airportName": "Gibb River Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Gibb River",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.42329979",
        "longitude": "126.4449997",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3497",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1333,
            "s_seats": 2466,
            "j_seats": 1165,
            "f_seats": 206,
            "r_seats": 78,
            "cargo_demand": 10391
        }
    },
    {
        "iata": "GBW",
        "icao": "YGIA",
        "airportName": "Ginbata Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Ginbata",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.5812",
        "longitude": "120.03553",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3180,
            "s_seats": 2232,
            "j_seats": 1097,
            "f_seats": 267,
            "r_seats": 179,
            "cargo_demand": 52256
        }
    },
    {
        "iata": "GBZ",
        "icao": "NZGB",
        "airportName": "Great Barrier Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Great Barrier Island",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "small_airport",
        "latitude": "-36.24140167",
        "longitude": "175.4720001",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3051",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3514,
            "s_seats": 1590,
            "j_seats": 1172,
            "f_seats": 113,
            "r_seats": 181,
            "cargo_demand": 53353
        }
    },
    {
        "iata": "GCC",
        "icao": "KGCC",
        "airportName": "Gillette-Campbell County Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Gillette",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.34889984",
        "longitude": "-105.5390015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5803",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7500",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 135343,
            "s_seats": 26764,
            "j_seats": 54600,
            "f_seats": 7494,
            "r_seats": 5560,
            "cargo_demand": 86906
        }
    },
    {
        "iata": "GCH",
        "icao": "OIAH",
        "airportName": "Gachsaran Airport",
        "location_country": "Iran",
        "location_city": "Gachsaran",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "30.33760071",
        "longitude": "50.82799912",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6070",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94727,
            "s_seats": 34328,
            "j_seats": 15083,
            "f_seats": 13486,
            "r_seats": 8470,
            "cargo_demand": 126043
        }
    },
    {
        "iata": "GCI",
        "icao": "EGJB",
        "airportName": "Guernsey Airport",
        "location_country": "United Kingdom",
        "location_city": "Channel Islands",
        "location_state": "Guernsey",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.43500137",
        "longitude": "-2.601969957",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5194",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56163,
            "s_seats": 43444,
            "j_seats": 17847,
            "f_seats": 9911,
            "r_seats": 4038,
            "cargo_demand": 296569
        }
    },
    {
        "iata": "GCJ",
        "icao": "FAGC",
        "airportName": "Grand Central Airport",
        "location_country": "South Africa",
        "location_city": "Johannesburg",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.98629951",
        "longitude": "28.14010048",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6004",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76413,
            "s_seats": 43397,
            "j_seats": 20215,
            "f_seats": 6711,
            "r_seats": 5702,
            "cargo_demand": 267239
        }
    },
    {
        "iata": "GCK",
        "icao": "KGCK",
        "airportName": "Garden City Regional Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Garden City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.92750168",
        "longitude": "-100.723999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5700",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7300",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 75812,
            "s_seats": 42678,
            "j_seats": 7835,
            "f_seats": 4323,
            "r_seats": 9339,
            "cargo_demand": 48223
        }
    },
    {
        "iata": "GCM",
        "icao": "MWCR",
        "airportName": "Owen Roberts International Airport",
        "location_country": "British Overseas Territory of Cayman Islands",
        "location_city": "Grand Cayman",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "19.2928009",
        "longitude": "-81.35769653",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7021",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 453739,
            "s_seats": 83635,
            "j_seats": 67529,
            "f_seats": 33250,
            "r_seats": 10000,
            "cargo_demand": 21858456
        }
    },
    {
        "iata": "GCN",
        "icao": "KGCN",
        "airportName": "Grand Canyon National Park Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Tusayan",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.95240021",
        "longitude": "-112.1470032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8999",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125790,
            "s_seats": 34988,
            "j_seats": 36555,
            "f_seats": 9849,
            "r_seats": 6287,
            "cargo_demand": 341515
        }
    },
    {
        "iata": "GCY",
        "icao": "KGCY",
        "airportName": "Greeneville-Greene County Municipal Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Greeneville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.193001",
        "longitude": "-82.815102",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6302",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1983,
            "s_seats": 2875,
            "j_seats": 1630,
            "f_seats": 422,
            "r_seats": 102,
            "cargo_demand": 94704
        }
    },
    {
        "iata": "GDC",
        "icao": "KGYH",
        "airportName": "Donaldson Center Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Greenville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.758301",
        "longitude": "-82.376404",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1572,
            "s_seats": 2178,
            "j_seats": 1165,
            "f_seats": 95,
            "r_seats": 191,
            "cargo_demand": 49113
        }
    },
    {
        "iata": "GDE",
        "icao": "HAGO",
        "airportName": "Gode Airport",
        "location_country": "Ethiopia",
        "location_city": "Gode",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.935130119",
        "longitude": "43.57860184",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7505",
                "runway_width": "115",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 99421,
            "s_seats": 44609,
            "j_seats": 53608,
            "f_seats": 18558,
            "r_seats": 4873,
            "cargo_demand": 296021
        }
    },
    {
        "iata": "GDJ",
        "icao": "FZWC",
        "airportName": "Gandajika Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Gandajika",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.733",
        "longitude": "23.950001",
        "region": "AF",
        "runway": [
            {
                "runway_name": "unknown/unknown",
                "runway_length": "3610",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1767,
            "s_seats": 1618,
            "j_seats": 1924,
            "f_seats": 109,
            "r_seats": 53,
            "cargo_demand": 59085
        }
    },
    {
        "iata": "GDL",
        "icao": "MMGL",
        "airportName": "Guadalajara International Airport",
        "location_country": "Mexico",
        "location_city": "Jalisco",
        "location_state": "Guadalajara",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "large_airport",
        "latitude": "20.52179909",
        "longitude": "-103.310997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5964",
                "runway_width": "94",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "13140",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 281715,
            "s_seats": 74643,
            "j_seats": 118516,
            "f_seats": 44377,
            "r_seats": 10000,
            "cargo_demand": 29403530
        }
    },
    {
        "iata": "GDM",
        "icao": "KGDM",
        "airportName": "Gardner Municipal Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Gardner",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.54999924",
        "longitude": "-72.01609802",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "2999",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3105,
            "s_seats": 2060,
            "j_seats": 1337,
            "f_seats": 315,
            "r_seats": 7,
            "cargo_demand": 90942
        }
    },
    {
        "iata": "GDN",
        "icao": "EPGD",
        "airportName": "Gda\u0144sk Lech Wa\u0142\u0119sa Airport",
        "location_country": "Poland",
        "location_city": "Gda\u0144sk",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "54.37760162",
        "longitude": "18.46619987",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9186",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 340279,
            "s_seats": 92620,
            "j_seats": 112225,
            "f_seats": 50263,
            "r_seats": 10000,
            "cargo_demand": 26212279
        }
    },
    {
        "iata": "GDO",
        "icao": "SVGD",
        "airportName": "Guasdualito Airport",
        "location_country": "Venezuela",
        "location_city": "Guasdualito",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.233333111",
        "longitude": "-70.80000305",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6792",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 98118,
            "s_seats": 31756,
            "j_seats": 19391,
            "f_seats": 5916,
            "r_seats": 3012,
            "cargo_demand": 219376
        }
    },
    {
        "iata": "GDP",
        "icao": "SNGD",
        "airportName": "Guadalupe Airport",
        "location_country": "Brazil",
        "location_city": "Piaui",
        "location_state": "Guadalupe",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.782219887",
        "longitude": "-43.5821991",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5249",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1247,
            "s_seats": 1577,
            "j_seats": 1014,
            "f_seats": 325,
            "r_seats": 16,
            "cargo_demand": 4352
        }
    },
    {
        "iata": "GDQ",
        "icao": "HAGN",
        "airportName": "Gondar Airport",
        "location_country": "Ethiopia",
        "location_city": "Gondar",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.51990032",
        "longitude": "37.43399811",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9072",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109481,
            "s_seats": 33608,
            "j_seats": 21979,
            "f_seats": 2248,
            "r_seats": 6622,
            "cargo_demand": 368084
        }
    },
    {
        "iata": "GDT",
        "icao": "MBGT",
        "airportName": "JAGS McCartney International Airport",
        "location_country": "British Overseas Territory of Turks and Caicos Islands",
        "location_city": "Grand Turk Island",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.44449997",
        "longitude": "-71.14230347",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6362",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69376,
            "s_seats": 32539,
            "j_seats": 52945,
            "f_seats": 19542,
            "r_seats": 8835,
            "cargo_demand": 328690
        }
    },
    {
        "iata": "GDV",
        "icao": "KGDV",
        "airportName": "Dawson Community Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Glendive",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.13869858",
        "longitude": "-104.8069992",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5704",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177379,
            "s_seats": 30769,
            "j_seats": 25392,
            "f_seats": 7089,
            "r_seats": 7934,
            "cargo_demand": 183845
        }
    },
    {
        "iata": "GDW",
        "icao": "KGDW",
        "airportName": "Gladwin Zettel Memorial Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Gladwin",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.97060013",
        "longitude": "-84.47499847",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4700",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2500",
                "runway_width": "150",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2090,
            "s_seats": 2482,
            "j_seats": 1637,
            "f_seats": 66,
            "r_seats": 74,
            "cargo_demand": 74325
        }
    },
    {
        "iata": "GDX",
        "icao": "UHMM",
        "airportName": "Sokol Airport",
        "location_country": "Russia",
        "location_city": "Magadan Oblast",
        "location_state": "Magadan",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "59.9109993",
        "longitude": "150.7200012",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "11326",
                "runway_width": "195",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 154957,
            "s_seats": 49048,
            "j_seats": 6397,
            "f_seats": 14850,
            "r_seats": 6771,
            "cargo_demand": 487059
        }
    },
    {
        "iata": "GDZ",
        "icao": "URKG",
        "airportName": "Gelendzhik Airport",
        "location_country": "Russia",
        "location_city": "Krasnodar Krai",
        "location_state": "Gelendzhik",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.58209263",
        "longitude": "38.01248074",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10171",
                "runway_width": "197",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 144756,
            "s_seats": 37766,
            "j_seats": 24745,
            "f_seats": 16786,
            "r_seats": 5145,
            "cargo_demand": 469222
        }
    },
    {
        "iata": "GEA",
        "icao": "NWWM",
        "airportName": "Noumea Magenta Airport",
        "location_country": "New Caledonia",
        "location_city": "Noumea",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-22.258301",
        "longitude": "166.473007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4101",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194191,
            "s_seats": 77409,
            "j_seats": 105914,
            "f_seats": 39711,
            "r_seats": 10000,
            "cargo_demand": 2600741
        }
    },
    {
        "iata": "GEB",
        "icao": "WAMJ",
        "airportName": "Gebe Airport",
        "location_country": "Indonesia",
        "location_city": "Gebe",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.078888997",
        "longitude": "129.4579926",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "2953",
                "runway_width": "75",
                "runway_material": "Coral"
            }
        ],
        "demand": {
            "y_seats": 1384,
            "s_seats": 2996,
            "j_seats": 1530,
            "f_seats": 485,
            "r_seats": 112,
            "cargo_demand": 24346
        }
    },
    {
        "iata": "GED",
        "icao": "KGED",
        "airportName": "Delaware Coastal Airport",
        "location_country": "United States",
        "location_city": "Delaware",
        "location_state": "Georgetown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.689201",
        "longitude": "-75.358902",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3109",
                "runway_width": "75",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 3455,
            "s_seats": 2868,
            "j_seats": 1626,
            "f_seats": 86,
            "r_seats": 24,
            "cargo_demand": 39416
        }
    },
    {
        "iata": "GEE",
        "icao": "YGTO",
        "airportName": "George Town Aerodrome",
        "location_country": "Australia",
        "location_city": "Tasmania",
        "location_state": "George Town",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-41.08000183",
        "longitude": "146.8399963",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "B"
            },
            {
                "runway_name": "11/29",
                "runway_length": "1968",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 1641,
            "s_seats": 2867,
            "j_seats": 1682,
            "f_seats": 304,
            "r_seats": 183,
            "cargo_demand": 3858
        }
    },
    {
        "iata": "GEF",
        "icao": "AGEV",
        "airportName": "Geva Airport",
        "location_country": "Solomon Islands",
        "location_city": "Vella Lavella",
        "location_state": "Liangai",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.575829983",
        "longitude": "156.5970001",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2297",
                "runway_width": "82",
                "runway_material": "Gravel dirt"
            }
        ],
        "demand": {
            "y_seats": 2633,
            "s_seats": 2144,
            "j_seats": 1555,
            "f_seats": 376,
            "r_seats": 74,
            "cargo_demand": 68894
        }
    },
    {
        "iata": "GEG",
        "icao": "KGEG",
        "airportName": "Spokane International Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Spokane",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "47.61989975",
        "longitude": "-117.5339966",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "11002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "8198",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 372826,
            "s_seats": 79573,
            "j_seats": 47000,
            "f_seats": 38391,
            "r_seats": 10000,
            "cargo_demand": 20979251
        }
    },
    {
        "iata": "GEL",
        "icao": "SBNM",
        "airportName": "Sepe Tiaraju Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Santo i\u201angelo",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-28.2817",
        "longitude": "-54.169102",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5331",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188926,
            "s_seats": 44331,
            "j_seats": 51118,
            "f_seats": 12060,
            "r_seats": 5522,
            "cargo_demand": 71547
        }
    },
    {
        "iata": "GEM",
        "icao": "FGMY",
        "airportName": "President Obiang Nguema International Airport",
        "location_country": "Equatorial Guinea",
        "location_city": "Mengomeyen",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "1.685334",
        "longitude": "11.024394",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9843",
                "runway_width": "",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1576,
            "s_seats": 2783,
            "j_seats": 1178,
            "f_seats": 437,
            "r_seats": 108,
            "cargo_demand": 83805
        }
    },
    {
        "iata": "GEO",
        "icao": "SYCJ",
        "airportName": "Cheddi Jagan International Airport",
        "location_country": "Guyana",
        "location_city": "Georgetown",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.498549938",
        "longitude": "-58.2541008",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7448",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5002",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180009,
            "s_seats": 47826,
            "j_seats": 42233,
            "f_seats": 16665,
            "r_seats": 9402,
            "cargo_demand": 45828
        }
    },
    {
        "iata": "GER",
        "icao": "MUNG",
        "airportName": "Rafael Cabrera Mustelier Airport",
        "location_country": "Cuba",
        "location_city": "Nueva Gerona",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "21.83469963",
        "longitude": "-82.78379822",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5325",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53622,
            "s_seats": 49771,
            "j_seats": 38868,
            "f_seats": 15948,
            "r_seats": 5133,
            "cargo_demand": 309105
        }
    },
    {
        "iata": "GES",
        "icao": "RPMR",
        "airportName": "General Santos International Airport",
        "location_country": "Philippines",
        "location_city": "General Santos",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.058000088",
        "longitude": "125.0960007",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "10587",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 198282,
            "s_seats": 35673,
            "j_seats": 50041,
            "f_seats": 13817,
            "r_seats": 6386,
            "cargo_demand": 343747
        }
    },
    {
        "iata": "GET",
        "icao": "YGEL",
        "airportName": "Geraldton Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Geraldton",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.796101",
        "longitude": "114.707001",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7838",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "2953",
                "runway_width": "59",
                "runway_material": "GVL"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2769",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92553,
            "s_seats": 27755,
            "j_seats": 44749,
            "f_seats": 2273,
            "r_seats": 8373,
            "cargo_demand": 102280
        }
    },
    {
        "iata": "GEV",
        "icao": "ESNG",
        "airportName": "GAllivare Airport",
        "location_country": "Sweden",
        "location_city": "GAllivare",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "67.13240051",
        "longitude": "20.81459999",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5623",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 84579,
            "s_seats": 31704,
            "j_seats": 42422,
            "f_seats": 4975,
            "r_seats": 9485,
            "cargo_demand": 63460
        }
    },
    {
        "iata": "GEX",
        "icao": "YGLG",
        "airportName": "Geelong Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Geelong",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-38.22499847",
        "longitude": "144.3329926",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2542",
                "runway_width": "",
                "runway_material": "G"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3559",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 1042,
            "s_seats": 2616,
            "j_seats": 1343,
            "f_seats": 240,
            "r_seats": 134,
            "cargo_demand": 41748
        }
    },
    {
        "iata": "GEY",
        "icao": "KGEY",
        "airportName": "South Big Horn County Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Greybull",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.51679993",
        "longitude": "-108.0830002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3699",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6302",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3151,
            "s_seats": 1649,
            "j_seats": 1609,
            "f_seats": 182,
            "r_seats": 44,
            "cargo_demand": 15570
        }
    },
    {
        "iata": "GFF",
        "icao": "YGTH",
        "airportName": "Griffith Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Griffith",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-34.25080109",
        "longitude": "146.0670013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4931",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1969",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 66093,
            "s_seats": 29200,
            "j_seats": 34432,
            "f_seats": 4872,
            "r_seats": 7544,
            "cargo_demand": 490192
        }
    },
    {
        "iata": "GFK",
        "icao": "KGFK",
        "airportName": "Grand Forks International Airport",
        "location_country": "United States",
        "location_city": "North Dakota",
        "location_state": "Grand Forks",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.949299",
        "longitude": "-97.176102",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "4206",
                "runway_width": "100",
                "runway_material": "CONC-G"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "3300",
                "runway_width": "60",
                "runway_material": "CON"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "3900",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "7349",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192196,
            "s_seats": 34917,
            "j_seats": 44122,
            "f_seats": 14551,
            "r_seats": 8507,
            "cargo_demand": 153571
        }
    },
    {
        "iata": "GFL",
        "icao": "KGFL",
        "airportName": "Floyd Bennett Memorial Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Glens Falls",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.34120178",
        "longitude": "-73.61029816",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157166,
            "s_seats": 48802,
            "j_seats": 10462,
            "f_seats": 4839,
            "r_seats": 4724,
            "cargo_demand": 499835
        }
    },
    {
        "iata": "GFN",
        "icao": "YGFN",
        "airportName": "Clarence Valley Regional Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Grafton",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-29.75939941",
        "longitude": "153.0299988",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5607",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79907,
            "s_seats": 46490,
            "j_seats": 43324,
            "f_seats": 12986,
            "r_seats": 3832,
            "cargo_demand": 122509
        }
    },
    {
        "iata": "GFR",
        "icao": "LFRF",
        "airportName": "Granville-Mont-Saint-Michel Aerodrome",
        "location_country": "France",
        "location_city": "Lower Normandy",
        "location_state": "Granville",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.8830986",
        "longitude": "-1.564170003",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3150",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183502,
            "s_seats": 30280,
            "j_seats": 30439,
            "f_seats": 6731,
            "r_seats": 9998,
            "cargo_demand": 331497
        }
    },
    {
        "iata": "GFY",
        "icao": "FYGF",
        "airportName": "Grootfontein Air Base",
        "location_country": "Namibia",
        "location_city": "Grootfontein",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-19.60219955",
        "longitude": "18.12269974",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11680",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3412",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106088,
            "s_seats": 49142,
            "j_seats": 28680,
            "f_seats": 15944,
            "r_seats": 4618,
            "cargo_demand": 477842
        }
    },
    {
        "iata": "GGB",
        "icao": "SWHP",
        "airportName": "agua Boa Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "agua Boa",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.019444",
        "longitude": "-52.152222",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5208",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1095,
            "s_seats": 2452,
            "j_seats": 1237,
            "f_seats": 196,
            "r_seats": 141,
            "cargo_demand": 60405
        }
    },
    {
        "iata": "GGD",
        "icao": "YGDS",
        "airportName": "Gregory Downs Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Gregory Downs",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.625",
        "longitude": "139.2330017",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3871",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 3562,
            "s_seats": 2384,
            "j_seats": 1248,
            "f_seats": 164,
            "r_seats": 126,
            "cargo_demand": 51254
        }
    },
    {
        "iata": "GGE",
        "icao": "KGGE",
        "airportName": "Georgetown County Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Georgetown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.31169891",
        "longitude": "-79.31960297",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4539",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4986",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1837,
            "s_seats": 2261,
            "j_seats": 1878,
            "f_seats": 369,
            "r_seats": 77,
            "cargo_demand": 58215
        }
    },
    {
        "iata": "GGF",
        "icao": "SNYA",
        "airportName": "Almeirim Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Almeirim",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.479524",
        "longitude": "-52.578214",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "PiiCarra gravel"
            }
        ],
        "demand": {
            "y_seats": 3715,
            "s_seats": 2851,
            "j_seats": 1143,
            "f_seats": 97,
            "r_seats": 158,
            "cargo_demand": 21657
        }
    },
    {
        "iata": "GGG",
        "icao": "KGGG",
        "airportName": "East Texas Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Longview",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.38399887",
        "longitude": "-94.71150208",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6109",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 160497,
            "s_seats": 38062,
            "j_seats": 20045,
            "f_seats": 7100,
            "r_seats": 6616,
            "cargo_demand": 460062
        }
    },
    {
        "iata": "GGH",
        "icao": "SSCT",
        "airportName": "Gastio Mesquita Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Cianorte",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-23.69149971",
        "longitude": "-52.64189911",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "3937",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2226,
            "s_seats": 2338,
            "j_seats": 1549,
            "f_seats": 10,
            "r_seats": 124,
            "cargo_demand": 21674
        }
    },
    {
        "iata": "GGJ",
        "icao": "SSGY",
        "airportName": "Guaira Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Guaira",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-24.08110046",
        "longitude": "-54.19169998",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1289,
            "s_seats": 2676,
            "j_seats": 1593,
            "f_seats": 143,
            "r_seats": 67,
            "cargo_demand": 73157
        }
    },
    {
        "iata": "GGM",
        "icao": "HKKG",
        "airportName": "Kakamega Airport",
        "location_country": "Kenya",
        "location_city": "Kakamega",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.271342009",
        "longitude": "34.78730011",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3420",
                "runway_width": "49",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 1970,
            "s_seats": 1894,
            "j_seats": 1920,
            "f_seats": 431,
            "r_seats": 186,
            "cargo_demand": 1143
        }
    },
    {
        "iata": "GGS",
        "icao": "SAWR",
        "airportName": "Gobernador Gregores Airport",
        "location_country": "Argentina",
        "location_city": "Santa Cruz",
        "location_state": "Gobernador Gregores",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-48.7831",
        "longitude": "-70.150002",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1210,
            "s_seats": 1577,
            "j_seats": 1186,
            "f_seats": 336,
            "r_seats": 132,
            "cargo_demand": 91739
        }
    },
    {
        "iata": "GGT",
        "icao": "MYEF",
        "airportName": "Exuma International Airport",
        "location_country": "Bahamas",
        "location_city": "Great Exuma Island",
        "location_state": "Moss Town",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "23.562599",
        "longitude": "-75.877998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7051",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81678,
            "s_seats": 49705,
            "j_seats": 43170,
            "f_seats": 13801,
            "r_seats": 6511,
            "cargo_demand": 349290
        }
    },
    {
        "iata": "GGW",
        "icao": "KGGW",
        "airportName": "Glasgow Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Glasgow",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "48.212502",
        "longitude": "-106.614998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184308,
            "s_seats": 35647,
            "j_seats": 11335,
            "f_seats": 8142,
            "r_seats": 9591,
            "cargo_demand": 129677
        }
    },
    {
        "iata": "GHA",
        "icao": "DAUG",
        "airportName": "Noumerat - Moufdi Zakaria Airport",
        "location_country": "Algeria",
        "location_city": "Ghardai\u00afa",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.38410187",
        "longitude": "3.79411006",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "10171",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139057,
            "s_seats": 39359,
            "j_seats": 9698,
            "f_seats": 8781,
            "r_seats": 4163,
            "cargo_demand": 226116
        }
    },
    {
        "iata": "GHB",
        "icao": "MYEM",
        "airportName": "Governor's Harbour Airport",
        "location_country": "Bahamas",
        "location_city": "Eleuthera Island",
        "location_state": "Governor's Harbour",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "25.2847",
        "longitude": "-76.331001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "8024",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124688,
            "s_seats": 29951,
            "j_seats": 34606,
            "f_seats": 14373,
            "r_seats": 9883,
            "cargo_demand": 487918
        }
    },
    {
        "iata": "GHC",
        "icao": "MYBG",
        "airportName": "Great Harbour Cay Airport",
        "location_country": "Bahamas",
        "location_city": "Berry Islands",
        "location_state": "Great Harbour Cay",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "25.7383",
        "longitude": "-77.840103",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4536",
                "runway_width": "80",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 75739,
            "s_seats": 46525,
            "j_seats": 41051,
            "f_seats": 12380,
            "r_seats": 3040,
            "cargo_demand": 390383
        }
    },
    {
        "iata": "GHM",
        "icao": "KGHM",
        "airportName": "Centerville Municipal Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Centerville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.83739853",
        "longitude": "-87.44539642",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4002",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1187,
            "s_seats": 2219,
            "j_seats": 1196,
            "f_seats": 431,
            "r_seats": 114,
            "cargo_demand": 47981
        }
    },
    {
        "iata": "GHS",
        "icao": "WALE",
        "airportName": "West Kutai Melalan Airport",
        "location_country": "Indonesia",
        "location_city": "East Kalimantan",
        "location_state": "Melak",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.203611",
        "longitude": "115.760002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2953",
                "runway_width": "75",
                "runway_material": "Compacted sand"
            }
        ],
        "demand": {
            "y_seats": 3823,
            "s_seats": 1922,
            "j_seats": 1535,
            "f_seats": 135,
            "r_seats": 15,
            "cargo_demand": 58981
        }
    },
    {
        "iata": "GHT",
        "icao": "HLGT",
        "airportName": "Ghat Airport",
        "location_country": "Libya",
        "location_city": "Ghat",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.14559937",
        "longitude": "10.14260006",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6643",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95878,
            "s_seats": 43768,
            "j_seats": 44024,
            "f_seats": 8038,
            "r_seats": 5381,
            "cargo_demand": 473830
        }
    },
    {
        "iata": "GHU",
        "icao": "SAAG",
        "airportName": "Gualeguaychu Airport",
        "location_country": "Argentina",
        "location_city": "Entre Rios",
        "location_state": "Gualeguaychu",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.0103",
        "longitude": "-58.6131",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "1/19",
                "runway_length": "4364",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175561,
            "s_seats": 39640,
            "j_seats": 38171,
            "f_seats": 2672,
            "r_seats": 8890,
            "cargo_demand": 169220
        }
    },
    {
        "iata": "GIB",
        "icao": "LXGB",
        "airportName": "Gibraltar International Airport",
        "location_country": "British Overseas Territory of Gibraltar",
        "location_city": "",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "36.15119934",
        "longitude": "-5.34965992",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191510,
            "s_seats": 42079,
            "j_seats": 10843,
            "f_seats": 2109,
            "r_seats": 9233,
            "cargo_demand": 442401
        }
    },
    {
        "iata": "GIC",
        "icao": "YBOI",
        "airportName": "Boigu Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Boigu Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.232780457",
        "longitude": "142.2180023",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2165",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 1279,
            "s_seats": 1972,
            "j_seats": 1515,
            "f_seats": 279,
            "r_seats": 158,
            "cargo_demand": 18071
        }
    },
    {
        "iata": "GID",
        "icao": "HBBE",
        "airportName": "Gitega Airport",
        "location_country": "Burundi",
        "location_city": "Gitega",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.417209",
        "longitude": "29.911308",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3117",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3454,
            "s_seats": 2970,
            "j_seats": 1618,
            "f_seats": 71,
            "r_seats": 162,
            "cargo_demand": 92524
        }
    },
    {
        "iata": "GIF",
        "icao": "KGIF",
        "airportName": "Winter Haven's Gilbert Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Winter Haven",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "28.062901",
        "longitude": "-81.753304",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4001",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "5/23",
                "runway_length": "5006",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3937,
            "s_seats": 2866,
            "j_seats": 1692,
            "f_seats": 81,
            "r_seats": 128,
            "cargo_demand": 14873
        }
    },
    {
        "iata": "GIG",
        "icao": "SBGL",
        "airportName": "Rio de Janeiro-Galeio International Airport",
        "location_country": "Brazil",
        "location_city": "Rio de Janeiro",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "large_airport",
        "latitude": "-22.80999947",
        "longitude": "-43.25055695",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "15/33",
                "runway_length": "10433",
                "runway_width": "154",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 363150,
            "s_seats": 86971,
            "j_seats": 55030,
            "f_seats": 36358,
            "r_seats": 10000,
            "cargo_demand": 12179547
        }
    },
    {
        "iata": "GIL",
        "icao": "OPGT",
        "airportName": "Gilgit Airport",
        "location_country": "Pakistan",
        "location_city": "Gilgit",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.91880035",
        "longitude": "74.33360291",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5400",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "983",
                "runway_width": "49",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 150754,
            "s_seats": 29398,
            "j_seats": 56232,
            "f_seats": 13674,
            "r_seats": 3343,
            "cargo_demand": 192737
        }
    },
    {
        "iata": "GIR",
        "icao": "SKGI",
        "airportName": "Santiago Vila Airport",
        "location_country": "Colombia",
        "location_city": "Girardot",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.27624",
        "longitude": "-74.7967",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5249",
                "runway_width": "92",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114433,
            "s_seats": 39619,
            "j_seats": 33066,
            "f_seats": 12435,
            "r_seats": 5978,
            "cargo_demand": 433359
        }
    },
    {
        "iata": "GIS",
        "icao": "NZGS",
        "airportName": "Gisborne Airport",
        "location_country": "New Zealand",
        "location_city": "Gisborne",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-38.66329956",
        "longitude": "177.9779968",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3773",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3839",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "2503",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "4298",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82664,
            "s_seats": 33723,
            "j_seats": 58390,
            "f_seats": 17153,
            "r_seats": 6238,
            "cargo_demand": 191352
        }
    },
    {
        "iata": "GIU",
        "icao": "VCCS",
        "airportName": "Sigiriya Airport",
        "location_country": "Sri Lanka",
        "location_city": "Dambulla",
        "location_state": "",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "7.956669807",
        "longitude": "80.72850037",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5925",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3923,
            "s_seats": 2595,
            "j_seats": 1132,
            "f_seats": 421,
            "r_seats": 109,
            "cargo_demand": 48848
        }
    },
    {
        "iata": "GIZ",
        "icao": "OEGN",
        "airportName": "Jizan Regional Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Jizan",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.9011",
        "longitude": "42.5858",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "10006",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116031,
            "s_seats": 33713,
            "j_seats": 23180,
            "f_seats": 8625,
            "r_seats": 5010,
            "cargo_demand": 353665
        }
    },
    {
        "iata": "GJA",
        "icao": "MHNJ",
        "airportName": "Guanaja Airport",
        "location_country": "Honduras",
        "location_city": "Guanaja",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.4454",
        "longitude": "-85.906601",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3990",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56550,
            "s_seats": 43730,
            "j_seats": 25117,
            "f_seats": 17142,
            "r_seats": 4493,
            "cargo_demand": 331361
        }
    },
    {
        "iata": "GJL",
        "icao": "DAAV",
        "airportName": "Jijel Ferhat Abbas Airport",
        "location_country": "Algeria",
        "location_city": "Jijel",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.79510117",
        "longitude": "5.87361002",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 98812,
            "s_seats": 49214,
            "j_seats": 14438,
            "f_seats": 13431,
            "r_seats": 3933,
            "cargo_demand": 305666
        }
    },
    {
        "iata": "GJM",
        "icao": "SBGM",
        "airportName": "Guajara-Mirim Airport",
        "location_country": "Brazil",
        "location_city": "Rondonia",
        "location_state": "Guajara-Mirim",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-10.78639984",
        "longitude": "-65.28479767",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5889",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103357,
            "s_seats": 39305,
            "j_seats": 21974,
            "f_seats": 4754,
            "r_seats": 5222,
            "cargo_demand": 225792
        }
    },
    {
        "iata": "GJR",
        "icao": "BIGJ",
        "airportName": "Gjogur Airport",
        "location_country": "Iceland",
        "location_city": "Gjogur",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.99530029",
        "longitude": "-21.32690048",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3041",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2207,
            "s_seats": 2276,
            "j_seats": 1457,
            "f_seats": 67,
            "r_seats": 146,
            "cargo_demand": 23414
        }
    },
    {
        "iata": "GJT",
        "icao": "KGJT",
        "airportName": "Grand Junction Regional Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Grand Junction",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.12239838",
        "longitude": "-108.5270004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5502",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "10501",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196427,
            "s_seats": 46433,
            "j_seats": 28485,
            "f_seats": 17952,
            "r_seats": 6113,
            "cargo_demand": 487029
        }
    },
    {
        "iata": "GKA",
        "icao": "AYGA",
        "airportName": "Goroka Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Goroka",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.081689835",
        "longitude": "145.3919983",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "3983",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "5400",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154566,
            "s_seats": 31390,
            "j_seats": 18682,
            "f_seats": 7399,
            "r_seats": 7960,
            "cargo_demand": 204303
        }
    },
    {
        "iata": "GKE",
        "icao": "ETNG",
        "airportName": "NATO Air Base Geilenkirchen",
        "location_country": "Germany",
        "location_city": " North Rhine-Westphalia",
        "location_state": "Geilenkirchen",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.9608",
        "longitude": "6.04242",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10009",
                "runway_width": "147",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 166182,
            "s_seats": 38923,
            "j_seats": 28961,
            "f_seats": 4790,
            "r_seats": 4480,
            "cargo_demand": 355573
        }
    },
    {
        "iata": "GKK",
        "icao": "VRMO",
        "airportName": "Kooddoo Airport",
        "location_country": "Maldives",
        "location_city": "Gaafu Alif Atoll",
        "location_state": "Kooddoo",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.7324",
        "longitude": "73.4336",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5905",
                "runway_width": "100",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3454,
            "s_seats": 1920,
            "j_seats": 1608,
            "f_seats": 140,
            "r_seats": 137,
            "cargo_demand": 79788
        }
    },
    {
        "iata": "GKL",
        "icao": "YGKL",
        "airportName": "Great Keppel Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Great Keppel Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-23.18330002",
        "longitude": "150.9420013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2870",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 2377,
            "s_seats": 2993,
            "j_seats": 1036,
            "f_seats": 120,
            "r_seats": 179,
            "cargo_demand": 73460
        }
    },
    {
        "iata": "GKN",
        "icao": "PAGK",
        "airportName": "Gulkana Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Gulkana",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "62.1548996",
        "longitude": "-145.4570007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 119098,
            "s_seats": 25898,
            "j_seats": 55569,
            "f_seats": 19137,
            "r_seats": 6603,
            "cargo_demand": 204447
        }
    },
    {
        "iata": "GKT",
        "icao": "KGKT",
        "airportName": "Gatlinburg-Pigeon Forge Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Sevierville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.85779953",
        "longitude": "-83.52870178",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5506",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2488,
            "s_seats": 1592,
            "j_seats": 1176,
            "f_seats": 354,
            "r_seats": 196,
            "cargo_demand": 17332
        }
    },
    {
        "iata": "GLA",
        "icao": "EGPF",
        "airportName": "Glasgow Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Glasgow",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "55.871899",
        "longitude": "-4.43306",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8743",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3622",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188438,
            "s_seats": 74911,
            "j_seats": 76703,
            "f_seats": 66320,
            "r_seats": 10000,
            "cargo_demand": 11522019
        }
    },
    {
        "iata": "GLD",
        "icao": "KGLD",
        "airportName": "Goodland Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Goodland",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.37060165",
        "longitude": "-101.6989975",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3501",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5499",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "1800",
                "runway_width": "40",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 115037,
            "s_seats": 28076,
            "j_seats": 49407,
            "f_seats": 19510,
            "r_seats": 4500,
            "cargo_demand": 453578
        }
    },
    {
        "iata": "GLE",
        "icao": "KGLE",
        "airportName": "Gainesville Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Gainesville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.65140152",
        "longitude": "-97.1969986",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4691",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3920",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4296",
                "runway_width": "80",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2771,
            "s_seats": 2693,
            "j_seats": 1789,
            "f_seats": 150,
            "r_seats": 17,
            "cargo_demand": 1449
        }
    },
    {
        "iata": "GLF",
        "icao": "MRGF",
        "airportName": "Golfito Airport",
        "location_country": "Costa Rica",
        "location_city": "Golfito",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.654009819",
        "longitude": "-83.18219757",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4593",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67158,
            "s_seats": 30361,
            "j_seats": 13287,
            "f_seats": 3634,
            "r_seats": 7055,
            "cargo_demand": 437926
        }
    },
    {
        "iata": "GLG",
        "icao": "YGLE",
        "airportName": "Glengyle Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Glengyle",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.80830002",
        "longitude": "139.6000061",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2887",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 2381,
            "s_seats": 2863,
            "j_seats": 1457,
            "f_seats": 331,
            "r_seats": 78,
            "cargo_demand": 84343
        }
    },
    {
        "iata": "GLH",
        "icao": "KGLH",
        "airportName": "Mid-Delta Regional Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Greenville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.48289871",
        "longitude": "-90.98560333",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "7019",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 101967,
            "s_seats": 29188,
            "j_seats": 48114,
            "f_seats": 9245,
            "r_seats": 6013,
            "cargo_demand": 326570
        }
    },
    {
        "iata": "GLI",
        "icao": "YGLI",
        "airportName": "Glen Innes Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Glen Innes",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-29.67499924",
        "longitude": "151.6889954",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5499",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4915",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134961,
            "s_seats": 48944,
            "j_seats": 12445,
            "f_seats": 2682,
            "r_seats": 9404,
            "cargo_demand": 121479
        }
    },
    {
        "iata": "GLL",
        "icao": "ENKL",
        "airportName": "Gol Airport/c/ Klanten",
        "location_country": "Norway",
        "location_city": "Gol",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "60.791066",
        "longitude": "9.048672",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3281",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 2949,
            "s_seats": 2182,
            "j_seats": 1155,
            "f_seats": 175,
            "r_seats": 198,
            "cargo_demand": 86481
        }
    },
    {
        "iata": "GLM",
        "icao": "YGLO",
        "airportName": "Glenormiston Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Glenormiston",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.88829994",
        "longitude": "138.8249969",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "1998",
                "runway_width": "",
                "runway_material": "C"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3405",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 3670,
            "s_seats": 2503,
            "j_seats": 1479,
            "f_seats": 411,
            "r_seats": 164,
            "cargo_demand": 98380
        }
    },
    {
        "iata": "GLO",
        "icao": "EGBJ",
        "airportName": "Gloucestershire Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Gloucester / Cheltenham",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.89419937",
        "longitude": "-2.167220116",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3218",
                "runway_width": "112",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4656",
                "runway_width": "112",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2625",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121832,
            "s_seats": 37138,
            "j_seats": 33727,
            "f_seats": 17541,
            "r_seats": 7878,
            "cargo_demand": 412109
        }
    },
    {
        "iata": "GLR",
        "icao": "KGLR",
        "airportName": "Gaylord Regional Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Gaylord",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.01350021",
        "longitude": "-84.70359802",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6578",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3322,
            "s_seats": 2577,
            "j_seats": 1494,
            "f_seats": 146,
            "r_seats": 28,
            "cargo_demand": 9735
        }
    },
    {
        "iata": "GLS",
        "icao": "KGLS",
        "airportName": "Scholes International Airport at Galveston",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Galveston",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "29.26530075",
        "longitude": "-94.86039734",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3774",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6001",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 98212,
            "s_seats": 44118,
            "j_seats": 15899,
            "f_seats": 19970,
            "r_seats": 9250,
            "cargo_demand": 191595
        }
    },
    {
        "iata": "GLT",
        "icao": "YGLA",
        "airportName": "Gladstone Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Gladstone",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.869699",
        "longitude": "151.223007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5364",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 91718,
            "s_seats": 31581,
            "j_seats": 21173,
            "f_seats": 13462,
            "r_seats": 6490,
            "cargo_demand": 249068
        }
    },
    {
        "iata": "GLU",
        "icao": "VQGP",
        "airportName": "Gelephu Airport",
        "location_country": "Bhutan",
        "location_city": "Gelephu",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.88456",
        "longitude": "90.46412",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4921",
                "runway_width": "115",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 149400,
            "s_seats": 28516,
            "j_seats": 55170,
            "f_seats": 11348,
            "r_seats": 6577,
            "cargo_demand": 265706
        }
    },
    {
        "iata": "GLV",
        "icao": "PAGL",
        "airportName": "Golovin Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Golovin",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "64.55049896",
        "longitude": "-163.0070038",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 1335,
            "s_seats": 2249,
            "j_seats": 1198,
            "f_seats": 7,
            "r_seats": 12,
            "cargo_demand": 16200
        }
    },
    {
        "iata": "GLW",
        "icao": "KGLW",
        "airportName": "Glasgow Municipal Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Glasgow",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.03179932",
        "longitude": "-85.9536972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5301",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2982,
            "s_seats": 2889,
            "j_seats": 1966,
            "f_seats": 6,
            "r_seats": 191,
            "cargo_demand": 33763
        }
    },
    {
        "iata": "GLX",
        "icao": "WAMA",
        "airportName": "Gamar Malamo Airport",
        "location_country": "Indonesia",
        "location_city": "Galela",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "1.83833003",
        "longitude": "127.7860031",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2460",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1850,
            "s_seats": 2780,
            "j_seats": 1541,
            "f_seats": 206,
            "r_seats": 94,
            "cargo_demand": 13805
        }
    },
    {
        "iata": "GLZ",
        "icao": "EHGR",
        "airportName": "Gilze-Rijen Air Base",
        "location_country": "Netherlands",
        "location_city": "Breda",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.56740189",
        "longitude": "4.931829929",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6549",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "9118",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168082,
            "s_seats": 39526,
            "j_seats": 46351,
            "f_seats": 6255,
            "r_seats": 8680,
            "cargo_demand": 298221
        }
    },
    {
        "iata": "GMA",
        "icao": "FZFK",
        "airportName": "Gemena Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Gemena",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.235369921",
        "longitude": "19.77129936",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6550",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111231,
            "s_seats": 47760,
            "j_seats": 10037,
            "f_seats": 5669,
            "r_seats": 7070,
            "cargo_demand": 493712
        }
    },
    {
        "iata": "GMB",
        "icao": "HAGM",
        "airportName": "Gambela Airport",
        "location_country": "Ethiopia",
        "location_city": "Gambela",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.128760338",
        "longitude": "34.56309891",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8248",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 147471,
            "s_seats": 33390,
            "j_seats": 36634,
            "f_seats": 12269,
            "r_seats": 8769,
            "cargo_demand": 241854
        }
    },
    {
        "iata": "GMD",
        "icao": "GMMB",
        "airportName": "Ben Slimane Airport",
        "location_country": "Morocco",
        "location_city": "Ben Slimane",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct1",
        "airport_size": "small_airport",
        "latitude": "33.655399",
        "longitude": "-7.22145",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "10085",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3193,
            "s_seats": 1677,
            "j_seats": 1652,
            "f_seats": 262,
            "r_seats": 90,
            "cargo_demand": 52464
        }
    },
    {
        "iata": "GME",
        "icao": "UMGG",
        "airportName": "Gomel Airport",
        "location_country": "Belarus",
        "location_city": "Gomel",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.52700043",
        "longitude": "31.01670074",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8432",
                "runway_width": "141",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163380,
            "s_seats": 48952,
            "j_seats": 41367,
            "f_seats": 3001,
            "r_seats": 4638,
            "cargo_demand": 239729
        }
    },
    {
        "iata": "GMN",
        "icao": "NZGM",
        "airportName": "Greymouth Airport",
        "location_country": "New Zealand",
        "location_city": "Greymouth",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "small_airport",
        "latitude": "-42.46170044",
        "longitude": "171.1900024",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3579",
                "runway_width": "",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 2274,
            "s_seats": 2796,
            "j_seats": 1975,
            "f_seats": 494,
            "r_seats": 8,
            "cargo_demand": 97254
        }
    },
    {
        "iata": "GMP",
        "icao": "RKSS",
        "airportName": "Gimpo International Airport",
        "location_country": "South Korea",
        "location_city": "Seoul",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.5583",
        "longitude": "126.791",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "10499",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130000,
            "s_seats": 98248,
            "j_seats": 102819,
            "f_seats": 28191,
            "r_seats": 10000,
            "cargo_demand": 13830786
        }
    },
    {
        "iata": "GMR",
        "icao": "NTGJ",
        "airportName": "Totegegie Airport",
        "location_country": "French Polynesia",
        "location_city": "Gambier Islands",
        "location_state": "Mangareva",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.07990074",
        "longitude": "-134.8899994",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165678,
            "s_seats": 47926,
            "j_seats": 59693,
            "f_seats": 7927,
            "r_seats": 4141,
            "cargo_demand": 215917
        }
    },
    {
        "iata": "GMU",
        "icao": "KGMU",
        "airportName": "Greenville Downtown Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Greenville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.84790039",
        "longitude": "-82.34999847",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5393",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3998",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140933,
            "s_seats": 40158,
            "j_seats": 12949,
            "f_seats": 15715,
            "r_seats": 4328,
            "cargo_demand": 171821
        }
    },
    {
        "iata": "GMZ",
        "icao": "GCGM",
        "airportName": "La Gomera Airport",
        "location_country": "Spain",
        "location_city": "La Gomera / Canary Islands",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "28.02960014",
        "longitude": "-17.21459961",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3595,
            "s_seats": 1592,
            "j_seats": 1910,
            "f_seats": 307,
            "r_seats": 37,
            "cargo_demand": 38788
        }
    },
    {
        "iata": "GNA",
        "icao": "UMMG",
        "airportName": "Grodno Airport",
        "location_country": "Belarus",
        "location_city": "Grodno",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.60200119",
        "longitude": "24.05380058",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2172",
                "runway_width": "92",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "8399",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 175719,
            "s_seats": 40312,
            "j_seats": 16486,
            "f_seats": 8728,
            "r_seats": 3035,
            "cargo_demand": 167054
        }
    },
    {
        "iata": "GNB",
        "icao": "LFLS",
        "airportName": "Grenoble-Isere Airport",
        "location_country": "France",
        "location_city": "Rhone-Alpes",
        "location_state": "Grenoble",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.3629",
        "longitude": "5.32937",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67819,
            "s_seats": 29353,
            "j_seats": 35888,
            "f_seats": 8000,
            "r_seats": 4986,
            "cargo_demand": 427782
        }
    },
    {
        "iata": "GND",
        "icao": "TGPY",
        "airportName": "Maurice Bishop International Airport",
        "location_country": "Grenada",
        "location_city": "St. George's",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.00419998",
        "longitude": "-61.78620148",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9003",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112280,
            "s_seats": 44022,
            "j_seats": 37327,
            "f_seats": 6121,
            "r_seats": 3087,
            "cargo_demand": 204986
        }
    },
    {
        "iata": "GNG",
        "icao": "KGNG",
        "airportName": "Gooding Municipal Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Gooding",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.91719818",
        "longitude": "-114.7649994",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4736",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2467,
            "s_seats": 2050,
            "j_seats": 1516,
            "f_seats": 87,
            "r_seats": 8,
            "cargo_demand": 3727
        }
    },
    {
        "iata": "GNI",
        "icao": "RCGI",
        "airportName": "Lyudao Airport",
        "location_country": "Taiwan",
        "location_city": "Lyudao (Green Island)",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "22.6739006",
        "longitude": "121.4660034",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3255",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1192,
            "s_seats": 2938,
            "j_seats": 1304,
            "f_seats": 67,
            "r_seats": 26,
            "cargo_demand": 97687
        }
    },
    {
        "iata": "GNM",
        "icao": "SNGI",
        "airportName": "Guanambi Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Guanambi",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.20820045",
        "longitude": "-42.74610138",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5577",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1124,
            "s_seats": 1946,
            "j_seats": 1449,
            "f_seats": 444,
            "r_seats": 167,
            "cargo_demand": 64763
        }
    },
    {
        "iata": "GNR",
        "icao": "SAHR",
        "airportName": "Dr. Arturo Umberto Illia Airport",
        "location_country": "Argentina",
        "location_city": "Rio Negro",
        "location_state": "General Roca",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-39.0007019",
        "longitude": "-67.62049866",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "7073",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3797,
            "s_seats": 2845,
            "j_seats": 1188,
            "f_seats": 22,
            "r_seats": 110,
            "cargo_demand": 94832
        }
    },
    {
        "iata": "GNS",
        "icao": "WIMB",
        "airportName": "Binaka Airport",
        "location_country": "Indonesia",
        "location_city": "Gunungsitoli",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.16628",
        "longitude": "97.704327",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4445",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198656,
            "s_seats": 48687,
            "j_seats": 34407,
            "f_seats": 7423,
            "r_seats": 7887,
            "cargo_demand": 165653
        }
    },
    {
        "iata": "GNT",
        "icao": "KGNT",
        "airportName": "Grants-Milan Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Grants",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.16730118",
        "longitude": "-107.9020004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2350",
                "runway_width": "40",
                "runway_material": "GRE"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7172",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1787,
            "s_seats": 2755,
            "j_seats": 1358,
            "f_seats": 442,
            "r_seats": 56,
            "cargo_demand": 98194
        }
    },
    {
        "iata": "GNV",
        "icao": "KGNV",
        "airportName": "Gainesville Regional Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Gainesville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "29.69009972",
        "longitude": "-82.27179718",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4158",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "7504",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191291,
            "s_seats": 47045,
            "j_seats": 36410,
            "f_seats": 1438,
            "r_seats": 5289,
            "cargo_demand": 425775
        }
    },
    {
        "iata": "GOA",
        "icao": "LIMJ",
        "airportName": "Genoa Cristoforo Colombo Airport",
        "location_country": "Italy",
        "location_city": "Liguria",
        "location_state": "Genoa",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "44.4133",
        "longitude": "8.8375",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9564",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 491225,
            "s_seats": 71625,
            "j_seats": 77814,
            "f_seats": 22543,
            "r_seats": 10000,
            "cargo_demand": 23706641
        }
    },
    {
        "iata": "GOH",
        "icao": "BGGH",
        "airportName": "Nuuk Airport",
        "location_country": "Greenland",
        "location_city": "Nuuk",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "64.19090271",
        "longitude": "-51.67810059",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3117",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57430,
            "s_seats": 27422,
            "j_seats": 53091,
            "f_seats": 7629,
            "r_seats": 5415,
            "cargo_demand": 149445
        }
    },
    {
        "iata": "GOJ",
        "icao": "UWGG",
        "airportName": "Nizhny Novgorod International Airport",
        "location_country": "Russia",
        "location_city": "Nizhny Novgorod Oblast",
        "location_state": "Nizhny Novgorod",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.23009872",
        "longitude": "43.7840004",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "1640",
                "runway_width": "72",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "1969",
                "runway_width": "72",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "9203",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 198466,
            "s_seats": 41427,
            "j_seats": 5635,
            "f_seats": 4029,
            "r_seats": 7240,
            "cargo_demand": 75164
        }
    },
    {
        "iata": "GOK",
        "icao": "KGOK",
        "airportName": "Guthrie-Edmond Regional Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Guthrie",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.84980011",
        "longitude": "-97.41560364",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4102",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1546,
            "s_seats": 2595,
            "j_seats": 1949,
            "f_seats": 301,
            "r_seats": 127,
            "cargo_demand": 77472
        }
    },
    {
        "iata": "GOM",
        "icao": "FZNA",
        "airportName": "Goma International Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Goma",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.670809984",
        "longitude": "29.2385006",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6545",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167548,
            "s_seats": 46456,
            "j_seats": 52742,
            "f_seats": 4838,
            "r_seats": 9017,
            "cargo_demand": 350775
        }
    },
    {
        "iata": "GON",
        "icao": "KGON",
        "airportName": "Groton-New London Airport",
        "location_country": "United States",
        "location_city": "Connecticut",
        "location_state": "Groton / New London",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.33010101",
        "longitude": "-72.04509735",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4000",
                "runway_width": "96",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161373,
            "s_seats": 42686,
            "j_seats": 45655,
            "f_seats": 6944,
            "r_seats": 7584,
            "cargo_demand": 354906
        }
    },
    {
        "iata": "GOO",
        "icao": "YGDI",
        "airportName": "Goondiwindi Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Goondiwindi",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.52140045",
        "longitude": "150.3200073",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4396",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2608",
                "runway_width": "98",
                "runway_material": "CLA"
            }
        ],
        "demand": {
            "y_seats": 2881,
            "s_seats": 1567,
            "j_seats": 1902,
            "f_seats": 13,
            "r_seats": 78,
            "cargo_demand": 19010
        }
    },
    {
        "iata": "GOP",
        "icao": "VEGK",
        "airportName": "Gorakhpur Airport",
        "location_country": "India",
        "location_city": "Uttar Pradesh",
        "location_state": "Gorakhpur",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.73970032",
        "longitude": "83.4496994",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 103518,
            "s_seats": 26505,
            "j_seats": 48801,
            "f_seats": 8838,
            "r_seats": 8697,
            "cargo_demand": 448069
        }
    },
    {
        "iata": "GOQ",
        "icao": "ZLGM",
        "airportName": "Golmud Airport",
        "location_country": "China",
        "location_city": "Qinghai",
        "location_state": "Golmud",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.4006",
        "longitude": "94.786102",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "15748",
                "runway_width": "160",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 80378,
            "s_seats": 49204,
            "j_seats": 31195,
            "f_seats": 3474,
            "r_seats": 9554,
            "cargo_demand": 154049
        }
    },
    {
        "iata": "GOT",
        "icao": "ESGG",
        "airportName": "Goteborg Landvetter Airport",
        "location_country": "Sweden",
        "location_city": "Gothenburg",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "57.66279984",
        "longitude": "12.27980042",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10823",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 476915,
            "s_seats": 75667,
            "j_seats": 145401,
            "f_seats": 42166,
            "r_seats": 10000,
            "cargo_demand": 25491063
        }
    },
    {
        "iata": "GOU",
        "icao": "FKKR",
        "airportName": "Garoua International Airport",
        "location_country": "Cameroon",
        "location_city": "Garoua",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.335889816",
        "longitude": "13.37010002",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "11032",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179755,
            "s_seats": 35008,
            "j_seats": 34412,
            "f_seats": 11782,
            "r_seats": 5579,
            "cargo_demand": 107710
        }
    },
    {
        "iata": "GOV",
        "icao": "YPGV",
        "airportName": "Gove Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Nhulunbuy",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.26939964",
        "longitude": "136.8179932",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7244",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173367,
            "s_seats": 48637,
            "j_seats": 52863,
            "f_seats": 4501,
            "r_seats": 9351,
            "cargo_demand": 151090
        }
    },
    {
        "iata": "GOZ",
        "icao": "LBGO",
        "airportName": "Gorna Oryahovitsa Airport",
        "location_country": "Bulgaria",
        "location_city": "Gorna Oryahovitsa",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.15140152",
        "longitude": "25.71290016",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8038",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 156411,
            "s_seats": 49113,
            "j_seats": 51308,
            "f_seats": 8551,
            "r_seats": 3597,
            "cargo_demand": 320799
        }
    },
    {
        "iata": "GPA",
        "icao": "LGRX",
        "airportName": "Araxos Airport",
        "location_country": "Greece",
        "location_city": "Patras",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "38.1511",
        "longitude": "21.4256",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "10999",
                "runway_width": "70",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "10997",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 197456,
            "s_seats": 48480,
            "j_seats": 44326,
            "f_seats": 2161,
            "r_seats": 7207,
            "cargo_demand": 356143
        }
    },
    {
        "iata": "GPB",
        "icao": "SBGU",
        "airportName": "Tancredo Thomas de Faria Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Guarapuava",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-25.388327",
        "longitude": "-51.52349",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "5315",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3549,
            "s_seats": 2149,
            "j_seats": 1641,
            "f_seats": 256,
            "r_seats": 114,
            "cargo_demand": 8177
        }
    },
    {
        "iata": "GPI",
        "icao": "SKGP",
        "airportName": "Guapi Airport",
        "location_country": "Colombia",
        "location_city": "Guapi",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.57013",
        "longitude": "-77.8986",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4256",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83172,
            "s_seats": 35524,
            "j_seats": 33279,
            "f_seats": 15093,
            "r_seats": 8492,
            "cargo_demand": 187071
        }
    },
    {
        "iata": "GPL",
        "icao": "MRGP",
        "airportName": "Guapiles Airport",
        "location_country": "Costa Rica",
        "location_city": "Guapiles",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.21720028",
        "longitude": "-83.79699707",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3609",
                "runway_width": "30",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 139379,
            "s_seats": 28685,
            "j_seats": 37135,
            "f_seats": 10433,
            "r_seats": 6791,
            "cargo_demand": 299134
        }
    },
    {
        "iata": "GPN",
        "icao": "YGPT",
        "airportName": "Garden Point Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Pularumpi",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.40250015",
        "longitude": "130.4219971",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4314",
                "runway_width": "98",
                "runway_material": "COP"
            }
        ],
        "demand": {
            "y_seats": 105935,
            "s_seats": 40738,
            "j_seats": 54607,
            "f_seats": 6220,
            "r_seats": 6574,
            "cargo_demand": 395613
        }
    },
    {
        "iata": "GPO",
        "icao": "SAZG",
        "airportName": "General Pico Airport",
        "location_country": "Argentina",
        "location_city": "La Pampa",
        "location_state": "General Pico",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-35.69620132",
        "longitude": "-63.75830078",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2789",
                "runway_width": "164",
                "runway_material": "GRE"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7710",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183905,
            "s_seats": 49125,
            "j_seats": 58773,
            "f_seats": 9887,
            "r_seats": 8194,
            "cargo_demand": 215303
        }
    },
    {
        "iata": "GPS",
        "icao": "SEGS",
        "airportName": "Seymour Airport",
        "location_country": "Ecuador",
        "location_city": "Galapagos Islands",
        "location_state": "Baltra Island",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.453758001",
        "longitude": "-90.26589966",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7876",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1898,
            "s_seats": 2437,
            "j_seats": 1292,
            "f_seats": 343,
            "r_seats": 181,
            "cargo_demand": 15366
        }
    },
    {
        "iata": "GPT",
        "icao": "KGPT",
        "airportName": "Gulfport-Biloxi International Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Gulfport / Biloxi",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "30.40730095",
        "longitude": "-89.07009888",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "9002",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4935",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 459434,
            "s_seats": 84964,
            "j_seats": 109985,
            "f_seats": 68688,
            "r_seats": 10000,
            "cargo_demand": 20793958
        }
    },
    {
        "iata": "GPZ",
        "icao": "KGPZ",
        "airportName": "Grand Rapids-Itasca County Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Grand Rapids",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "47.21110153",
        "longitude": "-93.50980377",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASPH-F"
            },
            {
                "runway_name": "10/28",
                "runway_length": "1450",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5755",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1885,
            "s_seats": 2995,
            "j_seats": 1063,
            "f_seats": 341,
            "r_seats": 71,
            "cargo_demand": 89465
        }
    },
    {
        "iata": "GQQ",
        "icao": "KGQQ",
        "airportName": "Galion Municipal Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Galion",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.7533989",
        "longitude": "-82.72380066",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3505",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2212,
            "s_seats": 2597,
            "j_seats": 1381,
            "f_seats": 417,
            "r_seats": 128,
            "cargo_demand": 55475
        }
    },
    {
        "iata": "GRB",
        "icao": "KGRB",
        "airportName": "Austin Straubel International Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Green Bay",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "44.48509979",
        "longitude": "-88.12960052",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7700",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3198",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8201",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 233737,
            "s_seats": 73539,
            "j_seats": 31200,
            "f_seats": 58890,
            "r_seats": 10000,
            "cargo_demand": 8252707
        }
    },
    {
        "iata": "GRD",
        "icao": "KGRD",
        "airportName": "Greenwood County Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Greenwood",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.24869919",
        "longitude": "-82.15910339",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5003",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2374,
            "s_seats": 1678,
            "j_seats": 1109,
            "f_seats": 22,
            "r_seats": 53,
            "cargo_demand": 43485
        }
    },
    {
        "iata": "GRE",
        "icao": "KGRE",
        "airportName": "Greenville Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Greenville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.83620071",
        "longitude": "-89.37840271",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3200",
                "runway_width": "250",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4002",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2992,
            "s_seats": 2615,
            "j_seats": 1829,
            "f_seats": 65,
            "r_seats": 109,
            "cargo_demand": 74264
        }
    },
    {
        "iata": "GRF",
        "icao": "KGRF",
        "airportName": "Gray Army Airfield",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Tacoma",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.07920074",
        "longitude": "-122.5810013",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6125",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110747,
            "s_seats": 38632,
            "j_seats": 51532,
            "f_seats": 9676,
            "r_seats": 6947,
            "cargo_demand": 289808
        }
    },
    {
        "iata": "GRI",
        "icao": "KGRI",
        "airportName": "Central Nebraska Regional Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Grand Island",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.96749878",
        "longitude": "-98.30960083",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6608",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7002",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73331,
            "s_seats": 25197,
            "j_seats": 57757,
            "f_seats": 3184,
            "r_seats": 6402,
            "cargo_demand": 125160
        }
    },
    {
        "iata": "GRJ",
        "icao": "FAGG",
        "airportName": "George Airport",
        "location_country": "South Africa",
        "location_city": "George",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-34.0056",
        "longitude": "22.378902",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 332330,
            "s_seats": 73346,
            "j_seats": 92920,
            "f_seats": 69198,
            "r_seats": 10000,
            "cargo_demand": 416043
        }
    },
    {
        "iata": "GRK",
        "icao": "KGRK",
        "airportName": "Killeen-Fort Hood Regional Airport / Robert Gray Army Airfield",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Fort Hood / Killeen",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.0672",
        "longitude": "-97.828903",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "10000",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 96430,
            "s_seats": 40987,
            "j_seats": 58293,
            "f_seats": 12923,
            "r_seats": 3003,
            "cargo_demand": 118064
        }
    },
    {
        "iata": "GRM",
        "icao": "KCKC",
        "airportName": "Grand Marais/Cook County Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Grand Marais",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "47.8382988",
        "longitude": "-90.38289642",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4199",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3987,
            "s_seats": 1650,
            "j_seats": 1390,
            "f_seats": 103,
            "r_seats": 120,
            "cargo_demand": 49154
        }
    },
    {
        "iata": "GRN",
        "icao": "KGRN",
        "airportName": "Gordon Municipal Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Gordon",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.80599976",
        "longitude": "-102.1750031",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5196",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2284",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3604,
            "s_seats": 2138,
            "j_seats": 1978,
            "f_seats": 249,
            "r_seats": 186,
            "cargo_demand": 38434
        }
    },
    {
        "iata": "GRO",
        "icao": "LEGE",
        "airportName": "Girona-Costa Brava Airport",
        "location_country": "Spain",
        "location_city": "Catalonia",
        "location_state": "Girona",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "41.90100098",
        "longitude": "2.760550022",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88441,
            "s_seats": 35877,
            "j_seats": 13572,
            "f_seats": 18673,
            "r_seats": 8758,
            "cargo_demand": 457228
        }
    },
    {
        "iata": "GRP",
        "icao": "SWGI",
        "airportName": "Gurupi Airport",
        "location_country": "Brazil",
        "location_city": "Tocantins",
        "location_state": "Gurupi",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-11.73960018",
        "longitude": "-49.13219833",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5577",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3460,
            "s_seats": 2523,
            "j_seats": 1864,
            "f_seats": 294,
            "r_seats": 101,
            "cargo_demand": 83890
        }
    },
    {
        "iata": "GRQ",
        "icao": "EHGG",
        "airportName": "Groningen Airport Eelde",
        "location_country": "Netherlands",
        "location_city": "Groningen",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "53.11970139",
        "longitude": "6.579440117",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4922",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 133179,
            "s_seats": 40744,
            "j_seats": 51238,
            "f_seats": 16510,
            "r_seats": 9328,
            "cargo_demand": 270785
        }
    },
    {
        "iata": "GRR",
        "icao": "KGRR",
        "airportName": "Gerald R. Ford International Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Grand Rapids",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.88079834",
        "longitude": "-85.52279663",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "8501",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 119820,
            "s_seats": 46095,
            "j_seats": 31753,
            "f_seats": 12266,
            "r_seats": 6092,
            "cargo_demand": 147207
        }
    },
    {
        "iata": "GRS",
        "icao": "LIRS",
        "airportName": "Grosseto Airport",
        "location_country": "Italy",
        "location_city": "Tuscany",
        "location_state": "Grosseto",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "42.759701",
        "longitude": "11.0719",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9823",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "7723",
                "runway_width": "85",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69927,
            "s_seats": 43113,
            "j_seats": 49117,
            "f_seats": 5752,
            "r_seats": 4535,
            "cargo_demand": 79381
        }
    },
    {
        "iata": "GRU",
        "icao": "SBGR",
        "airportName": "Sio Paulo/Guarulhos-Governador Andre Franco Montoro International Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "large_airport",
        "latitude": "-23.43555641",
        "longitude": "-46.47305679",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "12139",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129841,
            "s_seats": 72898,
            "j_seats": 140618,
            "f_seats": 51332,
            "r_seats": 10000,
            "cargo_demand": 671641
        }
    },
    {
        "iata": "GRW",
        "icao": "LPGR",
        "airportName": "Graciosa Airport",
        "location_country": "Portugal",
        "location_city": "Azores",
        "location_state": "Graciosa",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "39.09220123",
        "longitude": "-28.02980042",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4529",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 101108,
            "s_seats": 46931,
            "j_seats": 10449,
            "f_seats": 14462,
            "r_seats": 7909,
            "cargo_demand": 433892
        }
    },
    {
        "iata": "GRX",
        "icao": "LEGR",
        "airportName": "Federico Garcia Lorca Airport",
        "location_country": "Spain",
        "location_city": "Andalusia",
        "location_state": "Granada",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "37.18870163",
        "longitude": "-3.777359962",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9514",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79329,
            "s_seats": 39481,
            "j_seats": 24512,
            "f_seats": 11210,
            "r_seats": 6423,
            "cargo_demand": 466993
        }
    },
    {
        "iata": "GRY",
        "icao": "BIGR",
        "airportName": "Grimsey Airport",
        "location_country": "Iceland",
        "location_city": "Grimsey",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "66.5458",
        "longitude": "-18.0173",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3379",
                "runway_width": "",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 2837,
            "s_seats": 1910,
            "j_seats": 1204,
            "f_seats": 107,
            "r_seats": 142,
            "cargo_demand": 2381
        }
    },
    {
        "iata": "GRZ",
        "icao": "LOWG",
        "airportName": "Graz Airport",
        "location_country": "Austria",
        "location_city": "Graz",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.9911",
        "longitude": "15.4396",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16C/34C",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "2100",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "2493",
                "runway_width": "82",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 176433,
            "s_seats": 32932,
            "j_seats": 16137,
            "f_seats": 2852,
            "r_seats": 8681,
            "cargo_demand": 120402
        }
    },
    {
        "iata": "GSB",
        "icao": "KGSB",
        "airportName": "Seymour Johnson Air Force Base",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Goldsboro",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "35.33940125",
        "longitude": "-77.96060181",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11758",
                "runway_width": "300",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 465930,
            "s_seats": 92133,
            "j_seats": 65406,
            "f_seats": 37622,
            "r_seats": 10000,
            "cargo_demand": 4143511
        }
    },
    {
        "iata": "GSC",
        "icao": "YGSC",
        "airportName": "Gascoyne Junction Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Gascoyne Junction",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.0546",
        "longitude": "115.2026",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4035",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1985,
            "s_seats": 1725,
            "j_seats": 1430,
            "f_seats": 460,
            "r_seats": 127,
            "cargo_demand": 50555
        }
    },
    {
        "iata": "GSE",
        "icao": "ESGP",
        "airportName": "Goteborg City Airport",
        "location_country": "Sweden",
        "location_city": "Gothenburg",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "57.7747",
        "longitude": "11.8704",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3560",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04/22",
                "runway_length": "2858",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156040,
            "s_seats": 36620,
            "j_seats": 37780,
            "f_seats": 7506,
            "r_seats": 8954,
            "cargo_demand": 349735
        }
    },
    {
        "iata": "GSH",
        "icao": "KGSH",
        "airportName": "Goshen Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Goshen",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.52640152",
        "longitude": "-85.79290009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2585",
                "runway_width": "180",
                "runway_material": "GRS"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3252,
            "s_seats": 1516,
            "j_seats": 1942,
            "f_seats": 289,
            "r_seats": 135,
            "cargo_demand": 25520
        }
    },
    {
        "iata": "GSJ",
        "icao": "MGSJ",
        "airportName": "San Jose Airport",
        "location_country": "Guatemala",
        "location_city": "Puerto San Jose",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.93620014",
        "longitude": "-90.83580017",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6595",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86211,
            "s_seats": 34324,
            "j_seats": 56564,
            "f_seats": 5293,
            "r_seats": 9671,
            "cargo_demand": 215172
        }
    },
    {
        "iata": "GSM",
        "icao": "OIKQ",
        "airportName": "Dayrestan Airport",
        "location_country": "Iran",
        "location_city": "Qeshm",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "26.75460052",
        "longitude": "55.90240097",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "13864",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166670,
            "s_seats": 29516,
            "j_seats": 42753,
            "f_seats": 12112,
            "r_seats": 9586,
            "cargo_demand": 158734
        }
    },
    {
        "iata": "GSN",
        "icao": "YMGN",
        "airportName": "Mount Gunson Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Mount Gunson",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-31.4597",
        "longitude": "137.1744433",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3608",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1544,
            "s_seats": 1960,
            "j_seats": 1731,
            "f_seats": 204,
            "r_seats": 41,
            "cargo_demand": 3553
        }
    },
    {
        "iata": "GSO",
        "icao": "KGSO",
        "airportName": "Piedmont Triad International Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Greensboro",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "36.09780121",
        "longitude": "-79.93730164",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "10001",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6380",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 219191,
            "s_seats": 82804,
            "j_seats": 136040,
            "f_seats": 20818,
            "r_seats": 10000,
            "cargo_demand": 13286605
        }
    },
    {
        "iata": "GSP",
        "icao": "KGSP",
        "airportName": "Greenville-Spartanburg International Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Greenville / Spartanburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "34.895699",
        "longitude": "-82.218903",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "11000",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 129282,
            "s_seats": 76073,
            "j_seats": 52891,
            "f_seats": 26526,
            "r_seats": 10000,
            "cargo_demand": 315531
        }
    },
    {
        "iata": "GSQ",
        "icao": "HEOW",
        "airportName": "Sharq Al-Owainat Airport",
        "location_country": "Egypt",
        "location_city": "Sharq Al-Owainat",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "22.585699",
        "longitude": "28.7166",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2966,
            "s_seats": 2073,
            "j_seats": 1752,
            "f_seats": 267,
            "r_seats": 183,
            "cargo_demand": 50944
        }
    },
    {
        "iata": "GST",
        "icao": "PAGS",
        "airportName": "Gustavus Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Gustavus",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "58.4253006",
        "longitude": "-135.7070007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3010",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6720",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151418,
            "s_seats": 37264,
            "j_seats": 40523,
            "f_seats": 16925,
            "r_seats": 7206,
            "cargo_demand": 366541
        }
    },
    {
        "iata": "GTE",
        "icao": "YGTE",
        "airportName": "Groote Eylandt Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Groote Eylandt",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-13.97500038",
        "longitude": "136.4600067",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6237",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122327,
            "s_seats": 28674,
            "j_seats": 38557,
            "f_seats": 5258,
            "r_seats": 9661,
            "cargo_demand": 124279
        }
    },
    {
        "iata": "GTF",
        "icao": "KGTF",
        "airportName": "Great Falls International Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Great Falls",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.48199844",
        "longitude": "-111.3710022",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10502",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "4294",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6357",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139879,
            "s_seats": 46020,
            "j_seats": 44964,
            "f_seats": 12352,
            "r_seats": 4294,
            "cargo_demand": 225444
        }
    },
    {
        "iata": "GTG",
        "icao": "KGTG",
        "airportName": "Grantsburg Municipal Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Grantsburg",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.79809952",
        "longitude": "-92.66439819",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3315",
                "runway_width": "120",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2422,
            "s_seats": 2679,
            "j_seats": 1669,
            "f_seats": 160,
            "r_seats": 68,
            "cargo_demand": 5751
        }
    },
    {
        "iata": "GTI",
        "icao": "EDCG",
        "airportName": "Rugen Airport",
        "location_country": "Germany",
        "location_city": "Mecklenburg-Vorpommern",
        "location_state": "Guttin",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "54.383331",
        "longitude": "13.325556",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2953",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1498,
            "s_seats": 1509,
            "j_seats": 1918,
            "f_seats": 357,
            "r_seats": 91,
            "cargo_demand": 98840
        }
    },
    {
        "iata": "GTN",
        "icao": "NZGT",
        "airportName": "Glentanner Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Mount Cook",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-43.90670013",
        "longitude": "170.128006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3166",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130149,
            "s_seats": 45051,
            "j_seats": 6039,
            "f_seats": 4774,
            "r_seats": 9231,
            "cargo_demand": 189421
        }
    },
    {
        "iata": "GTO",
        "icao": "WAMG",
        "airportName": "Jalaluddin Airport",
        "location_country": "Indonesia",
        "location_city": "Gorontalo",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.637118995",
        "longitude": "122.8499985",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7407",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1368,
            "s_seats": 2838,
            "j_seats": 1827,
            "f_seats": 377,
            "r_seats": 179,
            "cargo_demand": 52832
        }
    },
    {
        "iata": "GTR",
        "icao": "KGTR",
        "airportName": "Golden Triangle Regional Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Columbus / West Point / Starkville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.45029831",
        "longitude": "-88.59140015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8002",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76777,
            "s_seats": 27261,
            "j_seats": 25303,
            "f_seats": 18535,
            "r_seats": 3029,
            "cargo_demand": 126476
        }
    },
    {
        "iata": "GTS",
        "icao": "YTGT",
        "airportName": "The Granites Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "The Granites",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.5483",
        "longitude": "130.347",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1377,
            "s_seats": 2655,
            "j_seats": 1518,
            "f_seats": 219,
            "r_seats": 115,
            "cargo_demand": 91274
        }
    },
    {
        "iata": "GTT",
        "icao": "YGTN",
        "airportName": "Georgetown Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Georgetown",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.30500031",
        "longitude": "143.5299988",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3799",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1062,
            "s_seats": 1784,
            "j_seats": 1915,
            "f_seats": 86,
            "r_seats": 17,
            "cargo_demand": 86180
        }
    },
    {
        "iata": "GUA",
        "icao": "MGGT",
        "airportName": "La Aurora International Airport",
        "location_country": "Guatemala",
        "location_city": "Guatemala City",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "14.5833",
        "longitude": "-90.527496",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9800",
                "runway_width": "196",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156542,
            "s_seats": 78890,
            "j_seats": 135725,
            "f_seats": 67955,
            "r_seats": 10000,
            "cargo_demand": 14548806
        }
    },
    {
        "iata": "GUB",
        "icao": "MMGR",
        "airportName": "Guerrero Negro Airport",
        "location_country": "Mexico",
        "location_city": "Baja California Sur",
        "location_state": "Guerrero Negro",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "small_airport",
        "latitude": "28.0261",
        "longitude": "-114.024002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7216",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1234,
            "s_seats": 2343,
            "j_seats": 1418,
            "f_seats": 378,
            "r_seats": 88,
            "cargo_demand": 1093
        }
    },
    {
        "iata": "GUC",
        "icao": "KGUC",
        "airportName": "Gunnison-Crested Butte Regional Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Gunnison",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.53390121",
        "longitude": "-106.9329987",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9400",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3000",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 1357,
            "s_seats": 1613,
            "j_seats": 1220,
            "f_seats": 264,
            "r_seats": 169,
            "cargo_demand": 26189
        }
    },
    {
        "iata": "GUD",
        "icao": "GAGM",
        "airportName": "Goundam Airport",
        "location_country": "Mali",
        "location_city": "Goundam",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "16.3614006",
        "longitude": "-3.599720001",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3744,
            "s_seats": 1816,
            "j_seats": 1583,
            "f_seats": 94,
            "r_seats": 100,
            "cargo_demand": 12883
        }
    },
    {
        "iata": "GUF",
        "icao": "KJKA",
        "airportName": "Jack Edwards Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Gulf Shores",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "30.290501",
        "longitude": "-87.671799",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6962",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3599",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2871,
            "s_seats": 1635,
            "j_seats": 1542,
            "f_seats": 363,
            "r_seats": 142,
            "cargo_demand": 25055
        }
    },
    {
        "iata": "GUH",
        "icao": "YGDH",
        "airportName": "Gunnedah Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Gunnedah",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-30.96109962",
        "longitude": "150.2510071",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5400",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "1942",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 114784,
            "s_seats": 44351,
            "j_seats": 53599,
            "f_seats": 18842,
            "r_seats": 9539,
            "cargo_demand": 112106
        }
    },
    {
        "iata": "GUI",
        "icao": "SVGI",
        "airportName": "Guiria Airport",
        "location_country": "Venezuela",
        "location_city": "Guiria",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.57407761",
        "longitude": "-62.31266785",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6585",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173780,
            "s_seats": 44689,
            "j_seats": 33565,
            "f_seats": 9800,
            "r_seats": 5183,
            "cargo_demand": 49181
        }
    },
    {
        "iata": "GUJ",
        "icao": "SBGW",
        "airportName": "Guaratingueta Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Guaratingueta",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-22.79159927",
        "longitude": "-45.20479965",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "5089",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180697,
            "s_seats": 49575,
            "j_seats": 58039,
            "f_seats": 4407,
            "r_seats": 4081,
            "cargo_demand": 462304
        }
    },
    {
        "iata": "GUL",
        "icao": "YGLB",
        "airportName": "Goulburn Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Goulburn",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-34.81029892",
        "longitude": "149.7259979",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4209",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "2218",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 103645,
            "s_seats": 39461,
            "j_seats": 56523,
            "f_seats": 1588,
            "r_seats": 3894,
            "cargo_demand": 365748
        }
    },
    {
        "iata": "GUM",
        "icao": "PGUM",
        "airportName": "Antonio B. Won Pat International Airport",
        "location_country": "Guam",
        "location_city": "Hagiotna",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "13.4834",
        "longitude": "144.796005",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "12015",
                "runway_width": "150",
                "runway_material": "asphalt"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "10014",
                "runway_width": "150",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 216580,
            "s_seats": 87147,
            "j_seats": 84365,
            "f_seats": 63261,
            "r_seats": 10000,
            "cargo_demand": 21768696
        }
    },
    {
        "iata": "GUP",
        "icao": "KGUP",
        "airportName": "Gallup Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Gallup",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.51110077",
        "longitude": "-108.7890015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7316",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67979,
            "s_seats": 43616,
            "j_seats": 34586,
            "f_seats": 11512,
            "r_seats": 3990,
            "cargo_demand": 437027
        }
    },
    {
        "iata": "GUQ",
        "icao": "SVGU",
        "airportName": "Guanare Airport",
        "location_country": "Venezuela",
        "location_city": "Guanare",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.02694416",
        "longitude": "-69.75514984",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5905",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134461,
            "s_seats": 30031,
            "j_seats": 43269,
            "f_seats": 15698,
            "r_seats": 5040,
            "cargo_demand": 434908
        }
    },
    {
        "iata": "GUR",
        "icao": "AYGN",
        "airportName": "Gurney Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Alotau",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-10.3114996",
        "longitude": "150.3339996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5546",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68272,
            "s_seats": 32863,
            "j_seats": 12757,
            "f_seats": 1631,
            "r_seats": 9083,
            "cargo_demand": 313959
        }
    },
    {
        "iata": "GUS",
        "icao": "KGUS",
        "airportName": "Grissom Air Reserve Base",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Peru",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "40.64810181",
        "longitude": "-86.15209961",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "12501",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 469471,
            "s_seats": 72917,
            "j_seats": 40799,
            "f_seats": 39840,
            "r_seats": 10000,
            "cargo_demand": 10207419
        }
    },
    {
        "iata": "GUW",
        "icao": "UATG",
        "airportName": "Atyrau Airport",
        "location_country": "Kazakhstan",
        "location_city": "Atyrau",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.12189865",
        "longitude": "51.82139969",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9842",
                "runway_width": "144",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137314,
            "s_seats": 37922,
            "j_seats": 24155,
            "f_seats": 2818,
            "r_seats": 8152,
            "cargo_demand": 268539
        }
    },
    {
        "iata": "GUX",
        "icao": "VAGN",
        "airportName": "Guna Airport",
        "location_country": "India",
        "location_city": "Madhya Pradesh",
        "location_state": "Guna",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "24.654699",
        "longitude": "77.347298",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2982",
                "runway_width": "72",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2126,
            "s_seats": 2877,
            "j_seats": 1041,
            "f_seats": 15,
            "r_seats": 53,
            "cargo_demand": 60887
        }
    },
    {
        "iata": "GUY",
        "icao": "KGUY",
        "airportName": "Guymon Municipal Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Guymon",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.68510056",
        "longitude": "-101.5080032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "1795",
                "runway_width": "200",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5900",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57707,
            "s_seats": 36288,
            "j_seats": 24317,
            "f_seats": 16711,
            "r_seats": 7537,
            "cargo_demand": 271664
        }
    },
    {
        "iata": "GUZ",
        "icao": "SNGA",
        "airportName": "Guarapari Airport",
        "location_country": "Brazil",
        "location_city": "Espirito Santo",
        "location_state": "Guarapari",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-20.6465",
        "longitude": "-40.491901",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "3904",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3525,
            "s_seats": 1779,
            "j_seats": 1510,
            "f_seats": 351,
            "r_seats": 87,
            "cargo_demand": 7850
        }
    },
    {
        "iata": "GVA",
        "icao": "LSGG",
        "airportName": "Geneva Airport",
        "location_country": "Switzerland",
        "location_city": "Geneva",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "46.23809814",
        "longitude": "6.108950138",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "12795",
                "runway_width": "164",
                "runway_material": "CONC"
            },
            {
                "runway_name": "04L/22R",
                "runway_length": "2700",
                "runway_width": "98",
                "runway_material": "GRASS CAUTION: ATC do NOT apply wake turbulence separation!"
            }
        ],
        "demand": {
            "y_seats": 492781,
            "s_seats": 83253,
            "j_seats": 142497,
            "f_seats": 21301,
            "r_seats": 10000,
            "cargo_demand": 26689220
        }
    },
    {
        "iata": "GVL",
        "icao": "KGVL",
        "airportName": "Lee Gilmer Memorial Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Gainesville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.27259827",
        "longitude": "-83.8302002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2158,
            "s_seats": 1688,
            "j_seats": 1531,
            "f_seats": 112,
            "r_seats": 92,
            "cargo_demand": 79240
        }
    },
    {
        "iata": "GVP",
        "icao": "YGNV",
        "airportName": "Greenvale Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Greenvale",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.9966",
        "longitude": "145.0136",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3018",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3032,
            "s_seats": 2393,
            "j_seats": 1823,
            "f_seats": 275,
            "r_seats": 76,
            "cargo_demand": 71919
        }
    },
    {
        "iata": "GVR",
        "icao": "SBGV",
        "airportName": "Coronel Altino Machado de Oliveira Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Governador Valadares",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-18.89520073",
        "longitude": "-41.98220062",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1189,
            "s_seats": 2308,
            "j_seats": 1542,
            "f_seats": 174,
            "r_seats": 56,
            "cargo_demand": 75216
        }
    },
    {
        "iata": "GVT",
        "icao": "KGVT",
        "airportName": "Majors Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Greenville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.06779861",
        "longitude": "-96.06529999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "8030",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1084,
            "s_seats": 1765,
            "j_seats": 1841,
            "f_seats": 426,
            "r_seats": 136,
            "cargo_demand": 91336
        }
    },
    {
        "iata": "GVX",
        "icao": "ESSK",
        "airportName": "GAvle-Sandviken Airport",
        "location_country": "Sweden",
        "location_city": "GAvle",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "60.59329987",
        "longitude": "16.95140076",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130079,
            "s_seats": 30288,
            "j_seats": 34620,
            "f_seats": 1139,
            "r_seats": 5738,
            "cargo_demand": 298208
        }
    },
    {
        "iata": "GWD",
        "icao": "OPGD",
        "airportName": "Gwadar International Airport",
        "location_country": "Pakistan",
        "location_city": "Gwadar",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.232391",
        "longitude": "62.327671",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4960",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183398,
            "s_seats": 42890,
            "j_seats": 28370,
            "f_seats": 9805,
            "r_seats": 3948,
            "cargo_demand": 121500
        }
    },
    {
        "iata": "GWE",
        "icao": "FVTL",
        "airportName": "Thornhill Air Base",
        "location_country": "Zimbabwe",
        "location_city": "Gweru",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.43639946",
        "longitude": "29.86190033",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "8766",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "7826",
                "runway_width": "93",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160608,
            "s_seats": 36761,
            "j_seats": 41611,
            "f_seats": 1308,
            "r_seats": 9490,
            "cargo_demand": 374728
        }
    },
    {
        "iata": "GWL",
        "icao": "VIGR",
        "airportName": "Rajmata Vijaya Raje Scindia Airport",
        "location_country": "India",
        "location_city": "Madhya Pradesh",
        "location_state": "Gwalior",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.29330063",
        "longitude": "78.22779846",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 172887,
            "s_seats": 43082,
            "j_seats": 10754,
            "f_seats": 9156,
            "r_seats": 6070,
            "cargo_demand": 142078
        }
    },
    {
        "iata": "GWO",
        "icao": "KGWO",
        "airportName": "Greenwood-Leflore Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Greenwood",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.49430084",
        "longitude": "-90.08470154",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5005",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2689",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6503",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69029,
            "s_seats": 33196,
            "j_seats": 20006,
            "f_seats": 3965,
            "r_seats": 5065,
            "cargo_demand": 395930
        }
    },
    {
        "iata": "GWS",
        "icao": "KGWS",
        "airportName": "Glenwood Springs Municipal Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Glenwood Springs",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.50830078",
        "longitude": "-107.310997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3305",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1653,
            "s_seats": 2162,
            "j_seats": 1904,
            "f_seats": 447,
            "r_seats": 191,
            "cargo_demand": 59464
        }
    },
    {
        "iata": "GWT",
        "icao": "EDXW",
        "airportName": "Sylt Airport (Westerland Airport)",
        "location_country": "Germany",
        "location_city": "Schleswig-Holstein",
        "location_state": "Sylt",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "54.91320038",
        "longitude": "8.340470314",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5564",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6955",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 89202,
            "s_seats": 45397,
            "j_seats": 54654,
            "f_seats": 4538,
            "r_seats": 8338,
            "cargo_demand": 478626
        }
    },
    {
        "iata": "GWY",
        "icao": "EICM",
        "airportName": "Galway Airport",
        "location_country": "Ireland",
        "location_city": "Galway",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "53.30020142",
        "longitude": "-8.941590309",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4003",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184142,
            "s_seats": 44254,
            "j_seats": 43336,
            "f_seats": 4713,
            "r_seats": 9472,
            "cargo_demand": 276836
        }
    },
    {
        "iata": "GXA",
        "icao": "WAOM",
        "airportName": "Beringin Airport",
        "location_country": "Indonesia",
        "location_city": "Central Kalimantan",
        "location_state": "North Barito",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.940325022",
        "longitude": "114.8938751",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2953",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2298,
            "s_seats": 2221,
            "j_seats": 1882,
            "f_seats": 321,
            "r_seats": 2,
            "cargo_demand": 11443
        }
    },
    {
        "iata": "GXF",
        "icao": "OYSY",
        "airportName": "Sayun Airport",
        "location_country": "Yemen",
        "location_city": "Sayun",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.9661",
        "longitude": "48.7883",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9843",
                "runway_width": "120",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 65021,
            "s_seats": 44018,
            "j_seats": 16350,
            "f_seats": 14397,
            "r_seats": 3718,
            "cargo_demand": 114173
        }
    },
    {
        "iata": "GXG",
        "icao": "FNNG",
        "airportName": "Negage Airport",
        "location_country": "Angola",
        "location_city": "Negage",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.754509926",
        "longitude": "15.2876997",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7874",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100520,
            "s_seats": 34031,
            "j_seats": 31477,
            "f_seats": 10067,
            "r_seats": 9737,
            "cargo_demand": 211696
        }
    },
    {
        "iata": "GXH",
        "icao": "ZLXH",
        "airportName": "Gannan Xiahe Airport",
        "location_country": "China",
        "location_city": "Gansu",
        "location_state": "Xiahe",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.8105",
        "longitude": "102.6447",
        "region": "AS",
        "runway": [
            {
                "runway_name": "unknown/unknown",
                "runway_length": "10499",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 132206,
            "s_seats": 35704,
            "j_seats": 11074,
            "f_seats": 12188,
            "r_seats": 7706,
            "cargo_demand": 273021
        }
    },
    {
        "iata": "GXQ",
        "icao": "SCCY",
        "airportName": "Teniente Vidal Airfield",
        "location_country": "Chile",
        "location_city": "Coyhaique",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-45.59420013",
        "longitude": "-72.10610199",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5072",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180180,
            "s_seats": 29160,
            "j_seats": 56502,
            "f_seats": 15986,
            "r_seats": 8494,
            "cargo_demand": 448226
        }
    },
    {
        "iata": "GXY",
        "icao": "KGXY",
        "airportName": "Greeley-Weld County Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Greeley",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.43740082",
        "longitude": "-104.6330032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5801",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "10000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3599",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1463,
            "s_seats": 2342,
            "j_seats": 1932,
            "f_seats": 459,
            "r_seats": 113,
            "cargo_demand": 77936
        }
    },
    {
        "iata": "GYA",
        "icao": "SLGY",
        "airportName": "Guayaramerin Airport",
        "location_country": "Bolivia",
        "location_city": "Guayaramerin",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.82059956",
        "longitude": "-65.34559631",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5905",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3217,
            "s_seats": 1540,
            "j_seats": 1197,
            "f_seats": 147,
            "r_seats": 49,
            "cargo_demand": 43020
        }
    },
    {
        "iata": "GYD",
        "icao": "UBBB",
        "airportName": "Heydar Aliyev International Airport",
        "location_country": "Azerbaijan",
        "location_city": "Baku",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.46749878",
        "longitude": "50.04669952",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 239484,
            "s_seats": 72795,
            "j_seats": 104075,
            "f_seats": 27293,
            "r_seats": 10000,
            "cargo_demand": 22926029
        }
    },
    {
        "iata": "GYE",
        "icao": "SEGU",
        "airportName": "Jose Joaquin de Olmedo International Airport",
        "location_country": "Ecuador",
        "location_city": "Guayaquil",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-2.15742",
        "longitude": "-79.883598",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9154",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 267193,
            "s_seats": 95641,
            "j_seats": 121869,
            "f_seats": 57783,
            "r_seats": 10000,
            "cargo_demand": 2974933
        }
    },
    {
        "iata": "GYI",
        "icao": "HRYG",
        "airportName": "Gisenyi Airport",
        "location_country": "Rwanda",
        "location_city": "Gisenyi",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.67719996",
        "longitude": "29.25889969",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3330",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149715,
            "s_seats": 26957,
            "j_seats": 25874,
            "f_seats": 13827,
            "r_seats": 7686,
            "cargo_demand": 148595
        }
    },
    {
        "iata": "GYL",
        "icao": "YARG",
        "airportName": "Argyle Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Argyle",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.6369",
        "longitude": "128.451004",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7546",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2197,
            "s_seats": 2572,
            "j_seats": 1332,
            "f_seats": 137,
            "r_seats": 109,
            "cargo_demand": 79700
        }
    },
    {
        "iata": "GYM",
        "icao": "MMGM",
        "airportName": "General Jose Maria Yanez International Airport",
        "location_country": "Mexico",
        "location_city": "Sonora",
        "location_state": "Guaymas",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.96899986",
        "longitude": "-110.9250031",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7710",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104969,
            "s_seats": 34397,
            "j_seats": 43948,
            "f_seats": 14510,
            "r_seats": 8942,
            "cargo_demand": 101123
        }
    },
    {
        "iata": "GYN",
        "icao": "SBGO",
        "airportName": "Santa Genoveva Airport",
        "location_country": "Brazil",
        "location_city": "Goias",
        "location_state": "Goi\u00e2nia",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-16.63199997",
        "longitude": "-49.22069931",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79358,
            "s_seats": 49839,
            "j_seats": 49815,
            "f_seats": 1701,
            "r_seats": 9265,
            "cargo_demand": 259599
        }
    },
    {
        "iata": "GYP",
        "icao": "YGYM",
        "airportName": "Gympie Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Gympie",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.28280067",
        "longitude": "152.7019958",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1955",
                "runway_width": "98",
                "runway_material": "CLA"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4600",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2788,
            "s_seats": 2795,
            "j_seats": 1123,
            "f_seats": 197,
            "r_seats": 89,
            "cargo_demand": 55707
        }
    },
    {
        "iata": "GYR",
        "icao": "KGYR",
        "airportName": "Phoenix Goodyear Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Goodyear",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.42250061",
        "longitude": "-112.3759995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3135,
            "s_seats": 2232,
            "j_seats": 1078,
            "f_seats": 154,
            "r_seats": 100,
            "cargo_demand": 33045
        }
    },
    {
        "iata": "GYU",
        "icao": "ZLGY",
        "airportName": "Guyuan Liupanshan Airport",
        "location_country": "China",
        "location_city": "Ningxia",
        "location_state": "Guyuan",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.07888889",
        "longitude": "106.2169444",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 76017,
            "s_seats": 40694,
            "j_seats": 38149,
            "f_seats": 13101,
            "r_seats": 7098,
            "cargo_demand": 458263
        }
    },
    {
        "iata": "GZO",
        "icao": "AGGN",
        "airportName": "Nusatupe Airport",
        "location_country": "Solomon Islands",
        "location_city": "Western Province",
        "location_state": "Gizo",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.097780228",
        "longitude": "156.8639984",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3609",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3362,
            "s_seats": 1539,
            "j_seats": 1149,
            "f_seats": 389,
            "r_seats": 133,
            "cargo_demand": 45259
        }
    },
    {
        "iata": "GZT",
        "icao": "LTAJ",
        "airportName": "Gaziantep Oguzeli International Airport",
        "location_country": "Turkey",
        "location_city": "Gaziantep",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.94720078",
        "longitude": "37.47869873",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "9843",
                "runway_width": "157",
                "runway_material": "CON"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "9842",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 376083,
            "s_seats": 92404,
            "j_seats": 63281,
            "f_seats": 68626,
            "r_seats": 10000,
            "cargo_demand": 2725447
        }
    },
    {
        "iata": "GZW",
        "icao": "OIIK",
        "airportName": "Qazvin Airport",
        "location_country": "Iran",
        "location_city": "Qazvin",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "36.240101",
        "longitude": "50.0471",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3670",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183840,
            "s_seats": 39203,
            "j_seats": 59993,
            "f_seats": 7756,
            "r_seats": 5047,
            "cargo_demand": 96784
        }
    },
    {
        "iata": "HAA",
        "icao": "ENHK",
        "airportName": "Hasvik Airport",
        "location_country": "Norway",
        "location_city": "Hasvik",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "70.48670197",
        "longitude": "22.13969994",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3179",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2804,
            "s_seats": 2815,
            "j_seats": 1525,
            "f_seats": 479,
            "r_seats": 152,
            "cargo_demand": 50271
        }
    },
    {
        "iata": "HAB",
        "icao": "KHAB",
        "airportName": "Marion County - Rankin Fite Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Hamilton",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.11759949",
        "longitude": "-87.99819946",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3417,
            "s_seats": 2280,
            "j_seats": 1792,
            "f_seats": 290,
            "r_seats": 74,
            "cargo_demand": 33887
        }
    },
    {
        "iata": "HAC",
        "icao": "RJTH",
        "airportName": "Hachijojima Airport",
        "location_country": "Japan",
        "location_city": "Izu Islands",
        "location_state": "Hachijo\u008d-jima (Hachijojima)",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.11500168",
        "longitude": "139.7859955",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6563",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57625,
            "s_seats": 38384,
            "j_seats": 10293,
            "f_seats": 5377,
            "r_seats": 4425,
            "cargo_demand": 97381
        }
    },
    {
        "iata": "HAD",
        "icao": "ESMT",
        "airportName": "Halmstad Airport",
        "location_country": "Sweden",
        "location_city": "Halmstad",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "56.69110107",
        "longitude": "12.82019997",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7419",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139551,
            "s_seats": 44659,
            "j_seats": 38924,
            "f_seats": 13163,
            "r_seats": 3953,
            "cargo_demand": 163515
        }
    },
    {
        "iata": "HAF",
        "icao": "KHAF",
        "airportName": "Half Moon Bay Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Half Moon Bay",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.51340103",
        "longitude": "-122.5009995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2133,
            "s_seats": 2840,
            "j_seats": 1610,
            "f_seats": 467,
            "r_seats": 10,
            "cargo_demand": 98846
        }
    },
    {
        "iata": "HAH",
        "icao": "FMCH",
        "airportName": "Prince Said Ibrahim International Airport",
        "location_country": "Comoros",
        "location_city": "Moroni",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.5337",
        "longitude": "43.2719",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9514",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121950,
            "s_seats": 33446,
            "j_seats": 31974,
            "f_seats": 18510,
            "r_seats": 7884,
            "cargo_demand": 400850
        }
    },
    {
        "iata": "HAI",
        "icao": "KHAI",
        "airportName": "Three Rivers Municipal Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Three Rivers",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.95980072",
        "longitude": "-85.59339905",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2729",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3541,
            "s_seats": 2983,
            "j_seats": 1944,
            "f_seats": 35,
            "r_seats": 25,
            "cargo_demand": 70977
        }
    },
    {
        "iata": "HAJ",
        "icao": "EDDV",
        "airportName": "Hannover Airport",
        "location_country": "Germany",
        "location_city": "Lower Saxony",
        "location_state": "Hanover",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "52.46110153",
        "longitude": "9.685079575",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09C/27C",
                "runway_length": "2559",
                "runway_width": "74",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "12467",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "7677",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 496545,
            "s_seats": 82251,
            "j_seats": 117334,
            "f_seats": 26706,
            "r_seats": 10000,
            "cargo_demand": 23058315
        }
    },
    {
        "iata": "HAK",
        "icao": "ZJHK",
        "airportName": "Haikou Meilan International Airport",
        "location_country": "China",
        "location_city": "Hainan",
        "location_state": "Haikou",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "19.9349",
        "longitude": "110.459",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 211956,
            "s_seats": 79846,
            "j_seats": 157378,
            "f_seats": 33583,
            "r_seats": 10000,
            "cargo_demand": 20151899
        }
    },
    {
        "iata": "HAM",
        "icao": "EDDH",
        "airportName": "Hamburg Airport",
        "location_country": "Germany",
        "location_city": "Hamburg",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "53.630402",
        "longitude": "9.98823",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10663",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "12028",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130105,
            "s_seats": 89098,
            "j_seats": 39832,
            "f_seats": 57927,
            "r_seats": 10000,
            "cargo_demand": 7214096
        }
    },
    {
        "iata": "HAN",
        "icao": "VVNB",
        "airportName": "Noi Bai International Airport",
        "location_country": "Vietnam",
        "location_city": "Hanoi",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "21.221201",
        "longitude": "105.806999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11L/29R",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "12466",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 379997,
            "s_seats": 87108,
            "j_seats": 55895,
            "f_seats": 28940,
            "r_seats": 10000,
            "cargo_demand": 3433462
        }
    },
    {
        "iata": "HAO",
        "icao": "KHAO",
        "airportName": "Butler County Regional Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Hamilton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.36380005",
        "longitude": "-84.52200317",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1775,
            "s_seats": 2843,
            "j_seats": 1719,
            "f_seats": 18,
            "r_seats": 63,
            "cargo_demand": 76968
        }
    },
    {
        "iata": "HAQ",
        "icao": "VRMH",
        "airportName": "Hanimaadhoo International Airport",
        "location_country": "Maldives",
        "location_city": "Haa Dhaalu Atoll",
        "location_state": "Hanimaadhoo",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.744229794",
        "longitude": "73.17050171",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4003",
                "runway_width": "98",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 52038,
            "s_seats": 42172,
            "j_seats": 36493,
            "f_seats": 3532,
            "r_seats": 4581,
            "cargo_demand": 186185
        }
    },
    {
        "iata": "HAR",
        "icao": "KCXY",
        "airportName": "Capital City Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Harrisburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.21709824",
        "longitude": "-76.85150146",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3925",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1796,
            "s_seats": 2410,
            "j_seats": 1294,
            "f_seats": 401,
            "r_seats": 24,
            "cargo_demand": 46147
        }
    },
    {
        "iata": "HAS",
        "icao": "OEHL",
        "airportName": "Ha'il Regional Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Ha'il (Hail)",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.437901",
        "longitude": "41.686298",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "12204",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190863,
            "s_seats": 37112,
            "j_seats": 29509,
            "f_seats": 16418,
            "r_seats": 3182,
            "cargo_demand": 308668
        }
    },
    {
        "iata": "HAT",
        "icao": "YHTL",
        "airportName": "Heathlands Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Heathlands",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.73690033",
        "longitude": "142.5769959",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1921,
            "s_seats": 2123,
            "j_seats": 1756,
            "f_seats": 470,
            "r_seats": 20,
            "cargo_demand": 29499
        }
    },
    {
        "iata": "HAU",
        "icao": "ENHD",
        "airportName": "Haugesund Airport",
        "location_country": "Norway",
        "location_city": "Haugesund",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "59.345299",
        "longitude": "5.20836",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6957",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172754,
            "s_seats": 48971,
            "j_seats": 18386,
            "f_seats": 18632,
            "r_seats": 9436,
            "cargo_demand": 246459
        }
    },
    {
        "iata": "HAV",
        "icao": "MUHA",
        "airportName": "Jose Marti International Airport",
        "location_country": "Cuba",
        "location_city": "Havana",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "22.98920059",
        "longitude": "-82.40910339",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 430717,
            "s_seats": 77059,
            "j_seats": 77159,
            "f_seats": 56626,
            "r_seats": 10000,
            "cargo_demand": 14193394
        }
    },
    {
        "iata": "HAW",
        "icao": "EGFE",
        "airportName": "Haverfordwest Aerodrome",
        "location_country": "United Kingdom",
        "location_city": "Wales",
        "location_state": "Haverfordwest",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.83309937",
        "longitude": "-4.961110115",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5000",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3412",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61699,
            "s_seats": 40045,
            "j_seats": 5354,
            "f_seats": 5966,
            "r_seats": 4316,
            "cargo_demand": 436488
        }
    },
    {
        "iata": "HBA",
        "icao": "YMHB",
        "airportName": "Hobart International Airport",
        "location_country": "Australia",
        "location_city": "Tasmania",
        "location_state": "Hobart",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-42.83610153",
        "longitude": "147.5099945",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7385",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56442,
            "s_seats": 45083,
            "j_seats": 38283,
            "f_seats": 13407,
            "r_seats": 4623,
            "cargo_demand": 147538
        }
    },
    {
        "iata": "HBE",
        "icao": "HEBA",
        "airportName": "Borg El Arab Airport",
        "location_country": "Egypt",
        "location_city": "Alexandria / Borg El Arab",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.91769981",
        "longitude": "29.69639969",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "11156",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "1347",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14S/32S",
                "runway_length": "1347",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142625,
            "s_seats": 30742,
            "j_seats": 31891,
            "f_seats": 2234,
            "r_seats": 3636,
            "cargo_demand": 211616
        }
    },
    {
        "iata": "HBG",
        "icao": "KHBG",
        "airportName": "Hattiesburg Bobby L. Chain Municipal Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Hattiesburg",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.26479912",
        "longitude": "-89.25279999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6099",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106657,
            "s_seats": 28196,
            "j_seats": 24483,
            "f_seats": 17176,
            "r_seats": 5896,
            "cargo_demand": 261966
        }
    },
    {
        "iata": "HBR",
        "icao": "KHBR",
        "airportName": "Hobart Regional Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Hobart",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.991317",
        "longitude": "-99.051313",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5293",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5293",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5507",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174126,
            "s_seats": 33856,
            "j_seats": 53997,
            "f_seats": 9917,
            "r_seats": 8935,
            "cargo_demand": 192854
        }
    },
    {
        "iata": "HBU",
        "icao": "ZMBS",
        "airportName": "Bulgan Airport/c/ Khovd",
        "location_country": "Mongolia",
        "location_city": "Bulgan (Khovd Province)",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "46.100601",
        "longitude": "91.584198",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5906",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2306,
            "s_seats": 1959,
            "j_seats": 1227,
            "f_seats": 335,
            "r_seats": 154,
            "cargo_demand": 84709
        }
    },
    {
        "iata": "HBX",
        "icao": "VAHB",
        "airportName": "Hubli Airport",
        "location_country": "India",
        "location_city": "Karnataka",
        "location_state": "Hubli / Dharwad",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.36170006",
        "longitude": "75.0848999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5479",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143397,
            "s_seats": 45582,
            "j_seats": 50928,
            "f_seats": 17585,
            "r_seats": 8934,
            "cargo_demand": 355261
        }
    },
    {
        "iata": "HCA",
        "icao": "KBPG",
        "airportName": "Big Spring McMahon-Wrinkle Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Big Spring",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.212601",
        "longitude": "-101.522003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4601",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "8802",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 3396,
            "s_seats": 2734,
            "j_seats": 1441,
            "f_seats": 192,
            "r_seats": 170,
            "cargo_demand": 96268
        }
    },
    {
        "iata": "HCJ",
        "icao": "ZGHC",
        "airportName": "Hechi Jinchengjiang Airport",
        "location_country": "China",
        "location_city": "Guangxi",
        "location_state": "Hechi",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.804344",
        "longitude": "107.710819",
        "region": "AS",
        "runway": [
            {
                "runway_name": "unknown/unknown",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 137015,
            "s_seats": 36311,
            "j_seats": 58166,
            "f_seats": 8612,
            "r_seats": 9198,
            "cargo_demand": 181126
        }
    },
    {
        "iata": "HCM",
        "icao": "HCME",
        "airportName": "Eyl Airport",
        "location_country": "Somalia",
        "location_city": "Eyl",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "8.104",
        "longitude": "49.82",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3445",
                "runway_width": "164",
                "runway_material": "Graded earth"
            }
        ],
        "demand": {
            "y_seats": 1446,
            "s_seats": 2739,
            "j_seats": 1268,
            "f_seats": 446,
            "r_seats": 126,
            "cargo_demand": 74266
        }
    },
    {
        "iata": "HCN",
        "icao": "RCKW",
        "airportName": "Hengchun Airport",
        "location_country": "Taiwan",
        "location_city": "Hengchun",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.04109955",
        "longitude": "120.7300034",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5577",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 190648,
            "s_seats": 30557,
            "j_seats": 15530,
            "f_seats": 1445,
            "r_seats": 5282,
            "cargo_demand": 61734
        }
    },
    {
        "iata": "HCQ",
        "icao": "YHLC",
        "airportName": "Halls Creek Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Halls Creek",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-18.23390007",
        "longitude": "127.6699982",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4839",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3104",
                "runway_width": "59",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 71678,
            "s_seats": 44644,
            "j_seats": 6806,
            "f_seats": 6197,
            "r_seats": 6782,
            "cargo_demand": 386515
        }
    },
    {
        "iata": "HCR",
        "icao": "PAHC",
        "airportName": "Holy Cross Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Holy Cross",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "62.18830109",
        "longitude": "-159.7749939",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 75325,
            "s_seats": 33442,
            "j_seats": 38815,
            "f_seats": 11488,
            "r_seats": 6033,
            "cargo_demand": 259777
        }
    },
    {
        "iata": "HCW",
        "icao": "KCQW",
        "airportName": "Cheraw Municipal Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Cheraw",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.71289825",
        "longitude": "-79.95700073",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2861,
            "s_seats": 1683,
            "j_seats": 1775,
            "f_seats": 315,
            "r_seats": 83,
            "cargo_demand": 30729
        }
    },
    {
        "iata": "HDE",
        "icao": "KHDE",
        "airportName": "Brewster Field",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Holdrege",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.452099",
        "longitude": "-99.336502",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "1769",
                "runway_width": "300",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4701",
                "runway_width": "75",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 1225,
            "s_seats": 1796,
            "j_seats": 1459,
            "f_seats": 288,
            "r_seats": 123,
            "cargo_demand": 68282
        }
    },
    {
        "iata": "HDF",
        "icao": "EDAH",
        "airportName": "Heringsdorf Airport",
        "location_country": "Germany",
        "location_city": "Mecklenburg-Vorpommern",
        "location_state": "Heringsdorf",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "53.87870026",
        "longitude": "14.15229988",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7562",
                "runway_width": "115",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "1969",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 163619,
            "s_seats": 36299,
            "j_seats": 21417,
            "f_seats": 12050,
            "r_seats": 3703,
            "cargo_demand": 46133
        }
    },
    {
        "iata": "HDG",
        "icao": "ZBHD",
        "airportName": "Handan Airport",
        "location_country": "China",
        "location_city": "Hebei",
        "location_state": "Handan",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.52583333",
        "longitude": "114.4255556",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 65620,
            "s_seats": 44444,
            "j_seats": 32961,
            "f_seats": 13673,
            "r_seats": 4465,
            "cargo_demand": 364262
        }
    },
    {
        "iata": "HDH",
        "icao": "PHDH",
        "airportName": "Dillingham Airfield",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "MokulA\u201cia",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.5795002",
        "longitude": "-158.1970062",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9007",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192161,
            "s_seats": 43051,
            "j_seats": 54720,
            "f_seats": 12601,
            "r_seats": 4801,
            "cargo_demand": 295439
        }
    },
    {
        "iata": "HDM",
        "icao": "OIHH",
        "airportName": "Hamadan Airport",
        "location_country": "Iran",
        "location_city": "Hamadan",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "34.869202",
        "longitude": "48.552502",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10611",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190258,
            "s_seats": 37369,
            "j_seats": 6549,
            "f_seats": 16862,
            "r_seats": 3445,
            "cargo_demand": 117088
        }
    },
    {
        "iata": "HDN",
        "icao": "KHDN",
        "airportName": "Yampa Valley Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Hayden",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.48120117",
        "longitude": "-107.2180023",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3840,
            "s_seats": 1926,
            "j_seats": 1638,
            "f_seats": 353,
            "r_seats": 184,
            "cargo_demand": 74429
        }
    },
    {
        "iata": "HDR",
        "icao": "OIKP",
        "airportName": "Havadarya Airport",
        "location_country": "Iran",
        "location_city": "Bandar Abbas",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "small_airport",
        "latitude": "27.1583004",
        "longitude": "56.17250061",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8525",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2733,
            "s_seats": 1637,
            "j_seats": 1250,
            "f_seats": 263,
            "r_seats": 21,
            "cargo_demand": 45139
        }
    },
    {
        "iata": "HDS",
        "icao": "FAHS",
        "airportName": "Air Force Base Hoedspruit",
        "location_country": "South Africa",
        "location_city": "Hoedspruit",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.36860085",
        "longitude": "31.04870033",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6939",
                "runway_width": "88",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "13095",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2540,
            "s_seats": 1611,
            "j_seats": 1105,
            "f_seats": 449,
            "r_seats": 21,
            "cargo_demand": 82436
        }
    },
    {
        "iata": "HDY",
        "icao": "VTSS",
        "airportName": "Hat Yai International Airport",
        "location_country": "Thailand",
        "location_city": "Hat Yai",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.933209896",
        "longitude": "100.3929977",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167003,
            "s_seats": 27334,
            "j_seats": 35075,
            "f_seats": 2624,
            "r_seats": 8309,
            "cargo_demand": 354532
        }
    },
    {
        "iata": "HEA",
        "icao": "OAHR",
        "airportName": "Herat International Airport",
        "location_country": "Afghanistan",
        "location_city": "Herat",
        "location_state": "",
        "time_zone": "UTC+04:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.209999",
        "longitude": "62.228298",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9888",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 96012,
            "s_seats": 32480,
            "j_seats": 58445,
            "f_seats": 8406,
            "r_seats": 3875,
            "cargo_demand": 217766
        }
    },
    {
        "iata": "HEE",
        "icao": "KHEE",
        "airportName": "Thompson-Robbins Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Helena-West Helena",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.576571",
        "longitude": "-90.67616",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3009",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "96",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1694,
            "s_seats": 1537,
            "j_seats": 1321,
            "f_seats": 188,
            "r_seats": 172,
            "cargo_demand": 17683
        }
    },
    {
        "iata": "HEH",
        "icao": "VYHH",
        "airportName": "Heho Airport",
        "location_country": "Myanmar",
        "location_city": "Heho",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.74699974",
        "longitude": "96.79199982",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179443,
            "s_seats": 28133,
            "j_seats": 36536,
            "f_seats": 9347,
            "r_seats": 6836,
            "cargo_demand": 389599
        }
    },
    {
        "iata": "HEI",
        "icao": "EDXB",
        "airportName": "Heide-Busum Airport",
        "location_country": "Germany",
        "location_city": "Schleswig-Holstein",
        "location_state": "Heide / Busum",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "54.15333176",
        "longitude": "8.901666641",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2362",
                "runway_width": "76",
                "runway_material": "ASPHALT"
            }
        ],
        "demand": {
            "y_seats": 2465,
            "s_seats": 2601,
            "j_seats": 1389,
            "f_seats": 393,
            "r_seats": 51,
            "cargo_demand": 48396
        }
    },
    {
        "iata": "HEK",
        "icao": "ZYHE",
        "airportName": "Heihe Airport",
        "location_country": "China",
        "location_city": "Heilongjiang",
        "location_state": "Heihe",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.171621",
        "longitude": "127.308884",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92351,
            "s_seats": 25477,
            "j_seats": 12402,
            "f_seats": 2568,
            "r_seats": 6677,
            "cargo_demand": 304599
        }
    },
    {
        "iata": "HEL",
        "icao": "EFHK",
        "airportName": "Helsinki Airport",
        "location_country": "Finland",
        "location_city": "Helsinki",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "60.3172",
        "longitude": "24.963301",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "10039",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "11286",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "9518",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174315,
            "s_seats": 82867,
            "j_seats": 54278,
            "f_seats": 41685,
            "r_seats": 10000,
            "cargo_demand": 23043412
        }
    },
    {
        "iata": "HEM",
        "icao": "EFHF",
        "airportName": "Helsinki-Malmi Airport",
        "location_country": "Finland",
        "location_city": "Helsinki",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "60.254601",
        "longitude": "25.042801",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3360",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4396",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153417,
            "s_seats": 48029,
            "j_seats": 18125,
            "f_seats": 2422,
            "r_seats": 5475,
            "cargo_demand": 466994
        }
    },
    {
        "iata": "HER",
        "icao": "LGIR",
        "airportName": "Heraklion International Airport",
        "location_country": "Greece",
        "location_city": "Heraklion",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "35.33969879",
        "longitude": "25.18029976",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8800",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5138",
                "runway_width": "164",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2559",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 488605,
            "s_seats": 88342,
            "j_seats": 42628,
            "f_seats": 39244,
            "r_seats": 10000,
            "cargo_demand": 23499374
        }
    },
    {
        "iata": "HES",
        "icao": "KHRI",
        "airportName": "Hermiston Municipal Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Hermiston",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.828223",
        "longitude": "-119.259024",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4500",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2093,
            "s_seats": 2367,
            "j_seats": 1710,
            "f_seats": 448,
            "r_seats": 187,
            "cargo_demand": 17371
        }
    },
    {
        "iata": "HET",
        "icao": "ZBHH",
        "airportName": "Hohhot Baita International Airport",
        "location_country": "China",
        "location_city": "Inner Mongolia",
        "location_state": "Hohhot",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.851398",
        "longitude": "111.823997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 333417,
            "s_seats": 99095,
            "j_seats": 75659,
            "f_seats": 40408,
            "r_seats": 10000,
            "cargo_demand": 12661841
        }
    },
    {
        "iata": "HEZ",
        "icao": "KHEZ",
        "airportName": "Natchez-Adams County Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Natchez",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.613738",
        "longitude": "-91.297313",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1185,
            "s_seats": 2199,
            "j_seats": 1077,
            "f_seats": 272,
            "r_seats": 143,
            "cargo_demand": 4867
        }
    },
    {
        "iata": "HFA",
        "icao": "LLHA",
        "airportName": "Haifa Airport",
        "location_country": "Israel",
        "location_city": "Haifa",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "32.80939865",
        "longitude": "35.04309845",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4324",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140187,
            "s_seats": 29442,
            "j_seats": 56765,
            "f_seats": 9520,
            "r_seats": 7366,
            "cargo_demand": 118769
        }
    },
    {
        "iata": "HFD",
        "icao": "KHFD",
        "airportName": "Hartford-Brainard Airport",
        "location_country": "United States",
        "location_city": "Connecticut",
        "location_state": "Hartford",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.73669815",
        "longitude": "-72.6493988",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4417",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2314",
                "runway_width": "71",
                "runway_material": "ASP"
            },
            {
                "runway_name": "NE/SW",
                "runway_length": "2309",
                "runway_width": "150",
                "runway_material": "TURF"
            }
        ],
        "demand": {
            "y_seats": 64857,
            "s_seats": 47971,
            "j_seats": 29734,
            "f_seats": 17023,
            "r_seats": 5871,
            "cargo_demand": 119901
        }
    },
    {
        "iata": "HFE",
        "icao": "ZSOF",
        "airportName": "Hefei Xinqiao International Airport",
        "location_country": "China",
        "location_city": "Anhui",
        "location_state": "Hefei",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.78000069",
        "longitude": "117.2979965",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9843",
                "runway_width": "164",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 149110,
            "s_seats": 37030,
            "j_seats": 36181,
            "f_seats": 11589,
            "r_seats": 7168,
            "cargo_demand": 471937
        }
    },
    {
        "iata": "HFF",
        "icao": "KHFF",
        "airportName": "Mackall Army Airfield",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Hoffman",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.036288",
        "longitude": "-79.497755",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4740",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4916",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3277,
            "s_seats": 1592,
            "j_seats": 1108,
            "f_seats": 242,
            "r_seats": 36,
            "cargo_demand": 84888
        }
    },
    {
        "iata": "HFN",
        "icao": "BIHN",
        "airportName": "Hornafjor\u00f0ur Airport",
        "location_country": "Iceland",
        "location_city": "Hofn",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.295601",
        "longitude": "-15.2272",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4921",
                "runway_width": "148",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 95876,
            "s_seats": 34553,
            "j_seats": 28235,
            "f_seats": 3765,
            "r_seats": 8627,
            "cargo_demand": 270552
        }
    },
    {
        "iata": "HFS",
        "icao": "ESOH",
        "airportName": "Hagfors Airport",
        "location_country": "Sweden",
        "location_city": "Hagfors",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "60.0201",
        "longitude": "13.5789",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4951",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 126951,
            "s_seats": 26127,
            "j_seats": 42535,
            "f_seats": 9443,
            "r_seats": 6756,
            "cargo_demand": 428631
        }
    },
    {
        "iata": "HFT",
        "icao": "ENHF",
        "airportName": "Hammerfest Airport",
        "location_country": "Norway",
        "location_city": "Hammerfest",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "70.67970276",
        "longitude": "23.66860008",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3061",
                "runway_width": "98",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 102725,
            "s_seats": 45336,
            "j_seats": 22466,
            "f_seats": 4816,
            "r_seats": 4212,
            "cargo_demand": 308205
        }
    },
    {
        "iata": "HGA",
        "icao": "HCMH",
        "airportName": "Hargeisa International Airport",
        "location_country": "Somalia",
        "location_city": "Hargeisa",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "9.513207",
        "longitude": "44.082389",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "12139",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147626,
            "s_seats": 93078,
            "j_seats": 21031,
            "f_seats": 20424,
            "r_seats": 10000,
            "cargo_demand": 4985797
        }
    },
    {
        "iata": "HGD",
        "icao": "YHUG",
        "airportName": "Hughenden Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Hughenden",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.81500053",
        "longitude": "144.2250061",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3051",
                "runway_width": "59",
                "runway_material": "GVL"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5394",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1011,
            "s_seats": 2799,
            "j_seats": 1831,
            "f_seats": 167,
            "r_seats": 66,
            "cargo_demand": 37428
        }
    },
    {
        "iata": "HGE",
        "icao": "SVHG",
        "airportName": "Higuerote Airport",
        "location_country": "Venezuela",
        "location_city": "Higuerote",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "10.462474",
        "longitude": "-66.092779",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1029,
            "s_seats": 2102,
            "j_seats": 1699,
            "f_seats": 136,
            "r_seats": 163,
            "cargo_demand": 82605
        }
    },
    {
        "iata": "HGH",
        "icao": "ZSHC",
        "airportName": "Hangzhou Xiaoshan International Airport",
        "location_country": "China",
        "location_city": "Zhejiang",
        "location_state": "Hangzhou",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "30.22949982",
        "longitude": "120.4339981",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "11155",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "07/25",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 494197,
            "s_seats": 84190,
            "j_seats": 165853,
            "f_seats": 24757,
            "r_seats": 10000,
            "cargo_demand": 20836984
        }
    },
    {
        "iata": "HGI",
        "icao": "HSFA",
        "airportName": "Paloich Airport",
        "location_country": "Sudan",
        "location_city": "Higlieg",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "\" Heliport\"\"\"",
        "longitude": "10.529167",
        "region": "1290",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8202",
                "runway_width": "147",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 155071,
            "s_seats": 49038,
            "j_seats": 7407,
            "f_seats": 3683,
            "r_seats": 4601,
            "cargo_demand": 477974
        }
    },
    {
        "iata": "HGL",
        "icao": "EDXH",
        "airportName": "Heligoland Airport",
        "location_country": "Germany",
        "location_city": "Schleswig-Holstein",
        "location_state": "Heligoland",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "54.18527985",
        "longitude": "7.915832996",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1217",
                "runway_width": "66",
                "runway_material": "concrete"
            },
            {
                "runway_name": "06/24",
                "runway_length": "846",
                "runway_width": "39",
                "runway_material": "concrete"
            },
            {
                "runway_name": "15/33",
                "runway_length": "1574",
                "runway_width": "98",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 1019,
            "s_seats": 1805,
            "j_seats": 1817,
            "f_seats": 378,
            "r_seats": 109,
            "cargo_demand": 28143
        }
    },
    {
        "iata": "HGN",
        "icao": "VTCH",
        "airportName": "Mae Hong Son Airport",
        "location_country": "Thailand",
        "location_city": "Mae Hong Son",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.30130005",
        "longitude": "97.97579956",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109284,
            "s_seats": 48830,
            "j_seats": 18548,
            "f_seats": 2601,
            "r_seats": 4498,
            "cargo_demand": 483425
        }
    },
    {
        "iata": "HGO",
        "icao": "DIKO",
        "airportName": "Korhogo Airport",
        "location_country": "Ivory Coast",
        "location_city": "Korhogo",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.387180328",
        "longitude": "-5.556660175",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6890",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 96257,
            "s_seats": 41567,
            "j_seats": 13223,
            "f_seats": 5468,
            "r_seats": 8537,
            "cargo_demand": 109154
        }
    },
    {
        "iata": "HGR",
        "icao": "KHGR",
        "airportName": "Hagerstown Regional Airport",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Hagerstown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.707901",
        "longitude": "-77.72949982",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3160",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5461",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173822,
            "s_seats": 25239,
            "j_seats": 37378,
            "f_seats": 5237,
            "r_seats": 4741,
            "cargo_demand": 488382
        }
    },
    {
        "iata": "HGU",
        "icao": "AYMH",
        "airportName": "Mount Hagen Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Mount Hagen",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.826789856",
        "longitude": "144.2960052",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3599",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7185",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72040,
            "s_seats": 43290,
            "j_seats": 34468,
            "f_seats": 2292,
            "r_seats": 8624,
            "cargo_demand": 398522
        }
    },
    {
        "iata": "HHE",
        "icao": "RJSH",
        "airportName": "JMSDF Hachinohe Air Base",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Hachinohe",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.551181",
        "longitude": "141.465428",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2548",
                "runway_width": "45",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "7380",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "1180",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82589,
            "s_seats": 36863,
            "j_seats": 5711,
            "f_seats": 18131,
            "r_seats": 6648,
            "cargo_demand": 215879
        }
    },
    {
        "iata": "HHH",
        "icao": "KHXD",
        "airportName": "Hilton Head Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Hilton Head Island",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.22439957",
        "longitude": "-80.69750214",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1092,
            "s_seats": 1995,
            "j_seats": 1999,
            "f_seats": 321,
            "r_seats": 175,
            "cargo_demand": 57240
        }
    },
    {
        "iata": "HHI",
        "icao": "PHHI",
        "airportName": "Wheeler Army Airfield",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Wahiawa",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.481637",
        "longitude": "-158.037048",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5604",
                "runway_width": "295",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56721,
            "s_seats": 25500,
            "j_seats": 8123,
            "f_seats": 5397,
            "r_seats": 9062,
            "cargo_demand": 447940
        }
    },
    {
        "iata": "HHN",
        "icao": "EDFH",
        "airportName": "Frankfurt-Hahn Airport",
        "location_country": "Germany",
        "location_city": "Rhineland-Palatinate",
        "location_state": "Hahn",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.9487",
        "longitude": "7.26389",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "12467",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170733,
            "s_seats": 49153,
            "j_seats": 12564,
            "f_seats": 17907,
            "r_seats": 8566,
            "cargo_demand": 53239
        }
    },
    {
        "iata": "HHQ",
        "icao": "VTPH",
        "airportName": "Hua Hin Airport",
        "location_country": "Thailand",
        "location_city": "Hua Hin",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.63619995",
        "longitude": "99.95149994",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6890",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100675,
            "s_seats": 29099,
            "j_seats": 50020,
            "f_seats": 7761,
            "r_seats": 5010,
            "cargo_demand": 320669
        }
    },
    {
        "iata": "HHR",
        "icao": "KHHR",
        "airportName": "Hawthorne Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Hawthorne",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.922798",
        "longitude": "-118.334999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4956",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 128915,
            "s_seats": 34881,
            "j_seats": 30282,
            "f_seats": 14928,
            "r_seats": 8330,
            "cargo_demand": 450599
        }
    },
    {
        "iata": "HIB",
        "icao": "KHIB",
        "airportName": "Range Regional Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Hibbing",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "47.38660049",
        "longitude": "-92.83899689",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3075",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6758",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141213,
            "s_seats": 95442,
            "j_seats": 34087,
            "f_seats": 24194,
            "r_seats": 10000,
            "cargo_demand": 13113298
        }
    },
    {
        "iata": "HID",
        "icao": "YHID",
        "airportName": "Horn Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Horn Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-10.58640003",
        "longitude": "142.2899933",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4557",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4052",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189447,
            "s_seats": 38064,
            "j_seats": 24969,
            "f_seats": 9122,
            "r_seats": 8167,
            "cargo_demand": 213846
        }
    },
    {
        "iata": "HIE",
        "icao": "KHIE",
        "airportName": "Mount Washington Regional Airport",
        "location_country": "United States",
        "location_city": "New Hampshire",
        "location_state": "Whitefield",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.367637",
        "longitude": "-71.544502",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1633,
            "s_seats": 2892,
            "j_seats": 1899,
            "f_seats": 167,
            "r_seats": 143,
            "cargo_demand": 40691
        }
    },
    {
        "iata": "HIF",
        "icao": "KHIF",
        "airportName": "Hill Air Force Base",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Ogden",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.12403",
        "longitude": "-111.973086",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "13508",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 53519,
            "s_seats": 36227,
            "j_seats": 57581,
            "f_seats": 19097,
            "r_seats": 3664,
            "cargo_demand": 233034
        }
    },
    {
        "iata": "HIG",
        "icao": "YHHY",
        "airportName": "Highbury Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Highbury",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.42440033",
        "longitude": "143.1459961",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2624",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2277,
            "s_seats": 2870,
            "j_seats": 1036,
            "f_seats": 441,
            "r_seats": 30,
            "cargo_demand": 82267
        }
    },
    {
        "iata": "HII",
        "icao": "KHII",
        "airportName": "Lake Havasu City Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Lake Havasu City",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.571098",
        "longitude": "-114.358002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143634,
            "s_seats": 27884,
            "j_seats": 40490,
            "f_seats": 14557,
            "r_seats": 5709,
            "cargo_demand": 119710
        }
    },
    {
        "iata": "HIJ",
        "icao": "RJOA",
        "airportName": "Hiroshima Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Hiroshima",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.4361",
        "longitude": "132.919006",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9842",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52557,
            "s_seats": 31985,
            "j_seats": 37378,
            "f_seats": 2529,
            "r_seats": 4510,
            "cargo_demand": 449997
        }
    },
    {
        "iata": "HIN",
        "icao": "RKPS",
        "airportName": "Sacheon Airport",
        "location_country": "South Korea",
        "location_city": "Jinju",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.088591",
        "longitude": "128.071747",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 74132,
            "s_seats": 41724,
            "j_seats": 53802,
            "f_seats": 13993,
            "r_seats": 7447,
            "cargo_demand": 240737
        }
    },
    {
        "iata": "HIO",
        "icao": "KHIO",
        "airportName": "Hillsboro Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Hillsboro / Portland",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "45.540401",
        "longitude": "-122.949997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4049",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "3600",
                "runway_width": "60",
                "runway_material": "Asphalt"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "6600",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140703,
            "s_seats": 34765,
            "j_seats": 59124,
            "f_seats": 6579,
            "r_seats": 6225,
            "cargo_demand": 238079
        }
    },
    {
        "iata": "HIP",
        "icao": "YHDY",
        "airportName": "Headingly Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Headingly",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-21.33329964",
        "longitude": "138.2830048",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3999",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 2728,
            "s_seats": 2314,
            "j_seats": 1806,
            "f_seats": 147,
            "r_seats": 115,
            "cargo_demand": 34021
        }
    },
    {
        "iata": "HIR",
        "icao": "AGGH",
        "airportName": "Honiara International Airport",
        "location_country": "Solomon Islands",
        "location_city": "Guadalcanal",
        "location_state": "Honiara",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-9.428",
        "longitude": "160.054993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 258263,
            "s_seats": 95206,
            "j_seats": 78376,
            "f_seats": 46659,
            "r_seats": 10000,
            "cargo_demand": 14240068
        }
    },
    {
        "iata": "HIT",
        "icao": "AYHO",
        "airportName": "Haivaro Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Haivaro",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.9406",
        "longitude": "143.059",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "1641",
                "runway_width": "98",
                "runway_material": "Grassed brown clay"
            }
        ],
        "demand": {
            "y_seats": 2022,
            "s_seats": 2911,
            "j_seats": 1561,
            "f_seats": 213,
            "r_seats": 102,
            "cargo_demand": 18113
        }
    },
    {
        "iata": "HJR",
        "icao": "VAKJ",
        "airportName": "Civil Aerodrome Khajuraho",
        "location_country": "India",
        "location_city": "Madhya Pradesh",
        "location_state": "Khajuraho",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.8172",
        "longitude": "79.918602",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7460",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194618,
            "s_seats": 49791,
            "j_seats": 49983,
            "f_seats": 6610,
            "r_seats": 7923,
            "cargo_demand": 495674
        }
    },
    {
        "iata": "HJT",
        "icao": "ZMHU",
        "airportName": "Khujirt Airport",
        "location_country": "Mongolia",
        "location_city": "Khujirt",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "46.92580032",
        "longitude": "102.7730026",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7218",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1403,
            "s_seats": 2296,
            "j_seats": 1937,
            "f_seats": 459,
            "r_seats": 51,
            "cargo_demand": 76623
        }
    },
    {
        "iata": "HKA",
        "icao": "KHKA",
        "airportName": "Blytheville Municipal Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Blytheville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.94039917",
        "longitude": "-89.83080292",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1161,
            "s_seats": 2746,
            "j_seats": 1200,
            "f_seats": 83,
            "r_seats": 78,
            "cargo_demand": 52178
        }
    },
    {
        "iata": "HKD",
        "icao": "RJCH",
        "airportName": "Hakodate Airport",
        "location_country": "Japan",
        "location_city": "Hokkaido",
        "location_state": "Hakodate",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.77000046",
        "longitude": "140.8220062",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9842",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124920,
            "s_seats": 33767,
            "j_seats": 36213,
            "f_seats": 11841,
            "r_seats": 5723,
            "cargo_demand": 385577
        }
    },
    {
        "iata": "HKG",
        "icao": "VHHH",
        "airportName": "Hong Kong International Airport",
        "location_country": "Hong Kong",
        "location_city": "",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "22.308901",
        "longitude": "113.915001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199610,
            "s_seats": 98168,
            "j_seats": 93695,
            "f_seats": 20285,
            "r_seats": 10000,
            "cargo_demand": 2207036
        }
    },
    {
        "iata": "HKK",
        "icao": "NZHK",
        "airportName": "Hokitika Airport",
        "location_country": "New Zealand",
        "location_city": "Hokitika",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-42.71360016",
        "longitude": "170.9850006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4311",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3858",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125857,
            "s_seats": 38293,
            "j_seats": 42254,
            "f_seats": 13921,
            "r_seats": 4980,
            "cargo_demand": 244235
        }
    },
    {
        "iata": "HKN",
        "icao": "AYHK",
        "airportName": "Hoskins Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Kimbe",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.462170124",
        "longitude": "150.4049988",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5212",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152198,
            "s_seats": 34296,
            "j_seats": 6636,
            "f_seats": 18873,
            "r_seats": 6655,
            "cargo_demand": 329773
        }
    },
    {
        "iata": "HKS",
        "icao": "KHKS",
        "airportName": "Hawkins Field",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Jackson",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.33449936",
        "longitude": "-90.22219849",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4822",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5387",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2003,
            "s_seats": 2549,
            "j_seats": 1924,
            "f_seats": 124,
            "r_seats": 154,
            "cargo_demand": 98648
        }
    },
    {
        "iata": "HKT",
        "icao": "VTSP",
        "airportName": "Phuket International Airport",
        "location_country": "Thailand",
        "location_city": "Phuket",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "8.1132",
        "longitude": "98.316902",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 435022,
            "s_seats": 87633,
            "j_seats": 128837,
            "f_seats": 40732,
            "r_seats": 10000,
            "cargo_demand": 6746084
        }
    },
    {
        "iata": "HKY",
        "icao": "KHKY",
        "airportName": "Hickory Regional Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Hickory",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.74110031",
        "longitude": "-81.38950348",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4400",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "6400",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59663,
            "s_seats": 47317,
            "j_seats": 12847,
            "f_seats": 15329,
            "r_seats": 6256,
            "cargo_demand": 251921
        }
    },
    {
        "iata": "HLA",
        "icao": "FALA",
        "airportName": "Lanseria International Airport",
        "location_country": "South Africa",
        "location_city": "Johannesburg",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.93849945",
        "longitude": "27.92609978",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "10000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "9996",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161025,
            "s_seats": 28158,
            "j_seats": 29820,
            "f_seats": 2173,
            "r_seats": 7315,
            "cargo_demand": 482223
        }
    },
    {
        "iata": "HLB",
        "icao": "KHLB",
        "airportName": "Hillenbrand Industries Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Batesville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.3445015",
        "longitude": "-85.25830078",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4948",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2014,
            "s_seats": 1662,
            "j_seats": 1758,
            "f_seats": 28,
            "r_seats": 191,
            "cargo_demand": 92204
        }
    },
    {
        "iata": "HLC",
        "icao": "KHLC",
        "airportName": "Hill City Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Hill City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.37879944",
        "longitude": "-99.83149719",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2851,
            "s_seats": 2719,
            "j_seats": 1440,
            "f_seats": 368,
            "r_seats": 0,
            "cargo_demand": 94602
        }
    },
    {
        "iata": "HLD",
        "icao": "ZBLA",
        "airportName": "Hulunbuir Hailar Airport",
        "location_country": "China",
        "location_city": "Inner Mongolia",
        "location_state": "Hailar",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.205002",
        "longitude": "119.824997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 195648,
            "s_seats": 36770,
            "j_seats": 55404,
            "f_seats": 11551,
            "r_seats": 4480,
            "cargo_demand": 78920
        }
    },
    {
        "iata": "HLE",
        "icao": "FHSH",
        "airportName": "Saint Helena Airport",
        "location_country": "Ascension and Tristan da Cunha",
        "location_city": "British Overseas Territoryof Saint Helena",
        "location_state": "Jamestown",
        "time_zone": "UTC+00:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.957725",
        "longitude": "-5.645943",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6398",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1529,
            "s_seats": 2921,
            "j_seats": 1463,
            "f_seats": 98,
            "r_seats": 165,
            "cargo_demand": 55196
        }
    },
    {
        "iata": "HLF",
        "icao": "ESSF",
        "airportName": "Hultsfred-Vimmerby Airport",
        "location_country": "Sweden",
        "location_city": "Hultsfred / Vimmerby",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "57.525799",
        "longitude": "15.8233",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6383",
                "runway_width": "131",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2325,
            "s_seats": 2382,
            "j_seats": 1062,
            "f_seats": 392,
            "r_seats": 66,
            "cargo_demand": 21216
        }
    },
    {
        "iata": "HLG",
        "icao": "KHLG",
        "airportName": "Wheeling Ohio County Airport",
        "location_country": "United States",
        "location_city": "West Virginia",
        "location_state": "Wheeling",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.17499924",
        "longitude": "-80.64630127",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4499",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92671,
            "s_seats": 40979,
            "j_seats": 43894,
            "f_seats": 1961,
            "r_seats": 9265,
            "cargo_demand": 274224
        }
    },
    {
        "iata": "HLL",
        "icao": "YHIL",
        "airportName": "Hillside Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Hillside",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-21.7244",
        "longitude": "119.3922",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3675,
            "s_seats": 2245,
            "j_seats": 1181,
            "f_seats": 129,
            "r_seats": 80,
            "cargo_demand": 13127
        }
    },
    {
        "iata": "HLM",
        "icao": "KHLM",
        "airportName": "Park Township Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Holland",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.795898",
        "longitude": "-86.162003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2999",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2244",
                "runway_width": "90",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3956,
            "s_seats": 2806,
            "j_seats": 1569,
            "f_seats": 390,
            "r_seats": 56,
            "cargo_demand": 43855
        }
    },
    {
        "iata": "HLN",
        "icao": "KHLN",
        "airportName": "Helena Regional Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Helena",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.60680008",
        "longitude": "-111.9830017",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4644",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2989",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97983,
            "s_seats": 27625,
            "j_seats": 6557,
            "f_seats": 2753,
            "r_seats": 3824,
            "cargo_demand": 194993
        }
    },
    {
        "iata": "HLP",
        "icao": "WIHH",
        "airportName": "Halim Perdanakusuma International Airport",
        "location_country": "Indonesia",
        "location_city": "Jakarta",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.266610146",
        "longitude": "106.8909988",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67391,
            "s_seats": 41265,
            "j_seats": 32035,
            "f_seats": 6005,
            "r_seats": 6331,
            "cargo_demand": 306406
        }
    },
    {
        "iata": "HLR",
        "icao": "KHLR",
        "airportName": "Hood Army Airfield",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Fort Hood / Killeen",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.1387",
        "longitude": "-97.7145",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3803",
                "runway_width": "144",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83445,
            "s_seats": 31111,
            "j_seats": 47196,
            "f_seats": 4783,
            "r_seats": 6345,
            "cargo_demand": 434489
        }
    },
    {
        "iata": "HLS",
        "icao": "YSTH",
        "airportName": "St Helens Airport",
        "location_country": "Australia",
        "location_city": "Tasmania",
        "location_state": "St Helens",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-41.33670044",
        "longitude": "148.2819977",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3510",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 3479,
            "s_seats": 2332,
            "j_seats": 1699,
            "f_seats": 132,
            "r_seats": 92,
            "cargo_demand": 56564
        }
    },
    {
        "iata": "HLT",
        "icao": "YHML",
        "airportName": "Hamilton Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Hamilton",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-37.64889908",
        "longitude": "142.0650024",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4045",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4606",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130116,
            "s_seats": 26483,
            "j_seats": 7869,
            "f_seats": 15251,
            "r_seats": 4766,
            "cargo_demand": 302004
        }
    },
    {
        "iata": "HLW",
        "icao": "FAHL",
        "airportName": "Hluhluwe Airport",
        "location_country": "South Africa",
        "location_city": "Hluhluwe",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.01660499",
        "longitude": "32.27517128",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3963",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2154,
            "s_seats": 1825,
            "j_seats": 1272,
            "f_seats": 246,
            "r_seats": 121,
            "cargo_demand": 64296
        }
    },
    {
        "iata": "HLZ",
        "icao": "NZHN",
        "airportName": "Hamilton Airport",
        "location_country": "New Zealand",
        "location_city": "Hamilton",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-37.86669922",
        "longitude": "175.3320007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2033",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "2033",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "6430",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "2871",
                "runway_width": "197",
                "runway_material": "ASP/GRS"
            }
        ],
        "demand": {
            "y_seats": 124601,
            "s_seats": 32158,
            "j_seats": 48813,
            "f_seats": 6413,
            "r_seats": 4612,
            "cargo_demand": 451711
        }
    },
    {
        "iata": "HMA",
        "icao": "USHH",
        "airportName": "Khanty-Mansiysk Airport",
        "location_country": "Russia",
        "location_city": "Khanty-Mansi Autonomous Okrug",
        "location_state": "Khanty-Mansiysk",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.0284996",
        "longitude": "69.08609772",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9180",
                "runway_width": "141",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 119930,
            "s_seats": 29799,
            "j_seats": 14771,
            "f_seats": 5921,
            "r_seats": 3890,
            "cargo_demand": 314604
        }
    },
    {
        "iata": "HME",
        "icao": "DAUH",
        "airportName": "Oued Irara-Krim Belkacem Airport",
        "location_country": "Algeria",
        "location_city": "Hassi Messaoud",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.673",
        "longitude": "6.14044",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "18/36",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187070,
            "s_seats": 45014,
            "j_seats": 8449,
            "f_seats": 8195,
            "r_seats": 5955,
            "cargo_demand": 323939
        }
    },
    {
        "iata": "HMG",
        "icao": "YHMB",
        "airportName": "Hermannsburg Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Hermannsburg",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-23.93000031",
        "longitude": "132.8049927",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3704",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 3188,
            "s_seats": 2820,
            "j_seats": 1613,
            "f_seats": 23,
            "r_seats": 155,
            "cargo_demand": 63810
        }
    },
    {
        "iata": "HMJ",
        "icao": "UKLH",
        "airportName": "Khmelnytskyi Airport",
        "location_country": "Ukraine",
        "location_city": "Khmelnytsky",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.359699",
        "longitude": "26.933399",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7219",
                "runway_width": "140",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 73487,
            "s_seats": 37243,
            "j_seats": 37248,
            "f_seats": 3646,
            "r_seats": 3407,
            "cargo_demand": 222315
        }
    },
    {
        "iata": "HMN",
        "icao": "KHMN",
        "airportName": "Holloman Air Force Base",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Alamogordo",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "32.85250092",
        "longitude": "-106.1070023",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10575",
                "runway_width": "300",
                "runway_material": "PEM"
            },
            {
                "runway_name": "07/25",
                "runway_length": "12800",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "16/34",
                "runway_length": "12131",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 337993,
            "s_seats": 84249,
            "j_seats": 128285,
            "f_seats": 20819,
            "r_seats": 10000,
            "cargo_demand": 7587383
        }
    },
    {
        "iata": "HMO",
        "icao": "MMHO",
        "airportName": "General Ignacio Pesqueira Garcia International Airport",
        "location_country": "Mexico",
        "location_city": "Sonora",
        "location_state": "Hermosillo",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "29.09589958",
        "longitude": "-111.0479965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110401,
            "s_seats": 76266,
            "j_seats": 48666,
            "f_seats": 48062,
            "r_seats": 10000,
            "cargo_demand": 4474750
        }
    },
    {
        "iata": "HMR",
        "icao": "ENHA",
        "airportName": "Hamar Airport/c/ Stafsberg",
        "location_country": "Norway",
        "location_city": "Hamar",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "\" Stavsberg\"\"\"",
        "longitude": "60.8181",
        "region": "729",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2625",
                "runway_width": "33",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 3928,
            "s_seats": 2281,
            "j_seats": 1865,
            "f_seats": 198,
            "r_seats": 129,
            "cargo_demand": 91145
        }
    },
    {
        "iata": "HMT",
        "icao": "KHMT",
        "airportName": "Hemet-Ryan Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Hemet",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.73400116",
        "longitude": "-117.0230026",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2045",
                "runway_width": "25",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "4314",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2984,
            "s_seats": 2290,
            "j_seats": 1324,
            "f_seats": 281,
            "r_seats": 100,
            "cargo_demand": 26643
        }
    },
    {
        "iata": "HMV",
        "icao": "ESUT",
        "airportName": "Hemavan TArnaby Airport",
        "location_country": "Sweden",
        "location_city": "Hemavan / TArnaby",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "65.806099",
        "longitude": "15.0828",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5254",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82705,
            "s_seats": 45374,
            "j_seats": 19809,
            "f_seats": 9279,
            "r_seats": 3577,
            "cargo_demand": 403649
        }
    },
    {
        "iata": "HMY",
        "icao": "RKTP",
        "airportName": "Seosan Air Base",
        "location_country": "South Korea",
        "location_city": "Seosan",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.703999",
        "longitude": "126.486",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3865,
            "s_seats": 1934,
            "j_seats": 1683,
            "f_seats": 157,
            "r_seats": 57,
            "cargo_demand": 21880
        }
    },
    {
        "iata": "HNA",
        "icao": "RJSI",
        "airportName": "Hanamaki Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Hanamaki",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.4286",
        "longitude": "141.134995",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185771,
            "s_seats": 36152,
            "j_seats": 30550,
            "f_seats": 1855,
            "r_seats": 4040,
            "cargo_demand": 179468
        }
    },
    {
        "iata": "HNB",
        "icao": "KHNB",
        "airportName": "Huntingburg Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Huntingburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.24900055",
        "longitude": "-86.9536972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3375,
            "s_seats": 2986,
            "j_seats": 1113,
            "f_seats": 435,
            "r_seats": 190,
            "cargo_demand": 58275
        }
    },
    {
        "iata": "HNC",
        "icao": "KHSE",
        "airportName": "Billy Mitchell Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Hatteras",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.23279953",
        "longitude": "-75.61779785",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3954,
            "s_seats": 1714,
            "j_seats": 1869,
            "f_seats": 82,
            "r_seats": 152,
            "cargo_demand": 5925
        }
    },
    {
        "iata": "HND",
        "icao": "RJTT",
        "airportName": "Haneda Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Tokyo",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "35.552299",
        "longitude": "139.779999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8200",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "8200",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "11024",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "9843",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 459266,
            "s_seats": 82386,
            "j_seats": 142142,
            "f_seats": 60014,
            "r_seats": 10000,
            "cargo_demand": 5259647
        }
    },
    {
        "iata": "HNH",
        "icao": "PAOH",
        "airportName": "Hoonah Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Hoonah",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "58.0961",
        "longitude": "-135.410111",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2997",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 1180,
            "s_seats": 1549,
            "j_seats": 1576,
            "f_seats": 431,
            "r_seats": 172,
            "cargo_demand": 25682
        }
    },
    {
        "iata": "HNL",
        "icao": "PHNL",
        "airportName": "Honolulu International Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Honolulu",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "21.32062",
        "longitude": "-157.924228",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "6952",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "04W/22W",
                "runway_length": "3000",
                "runway_width": "150",
                "runway_material": "WATER"
            },
            {
                "runway_name": "08L/26R",
                "runway_length": "12300",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "12000",
                "runway_width": "200",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "08W/26W",
                "runway_length": "5000",
                "runway_width": "300",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 261312,
            "s_seats": 88823,
            "j_seats": 142462,
            "f_seats": 31762,
            "r_seats": 10000,
            "cargo_demand": 9200775
        }
    },
    {
        "iata": "HNM",
        "icao": "PHHN",
        "airportName": "Hana Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Hana",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.79560089",
        "longitude": "-156.0140076",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3606",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 60130,
            "s_seats": 44288,
            "j_seats": 49326,
            "f_seats": 13638,
            "r_seats": 8806,
            "cargo_demand": 203508
        }
    },
    {
        "iata": "HNS",
        "icao": "PAHN",
        "airportName": "Haines Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Haines",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "59.24380112",
        "longitude": "-135.5240021",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80555,
            "s_seats": 34277,
            "j_seats": 13445,
            "f_seats": 3160,
            "r_seats": 7893,
            "cargo_demand": 239302
        }
    },
    {
        "iata": "HOB",
        "icao": "KHOB",
        "airportName": "Lea County Regional Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Hobbs",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.6875",
        "longitude": "-103.2170029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7398",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4998",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62841,
            "s_seats": 45973,
            "j_seats": 24715,
            "f_seats": 9602,
            "r_seats": 9669,
            "cargo_demand": 387883
        }
    },
    {
        "iata": "HOD",
        "icao": "OYHD",
        "airportName": "Hodeida International Airport",
        "location_country": "Yemen",
        "location_city": "Al Hudaydah (Hodeida)",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.75300026",
        "longitude": "42.97629929",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173957,
            "s_seats": 27059,
            "j_seats": 22015,
            "f_seats": 17116,
            "r_seats": 7255,
            "cargo_demand": 437015
        }
    },
    {
        "iata": "HOE",
        "icao": "VLHS",
        "airportName": "Ban Huoeisay Airport",
        "location_country": "Laos",
        "location_city": "Ban Houayxay (Ban Huoeisay)",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "20.25729942",
        "longitude": "100.4369965",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4922",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1752,
            "s_seats": 2713,
            "j_seats": 1125,
            "f_seats": 150,
            "r_seats": 192,
            "cargo_demand": 32075
        }
    },
    {
        "iata": "HOF",
        "icao": "OEAH",
        "airportName": "Al-Ahsa International Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Hofuf",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.285299",
        "longitude": "49.485199",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "10039",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180399,
            "s_seats": 39145,
            "j_seats": 57767,
            "f_seats": 19765,
            "r_seats": 9637,
            "cargo_demand": 341203
        }
    },
    {
        "iata": "HOG",
        "icao": "MUHG",
        "airportName": "Frank Pais Airport",
        "location_country": "Cuba",
        "location_city": "Holguin",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "20.78560066",
        "longitude": "-76.31510162",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10624",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145172,
            "s_seats": 37879,
            "j_seats": 47185,
            "f_seats": 6172,
            "r_seats": 3559,
            "cargo_demand": 55304
        }
    },
    {
        "iata": "HOH",
        "icao": "LOIH",
        "airportName": "Hohenems-Dornbirn Airport",
        "location_country": "Austria",
        "location_city": "Dornbirn",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "47.384998",
        "longitude": "9.7",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2067",
                "runway_width": "59",
                "runway_material": "ASPHALT"
            }
        ],
        "demand": {
            "y_seats": 3649,
            "s_seats": 1922,
            "j_seats": 1871,
            "f_seats": 480,
            "r_seats": 3,
            "cargo_demand": 82757
        }
    },
    {
        "iata": "HOI",
        "icao": "NTTO",
        "airportName": "Hao Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Hao",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-18.07480049",
        "longitude": "-140.9459991",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "11089",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109202,
            "s_seats": 34271,
            "j_seats": 17036,
            "f_seats": 8596,
            "r_seats": 3690,
            "cargo_demand": 246284
        }
    },
    {
        "iata": "HOK",
        "icao": "YHOO",
        "airportName": "Hooker Creek Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Lajamanu",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.33670044",
        "longitude": "130.6380005",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5118",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1484,
            "s_seats": 1617,
            "j_seats": 1306,
            "f_seats": 494,
            "r_seats": 73,
            "cargo_demand": 4810
        }
    },
    {
        "iata": "HOM",
        "icao": "PAHO",
        "airportName": "Homer Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Homer",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "59.64559937",
        "longitude": "-151.477005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6701",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79226,
            "s_seats": 28811,
            "j_seats": 34026,
            "f_seats": 5097,
            "r_seats": 7803,
            "cargo_demand": 89038
        }
    },
    {
        "iata": "HON",
        "icao": "KHON",
        "airportName": "Huron Regional Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Huron",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.3852005",
        "longitude": "-98.22850037",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7201",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 160383,
            "s_seats": 33247,
            "j_seats": 59477,
            "f_seats": 3982,
            "r_seats": 6122,
            "cargo_demand": 174317
        }
    },
    {
        "iata": "HOP",
        "icao": "KHOP",
        "airportName": "Campbell Army Airfield",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Fort Campbell / Hopkinsville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.66859818",
        "longitude": "-87.49620056",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11800",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116204,
            "s_seats": 45747,
            "j_seats": 6350,
            "f_seats": 7599,
            "r_seats": 9672,
            "cargo_demand": 392184
        }
    },
    {
        "iata": "HOQ",
        "icao": "EDQM",
        "airportName": "Hof-Plauen Airport",
        "location_country": "Germany",
        "location_city": "Bavaria",
        "location_state": "Hof",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.288612",
        "longitude": "11.856389",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4856",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197410,
            "s_seats": 43961,
            "j_seats": 55017,
            "f_seats": 11353,
            "r_seats": 5179,
            "cargo_demand": 310836
        }
    },
    {
        "iata": "HOR",
        "icao": "LPHR",
        "airportName": "Horta Airport",
        "location_country": "Portugal",
        "location_city": "Azores",
        "location_state": "Horta",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "38.51990128",
        "longitude": "-28.71590042",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5233",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64132,
            "s_seats": 41689,
            "j_seats": 11490,
            "f_seats": 1320,
            "r_seats": 6175,
            "cargo_demand": 343095
        }
    },
    {
        "iata": "HOS",
        "icao": "SAHC",
        "airportName": "Chos Malal Airport",
        "location_country": "Argentina",
        "location_city": "Neuquen",
        "location_state": "Chos Malal",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-37.44469833",
        "longitude": "-70.22250366",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3366,
            "s_seats": 2578,
            "j_seats": 1757,
            "f_seats": 102,
            "r_seats": 5,
            "cargo_demand": 10330
        }
    },
    {
        "iata": "HOT",
        "icao": "KHOT",
        "airportName": "Memorial Field Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Hot Springs",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.47800064",
        "longitude": "-93.09619904",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6595",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4099",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 119727,
            "s_seats": 44854,
            "j_seats": 44670,
            "f_seats": 11877,
            "r_seats": 7835,
            "cargo_demand": 185019
        }
    },
    {
        "iata": "HOU",
        "icao": "KHOU",
        "airportName": "William P. Hobby Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Houston",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "29.64539909",
        "longitude": "-95.27890015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7602",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "5148",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "7602",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 104964,
            "s_seats": 88695,
            "j_seats": 78263,
            "f_seats": 51043,
            "r_seats": 10000,
            "cargo_demand": 17409760
        }
    },
    {
        "iata": "HOV",
        "icao": "ENOV",
        "airportName": "orsta-Volda Airport",
        "location_country": "Norway",
        "location_city": "orsta / Volda",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "\" Hovden\"\"\"",
        "longitude": "62.18000031",
        "region": "243",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3510",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127912,
            "s_seats": 36483,
            "j_seats": 13313,
            "f_seats": 5321,
            "r_seats": 5310,
            "cargo_demand": 68664
        }
    },
    {
        "iata": "HOX",
        "icao": "VYHL",
        "airportName": "Homalin Airport",
        "location_country": "Myanmar",
        "location_city": "Homalin",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "24.89959908",
        "longitude": "94.91400146",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "11970",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1755,
            "s_seats": 1535,
            "j_seats": 2000,
            "f_seats": 345,
            "r_seats": 125,
            "cargo_demand": 13940
        }
    },
    {
        "iata": "HPA",
        "icao": "NFTL",
        "airportName": "Lifuka Island Airport",
        "location_country": "Tonga",
        "location_city": "Ha'apai",
        "location_state": "Lifuka",
        "time_zone": "UTC+13:00",
        "daylight_saving": "Nov-Jan",
        "airport_size": "medium_airport",
        "latitude": "-19.77700043",
        "longitude": "-174.3410034",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 61577,
            "s_seats": 48699,
            "j_seats": 57452,
            "f_seats": 2702,
            "r_seats": 4584,
            "cargo_demand": 434826
        }
    },
    {
        "iata": "HPB",
        "icao": "PAHP",
        "airportName": "Hooper Bay Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Hooper Bay",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "61.52389908",
        "longitude": "-166.1470032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3300",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122656,
            "s_seats": 38305,
            "j_seats": 45363,
            "f_seats": 1067,
            "r_seats": 7742,
            "cargo_demand": 296896
        }
    },
    {
        "iata": "HPH",
        "icao": "VVCI",
        "airportName": "Cat Bi International Airport",
        "location_country": "Vietnam",
        "location_city": "Haiphong",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.81940079",
        "longitude": "106.7249985",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7880",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125034,
            "s_seats": 31020,
            "j_seats": 14815,
            "f_seats": 14599,
            "r_seats": 3400,
            "cargo_demand": 404539
        }
    },
    {
        "iata": "HPN",
        "icao": "KHPN",
        "airportName": "Westchester County Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "White Plains",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.06700134",
        "longitude": "-73.70760345",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4451",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6548",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141044,
            "s_seats": 31188,
            "j_seats": 30439,
            "f_seats": 5815,
            "r_seats": 8891,
            "cargo_demand": 260893
        }
    },
    {
        "iata": "HPT",
        "icao": "KHPT",
        "airportName": "Hampton Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Hampton",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.72370148",
        "longitude": "-93.2263031",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4018",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1294,
            "s_seats": 2275,
            "j_seats": 1064,
            "f_seats": 203,
            "r_seats": 109,
            "cargo_demand": 78218
        }
    },
    {
        "iata": "HPY",
        "icao": "KHPY",
        "airportName": "Baytown Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Baytown",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "29.78610039",
        "longitude": "-94.95269775",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4334",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3043,
            "s_seats": 2093,
            "j_seats": 1486,
            "f_seats": 229,
            "r_seats": 149,
            "cargo_demand": 20321
        }
    },
    {
        "iata": "HQM",
        "icao": "KHQM",
        "airportName": "Bowerman Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Hoquiam",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.97119904",
        "longitude": "-123.9369965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180846,
            "s_seats": 34570,
            "j_seats": 18784,
            "f_seats": 2558,
            "r_seats": 9207,
            "cargo_demand": 219337
        }
    },
    {
        "iata": "HRB",
        "icao": "ZYHB",
        "airportName": "Harbin Taiping International Airport",
        "location_country": "China",
        "location_city": "Heilongjiang",
        "location_state": "Harbin",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "45.62340164",
        "longitude": "126.25",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10500",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155169,
            "s_seats": 87812,
            "j_seats": 167909,
            "f_seats": 33765,
            "r_seats": 10000,
            "cargo_demand": 2302242
        }
    },
    {
        "iata": "HRE",
        "icao": "FVHA",
        "airportName": "Harare International Airport",
        "location_country": "Zimbabwe",
        "location_city": "Harare",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-17.931801",
        "longitude": "31.0928",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "15502",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134844,
            "s_seats": 83251,
            "j_seats": 26418,
            "f_seats": 65117,
            "r_seats": 10000,
            "cargo_demand": 4734709
        }
    },
    {
        "iata": "HRG",
        "icao": "HEGN",
        "airportName": "Hurghada International Airport",
        "location_country": "Egypt",
        "location_city": "Hurghada",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "27.180325",
        "longitude": "33.807292",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "13124",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 465764,
            "s_seats": 94122,
            "j_seats": 40276,
            "f_seats": 53546,
            "r_seats": 10000,
            "cargo_demand": 26142588
        }
    },
    {
        "iata": "HRI",
        "icao": "VCRI",
        "airportName": "Mattala Rajapaksa International Airport",
        "location_country": "Sri Lanka",
        "location_city": "Hambantota",
        "location_state": "",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "6.284467",
        "longitude": "81.124128",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11483",
                "runway_width": "197",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 162672,
            "s_seats": 95331,
            "j_seats": 92883,
            "f_seats": 34316,
            "r_seats": 10000,
            "cargo_demand": 6198753
        }
    },
    {
        "iata": "HRK",
        "icao": "UKHH",
        "airportName": "Kharkiv International Airport",
        "location_country": "Ukraine",
        "location_city": "Kharkiv",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "49.92480087",
        "longitude": "36.29000092",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7285",
                "runway_width": "165",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 436707,
            "s_seats": 89583,
            "j_seats": 86848,
            "f_seats": 44986,
            "r_seats": 10000,
            "cargo_demand": 23867840
        }
    },
    {
        "iata": "HRL",
        "icao": "KHRL",
        "airportName": "Valley International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Harlingen",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "26.22850037",
        "longitude": "-97.65440369",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7257",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "5949",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "8301",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134574,
            "s_seats": 37085,
            "j_seats": 33754,
            "f_seats": 11540,
            "r_seats": 4460,
            "cargo_demand": 283439
        }
    },
    {
        "iata": "HRM",
        "icao": "DAFH",
        "airportName": "Hassi R'Mel Airport",
        "location_country": "Algeria",
        "location_city": "Hassi R'Mel",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.930401",
        "longitude": "3.31154",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9835",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4695",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 96895,
            "s_seats": 39943,
            "j_seats": 35605,
            "f_seats": 17216,
            "r_seats": 3800,
            "cargo_demand": 332613
        }
    },
    {
        "iata": "HRO",
        "icao": "KHRO",
        "airportName": "Boone County Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Harrison",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.26150131",
        "longitude": "-93.15470123",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6161",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165289,
            "s_seats": 34707,
            "j_seats": 52058,
            "f_seats": 1309,
            "r_seats": 8452,
            "cargo_demand": 87841
        }
    },
    {
        "iata": "HRS",
        "icao": "FAHR",
        "airportName": "Harrismith Airport",
        "location_country": "South Africa",
        "location_city": "Harrismith",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.23509979",
        "longitude": "29.10619926",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3937",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135334,
            "s_seats": 32449,
            "j_seats": 6618,
            "f_seats": 18049,
            "r_seats": 4052,
            "cargo_demand": 176973
        }
    },
    {
        "iata": "HRT",
        "icao": "EGXU",
        "airportName": "RAF Linton-on-Ouse",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Linton-on-Ouse",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "54.0489006",
        "longitude": "-1.252750039",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6020",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4394",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86191,
            "s_seats": 41852,
            "j_seats": 35590,
            "f_seats": 7879,
            "r_seats": 5733,
            "cargo_demand": 121695
        }
    },
    {
        "iata": "HRY",
        "icao": "YHBY",
        "airportName": "Henbury Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Henbury",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.584",
        "longitude": "133.236",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 2993,
            "s_seats": 2822,
            "j_seats": 1821,
            "f_seats": 162,
            "r_seats": 103,
            "cargo_demand": 6752
        }
    },
    {
        "iata": "HRZ",
        "icao": "SSHZ",
        "airportName": "Horizontina Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Horizontina",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-27.63829994",
        "longitude": "-54.33909988",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3445",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1347,
            "s_seats": 1586,
            "j_seats": 1546,
            "f_seats": 365,
            "r_seats": 131,
            "cargo_demand": 48490
        }
    },
    {
        "iata": "HSA",
        "icao": "UAIT",
        "airportName": "Hazret Sultan International Airport",
        "location_country": "Kazakhstan",
        "location_city": "Turkistan Region",
        "location_state": "Turkistan",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.313126",
        "longitude": "68.549881",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192932,
            "s_seats": 95624,
            "j_seats": 69220,
            "f_seats": 21271,
            "r_seats": 10000,
            "cargo_demand": 20942754
        }
    },
    {
        "iata": "HSB",
        "icao": "KHSB",
        "airportName": "Harrisburg-Raleigh Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Harrisburg",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.81129837",
        "longitude": "-88.5503006",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5013",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2764",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3857,
            "s_seats": 2364,
            "j_seats": 1820,
            "f_seats": 342,
            "r_seats": 87,
            "cargo_demand": 81402
        }
    },
    {
        "iata": "HSG",
        "icao": "RJFS",
        "airportName": "Saga Airport",
        "location_country": "Japan",
        "location_city": "Kyushu",
        "location_state": "Saga",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.14970016",
        "longitude": "130.302002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179185,
            "s_seats": 37765,
            "j_seats": 15857,
            "f_seats": 12288,
            "r_seats": 8099,
            "cargo_demand": 144675
        }
    },
    {
        "iata": "HSH",
        "icao": "KHND",
        "airportName": "Henderson Executive Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Las Vegas",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.97280121",
        "longitude": "-115.1340027",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "5001",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "6501",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2761,
            "s_seats": 2653,
            "j_seats": 1393,
            "f_seats": 438,
            "r_seats": 74,
            "cargo_demand": 15719
        }
    },
    {
        "iata": "HSI",
        "icao": "KHSI",
        "airportName": "Hastings Municipal Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Hastings",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.6053009",
        "longitude": "-98.42790222",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4501",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6451",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3990,
            "s_seats": 1872,
            "j_seats": 1696,
            "f_seats": 148,
            "r_seats": 21,
            "cargo_demand": 28946
        }
    },
    {
        "iata": "HSK",
        "icao": "LEHC",
        "airportName": "Huesca-Pirineos Airport",
        "location_country": "Spain",
        "location_city": "Aragon",
        "location_state": "Huesca",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "42.076099",
        "longitude": "-0.316667",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12L/30R",
                "runway_length": "2018",
                "runway_width": "39",
                "runway_material": "Asphalt"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "6889",
                "runway_width": "147",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 3828,
            "s_seats": 2571,
            "j_seats": 1543,
            "f_seats": 329,
            "r_seats": 47,
            "cargo_demand": 98712
        }
    },
    {
        "iata": "HSL",
        "icao": "PAHL",
        "airportName": "Huslia Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Huslia",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "65.69789886",
        "longitude": "-156.3509979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 148541,
            "s_seats": 35450,
            "j_seats": 23015,
            "f_seats": 13278,
            "r_seats": 7158,
            "cargo_demand": 240726
        }
    },
    {
        "iata": "HSM",
        "icao": "YHSM",
        "airportName": "Horsham Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Horsham",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-36.66970062",
        "longitude": "142.1730042",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4337",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2621",
                "runway_width": "60",
                "runway_material": "SAN"
            }
        ],
        "demand": {
            "y_seats": 115606,
            "s_seats": 31351,
            "j_seats": 30507,
            "f_seats": 9814,
            "r_seats": 5123,
            "cargo_demand": 174754
        }
    },
    {
        "iata": "HSP",
        "icao": "KHSP",
        "airportName": "Ingalls Field",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Hot Springs",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.95140076",
        "longitude": "-79.83390045",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5600",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2835,
            "s_seats": 1578,
            "j_seats": 1890,
            "f_seats": 38,
            "r_seats": 155,
            "cargo_demand": 67067
        }
    },
    {
        "iata": "HSS",
        "icao": "VIHR",
        "airportName": "Hisar Airport",
        "location_country": "India",
        "location_city": "Haryana",
        "location_state": "Hisar",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.17939949",
        "longitude": "75.75530243",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53570,
            "s_seats": 26235,
            "j_seats": 22184,
            "f_seats": 3095,
            "r_seats": 6721,
            "cargo_demand": 250474
        }
    },
    {
        "iata": "HST",
        "icao": "KHST",
        "airportName": "Homestead Air Reserve Base",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Homestead",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "25.48859978",
        "longitude": "-80.38359833",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11201",
                "runway_width": "300",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 199711,
            "s_seats": 37402,
            "j_seats": 47154,
            "f_seats": 10245,
            "r_seats": 4742,
            "cargo_demand": 476475
        }
    },
    {
        "iata": "HSV",
        "icao": "KHSV",
        "airportName": "Huntsville International Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Huntsville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "34.6371994",
        "longitude": "-86.77510071",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "10006",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "12600",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 331705,
            "s_seats": 75826,
            "j_seats": 88968,
            "f_seats": 55890,
            "r_seats": 10000,
            "cargo_demand": 9530529
        }
    },
    {
        "iata": "HSZ",
        "icao": "RCPO",
        "airportName": "Hsinchu Air Base",
        "location_country": "Taiwan",
        "location_city": "Hsinchu",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.81800079",
        "longitude": "120.939003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11955",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 73212,
            "s_seats": 42222,
            "j_seats": 23987,
            "f_seats": 3624,
            "r_seats": 3511,
            "cargo_demand": 213936
        }
    },
    {
        "iata": "HTA",
        "icao": "UIAA",
        "airportName": "Chita International Airport",
        "location_country": "Russia",
        "location_city": "Zabaykalsky Krai",
        "location_state": "Chita",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.026299",
        "longitude": "113.306",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9430",
                "runway_width": "184",
                "runway_material": "CON"
            },
            {
                "runway_name": "11L/29R",
                "runway_length": "5905",
                "runway_width": "245",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 127588,
            "s_seats": 48222,
            "j_seats": 34099,
            "f_seats": 4564,
            "r_seats": 9717,
            "cargo_demand": 476592
        }
    },
    {
        "iata": "HTG",
        "icao": "UOHH",
        "airportName": "Khatanga Airport",
        "location_country": "Russia",
        "location_city": "Krasnoyarsk Krai",
        "location_state": "Khatanga",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "71.97810364",
        "longitude": "102.4909973",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8872",
                "runway_width": "158",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 134905,
            "s_seats": 40370,
            "j_seats": 45945,
            "f_seats": 3036,
            "r_seats": 8928,
            "cargo_demand": 294908
        }
    },
    {
        "iata": "HTH",
        "icao": "KHTH",
        "airportName": "Hawthorne Industrial Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Hawthorne",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.544399",
        "longitude": "-118.634002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3500",
                "runway_width": "130",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 3169,
            "s_seats": 2786,
            "j_seats": 1714,
            "f_seats": 26,
            "r_seats": 79,
            "cargo_demand": 59475
        }
    },
    {
        "iata": "HTI",
        "icao": "YBHM",
        "airportName": "Great Barrier Reef Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Hamilton Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.35810089",
        "longitude": "148.9519959",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5591",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89535,
            "s_seats": 43627,
            "j_seats": 31552,
            "f_seats": 9765,
            "r_seats": 9387,
            "cargo_demand": 307763
        }
    },
    {
        "iata": "HTL",
        "icao": "KHTL",
        "airportName": "Roscommon County-Blodgett Memorial Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Houghton Lake",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.359798",
        "longitude": "-84.671095",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2200",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1844,
            "s_seats": 2311,
            "j_seats": 1170,
            "f_seats": 350,
            "r_seats": 33,
            "cargo_demand": 31010
        }
    },
    {
        "iata": "HTN",
        "icao": "ZWTN",
        "airportName": "Hotan Airport",
        "location_country": "China",
        "location_city": "Xinjiang",
        "location_state": "Hotan",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.03850174",
        "longitude": "79.86489868",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10499",
                "runway_width": "164",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 183724,
            "s_seats": 35538,
            "j_seats": 46441,
            "f_seats": 8824,
            "r_seats": 6158,
            "cargo_demand": 392691
        }
    },
    {
        "iata": "HTO",
        "icao": "KHTO",
        "airportName": "East Hampton Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "East Hampton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.95959854",
        "longitude": "-72.25180054",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4255",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2223",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1146,
            "s_seats": 2460,
            "j_seats": 1104,
            "f_seats": 143,
            "r_seats": 68,
            "cargo_demand": 9220
        }
    },
    {
        "iata": "HTR",
        "icao": "RORH",
        "airportName": "Hateruma Airport",
        "location_country": "Japan",
        "location_city": "Yaeyama Islands",
        "location_state": "Hateruma",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "24.059751",
        "longitude": "123.80558",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2625",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3179,
            "s_seats": 2185,
            "j_seats": 1300,
            "f_seats": 114,
            "r_seats": 67,
            "cargo_demand": 53937
        }
    },
    {
        "iata": "HTS",
        "icao": "KHTS",
        "airportName": "Tri-State Airport",
        "location_country": "United States",
        "location_city": "West Virginia",
        "location_state": "Huntington",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "38.36669922",
        "longitude": "-82.55799866",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3007",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6509",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199572,
            "s_seats": 93644,
            "j_seats": 27395,
            "f_seats": 47694,
            "r_seats": 10000,
            "cargo_demand": 125603
        }
    },
    {
        "iata": "HTU",
        "icao": "YHPN",
        "airportName": "Hopetoun Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Hopetoun",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-35.71530151",
        "longitude": "142.3600006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3730",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "1598",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 56768,
            "s_seats": 44115,
            "j_seats": 25034,
            "f_seats": 5298,
            "r_seats": 3798,
            "cargo_demand": 410267
        }
    },
    {
        "iata": "HTV",
        "icao": "KUTS",
        "airportName": "Huntsville Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Huntsville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.74690056",
        "longitude": "-95.58719635",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5006",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58971,
            "s_seats": 29676,
            "j_seats": 9677,
            "f_seats": 12185,
            "r_seats": 3906,
            "cargo_demand": 473618
        }
    },
    {
        "iata": "HTW",
        "icao": "KHTW",
        "airportName": "Lawrence County Airpark",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Chesapeake / Huntington (WV)",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.4193",
        "longitude": "-82.494301",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3001",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1527,
            "s_seats": 2830,
            "j_seats": 1023,
            "f_seats": 465,
            "r_seats": 56,
            "cargo_demand": 46826
        }
    },
    {
        "iata": "HTY",
        "icao": "LTDA",
        "airportName": "Hatay Airport",
        "location_country": "Turkey",
        "location_city": "Hatay",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.362778",
        "longitude": "36.282223",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 58705,
            "s_seats": 44728,
            "j_seats": 35988,
            "f_seats": 19451,
            "r_seats": 8770,
            "cargo_demand": 168728
        }
    },
    {
        "iata": "HUA",
        "icao": "KHUA",
        "airportName": "Redstone Army Airfield",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Redstone Arsenal / Huntsville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.67869949",
        "longitude": "-86.68479919",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7300",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157780,
            "s_seats": 46611,
            "j_seats": 39706,
            "f_seats": 14531,
            "r_seats": 4595,
            "cargo_demand": 260212
        }
    },
    {
        "iata": "HUB",
        "icao": "YHBR",
        "airportName": "Humbert River Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Humbert River",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.4896698",
        "longitude": "130.6302795",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1692,
            "s_seats": 2224,
            "j_seats": 1028,
            "f_seats": 198,
            "r_seats": 158,
            "cargo_demand": 9255
        }
    },
    {
        "iata": "HUF",
        "icao": "KHUF",
        "airportName": "Terre Haute International Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Terre Haute",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "\" Hulman Field\"\"\"",
        "longitude": "39.4515",
        "region": "589",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9020",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "7200",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4001",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 189949,
            "s_seats": 39728,
            "j_seats": 6660,
            "f_seats": 19758,
            "r_seats": 7404,
            "cargo_demand": 490696
        }
    },
    {
        "iata": "HUH",
        "icao": "NTTH",
        "airportName": "Huahine - Fare Airport",
        "location_country": "French Polynesia",
        "location_city": "Society Islands",
        "location_state": "Huahine",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.68720055",
        "longitude": "-151.0220032",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4921",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171085,
            "s_seats": 38278,
            "j_seats": 6386,
            "f_seats": 13045,
            "r_seats": 3495,
            "cargo_demand": 337589
        }
    },
    {
        "iata": "HUI",
        "icao": "VVPB",
        "airportName": "Phu Bai International Airport",
        "location_country": "Vietnam",
        "location_city": "Hua\u00ba\u00bf",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.401501",
        "longitude": "107.703003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8775",
                "runway_width": "130",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50345,
            "s_seats": 36974,
            "j_seats": 34009,
            "f_seats": 14685,
            "r_seats": 9084,
            "cargo_demand": 156915
        }
    },
    {
        "iata": "HUJ",
        "icao": "KHHW",
        "airportName": "Stan Stamper Municipal Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Hugo",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.03480148",
        "longitude": "-95.54190063",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3400",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3982,
            "s_seats": 2832,
            "j_seats": 1554,
            "f_seats": 409,
            "r_seats": 101,
            "cargo_demand": 76685
        }
    },
    {
        "iata": "HUL",
        "icao": "KHUL",
        "airportName": "Houlton International Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Houlton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.12310028",
        "longitude": "-67.792099",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72916,
            "s_seats": 33347,
            "j_seats": 13494,
            "f_seats": 19052,
            "r_seats": 8010,
            "cargo_demand": 181899
        }
    },
    {
        "iata": "HUM",
        "icao": "KHUM",
        "airportName": "Houma-Terrebonne Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Houma",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "29.56649971",
        "longitude": "-90.66040039",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4999",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6508",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1683,
            "s_seats": 1949,
            "j_seats": 1636,
            "f_seats": 42,
            "r_seats": 197,
            "cargo_demand": 83920
        }
    },
    {
        "iata": "HUN",
        "icao": "RCYU",
        "airportName": "Hualien Airport",
        "location_country": "Taiwan",
        "location_city": "Hualien",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.0230999",
        "longitude": "121.6179962",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9022",
                "runway_width": "148",
                "runway_material": "PEM"
            },
            {
                "runway_name": "03L/21R",
                "runway_length": "9022",
                "runway_width": "148",
                "runway_material": "PEM"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "9005",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114895,
            "s_seats": 48530,
            "j_seats": 28751,
            "f_seats": 13582,
            "r_seats": 5722,
            "cargo_demand": 244290
        }
    },
    {
        "iata": "HUO",
        "icao": "ZBHZ",
        "airportName": "Holingol Huolinhe Airport",
        "location_country": "China",
        "location_city": "Inner Mongolia",
        "location_state": "Holingol (Huolinguole)",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.487222",
        "longitude": "119.407222",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 164477,
            "s_seats": 33769,
            "j_seats": 39287,
            "f_seats": 12386,
            "r_seats": 4755,
            "cargo_demand": 408879
        }
    },
    {
        "iata": "HUQ",
        "icao": "HLON",
        "airportName": "Hun Airport",
        "location_country": "Libya",
        "location_city": "Hun",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "29.1101",
        "longitude": "15.9656",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2213,
            "s_seats": 1903,
            "j_seats": 1763,
            "f_seats": 221,
            "r_seats": 83,
            "cargo_demand": 22854
        }
    },
    {
        "iata": "HUS",
        "icao": "PAHU",
        "airportName": "Hughes Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Hughes",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "66.04109955",
        "longitude": "-154.2630005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3380",
                "runway_width": "100",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 1649,
            "s_seats": 2432,
            "j_seats": 1092,
            "f_seats": 20,
            "r_seats": 6,
            "cargo_demand": 62637
        }
    },
    {
        "iata": "HUT",
        "icao": "KHUT",
        "airportName": "Hutchinson Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Hutchinson",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.06549835",
        "longitude": "-97.86060333",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7004",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4252",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115315,
            "s_seats": 37270,
            "j_seats": 48844,
            "f_seats": 13522,
            "r_seats": 7330,
            "cargo_demand": 164011
        }
    },
    {
        "iata": "HUU",
        "icao": "SPNC",
        "airportName": "Alferez FAP David Figueroa Fernandini Airport",
        "location_country": "Peru",
        "location_city": "Huanuco",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.878809929",
        "longitude": "-76.20480347",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8202",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141431,
            "s_seats": 26807,
            "j_seats": 37589,
            "f_seats": 16966,
            "r_seats": 3604,
            "cargo_demand": 276749
        }
    },
    {
        "iata": "HUW",
        "icao": "SWHT",
        "airportName": "Francisco Correa da Cruz Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Humaita",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-7.532120228",
        "longitude": "-63.07210159",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4987",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2858,
            "s_seats": 1752,
            "j_seats": 1485,
            "f_seats": 117,
            "r_seats": 45,
            "cargo_demand": 27543
        }
    },
    {
        "iata": "HUX",
        "icao": "MMBT",
        "airportName": "Bahias de Huatulco International Airport",
        "location_country": "Mexico",
        "location_city": "Oaxaca",
        "location_state": "Huatulco",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "15.7753",
        "longitude": "-96.262604",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140231,
            "s_seats": 35827,
            "j_seats": 34472,
            "f_seats": 8083,
            "r_seats": 4545,
            "cargo_demand": 493954
        }
    },
    {
        "iata": "HUY",
        "icao": "EGNJ",
        "airportName": "Humberside Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Kingston upon Hull",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "53.57440186",
        "longitude": "-0.350832999",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7205",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3458",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156895,
            "s_seats": 42349,
            "j_seats": 11968,
            "f_seats": 6279,
            "r_seats": 5767,
            "cargo_demand": 232223
        }
    },
    {
        "iata": "HVA",
        "icao": "FMNL",
        "airportName": "Analalava Airport",
        "location_country": "Madagascar",
        "location_city": "Analalava",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.6297",
        "longitude": "47.763802",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3937",
                "runway_width": "72",
                "runway_material": "COP"
            }
        ],
        "demand": {
            "y_seats": 63890,
            "s_seats": 38318,
            "j_seats": 46300,
            "f_seats": 16875,
            "r_seats": 6986,
            "cargo_demand": 85622
        }
    },
    {
        "iata": "HVB",
        "icao": "YHBA",
        "airportName": "Hervey Bay Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Hervey Bay",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.31889915",
        "longitude": "152.8800049",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6561",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180048,
            "s_seats": 28328,
            "j_seats": 19142,
            "f_seats": 18432,
            "r_seats": 7452,
            "cargo_demand": 64633
        }
    },
    {
        "iata": "HVD",
        "icao": "ZMKD",
        "airportName": "Khovd Airport",
        "location_country": "Mongolia",
        "location_city": "Khovd",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.95410156",
        "longitude": "91.62819672",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "16/34",
                "runway_length": "9352",
                "runway_width": "160",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "6535",
                "runway_width": "164",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 190484,
            "s_seats": 32297,
            "j_seats": 7814,
            "f_seats": 7442,
            "r_seats": 9661,
            "cargo_demand": 324660
        }
    },
    {
        "iata": "HVE",
        "icao": "KHVE",
        "airportName": "Hanksville Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Hanksville",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.41799927",
        "longitude": "-110.7040024",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5675",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "120",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1531,
            "s_seats": 2388,
            "j_seats": 1757,
            "f_seats": 145,
            "r_seats": 177,
            "cargo_demand": 66587
        }
    },
    {
        "iata": "HVG",
        "icao": "ENHV",
        "airportName": "Honningsviog Airport",
        "location_country": "Norway",
        "location_city": "Honningsviog",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "71.00969696",
        "longitude": "25.98360062",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2625",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157508,
            "s_seats": 30264,
            "j_seats": 9452,
            "f_seats": 5203,
            "r_seats": 5237,
            "cargo_demand": 109465
        }
    },
    {
        "iata": "HVK",
        "icao": "BIHK",
        "airportName": "Holmavik Airport",
        "location_country": "Iceland",
        "location_city": "Holmavik",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.70469666",
        "longitude": "-21.69639969",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2948,
            "s_seats": 1771,
            "j_seats": 1256,
            "f_seats": 222,
            "r_seats": 52,
            "cargo_demand": 8046
        }
    },
    {
        "iata": "HVN",
        "icao": "KHVN",
        "airportName": "Tweed New Haven Airport",
        "location_country": "United States",
        "location_city": "Connecticut",
        "location_state": "New Haven",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.26369858",
        "longitude": "-72.88680267",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5600",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3626",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114862,
            "s_seats": 25511,
            "j_seats": 57381,
            "f_seats": 14749,
            "r_seats": 8647,
            "cargo_demand": 390798
        }
    },
    {
        "iata": "HVR",
        "icao": "KHVR",
        "airportName": "Havre City-County Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Havre",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "48.54299927",
        "longitude": "-109.762001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3699",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "5205",
                "runway_width": "100",
                "runway_material": "ASPH-F"
            }
        ],
        "demand": {
            "y_seats": 92332,
            "s_seats": 33278,
            "j_seats": 56031,
            "f_seats": 12229,
            "r_seats": 6914,
            "cargo_demand": 80142
        }
    },
    {
        "iata": "HVS",
        "icao": "KHVS",
        "airportName": "Hartsville Regional Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Hartsville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.40309906",
        "longitude": "-80.11920166",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1685,
            "s_seats": 2702,
            "j_seats": 1688,
            "f_seats": 163,
            "r_seats": 114,
            "cargo_demand": 40885
        }
    },
    {
        "iata": "HWD",
        "icao": "KHWD",
        "airportName": "Hayward Executive Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Hayward",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.65919876",
        "longitude": "-122.1220016",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "3107",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "5694",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3130,
            "s_seats": 2743,
            "j_seats": 1403,
            "f_seats": 227,
            "r_seats": 111,
            "cargo_demand": 82583
        }
    },
    {
        "iata": "HWK",
        "icao": "YHAW",
        "airportName": "Wilpena Pound Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Hawker",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-31.85590744",
        "longitude": "138.4680786",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3383,
            "s_seats": 1577,
            "j_seats": 1099,
            "f_seats": 309,
            "r_seats": 153,
            "cargo_demand": 96181
        }
    },
    {
        "iata": "HWN",
        "icao": "FVWN",
        "airportName": "Hwange National Park Airport",
        "location_country": "Zimbabwe",
        "location_city": "Hwange National Park",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-18.62989998",
        "longitude": "27.02099991",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "15091",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125670,
            "s_seats": 31073,
            "j_seats": 48224,
            "f_seats": 17196,
            "r_seats": 3231,
            "cargo_demand": 92034
        }
    },
    {
        "iata": "HWO",
        "icao": "KHWO",
        "airportName": "North Perry Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Hollywood",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "26.0012",
        "longitude": "-80.2407",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "3240",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "3255",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "19L/1R",
                "runway_length": "3260",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "19R/1L",
                "runway_length": "3350",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1748,
            "s_seats": 2888,
            "j_seats": 1305,
            "f_seats": 464,
            "r_seats": 2,
            "cargo_demand": 81664
        }
    },
    {
        "iata": "HXX",
        "icao": "YHAY",
        "airportName": "Hay Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Hay",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-34.53139877",
        "longitude": "144.8300018",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4800",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3740",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 62272,
            "s_seats": 29899,
            "j_seats": 45494,
            "f_seats": 17919,
            "r_seats": 3011,
            "cargo_demand": 60956
        }
    },
    {
        "iata": "HYA",
        "icao": "KHYA",
        "airportName": "Barnstable Municipal Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Hyannis",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.66930008",
        "longitude": "-70.28040314",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5425",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5252",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110776,
            "s_seats": 46856,
            "j_seats": 41738,
            "f_seats": 17065,
            "r_seats": 4732,
            "cargo_demand": 271177
        }
    },
    {
        "iata": "HYC",
        "icao": "EGTB",
        "airportName": "Wycombe Air Park",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "High Wycombe",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "51.61169815",
        "longitude": "-0.80833298",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2411",
                "runway_width": "75",
                "runway_material": "Asphalt"
            },
            {
                "runway_name": "06G/24G",
                "runway_length": "2001",
                "runway_width": "75",
                "runway_material": "Grass"
            },
            {
                "runway_name": "35/",
                "runway_length": "2280",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3816,
            "s_seats": 2577,
            "j_seats": 1747,
            "f_seats": 246,
            "r_seats": 70,
            "cargo_demand": 29071
        }
    },
    {
        "iata": "HYD",
        "icao": "VOHS",
        "airportName": "Rajiv Gandhi International Airport",
        "location_country": "India",
        "location_city": "Telangana",
        "location_state": "Hyderabad",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "17.231318",
        "longitude": "78.429855",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "12162",
                "runway_width": "148",
                "runway_material": "PAVED"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "13976",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 216826,
            "s_seats": 90433,
            "j_seats": 124395,
            "f_seats": 49931,
            "r_seats": 10000,
            "cargo_demand": 17341101
        }
    },
    {
        "iata": "HYR",
        "icao": "KHYR",
        "airportName": "Sawyer County Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Hayward",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.02519989",
        "longitude": "-91.44429779",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5003",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "1240",
                "runway_width": "120",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 178324,
            "s_seats": 27395,
            "j_seats": 12717,
            "f_seats": 13479,
            "r_seats": 5738,
            "cargo_demand": 154190
        }
    },
    {
        "iata": "HYS",
        "icao": "KHYS",
        "airportName": "Hays Regional Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Hays",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.84220123",
        "longitude": "-99.27320099",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4500",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184725,
            "s_seats": 48970,
            "j_seats": 26625,
            "f_seats": 5549,
            "r_seats": 6843,
            "cargo_demand": 261320
        }
    },
    {
        "iata": "HYV",
        "icao": "EFHV",
        "airportName": "HyvinkAA Airfield",
        "location_country": "Finland",
        "location_city": "HyvinkAA",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "60.6544",
        "longitude": "24.8811",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4134",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2592",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2612,
            "s_seats": 2441,
            "j_seats": 1245,
            "f_seats": 193,
            "r_seats": 20,
            "cargo_demand": 55395
        }
    },
    {
        "iata": "HZB",
        "icao": "LFQT",
        "airportName": "Merville-Calonne Airport",
        "location_country": "France",
        "location_city": "Nord-Pas-de-Calais",
        "location_state": "Hazebrouck",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.61840057",
        "longitude": "2.642240047",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6037",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108712,
            "s_seats": 49863,
            "j_seats": 41671,
            "f_seats": 9894,
            "r_seats": 4022,
            "cargo_demand": 470722
        }
    },
    {
        "iata": "HZK",
        "icao": "BIHU",
        "airportName": "Husavik Airport",
        "location_country": "Iceland",
        "location_city": "Husavik",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "65.952301",
        "longitude": "-17.426001",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5266",
                "runway_width": "157",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 67078,
            "s_seats": 44589,
            "j_seats": 10348,
            "f_seats": 5802,
            "r_seats": 9573,
            "cargo_demand": 410735
        }
    },
    {
        "iata": "HZL",
        "icao": "KHZL",
        "airportName": "Hazleton Municipal Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Hazleton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.98680115",
        "longitude": "-75.99490356",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4898",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1596,
            "s_seats": 2864,
            "j_seats": 1064,
            "f_seats": 26,
            "r_seats": 117,
            "cargo_demand": 5928
        }
    },
    {
        "iata": "HZP",
        "icao": "CYNR",
        "airportName": "Fort MacKay/Horizon Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Fort McKay",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "57.38169861",
        "longitude": "-111.7009964",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6000",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2535,
            "s_seats": 1912,
            "j_seats": 1702,
            "f_seats": 500,
            "r_seats": 83,
            "cargo_demand": 8491
        }
    },
    {
        "iata": "IAB",
        "icao": "KIAB",
        "airportName": "McConnell Air Force Base",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Wichita",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.62189865",
        "longitude": "-97.26820374",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "12000",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "12000",
                "runway_width": "300",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 67629,
            "s_seats": 27607,
            "j_seats": 58226,
            "f_seats": 15237,
            "r_seats": 7365,
            "cargo_demand": 234056
        }
    },
    {
        "iata": "IAD",
        "icao": "KIAD",
        "airportName": "Washington Dulles International Airport",
        "location_country": "United States",
        "location_city": "D.C.",
        "location_state": "Washington",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "38.9445",
        "longitude": "-77.455803",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01C/19C",
                "runway_length": "11501",
                "runway_width": "150",
                "runway_material": "CONC-G"
            },
            {
                "runway_name": "01L/19R",
                "runway_length": "11501",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "11500",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "10501",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 255681,
            "s_seats": 72255,
            "j_seats": 27493,
            "f_seats": 38549,
            "r_seats": 10000,
            "cargo_demand": 14790462
        }
    },
    {
        "iata": "IAG",
        "icao": "KIAG",
        "airportName": "Niagara Falls International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Niagara Falls",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.1073",
        "longitude": "-78.946198",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5189",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "9829",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "3973",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55360,
            "s_seats": 49323,
            "j_seats": 44503,
            "f_seats": 13808,
            "r_seats": 7380,
            "cargo_demand": 338375
        }
    },
    {
        "iata": "IAH",
        "icao": "KIAH",
        "airportName": "George Bush Intercontinental Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Houston",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "29.9843998",
        "longitude": "-95.34140015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "9402",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "09/27",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15L/33R",
                "runway_length": "12001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "9999",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 269419,
            "s_seats": 94477,
            "j_seats": 32601,
            "f_seats": 34106,
            "r_seats": 10000,
            "cargo_demand": 26650719
        }
    },
    {
        "iata": "IAM",
        "icao": "DAUZ",
        "airportName": "In Amenas Airport",
        "location_country": "Algeria",
        "location_city": "In Amenas",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.0515",
        "longitude": "9.64291",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "7218",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82047,
            "s_seats": 49018,
            "j_seats": 37798,
            "f_seats": 1018,
            "r_seats": 4698,
            "cargo_demand": 125586
        }
    },
    {
        "iata": "IAN",
        "icao": "PAIK",
        "airportName": "Bob Baker Memorial Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kiana",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "66.97599792",
        "longitude": "-160.4369965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3400",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 133463,
            "s_seats": 27961,
            "j_seats": 42912,
            "f_seats": 17952,
            "r_seats": 8894,
            "cargo_demand": 95130
        }
    },
    {
        "iata": "IAO",
        "icao": "RPNS",
        "airportName": "Sayak Airport",
        "location_country": "Philippines",
        "location_city": "Del Carmen",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "9.8591",
        "longitude": "126.014",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4167",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3636,
            "s_seats": 2871,
            "j_seats": 1948,
            "f_seats": 46,
            "r_seats": 93,
            "cargo_demand": 61486
        }
    },
    {
        "iata": "IAQ",
        "icao": "OIBH",
        "airportName": "Bahregan Airport",
        "location_country": "Iran",
        "location_city": "Bahregan",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "small_airport",
        "latitude": "27.21269989",
        "longitude": "54.3185997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2705,
            "s_seats": 1809,
            "j_seats": 1488,
            "f_seats": 329,
            "r_seats": 42,
            "cargo_demand": 63560
        }
    },
    {
        "iata": "IAR",
        "icao": "UUDL",
        "airportName": "Tunoshna Airport",
        "location_country": "Russia",
        "location_city": "Yaroslavl Oblast",
        "location_state": "Yaroslavl",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "57.56069946",
        "longitude": "40.15739822",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9870",
                "runway_width": "135",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1567,
            "s_seats": 2846,
            "j_seats": 1125,
            "f_seats": 240,
            "r_seats": 12,
            "cargo_demand": 67071
        }
    },
    {
        "iata": "IAS",
        "icao": "LRIA",
        "airportName": "Ia\u0219i International Airport",
        "location_country": "Romania",
        "location_city": "Ia\u0219i",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.17850113",
        "longitude": "27.62059975",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7874",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 94975,
            "s_seats": 47363,
            "j_seats": 21758,
            "f_seats": 3397,
            "r_seats": 3176,
            "cargo_demand": 248233
        }
    },
    {
        "iata": "IBA",
        "icao": "DNIB",
        "airportName": "Ibadan Airport",
        "location_country": "Nigeria",
        "location_city": "Ibadan",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.362460136",
        "longitude": "3.978329897",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7875",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117955,
            "s_seats": 33492,
            "j_seats": 58312,
            "f_seats": 5990,
            "r_seats": 3569,
            "cargo_demand": 285595
        }
    },
    {
        "iata": "IBB",
        "icao": "SEII",
        "airportName": "General Villamil Airport",
        "location_country": "Ecuador",
        "location_city": "Galapagos Islands",
        "location_state": "Isabela Island",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.942628026",
        "longitude": "-90.95300293",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2267,
            "s_seats": 1620,
            "j_seats": 1277,
            "f_seats": 157,
            "r_seats": 15,
            "cargo_demand": 60010
        }
    },
    {
        "iata": "IBE",
        "icao": "SKIB",
        "airportName": "Perales Airport",
        "location_country": "Colombia",
        "location_city": "Ibague",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.42161",
        "longitude": "-75.1333",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130466,
            "s_seats": 44249,
            "j_seats": 59488,
            "f_seats": 18165,
            "r_seats": 3737,
            "cargo_demand": 323344
        }
    },
    {
        "iata": "IBP",
        "icao": "SPBR",
        "airportName": "Iberia Airport",
        "location_country": "Peru",
        "location_city": "Iberia",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.41160011",
        "longitude": "-69.48870087",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4429",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62794,
            "s_seats": 25835,
            "j_seats": 58192,
            "f_seats": 6486,
            "r_seats": 3620,
            "cargo_demand": 359153
        }
    },
    {
        "iata": "IBR",
        "icao": "RJAH",
        "airportName": "Ibaraki Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Omitama",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.181099",
        "longitude": "140.414993",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8860",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 101466,
            "s_seats": 47514,
            "j_seats": 25613,
            "f_seats": 9084,
            "r_seats": 3600,
            "cargo_demand": 279808
        }
    },
    {
        "iata": "IBZ",
        "icao": "LEIB",
        "airportName": "Ibiza Airport",
        "location_country": "Spain",
        "location_city": "Balearic Islands",
        "location_state": "Ibiza",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "38.872898",
        "longitude": "1.37312",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 446376,
            "s_seats": 76240,
            "j_seats": 161319,
            "f_seats": 32256,
            "r_seats": 10000,
            "cargo_demand": 27013996
        }
    },
    {
        "iata": "ICC",
        "icao": "SVIE",
        "airportName": "Andres Miguel Salazar Marcano Airport",
        "location_country": "Venezuela",
        "location_city": "Coche Island",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "10.794432",
        "longitude": "-63.98159",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2270,
            "s_seats": 1862,
            "j_seats": 1277,
            "f_seats": 171,
            "r_seats": 133,
            "cargo_demand": 9669
        }
    },
    {
        "iata": "ICL",
        "icao": "KICL",
        "airportName": "Schenck Field",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Clarinda",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.72180176",
        "longitude": "-95.02639771",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2425",
                "runway_width": "255",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3190,
            "s_seats": 2832,
            "j_seats": 1364,
            "f_seats": 87,
            "r_seats": 138,
            "cargo_demand": 59812
        }
    },
    {
        "iata": "ICN",
        "icao": "RKSI",
        "airportName": "Incheon International Airport",
        "location_country": "South Korea",
        "location_city": "Seoul",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.46910095",
        "longitude": "126.4509964",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15L/33R",
                "runway_length": "12303",
                "runway_width": "197",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "12303",
                "runway_width": "197",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "12303",
                "runway_width": "197",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 217920,
            "s_seats": 73754,
            "j_seats": 62261,
            "f_seats": 57749,
            "r_seats": 10000,
            "cargo_demand": 8056400
        }
    },
    {
        "iata": "ICT",
        "icao": "KICT",
        "airportName": "Wichita Dwight D. Eisenhower National Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Wichita",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.649899",
        "longitude": "-97.433098",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "10301",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "7301",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6301",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 167310,
            "s_seats": 26339,
            "j_seats": 18677,
            "f_seats": 5859,
            "r_seats": 7783,
            "cargo_demand": 460403
        }
    },
    {
        "iata": "IDA",
        "icao": "KIDA",
        "airportName": "Idaho Falls Regional Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Idaho Falls",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.514599",
        "longitude": "-112.070999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4051",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189928,
            "s_seats": 36660,
            "j_seats": 41184,
            "f_seats": 2058,
            "r_seats": 6756,
            "cargo_demand": 290824
        }
    },
    {
        "iata": "IDG",
        "icao": "KIDG",
        "airportName": "Ida Grove Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Ida Grove",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.33259964",
        "longitude": "-95.44490051",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3172",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2207,
            "s_seats": 2876,
            "j_seats": 1959,
            "f_seats": 291,
            "r_seats": 135,
            "cargo_demand": 582
        }
    },
    {
        "iata": "IDI",
        "icao": "KIDI",
        "airportName": "Indiana County-Jimmy Stewart Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Indiana",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.63219833",
        "longitude": "-79.10549927",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2309,
            "s_seats": 2496,
            "j_seats": 1392,
            "f_seats": 256,
            "r_seats": 77,
            "cargo_demand": 44191
        }
    },
    {
        "iata": "IDK",
        "icao": "YIDK",
        "airportName": "Indulkana Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Indulkana",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-26.9666996",
        "longitude": "133.3249969",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3969",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3094,
            "s_seats": 1912,
            "j_seats": 1842,
            "f_seats": 47,
            "r_seats": 108,
            "cargo_demand": 49925
        }
    },
    {
        "iata": "IDO",
        "icao": "SWIY",
        "airportName": "Santa Isabel do Morro Airport",
        "location_country": "Brazil",
        "location_city": "Tocantins",
        "location_state": "Santa Isabel do Morro",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.57229996",
        "longitude": "-50.66619873",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5052",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2880,
            "s_seats": 1937,
            "j_seats": 1279,
            "f_seats": 289,
            "r_seats": 41,
            "cargo_demand": 40669
        }
    },
    {
        "iata": "IDP",
        "icao": "KIDP",
        "airportName": "Independence Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Independence",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.15840149",
        "longitude": "-95.77839661",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3402",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5501",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1775,
            "s_seats": 2838,
            "j_seats": 1409,
            "f_seats": 142,
            "r_seats": 177,
            "cargo_demand": 96740
        }
    },
    {
        "iata": "IDR",
        "icao": "VAID",
        "airportName": "Devi Ahilyabai Holkar International Airport",
        "location_country": "India",
        "location_city": "Madhya Pradesh",
        "location_state": "Indore",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.72179985",
        "longitude": "75.80110168",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7480",
                "runway_width": "148",
                "runway_material": "COM"
            }
        ],
        "demand": {
            "y_seats": 58264,
            "s_seats": 45453,
            "j_seats": 5553,
            "f_seats": 18582,
            "r_seats": 6665,
            "cargo_demand": 240773
        }
    },
    {
        "iata": "IDY",
        "icao": "LFEY",
        "airportName": "Ile d'Yeu Aerodrome",
        "location_country": "France",
        "location_city": "Pays de la Loire",
        "location_state": "Ile d'Yeu",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.71860123",
        "longitude": "-2.391109943",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "1886",
                "runway_width": "164",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4003",
                "runway_width": "82",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 99758,
            "s_seats": 43238,
            "j_seats": 34359,
            "f_seats": 1549,
            "r_seats": 6523,
            "cargo_demand": 458443
        }
    },
    {
        "iata": "IEG",
        "icao": "EPZG",
        "airportName": "Zielona Gora Airport",
        "location_country": "Poland",
        "location_city": "Zielona Gora",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "52.13850021",
        "longitude": "15.7986002",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8202",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 83113,
            "s_seats": 31794,
            "j_seats": 42922,
            "f_seats": 17919,
            "r_seats": 7347,
            "cargo_demand": 349883
        }
    },
    {
        "iata": "IEJ",
        "icao": "RORE",
        "airportName": "Iejima Airport",
        "location_country": "Japan",
        "location_city": "Okinawa",
        "location_state": "Iejima",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.722537",
        "longitude": "127.786801",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4920",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "4961",
                "runway_width": "92",
                "runway_material": "U"
            }
        ],
        "demand": {
            "y_seats": 147246,
            "s_seats": 45534,
            "j_seats": 12871,
            "f_seats": 19880,
            "r_seats": 7685,
            "cargo_demand": 494801
        }
    },
    {
        "iata": "IES",
        "icao": "EDAU",
        "airportName": "Riesa-Gohlis Airfield",
        "location_country": "Germany",
        "location_city": "Saxony",
        "location_state": "Riesa",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "51.29360962",
        "longitude": "13.35611057",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2461",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3281",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1990,
            "s_seats": 1688,
            "j_seats": 1424,
            "f_seats": 261,
            "r_seats": 39,
            "cargo_demand": 39312
        }
    },
    {
        "iata": "IEV",
        "icao": "UKKK",
        "airportName": "Kyiv International Airport",
        "location_country": "Ukraine",
        "location_city": "Kyiv",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.40194",
        "longitude": "30.45194",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7579",
                "runway_width": "148",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 72538,
            "s_seats": 45041,
            "j_seats": 12702,
            "f_seats": 15611,
            "r_seats": 6697,
            "cargo_demand": 348087
        }
    },
    {
        "iata": "IFA",
        "icao": "KIFA",
        "airportName": "Iowa Falls Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Iowa Falls",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.47079849",
        "longitude": "-93.26999664",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1697,
            "s_seats": 2502,
            "j_seats": 1642,
            "f_seats": 331,
            "r_seats": 6,
            "cargo_demand": 86646
        }
    },
    {
        "iata": "IFF",
        "icao": "YIFY",
        "airportName": "Iffley Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Iffley",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.89999962",
        "longitude": "141.2169952",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2887",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1240,
            "s_seats": 2674,
            "j_seats": 1406,
            "f_seats": 59,
            "r_seats": 53,
            "cargo_demand": 62065
        }
    },
    {
        "iata": "IFH",
        "icao": "OIFE",
        "airportName": "Hesa Air Base",
        "location_country": "Iran",
        "location_city": "Shahin Shahr",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "small_airport",
        "latitude": "32.92890167",
        "longitude": "51.56110001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9830",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "1091",
                "runway_width": "40",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2994,
            "s_seats": 1503,
            "j_seats": 1726,
            "f_seats": 146,
            "r_seats": 151,
            "cargo_demand": 72813
        }
    },
    {
        "iata": "IFJ",
        "icao": "BIIS",
        "airportName": "i\u008dsafjor\u00f0ur Airport",
        "location_country": "Iceland",
        "location_city": "i\u008dsafjor\u00f0ur",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "66.05809784",
        "longitude": "-23.13529968",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4593",
                "runway_width": "148",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 95201,
            "s_seats": 33026,
            "j_seats": 34481,
            "f_seats": 6510,
            "r_seats": 7639,
            "cargo_demand": 369257
        }
    },
    {
        "iata": "IFL",
        "icao": "YIFL",
        "airportName": "Innisfail Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Innisfail",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.55940056",
        "longitude": "146.0119934",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4409",
                "runway_width": "98",
                "runway_material": "CLA"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4439",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2896,
            "s_seats": 2062,
            "j_seats": 1524,
            "f_seats": 203,
            "r_seats": 136,
            "cargo_demand": 16355
        }
    },
    {
        "iata": "IFN",
        "icao": "OIFM",
        "airportName": "Isfahan International Airport",
        "location_country": "Iran",
        "location_city": "Isfahan",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "32.75080109",
        "longitude": "51.86130142",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "14425",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "14425",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110932,
            "s_seats": 31261,
            "j_seats": 50274,
            "f_seats": 4238,
            "r_seats": 4386,
            "cargo_demand": 79056
        }
    },
    {
        "iata": "IFO",
        "icao": "UKLI",
        "airportName": "Ivano-Frankivsk International Airport",
        "location_country": "Ukraine",
        "location_city": "Ivano-Frankivsk",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.88420105",
        "longitude": "24.68610001",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8226",
                "runway_width": "144",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 57495,
            "s_seats": 33940,
            "j_seats": 17756,
            "f_seats": 15683,
            "r_seats": 6175,
            "cargo_demand": 131055
        }
    },
    {
        "iata": "IFP",
        "icao": "KIFP",
        "airportName": "Laughlin/Bullhead International Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Bullhead City",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.15739822",
        "longitude": "-114.5599976",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7520",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1872,
            "s_seats": 2487,
            "j_seats": 1830,
            "f_seats": 237,
            "r_seats": 36,
            "cargo_demand": 12672
        }
    },
    {
        "iata": "IGA",
        "icao": "MYIG",
        "airportName": "Inagua Airport",
        "location_country": "Bahamas",
        "location_city": "Inagua Islands",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "20.97500038",
        "longitude": "-73.66690063",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7016",
                "runway_width": "89",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "7020",
                "runway_width": "100",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 198415,
            "s_seats": 42105,
            "j_seats": 44795,
            "f_seats": 19693,
            "r_seats": 9113,
            "cargo_demand": 70060
        }
    },
    {
        "iata": "IGB",
        "icao": "SAVJ",
        "airportName": "Ingeniero Jacobacci Airport",
        "location_country": "Argentina",
        "location_city": "Rio Negro",
        "location_state": "Ingeniero Jacobacci",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-41.32089996",
        "longitude": "-69.57489777",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "6890",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1665,
            "s_seats": 1546,
            "j_seats": 1222,
            "f_seats": 463,
            "r_seats": 49,
            "cargo_demand": 88617
        }
    },
    {
        "iata": "IGG",
        "icao": "PAIG",
        "airportName": "Igiugig Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Igiugig",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "59.32400131",
        "longitude": "-155.9019928",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "GRVL-F"
            }
        ],
        "demand": {
            "y_seats": 3368,
            "s_seats": 2072,
            "j_seats": 1590,
            "f_seats": 408,
            "r_seats": 172,
            "cargo_demand": 97870
        }
    },
    {
        "iata": "IGH",
        "icao": "YIGM",
        "airportName": "Ingham Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Ingham",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.66060066",
        "longitude": "146.1519928",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5006",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1465,
            "s_seats": 1991,
            "j_seats": 1190,
            "f_seats": 201,
            "r_seats": 195,
            "cargo_demand": 23529
        }
    },
    {
        "iata": "IGL",
        "icao": "LTBL",
        "airportName": "Cigli Air Base",
        "location_country": "Turkey",
        "location_city": "Izmir",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.513",
        "longitude": "27.010099",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9821",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176540,
            "s_seats": 27370,
            "j_seats": 23559,
            "f_seats": 17815,
            "r_seats": 6952,
            "cargo_demand": 475461
        }
    },
    {
        "iata": "IGM",
        "icao": "KIGM",
        "airportName": "Kingman Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Kingman",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.2594986",
        "longitude": "-113.9380035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6826",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6725",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6725",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2847,
            "s_seats": 2851,
            "j_seats": 1047,
            "f_seats": 337,
            "r_seats": 123,
            "cargo_demand": 64684
        }
    },
    {
        "iata": "IGN",
        "icao": "RPMI",
        "airportName": "Maria Cristina Airport",
        "location_country": "Philippines",
        "location_city": "Iligan",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "8.13049",
        "longitude": "124.214996",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4593",
                "runway_width": "60",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3481,
            "s_seats": 1931,
            "j_seats": 1280,
            "f_seats": 106,
            "r_seats": 148,
            "cargo_demand": 81970
        }
    },
    {
        "iata": "IGR",
        "icao": "SARI",
        "airportName": "Cataratas del Iguazu International Airport",
        "location_country": "Argentina",
        "location_city": "Misiones",
        "location_state": "Puerto Iguazu",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.737301",
        "longitude": "-54.4734",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171451,
            "s_seats": 43996,
            "j_seats": 31755,
            "f_seats": 4830,
            "r_seats": 8949,
            "cargo_demand": 428934
        }
    },
    {
        "iata": "IGS",
        "icao": "ETSI",
        "airportName": "Ingolstadt Manching Airport",
        "location_country": "Germany",
        "location_city": "Bavaria",
        "location_state": "Ingolstadt",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.71569824",
        "longitude": "11.5340004",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "8002",
                "runway_width": "98",
                "runway_material": "CON"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "9646",
                "runway_width": "197",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 127072,
            "s_seats": 27627,
            "j_seats": 55660,
            "f_seats": 1697,
            "r_seats": 9393,
            "cargo_demand": 200771
        }
    },
    {
        "iata": "IGU",
        "icao": "SBFI",
        "airportName": "Foz do IguaiCu International Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Foz do IguaiCu",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-25.60027885",
        "longitude": "-54.48500061",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7201",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78589,
            "s_seats": 31099,
            "j_seats": 6121,
            "f_seats": 3324,
            "r_seats": 6152,
            "cargo_demand": 461849
        }
    },
    {
        "iata": "IHR",
        "icao": "OIZI",
        "airportName": "Iranshahr Airport",
        "location_country": "Iran",
        "location_city": "Iranshahr",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "27.236099",
        "longitude": "60.720001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7711",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 93899,
            "s_seats": 37650,
            "j_seats": 54556,
            "f_seats": 14858,
            "r_seats": 8887,
            "cargo_demand": 112623
        }
    },
    {
        "iata": "IIA",
        "icao": "EIMN",
        "airportName": "Inishmaan Aerodrome",
        "location_country": "Ireland",
        "location_city": "Inishmaan",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "53.0929985",
        "longitude": "-9.568059921",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "869",
                "runway_width": "65",
                "runway_material": ""
            },
            {
                "runway_name": "15/33",
                "runway_length": "1791",
                "runway_width": "65",
                "runway_material": "Bituminous"
            }
        ],
        "demand": {
            "y_seats": 1969,
            "s_seats": 2025,
            "j_seats": 1889,
            "f_seats": 495,
            "r_seats": 50,
            "cargo_demand": 9071
        }
    },
    {
        "iata": "IIL",
        "icao": "OICI",
        "airportName": "Ilam Airport",
        "location_country": "Iran",
        "location_city": "Ilam",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "33.58660126",
        "longitude": "46.40480042",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9183",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90723,
            "s_seats": 38521,
            "j_seats": 14745,
            "f_seats": 15032,
            "r_seats": 7826,
            "cargo_demand": 251767
        }
    },
    {
        "iata": "IJK",
        "icao": "USII",
        "airportName": "Izhevsk Airport",
        "location_country": "Russia",
        "location_city": "Udmurtia",
        "location_state": "Izhevsk",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.8280983",
        "longitude": "53.45750046",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8202",
                "runway_width": "142",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 190385,
            "s_seats": 47554,
            "j_seats": 24934,
            "f_seats": 3228,
            "r_seats": 5016,
            "cargo_demand": 201489
        }
    },
    {
        "iata": "IJU",
        "icao": "SSIJ",
        "airportName": "Joio Batista Bos Filho Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Ijui",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-28.36870003",
        "longitude": "-53.84659958",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3642",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3553,
            "s_seats": 2241,
            "j_seats": 1831,
            "f_seats": 144,
            "r_seats": 127,
            "cargo_demand": 14118
        }
    },
    {
        "iata": "IJX",
        "icao": "KIJX",
        "airportName": "Jacksonville Municipal Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Jacksonville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.77460098",
        "longitude": "-90.23829651",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1477,
            "s_seats": 2358,
            "j_seats": 1424,
            "f_seats": 151,
            "r_seats": 182,
            "cargo_demand": 38556
        }
    },
    {
        "iata": "IKA",
        "icao": "OIIE",
        "airportName": "Tehran Imam Khomeini International Airport",
        "location_country": "Iran",
        "location_city": "Tehran",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "large_airport",
        "latitude": "35.41609955",
        "longitude": "51.15219879",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11L/29R",
                "runway_length": "13772",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "13940",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142337,
            "s_seats": 80932,
            "j_seats": 21881,
            "f_seats": 59923,
            "r_seats": 10000,
            "cargo_demand": 29445768
        }
    },
    {
        "iata": "IKB",
        "icao": "KUKF",
        "airportName": "Wilkes County Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "North Wilkesboro",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.22280121",
        "longitude": "-81.09829712",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6200",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2815,
            "s_seats": 2319,
            "j_seats": 1485,
            "f_seats": 190,
            "r_seats": 182,
            "cargo_demand": 22220
        }
    },
    {
        "iata": "IKI",
        "icao": "RJDB",
        "airportName": "Iki Airport",
        "location_country": "Japan",
        "location_city": "Iki Island",
        "location_state": "Iki",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.74900055",
        "longitude": "129.7850037",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4331",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147934,
            "s_seats": 28694,
            "j_seats": 9954,
            "f_seats": 12241,
            "r_seats": 3572,
            "cargo_demand": 475931
        }
    },
    {
        "iata": "IKK",
        "icao": "KIKK",
        "airportName": "Greater Kankakee Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Kankakee",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.07139969",
        "longitude": "-87.84629822",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5979",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4399",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167631,
            "s_seats": 40569,
            "j_seats": 50147,
            "f_seats": 9033,
            "r_seats": 6524,
            "cargo_demand": 464919
        }
    },
    {
        "iata": "IKO",
        "icao": "PAKO",
        "airportName": "Nikolski Air Station",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Nikolski",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "52.9416008",
        "longitude": "-168.848999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3512",
                "runway_width": "135",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 1425,
            "s_seats": 1956,
            "j_seats": 1310,
            "f_seats": 228,
            "r_seats": 150,
            "cargo_demand": 31171
        }
    },
    {
        "iata": "IKP",
        "icao": "YIKM",
        "airportName": "Inkerman Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Inkerman",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.27499962",
        "longitude": "141.4420013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3018",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 2425,
            "s_seats": 1892,
            "j_seats": 1693,
            "f_seats": 356,
            "r_seats": 161,
            "cargo_demand": 13401
        }
    },
    {
        "iata": "IKS",
        "icao": "UEST",
        "airportName": "Tiksi Airport",
        "location_country": "Russia",
        "location_city": "Yakutia",
        "location_state": "Tiksi",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "71.6977005",
        "longitude": "128.9029999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9845",
                "runway_width": "194",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 116193,
            "s_seats": 28764,
            "j_seats": 40437,
            "f_seats": 3253,
            "r_seats": 5614,
            "cargo_demand": 372769
        }
    },
    {
        "iata": "IKT",
        "icao": "UIII",
        "airportName": "International Airport Irkutsk",
        "location_country": "Russia",
        "location_city": "Irkutsk Oblast",
        "location_state": "Irkutsk",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.26800156",
        "longitude": "104.3889999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "11696",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 60171,
            "s_seats": 29337,
            "j_seats": 41404,
            "f_seats": 10349,
            "r_seats": 9037,
            "cargo_demand": 487789
        }
    },
    {
        "iata": "ILA",
        "icao": "WABL",
        "airportName": "Illaga Airport",
        "location_country": "Indonesia",
        "location_city": "Illaga",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.97648",
        "longitude": "137.6225",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "1968",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2288,
            "s_seats": 1936,
            "j_seats": 1169,
            "f_seats": 296,
            "r_seats": 123,
            "cargo_demand": 70042
        }
    },
    {
        "iata": "ILD",
        "icao": "LEDA",
        "airportName": "Lleida-Alguaire Airport",
        "location_country": "Spain",
        "location_city": "Catalonia",
        "location_state": "Lleida",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "41.728185",
        "longitude": "0.535023",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8202",
                "runway_width": "200",
                "runway_material": "hard"
            }
        ],
        "demand": {
            "y_seats": 104746,
            "s_seats": 46024,
            "j_seats": 5080,
            "f_seats": 9891,
            "r_seats": 5949,
            "cargo_demand": 349674
        }
    },
    {
        "iata": "ILE",
        "icao": "KILE",
        "airportName": "Skylark Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Killeen",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.08580017",
        "longitude": "-97.68650055",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5495",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2997,
            "s_seats": 2887,
            "j_seats": 1220,
            "f_seats": 55,
            "r_seats": 4,
            "cargo_demand": 80197
        }
    },
    {
        "iata": "ILF",
        "icao": "CZBD",
        "airportName": "Ilford Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Ilford",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "56.05163",
        "longitude": "-95.620523",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 58167,
            "s_seats": 27153,
            "j_seats": 50302,
            "f_seats": 6825,
            "r_seats": 4995,
            "cargo_demand": 358220
        }
    },
    {
        "iata": "ILG",
        "icao": "KILG",
        "airportName": "Wilmington Airport",
        "location_country": "United States",
        "location_city": "Delaware",
        "location_state": "Wilmington",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.67869949",
        "longitude": "-75.60649872",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7012",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "7181",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4603",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120296,
            "s_seats": 38804,
            "j_seats": 41948,
            "f_seats": 8960,
            "r_seats": 6818,
            "cargo_demand": 154338
        }
    },
    {
        "iata": "ILI",
        "icao": "PAIL",
        "airportName": "Iliamna Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Iliamna",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "59.75439835",
        "longitude": "-154.9109955",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5087",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4800",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "E/W",
                "runway_length": "2998",
                "runway_width": "400",
                "runway_material": "WATER-E"
            },
            {
                "runway_name": "N/S",
                "runway_length": "2892",
                "runway_width": "400",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 65111,
            "s_seats": 27570,
            "j_seats": 39726,
            "f_seats": 8811,
            "r_seats": 8874,
            "cargo_demand": 181330
        }
    },
    {
        "iata": "ILM",
        "icao": "KILM",
        "airportName": "Wilmington International Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Wilmington",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.27059937",
        "longitude": "-77.90260315",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8016",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7004",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109742,
            "s_seats": 31425,
            "j_seats": 31296,
            "f_seats": 8018,
            "r_seats": 5740,
            "cargo_demand": 491073
        }
    },
    {
        "iata": "ILN",
        "icao": "KILN",
        "airportName": "Wilmington Air Park",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Wilmington",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.42789841",
        "longitude": "-83.792099",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "10701",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 177182,
            "s_seats": 36304,
            "j_seats": 18581,
            "f_seats": 17093,
            "r_seats": 4991,
            "cargo_demand": 401615
        }
    },
    {
        "iata": "ILO",
        "icao": "RPVI",
        "airportName": "Iloilo International Airport",
        "location_country": "Philippines",
        "location_city": "Iloilo",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.833017",
        "longitude": "122.493358",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155427,
            "s_seats": 47404,
            "j_seats": 21064,
            "f_seats": 10835,
            "r_seats": 5140,
            "cargo_demand": 322704
        }
    },
    {
        "iata": "ILP",
        "icao": "NWWE",
        "airportName": "Ile des Pins Airport",
        "location_country": "New Caledonia",
        "location_city": "Ile des Pins",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.58889961",
        "longitude": "167.4559937",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3608",
                "runway_width": "76",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139642,
            "s_seats": 42526,
            "j_seats": 13272,
            "f_seats": 6072,
            "r_seats": 4147,
            "cargo_demand": 451089
        }
    },
    {
        "iata": "ILQ",
        "icao": "SPLO",
        "airportName": "Ilo Airport",
        "location_country": "Peru",
        "location_city": "Ilo",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.69499969",
        "longitude": "-71.34400177",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136231,
            "s_seats": 30078,
            "j_seats": 21506,
            "f_seats": 2119,
            "r_seats": 7132,
            "cargo_demand": 294517
        }
    },
    {
        "iata": "ILR",
        "icao": "DNIL",
        "airportName": "Ilorin International Airport",
        "location_country": "Nigeria",
        "location_city": "Ilorin",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.440210342",
        "longitude": "4.493919849",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10169",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155991,
            "s_seats": 26393,
            "j_seats": 16921,
            "f_seats": 11342,
            "r_seats": 3995,
            "cargo_demand": 220806
        }
    },
    {
        "iata": "ILS",
        "icao": "MSSS",
        "airportName": "Ilopango International Airport",
        "location_country": "El Salvador",
        "location_city": "San Salvador",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.69950008",
        "longitude": "-89.11990356",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7349",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188463,
            "s_seats": 44733,
            "j_seats": 17367,
            "f_seats": 8846,
            "r_seats": 3246,
            "cargo_demand": 383710
        }
    },
    {
        "iata": "ILY",
        "icao": "EGPI",
        "airportName": "Islay Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Islay",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "55.68190002",
        "longitude": "-6.256669998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2083",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5069",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105397,
            "s_seats": 41780,
            "j_seats": 37418,
            "f_seats": 18086,
            "r_seats": 7091,
            "cargo_demand": 186416
        }
    },
    {
        "iata": "ILZ",
        "icao": "LZZI",
        "airportName": "\u017dilina Airport",
        "location_country": "Slovakia",
        "location_city": "\u017dilina",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.23149872",
        "longitude": "18.6135006",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3773",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 141063,
            "s_seats": 40777,
            "j_seats": 10249,
            "f_seats": 11528,
            "r_seats": 7047,
            "cargo_demand": 142200
        }
    },
    {
        "iata": "IMF",
        "icao": "VEIM",
        "airportName": "Imphal International Airport",
        "location_country": "India",
        "location_city": "Manipur",
        "location_state": "Imphal",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.76000023",
        "longitude": "93.896698",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9009",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117986,
            "s_seats": 30495,
            "j_seats": 40333,
            "f_seats": 7999,
            "r_seats": 6875,
            "cargo_demand": 305833
        }
    },
    {
        "iata": "IML",
        "icao": "KIML",
        "airportName": "Imperial Municipal Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Imperial",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.50930023",
        "longitude": "-101.6210022",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3058",
                "runway_width": "300",
                "runway_material": "GRE"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5022",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3868,
            "s_seats": 2535,
            "j_seats": 1265,
            "f_seats": 433,
            "r_seats": 186,
            "cargo_demand": 69819
        }
    },
    {
        "iata": "IMM",
        "icao": "KIMM",
        "airportName": "Immokalee Regional Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Immokalee",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "26.43320084",
        "longitude": "-81.40100098",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3274,
            "s_seats": 2239,
            "j_seats": 1986,
            "f_seats": 347,
            "r_seats": 168,
            "cargo_demand": 31850
        }
    },
    {
        "iata": "IMN",
        "icao": "AYII",
        "airportName": "Imane Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Imane",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.7524",
        "longitude": "146.1072",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "1640",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1500,
            "s_seats": 2745,
            "j_seats": 1595,
            "f_seats": 111,
            "r_seats": 183,
            "cargo_demand": 37231
        }
    },
    {
        "iata": "IMP",
        "icao": "SBIZ",
        "airportName": "Imperatriz Airport",
        "location_country": "Brazil",
        "location_city": "Maranhio",
        "location_state": "Imperatriz",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.53129",
        "longitude": "-47.459999",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "5899",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117337,
            "s_seats": 44617,
            "j_seats": 44233,
            "f_seats": 1697,
            "r_seats": 8736,
            "cargo_demand": 64692
        }
    },
    {
        "iata": "IMT",
        "icao": "KIMT",
        "airportName": "Ford Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Iron Mountain / Kingsford",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.81840134",
        "longitude": "-88.11450195",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3812",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1199,
            "s_seats": 1876,
            "j_seats": 1815,
            "f_seats": 328,
            "r_seats": 14,
            "cargo_demand": 33587
        }
    },
    {
        "iata": "IND",
        "icao": "KIND",
        "airportName": "Indianapolis International Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Indianapolis",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "39.7173",
        "longitude": "-86.294403",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "11200",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "7605",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 329550,
            "s_seats": 99260,
            "j_seats": 135943,
            "f_seats": 50754,
            "r_seats": 10000,
            "cargo_demand": 3075777
        }
    },
    {
        "iata": "INH",
        "icao": "FQIN",
        "airportName": "Inhambane Airport",
        "location_country": "Mozambique",
        "location_city": "Inhambane",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.8764",
        "longitude": "35.408501",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2625",
                "runway_width": "98",
                "runway_material": "SAN"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165558,
            "s_seats": 32276,
            "j_seats": 49795,
            "f_seats": 15001,
            "r_seats": 9749,
            "cargo_demand": 413751
        }
    },
    {
        "iata": "INI",
        "icao": "LYNI",
        "airportName": "Nio\u00a1 Constantine the Great Airport",
        "location_country": "Serbia",
        "location_city": "Nio\u00a1",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.337299",
        "longitude": "21.853701",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172815,
            "s_seats": 49745,
            "j_seats": 18415,
            "f_seats": 18909,
            "r_seats": 4278,
            "cargo_demand": 174063
        }
    },
    {
        "iata": "INJ",
        "icao": "YINJ",
        "airportName": "Injune Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Injune",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.851",
        "longitude": "148.5497",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3956",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3254,
            "s_seats": 1573,
            "j_seats": 1135,
            "f_seats": 113,
            "r_seats": 158,
            "cargo_demand": 85516
        }
    },
    {
        "iata": "INK",
        "icao": "KINK",
        "airportName": "Winkler County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Wink",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.77960014",
        "longitude": "-103.2009964",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3515",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129509,
            "s_seats": 47419,
            "j_seats": 51109,
            "f_seats": 10991,
            "r_seats": 6493,
            "cargo_demand": 417511
        }
    },
    {
        "iata": "INL",
        "icao": "KINL",
        "airportName": "Falls International Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "International Falls",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "48.56620026",
        "longitude": "-93.40309906",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2999",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6508",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191671,
            "s_seats": 38538,
            "j_seats": 34403,
            "f_seats": 7933,
            "r_seats": 9618,
            "cargo_demand": 266734
        }
    },
    {
        "iata": "INM",
        "icao": "YINN",
        "airportName": "Innamincka Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Innamincka",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-27.70000076",
        "longitude": "140.7330017",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4071",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1041,
            "s_seats": 2578,
            "j_seats": 1090,
            "f_seats": 160,
            "r_seats": 154,
            "cargo_demand": 40681
        }
    },
    {
        "iata": "INN",
        "icao": "LOWI",
        "airportName": "Innsbruck Airport",
        "location_country": "Austria",
        "location_city": "Innsbruck",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.260201",
        "longitude": "11.344",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152417,
            "s_seats": 47792,
            "j_seats": 26504,
            "f_seats": 17722,
            "r_seats": 8235,
            "cargo_demand": 223642
        }
    },
    {
        "iata": "INQ",
        "icao": "EIIR",
        "airportName": "Inisheer Aerodrome",
        "location_country": "Ireland",
        "location_city": "Inisheer",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "53.064701",
        "longitude": "-9.5109",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "1706",
                "runway_width": "59",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1708,
            "s_seats": 1634,
            "j_seats": 1403,
            "f_seats": 426,
            "r_seats": 147,
            "cargo_demand": 7213
        }
    },
    {
        "iata": "INS",
        "icao": "KINS",
        "airportName": "Creech Air Force Base",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Indian Springs",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.58720016",
        "longitude": "-115.6729965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9002",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5002",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1810,
            "s_seats": 1656,
            "j_seats": 1557,
            "f_seats": 438,
            "r_seats": 60,
            "cargo_demand": 76347
        }
    },
    {
        "iata": "INT",
        "icao": "KINT",
        "airportName": "Smith Reynolds Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Winston-Salem",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.13370132",
        "longitude": "-80.22200012",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3938",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6655",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143171,
            "s_seats": 34532,
            "j_seats": 45009,
            "f_seats": 5591,
            "r_seats": 6829,
            "cargo_demand": 408043
        }
    },
    {
        "iata": "INU",
        "icao": "ANYN",
        "airportName": "Nauru International Airport",
        "location_country": "Nauru",
        "location_city": "Yaren",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.547458",
        "longitude": "166.919006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7054",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111968,
            "s_seats": 39478,
            "j_seats": 47147,
            "f_seats": 12227,
            "r_seats": 4721,
            "cargo_demand": 454864
        }
    },
    {
        "iata": "INV",
        "icao": "EGPE",
        "airportName": "Inverness Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Inverness",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "57.54249954",
        "longitude": "-4.047500134",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6191",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2297",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148638,
            "s_seats": 35831,
            "j_seats": 58891,
            "f_seats": 12388,
            "r_seats": 3887,
            "cargo_demand": 371424
        }
    },
    {
        "iata": "INW",
        "icao": "KINW",
        "airportName": "Winslow-Lindbergh Regional Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Winslow",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.02190018",
        "longitude": "-110.7229996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7499",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "7100",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6068",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146383,
            "s_seats": 29836,
            "j_seats": 8388,
            "f_seats": 16474,
            "r_seats": 8801,
            "cargo_demand": 209967
        }
    },
    {
        "iata": "INX",
        "icao": "WASI",
        "airportName": "Inanwatan Airport",
        "location_country": "Indonesia",
        "location_city": "Inanwatan",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.128099918",
        "longitude": "132.1609955",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "1969",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2784,
            "s_seats": 2750,
            "j_seats": 1443,
            "f_seats": 350,
            "r_seats": 108,
            "cargo_demand": 65827
        }
    },
    {
        "iata": "INZ",
        "icao": "DAUI",
        "airportName": "In Salah Airport",
        "location_country": "Algeria",
        "location_city": "In Salah",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.25099945",
        "longitude": "2.512020111",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103655,
            "s_seats": 48954,
            "j_seats": 8632,
            "f_seats": 10206,
            "r_seats": 6013,
            "cargo_demand": 47744
        }
    },
    {
        "iata": "IOA",
        "icao": "LGIO",
        "airportName": "Ioannina National Airport",
        "location_country": "Greece",
        "location_city": "Ioannina",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "39.69639969",
        "longitude": "20.82250023",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103679,
            "s_seats": 34652,
            "j_seats": 42412,
            "f_seats": 18484,
            "r_seats": 8513,
            "cargo_demand": 224480
        }
    },
    {
        "iata": "IOM",
        "icao": "EGNS",
        "airportName": "Isle of Man Airport",
        "location_country": "United Kingdom",
        "location_city": "Isle of Man",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "54.08330154",
        "longitude": "-4.623889923",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3934",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "5751",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2963",
                "runway_width": "89",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172393,
            "s_seats": 27801,
            "j_seats": 10752,
            "f_seats": 12694,
            "r_seats": 6775,
            "cargo_demand": 134399
        }
    },
    {
        "iata": "IOR",
        "icao": "EIIM",
        "airportName": "Inishmore Aerodrome",
        "location_country": "Ireland",
        "location_city": "Inishmore",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "53.1067009",
        "longitude": "-9.653610229",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "520",
                "runway_width": "18",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3127,
            "s_seats": 2233,
            "j_seats": 1711,
            "f_seats": 123,
            "r_seats": 1,
            "cargo_demand": 93028
        }
    },
    {
        "iata": "IOS",
        "icao": "SBIL",
        "airportName": "Ilheus Jorge Amado Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Ilheus",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.81599998",
        "longitude": "-39.03319931",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5174",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163897,
            "s_seats": 37658,
            "j_seats": 45514,
            "f_seats": 19021,
            "r_seats": 7961,
            "cargo_demand": 350725
        }
    },
    {
        "iata": "IOW",
        "icao": "KIOW",
        "airportName": "Iowa City Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Iowa City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.6391983",
        "longitude": "-91.54650116",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4355",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3900",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1824,
            "s_seats": 2098,
            "j_seats": 1243,
            "f_seats": 162,
            "r_seats": 5,
            "cargo_demand": 4629
        }
    },
    {
        "iata": "IPA",
        "icao": "NVVI",
        "airportName": "Ipota Airport",
        "location_country": "Vanuatu",
        "location_city": "Tafea",
        "location_state": "Erromango",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.856389",
        "longitude": "169.283333",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2995",
                "runway_width": "98",
                "runway_material": "Grass on coral"
            }
        ],
        "demand": {
            "y_seats": 3128,
            "s_seats": 2925,
            "j_seats": 1068,
            "f_seats": 349,
            "r_seats": 36,
            "cargo_demand": 81753
        }
    },
    {
        "iata": "IPC",
        "icao": "SCIP",
        "airportName": "Mataveri International Airport",
        "location_country": "Chile",
        "location_city": "Easter Island",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-27.16480064",
        "longitude": "-109.4219971",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180979,
            "s_seats": 41354,
            "j_seats": 42232,
            "f_seats": 6093,
            "r_seats": 8601,
            "cargo_demand": 128288
        }
    },
    {
        "iata": "IPG",
        "icao": "SWII",
        "airportName": "Ipiranga Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Santo Antonio do IiCa",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.939069986",
        "longitude": "-69.69400024",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "3937",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3774,
            "s_seats": 1799,
            "j_seats": 1708,
            "f_seats": 196,
            "r_seats": 163,
            "cargo_demand": 17468
        }
    },
    {
        "iata": "IPH",
        "icao": "WMKI",
        "airportName": "Sultan Azlan Shah Airport",
        "location_country": "Malaysia",
        "location_city": "Perak",
        "location_state": "Ipoh",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.567969799",
        "longitude": "101.0920029",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5900",
                "runway_width": "121",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171035,
            "s_seats": 35292,
            "j_seats": 9659,
            "f_seats": 5269,
            "r_seats": 6626,
            "cargo_demand": 410703
        }
    },
    {
        "iata": "IPI",
        "icao": "SKIP",
        "airportName": "San Luis Airport",
        "location_country": "Colombia",
        "location_city": "Ipiales",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.861925",
        "longitude": "-77.6718",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5774",
                "runway_width": "92",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88602,
            "s_seats": 41107,
            "j_seats": 55219,
            "f_seats": 10493,
            "r_seats": 9638,
            "cargo_demand": 107975
        }
    },
    {
        "iata": "IPL",
        "icao": "KIPL",
        "airportName": "Imperial County Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Imperial / El Centro",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.83420181",
        "longitude": "-115.5790024",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4500",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5304",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153814,
            "s_seats": 47848,
            "j_seats": 16174,
            "f_seats": 14019,
            "r_seats": 7414,
            "cargo_demand": 494113
        }
    },
    {
        "iata": "IPN",
        "icao": "SBIP",
        "airportName": "Usiminas Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Ipatinga",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-19.47069931",
        "longitude": "-42.48759842",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "6575",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135897,
            "s_seats": 28685,
            "j_seats": 18379,
            "f_seats": 18267,
            "r_seats": 7497,
            "cargo_demand": 295530
        }
    },
    {
        "iata": "IPT",
        "icao": "KIPT",
        "airportName": "Williamsport Regional Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Williamsport",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.2417984",
        "longitude": "-76.9210968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6474",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4280",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79342,
            "s_seats": 40563,
            "j_seats": 45222,
            "f_seats": 7090,
            "r_seats": 4650,
            "cargo_demand": 448947
        }
    },
    {
        "iata": "IPU",
        "icao": "SNIU",
        "airportName": "Ipiau Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Ipiau",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.1338892",
        "longitude": "-39.73389053",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4265",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1129,
            "s_seats": 2347,
            "j_seats": 1555,
            "f_seats": 470,
            "r_seats": 66,
            "cargo_demand": 74415
        }
    },
    {
        "iata": "IQA",
        "icao": "ORAA",
        "airportName": "Al Asad Airbase",
        "location_country": "Iraq",
        "location_city": "Anbar Province",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.78559875",
        "longitude": "42.44120026",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10125",
                "runway_width": "338",
                "runway_material": "GRE"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "13124",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167511,
            "s_seats": 29641,
            "j_seats": 47729,
            "f_seats": 14145,
            "r_seats": 6427,
            "cargo_demand": 140701
        }
    },
    {
        "iata": "IQQ",
        "icao": "SCDA",
        "airportName": "Diego Aracena International Airport",
        "location_country": "Chile",
        "location_city": "Iquique",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-20.53520012",
        "longitude": "-70.1812973",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10991",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76747,
            "s_seats": 29678,
            "j_seats": 43222,
            "f_seats": 4946,
            "r_seats": 6835,
            "cargo_demand": 157469
        }
    },
    {
        "iata": "IQT",
        "icao": "SPQT",
        "airportName": "Coronel FAP Francisco Secada Vignetta International Airport",
        "location_country": "Peru",
        "location_city": "Iquitos",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.784739971",
        "longitude": "-73.30879974",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 92036,
            "s_seats": 41743,
            "j_seats": 57065,
            "f_seats": 18061,
            "r_seats": 6664,
            "cargo_demand": 359325
        }
    },
    {
        "iata": "IRA",
        "icao": "AGGK",
        "airportName": "Kirakira Airport",
        "location_country": "Solomon Islands",
        "location_city": "Makira Island",
        "location_state": "Kirakira",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.44970036",
        "longitude": "161.897995",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4377",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3672,
            "s_seats": 1586,
            "j_seats": 1439,
            "f_seats": 268,
            "r_seats": 200,
            "cargo_demand": 25550
        }
    },
    {
        "iata": "IRC",
        "icao": "PACR",
        "airportName": "Circle City Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Circle",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "65.827673",
        "longitude": "-144.076195",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2979",
                "runway_width": "60",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 1891,
            "s_seats": 2479,
            "j_seats": 1469,
            "f_seats": 450,
            "r_seats": 174,
            "cargo_demand": 94558
        }
    },
    {
        "iata": "IRD",
        "icao": "VGIS",
        "airportName": "Ishwardi Airport",
        "location_country": "Bangladesh",
        "location_city": "Ishwardi",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.15250015",
        "longitude": "89.04940033",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4700",
                "runway_width": "80",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 85523,
            "s_seats": 26514,
            "j_seats": 54930,
            "f_seats": 17798,
            "r_seats": 4053,
            "cargo_demand": 484669
        }
    },
    {
        "iata": "IRE",
        "icao": "SNIC",
        "airportName": "Irece Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Irece",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.33990002",
        "longitude": "-41.84700012",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3010,
            "s_seats": 1866,
            "j_seats": 1857,
            "f_seats": 204,
            "r_seats": 90,
            "cargo_demand": 91606
        }
    },
    {
        "iata": "IRG",
        "icao": "YLHR",
        "airportName": "Lockhart River Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Lockhart River",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.7869",
        "longitude": "143.304993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4919",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111288,
            "s_seats": 32072,
            "j_seats": 48605,
            "f_seats": 5375,
            "r_seats": 8895,
            "cargo_demand": 485142
        }
    },
    {
        "iata": "IRI",
        "icao": "HTIR",
        "airportName": "Iringa Airport",
        "location_country": "Tanzania",
        "location_city": "Iringa",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.668630123",
        "longitude": "35.7521019",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5508",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104916,
            "s_seats": 28171,
            "j_seats": 46081,
            "f_seats": 5388,
            "r_seats": 7678,
            "cargo_demand": 164080
        }
    },
    {
        "iata": "IRJ",
        "icao": "SANL",
        "airportName": "Capitan Vicente Almandos Almonacid Airport",
        "location_country": "Argentina",
        "location_city": "La Rioja",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.38159943",
        "longitude": "-66.79579926",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "9383",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189924,
            "s_seats": 33084,
            "j_seats": 26416,
            "f_seats": 13900,
            "r_seats": 7622,
            "cargo_demand": 427957
        }
    },
    {
        "iata": "IRK",
        "icao": "KIRK",
        "airportName": "Kirksville Regional Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Kirksville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.09349823",
        "longitude": "-92.54489899",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "1393",
                "runway_width": "100",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6005",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 174959,
            "s_seats": 42635,
            "j_seats": 36600,
            "f_seats": 5340,
            "r_seats": 9386,
            "cargo_demand": 309413
        }
    },
    {
        "iata": "IRP",
        "icao": "FZJH",
        "airportName": "Matari Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Isiro",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.827610016",
        "longitude": "27.5883007",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58201,
            "s_seats": 41456,
            "j_seats": 12220,
            "f_seats": 11223,
            "r_seats": 8237,
            "cargo_demand": 104868
        }
    },
    {
        "iata": "IRS",
        "icao": "KIRS",
        "airportName": "Kirsch Municipal Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Sturgis",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.81330109",
        "longitude": "-85.43900299",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3421",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5200",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2471,
            "s_seats": 1684,
            "j_seats": 1003,
            "f_seats": 133,
            "r_seats": 132,
            "cargo_demand": 29803
        }
    },
    {
        "iata": "IRZ",
        "icao": "SWTP",
        "airportName": "Tapuruquara Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Santa Isabel do Rio Negro",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.3786",
        "longitude": "-64.9923",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "SAND"
            }
        ],
        "demand": {
            "y_seats": 3685,
            "s_seats": 2485,
            "j_seats": 1824,
            "f_seats": 207,
            "r_seats": 180,
            "cargo_demand": 73557
        }
    },
    {
        "iata": "ISA",
        "icao": "YBMA",
        "airportName": "Mount Isa Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Mount Isa",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.66390038",
        "longitude": "139.4889984",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2802",
                "runway_width": "59",
                "runway_material": "GVL"
            },
            {
                "runway_name": "16/34",
                "runway_length": "8399",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71431,
            "s_seats": 38076,
            "j_seats": 19178,
            "f_seats": 14839,
            "r_seats": 9700,
            "cargo_demand": 271880
        }
    },
    {
        "iata": "ISB",
        "icao": "OPIS",
        "airportName": "Islamabad  International Airport",
        "location_country": "Pakistan",
        "location_city": "Islamabad / Rawalpindi",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "33.549",
        "longitude": "72.82566",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "12001",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "12001",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 266161,
            "s_seats": 86146,
            "j_seats": 103970,
            "f_seats": 39258,
            "r_seats": 10000,
            "cargo_demand": 18402521
        }
    },
    {
        "iata": "ISC",
        "icao": "EGHE",
        "airportName": "St Mary's Airport",
        "location_country": "United Kingdom",
        "location_city": "Isles of Scilly",
        "location_state": "St Mary's",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "49.9133",
        "longitude": "-6.29167",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "1716",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2123",
                "runway_width": "75",
                "runway_material": "asphalt"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1312",
                "runway_width": "147",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3601,
            "s_seats": 1853,
            "j_seats": 1737,
            "f_seats": 242,
            "r_seats": 0,
            "cargo_demand": 77878
        }
    },
    {
        "iata": "ISE",
        "icao": "LTFC",
        "airportName": "Isparta Suleyman Demirel Airport",
        "location_country": "Turkey",
        "location_city": "Isparta",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.85540009",
        "longitude": "30.36840057",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 180004,
            "s_seats": 78691,
            "j_seats": 39591,
            "f_seats": 60099,
            "r_seats": 10000,
            "cargo_demand": 10844195
        }
    },
    {
        "iata": "ISI",
        "icao": "YISF",
        "airportName": "Isisford Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Isisford",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.25830078",
        "longitude": "144.4250031",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4498",
                "runway_width": "100",
                "runway_material": "COP"
            }
        ],
        "demand": {
            "y_seats": 2359,
            "s_seats": 2692,
            "j_seats": 1600,
            "f_seats": 38,
            "r_seats": 174,
            "cargo_demand": 50715
        }
    },
    {
        "iata": "ISJ",
        "icao": "MMIM",
        "airportName": "Isla Mujeres Airport",
        "location_country": "Mexico",
        "location_city": "Quintana Roo",
        "location_state": "Isla Mujeres",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.24500084",
        "longitude": "-86.73999786",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3437",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3945,
            "s_seats": 2080,
            "j_seats": 1149,
            "f_seats": 24,
            "r_seats": 2,
            "cargo_demand": 94046
        }
    },
    {
        "iata": "ISK",
        "icao": "VAOZ",
        "airportName": "Ozar Airport",
        "location_country": "India",
        "location_city": "Maharashtra",
        "location_state": "Nashik",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.119101",
        "longitude": "73.912903",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9843",
                "runway_width": "150",
                "runway_material": "CON/ASP"
            }
        ],
        "demand": {
            "y_seats": 182777,
            "s_seats": 48543,
            "j_seats": 45174,
            "f_seats": 15534,
            "r_seats": 3423,
            "cargo_demand": 220046
        }
    },
    {
        "iata": "ISL",
        "icao": "LTBA",
        "airportName": "Istanbul Ataturk Airport",
        "location_country": "Turkey",
        "location_city": "Istanbul",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.976898",
        "longitude": "28.8146",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8530",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 232756,
            "s_seats": 99790,
            "j_seats": 73066,
            "f_seats": 23771,
            "r_seats": 10000,
            "cargo_demand": 21745131
        }
    },
    {
        "iata": "ISM",
        "icao": "KISM",
        "airportName": "Kissimmee Gateway Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Orlando",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "28.28980064",
        "longitude": "-81.43710327",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 65222,
            "s_seats": 35994,
            "j_seats": 49973,
            "f_seats": 17734,
            "r_seats": 6623,
            "cargo_demand": 315338
        }
    },
    {
        "iata": "ISO",
        "icao": "KISO",
        "airportName": "Kinston Regional Jetport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Kinston",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.33140183",
        "longitude": "-77.6088028",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180160,
            "s_seats": 31721,
            "j_seats": 31129,
            "f_seats": 10958,
            "r_seats": 5323,
            "cargo_demand": 401362
        }
    },
    {
        "iata": "ISP",
        "icao": "KISP",
        "airportName": "Long Island MacArthur Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Islip",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.79520035",
        "longitude": "-73.10019684",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7006",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5034",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15L/33R",
                "runway_length": "3175",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "5186",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182928,
            "s_seats": 39222,
            "j_seats": 42043,
            "f_seats": 10722,
            "r_seats": 7330,
            "cargo_demand": 337366
        }
    },
    {
        "iata": "ISQ",
        "icao": "KISQ",
        "airportName": "Schoolcraft County Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Manistique",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.97460175",
        "longitude": "-86.17179871",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2501",
                "runway_width": "50",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3116,
            "s_seats": 1948,
            "j_seats": 1760,
            "f_seats": 149,
            "r_seats": 23,
            "cargo_demand": 77500
        }
    },
    {
        "iata": "ISS",
        "icao": "KIWI",
        "airportName": "Wiscasset Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Wiscasset",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.96139908",
        "longitude": "-69.71260071",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3397",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2506,
            "s_seats": 1642,
            "j_seats": 1672,
            "f_seats": 447,
            "r_seats": 19,
            "cargo_demand": 66650
        }
    },
    {
        "iata": "IST",
        "icao": "LTFM",
        "airportName": "Istanbul New Airport",
        "location_country": "Turkey",
        "location_city": "Istanbul",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "41.261297",
        "longitude": "28.741951",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "12303",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "12303",
                "runway_width": "196",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "13451",
                "runway_width": "196",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "13451",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "10039",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 242395,
            "s_seats": 80803,
            "j_seats": 77460,
            "f_seats": 66695,
            "r_seats": 10000,
            "cargo_demand": 20326935
        }
    },
    {
        "iata": "ISU",
        "icao": "ORSU",
        "airportName": "Sulaimaniyah International Airport",
        "location_country": "Iraq",
        "location_city": "Sulaymaniyah",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.5617485",
        "longitude": "45.31673813",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "11481",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 67928,
            "s_seats": 36050,
            "j_seats": 21710,
            "f_seats": 10278,
            "r_seats": 7789,
            "cargo_demand": 285234
        }
    },
    {
        "iata": "ISW",
        "icao": "KISW",
        "airportName": "South Wood County Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Wisconsin Rapids",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.36029816",
        "longitude": "-89.83899689",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3640",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2100",
                "runway_width": "50",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2340,
            "s_seats": 2164,
            "j_seats": 1099,
            "f_seats": 273,
            "r_seats": 122,
            "cargo_demand": 47272
        }
    },
    {
        "iata": "ITA",
        "icao": "SBIC",
        "airportName": "Itacoatiara Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Itacoatiara",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.12725997",
        "longitude": "-58.48120117",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4970",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124421,
            "s_seats": 49760,
            "j_seats": 46415,
            "f_seats": 10388,
            "r_seats": 6805,
            "cargo_demand": 387277
        }
    },
    {
        "iata": "ITB",
        "icao": "SBIH",
        "airportName": "Itaituba Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Itaituba",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-4.242340088",
        "longitude": "-56.0007019",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5266",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148506,
            "s_seats": 32677,
            "j_seats": 33274,
            "f_seats": 7762,
            "r_seats": 9075,
            "cargo_demand": 438517
        }
    },
    {
        "iata": "ITE",
        "icao": "SNZW",
        "airportName": "Itubera Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Itubera",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.731571",
        "longitude": "-39.140377",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "2789",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1151,
            "s_seats": 2139,
            "j_seats": 1943,
            "f_seats": 202,
            "r_seats": 47,
            "cargo_demand": 33019
        }
    },
    {
        "iata": "ITH",
        "icao": "KITH",
        "airportName": "Ithaca Tompkins Regional Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Ithaca",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.49100113",
        "longitude": "-76.45839691",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6601",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2018",
                "runway_width": "50",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 83948,
            "s_seats": 34261,
            "j_seats": 32217,
            "f_seats": 5926,
            "r_seats": 4084,
            "cargo_demand": 199953
        }
    },
    {
        "iata": "ITM",
        "icao": "RJOO",
        "airportName": "Osaka International Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Osaka",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "34.78549957",
        "longitude": "135.4380035",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "5997",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "9840",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 238335,
            "s_seats": 91362,
            "j_seats": 113569,
            "f_seats": 55384,
            "r_seats": 10000,
            "cargo_demand": 11821011
        }
    },
    {
        "iata": "ITN",
        "icao": "SNHA",
        "airportName": "Itabuna Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Itabuna",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.8105",
        "longitude": "-39.290401",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4993",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1154,
            "s_seats": 2974,
            "j_seats": 1036,
            "f_seats": 174,
            "r_seats": 56,
            "cargo_demand": 51951
        }
    },
    {
        "iata": "ITO",
        "icao": "PHTO",
        "airportName": "Hilo International Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Hilo",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.72139931",
        "longitude": "-155.0480042",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5600",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "9800",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152236,
            "s_seats": 30578,
            "j_seats": 34348,
            "f_seats": 18054,
            "r_seats": 7363,
            "cargo_demand": 75354
        }
    },
    {
        "iata": "ITP",
        "icao": "SDUN",
        "airportName": "Itaperuna Airport",
        "location_country": "Brazil",
        "location_city": "Rio de Janeiro",
        "location_state": "Itaperuna",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-21.21929932",
        "longitude": "-41.87590027",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2464,
            "s_seats": 2645,
            "j_seats": 1940,
            "f_seats": 291,
            "r_seats": 8,
            "cargo_demand": 15582
        }
    },
    {
        "iata": "ITQ",
        "icao": "SSIQ",
        "airportName": "Itaqui Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Itaqui",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-29.17309952",
        "longitude": "-56.5367012",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3169",
                "runway_width": "75",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1927,
            "s_seats": 2439,
            "j_seats": 1132,
            "f_seats": 195,
            "r_seats": 55,
            "cargo_demand": 15454
        }
    },
    {
        "iata": "ITR",
        "icao": "SBIT",
        "airportName": "Itumbiara Airport",
        "location_country": "Brazil",
        "location_city": "Goias",
        "location_state": "Itumbiara",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-18.44470024",
        "longitude": "-49.21340179",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5741",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3660,
            "s_seats": 2554,
            "j_seats": 1162,
            "f_seats": 91,
            "r_seats": 68,
            "cargo_demand": 53249
        }
    },
    {
        "iata": "ITU",
        "icao": "UHSI",
        "airportName": "Iturup Airport",
        "location_country": "Russia",
        "location_city": "Sakhalin Oblast",
        "location_state": "Kurilsk",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "45.256389",
        "longitude": "147.95549",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7546",
                "runway_width": "138",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 1824,
            "s_seats": 2310,
            "j_seats": 1802,
            "f_seats": 273,
            "r_seats": 162,
            "cargo_demand": 59119
        }
    },
    {
        "iata": "IUE",
        "icao": "NIUE",
        "airportName": "Niue International Airport",
        "location_country": "Niue",
        "location_city": "Alofi",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.07903099",
        "longitude": "-169.9255981",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7660",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56418,
            "s_seats": 45626,
            "j_seats": 28662,
            "f_seats": 7779,
            "r_seats": 6792,
            "cargo_demand": 69730
        }
    },
    {
        "iata": "IVC",
        "icao": "NZNV",
        "airportName": "Invercargill Airport",
        "location_country": "New Zealand",
        "location_city": "Invercargill",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-46.41239929",
        "longitude": "168.3130035",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7251",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "2280",
                "runway_width": "66",
                "runway_material": "GRS"
            },
            {
                "runway_name": "07/25",
                "runway_length": "1398",
                "runway_width": "66",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2996",
                "runway_width": "82",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 111592,
            "s_seats": 47117,
            "j_seats": 32917,
            "f_seats": 4913,
            "r_seats": 7416,
            "cargo_demand": 65694
        }
    },
    {
        "iata": "IVL",
        "icao": "EFIV",
        "airportName": "Ivalo Airport",
        "location_country": "Finland",
        "location_city": "Ivalo",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "68.6072998",
        "longitude": "27.40530014",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8199",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "2625",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141469,
            "s_seats": 41724,
            "j_seats": 41093,
            "f_seats": 5401,
            "r_seats": 7881,
            "cargo_demand": 362398
        }
    },
    {
        "iata": "IVR",
        "icao": "YIVL",
        "airportName": "Inverell Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Inverell",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-29.88829994",
        "longitude": "151.1439972",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2297",
                "runway_width": "60",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6936",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131093,
            "s_seats": 44347,
            "j_seats": 27091,
            "f_seats": 9318,
            "r_seats": 4243,
            "cargo_demand": 293926
        }
    },
    {
        "iata": "IWD",
        "icao": "KIWD",
        "airportName": "Gogebic-Iron County Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Ironwood",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "46.52750015",
        "longitude": "-90.13140106",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6501",
                "runway_width": "130",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1353,
            "s_seats": 2885,
            "j_seats": 1624,
            "f_seats": 353,
            "r_seats": 80,
            "cargo_demand": 54747
        }
    },
    {
        "iata": "IWJ",
        "icao": "RJOW",
        "airportName": "Iwami Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Masuda",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.67639923",
        "longitude": "131.7899933",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139603,
            "s_seats": 47533,
            "j_seats": 17763,
            "f_seats": 13187,
            "r_seats": 7734,
            "cargo_demand": 476525
        }
    },
    {
        "iata": "IWK",
        "icao": "RJOI",
        "airportName": "Marine Corps Air Station Iwakuni",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Iwakuni",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.146333",
        "longitude": "132.247238",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "1200",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 55427,
            "s_seats": 42880,
            "j_seats": 16850,
            "f_seats": 8745,
            "r_seats": 7894,
            "cargo_demand": 153087
        }
    },
    {
        "iata": "IWO",
        "icao": "RJAW",
        "airportName": "Iwo Jima Air Base",
        "location_country": "Japan",
        "location_city": "Bonin Islands",
        "location_state": "Iwo Jima",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.784153",
        "longitude": "141.32261",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8700",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88403,
            "s_seats": 31572,
            "j_seats": 40286,
            "f_seats": 16241,
            "r_seats": 8281,
            "cargo_demand": 230224
        }
    },
    {
        "iata": "IWS",
        "icao": "KIWS",
        "airportName": "West Houston Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Houston",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "29.81819916",
        "longitude": "-95.67259979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3953",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2735,
            "s_seats": 2763,
            "j_seats": 1606,
            "f_seats": 188,
            "r_seats": 6,
            "cargo_demand": 61669
        }
    },
    {
        "iata": "IXA",
        "icao": "VEAT",
        "airportName": "Agartala Airport",
        "location_country": "India",
        "location_city": "Tripura",
        "location_state": "Agartala",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.88699913",
        "longitude": "91.24040222",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7500",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81385,
            "s_seats": 37735,
            "j_seats": 22837,
            "f_seats": 9180,
            "r_seats": 4266,
            "cargo_demand": 51826
        }
    },
    {
        "iata": "IXB",
        "icao": "VEBD",
        "airportName": "Bagdogra Airport",
        "location_country": "India",
        "location_city": "West Bengal",
        "location_state": "Siliguri",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.68120003",
        "longitude": "88.32859802",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9035",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 184349,
            "s_seats": 46138,
            "j_seats": 12445,
            "f_seats": 8853,
            "r_seats": 9927,
            "cargo_demand": 224483
        }
    },
    {
        "iata": "IXC",
        "icao": "VICG",
        "airportName": "Chandigarh Airport",
        "location_country": "India",
        "location_city": "Chandigarh",
        "location_state": "",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.67350006",
        "longitude": "76.78849792",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9001",
                "runway_width": "168",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89607,
            "s_seats": 44644,
            "j_seats": 59708,
            "f_seats": 10694,
            "r_seats": 9430,
            "cargo_demand": 209975
        }
    },
    {
        "iata": "IXD",
        "icao": "VIAL",
        "airportName": "Allahabad Airport",
        "location_country": "India",
        "location_city": "Uttar Pradesh",
        "location_state": "Allahabad (Prayagraj)",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.4401",
        "longitude": "81.733902",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4800",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8110",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109479,
            "s_seats": 46741,
            "j_seats": 55951,
            "f_seats": 10381,
            "r_seats": 3465,
            "cargo_demand": 319524
        }
    },
    {
        "iata": "IXE",
        "icao": "VOML",
        "airportName": "Mangalore Airport",
        "location_country": "India",
        "location_city": "Karnataka",
        "location_state": "Mangalore",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.9612999",
        "longitude": "74.89009857",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8035",
                "runway_width": "151",
                "runway_material": "CON"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5300",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146618,
            "s_seats": 42071,
            "j_seats": 8031,
            "f_seats": 3914,
            "r_seats": 6218,
            "cargo_demand": 369775
        }
    },
    {
        "iata": "IXG",
        "icao": "VABM",
        "airportName": "Belgaum Airport",
        "location_country": "India",
        "location_city": "Karnataka",
        "location_state": "Belgaum",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.8593",
        "longitude": "74.618301",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "MAC"
            }
        ],
        "demand": {
            "y_seats": 118614,
            "s_seats": 46364,
            "j_seats": 12190,
            "f_seats": 12132,
            "r_seats": 6448,
            "cargo_demand": 55342
        }
    },
    {
        "iata": "IXH",
        "icao": "VEKR",
        "airportName": "Kailashahar Airport",
        "location_country": "India",
        "location_city": "Tripura",
        "location_state": "Kailashahar",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.30820084",
        "longitude": "92.00720215",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170363,
            "s_seats": 46473,
            "j_seats": 39069,
            "f_seats": 6792,
            "r_seats": 4232,
            "cargo_demand": 154282
        }
    },
    {
        "iata": "IXI",
        "icao": "VELR",
        "airportName": "Lilabari Airport",
        "location_country": "India",
        "location_city": "Assam",
        "location_state": "North Lakhimpur",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.2954998",
        "longitude": "94.09760284",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7500",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 108483,
            "s_seats": 46443,
            "j_seats": 30730,
            "f_seats": 11312,
            "r_seats": 7489,
            "cargo_demand": 262752
        }
    },
    {
        "iata": "IXJ",
        "icao": "VIJU",
        "airportName": "Jammu Airport",
        "location_country": "India",
        "location_city": "Jammu and Kashmir",
        "location_state": "Jammu",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.688849",
        "longitude": "74.838152",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6700",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131202,
            "s_seats": 38427,
            "j_seats": 46915,
            "f_seats": 4640,
            "r_seats": 3959,
            "cargo_demand": 399409
        }
    },
    {
        "iata": "IXK",
        "icao": "VAKS",
        "airportName": "Keshod Airport",
        "location_country": "India",
        "location_city": "Gujarat",
        "location_state": "Keshod",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.317101",
        "longitude": "70.270401",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79908,
            "s_seats": 28990,
            "j_seats": 32244,
            "f_seats": 17793,
            "r_seats": 3523,
            "cargo_demand": 466224
        }
    },
    {
        "iata": "IXL",
        "icao": "VILH",
        "airportName": "Kushok Bakula Rimpochee Airport",
        "location_country": "India",
        "location_city": "Ladakh",
        "location_state": "Leh",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.13589859",
        "longitude": "77.54650116",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "500",
                "runway_width": "85",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "500",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "9040",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171466,
            "s_seats": 32674,
            "j_seats": 59410,
            "f_seats": 16115,
            "r_seats": 9924,
            "cargo_demand": 401769
        }
    },
    {
        "iata": "IXM",
        "icao": "VOMD",
        "airportName": "Madurai Airport",
        "location_country": "India",
        "location_city": "Tamil Nadu",
        "location_state": "Madurai",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.83450985",
        "longitude": "78.09339905",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5990",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4603",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 142684,
            "s_seats": 36081,
            "j_seats": 51154,
            "f_seats": 14633,
            "r_seats": 3770,
            "cargo_demand": 63520
        }
    },
    {
        "iata": "IXN",
        "icao": "VEKW",
        "airportName": "Khowai Airport",
        "location_country": "India",
        "location_city": "Tripura",
        "location_state": "Khowai",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "24.061899",
        "longitude": "91.603897",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3000",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3836,
            "s_seats": 2844,
            "j_seats": 1105,
            "f_seats": 138,
            "r_seats": 30,
            "cargo_demand": 46466
        }
    },
    {
        "iata": "IXP",
        "icao": "VIPK",
        "airportName": "Pathankot Airport",
        "location_country": "India",
        "location_city": "Punjab",
        "location_state": "Pathankot",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.233611",
        "longitude": "75.634444",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8970",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61327,
            "s_seats": 40017,
            "j_seats": 52694,
            "f_seats": 17555,
            "r_seats": 4838,
            "cargo_demand": 185876
        }
    },
    {
        "iata": "IXQ",
        "icao": "VEKM",
        "airportName": "Kamalpur Airport",
        "location_country": "India",
        "location_city": "Tripura",
        "location_state": "Kamalpur",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "24.13170052",
        "longitude": "91.81420135",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4500",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2749,
            "s_seats": 2491,
            "j_seats": 1029,
            "f_seats": 31,
            "r_seats": 83,
            "cargo_demand": 44015
        }
    },
    {
        "iata": "IXR",
        "icao": "VERC",
        "airportName": "Birsa Munda Airport",
        "location_country": "India",
        "location_city": "Jharkhand",
        "location_state": "Ranchi",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.31430054",
        "longitude": "85.32170105",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8855",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79753,
            "s_seats": 31465,
            "j_seats": 8603,
            "f_seats": 2612,
            "r_seats": 9812,
            "cargo_demand": 215236
        }
    },
    {
        "iata": "IXS",
        "icao": "VEKU",
        "airportName": "Silchar Airport",
        "location_country": "India",
        "location_city": "Assam",
        "location_state": "Silchar",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.91290092",
        "longitude": "92.97869873",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5993",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66676,
            "s_seats": 32576,
            "j_seats": 28101,
            "f_seats": 6630,
            "r_seats": 7732,
            "cargo_demand": 145706
        }
    },
    {
        "iata": "IXT",
        "icao": "VEPG",
        "airportName": "Pasighat Airport",
        "location_country": "India",
        "location_city": "Arunachal Pradesh",
        "location_state": "Pasighat",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.06609917",
        "longitude": "95.33560181",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3300",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2719,
            "s_seats": 1955,
            "j_seats": 1649,
            "f_seats": 34,
            "r_seats": 185,
            "cargo_demand": 58209
        }
    },
    {
        "iata": "IXU",
        "icao": "VAAU",
        "airportName": "Aurangabad Airport",
        "location_country": "India",
        "location_city": "Maharashtra",
        "location_state": "Aurangabad",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.86269951",
        "longitude": "75.39810181",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9314",
                "runway_width": "148",
                "runway_material": "Macadam"
            }
        ],
        "demand": {
            "y_seats": 195719,
            "s_seats": 25950,
            "j_seats": 39524,
            "f_seats": 3785,
            "r_seats": 6031,
            "cargo_demand": 364227
        }
    },
    {
        "iata": "IXV",
        "icao": "VEAN",
        "airportName": "Along Airport",
        "location_country": "India",
        "location_city": "Arunachal Pradesh",
        "location_state": "Along (Aalo)",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.1753006",
        "longitude": "94.80200195",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3000",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 158674,
            "s_seats": 42669,
            "j_seats": 22975,
            "f_seats": 9327,
            "r_seats": 7845,
            "cargo_demand": 290882
        }
    },
    {
        "iata": "IXW",
        "icao": "VEJS",
        "airportName": "Sonari Airport",
        "location_country": "India",
        "location_city": "Jharkhand",
        "location_state": "Jamshedpur",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.813786",
        "longitude": "86.169739",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4010",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87572,
            "s_seats": 38684,
            "j_seats": 46539,
            "f_seats": 6220,
            "r_seats": 6823,
            "cargo_demand": 379864
        }
    },
    {
        "iata": "IXX",
        "icao": "VOBR",
        "airportName": "Bidar Airport",
        "location_country": "India",
        "location_city": "Karnataka",
        "location_state": "Bidar",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.9081",
        "longitude": "77.487099",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6955",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6798",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162690,
            "s_seats": 29552,
            "j_seats": 30163,
            "f_seats": 12451,
            "r_seats": 4160,
            "cargo_demand": 140185
        }
    },
    {
        "iata": "IXY",
        "icao": "VAKE",
        "airportName": "Kandla Airport",
        "location_country": "India",
        "location_city": "Gujarat",
        "location_state": "Kandla / Gandhidham",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.1127",
        "longitude": "70.100304",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4997",
                "runway_width": "94",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 71584,
            "s_seats": 39411,
            "j_seats": 35515,
            "f_seats": 14535,
            "r_seats": 8498,
            "cargo_demand": 144488
        }
    },
    {
        "iata": "IXZ",
        "icao": "VOPB",
        "airportName": "Veer Savarkar International Airport",
        "location_country": "India",
        "location_city": "Andaman and Nicobar Islands",
        "location_state": "Port Blair",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.641208",
        "longitude": "92.729643",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10795",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150638,
            "s_seats": 38880,
            "j_seats": 19376,
            "f_seats": 12006,
            "r_seats": 6767,
            "cargo_demand": 154915
        }
    },
    {
        "iata": "IYK",
        "icao": "KIYK",
        "airportName": "Inyokern Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Inyokern",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.65879822",
        "longitude": "-117.8300018",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6275",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4150",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "7100",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2559,
            "s_seats": 2526,
            "j_seats": 1350,
            "f_seats": 98,
            "r_seats": 174,
            "cargo_demand": 59203
        }
    },
    {
        "iata": "IZA",
        "icao": "SBZM",
        "airportName": "Presidente Itamar Franco Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Juiz de Fora",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-21.513086",
        "longitude": "-43.173069",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8284",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175234,
            "s_seats": 47889,
            "j_seats": 10637,
            "f_seats": 3704,
            "r_seats": 9150,
            "cargo_demand": 54288
        }
    },
    {
        "iata": "IZO",
        "icao": "RJOC",
        "airportName": "Izumo Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Izumo",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.413601",
        "longitude": "132.889999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127654,
            "s_seats": 39838,
            "j_seats": 7550,
            "f_seats": 13705,
            "r_seats": 6416,
            "cargo_demand": 133137
        }
    },
    {
        "iata": "IZT",
        "icao": "MMIT",
        "airportName": "Ixtepec Airport",
        "location_country": "Mexico",
        "location_city": "Oaxaca",
        "location_state": "Ixtepec",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "16.449301",
        "longitude": "-95.093697",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7640",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162346,
            "s_seats": 25440,
            "j_seats": 37876,
            "f_seats": 5542,
            "r_seats": 8274,
            "cargo_demand": 131379
        }
    },
    {
        "iata": "JAA",
        "icao": "OAJL",
        "airportName": "Jalalabad Airport",
        "location_country": "Afghanistan",
        "location_city": "Jalalabad",
        "location_state": "",
        "time_zone": "UTC+04:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.399799",
        "longitude": "70.498596",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7262",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180928,
            "s_seats": 35757,
            "j_seats": 24828,
            "f_seats": 6043,
            "r_seats": 8700,
            "cargo_demand": 336945
        }
    },
    {
        "iata": "JAB",
        "icao": "YJAB",
        "airportName": "Jabiru Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Jabiru",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.6583004",
        "longitude": "132.8930054",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4670",
                "runway_width": "100",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 3511,
            "s_seats": 2519,
            "j_seats": 1336,
            "f_seats": 441,
            "r_seats": 40,
            "cargo_demand": 33284
        }
    },
    {
        "iata": "JAC",
        "icao": "KJAC",
        "airportName": "Jackson Hole Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Jackson",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.6072998",
        "longitude": "-110.737999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6300",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83001,
            "s_seats": 45191,
            "j_seats": 37077,
            "f_seats": 15929,
            "r_seats": 6497,
            "cargo_demand": 454162
        }
    },
    {
        "iata": "JAD",
        "icao": "YPJT",
        "airportName": "Jandakot Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Perth",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-32.09749985",
        "longitude": "115.8809967",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "4569",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "3773",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4567",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179671,
            "s_seats": 46278,
            "j_seats": 32045,
            "f_seats": 16131,
            "r_seats": 7149,
            "cargo_demand": 351795
        }
    },
    {
        "iata": "JAE",
        "icao": "SPJE",
        "airportName": "Jaen Airport",
        "location_country": "Peru",
        "location_city": "Jaen",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.59248",
        "longitude": "-78.774002",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81460,
            "s_seats": 36017,
            "j_seats": 28414,
            "f_seats": 5766,
            "r_seats": 6016,
            "cargo_demand": 107026
        }
    },
    {
        "iata": "JAF",
        "icao": "VCCJ",
        "airportName": "Jaffna Airport",
        "location_country": "Sri Lanka",
        "location_city": "Jaffna",
        "location_state": "",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.792329788",
        "longitude": "80.07009888",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7500",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77372,
            "s_seats": 42819,
            "j_seats": 23788,
            "f_seats": 18563,
            "r_seats": 8763,
            "cargo_demand": 125828
        }
    },
    {
        "iata": "JAG",
        "icao": "OPJA",
        "airportName": "PAF Base Shahbaz",
        "location_country": "Pakistan",
        "location_city": "Jacobabad",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.28420067",
        "longitude": "68.4496994",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "10040",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15L/33R",
                "runway_length": "10081",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153392,
            "s_seats": 49928,
            "j_seats": 10972,
            "f_seats": 3643,
            "r_seats": 6679,
            "cargo_demand": 251495
        }
    },
    {
        "iata": "JAI",
        "icao": "VIJP",
        "airportName": "Jaipur International Airport",
        "location_country": "India",
        "location_city": "Rajasthan",
        "location_state": "Jaipur",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.8242",
        "longitude": "75.812202",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9177",
                "runway_width": "148",
                "runway_material": "PEM"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5223",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194257,
            "s_seats": 38172,
            "j_seats": 8868,
            "f_seats": 4278,
            "r_seats": 3885,
            "cargo_demand": 286838
        }
    },
    {
        "iata": "JAK",
        "icao": "MTJA",
        "airportName": "Jacmel Airport",
        "location_country": "Haiti",
        "location_city": "Jacmel",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "18.24110031",
        "longitude": "-72.51850128",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3300",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100008,
            "s_seats": 34381,
            "j_seats": 43850,
            "f_seats": 12133,
            "r_seats": 6752,
            "cargo_demand": 397247
        }
    },
    {
        "iata": "JAL",
        "icao": "MMJA",
        "airportName": "El Lencero Airport",
        "location_country": "Mexico",
        "location_city": "Veracruz",
        "location_state": "Xalapa (Jalapa)",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "19.47509956",
        "longitude": "-96.79750061",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5577",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184612,
            "s_seats": 31069,
            "j_seats": 17351,
            "f_seats": 15657,
            "r_seats": 4159,
            "cargo_demand": 131329
        }
    },
    {
        "iata": "JAN",
        "icao": "KJAN",
        "airportName": "Jackson-Evers International Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Jackson",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "32.31119919",
        "longitude": "-90.07589722",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "8500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "8500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142743,
            "s_seats": 71312,
            "j_seats": 169387,
            "f_seats": 38243,
            "r_seats": 10000,
            "cargo_demand": 26807656
        }
    },
    {
        "iata": "JAP",
        "icao": "MRCH",
        "airportName": "Chacarita Airport",
        "location_country": "Costa Rica",
        "location_city": "Puntarenas",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "9.981410027",
        "longitude": "-84.77269745",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4921",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2902,
            "s_seats": 2691,
            "j_seats": 1835,
            "f_seats": 451,
            "r_seats": 71,
            "cargo_demand": 60824
        }
    },
    {
        "iata": "JAR",
        "icao": "OISJ",
        "airportName": "Jahrom Airport",
        "location_country": "Iran",
        "location_city": "Jahrom",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "small_airport",
        "latitude": "28.58670044",
        "longitude": "53.57910156",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7899",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2956,
            "s_seats": 2945,
            "j_seats": 1486,
            "f_seats": 178,
            "r_seats": 141,
            "cargo_demand": 13157
        }
    },
    {
        "iata": "JAS",
        "icao": "KJAS",
        "airportName": "Jasper County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Jasper",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "30.8857",
        "longitude": "-94.034897",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5500",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3025,
            "s_seats": 2483,
            "j_seats": 1831,
            "f_seats": 315,
            "r_seats": 133,
            "cargo_demand": 54573
        }
    },
    {
        "iata": "JAU",
        "icao": "SPJJ",
        "airportName": "Francisco Carle Airport",
        "location_country": "Peru",
        "location_city": "Jauja",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.78310013",
        "longitude": "-75.4733963",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9220",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 75244,
            "s_seats": 34681,
            "j_seats": 24862,
            "f_seats": 5892,
            "r_seats": 3212,
            "cargo_demand": 176395
        }
    },
    {
        "iata": "JAV",
        "icao": "BGJN",
        "airportName": "Ilulissat Airport",
        "location_country": "Greenland",
        "location_city": "Ilulissat",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "69.24320221",
        "longitude": "-51.05709839",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2772",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1611,
            "s_seats": 2678,
            "j_seats": 1888,
            "f_seats": 123,
            "r_seats": 164,
            "cargo_demand": 30274
        }
    },
    {
        "iata": "JAX",
        "icao": "KJAX",
        "airportName": "Jacksonville International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Jacksonville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "30.49410057",
        "longitude": "-81.68789673",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "7701",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 481616,
            "s_seats": 92489,
            "j_seats": 91742,
            "f_seats": 68241,
            "r_seats": 10000,
            "cargo_demand": 5898241
        }
    },
    {
        "iata": "JBQ",
        "icao": "MDJB",
        "airportName": "La Isabela International Airport",
        "location_country": "Dominican Republic",
        "location_city": "La Isabela",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.57250023",
        "longitude": "-69.98560333",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5412",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177960,
            "s_seats": 40950,
            "j_seats": 18919,
            "f_seats": 5172,
            "r_seats": 4853,
            "cargo_demand": 94085
        }
    },
    {
        "iata": "JBR",
        "icao": "KJBR",
        "airportName": "Jonesboro Municipal Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Jonesboro",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.83169937",
        "longitude": "-90.64640045",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4099",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148030,
            "s_seats": 42536,
            "j_seats": 30382,
            "f_seats": 11363,
            "r_seats": 5237,
            "cargo_demand": 231824
        }
    },
    {
        "iata": "JBS",
        "icao": "SSSB",
        "airportName": "Sio Borja Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Sio Borja",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-28.6549",
        "longitude": "-56.034599",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3445",
                "runway_width": "98",
                "runway_material": "SAI"
            },
            {
                "runway_name": "6/24",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3309,
            "s_seats": 2817,
            "j_seats": 1112,
            "f_seats": 329,
            "r_seats": 60,
            "cargo_demand": 6498
        }
    },
    {
        "iata": "JCB",
        "icao": "SSJA",
        "airportName": "Santa Terezinha Municipal Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "JoaiCaba",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-27.17140007",
        "longitude": "-51.55329895",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4134",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1889,
            "s_seats": 2226,
            "j_seats": 1736,
            "f_seats": 471,
            "r_seats": 106,
            "cargo_demand": 78769
        }
    },
    {
        "iata": "JCI",
        "icao": "KIXD",
        "airportName": "New Century AirCenter",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Olathe",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.83089828",
        "longitude": "-94.89029694",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5130",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7339",
                "runway_width": "190",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1661,
            "s_seats": 2814,
            "j_seats": 1918,
            "f_seats": 500,
            "r_seats": 144,
            "cargo_demand": 81903
        }
    },
    {
        "iata": "JCK",
        "icao": "YJLC",
        "airportName": "Julia Creek Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Julia Creek",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.66830063",
        "longitude": "141.7230072",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4600",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2892,
            "s_seats": 2268,
            "j_seats": 1107,
            "f_seats": 189,
            "r_seats": 179,
            "cargo_demand": 66670
        }
    },
    {
        "iata": "JCM",
        "icao": "SNJB",
        "airportName": "Jacobina Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Jacobina",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.16320038",
        "longitude": "-40.55310059",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4101",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1629,
            "s_seats": 2130,
            "j_seats": 1439,
            "f_seats": 245,
            "r_seats": 154,
            "cargo_demand": 11085
        }
    },
    {
        "iata": "JCR",
        "icao": "SBEK",
        "airportName": "Jacareacanga Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Jacareacanga",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.233160019",
        "longitude": "-57.77690125",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "5249",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81474,
            "s_seats": 46563,
            "j_seats": 27286,
            "f_seats": 2402,
            "r_seats": 7057,
            "cargo_demand": 331523
        }
    },
    {
        "iata": "JCT",
        "icao": "KJCT",
        "airportName": "Kimble County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Junction",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.51129913",
        "longitude": "-99.7634964",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2255",
                "runway_width": "146",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94480,
            "s_seats": 25969,
            "j_seats": 58686,
            "f_seats": 17251,
            "r_seats": 9890,
            "cargo_demand": 128802
        }
    },
    {
        "iata": "JDA",
        "icao": "KGCD",
        "airportName": "Grant County Regional Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "John Day",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.40420151",
        "longitude": "-118.9629974",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4471",
                "runway_width": "60",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5220",
                "runway_width": "60",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 1265,
            "s_seats": 1565,
            "j_seats": 1337,
            "f_seats": 113,
            "r_seats": 3,
            "cargo_demand": 70187
        }
    },
    {
        "iata": "JDF",
        "icao": "SBJF",
        "airportName": "Francisco alvares de Assis Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Juiz de Fora",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-21.79150009",
        "longitude": "-43.38679886",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "5036",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193290,
            "s_seats": 36326,
            "j_seats": 6215,
            "f_seats": 8205,
            "r_seats": 5584,
            "cargo_demand": 494391
        }
    },
    {
        "iata": "JDG",
        "icao": "RKPD",
        "airportName": "Jeongseok Airport",
        "location_country": "South Korea",
        "location_city": "Seogwipo",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.39960098",
        "longitude": "126.711998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7589",
                "runway_width": "149",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4974",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176332,
            "s_seats": 26267,
            "j_seats": 28724,
            "f_seats": 12987,
            "r_seats": 4875,
            "cargo_demand": 181431
        }
    },
    {
        "iata": "JDH",
        "icao": "VIJO",
        "airportName": "Jodhpur Airport",
        "location_country": "India",
        "location_city": "Rajasthan",
        "location_state": "Jodhpur",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.25110054",
        "longitude": "73.04889679",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9005",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 192374,
            "s_seats": 26925,
            "j_seats": 34451,
            "f_seats": 9366,
            "r_seats": 8570,
            "cargo_demand": 203713
        }
    },
    {
        "iata": "JDN",
        "icao": "KJDN",
        "airportName": "Jordan Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Jordan",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "47.3288002",
        "longitude": "-106.9530029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4300",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3135,
            "s_seats": 1501,
            "j_seats": 1700,
            "f_seats": 168,
            "r_seats": 148,
            "cargo_demand": 83945
        }
    },
    {
        "iata": "JDO",
        "icao": "SBJU",
        "airportName": "Juazeiro do Norte Airport",
        "location_country": "Brazil",
        "location_city": "Ceara",
        "location_state": "Juazeiro do Norte",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.218959808",
        "longitude": "-39.27009964",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5906",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2308,
            "s_seats": 2918,
            "j_seats": 1773,
            "f_seats": 344,
            "r_seats": 17,
            "cargo_demand": 83204
        }
    },
    {
        "iata": "JDR",
        "icao": "SNJR",
        "airportName": "Prefeito Octavio de Almeida Neves Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Sio Joio del Rei",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-21.08499908",
        "longitude": "-44.22472382",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3873,
            "s_seats": 2712,
            "j_seats": 1957,
            "f_seats": 433,
            "r_seats": 89,
            "cargo_demand": 34221
        }
    },
    {
        "iata": "JED",
        "icao": "OEJN",
        "airportName": "King Abdulaziz International Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Jeddah",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "21.6796",
        "longitude": "39.156502",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16C/34C",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 200884,
            "s_seats": 70418,
            "j_seats": 90654,
            "f_seats": 20477,
            "r_seats": 10000,
            "cargo_demand": 17767624
        }
    },
    {
        "iata": "JEF",
        "icao": "KJEF",
        "airportName": "Jefferson City Memorial Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Jefferson City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.59120178",
        "longitude": "-92.15609741",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3401",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1515,
            "s_seats": 2633,
            "j_seats": 1344,
            "f_seats": 218,
            "r_seats": 127,
            "cargo_demand": 12619
        }
    },
    {
        "iata": "JEG",
        "icao": "BGAA",
        "airportName": "Aasiaat Airport",
        "location_country": "Greenland",
        "location_city": "Aasiaat",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "68.72180176",
        "longitude": "-52.78469849",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2621",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 135589,
            "s_seats": 43766,
            "j_seats": 50130,
            "f_seats": 2086,
            "r_seats": 9916,
            "cargo_demand": 441096
        }
    },
    {
        "iata": "JEQ",
        "icao": "SNJK",
        "airportName": "Jequie Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Jequie",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.87769985",
        "longitude": "-40.07160187",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4199",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2864,
            "s_seats": 2732,
            "j_seats": 1882,
            "f_seats": 80,
            "r_seats": 116,
            "cargo_demand": 50194
        }
    },
    {
        "iata": "JER",
        "icao": "EGJJ",
        "airportName": "Jersey Airport",
        "location_country": "United Kingdom",
        "location_city": "Channel Islands",
        "location_state": "Jersey",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.207901",
        "longitude": "-2.195509911",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5597",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120444,
            "s_seats": 34593,
            "j_seats": 16408,
            "f_seats": 8526,
            "r_seats": 4659,
            "cargo_demand": 478590
        }
    },
    {
        "iata": "JFK",
        "icao": "KJFK",
        "airportName": "John F. Kennedy International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "New York City",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "40.639801",
        "longitude": "-73.7789",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "12079",
                "runway_width": "200",
                "runway_material": "Concrete - Grooved"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "8400",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "10000",
                "runway_width": "200",
                "runway_material": "Concrete"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "14511",
                "runway_width": "200",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 481985,
            "s_seats": 77099,
            "j_seats": 20622,
            "f_seats": 28229,
            "r_seats": 10000,
            "cargo_demand": 5853637
        }
    },
    {
        "iata": "JFN",
        "icao": "KHZY",
        "airportName": "Northeast Ohio Regional Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Ashtabula",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.778",
        "longitude": "-80.695503",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5197",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160411,
            "s_seats": 38879,
            "j_seats": 14766,
            "f_seats": 11340,
            "r_seats": 7850,
            "cargo_demand": 204567
        }
    },
    {
        "iata": "JGA",
        "icao": "VAJM",
        "airportName": "Jamnagar Airport",
        "location_country": "India",
        "location_city": "Gujarat",
        "location_state": "Jamnagar",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.46549988",
        "longitude": "70.01260376",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8242",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8236",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139850,
            "s_seats": 26265,
            "j_seats": 26481,
            "f_seats": 18706,
            "r_seats": 5970,
            "cargo_demand": 410346
        }
    },
    {
        "iata": "JGN",
        "icao": "ZLJQ",
        "airportName": "Jiayuguan Airport",
        "location_country": "China",
        "location_city": "Gansu",
        "location_state": "Jiayuguan",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.856899",
        "longitude": "98.3414",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9834",
                "runway_width": "131",
                "runway_material": "paved"
            }
        ],
        "demand": {
            "y_seats": 120701,
            "s_seats": 41550,
            "j_seats": 34440,
            "f_seats": 5114,
            "r_seats": 9469,
            "cargo_demand": 403823
        }
    },
    {
        "iata": "JHB",
        "icao": "WMKJ",
        "airportName": "Senai International Airport",
        "location_country": "Malaysia",
        "location_city": "Johor",
        "location_state": "Johor Bahru",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.64131",
        "longitude": "103.669998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "12467",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176416,
            "s_seats": 44910,
            "j_seats": 46952,
            "f_seats": 3125,
            "r_seats": 8876,
            "cargo_demand": 337379
        }
    },
    {
        "iata": "JHG",
        "icao": "ZPJH",
        "airportName": "Xishuangbanna Gasa Airport",
        "location_country": "China",
        "location_city": "Yunnan",
        "location_state": "Jinghong",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.97389984",
        "longitude": "100.7600021",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 144021,
            "s_seats": 49194,
            "j_seats": 17510,
            "f_seats": 2800,
            "r_seats": 9002,
            "cargo_demand": 118104
        }
    },
    {
        "iata": "JHM",
        "icao": "PHJH",
        "airportName": "Kapalua Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Kapalua",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.96290016",
        "longitude": "-156.6730042",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181499,
            "s_seats": 26667,
            "j_seats": 12196,
            "f_seats": 15695,
            "r_seats": 4771,
            "cargo_demand": 355289
        }
    },
    {
        "iata": "JHS",
        "icao": "BGSS",
        "airportName": "Sisimiut Airport",
        "location_country": "Greenland",
        "location_city": "Sisimiut",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "66.951302",
        "longitude": "-53.729301",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2621",
                "runway_width": "98",
                "runway_material": "ASPHALT"
            }
        ],
        "demand": {
            "y_seats": 113368,
            "s_seats": 42409,
            "j_seats": 42228,
            "f_seats": 3744,
            "r_seats": 9556,
            "cargo_demand": 285110
        }
    },
    {
        "iata": "JHW",
        "icao": "KJHW",
        "airportName": "Chautauqua County-Jamestown Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Jamestown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.15340042",
        "longitude": "-79.25800323",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5299",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136425,
            "s_seats": 26844,
            "j_seats": 7052,
            "f_seats": 2225,
            "r_seats": 8600,
            "cargo_demand": 73994
        }
    },
    {
        "iata": "JIA",
        "icao": "SWJN",
        "airportName": "Juina Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Juina",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-11.419444",
        "longitude": "-58.701668",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5249",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3460,
            "s_seats": 2593,
            "j_seats": 1091,
            "f_seats": 106,
            "r_seats": 84,
            "cargo_demand": 24717
        }
    },
    {
        "iata": "JIB",
        "icao": "HDAM",
        "airportName": "Djibouti-Ambouli International Airport",
        "location_country": "Djibouti",
        "location_city": "Djibouti",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.54730034",
        "longitude": "43.15950012",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10335",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 129992,
            "s_seats": 37049,
            "j_seats": 9338,
            "f_seats": 5828,
            "r_seats": 7769,
            "cargo_demand": 127024
        }
    },
    {
        "iata": "JIK",
        "icao": "LGIK",
        "airportName": "Ikaria Island National Airport",
        "location_country": "Greece",
        "location_city": "Ikaria Island",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "37.68270111",
        "longitude": "26.3470993",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4551",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3531,
            "s_seats": 2685,
            "j_seats": 1687,
            "f_seats": 250,
            "r_seats": 91,
            "cargo_demand": 71747
        }
    },
    {
        "iata": "JIM",
        "icao": "HAJM",
        "airportName": "Aba Segud Airport",
        "location_country": "Ethiopia",
        "location_city": "Jimma",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.666090012",
        "longitude": "36.8166008",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6562",
                "runway_width": "163",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193589,
            "s_seats": 33705,
            "j_seats": 23750,
            "f_seats": 9713,
            "r_seats": 3114,
            "cargo_demand": 222298
        }
    },
    {
        "iata": "JIU",
        "icao": "ZSJJ",
        "airportName": "Jiujiang Lushan Airport",
        "location_country": "China",
        "location_city": "Jiangxi",
        "location_state": "Jiujiang",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "29.476944",
        "longitude": "115.801111",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9186",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3952,
            "s_seats": 1623,
            "j_seats": 1453,
            "f_seats": 418,
            "r_seats": 187,
            "cargo_demand": 51898
        }
    },
    {
        "iata": "JIW",
        "icao": "OPJI",
        "airportName": "Jiwani Airport",
        "location_country": "Pakistan",
        "location_city": "Jiwani",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "25.06780052",
        "longitude": "61.80540085",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5850",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3266,
            "s_seats": 2808,
            "j_seats": 1754,
            "f_seats": 203,
            "r_seats": 182,
            "cargo_demand": 35872
        }
    },
    {
        "iata": "JJG",
        "icao": "SBJA",
        "airportName": "Humberto Ghizzo Bortoluzzi Regional Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "Jaguaruna",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-28.6753",
        "longitude": "-49.0596",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8199",
                "runway_width": "89",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3846,
            "s_seats": 1621,
            "j_seats": 1762,
            "f_seats": 461,
            "r_seats": 127,
            "cargo_demand": 6949
        }
    },
    {
        "iata": "JJI",
        "icao": "SPJI",
        "airportName": "Juanjui Airport",
        "location_country": "Peru",
        "location_city": "Juanjui",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.169099808",
        "longitude": "-76.72859955",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6889",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101792,
            "s_seats": 38258,
            "j_seats": 15120,
            "f_seats": 11391,
            "r_seats": 5214,
            "cargo_demand": 209181
        }
    },
    {
        "iata": "JKG",
        "icao": "ESGJ",
        "airportName": "Jonkoping Airport",
        "location_country": "Sweden",
        "location_city": "Jonkoping",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "57.75759888",
        "longitude": "14.06869984",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7228",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "1969",
                "runway_width": "82",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 191258,
            "s_seats": 38480,
            "j_seats": 13254,
            "f_seats": 19144,
            "r_seats": 3879,
            "cargo_demand": 191886
        }
    },
    {
        "iata": "JKH",
        "icao": "LGHI",
        "airportName": "Chios Island National Airport",
        "location_country": "Greece",
        "location_city": "Chios",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "38.34320068",
        "longitude": "26.1406002",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4957",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186322,
            "s_seats": 26094,
            "j_seats": 39381,
            "f_seats": 16033,
            "r_seats": 3394,
            "cargo_demand": 486221
        }
    },
    {
        "iata": "JKL",
        "icao": "LGKY",
        "airportName": "Kalymnos Island National Airport",
        "location_country": "Greece",
        "location_city": "Kalymnos",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "36.9632988",
        "longitude": "26.94059944",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3330",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1082,
            "s_seats": 2182,
            "j_seats": 1887,
            "f_seats": 481,
            "r_seats": 49,
            "cargo_demand": 36449
        }
    },
    {
        "iata": "JKR",
        "icao": "VNJP",
        "airportName": "Janakpur Airport",
        "location_country": "Nepal",
        "location_city": "Janakpur",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.70879936",
        "longitude": "85.92240143",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152326,
            "s_seats": 44849,
            "j_seats": 5589,
            "f_seats": 9296,
            "r_seats": 7267,
            "cargo_demand": 58047
        }
    },
    {
        "iata": "JKV",
        "icao": "KJSO",
        "airportName": "Cherokee County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Jacksonville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.86930084",
        "longitude": "-95.2173996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5011",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2064,
            "s_seats": 1734,
            "j_seats": 1801,
            "f_seats": 230,
            "r_seats": 26,
            "cargo_demand": 92090
        }
    },
    {
        "iata": "JLN",
        "icao": "KJLN",
        "airportName": "Joplin Regional Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Joplin",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "37.15179825",
        "longitude": "-94.49829865",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3604",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6502",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 400679,
            "s_seats": 75200,
            "j_seats": 65263,
            "f_seats": 31656,
            "r_seats": 10000,
            "cargo_demand": 17294845
        }
    },
    {
        "iata": "JLR",
        "icao": "VAJB",
        "airportName": "Jabalpur Airport",
        "location_country": "India",
        "location_city": "Madhya Pradesh",
        "location_state": "Jabalpur",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.17779922",
        "longitude": "80.05200195",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6522",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66887,
            "s_seats": 43959,
            "j_seats": 30015,
            "f_seats": 4334,
            "r_seats": 6873,
            "cargo_demand": 152091
        }
    },
    {
        "iata": "JLS",
        "icao": "SDJL",
        "airportName": "Jales Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Jales",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-20.29299927",
        "longitude": "-50.54639816",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "3478",
                "runway_width": "62",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2386,
            "s_seats": 2467,
            "j_seats": 1599,
            "f_seats": 361,
            "r_seats": 24,
            "cargo_demand": 81826
        }
    },
    {
        "iata": "JMJ",
        "icao": "ZPJM",
        "airportName": "Lancang Jingmai Airport",
        "location_country": "China",
        "location_city": "Yunnan",
        "location_state": "Donghui Township",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.415833",
        "longitude": "99.786389",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 128318,
            "s_seats": 49794,
            "j_seats": 7839,
            "f_seats": 12033,
            "r_seats": 4013,
            "cargo_demand": 151645
        }
    },
    {
        "iata": "JMK",
        "icao": "LGMK",
        "airportName": "Mykonos Island National Airport",
        "location_country": "Greece",
        "location_city": "Mykonos",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "37.43510056",
        "longitude": "25.34810066",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6240",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 195687,
            "s_seats": 33962,
            "j_seats": 48295,
            "f_seats": 17221,
            "r_seats": 6698,
            "cargo_demand": 147328
        }
    },
    {
        "iata": "JMO",
        "icao": "VNJS",
        "airportName": "Jomsom Airport",
        "location_country": "Nepal",
        "location_city": "Jomsom",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.780426",
        "longitude": "83.723",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2424",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1038,
            "s_seats": 2479,
            "j_seats": 1902,
            "f_seats": 17,
            "r_seats": 141,
            "cargo_demand": 59193
        }
    },
    {
        "iata": "JMS",
        "icao": "KJMS",
        "airportName": "Jamestown Regional Airport",
        "location_country": "United States",
        "location_city": "North Dakota",
        "location_state": "Jamestown",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.92969894",
        "longitude": "-98.67819977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5749",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 65935,
            "s_seats": 28006,
            "j_seats": 10151,
            "f_seats": 9049,
            "r_seats": 9172,
            "cargo_demand": 373706
        }
    },
    {
        "iata": "JMU",
        "icao": "ZYJM",
        "airportName": "Jiamusi Dongjiao Airport",
        "location_country": "China",
        "location_city": "Heilongjiang",
        "location_state": "Jiamusi",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.84339905",
        "longitude": "130.4649963",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 70901,
            "s_seats": 43751,
            "j_seats": 30465,
            "f_seats": 3782,
            "r_seats": 5426,
            "cargo_demand": 110669
        }
    },
    {
        "iata": "JNA",
        "icao": "SNJN",
        "airportName": "Januaria Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Januaria",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-15.47379971",
        "longitude": "-44.38550186",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1801,
            "s_seats": 2179,
            "j_seats": 1929,
            "f_seats": 172,
            "r_seats": 69,
            "cargo_demand": 4545
        }
    },
    {
        "iata": "JNB",
        "icao": "FAOR",
        "airportName": "O. R. Tambo International Airport",
        "location_country": "South Africa",
        "location_city": "Johannesburg",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-26.1392",
        "longitude": "28.246",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "14495",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "11155",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115281,
            "s_seats": 72333,
            "j_seats": 146520,
            "f_seats": 66103,
            "r_seats": 10000,
            "cargo_demand": 9000434
        }
    },
    {
        "iata": "JNU",
        "icao": "PAJN",
        "airportName": "Juneau International Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Juneau",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "58.35499954",
        "longitude": "-134.576004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8457",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08W/26W",
                "runway_length": "4900",
                "runway_width": "450",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 151954,
            "s_seats": 39024,
            "j_seats": 42805,
            "f_seats": 19512,
            "r_seats": 3467,
            "cargo_demand": 87174
        }
    },
    {
        "iata": "JNX",
        "icao": "LGNX",
        "airportName": "Naxos Island National Airport",
        "location_country": "Greece",
        "location_city": "Naxos",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "37.0811",
        "longitude": "25.368099",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "2953",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1915,
            "s_seats": 2786,
            "j_seats": 1720,
            "f_seats": 234,
            "r_seats": 50,
            "cargo_demand": 10265
        }
    },
    {
        "iata": "JOE",
        "icao": "EFJO",
        "airportName": "Joensuu Airport",
        "location_country": "Finland",
        "location_city": "Joensuu",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "62.662899",
        "longitude": "29.6075",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8202",
                "runway_width": "171",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "COM"
            }
        ],
        "demand": {
            "y_seats": 191871,
            "s_seats": 31770,
            "j_seats": 54163,
            "f_seats": 19574,
            "r_seats": 8163,
            "cargo_demand": 492910
        }
    },
    {
        "iata": "JOH",
        "icao": "FAPJ",
        "airportName": "Port St. Johns Airport",
        "location_country": "South Africa",
        "location_city": "Port St. Johns",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.60612",
        "longitude": "29.52175",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3610",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112526,
            "s_seats": 25975,
            "j_seats": 27273,
            "f_seats": 16903,
            "r_seats": 4717,
            "cargo_demand": 486148
        }
    },
    {
        "iata": "JOI",
        "icao": "SBJV",
        "airportName": "Joinville-Lauro Carneiro de Loyola Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "Joinville",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-26.22450066",
        "longitude": "-48.79740143",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5381",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141312,
            "s_seats": 27757,
            "j_seats": 40858,
            "f_seats": 10801,
            "r_seats": 7143,
            "cargo_demand": 396870
        }
    },
    {
        "iata": "JOL",
        "icao": "RPMJ",
        "airportName": "Jolo Airport",
        "location_country": "Philippines",
        "location_city": "Jolo",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.05366993",
        "longitude": "121.0110016",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4144",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63307,
            "s_seats": 44896,
            "j_seats": 59451,
            "f_seats": 1221,
            "r_seats": 9777,
            "cargo_demand": 109330
        }
    },
    {
        "iata": "JOS",
        "icao": "DNJO",
        "airportName": "Yakubu Gowon Airport",
        "location_country": "Nigeria",
        "location_city": "Jos",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.639829636",
        "longitude": "8.869050026",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9845",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145947,
            "s_seats": 37960,
            "j_seats": 58726,
            "f_seats": 13016,
            "r_seats": 3260,
            "cargo_demand": 195029
        }
    },
    {
        "iata": "JOT",
        "icao": "KJOT",
        "airportName": "Joliet Regional Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Joliet",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.51779938",
        "longitude": "-88.17549896",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3228",
                "runway_width": "140",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2937",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1870,
            "s_seats": 1756,
            "j_seats": 1836,
            "f_seats": 290,
            "r_seats": 130,
            "cargo_demand": 84330
        }
    },
    {
        "iata": "JPA",
        "icao": "SBJP",
        "airportName": "Presidente Castro Pinto International Airport",
        "location_country": "Brazil",
        "location_city": "Paraiba",
        "location_state": "Joio Pessoa",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.145833015",
        "longitude": "-34.94861221",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8251",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105549,
            "s_seats": 34970,
            "j_seats": 46138,
            "f_seats": 11804,
            "r_seats": 9069,
            "cargo_demand": 184646
        }
    },
    {
        "iata": "JPR",
        "icao": "SWJI",
        "airportName": "Jose Coleto Airport",
        "location_country": "Brazil",
        "location_city": "Rondonia",
        "location_state": "Ji-Parana",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.87080002",
        "longitude": "-61.8465004",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "5906",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2791,
            "s_seats": 2381,
            "j_seats": 1009,
            "f_seats": 483,
            "r_seats": 17,
            "cargo_demand": 1856
        }
    },
    {
        "iata": "JQA",
        "icao": "BGUQ",
        "airportName": "Qaarsut Airport",
        "location_country": "Greenland",
        "location_city": "Qaarsut",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "70.73419952",
        "longitude": "-52.69620132",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "2953",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1479,
            "s_seats": 2742,
            "j_seats": 1299,
            "f_seats": 487,
            "r_seats": 74,
            "cargo_demand": 78266
        }
    },
    {
        "iata": "JRF",
        "icao": "PHJR",
        "airportName": "Kalaeloa Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Kapalua",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.3074",
        "longitude": "-158.070009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "4500",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190382,
            "s_seats": 42783,
            "j_seats": 38597,
            "f_seats": 18631,
            "r_seats": 3838,
            "cargo_demand": 255447
        }
    },
    {
        "iata": "JRG",
        "icao": "VEJH",
        "airportName": "Veer Surendra Sai Airport",
        "location_country": "India",
        "location_city": "Odisha",
        "location_state": "Jharsuguda",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.9135",
        "longitude": "84.0504",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7844",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3030,
            "s_seats": 1623,
            "j_seats": 1016,
            "f_seats": 16,
            "r_seats": 199,
            "cargo_demand": 63718
        }
    },
    {
        "iata": "JRH",
        "icao": "VEJT",
        "airportName": "Jorhat Airport",
        "location_country": "India",
        "location_city": "Assam",
        "location_state": "Jorhat",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.73150063",
        "longitude": "94.17549896",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 50572,
            "s_seats": 37199,
            "j_seats": 26874,
            "f_seats": 3249,
            "r_seats": 9416,
            "cargo_demand": 145080
        }
    },
    {
        "iata": "JRN",
        "icao": "SWJU",
        "airportName": "Juruena Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Juruena",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-10.30583286",
        "longitude": "-58.48944473",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5381",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3755,
            "s_seats": 2609,
            "j_seats": 1933,
            "f_seats": 314,
            "r_seats": 146,
            "cargo_demand": 76967
        }
    },
    {
        "iata": "JRO",
        "icao": "HTKJ",
        "airportName": "Kilimanjaro International Airport",
        "location_country": "Tanzania",
        "location_city": "Kilimanjaro",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.42941",
        "longitude": "37.074501",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "11834",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177838,
            "s_seats": 33444,
            "j_seats": 21319,
            "f_seats": 15768,
            "r_seats": 3578,
            "cargo_demand": 209661
        }
    },
    {
        "iata": "JSA",
        "icao": "VIJR",
        "airportName": "Jaisalmer Airport",
        "location_country": "India",
        "location_city": "Rajasthan",
        "location_state": "Jaisalmer",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.88870049",
        "longitude": "70.86499786",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 143448,
            "s_seats": 47585,
            "j_seats": 36102,
            "f_seats": 7890,
            "r_seats": 5569,
            "cargo_demand": 70695
        }
    },
    {
        "iata": "JSH",
        "icao": "LGST",
        "airportName": "Sitia Public Airport",
        "location_country": "Greece",
        "location_city": "Sitia",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "35.21609879",
        "longitude": "26.10129929",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6804",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83383,
            "s_seats": 32510,
            "j_seats": 44403,
            "f_seats": 2184,
            "r_seats": 7043,
            "cargo_demand": 225088
        }
    },
    {
        "iata": "JSI",
        "icao": "LGSK",
        "airportName": "Skiathos Island National Airport",
        "location_country": "Greece",
        "location_city": "Skiathos",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "39.17710114",
        "longitude": "23.50370026",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5341",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196757,
            "s_seats": 45664,
            "j_seats": 19586,
            "f_seats": 17342,
            "r_seats": 3065,
            "cargo_demand": 94990
        }
    },
    {
        "iata": "JSM",
        "icao": "SAWS",
        "airportName": "Jose de San Martin Airport",
        "location_country": "Argentina",
        "location_city": "Chubut",
        "location_state": "Jose de San Martin",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-44.04859924",
        "longitude": "-70.45890045",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "GRE"
            },
            {
                "runway_name": "9/27",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1419,
            "s_seats": 1915,
            "j_seats": 1326,
            "f_seats": 251,
            "r_seats": 22,
            "cargo_demand": 15070
        }
    },
    {
        "iata": "JSR",
        "icao": "VGJR",
        "airportName": "Jessore Airport",
        "location_country": "Bangladesh",
        "location_city": "Jessore",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.1838",
        "longitude": "89.160797",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 164864,
            "s_seats": 33367,
            "j_seats": 19361,
            "f_seats": 4827,
            "r_seats": 3013,
            "cargo_demand": 56992
        }
    },
    {
        "iata": "JST",
        "icao": "KJST",
        "airportName": "John Murtha Johnstown-Cambria County Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Johnstown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.31610107",
        "longitude": "-78.83390045",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4386",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3700",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "7003",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66854,
            "s_seats": 32985,
            "j_seats": 36261,
            "f_seats": 16704,
            "r_seats": 7844,
            "cargo_demand": 216445
        }
    },
    {
        "iata": "JSU",
        "icao": "BGMQ",
        "airportName": "Maniitsoq Airport",
        "location_country": "Greenland",
        "location_city": "Maniitsoq",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "65.41249847",
        "longitude": "-52.93939972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "2621",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1930,
            "s_seats": 1661,
            "j_seats": 1607,
            "f_seats": 111,
            "r_seats": 101,
            "cargo_demand": 48136
        }
    },
    {
        "iata": "JSY",
        "icao": "LGSO",
        "airportName": "Syros Island National Airport",
        "location_country": "Greece",
        "location_city": "Syros",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "37.42279816",
        "longitude": "24.95089912",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3542",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2404,
            "s_seats": 1789,
            "j_seats": 1537,
            "f_seats": 272,
            "r_seats": 61,
            "cargo_demand": 4535
        }
    },
    {
        "iata": "JTC",
        "icao": "SBAE",
        "airportName": "Moussa Nakhl Tobias-Bauru/Arealva State Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Bauru",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "0.1",
        "longitude": "0.1",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6594",
                "runway_width": "148",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 1073,
            "s_seats": 2187,
            "j_seats": 1524,
            "f_seats": 87,
            "r_seats": 15,
            "cargo_demand": 89284
        }
    },
    {
        "iata": "JTI",
        "icao": "SWJW",
        "airportName": "Jatai Airport",
        "location_country": "Brazil",
        "location_city": "Goias",
        "location_state": "Jatai",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-17.82990074",
        "longitude": "-51.77299881",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4921",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1822,
            "s_seats": 2770,
            "j_seats": 1288,
            "f_seats": 281,
            "r_seats": 175,
            "cargo_demand": 79985
        }
    },
    {
        "iata": "JTR",
        "icao": "LGSR",
        "airportName": "Santorini (Thira) National Airport",
        "location_country": "Greece",
        "location_city": "Santorini (Thira)",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "36.39920044",
        "longitude": "25.47929955",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6972",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 112148,
            "s_seats": 44536,
            "j_seats": 43009,
            "f_seats": 14610,
            "r_seats": 9726,
            "cargo_demand": 430741
        }
    },
    {
        "iata": "JTY",
        "icao": "LGPL",
        "airportName": "Astypalaia Island National Airport",
        "location_country": "Greece",
        "location_city": "Astypalaia",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "36.57989883",
        "longitude": "26.37579918",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3245",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3319,
            "s_seats": 2859,
            "j_seats": 1050,
            "f_seats": 23,
            "r_seats": 140,
            "cargo_demand": 33151
        }
    },
    {
        "iata": "JUA",
        "icao": "SIZX",
        "airportName": "Inacio Luis do Nascimento Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Juara",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-11.2966",
        "longitude": "-57.5495",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3762,
            "s_seats": 2808,
            "j_seats": 1301,
            "f_seats": 409,
            "r_seats": 14,
            "cargo_demand": 83880
        }
    },
    {
        "iata": "JUI",
        "icao": "EDWJ",
        "airportName": "Juist Airport",
        "location_country": "Germany",
        "location_city": "Lower Saxony",
        "location_state": "Juist",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "53.68111038",
        "longitude": "7.055832863",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "1640",
                "runway_width": "98",
                "runway_material": "Grass"
            },
            {
                "runway_name": "07/25",
                "runway_length": "2296",
                "runway_width": "65",
                "runway_material": "Paved"
            },
            {
                "runway_name": "08G/26G",
                "runway_length": "1404",
                "runway_width": "98",
                "runway_material": "Grass"
            },
            {
                "runway_name": "12/30",
                "runway_length": "1558",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1848,
            "s_seats": 2725,
            "j_seats": 1956,
            "f_seats": 92,
            "r_seats": 9,
            "cargo_demand": 59935
        }
    },
    {
        "iata": "JUJ",
        "icao": "SASJ",
        "airportName": "Gobernador Horacio Guzman International Airport",
        "location_country": "Argentina",
        "location_city": "Jujuy",
        "location_state": "San Salvador de Jujuy",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-24.392799",
        "longitude": "-65.097801",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9698",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56177,
            "s_seats": 48206,
            "j_seats": 15017,
            "f_seats": 14767,
            "r_seats": 7464,
            "cargo_demand": 481465
        }
    },
    {
        "iata": "JUL",
        "icao": "SPJL",
        "airportName": "Inca Manco Capac International Airport",
        "location_country": "Peru",
        "location_city": "Juliaca",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.46710014",
        "longitude": "-70.15820313",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "13779",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159982,
            "s_seats": 44265,
            "j_seats": 19701,
            "f_seats": 4284,
            "r_seats": 5561,
            "cargo_demand": 429709
        }
    },
    {
        "iata": "JUM",
        "icao": "VNJL",
        "airportName": "Jumla Airport",
        "location_country": "Nepal",
        "location_city": "Jumla",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "29.2742",
        "longitude": "82.193298",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2165",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2904,
            "s_seats": 1830,
            "j_seats": 1200,
            "f_seats": 376,
            "r_seats": 28,
            "cargo_demand": 30406
        }
    },
    {
        "iata": "JUN",
        "icao": "YJDA",
        "airportName": "Jundah Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Jundah",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.8416996",
        "longitude": "143.0579987",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4498",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1761,
            "s_seats": 2492,
            "j_seats": 1118,
            "f_seats": 472,
            "r_seats": 18,
            "cargo_demand": 36788
        }
    },
    {
        "iata": "JUR",
        "icao": "YJNB",
        "airportName": "Jurien Bay Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Jurien Bay",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-30.301629",
        "longitude": "115.055914",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4265",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 3300,
            "s_seats": 1531,
            "j_seats": 1757,
            "f_seats": 298,
            "r_seats": 60,
            "cargo_demand": 65465
        }
    },
    {
        "iata": "JUV",
        "icao": "BGUK",
        "airportName": "Upernavik Airport",
        "location_country": "Greenland",
        "location_city": "Upernavik",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "72.79019928",
        "longitude": "-56.13059998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2621",
                "runway_width": "99",
                "runway_material": "ASPHALT"
            }
        ],
        "demand": {
            "y_seats": 3882,
            "s_seats": 1700,
            "j_seats": 1758,
            "f_seats": 41,
            "r_seats": 96,
            "cargo_demand": 45075
        }
    },
    {
        "iata": "JVL",
        "icao": "KJVL",
        "airportName": "Southern Wisconsin Regional Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Janesville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.62030029",
        "longitude": "-89.04160309",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6701",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "7300",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3870,
            "s_seats": 2895,
            "j_seats": 1128,
            "f_seats": 129,
            "r_seats": 122,
            "cargo_demand": 76808
        }
    },
    {
        "iata": "JWA",
        "icao": "FBJW",
        "airportName": "Jwaneng Airport",
        "location_country": "Botswana",
        "location_city": "Jwaneng",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-24.602301",
        "longitude": "24.691",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5495",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185350,
            "s_seats": 44706,
            "j_seats": 51763,
            "f_seats": 6973,
            "r_seats": 4569,
            "cargo_demand": 128853
        }
    },
    {
        "iata": "JWN",
        "icao": "OITZ",
        "airportName": "Zanjan Airport",
        "location_country": "Iran",
        "location_city": "Zanjan",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "36.77370071",
        "longitude": "48.3594017",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9840",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54262,
            "s_seats": 26453,
            "j_seats": 14757,
            "f_seats": 12689,
            "r_seats": 6455,
            "cargo_demand": 464437
        }
    },
    {
        "iata": "JWO",
        "icao": "RKTI",
        "airportName": "Jungwon Air Base",
        "location_country": "South Korea",
        "location_city": "Chungju",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.03024",
        "longitude": "127.886353",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "9021",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "9330",
                "runway_width": "120",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 73875,
            "s_seats": 41114,
            "j_seats": 31598,
            "f_seats": 3253,
            "r_seats": 9976,
            "cargo_demand": 160890
        }
    },
    {
        "iata": "JXN",
        "icao": "KJXN",
        "airportName": "Jackson County Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Jackson",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.25979996",
        "longitude": "-84.45939636",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5344",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3501",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189271,
            "s_seats": 49060,
            "j_seats": 27448,
            "f_seats": 5226,
            "r_seats": 5170,
            "cargo_demand": 158974
        }
    },
    {
        "iata": "JYR",
        "icao": "OIKJ",
        "airportName": "Jiroft Airport",
        "location_country": "Iran",
        "location_city": "Jiroft",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "28.7269001",
        "longitude": "57.67029953",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7236",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176528,
            "s_seats": 46333,
            "j_seats": 25347,
            "f_seats": 16999,
            "r_seats": 5640,
            "cargo_demand": 413836
        }
    },
    {
        "iata": "JYV",
        "icao": "EFJY",
        "airportName": "JyvAskylA Airport",
        "location_country": "Finland",
        "location_city": "JyvAskylA",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "62.399502",
        "longitude": "25.678301",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8533",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125596,
            "s_seats": 34965,
            "j_seats": 51488,
            "f_seats": 14592,
            "r_seats": 8526,
            "cargo_demand": 399098
        }
    },
    {
        "iata": "KAA",
        "icao": "FLKS",
        "airportName": "Kasama Airport",
        "location_country": "Zambia",
        "location_city": "Kasama",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.2166996",
        "longitude": "31.13330078",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6148",
                "runway_width": "",
                "runway_material": "Gravel"
            }
        ],
        "demand": {
            "y_seats": 1635,
            "s_seats": 2809,
            "j_seats": 1099,
            "f_seats": 400,
            "r_seats": 63,
            "cargo_demand": 73019
        }
    },
    {
        "iata": "KAB",
        "icao": "FVKB",
        "airportName": "Kariba Airport",
        "location_country": "Zimbabwe",
        "location_city": "Kariba",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.51980019",
        "longitude": "28.88500023",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5413",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177062,
            "s_seats": 46906,
            "j_seats": 47076,
            "f_seats": 2565,
            "r_seats": 3884,
            "cargo_demand": 346414
        }
    },
    {
        "iata": "KAC",
        "icao": "OSKL",
        "airportName": "Kamishly Airport",
        "location_country": "Syria",
        "location_city": "Qamishli (Kamishly)",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "37.020599",
        "longitude": "41.191399",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "11860",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159500,
            "s_seats": 36073,
            "j_seats": 25447,
            "f_seats": 16982,
            "r_seats": 6012,
            "cargo_demand": 394733
        }
    },
    {
        "iata": "KAD",
        "icao": "DNKA",
        "airportName": "Kaduna Airport",
        "location_country": "Nigeria",
        "location_city": "Kaduna",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.6960001",
        "longitude": "7.320109844",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9843",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193660,
            "s_seats": 47338,
            "j_seats": 6616,
            "f_seats": 18951,
            "r_seats": 9780,
            "cargo_demand": 158182
        }
    },
    {
        "iata": "KAG",
        "icao": "RKNN",
        "airportName": "Gangneung Air Base",
        "location_country": "South Korea",
        "location_city": "Gangneung",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.753601",
        "longitude": "128.943915",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 190361,
            "s_seats": 28357,
            "j_seats": 51594,
            "f_seats": 5208,
            "r_seats": 9520,
            "cargo_demand": 457311
        }
    },
    {
        "iata": "KAJ",
        "icao": "EFKI",
        "airportName": "Kajaani Airport",
        "location_country": "Finland",
        "location_city": "Kajaani",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "64.2855",
        "longitude": "27.6924",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8199",
                "runway_width": "157",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149150,
            "s_seats": 41432,
            "j_seats": 52038,
            "f_seats": 2382,
            "r_seats": 4647,
            "cargo_demand": 352091
        }
    },
    {
        "iata": "KAL",
        "icao": "PAKV",
        "airportName": "Kaltag Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kaltag",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "64.31909943",
        "longitude": "-158.7409973",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3986",
                "runway_width": "100",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 2136,
            "s_seats": 2685,
            "j_seats": 1886,
            "f_seats": 193,
            "r_seats": 111,
            "cargo_demand": 36883
        }
    },
    {
        "iata": "KAN",
        "icao": "DNKN",
        "airportName": "Mallam Aminu Kano International Airport",
        "location_country": "Nigeria",
        "location_city": "Kano",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "12.0476",
        "longitude": "8.52462",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8040",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "10831",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 460792,
            "s_seats": 97187,
            "j_seats": 61286,
            "f_seats": 53696,
            "r_seats": 10000,
            "cargo_demand": 6149129
        }
    },
    {
        "iata": "KAO",
        "icao": "EFKS",
        "airportName": "Kuusamo Airport",
        "location_country": "Finland",
        "location_city": "Kuusamo",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "65.987602",
        "longitude": "29.239401",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146714,
            "s_seats": 36148,
            "j_seats": 19166,
            "f_seats": 14765,
            "r_seats": 6933,
            "cargo_demand": 332337
        }
    },
    {
        "iata": "KAP",
        "icao": "FZSK",
        "airportName": "Kapanga Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Kapanga",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.350000381",
        "longitude": "22.58300018",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4035",
                "runway_width": "",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1948,
            "s_seats": 2859,
            "j_seats": 1697,
            "f_seats": 104,
            "r_seats": 93,
            "cargo_demand": 10622
        }
    },
    {
        "iata": "KAS",
        "icao": "FYKB",
        "airportName": "Karasburg Airport",
        "location_country": "Namibia",
        "location_city": "Karasburg",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "small_airport",
        "latitude": "-28.0297",
        "longitude": "18.7385",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4930",
                "runway_width": "",
                "runway_material": ""
            },
            {
                "runway_name": "17/35",
                "runway_length": "2750",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3480,
            "s_seats": 2173,
            "j_seats": 1818,
            "f_seats": 48,
            "r_seats": 48,
            "cargo_demand": 40305
        }
    },
    {
        "iata": "KAT",
        "icao": "NZKT",
        "airportName": "Kaitaia Airport",
        "location_country": "New Zealand",
        "location_city": "Kaitaia",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-35.06999969",
        "longitude": "173.2850037",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4600",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4190",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 179749,
            "s_seats": 49954,
            "j_seats": 15152,
            "f_seats": 9999,
            "r_seats": 7278,
            "cargo_demand": 173070
        }
    },
    {
        "iata": "KAU",
        "icao": "EFKA",
        "airportName": "Kauhava Airfield",
        "location_country": "Finland",
        "location_city": "Kauhava",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "63.127102",
        "longitude": "23.051399",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8858",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141053,
            "s_seats": 27000,
            "j_seats": 17721,
            "f_seats": 5034,
            "r_seats": 5133,
            "cargo_demand": 416490
        }
    },
    {
        "iata": "KAW",
        "icao": "VYKT",
        "airportName": "Kawthaung Airport",
        "location_country": "Myanmar",
        "location_city": "Kawthaung",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.04930019",
        "longitude": "98.53800201",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176877,
            "s_seats": 33061,
            "j_seats": 10517,
            "f_seats": 6563,
            "r_seats": 7372,
            "cargo_demand": 161944
        }
    },
    {
        "iata": "KAX",
        "icao": "YKBR",
        "airportName": "Kalbarri Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Kalbarri",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.692813",
        "longitude": "114.259169",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5246",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3489,
            "s_seats": 2850,
            "j_seats": 1104,
            "f_seats": 250,
            "r_seats": 78,
            "cargo_demand": 23240
        }
    },
    {
        "iata": "KAZ",
        "icao": "WAMK",
        "airportName": "Kuabang Airport",
        "location_country": "Indonesia",
        "location_city": "Kao",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "1.185279965",
        "longitude": "127.8960037",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "2963",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2891,
            "s_seats": 2947,
            "j_seats": 1247,
            "f_seats": 456,
            "r_seats": 34,
            "cargo_demand": 54703
        }
    },
    {
        "iata": "KBG",
        "icao": "HUKF",
        "airportName": "Kabalega Falls Airport",
        "location_country": "Uganda",
        "location_city": "Kabalega Falls",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "2.326563",
        "longitude": "31.497698",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5774",
                "runway_width": "98",
                "runway_material": "Murram"
            }
        ],
        "demand": {
            "y_seats": 3546,
            "s_seats": 2577,
            "j_seats": 1119,
            "f_seats": 338,
            "r_seats": 45,
            "cargo_demand": 60393
        }
    },
    {
        "iata": "KBI",
        "icao": "FKKB",
        "airportName": "Kribi Airport",
        "location_country": "Cameroon",
        "location_city": "Kribi",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "2.873889923",
        "longitude": "9.977780342",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5906",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2789,
            "s_seats": 1612,
            "j_seats": 1765,
            "f_seats": 4,
            "r_seats": 15,
            "cargo_demand": 30377
        }
    },
    {
        "iata": "KBJ",
        "icao": "YKCA",
        "airportName": "Kings Canyon Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Kings Canyon",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.26000023",
        "longitude": "131.4900055",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4908",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1757,
            "s_seats": 2019,
            "j_seats": 1305,
            "f_seats": 419,
            "r_seats": 78,
            "cargo_demand": 52184
        }
    },
    {
        "iata": "KBL",
        "icao": "OAKB",
        "airportName": "Hamid Karzai International Airport",
        "location_country": "Afghanistan",
        "location_city": "Kabul",
        "location_state": "",
        "time_zone": "UTC+04:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.565899",
        "longitude": "69.212303",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "11483",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166609,
            "s_seats": 37360,
            "j_seats": 23081,
            "f_seats": 7976,
            "r_seats": 6523,
            "cargo_demand": 172768
        }
    },
    {
        "iata": "KBP",
        "icao": "UKBB",
        "airportName": "Boryspil International Airport",
        "location_country": "Ukraine",
        "location_city": "Kyiv",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "50.34500122",
        "longitude": "30.8946991",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "11483",
                "runway_width": "206",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 423593,
            "s_seats": 81445,
            "j_seats": 30509,
            "f_seats": 63358,
            "r_seats": 10000,
            "cargo_demand": 13689457
        }
    },
    {
        "iata": "KBQ",
        "icao": "FWKG",
        "airportName": "Kasungu Airport",
        "location_country": "Malawi",
        "location_city": "Kasungu",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.0145998",
        "longitude": "33.46860123",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3937",
                "runway_width": "57",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1585,
            "s_seats": 2641,
            "j_seats": 1300,
            "f_seats": 68,
            "r_seats": 25,
            "cargo_demand": 98156
        }
    },
    {
        "iata": "KBR",
        "icao": "WMKC",
        "airportName": "Sultan Ismail Petra Airport",
        "location_country": "Malaysia",
        "location_city": "Kelantan",
        "location_state": "Kota Bharu",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.16685009",
        "longitude": "102.2929993",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6982",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169405,
            "s_seats": 34904,
            "j_seats": 31254,
            "f_seats": 11975,
            "r_seats": 8887,
            "cargo_demand": 334665
        }
    },
    {
        "iata": "KBV",
        "icao": "VTSG",
        "airportName": "Krabi Airport",
        "location_country": "Thailand",
        "location_city": "Krabi",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.09912014",
        "longitude": "98.98619843",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150198,
            "s_seats": 39818,
            "j_seats": 10515,
            "f_seats": 14585,
            "r_seats": 8368,
            "cargo_demand": 140584
        }
    },
    {
        "iata": "KBY",
        "icao": "YKBY",
        "airportName": "Streaky Bay Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Streaky Bay",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-32.83580017",
        "longitude": "134.2929993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2559",
                "runway_width": "59",
                "runway_material": "GVL"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4432",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2865,
            "s_seats": 2265,
            "j_seats": 1144,
            "f_seats": 176,
            "r_seats": 38,
            "cargo_demand": 16135
        }
    },
    {
        "iata": "KBZ",
        "icao": "NZKI",
        "airportName": "Kaikoura Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Kaikoura",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "small_airport",
        "latitude": "-42.42499924",
        "longitude": "173.6049957",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "2018",
                "runway_width": "82",
                "runway_material": "GRS"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "2297",
                "runway_width": "33",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1542,
            "s_seats": 1985,
            "j_seats": 1110,
            "f_seats": 22,
            "r_seats": 77,
            "cargo_demand": 16679
        }
    },
    {
        "iata": "KCE",
        "icao": "YCSV",
        "airportName": "Collinsville Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Collinsville",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.59670067",
        "longitude": "147.8600006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4599",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3841,
            "s_seats": 2922,
            "j_seats": 1895,
            "f_seats": 338,
            "r_seats": 148,
            "cargo_demand": 33105
        }
    },
    {
        "iata": "KCH",
        "icao": "WBGG",
        "airportName": "Kuching International Airport",
        "location_country": "Malaysia",
        "location_city": "Sarawak",
        "location_state": "Kuching",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.484699965",
        "longitude": "110.3470001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8051",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99464,
            "s_seats": 40047,
            "j_seats": 38145,
            "f_seats": 14358,
            "r_seats": 3858,
            "cargo_demand": 357561
        }
    },
    {
        "iata": "KCM",
        "icao": "LTCN",
        "airportName": "Kahramanmaras Airport",
        "location_country": "Turkey",
        "location_city": "Kahramanmaras",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.53882599",
        "longitude": "36.95352173",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7546",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50170,
            "s_seats": 33312,
            "j_seats": 10715,
            "f_seats": 17529,
            "r_seats": 7222,
            "cargo_demand": 294960
        }
    },
    {
        "iata": "KCO",
        "icao": "LTBQ",
        "airportName": "Cengiz Topel Naval Air Station",
        "location_country": "Turkey",
        "location_city": "Izmit",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.735001",
        "longitude": "30.0833",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9842",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186801,
            "s_seats": 43393,
            "j_seats": 21352,
            "f_seats": 1891,
            "r_seats": 3676,
            "cargo_demand": 95861
        }
    },
    {
        "iata": "KCS",
        "icao": "YKCS",
        "airportName": "Kings Creek Station Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Kings Creek Station",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.42329979",
        "longitude": "131.8350067",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5029",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2026,
            "s_seats": 2270,
            "j_seats": 1933,
            "f_seats": 69,
            "r_seats": 174,
            "cargo_demand": 13188
        }
    },
    {
        "iata": "KCT",
        "icao": "VCCK",
        "airportName": "Koggala Airport",
        "location_country": "Sri Lanka",
        "location_city": "Koggala",
        "location_state": "",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.99368",
        "longitude": "80.32029724",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3142",
                "runway_width": "140",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68758,
            "s_seats": 47272,
            "j_seats": 35247,
            "f_seats": 6641,
            "r_seats": 7044,
            "cargo_demand": 314626
        }
    },
    {
        "iata": "KCZ",
        "icao": "RJOK",
        "airportName": "Ko\u008dchi Ryo\u008dma Airport",
        "location_country": "Japan",
        "location_city": "Shikoku",
        "location_state": "Ko\u008dchi",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.546101",
        "longitude": "133.669006",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8203",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141492,
            "s_seats": 40163,
            "j_seats": 59307,
            "f_seats": 16785,
            "r_seats": 5141,
            "cargo_demand": 219333
        }
    },
    {
        "iata": "KDB",
        "icao": "YKBL",
        "airportName": "Kambalda Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Kambalda",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31.1907",
        "longitude": "121.5978",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3576",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2089,
            "s_seats": 2752,
            "j_seats": 1251,
            "f_seats": 459,
            "r_seats": 187,
            "cargo_demand": 58150
        }
    },
    {
        "iata": "KDD",
        "icao": "OPKH",
        "airportName": "Khuzdar Airport",
        "location_country": "Pakistan",
        "location_city": "Khuzdar",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.79059982",
        "longitude": "66.64730072",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6001",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2258,
            "s_seats": 2135,
            "j_seats": 1231,
            "f_seats": 392,
            "r_seats": 197,
            "cargo_demand": 20288
        }
    },
    {
        "iata": "KDH",
        "icao": "OAKN",
        "airportName": "Kandahar International Airport",
        "location_country": "Afghanistan",
        "location_city": "Kandahar",
        "location_state": "",
        "time_zone": "UTC+04:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.5058",
        "longitude": "65.847801",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10532",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87277,
            "s_seats": 47418,
            "j_seats": 34599,
            "f_seats": 6099,
            "r_seats": 8475,
            "cargo_demand": 148360
        }
    },
    {
        "iata": "KDI",
        "icao": "WAWW",
        "airportName": "Haluoleo Airport",
        "location_country": "Indonesia",
        "location_city": "Kendari",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-4.081610203",
        "longitude": "122.4179993",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6890",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2686,
            "s_seats": 1931,
            "j_seats": 1550,
            "f_seats": 349,
            "r_seats": 79,
            "cargo_demand": 43528
        }
    },
    {
        "iata": "KDK",
        "icao": "PAKD",
        "airportName": "Kodiak Municipal Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kodiak",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "57.80590057",
        "longitude": "-152.3739929",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2475",
                "runway_width": "40",
                "runway_material": "ASPH-GRVL-F"
            }
        ],
        "demand": {
            "y_seats": 3408,
            "s_seats": 1598,
            "j_seats": 1569,
            "f_seats": 22,
            "r_seats": 30,
            "cargo_demand": 98642
        }
    },
    {
        "iata": "KDL",
        "icao": "EEKA",
        "airportName": "KArdla Airport",
        "location_country": "Estonia",
        "location_city": "KArdla",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "58.99079895",
        "longitude": "22.83069992",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4987",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193668,
            "s_seats": 48348,
            "j_seats": 5061,
            "f_seats": 6575,
            "r_seats": 6241,
            "cargo_demand": 95182
        }
    },
    {
        "iata": "KDM",
        "icao": "VRMT",
        "airportName": "Kaadedhdhoo Airport",
        "location_country": "Maldives",
        "location_city": "Gaafu Dhaalu Atoll",
        "location_state": "Kaadedhdhoo Island",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.488130987",
        "longitude": "72.99690247",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4003",
                "runway_width": "98",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 144081,
            "s_seats": 39947,
            "j_seats": 46812,
            "f_seats": 19591,
            "r_seats": 5257,
            "cargo_demand": 144143
        }
    },
    {
        "iata": "KDO",
        "icao": "VRMK",
        "airportName": "Kadhdhoo Airport",
        "location_country": "Maldives",
        "location_city": "Laamu Atoll",
        "location_state": "Kadhdhoo Island",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.85916996",
        "longitude": "73.52189636",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4003",
                "runway_width": "98",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 154048,
            "s_seats": 44600,
            "j_seats": 34769,
            "f_seats": 18060,
            "r_seats": 6459,
            "cargo_demand": 141877
        }
    },
    {
        "iata": "KDT",
        "icao": "VTBK",
        "airportName": "Kamphaeng Saen Airport",
        "location_country": "Thailand",
        "location_city": "Kamphaeng Saen",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.10200024",
        "longitude": "99.91719818",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9000",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 109895,
            "s_seats": 42413,
            "j_seats": 6032,
            "f_seats": 4363,
            "r_seats": 8168,
            "cargo_demand": 73306
        }
    },
    {
        "iata": "KDU",
        "icao": "OPSD",
        "airportName": "Skardu Airport",
        "location_country": "Pakistan",
        "location_city": "Skardu",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.33549881",
        "longitude": "75.53600311",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "11944",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6501",
                "runway_width": "101",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76711,
            "s_seats": 43427,
            "j_seats": 8850,
            "f_seats": 6978,
            "r_seats": 6642,
            "cargo_demand": 92688
        }
    },
    {
        "iata": "KDX",
        "icao": "HSLI",
        "airportName": "Kadugli Airport",
        "location_country": "Sudan",
        "location_city": "Kaduqli (Kadugli)",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "11.13799953",
        "longitude": "29.7010994",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8375",
                "runway_width": "175",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1946,
            "s_seats": 1750,
            "j_seats": 1331,
            "f_seats": 405,
            "r_seats": 152,
            "cargo_demand": 90006
        }
    },
    {
        "iata": "KED",
        "icao": "GQNK",
        "airportName": "Kaedi Airport",
        "location_country": "Mauritania",
        "location_city": "Kaedi",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "16.1595",
        "longitude": "-13.5076",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2923,
            "s_seats": 2697,
            "j_seats": 1180,
            "f_seats": 279,
            "r_seats": 186,
            "cargo_demand": 77093
        }
    },
    {
        "iata": "KEF",
        "icao": "BIKF",
        "airportName": "Keflavik International Airport",
        "location_country": "Iceland",
        "location_city": "Reykjavik",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "63.985001",
        "longitude": "-22.6056",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10020",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "10056",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 314430,
            "s_seats": 99566,
            "j_seats": 69009,
            "f_seats": 67922,
            "r_seats": 10000,
            "cargo_demand": 12402565
        }
    },
    {
        "iata": "KEI",
        "icao": "WAKP",
        "airportName": "Kepi Airport",
        "location_country": "Indonesia",
        "location_city": "Kepi",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.5418",
        "longitude": "139.3318",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2005",
                "runway_width": "79",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3169,
            "s_seats": 2483,
            "j_seats": 1331,
            "f_seats": 329,
            "r_seats": 5,
            "cargo_demand": 86961
        }
    },
    {
        "iata": "KEJ",
        "icao": "UNEE",
        "airportName": "Kemerovo International Airport",
        "location_country": "Russia",
        "location_city": "Kemerovo Oblast",
        "location_state": "Kemerovo",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.27009964",
        "longitude": "86.10720062",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10499",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "05L/23R",
                "runway_length": "8655",
                "runway_width": "155",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105958,
            "s_seats": 42498,
            "j_seats": 55827,
            "f_seats": 13947,
            "r_seats": 5904,
            "cargo_demand": 415622
        }
    },
    {
        "iata": "KEL",
        "icao": "EDHK",
        "airportName": "Kiel Airport",
        "location_country": "Germany",
        "location_city": "Schleswig-Holstein",
        "location_state": "Kiel",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "54.379444",
        "longitude": "10.145278",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4134",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08L/26R",
                "runway_length": "1476",
                "runway_width": "115",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 121689,
            "s_seats": 48220,
            "j_seats": 21188,
            "f_seats": 8199,
            "r_seats": 8711,
            "cargo_demand": 54362
        }
    },
    {
        "iata": "KEM",
        "icao": "EFKE",
        "airportName": "Kemi-Tornio Airport",
        "location_country": "Finland",
        "location_city": "Kemi / Tornio",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "65.77870178",
        "longitude": "24.58209991",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8212",
                "runway_width": "157",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167342,
            "s_seats": 45308,
            "j_seats": 44383,
            "f_seats": 19321,
            "r_seats": 9949,
            "cargo_demand": 201108
        }
    },
    {
        "iata": "KEP",
        "icao": "VNNG",
        "airportName": "Nepalgunj Airport",
        "location_country": "Nepal",
        "location_city": "Nepalgunj",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.10359955",
        "longitude": "81.66699982",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4935",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54429,
            "s_seats": 45331,
            "j_seats": 29847,
            "f_seats": 9687,
            "r_seats": 5370,
            "cargo_demand": 194093
        }
    },
    {
        "iata": "KER",
        "icao": "OIKK",
        "airportName": "Kerman Airport",
        "location_country": "Iran",
        "location_city": "Kerman",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "30.27440071",
        "longitude": "56.9510994",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6657",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "12620",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106164,
            "s_seats": 26846,
            "j_seats": 32239,
            "f_seats": 6176,
            "r_seats": 6650,
            "cargo_demand": 419009
        }
    },
    {
        "iata": "KES",
        "icao": "CZEE",
        "airportName": "Kelsey Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Kelsey",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "56.03749847",
        "longitude": "-96.50969696",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2590",
                "runway_width": "75",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 50722,
            "s_seats": 31764,
            "j_seats": 31860,
            "f_seats": 14198,
            "r_seats": 9293,
            "cargo_demand": 236551
        }
    },
    {
        "iata": "KET",
        "icao": "VYKG",
        "airportName": "Kengtung Airport",
        "location_country": "Myanmar",
        "location_city": "Kengtung",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.3015995",
        "longitude": "99.63600159",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7815",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162721,
            "s_seats": 29023,
            "j_seats": 55108,
            "f_seats": 17733,
            "r_seats": 6534,
            "cargo_demand": 234635
        }
    },
    {
        "iata": "KEV",
        "icao": "EFHA",
        "airportName": "Halli Airport",
        "location_country": "Finland",
        "location_city": "Kuorevesi",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "61.856039",
        "longitude": "24.786686",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8533",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161841,
            "s_seats": 35934,
            "j_seats": 9520,
            "f_seats": 2782,
            "r_seats": 6236,
            "cargo_demand": 320878
        }
    },
    {
        "iata": "KFA",
        "icao": "GQNF",
        "airportName": "Kiffa Airport",
        "location_country": "Mauritania",
        "location_city": "Kiffa",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "16.59",
        "longitude": "-11.4062",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5208",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1566,
            "s_seats": 1756,
            "j_seats": 1977,
            "f_seats": 37,
            "r_seats": 72,
            "cargo_demand": 37982
        }
    },
    {
        "iata": "KFE",
        "icao": "YFDF",
        "airportName": "Fortescue Dave Forrest Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Cloudbreak",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.290754",
        "longitude": "119.437143",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7546",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1729,
            "s_seats": 2289,
            "j_seats": 1041,
            "f_seats": 78,
            "r_seats": 127,
            "cargo_demand": 3505
        }
    },
    {
        "iata": "KFG",
        "icao": "YKKG",
        "airportName": "Kalkgurung Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Kalkarindji",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.43190002",
        "longitude": "130.8079987",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4101",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3038",
                "runway_width": "60",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 3195,
            "s_seats": 2206,
            "j_seats": 1982,
            "f_seats": 121,
            "r_seats": 21,
            "cargo_demand": 75436
        }
    },
    {
        "iata": "KFP",
        "icao": "PAKF",
        "airportName": "False Pass Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "False Pass",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "54.84740067",
        "longitude": "-163.4100037",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2100",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 3778,
            "s_seats": 2728,
            "j_seats": 1980,
            "f_seats": 54,
            "r_seats": 102,
            "cargo_demand": 97543
        }
    },
    {
        "iata": "KFS",
        "icao": "LTAL",
        "airportName": "Kastamonu Airport",
        "location_country": "Turkey",
        "location_city": "Kastamonu",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.31420135",
        "longitude": "33.79579926",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7382",
                "runway_width": "148",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 2370,
            "s_seats": 2502,
            "j_seats": 1423,
            "f_seats": 180,
            "r_seats": 75,
            "cargo_demand": 38504
        }
    },
    {
        "iata": "KFZ",
        "icao": "LAKU",
        "airportName": "Kukes International Airport",
        "location_country": "Albania",
        "location_city": "Kukes",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "42.035802",
        "longitude": "20.415955",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7211",
                "runway_width": "98",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 103659,
            "s_seats": 49297,
            "j_seats": 8249,
            "f_seats": 19899,
            "r_seats": 6278,
            "cargo_demand": 249527
        }
    },
    {
        "iata": "KGA",
        "icao": "FZUA",
        "airportName": "Kananga Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Kananga",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.900050163",
        "longitude": "22.46920013",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163311,
            "s_seats": 35770,
            "j_seats": 9457,
            "f_seats": 19715,
            "r_seats": 8810,
            "cargo_demand": 360772
        }
    },
    {
        "iata": "KGB",
        "icao": "AYOE",
        "airportName": "Konge Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Konge",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.2239",
        "longitude": "147.2152",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2014",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3851,
            "s_seats": 2988,
            "j_seats": 1946,
            "f_seats": 172,
            "r_seats": 27,
            "cargo_demand": 47138
        }
    },
    {
        "iata": "KGC",
        "icao": "YKSC",
        "airportName": "Kingscote Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Cygnet River",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-35.71390152",
        "longitude": "137.5209961",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4600",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "3720",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3819",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 82733,
            "s_seats": 42739,
            "j_seats": 10511,
            "f_seats": 2518,
            "r_seats": 7929,
            "cargo_demand": 468686
        }
    },
    {
        "iata": "KGD",
        "icao": "UMKK",
        "airportName": "Khrabrovo Airport",
        "location_country": "Russia",
        "location_city": "Kaliningrad Oblast",
        "location_state": "Kaliningrad",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.88999939",
        "longitude": "20.59259987",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10991",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 166063,
            "s_seats": 33404,
            "j_seats": 24786,
            "f_seats": 16163,
            "r_seats": 3286,
            "cargo_demand": 297230
        }
    },
    {
        "iata": "KGE",
        "icao": "AGKG",
        "airportName": "Kaghau Airport",
        "location_country": "Solomon Islands",
        "location_city": "Kaghau Island",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.3305",
        "longitude": "157.585",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2428",
                "runway_width": "148",
                "runway_material": "Sand/grass"
            }
        ],
        "demand": {
            "y_seats": 3179,
            "s_seats": 1845,
            "j_seats": 1586,
            "f_seats": 454,
            "r_seats": 6,
            "cargo_demand": 9451
        }
    },
    {
        "iata": "KGF",
        "icao": "UAKK",
        "airportName": "Sary-Arka Airport",
        "location_country": "Kazakhstan",
        "location_city": "Karaganda",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "49.67079926",
        "longitude": "73.33439636",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10831",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 214464,
            "s_seats": 83164,
            "j_seats": 71076,
            "f_seats": 43567,
            "r_seats": 10000,
            "cargo_demand": 18580040
        }
    },
    {
        "iata": "KGG",
        "icao": "GOTK",
        "airportName": "Kedougou Airport",
        "location_country": "Senegal",
        "location_city": "Kedougou",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.57229996",
        "longitude": "-12.22029972",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5906",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134720,
            "s_seats": 32196,
            "j_seats": 40808,
            "f_seats": 14879,
            "r_seats": 6808,
            "cargo_demand": 170447
        }
    },
    {
        "iata": "KGI",
        "icao": "YPKG",
        "airportName": "Kalgoorlie-Boulder Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Kalgoorlie",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-30.7894001",
        "longitude": "121.461998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3936",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129691,
            "s_seats": 34873,
            "j_seats": 46114,
            "f_seats": 13043,
            "r_seats": 4905,
            "cargo_demand": 380005
        }
    },
    {
        "iata": "KGJ",
        "icao": "FWKA",
        "airportName": "Karonga Airport",
        "location_country": "Malawi",
        "location_city": "Karonga",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.953570366",
        "longitude": "33.89300156",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4200",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102734,
            "s_seats": 32593,
            "j_seats": 38376,
            "f_seats": 13524,
            "r_seats": 9108,
            "cargo_demand": 204306
        }
    },
    {
        "iata": "KGK",
        "icao": "PAJZ",
        "airportName": "Koliganek Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Koliganek",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "59.72660065",
        "longitude": "-157.2590027",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "GRVL-F"
            }
        ],
        "demand": {
            "y_seats": 2803,
            "s_seats": 1619,
            "j_seats": 1672,
            "f_seats": 381,
            "r_seats": 81,
            "cargo_demand": 73485
        }
    },
    {
        "iata": "KGL",
        "icao": "HRYR",
        "airportName": "Kigali International Airport",
        "location_country": "Rwanda",
        "location_city": "Kigali",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-1.96863",
        "longitude": "30.1395",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145765,
            "s_seats": 92900,
            "j_seats": 133825,
            "f_seats": 64332,
            "r_seats": 10000,
            "cargo_demand": 10112055
        }
    },
    {
        "iata": "KGP",
        "icao": "USRK",
        "airportName": "Kogalym International Airport",
        "location_country": "Russia",
        "location_city": "Khanty-Mansi Autonomous Okrug",
        "location_state": "Kogalym",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.19039917",
        "longitude": "74.53379822",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8225",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 84553,
            "s_seats": 42090,
            "j_seats": 36084,
            "f_seats": 17994,
            "r_seats": 8593,
            "cargo_demand": 425011
        }
    },
    {
        "iata": "KGS",
        "icao": "LGKO",
        "airportName": "Kos Island International Airport",
        "location_country": "Greece",
        "location_city": "Kos",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "36.79330063",
        "longitude": "27.0916996",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7841",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50882,
            "s_seats": 27159,
            "j_seats": 31422,
            "f_seats": 5438,
            "r_seats": 3168,
            "cargo_demand": 245264
        }
    },
    {
        "iata": "KGT",
        "icao": "ZUKD",
        "airportName": "Kangding Airport",
        "location_country": "China",
        "location_city": "Sichuan",
        "location_state": "Kangding",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.142464",
        "longitude": "101.73872",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "13123",
                "runway_width": "172",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 79700,
            "s_seats": 47867,
            "j_seats": 58791,
            "f_seats": 16364,
            "r_seats": 8051,
            "cargo_demand": 270220
        }
    },
    {
        "iata": "KGY",
        "icao": "YKRY",
        "airportName": "Kingaroy Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Kingaroy",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.5807991",
        "longitude": "151.8410034",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4275",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5249",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198179,
            "s_seats": 35043,
            "j_seats": 44137,
            "f_seats": 4527,
            "r_seats": 5658,
            "cargo_demand": 245467
        }
    },
    {
        "iata": "KHC",
        "icao": "UKFK",
        "airportName": "Kerch Airport",
        "location_country": "Ukraine",
        "location_city": "Kerch",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "45.37250137",
        "longitude": "36.40140152",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5420",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3144,
            "s_seats": 2504,
            "j_seats": 1851,
            "f_seats": 116,
            "r_seats": 123,
            "cargo_demand": 25119
        }
    },
    {
        "iata": "KHD",
        "icao": "OICK",
        "airportName": "Khorramabad Airport",
        "location_country": "Iran",
        "location_city": "Khorramabad",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "33.4353981",
        "longitude": "48.28290176",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10498",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144064,
            "s_seats": 35399,
            "j_seats": 35173,
            "f_seats": 11828,
            "r_seats": 7167,
            "cargo_demand": 200324
        }
    },
    {
        "iata": "KHE",
        "icao": "UKOH",
        "airportName": "Kherson International Airport",
        "location_country": "Ukraine",
        "location_city": "Kherson",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.6758",
        "longitude": "32.506401",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8202",
                "runway_width": "138",
                "runway_material": "CONC"
            }
        ],
        "demand": {
            "y_seats": 50633,
            "s_seats": 46146,
            "j_seats": 11199,
            "f_seats": 3976,
            "r_seats": 3488,
            "cargo_demand": 391886
        }
    },
    {
        "iata": "KHG",
        "icao": "ZWSH",
        "airportName": "Kashgar Airport",
        "location_country": "China",
        "location_city": "Xinjiang",
        "location_state": "Kashgar",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.54290009",
        "longitude": "76.01999664",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10499",
                "runway_width": "135",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 79748,
            "s_seats": 38676,
            "j_seats": 10701,
            "f_seats": 13832,
            "r_seats": 4233,
            "cargo_demand": 349993
        }
    },
    {
        "iata": "KHH",
        "icao": "RCKH",
        "airportName": "Kaohsiung International Airport",
        "location_country": "Taiwan",
        "location_city": "Kaohsiung",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "22.577101",
        "longitude": "120.349998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10335",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 418890,
            "s_seats": 85771,
            "j_seats": 73302,
            "f_seats": 69050,
            "r_seats": 10000,
            "cargo_demand": 11179988
        }
    },
    {
        "iata": "KHI",
        "icao": "OPKC",
        "airportName": "Jinnah International Airport",
        "location_country": "Pakistan",
        "location_city": "Karachi",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.9065",
        "longitude": "67.160797",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "10500",
                "runway_width": "151",
                "runway_material": "CON"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "11155",
                "runway_width": "151",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 51074,
            "s_seats": 44667,
            "j_seats": 44453,
            "f_seats": 4505,
            "r_seats": 9621,
            "cargo_demand": 71737
        }
    },
    {
        "iata": "KHJ",
        "icao": "EFKJ",
        "airportName": "Kauhajoki Airfield",
        "location_country": "Finland",
        "location_city": "Kauhajoki",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "62.462502",
        "longitude": "22.393101",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3806",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174655,
            "s_seats": 45813,
            "j_seats": 38184,
            "f_seats": 5254,
            "r_seats": 4270,
            "cargo_demand": 178551
        }
    },
    {
        "iata": "KHK",
        "icao": "OIBQ",
        "airportName": "Kharg Airport",
        "location_country": "Iran",
        "location_city": "Kharg Island",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "29.26029968",
        "longitude": "50.32389832",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5922",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183311,
            "s_seats": 46133,
            "j_seats": 56439,
            "f_seats": 11893,
            "r_seats": 3838,
            "cargo_demand": 214061
        }
    },
    {
        "iata": "KHN",
        "icao": "ZSCN",
        "airportName": "Nanchang Changbei International Airport",
        "location_country": "China",
        "location_city": "Jiangxi",
        "location_state": "Nanchang",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.86499977",
        "longitude": "115.9000015",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 76685,
            "s_seats": 41756,
            "j_seats": 50822,
            "f_seats": 14021,
            "r_seats": 8328,
            "cargo_demand": 272401
        }
    },
    {
        "iata": "KHR",
        "icao": "ZMHH",
        "airportName": "Kharkhorin Airport",
        "location_country": "Mongolia",
        "location_city": "Kharkhorin",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Sep",
        "airport_size": "small_airport",
        "latitude": "47.246667",
        "longitude": "102.826111",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5905",
                "runway_width": "",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1955,
            "s_seats": 1622,
            "j_seats": 1228,
            "f_seats": 492,
            "r_seats": 18,
            "cargo_demand": 51974
        }
    },
    {
        "iata": "KHS",
        "icao": "OOKB",
        "airportName": "Khasab Airport",
        "location_country": "Oman",
        "location_city": "Khasab",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.17099953",
        "longitude": "56.24060059",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8202",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139882,
            "s_seats": 41791,
            "j_seats": 39699,
            "f_seats": 17312,
            "r_seats": 4300,
            "cargo_demand": 105829
        }
    },
    {
        "iata": "KHV",
        "icao": "UHHH",
        "airportName": "Khabarovsk Novy Airport",
        "location_country": "Russia",
        "location_city": "Khabarovsk Krai",
        "location_state": "Khabarovsk",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "48.528338",
        "longitude": "135.188588",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "13124",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 383468,
            "s_seats": 94947,
            "j_seats": 22230,
            "f_seats": 50978,
            "r_seats": 10000,
            "cargo_demand": 21517107
        }
    },
    {
        "iata": "KHY",
        "icao": "OITK",
        "airportName": "Khoy Airport",
        "location_country": "Iran",
        "location_city": "Khoy",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "small_airport",
        "latitude": "38.42750168",
        "longitude": "44.97359848",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "9190",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3375,
            "s_seats": 2915,
            "j_seats": 1427,
            "f_seats": 374,
            "r_seats": 147,
            "cargo_demand": 90226
        }
    },
    {
        "iata": "KIC",
        "icao": "KKIC",
        "airportName": "Mesa Del Rey Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "King City",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.22800064",
        "longitude": "-121.1220016",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4485",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1799,
            "s_seats": 2064,
            "j_seats": 1698,
            "f_seats": 148,
            "r_seats": 175,
            "cargo_demand": 55073
        }
    },
    {
        "iata": "KID",
        "icao": "ESMK",
        "airportName": "Kristianstad Airport",
        "location_country": "Sweden",
        "location_city": "Kristianstad",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "55.92169952",
        "longitude": "14.08549976",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7267",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97167,
            "s_seats": 25478,
            "j_seats": 8069,
            "f_seats": 15929,
            "r_seats": 8167,
            "cargo_demand": 480902
        }
    },
    {
        "iata": "KIE",
        "icao": "AYIQ",
        "airportName": "Aropa Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Kieta",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.305417",
        "longitude": "155.728139",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5397",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2111,
            "s_seats": 2107,
            "j_seats": 1490,
            "f_seats": 275,
            "r_seats": 126,
            "cargo_demand": 42940
        }
    },
    {
        "iata": "KIH",
        "icao": "OIBK",
        "airportName": "Kish International Airport",
        "location_country": "Iran",
        "location_city": "Kish Island",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "26.52619934",
        "longitude": "53.98020172",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "12004",
                "runway_width": "215",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "12004",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108579,
            "s_seats": 27535,
            "j_seats": 43222,
            "f_seats": 9135,
            "r_seats": 5010,
            "cargo_demand": 458674
        }
    },
    {
        "iata": "KIJ",
        "icao": "RJSN",
        "airportName": "Niigata Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Niigata",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.95589828",
        "longitude": "139.1210022",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4300",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "8200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69930,
            "s_seats": 49439,
            "j_seats": 17225,
            "f_seats": 4488,
            "r_seats": 6060,
            "cargo_demand": 217022
        }
    },
    {
        "iata": "KIK",
        "icao": "ORKK",
        "airportName": "Kirkuk Airport",
        "location_country": "Iraq",
        "location_city": "Kirkuk",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.4695015",
        "longitude": "44.34889984",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9809",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8535",
                "runway_width": "160",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62783,
            "s_seats": 39785,
            "j_seats": 33284,
            "f_seats": 15848,
            "r_seats": 7135,
            "cargo_demand": 118067
        }
    },
    {
        "iata": "KIM",
        "icao": "FAKM",
        "airportName": "Kimberley Airport",
        "location_country": "South Africa",
        "location_city": "Kimberley",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.80279922",
        "longitude": "24.76519966",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9843",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "8002",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167106,
            "s_seats": 27198,
            "j_seats": 52275,
            "f_seats": 15956,
            "r_seats": 8547,
            "cargo_demand": 363289
        }
    },
    {
        "iata": "KIN",
        "icao": "MKJP",
        "airportName": "Norman Manley International Airport",
        "location_country": "Jamaica",
        "location_city": "Kingston",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "17.93569946",
        "longitude": "-76.78749847",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8900",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 331211,
            "s_seats": 92882,
            "j_seats": 105262,
            "f_seats": 23215,
            "r_seats": 10000,
            "cargo_demand": 17536023
        }
    },
    {
        "iata": "KIP",
        "icao": "KCWC",
        "airportName": "Kickapoo Downtown Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Wichita Falls",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.85779953",
        "longitude": "-98.49040222",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4450",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2297,
            "s_seats": 1933,
            "j_seats": 1651,
            "f_seats": 197,
            "r_seats": 67,
            "cargo_demand": 24397
        }
    },
    {
        "iata": "KIR",
        "icao": "EIKY",
        "airportName": "Kerry Airport (Farranfore Airport)",
        "location_country": "Ireland",
        "location_city": "Kerry",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "52.18090057",
        "longitude": "-9.523779869",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4035",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193473,
            "s_seats": 41651,
            "j_seats": 10312,
            "f_seats": 2733,
            "r_seats": 4377,
            "cargo_demand": 269223
        }
    },
    {
        "iata": "KIS",
        "icao": "HKKI",
        "airportName": "Kisumu International Airport",
        "location_country": "Kenya",
        "location_city": "Kisumu",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.086139001",
        "longitude": "34.72890091",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6511",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77275,
            "s_seats": 48775,
            "j_seats": 19429,
            "f_seats": 5916,
            "r_seats": 3620,
            "cargo_demand": 125103
        }
    },
    {
        "iata": "KIT",
        "icao": "LGKC",
        "airportName": "Kithira Island National Airport",
        "location_country": "Greece",
        "location_city": "Kythira",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "36.27429962",
        "longitude": "23.0170002",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4794",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3714,
            "s_seats": 2620,
            "j_seats": 1950,
            "f_seats": 85,
            "r_seats": 80,
            "cargo_demand": 7951
        }
    },
    {
        "iata": "KIV",
        "icao": "LUKK",
        "airportName": "Chi\u0219in\u0103u International Airport",
        "location_country": "Moldova",
        "location_city": "Chi\u0219in\u0103u",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.92770004",
        "longitude": "28.93099976",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11778",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "09/27",
                "runway_length": "7818",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 84027,
            "s_seats": 26856,
            "j_seats": 58902,
            "f_seats": 2915,
            "r_seats": 9783,
            "cargo_demand": 184251
        }
    },
    {
        "iata": "KIW",
        "icao": "FLSO",
        "airportName": "Southdowns Airport",
        "location_country": "Zambia",
        "location_city": "Kitwe",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.9005003",
        "longitude": "28.14990044",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6562",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109730,
            "s_seats": 34003,
            "j_seats": 47343,
            "f_seats": 13316,
            "r_seats": 8622,
            "cargo_demand": 89526
        }
    },
    {
        "iata": "KIX",
        "icao": "RJBB",
        "airportName": "Kansai International Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Osaka",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "34.427299",
        "longitude": "135.244003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "13123",
                "runway_width": "196",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "11483",
                "runway_width": "196",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 348441,
            "s_seats": 80669,
            "j_seats": 160457,
            "f_seats": 57820,
            "r_seats": 10000,
            "cargo_demand": 22820951
        }
    },
    {
        "iata": "KJA",
        "icao": "UNKL",
        "airportName": "Yemelyanovo International Airport",
        "location_country": "Russia",
        "location_city": "Krasnoyarsk Krai",
        "location_state": "Krasnoyarsk",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "56.173077",
        "longitude": "92.492437",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "12139",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 462197,
            "s_seats": 95971,
            "j_seats": 26239,
            "f_seats": 62339,
            "r_seats": 10000,
            "cargo_demand": 10492570
        }
    },
    {
        "iata": "KJK",
        "icao": "EBKT",
        "airportName": "Kortrijk-Wevelgem International Airport",
        "location_country": "Belgium",
        "location_city": "Kortrijk",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.8172",
        "longitude": "3.20472",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6234",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52503,
            "s_seats": 35140,
            "j_seats": 10186,
            "f_seats": 19638,
            "r_seats": 3381,
            "cargo_demand": 485783
        }
    },
    {
        "iata": "KKA",
        "icao": "PAKK",
        "airportName": "Koyuk Alfred Adams Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Koyuk",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "64.9394989",
        "longitude": "-161.154007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 3792,
            "s_seats": 2812,
            "j_seats": 1207,
            "f_seats": 27,
            "r_seats": 101,
            "cargo_demand": 84794
        }
    },
    {
        "iata": "KKC",
        "icao": "VTUK",
        "airportName": "Khon Kaen Airport",
        "location_country": "Thailand",
        "location_city": "Khon Kaen",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.46660042",
        "longitude": "102.7839966",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 164801,
            "s_seats": 47449,
            "j_seats": 49456,
            "f_seats": 8623,
            "r_seats": 7443,
            "cargo_demand": 495250
        }
    },
    {
        "iata": "KKE",
        "icao": "NZKK",
        "airportName": "Kerikeri Airport",
        "location_country": "New Zealand",
        "location_city": "Kerikeri",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-35.26279831",
        "longitude": "173.9120026",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "1608",
                "runway_width": "121",
                "runway_material": "GRS"
            },
            {
                "runway_name": "15/33",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "15L/33R",
                "runway_length": "2247",
                "runway_width": "66",
                "runway_material": "GRS"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "3904",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142263,
            "s_seats": 40874,
            "j_seats": 53881,
            "f_seats": 16526,
            "r_seats": 6827,
            "cargo_demand": 396207
        }
    },
    {
        "iata": "KKH",
        "icao": "PADY",
        "airportName": "Kongiganak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kongiganak",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "59.96080017",
        "longitude": "-162.8809967",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "1885",
                "runway_width": "35",
                "runway_material": "GRVL-P"
            }
        ],
        "demand": {
            "y_seats": 2402,
            "s_seats": 1510,
            "j_seats": 1453,
            "f_seats": 74,
            "r_seats": 86,
            "cargo_demand": 85497
        }
    },
    {
        "iata": "KKJ",
        "icao": "RJFR",
        "airportName": "Kitakyushu Airport",
        "location_country": "Japan",
        "location_city": "Kyushu",
        "location_state": "Kitakyushu",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.845901",
        "longitude": "131.035004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8202",
                "runway_width": "260",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 195455,
            "s_seats": 40614,
            "j_seats": 56959,
            "f_seats": 1809,
            "r_seats": 4869,
            "cargo_demand": 341530
        }
    },
    {
        "iata": "KKM",
        "icao": "VTBL",
        "airportName": "Khok Kathiam Air Force Base",
        "location_country": "Thailand",
        "location_city": "Lopburi",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.87460041",
        "longitude": "100.663002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4435",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "10/28",
                "runway_length": "600",
                "runway_width": "64",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7252",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151616,
            "s_seats": 37144,
            "j_seats": 55003,
            "f_seats": 10626,
            "r_seats": 6677,
            "cargo_demand": 137662
        }
    },
    {
        "iata": "KKN",
        "icao": "ENKR",
        "airportName": "Kirkenes Airport/c/ Hoybuktmoen",
        "location_country": "Norway",
        "location_city": "Kirkenes",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "69.72579956",
        "longitude": "29.8913002",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6939",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130088,
            "s_seats": 49498,
            "j_seats": 16995,
            "f_seats": 2863,
            "r_seats": 5924,
            "cargo_demand": 412826
        }
    },
    {
        "iata": "KKP",
        "icao": "YKLB",
        "airportName": "Koolburra Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Koolburra",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.31890011",
        "longitude": "143.9550018",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 2348,
            "s_seats": 2592,
            "j_seats": 1884,
            "f_seats": 41,
            "r_seats": 87,
            "cargo_demand": 33352
        }
    },
    {
        "iata": "KKR",
        "icao": "NTGK",
        "airportName": "Kaukura Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Kaukura Atoll",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.6633",
        "longitude": "-146.884995",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3543",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125118,
            "s_seats": 34484,
            "j_seats": 14771,
            "f_seats": 14093,
            "r_seats": 3742,
            "cargo_demand": 495403
        }
    },
    {
        "iata": "KKS",
        "icao": "OIFK",
        "airportName": "Kashan Airport",
        "location_country": "Iran",
        "location_city": "Isfahan",
        "location_state": "Kashan",
        "time_zone": "UTC+03:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.895302",
        "longitude": "51.577",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8845",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63360,
            "s_seats": 48864,
            "j_seats": 39992,
            "f_seats": 13982,
            "r_seats": 8334,
            "cargo_demand": 310989
        }
    },
    {
        "iata": "KKW",
        "icao": "FZCA",
        "airportName": "Kikwit Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Kikwit",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.03577",
        "longitude": "18.785601",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5151",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145116,
            "s_seats": 33664,
            "j_seats": 27338,
            "f_seats": 4179,
            "r_seats": 8760,
            "cargo_demand": 485975
        }
    },
    {
        "iata": "KKX",
        "icao": "RJKI",
        "airportName": "Kikai Airport",
        "location_country": "Japan",
        "location_city": "Satsunan Islands",
        "location_state": "Kikai",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.32130051",
        "longitude": "129.9279938",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4307",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132259,
            "s_seats": 34551,
            "j_seats": 10265,
            "f_seats": 18981,
            "r_seats": 3491,
            "cargo_demand": 137268
        }
    },
    {
        "iata": "KKY",
        "icao": "EIKK",
        "airportName": "Kilkenny Airport",
        "location_country": "Ireland",
        "location_city": "Kilkenny",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "52.6507988",
        "longitude": "-7.296110153",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3051",
                "runway_width": "75",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3764,
            "s_seats": 1640,
            "j_seats": 1839,
            "f_seats": 309,
            "r_seats": 19,
            "cargo_demand": 90057
        }
    },
    {
        "iata": "KLC",
        "icao": "GOOK",
        "airportName": "Kaolack Airport",
        "location_country": "Senegal",
        "location_city": "Kaolack",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.14690018",
        "longitude": "-16.05130005",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5249",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76983,
            "s_seats": 43795,
            "j_seats": 41716,
            "f_seats": 12056,
            "r_seats": 7939,
            "cargo_demand": 354202
        }
    },
    {
        "iata": "KLD",
        "icao": "UUEM",
        "airportName": "Migalovo Air Base",
        "location_country": "Russia",
        "location_city": "Tver Oblast",
        "location_state": "Tver",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.8246994",
        "longitude": "35.75770187",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8202",
                "runway_width": "160",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 199193,
            "s_seats": 29393,
            "j_seats": 32062,
            "f_seats": 8175,
            "r_seats": 4596,
            "cargo_demand": 125104
        }
    },
    {
        "iata": "KLG",
        "icao": "PALG",
        "airportName": "Kalskag Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kalskag",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "61.53630066",
        "longitude": "-160.3410034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3172",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 3417,
            "s_seats": 2234,
            "j_seats": 1798,
            "f_seats": 343,
            "r_seats": 164,
            "cargo_demand": 88508
        }
    },
    {
        "iata": "KLH",
        "icao": "VAKP",
        "airportName": "Kolhapur Airport",
        "location_country": "India",
        "location_city": "Maharashtra",
        "location_state": "Kolhapur",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.66469955",
        "longitude": "74.28939819",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4495",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67682,
            "s_seats": 26450,
            "j_seats": 20714,
            "f_seats": 16648,
            "r_seats": 5525,
            "cargo_demand": 106049
        }
    },
    {
        "iata": "KLI",
        "icao": "FZFP",
        "airportName": "Kotakoli Air Base",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Kotakoli",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "4.15763998",
        "longitude": "21.65089989",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6626",
                "runway_width": "134",
                "runway_material": "LAT"
            }
        ],
        "demand": {
            "y_seats": 2440,
            "s_seats": 2475,
            "j_seats": 1491,
            "f_seats": 476,
            "r_seats": 98,
            "cargo_demand": 29000
        }
    },
    {
        "iata": "KLM",
        "icao": "OINE",
        "airportName": "Kalaleh Airport",
        "location_country": "Iran",
        "location_city": "Kalaleh",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "small_airport",
        "latitude": "37.38330078",
        "longitude": "55.45199966",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7277",
                "runway_width": "92",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3203,
            "s_seats": 2583,
            "j_seats": 1926,
            "f_seats": 307,
            "r_seats": 179,
            "cargo_demand": 84336
        }
    },
    {
        "iata": "KLN",
        "icao": "PALB",
        "airportName": "Larsen Bay Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Larsen Bay",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "57.535099",
        "longitude": "-153.977993",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2690",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 1231,
            "s_seats": 2116,
            "j_seats": 1731,
            "f_seats": 400,
            "r_seats": 160,
            "cargo_demand": 3813
        }
    },
    {
        "iata": "KLO",
        "icao": "RPVK",
        "airportName": "Kalibo International Airport",
        "location_country": "Philippines",
        "location_city": "Kalibo",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.67940044",
        "longitude": "122.3759995",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7175",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 71905,
            "s_seats": 48642,
            "j_seats": 12616,
            "f_seats": 7626,
            "r_seats": 3408,
            "cargo_demand": 416232
        }
    },
    {
        "iata": "KLR",
        "icao": "ESMQ",
        "airportName": "Kalmar Airport",
        "location_country": "Sweden",
        "location_city": "Kalmar",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "56.685501",
        "longitude": "16.2876",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2179",
                "runway_width": "131",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6726",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169707,
            "s_seats": 48451,
            "j_seats": 20227,
            "f_seats": 6510,
            "r_seats": 5175,
            "cargo_demand": 459113
        }
    },
    {
        "iata": "KLS",
        "icao": "KKLS",
        "airportName": "Southwest Washington Regional Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Kelso",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.11800003",
        "longitude": "-122.8980026",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4391",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116686,
            "s_seats": 39041,
            "j_seats": 27330,
            "f_seats": 1832,
            "r_seats": 5032,
            "cargo_demand": 317102
        }
    },
    {
        "iata": "KLU",
        "icao": "LOWK",
        "airportName": "Klagenfurt Airport",
        "location_country": "Austria",
        "location_city": "Klagenfurt",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.642502",
        "longitude": "14.3377",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "8924",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "2427",
                "runway_width": "90",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 104955,
            "s_seats": 36538,
            "j_seats": 25181,
            "f_seats": 10697,
            "r_seats": 3826,
            "cargo_demand": 102584
        }
    },
    {
        "iata": "KLV",
        "icao": "LKKV",
        "airportName": "Karlovy Vary Airport",
        "location_country": "Czech Republic",
        "location_city": "Karlovy Vary",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.20299911",
        "longitude": "12.91499996",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7054",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2624",
                "runway_width": "59",
                "runway_material": "grass"
            }
        ],
        "demand": {
            "y_seats": 153332,
            "s_seats": 41697,
            "j_seats": 54943,
            "f_seats": 12593,
            "r_seats": 3387,
            "cargo_demand": 289333
        }
    },
    {
        "iata": "KLW",
        "icao": "PAKW",
        "airportName": "Klawock Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Klawock",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "55.57920074",
        "longitude": "-133.076004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199185,
            "s_seats": 40135,
            "j_seats": 38110,
            "f_seats": 18168,
            "r_seats": 5949,
            "cargo_demand": 433457
        }
    },
    {
        "iata": "KLX",
        "icao": "LGKL",
        "airportName": "Kalamata International Airport",
        "location_country": "Greece",
        "location_city": "Kalamata",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "37.06829834",
        "longitude": "22.02549934",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17R/35L",
                "runway_length": "8868",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153246,
            "s_seats": 29781,
            "j_seats": 59417,
            "f_seats": 1698,
            "r_seats": 9121,
            "cargo_demand": 48816
        }
    },
    {
        "iata": "KLZ",
        "icao": "FAKZ",
        "airportName": "Kleinzee Airport",
        "location_country": "South Africa",
        "location_city": "Kleinzee",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.68840027",
        "longitude": "17.09399986",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113072,
            "s_seats": 26404,
            "j_seats": 6142,
            "f_seats": 8187,
            "r_seats": 7567,
            "cargo_demand": 126878
        }
    },
    {
        "iata": "KMA",
        "icao": "AYKM",
        "airportName": "Kerema Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Kerema",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.963610172",
        "longitude": "145.7709961",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3044",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112534,
            "s_seats": 40475,
            "j_seats": 23550,
            "f_seats": 17165,
            "r_seats": 4387,
            "cargo_demand": 376561
        }
    },
    {
        "iata": "KMC",
        "icao": "OEKK",
        "airportName": "King Khaled Military City Airport",
        "location_country": "Saudi Arabia",
        "location_city": "King Khalid Military City",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.9009",
        "longitude": "45.528198",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "12005",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181336,
            "s_seats": 33962,
            "j_seats": 9761,
            "f_seats": 6988,
            "r_seats": 4742,
            "cargo_demand": 51944
        }
    },
    {
        "iata": "KME",
        "icao": "HRZA",
        "airportName": "Kamembe Airport",
        "location_country": "Rwanda",
        "location_city": "Cyangugu",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.462239981",
        "longitude": "28.90789986",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4921",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134002,
            "s_seats": 49815,
            "j_seats": 38539,
            "f_seats": 7224,
            "r_seats": 9419,
            "cargo_demand": 270389
        }
    },
    {
        "iata": "KMG",
        "icao": "ZPPP",
        "airportName": "Kunming Changshui International Airport",
        "location_country": "China",
        "location_city": "Yunnan",
        "location_state": "Kunming",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "25.1019444",
        "longitude": "102.9291667",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "04/22",
                "runway_length": "14764",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154400,
            "s_seats": 86458,
            "j_seats": 31362,
            "f_seats": 38957,
            "r_seats": 10000,
            "cargo_demand": 10413972
        }
    },
    {
        "iata": "KMH",
        "icao": "FAKU",
        "airportName": "Johan Pienaar Airport",
        "location_country": "South Africa",
        "location_city": "Kuruman",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.45669937",
        "longitude": "23.41139984",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5578",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139696,
            "s_seats": 36215,
            "j_seats": 49776,
            "f_seats": 16074,
            "r_seats": 3811,
            "cargo_demand": 129642
        }
    },
    {
        "iata": "KMI",
        "icao": "RJFM",
        "airportName": "Miyazaki Airport",
        "location_country": "Japan",
        "location_city": "Kyushu",
        "location_state": "Miyazaki",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.87719917",
        "longitude": "131.4490051",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197794,
            "s_seats": 39728,
            "j_seats": 12247,
            "f_seats": 10490,
            "r_seats": 5401,
            "cargo_demand": 211143
        }
    },
    {
        "iata": "KMJ",
        "icao": "RJFT",
        "airportName": "Kumamoto Airport",
        "location_country": "Japan",
        "location_city": "Kyushu",
        "location_state": "Mashiki",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.83729935",
        "longitude": "130.8549957",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9840",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 91725,
            "s_seats": 47530,
            "j_seats": 51830,
            "f_seats": 3625,
            "r_seats": 4386,
            "cargo_demand": 329220
        }
    },
    {
        "iata": "KML",
        "icao": "YKML",
        "airportName": "Kamileroi Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Kamileroi",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-19.375",
        "longitude": "140.0570068",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5275",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 2871,
            "s_seats": 2412,
            "j_seats": 1786,
            "f_seats": 345,
            "r_seats": 186,
            "cargo_demand": 58341
        }
    },
    {
        "iata": "KMO",
        "icao": "PAMB",
        "airportName": "Manokotak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Manokotak",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "58.99020004",
        "longitude": "-159.0500031",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3300",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 2744,
            "s_seats": 1907,
            "j_seats": 1828,
            "f_seats": 96,
            "r_seats": 8,
            "cargo_demand": 75050
        }
    },
    {
        "iata": "KMP",
        "icao": "FYKT",
        "airportName": "Keetmanshoop Airport",
        "location_country": "Namibia",
        "location_city": "Keetmanshoop",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-26.53980064",
        "longitude": "18.1114006",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7598",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4705",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 95357,
            "s_seats": 46334,
            "j_seats": 39555,
            "f_seats": 14989,
            "r_seats": 6986,
            "cargo_demand": 329561
        }
    },
    {
        "iata": "KMQ",
        "icao": "RJNK",
        "airportName": "Komatsu Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Komatsu",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.3946",
        "longitude": "136.406998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8860",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "06T/24T",
                "runway_length": "8876",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171548,
            "s_seats": 31656,
            "j_seats": 40786,
            "f_seats": 10274,
            "r_seats": 5941,
            "cargo_demand": 158222
        }
    },
    {
        "iata": "KMR",
        "icao": "AYRI",
        "airportName": "Karimui Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Karimui",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.4921",
        "longitude": "144.823",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2900",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2706,
            "s_seats": 1720,
            "j_seats": 1942,
            "f_seats": 237,
            "r_seats": 127,
            "cargo_demand": 83867
        }
    },
    {
        "iata": "KMS",
        "icao": "DGSI",
        "airportName": "Kumasi Airport",
        "location_country": "Ghana",
        "location_city": "Kumasi",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.714560032",
        "longitude": "-1.590819955",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6502",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192226,
            "s_seats": 29689,
            "j_seats": 56844,
            "f_seats": 11444,
            "r_seats": 4402,
            "cargo_demand": 380358
        }
    },
    {
        "iata": "KMU",
        "icao": "HCMK",
        "airportName": "Kismayo Airport",
        "location_country": "Somalia",
        "location_city": "Kismayo",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.377353",
        "longitude": "42.459202",
        "region": "AF",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "12139",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152521,
            "s_seats": 25236,
            "j_seats": 30934,
            "f_seats": 2766,
            "r_seats": 8238,
            "cargo_demand": 215537
        }
    },
    {
        "iata": "KMV",
        "icao": "VYKL",
        "airportName": "Kalaymyo Airport",
        "location_country": "Myanmar",
        "location_city": "Kalaymyo",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "23.18880081",
        "longitude": "94.05110168",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5502",
                "runway_width": "102",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 1287,
            "s_seats": 1693,
            "j_seats": 1962,
            "f_seats": 245,
            "r_seats": 137,
            "cargo_demand": 40613
        }
    },
    {
        "iata": "KMX",
        "icao": "OEKM",
        "airportName": "King Khalid Air Base",
        "location_country": "Saudi Arabia",
        "location_city": "Khamis Mushait",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.2973",
        "longitude": "42.803501",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "12467",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "12467",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104132,
            "s_seats": 32418,
            "j_seats": 10918,
            "f_seats": 10690,
            "r_seats": 8718,
            "cargo_demand": 256518
        }
    },
    {
        "iata": "KNA",
        "icao": "SCVM",
        "airportName": "Vina del Mar Airport",
        "location_country": "Chile",
        "location_city": "Vina del Mar",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-32.9496",
        "longitude": "-71.4786",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5760",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199172,
            "s_seats": 48164,
            "j_seats": 8904,
            "f_seats": 19513,
            "r_seats": 6944,
            "cargo_demand": 230328
        }
    },
    {
        "iata": "KNB",
        "icao": "KKNB",
        "airportName": "Kanab Municipal Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Kanab",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.01110077",
        "longitude": "-112.5309982",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6193",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1770,
            "s_seats": 2250,
            "j_seats": 1800,
            "f_seats": 95,
            "r_seats": 77,
            "cargo_demand": 79495
        }
    },
    {
        "iata": "KND",
        "icao": "FZOA",
        "airportName": "Kindu Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Kindu",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.919179916",
        "longitude": "25.91539955",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145219,
            "s_seats": 44345,
            "j_seats": 9429,
            "f_seats": 6347,
            "r_seats": 3416,
            "cargo_demand": 257434
        }
    },
    {
        "iata": "KNE",
        "icao": "AYKJ",
        "airportName": "Kanainj Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Kanainj",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.2903",
        "longitude": "144.7072",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "1273",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2451,
            "s_seats": 2704,
            "j_seats": 1678,
            "f_seats": 107,
            "r_seats": 156,
            "cargo_demand": 96025
        }
    },
    {
        "iata": "KNF",
        "icao": "EGYM",
        "airportName": "RAF Marham",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "King's Lynn",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "52.648395",
        "longitude": "0.550692",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6086",
                "runway_width": "300",
                "runway_material": "CON"
            },
            {
                "runway_name": "06/24",
                "runway_length": "9140",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162572,
            "s_seats": 39510,
            "j_seats": 43390,
            "f_seats": 11861,
            "r_seats": 3057,
            "cargo_demand": 484101
        }
    },
    {
        "iata": "KNG",
        "icao": "WASK",
        "airportName": "Kaimana Airport",
        "location_country": "Indonesia",
        "location_city": "Kaimana",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.644520044",
        "longitude": "133.6959991",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5249",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151957,
            "s_seats": 36995,
            "j_seats": 37843,
            "f_seats": 1626,
            "r_seats": 5278,
            "cargo_demand": 480508
        }
    },
    {
        "iata": "KNH",
        "icao": "RCBS",
        "airportName": "Kinmen Airport",
        "location_country": "Taiwan",
        "location_city": "Kinmen",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "24.4279",
        "longitude": "118.359001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9865",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 298237,
            "s_seats": 87672,
            "j_seats": 156459,
            "f_seats": 51160,
            "r_seats": 10000,
            "cargo_demand": 17419169
        }
    },
    {
        "iata": "KNI",
        "icao": "YKNG",
        "airportName": "Katanning Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Katanning",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-33.71670151",
        "longitude": "117.6330032",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "07/25",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3970,
            "s_seats": 1839,
            "j_seats": 1596,
            "f_seats": 76,
            "r_seats": 13,
            "cargo_demand": 1105
        }
    },
    {
        "iata": "KNK",
        "icao": "PFKK",
        "airportName": "Kokhanok Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kokhanok",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "59.43320084",
        "longitude": "-154.8040009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3300",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 2239,
            "s_seats": 2722,
            "j_seats": 1573,
            "f_seats": 181,
            "r_seats": 181,
            "cargo_demand": 54943
        }
    },
    {
        "iata": "KNN",
        "icao": "GUXN",
        "airportName": "Kankan Airport",
        "location_country": "Guinea",
        "location_city": "Kankan",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "10.448438",
        "longitude": "-9.228757",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9843",
                "runway_width": "144",
                "runway_material": "ASP/GVL"
            }
        ],
        "demand": {
            "y_seats": 2182,
            "s_seats": 2466,
            "j_seats": 1569,
            "f_seats": 137,
            "r_seats": 36,
            "cargo_demand": 62978
        }
    },
    {
        "iata": "KNO",
        "icao": "WIMM",
        "airportName": "Kualanamu International Airport",
        "location_country": "Indonesia",
        "location_city": "Medan",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "3.64177",
        "longitude": "98.885307",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "12303",
                "runway_width": "",
                "runway_material": "asphalt/concrete"
            }
        ],
        "demand": {
            "y_seats": 354234,
            "s_seats": 84554,
            "j_seats": 89039,
            "f_seats": 35988,
            "r_seats": 10000,
            "cargo_demand": 2020210
        }
    },
    {
        "iata": "KNQ",
        "icao": "NWWD",
        "airportName": "Kone Airport",
        "location_country": "New Caledonia",
        "location_city": "Kone",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.05430031",
        "longitude": "164.8370056",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3281",
                "runway_width": "66",
                "runway_material": "MAC"
            }
        ],
        "demand": {
            "y_seats": 105852,
            "s_seats": 39228,
            "j_seats": 21121,
            "f_seats": 18228,
            "r_seats": 3876,
            "cargo_demand": 192775
        }
    },
    {
        "iata": "KNR",
        "icao": "OIBJ",
        "airportName": "Jam Airport",
        "location_country": "Iran",
        "location_city": "Kangan",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "27.82049942",
        "longitude": "52.35219955",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7711",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76367,
            "s_seats": 37102,
            "j_seats": 16112,
            "f_seats": 16302,
            "r_seats": 3851,
            "cargo_demand": 261551
        }
    },
    {
        "iata": "KNS",
        "icao": "YKII",
        "airportName": "King Island Airport",
        "location_country": "Australia",
        "location_city": "Tasmania",
        "location_state": "King Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-39.87749863",
        "longitude": "143.878006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2626",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5198",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3632",
                "runway_width": "98",
                "runway_material": "COM"
            }
        ],
        "demand": {
            "y_seats": 140342,
            "s_seats": 39117,
            "j_seats": 29165,
            "f_seats": 19274,
            "r_seats": 5465,
            "cargo_demand": 174389
        }
    },
    {
        "iata": "KNT",
        "icao": "KTKX",
        "airportName": "Kennett Memorial Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Kennett",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.22589874",
        "longitude": "-90.03659821",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3012",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1340,
            "s_seats": 3000,
            "j_seats": 1697,
            "f_seats": 286,
            "r_seats": 9,
            "cargo_demand": 67246
        }
    },
    {
        "iata": "KNU",
        "icao": "VIKA",
        "airportName": "Kanpur Airport",
        "location_country": "India",
        "location_city": "Uttar Pradesh",
        "location_state": "Kanpur",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "26.441401",
        "longitude": "80.364899",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3685",
                "runway_width": "135",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3300,
            "s_seats": 2145,
            "j_seats": 1204,
            "f_seats": 139,
            "r_seats": 134,
            "cargo_demand": 59862
        }
    },
    {
        "iata": "KNW",
        "icao": "PANW",
        "airportName": "New Stuyahok Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "New Stuyahok",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "59.44990158",
        "longitude": "-157.3280029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3281",
                "runway_width": "98",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 1620,
            "s_seats": 2802,
            "j_seats": 1908,
            "f_seats": 212,
            "r_seats": 183,
            "cargo_demand": 77113
        }
    },
    {
        "iata": "KNX",
        "icao": "YPKU",
        "airportName": "East Kimberley Regional Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Kununurra",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.77810001",
        "longitude": "128.7079926",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6000",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129760,
            "s_seats": 32596,
            "j_seats": 31554,
            "f_seats": 10426,
            "r_seats": 4789,
            "cargo_demand": 435680
        }
    },
    {
        "iata": "KOA",
        "icao": "PHKO",
        "airportName": "Kona International Airport at Ke\u0101hole",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Kailua / Kona",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.738783",
        "longitude": "-156.045603",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "11000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94605,
            "s_seats": 40976,
            "j_seats": 5655,
            "f_seats": 11532,
            "r_seats": 6662,
            "cargo_demand": 282541
        }
    },
    {
        "iata": "KOC",
        "icao": "NWWK",
        "airportName": "Koumac Airport",
        "location_country": "New Caledonia",
        "location_city": "Koumac",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.54630089",
        "longitude": "164.2559967",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3616",
                "runway_width": "85",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50939,
            "s_seats": 41755,
            "j_seats": 48086,
            "f_seats": 7853,
            "r_seats": 5562,
            "cargo_demand": 126846
        }
    },
    {
        "iata": "KOE",
        "icao": "WATT",
        "airportName": "El Tari Airport",
        "location_country": "Indonesia",
        "location_city": "Kupang",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.17160034",
        "longitude": "123.6709976",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4175",
                "runway_width": "210",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 3735,
            "s_seats": 2739,
            "j_seats": 1937,
            "f_seats": 165,
            "r_seats": 135,
            "cargo_demand": 27636
        }
    },
    {
        "iata": "KOF",
        "icao": "FAKP",
        "airportName": "Komatipoort Airport",
        "location_country": "South Africa",
        "location_city": "Komatipoort",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.44029999",
        "longitude": "31.93000031",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2175,
            "s_seats": 2068,
            "j_seats": 1172,
            "f_seats": 60,
            "r_seats": 85,
            "cargo_demand": 80802
        }
    },
    {
        "iata": "KOH",
        "icao": "YKLA",
        "airportName": "Koolatah Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Koolatah",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.88860035",
        "longitude": "142.4389954",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2296",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1007,
            "s_seats": 2742,
            "j_seats": 1207,
            "f_seats": 168,
            "r_seats": 125,
            "cargo_demand": 94241
        }
    },
    {
        "iata": "KOI",
        "icao": "EGPA",
        "airportName": "Kirkwall Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Kirkwall",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "58.95780182",
        "longitude": "-2.904999971",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3881",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4685",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2231",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183673,
            "s_seats": 36279,
            "j_seats": 14679,
            "f_seats": 15489,
            "r_seats": 4644,
            "cargo_demand": 103974
        }
    },
    {
        "iata": "KOJ",
        "icao": "RJFK",
        "airportName": "Kagoshima Airport",
        "location_country": "Japan",
        "location_city": "Kyushu",
        "location_state": "Kagoshima",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "31.80340004",
        "longitude": "130.7189941",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9840",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 377871,
            "s_seats": 85940,
            "j_seats": 78886,
            "f_seats": 60474,
            "r_seats": 10000,
            "cargo_demand": 7304912
        }
    },
    {
        "iata": "KOK",
        "icao": "EFKK",
        "airportName": "Kokkola-Pietarsaari Airport",
        "location_country": "Finland",
        "location_city": "Kokkola / Jakobstad",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "63.721199",
        "longitude": "23.143101",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8202",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2297",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64875,
            "s_seats": 48954,
            "j_seats": 12995,
            "f_seats": 11801,
            "r_seats": 7834,
            "cargo_demand": 335566
        }
    },
    {
        "iata": "KOO",
        "icao": "FZRQ",
        "airportName": "Kongolo Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Kongolo",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.39444",
        "longitude": "26.99",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6234",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3514,
            "s_seats": 2479,
            "j_seats": 1216,
            "f_seats": 138,
            "r_seats": 190,
            "cargo_demand": 96618
        }
    },
    {
        "iata": "KOP",
        "icao": "VTUW",
        "airportName": "Nakhon Phanom Airport",
        "location_country": "Thailand",
        "location_city": "Nakhon Phanom",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.38380051",
        "longitude": "104.6429977",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "8203",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124205,
            "s_seats": 31436,
            "j_seats": 27781,
            "f_seats": 15899,
            "r_seats": 3673,
            "cargo_demand": 162438
        }
    },
    {
        "iata": "KOQ",
        "icao": "EDCK",
        "airportName": "Kothen Airport",
        "location_country": "Germany",
        "location_city": "Saxony-Anhalt",
        "location_state": "Kothen",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "51.7211113",
        "longitude": "11.95277786",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2625",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3182,
            "s_seats": 1882,
            "j_seats": 1444,
            "f_seats": 136,
            "r_seats": 104,
            "cargo_demand": 71309
        }
    },
    {
        "iata": "KOS",
        "icao": "VDSV",
        "airportName": "Sihanoukville International Airport",
        "location_country": "Cambodia",
        "location_city": "Sihanoukville",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "10.5797",
        "longitude": "103.637001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8202",
                "runway_width": "112",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2858,
            "s_seats": 2338,
            "j_seats": 1334,
            "f_seats": 369,
            "r_seats": 10,
            "cargo_demand": 95965
        }
    },
    {
        "iata": "KOT",
        "icao": "PFKO",
        "airportName": "Kotlik Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kotlik",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "63.0306015",
        "longitude": "-163.5330048",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4422",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 2176,
            "s_seats": 2020,
            "j_seats": 1760,
            "f_seats": 278,
            "r_seats": 188,
            "cargo_demand": 36183
        }
    },
    {
        "iata": "KOU",
        "icao": "FOGK",
        "airportName": "Koulamoutou Airport",
        "location_country": "Gabon",
        "location_city": "Koulamoutou",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.184610009",
        "longitude": "12.44130039",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5841",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 107757,
            "s_seats": 31858,
            "j_seats": 40655,
            "f_seats": 18841,
            "r_seats": 8188,
            "cargo_demand": 135198
        }
    },
    {
        "iata": "KOV",
        "icao": "UACK",
        "airportName": "Kokshetau Airport",
        "location_country": "Kazakhstan",
        "location_city": "Kokshetau",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.329102",
        "longitude": "69.594597",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8325",
                "runway_width": "125",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136630,
            "s_seats": 26841,
            "j_seats": 42658,
            "f_seats": 8608,
            "r_seats": 5819,
            "cargo_demand": 472956
        }
    },
    {
        "iata": "KOX",
        "icao": "WABN",
        "airportName": "Kokonao Airport",
        "location_country": "Indonesia",
        "location_city": "Kokonao",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-4.71075",
        "longitude": "136.43515",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "1969",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1829,
            "s_seats": 2028,
            "j_seats": 1452,
            "f_seats": 347,
            "r_seats": 125,
            "cargo_demand": 57411
        }
    },
    {
        "iata": "KPC",
        "icao": "PAPC",
        "airportName": "Port Clarence Coast Guard Station",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Port Clarence",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "65.25370026",
        "longitude": "-166.8589935",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4500",
                "runway_width": "120",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 71871,
            "s_seats": 44754,
            "j_seats": 51545,
            "f_seats": 14543,
            "r_seats": 5487,
            "cargo_demand": 133587
        }
    },
    {
        "iata": "KPI",
        "icao": "WBGP",
        "airportName": "Kapit Airport",
        "location_country": "Malaysia",
        "location_city": "Sarawak",
        "location_state": "Kapit",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "2.010523745",
        "longitude": "112.9314682",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "1401",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1383,
            "s_seats": 2658,
            "j_seats": 1488,
            "f_seats": 125,
            "r_seats": 156,
            "cargo_demand": 9348
        }
    },
    {
        "iata": "KPM",
        "icao": "AYAQ",
        "airportName": "Kompiam Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Kompiam",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.381666667",
        "longitude": "143.9247222",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2559",
                "runway_width": "59",
                "runway_material": "Grey gravel"
            }
        ],
        "demand": {
            "y_seats": 1877,
            "s_seats": 2761,
            "j_seats": 1454,
            "f_seats": 212,
            "r_seats": 20,
            "cargo_demand": 78466
        }
    },
    {
        "iata": "KPN",
        "icao": "PAKI",
        "airportName": "Kipnuk Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kipnuk",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "59.93299866",
        "longitude": "-164.0310059",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2120",
                "runway_width": "35",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 2920,
            "s_seats": 2084,
            "j_seats": 1128,
            "f_seats": 304,
            "r_seats": 75,
            "cargo_demand": 85257
        }
    },
    {
        "iata": "KPO",
        "icao": "RKTH",
        "airportName": "Pohang Airport",
        "location_country": "South Korea",
        "location_city": "Pohang",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.987955",
        "longitude": "129.420383",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 103994,
            "s_seats": 33025,
            "j_seats": 47351,
            "f_seats": 18610,
            "r_seats": 3497,
            "cargo_demand": 303272
        }
    },
    {
        "iata": "KPP",
        "icao": "YKPR",
        "airportName": "Kalpowar Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Kalpowar",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.89",
        "longitude": "144.22",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3402,
            "s_seats": 1933,
            "j_seats": 1157,
            "f_seats": 472,
            "r_seats": 170,
            "cargo_demand": 94864
        }
    },
    {
        "iata": "KPS",
        "icao": "YKMP",
        "airportName": "Kempsey Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Kempsey",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-31.07439995",
        "longitude": "152.7700043",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5413",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2014",
                "runway_width": "60",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 191122,
            "s_seats": 39851,
            "j_seats": 34460,
            "f_seats": 14507,
            "r_seats": 9164,
            "cargo_demand": 64685
        }
    },
    {
        "iata": "KPV",
        "icao": "PAPE",
        "airportName": "Perryville Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Perryville",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "55.905725",
        "longitude": "-159.162188",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3300",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 3789,
            "s_seats": 2790,
            "j_seats": 1977,
            "f_seats": 118,
            "r_seats": 112,
            "cargo_demand": 88128
        }
    },
    {
        "iata": "KQA",
        "icao": "PAUT",
        "airportName": "Akutan Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Akutan",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "54.14459",
        "longitude": "-165.604332",
        "region": "NA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "4500",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1272,
            "s_seats": 2226,
            "j_seats": 1937,
            "f_seats": 449,
            "r_seats": 57,
            "cargo_demand": 67078
        }
    },
    {
        "iata": "KQH",
        "icao": "VIKG",
        "airportName": "Ajmer Kishangarh Airport",
        "location_country": "India",
        "location_city": "Rajasthan",
        "location_state": "Kishangarh",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.591007",
        "longitude": "74.812956",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7060",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 100539,
            "s_seats": 48897,
            "j_seats": 45747,
            "f_seats": 2967,
            "r_seats": 3145,
            "cargo_demand": 401478
        }
    },
    {
        "iata": "KRA",
        "icao": "YKER",
        "airportName": "Kerang Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Kerang",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-35.75139999",
        "longitude": "143.9389954",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2267",
                "runway_width": "60",
                "runway_material": "GVL"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3501",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62949,
            "s_seats": 39030,
            "j_seats": 12365,
            "f_seats": 5909,
            "r_seats": 7067,
            "cargo_demand": 172600
        }
    },
    {
        "iata": "KRB",
        "icao": "YKMB",
        "airportName": "Karumba Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Karumba",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.45669937",
        "longitude": "140.8300018",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4169",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 2777,
            "s_seats": 2669,
            "j_seats": 1396,
            "f_seats": 239,
            "r_seats": 4,
            "cargo_demand": 14400
        }
    },
    {
        "iata": "KRF",
        "icao": "ESNK",
        "airportName": "Hoga Kusten Airport",
        "location_country": "Sweden",
        "location_city": "Kramfors / Sollefteio",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "63.048599",
        "longitude": "17.7689",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6565",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134741,
            "s_seats": 36932,
            "j_seats": 26351,
            "f_seats": 17407,
            "r_seats": 4659,
            "cargo_demand": 123269
        }
    },
    {
        "iata": "KRK",
        "icao": "EPKK",
        "airportName": "John Paul II International Airport Krakow-Balice",
        "location_country": "Poland",
        "location_city": "Krakow",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "50.077702",
        "longitude": "19.7848",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8366",
                "runway_width": "196",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 232939,
            "s_seats": 87706,
            "j_seats": 106021,
            "f_seats": 40856,
            "r_seats": 10000,
            "cargo_demand": 17056325
        }
    },
    {
        "iata": "KRN",
        "icao": "ESNQ",
        "airportName": "Kiruna Airport",
        "location_country": "Sweden",
        "location_city": "Kiruna",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "67.8219986",
        "longitude": "20.33679962",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8209",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62401,
            "s_seats": 31798,
            "j_seats": 34546,
            "f_seats": 16104,
            "r_seats": 4884,
            "cargo_demand": 322768
        }
    },
    {
        "iata": "KRO",
        "icao": "USUU",
        "airportName": "Kurgan Airport",
        "location_country": "Russia",
        "location_city": "Kurgan Oblast",
        "location_state": "Kurgan",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.47529984",
        "longitude": "65.41560364",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8500",
                "runway_width": "145",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 177038,
            "s_seats": 32776,
            "j_seats": 17493,
            "f_seats": 17238,
            "r_seats": 7079,
            "cargo_demand": 217436
        }
    },
    {
        "iata": "KRP",
        "icao": "EKKA",
        "airportName": "Karup Airport",
        "location_country": "Denmark",
        "location_city": "Karup",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "56.29750061",
        "longitude": "9.124629974",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2818",
                "runway_width": "50",
                "runway_material": "PEM"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2789",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "9816",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "9607",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2296",
                "runway_width": "60",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 170119,
            "s_seats": 28513,
            "j_seats": 58268,
            "f_seats": 7229,
            "r_seats": 9438,
            "cargo_demand": 268042
        }
    },
    {
        "iata": "KRR",
        "icao": "URKK",
        "airportName": "Krasnodar International Airport",
        "location_country": "Russia",
        "location_city": "Krasnodar Krai",
        "location_state": "Krasnodar",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.03469849",
        "longitude": "39.17050171",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "7218",
                "runway_width": "160",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "9835",
                "runway_width": "145",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 161960,
            "s_seats": 46293,
            "j_seats": 41191,
            "f_seats": 18656,
            "r_seats": 8994,
            "cargo_demand": 114824
        }
    },
    {
        "iata": "KRS",
        "icao": "ENCN",
        "airportName": "Kristiansand Airport",
        "location_country": "Norway",
        "location_city": "Kristiansand",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "58.204201",
        "longitude": "8.08537",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6660",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194182,
            "s_seats": 49989,
            "j_seats": 48363,
            "f_seats": 8629,
            "r_seats": 3069,
            "cargo_demand": 53718
        }
    },
    {
        "iata": "KRT",
        "icao": "HSSS",
        "airportName": "Khartoum International Airport",
        "location_country": "Sudan",
        "location_city": "Khartoum",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "15.5895",
        "longitude": "32.5532",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9751",
                "runway_width": "148",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 345518,
            "s_seats": 90972,
            "j_seats": 110257,
            "f_seats": 26627,
            "r_seats": 10000,
            "cargo_demand": 25478415
        }
    },
    {
        "iata": "KRW",
        "icao": "UTAK",
        "airportName": "Turkmenbashi International Airport",
        "location_country": "Turkmenistan",
        "location_city": "Turkmenbasy",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.063301",
        "longitude": "53.007198",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8202",
                "runway_width": "144",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 91473,
            "s_seats": 37695,
            "j_seats": 46606,
            "f_seats": 16787,
            "r_seats": 5835,
            "cargo_demand": 91623
        }
    },
    {
        "iata": "KRX",
        "icao": "AYKR",
        "airportName": "Karkar Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Karkar Island",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-4.557",
        "longitude": "145.9404",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "2953",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1149,
            "s_seats": 2213,
            "j_seats": 1340,
            "f_seats": 200,
            "r_seats": 173,
            "cargo_demand": 18253
        }
    },
    {
        "iata": "KSA",
        "icao": "PTSA",
        "airportName": "Kosrae International Airport",
        "location_country": "Federated States of Micronesia",
        "location_city": "Kosrae",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.35698",
        "longitude": "162.957993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5750",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57483,
            "s_seats": 31669,
            "j_seats": 7553,
            "f_seats": 6125,
            "r_seats": 9093,
            "cargo_demand": 209137
        }
    },
    {
        "iata": "KSB",
        "icao": "AYNM",
        "airportName": "Kasanombe Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Kasanombe",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.3819",
        "longitude": "146.9859",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "1499",
                "runway_width": "98",
                "runway_material": "Grassed brown silt clay"
            }
        ],
        "demand": {
            "y_seats": 2030,
            "s_seats": 2113,
            "j_seats": 1694,
            "f_seats": 240,
            "r_seats": 159,
            "cargo_demand": 19467
        }
    },
    {
        "iata": "KSC",
        "icao": "LZKZ",
        "airportName": "Koo\u00a1ice International Airport",
        "location_country": "Slovakia",
        "location_city": "Koo\u00a1ice",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.6631012",
        "longitude": "21.24110031",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172617,
            "s_seats": 28628,
            "j_seats": 5478,
            "f_seats": 11853,
            "r_seats": 5570,
            "cargo_demand": 48902
        }
    },
    {
        "iata": "KSD",
        "icao": "ESOK",
        "airportName": "Karlstad Airport",
        "location_country": "Sweden",
        "location_city": "Karlstad",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "59.44469833",
        "longitude": "13.33740044",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8255",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03L/21R",
                "runway_length": "1840",
                "runway_width": "170",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 182803,
            "s_seats": 37313,
            "j_seats": 59648,
            "f_seats": 18008,
            "r_seats": 8784,
            "cargo_demand": 448230
        }
    },
    {
        "iata": "KSE",
        "icao": "HUKS",
        "airportName": "Kasese Airport",
        "location_country": "Uganda",
        "location_city": "Kasese",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.182999998",
        "longitude": "30.10000038",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5151",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3784,
            "s_seats": 1927,
            "j_seats": 1459,
            "f_seats": 268,
            "r_seats": 18,
            "cargo_demand": 56295
        }
    },
    {
        "iata": "KSF",
        "icao": "EDVK",
        "airportName": "Kassel Airport",
        "location_country": "Germany",
        "location_city": "Hesse",
        "location_state": "Kassel",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.417273",
        "longitude": "9.384967",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04L/22R",
                "runway_length": "2297",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "09/27",
                "runway_length": "8202",
                "runway_width": "147",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 62477,
            "s_seats": 45068,
            "j_seats": 26044,
            "f_seats": 8071,
            "r_seats": 8707,
            "cargo_demand": 179531
        }
    },
    {
        "iata": "KSH",
        "icao": "OICC",
        "airportName": "Shahid Ashrafi Esfahani Airport",
        "location_country": "Iran",
        "location_city": "Kermanshah",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "34.34590149",
        "longitude": "47.15810013",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "11213",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183048,
            "s_seats": 37443,
            "j_seats": 27073,
            "f_seats": 7628,
            "r_seats": 4788,
            "cargo_demand": 433468
        }
    },
    {
        "iata": "KSJ",
        "icao": "LGKS",
        "airportName": "Kasos Island Public Airport",
        "location_country": "Greece",
        "location_city": "Kasos Island",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "35.42139816",
        "longitude": "26.90999985",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3221",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3086,
            "s_seats": 2736,
            "j_seats": 1099,
            "f_seats": 163,
            "r_seats": 20,
            "cargo_demand": 72766
        }
    },
    {
        "iata": "KSK",
        "icao": "ESKK",
        "airportName": "Karlskoga Airport",
        "location_country": "Sweden",
        "location_city": "Karlskoga",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "59.34590149",
        "longitude": "14.49590015",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4919",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170643,
            "s_seats": 43022,
            "j_seats": 33509,
            "f_seats": 15622,
            "r_seats": 6262,
            "cargo_demand": 175284
        }
    },
    {
        "iata": "KSL",
        "icao": "HSKA",
        "airportName": "Kassala Airport",
        "location_country": "Sudan",
        "location_city": "Kassala",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.38749981",
        "longitude": "36.3288002",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69310,
            "s_seats": 40103,
            "j_seats": 20410,
            "f_seats": 13798,
            "r_seats": 8367,
            "cargo_demand": 257876
        }
    },
    {
        "iata": "KSM",
        "icao": "PASM",
        "airportName": "St. Mary's Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "St. Mary's",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "62.0605011",
        "longitude": "-163.302002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "1900",
                "runway_width": "60",
                "runway_material": "GVL"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6003",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 145164,
            "s_seats": 43655,
            "j_seats": 38805,
            "f_seats": 16807,
            "r_seats": 7404,
            "cargo_demand": 454943
        }
    },
    {
        "iata": "KSN",
        "icao": "UAUU",
        "airportName": "Kostanay Airport",
        "location_country": "Kazakhstan",
        "location_city": "Kostanay",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.20690155",
        "longitude": "63.5503006",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "8150",
                "runway_width": "157",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132916,
            "s_seats": 48210,
            "j_seats": 21700,
            "f_seats": 15246,
            "r_seats": 7005,
            "cargo_demand": 274366
        }
    },
    {
        "iata": "KSO",
        "icao": "LGKA",
        "airportName": "Kastoria National Airport",
        "location_country": "Greece",
        "location_city": "Kastoria",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "40.44630051",
        "longitude": "21.28219986",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8852",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2937,
            "s_seats": 2551,
            "j_seats": 1896,
            "f_seats": 280,
            "r_seats": 9,
            "cargo_demand": 33807
        }
    },
    {
        "iata": "KSQ",
        "icao": "UTSL",
        "airportName": "Karshi Airport",
        "location_country": "Uzbekistan",
        "location_city": "Karshi",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.833599",
        "longitude": "65.921501",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8195",
                "runway_width": "132",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1002,
            "s_seats": 1910,
            "j_seats": 1236,
            "f_seats": 199,
            "r_seats": 91,
            "cargo_demand": 69052
        }
    },
    {
        "iata": "KSU",
        "icao": "ENKB",
        "airportName": "Kristiansund Airport",
        "location_country": "Norway",
        "location_city": "Kristiansund",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "63.11180115",
        "longitude": "7.824520111",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6037",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87979,
            "s_seats": 43447,
            "j_seats": 55709,
            "f_seats": 4529,
            "r_seats": 4295,
            "cargo_demand": 311502
        }
    },
    {
        "iata": "KSV",
        "icao": "YSPV",
        "airportName": "Springvale Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Springvale",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-23.54999924",
        "longitude": "140.6999969",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5000",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2624",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3825,
            "s_seats": 1790,
            "j_seats": 1016,
            "f_seats": 146,
            "r_seats": 97,
            "cargo_demand": 75082
        }
    },
    {
        "iata": "KSY",
        "icao": "LTCF",
        "airportName": "Kars Harakani Airport",
        "location_country": "Turkey",
        "location_city": "Kars",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.56219864",
        "longitude": "43.11500168",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 132944,
            "s_seats": 32289,
            "j_seats": 37903,
            "f_seats": 17691,
            "r_seats": 3571,
            "cargo_demand": 372824
        }
    },
    {
        "iata": "KTA",
        "icao": "YPKA",
        "airportName": "Karratha Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Karratha",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.71220016",
        "longitude": "116.7730026",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7480",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146692,
            "s_seats": 44507,
            "j_seats": 16571,
            "f_seats": 7213,
            "r_seats": 4843,
            "cargo_demand": 92462
        }
    },
    {
        "iata": "KTD",
        "icao": "RORK",
        "airportName": "Kitadaito Airport",
        "location_country": "Japan",
        "location_city": "Daito\u008d Islands",
        "location_state": "Kitadaito",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.94470024",
        "longitude": "131.3269959",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4921",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50976,
            "s_seats": 36653,
            "j_seats": 40173,
            "f_seats": 10955,
            "r_seats": 5890,
            "cargo_demand": 187539
        }
    },
    {
        "iata": "KTE",
        "icao": "WMKE",
        "airportName": "Kerteh Airport",
        "location_country": "Malaysia",
        "location_city": "Terengganu",
        "location_state": "Kerteh",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.537220001",
        "longitude": "103.427002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4446",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67268,
            "s_seats": 42611,
            "j_seats": 54348,
            "f_seats": 2252,
            "r_seats": 6975,
            "cargo_demand": 122940
        }
    },
    {
        "iata": "KTG",
        "icao": "WIOK",
        "airportName": "Rahadi Osman Airport",
        "location_country": "Indonesia",
        "location_city": "Ketapang",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.81664",
        "longitude": "109.962997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4585",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124635,
            "s_seats": 25985,
            "j_seats": 51050,
            "f_seats": 12025,
            "r_seats": 9823,
            "cargo_demand": 401876
        }
    },
    {
        "iata": "KTL",
        "icao": "HKKT",
        "airportName": "Kitale Airport",
        "location_country": "Kenya",
        "location_city": "Kitale",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.971988976",
        "longitude": "34.95859909",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4757",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160828,
            "s_seats": 26516,
            "j_seats": 9601,
            "f_seats": 12297,
            "r_seats": 9003,
            "cargo_demand": 394608
        }
    },
    {
        "iata": "KTM",
        "icao": "VNKT",
        "airportName": "Tribhuvan International Airport",
        "location_country": "Nepal",
        "location_city": "Kathmandu",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "27.6966",
        "longitude": "85.3591",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115600,
            "s_seats": 89752,
            "j_seats": 114522,
            "f_seats": 38884,
            "r_seats": 10000,
            "cargo_demand": 21822041
        }
    },
    {
        "iata": "KTN",
        "icao": "PAKT",
        "airportName": "Ketchikan International Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Ketchikan",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "55.35559845",
        "longitude": "-131.7140045",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7500",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "ESE/WNW",
                "runway_length": "9500",
                "runway_width": "1500",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 192733,
            "s_seats": 35508,
            "j_seats": 15326,
            "f_seats": 14183,
            "r_seats": 5271,
            "cargo_demand": 140031
        }
    },
    {
        "iata": "KTP",
        "icao": "MKTP",
        "airportName": "Tinson Pen Aerodrome",
        "location_country": "Jamaica",
        "location_city": "Kingston",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.98859978",
        "longitude": "-76.82379913",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4300",
                "runway_width": "110",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71554,
            "s_seats": 33925,
            "j_seats": 15105,
            "f_seats": 9384,
            "r_seats": 9225,
            "cargo_demand": 286969
        }
    },
    {
        "iata": "KTQ",
        "icao": "EFIT",
        "airportName": "Kitee Airfield",
        "location_country": "Finland",
        "location_city": "Kitee",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "62.1661",
        "longitude": "30.073601",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132216,
            "s_seats": 40272,
            "j_seats": 25034,
            "f_seats": 3981,
            "r_seats": 7363,
            "cargo_demand": 111941
        }
    },
    {
        "iata": "KTR",
        "icao": "YPTN",
        "airportName": "RAAF Base Tindal",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Katherine",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.52110004",
        "longitude": "132.378006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9003",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147366,
            "s_seats": 33401,
            "j_seats": 58289,
            "f_seats": 5445,
            "r_seats": 5581,
            "cargo_demand": 106970
        }
    },
    {
        "iata": "KTS",
        "icao": "PFKT",
        "airportName": "Brevig Mission Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Brevig Mission",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "65.33129883",
        "longitude": "-166.4660034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2110",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2990",
                "runway_width": "100",
                "runway_material": "GRVL-E"
            }
        ],
        "demand": {
            "y_seats": 1406,
            "s_seats": 2941,
            "j_seats": 1730,
            "f_seats": 162,
            "r_seats": 53,
            "cargo_demand": 21350
        }
    },
    {
        "iata": "KTT",
        "icao": "EFKT",
        "airportName": "KittilA Airport",
        "location_country": "Finland",
        "location_city": "KittilA",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "67.7009964",
        "longitude": "24.84679985",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73582,
            "s_seats": 29184,
            "j_seats": 9021,
            "f_seats": 3203,
            "r_seats": 8354,
            "cargo_demand": 498326
        }
    },
    {
        "iata": "KTU",
        "icao": "VIKO",
        "airportName": "Kota Airport",
        "location_country": "India",
        "location_city": "Rajasthan",
        "location_state": "Kota",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.16020012",
        "longitude": "75.84559631",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4078",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166761,
            "s_seats": 45479,
            "j_seats": 21859,
            "f_seats": 5725,
            "r_seats": 8416,
            "cargo_demand": 49031
        }
    },
    {
        "iata": "KTW",
        "icao": "EPKT",
        "airportName": "Katowice International Airport",
        "location_country": "Poland",
        "location_city": "Katowice",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "50.4743",
        "longitude": "19.08",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10499",
                "runway_width": "147",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 422573,
            "s_seats": 95368,
            "j_seats": 137754,
            "f_seats": 44636,
            "r_seats": 10000,
            "cargo_demand": 12745775
        }
    },
    {
        "iata": "KTY",
        "icao": "VCCN",
        "airportName": "Katukurunda Airport",
        "location_country": "Sri Lanka",
        "location_city": "Kalutara",
        "location_state": "",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "6.552120209",
        "longitude": "79.97750092",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3200",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2516,
            "s_seats": 2675,
            "j_seats": 1813,
            "f_seats": 49,
            "r_seats": 1,
            "cargo_demand": 59439
        }
    },
    {
        "iata": "KUA",
        "icao": "WMKD",
        "airportName": "Sultan Haji Ahmad Shah Airport",
        "location_country": "Malaysia",
        "location_city": "Pahang",
        "location_state": "Kuantan",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.77538991",
        "longitude": "103.2089996",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 75078,
            "s_seats": 45830,
            "j_seats": 22641,
            "f_seats": 8977,
            "r_seats": 6004,
            "cargo_demand": 63455
        }
    },
    {
        "iata": "KUE",
        "icao": "AGKU",
        "airportName": "Kukundu Airport",
        "location_country": "Solomon Islands",
        "location_city": "Western Province",
        "location_state": "Kolombangara",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.0262",
        "longitude": "156.94783",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "1821",
                "runway_width": "66",
                "runway_material": "Graded earth"
            }
        ],
        "demand": {
            "y_seats": 2584,
            "s_seats": 2401,
            "j_seats": 1605,
            "f_seats": 79,
            "r_seats": 140,
            "cargo_demand": 79938
        }
    },
    {
        "iata": "KUF",
        "icao": "UWWW",
        "airportName": "Kurumoch International Airport",
        "location_country": "Russia",
        "location_city": "Samara Oblast",
        "location_state": "Samara",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "53.50490189",
        "longitude": "50.16429901",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8360",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "9846",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 280034,
            "s_seats": 87851,
            "j_seats": 47646,
            "f_seats": 44176,
            "r_seats": 10000,
            "cargo_demand": 6544655
        }
    },
    {
        "iata": "KUG",
        "icao": "YKUB",
        "airportName": "Kubin Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Moa Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.22500038",
        "longitude": "142.2180023",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3281",
                "runway_width": "60",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 3616,
            "s_seats": 1890,
            "j_seats": 1310,
            "f_seats": 437,
            "r_seats": 6,
            "cargo_demand": 37857
        }
    },
    {
        "iata": "KUH",
        "icao": "RJCK",
        "airportName": "Kushiro Airport",
        "location_country": "Japan",
        "location_city": "Hokkaido",
        "location_state": "Kushiro",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.04100037",
        "longitude": "144.1929932",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8202",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154735,
            "s_seats": 49393,
            "j_seats": 52448,
            "f_seats": 15839,
            "r_seats": 9561,
            "cargo_demand": 271560
        }
    },
    {
        "iata": "KUK",
        "icao": "PFKA",
        "airportName": "Kasigluk Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kasigluk",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "60.87440109",
        "longitude": "-162.5240021",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 3671,
            "s_seats": 2705,
            "j_seats": 1315,
            "f_seats": 322,
            "r_seats": 186,
            "cargo_demand": 29373
        }
    },
    {
        "iata": "KUL",
        "icao": "WMKK",
        "airportName": "Kuala Lumpur International Airport",
        "location_country": "Malaysia",
        "location_city": "Kuala Lumpur",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "2.745579958",
        "longitude": "101.7099991",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "13530",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "13288",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "12993",
                "runway_width": "197",
                "runway_material": "paved"
            }
        ],
        "demand": {
            "y_seats": 208240,
            "s_seats": 73567,
            "j_seats": 25573,
            "f_seats": 66817,
            "r_seats": 10000,
            "cargo_demand": 12510210
        }
    },
    {
        "iata": "KUM",
        "icao": "RJFC",
        "airportName": "Yakushima Airport",
        "location_country": "Japan",
        "location_city": "o\u0152sumi Islands",
        "location_state": "Yakushima",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.38559914",
        "longitude": "130.6589966",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4921",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115664,
            "s_seats": 38884,
            "j_seats": 23089,
            "f_seats": 12502,
            "r_seats": 3716,
            "cargo_demand": 428172
        }
    },
    {
        "iata": "KUN",
        "icao": "EYKA",
        "airportName": "Kaunas International Airport",
        "location_country": "Lithuania",
        "location_city": "Kaunas",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "54.96390152",
        "longitude": "24.08480072",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10335",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137727,
            "s_seats": 40360,
            "j_seats": 13602,
            "f_seats": 14756,
            "r_seats": 5053,
            "cargo_demand": 320635
        }
    },
    {
        "iata": "KUO",
        "icao": "EFKU",
        "airportName": "Kuopio Airport",
        "location_country": "Finland",
        "location_city": "Kuopio",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "63.007099",
        "longitude": "27.7978",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4921",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "9186",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135135,
            "s_seats": 27844,
            "j_seats": 56635,
            "f_seats": 2550,
            "r_seats": 7463,
            "cargo_demand": 489467
        }
    },
    {
        "iata": "KUS",
        "icao": "BGKK",
        "airportName": "Kulusuk Airport",
        "location_country": "Greenland",
        "location_city": "Kulusuk",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "65.57360077",
        "longitude": "-37.12360001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3934",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1045,
            "s_seats": 2401,
            "j_seats": 1560,
            "f_seats": 444,
            "r_seats": 9,
            "cargo_demand": 88694
        }
    },
    {
        "iata": "KUT",
        "icao": "UGKO",
        "airportName": "David the Builder Kutaisi International Airport",
        "location_country": "Georgia",
        "location_city": "Kutaisi",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.176768",
        "longitude": "42.482393",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8202",
                "runway_width": "144",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 104792,
            "s_seats": 46921,
            "j_seats": 25996,
            "f_seats": 15299,
            "r_seats": 3107,
            "cargo_demand": 329545
        }
    },
    {
        "iata": "KUU",
        "icao": "VIBR",
        "airportName": "Bhuntar Airport",
        "location_country": "India",
        "location_city": "Himachal Pradesh",
        "location_state": "Kullu",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.876699",
        "longitude": "77.154404",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3690",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58282,
            "s_seats": 40213,
            "j_seats": 39811,
            "f_seats": 17314,
            "r_seats": 6353,
            "cargo_demand": 261039
        }
    },
    {
        "iata": "KUV",
        "icao": "RKJK",
        "airportName": "Gunsan Airport",
        "location_country": "South Korea",
        "location_city": "Gunsan",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "35.90380096",
        "longitude": "126.6159973",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 312923,
            "s_seats": 88318,
            "j_seats": 125580,
            "f_seats": 33204,
            "r_seats": 10000,
            "cargo_demand": 19590522
        }
    },
    {
        "iata": "KVA",
        "icao": "LGKV",
        "airportName": "Kavala International Airport",
        "location_country": "Greece",
        "location_city": "Kavala",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "40.9133",
        "longitude": "24.6192",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05R/23L",
                "runway_length": "9844",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152081,
            "s_seats": 28874,
            "j_seats": 32230,
            "f_seats": 6605,
            "r_seats": 8818,
            "cargo_demand": 382144
        }
    },
    {
        "iata": "KVB",
        "icao": "ESGR",
        "airportName": "Skovde Airport",
        "location_country": "Sweden",
        "location_city": "Skovde",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "58.45640182",
        "longitude": "13.97270012",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5696",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193346,
            "s_seats": 42141,
            "j_seats": 26160,
            "f_seats": 7117,
            "r_seats": 3164,
            "cargo_demand": 353076
        }
    },
    {
        "iata": "KVC",
        "icao": "PAVC",
        "airportName": "King Cove Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "King Cove",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "55.11629868",
        "longitude": "-162.2660065",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 1621,
            "s_seats": 1923,
            "j_seats": 1257,
            "f_seats": 41,
            "r_seats": 118,
            "cargo_demand": 4670
        }
    },
    {
        "iata": "KVD",
        "icao": "UBBG",
        "airportName": "Ganja International Airport",
        "location_country": "Azerbaijan",
        "location_city": "Ganja",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.737701",
        "longitude": "46.3176",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8217",
                "runway_width": "144",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 109548,
            "s_seats": 44729,
            "j_seats": 8193,
            "f_seats": 14588,
            "r_seats": 5265,
            "cargo_demand": 212768
        }
    },
    {
        "iata": "KVG",
        "icao": "AYKV",
        "airportName": "Kavieng Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Kavieng",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.579400063",
        "longitude": "150.8079987",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5592",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171124,
            "s_seats": 30415,
            "j_seats": 13798,
            "f_seats": 1948,
            "r_seats": 3790,
            "cargo_demand": 306019
        }
    },
    {
        "iata": "KVL",
        "icao": "PAVL",
        "airportName": "Kivalina Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kivalina",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "67.73619843",
        "longitude": "-164.5630035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 155178,
            "s_seats": 48684,
            "j_seats": 22329,
            "f_seats": 4873,
            "r_seats": 4187,
            "cargo_demand": 364598
        }
    },
    {
        "iata": "KVM",
        "icao": "UHMO",
        "airportName": "Markovo Airport",
        "location_country": "Russia",
        "location_city": "Chukotka",
        "location_state": "Markovo",
        "time_zone": "UTC+12:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "64.665088",
        "longitude": "170.429782",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7874",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1465,
            "s_seats": 2268,
            "j_seats": 1013,
            "f_seats": 436,
            "r_seats": 95,
            "cargo_demand": 38811
        }
    },
    {
        "iata": "KWA",
        "icao": "PKWA",
        "airportName": "Bucholz Army Airfield",
        "location_country": "Marshall Islands",
        "location_city": "Kwajalein",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.72012043",
        "longitude": "167.7319946",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6668",
                "runway_width": "198",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174160,
            "s_seats": 45803,
            "j_seats": 47130,
            "f_seats": 4028,
            "r_seats": 8656,
            "cargo_demand": 412625
        }
    },
    {
        "iata": "KWE",
        "icao": "ZUGY",
        "airportName": "Guiyang Longdongbao International Airport",
        "location_country": "China",
        "location_city": "Guizhou",
        "location_state": "Guiyang",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "26.53849983",
        "longitude": "106.8010025",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 287414,
            "s_seats": 85614,
            "j_seats": 64261,
            "f_seats": 69476,
            "r_seats": 10000,
            "cargo_demand": 15784158
        }
    },
    {
        "iata": "KWG",
        "icao": "UKDR",
        "airportName": "Kryvyi Rih International Airport",
        "location_country": "Ukraine",
        "location_city": "Kryvyi Rih (Krivoi Rog)",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.04330063",
        "longitude": "33.20999908",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8202",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 178376,
            "s_seats": 29944,
            "j_seats": 8442,
            "f_seats": 11386,
            "r_seats": 8443,
            "cargo_demand": 202491
        }
    },
    {
        "iata": "KWI",
        "icao": "OKBK",
        "airportName": "Kuwait International Airport",
        "location_country": "Kuwait",
        "location_city": "Kuwait City",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "29.22660065",
        "longitude": "47.96889877",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15L/33R",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 447444,
            "s_seats": 70074,
            "j_seats": 90833,
            "f_seats": 34188,
            "r_seats": 10000,
            "cargo_demand": 18328340
        }
    },
    {
        "iata": "KWJ",
        "icao": "RKJJ",
        "airportName": "Gwangju Airport",
        "location_country": "South Korea",
        "location_city": "Gwangju (Kwangju)",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.123173",
        "longitude": "126.805444",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "9300",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "9300",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 128462,
            "s_seats": 39019,
            "j_seats": 37226,
            "f_seats": 4875,
            "r_seats": 6781,
            "cargo_demand": 159718
        }
    },
    {
        "iata": "KWK",
        "icao": "PAGG",
        "airportName": "Kwigillingok Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kwigillingok",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "59.876499",
        "longitude": "-163.169005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2510",
                "runway_width": "60",
                "runway_material": "GRVL-P"
            }
        ],
        "demand": {
            "y_seats": 3137,
            "s_seats": 2227,
            "j_seats": 1068,
            "f_seats": 64,
            "r_seats": 132,
            "cargo_demand": 55864
        }
    },
    {
        "iata": "KWL",
        "icao": "ZGKL",
        "airportName": "Guilin Liangjiang International Airport",
        "location_country": "China",
        "location_city": "Guangxi",
        "location_state": "Guilin",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "25.2181",
        "longitude": "110.039001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9186",
                "runway_width": "147",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 100721,
            "s_seats": 78782,
            "j_seats": 150591,
            "f_seats": 28197,
            "r_seats": 10000,
            "cargo_demand": 16553722
        }
    },
    {
        "iata": "KWM",
        "icao": "YKOW",
        "airportName": "Kowanyama Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Kowanyama",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.48560047",
        "longitude": "141.7510071",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4528",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142373,
            "s_seats": 32785,
            "j_seats": 19198,
            "f_seats": 18670,
            "r_seats": 4827,
            "cargo_demand": 233373
        }
    },
    {
        "iata": "KWN",
        "icao": "PAQH",
        "airportName": "Quinhagak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Quinhagak",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "59.7551",
        "longitude": "-161.845",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 2384,
            "s_seats": 2804,
            "j_seats": 1702,
            "f_seats": 204,
            "r_seats": 28,
            "cargo_demand": 80837
        }
    },
    {
        "iata": "KWS",
        "icao": "AGKW",
        "airportName": "Kwailabesi Airport",
        "location_country": "Solomon Islands",
        "location_city": "Kwailabesi",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.360508",
        "longitude": "160.775127",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "1896",
                "runway_width": "66",
                "runway_material": "Limestone/Grass"
            }
        ],
        "demand": {
            "y_seats": 2142,
            "s_seats": 2485,
            "j_seats": 1929,
            "f_seats": 311,
            "r_seats": 97,
            "cargo_demand": 78466
        }
    },
    {
        "iata": "KWT",
        "icao": "PFKW",
        "airportName": "Kwethluk Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kwethluk",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "60.79029846",
        "longitude": "-161.4440002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3198",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 3221,
            "s_seats": 1941,
            "j_seats": 1078,
            "f_seats": 248,
            "r_seats": 168,
            "cargo_demand": 55675
        }
    },
    {
        "iata": "KWZ",
        "icao": "FZQM",
        "airportName": "Kolwezi Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Kolwezi",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-10.76589966",
        "longitude": "25.50569916",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5741",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173516,
            "s_seats": 39129,
            "j_seats": 24514,
            "f_seats": 6128,
            "r_seats": 4746,
            "cargo_demand": 223251
        }
    },
    {
        "iata": "KXE",
        "icao": "FAKD",
        "airportName": "Klerksdorp Airport",
        "location_country": "South Africa",
        "location_city": "Klerksdorp",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.87109947",
        "longitude": "26.71800041",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2953",
                "runway_width": "82",
                "runway_material": "GRE"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3281",
                "runway_width": "82",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4921",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70784,
            "s_seats": 48402,
            "j_seats": 21530,
            "f_seats": 14670,
            "r_seats": 6656,
            "cargo_demand": 304312
        }
    },
    {
        "iata": "KXK",
        "icao": "UHKK",
        "airportName": "Komsomolsk-on-Amur Airport",
        "location_country": "Russia",
        "location_city": "Khabarovsk Krai",
        "location_state": "Komsomolsk-on-Amur",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.4090004",
        "longitude": "136.9340057",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8202",
                "runway_width": "",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 186333,
            "s_seats": 44952,
            "j_seats": 28958,
            "f_seats": 4726,
            "r_seats": 4582,
            "cargo_demand": 347669
        }
    },
    {
        "iata": "KYA",
        "icao": "LTAN",
        "airportName": "Konya Airport",
        "location_country": "Turkey",
        "location_city": "Konya",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.979",
        "longitude": "32.561901",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "10984",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "10984",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 183239,
            "s_seats": 47509,
            "j_seats": 40856,
            "f_seats": 18464,
            "r_seats": 4322,
            "cargo_demand": 469978
        }
    },
    {
        "iata": "KYD",
        "icao": "RCLY",
        "airportName": "Lanyu Airport",
        "location_country": "Taiwan",
        "location_city": "Orchid Island",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.02700043",
        "longitude": "121.5350037",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3852",
                "runway_width": "79",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 71195,
            "s_seats": 35890,
            "j_seats": 20176,
            "f_seats": 11503,
            "r_seats": 9723,
            "cargo_demand": 166771
        }
    },
    {
        "iata": "KYE",
        "icao": "OLKA",
        "airportName": "Rene Mouawad Air Base",
        "location_country": "Lebanon",
        "location_city": "Tripoli",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "34.589298",
        "longitude": "36.011299",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 77610,
            "s_seats": 36393,
            "j_seats": 19513,
            "f_seats": 10720,
            "r_seats": 6376,
            "cargo_demand": 136449
        }
    },
    {
        "iata": "KYF",
        "icao": "YYLR",
        "airportName": "Yeelirrie Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Yeelirrie",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.27706004",
        "longitude": "120.0956726",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "C"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2624",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 1541,
            "s_seats": 1532,
            "j_seats": 1624,
            "f_seats": 305,
            "r_seats": 178,
            "cargo_demand": 22609
        }
    },
    {
        "iata": "KYI",
        "icao": "YYTA",
        "airportName": "Yalata Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Yalata Mission",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-31.47060013",
        "longitude": "131.8249969",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 3015,
            "s_seats": 2835,
            "j_seats": 1502,
            "f_seats": 466,
            "r_seats": 167,
            "cargo_demand": 14401
        }
    },
    {
        "iata": "KYK",
        "icao": "PAKY",
        "airportName": "Karluk Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Karluk",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "57.56710052",
        "longitude": "-154.4499969",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2000",
                "runway_width": "60",
                "runway_material": "GRVL-P"
            }
        ],
        "demand": {
            "y_seats": 2420,
            "s_seats": 2554,
            "j_seats": 1698,
            "f_seats": 156,
            "r_seats": 133,
            "cargo_demand": 46915
        }
    },
    {
        "iata": "KYP",
        "icao": "VYKP",
        "airportName": "Kyaukpyu Airport",
        "location_country": "Myanmar",
        "location_city": "Kyaukpyu",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.42639923",
        "longitude": "93.53479767",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4600",
                "runway_width": "100",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 170603,
            "s_seats": 30878,
            "j_seats": 22714,
            "f_seats": 9029,
            "r_seats": 8183,
            "cargo_demand": 161783
        }
    },
    {
        "iata": "KYS",
        "icao": "GAKY",
        "airportName": "Kayes Airport",
        "location_country": "Mali",
        "location_city": "Kayes",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.48120022",
        "longitude": "-11.40439987",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5267",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92386,
            "s_seats": 40712,
            "j_seats": 44264,
            "f_seats": 7510,
            "r_seats": 3960,
            "cargo_demand": 456810
        }
    },
    {
        "iata": "KYU",
        "icao": "PFKU",
        "airportName": "Koyukuk Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Koyukuk",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "64.87609863",
        "longitude": "-157.727005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "GRVL-DIRT-G"
            }
        ],
        "demand": {
            "y_seats": 1300,
            "s_seats": 2661,
            "j_seats": 1891,
            "f_seats": 43,
            "r_seats": 119,
            "cargo_demand": 32713
        }
    },
    {
        "iata": "KZC",
        "icao": "VDKH",
        "airportName": "Kampong Chhnang Airport",
        "location_country": "Cambodia",
        "location_city": "Kampong Chhnang",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.255032",
        "longitude": "104.564657",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7874",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 170817,
            "s_seats": 48918,
            "j_seats": 59051,
            "f_seats": 2458,
            "r_seats": 8088,
            "cargo_demand": 204558
        }
    },
    {
        "iata": "KZG",
        "icao": "ETIN",
        "airportName": "Kitzingen Airport",
        "location_country": "Germany",
        "location_city": "Bavaria",
        "location_state": "Kitzingen",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "49.743099",
        "longitude": "10.2006",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2625",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2713,
            "s_seats": 2097,
            "j_seats": 1647,
            "f_seats": 345,
            "r_seats": 176,
            "cargo_demand": 90140
        }
    },
    {
        "iata": "KZI",
        "icao": "LGKZ",
        "airportName": "Kozani National Airport",
        "location_country": "Greece",
        "location_city": "Kozani",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "40.28609848",
        "longitude": "21.84079933",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5978",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102857,
            "s_seats": 35668,
            "j_seats": 38753,
            "f_seats": 2594,
            "r_seats": 5207,
            "cargo_demand": 156039
        }
    },
    {
        "iata": "KZN",
        "icao": "UWKD",
        "airportName": "Kazan International Airport",
        "location_country": "Russia",
        "location_city": "Tatarstan",
        "location_state": "Kazan",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "55.60620117",
        "longitude": "49.27870178",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11L/29R",
                "runway_length": "12303",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "8196",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 381227,
            "s_seats": 73057,
            "j_seats": 107920,
            "f_seats": 44581,
            "r_seats": 10000,
            "cargo_demand": 9104204
        }
    },
    {
        "iata": "KZS",
        "icao": "LGKJ",
        "airportName": "Kastellorizo Island Public Airport",
        "location_country": "Greece",
        "location_city": "Kastellorizo",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "36.14170074",
        "longitude": "29.57640076",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2618",
                "runway_width": "82",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 2585,
            "s_seats": 2008,
            "j_seats": 1164,
            "f_seats": 393,
            "r_seats": 116,
            "cargo_demand": 2471
        }
    },
    {
        "iata": "LAA",
        "icao": "KLAA",
        "airportName": "Lamar Municipal Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Lamar",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.064852",
        "longitude": "-102.683201",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5001",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6304",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3575,
            "s_seats": 2308,
            "j_seats": 1244,
            "f_seats": 450,
            "r_seats": 48,
            "cargo_demand": 76051
        }
    },
    {
        "iata": "LAD",
        "icao": "FNLU",
        "airportName": "Quatro de Fevereiro Airport",
        "location_country": "Angola",
        "location_city": "Luanda",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-8.85837",
        "longitude": "13.2312",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "12190",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "8530",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 299193,
            "s_seats": 74584,
            "j_seats": 68009,
            "f_seats": 43637,
            "r_seats": 10000,
            "cargo_demand": 7628970
        }
    },
    {
        "iata": "LAE",
        "icao": "AYNZ",
        "airportName": "Lae Nadzab Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Lae",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.569803",
        "longitude": "146.725977",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8004",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95910,
            "s_seats": 49375,
            "j_seats": 8660,
            "f_seats": 14894,
            "r_seats": 4879,
            "cargo_demand": 430664
        }
    },
    {
        "iata": "LAF",
        "icao": "KLAF",
        "airportName": "Purdue University Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Lafayette",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.41230011",
        "longitude": "-86.93689728",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4226",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "6600",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80872,
            "s_seats": 33140,
            "j_seats": 15583,
            "f_seats": 18817,
            "r_seats": 6075,
            "cargo_demand": 299971
        }
    },
    {
        "iata": "LAH",
        "icao": "WAPH",
        "airportName": "Oesman Sadik Airport",
        "location_country": "Indonesia",
        "location_city": "Labuha",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.635259",
        "longitude": "127.501999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/24",
                "runway_length": "4300",
                "runway_width": "75",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 2271,
            "s_seats": 2029,
            "j_seats": 1462,
            "f_seats": 72,
            "r_seats": 81,
            "cargo_demand": 97032
        }
    },
    {
        "iata": "LAI",
        "icao": "LFRO",
        "airportName": "Lannion - Cote de Granit Airport",
        "location_country": "France",
        "location_city": "Brittany",
        "location_state": "Lannion",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.754398",
        "longitude": "-3.47166",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5577",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185099,
            "s_seats": 32724,
            "j_seats": 34751,
            "f_seats": 19056,
            "r_seats": 7117,
            "cargo_demand": 231784
        }
    },
    {
        "iata": "LAJ",
        "icao": "SBLJ",
        "airportName": "Antonio Correia Pinto de Macedo Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "Lages",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-27.78210068",
        "longitude": "-50.28150177",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5020",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90129,
            "s_seats": 34000,
            "j_seats": 54306,
            "f_seats": 14240,
            "r_seats": 7995,
            "cargo_demand": 407386
        }
    },
    {
        "iata": "LAK",
        "icao": "CYKD",
        "airportName": "Aklavik/Freddie Carmichael Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Aklavik",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "68.223297",
        "longitude": "-135.00599",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "GRVL"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 58018,
            "s_seats": 47712,
            "j_seats": 21279,
            "f_seats": 3850,
            "r_seats": 7320,
            "cargo_demand": 436779
        }
    },
    {
        "iata": "LAL",
        "icao": "KLAL",
        "airportName": "Lakeland Linder International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Lakeland",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "27.988899",
        "longitude": "-82.018602",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "8500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 85273,
            "s_seats": 29755,
            "j_seats": 27308,
            "f_seats": 19030,
            "r_seats": 3245,
            "cargo_demand": 328021
        }
    },
    {
        "iata": "LAM",
        "icao": "KLAM",
        "airportName": "Los Alamos County Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Los Alamos",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.87979889",
        "longitude": "-106.2689972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5550",
                "runway_width": "113",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1440,
            "s_seats": 2896,
            "j_seats": 1571,
            "f_seats": 174,
            "r_seats": 98,
            "cargo_demand": 15500
        }
    },
    {
        "iata": "LAN",
        "icao": "KLAN",
        "airportName": "Capital Region International Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Lansing",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.77870178",
        "longitude": "-84.58740234",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5001",
                "runway_width": "120",
                "runway_material": "PEM"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "3601",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "7251",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191722,
            "s_seats": 44495,
            "j_seats": 14112,
            "f_seats": 16972,
            "r_seats": 3960,
            "cargo_demand": 459257
        }
    },
    {
        "iata": "LAO",
        "icao": "RPLI",
        "airportName": "Laoag International Airport",
        "location_country": "Philippines",
        "location_city": "Laoag",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.17810059",
        "longitude": "120.5319977",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9120",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 146710,
            "s_seats": 26078,
            "j_seats": 39138,
            "f_seats": 14462,
            "r_seats": 6809,
            "cargo_demand": 118384
        }
    },
    {
        "iata": "LAP",
        "icao": "MMLP",
        "airportName": "Manuel Marquez de Leon International Airport",
        "location_country": "Mexico",
        "location_city": "Baja California Sur",
        "location_state": "La Paz",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "24.0727005",
        "longitude": "-110.3619995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 106715,
            "s_seats": 41641,
            "j_seats": 16233,
            "f_seats": 15767,
            "r_seats": 4065,
            "cargo_demand": 274623
        }
    },
    {
        "iata": "LAQ",
        "icao": "HLLQ",
        "airportName": "Al Abraq International Airport",
        "location_country": "Libya",
        "location_city": "Bayda",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.7887",
        "longitude": "21.9643",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6214",
                "runway_width": "190",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "11824",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 60452,
            "s_seats": 47809,
            "j_seats": 37668,
            "f_seats": 10162,
            "r_seats": 9320,
            "cargo_demand": 375836
        }
    },
    {
        "iata": "LAR",
        "icao": "KLAR",
        "airportName": "Laramie Regional Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Laramie",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.31209946",
        "longitude": "-105.6750031",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148276,
            "s_seats": 27645,
            "j_seats": 5984,
            "f_seats": 19240,
            "r_seats": 5292,
            "cargo_demand": 253159
        }
    },
    {
        "iata": "LAS",
        "icao": "KLAS",
        "airportName": "McCarran International Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Las Vegas",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "36.08010101",
        "longitude": "-115.1520004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "8988",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "9771",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08L/26R",
                "runway_length": "14515",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "10526",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191570,
            "s_seats": 77171,
            "j_seats": 159874,
            "f_seats": 35182,
            "r_seats": 10000,
            "cargo_demand": 18902119
        }
    },
    {
        "iata": "LAU",
        "icao": "HKLU",
        "airportName": "Manda Airport",
        "location_country": "Kenya",
        "location_city": "Lamu",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.252419949",
        "longitude": "40.9131012",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3054",
                "runway_width": "46",
                "runway_material": "GRE"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3054",
                "runway_width": "46",
                "runway_material": "GRE"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6561",
                "runway_width": "105",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184193,
            "s_seats": 49031,
            "j_seats": 47326,
            "f_seats": 18912,
            "r_seats": 7713,
            "cargo_demand": 237866
        }
    },
    {
        "iata": "LAW",
        "icao": "KLAW",
        "airportName": "Lawton-Fort Sill Regional Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Lawton",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.56769943",
        "longitude": "-98.41660309",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8599",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 105569,
            "s_seats": 25015,
            "j_seats": 8588,
            "f_seats": 16703,
            "r_seats": 5488,
            "cargo_demand": 261070
        }
    },
    {
        "iata": "LAX",
        "icao": "KLAX",
        "airportName": "Los Angeles International Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Los Angeles",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "33.942501",
        "longitude": "-118.407997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "8925",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "10285",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "12091",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "11096",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "09l/09R",
                "runway_length": "",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 423467,
            "s_seats": 98223,
            "j_seats": 97975,
            "f_seats": 66213,
            "r_seats": 10000,
            "cargo_demand": 8619301
        }
    },
    {
        "iata": "LAY",
        "icao": "FALY",
        "airportName": "Ladysmith Airport",
        "location_country": "South Africa",
        "location_city": "Ladysmith",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.58169937",
        "longitude": "29.74970055",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3937",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113976,
            "s_seats": 49084,
            "j_seats": 34899,
            "f_seats": 13860,
            "r_seats": 7874,
            "cargo_demand": 459767
        }
    },
    {
        "iata": "LAZ",
        "icao": "SBLP",
        "airportName": "Bom Jesus da Lapa Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Bom Jesus da Lapa",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-13.26210022",
        "longitude": "-43.40810013",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3973",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63942,
            "s_seats": 46642,
            "j_seats": 14516,
            "f_seats": 15242,
            "r_seats": 4718,
            "cargo_demand": 95073
        }
    },
    {
        "iata": "LBA",
        "icao": "EGNM",
        "airportName": "Leeds Bradford Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Leeds / Bradford",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "53.86589813",
        "longitude": "-1.660570025",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7381",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 104155,
            "s_seats": 74615,
            "j_seats": 28507,
            "f_seats": 45301,
            "r_seats": 10000,
            "cargo_demand": 2567463
        }
    },
    {
        "iata": "LBB",
        "icao": "KLBB",
        "airportName": "Lubbock Preston Smith International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Lubbock",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "33.663601",
        "longitude": "-101.822998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "2891",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "11500",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 444588,
            "s_seats": 81538,
            "j_seats": 90251,
            "f_seats": 47900,
            "r_seats": 10000,
            "cargo_demand": 6122999
        }
    },
    {
        "iata": "LBC",
        "icao": "EDHL",
        "airportName": "Lubeck Airport",
        "location_country": "Germany",
        "location_city": "Schleswig-Holstein",
        "location_state": "Lubeck",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "53.80540085",
        "longitude": "10.71920013",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6896",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 98765,
            "s_seats": 33552,
            "j_seats": 54206,
            "f_seats": 18969,
            "r_seats": 8483,
            "cargo_demand": 341791
        }
    },
    {
        "iata": "LBD",
        "icao": "UTDL",
        "airportName": "Khujand Airport",
        "location_country": "Tajikistan",
        "location_city": "Khujand",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.215401",
        "longitude": "69.694702",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10450",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67626,
            "s_seats": 35036,
            "j_seats": 7229,
            "f_seats": 7551,
            "r_seats": 3926,
            "cargo_demand": 408085
        }
    },
    {
        "iata": "LBE",
        "icao": "KLBE",
        "airportName": "Arnold Palmer Regional Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Latrobe",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.27590179",
        "longitude": "-79.40480042",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3596",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "7001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78985,
            "s_seats": 49262,
            "j_seats": 13845,
            "f_seats": 1008,
            "r_seats": 7921,
            "cargo_demand": 459627
        }
    },
    {
        "iata": "LBF",
        "icao": "KLBF",
        "airportName": "North Platte Regional Airport (Lee Bird Field)",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "North Platte",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.12620163",
        "longitude": "-100.6839981",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4436",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86680,
            "s_seats": 30221,
            "j_seats": 16690,
            "f_seats": 7705,
            "r_seats": 4191,
            "cargo_demand": 141395
        }
    },
    {
        "iata": "LBG",
        "icao": "LFPB",
        "airportName": "Paris-Le Bourget Airport",
        "location_country": "France",
        "location_city": "Ile-de-France",
        "location_state": "Paris",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.9693985",
        "longitude": "2.441390038",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7858",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "9813",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "09/27",
                "runway_length": "6060",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113475,
            "s_seats": 42906,
            "j_seats": 14144,
            "f_seats": 12089,
            "r_seats": 6132,
            "cargo_demand": 433170
        }
    },
    {
        "iata": "LBI",
        "icao": "LFCI",
        "airportName": "Le Sequestre Airport",
        "location_country": "France",
        "location_city": "Midi-Pyrenees",
        "location_state": "Albi",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.91389847",
        "longitude": "2.113059998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5118",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79930,
            "s_seats": 34089,
            "j_seats": 22891,
            "f_seats": 16379,
            "r_seats": 9280,
            "cargo_demand": 383462
        }
    },
    {
        "iata": "LBJ",
        "icao": "WATO",
        "airportName": "Komodo Airport",
        "location_country": "Indonesia",
        "location_city": "Labuan Bajo",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.48666",
        "longitude": "119.889",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7381",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1272,
            "s_seats": 2759,
            "j_seats": 1302,
            "f_seats": 218,
            "r_seats": 124,
            "cargo_demand": 25665
        }
    },
    {
        "iata": "LBL",
        "icao": "KLBL",
        "airportName": "Liberal Mid-America Regional Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Liberal",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.0442009",
        "longitude": "-100.9599991",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5721",
                "runway_width": "150",
                "runway_material": "CONC-F"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7105",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76762,
            "s_seats": 38948,
            "j_seats": 44421,
            "f_seats": 13912,
            "r_seats": 7771,
            "cargo_demand": 257526
        }
    },
    {
        "iata": "LBQ",
        "icao": "FOGR",
        "airportName": "Lambarene Airport",
        "location_country": "Gabon",
        "location_city": "Lambarene",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.704388976",
        "longitude": "10.24569988",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7555",
                "runway_width": "148",
                "runway_material": "LAT"
            },
            {
                "runway_name": "05/23",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 140820,
            "s_seats": 26584,
            "j_seats": 15759,
            "f_seats": 4025,
            "r_seats": 3206,
            "cargo_demand": 442909
        }
    },
    {
        "iata": "LBR",
        "icao": "SWLB",
        "airportName": "Labrea Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Labrea",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.278969765",
        "longitude": "-64.76950073",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3233,
            "s_seats": 1723,
            "j_seats": 1166,
            "f_seats": 403,
            "r_seats": 127,
            "cargo_demand": 62523
        }
    },
    {
        "iata": "LBS",
        "icao": "NFNL",
        "airportName": "Labasa Airport",
        "location_country": "Fiji",
        "location_city": "Labasa",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Nov-Jan",
        "airport_size": "medium_airport",
        "latitude": "-16.4666996",
        "longitude": "179.3399963",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3521",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141923,
            "s_seats": 38278,
            "j_seats": 51397,
            "f_seats": 1313,
            "r_seats": 7544,
            "cargo_demand": 474631
        }
    },
    {
        "iata": "LBT",
        "icao": "KLBT",
        "airportName": "Lumberton Municipal Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Lumberton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.60990143",
        "longitude": "-79.05940247",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4974",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "5510",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4998",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51808,
            "s_seats": 46803,
            "j_seats": 55955,
            "f_seats": 17556,
            "r_seats": 3796,
            "cargo_demand": 466178
        }
    },
    {
        "iata": "LBU",
        "icao": "WBKL",
        "airportName": "Labuan Airport",
        "location_country": "Malaysia",
        "location_city": "Labuan",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.300680161",
        "longitude": "115.25",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127781,
            "s_seats": 30734,
            "j_seats": 23229,
            "f_seats": 14308,
            "r_seats": 6236,
            "cargo_demand": 268134
        }
    },
    {
        "iata": "LBV",
        "icao": "FOOL",
        "airportName": "Libreville International Airport",
        "location_country": "Gabon",
        "location_city": "Libreville",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.458600014",
        "longitude": "9.412280083",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9844",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102993,
            "s_seats": 29758,
            "j_seats": 11950,
            "f_seats": 6012,
            "r_seats": 7400,
            "cargo_demand": 84104
        }
    },
    {
        "iata": "LBW",
        "icao": "WRLB",
        "airportName": "Juvai Semaring Airport (Long Bawan Airport)",
        "location_country": "Indonesia",
        "location_city": "Long Bawan",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "3.9028",
        "longitude": "115.6921",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2953",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2603,
            "s_seats": 2896,
            "j_seats": 1454,
            "f_seats": 395,
            "r_seats": 47,
            "cargo_demand": 86650
        }
    },
    {
        "iata": "LBX",
        "icao": "RPLU",
        "airportName": "Lubang Airport",
        "location_country": "Philippines",
        "location_city": "Lubang",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.85540009",
        "longitude": "120.1050034",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4135",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103443,
            "s_seats": 35212,
            "j_seats": 5437,
            "f_seats": 12961,
            "r_seats": 9004,
            "cargo_demand": 305478
        }
    },
    {
        "iata": "LBY",
        "icao": "LFRE",
        "airportName": "La Baule-Escoublac Airport",
        "location_country": "France",
        "location_city": "Pays de la Loire",
        "location_state": "La Baule-Escoublac",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.28939819",
        "longitude": "-2.346390009",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3117",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86123,
            "s_seats": 30234,
            "j_seats": 36513,
            "f_seats": 12351,
            "r_seats": 8267,
            "cargo_demand": 245008
        }
    },
    {
        "iata": "LCA",
        "icao": "LCLK",
        "airportName": "Larnaca International Airport",
        "location_country": "Cyprus",
        "location_city": "Larnaca",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "34.875099",
        "longitude": "33.624901",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9776",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120903,
            "s_seats": 94467,
            "j_seats": 50057,
            "f_seats": 22297,
            "r_seats": 10000,
            "cargo_demand": 20403345
        }
    },
    {
        "iata": "LCC",
        "icao": "LIBN",
        "airportName": "Galatina Air Base",
        "location_country": "Italy",
        "location_city": "Apulia",
        "location_state": "Lecce",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "40.239201",
        "longitude": "18.133301",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6240",
                "runway_width": "190",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196205,
            "s_seats": 27915,
            "j_seats": 15402,
            "f_seats": 9058,
            "r_seats": 4337,
            "cargo_demand": 331681
        }
    },
    {
        "iata": "LCD",
        "icao": "FALO",
        "airportName": "Louis Trichardt Airport",
        "location_country": "South Africa",
        "location_city": "Louis Trichardt",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-23.06189919",
        "longitude": "29.86470032",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3937",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1377,
            "s_seats": 2129,
            "j_seats": 1297,
            "f_seats": 322,
            "r_seats": 69,
            "cargo_demand": 57474
        }
    },
    {
        "iata": "LCE",
        "icao": "MHLC",
        "airportName": "Goloson International Airport",
        "location_country": "Honduras",
        "location_city": "La Ceiba",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.7425",
        "longitude": "-86.852997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9875",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 195624,
            "s_seats": 30377,
            "j_seats": 36565,
            "f_seats": 12927,
            "r_seats": 7647,
            "cargo_demand": 241931
        }
    },
    {
        "iata": "LCG",
        "icao": "LECO",
        "airportName": "A Coruna Airport",
        "location_country": "Spain",
        "location_city": "Galicia",
        "location_state": "A Coruna",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.302101",
        "longitude": "-8.37726",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6358",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55005,
            "s_seats": 46139,
            "j_seats": 34101,
            "f_seats": 14027,
            "r_seats": 3197,
            "cargo_demand": 326870
        }
    },
    {
        "iata": "LCH",
        "icao": "KLCH",
        "airportName": "Lake Charles Regional Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Lake Charles",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "30.12610054",
        "longitude": "-93.22329712",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5200",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 148241,
            "s_seats": 40121,
            "j_seats": 48269,
            "f_seats": 17667,
            "r_seats": 7364,
            "cargo_demand": 399059
        }
    },
    {
        "iata": "LCI",
        "icao": "KLCI",
        "airportName": "Laconia Municipal Airport",
        "location_country": "United States",
        "location_city": "New Hampshire",
        "location_state": "Laconia",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.572701",
        "longitude": "-71.4189",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5286",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2929,
            "s_seats": 2971,
            "j_seats": 1053,
            "f_seats": 229,
            "r_seats": 134,
            "cargo_demand": 21859
        }
    },
    {
        "iata": "LCJ",
        "icao": "EPLL",
        "airportName": " \u0141odz W\u0142adys\u0142aw Reymont Airport",
        "location_country": "Poland",
        "location_city": " \u0141odz",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.72190094",
        "longitude": "19.3980999",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "8202",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "2296",
                "runway_width": "574",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 176544,
            "s_seats": 46578,
            "j_seats": 36866,
            "f_seats": 19746,
            "r_seats": 5999,
            "cargo_demand": 222335
        }
    },
    {
        "iata": "LCK",
        "icao": "KLCK",
        "airportName": "Rickenbacker International Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Columbus",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.813801",
        "longitude": "-82.927803",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "11937",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "12102",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "06/24",
                "runway_length": "3244",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151450,
            "s_seats": 42290,
            "j_seats": 55957,
            "f_seats": 16070,
            "r_seats": 5292,
            "cargo_demand": 345634
        }
    },
    {
        "iata": "LCL",
        "icao": "MULM",
        "airportName": "La Coloma Airport",
        "location_country": "Cuba",
        "location_city": "Pinar del Rio",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "22.33609962",
        "longitude": "-83.64189911",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90421,
            "s_seats": 46085,
            "j_seats": 47809,
            "f_seats": 1224,
            "r_seats": 5767,
            "cargo_demand": 416438
        }
    },
    {
        "iata": "LCM",
        "icao": "SACC",
        "airportName": "La Cumbre Airport",
        "location_country": "Argentina",
        "location_city": "Cordoba",
        "location_state": "La Cumbre",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31.00580025",
        "longitude": "-64.5318985",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4101",
                "runway_width": "148",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2425,
            "s_seats": 1835,
            "j_seats": 1612,
            "f_seats": 263,
            "r_seats": 164,
            "cargo_demand": 40526
        }
    },
    {
        "iata": "LCN",
        "icao": "YBLC",
        "airportName": "Balcanoona Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Balcanoona",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-30.53499985",
        "longitude": "139.3370056",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4199",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 3586,
            "s_seats": 2926,
            "j_seats": 1523,
            "f_seats": 189,
            "r_seats": 112,
            "cargo_demand": 98803
        }
    },
    {
        "iata": "LCQ",
        "icao": "KLCQ",
        "airportName": "Lake City Gateway Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Lake City",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "30.18199921",
        "longitude": "-82.57689667",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "8002",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3877,
            "s_seats": 2226,
            "j_seats": 1782,
            "f_seats": 332,
            "r_seats": 24,
            "cargo_demand": 81916
        }
    },
    {
        "iata": "LCV",
        "icao": "LIQL",
        "airportName": "Lucca-Tassignano Airport",
        "location_country": "Italy",
        "location_city": "Tuscany",
        "location_state": "Lucca",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "43.825825",
        "longitude": "10.577928",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2986",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3056,
            "s_seats": 1730,
            "j_seats": 1170,
            "f_seats": 234,
            "r_seats": 57,
            "cargo_demand": 57527
        }
    },
    {
        "iata": "LCY",
        "icao": "EGLC",
        "airportName": "London City Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "London",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.505299",
        "longitude": "0.055278",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4948",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 70129,
            "s_seats": 28071,
            "j_seats": 30497,
            "f_seats": 8547,
            "r_seats": 9638,
            "cargo_demand": 267361
        }
    },
    {
        "iata": "LDA",
        "icao": "VEMH",
        "airportName": "Malda Airport",
        "location_country": "India",
        "location_city": "West Bengal",
        "location_state": "Malda",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "25.033001",
        "longitude": "88.133003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3600",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1721,
            "s_seats": 2089,
            "j_seats": 1177,
            "f_seats": 1,
            "r_seats": 42,
            "cargo_demand": 51136
        }
    },
    {
        "iata": "LDB",
        "icao": "SBLO",
        "airportName": "Londrina-Governador Jose Richa Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Londrina",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-23.33359909",
        "longitude": "-51.13010025",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59656,
            "s_seats": 32053,
            "j_seats": 40220,
            "f_seats": 13044,
            "r_seats": 4068,
            "cargo_demand": 324224
        }
    },
    {
        "iata": "LDE",
        "icao": "LFBT",
        "airportName": "Tarbes-Lourdes-Pyrenees Airport",
        "location_country": "France",
        "location_city": "Midi-Pyrenees",
        "location_state": "Tarbes / Lourdes",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.17869949",
        "longitude": "-0.006439",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171445,
            "s_seats": 31973,
            "j_seats": 32229,
            "f_seats": 6025,
            "r_seats": 6525,
            "cargo_demand": 384487
        }
    },
    {
        "iata": "LDG",
        "icao": "ULAL",
        "airportName": "Leshukonskoye Airport",
        "location_country": "Russia",
        "location_city": "Arkhangelsk Oblast",
        "location_state": "Leshukonskoye",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "64.89600372",
        "longitude": "45.72299957",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2132",
                "runway_width": "197",
                "runway_material": "dirt"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5236",
                "runway_width": "118",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 2475,
            "s_seats": 2581,
            "j_seats": 1832,
            "f_seats": 112,
            "r_seats": 113,
            "cargo_demand": 41767
        }
    },
    {
        "iata": "LDH",
        "icao": "YLHI",
        "airportName": "Lord Howe Island Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Lord Howe Island",
        "time_zone": "UTC+10:30",
        "daylight_saving": "Oct-Apr1",
        "airport_size": "small_airport",
        "latitude": "-31.538204",
        "longitude": "159.07546",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2906",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 3057,
            "s_seats": 2834,
            "j_seats": 1649,
            "f_seats": 382,
            "r_seats": 177,
            "cargo_demand": 7263
        }
    },
    {
        "iata": "LDJ",
        "icao": "KLDJ",
        "airportName": "Linden Airport",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Linden",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.61740112",
        "longitude": "-74.24459839",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4137",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2595,
            "s_seats": 1696,
            "j_seats": 1588,
            "f_seats": 47,
            "r_seats": 122,
            "cargo_demand": 68564
        }
    },
    {
        "iata": "LDK",
        "icao": "ESGL",
        "airportName": "Lidkoping-Hovby Airport",
        "location_country": "Sweden",
        "location_city": "Lidkoping",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "58.4655",
        "longitude": "13.1744",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6529",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2895,
            "s_seats": 1746,
            "j_seats": 1866,
            "f_seats": 133,
            "r_seats": 67,
            "cargo_demand": 57035
        }
    },
    {
        "iata": "LDM",
        "icao": "KLDM",
        "airportName": "Mason County Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Ludington",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.96250153",
        "longitude": "-86.40789795",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3503",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "08/26",
                "runway_length": "5003",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 3578,
            "s_seats": 1860,
            "j_seats": 1311,
            "f_seats": 476,
            "r_seats": 157,
            "cargo_demand": 66607
        }
    },
    {
        "iata": "LDU",
        "icao": "WBKD",
        "airportName": "Lahad Datu Airport",
        "location_country": "Malaysia",
        "location_city": "Sabah",
        "location_state": "Lahad Datu",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.032249928",
        "longitude": "118.3239975",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4498",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172887,
            "s_seats": 33614,
            "j_seats": 53917,
            "f_seats": 7998,
            "r_seats": 5309,
            "cargo_demand": 206836
        }
    },
    {
        "iata": "LDV",
        "icao": "LFRJ",
        "airportName": "Landivisiau Air Base",
        "location_country": "France",
        "location_city": "Brittany",
        "location_state": "Landivisiau",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.53030014",
        "longitude": "-4.151639938",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 185482,
            "s_seats": 39131,
            "j_seats": 13850,
            "f_seats": 17846,
            "r_seats": 8137,
            "cargo_demand": 146761
        }
    },
    {
        "iata": "LDY",
        "icao": "EGAE",
        "airportName": "City of Derry Airport",
        "location_country": "United Kingdom",
        "location_city": "Northern Ireland",
        "location_state": "Derry (Londonderry)",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "55.0428009",
        "longitude": "-7.161109924",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3955",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6460",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70461,
            "s_seats": 47168,
            "j_seats": 15596,
            "f_seats": 2760,
            "r_seats": 7236,
            "cargo_demand": 462551
        }
    },
    {
        "iata": "LDZ",
        "icao": "FALD",
        "airportName": "Londolozi Airport",
        "location_country": "South Africa",
        "location_city": "Londolozi",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.7478",
        "longitude": "31.4743",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3000",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2230,
            "s_seats": 2673,
            "j_seats": 1345,
            "f_seats": 256,
            "r_seats": 79,
            "cargo_demand": 82242
        }
    },
    {
        "iata": "LEA",
        "icao": "YPLM",
        "airportName": "Learmonth Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Exmouth",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.23559952",
        "longitude": "114.0889969",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9997",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173069,
            "s_seats": 41454,
            "j_seats": 15712,
            "f_seats": 1139,
            "r_seats": 3665,
            "cargo_demand": 354913
        }
    },
    {
        "iata": "LEB",
        "icao": "KLEB",
        "airportName": "Lebanon Municipal Airport",
        "location_country": "United States",
        "location_city": "New Hampshire",
        "location_state": "Lebanon / Hanover",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.62609863",
        "longitude": "-72.30419922",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5496",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5200",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63086,
            "s_seats": 33692,
            "j_seats": 48776,
            "f_seats": 16232,
            "r_seats": 4892,
            "cargo_demand": 290030
        }
    },
    {
        "iata": "LEC",
        "icao": "SBLE",
        "airportName": "Coronel Horacio de Mattos Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "LeniCois",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.4822998",
        "longitude": "-41.27700043",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6831",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1927,
            "s_seats": 2684,
            "j_seats": 1581,
            "f_seats": 40,
            "r_seats": 144,
            "cargo_demand": 35629
        }
    },
    {
        "iata": "LED",
        "icao": "ULLI",
        "airportName": "Pulkovo Airport",
        "location_country": "Russia",
        "location_city": "Saint Petersburg",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "59.8003006",
        "longitude": "30.26250076",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "11146",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "12402",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 483291,
            "s_seats": 78458,
            "j_seats": 129777,
            "f_seats": 34342,
            "r_seats": 10000,
            "cargo_demand": 26562520
        }
    },
    {
        "iata": "LEE",
        "icao": "KLEE",
        "airportName": "Leesburg International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Leesburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "28.82309914",
        "longitude": "-81.80870056",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4957",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135553,
            "s_seats": 34357,
            "j_seats": 50515,
            "f_seats": 11811,
            "r_seats": 6527,
            "cargo_demand": 252937
        }
    },
    {
        "iata": "LEH",
        "icao": "LFOH",
        "airportName": "Le Havre - Octeville Airport",
        "location_country": "France",
        "location_city": "Upper Normandy",
        "location_state": "Le Havre",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.53390121",
        "longitude": "0.088055998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7546",
                "runway_width": "130",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106605,
            "s_seats": 41542,
            "j_seats": 28146,
            "f_seats": 7234,
            "r_seats": 4279,
            "cargo_demand": 486937
        }
    },
    {
        "iata": "LEI",
        "icao": "LEAM",
        "airportName": "Almeria Airport",
        "location_country": "Spain",
        "location_city": "Andalusia",
        "location_state": "Almeria",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "36.84389877",
        "longitude": "-2.370100021",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192495,
            "s_seats": 34684,
            "j_seats": 47740,
            "f_seats": 17712,
            "r_seats": 6259,
            "cargo_demand": 308780
        }
    },
    {
        "iata": "LEJ",
        "icao": "EDDP",
        "airportName": "Leipzig/Halle Airport",
        "location_country": "Germany",
        "location_city": "Saxony-Anhalt",
        "location_state": "Saxony / Halle",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "51.423889",
        "longitude": "12.236389",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "11811",
                "runway_width": "198",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 407187,
            "s_seats": 90588,
            "j_seats": 67376,
            "f_seats": 57825,
            "r_seats": 10000,
            "cargo_demand": 9689567
        }
    },
    {
        "iata": "LEK",
        "icao": "GULB",
        "airportName": "Tata Airport",
        "location_country": "Guinea",
        "location_city": "Labe",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "11.32610035",
        "longitude": "-12.28680038",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2904,
            "s_seats": 2281,
            "j_seats": 1727,
            "f_seats": 97,
            "r_seats": 56,
            "cargo_demand": 11259
        }
    },
    {
        "iata": "LEL",
        "icao": "YLEV",
        "airportName": "Lake Evella Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Gapuwiyak",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.49890041",
        "longitude": "135.8059998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3494",
                "runway_width": "60",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 3933,
            "s_seats": 2601,
            "j_seats": 1736,
            "f_seats": 263,
            "r_seats": 189,
            "cargo_demand": 34882
        }
    },
    {
        "iata": "LEM",
        "icao": "KLEM",
        "airportName": "Lemmon Municipal Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Lemmon",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.91870117",
        "longitude": "-102.1060028",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4501",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "1900",
                "runway_width": "60",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1276,
            "s_seats": 2685,
            "j_seats": 1696,
            "f_seats": 242,
            "r_seats": 39,
            "cargo_demand": 90730
        }
    },
    {
        "iata": "LEN",
        "icao": "LELN",
        "airportName": "Leon Airport",
        "location_country": "Spain",
        "location_city": "Castile and Leon",
        "location_state": "Leon",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "42.589001",
        "longitude": "-5.65556",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "3609",
                "runway_width": "328",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 162536,
            "s_seats": 39977,
            "j_seats": 56054,
            "f_seats": 12036,
            "r_seats": 7559,
            "cargo_demand": 157077
        }
    },
    {
        "iata": "LEP",
        "icao": "SNDN",
        "airportName": "Leopoldina Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Leopoldina",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-21.466101",
        "longitude": "-42.727001",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3586,
            "s_seats": 2200,
            "j_seats": 1629,
            "f_seats": 416,
            "r_seats": 45,
            "cargo_demand": 99311
        }
    },
    {
        "iata": "LEQ",
        "icao": "EGHC",
        "airportName": "Land's End Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "St Just",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "50.102798",
        "longitude": "-5.67056",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "1981",
                "runway_width": "59",
                "runway_material": "Grass"
            },
            {
                "runway_name": "07/25",
                "runway_length": "2280",
                "runway_width": "118",
                "runway_material": "Grass"
            },
            {
                "runway_name": "12/30",
                "runway_length": "1571",
                "runway_width": "59",
                "runway_material": "Grass"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2598",
                "runway_width": "118",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3778,
            "s_seats": 2860,
            "j_seats": 1169,
            "f_seats": 376,
            "r_seats": 25,
            "cargo_demand": 39609
        }
    },
    {
        "iata": "LER",
        "icao": "YLST",
        "airportName": "Leinster Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Leinster",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.84329987",
        "longitude": "120.7030029",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74186,
            "s_seats": 47663,
            "j_seats": 6343,
            "f_seats": 12838,
            "r_seats": 7714,
            "cargo_demand": 491923
        }
    },
    {
        "iata": "LET",
        "icao": "SKLT",
        "airportName": "Alfredo Vasquez Cobo International Airport",
        "location_country": "Colombia",
        "location_city": "Leticia",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-4.19355",
        "longitude": "-69.9432",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6168",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118378,
            "s_seats": 26336,
            "j_seats": 34370,
            "f_seats": 16591,
            "r_seats": 3234,
            "cargo_demand": 496066
        }
    },
    {
        "iata": "LEU",
        "icao": "LESU",
        "airportName": "La Seu d'Urgell Airport",
        "location_country": "Spain",
        "location_city": "Catalonia",
        "location_state": "La Seu d'Urgell",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "42.3386",
        "longitude": "1.40917",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4396",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2937,
            "s_seats": 2216,
            "j_seats": 1977,
            "f_seats": 440,
            "r_seats": 93,
            "cargo_demand": 64151
        }
    },
    {
        "iata": "LEW",
        "icao": "KLEW",
        "airportName": "Auburn/Lewiston Municipal Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Auburn / Lewiston",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.04850006",
        "longitude": "-70.28350067",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2750",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2114,
            "s_seats": 2354,
            "j_seats": 1519,
            "f_seats": 38,
            "r_seats": 190,
            "cargo_demand": 6637
        }
    },
    {
        "iata": "LEX",
        "icao": "KLEX",
        "airportName": "Blue Grass Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Lexington",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "38.03649902",
        "longitude": "-84.60590363",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7003",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 118875,
            "s_seats": 95632,
            "j_seats": 158698,
            "f_seats": 67823,
            "r_seats": 10000,
            "cargo_demand": 29779561
        }
    },
    {
        "iata": "LEY",
        "icao": "EHLE",
        "airportName": "Lelystad Airport",
        "location_country": "Netherlands",
        "location_city": "Lelystad",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "52.4603",
        "longitude": "5.52722",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8858",
                "runway_width": "147",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "05L/23R",
                "runway_length": "4495",
                "runway_width": "98",
                "runway_material": "grass"
            }
        ],
        "demand": {
            "y_seats": 145854,
            "s_seats": 28709,
            "j_seats": 23515,
            "f_seats": 6441,
            "r_seats": 3553,
            "cargo_demand": 480441
        }
    },
    {
        "iata": "LFB",
        "icao": "FQLU",
        "airportName": "Lumbo Airport",
        "location_country": "Mozambique",
        "location_city": "Lumbo",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.03310013",
        "longitude": "40.67169952",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3929,
            "s_seats": 2280,
            "j_seats": 1496,
            "f_seats": 182,
            "r_seats": 119,
            "cargo_demand": 46924
        }
    },
    {
        "iata": "LFI",
        "icao": "KLFI",
        "airportName": "Langley Air Force Base",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Hampton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "37.082901",
        "longitude": "-76.36049652",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 362691,
            "s_seats": 79876,
            "j_seats": 150588,
            "f_seats": 66504,
            "r_seats": 10000,
            "cargo_demand": 16797169
        }
    },
    {
        "iata": "LFK",
        "icao": "KLFK",
        "airportName": "Angelina County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Lufkin",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.23399925",
        "longitude": "-94.75",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5398",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4309",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198684,
            "s_seats": 34472,
            "j_seats": 47617,
            "f_seats": 5588,
            "r_seats": 3765,
            "cargo_demand": 369218
        }
    },
    {
        "iata": "LFM",
        "icao": "OISR",
        "airportName": "Lamerd Airport",
        "location_country": "Iran",
        "location_city": "Lamerd",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "27.3727",
        "longitude": "53.188801",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10020",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198576,
            "s_seats": 25461,
            "j_seats": 20918,
            "f_seats": 9208,
            "r_seats": 8854,
            "cargo_demand": 47981
        }
    },
    {
        "iata": "LFN",
        "icao": "KLHZ",
        "airportName": "Triangle North Executive Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Louisburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.0233",
        "longitude": "-78.330299",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2179,
            "s_seats": 2967,
            "j_seats": 1846,
            "f_seats": 89,
            "r_seats": 118,
            "cargo_demand": 79904
        }
    },
    {
        "iata": "LFP",
        "icao": "YLFD",
        "airportName": "Lakefield Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Lakefield",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.9207",
        "longitude": "144.203",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3074,
            "s_seats": 2341,
            "j_seats": 1091,
            "f_seats": 476,
            "r_seats": 79,
            "cargo_demand": 7440
        }
    },
    {
        "iata": "LFR",
        "icao": "SVLF",
        "airportName": "La Fria Airport",
        "location_country": "Venezuela",
        "location_city": "La Fria",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.239167213",
        "longitude": "-72.27102661",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6643",
                "runway_width": "190",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 116766,
            "s_seats": 28440,
            "j_seats": 41771,
            "f_seats": 19572,
            "r_seats": 4815,
            "cargo_demand": 336199
        }
    },
    {
        "iata": "LFT",
        "icao": "KLFT",
        "airportName": "Lafayette Regional Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Lafayette",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "30.20529938",
        "longitude": "-91.98760223",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "4099",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "7651",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5399",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 211409,
            "s_seats": 70337,
            "j_seats": 134169,
            "f_seats": 40020,
            "r_seats": 10000,
            "cargo_demand": 21780738
        }
    },
    {
        "iata": "LFW",
        "icao": "DXXX",
        "airportName": "Lome-Tokoin Airport",
        "location_country": "Togo",
        "location_city": "Lome",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.16561",
        "longitude": "1.25451",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9847",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178051,
            "s_seats": 43323,
            "j_seats": 23552,
            "f_seats": 17342,
            "r_seats": 5495,
            "cargo_demand": 245204
        }
    },
    {
        "iata": "LGA",
        "icao": "KLGA",
        "airportName": "LaGuardia Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "New York City",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "40.777199",
        "longitude": "-73.872597",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "H1/H1",
                "runway_length": "60",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147754,
            "s_seats": 77416,
            "j_seats": 38250,
            "f_seats": 55779,
            "r_seats": 10000,
            "cargo_demand": 22459370
        }
    },
    {
        "iata": "LGB",
        "icao": "KLGB",
        "airportName": "Long Beach Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Long Beach",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.817699",
        "longitude": "-118.152",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "6192",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "3918",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "10000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "4267",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "4470",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106463,
            "s_seats": 25522,
            "j_seats": 24378,
            "f_seats": 15388,
            "r_seats": 3714,
            "cargo_demand": 125859
        }
    },
    {
        "iata": "LGC",
        "icao": "KLGC",
        "airportName": "LaGrange Callaway Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "LaGrange",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.008873",
        "longitude": "-85.074331",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5600",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3810,
            "s_seats": 2183,
            "j_seats": 1401,
            "f_seats": 466,
            "r_seats": 162,
            "cargo_demand": 20814
        }
    },
    {
        "iata": "LGD",
        "icao": "KLGD",
        "airportName": "La Grande/Union County Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "La Grande",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.29019928",
        "longitude": "-118.0070038",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5600",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3876",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1059,
            "s_seats": 1647,
            "j_seats": 1556,
            "f_seats": 415,
            "r_seats": 37,
            "cargo_demand": 58763
        }
    },
    {
        "iata": "LGF",
        "icao": "KLGF",
        "airportName": "Laguna Army Airfield",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Yuma Proving Ground",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.86000061",
        "longitude": "-114.3970032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6118",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2850,
            "s_seats": 2405,
            "j_seats": 1742,
            "f_seats": 209,
            "r_seats": 133,
            "cargo_demand": 65304
        }
    },
    {
        "iata": "LGG",
        "icao": "EBLG",
        "airportName": "Liege Airport",
        "location_country": "Belgium",
        "location_city": "Liege",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "50.63740158",
        "longitude": "5.443220139",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "7677",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "12106",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186133,
            "s_seats": 71548,
            "j_seats": 34654,
            "f_seats": 45989,
            "r_seats": 10000,
            "cargo_demand": 16216435
        }
    },
    {
        "iata": "LGH",
        "icao": "YLEC",
        "airportName": "Leigh Creek Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Leigh Creek",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-30.59830093",
        "longitude": "138.4259949",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3999",
                "runway_width": "59",
                "runway_material": "BIT"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5610",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68856,
            "s_seats": 42542,
            "j_seats": 56231,
            "f_seats": 3810,
            "r_seats": 8154,
            "cargo_demand": 228371
        }
    },
    {
        "iata": "LGI",
        "icao": "MYLD",
        "airportName": "Deadman's Cay Airport",
        "location_country": "Bahamas",
        "location_city": "Long Island",
        "location_state": "Deadman's Cay",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "23.17900085",
        "longitude": "-75.09359741",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194690,
            "s_seats": 34818,
            "j_seats": 59829,
            "f_seats": 2403,
            "r_seats": 8599,
            "cargo_demand": 434212
        }
    },
    {
        "iata": "LGK",
        "icao": "WMKL",
        "airportName": "Langkawi International Airport",
        "location_country": "Malaysia",
        "location_city": "Kedah",
        "location_state": "Langkawi",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.329730034",
        "longitude": "99.72869873",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "12500",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136200,
            "s_seats": 33648,
            "j_seats": 39672,
            "f_seats": 5124,
            "r_seats": 9155,
            "cargo_demand": 408272
        }
    },
    {
        "iata": "LGO",
        "icao": "EDWL",
        "airportName": "Langeoog Airport",
        "location_country": "Germany",
        "location_city": "Lower Saxony",
        "location_state": "Langeoog",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "53.74250031",
        "longitude": "7.497777939",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "1968",
                "runway_width": "49",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 3787,
            "s_seats": 2062,
            "j_seats": 1759,
            "f_seats": 268,
            "r_seats": 72,
            "cargo_demand": 31271
        }
    },
    {
        "iata": "LGP",
        "icao": "RPLP",
        "airportName": "Legazpi International Airport",
        "location_country": "Philippines",
        "location_city": "Legazpi",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.1575",
        "longitude": "123.735",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7480",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135126,
            "s_seats": 28606,
            "j_seats": 15136,
            "f_seats": 6236,
            "r_seats": 9534,
            "cargo_demand": 199734
        }
    },
    {
        "iata": "LGQ",
        "icao": "SENL",
        "airportName": "Lago Agrio Airport",
        "location_country": "Ecuador",
        "location_city": "Nueva Loja (Lago Agrio)",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.093056001",
        "longitude": "-76.86750031",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7546",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1672,
            "s_seats": 2548,
            "j_seats": 1742,
            "f_seats": 257,
            "r_seats": 24,
            "cargo_demand": 87026
        }
    },
    {
        "iata": "LGR",
        "icao": "SCHR",
        "airportName": "Cochrane Airfield",
        "location_country": "Chile",
        "location_city": "Cochrane",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-47.24380112",
        "longitude": "-72.58840179",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3346",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57509,
            "s_seats": 27843,
            "j_seats": 31208,
            "f_seats": 2820,
            "r_seats": 6019,
            "cargo_demand": 155402
        }
    },
    {
        "iata": "LGS",
        "icao": "SAMM",
        "airportName": "Comodoro D. Ricardo Salomon Airport",
        "location_country": "Argentina",
        "location_city": "Mendoza",
        "location_state": "Malargue",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-35.49359894",
        "longitude": "-69.57430267",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8694",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "9/27",
                "runway_length": "4757",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104539,
            "s_seats": 44470,
            "j_seats": 49155,
            "f_seats": 18914,
            "r_seats": 9160,
            "cargo_demand": 496887
        }
    },
    {
        "iata": "LGU",
        "icao": "KLGU",
        "airportName": "Logan-Cache Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Logan",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "41.79119873",
        "longitude": "-111.8519974",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5007",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4075",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "9010",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190469,
            "s_seats": 25251,
            "j_seats": 57492,
            "f_seats": 9588,
            "r_seats": 4171,
            "cargo_demand": 226494
        }
    },
    {
        "iata": "LGW",
        "icao": "EGKK",
        "airportName": "Gatwick Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "London",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "51.148102",
        "longitude": "-0.190278",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "8415",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "10879",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 461566,
            "s_seats": 72480,
            "j_seats": 141973,
            "f_seats": 58289,
            "r_seats": 10000,
            "cargo_demand": 9198351
        }
    },
    {
        "iata": "LHA",
        "icao": "EDTL",
        "airportName": "Flughafen Lahr",
        "location_country": "Germany",
        "location_city": "Baden-Wurttemberg",
        "location_state": "Lahr",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.36930084",
        "longitude": "7.827720165",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118630,
            "s_seats": 30788,
            "j_seats": 33653,
            "f_seats": 15605,
            "r_seats": 7327,
            "cargo_demand": 364182
        }
    },
    {
        "iata": "LHE",
        "icao": "OPLA",
        "airportName": "Allama Iqbal International Airport",
        "location_country": "Pakistan",
        "location_city": "Lahore",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.52160072",
        "longitude": "74.4036026",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "11024",
                "runway_width": "151",
                "runway_material": "CON"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "9000",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170710,
            "s_seats": 42385,
            "j_seats": 35190,
            "f_seats": 12066,
            "r_seats": 6379,
            "cargo_demand": 341922
        }
    },
    {
        "iata": "LHG",
        "icao": "YLRD",
        "airportName": "Lightning Ridge Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Lightning Ridge",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-29.45669937",
        "longitude": "147.9839935",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2405",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4613",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138718,
            "s_seats": 31095,
            "j_seats": 47533,
            "f_seats": 5045,
            "r_seats": 5649,
            "cargo_demand": 489483
        }
    },
    {
        "iata": "LHI",
        "icao": "WAJL",
        "airportName": "Lereh Airport",
        "location_country": "Indonesia",
        "location_city": "Lereh",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.0795",
        "longitude": "139.952",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2016",
                "runway_width": "62",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1773,
            "s_seats": 1681,
            "j_seats": 1506,
            "f_seats": 356,
            "r_seats": 179,
            "cargo_demand": 98591
        }
    },
    {
        "iata": "LHR",
        "icao": "EGLL",
        "airportName": "Heathrow Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "London",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "51.4706",
        "longitude": "-0.461941",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "12799",
                "runway_width": "164",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "12001",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 426957,
            "s_seats": 72353,
            "j_seats": 94038,
            "f_seats": 42889,
            "r_seats": 10000,
            "cargo_demand": 1218310
        }
    },
    {
        "iata": "LHS",
        "icao": "SAVH",
        "airportName": "Las Heras Airport",
        "location_country": "Argentina",
        "location_city": "Santa Cruz",
        "location_state": "Las Heras",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-46.53829956",
        "longitude": "-68.96530151",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "4593",
                "runway_width": "56",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67287,
            "s_seats": 34476,
            "j_seats": 5101,
            "f_seats": 15590,
            "r_seats": 9875,
            "cargo_demand": 256821
        }
    },
    {
        "iata": "LHU",
        "icao": "FYLS",
        "airportName": "Lianshulu Airport",
        "location_country": "Namibia",
        "location_city": "Lianshulu",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "small_airport",
        "latitude": "-18.11669922",
        "longitude": "23.3932991",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4298",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2565,
            "s_seats": 2286,
            "j_seats": 1451,
            "f_seats": 220,
            "r_seats": 40,
            "cargo_demand": 43637
        }
    },
    {
        "iata": "LHV",
        "icao": "KLHV",
        "airportName": "William T. Piper Memorial Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Lock Haven",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.13560104",
        "longitude": "-77.42230225",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "3806",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "2200",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2800,
            "s_seats": 2527,
            "j_seats": 1974,
            "f_seats": 393,
            "r_seats": 36,
            "cargo_demand": 83615
        }
    },
    {
        "iata": "LHW",
        "icao": "ZLLL",
        "airportName": "Lanzhou Zhongchuan International Airport",
        "location_country": "China",
        "location_city": "Gansu",
        "location_state": "Lanzhou",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.51520157",
        "longitude": "103.6200027",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 160136,
            "s_seats": 45254,
            "j_seats": 46400,
            "f_seats": 9750,
            "r_seats": 4264,
            "cargo_demand": 421535
        }
    },
    {
        "iata": "LIC",
        "icao": "KLIC",
        "airportName": "Limon Municipal Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Limon",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.27479935",
        "longitude": "-103.6660004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4700",
                "runway_width": "60",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1180,
            "s_seats": 2717,
            "j_seats": 1026,
            "f_seats": 351,
            "r_seats": 15,
            "cargo_demand": 77672
        }
    },
    {
        "iata": "LIF",
        "icao": "NWWL",
        "airportName": "Ouanaham Airport",
        "location_country": "New Caledonia",
        "location_city": "Loyalty Islands",
        "location_state": "Lifou",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.77479935",
        "longitude": "167.2400055",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "MAC"
            }
        ],
        "demand": {
            "y_seats": 112026,
            "s_seats": 30282,
            "j_seats": 30417,
            "f_seats": 5772,
            "r_seats": 8859,
            "cargo_demand": 224168
        }
    },
    {
        "iata": "LIG",
        "icao": "LFBL",
        "airportName": "Limoges - Bellegarde Airport",
        "location_country": "France",
        "location_city": "Limousin",
        "location_state": "Limoges",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.8628006",
        "longitude": "1.179440022",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53232,
            "s_seats": 37403,
            "j_seats": 7817,
            "f_seats": 16912,
            "r_seats": 4190,
            "cargo_demand": 205323
        }
    },
    {
        "iata": "LIH",
        "icao": "PHLI",
        "airportName": "Lihue Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Lihue",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.97599983",
        "longitude": "-159.3390045",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154738,
            "s_seats": 39847,
            "j_seats": 44256,
            "f_seats": 3443,
            "r_seats": 7486,
            "cargo_demand": 481734
        }
    },
    {
        "iata": "LII",
        "icao": "WAJM",
        "airportName": "Mulia Airport",
        "location_country": "Indonesia",
        "location_city": "Mulia",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.7018",
        "longitude": "137.957",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2615",
                "runway_width": "89",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3997,
            "s_seats": 1531,
            "j_seats": 1736,
            "f_seats": 187,
            "r_seats": 38,
            "cargo_demand": 87106
        }
    },
    {
        "iata": "LIL",
        "icao": "LFQQ",
        "airportName": "Lille Airport",
        "location_country": "France",
        "location_city": "Nord-Pas-de-Calais",
        "location_state": "Lille",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.563332",
        "longitude": "3.086886",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5184",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "9268",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196037,
            "s_seats": 49322,
            "j_seats": 42786,
            "f_seats": 1538,
            "r_seats": 6207,
            "cargo_demand": 279709
        }
    },
    {
        "iata": "LIN",
        "icao": "LIML",
        "airportName": "Linate Airport",
        "location_country": "Italy",
        "location_city": "Lombardy",
        "location_state": "Milan",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.445099",
        "longitude": "9.27674",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "1972",
                "runway_width": "72",
                "runway_material": "BIT"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8005",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177037,
            "s_seats": 38925,
            "j_seats": 58851,
            "f_seats": 11303,
            "r_seats": 8971,
            "cargo_demand": 375399
        }
    },
    {
        "iata": "LIO",
        "icao": "MRLM",
        "airportName": "Limon International Airport",
        "location_country": "Costa Rica",
        "location_city": "Limon",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.957960129",
        "longitude": "-83.02200317",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142027,
            "s_seats": 32256,
            "j_seats": 14341,
            "f_seats": 6223,
            "r_seats": 5213,
            "cargo_demand": 356679
        }
    },
    {
        "iata": "LIP",
        "icao": "SBLN",
        "airportName": "Lins Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Lins",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-21.66399956",
        "longitude": "-49.73049927",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5577",
                "runway_width": "105",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137416,
            "s_seats": 36662,
            "j_seats": 22240,
            "f_seats": 6697,
            "r_seats": 9340,
            "cargo_demand": 179639
        }
    },
    {
        "iata": "LIQ",
        "icao": "FZGA",
        "airportName": "Lisala Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Lisala",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.17066",
        "longitude": "21.496901",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7200",
                "runway_width": "164",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 95789,
            "s_seats": 31331,
            "j_seats": 8062,
            "f_seats": 15285,
            "r_seats": 8071,
            "cargo_demand": 181597
        }
    },
    {
        "iata": "LIR",
        "icao": "MRLB",
        "airportName": "Daniel Oduber Quiros International Airport",
        "location_country": "Costa Rica",
        "location_city": "Liberia",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "10.5933",
        "longitude": "-85.544403",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9022",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 439398,
            "s_seats": 96352,
            "j_seats": 48818,
            "f_seats": 62737,
            "r_seats": 10000,
            "cargo_demand": 293374
        }
    },
    {
        "iata": "LIS",
        "icao": "LPPT",
        "airportName": "Lisbon Portela Airport",
        "location_country": "Portugal",
        "location_city": "Lisbon",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "38.7813",
        "longitude": "-9.13592",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "12484",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 486495,
            "s_seats": 91313,
            "j_seats": 64719,
            "f_seats": 49140,
            "r_seats": 10000,
            "cargo_demand": 13494735
        }
    },
    {
        "iata": "LIT",
        "icao": "KLIT",
        "airportName": "Clinton National Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Little Rock",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "34.729582",
        "longitude": "-92.223728",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "8273",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "7200",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5124",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 134368,
            "s_seats": 99711,
            "j_seats": 127776,
            "f_seats": 39398,
            "r_seats": 10000,
            "cargo_demand": 6538370
        }
    },
    {
        "iata": "LIW",
        "icao": "VYLK",
        "airportName": "Loikaw Airport",
        "location_country": "Myanmar",
        "location_city": "Loikaw",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.69149971",
        "longitude": "97.21479797",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5200",
                "runway_width": "75",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 69989,
            "s_seats": 34080,
            "j_seats": 23297,
            "f_seats": 2318,
            "r_seats": 4841,
            "cargo_demand": 391474
        }
    },
    {
        "iata": "LIY",
        "icao": "KLHW",
        "airportName": "MidCoast Regional Airport at Wright Army Airfield",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Fort Stewart / Hinesville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.889099",
        "longitude": "-81.562303",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "5010",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "2605",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15L/33R",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "2518",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3527,
            "s_seats": 1637,
            "j_seats": 1524,
            "f_seats": 338,
            "r_seats": 114,
            "cargo_demand": 82537
        }
    },
    {
        "iata": "LJN",
        "icao": "KLBX",
        "airportName": "Texas Gulf Coast Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Angleton / Lake Jackson",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "29.108601",
        "longitude": "-95.462097",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185630,
            "s_seats": 26571,
            "j_seats": 10857,
            "f_seats": 15298,
            "r_seats": 7018,
            "cargo_demand": 207462
        }
    },
    {
        "iata": "LJU",
        "icao": "LJLJ",
        "airportName": "Ljubljana Jo\u017ee Pucnik Airport",
        "location_country": "Slovenia",
        "location_city": "Ljubljana",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "46.223701",
        "longitude": "14.4576",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190087,
            "s_seats": 72882,
            "j_seats": 119462,
            "f_seats": 25091,
            "r_seats": 10000,
            "cargo_demand": 2115358
        }
    },
    {
        "iata": "LKD",
        "icao": "YLND",
        "airportName": "Lakeland Downs Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Lakeland Downs",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.83329964",
        "longitude": "144.8500061",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3838",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 1023,
            "s_seats": 2662,
            "j_seats": 1153,
            "f_seats": 236,
            "r_seats": 78,
            "cargo_demand": 46446
        }
    },
    {
        "iata": "LKG",
        "icao": "HKLK",
        "airportName": "Lokichogio Airport",
        "location_country": "Kenya",
        "location_city": "Lokichogio",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.204120159",
        "longitude": "34.34820175",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6195",
                "runway_width": "62",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102126,
            "s_seats": 40282,
            "j_seats": 16893,
            "f_seats": 4533,
            "r_seats": 5657,
            "cargo_demand": 183096
        }
    },
    {
        "iata": "LKK",
        "icao": "PAKL",
        "airportName": "Kulik Lake Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kulik Lake",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "58.98210144",
        "longitude": "-155.1210022",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4350",
                "runway_width": "110",
                "runway_material": "GRVL-P"
            },
            {
                "runway_name": "18W/36W",
                "runway_length": "5000",
                "runway_width": "5000",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 3076,
            "s_seats": 2988,
            "j_seats": 1269,
            "f_seats": 77,
            "r_seats": 139,
            "cargo_demand": 20407
        }
    },
    {
        "iata": "LKL",
        "icao": "ENNA",
        "airportName": "Lakselv Airport",
        "location_country": "Norway",
        "location_city": "Lakselv",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "70.06880188",
        "longitude": "24.9734993",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9147",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178127,
            "s_seats": 38676,
            "j_seats": 35257,
            "f_seats": 17849,
            "r_seats": 6215,
            "cargo_demand": 360434
        }
    },
    {
        "iata": "LKN",
        "icao": "ENLK",
        "airportName": "Leknes Airport",
        "location_country": "Norway",
        "location_city": "Leknes",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "68.15249634",
        "longitude": "13.6093998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2881",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80626,
            "s_seats": 39959,
            "j_seats": 34776,
            "f_seats": 13478,
            "r_seats": 7592,
            "cargo_demand": 287167
        }
    },
    {
        "iata": "LKO",
        "icao": "VILK",
        "airportName": "Chaudhary Charan Singh International Airport",
        "location_country": "India",
        "location_city": "Uttar Pradesh",
        "location_state": "Lucknow",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.76059914",
        "longitude": "80.88929749",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8996",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 161534,
            "s_seats": 25650,
            "j_seats": 54130,
            "f_seats": 12750,
            "r_seats": 3264,
            "cargo_demand": 97445
        }
    },
    {
        "iata": "LKP",
        "icao": "KLKP",
        "airportName": "Lake Placid Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Lake Placid",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.26449966",
        "longitude": "-73.9618988",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4200",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1377,
            "s_seats": 1700,
            "j_seats": 1507,
            "f_seats": 277,
            "r_seats": 119,
            "cargo_demand": 58191
        }
    },
    {
        "iata": "LKV",
        "icao": "KLKV",
        "airportName": "Lake County Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Lakeview",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.16109848",
        "longitude": "-120.3990021",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5306",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3055,
            "s_seats": 1853,
            "j_seats": 1453,
            "f_seats": 177,
            "r_seats": 49,
            "cargo_demand": 75780
        }
    },
    {
        "iata": "LKY",
        "icao": "HTLM",
        "airportName": "Lake Manyara Airport",
        "location_country": "Tanzania",
        "location_city": "Lake Manyara",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.37631011",
        "longitude": "35.81829834",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4003",
                "runway_width": "66",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 136684,
            "s_seats": 30612,
            "j_seats": 29660,
            "f_seats": 8216,
            "r_seats": 9640,
            "cargo_demand": 86625
        }
    },
    {
        "iata": "LKZ",
        "icao": "EGUL",
        "airportName": "RAF Lakenheath",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Brandon",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "52.409302",
        "longitude": "0.561",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 163069,
            "s_seats": 42848,
            "j_seats": 5716,
            "f_seats": 19337,
            "r_seats": 4393,
            "cargo_demand": 443871
        }
    },
    {
        "iata": "LLA",
        "icao": "ESPA",
        "airportName": "Luleio Airport",
        "location_country": "Sweden",
        "location_city": "Luleio",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "65.54380035",
        "longitude": "22.12199974",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "10990",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193360,
            "s_seats": 82784,
            "j_seats": 23436,
            "f_seats": 65906,
            "r_seats": 10000,
            "cargo_demand": 14197320
        }
    },
    {
        "iata": "LLB",
        "icao": "ZULB",
        "airportName": "Libo Airport",
        "location_country": "China",
        "location_city": "Guizhou",
        "location_state": "Libo",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "25.4525",
        "longitude": "107.961667",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7546",
                "runway_width": "90",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 2201,
            "s_seats": 1885,
            "j_seats": 1905,
            "f_seats": 273,
            "r_seats": 89,
            "cargo_demand": 69197
        }
    },
    {
        "iata": "LLE",
        "icao": "FAMN",
        "airportName": "Malelane Airport",
        "location_country": "South Africa",
        "location_city": "Malalane",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.43000031",
        "longitude": "31.57670021",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4041",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81753,
            "s_seats": 46817,
            "j_seats": 9413,
            "f_seats": 6796,
            "r_seats": 4543,
            "cargo_demand": 96495
        }
    },
    {
        "iata": "LLG",
        "icao": "YCGO",
        "airportName": "Chillagoe Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Chillagoe",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.14279938",
        "longitude": "144.529007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3218",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3677,
            "s_seats": 2588,
            "j_seats": 1265,
            "f_seats": 85,
            "r_seats": 64,
            "cargo_demand": 49923
        }
    },
    {
        "iata": "LLI",
        "icao": "HALL",
        "airportName": "Lalibela Airport",
        "location_country": "Ethiopia",
        "location_city": "Lalibela",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "11.97500038",
        "longitude": "38.97999954",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7990",
                "runway_width": "175",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1614,
            "s_seats": 2309,
            "j_seats": 1402,
            "f_seats": 250,
            "r_seats": 64,
            "cargo_demand": 20549
        }
    },
    {
        "iata": "LLK",
        "icao": "UBBL",
        "airportName": "Lankaran International Airport",
        "location_country": "Azerbaijan",
        "location_city": "Lankaran",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.757919",
        "longitude": "48.807042",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5172",
                "runway_width": "132",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3085,
            "s_seats": 1865,
            "j_seats": 1552,
            "f_seats": 485,
            "r_seats": 148,
            "cargo_demand": 30520
        }
    },
    {
        "iata": "LLT",
        "icao": "FNLB",
        "airportName": "Lobito Airport",
        "location_country": "Angola",
        "location_city": "Lobito",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.3712",
        "longitude": "13.5366",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4920",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3143,
            "s_seats": 2644,
            "j_seats": 1274,
            "f_seats": 368,
            "r_seats": 49,
            "cargo_demand": 41968
        }
    },
    {
        "iata": "LLW",
        "icao": "FWKI",
        "airportName": "Lilongwe International Airport",
        "location_country": "Malawi",
        "location_city": "Lilongwe",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-13.7894001",
        "longitude": "33.78099823",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "11614",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165659,
            "s_seats": 32510,
            "j_seats": 22568,
            "f_seats": 12330,
            "r_seats": 4754,
            "cargo_demand": 127187
        }
    },
    {
        "iata": "LLX",
        "icao": "KCDA",
        "airportName": "Caledonia County Airport",
        "location_country": "United States",
        "location_city": "Vermont",
        "location_state": "Lyndonville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.56909943",
        "longitude": "-72.01799774",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3300",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1293,
            "s_seats": 1775,
            "j_seats": 1228,
            "f_seats": 212,
            "r_seats": 94,
            "cargo_demand": 5984
        }
    },
    {
        "iata": "LLY",
        "icao": "KVAY",
        "airportName": "South Jersey Regional Airport",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Mount Holly",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.94290161",
        "longitude": "-74.84570313",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3911",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1151,
            "s_seats": 2026,
            "j_seats": 1552,
            "f_seats": 0,
            "r_seats": 45,
            "cargo_demand": 51127
        }
    },
    {
        "iata": "LMA",
        "icao": "PAMH",
        "airportName": "Lake Minchumina Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Lake Minchumina",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "63.886002",
        "longitude": "-152.302002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4184",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 2329,
            "s_seats": 1877,
            "j_seats": 1428,
            "f_seats": 129,
            "r_seats": 138,
            "cargo_demand": 19720
        }
    },
    {
        "iata": "LME",
        "icao": "LFRM",
        "airportName": "Le Mans Arnage Airport",
        "location_country": "France",
        "location_city": "Pays de la Loire",
        "location_state": "Le Mans",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.94860077",
        "longitude": "0.201666996",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4658",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167946,
            "s_seats": 49250,
            "j_seats": 51504,
            "f_seats": 4431,
            "r_seats": 8933,
            "cargo_demand": 157425
        }
    },
    {
        "iata": "LMM",
        "icao": "MMLM",
        "airportName": "Fort Valley Federal International Airport",
        "location_country": "Mexico",
        "location_city": "Sinaloa",
        "location_state": "Los Mochis",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "large_airport",
        "latitude": "25.6852",
        "longitude": "-109.081001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 248675,
            "s_seats": 96678,
            "j_seats": 113211,
            "f_seats": 60684,
            "r_seats": 10000,
            "cargo_demand": 26462971
        }
    },
    {
        "iata": "LMN",
        "icao": "WBGJ",
        "airportName": "Limbang Airport",
        "location_country": "Malaysia",
        "location_city": "Sarawak",
        "location_state": "Limbang",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.808300018",
        "longitude": "115.0100021",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4922",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178098,
            "s_seats": 48052,
            "j_seats": 55509,
            "f_seats": 15243,
            "r_seats": 7626,
            "cargo_demand": 59224
        }
    },
    {
        "iata": "LMO",
        "icao": "EGQS",
        "airportName": "RAF Lossiemouth",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Lossiemouth",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "57.7052002",
        "longitude": "-3.339169979",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9091",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "6066",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 85840,
            "s_seats": 25477,
            "j_seats": 47580,
            "f_seats": 18154,
            "r_seats": 3641,
            "cargo_demand": 239911
        }
    },
    {
        "iata": "LMP",
        "icao": "LICD",
        "airportName": "Lampedusa Airport",
        "location_country": "Italy",
        "location_city": "Sicily",
        "location_state": "Lampedusa",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "35.497898",
        "longitude": "12.6181",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5906",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123233,
            "s_seats": 35011,
            "j_seats": 34791,
            "f_seats": 4356,
            "r_seats": 6342,
            "cargo_demand": 353661
        }
    },
    {
        "iata": "LMQ",
        "icao": "HLMB",
        "airportName": "Marsa Brega Airport",
        "location_country": "Libya",
        "location_city": "Brega",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.37809944",
        "longitude": "19.57640076",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7220",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172005,
            "s_seats": 34730,
            "j_seats": 47260,
            "f_seats": 18347,
            "r_seats": 4111,
            "cargo_demand": 381201
        }
    },
    {
        "iata": "LMR",
        "icao": "FALC",
        "airportName": "Finsch Mine Airport",
        "location_country": "South Africa",
        "location_city": "Lime Acres",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.36009979",
        "longitude": "23.43910027",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5249",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106455,
            "s_seats": 42935,
            "j_seats": 24285,
            "f_seats": 13903,
            "r_seats": 9061,
            "cargo_demand": 46905
        }
    },
    {
        "iata": "LMS",
        "icao": "KLMS",
        "airportName": "Louisville Winston County Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Louisville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.14619827",
        "longitude": "-89.0625",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4519",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3014,
            "s_seats": 2461,
            "j_seats": 1828,
            "f_seats": 397,
            "r_seats": 153,
            "cargo_demand": 30073
        }
    },
    {
        "iata": "LMT",
        "icao": "KLMT",
        "airportName": "Klamath Falls Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Klamath Falls",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.156101",
        "longitude": "-121.733002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5162",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "5260",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "10301",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 121679,
            "s_seats": 34100,
            "j_seats": 32428,
            "f_seats": 7363,
            "r_seats": 4532,
            "cargo_demand": 411384
        }
    },
    {
        "iata": "LNA",
        "icao": "KLNA",
        "airportName": "Palm Beach County Park Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "West Palm Beach",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "26.59300041",
        "longitude": "-80.08509827",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3256",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3489",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3421",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1299,
            "s_seats": 2673,
            "j_seats": 1488,
            "f_seats": 251,
            "r_seats": 22,
            "cargo_demand": 84913
        }
    },
    {
        "iata": "LNB",
        "icao": "NVSM",
        "airportName": "Lamen Bay Airport",
        "location_country": "Vanuatu",
        "location_city": "Shefa Province",
        "location_state": "Epi Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.58419991",
        "longitude": "168.1589966",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2789",
                "runway_width": "79",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1712,
            "s_seats": 2163,
            "j_seats": 1665,
            "f_seats": 365,
            "r_seats": 100,
            "cargo_demand": 94036
        }
    },
    {
        "iata": "LND",
        "icao": "KLND",
        "airportName": "Hunt Field",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Lander",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.81520081",
        "longitude": "-108.7300034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174751,
            "s_seats": 34501,
            "j_seats": 59067,
            "f_seats": 2019,
            "r_seats": 4535,
            "cargo_demand": 269561
        }
    },
    {
        "iata": "LNE",
        "icao": "NVSO",
        "airportName": "Lonorore Airport",
        "location_country": "Vanuatu",
        "location_city": "Penama Province",
        "location_state": "Pentecost Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.86559963",
        "longitude": "168.1719971",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2175",
                "runway_width": "66",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 2101,
            "s_seats": 1969,
            "j_seats": 1409,
            "f_seats": 7,
            "r_seats": 103,
            "cargo_demand": 18374
        }
    },
    {
        "iata": "LNH",
        "icao": "YLKN",
        "airportName": "Lake Nash Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Alpurrurulam (Lake Nash)",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.9807",
        "longitude": "137.9178",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2559",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3015",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3591,
            "s_seats": 2155,
            "j_seats": 1387,
            "f_seats": 226,
            "r_seats": 40,
            "cargo_demand": 79019
        }
    },
    {
        "iata": "LNK",
        "icao": "KLNK",
        "airportName": "Lincoln Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Lincoln",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.85100174",
        "longitude": "-96.75920105",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8649",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5400",
                "runway_width": "100",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "12901",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 141550,
            "s_seats": 46114,
            "j_seats": 23312,
            "f_seats": 11791,
            "r_seats": 3400,
            "cargo_demand": 486900
        }
    },
    {
        "iata": "LNL",
        "icao": "ZLLN",
        "airportName": "Longnan Chengzhou Airport",
        "location_country": "China",
        "location_city": "Gansu",
        "location_state": "Longnan",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.788",
        "longitude": "105.797",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 60520,
            "s_seats": 34475,
            "j_seats": 7549,
            "f_seats": 4806,
            "r_seats": 5537,
            "cargo_demand": 310491
        }
    },
    {
        "iata": "LNN",
        "icao": "KLNN",
        "airportName": "Lost Nation Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Willoughby",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.683998",
        "longitude": "-81.389702",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5028",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4272",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3038,
            "s_seats": 2551,
            "j_seats": 1276,
            "f_seats": 91,
            "r_seats": 182,
            "cargo_demand": 30910
        }
    },
    {
        "iata": "LNO",
        "icao": "YLEO",
        "airportName": "Leonora Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Leonora",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.87809944",
        "longitude": "121.3150024",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6621",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3740",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 130864,
            "s_seats": 46314,
            "j_seats": 12159,
            "f_seats": 17253,
            "r_seats": 4113,
            "cargo_demand": 257362
        }
    },
    {
        "iata": "LNP",
        "icao": "KLNP",
        "airportName": "Lonesome Pine Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Wise",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.98749924",
        "longitude": "-82.52999878",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5280",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1160,
            "s_seats": 2686,
            "j_seats": 1234,
            "f_seats": 477,
            "r_seats": 34,
            "cargo_demand": 98552
        }
    },
    {
        "iata": "LNR",
        "icao": "KLNR",
        "airportName": "Tri-County Regional Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Lone Rock",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.21170044",
        "longitude": "-90.18160248",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1988",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3119,
            "s_seats": 1608,
            "j_seats": 1856,
            "f_seats": 237,
            "r_seats": 61,
            "cargo_demand": 83660
        }
    },
    {
        "iata": "LNS",
        "icao": "KLNS",
        "airportName": "Lancaster Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Lancaster",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.12170029",
        "longitude": "-76.2960968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6934",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4102",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137835,
            "s_seats": 35147,
            "j_seats": 58620,
            "f_seats": 17944,
            "r_seats": 6202,
            "cargo_demand": 67586
        }
    },
    {
        "iata": "LNV",
        "icao": "AYKY",
        "airportName": "Lihir Island Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Lihir Island",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.043610096",
        "longitude": "152.6289978",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2051,
            "s_seats": 2434,
            "j_seats": 1809,
            "f_seats": 159,
            "r_seats": 167,
            "cargo_demand": 403
        }
    },
    {
        "iata": "LNY",
        "icao": "PHNY",
        "airportName": "Lanai Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Lanai City",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.78560066",
        "longitude": "-156.951004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180620,
            "s_seats": 29253,
            "j_seats": 14395,
            "f_seats": 7498,
            "r_seats": 3690,
            "cargo_demand": 420222
        }
    },
    {
        "iata": "LNZ",
        "icao": "LOWL",
        "airportName": "Linz Airport",
        "location_country": "Austria",
        "location_city": "Linz",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.2332",
        "longitude": "14.1875",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9843",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08L/26R",
                "runway_length": "2165",
                "runway_width": "148",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 84858,
            "s_seats": 41323,
            "j_seats": 42600,
            "f_seats": 19966,
            "r_seats": 9498,
            "cargo_demand": 416486
        }
    },
    {
        "iata": "LOA",
        "icao": "YLOR",
        "airportName": "Lorraine Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Lorraine",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.99329948",
        "longitude": "139.9069977",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4504",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1992,
            "s_seats": 2401,
            "j_seats": 1400,
            "f_seats": 166,
            "r_seats": 57,
            "cargo_demand": 35171
        }
    },
    {
        "iata": "LOB",
        "icao": "SCAN",
        "airportName": "San Rafael Airport",
        "location_country": "Chile",
        "location_city": "Los Andes",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "small_airport",
        "latitude": "-32.81420135",
        "longitude": "-70.646698",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "2467",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1639,
            "s_seats": 1984,
            "j_seats": 1904,
            "f_seats": 68,
            "r_seats": 156,
            "cargo_demand": 15275
        }
    },
    {
        "iata": "LOC",
        "icao": "YLOK",
        "airportName": "Lock Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Lock",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-33.5442009",
        "longitude": "135.6929932",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3116",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3781,
            "s_seats": 2228,
            "j_seats": 1058,
            "f_seats": 152,
            "r_seats": 93,
            "cargo_demand": 72721
        }
    },
    {
        "iata": "LOD",
        "icao": "NVSG",
        "airportName": "Longana Airport",
        "location_country": "Vanuatu",
        "location_city": "Penama Province",
        "location_state": "Aoba Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.30669975",
        "longitude": "167.9669952",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2887",
                "runway_width": "92",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1719,
            "s_seats": 1794,
            "j_seats": 1218,
            "f_seats": 176,
            "r_seats": 188,
            "cargo_demand": 56136
        }
    },
    {
        "iata": "LOE",
        "icao": "VTUL",
        "airportName": "Loei Airport",
        "location_country": "Thailand",
        "location_city": "Loei",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.43910027",
        "longitude": "101.7220001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116675,
            "s_seats": 46494,
            "j_seats": 6768,
            "f_seats": 16430,
            "r_seats": 6699,
            "cargo_demand": 262937
        }
    },
    {
        "iata": "LOH",
        "icao": "SETM",
        "airportName": "Ciudad de Catamayo Airport",
        "location_country": "Ecuador",
        "location_city": "Loja",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.99589",
        "longitude": "-79.371902",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6725",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2386,
            "s_seats": 1806,
            "j_seats": 1724,
            "f_seats": 252,
            "r_seats": 56,
            "cargo_demand": 29745
        }
    },
    {
        "iata": "LOI",
        "icao": "SSLN",
        "airportName": "Helmuth Baungartem Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "Lontras",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-27.15999985",
        "longitude": "-49.54249954",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "3609",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2168,
            "s_seats": 2605,
            "j_seats": 1056,
            "f_seats": 169,
            "r_seats": 155,
            "cargo_demand": 29929
        }
    },
    {
        "iata": "LOK",
        "icao": "HKLO",
        "airportName": "Lodwar Airport",
        "location_country": "Kenya",
        "location_city": "Lodwar",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.121969938",
        "longitude": "35.6086998",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "1645",
                "runway_width": "64",
                "runway_material": "GRE"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "3281",
                "runway_width": "64",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74637,
            "s_seats": 28698,
            "j_seats": 26697,
            "f_seats": 14521,
            "r_seats": 3022,
            "cargo_demand": 468855
        }
    },
    {
        "iata": "LOL",
        "icao": "KLOL",
        "airportName": "Derby Field",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Lovelock",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.06639862",
        "longitude": "-118.5650024",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5529",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "4922",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134042,
            "s_seats": 26229,
            "j_seats": 33496,
            "f_seats": 1103,
            "r_seats": 7379,
            "cargo_demand": 217838
        }
    },
    {
        "iata": "LOO",
        "icao": "DAUL",
        "airportName": "L'Mekrareg Airport",
        "location_country": "Algeria",
        "location_city": "Laghouat",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.7644",
        "longitude": "2.92834",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "12486",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "12477",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122899,
            "s_seats": 28717,
            "j_seats": 38074,
            "f_seats": 8003,
            "r_seats": 9714,
            "cargo_demand": 299301
        }
    },
    {
        "iata": "LOP",
        "icao": "WADL",
        "airportName": "Lombok International Airport",
        "location_country": "Indonesia",
        "location_city": "Praya",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.757322",
        "longitude": "116.276675",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9022",
                "runway_width": "197",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 52507,
            "s_seats": 34280,
            "j_seats": 57926,
            "f_seats": 18434,
            "r_seats": 3672,
            "cargo_demand": 349126
        }
    },
    {
        "iata": "LOS",
        "icao": "DNMM",
        "airportName": "Murtala Muhammed International Airport",
        "location_country": "Nigeria",
        "location_city": "Lagos",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "6.577370167",
        "longitude": "3.321160078",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "8997",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "12794",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 359852,
            "s_seats": 93374,
            "j_seats": 157810,
            "f_seats": 63562,
            "r_seats": 10000,
            "cargo_demand": 15541222
        }
    },
    {
        "iata": "LOT",
        "icao": "KLOT",
        "airportName": "Lewis University Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Romeoville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.6072998",
        "longitude": "-88.09619904",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5100",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5697",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2548,
            "s_seats": 1692,
            "j_seats": 1558,
            "f_seats": 307,
            "r_seats": 145,
            "cargo_demand": 36944
        }
    },
    {
        "iata": "LOU",
        "icao": "KLOU",
        "airportName": "Bowman Field",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Louisville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.22800064",
        "longitude": "-85.66369629",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4357",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3579",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 86967,
            "s_seats": 42760,
            "j_seats": 52505,
            "f_seats": 16703,
            "r_seats": 6903,
            "cargo_demand": 213942
        }
    },
    {
        "iata": "LOV",
        "icao": "MMMV",
        "airportName": "Venustiano Carranza International Airport",
        "location_country": "Mexico",
        "location_city": "Coahuila",
        "location_state": "Monclova",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "26.9557",
        "longitude": "-101.470001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "4783",
                "runway_width": "66",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177049,
            "s_seats": 49149,
            "j_seats": 27718,
            "f_seats": 9980,
            "r_seats": 8033,
            "cargo_demand": 243999
        }
    },
    {
        "iata": "LOW",
        "icao": "KLKU",
        "airportName": "Louisa County Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Louisa",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.00979996",
        "longitude": "-77.9701004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4301",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3696,
            "s_seats": 1768,
            "j_seats": 1022,
            "f_seats": 365,
            "r_seats": 174,
            "cargo_demand": 88449
        }
    },
    {
        "iata": "LOZ",
        "icao": "KLOZ",
        "airportName": "London-Corbin Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "London",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.0821991",
        "longitude": "-84.0848999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5650",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92483,
            "s_seats": 35710,
            "j_seats": 11281,
            "f_seats": 2916,
            "r_seats": 3370,
            "cargo_demand": 117144
        }
    },
    {
        "iata": "LPA",
        "icao": "GCLP",
        "airportName": "Gran Canaria Airport",
        "location_country": "Spain",
        "location_city": "Canary Islands",
        "location_state": "Gran Canaria",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "27.93190002",
        "longitude": "-15.38659954",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 441605,
            "s_seats": 72433,
            "j_seats": 67612,
            "f_seats": 28994,
            "r_seats": 10000,
            "cargo_demand": 4470605
        }
    },
    {
        "iata": "LPB",
        "icao": "SLLP",
        "airportName": "El Alto International Airport",
        "location_country": "Bolivia",
        "location_city": "La Paz",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.51329994",
        "longitude": "-68.19229889",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "13123",
                "runway_width": "151",
                "runway_material": "CON"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "6725",
                "runway_width": "300",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 89308,
            "s_seats": 32918,
            "j_seats": 47811,
            "f_seats": 9181,
            "r_seats": 3505,
            "cargo_demand": 487559
        }
    },
    {
        "iata": "LPC",
        "icao": "KLPC",
        "airportName": "Lompoc Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Lompoc",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.66559982",
        "longitude": "-120.4680023",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4600",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1048,
            "s_seats": 1686,
            "j_seats": 1929,
            "f_seats": 228,
            "r_seats": 46,
            "cargo_demand": 76787
        }
    },
    {
        "iata": "LPD",
        "icao": "SKLP",
        "airportName": "La Pedrera Airport",
        "location_country": "Colombia",
        "location_city": "La Pedrera",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.32861",
        "longitude": "-69.5797",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5643",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1288,
            "s_seats": 2959,
            "j_seats": 1207,
            "f_seats": 136,
            "r_seats": 184,
            "cargo_demand": 97537
        }
    },
    {
        "iata": "LPG",
        "icao": "SADL",
        "airportName": "La Plata Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "La Plata",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.9722",
        "longitude": "-57.8947",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3904",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "2/20",
                "runway_length": "4682",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159905,
            "s_seats": 48925,
            "j_seats": 25274,
            "f_seats": 19254,
            "r_seats": 6008,
            "cargo_demand": 326142
        }
    },
    {
        "iata": "LPI",
        "icao": "ESSL",
        "airportName": "Linkoping/Saab Airport",
        "location_country": "Sweden",
        "location_city": "Linkoping",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "58.40620041",
        "longitude": "15.68050003",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6989",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 107288,
            "s_seats": 25773,
            "j_seats": 40273,
            "f_seats": 11413,
            "r_seats": 5773,
            "cargo_demand": 350040
        }
    },
    {
        "iata": "LPJ",
        "icao": "SVAS",
        "airportName": "Armando Schwarck Airport",
        "location_country": "Venezuela",
        "location_city": "Los Pijiguaos",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "6.578050137",
        "longitude": "-66.81690216",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4592",
                "runway_width": "72",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2441,
            "s_seats": 2681,
            "j_seats": 1789,
            "f_seats": 379,
            "r_seats": 31,
            "cargo_demand": 18638
        }
    },
    {
        "iata": "LPL",
        "icao": "EGGP",
        "airportName": "Liverpool John Lennon Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Liverpool",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "53.33359909",
        "longitude": "-2.849720001",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7497",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 229168,
            "s_seats": 85439,
            "j_seats": 128254,
            "f_seats": 23347,
            "r_seats": 10000,
            "cargo_demand": 24196479
        }
    },
    {
        "iata": "LPO",
        "icao": "KPPO",
        "airportName": "La Porte Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "La Porte",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.57249832",
        "longitude": "-86.73449707",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2800",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3937,
            "s_seats": 1629,
            "j_seats": 1733,
            "f_seats": 380,
            "r_seats": 165,
            "cargo_demand": 85658
        }
    },
    {
        "iata": "LPP",
        "icao": "EFLP",
        "airportName": "Lappeenranta Airport",
        "location_country": "Finland",
        "location_city": "Lappeenranta",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "61.044601",
        "longitude": "28.144743",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8202",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104283,
            "s_seats": 40515,
            "j_seats": 6623,
            "f_seats": 5939,
            "r_seats": 7307,
            "cargo_demand": 330047
        }
    },
    {
        "iata": "LPQ",
        "icao": "VLLB",
        "airportName": "Luang Prabang International Airport",
        "location_country": "Laos",
        "location_city": "Luang Prabang",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.89730072",
        "longitude": "102.1610031",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56891,
            "s_seats": 34290,
            "j_seats": 39545,
            "f_seats": 11449,
            "r_seats": 3389,
            "cargo_demand": 106924
        }
    },
    {
        "iata": "LPT",
        "icao": "VTCL",
        "airportName": "Lampang Airport",
        "location_country": "Thailand",
        "location_city": "Lampang",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.27090073",
        "longitude": "99.5042038",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6465",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120971,
            "s_seats": 43154,
            "j_seats": 6150,
            "f_seats": 6473,
            "r_seats": 9337,
            "cargo_demand": 398379
        }
    },
    {
        "iata": "LPU",
        "icao": "WRLP",
        "airportName": "Long Apung Airport",
        "location_country": "Indonesia",
        "location_city": "Long Apung",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "1.704486",
        "longitude": "114.970297",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "2620",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3941,
            "s_seats": 2024,
            "j_seats": 1640,
            "f_seats": 188,
            "r_seats": 63,
            "cargo_demand": 53110
        }
    },
    {
        "iata": "LPX",
        "icao": "EVLA",
        "airportName": "Liep\u0101ja International Airport",
        "location_country": "Latvia",
        "location_city": "Liep\u0101ja",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "56.51750183",
        "longitude": "21.09690094",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6568",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135276,
            "s_seats": 26959,
            "j_seats": 51042,
            "f_seats": 1262,
            "r_seats": 6033,
            "cargo_demand": 94097
        }
    },
    {
        "iata": "LPY",
        "icao": "LFHP",
        "airportName": "Le Puy - Loudes Airport",
        "location_country": "France",
        "location_city": "Auvergne",
        "location_state": "Le Puy-en-Velay",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.08069992",
        "longitude": "3.7628901",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4570",
                "runway_width": "98",
                "runway_material": "Paved"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "3084",
                "runway_width": "262",
                "runway_material": "Unpaved"
            }
        ],
        "demand": {
            "y_seats": 105888,
            "s_seats": 45524,
            "j_seats": 20239,
            "f_seats": 8273,
            "r_seats": 5651,
            "cargo_demand": 123030
        }
    },
    {
        "iata": "LQK",
        "icao": "KLQK",
        "airportName": "Pickens County Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Pickens",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.81000137",
        "longitude": "-82.70290375",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5002",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2597,
            "s_seats": 2335,
            "j_seats": 1109,
            "f_seats": 304,
            "r_seats": 12,
            "cargo_demand": 41370
        }
    },
    {
        "iata": "LQM",
        "icao": "SKLG",
        "airportName": "Caucaya Airport",
        "location_country": "Colombia",
        "location_city": "Puerto Leguizamo",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.182278",
        "longitude": "-74.7708",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3937",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1803,
            "s_seats": 2494,
            "j_seats": 1634,
            "f_seats": 312,
            "r_seats": 175,
            "cargo_demand": 84157
        }
    },
    {
        "iata": "LRA",
        "icao": "LGLR",
        "airportName": "Larissa National Airport",
        "location_country": "Greece",
        "location_city": "Larissa",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "39.650253",
        "longitude": "22.4655",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08C/26C",
                "runway_length": "9711",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "9711",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2470,
            "s_seats": 1884,
            "j_seats": 1711,
            "f_seats": 282,
            "r_seats": 36,
            "cargo_demand": 75422
        }
    },
    {
        "iata": "LRD",
        "icao": "KLRD",
        "airportName": "Laredo International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Laredo",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "27.54380035",
        "longitude": "-99.46160126",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5928",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "8236",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "7830",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122731,
            "s_seats": 42657,
            "j_seats": 8773,
            "f_seats": 18646,
            "r_seats": 8289,
            "cargo_demand": 77590
        }
    },
    {
        "iata": "LRE",
        "icao": "YLRE",
        "airportName": "Longreach Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Longreach",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.43420029",
        "longitude": "144.2799988",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6352",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2953",
                "runway_width": "59",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 178494,
            "s_seats": 48529,
            "j_seats": 22583,
            "f_seats": 7143,
            "r_seats": 9218,
            "cargo_demand": 98578
        }
    },
    {
        "iata": "LRF",
        "icao": "KLRF",
        "airportName": "Little Rock Air Force Base",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Jacksonville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.91690063",
        "longitude": "-92.14969635",
        "region": "NA",
        "runway": [
            {
                "runway_name": "069/249",
                "runway_length": "3482",
                "runway_width": "60",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "07/25",
                "runway_length": "12000",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 99613,
            "s_seats": 26113,
            "j_seats": 55144,
            "f_seats": 15148,
            "r_seats": 4681,
            "cargo_demand": 113300
        }
    },
    {
        "iata": "LRH",
        "icao": "LFBH",
        "airportName": "La Rochelle - Ile de Re Airport",
        "location_country": "France",
        "location_city": "Poitou-Charentes",
        "location_state": "La Rochelle",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.17919922",
        "longitude": "-1.195279956",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7398",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80960,
            "s_seats": 45504,
            "j_seats": 51690,
            "f_seats": 2657,
            "r_seats": 9055,
            "cargo_demand": 266056
        }
    },
    {
        "iata": "LRJ",
        "icao": "KLRJ",
        "airportName": "Le Mars Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Le Mars",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.77799988",
        "longitude": "-96.1937027",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1262,
            "s_seats": 1592,
            "j_seats": 1315,
            "f_seats": 299,
            "r_seats": 6,
            "cargo_demand": 23298
        }
    },
    {
        "iata": "LRL",
        "icao": "DXNG",
        "airportName": "Niamtougou International Airport",
        "location_country": "Togo",
        "location_city": "Niamtougou",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.76733017",
        "longitude": "1.091249943",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 127524,
            "s_seats": 43572,
            "j_seats": 55248,
            "f_seats": 17422,
            "r_seats": 7454,
            "cargo_demand": 61620
        }
    },
    {
        "iata": "LRM",
        "icao": "MDLR",
        "airportName": "La Romana International Airport",
        "location_country": "Dominican Republic",
        "location_city": "La Romana",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.45070076",
        "longitude": "-68.91179657",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9676",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77861,
            "s_seats": 48464,
            "j_seats": 45552,
            "f_seats": 3061,
            "r_seats": 3623,
            "cargo_demand": 460733
        }
    },
    {
        "iata": "LRR",
        "icao": "OISL",
        "airportName": "Larestan International Airport",
        "location_country": "Iran",
        "location_city": "Lar",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "27.6747",
        "longitude": "54.383301",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10334",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159912,
            "s_seats": 36846,
            "j_seats": 44876,
            "f_seats": 12852,
            "r_seats": 9591,
            "cargo_demand": 55853
        }
    },
    {
        "iata": "LRS",
        "icao": "LGLE",
        "airportName": "Leros Municipal Airport",
        "location_country": "Greece",
        "location_city": "Leros",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "37.184898",
        "longitude": "26.800301",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3320",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3500,
            "s_seats": 1529,
            "j_seats": 1243,
            "f_seats": 237,
            "r_seats": 117,
            "cargo_demand": 49080
        }
    },
    {
        "iata": "LRT",
        "icao": "LFRH",
        "airportName": "Lorient South Brittany Airport",
        "location_country": "France",
        "location_city": "Brittany",
        "location_state": "Lorient",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "47.76060104",
        "longitude": "-3.440000057",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5479",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "07/25",
                "runway_length": "7884",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 135845,
            "s_seats": 43279,
            "j_seats": 41325,
            "f_seats": 4557,
            "r_seats": 6890,
            "cargo_demand": 87323
        }
    },
    {
        "iata": "LRU",
        "icao": "KLRU",
        "airportName": "Las Cruces International Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Las Cruces",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.28939819",
        "longitude": "-106.9219971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7499",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6069",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7499",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 195238,
            "s_seats": 28901,
            "j_seats": 17974,
            "f_seats": 11481,
            "r_seats": 5766,
            "cargo_demand": 144386
        }
    },
    {
        "iata": "LRV",
        "icao": "SVRS",
        "airportName": "Los Roques Airport",
        "location_country": "Venezuela",
        "location_city": "Los Roques",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "11.946177",
        "longitude": "-66.669836",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2756",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1711,
            "s_seats": 1670,
            "j_seats": 1427,
            "f_seats": 160,
            "r_seats": 40,
            "cargo_demand": 94990
        }
    },
    {
        "iata": "LSA",
        "icao": "AYKA",
        "airportName": "Losuia Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Kiriwina Island",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.505820274",
        "longitude": "151.0809937",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5348",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2945,
            "s_seats": 2594,
            "j_seats": 1237,
            "f_seats": 377,
            "r_seats": 37,
            "cargo_demand": 24532
        }
    },
    {
        "iata": "LSB",
        "icao": "KLSB",
        "airportName": "Lordsburg Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Lordsburg",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.33349991",
        "longitude": "-108.6920013",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3213",
                "runway_width": "50",
                "runway_material": "GRE"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5011",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1758,
            "s_seats": 1721,
            "j_seats": 1928,
            "f_seats": 475,
            "r_seats": 49,
            "cargo_demand": 91033
        }
    },
    {
        "iata": "LSC",
        "icao": "SCSE",
        "airportName": "La Florida Airport",
        "location_country": "Chile",
        "location_city": "La Serena",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "medium_airport",
        "latitude": "-29.916201",
        "longitude": "-71.199501",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6358",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129122,
            "s_seats": 42608,
            "j_seats": 56321,
            "f_seats": 3040,
            "r_seats": 3705,
            "cargo_demand": 430867
        }
    },
    {
        "iata": "LSE",
        "icao": "KLSE",
        "airportName": "La Crosse Regional Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "La Crosse",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.879002",
        "longitude": "-91.256699",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5299",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6050",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8537",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 101640,
            "s_seats": 38957,
            "j_seats": 54706,
            "f_seats": 9045,
            "r_seats": 6378,
            "cargo_demand": 463118
        }
    },
    {
        "iata": "LSF",
        "icao": "KLSF",
        "airportName": "Lawson Army Airfield",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Fort Benning / Columbus",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.33729935",
        "longitude": "-84.99130249",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58312,
            "s_seats": 36639,
            "j_seats": 21634,
            "f_seats": 19547,
            "r_seats": 9611,
            "cargo_demand": 106376
        }
    },
    {
        "iata": "LSH",
        "icao": "VYLS",
        "airportName": "Lashio Airport",
        "location_country": "Myanmar",
        "location_city": "Lashio",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.97789955",
        "longitude": "97.75219727",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5285",
                "runway_width": "95",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 162925,
            "s_seats": 31773,
            "j_seats": 49752,
            "f_seats": 7828,
            "r_seats": 5059,
            "cargo_demand": 430065
        }
    },
    {
        "iata": "LSI",
        "icao": "EGPB",
        "airportName": "Sumburgh Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Shetland Islands",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "59.87889862",
        "longitude": "-1.295560002",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06H/24H",
                "runway_length": "1804",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4921",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4678",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182563,
            "s_seats": 27038,
            "j_seats": 37557,
            "f_seats": 5741,
            "r_seats": 9906,
            "cargo_demand": 431350
        }
    },
    {
        "iata": "LSK",
        "icao": "KLSK",
        "airportName": "Lusk Municipal Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Lusk",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.75379944",
        "longitude": "-104.4049988",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5058",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3321,
            "s_seats": 1947,
            "j_seats": 1878,
            "f_seats": 376,
            "r_seats": 116,
            "cargo_demand": 3990
        }
    },
    {
        "iata": "LSL",
        "icao": "MRLC",
        "airportName": "Los Chiles Airport",
        "location_country": "Costa Rica",
        "location_city": "Los Chiles",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.03530025",
        "longitude": "-84.70610046",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4265",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157889,
            "s_seats": 49734,
            "j_seats": 18049,
            "f_seats": 5075,
            "r_seats": 7867,
            "cargo_demand": 424908
        }
    },
    {
        "iata": "LSN",
        "icao": "KLSN",
        "airportName": "Los Banos Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Los Banos",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.06290054",
        "longitude": "-120.8690033",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3005",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1755,
            "s_seats": 2081,
            "j_seats": 1726,
            "f_seats": 4,
            "r_seats": 191,
            "cargo_demand": 11754
        }
    },
    {
        "iata": "LSP",
        "icao": "SVJC",
        "airportName": "Josefa Camejo International Airport",
        "location_country": "Venezuela",
        "location_city": "Punto Fijo",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.78077507",
        "longitude": "-70.15149689",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87487,
            "s_seats": 42988,
            "j_seats": 45055,
            "f_seats": 17619,
            "r_seats": 6152,
            "cargo_demand": 104428
        }
    },
    {
        "iata": "LSQ",
        "icao": "SCGE",
        "airportName": "Maria Dolores Airport",
        "location_country": "Chile",
        "location_city": "Los angeles",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "small_airport",
        "latitude": "-37.40169907",
        "longitude": "-72.42539978",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5577",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3768,
            "s_seats": 2526,
            "j_seats": 1484,
            "f_seats": 309,
            "r_seats": 180,
            "cargo_demand": 65206
        }
    },
    {
        "iata": "LST",
        "icao": "YMLT",
        "airportName": "Launceston Airport",
        "location_country": "Australia",
        "location_city": "Tasmania",
        "location_state": "Launceston",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-41.54529953",
        "longitude": "147.2140045",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "2297",
                "runway_width": "60",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "6499",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2264",
                "runway_width": "60",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 132791,
            "s_seats": 27745,
            "j_seats": 40371,
            "f_seats": 9972,
            "r_seats": 7209,
            "cargo_demand": 414065
        }
    },
    {
        "iata": "LSV",
        "icao": "KLSV",
        "airportName": "Nellis Air Force Base",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Las Vegas",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.23619843",
        "longitude": "-115.0339966",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "10123",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "10055",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 106241,
            "s_seats": 28109,
            "j_seats": 49735,
            "f_seats": 11784,
            "r_seats": 9057,
            "cargo_demand": 125679
        }
    },
    {
        "iata": "LSW",
        "icao": "WITM",
        "airportName": "Malikus Saleh Airport",
        "location_country": "Indonesia",
        "location_city": "Lhokseumawe",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "5.226679802",
        "longitude": "96.95030212",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6070",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3710,
            "s_seats": 2572,
            "j_seats": 1806,
            "f_seats": 105,
            "r_seats": 76,
            "cargo_demand": 86138
        }
    },
    {
        "iata": "LSX",
        "icao": "WITL",
        "airportName": "Lhok Sukon Airport",
        "location_country": "Indonesia",
        "location_city": "Lhoksukon",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.069509983",
        "longitude": "97.25920105",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3675",
                "runway_width": "69",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73420,
            "s_seats": 47834,
            "j_seats": 45839,
            "f_seats": 15545,
            "r_seats": 4063,
            "cargo_demand": 466948
        }
    },
    {
        "iata": "LSY",
        "icao": "YLIS",
        "airportName": "Lismore Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Lismore",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-28.83029938",
        "longitude": "153.2599945",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5404",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116714,
            "s_seats": 27064,
            "j_seats": 34938,
            "f_seats": 16938,
            "r_seats": 5584,
            "cargo_demand": 390203
        }
    },
    {
        "iata": "LSZ",
        "icao": "LDLO",
        "airportName": "Loo\u00a1inj Airport",
        "location_country": "Croatia",
        "location_city": "Loo\u00a1inj",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "44.56579971",
        "longitude": "14.39309978",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2953",
                "runway_width": "97",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 2529,
            "s_seats": 2657,
            "j_seats": 1761,
            "f_seats": 471,
            "r_seats": 30,
            "cargo_demand": 46585
        }
    },
    {
        "iata": "LTA",
        "icao": "FATZ",
        "airportName": "Tzaneen Airport",
        "location_country": "South Africa",
        "location_city": "Tzaneen",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.82439995",
        "longitude": "30.32929993",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4659",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153397,
            "s_seats": 43301,
            "j_seats": 36212,
            "f_seats": 16244,
            "r_seats": 5320,
            "cargo_demand": 243118
        }
    },
    {
        "iata": "LTD",
        "icao": "HLTD",
        "airportName": "Ghadames Airport",
        "location_country": "Libya",
        "location_city": "Ghadames",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.15169907",
        "longitude": "9.715310097",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6516",
                "runway_width": "131",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144523,
            "s_seats": 33270,
            "j_seats": 14577,
            "f_seats": 1813,
            "r_seats": 3399,
            "cargo_demand": 186254
        }
    },
    {
        "iata": "LTI",
        "icao": "ZMAT",
        "airportName": "Altai Airport",
        "location_country": "Mongolia",
        "location_city": "Altai",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "46.37639999",
        "longitude": "96.22109985",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7513",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 182280,
            "s_seats": 41124,
            "j_seats": 24823,
            "f_seats": 12265,
            "r_seats": 7542,
            "cargo_demand": 436960
        }
    },
    {
        "iata": "LTK",
        "icao": "OSLK",
        "airportName": "Bassel Al-Assad International Airport",
        "location_country": "Syria",
        "location_city": "Latakia",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "35.40110016",
        "longitude": "35.94869995",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9175",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 324196,
            "s_seats": 86410,
            "j_seats": 73352,
            "f_seats": 49809,
            "r_seats": 10000,
            "cargo_demand": 10493581
        }
    },
    {
        "iata": "LTM",
        "icao": "SYLT",
        "airportName": "Lethem Airport",
        "location_country": "Guyana",
        "location_city": "Lethem",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.37276",
        "longitude": "-59.789398",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5985",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160337,
            "s_seats": 49729,
            "j_seats": 55573,
            "f_seats": 12356,
            "r_seats": 6287,
            "cargo_demand": 198003
        }
    },
    {
        "iata": "LTN",
        "icao": "EGGW",
        "airportName": "Luton Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "London",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "51.87469864",
        "longitude": "-0.368333012",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7093",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106970,
            "s_seats": 70379,
            "j_seats": 77850,
            "f_seats": 61904,
            "r_seats": 10000,
            "cargo_demand": 22730492
        }
    },
    {
        "iata": "LTO",
        "icao": "MMLT",
        "airportName": "Loreto International Airport",
        "location_country": "Mexico",
        "location_city": "Baja California Sur",
        "location_state": "Loreto",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "25.98920059",
        "longitude": "-111.3479996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113052,
            "s_seats": 27304,
            "j_seats": 14986,
            "f_seats": 11920,
            "r_seats": 4286,
            "cargo_demand": 85231
        }
    },
    {
        "iata": "LTP",
        "icao": "YLHS",
        "airportName": "Lyndhurst Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Lyndhurst",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-19.19580078",
        "longitude": "144.3710022",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4232",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3327,
            "s_seats": 2327,
            "j_seats": 1795,
            "f_seats": 162,
            "r_seats": 135,
            "cargo_demand": 77194
        }
    },
    {
        "iata": "LTQ",
        "icao": "LFAT",
        "airportName": "Le Touquet - Cote d'Opale Airport",
        "location_country": "France",
        "location_city": "Nord-Pas-de-Calais",
        "location_state": "Le Touquet-Paris-Plage",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.51739883",
        "longitude": "1.620589972",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6070",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62732,
            "s_seats": 27588,
            "j_seats": 10490,
            "f_seats": 7298,
            "r_seats": 4300,
            "cargo_demand": 178662
        }
    },
    {
        "iata": "LTR",
        "icao": "EILT",
        "airportName": "Letterkenny Airfield",
        "location_country": "Ireland",
        "location_city": "Letterkenny",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "54.951302",
        "longitude": "-7.67283",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "1969",
                "runway_width": "0",
                "runway_material": "GRASS/HARDCORE"
            }
        ],
        "demand": {
            "y_seats": 1646,
            "s_seats": 1680,
            "j_seats": 1923,
            "f_seats": 448,
            "r_seats": 151,
            "cargo_demand": 27603
        }
    },
    {
        "iata": "LTS",
        "icao": "KLTS",
        "airportName": "Altus Air Force Base",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Altus",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.667099",
        "longitude": "-99.266701",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3501",
                "runway_width": "94",
                "runway_material": "ASP"
            },
            {
                "runway_name": "174/354",
                "runway_length": "3501",
                "runway_width": "94",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "13440",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 87669,
            "s_seats": 30983,
            "j_seats": 54298,
            "f_seats": 12845,
            "r_seats": 5917,
            "cargo_demand": 496700
        }
    },
    {
        "iata": "LTT",
        "icao": "LFTZ",
        "airportName": "La Mole - Saint-Tropez Airport",
        "location_country": "France",
        "location_city": "Provence-Alpes-Cote d'Azur",
        "location_state": "Saint-Tropez",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "43.205399",
        "longitude": "6.482",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3514",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3509,
            "s_seats": 2925,
            "j_seats": 1373,
            "f_seats": 444,
            "r_seats": 191,
            "cargo_demand": 2489
        }
    },
    {
        "iata": "LTV",
        "icao": "YLOV",
        "airportName": "Lotus Vale Station Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Lotus Vale Station",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.04829979",
        "longitude": "141.3760071",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2854",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 2305,
            "s_seats": 2193,
            "j_seats": 1828,
            "f_seats": 314,
            "r_seats": 165,
            "cargo_demand": 86808
        }
    },
    {
        "iata": "LUA",
        "icao": "VNLK",
        "airportName": "Tenzing-Hillary Airport",
        "location_country": "Nepal",
        "location_city": "Lukla",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.68689919",
        "longitude": "86.72969818",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "1616",
                "runway_width": "98",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 1049,
            "s_seats": 1530,
            "j_seats": 1007,
            "f_seats": 389,
            "r_seats": 106,
            "cargo_demand": 99271
        }
    },
    {
        "iata": "LUD",
        "icao": "FYLZ",
        "airportName": "Luderitz Airport",
        "location_country": "Namibia",
        "location_city": "Luderitz",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-26.68740082",
        "longitude": "15.24289989",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6004",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3830",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 187762,
            "s_seats": 26977,
            "j_seats": 6832,
            "f_seats": 9178,
            "r_seats": 7439,
            "cargo_demand": 278587
        }
    },
    {
        "iata": "LUF",
        "icao": "KLUF",
        "airportName": "Luke Air Force Base",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Glendale",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "33.53499985",
        "longitude": "-112.3830032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "10012",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "9904",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 210781,
            "s_seats": 97385,
            "j_seats": 132417,
            "f_seats": 61814,
            "r_seats": 10000,
            "cargo_demand": 24642835
        }
    },
    {
        "iata": "LUG",
        "icao": "LSZA",
        "airportName": "Lugano Airport",
        "location_country": "Switzerland",
        "location_city": "Lugano",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.00429916",
        "longitude": "8.910579681",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4429",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181514,
            "s_seats": 33397,
            "j_seats": 57926,
            "f_seats": 8596,
            "r_seats": 7012,
            "cargo_demand": 368824
        }
    },
    {
        "iata": "LUH",
        "icao": "VILD",
        "airportName": "Sahnewal Airport",
        "location_country": "India",
        "location_city": "Punjab",
        "location_state": "Ludhiana",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.85470009",
        "longitude": "75.95259857",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4800",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69106,
            "s_seats": 43949,
            "j_seats": 39736,
            "f_seats": 12832,
            "r_seats": 5996,
            "cargo_demand": 426481
        }
    },
    {
        "iata": "LUK",
        "icao": "KLUK",
        "airportName": "Cincinnati Municipal Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Cincinnati",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.10329819",
        "longitude": "-84.41860199",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "3802",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "6101",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "5128",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 85472,
            "s_seats": 28964,
            "j_seats": 21071,
            "f_seats": 1791,
            "r_seats": 8335,
            "cargo_demand": 187665
        }
    },
    {
        "iata": "LUL",
        "icao": "KLUL",
        "airportName": "Hesler-Noble Field",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Laurel",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.67259979",
        "longitude": "-89.17220306",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5512",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3947,
            "s_seats": 2394,
            "j_seats": 1014,
            "f_seats": 77,
            "r_seats": 137,
            "cargo_demand": 4822
        }
    },
    {
        "iata": "LUN",
        "icao": "FLLS",
        "airportName": "Kenneth Kaunda International Airport",
        "location_country": "Zambia",
        "location_city": "Lusaka",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-15.3308",
        "longitude": "28.4526",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "12998",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2700",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 163499,
            "s_seats": 74839,
            "j_seats": 138199,
            "f_seats": 41704,
            "r_seats": 10000,
            "cargo_demand": 1426211
        }
    },
    {
        "iata": "LUO",
        "icao": "FNUE",
        "airportName": "Luena Airport",
        "location_country": "Angola",
        "location_city": "Luena",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.76809978",
        "longitude": "19.89769936",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7875",
                "runway_width": "130",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54809,
            "s_seats": 41347,
            "j_seats": 8588,
            "f_seats": 18589,
            "r_seats": 4948,
            "cargo_demand": 401107
        }
    },
    {
        "iata": "LUP",
        "icao": "PHLU",
        "airportName": "Kalaupapa Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Kalaupapa",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.21100044",
        "longitude": "-156.973999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2700",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 1131,
            "s_seats": 2309,
            "j_seats": 1033,
            "f_seats": 379,
            "r_seats": 8,
            "cargo_demand": 64893
        }
    },
    {
        "iata": "LUQ",
        "icao": "SAOU",
        "airportName": "Brigadier Mayor Cesar Raul Ojeda Airport",
        "location_country": "Argentina",
        "location_city": "San Luis",
        "location_state": "San Luis",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.27320099",
        "longitude": "-66.35639954",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9678",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180167,
            "s_seats": 47138,
            "j_seats": 39807,
            "f_seats": 13491,
            "r_seats": 5080,
            "cargo_demand": 298283
        }
    },
    {
        "iata": "LUR",
        "icao": "PALU",
        "airportName": "Cape Lisburne LRRS Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Cape Lisburne",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "68.87509918",
        "longitude": "-166.1100006",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4800",
                "runway_width": "135",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 101157,
            "s_seats": 45905,
            "j_seats": 30258,
            "f_seats": 1141,
            "r_seats": 4351,
            "cargo_demand": 485640
        }
    },
    {
        "iata": "LUT",
        "icao": "YLRS",
        "airportName": "New Laura Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "New Laura",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.18330002",
        "longitude": "144.3670044",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 3729,
            "s_seats": 1579,
            "j_seats": 1104,
            "f_seats": 258,
            "r_seats": 44,
            "cargo_demand": 91124
        }
    },
    {
        "iata": "LUU",
        "icao": "YLRA",
        "airportName": "Laura Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Laura",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.55000019",
        "longitude": "144.4499969",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3526",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3929,
            "s_seats": 2667,
            "j_seats": 1108,
            "f_seats": 208,
            "r_seats": 193,
            "cargo_demand": 36367
        }
    },
    {
        "iata": "LUV",
        "icao": "WAPL",
        "airportName": "Karel Sadsuitubun Airport",
        "location_country": "Indonesia",
        "location_city": "Langgur",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.66162",
        "longitude": "132.731003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 66174,
            "s_seats": 31803,
            "j_seats": 21767,
            "f_seats": 8062,
            "r_seats": 8678,
            "cargo_demand": 260269
        }
    },
    {
        "iata": "LUW",
        "icao": "WAMW",
        "airportName": "Syukuran Aminuddin Amir Airport",
        "location_country": "Indonesia",
        "location_city": "Luwuk",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.03892",
        "longitude": "122.772003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6070",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182643,
            "s_seats": 34273,
            "j_seats": 17983,
            "f_seats": 14037,
            "r_seats": 5723,
            "cargo_demand": 148245
        }
    },
    {
        "iata": "LUX",
        "icao": "ELLX",
        "airportName": "Luxembourg Findel Airport",
        "location_country": "Luxembourg",
        "location_city": "Luxembourg",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "49.6233333",
        "longitude": "6.2044444",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 190281,
            "s_seats": 77826,
            "j_seats": 112298,
            "f_seats": 36723,
            "r_seats": 10000,
            "cargo_demand": 15206670
        }
    },
    {
        "iata": "LVA",
        "icao": "LFOV",
        "airportName": "Laval Entrammes Airport",
        "location_country": "France",
        "location_city": "Pays de la Loire",
        "location_state": "Laval",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.03139877",
        "longitude": "-0.742986023",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5020",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144629,
            "s_seats": 42344,
            "j_seats": 41722,
            "f_seats": 11383,
            "r_seats": 8068,
            "cargo_demand": 251988
        }
    },
    {
        "iata": "LVB",
        "icao": "SSLI",
        "airportName": "Galpi\u00b5es Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Santana do Livramento",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-22.165278",
        "longitude": "-47.892778",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2625",
                "runway_width": "66",
                "runway_material": "TER"
            }
        ],
        "demand": {
            "y_seats": 3218,
            "s_seats": 2086,
            "j_seats": 1713,
            "f_seats": 9,
            "r_seats": 40,
            "cargo_demand": 4143
        }
    },
    {
        "iata": "LVI",
        "icao": "FLLI",
        "airportName": "Harry Mwanga Nkumbula International Airport",
        "location_country": "Zambia",
        "location_city": "Livingstone",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.8218",
        "longitude": "25.822701",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7520",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4501",
                "runway_width": "98",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 175463,
            "s_seats": 35219,
            "j_seats": 45932,
            "f_seats": 7762,
            "r_seats": 5458,
            "cargo_demand": 255568
        }
    },
    {
        "iata": "LVK",
        "icao": "KLVK",
        "airportName": "Livermore Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Livermore",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.69340134",
        "longitude": "-121.8199997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "5253",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "2699",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3377,
            "s_seats": 2233,
            "j_seats": 1703,
            "f_seats": 394,
            "r_seats": 189,
            "cargo_demand": 14387
        }
    },
    {
        "iata": "LVL",
        "icao": "KLVL",
        "airportName": "Lawrenceville/Brunswick Municipal Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Lawrenceville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.7728",
        "longitude": "-77.794296",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "1890",
                "runway_width": "70",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3020",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2501,
            "s_seats": 1506,
            "j_seats": 1715,
            "f_seats": 121,
            "r_seats": 168,
            "cargo_demand": 66834
        }
    },
    {
        "iata": "LVM",
        "icao": "KLVM",
        "airportName": "Mission Field",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Livingston",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "45.69940186",
        "longitude": "-110.447998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5701",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3700",
                "runway_width": "125",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2715",
                "runway_width": "120",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 161256,
            "s_seats": 44844,
            "j_seats": 46582,
            "f_seats": 14139,
            "r_seats": 8446,
            "cargo_demand": 443480
        }
    },
    {
        "iata": "LVO",
        "icao": "YLTN",
        "airportName": "Laverton Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Laverton",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.61359978",
        "longitude": "122.4240036",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3015",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1152,
            "s_seats": 1970,
            "j_seats": 1901,
            "f_seats": 256,
            "r_seats": 171,
            "cargo_demand": 64661
        }
    },
    {
        "iata": "LVP",
        "icao": "OIBV",
        "airportName": "Lavan Airport",
        "location_country": "Iran",
        "location_city": "Lavan Island",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "26.81030083",
        "longitude": "53.35630035",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6815",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156428,
            "s_seats": 26417,
            "j_seats": 44283,
            "f_seats": 11068,
            "r_seats": 9608,
            "cargo_demand": 88400
        }
    },
    {
        "iata": "LVR",
        "icao": "SILC",
        "airportName": "Bom Futuro Municipal Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Lucas do Rio Verde",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-13.037861",
        "longitude": "-55.95025",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5676",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2680,
            "s_seats": 2842,
            "j_seats": 1964,
            "f_seats": 499,
            "r_seats": 111,
            "cargo_demand": 99763
        }
    },
    {
        "iata": "LVS",
        "icao": "KLVS",
        "airportName": "Las Vegas Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Las Vegas",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.65420151",
        "longitude": "-105.1419983",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5004",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8198",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197276,
            "s_seats": 48100,
            "j_seats": 59175,
            "f_seats": 9450,
            "r_seats": 4458,
            "cargo_demand": 234125
        }
    },
    {
        "iata": "LWB",
        "icao": "KLWB",
        "airportName": "Greenbrier Valley Airport",
        "location_country": "United States",
        "location_city": "West Virginia",
        "location_state": "Lewisburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.85829926",
        "longitude": "-80.39949799",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7004",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140341,
            "s_seats": 35599,
            "j_seats": 58702,
            "f_seats": 19548,
            "r_seats": 4743,
            "cargo_demand": 84831
        }
    },
    {
        "iata": "LWC",
        "icao": "KLWC",
        "airportName": "Lawrence Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Lawrence",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.01119995",
        "longitude": "-95.21659851",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3901",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5700",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1240,
            "s_seats": 1573,
            "j_seats": 1813,
            "f_seats": 9,
            "r_seats": 62,
            "cargo_demand": 49535
        }
    },
    {
        "iata": "LWH",
        "icao": "YLAH",
        "airportName": "Lawn Hill Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Lawn Hill",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.56830025",
        "longitude": "138.6349945",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4002",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2169,
            "s_seats": 2750,
            "j_seats": 1751,
            "f_seats": 441,
            "r_seats": 176,
            "cargo_demand": 69991
        }
    },
    {
        "iata": "LWK",
        "icao": "EGET",
        "airportName": "Tingwall Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Lerwick",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "60.19219971",
        "longitude": "-1.243610024",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5787",
                "runway_width": "59",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 2209,
            "s_seats": 1876,
            "j_seats": 1006,
            "f_seats": 215,
            "r_seats": 27,
            "cargo_demand": 48139
        }
    },
    {
        "iata": "LWL",
        "icao": "KLWL",
        "airportName": "Wells Municipal Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Wells",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.11709976",
        "longitude": "-114.9219971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2681",
                "runway_width": "150",
                "runway_material": "GVL"
            },
            {
                "runway_name": "08/26",
                "runway_length": "5498",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1580,
            "s_seats": 2313,
            "j_seats": 1795,
            "f_seats": 390,
            "r_seats": 43,
            "cargo_demand": 29286
        }
    },
    {
        "iata": "LWM",
        "icao": "KLWM",
        "airportName": "Lawrence Municipal Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Lawrence",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.71720123",
        "longitude": "-71.12339783",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3901",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155791,
            "s_seats": 47596,
            "j_seats": 43621,
            "f_seats": 1874,
            "r_seats": 9907,
            "cargo_demand": 159357
        }
    },
    {
        "iata": "LWN",
        "icao": "UDSG",
        "airportName": "Shirak Airport",
        "location_country": "Armenia",
        "location_city": "Gyumri",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.750401",
        "longitude": "43.859299",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "10564",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156046,
            "s_seats": 43686,
            "j_seats": 24262,
            "f_seats": 2327,
            "r_seats": 9101,
            "cargo_demand": 107323
        }
    },
    {
        "iata": "LWO",
        "icao": "UKLL",
        "airportName": "Lviv Danylo Halytskyi International Airport",
        "location_country": "Ukraine",
        "location_city": "Lviv",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "49.8125",
        "longitude": "23.9561",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 403681,
            "s_seats": 83645,
            "j_seats": 148395,
            "f_seats": 30674,
            "r_seats": 10000,
            "cargo_demand": 27196833
        }
    },
    {
        "iata": "LWR",
        "icao": "EHLW",
        "airportName": "Leeuwarden Air Base",
        "location_country": "Netherlands",
        "location_city": "Leeuwarden",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "53.22859955",
        "longitude": "5.760560036",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8045",
                "runway_width": "164",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "6559",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191508,
            "s_seats": 40916,
            "j_seats": 36979,
            "f_seats": 3811,
            "r_seats": 4411,
            "cargo_demand": 201807
        }
    },
    {
        "iata": "LWS",
        "icao": "KLWS",
        "airportName": "Lewiston-Nez Perce County Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Lewiston",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.37450027",
        "longitude": "-117.0149994",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6511",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5002",
                "runway_width": "100",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 51671,
            "s_seats": 30498,
            "j_seats": 34810,
            "f_seats": 19366,
            "r_seats": 9423,
            "cargo_demand": 350436
        }
    },
    {
        "iata": "LWT",
        "icao": "KLWT",
        "airportName": "Lewistown Municipal Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Lewistown",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.04930115",
        "longitude": "-109.4670029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5600",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6100",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4102",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116052,
            "s_seats": 44128,
            "j_seats": 32051,
            "f_seats": 19333,
            "r_seats": 8208,
            "cargo_demand": 285415
        }
    },
    {
        "iata": "LWV",
        "icao": "KLWV",
        "airportName": "Lawrenceville-Vincennes International Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Lawrenceville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.7643013",
        "longitude": "-87.60549927",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04U/22U",
                "runway_length": "1000",
                "runway_width": "200",
                "runway_material": "TURF-F"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5198",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5199",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1013,
            "s_seats": 2564,
            "j_seats": 1220,
            "f_seats": 338,
            "r_seats": 3,
            "cargo_demand": 17063
        }
    },
    {
        "iata": "LWY",
        "icao": "WBGW",
        "airportName": "Lawas Airport",
        "location_country": "Malaysia",
        "location_city": "Sarawak",
        "location_state": "Lawas",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "4.84917",
        "longitude": "115.407997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2251",
                "runway_width": "59",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2195,
            "s_seats": 2040,
            "j_seats": 1629,
            "f_seats": 403,
            "r_seats": 84,
            "cargo_demand": 47818
        }
    },
    {
        "iata": "LXA",
        "icao": "ZULS",
        "airportName": "Lhasa Gonggar Airport",
        "location_country": "China",
        "location_city": "Tibet",
        "location_state": "Lhasa",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.29780006",
        "longitude": "90.91190338",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "Asphalt"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "11811",
                "runway_width": "",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 74659,
            "s_seats": 31851,
            "j_seats": 53471,
            "f_seats": 18409,
            "r_seats": 6493,
            "cargo_demand": 258729
        }
    },
    {
        "iata": "LXN",
        "icao": "KLXN",
        "airportName": "Jim Kelly Field",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Lexington",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.79100037",
        "longitude": "-99.77729797",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3200",
                "runway_width": "250",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5489",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2570,
            "s_seats": 1989,
            "j_seats": 1688,
            "f_seats": 362,
            "r_seats": 82,
            "cargo_demand": 95338
        }
    },
    {
        "iata": "LXR",
        "icao": "HELX",
        "airportName": "Luxor International Airport",
        "location_country": "Egypt",
        "location_city": "Luxor",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.674629",
        "longitude": "32.700012",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89913,
            "s_seats": 31467,
            "j_seats": 59062,
            "f_seats": 9241,
            "r_seats": 8726,
            "cargo_demand": 269235
        }
    },
    {
        "iata": "LXS",
        "icao": "LGLM",
        "airportName": "Lemnos International Airport",
        "location_country": "Greece",
        "location_city": "Lemnos (Limnos)",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "39.917099",
        "longitude": "25.23629951",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04R/22L",
                "runway_length": "9895",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1653,
            "s_seats": 2754,
            "j_seats": 1699,
            "f_seats": 241,
            "r_seats": 173,
            "cargo_demand": 37244
        }
    },
    {
        "iata": "LXV",
        "icao": "KLXV",
        "airportName": "Lake County Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Leadville",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.22029877",
        "longitude": "-106.3170013",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6400",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2785,
            "s_seats": 2002,
            "j_seats": 1219,
            "f_seats": 386,
            "r_seats": 58,
            "cargo_demand": 63023
        }
    },
    {
        "iata": "LYB",
        "icao": "MWCL",
        "airportName": "Edward Bodden Airfield",
        "location_country": "British Overseas Territory of Cayman Islands",
        "location_city": "Little Cayman",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.660161",
        "longitude": "-80.088826",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3275",
                "runway_width": "",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 180959,
            "s_seats": 33384,
            "j_seats": 54447,
            "f_seats": 19654,
            "r_seats": 7850,
            "cargo_demand": 103874
        }
    },
    {
        "iata": "LYC",
        "icao": "ESNL",
        "airportName": "Lycksele Airport",
        "location_country": "Sweden",
        "location_city": "Lycksele",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "64.548302",
        "longitude": "18.7162",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6564",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166025,
            "s_seats": 28267,
            "j_seats": 58488,
            "f_seats": 3301,
            "r_seats": 6895,
            "cargo_demand": 355592
        }
    },
    {
        "iata": "LYH",
        "icao": "KLYH",
        "airportName": "Lynchburg Regional Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Lynchburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.3266983",
        "longitude": "-79.20040131",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5799",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3387",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80410,
            "s_seats": 34063,
            "j_seats": 54541,
            "f_seats": 1886,
            "r_seats": 3158,
            "cargo_demand": 337643
        }
    },
    {
        "iata": "LYN",
        "icao": "LFLY",
        "airportName": "Lyon-Bron Airport",
        "location_country": "France",
        "location_city": "Rhone-Alpes",
        "location_state": "Lyon",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "45.72719955",
        "longitude": "4.944270134",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5971",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71730,
            "s_seats": 38927,
            "j_seats": 34451,
            "f_seats": 8947,
            "r_seats": 8520,
            "cargo_demand": 149153
        }
    },
    {
        "iata": "LYO",
        "icao": "KLYO",
        "airportName": "Lyons-Rice County Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Lyons",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.34280014",
        "longitude": "-98.22689819",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "1700",
                "runway_width": "100",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "2550",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "2999",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1341,
            "s_seats": 2995,
            "j_seats": 1023,
            "f_seats": 81,
            "r_seats": 42,
            "cargo_demand": 60914
        }
    },
    {
        "iata": "LYP",
        "icao": "OPFA",
        "airportName": "Faisalabad International Airport",
        "location_country": "Pakistan",
        "location_city": "Faisalabad",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.36499977",
        "longitude": "72.99479675",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9272",
                "runway_width": "151",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 131958,
            "s_seats": 45671,
            "j_seats": 33302,
            "f_seats": 8150,
            "r_seats": 6315,
            "cargo_demand": 52186
        }
    },
    {
        "iata": "LYR",
        "icao": "ENSB",
        "airportName": "Svalbard Airport",
        "location_country": "Norway",
        "location_city": "Svalbard",
        "location_state": "Longyearbyen",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "\" Longyear\"\"\"",
        "longitude": "78.24610138",
        "region": "88",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7608",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178012,
            "s_seats": 33190,
            "j_seats": 14203,
            "f_seats": 7967,
            "r_seats": 6527,
            "cargo_demand": 487898
        }
    },
    {
        "iata": "LYS",
        "icao": "LFLL",
        "airportName": "Lyon-Saint-Exupery Airport",
        "location_country": "France",
        "location_city": "Rhone-Alpes",
        "location_state": "Lyon",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "45.725556",
        "longitude": "5.081111",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "8760",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "13124",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 490992,
            "s_seats": 92007,
            "j_seats": 72363,
            "f_seats": 31755,
            "r_seats": 10000,
            "cargo_demand": 19721488
        }
    },
    {
        "iata": "LYU",
        "icao": "KELO",
        "airportName": "Ely Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Ely",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.82450104",
        "longitude": "-91.83070374",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5600",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198377,
            "s_seats": 38017,
            "j_seats": 51629,
            "f_seats": 17218,
            "r_seats": 9504,
            "cargo_demand": 317900
        }
    },
    {
        "iata": "LYX",
        "icao": "EGMD",
        "airportName": "Lydd Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Lydd",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.9561",
        "longitude": "0.939167",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4938",
                "runway_width": "121",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2264",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135539,
            "s_seats": 49289,
            "j_seats": 44543,
            "f_seats": 19579,
            "r_seats": 9626,
            "cargo_demand": 323394
        }
    },
    {
        "iata": "LZC",
        "icao": "MMLC",
        "airportName": "Lazaro Cardenas Airport",
        "location_country": "Mexico",
        "location_city": "Michoacan",
        "location_state": "Lazaro Cardenas",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "18.00169945",
        "longitude": "-102.2210007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4900",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199244,
            "s_seats": 38974,
            "j_seats": 56795,
            "f_seats": 13911,
            "r_seats": 4134,
            "cargo_demand": 97660
        }
    },
    {
        "iata": "LZM",
        "icao": "FNLZ",
        "airportName": "Cuango-Luzamba Airport",
        "location_country": "Angola",
        "location_city": "Cuango (Luzamba)",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.115960121",
        "longitude": "18.04929924",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4915",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1518,
            "s_seats": 2708,
            "j_seats": 1872,
            "f_seats": 364,
            "r_seats": 53,
            "cargo_demand": 6285
        }
    },
    {
        "iata": "LZN",
        "icao": "RCFG",
        "airportName": "Matsu Nangan Airport",
        "location_country": "Taiwan",
        "location_city": "Nangan",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.1598",
        "longitude": "119.958",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5180",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 153789,
            "s_seats": 25531,
            "j_seats": 15528,
            "f_seats": 15297,
            "r_seats": 9272,
            "cargo_demand": 160817
        }
    },
    {
        "iata": "LZR",
        "icao": "YLZI",
        "airportName": "Lizard Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Lizard Island",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.673273",
        "longitude": "145.454571",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3038",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1416,
            "s_seats": 2730,
            "j_seats": 1041,
            "f_seats": 351,
            "r_seats": 33,
            "cargo_demand": 88811
        }
    },
    {
        "iata": "LZU",
        "icao": "KLZU",
        "airportName": "Gwinnett County Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "LaGrange",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.97809982",
        "longitude": "-83.96240234",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2351,
            "s_seats": 1549,
            "j_seats": 1977,
            "f_seats": 164,
            "r_seats": 66,
            "cargo_demand": 59419
        }
    },
    {
        "iata": "LZY",
        "icao": "ZUNZ",
        "airportName": "Nyingchi Mainling Airport",
        "location_country": "China",
        "location_city": "Tibet",
        "location_state": "Nyingchi (Linzhi)",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.30330086",
        "longitude": "94.33529663",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9843",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 89776,
            "s_seats": 41915,
            "j_seats": 57381,
            "f_seats": 3654,
            "r_seats": 3829,
            "cargo_demand": 280863
        }
    },
    {
        "iata": "MAA",
        "icao": "VOMM",
        "airportName": "Chennai International Airport",
        "location_country": "India",
        "location_city": "Tamil Nadu",
        "location_state": "Chennai",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "12.990005",
        "longitude": "80.169296",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "12001",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6708",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 363237,
            "s_seats": 95688,
            "j_seats": 124722,
            "f_seats": 59559,
            "r_seats": 10000,
            "cargo_demand": 11530110
        }
    },
    {
        "iata": "MAB",
        "icao": "SBMA",
        "airportName": "Joio Correa da Rocha Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Maraba",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.368589878",
        "longitude": "-49.13800049",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148133,
            "s_seats": 49399,
            "j_seats": 57779,
            "f_seats": 15895,
            "r_seats": 6468,
            "cargo_demand": 63909
        }
    },
    {
        "iata": "MAC",
        "icao": "KMAC",
        "airportName": "Macon Downtown Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Macon",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.82210159",
        "longitude": "-83.56199646",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4696",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3614",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2363,
            "s_seats": 2905,
            "j_seats": 1336,
            "f_seats": 311,
            "r_seats": 43,
            "cargo_demand": 77522
        }
    },
    {
        "iata": "MAD",
        "icao": "LEMD",
        "airportName": "Adolfo Suarez Madrid-Barajas Airport",
        "location_country": "Spain",
        "location_city": "Madrid",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "40.471926",
        "longitude": "-3.56264",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "11483",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "13084",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "11483",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "13711",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 406050,
            "s_seats": 75968,
            "j_seats": 124125,
            "f_seats": 65713,
            "r_seats": 10000,
            "cargo_demand": 13079829
        }
    },
    {
        "iata": "MAE",
        "icao": "KMAE",
        "airportName": "Madera Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Madera",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.98860168",
        "longitude": "-120.1119995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3702",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5545",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3515,
            "s_seats": 2048,
            "j_seats": 1346,
            "f_seats": 192,
            "r_seats": 161,
            "cargo_demand": 67850
        }
    },
    {
        "iata": "MAF",
        "icao": "KMAF",
        "airportName": "Midland International Air and Space Port",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Midland",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.94249916",
        "longitude": "-102.2020035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4605",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "8302",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "4247",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "9501",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57339,
            "s_seats": 39811,
            "j_seats": 28128,
            "f_seats": 18888,
            "r_seats": 7955,
            "cargo_demand": 241446
        }
    },
    {
        "iata": "MAG",
        "icao": "AYMD",
        "airportName": "Madang Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Madang",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.207079887",
        "longitude": "145.7890015",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5174",
                "runway_width": "105",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77185,
            "s_seats": 43807,
            "j_seats": 50387,
            "f_seats": 7481,
            "r_seats": 5083,
            "cargo_demand": 483951
        }
    },
    {
        "iata": "MAH",
        "icao": "LEMH",
        "airportName": "Menorca Airport",
        "location_country": "Spain",
        "location_city": "Balearic Islands",
        "location_state": "Menorca",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "39.86259842",
        "longitude": "4.218649864",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8366",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01L/19R",
                "runway_length": "7710",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70802,
            "s_seats": 34326,
            "j_seats": 58713,
            "f_seats": 10843,
            "r_seats": 8233,
            "cargo_demand": 381622
        }
    },
    {
        "iata": "MAJ",
        "icao": "PKMJ",
        "airportName": "Marshall Islands International Airport",
        "location_country": "Marshall Islands",
        "location_city": "Majuro",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.064760208",
        "longitude": "171.2720032",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7897",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 65909,
            "s_seats": 35034,
            "j_seats": 43233,
            "f_seats": 8492,
            "r_seats": 6702,
            "cargo_demand": 399416
        }
    },
    {
        "iata": "MAK",
        "icao": "HSSM",
        "airportName": "Malakal Airport",
        "location_country": "South Sudan",
        "location_city": "Malakal",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.558970451",
        "longitude": "31.6522007",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6562",
                "runway_width": "125",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136586,
            "s_seats": 49217,
            "j_seats": 22985,
            "f_seats": 8443,
            "r_seats": 3158,
            "cargo_demand": 296689
        }
    },
    {
        "iata": "MAM",
        "icao": "MMMA",
        "airportName": "General Servando Canales International Airport",
        "location_country": "Mexico",
        "location_city": "Tamaulipas",
        "location_state": "Matamoros",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "25.76989937",
        "longitude": "-97.52529907",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7546",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103467,
            "s_seats": 29893,
            "j_seats": 11209,
            "f_seats": 2384,
            "r_seats": 8592,
            "cargo_demand": 425282
        }
    },
    {
        "iata": "MAN",
        "icao": "EGCC",
        "airportName": "Manchester Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Manchester",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "53.349375",
        "longitude": "-2.279521",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "10000",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 151974,
            "s_seats": 83461,
            "j_seats": 158839,
            "f_seats": 53529,
            "r_seats": 10000,
            "cargo_demand": 9161638
        }
    },
    {
        "iata": "MAO",
        "icao": "SBEG",
        "airportName": "Eduardo Gomes International Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Manaus",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-3.03861",
        "longitude": "-60.049702",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASPH 71/F/C/X/T"
            }
        ],
        "demand": {
            "y_seats": 376174,
            "s_seats": 73294,
            "j_seats": 47342,
            "f_seats": 58501,
            "r_seats": 10000,
            "cargo_demand": 19803876
        }
    },
    {
        "iata": "MAQ",
        "icao": "VTPM",
        "airportName": "Mae Sot Airport",
        "location_country": "Thailand",
        "location_city": "Mae Sot",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.69989967",
        "longitude": "98.54509735",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128261,
            "s_seats": 37491,
            "j_seats": 13129,
            "f_seats": 10080,
            "r_seats": 6911,
            "cargo_demand": 113851
        }
    },
    {
        "iata": "MAR",
        "icao": "SVMC",
        "airportName": "La Chinita International Airport",
        "location_country": "Venezuela",
        "location_city": "Maracaibo",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.55820847",
        "longitude": "-71.7278595",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CONCRETE"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "8202",
                "runway_width": "98",
                "runway_material": "CONCRETE"
            }
        ],
        "demand": {
            "y_seats": 111720,
            "s_seats": 27615,
            "j_seats": 14267,
            "f_seats": 18780,
            "r_seats": 3494,
            "cargo_demand": 91916
        }
    },
    {
        "iata": "MAS",
        "icao": "AYMO",
        "airportName": "Momote Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Manus Island",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.06189",
        "longitude": "147.423996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6136",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122494,
            "s_seats": 39017,
            "j_seats": 44664,
            "f_seats": 4055,
            "r_seats": 8755,
            "cargo_demand": 300099
        }
    },
    {
        "iata": "MAT",
        "icao": "FZAM",
        "airportName": "Matadi Tshimpi Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Matadi",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.799610138",
        "longitude": "13.44040012",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5150",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3998,
            "s_seats": 2739,
            "j_seats": 1736,
            "f_seats": 9,
            "r_seats": 89,
            "cargo_demand": 41147
        }
    },
    {
        "iata": "MAU",
        "icao": "NTTP",
        "airportName": "Maupiti Airport",
        "location_country": "French Polynesia",
        "location_city": "Leeward Islands",
        "location_state": "Maupiti",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.42650032",
        "longitude": "-152.2440033",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3135",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 96721,
            "s_seats": 39028,
            "j_seats": 13874,
            "f_seats": 3469,
            "r_seats": 3399,
            "cargo_demand": 372032
        }
    },
    {
        "iata": "MAW",
        "icao": "KMAW",
        "airportName": "Malden Regional Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Malden",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.6006012",
        "longitude": "-89.99220276",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4999",
                "runway_width": "80",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5011",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1726,
            "s_seats": 2635,
            "j_seats": 1253,
            "f_seats": 269,
            "r_seats": 200,
            "cargo_demand": 95237
        }
    },
    {
        "iata": "MAY",
        "icao": "MYAB",
        "airportName": "Clarence A. Bain Airport",
        "location_country": "Bahamas",
        "location_city": "Andros Island",
        "location_state": "Mangrove Cay",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "24.28770065",
        "longitude": "-77.68460083",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 146813,
            "s_seats": 36216,
            "j_seats": 39277,
            "f_seats": 14052,
            "r_seats": 5782,
            "cargo_demand": 172600
        }
    },
    {
        "iata": "MAZ",
        "icao": "TJMZ",
        "airportName": "Eugenio Maria de Hostos Airport",
        "location_country": "United States",
        "location_city": "Puerto Rico",
        "location_state": "Mayaguez",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.25569916",
        "longitude": "-67.14849854",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4998",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180725,
            "s_seats": 29768,
            "j_seats": 35665,
            "f_seats": 14734,
            "r_seats": 3796,
            "cargo_demand": 104762
        }
    },
    {
        "iata": "MBA",
        "icao": "HKMO",
        "airportName": "Moi International Airport",
        "location_country": "Kenya",
        "location_city": "Mombasa",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-4.03483",
        "longitude": "39.5942",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10991",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4473",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115078,
            "s_seats": 90186,
            "j_seats": 100069,
            "f_seats": 67693,
            "r_seats": 10000,
            "cargo_demand": 26226417
        }
    },
    {
        "iata": "MBB",
        "icao": "YMBL",
        "airportName": "Marble Bar Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Marble Bar",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-21.16329956",
        "longitude": "119.8330002",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4009",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3454",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3640,
            "s_seats": 1509,
            "j_seats": 1255,
            "f_seats": 215,
            "r_seats": 194,
            "cargo_demand": 72682
        }
    },
    {
        "iata": "MBD",
        "icao": "FAMM",
        "airportName": "Mahikeng Airport",
        "location_country": "South Africa",
        "location_city": "Mmabatho",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.79840088",
        "longitude": "25.54800034",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "15157",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177709,
            "s_seats": 41222,
            "j_seats": 59260,
            "f_seats": 10035,
            "r_seats": 6705,
            "cargo_demand": 375980
        }
    },
    {
        "iata": "MBE",
        "icao": "RJEB",
        "airportName": "Monbetsu Airport",
        "location_country": "Japan",
        "location_city": "Hokkaido",
        "location_state": "Hokkaido",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.30390167",
        "longitude": "143.404007",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138226,
            "s_seats": 44160,
            "j_seats": 9313,
            "f_seats": 11182,
            "r_seats": 9272,
            "cargo_demand": 129579
        }
    },
    {
        "iata": "MBF",
        "icao": "YPOK",
        "airportName": "Porepunkah Airfield",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Mount Buffalo",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-36.71773071",
        "longitude": "146.8900394",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "2755",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 1823,
            "s_seats": 2744,
            "j_seats": 1678,
            "f_seats": 19,
            "r_seats": 175,
            "cargo_demand": 95560
        }
    },
    {
        "iata": "MBG",
        "icao": "KMBG",
        "airportName": "Mobridge Municipal Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Mobridge",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "45.54650116",
        "longitude": "-100.4079971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4411",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2400",
                "runway_width": "250",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 116608,
            "s_seats": 49672,
            "j_seats": 27084,
            "f_seats": 16232,
            "r_seats": 4715,
            "cargo_demand": 174630
        }
    },
    {
        "iata": "MBH",
        "icao": "YMYB",
        "airportName": "Maryborough Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Maryborough",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.5133",
        "longitude": "152.714996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2904",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5207",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2549,
            "s_seats": 2088,
            "j_seats": 1610,
            "f_seats": 235,
            "r_seats": 153,
            "cargo_demand": 5900
        }
    },
    {
        "iata": "MBI",
        "icao": "HTMB",
        "airportName": "Mbeya Airport",
        "location_country": "Tanzania",
        "location_city": "Mbeya",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.919444",
        "longitude": "33.463889",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1868,
            "s_seats": 2256,
            "j_seats": 1243,
            "f_seats": 102,
            "r_seats": 7,
            "cargo_demand": 28184
        }
    },
    {
        "iata": "MBJ",
        "icao": "MKJS",
        "airportName": "Sangster International Airport",
        "location_country": "Jamaica",
        "location_city": "Montego Bay",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.50370026",
        "longitude": "-77.91339874",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8735",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198254,
            "s_seats": 47487,
            "j_seats": 31811,
            "f_seats": 8310,
            "r_seats": 6467,
            "cargo_demand": 391331
        }
    },
    {
        "iata": "MBK",
        "icao": "SWXM",
        "airportName": "Orlando Villas-Boas Regional Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Matupa",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "small_airport",
        "latitude": "-10.1702776",
        "longitude": "-54.95277786",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "4862",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1298,
            "s_seats": 1514,
            "j_seats": 1809,
            "f_seats": 236,
            "r_seats": 179,
            "cargo_demand": 28606
        }
    },
    {
        "iata": "MBL",
        "icao": "KMBL",
        "airportName": "Manistee County Blacker Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Manistee",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.272738",
        "longitude": "-86.251945",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5502",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2720",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1033,
            "s_seats": 2231,
            "j_seats": 1669,
            "f_seats": 235,
            "r_seats": 161,
            "cargo_demand": 96674
        }
    },
    {
        "iata": "MBO",
        "icao": "RPUM",
        "airportName": "Mamburao Airport",
        "location_country": "Philippines",
        "location_city": "Mamburao",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.20810032",
        "longitude": "120.6050034",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149750,
            "s_seats": 35092,
            "j_seats": 52535,
            "f_seats": 13654,
            "r_seats": 7627,
            "cargo_demand": 437613
        }
    },
    {
        "iata": "MBQ",
        "icao": "HUMA",
        "airportName": "Mbarara Airport",
        "location_country": "Uganda",
        "location_city": "Mbarara",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.555278",
        "longitude": "30.5994",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5446",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 3458,
            "s_seats": 2555,
            "j_seats": 1093,
            "f_seats": 440,
            "r_seats": 46,
            "cargo_demand": 8435
        }
    },
    {
        "iata": "MBS",
        "icao": "KMBS",
        "airportName": "MBS International Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Saginaw / Midland / Bay City",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "43.53290176",
        "longitude": "-84.07959747",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6400",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 359134,
            "s_seats": 88476,
            "j_seats": 124479,
            "f_seats": 62618,
            "r_seats": 10000,
            "cargo_demand": 19063168
        }
    },
    {
        "iata": "MBT",
        "icao": "RPVJ",
        "airportName": "Moises R. Espinosa Airport ",
        "location_country": "Philippines",
        "location_city": "Masbate",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.369682",
        "longitude": "123.630095",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4199",
                "runway_width": "98",
                "runway_material": "ASP/CON"
            }
        ],
        "demand": {
            "y_seats": 199608,
            "s_seats": 46872,
            "j_seats": 20544,
            "f_seats": 8603,
            "r_seats": 5473,
            "cargo_demand": 413166
        }
    },
    {
        "iata": "MBU",
        "icao": "AGGI",
        "airportName": "Mbambanakira Airport",
        "location_country": "Solomon Islands",
        "location_city": "Mbambanakira",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.74750042",
        "longitude": "159.8390045",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "1953",
                "runway_width": "72",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3622,
            "s_seats": 1785,
            "j_seats": 1088,
            "f_seats": 433,
            "r_seats": 117,
            "cargo_demand": 39541
        }
    },
    {
        "iata": "MBW",
        "icao": "YMMB",
        "airportName": "Moorabbin Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Melbourne",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-37.97579956",
        "longitude": "145.102005",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "1873",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "3783",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "3478",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "4383",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "4069",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181454,
            "s_seats": 40141,
            "j_seats": 20364,
            "f_seats": 11578,
            "r_seats": 4277,
            "cargo_demand": 450718
        }
    },
    {
        "iata": "MBX",
        "icao": "LJMB",
        "airportName": "Maribor Edvard Rusjan Airport",
        "location_country": "Slovenia",
        "location_city": "Maribor",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.4799",
        "longitude": "15.6861",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "3937",
                "runway_width": "197",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 176743,
            "s_seats": 37394,
            "j_seats": 8451,
            "f_seats": 4008,
            "r_seats": 3559,
            "cargo_demand": 103221
        }
    },
    {
        "iata": "MBY",
        "icao": "KMBY",
        "airportName": "Omar N. Bradley Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Moberly",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.464393",
        "longitude": "-92.428365",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3350",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4270",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3469,
            "s_seats": 2184,
            "j_seats": 1595,
            "f_seats": 60,
            "r_seats": 49,
            "cargo_demand": 51718
        }
    },
    {
        "iata": "MBZ",
        "icao": "SWMW",
        "airportName": "Maues Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Maues",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.37217",
        "longitude": "-57.7248",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "3937",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1483,
            "s_seats": 2117,
            "j_seats": 1935,
            "f_seats": 117,
            "r_seats": 180,
            "cargo_demand": 12244
        }
    },
    {
        "iata": "MCB",
        "icao": "KMCB",
        "airportName": "McComb-Pike County Airport ",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "McComb",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "31.178499",
        "longitude": "-90.471901",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138589,
            "s_seats": 36100,
            "j_seats": 42154,
            "f_seats": 18689,
            "r_seats": 6256,
            "cargo_demand": 406199
        }
    },
    {
        "iata": "MCC",
        "icao": "KMCC",
        "airportName": "McClellan Airfield",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Sacramento",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.66759872",
        "longitude": "-121.401001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "10600",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 97119,
            "s_seats": 38293,
            "j_seats": 16808,
            "f_seats": 11370,
            "r_seats": 7802,
            "cargo_demand": 240620
        }
    },
    {
        "iata": "MCD",
        "icao": "KMCD",
        "airportName": "Mackinac Island Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Mackinac Island",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.86489868",
        "longitude": "-84.63729858",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3501",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3545,
            "s_seats": 1756,
            "j_seats": 1569,
            "f_seats": 482,
            "r_seats": 15,
            "cargo_demand": 84122
        }
    },
    {
        "iata": "MCE",
        "icao": "KMCE",
        "airportName": "Merced Regional Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Merced",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.28469849",
        "longitude": "-120.5139999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5903",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55960,
            "s_seats": 27243,
            "j_seats": 32255,
            "f_seats": 5055,
            "r_seats": 7807,
            "cargo_demand": 458532
        }
    },
    {
        "iata": "MCF",
        "icao": "KMCF",
        "airportName": "MacDill Air Force Base",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Tampa",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "27.84930038",
        "longitude": "-82.52120209",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "11421",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 352111,
            "s_seats": 98010,
            "j_seats": 124135,
            "f_seats": 59910,
            "r_seats": 10000,
            "cargo_demand": 16697295
        }
    },
    {
        "iata": "MCG",
        "icao": "PAMC",
        "airportName": "McGrath Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "McGrath",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "62.95289993",
        "longitude": "-155.6060028",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2000",
                "runway_width": "60",
                "runway_material": "GRVL-G"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5936",
                "runway_width": "100",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 140497,
            "s_seats": 39495,
            "j_seats": 35666,
            "f_seats": 16750,
            "r_seats": 9054,
            "cargo_demand": 488988
        }
    },
    {
        "iata": "MCH",
        "icao": "SEMH",
        "airportName": "General Manuel Serrano Airport",
        "location_country": "Ecuador",
        "location_city": "Machala",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.2689",
        "longitude": "-79.961601",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5493",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66287,
            "s_seats": 31299,
            "j_seats": 45912,
            "f_seats": 4950,
            "r_seats": 8733,
            "cargo_demand": 396992
        }
    },
    {
        "iata": "MCI",
        "icao": "KMCI",
        "airportName": "Kansas City International Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Kansas City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "39.2976",
        "longitude": "-94.713898",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "10801",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "9500",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "09/27",
                "runway_length": "9500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 460491,
            "s_seats": 73161,
            "j_seats": 165688,
            "f_seats": 30867,
            "r_seats": 10000,
            "cargo_demand": 16639714
        }
    },
    {
        "iata": "MCJ",
        "icao": "SKLM",
        "airportName": "Jorge Isaacs Airport",
        "location_country": "Colombia",
        "location_city": "Maicao",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.2325",
        "longitude": "-72.4901",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4876",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 181807,
            "s_seats": 42153,
            "j_seats": 30212,
            "f_seats": 7144,
            "r_seats": 4820,
            "cargo_demand": 150745
        }
    },
    {
        "iata": "MCK",
        "icao": "KMCK",
        "airportName": "McCook Ben Nelson Regional Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "McCook",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.20629883",
        "longitude": "-100.5920029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "CONC-G"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6449",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "1350",
                "runway_width": "160",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 102563,
            "s_seats": 42443,
            "j_seats": 55059,
            "f_seats": 7620,
            "r_seats": 4476,
            "cargo_demand": 53445
        }
    },
    {
        "iata": "MCL",
        "icao": "PAIN",
        "airportName": "McKinley National Park Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "McKinley Park",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "63.73260117",
        "longitude": "-148.9109955",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3000",
                "runway_width": "68",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 3906,
            "s_seats": 2990,
            "j_seats": 1496,
            "f_seats": 326,
            "r_seats": 135,
            "cargo_demand": 97493
        }
    },
    {
        "iata": "MCN",
        "icao": "KMCN",
        "airportName": "Middle Georgia Regional Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Macon",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.69279861",
        "longitude": "-83.64920044",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6501",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113843,
            "s_seats": 32938,
            "j_seats": 17791,
            "f_seats": 5098,
            "r_seats": 9986,
            "cargo_demand": 97944
        }
    },
    {
        "iata": "MCO",
        "icao": "KMCO",
        "airportName": "Orlando International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Orlando",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "28.42939949",
        "longitude": "-81.30899811",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "12005",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "12004",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 255466,
            "s_seats": 93986,
            "j_seats": 40909,
            "f_seats": 54382,
            "r_seats": 10000,
            "cargo_demand": 22219587
        }
    },
    {
        "iata": "MCP",
        "icao": "SBMQ",
        "airportName": "Alberto Alcolumbre International Airport",
        "location_country": "Brazil",
        "location_city": "Amapa",
        "location_state": "Macapa",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.050664",
        "longitude": "-51.07220078",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70488,
            "s_seats": 25530,
            "j_seats": 52246,
            "f_seats": 2896,
            "r_seats": 7315,
            "cargo_demand": 330166
        }
    },
    {
        "iata": "MCS",
        "icao": "SARM",
        "airportName": "Monte Caseros Airport",
        "location_country": "Argentina",
        "location_city": "Corrientes",
        "location_state": "Monte Caseros",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-30.2719",
        "longitude": "-57.6402",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4068",
                "runway_width": "98",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "4323",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 166432,
            "s_seats": 43244,
            "j_seats": 9317,
            "f_seats": 17476,
            "r_seats": 6278,
            "cargo_demand": 455410
        }
    },
    {
        "iata": "MCT",
        "icao": "OOMS",
        "airportName": "Muscat International Airport",
        "location_country": "Oman",
        "location_city": "Muscat",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "23.59329987",
        "longitude": "58.28440094",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "11759",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 442362,
            "s_seats": 79460,
            "j_seats": 163343,
            "f_seats": 34799,
            "r_seats": 10000,
            "cargo_demand": 25051806
        }
    },
    {
        "iata": "MCU",
        "icao": "LFBK",
        "airportName": "MontluiCon - Gueret Airport",
        "location_country": "France",
        "location_city": "Auvergne",
        "location_state": "MontluiCon",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "46.22259903",
        "longitude": "2.363960028",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6234",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171714,
            "s_seats": 31835,
            "j_seats": 15586,
            "f_seats": 15492,
            "r_seats": 3322,
            "cargo_demand": 95768
        }
    },
    {
        "iata": "MCV",
        "icao": "YMHU",
        "airportName": "McArthur River Mine Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "McArthur River",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.44249916",
        "longitude": "136.0839996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4931",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1923,
            "s_seats": 2703,
            "j_seats": 1007,
            "f_seats": 409,
            "r_seats": 14,
            "cargo_demand": 47755
        }
    },
    {
        "iata": "MCW",
        "icao": "KMCW",
        "airportName": "Mason City Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Mason City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.15779877",
        "longitude": "-93.33129883",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5502",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6501",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168328,
            "s_seats": 32425,
            "j_seats": 53851,
            "f_seats": 19859,
            "r_seats": 9119,
            "cargo_demand": 141209
        }
    },
    {
        "iata": "MCX",
        "icao": "URML",
        "airportName": "Uytash Airport",
        "location_country": "Russia",
        "location_city": "Dagestan",
        "location_state": "Makhachkala",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.81679916",
        "longitude": "47.65230179",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8661",
                "runway_width": "138",
                "runway_material": "CON"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "8688",
                "runway_width": "230",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 136582,
            "s_seats": 47175,
            "j_seats": 27389,
            "f_seats": 19960,
            "r_seats": 5613,
            "cargo_demand": 51985
        }
    },
    {
        "iata": "MCY",
        "icao": "YBSU",
        "airportName": "Sunshine Coast Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Sunshine Coast",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.6033",
        "longitude": "153.091003",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8038",
                "runway_width": "148",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5896",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171704,
            "s_seats": 34172,
            "j_seats": 20581,
            "f_seats": 3723,
            "r_seats": 4192,
            "cargo_demand": 211522
        }
    },
    {
        "iata": "MCZ",
        "icao": "SBMO",
        "airportName": "Zumbi dos Palmares International Airport",
        "location_country": "Brazil",
        "location_city": "Alagoas",
        "location_state": "Maceio",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.510809898",
        "longitude": "-35.79169846",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8537",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162907,
            "s_seats": 48833,
            "j_seats": 45358,
            "f_seats": 12793,
            "r_seats": 7208,
            "cargo_demand": 452536
        }
    },
    {
        "iata": "MDC",
        "icao": "WAMM",
        "airportName": "Sam Ratulangi International Airport",
        "location_country": "Indonesia",
        "location_city": "Manado",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.54926002",
        "longitude": "124.9260025",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8693",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62758,
            "s_seats": 26285,
            "j_seats": 40847,
            "f_seats": 18594,
            "r_seats": 8838,
            "cargo_demand": 263871
        }
    },
    {
        "iata": "MDD",
        "icao": "KMDD",
        "airportName": "Midland Airpark",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Midland",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.03649902",
        "longitude": "-102.1009979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5022",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3977",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1117,
            "s_seats": 1774,
            "j_seats": 1577,
            "f_seats": 466,
            "r_seats": 79,
            "cargo_demand": 13219
        }
    },
    {
        "iata": "MDE",
        "icao": "SKRG",
        "airportName": "Jose Maria Cordova International Airport",
        "location_country": "Colombia",
        "location_city": "Medellin",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.16454",
        "longitude": "-75.4231",
        "region": "SA",
        "runway": [
            {
                "runway_name": "19/01",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67694,
            "s_seats": 39850,
            "j_seats": 28013,
            "f_seats": 10834,
            "r_seats": 4769,
            "cargo_demand": 487144
        }
    },
    {
        "iata": "MDF",
        "icao": "KMDZ",
        "airportName": "Taylor County Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Medford",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.10100174",
        "longitude": "-90.30329895",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5001",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4101",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2118,
            "s_seats": 2893,
            "j_seats": 1601,
            "f_seats": 421,
            "r_seats": 90,
            "cargo_demand": 3928
        }
    },
    {
        "iata": "MDG",
        "icao": "ZYMD",
        "airportName": "Mudanjiang Hailang Airport",
        "location_country": "China",
        "location_city": "Heilongjiang",
        "location_state": "Mudanjiang",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.52410126",
        "longitude": "129.5690002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 110264,
            "s_seats": 29441,
            "j_seats": 12167,
            "f_seats": 2999,
            "r_seats": 8988,
            "cargo_demand": 296637
        }
    },
    {
        "iata": "MDH",
        "icao": "KMDH",
        "airportName": "Southern Illinois Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Carbondale / Murphysboro",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.77809906",
        "longitude": "-89.2519989",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4164",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "6506",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "3498",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143760,
            "s_seats": 31344,
            "j_seats": 56661,
            "f_seats": 15074,
            "r_seats": 8345,
            "cargo_demand": 430400
        }
    },
    {
        "iata": "MDI",
        "icao": "DNMK",
        "airportName": "Makurdi Airport",
        "location_country": "Nigeria",
        "location_city": "Makurdi",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.70388",
        "longitude": "8.61394",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9830",
                "runway_width": "164",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 111452,
            "s_seats": 43669,
            "j_seats": 48721,
            "f_seats": 18815,
            "r_seats": 7744,
            "cargo_demand": 186712
        }
    },
    {
        "iata": "MDK",
        "icao": "FZEA",
        "airportName": "Mbandaka Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Mbandaka",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.022600001",
        "longitude": "18.2887001",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7223",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161829,
            "s_seats": 48093,
            "j_seats": 35333,
            "f_seats": 3691,
            "r_seats": 8400,
            "cargo_demand": 185652
        }
    },
    {
        "iata": "MDL",
        "icao": "VYMD",
        "airportName": "Mandalay International Airport",
        "location_country": "Myanmar",
        "location_city": "Mandalay",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "21.70219994",
        "longitude": "95.97789764",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "14003",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 447401,
            "s_seats": 70842,
            "j_seats": 32617,
            "f_seats": 51840,
            "r_seats": 10000,
            "cargo_demand": 24284609
        }
    },
    {
        "iata": "MDN",
        "icao": "KIMS",
        "airportName": "Madison Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Madison",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.75889969",
        "longitude": "-85.46549988",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4400",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1194,
            "s_seats": 1593,
            "j_seats": 1902,
            "f_seats": 169,
            "r_seats": 64,
            "cargo_demand": 71478
        }
    },
    {
        "iata": "MDO",
        "icao": "PAMD",
        "airportName": "Middleton Island Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Middleton Island",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "59.44990158",
        "longitude": "-146.3070068",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4178",
                "runway_width": "115",
                "runway_material": "GRVL-E"
            },
            {
                "runway_name": "13/31",
                "runway_length": "1500",
                "runway_width": "125",
                "runway_material": "GRVL-E"
            }
        ],
        "demand": {
            "y_seats": 3762,
            "s_seats": 2225,
            "j_seats": 1963,
            "f_seats": 376,
            "r_seats": 132,
            "cargo_demand": 59160
        }
    },
    {
        "iata": "MDQ",
        "icao": "SAZM",
        "airportName": "Astor Piazzolla International Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "Mar del Plata",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-37.9342",
        "longitude": "-57.5733",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127103,
            "s_seats": 28652,
            "j_seats": 37868,
            "f_seats": 5935,
            "r_seats": 5821,
            "cargo_demand": 402110
        }
    },
    {
        "iata": "MDS",
        "icao": "MBMC",
        "airportName": "Middle Caicos Airport",
        "location_country": "British Overseas Territory of Turks and Caicos Islands",
        "location_city": "Middle Caicos",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.82602",
        "longitude": "-71.8025",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2894",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2519,
            "s_seats": 2235,
            "j_seats": 1284,
            "f_seats": 109,
            "r_seats": 77,
            "cargo_demand": 42099
        }
    },
    {
        "iata": "MDT",
        "icao": "KMDT",
        "airportName": "Harrisburg International Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Harrisburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.19350052",
        "longitude": "-76.76339722",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10001",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186796,
            "s_seats": 42434,
            "j_seats": 23471,
            "f_seats": 16622,
            "r_seats": 8751,
            "cargo_demand": 202628
        }
    },
    {
        "iata": "MDU",
        "icao": "AYMN",
        "airportName": "Mendi Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Mendi",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.14774",
        "longitude": "143.656998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4411",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68537,
            "s_seats": 34544,
            "j_seats": 47736,
            "f_seats": 12942,
            "r_seats": 8543,
            "cargo_demand": 453789
        }
    },
    {
        "iata": "MDW",
        "icao": "KMDW",
        "airportName": "Midway International Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Chicago",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "41.785999",
        "longitude": "-87.752403",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "5507",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "6446",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13C/31C",
                "runway_length": "6522",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "5141",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "3859",
                "runway_width": "60",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 323123,
            "s_seats": 77536,
            "j_seats": 161914,
            "f_seats": 25548,
            "r_seats": 10000,
            "cargo_demand": 28386656
        }
    },
    {
        "iata": "MDY",
        "icao": "PMDY",
        "airportName": "Henderson Field",
        "location_country": "United States",
        "location_city": "Midway Atoll",
        "location_state": "Sand Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.20170021",
        "longitude": "-177.3809967",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7904",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 170428,
            "s_seats": 35454,
            "j_seats": 35071,
            "f_seats": 17822,
            "r_seats": 8938,
            "cargo_demand": 72835
        }
    },
    {
        "iata": "MDZ",
        "icao": "SAME",
        "airportName": "Gov. Francisco Gabrielli International Airport",
        "location_country": "Argentina",
        "location_city": "Mendoza",
        "location_state": "Mendoza",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-32.83169937",
        "longitude": "-68.79290009",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9301",
                "runway_width": "177",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 60369,
            "s_seats": 26940,
            "j_seats": 50395,
            "f_seats": 5567,
            "r_seats": 7455,
            "cargo_demand": 383293
        }
    },
    {
        "iata": "MEA",
        "icao": "SBME",
        "airportName": "Benedito Lacerda Airport",
        "location_country": "Brazil",
        "location_city": "Rio de Janeiro",
        "location_state": "Macae",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-22.34300041",
        "longitude": "-41.76599884",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132887,
            "s_seats": 31002,
            "j_seats": 47651,
            "f_seats": 8352,
            "r_seats": 9501,
            "cargo_demand": 273192
        }
    },
    {
        "iata": "MEB",
        "icao": "YMEN",
        "airportName": "Essendon Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Melbourne",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-37.7281",
        "longitude": "144.901993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6302",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4311",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151605,
            "s_seats": 45071,
            "j_seats": 39905,
            "f_seats": 7758,
            "r_seats": 4236,
            "cargo_demand": 394583
        }
    },
    {
        "iata": "MEC",
        "icao": "SEMT",
        "airportName": "Eloy Alfaro International Airport",
        "location_country": "Ecuador",
        "location_city": "Manta",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.946078002",
        "longitude": "-80.67880249",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9383",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88530,
            "s_seats": 35594,
            "j_seats": 40830,
            "f_seats": 8432,
            "r_seats": 3837,
            "cargo_demand": 132362
        }
    },
    {
        "iata": "MED",
        "icao": "OEMA",
        "airportName": "Prince Mohammad bin Abdulaziz Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Medina",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "24.5534",
        "longitude": "39.705101",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "13149",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "10039",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 401201,
            "s_seats": 74974,
            "j_seats": 37972,
            "f_seats": 68467,
            "r_seats": 10000,
            "cargo_demand": 16128200
        }
    },
    {
        "iata": "MEE",
        "icao": "NWWR",
        "airportName": "Mare Airport",
        "location_country": "New Caledonia",
        "location_city": "Loyalty Islands",
        "location_state": "Mare",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.4817009",
        "longitude": "168.0379944",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3281",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109988,
            "s_seats": 37499,
            "j_seats": 8731,
            "f_seats": 10936,
            "r_seats": 9751,
            "cargo_demand": 113404
        }
    },
    {
        "iata": "MEG",
        "icao": "FNMA",
        "airportName": "Malanje Airport",
        "location_country": "Angola",
        "location_city": "Malanje",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.525090218",
        "longitude": "16.31240082",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7283",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184863,
            "s_seats": 30274,
            "j_seats": 28731,
            "f_seats": 3879,
            "r_seats": 9753,
            "cargo_demand": 202615
        }
    },
    {
        "iata": "MEH",
        "icao": "ENMH",
        "airportName": "Mehamn Airport",
        "location_country": "Norway",
        "location_city": "Mehamn",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "71.02970123",
        "longitude": "27.82670021",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "2887",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159060,
            "s_seats": 36424,
            "j_seats": 51963,
            "f_seats": 18523,
            "r_seats": 3723,
            "cargo_demand": 455275
        }
    },
    {
        "iata": "MEI",
        "icao": "KMEI",
        "airportName": "Meridian Regional Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Meridian",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.3326",
        "longitude": "-88.7519",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10003",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04/22",
                "runway_length": "4599",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148552,
            "s_seats": 40784,
            "j_seats": 56769,
            "f_seats": 17886,
            "r_seats": 7830,
            "cargo_demand": 490253
        }
    },
    {
        "iata": "MEJ",
        "icao": "KGKJ",
        "airportName": "Port Meadville Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Meadville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.62649918",
        "longitude": "-80.21469879",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5002",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2911,
            "s_seats": 2763,
            "j_seats": 1727,
            "f_seats": 427,
            "r_seats": 196,
            "cargo_demand": 98289
        }
    },
    {
        "iata": "MEK",
        "icao": "GMFM",
        "airportName": "Bassatine Air Base",
        "location_country": "Morocco",
        "location_city": "Meknes",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct4",
        "airport_size": "medium_airport",
        "latitude": "33.8791008",
        "longitude": "-5.515120029",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6420",
                "runway_width": "164",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "9252",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128294,
            "s_seats": 30564,
            "j_seats": 15267,
            "f_seats": 10016,
            "r_seats": 3158,
            "cargo_demand": 105680
        }
    },
    {
        "iata": "MEL",
        "icao": "YMML",
        "airportName": "Melbourne Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Melbourne",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "large_airport",
        "latitude": "-37.673302",
        "longitude": "144.843002",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7500",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "11998",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 234732,
            "s_seats": 71243,
            "j_seats": 21049,
            "f_seats": 28783,
            "r_seats": 10000,
            "cargo_demand": 14899176
        }
    },
    {
        "iata": "MEM",
        "icao": "KMEM",
        "airportName": "Memphis International Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Memphis",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "35.04240036",
        "longitude": "-89.97669983",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8946",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18C/36C",
                "runway_length": "11120",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "9320",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 306395,
            "s_seats": 88843,
            "j_seats": 53423,
            "f_seats": 62056,
            "r_seats": 10000,
            "cargo_demand": 14486530
        }
    },
    {
        "iata": "MEN",
        "icao": "LFNB",
        "airportName": "Brenoux Airport",
        "location_country": "France",
        "location_city": "Languedoc-Roussillon",
        "location_state": "Mende",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "44.502102",
        "longitude": "3.53282",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94461,
            "s_seats": 49895,
            "j_seats": 22749,
            "f_seats": 2399,
            "r_seats": 4607,
            "cargo_demand": 334172
        }
    },
    {
        "iata": "MEO",
        "icao": "KMQI",
        "airportName": "Dare County Regional Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Manteo",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.91899872",
        "longitude": "-75.69550323",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4300",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3303",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1342,
            "s_seats": 2493,
            "j_seats": 1241,
            "f_seats": 406,
            "r_seats": 72,
            "cargo_demand": 87154
        }
    },
    {
        "iata": "MER",
        "icao": "KMER",
        "airportName": "Castle Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Merced",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.38050079",
        "longitude": "-120.5680008",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "11802",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 56171,
            "s_seats": 35600,
            "j_seats": 58414,
            "f_seats": 5348,
            "r_seats": 8652,
            "cargo_demand": 285558
        }
    },
    {
        "iata": "MES",
        "icao": "WIMK",
        "airportName": "Soewondo Air Force Base",
        "location_country": "Indonesia",
        "location_city": "Medan",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.559167",
        "longitude": "98.671111",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9514",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101023,
            "s_seats": 28046,
            "j_seats": 54158,
            "f_seats": 8636,
            "r_seats": 5773,
            "cargo_demand": 371003
        }
    },
    {
        "iata": "MET",
        "icao": "YMOT",
        "airportName": "Moreton Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Moreton",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.44419956",
        "longitude": "142.6380005",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 1622,
            "s_seats": 1608,
            "j_seats": 1716,
            "f_seats": 198,
            "r_seats": 31,
            "cargo_demand": 14035
        }
    },
    {
        "iata": "MEU",
        "icao": "SBMD",
        "airportName": "Serra do Areio Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Monte Dourado",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.889839",
        "longitude": "-52.6022",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66515,
            "s_seats": 45048,
            "j_seats": 54456,
            "f_seats": 19670,
            "r_seats": 4043,
            "cargo_demand": 173218
        }
    },
    {
        "iata": "MEV",
        "icao": "KMEV",
        "airportName": "Minden-Tahoe Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Minden",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.00030136",
        "longitude": "-119.7509995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5300",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "12G/30G",
                "runway_length": "2200",
                "runway_width": "60",
                "runway_material": "DIRT-G"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7400",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1758,
            "s_seats": 2080,
            "j_seats": 1952,
            "f_seats": 233,
            "r_seats": 102,
            "cargo_demand": 3470
        }
    },
    {
        "iata": "MEX",
        "icao": "MMMX",
        "airportName": "Mexico City International Airport",
        "location_country": "Mexico",
        "location_city": "Mexico City",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "large_airport",
        "latitude": "19.4363",
        "longitude": "-99.072098",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "12966",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "12795",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 433557,
            "s_seats": 71697,
            "j_seats": 142015,
            "f_seats": 42085,
            "r_seats": 10000,
            "cargo_demand": 466999
        }
    },
    {
        "iata": "MEY",
        "icao": "VNMG",
        "airportName": "Meghauli Airport",
        "location_country": "Nepal",
        "location_city": "Meghauli",
        "location_state": "",
        "time_zone": "UTC+05:45",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.5774",
        "longitude": "84.22875",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3560",
                "runway_width": "",
                "runway_material": "grass"
            }
        ],
        "demand": {
            "y_seats": 3672,
            "s_seats": 2699,
            "j_seats": 1840,
            "f_seats": 85,
            "r_seats": 124,
            "cargo_demand": 25566
        }
    },
    {
        "iata": "MEZ",
        "icao": "FAMS",
        "airportName": "Messina Airport",
        "location_country": "South Africa",
        "location_city": "Messina",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.7045002",
        "longitude": "26.9090004",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5046",
                "runway_width": "33",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57260,
            "s_seats": 33028,
            "j_seats": 58416,
            "f_seats": 19645,
            "r_seats": 4809,
            "cargo_demand": 329197
        }
    },
    {
        "iata": "MFA",
        "icao": "HTMA",
        "airportName": "Mafia Airport",
        "location_country": "Tanzania",
        "location_city": "Mafia Island",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.917478",
        "longitude": "39.668502",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5348",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2458,
            "s_seats": 2759,
            "j_seats": 1949,
            "f_seats": 240,
            "r_seats": 174,
            "cargo_demand": 1739
        }
    },
    {
        "iata": "MFD",
        "icao": "KMFD",
        "airportName": "Mansfield Lahm Regional Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Mansfield",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.82139969",
        "longitude": "-82.51660156",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6795",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "9001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181986,
            "s_seats": 44629,
            "j_seats": 47075,
            "f_seats": 17245,
            "r_seats": 7913,
            "cargo_demand": 243455
        }
    },
    {
        "iata": "MFE",
        "icao": "KMFE",
        "airportName": "McAllen Miller International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "McAllen",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "26.176141",
        "longitude": "-98.237965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7120",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2638",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79440,
            "s_seats": 41393,
            "j_seats": 10795,
            "f_seats": 16790,
            "r_seats": 6826,
            "cargo_demand": 325478
        }
    },
    {
        "iata": "MFF",
        "icao": "FOOD",
        "airportName": "Moanda Airport",
        "location_country": "Gabon",
        "location_city": "Moanda",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.532999992",
        "longitude": "13.2670002",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5906",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3746,
            "s_seats": 2963,
            "j_seats": 1149,
            "f_seats": 266,
            "r_seats": 111,
            "cargo_demand": 92219
        }
    },
    {
        "iata": "MFG",
        "icao": "OPMF",
        "airportName": "Muzaffarabad Airport",
        "location_country": "Pakistan",
        "location_city": "Muzaffarabad",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.3390007",
        "longitude": "73.50859833",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2960",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94880,
            "s_seats": 38457,
            "j_seats": 33792,
            "f_seats": 19730,
            "r_seats": 8227,
            "cargo_demand": 473468
        }
    },
    {
        "iata": "MFI",
        "icao": "KMFI",
        "airportName": "Marshfield Municipal Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Marshfield",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.63690186",
        "longitude": "-90.18930054",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3600",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2797,
            "s_seats": 2900,
            "j_seats": 1999,
            "f_seats": 268,
            "r_seats": 118,
            "cargo_demand": 75168
        }
    },
    {
        "iata": "MFK",
        "icao": "RCMT",
        "airportName": "Matsu Beigan Airport",
        "location_country": "Taiwan",
        "location_city": "Beigan",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.224199",
        "longitude": "120.002998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3773",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 134113,
            "s_seats": 25561,
            "j_seats": 34219,
            "f_seats": 15786,
            "r_seats": 7379,
            "cargo_demand": 415697
        }
    },
    {
        "iata": "MFM",
        "icao": "VMMC",
        "airportName": "Macau International Airport",
        "location_country": "Macau",
        "location_city": "",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "22.149599",
        "longitude": "113.592003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "10544",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 155650,
            "s_seats": 71854,
            "j_seats": 131248,
            "f_seats": 52887,
            "r_seats": 10000,
            "cargo_demand": 16816534
        }
    },
    {
        "iata": "MFN",
        "icao": "NZMF",
        "airportName": "Milford Sound Airport",
        "location_country": "New Zealand",
        "location_city": "Milford Sound",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "small_airport",
        "latitude": "-44.6733017",
        "longitude": "167.9230042",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2598",
                "runway_width": "69",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 2327,
            "s_seats": 1789,
            "j_seats": 1189,
            "f_seats": 152,
            "r_seats": 21,
            "cargo_demand": 72102
        }
    },
    {
        "iata": "MFP",
        "icao": "YMCR",
        "airportName": "Manners Creek Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Manners Creek Station",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.10000038",
        "longitude": "137.9830017",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4002",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2779,
            "s_seats": 2523,
            "j_seats": 1852,
            "f_seats": 264,
            "r_seats": 60,
            "cargo_demand": 9438
        }
    },
    {
        "iata": "MFQ",
        "icao": "DRRM",
        "airportName": "Maradi Airport",
        "location_country": "Niger",
        "location_city": "Maradi",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.50249958",
        "longitude": "7.126749992",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6070",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 84512,
            "s_seats": 29180,
            "j_seats": 21070,
            "f_seats": 4740,
            "r_seats": 3477,
            "cargo_demand": 58169
        }
    },
    {
        "iata": "MFR",
        "icao": "KMFR",
        "airportName": "Rogue Valley International-Medford Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Medford",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.37419891",
        "longitude": "-122.8730011",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3136",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8800",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112296,
            "s_seats": 34004,
            "j_seats": 6285,
            "f_seats": 14273,
            "r_seats": 6039,
            "cargo_demand": 341582
        }
    },
    {
        "iata": "MFU",
        "icao": "FLMF",
        "airportName": "Mfuwe Airport",
        "location_country": "Zambia",
        "location_city": "Mfuwe",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-13.25889969",
        "longitude": "31.93659973",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7218",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147847,
            "s_seats": 33099,
            "j_seats": 39205,
            "f_seats": 13809,
            "r_seats": 6239,
            "cargo_demand": 316519
        }
    },
    {
        "iata": "MFV",
        "icao": "KMFV",
        "airportName": "Accomack County Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Melfa",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.64690018",
        "longitude": "-75.76110077",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5004",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2608,
            "s_seats": 1886,
            "j_seats": 1474,
            "f_seats": 186,
            "r_seats": 33,
            "cargo_demand": 60004
        }
    },
    {
        "iata": "MGA",
        "icao": "MNMG",
        "airportName": "Augusto C. Sandino International Airport",
        "location_country": "Nicaragua",
        "location_city": "Managua",
        "location_state": "",
        "time_zone": "UTC+06:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.14150047",
        "longitude": "-86.16819763",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8012",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67868,
            "s_seats": 45372,
            "j_seats": 52527,
            "f_seats": 1113,
            "r_seats": 3743,
            "cargo_demand": 224229
        }
    },
    {
        "iata": "MGB",
        "icao": "YMTG",
        "airportName": "Mount Gambier Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Mount Gambier",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-37.74560165",
        "longitude": "140.7850037",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2776",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3025",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82838,
            "s_seats": 25608,
            "j_seats": 40558,
            "f_seats": 19744,
            "r_seats": 9784,
            "cargo_demand": 184534
        }
    },
    {
        "iata": "MGC",
        "icao": "KMGC",
        "airportName": "Michigan City Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Michigan City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.70330048",
        "longitude": "-86.82119751",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4100",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2483,
            "s_seats": 2658,
            "j_seats": 1785,
            "f_seats": 467,
            "r_seats": 163,
            "cargo_demand": 6254
        }
    },
    {
        "iata": "MGD",
        "icao": "SLMG",
        "airportName": "Magdalena Airport",
        "location_country": "Bolivia",
        "location_city": "Magdalena",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.26074838",
        "longitude": "-64.06076431",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4593",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1356,
            "s_seats": 2198,
            "j_seats": 1556,
            "f_seats": 170,
            "r_seats": 8,
            "cargo_demand": 93147
        }
    },
    {
        "iata": "MGE",
        "icao": "KMGE",
        "airportName": "Dobbins Air Reserve Base",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Marietta",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "33.91540146",
        "longitude": "-84.51629639",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10000",
                "runway_width": "300",
                "runway_material": "CON"
            },
            {
                "runway_name": "110/290",
                "runway_length": "4000",
                "runway_width": "60",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 493654,
            "s_seats": 88560,
            "j_seats": 31397,
            "f_seats": 53365,
            "r_seats": 10000,
            "cargo_demand": 11459357
        }
    },
    {
        "iata": "MGF",
        "icao": "SBMG",
        "airportName": "Silvio Name Junior Regional Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Maringa",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-23.47606",
        "longitude": "-52.016187",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77949,
            "s_seats": 32602,
            "j_seats": 23563,
            "f_seats": 12295,
            "r_seats": 3649,
            "cargo_demand": 405966
        }
    },
    {
        "iata": "MGH",
        "icao": "FAMG",
        "airportName": "Margate Airport",
        "location_country": "South Africa",
        "location_city": "Margate",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-30.85740089",
        "longitude": "30.34300041",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4495",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190156,
            "s_seats": 34888,
            "j_seats": 13466,
            "f_seats": 18124,
            "r_seats": 7538,
            "cargo_demand": 90322
        }
    },
    {
        "iata": "MGJ",
        "icao": "KMGJ",
        "airportName": "Orange County Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Montgomery",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.50999832",
        "longitude": "-74.26460266",
        "region": "NA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "5006",
                "runway_width": "100",
                "runway_material": "PEM"
            },
            {
                "runway_name": "8/26",
                "runway_length": "3664",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2905,
            "s_seats": 2995,
            "j_seats": 1336,
            "f_seats": 133,
            "r_seats": 14,
            "cargo_demand": 27402
        }
    },
    {
        "iata": "MGL",
        "icao": "EDLN",
        "airportName": "Dusseldorf Monchengladbach Airport",
        "location_country": "Germany",
        "location_city": "North Rhine-Westphalia",
        "location_state": "Monchengladbach",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "51.230278",
        "longitude": "6.504444",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100073,
            "s_seats": 26752,
            "j_seats": 49785,
            "f_seats": 13548,
            "r_seats": 8591,
            "cargo_demand": 300424
        }
    },
    {
        "iata": "MGM",
        "icao": "KMGM",
        "airportName": "Montgomery Regional Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Montgomery",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "32.30059814",
        "longitude": "-86.39399719",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4010",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "9010",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 323346,
            "s_seats": 72637,
            "j_seats": 127978,
            "f_seats": 56680,
            "r_seats": 10000,
            "cargo_demand": 7757718
        }
    },
    {
        "iata": "MGN",
        "icao": "SKMG",
        "airportName": "Baracoa Regional Airport",
        "location_country": "Colombia",
        "location_city": "Magangue",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.28474",
        "longitude": "-74.8461",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4921",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53967,
            "s_seats": 38493,
            "j_seats": 16834,
            "f_seats": 12278,
            "r_seats": 9749,
            "cargo_demand": 496100
        }
    },
    {
        "iata": "MGQ",
        "icao": "HCMM",
        "airportName": "Aden Adde International Airport",
        "location_country": "Somalia",
        "location_city": "Mogadishu",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.01444006",
        "longitude": "45.30469894",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10446",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103707,
            "s_seats": 46939,
            "j_seats": 36683,
            "f_seats": 10659,
            "r_seats": 8178,
            "cargo_demand": 483385
        }
    },
    {
        "iata": "MGR",
        "icao": "KMGR",
        "airportName": "Moultrie Municipal Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Moultrie",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.0848999",
        "longitude": "-83.80329895",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5129",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3878",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1335,
            "s_seats": 2720,
            "j_seats": 1749,
            "f_seats": 244,
            "r_seats": 194,
            "cargo_demand": 53488
        }
    },
    {
        "iata": "MGS",
        "icao": "NCMG",
        "airportName": "Mangaia Airport",
        "location_country": "Cook Islands",
        "location_city": "Mangaia Island",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-21.89598656",
        "longitude": "-157.906662",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3274",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2505,
            "s_seats": 2207,
            "j_seats": 1058,
            "f_seats": 37,
            "r_seats": 40,
            "cargo_demand": 65415
        }
    },
    {
        "iata": "MGT",
        "icao": "YMGB",
        "airportName": "Milingimbi Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Milingimbi Island",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.09440041",
        "longitude": "134.8939972",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4626",
                "runway_width": "60",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 1828,
            "s_seats": 2393,
            "j_seats": 1488,
            "f_seats": 220,
            "r_seats": 15,
            "cargo_demand": 83219
        }
    },
    {
        "iata": "MGV",
        "icao": "YMGR",
        "airportName": "Margaret River Station Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Margaret River Station",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.62170029",
        "longitude": "126.8830032",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5006",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 2807,
            "s_seats": 2494,
            "j_seats": 1999,
            "f_seats": 396,
            "r_seats": 31,
            "cargo_demand": 74638
        }
    },
    {
        "iata": "MGW",
        "icao": "KMGW",
        "airportName": "Morgantown Municipal Airport",
        "location_country": "United States",
        "location_city": "West Virginia",
        "location_state": "Morgantown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.64289856",
        "longitude": "-79.91629791",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2769",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5199",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103216,
            "s_seats": 37481,
            "j_seats": 14017,
            "f_seats": 13326,
            "r_seats": 6290,
            "cargo_demand": 200774
        }
    },
    {
        "iata": "MGY",
        "icao": "KMGY",
        "airportName": "Dayton-Wright Brothers Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Dayton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.5890007",
        "longitude": "-84.22489929",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3744,
            "s_seats": 2945,
            "j_seats": 1289,
            "f_seats": 171,
            "r_seats": 107,
            "cargo_demand": 1069
        }
    },
    {
        "iata": "MGZ",
        "icao": "VYME",
        "airportName": "Myeik Airport",
        "location_country": "Myanmar",
        "location_city": "Myeik",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.43980026",
        "longitude": "98.62149811",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8795",
                "runway_width": "180",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89263,
            "s_seats": 30009,
            "j_seats": 38660,
            "f_seats": 19515,
            "r_seats": 6222,
            "cargo_demand": 205850
        }
    },
    {
        "iata": "MHC",
        "icao": "SCPQ",
        "airportName": "Mocopulli Airport",
        "location_country": "Chile",
        "location_city": "Castro",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "Aug-May",
        "airport_size": "small_airport",
        "latitude": "-42.340388",
        "longitude": "-73.715693",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6600",
                "runway_width": "148",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 3228,
            "s_seats": 1601,
            "j_seats": 1358,
            "f_seats": 222,
            "r_seats": 136,
            "cargo_demand": 33186
        }
    },
    {
        "iata": "MHD",
        "icao": "OIMM",
        "airportName": "Mashhad International Airport",
        "location_country": "Iran",
        "location_city": "Mashhad",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "large_airport",
        "latitude": "36.23519897",
        "longitude": "59.64099884",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "12503",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "12877",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184389,
            "s_seats": 86345,
            "j_seats": 92782,
            "f_seats": 59325,
            "r_seats": 10000,
            "cargo_demand": 23131665
        }
    },
    {
        "iata": "MHE",
        "icao": "KMHE",
        "airportName": "Mitchell Municipal Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Mitchell",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.77479935",
        "longitude": "-98.03859711",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6700",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5512",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1161,
            "s_seats": 2057,
            "j_seats": 1072,
            "f_seats": 263,
            "r_seats": 73,
            "cargo_demand": 34647
        }
    },
    {
        "iata": "MHG",
        "icao": "EDFM",
        "airportName": "Mannheim City Airport",
        "location_country": "Germany",
        "location_city": "Baden-Wurttemberg",
        "location_state": "Mannheim",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "49.473057",
        "longitude": "8.514167",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3497",
                "runway_width": "82",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "2297",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 104834,
            "s_seats": 43019,
            "j_seats": 7137,
            "f_seats": 14645,
            "r_seats": 3688,
            "cargo_demand": 336535
        }
    },
    {
        "iata": "MHH",
        "icao": "MYAM",
        "airportName": "Marsh Harbour Airport",
        "location_country": "Bahamas",
        "location_city": "Abaco Islands",
        "location_state": "Marsh Harbour",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "26.5114",
        "longitude": "-77.083503",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4998",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94899,
            "s_seats": 32844,
            "j_seats": 41330,
            "f_seats": 4408,
            "r_seats": 4568,
            "cargo_demand": 253001
        }
    },
    {
        "iata": "MHK",
        "icao": "KMHK",
        "airportName": "Manhattan Regional Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Manhattan",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.14099884",
        "longitude": "-96.67079926",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3800",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 195602,
            "s_seats": 25026,
            "j_seats": 31890,
            "f_seats": 15737,
            "r_seats": 7109,
            "cargo_demand": 73271
        }
    },
    {
        "iata": "MHL",
        "icao": "KMHL",
        "airportName": "Marshall Memorial Municipal Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Marshall",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.09579849",
        "longitude": "-93.20290375",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3320",
                "runway_width": "150",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4701",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3027,
            "s_seats": 2800,
            "j_seats": 1669,
            "f_seats": 144,
            "r_seats": 139,
            "cargo_demand": 50240
        }
    },
    {
        "iata": "MHO",
        "icao": "YMHO",
        "airportName": "Mount House Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Mount House Station",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.05500031",
        "longitude": "125.7099991",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3815",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2007,
            "s_seats": 2219,
            "j_seats": 1634,
            "f_seats": 266,
            "r_seats": 59,
            "cargo_demand": 2015
        }
    },
    {
        "iata": "MHQ",
        "icao": "EFMA",
        "airportName": "Mariehamn Airport",
        "location_country": "Finland",
        "location_city": "\u00c5land Islands",
        "location_state": "Mariehamn",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "60.1222",
        "longitude": "19.898199",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6243",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55133,
            "s_seats": 40249,
            "j_seats": 14029,
            "f_seats": 10753,
            "r_seats": 8542,
            "cargo_demand": 234505
        }
    },
    {
        "iata": "MHR",
        "icao": "KMHR",
        "airportName": "Sacramento Mather Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Sacramento",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.553902",
        "longitude": "-121.297997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "6040",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "11301",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 62149,
            "s_seats": 45228,
            "j_seats": 39936,
            "f_seats": 14631,
            "r_seats": 3068,
            "cargo_demand": 264506
        }
    },
    {
        "iata": "MHT",
        "icao": "KMHT",
        "airportName": "Manchester-Boston Regional Airport",
        "location_country": "United States",
        "location_city": "New Hampshire",
        "location_state": "Manchester",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.932598",
        "longitude": "-71.435699",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6850",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "9250",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103529,
            "s_seats": 41851,
            "j_seats": 28277,
            "f_seats": 17494,
            "r_seats": 7541,
            "cargo_demand": 84557
        }
    },
    {
        "iata": "MHU",
        "icao": "YHOT",
        "airportName": "Mount Hotham Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Mount Hotham",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-37.04750061",
        "longitude": "147.3339996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4762",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159221,
            "s_seats": 39416,
            "j_seats": 51204,
            "f_seats": 3827,
            "r_seats": 6264,
            "cargo_demand": 319289
        }
    },
    {
        "iata": "MHV",
        "icao": "KMHV",
        "airportName": "Mojave Air and Space Port",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Mojave",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.05939865",
        "longitude": "-118.1520004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4743",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "7050",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "9502",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 3196,
            "s_seats": 2869,
            "j_seats": 1121,
            "f_seats": 279,
            "r_seats": 45,
            "cargo_demand": 27447
        }
    },
    {
        "iata": "MHX",
        "icao": "NCMH",
        "airportName": "Manihiki Island Airport",
        "location_country": "Cook Islands",
        "location_city": "Manihiki",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.3767004",
        "longitude": "-161.0019989",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3127,
            "s_seats": 2634,
            "j_seats": 1249,
            "f_seats": 175,
            "r_seats": 56,
            "cargo_demand": 9442
        }
    },
    {
        "iata": "MHZ",
        "icao": "EGUN",
        "airportName": "RAF Mildenhall",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Mildenhall",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "52.3619",
        "longitude": "0.486406",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9221",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 153929,
            "s_seats": 28685,
            "j_seats": 41663,
            "f_seats": 6328,
            "r_seats": 8013,
            "cargo_demand": 205960
        }
    },
    {
        "iata": "MIA",
        "icao": "KMIA",
        "airportName": "Miami International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Miami",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "25.79319954",
        "longitude": "-80.29060364",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "8600",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "10506",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "13000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "9354",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115830,
            "s_seats": 81515,
            "j_seats": 29471,
            "f_seats": 38966,
            "r_seats": 10000,
            "cargo_demand": 25524173
        }
    },
    {
        "iata": "MIB",
        "icao": "KMIB",
        "airportName": "Minot Air Force Base",
        "location_country": "United States",
        "location_city": "North Dakota",
        "location_state": "Minot",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "48.4156",
        "longitude": "-101.358002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "13197",
                "runway_width": "300",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 178925,
            "s_seats": 29411,
            "j_seats": 14593,
            "f_seats": 18461,
            "r_seats": 9243,
            "cargo_demand": 226051
        }
    },
    {
        "iata": "MIC",
        "icao": "KMIC",
        "airportName": "Crystal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Minneapolis / St Paul",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.06200027",
        "longitude": "-93.35389709",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "2499",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "1669",
                "runway_width": "137",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "3263",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "3266",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3871,
            "s_seats": 2101,
            "j_seats": 1908,
            "f_seats": 381,
            "r_seats": 75,
            "cargo_demand": 65850
        }
    },
    {
        "iata": "MID",
        "icao": "MMMD",
        "airportName": "Manuel Crescencio Rejon International Airport",
        "location_country": "Mexico",
        "location_city": "Yucatan",
        "location_state": "Merida",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "20.93700027",
        "longitude": "-89.65769959",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10499",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7546",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137555,
            "s_seats": 36055,
            "j_seats": 51631,
            "f_seats": 19762,
            "r_seats": 8528,
            "cargo_demand": 183063
        }
    },
    {
        "iata": "MIE",
        "icao": "KMIE",
        "airportName": "Delaware County Regional Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Muncie",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.24229813",
        "longitude": "-85.39589691",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4998",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 119869,
            "s_seats": 37095,
            "j_seats": 51790,
            "f_seats": 10085,
            "r_seats": 3946,
            "cargo_demand": 342570
        }
    },
    {
        "iata": "MIH",
        "icao": "YMIP",
        "airportName": "Mitchell Plateau Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Mitchell Plateau",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.79139996",
        "longitude": "125.8239975",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4635",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5157",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1457,
            "s_seats": 1604,
            "j_seats": 1760,
            "f_seats": 116,
            "r_seats": 19,
            "cargo_demand": 61829
        }
    },
    {
        "iata": "MII",
        "icao": "SBML",
        "airportName": "Frank Miloye Milenkowichi-Marilia State Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Marilia",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-22.19689941",
        "longitude": "-49.92639923",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "4921",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100952,
            "s_seats": 29821,
            "j_seats": 24970,
            "f_seats": 8682,
            "r_seats": 5938,
            "cargo_demand": 211936
        }
    },
    {
        "iata": "MIK",
        "icao": "EFMI",
        "airportName": "Mikkeli Airport",
        "location_country": "Finland",
        "location_city": "Mikkeli",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "61.6866",
        "longitude": "27.201799",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5584",
                "runway_width": "144",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173047,
            "s_seats": 43484,
            "j_seats": 28778,
            "f_seats": 2227,
            "r_seats": 6154,
            "cargo_demand": 248911
        }
    },
    {
        "iata": "MIM",
        "icao": "YMER",
        "airportName": "Merimbula Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Merimbula",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-36.90859985",
        "longitude": "149.901001",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5256",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 98581,
            "s_seats": 29807,
            "j_seats": 20850,
            "f_seats": 16811,
            "r_seats": 9020,
            "cargo_demand": 125917
        }
    },
    {
        "iata": "MIN",
        "icao": "YMPA",
        "airportName": "Minnipa Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Minnipa",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-32.84329987",
        "longitude": "135.1450043",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4475",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1629,
            "s_seats": 1886,
            "j_seats": 1876,
            "f_seats": 137,
            "r_seats": 155,
            "cargo_demand": 46967
        }
    },
    {
        "iata": "MIO",
        "icao": "KMIO",
        "airportName": "Miami Municipal Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Miami",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.909199",
        "longitude": "-94.887497",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5020",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3764,
            "s_seats": 2720,
            "j_seats": 1380,
            "f_seats": 262,
            "r_seats": 153,
            "cargo_demand": 43469
        }
    },
    {
        "iata": "MIQ",
        "icao": "KMLE",
        "airportName": "Millard Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Omaha",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.19599915",
        "longitude": "-96.11199951",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3801",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2934,
            "s_seats": 2837,
            "j_seats": 1780,
            "f_seats": 452,
            "r_seats": 48,
            "cargo_demand": 15707
        }
    },
    {
        "iata": "MIR",
        "icao": "DTMB",
        "airportName": "Monastir Habib Bourguiba International Airport",
        "location_country": "Tunisia",
        "location_city": "Monastir",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.7580986",
        "longitude": "10.75469971",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9678",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190455,
            "s_seats": 33389,
            "j_seats": 48612,
            "f_seats": 2475,
            "r_seats": 6468,
            "cargo_demand": 459960
        }
    },
    {
        "iata": "MIS",
        "icao": "AYMS",
        "airportName": "Misima Island Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Misima Island",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.6892004",
        "longitude": "152.8379974",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2631,
            "s_seats": 2931,
            "j_seats": 1880,
            "f_seats": 214,
            "r_seats": 196,
            "cargo_demand": 57912
        }
    },
    {
        "iata": "MIT",
        "icao": "KMIT",
        "airportName": "Shafter Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Shafter",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.507401",
        "longitude": "-119.192002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4501",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2972",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1509,
            "s_seats": 2695,
            "j_seats": 1198,
            "f_seats": 26,
            "r_seats": 196,
            "cargo_demand": 58156
        }
    },
    {
        "iata": "MIU",
        "icao": "DNMA",
        "airportName": "Maiduguri International Airport",
        "location_country": "Nigeria",
        "location_city": "Maiduguri",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.85529995",
        "longitude": "13.08090019",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9846",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147028,
            "s_seats": 44315,
            "j_seats": 10376,
            "f_seats": 17612,
            "r_seats": 3431,
            "cargo_demand": 100866
        }
    },
    {
        "iata": "MIV",
        "icao": "KMIV",
        "airportName": "Millville Municipal Airport",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Millville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.367802",
        "longitude": "-75.072197",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5057",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 82813,
            "s_seats": 34933,
            "j_seats": 21312,
            "f_seats": 3419,
            "r_seats": 5830,
            "cargo_demand": 435320
        }
    },
    {
        "iata": "MIW",
        "icao": "KMIW",
        "airportName": "Marshalltown Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Marshalltown",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.11270142",
        "longitude": "-92.9178009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5006",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2806",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1766,
            "s_seats": 1641,
            "j_seats": 1906,
            "f_seats": 167,
            "r_seats": 29,
            "cargo_demand": 28781
        }
    },
    {
        "iata": "MJC",
        "icao": "DIMN",
        "airportName": "Man Airport",
        "location_country": "Ivory Coast",
        "location_city": "Man",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.272069931",
        "longitude": "-7.587359905",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6726",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 192487,
            "s_seats": 49379,
            "j_seats": 41600,
            "f_seats": 4098,
            "r_seats": 7412,
            "cargo_demand": 477145
        }
    },
    {
        "iata": "MJD",
        "icao": "OPMJ",
        "airportName": "Moenjodaro Airport",
        "location_country": "Pakistan",
        "location_city": "Mohenjo-daro",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.33519936",
        "longitude": "68.14309692",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6512",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90329,
            "s_seats": 42471,
            "j_seats": 35892,
            "f_seats": 16869,
            "r_seats": 4449,
            "cargo_demand": 320378
        }
    },
    {
        "iata": "MJF",
        "icao": "ENMS",
        "airportName": "Mosjoen Airport",
        "location_country": "Norway",
        "location_city": "Mosjoen",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "65.783997",
        "longitude": "13.2149",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3015",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117709,
            "s_seats": 38190,
            "j_seats": 15798,
            "f_seats": 5102,
            "r_seats": 6518,
            "cargo_demand": 316443
        }
    },
    {
        "iata": "MJI",
        "icao": "HLLM",
        "airportName": "Mitiga International Airport",
        "location_country": "Libya",
        "location_city": "Tripoli",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.89410019",
        "longitude": "13.27600002",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6000",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 93001,
            "s_seats": 37371,
            "j_seats": 6834,
            "f_seats": 7197,
            "r_seats": 5975,
            "cargo_demand": 211205
        }
    },
    {
        "iata": "MJK",
        "icao": "YSHK",
        "airportName": "Shark Bay Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Monkey Mia",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.89389992",
        "longitude": "113.5770035",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5545",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53580,
            "s_seats": 32261,
            "j_seats": 21509,
            "f_seats": 18493,
            "r_seats": 4779,
            "cargo_demand": 438805
        }
    },
    {
        "iata": "MJL",
        "icao": "FOGM",
        "airportName": "Mouila Airport",
        "location_country": "Gabon",
        "location_city": "Mouila",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.84513998",
        "longitude": "11.05669975",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5891",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143591,
            "s_seats": 45789,
            "j_seats": 58886,
            "f_seats": 4481,
            "r_seats": 8016,
            "cargo_demand": 262622
        }
    },
    {
        "iata": "MJM",
        "icao": "FZWA",
        "airportName": "Mbuji Mayi Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Mbuji-Mayi",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.121240139",
        "longitude": "23.56900024",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6558",
                "runway_width": "146",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163053,
            "s_seats": 35924,
            "j_seats": 16038,
            "f_seats": 8973,
            "r_seats": 6074,
            "cargo_demand": 382970
        }
    },
    {
        "iata": "MJN",
        "icao": "FMNM",
        "airportName": "Amborovy Airport",
        "location_country": "Madagascar",
        "location_city": "Mahajanga",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.666842",
        "longitude": "46.351233",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116406,
            "s_seats": 30324,
            "j_seats": 14788,
            "f_seats": 10287,
            "r_seats": 9062,
            "cargo_demand": 79849
        }
    },
    {
        "iata": "MJP",
        "icao": "YMJM",
        "airportName": "Manjimup Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Manjimup",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-34.26530075",
        "longitude": "116.1399994",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4016",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2249,
            "s_seats": 1623,
            "j_seats": 1218,
            "f_seats": 51,
            "r_seats": 52,
            "cargo_demand": 54291
        }
    },
    {
        "iata": "MJQ",
        "icao": "KMJQ",
        "airportName": "Jackson Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Jackson",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.65000153",
        "longitude": "-94.9865036",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2250",
                "runway_width": "300",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3591",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3578,
            "s_seats": 2544,
            "j_seats": 1393,
            "f_seats": 169,
            "r_seats": 141,
            "cargo_demand": 71166
        }
    },
    {
        "iata": "MJT",
        "icao": "LGMT",
        "airportName": "Mytilene International Airport",
        "location_country": "Greece",
        "location_city": "Lesbos",
        "location_state": "Mytilene",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "39.05670166",
        "longitude": "26.59830093",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7894",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183625,
            "s_seats": 33307,
            "j_seats": 36714,
            "f_seats": 12453,
            "r_seats": 7474,
            "cargo_demand": 281489
        }
    },
    {
        "iata": "MJU",
        "icao": "WAAJ",
        "airportName": "Tampa Padang Airport",
        "location_country": "Indonesia",
        "location_city": "Mamuju",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.583333",
        "longitude": "119.033333",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6398",
                "runway_width": "30",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2261,
            "s_seats": 2203,
            "j_seats": 1202,
            "f_seats": 45,
            "r_seats": 54,
            "cargo_demand": 20682
        }
    },
    {
        "iata": "MJV",
        "icao": "LELC",
        "airportName": "Murcia-San Javier Airport",
        "location_country": "Spain",
        "location_city": "Region of Murcia",
        "location_state": "Murcia",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "37.775002",
        "longitude": "-0.812389",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "5174",
                "runway_width": "148",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "7612",
                "runway_width": "148",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2625",
                "runway_width": "197",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 118889,
            "s_seats": 41763,
            "j_seats": 42303,
            "f_seats": 6530,
            "r_seats": 5310,
            "cargo_demand": 490291
        }
    },
    {
        "iata": "MJX",
        "icao": "KMJX",
        "airportName": "Robert J. Miller Air Park",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Toms River",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.92750168",
        "longitude": "-74.29239655",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5949",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3599",
                "runway_width": "75",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 3992,
            "s_seats": 2872,
            "j_seats": 1829,
            "f_seats": 398,
            "r_seats": 184,
            "cargo_demand": 64204
        }
    },
    {
        "iata": "MJZ",
        "icao": "UERR",
        "airportName": "Mirny Airport",
        "location_country": "Russia",
        "location_city": "Yakutia",
        "location_state": "Mirny",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.53469849",
        "longitude": "114.0390015",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9187",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 90268,
            "s_seats": 28986,
            "j_seats": 50112,
            "f_seats": 12291,
            "r_seats": 3368,
            "cargo_demand": 66912
        }
    },
    {
        "iata": "MKA",
        "icao": "LKMR",
        "airportName": "Marianske Lazn\u011b Airport",
        "location_country": "Czech Republic",
        "location_city": "Marianske Lazn\u011b",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "49.92279816",
        "longitude": "12.72469997",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2608",
                "runway_width": "164",
                "runway_material": "UNK"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "3396",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1988,
            "s_seats": 1950,
            "j_seats": 1802,
            "f_seats": 492,
            "r_seats": 42,
            "cargo_demand": 13753
        }
    },
    {
        "iata": "MKC",
        "icao": "KMKC",
        "airportName": "Charles B. Wheeler Downtown Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Kansas City",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.12319946",
        "longitude": "-94.59279633",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7002",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "03/21",
                "runway_length": "5050",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196774,
            "s_seats": 26773,
            "j_seats": 45874,
            "f_seats": 11654,
            "r_seats": 9356,
            "cargo_demand": 207605
        }
    },
    {
        "iata": "MKE",
        "icao": "KMKE",
        "airportName": "Milwaukee Mitchell International Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Milwaukee",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "42.94720078",
        "longitude": "-87.89659882",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "9690",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "4183",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "4800",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "8012",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5868",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 367775,
            "s_seats": 70183,
            "j_seats": 53438,
            "f_seats": 36763,
            "r_seats": 10000,
            "cargo_demand": 14551543
        }
    },
    {
        "iata": "MKG",
        "icao": "KMKG",
        "airportName": "Muskegon County Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Muskegon",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.169498",
        "longitude": "-86.238197",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6501",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62044,
            "s_seats": 37287,
            "j_seats": 43835,
            "f_seats": 2624,
            "r_seats": 8783,
            "cargo_demand": 122007
        }
    },
    {
        "iata": "MKJ",
        "icao": "FCOM",
        "airportName": "Makoua Airport",
        "location_country": "Republic of the Congo",
        "location_city": "Makoua",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.017000001",
        "longitude": "15.58300018",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5906",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3100,
            "s_seats": 2475,
            "j_seats": 1241,
            "f_seats": 63,
            "r_seats": 108,
            "cargo_demand": 47774
        }
    },
    {
        "iata": "MKK",
        "icao": "PHMK",
        "airportName": "Molokai Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Kaunakakai",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.1529007",
        "longitude": "-157.095993",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4494",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3118",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193015,
            "s_seats": 37264,
            "j_seats": 35128,
            "f_seats": 12120,
            "r_seats": 5924,
            "cargo_demand": 392320
        }
    },
    {
        "iata": "MKL",
        "icao": "KMKL",
        "airportName": "McKellar-Sipes Regional Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Jackson",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "35.599899",
        "longitude": "-88.915604",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6006",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3539",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149129,
            "s_seats": 37604,
            "j_seats": 5319,
            "f_seats": 6813,
            "r_seats": 5142,
            "cargo_demand": 116687
        }
    },
    {
        "iata": "MKM",
        "icao": "WBGK",
        "airportName": "Mukah Airport",
        "location_country": "Malaysia",
        "location_city": "Sarawak",
        "location_state": "Mukah",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "2.90639",
        "longitude": "112.080002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3599",
                "runway_width": "75",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1575,
            "s_seats": 2364,
            "j_seats": 1002,
            "f_seats": 335,
            "r_seats": 150,
            "cargo_demand": 92101
        }
    },
    {
        "iata": "MKO",
        "icao": "KMKO",
        "airportName": "Davis Field",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Muskogee",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.656502",
        "longitude": "-95.366699",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4500",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7201",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1900",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1778,
            "s_seats": 1529,
            "j_seats": 1567,
            "f_seats": 462,
            "r_seats": 196,
            "cargo_demand": 83710
        }
    },
    {
        "iata": "MKP",
        "icao": "NTGM",
        "airportName": "Makemo Airport",
        "location_country": "French Polynesia",
        "location_city": "Makemo",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.58390045",
        "longitude": "-143.6580048",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4920",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140195,
            "s_seats": 33114,
            "j_seats": 29891,
            "f_seats": 19063,
            "r_seats": 9937,
            "cargo_demand": 98340
        }
    },
    {
        "iata": "MKQ",
        "icao": "WAKK",
        "airportName": "Mopah Airport",
        "location_country": "Indonesia",
        "location_city": "Merauke",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.520290375",
        "longitude": "140.4179993",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6070",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124101,
            "s_seats": 42112,
            "j_seats": 17991,
            "f_seats": 5040,
            "r_seats": 3241,
            "cargo_demand": 332447
        }
    },
    {
        "iata": "MKR",
        "icao": "YMEK",
        "airportName": "Meekatharra Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Meekatharra",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.61170006",
        "longitude": "118.5479965",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7156",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3494",
                "runway_width": "98",
                "runway_material": "SAN"
            }
        ],
        "demand": {
            "y_seats": 117300,
            "s_seats": 29252,
            "j_seats": 30596,
            "f_seats": 3213,
            "r_seats": 6313,
            "cargo_demand": 469300
        }
    },
    {
        "iata": "MKT",
        "icao": "KMKT",
        "airportName": "Mankato Regional Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Mankato",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.22159958",
        "longitude": "-93.91870117",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3999",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5400",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2038,
            "s_seats": 2827,
            "j_seats": 1643,
            "f_seats": 108,
            "r_seats": 55,
            "cargo_demand": 2019
        }
    },
    {
        "iata": "MKU",
        "icao": "FOOK",
        "airportName": "Makokou Airport",
        "location_country": "Gabon",
        "location_city": "Makokou",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.579210997",
        "longitude": "12.89089966",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5892",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59577,
            "s_seats": 31741,
            "j_seats": 18180,
            "f_seats": 9370,
            "r_seats": 4336,
            "cargo_demand": 472985
        }
    },
    {
        "iata": "MKV",
        "icao": "YMVG",
        "airportName": "Mount Cavenagh Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Mount Cavenagh",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.9666996",
        "longitude": "133.1999969",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "2296",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 3902,
            "s_seats": 2247,
            "j_seats": 1825,
            "f_seats": 301,
            "r_seats": 91,
            "cargo_demand": 82173
        }
    },
    {
        "iata": "MKW",
        "icao": "WASR",
        "airportName": "Rendani Airport",
        "location_country": "Indonesia",
        "location_city": "Manokwari",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.891833",
        "longitude": "134.048996",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178276,
            "s_seats": 48471,
            "j_seats": 43212,
            "f_seats": 13503,
            "r_seats": 3470,
            "cargo_demand": 76826
        }
    },
    {
        "iata": "MKY",
        "icao": "YBMK",
        "airportName": "Mackay Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Mackay",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.17169952",
        "longitude": "149.1799927",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4409",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165567,
            "s_seats": 47669,
            "j_seats": 58449,
            "f_seats": 3559,
            "r_seats": 3432,
            "cargo_demand": 458780
        }
    },
    {
        "iata": "MKZ",
        "icao": "WMKM",
        "airportName": "Malacca International Airport",
        "location_country": "Malaysia",
        "location_city": "Malacca",
        "location_state": "Malacca City",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.263360024",
        "longitude": "102.2519989",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7005",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79852,
            "s_seats": 40405,
            "j_seats": 33023,
            "f_seats": 4892,
            "r_seats": 4175,
            "cargo_demand": 79891
        }
    },
    {
        "iata": "MLA",
        "icao": "LMML",
        "airportName": "Malta International Airport",
        "location_country": "Malta",
        "location_city": "Luqa",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "35.857498",
        "longitude": "14.4775",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7799",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "11627",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 468624,
            "s_seats": 75732,
            "j_seats": 30123,
            "f_seats": 48858,
            "r_seats": 10000,
            "cargo_demand": 7300645
        }
    },
    {
        "iata": "MLB",
        "icao": "KMLB",
        "airportName": "Orlando Melbourne International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Melbourne",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "28.10280037",
        "longitude": "-80.64530182",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3001",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "10181",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148318,
            "s_seats": 39153,
            "j_seats": 30806,
            "f_seats": 4101,
            "r_seats": 6288,
            "cargo_demand": 140412
        }
    },
    {
        "iata": "MLC",
        "icao": "KMLC",
        "airportName": "McAlester Regional Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "McAlester",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.882401",
        "longitude": "-95.783501",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5602",
                "runway_width": "100",
                "runway_material": "CONC-F"
            }
        ],
        "demand": {
            "y_seats": 97987,
            "s_seats": 38793,
            "j_seats": 20440,
            "f_seats": 8605,
            "r_seats": 9480,
            "cargo_demand": 83591
        }
    },
    {
        "iata": "MLD",
        "icao": "KMLD",
        "airportName": "Malad City Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Malad City",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.16659927",
        "longitude": "-112.2969971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4950",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3596,
            "s_seats": 1511,
            "j_seats": 1872,
            "f_seats": 426,
            "r_seats": 10,
            "cargo_demand": 638
        }
    },
    {
        "iata": "MLE",
        "icao": "VRMM",
        "airportName": "Ibrahim Nasir International Airport",
        "location_country": "Maldives",
        "location_city": "Male",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "4.191830158",
        "longitude": "73.52909851",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 357774,
            "s_seats": 78424,
            "j_seats": 149597,
            "f_seats": 57965,
            "r_seats": 10000,
            "cargo_demand": 5765242
        }
    },
    {
        "iata": "MLF",
        "icao": "KMLF",
        "airportName": "Milford Municipal Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Milford",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.42660141",
        "longitude": "-113.012001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3997,
            "s_seats": 1890,
            "j_seats": 1807,
            "f_seats": 130,
            "r_seats": 168,
            "cargo_demand": 89217
        }
    },
    {
        "iata": "MLG",
        "icao": "WARA",
        "airportName": "Abdul Rachman Saleh Airport",
        "location_country": "Indonesia",
        "location_city": "Malang",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.92656",
        "longitude": "112.714996",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6464",
                "runway_width": "131",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3449,
            "s_seats": 1701,
            "j_seats": 1962,
            "f_seats": 490,
            "r_seats": 51,
            "cargo_demand": 97218
        }
    },
    {
        "iata": "MLH",
        "icao": "LFSB",
        "airportName": "EuroAirport Basel Mulhouse Freiburg",
        "location_country": "France",
        "location_city": "Alsace",
        "location_state": "Mulhouse",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "47.59",
        "longitude": "7.529167",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5969",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "15/33",
                "runway_length": "12795",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 237368,
            "s_seats": 84156,
            "j_seats": 137155,
            "f_seats": 65207,
            "r_seats": 10000,
            "cargo_demand": 17388392
        }
    },
    {
        "iata": "MLI",
        "icao": "KMLI",
        "airportName": "Quad Cities International Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Moline",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "41.44850159",
        "longitude": "-90.50749969",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4509",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "10002",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7001",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 232973,
            "s_seats": 81285,
            "j_seats": 98092,
            "f_seats": 42159,
            "r_seats": 10000,
            "cargo_demand": 26953087
        }
    },
    {
        "iata": "MLJ",
        "icao": "KMLJ",
        "airportName": "Baldwin County Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Milledgeville",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.154202",
        "longitude": "-83.240701",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3219,
            "s_seats": 1947,
            "j_seats": 1524,
            "f_seats": 19,
            "r_seats": 195,
            "cargo_demand": 51142
        }
    },
    {
        "iata": "MLL",
        "icao": "PADM",
        "airportName": "Marshall Don Hunter Sr. Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Marshall",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "61.86429977",
        "longitude": "-162.026001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3201",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 198741,
            "s_seats": 42577,
            "j_seats": 6437,
            "f_seats": 12605,
            "r_seats": 8637,
            "cargo_demand": 239601
        }
    },
    {
        "iata": "MLM",
        "icao": "MMMM",
        "airportName": "General Francisco J. Mujica International Airport",
        "location_country": "Mexico",
        "location_city": "Michoacan",
        "location_state": "Morelia",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "19.84989929",
        "longitude": "-101.0250015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76683,
            "s_seats": 46029,
            "j_seats": 43929,
            "f_seats": 15076,
            "r_seats": 9677,
            "cargo_demand": 465155
        }
    },
    {
        "iata": "MLN",
        "icao": "GEML",
        "airportName": "Melilla Airport",
        "location_country": "Spain",
        "location_city": "Melilla",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "35.27980042",
        "longitude": "-2.956259966",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4685",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177581,
            "s_seats": 36811,
            "j_seats": 48536,
            "f_seats": 7694,
            "r_seats": 5653,
            "cargo_demand": 237458
        }
    },
    {
        "iata": "MLO",
        "icao": "LGML",
        "airportName": "Milos Island National Airport",
        "location_country": "Greece",
        "location_city": "Milos",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "36.696899",
        "longitude": "24.4769",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3527",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3179,
            "s_seats": 1637,
            "j_seats": 1170,
            "f_seats": 18,
            "r_seats": 122,
            "cargo_demand": 20916
        }
    },
    {
        "iata": "MLR",
        "icao": "YMCT",
        "airportName": "Millicent Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Millicent",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-37.58359909",
        "longitude": "140.3659973",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3937",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2674,
            "s_seats": 2142,
            "j_seats": 1772,
            "f_seats": 392,
            "r_seats": 142,
            "cargo_demand": 32430
        }
    },
    {
        "iata": "MLS",
        "icao": "KMLS",
        "airportName": "Miles City Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Miles City",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.4280014",
        "longitude": "-105.8860016",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5680",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5624",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178988,
            "s_seats": 28504,
            "j_seats": 43716,
            "f_seats": 4544,
            "r_seats": 5526,
            "cargo_demand": 472559
        }
    },
    {
        "iata": "MLT",
        "icao": "KMLT",
        "airportName": "Millinocket Municipal Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Millinocket",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.64780045",
        "longitude": "-68.68560028",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4713",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4008",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2803,
            "s_seats": 2878,
            "j_seats": 1542,
            "f_seats": 492,
            "r_seats": 90,
            "cargo_demand": 63246
        }
    },
    {
        "iata": "MLU",
        "icao": "KMLU",
        "airportName": "Monroe Regional Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Monroe",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "32.51089859",
        "longitude": "-92.03769684",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7507",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 474718,
            "s_seats": 75843,
            "j_seats": 31520,
            "f_seats": 60838,
            "r_seats": 10000,
            "cargo_demand": 22081980
        }
    },
    {
        "iata": "MLV",
        "icao": "YMEU",
        "airportName": "Merluna Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Merluna",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.0649",
        "longitude": "142.4536",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1693,
            "s_seats": 1759,
            "j_seats": 1551,
            "f_seats": 392,
            "r_seats": 7,
            "cargo_demand": 11312
        }
    },
    {
        "iata": "MLW",
        "icao": "GLMR",
        "airportName": "Spriggs Payne Airport",
        "location_country": "Liberia",
        "location_city": "Monrovia",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.289060116",
        "longitude": "-10.75870037",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54471,
            "s_seats": 30851,
            "j_seats": 37789,
            "f_seats": 1174,
            "r_seats": 9329,
            "cargo_demand": 313875
        }
    },
    {
        "iata": "MLX",
        "icao": "LTAT",
        "airportName": "Malatya ErhaiC Airport",
        "location_country": "Turkey",
        "location_city": "Malatya",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.43529892",
        "longitude": "38.0909996",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10990",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104765,
            "s_seats": 37609,
            "j_seats": 7960,
            "f_seats": 5016,
            "r_seats": 4491,
            "cargo_demand": 493614
        }
    },
    {
        "iata": "MLY",
        "icao": "PAML",
        "airportName": "Manley Hot Springs Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Manley Hot Springs",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "64.99759674",
        "longitude": "-150.6439972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2875",
                "runway_width": "30",
                "runway_material": "GRAVEL-F"
            }
        ],
        "demand": {
            "y_seats": 3232,
            "s_seats": 2533,
            "j_seats": 1628,
            "f_seats": 428,
            "r_seats": 190,
            "cargo_demand": 63
        }
    },
    {
        "iata": "MLZ",
        "icao": "SUMO",
        "airportName": "Cerro Largo International Airport",
        "location_country": "Uruguay",
        "location_city": "Melo",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-32.33789825",
        "longitude": "-54.21670151",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3722,
            "s_seats": 1537,
            "j_seats": 1836,
            "f_seats": 66,
            "r_seats": 119,
            "cargo_demand": 6278
        }
    },
    {
        "iata": "MMB",
        "icao": "RJCM",
        "airportName": "Memanbetsu Airport",
        "location_country": "Japan",
        "location_city": "Hokkaido",
        "location_state": "o\u0152zora",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.88059998",
        "longitude": "144.1640015",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8202",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187427,
            "s_seats": 35253,
            "j_seats": 58121,
            "f_seats": 2816,
            "r_seats": 7261,
            "cargo_demand": 148011
        }
    },
    {
        "iata": "MMD",
        "icao": "ROMD",
        "airportName": "Minami-Daito Airport",
        "location_country": "Japan",
        "location_city": "Daito\u008d Islands",
        "location_state": "Minamidaito\u008d",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.8465",
        "longitude": "131.263",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4921",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134842,
            "s_seats": 43229,
            "j_seats": 50720,
            "f_seats": 17330,
            "r_seats": 4938,
            "cargo_demand": 459328
        }
    },
    {
        "iata": "MME",
        "icao": "EGNV",
        "airportName": "Teesside International Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Teesside",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "54.50920105",
        "longitude": "-1.429409981",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7516",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92857,
            "s_seats": 42596,
            "j_seats": 42476,
            "f_seats": 14745,
            "r_seats": 4814,
            "cargo_demand": 475457
        }
    },
    {
        "iata": "MMF",
        "icao": "FKKF",
        "airportName": "Mamfe Airport",
        "location_country": "Cameroon",
        "location_city": "Mamfe",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "5.704170227",
        "longitude": "9.306389809",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4495",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2393,
            "s_seats": 1916,
            "j_seats": 1717,
            "f_seats": 208,
            "r_seats": 115,
            "cargo_demand": 51849
        }
    },
    {
        "iata": "MMG",
        "icao": "YMOG",
        "airportName": "Mount Magnet Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Mount Magnet",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.11610031",
        "longitude": "117.8420029",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2953",
                "runway_width": "98",
                "runway_material": "PER"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 168182,
            "s_seats": 44605,
            "j_seats": 5546,
            "f_seats": 14785,
            "r_seats": 8549,
            "cargo_demand": 292813
        }
    },
    {
        "iata": "MMH",
        "icao": "KMMH",
        "airportName": "Mammoth Yosemite Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Mammoth Lakes",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.62409973",
        "longitude": "-118.8379974",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2413,
            "s_seats": 2530,
            "j_seats": 1958,
            "f_seats": 325,
            "r_seats": 5,
            "cargo_demand": 76152
        }
    },
    {
        "iata": "MMI",
        "icao": "KMMI",
        "airportName": "McMinn County Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Athens",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.39730072",
        "longitude": "-84.56259918",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1460,
            "s_seats": 2358,
            "j_seats": 1596,
            "f_seats": 238,
            "r_seats": 11,
            "cargo_demand": 9630
        }
    },
    {
        "iata": "MMJ",
        "icao": "RJAF",
        "airportName": "Matsumoto Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Matsumoto",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.166801",
        "longitude": "137.923004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6560",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148247,
            "s_seats": 36497,
            "j_seats": 58056,
            "f_seats": 18141,
            "r_seats": 3479,
            "cargo_demand": 212751
        }
    },
    {
        "iata": "MMK",
        "icao": "ULMM",
        "airportName": "Murmansk Airport",
        "location_country": "Russia",
        "location_city": "Murmansk Oblast",
        "location_state": "Murmansk",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "68.78170013",
        "longitude": "32.75080109",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 80429,
            "s_seats": 43035,
            "j_seats": 16707,
            "f_seats": 1480,
            "r_seats": 3215,
            "cargo_demand": 204652
        }
    },
    {
        "iata": "MML",
        "icao": "KMML",
        "airportName": "Southwest Minnesota Regional Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Marshall",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.45050049",
        "longitude": "-95.82189941",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5010",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1775,
            "s_seats": 2687,
            "j_seats": 1120,
            "f_seats": 305,
            "r_seats": 163,
            "cargo_demand": 55971
        }
    },
    {
        "iata": "MMM",
        "icao": "YMMU",
        "airportName": "Middlemount Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Middlemount",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.80249977",
        "longitude": "148.7050018",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5085",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3530,
            "s_seats": 1832,
            "j_seats": 1081,
            "f_seats": 156,
            "r_seats": 21,
            "cargo_demand": 42873
        }
    },
    {
        "iata": "MMO",
        "icao": "GVMA",
        "airportName": "Maio Airport",
        "location_country": "Cape Verde",
        "location_city": "Maio Island",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.1559",
        "longitude": "-23.21369934",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "02/20",
                "runway_length": "3924",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120435,
            "s_seats": 42563,
            "j_seats": 6785,
            "f_seats": 15544,
            "r_seats": 3496,
            "cargo_demand": 459509
        }
    },
    {
        "iata": "MMP",
        "icao": "SKMP",
        "airportName": "San Bernardo Airport",
        "location_country": "Colombia",
        "location_city": "Mompos (Mompox)",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "9.25872",
        "longitude": "-74.438",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4068",
                "runway_width": "48",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2555,
            "s_seats": 1763,
            "j_seats": 1985,
            "f_seats": 364,
            "r_seats": 81,
            "cargo_demand": 18247
        }
    },
    {
        "iata": "MMS",
        "icao": "KMMS",
        "airportName": "Selfs Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Marks",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.23149872",
        "longitude": "-90.28959656",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3348",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1759,
            "s_seats": 2549,
            "j_seats": 1019,
            "f_seats": 228,
            "r_seats": 39,
            "cargo_demand": 90930
        }
    },
    {
        "iata": "MMT",
        "icao": "KMMT",
        "airportName": "McEntire Joint National Guard Base",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Columbia",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.92079926",
        "longitude": "-80.80130005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2033",
                "runway_width": "90",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "9017",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 65719,
            "s_seats": 31443,
            "j_seats": 6935,
            "f_seats": 17653,
            "r_seats": 5823,
            "cargo_demand": 350583
        }
    },
    {
        "iata": "MMU",
        "icao": "KMMU",
        "airportName": "Morristown Municipal Airport",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Morristown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.79940033",
        "longitude": "-74.41490173",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5999",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3998",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159966,
            "s_seats": 41035,
            "j_seats": 12049,
            "f_seats": 1665,
            "r_seats": 7860,
            "cargo_demand": 316682
        }
    },
    {
        "iata": "MMX",
        "icao": "ESMS",
        "airportName": "Malmo Airport",
        "location_country": "Sweden",
        "location_city": "Malmo",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "55.53630536",
        "longitude": "13.37619781",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2615",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 461720,
            "s_seats": 72500,
            "j_seats": 35177,
            "f_seats": 55006,
            "r_seats": 10000,
            "cargo_demand": 17396098
        }
    },
    {
        "iata": "MMY",
        "icao": "ROMY",
        "airportName": "Miyako Airport",
        "location_country": "Japan",
        "location_city": "Miyako Islands",
        "location_state": "Miyakojima",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.78280067",
        "longitude": "125.2949982",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6560",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117664,
            "s_seats": 37774,
            "j_seats": 34712,
            "f_seats": 6775,
            "r_seats": 6354,
            "cargo_demand": 303773
        }
    },
    {
        "iata": "MMZ",
        "icao": "OAMN",
        "airportName": "Maymana Airport",
        "location_country": "Afghanistan",
        "location_city": "Maymana",
        "location_state": "",
        "time_zone": "UTC+04:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.930801",
        "longitude": "64.760902",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5090",
                "runway_width": "76",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 164741,
            "s_seats": 33212,
            "j_seats": 46753,
            "f_seats": 2112,
            "r_seats": 5594,
            "cargo_demand": 477511
        }
    },
    {
        "iata": "MNA",
        "icao": "WAMN",
        "airportName": "Melangguane Airport",
        "location_country": "Indonesia",
        "location_city": "Melonguane",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "4.00694",
        "longitude": "126.672997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4593",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2308,
            "s_seats": 2771,
            "j_seats": 1151,
            "f_seats": 387,
            "r_seats": 145,
            "cargo_demand": 49594
        }
    },
    {
        "iata": "MNB",
        "icao": "FZAG",
        "airportName": "Muanda Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Moanda",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.930637",
        "longitude": "12.351334",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4864",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2224,
            "s_seats": 2423,
            "j_seats": 1053,
            "f_seats": 375,
            "r_seats": 84,
            "cargo_demand": 10307
        }
    },
    {
        "iata": "MNC",
        "icao": "FQNC",
        "airportName": "Nacala Airport",
        "location_country": "Mozambique",
        "location_city": "Nacala",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.48820019",
        "longitude": "40.71220016",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2093,
            "s_seats": 2285,
            "j_seats": 1317,
            "f_seats": 296,
            "r_seats": 190,
            "cargo_demand": 21728
        }
    },
    {
        "iata": "MNE",
        "icao": "YMUG",
        "airportName": "Mungeranie Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Mungeranie",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-28.00919914",
        "longitude": "138.6569977",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3444",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1763,
            "s_seats": 2708,
            "j_seats": 1058,
            "f_seats": 294,
            "r_seats": 100,
            "cargo_demand": 46663
        }
    },
    {
        "iata": "MNG",
        "icao": "YMGD",
        "airportName": "Maningrida Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Maningrida",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.05609989",
        "longitude": "134.2339935",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5020",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159612,
            "s_seats": 41571,
            "j_seats": 34118,
            "f_seats": 3576,
            "r_seats": 4138,
            "cargo_demand": 435490
        }
    },
    {
        "iata": "MNI",
        "icao": "TRPG",
        "airportName": "John A. Osborne Airport",
        "location_country": "British Overseas Territory of Montserrat",
        "location_city": "",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.79140091",
        "longitude": "-62.19329834",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "1968",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186666,
            "s_seats": 46554,
            "j_seats": 58980,
            "f_seats": 1295,
            "r_seats": 4482,
            "cargo_demand": 273105
        }
    },
    {
        "iata": "MNJ",
        "icao": "FMSM",
        "airportName": "Mananjary Airport",
        "location_country": "Madagascar",
        "location_city": "Mananjary",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.201799",
        "longitude": "48.358299",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132510,
            "s_seats": 25697,
            "j_seats": 23408,
            "f_seats": 11984,
            "r_seats": 4287,
            "cargo_demand": 402417
        }
    },
    {
        "iata": "MNL",
        "icao": "RPLL",
        "airportName": "Ninoy Aquino International Airport / Villamor Air Base",
        "location_country": "Philippines",
        "location_city": "Manila",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "14.5086",
        "longitude": "121.019997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "12261",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7408",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 345012,
            "s_seats": 92010,
            "j_seats": 123377,
            "f_seats": 22420,
            "r_seats": 10000,
            "cargo_demand": 25091482
        }
    },
    {
        "iata": "MNM",
        "icao": "KMNM",
        "airportName": "Menominee-Marinette Twin County Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Menominee",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.126701",
        "longitude": "-87.638397",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5100",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3879,
            "s_seats": 2665,
            "j_seats": 1731,
            "f_seats": 51,
            "r_seats": 6,
            "cargo_demand": 17855
        }
    },
    {
        "iata": "MNN",
        "icao": "KMNN",
        "airportName": "Marion Municipal Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Marion",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.61619949",
        "longitude": "-83.06349945",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3498",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2275,
            "s_seats": 2674,
            "j_seats": 1604,
            "f_seats": 72,
            "r_seats": 21,
            "cargo_demand": 88516
        }
    },
    {
        "iata": "MNQ",
        "icao": "YMTO",
        "airportName": "Monto Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Monto",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.88579941",
        "longitude": "151.1000061",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4301",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3086,
            "s_seats": 2874,
            "j_seats": 1366,
            "f_seats": 429,
            "r_seats": 23,
            "cargo_demand": 71028
        }
    },
    {
        "iata": "MNR",
        "icao": "FLMG",
        "airportName": "Mongu Airport",
        "location_country": "Zambia",
        "location_city": "Mongu",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.25450039",
        "longitude": "23.16230011",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4800",
                "runway_width": "69",
                "runway_material": "BRI"
            }
        ],
        "demand": {
            "y_seats": 119020,
            "s_seats": 40096,
            "j_seats": 9190,
            "f_seats": 2511,
            "r_seats": 3383,
            "cargo_demand": 160621
        }
    },
    {
        "iata": "MNU",
        "icao": "VYMM",
        "airportName": "Mawlamyaing Airport",
        "location_country": "Myanmar",
        "location_city": "Mawlamyine (Mawlamyaing)",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "16.44470024",
        "longitude": "97.66069794",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5260",
                "runway_width": "150",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 1803,
            "s_seats": 2237,
            "j_seats": 1270,
            "f_seats": 275,
            "r_seats": 12,
            "cargo_demand": 23437
        }
    },
    {
        "iata": "MNW",
        "icao": "YMDS",
        "airportName": "MacDonald Downs Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "MacDonald Downs",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.44400024",
        "longitude": "135.1990051",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3608",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 2189,
            "s_seats": 2409,
            "j_seats": 1959,
            "f_seats": 75,
            "r_seats": 156,
            "cargo_demand": 68642
        }
    },
    {
        "iata": "MNX",
        "icao": "SBMY",
        "airportName": "Manicore Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Manicore",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.81137991",
        "longitude": "-61.27830124",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "4199",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130420,
            "s_seats": 28293,
            "j_seats": 21659,
            "f_seats": 13990,
            "r_seats": 7302,
            "cargo_demand": 491077
        }
    },
    {
        "iata": "MNY",
        "icao": "AGGO",
        "airportName": "Mono Airport",
        "location_country": "Solomon Islands",
        "location_city": "Treasury Islands",
        "location_state": "Mono Island",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.416940212",
        "longitude": "155.5650024",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3281",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2724,
            "s_seats": 2666,
            "j_seats": 1311,
            "f_seats": 473,
            "r_seats": 157,
            "cargo_demand": 71908
        }
    },
    {
        "iata": "MNZ",
        "icao": "KHEF",
        "airportName": "Manassas Regional Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Manassas",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.72140121",
        "longitude": "-77.51540375",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "5700",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "3702",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2954,
            "s_seats": 2502,
            "j_seats": 1647,
            "f_seats": 304,
            "r_seats": 60,
            "cargo_demand": 24367
        }
    },
    {
        "iata": "MOA",
        "icao": "MUMO",
        "airportName": "Orestes Acosta Airport",
        "location_country": "Cuba",
        "location_city": "Moa",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "20.65390015",
        "longitude": "-74.92220306",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6102",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66230,
            "s_seats": 32189,
            "j_seats": 29260,
            "f_seats": 12519,
            "r_seats": 4892,
            "cargo_demand": 445920
        }
    },
    {
        "iata": "MOB",
        "icao": "KMOB",
        "airportName": "Mobile Regional Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Mobile",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "30.69120026",
        "longitude": "-88.24279785",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8521",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4365",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168206,
            "s_seats": 94664,
            "j_seats": 125612,
            "f_seats": 35980,
            "r_seats": 10000,
            "cargo_demand": 17525931
        }
    },
    {
        "iata": "MOC",
        "icao": "SBMK",
        "airportName": "Montes Claros/Mario Ribeiro Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Montes Claros",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-16.70689964",
        "longitude": "-43.81890106",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186343,
            "s_seats": 35622,
            "j_seats": 56564,
            "f_seats": 5357,
            "r_seats": 9453,
            "cargo_demand": 205622
        }
    },
    {
        "iata": "MOD",
        "icao": "KMOD",
        "airportName": "Modesto City-County Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Modesto",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "37.625801",
        "longitude": "-120.954002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "5911",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "3459",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90580,
            "s_seats": 47435,
            "j_seats": 24012,
            "f_seats": 11209,
            "r_seats": 6747,
            "cargo_demand": 269257
        }
    },
    {
        "iata": "MOE",
        "icao": "VYMO",
        "airportName": "Momeik Airport",
        "location_country": "Myanmar",
        "location_city": "Momeik",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.09250069",
        "longitude": "96.64530182",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4498",
                "runway_width": "102",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 136626,
            "s_seats": 26515,
            "j_seats": 35673,
            "f_seats": 7327,
            "r_seats": 3665,
            "cargo_demand": 435481
        }
    },
    {
        "iata": "MOF",
        "icao": "WATC",
        "airportName": "Frans Seda Airport",
        "location_country": "Indonesia",
        "location_city": "Maumere",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.640649796",
        "longitude": "122.2369995",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5980",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2217,
            "s_seats": 2950,
            "j_seats": 1155,
            "f_seats": 437,
            "r_seats": 108,
            "cargo_demand": 98712
        }
    },
    {
        "iata": "MOG",
        "icao": "VYMS",
        "airportName": "Monghsat Airport",
        "location_country": "Myanmar",
        "location_city": "Mong Hsat (Monghsat)",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.51679993",
        "longitude": "99.25679779",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5000",
                "runway_width": "102",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 75659,
            "s_seats": 42141,
            "j_seats": 43490,
            "f_seats": 11268,
            "r_seats": 8270,
            "cargo_demand": 262395
        }
    },
    {
        "iata": "MOL",
        "icao": "ENML",
        "airportName": "Molde Airport",
        "location_country": "Norway",
        "location_city": "Molde",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "62.74470139",
        "longitude": "7.262499809",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6922",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86372,
            "s_seats": 38471,
            "j_seats": 59098,
            "f_seats": 2422,
            "r_seats": 3176,
            "cargo_demand": 94626
        }
    },
    {
        "iata": "MON",
        "icao": "NZMC",
        "airportName": "Mount Cook Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Mount Cook",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-43.76499939",
        "longitude": "170.1329956",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5157",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170130,
            "s_seats": 26178,
            "j_seats": 14247,
            "f_seats": 16213,
            "r_seats": 3614,
            "cargo_demand": 70873
        }
    },
    {
        "iata": "MOO",
        "icao": "YOOM",
        "airportName": "Moomba Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Moomba",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-28.09939957",
        "longitude": "140.1970062",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5636",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1071,
            "s_seats": 2171,
            "j_seats": 1254,
            "f_seats": 319,
            "r_seats": 30,
            "cargo_demand": 39929
        }
    },
    {
        "iata": "MOP",
        "icao": "KMOP",
        "airportName": "Mount Pleasant Municipal Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Mount Pleasant",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.62170029",
        "longitude": "-84.73750305",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2500",
                "runway_width": "160",
                "runway_material": "GRS"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1278,
            "s_seats": 2984,
            "j_seats": 1806,
            "f_seats": 16,
            "r_seats": 56,
            "cargo_demand": 15951
        }
    },
    {
        "iata": "MOQ",
        "icao": "FMMV",
        "airportName": "Morondava Airport",
        "location_country": "Madagascar",
        "location_city": "Morondava",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.2847",
        "longitude": "44.3176",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4413",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121666,
            "s_seats": 27938,
            "j_seats": 7182,
            "f_seats": 2279,
            "r_seats": 9915,
            "cargo_demand": 384204
        }
    },
    {
        "iata": "MOR",
        "icao": "KMOR",
        "airportName": "Morristown Regional Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Morristown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "36.1794014",
        "longitude": "-83.37550354",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5701",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3468,
            "s_seats": 1869,
            "j_seats": 1022,
            "f_seats": 381,
            "r_seats": 66,
            "cargo_demand": 19575
        }
    },
    {
        "iata": "MOT",
        "icao": "KMOT",
        "airportName": "Minot International Airport",
        "location_country": "United States",
        "location_city": "North Dakota",
        "location_state": "Minot",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "48.25939941",
        "longitude": "-101.2799988",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6350",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7700",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 138484,
            "s_seats": 43678,
            "j_seats": 14828,
            "f_seats": 5534,
            "r_seats": 6562,
            "cargo_demand": 259035
        }
    },
    {
        "iata": "MOU",
        "icao": "PAMO",
        "airportName": "Mountain Village Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Mountain Village",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "62.09540176",
        "longitude": "-163.6820068",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3500",
                "runway_width": "75",
                "runway_material": "GRVL-P"
            }
        ],
        "demand": {
            "y_seats": 2933,
            "s_seats": 2652,
            "j_seats": 1194,
            "f_seats": 266,
            "r_seats": 88,
            "cargo_demand": 52092
        }
    },
    {
        "iata": "MOV",
        "icao": "YMRB",
        "airportName": "Moranbah Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Moranbah",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.05780029",
        "longitude": "148.0769959",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5000",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58605,
            "s_seats": 27788,
            "j_seats": 47536,
            "f_seats": 14932,
            "r_seats": 3969,
            "cargo_demand": 166670
        }
    },
    {
        "iata": "MOX",
        "icao": "KMOX",
        "airportName": "Morris Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Morris",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "45.56600189",
        "longitude": "-95.96720123",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2640",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3445,
            "s_seats": 2226,
            "j_seats": 1127,
            "f_seats": 23,
            "r_seats": 153,
            "cargo_demand": 78971
        }
    },
    {
        "iata": "MOZ",
        "icao": "NTTM",
        "airportName": "Moorea Airport",
        "location_country": "French Polynesia",
        "location_city": "Windward Islands",
        "location_state": "Mo'orea",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.49",
        "longitude": "-149.761993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3871",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185013,
            "s_seats": 35588,
            "j_seats": 19459,
            "f_seats": 1128,
            "r_seats": 7313,
            "cargo_demand": 247246
        }
    },
    {
        "iata": "MPA",
        "icao": "FYKM",
        "airportName": "Katima Mulilo Airport",
        "location_country": "Namibia",
        "location_city": "Katima Mulilo",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "small_airport",
        "latitude": "-17.634399",
        "longitude": "24.176701",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7520",
                "runway_width": "128",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2217,
            "s_seats": 2108,
            "j_seats": 1813,
            "f_seats": 92,
            "r_seats": 85,
            "cargo_demand": 76398
        }
    },
    {
        "iata": "MPH",
        "icao": "RPVE",
        "airportName": "Godofredo P. Ramos Airport",
        "location_country": "Philippines",
        "location_city": "Malay",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.9245",
        "longitude": "121.954002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 52477,
            "s_seats": 32835,
            "j_seats": 15947,
            "f_seats": 3982,
            "r_seats": 9990,
            "cargo_demand": 84032
        }
    },
    {
        "iata": "MPJ",
        "icao": "KMPJ",
        "airportName": "Petit Jean Park Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Morrilton",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.138901",
        "longitude": "-92.909202",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5853",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2143,
            "s_seats": 2264,
            "j_seats": 1192,
            "f_seats": 187,
            "r_seats": 75,
            "cargo_demand": 40433
        }
    },
    {
        "iata": "MPL",
        "icao": "LFMT",
        "airportName": "Montpellier-Mediterranee Airport",
        "location_country": "France",
        "location_city": "Languedoc-Roussillon",
        "location_state": "Montpellier",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "43.57619858",
        "longitude": "3.963010073",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12L/30R",
                "runway_length": "8530",
                "runway_width": "164",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131158,
            "s_seats": 33616,
            "j_seats": 56420,
            "f_seats": 7994,
            "r_seats": 9288,
            "cargo_demand": 195416
        }
    },
    {
        "iata": "MPM",
        "icao": "FQMA",
        "airportName": "Maputo International Airport",
        "location_country": "Mozambique",
        "location_city": "Maputo",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-25.920799",
        "longitude": "32.572601",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "12008",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5577",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 479240,
            "s_seats": 72018,
            "j_seats": 123822,
            "f_seats": 26223,
            "r_seats": 10000,
            "cargo_demand": 16740429
        }
    },
    {
        "iata": "MPN",
        "icao": "EGYP",
        "airportName": "RAF Mount Pleasant",
        "location_country": "British Overseas Territory of Falkland Islands",
        "location_city": "",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-51.82279968",
        "longitude": "-58.44720078",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5003",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "8497",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143065,
            "s_seats": 26389,
            "j_seats": 36127,
            "f_seats": 8694,
            "r_seats": 5895,
            "cargo_demand": 465340
        }
    },
    {
        "iata": "MPO",
        "icao": "KMPO",
        "airportName": "Pocono Mountains Municipal Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Mount Pocono",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.137503",
        "longitude": "-75.378897",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3948",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3386,
            "s_seats": 2383,
            "j_seats": 1399,
            "f_seats": 144,
            "r_seats": 4,
            "cargo_demand": 77089
        }
    },
    {
        "iata": "MPR",
        "icao": "KMPR",
        "airportName": "McPherson Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "McPherson",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.35240173",
        "longitude": "-97.69129944",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2511",
                "runway_width": "75",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1602,
            "s_seats": 2061,
            "j_seats": 1670,
            "f_seats": 466,
            "r_seats": 158,
            "cargo_demand": 35836
        }
    },
    {
        "iata": "MPS",
        "icao": "KOSA",
        "airportName": "Mount Pleasant Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Mount Pleasant",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "33.09550095",
        "longitude": "-94.96150208",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2992,
            "s_seats": 2908,
            "j_seats": 1880,
            "f_seats": 17,
            "r_seats": 110,
            "cargo_demand": 91948
        }
    },
    {
        "iata": "MPV",
        "icao": "KMPV",
        "airportName": "Edward F. Knapp State Airport",
        "location_country": "United States",
        "location_city": "Vermont",
        "location_state": "Barre / Montpelier",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.20349884",
        "longitude": "-72.56230164",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4022",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5002",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192012,
            "s_seats": 31296,
            "j_seats": 12302,
            "f_seats": 10749,
            "r_seats": 6696,
            "cargo_demand": 454574
        }
    },
    {
        "iata": "MPW",
        "icao": "UKCM",
        "airportName": "Mariupol International Airport",
        "location_country": "Ukraine",
        "location_city": "Mariupol",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.0760994",
        "longitude": "37.44960022",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8431",
                "runway_width": "164",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4624",
                "runway_width": "280",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 179167,
            "s_seats": 38015,
            "j_seats": 48001,
            "f_seats": 1520,
            "r_seats": 6232,
            "cargo_demand": 290041
        }
    },
    {
        "iata": "MPY",
        "icao": "SOOA",
        "airportName": "Maripasoula Airport",
        "location_country": "French Guiana",
        "location_city": "Maripasoula",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "3.6575",
        "longitude": "-54.037201",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3935",
                "runway_width": "49",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2254,
            "s_seats": 2238,
            "j_seats": 1035,
            "f_seats": 365,
            "r_seats": 199,
            "cargo_demand": 48789
        }
    },
    {
        "iata": "MPZ",
        "icao": "KMPZ",
        "airportName": "Mount Pleasant Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Mount Pleasant",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.94660187",
        "longitude": "-91.51110077",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1965",
                "runway_width": "120",
                "runway_material": "GRS"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1701,
            "s_seats": 2627,
            "j_seats": 1230,
            "f_seats": 49,
            "r_seats": 50,
            "cargo_demand": 58648
        }
    },
    {
        "iata": "MQA",
        "icao": "YMDI",
        "airportName": "Mandora Station Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Mandora Station",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-19.73830032",
        "longitude": "120.8379974",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3444",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1916,
            "s_seats": 2832,
            "j_seats": 1651,
            "f_seats": 476,
            "r_seats": 68,
            "cargo_demand": 68209
        }
    },
    {
        "iata": "MQB",
        "icao": "KMQB",
        "airportName": "Macomb Municipal Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Macomb",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.52009964",
        "longitude": "-90.65239716",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5101",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3719",
                "runway_width": "190",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2277,
            "s_seats": 2091,
            "j_seats": 1199,
            "f_seats": 239,
            "r_seats": 0,
            "cargo_demand": 59618
        }
    },
    {
        "iata": "MQC",
        "icao": "LFVM",
        "airportName": "Miquelon Airport",
        "location_country": "Saint Pierre and Miquelon",
        "location_city": "Miquelon",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.095501",
        "longitude": "-56.380299",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3280",
                "runway_width": "65",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189619,
            "s_seats": 47218,
            "j_seats": 37122,
            "f_seats": 16803,
            "r_seats": 8737,
            "cargo_demand": 225393
        }
    },
    {
        "iata": "MQD",
        "icao": "SAVQ",
        "airportName": "Maquinchao Airport",
        "location_country": "Argentina",
        "location_city": "Rio Negro",
        "location_state": "Maquinchao",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-41.24309921",
        "longitude": "-68.70780182",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "3773",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 3255,
            "s_seats": 2650,
            "j_seats": 1611,
            "f_seats": 383,
            "r_seats": 185,
            "cargo_demand": 85379
        }
    },
    {
        "iata": "MQE",
        "icao": "YMQA",
        "airportName": "Marqua Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Marqua",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.80579948",
        "longitude": "137.2510071",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2952",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3664,
            "s_seats": 2490,
            "j_seats": 1030,
            "f_seats": 335,
            "r_seats": 193,
            "cargo_demand": 7580
        }
    },
    {
        "iata": "MQF",
        "icao": "USCM",
        "airportName": "Magnitogorsk International Airport",
        "location_country": "Russia",
        "location_city": "Chelyabinsk Oblast5",
        "location_state": "Magnitogorsk",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.39310074",
        "longitude": "58.75569916",
        "region": "EU",
        "runway": [
            {
                "runway_name": "36/18",
                "runway_length": "10663",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 143693,
            "s_seats": 27348,
            "j_seats": 39403,
            "f_seats": 11551,
            "r_seats": 9097,
            "cargo_demand": 116255
        }
    },
    {
        "iata": "MQG",
        "icao": "FYMG",
        "airportName": "Midgard Airport",
        "location_country": "Namibia",
        "location_city": "Midgard",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "small_airport",
        "latitude": "-22.0106",
        "longitude": "17.37",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4828",
                "runway_width": "33",
                "runway_material": "Gravel"
            }
        ],
        "demand": {
            "y_seats": 1180,
            "s_seats": 2218,
            "j_seats": 1508,
            "f_seats": 320,
            "r_seats": 132,
            "cargo_demand": 67653
        }
    },
    {
        "iata": "MQH",
        "icao": "SBMC",
        "airportName": "MinaiCu Airport",
        "location_country": "Brazil",
        "location_city": "Goias",
        "location_state": "MinaiCu",
        "time_zone": "UTC+03:00",
        "daylight_saving": "Oct-Feb",
        "airport_size": "medium_airport",
        "latitude": "-13.5491",
        "longitude": "-48.195301",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "4183",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177811,
            "s_seats": 27990,
            "j_seats": 41166,
            "f_seats": 10886,
            "r_seats": 9892,
            "cargo_demand": 467483
        }
    },
    {
        "iata": "MQJ",
        "icao": "UEMA",
        "airportName": "Moma Airport",
        "location_country": "Russia",
        "location_city": "Yakutia",
        "location_state": "Khonuu",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "66.450861",
        "longitude": "143.261551",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 73914,
            "s_seats": 45714,
            "j_seats": 10616,
            "f_seats": 6861,
            "r_seats": 8195,
            "cargo_demand": 407755
        }
    },
    {
        "iata": "MQL",
        "icao": "YMIA",
        "airportName": "Mildura Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Mildura",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-34.22919846",
        "longitude": "142.0859985",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6004",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3737",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95819,
            "s_seats": 47837,
            "j_seats": 6654,
            "f_seats": 6255,
            "r_seats": 8034,
            "cargo_demand": 454371
        }
    },
    {
        "iata": "MQM",
        "icao": "LTCR",
        "airportName": "Mardin Airport",
        "location_country": "Turkey",
        "location_city": "Mardin",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.22330093",
        "longitude": "40.63169861",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8204",
                "runway_width": "118",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 134490,
            "s_seats": 38267,
            "j_seats": 56252,
            "f_seats": 9515,
            "r_seats": 8867,
            "cargo_demand": 191552
        }
    },
    {
        "iata": "MQN",
        "icao": "ENRA",
        "airportName": "Mo i Rana Airport",
        "location_country": "Norway",
        "location_city": "Mo i Rana",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "\" Rossvoll\"\"\"",
        "longitude": "66.36389923",
        "region": "229",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2759",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108813,
            "s_seats": 48289,
            "j_seats": 55306,
            "f_seats": 19050,
            "r_seats": 5686,
            "cargo_demand": 125402
        }
    },
    {
        "iata": "MQP",
        "icao": "FAKN",
        "airportName": "Kruger Mpumalanga International Airport",
        "location_country": "South Africa",
        "location_city": "Mbombela",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.38319969",
        "longitude": "31.10560036",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109959,
            "s_seats": 25847,
            "j_seats": 22454,
            "f_seats": 18787,
            "r_seats": 5066,
            "cargo_demand": 367800
        }
    },
    {
        "iata": "MQQ",
        "icao": "FTTD",
        "airportName": "Moundou Airport",
        "location_country": "Chad",
        "location_city": "Moundou",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.62441",
        "longitude": "16.0714",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5906",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148551,
            "s_seats": 45052,
            "j_seats": 28809,
            "f_seats": 18744,
            "r_seats": 3241,
            "cargo_demand": 263630
        }
    },
    {
        "iata": "MQS",
        "icao": "TVSM",
        "airportName": "Mustique Airport",
        "location_country": "Saint Vincent and the Grenadines",
        "location_city": "Mustique Island",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.88790035",
        "longitude": "-61.18019867",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3255",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168603,
            "s_seats": 29441,
            "j_seats": 28240,
            "f_seats": 5145,
            "r_seats": 9139,
            "cargo_demand": 87315
        }
    },
    {
        "iata": "MQT",
        "icao": "KSAW",
        "airportName": "Sawyer International Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Marquette",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.35359955",
        "longitude": "-87.395401",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "12370",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 163403,
            "s_seats": 26156,
            "j_seats": 50287,
            "f_seats": 5904,
            "r_seats": 5607,
            "cargo_demand": 343976
        }
    },
    {
        "iata": "MQU",
        "icao": "SKQU",
        "airportName": "Mariquita Airport",
        "location_country": "Colombia",
        "location_city": "Mariquita",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.21256",
        "longitude": "-74.8836",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5905",
                "runway_width": "164",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 71016,
            "s_seats": 32479,
            "j_seats": 28020,
            "f_seats": 4532,
            "r_seats": 4571,
            "cargo_demand": 164772
        }
    },
    {
        "iata": "MQW",
        "icao": "KMQW",
        "airportName": "Telfair-Wheeler Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "McRae",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.09579849",
        "longitude": "-82.87999725",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4030",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3812,
            "s_seats": 2053,
            "j_seats": 1868,
            "f_seats": 435,
            "r_seats": 184,
            "cargo_demand": 50187
        }
    },
    {
        "iata": "MQX",
        "icao": "HAMK",
        "airportName": "Alula Aba Nega Airport",
        "location_country": "Ethiopia",
        "location_city": "Mek'ele (Mekelle)",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.4673996",
        "longitude": "39.53350067",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "11825",
                "runway_width": "142",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90506,
            "s_seats": 28455,
            "j_seats": 37983,
            "f_seats": 3589,
            "r_seats": 5160,
            "cargo_demand": 133063
        }
    },
    {
        "iata": "MQY",
        "icao": "KMQY",
        "airportName": "Smyrna Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Smyrna",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.00899887",
        "longitude": "-86.52010345",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5546",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8036",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184962,
            "s_seats": 42779,
            "j_seats": 31051,
            "f_seats": 6680,
            "r_seats": 5024,
            "cargo_demand": 433262
        }
    },
    {
        "iata": "MQZ",
        "icao": "YMGT",
        "airportName": "Margaret River Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Margaret River",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-33.93059921",
        "longitude": "115.0999985",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3619",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3977,
            "s_seats": 1671,
            "j_seats": 1716,
            "f_seats": 103,
            "r_seats": 71,
            "cargo_demand": 41148
        }
    },
    {
        "iata": "MRB",
        "icao": "KMRB",
        "airportName": "Eastern WV Regional Airport",
        "location_country": "United States",
        "location_city": "West Virginia",
        "location_state": "Martinsburg",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.40190125",
        "longitude": "-77.98459625",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9600",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130079,
            "s_seats": 45058,
            "j_seats": 48789,
            "f_seats": 15605,
            "r_seats": 9210,
            "cargo_demand": 343412
        }
    },
    {
        "iata": "MRC",
        "icao": "KMRC",
        "airportName": "Maury County Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Columbia/Mount Pleasant",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.55410004",
        "longitude": "-87.17890167",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6003",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2000",
                "runway_width": "200",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2648,
            "s_seats": 2550,
            "j_seats": 1414,
            "f_seats": 72,
            "r_seats": 92,
            "cargo_demand": 53126
        }
    },
    {
        "iata": "MRD",
        "icao": "SVMD",
        "airportName": "Alberto Carnevalli Airport",
        "location_country": "Venezuela",
        "location_city": "Merida",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.582078",
        "longitude": "-71.161041",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5348",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173966,
            "s_seats": 28590,
            "j_seats": 45759,
            "f_seats": 17825,
            "r_seats": 8404,
            "cargo_demand": 149208
        }
    },
    {
        "iata": "MRF",
        "icao": "KMRF",
        "airportName": "Marfa Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Marfa",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "30.369593",
        "longitude": "-104.015893",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5307",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "2825",
                "runway_width": "60",
                "runway_material": "GRE"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6214",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1892,
            "s_seats": 2225,
            "j_seats": 1739,
            "f_seats": 268,
            "r_seats": 199,
            "cargo_demand": 1010
        }
    },
    {
        "iata": "MRG",
        "icao": "YMBA",
        "airportName": "Mareeba Airfield",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Mareeba",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.06920052",
        "longitude": "145.4190063",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4938",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186404,
            "s_seats": 44685,
            "j_seats": 15038,
            "f_seats": 1728,
            "r_seats": 4024,
            "cargo_demand": 300325
        }
    },
    {
        "iata": "MRI",
        "icao": "PAMR",
        "airportName": "Merrill Field",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Anchorage",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "61.21350098",
        "longitude": "-149.8439941",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2000",
                "runway_width": "60",
                "runway_material": "GVL"
            },
            {
                "runway_name": "07/25",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2640",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83749,
            "s_seats": 44767,
            "j_seats": 57351,
            "f_seats": 13457,
            "r_seats": 3826,
            "cargo_demand": 244050
        }
    },
    {
        "iata": "MRK",
        "icao": "KMKY",
        "airportName": "Marco Island Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Marco Island",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "25.995001",
        "longitude": "-81.672501",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2512,
            "s_seats": 2409,
            "j_seats": 1297,
            "f_seats": 200,
            "r_seats": 161,
            "cargo_demand": 44104
        }
    },
    {
        "iata": "MRN",
        "icao": "KMRN",
        "airportName": "Foothills Regional Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Morganton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.820202",
        "longitude": "-81.611397",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5500",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3790,
            "s_seats": 1990,
            "j_seats": 1122,
            "f_seats": 230,
            "r_seats": 46,
            "cargo_demand": 81776
        }
    },
    {
        "iata": "MRO",
        "icao": "NZMS",
        "airportName": "Hood Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Masterton",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "medium_airport",
        "latitude": "-40.97330093",
        "longitude": "175.6340027",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "06C/24C",
                "runway_length": "4101",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06L/24R",
                "runway_length": "1476",
                "runway_width": "66",
                "runway_material": "GRS"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "3478",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3419",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 119566,
            "s_seats": 42933,
            "j_seats": 27093,
            "f_seats": 9584,
            "r_seats": 9279,
            "cargo_demand": 211531
        }
    },
    {
        "iata": "MRP",
        "icao": "YALA",
        "airportName": "Marla Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Marla",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-27.33329964",
        "longitude": "133.6269989",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5413",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2073,
            "s_seats": 1718,
            "j_seats": 1810,
            "f_seats": 362,
            "r_seats": 156,
            "cargo_demand": 46241
        }
    },
    {
        "iata": "MRQ",
        "icao": "RPUW",
        "airportName": "Marinduque Airport",
        "location_country": "Philippines",
        "location_city": "Marinduque Island",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.36100006",
        "longitude": "121.8259964",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4785",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182448,
            "s_seats": 39843,
            "j_seats": 38448,
            "f_seats": 13303,
            "r_seats": 6628,
            "cargo_demand": 439288
        }
    },
    {
        "iata": "MRR",
        "icao": "SEMA",
        "airportName": "Jose Maria Velasco Ibarra Airport",
        "location_country": "Ecuador",
        "location_city": "Macara",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-4.378230095",
        "longitude": "-79.94100189",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3532",
                "runway_width": "65",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182167,
            "s_seats": 35507,
            "j_seats": 37105,
            "f_seats": 4191,
            "r_seats": 9093,
            "cargo_demand": 98266
        }
    },
    {
        "iata": "MRS",
        "icao": "LFML",
        "airportName": "Marseille Provence Airport",
        "location_country": "France",
        "location_city": "Provence-Alpes-Cote d'Azur",
        "location_state": "Marseille",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "43.43927192",
        "longitude": "5.221424103",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "7776",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 382750,
            "s_seats": 86530,
            "j_seats": 70686,
            "f_seats": 44204,
            "r_seats": 10000,
            "cargo_demand": 10700135
        }
    },
    {
        "iata": "MRU",
        "icao": "FIMP",
        "airportName": "Sir Seewoosagur Ramgoolam International Airport",
        "location_country": "Mauritius",
        "location_city": "Plaine Magnien",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-20.430201",
        "longitude": "57.683601",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "11056",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 333166,
            "s_seats": 91848,
            "j_seats": 59868,
            "f_seats": 40154,
            "r_seats": 10000,
            "cargo_demand": 21218855
        }
    },
    {
        "iata": "MRV",
        "icao": "URMM",
        "airportName": "Mineralnye Vody Airport",
        "location_country": "Russia",
        "location_city": "Stavropol Krai",
        "location_state": "Mineralnye Vody",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.22510147",
        "longitude": "43.08190155",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "12795",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 124974,
            "s_seats": 38751,
            "j_seats": 30626,
            "f_seats": 14533,
            "r_seats": 4542,
            "cargo_demand": 202496
        }
    },
    {
        "iata": "MRW",
        "icao": "EKMB",
        "airportName": "Lolland Falster Airport",
        "location_country": "Denmark",
        "location_city": "Maribo",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "54.699299",
        "longitude": "11.4401",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162825,
            "s_seats": 41144,
            "j_seats": 38028,
            "f_seats": 2885,
            "r_seats": 8065,
            "cargo_demand": 193606
        }
    },
    {
        "iata": "MRX",
        "icao": "OIAM",
        "airportName": "Mahshahr Airport",
        "location_country": "Iran",
        "location_city": "Bandar-e Mahshahr",
        "location_state": "",
        "time_zone": "UTC+03:30",
        "daylight_saving": "Mar-Sep",
        "airport_size": "medium_airport",
        "latitude": "30.5562",
        "longitude": "49.151901",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50581,
            "s_seats": 30440,
            "j_seats": 17612,
            "f_seats": 19502,
            "r_seats": 9886,
            "cargo_demand": 467215
        }
    },
    {
        "iata": "MRY",
        "icao": "KMRY",
        "airportName": "Monterey Regional Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Monterey",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "36.5870018",
        "longitude": "-121.8430023",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "3513",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "7616",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62043,
            "s_seats": 36887,
            "j_seats": 20908,
            "f_seats": 19212,
            "r_seats": 8375,
            "cargo_demand": 471652
        }
    },
    {
        "iata": "MRZ",
        "icao": "YMOR",
        "airportName": "Moree Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Moree",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-29.49889946",
        "longitude": "149.8450012",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5292",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "3205",
                "runway_width": "60",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 93179,
            "s_seats": 46656,
            "j_seats": 52843,
            "f_seats": 8132,
            "r_seats": 3771,
            "cargo_demand": 223611
        }
    },
    {
        "iata": "MSA",
        "icao": "CZMD",
        "airportName": "Muskrat Dam Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Muskrat Dam",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "53.44139862",
        "longitude": "-91.76280212",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 162526,
            "s_seats": 41884,
            "j_seats": 38548,
            "f_seats": 16424,
            "r_seats": 3806,
            "cargo_demand": 167008
        }
    },
    {
        "iata": "MSC",
        "icao": "KFFZ",
        "airportName": "Falcon Field Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Phoenix",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.46080017",
        "longitude": "-111.7279968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "3799",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "5101",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1156,
            "s_seats": 1532,
            "j_seats": 1134,
            "f_seats": 431,
            "r_seats": 137,
            "cargo_demand": 2963
        }
    },
    {
        "iata": "MSF",
        "icao": "YMNS",
        "airportName": "Mount Swan Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Mount Swan",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.624696",
        "longitude": "135.034512",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4265",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1298,
            "s_seats": 1740,
            "j_seats": 1479,
            "f_seats": 115,
            "r_seats": 183,
            "cargo_demand": 26978
        }
    },
    {
        "iata": "MSH",
        "icao": "OOMA",
        "airportName": "Masirah Air Base",
        "location_country": "Oman",
        "location_city": "Masirah Island",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.67539978",
        "longitude": "58.89049911",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8446",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "10005",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116948,
            "s_seats": 46253,
            "j_seats": 37812,
            "f_seats": 12684,
            "r_seats": 7555,
            "cargo_demand": 340006
        }
    },
    {
        "iata": "MSJ",
        "icao": "RJSM",
        "airportName": "Misawa Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Misawa",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.703201",
        "longitude": "141.367996",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82786,
            "s_seats": 28075,
            "j_seats": 7457,
            "f_seats": 7493,
            "r_seats": 3139,
            "cargo_demand": 493725
        }
    },
    {
        "iata": "MSL",
        "icao": "KMSL",
        "airportName": "Northwest Alabama Regional Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Muscle Shoals / Florence",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "34.74530029",
        "longitude": "-87.61019897",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6693",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140714,
            "s_seats": 47934,
            "j_seats": 43570,
            "f_seats": 16721,
            "r_seats": 9638,
            "cargo_demand": 457367
        }
    },
    {
        "iata": "MSN",
        "icao": "KMSN",
        "airportName": "Dane County Regional Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Madison",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "43.1399",
        "longitude": "-89.337502",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7200",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5846",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "9005",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 181348,
            "s_seats": 25377,
            "j_seats": 45948,
            "f_seats": 19294,
            "r_seats": 3846,
            "cargo_demand": 380327
        }
    },
    {
        "iata": "MSO",
        "icao": "KMSO",
        "airportName": "Missoula International Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Missoula",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "46.91630173",
        "longitude": "-114.0910034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4612",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "9501",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113913,
            "s_seats": 28966,
            "j_seats": 15899,
            "f_seats": 10932,
            "r_seats": 7559,
            "cargo_demand": 360072
        }
    },
    {
        "iata": "MSP",
        "icao": "KMSP",
        "airportName": "Minneapolis-Saint Paul International Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Minneapolis / St Paul",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "44.882",
        "longitude": "-93.221802",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "11006",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "10000",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17/35",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 123877,
            "s_seats": 71211,
            "j_seats": 167941,
            "f_seats": 41120,
            "r_seats": 10000,
            "cargo_demand": 16882657
        }
    },
    {
        "iata": "MSQ",
        "icao": "UMMS",
        "airportName": "Minsk National Airport",
        "location_country": "Belarus",
        "location_city": "Minsk",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "53.888071",
        "longitude": "28.039964",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "12139",
                "runway_width": "197",
                "runway_material": "CONC"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "11946",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 485876,
            "s_seats": 78974,
            "j_seats": 166101,
            "f_seats": 68707,
            "r_seats": 10000,
            "cargo_demand": 28949211
        }
    },
    {
        "iata": "MSR",
        "icao": "LTCK",
        "airportName": "Mus Airport",
        "location_country": "Turkey",
        "location_city": "Mus",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.74779892",
        "longitude": "41.66120148",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "11649",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102153,
            "s_seats": 33619,
            "j_seats": 53474,
            "f_seats": 2414,
            "r_seats": 8502,
            "cargo_demand": 87496
        }
    },
    {
        "iata": "MSS",
        "icao": "KMSS",
        "airportName": "Massena International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Massena",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.93579865",
        "longitude": "-74.84559631",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4998",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191047,
            "s_seats": 48476,
            "j_seats": 46468,
            "f_seats": 11704,
            "r_seats": 9889,
            "cargo_demand": 412069
        }
    },
    {
        "iata": "MST",
        "icao": "EHBK",
        "airportName": "Maastricht Aachen Airport",
        "location_country": "Netherlands",
        "location_city": "Maastricht",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "50.911701",
        "longitude": "5.77014",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88305,
            "s_seats": 30312,
            "j_seats": 12086,
            "f_seats": 13979,
            "r_seats": 7092,
            "cargo_demand": 214316
        }
    },
    {
        "iata": "MSU",
        "icao": "FXMM",
        "airportName": "Moshoeshoe I International Airport",
        "location_country": "Lesotho",
        "location_city": "Maseru",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.46229935",
        "longitude": "27.55249977",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10498",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3314",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52394,
            "s_seats": 26532,
            "j_seats": 41396,
            "f_seats": 5800,
            "r_seats": 6614,
            "cargo_demand": 68293
        }
    },
    {
        "iata": "MSV",
        "icao": "KMSV",
        "airportName": "Sullivan County International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Monticello",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.701596",
        "longitude": "-74.794997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6300",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1620,
            "s_seats": 1715,
            "j_seats": 1954,
            "f_seats": 295,
            "r_seats": 3,
            "cargo_demand": 93334
        }
    },
    {
        "iata": "MSW",
        "icao": "HHMS",
        "airportName": "Massawa International Airport",
        "location_country": "Eritrea",
        "location_city": "Massawa",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.67000008",
        "longitude": "39.37009811",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "11384",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 106898,
            "s_seats": 35918,
            "j_seats": 19450,
            "f_seats": 18034,
            "r_seats": 9653,
            "cargo_demand": 135359
        }
    },
    {
        "iata": "MSY",
        "icao": "KMSY",
        "airportName": "Louis Armstrong New Orleans International Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "New Orleans",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "29.99340057",
        "longitude": "-90.25800323",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "3570",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "10104",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 307761,
            "s_seats": 89523,
            "j_seats": 134221,
            "f_seats": 52005,
            "r_seats": 10000,
            "cargo_demand": 21907691
        }
    },
    {
        "iata": "MSZ",
        "icao": "FNMO",
        "airportName": "MoiC\u00e2medes Airport",
        "location_country": "Angola",
        "location_city": "MoiC\u00e2medes",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.2612",
        "longitude": "12.1468",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108358,
            "s_seats": 36393,
            "j_seats": 22297,
            "f_seats": 2804,
            "r_seats": 7265,
            "cargo_demand": 348219
        }
    },
    {
        "iata": "MTA",
        "icao": "NZMA",
        "airportName": "Matamata Airport",
        "location_country": "New Zealand",
        "location_city": "Matamata",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "small_airport",
        "latitude": "-37.7344017",
        "longitude": "175.7420044",
        "region": "OC",
        "runway": [
            {
                "runway_name": "28/10",
                "runway_length": "3572",
                "runway_width": "449",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1847,
            "s_seats": 2435,
            "j_seats": 1817,
            "f_seats": 400,
            "r_seats": 122,
            "cargo_demand": 26835
        }
    },
    {
        "iata": "MTB",
        "icao": "SKML",
        "airportName": "Montelibano Airport",
        "location_country": "Colombia",
        "location_city": "Montelibano",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "7.97174",
        "longitude": "-75.4325",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3560",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2935,
            "s_seats": 2220,
            "j_seats": 1855,
            "f_seats": 105,
            "r_seats": 23,
            "cargo_demand": 44643
        }
    },
    {
        "iata": "MTC",
        "icao": "KMTC",
        "airportName": "Selfridge Air National Guard Base",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Mount Clemens",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "42.613463",
        "longitude": "-82.836919",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 143501,
            "s_seats": 27651,
            "j_seats": 46355,
            "f_seats": 15338,
            "r_seats": 5409,
            "cargo_demand": 303122
        }
    },
    {
        "iata": "MTD",
        "icao": "YMSF",
        "airportName": "Mount Sandford Station Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Mount Sandford Station",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.97830009",
        "longitude": "130.5549927",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3205",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 2096,
            "s_seats": 2792,
            "j_seats": 1773,
            "f_seats": 456,
            "r_seats": 115,
            "cargo_demand": 25623
        }
    },
    {
        "iata": "MTE",
        "icao": "SNMA",
        "airportName": "Monte Alegre Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Monte Alegre",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.995800018",
        "longitude": "-54.07419968",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4675",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3204,
            "s_seats": 1657,
            "j_seats": 1070,
            "f_seats": 371,
            "r_seats": 200,
            "cargo_demand": 69232
        }
    },
    {
        "iata": "MTH",
        "icao": "KMTH",
        "airportName": "Florida Keys Marathon Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Marathon",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "24.726101",
        "longitude": "-81.051399",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5008",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101750,
            "s_seats": 25140,
            "j_seats": 10293,
            "f_seats": 13410,
            "r_seats": 9122,
            "cargo_demand": 241332
        }
    },
    {
        "iata": "MTJ",
        "icao": "KMTJ",
        "airportName": "Montrose Regional Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Montrose",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "38.50979996",
        "longitude": "-107.8939972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7500",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118989,
            "s_seats": 31676,
            "j_seats": 5390,
            "f_seats": 19314,
            "r_seats": 8578,
            "cargo_demand": 334025
        }
    },
    {
        "iata": "MTL",
        "icao": "YMND",
        "airportName": "Maitland Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Maitland",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-32.701265",
        "longitude": "151.492912",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4022",
                "runway_width": "49",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3316",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1236,
            "s_seats": 1959,
            "j_seats": 1112,
            "f_seats": 83,
            "r_seats": 130,
            "cargo_demand": 90266
        }
    },
    {
        "iata": "MTN",
        "icao": "KMTN",
        "airportName": "Martin State Airport",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Baltimore",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.325699",
        "longitude": "-76.413803",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6996",
                "runway_width": "180",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 148136,
            "s_seats": 30229,
            "j_seats": 15313,
            "f_seats": 7973,
            "r_seats": 9114,
            "cargo_demand": 207334
        }
    },
    {
        "iata": "MTO",
        "icao": "KMTO",
        "airportName": "Coles County Memorial Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Mattoon / Charleston",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.477901",
        "longitude": "-88.279198",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5799",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6501",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1080",
                "runway_width": "250",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1322,
            "s_seats": 2363,
            "j_seats": 1155,
            "f_seats": 244,
            "r_seats": 48,
            "cargo_demand": 26539
        }
    },
    {
        "iata": "MTP",
        "icao": "KMTP",
        "airportName": "Montauk Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Montauk",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.0765",
        "longitude": "-71.920797",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3481",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1175,
            "s_seats": 2281,
            "j_seats": 1621,
            "f_seats": 322,
            "r_seats": 126,
            "cargo_demand": 74631
        }
    },
    {
        "iata": "MTQ",
        "icao": "YMIT",
        "airportName": "Mitchell Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Mitchell",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.48329926",
        "longitude": "147.9369965",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4675",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1139,
            "s_seats": 2258,
            "j_seats": 1937,
            "f_seats": 262,
            "r_seats": 174,
            "cargo_demand": 71662
        }
    },
    {
        "iata": "MTR",
        "icao": "SKMR",
        "airportName": "Los Garzones Airport",
        "location_country": "Colombia",
        "location_city": "Monteria",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.82374",
        "longitude": "-75.8258",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6285",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178245,
            "s_seats": 29557,
            "j_seats": 54765,
            "f_seats": 15421,
            "r_seats": 5607,
            "cargo_demand": 147622
        }
    },
    {
        "iata": "MTS",
        "icao": "FDMS",
        "airportName": "Matsapha Airport",
        "location_country": "Swaziland",
        "location_city": "Manzini",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.528999",
        "longitude": "31.307501",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137035,
            "s_seats": 34808,
            "j_seats": 11379,
            "f_seats": 14054,
            "r_seats": 5228,
            "cargo_demand": 344669
        }
    },
    {
        "iata": "MTT",
        "icao": "MMMT",
        "airportName": "Minatitlan/Coatzacoalcos International Airport",
        "location_country": "Mexico",
        "location_city": "Veracruz",
        "location_state": "Minatitlan",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "medium_airport",
        "latitude": "18.10339928",
        "longitude": "-94.58070374",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 96359,
            "s_seats": 48103,
            "j_seats": 43346,
            "f_seats": 6185,
            "r_seats": 8694,
            "cargo_demand": 340726
        }
    },
    {
        "iata": "MTV",
        "icao": "NVSA",
        "airportName": "Mota Lava Airport",
        "location_country": "Vanuatu",
        "location_city": "Mota Lava",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.66600037",
        "longitude": "167.7120056",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2953",
                "runway_width": "82",
                "runway_material": "Coral"
            }
        ],
        "demand": {
            "y_seats": 2420,
            "s_seats": 2213,
            "j_seats": 1333,
            "f_seats": 227,
            "r_seats": 155,
            "cargo_demand": 39068
        }
    },
    {
        "iata": "MTW",
        "icao": "KMTW",
        "airportName": "Manitowoc County Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Manitowoc",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.128799",
        "longitude": "-87.680602",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3343",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5002",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1499,
            "s_seats": 1962,
            "j_seats": 1837,
            "f_seats": 155,
            "r_seats": 161,
            "cargo_demand": 36123
        }
    },
    {
        "iata": "MTY",
        "icao": "MMMY",
        "airportName": "General Mariano Escobedo International Airport",
        "location_country": "Mexico",
        "location_city": "Nuevo Leon",
        "location_state": "Monterrey",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "large_airport",
        "latitude": "25.7784996",
        "longitude": "-100.1070023",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5909",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 452600,
            "s_seats": 90503,
            "j_seats": 133543,
            "f_seats": 57370,
            "r_seats": 10000,
            "cargo_demand": 19334826
        }
    },
    {
        "iata": "MTZ",
        "icao": "LLMZ",
        "airportName": "Bar Yehuda Airfield",
        "location_country": "Israel",
        "location_city": "Masada",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "31.32819939",
        "longitude": "35.3885994",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50650,
            "s_seats": 39059,
            "j_seats": 34877,
            "f_seats": 13952,
            "r_seats": 4965,
            "cargo_demand": 416778
        }
    },
    {
        "iata": "MUA",
        "icao": "AGGM",
        "airportName": "Munda Airport",
        "location_country": "Solomon Islands",
        "location_city": "New Georgia",
        "location_state": "Munda",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.32797",
        "longitude": "157.263",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139331,
            "s_seats": 31437,
            "j_seats": 23558,
            "f_seats": 5267,
            "r_seats": 5412,
            "cargo_demand": 203231
        }
    },
    {
        "iata": "MUB",
        "icao": "FBMN",
        "airportName": "Maun Airport",
        "location_country": "Botswana",
        "location_city": "Maun",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.97260094",
        "longitude": "23.43110085",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 126560,
            "s_seats": 38556,
            "j_seats": 34777,
            "f_seats": 2659,
            "r_seats": 8049,
            "cargo_demand": 116763
        }
    },
    {
        "iata": "MUC",
        "icao": "EDDM",
        "airportName": "Munich Airport",
        "location_country": "Germany",
        "location_city": "Bavaria",
        "location_state": "Munich",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "48.353802",
        "longitude": "11.7861",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 289619,
            "s_seats": 74227,
            "j_seats": 92712,
            "f_seats": 61444,
            "r_seats": 10000,
            "cargo_demand": 17614105
        }
    },
    {
        "iata": "MUD",
        "icao": "FQMD",
        "airportName": "Mueda Airport",
        "location_country": "Mozambique",
        "location_city": "Mueda",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.6729002",
        "longitude": "39.56309891",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7714",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80441,
            "s_seats": 42654,
            "j_seats": 43641,
            "f_seats": 11498,
            "r_seats": 3625,
            "cargo_demand": 497781
        }
    },
    {
        "iata": "MUE",
        "icao": "PHMU",
        "airportName": "Waimea-Kohala Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Kamuela",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.001301",
        "longitude": "-155.667999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5197",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 75237,
            "s_seats": 36370,
            "j_seats": 13458,
            "f_seats": 16901,
            "r_seats": 5974,
            "cargo_demand": 309737
        }
    },
    {
        "iata": "MUH",
        "icao": "HEMM",
        "airportName": "Marsa Matruh International Airport",
        "location_country": "Egypt",
        "location_city": "Mersa Matruh",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.325399",
        "longitude": "27.221701",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154915,
            "s_seats": 46736,
            "j_seats": 11771,
            "f_seats": 4610,
            "r_seats": 7785,
            "cargo_demand": 113880
        }
    },
    {
        "iata": "MUI",
        "icao": "KMUI",
        "airportName": "Muir Army Airfield",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Fort Indiantown Gap",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "40.434799",
        "longitude": "-76.569397",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3967",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181871,
            "s_seats": 42647,
            "j_seats": 33546,
            "f_seats": 4056,
            "r_seats": 9153,
            "cargo_demand": 351400
        }
    },
    {
        "iata": "MUK",
        "icao": "NCMK",
        "airportName": "Mauke Airport",
        "location_country": "Cook Islands",
        "location_city": "Mauke Island",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.13610077",
        "longitude": "-157.3450012",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2889,
            "s_seats": 2409,
            "j_seats": 1859,
            "f_seats": 7,
            "r_seats": 126,
            "cargo_demand": 15769
        }
    },
    {
        "iata": "MUL",
        "icao": "KMUL",
        "airportName": "Spence Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Moultrie",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.141569",
        "longitude": "-83.703718",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5000",
                "runway_width": "300",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4500",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2900",
                "runway_width": "300",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2958,
            "s_seats": 1977,
            "j_seats": 1165,
            "f_seats": 45,
            "r_seats": 153,
            "cargo_demand": 42427
        }
    },
    {
        "iata": "MUN",
        "icao": "SVMT",
        "airportName": "Jose Tadeo Monagas International Airport",
        "location_country": "Venezuela",
        "location_city": "Maturin",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.749023",
        "longitude": "-63.153348",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136135,
            "s_seats": 41715,
            "j_seats": 30979,
            "f_seats": 12852,
            "r_seats": 5160,
            "cargo_demand": 121269
        }
    },
    {
        "iata": "MUO",
        "icao": "KMUO",
        "airportName": "Mountain Home Air Force Base",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Mountain Home",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "large_airport",
        "latitude": "43.043598",
        "longitude": "-115.872002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10000",
                "runway_width": "500",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "13500",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 339367,
            "s_seats": 78285,
            "j_seats": 122790,
            "f_seats": 39436,
            "r_seats": 10000,
            "cargo_demand": 15181525
        }
    },
    {
        "iata": "MUP",
        "icao": "YMUP",
        "airportName": "Mulga Park Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Mulga Park",
        "time_zone": "UTC+09:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.86000061",
        "longitude": "131.6499939",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3362,
            "s_seats": 2073,
            "j_seats": 1951,
            "f_seats": 136,
            "r_seats": 137,
            "cargo_demand": 94030
        }
    },
    {
        "iata": "MUQ",
        "icao": "YMUC",
        "airportName": "Muccan Station Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Muccan Station",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.65889931",
        "longitude": "120.0670013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3608",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2487,
            "s_seats": 2926,
            "j_seats": 1662,
            "f_seats": 481,
            "r_seats": 100,
            "cargo_demand": 12941
        }
    },
    {
        "iata": "MUR",
        "icao": "WBGM",
        "airportName": "Marudi Airport",
        "location_country": "Malaysia",
        "location_city": "Sarawak",
        "location_state": "Marudi",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.178979874",
        "longitude": "114.3290024",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3274",
                "runway_width": "81",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198046,
            "s_seats": 32449,
            "j_seats": 41106,
            "f_seats": 14233,
            "r_seats": 9329,
            "cargo_demand": 414251
        }
    },
    {
        "iata": "MUS",
        "icao": "RJAM",
        "airportName": "Minami Torishima Airport",
        "location_country": "Japan",
        "location_city": "Minami-Tori-shima",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "24.2897",
        "longitude": "153.979004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4500",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1389,
            "s_seats": 2703,
            "j_seats": 1881,
            "f_seats": 472,
            "r_seats": 189,
            "cargo_demand": 16480
        }
    },
    {
        "iata": "MUT",
        "icao": "KMUT",
        "airportName": "Muscatine Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Muscatine",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.367803",
        "longitude": "-91.148201",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3540,
            "s_seats": 2819,
            "j_seats": 1470,
            "f_seats": 281,
            "r_seats": 193,
            "cargo_demand": 29116
        }
    },
    {
        "iata": "MUW",
        "icao": "DAOV",
        "airportName": "Ghriss Airport",
        "location_country": "Algeria",
        "location_city": "Ghriss",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.207699",
        "longitude": "0.147142",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5577",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73520,
            "s_seats": 38598,
            "j_seats": 23284,
            "f_seats": 5179,
            "r_seats": 3123,
            "cargo_demand": 132089
        }
    },
    {
        "iata": "MUX",
        "icao": "OPMT",
        "airportName": "Multan International Airport",
        "location_country": "Pakistan",
        "location_city": "Multan",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.20319939",
        "longitude": "71.4190979",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9046",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165341,
            "s_seats": 36161,
            "j_seats": 32436,
            "f_seats": 10037,
            "r_seats": 8421,
            "cargo_demand": 63693
        }
    },
    {
        "iata": "MUZ",
        "icao": "HTMU",
        "airportName": "Musoma Airport",
        "location_country": "Tanzania",
        "location_city": "Musoma",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.503",
        "longitude": "33.8021",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5249",
                "runway_width": "108",
                "runway_material": "Hard"
            }
        ],
        "demand": {
            "y_seats": 1632,
            "s_seats": 2610,
            "j_seats": 1158,
            "f_seats": 111,
            "r_seats": 89,
            "cargo_demand": 26391
        }
    },
    {
        "iata": "MVB",
        "icao": "FOON",
        "airportName": "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
        "location_country": "Gabon",
        "location_city": "Franceville",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.656159997",
        "longitude": "13.43799973",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "10105",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81878,
            "s_seats": 27754,
            "j_seats": 20845,
            "f_seats": 12965,
            "r_seats": 9629,
            "cargo_demand": 414678
        }
    },
    {
        "iata": "MVC",
        "icao": "KMVC",
        "airportName": "Monroe County Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Monroeville",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "31.458",
        "longitude": "-87.350996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6016",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3452,
            "s_seats": 1921,
            "j_seats": 1685,
            "f_seats": 366,
            "r_seats": 14,
            "cargo_demand": 43447
        }
    },
    {
        "iata": "MVD",
        "icao": "SUMU",
        "airportName": "Carrasco Gral. Cesareo L. Berisso International Airport",
        "location_country": "Uruguay",
        "location_city": "Montevideo",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-34.838402",
        "longitude": "-56.0308",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7382",
                "runway_width": "148",
                "runway_material": "PEM"
            },
            {
                "runway_name": "06/24",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "PEM"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5577",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 478939,
            "s_seats": 83300,
            "j_seats": 65893,
            "f_seats": 39335,
            "r_seats": 10000,
            "cargo_demand": 18064378
        }
    },
    {
        "iata": "MVE",
        "icao": "KMVE",
        "airportName": "Montevideo-Chippewa County Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Montevideo",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.969101",
        "longitude": "-95.710297",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2330",
                "runway_width": "165",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1668,
            "s_seats": 2163,
            "j_seats": 1367,
            "f_seats": 351,
            "r_seats": 198,
            "cargo_demand": 27578
        }
    },
    {
        "iata": "MVF",
        "icao": "SBMS",
        "airportName": "Gov. Dix-Sept Rosado Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Norte",
        "location_state": "Mossoro",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.201920032",
        "longitude": "-37.36429977",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186938,
            "s_seats": 37291,
            "j_seats": 44958,
            "f_seats": 14847,
            "r_seats": 7714,
            "cargo_demand": 209190
        }
    },
    {
        "iata": "MVK",
        "icao": "YMUK",
        "airportName": "Mulka Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Mulka",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-28.3477993",
        "longitude": "138.6499939",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 2460,
            "s_seats": 2812,
            "j_seats": 1537,
            "f_seats": 293,
            "r_seats": 19,
            "cargo_demand": 66721
        }
    },
    {
        "iata": "MVL",
        "icao": "KMVL",
        "airportName": "Morrisville-Stowe State Airport",
        "location_country": "United States",
        "location_city": "Vermont",
        "location_state": "Morrisville / Stowe",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "44.5345993",
        "longitude": "-72.61399841",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3701",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1193,
            "s_seats": 2022,
            "j_seats": 1688,
            "f_seats": 406,
            "r_seats": 91,
            "cargo_demand": 77146
        }
    },
    {
        "iata": "MVN",
        "icao": "KMVN",
        "airportName": "Mount Vernon Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Mount Vernon",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "38.322774",
        "longitude": "-88.859257",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6498",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3149",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1887,
            "s_seats": 2735,
            "j_seats": 1535,
            "f_seats": 140,
            "r_seats": 42,
            "cargo_demand": 95656
        }
    },
    {
        "iata": "MVP",
        "icao": "SKMU",
        "airportName": "Fabio Alberto Leon Bentley Airport",
        "location_country": "Colombia",
        "location_city": "Mitu",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.25366",
        "longitude": "-70.2339",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5889",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117683,
            "s_seats": 43284,
            "j_seats": 58732,
            "f_seats": 19341,
            "r_seats": 4613,
            "cargo_demand": 184672
        }
    },
    {
        "iata": "MVQ",
        "icao": "UMOO",
        "airportName": "Mogilev Airport",
        "location_country": "Belarus",
        "location_city": "Mogilev",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.95489883",
        "longitude": "30.0951004",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8422",
                "runway_width": "138",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55555,
            "s_seats": 27474,
            "j_seats": 57119,
            "f_seats": 19710,
            "r_seats": 3640,
            "cargo_demand": 383545
        }
    },
    {
        "iata": "MVR",
        "icao": "FKKL",
        "airportName": "Salak Airport",
        "location_country": "Cameroon",
        "location_city": "Maroua",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.4513998",
        "longitude": "14.25739956",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145375,
            "s_seats": 43882,
            "j_seats": 21730,
            "f_seats": 13603,
            "r_seats": 6744,
            "cargo_demand": 471868
        }
    },
    {
        "iata": "MVS",
        "icao": "SNMU",
        "airportName": "Mucuri Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Mucuri",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.0489006",
        "longitude": "-39.86420059",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "4593",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1789,
            "s_seats": 2189,
            "j_seats": 1113,
            "f_seats": 155,
            "r_seats": 87,
            "cargo_demand": 26896
        }
    },
    {
        "iata": "MVT",
        "icao": "NTGV",
        "airportName": "Mataiva Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Mataiva",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.86810017",
        "longitude": "-148.7169952",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189526,
            "s_seats": 41607,
            "j_seats": 59166,
            "f_seats": 19226,
            "r_seats": 8097,
            "cargo_demand": 231939
        }
    },
    {
        "iata": "MVU",
        "icao": "YMGV",
        "airportName": "Musgrave Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Musgrave",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.7757",
        "longitude": "143.5047",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1184,
            "s_seats": 1732,
            "j_seats": 1460,
            "f_seats": 225,
            "r_seats": 117,
            "cargo_demand": 55369
        }
    },
    {
        "iata": "MVV",
        "icao": "LFHM",
        "airportName": "Megeve Altiport",
        "location_country": "France",
        "location_city": "Rhone-Alpes",
        "location_state": "Megeve",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "small_airport",
        "latitude": "45.823005",
        "longitude": "6.649406",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "1798",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3541,
            "s_seats": 1765,
            "j_seats": 1791,
            "f_seats": 116,
            "r_seats": 197,
            "cargo_demand": 69561
        }
    },
    {
        "iata": "MVW",
        "icao": "KBVS",
        "airportName": "Skagit Regional Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Burlington / Mount Vernon",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "48.47090149",
        "longitude": "-122.4209976",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5477",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3192,
            "s_seats": 1675,
            "j_seats": 1607,
            "f_seats": 169,
            "r_seats": 101,
            "cargo_demand": 36381
        }
    },
    {
        "iata": "MVY",
        "icao": "KMVY",
        "airportName": "Martha's Vineyard Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Vineyard Haven",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "41.39310074",
        "longitude": "-70.61430359",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3177",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "5504",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3297",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1861,
            "s_seats": 1854,
            "j_seats": 1302,
            "f_seats": 19,
            "r_seats": 109,
            "cargo_demand": 45682
        }
    },
    {
        "iata": "MVZ",
        "icao": "FVMV",
        "airportName": "Masvingo Airport",
        "location_country": "Zimbabwe",
        "location_city": "Masvingo",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.05529976",
        "longitude": "30.85910034",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3937",
                "runway_width": "59",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5663",
                "runway_width": "59",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 61832,
            "s_seats": 30093,
            "j_seats": 23385,
            "f_seats": 5929,
            "r_seats": 9010,
            "cargo_demand": 136213
        }
    },
    {
        "iata": "MWA",
        "icao": "KMWA",
        "airportName": "Williamson County Regional Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Marion",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "37.751208",
        "longitude": "-89.016568",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8012",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1870,
            "s_seats": 1868,
            "j_seats": 1587,
            "f_seats": 232,
            "r_seats": 119,
            "cargo_demand": 23398
        }
    },
    {
        "iata": "MWB",
        "icao": "YMRW",
        "airportName": "Morawa Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Morawa",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-29.20170021",
        "longitude": "116.0220032",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3034",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5298",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "15/33",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2662,
            "s_seats": 2507,
            "j_seats": 1625,
            "f_seats": 322,
            "r_seats": 78,
            "cargo_demand": 81557
        }
    },
    {
        "iata": "MWC",
        "icao": "KMWC",
        "airportName": "Lawrence J. Timmerman Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Milwaukee",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.110401",
        "longitude": "-88.034401",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "3202",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "2862",
                "runway_width": "275",
                "runway_material": "GRS"
            },
            {
                "runway_name": "15L/33R",
                "runway_length": "4106",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "3251",
                "runway_width": "275",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1735,
            "s_seats": 1507,
            "j_seats": 1822,
            "f_seats": 413,
            "r_seats": 49,
            "cargo_demand": 28352
        }
    },
    {
        "iata": "MWD",
        "icao": "OPMI",
        "airportName": "PAF Base M.M. Alam",
        "location_country": "Pakistan",
        "location_city": "Mianwali",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.56309891",
        "longitude": "71.5707016",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "10348",
                "runway_width": "97",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "10161",
                "runway_width": "156",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101390,
            "s_seats": 28574,
            "j_seats": 20504,
            "f_seats": 1035,
            "r_seats": 6344,
            "cargo_demand": 426613
        }
    },
    {
        "iata": "MWE",
        "icao": "HSMR",
        "airportName": "Merowe Airport",
        "location_country": "Sudan",
        "location_city": "Merowe",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.455",
        "longitude": "31.8185",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2548,
            "s_seats": 2936,
            "j_seats": 1382,
            "f_seats": 232,
            "r_seats": 89,
            "cargo_demand": 96486
        }
    },
    {
        "iata": "MWF",
        "icao": "NVSN",
        "airportName": "Maewo-Naone Airport",
        "location_country": "Vanuatu",
        "location_city": "Maewo",
        "location_state": "",
        "time_zone": "UTC+11:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15",
        "longitude": "168.0829926",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2789",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3148,
            "s_seats": 1586,
            "j_seats": 1476,
            "f_seats": 227,
            "r_seats": 139,
            "cargo_demand": 3602
        }
    },
    {
        "iata": "MWH",
        "icao": "KMWH",
        "airportName": "Grant County International Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Moses Lake",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "47.20769882",
        "longitude": "-119.3199997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10000",
                "runway_width": "100",
                "runway_material": "PEM"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3500",
                "runway_width": "90",
                "runway_material": "CON"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "13503",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "2937",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3307",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168464,
            "s_seats": 38070,
            "j_seats": 53013,
            "f_seats": 13965,
            "r_seats": 3433,
            "cargo_demand": 280769
        }
    },
    {
        "iata": "MWK",
        "icao": "WIOM",
        "airportName": "Matak Airport",
        "location_country": "Indonesia",
        "location_city": "Matak",
        "location_state": "",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "3.348119974",
        "longitude": "106.2580032",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2818,
            "s_seats": 1683,
            "j_seats": 1731,
            "f_seats": 433,
            "r_seats": 132,
            "cargo_demand": 32606
        }
    },
    {
        "iata": "MWL",
        "icao": "KMWL",
        "airportName": "Mineral Wells Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Mineral Wells",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.781601",
        "longitude": "-98.060204",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5996",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4188",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1500,
            "s_seats": 2832,
            "j_seats": 1038,
            "f_seats": 296,
            "r_seats": 104,
            "cargo_demand": 23040
        }
    },
    {
        "iata": "MWM",
        "icao": "KMWM",
        "airportName": "Windom Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Windom",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "43.91339874",
        "longitude": "-95.10939789",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3599",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2254,
            "s_seats": 1514,
            "j_seats": 1047,
            "f_seats": 222,
            "r_seats": 79,
            "cargo_demand": 80594
        }
    },
    {
        "iata": "MWO",
        "icao": "KMWO",
        "airportName": "Middletown Regional Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Middletown",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "39.530998",
        "longitude": "-84.395302",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6100",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "2984",
                "runway_width": "300",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1825,
            "s_seats": 2490,
            "j_seats": 1214,
            "f_seats": 484,
            "r_seats": 150,
            "cargo_demand": 37955
        }
    },
    {
        "iata": "MWT",
        "icao": "YMWT",
        "airportName": "Moolawatana Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Moolawatana Station",
        "time_zone": "UTC+09:30",
        "daylight_saving": "Oct-Apr",
        "airport_size": "small_airport",
        "latitude": "-29.9069",
        "longitude": "139.765",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "S"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4068",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 1831,
            "s_seats": 1567,
            "j_seats": 1204,
            "f_seats": 89,
            "r_seats": 11,
            "cargo_demand": 57961
        }
    },
    {
        "iata": "MWX",
        "icao": "RKJB",
        "airportName": "Muan International Airport",
        "location_country": "South Korea",
        "location_city": "Muan",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "34.991406",
        "longitude": "126.382814",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9186",
                "runway_width": "",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 185089,
            "s_seats": 86190,
            "j_seats": 57834,
            "f_seats": 69238,
            "r_seats": 10000,
            "cargo_demand": 29930851
        }
    },
    {
        "iata": "MWZ",
        "icao": "HTMW",
        "airportName": "Mwanza Airport",
        "location_country": "Tanzania",
        "location_city": "Mwanza",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.444489956",
        "longitude": "32.93270111",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "10212",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183126,
            "s_seats": 35097,
            "j_seats": 44401,
            "f_seats": 3639,
            "r_seats": 7140,
            "cargo_demand": 61068
        }
    },
    {
        "iata": "MXA",
        "icao": "KMXA",
        "airportName": "Manila Municipal Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Manila",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "35.894402",
        "longitude": "-90.154602",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4200",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2095,
            "s_seats": 2234,
            "j_seats": 1007,
            "f_seats": 356,
            "r_seats": 189,
            "cargo_demand": 4312
        }
    },
    {
        "iata": "MXB",
        "icao": "WAWM",
        "airportName": "Andi Jemma Airport",
        "location_country": "Indonesia",
        "location_city": "Masamba",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.55803",
        "longitude": "120.323997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3133",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2979,
            "s_seats": 1987,
            "j_seats": 1020,
            "f_seats": 432,
            "r_seats": 198,
            "cargo_demand": 95786
        }
    },
    {
        "iata": "MXD",
        "icao": "YMWX",
        "airportName": "Marion Downs Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Marion Downs Station",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-23.363702",
        "longitude": "139.649663",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2798",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1023,
            "s_seats": 2611,
            "j_seats": 1369,
            "f_seats": 70,
            "r_seats": 84,
            "cargo_demand": 50914
        }
    },
    {
        "iata": "MXE",
        "icao": "KMEB",
        "airportName": "Laurinburg-Maxton Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Laurinburg / Maxton",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "34.791901",
        "longitude": "-79.365799",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6489",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3753",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1778,
            "s_seats": 2985,
            "j_seats": 1017,
            "f_seats": 221,
            "r_seats": 18,
            "cargo_demand": 15641
        }
    },
    {
        "iata": "MXF",
        "icao": "KMXF",
        "airportName": "Maxwell Air Force Base",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Montgomery",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.3829",
        "longitude": "-86.365799",
        "region": "NA",
        "runway": [
            {
                "runway_name": "007/187",
                "runway_length": "3015",
                "runway_width": "60",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "15/33",
                "runway_length": "8013",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105167,
            "s_seats": 39577,
            "j_seats": 53996,
            "f_seats": 10168,
            "r_seats": 6005,
            "cargo_demand": 499079
        }
    },
    {
        "iata": "MXH",
        "icao": "AYMR",
        "airportName": "Moro Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Moro",
        "location_state": "",
        "time_zone": "UTC+10:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.363329887",
        "longitude": "143.2380066",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5774",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2045,
            "s_seats": 2657,
            "j_seats": 1911,
            "f_seats": 112,
            "r_seats": 104,
            "cargo_demand": 28289
        }
    },
    {
        "iata": "MXI",
        "icao": "RPMQ",
        "airportName": "Mati Airport",
        "location_country": "Philippines",
        "location_city": "Mati",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.949091",
        "longitude": "126.27368",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5330",
                "runway_width": "105",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 55389,
            "s_seats": 33030,
            "j_seats": 31278,
            "f_seats": 10554,
            "r_seats": 3988,
            "cargo_demand": 99118
        }
    },
    {
        "iata": "MXJ",
        "icao": "DNMN",
        "airportName": "Minna Airport",
        "location_country": "Nigeria",
        "location_city": "Minna",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.652170181",
        "longitude": "6.462259769",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11153",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194681,
            "s_seats": 31217,
            "j_seats": 27747,
            "f_seats": 16327,
            "r_seats": 5375,
            "cargo_demand": 335637
        }
    },
    {
        "iata": "MXL",
        "icao": "MMML",
        "airportName": "General Rodolfo Sanchez Taboada International Airport",
        "location_country": "Mexico",
        "location_city": "Baja California",
        "location_state": "Mexicali",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "32.6306",
        "longitude": "-115.241997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 112612,
            "s_seats": 29649,
            "j_seats": 14350,
            "f_seats": 1084,
            "r_seats": 9974,
            "cargo_demand": 207390
        }
    },
    {
        "iata": "MXM",
        "icao": "FMSR",
        "airportName": "Morombe Airport",
        "location_country": "Madagascar",
        "location_city": "Morombe",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.753837",
        "longitude": "43.374753",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 119967,
            "s_seats": 27165,
            "j_seats": 59685,
            "f_seats": 16225,
            "r_seats": 7116,
            "cargo_demand": 181544
        }
    },
    {
        "iata": "MXN",
        "icao": "LFRU",
        "airportName": "Morlaix - Ploujean Airport",
        "location_country": "France",
        "location_city": "Brittany",
        "location_state": "Morlaix",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "48.60319901",
        "longitude": "-3.815779924",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5305",
                "runway_width": "118",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "26",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 139588,
            "s_seats": 40225,
            "j_seats": 56523,
            "f_seats": 8124,
            "r_seats": 7292,
            "cargo_demand": 218368
        }
    },
    {
        "iata": "MXO",
        "icao": "KMXO",
        "airportName": "Monticello Regional Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Monticello",
        "time_zone": "UTC+06:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "42.22040176",
        "longitude": "-91.16329956",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2275",
                "runway_width": "90",
                "runway_material": "GRS"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4400",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1449,
            "s_seats": 2618,
            "j_seats": 1655,
            "f_seats": 80,
            "r_seats": 109,
            "cargo_demand": 39706
        }
    },
    {
        "iata": "MXP",
        "icao": "LIMC",
        "airportName": "Milan-Malpensa Airport",
        "location_country": "Italy",
        "location_city": "Lombardy",
        "location_state": "Milan",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "large_airport",
        "latitude": "45.6306",
        "longitude": "8.72811",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "12861",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "12861",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 213422,
            "s_seats": 90801,
            "j_seats": 91372,
            "f_seats": 47254,
            "r_seats": 10000,
            "cargo_demand": 21143681
        }
    },
    {
        "iata": "MXU",
        "icao": "YMWA",
        "airportName": "Mullewa Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Mullewa",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.47500038",
        "longitude": "115.5169983",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4721",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3903,
            "s_seats": 2989,
            "j_seats": 1505,
            "f_seats": 446,
            "r_seats": 36,
            "cargo_demand": 91987
        }
    },
    {
        "iata": "MXV",
        "icao": "ZMMN",
        "airportName": "Moron Airport",
        "location_country": "Mongolia",
        "location_city": "Moron",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.66329956",
        "longitude": "100.098999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8005",
                "runway_width": "137",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168914,
            "s_seats": 46561,
            "j_seats": 51642,
            "f_seats": 11841,
            "r_seats": 8760,
            "cargo_demand": 183669
        }
    },
    {
        "iata": "MXX",
        "icao": "ESKM",
        "airportName": "Mora-Siljan Airport",
        "location_country": "Sweden",
        "location_city": "Mora",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "Mar-Oct",
        "airport_size": "medium_airport",
        "latitude": "60.957901",
        "longitude": "14.5114",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5951",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77883,
            "s_seats": 34345,
            "j_seats": 51172,
            "f_seats": 7185,
            "r_seats": 5461,
            "cargo_demand": 102305
        }
    },
    {
        "iata": "MXY",
        "icao": "PAMX",
        "airportName": "McCarthy Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "McCarthy",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "61.43709946",
        "longitude": "-142.904007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3500",
                "runway_width": "60",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 1672,
            "s_seats": 2289,
            "j_seats": 1825,
            "f_seats": 69,
            "r_seats": 64,
            "cargo_demand": 65025
        }
    },
    {
        "iata": "MYA",
        "icao": "YMRY",
        "airportName": "Moruya Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Moruya",
        "time_zone": "UTC+10:00",
        "daylight_saving": "Oct-Apr",
        "airport_size": "medium_airport",
        "latitude": "-35.89780045",
        "longitude": "150.1439972",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2713",
                "runway_width": "60",
                "runway_material": "GVL"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4997",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135107,
            "s_seats": 38703,
            "j_seats": 49333,
            "f_seats": 18991,
            "r_seats": 9733,
            "cargo_demand": 64359
        }
    },
    {
        "iata": "MYC",
        "icao": "SVBS",
        "airportName": "Mariscal Sucre Airport",
        "location_country": "Venezuela",
        "location_city": "Maracay",
        "location_state": "",
        "time_zone": "UTC+04:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.24997807",
        "longitude": "-67.64942169",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4453",
                "runway_width": "131",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6846",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73736,
            "s_seats": 30395,
            "j_seats": 23255,
            "f_seats": 19768,
            "r_seats": 5575,
            "cargo_demand": 172397
        }
    },
    {
        "iata": "MYD",
        "icao": "HKML",
        "airportName": "Malindi Airport",
        "location_country": "Kenya",
        "location_city": "Malindi",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.229310036",
        "longitude": "40.10169983",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3701",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4600",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 167433,
            "s_seats": 46464,
            "j_seats": 57046,
            "f_seats": 4527,
            "r_seats": 3357,
            "cargo_demand": 392056
        }
    },
    {
        "iata": "MYE",
        "icao": "RJTQ",
        "airportName": "Miyakejima Airport",
        "location_country": "Japan",
        "location_city": "Miyake-jima",
        "location_state": "",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.07360077",
        "longitude": "139.5599976",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4350",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152908,
            "s_seats": 46311,
            "j_seats": 24422,
            "f_seats": 4280,
            "r_seats": 9040,
            "cargo_demand": 70603
        }
    },
    {
        "iata": "MYF",
        "icao": "KMYF",
        "airportName": "Montgomery Field Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "San Diego",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "32.8157",
        "longitude": "-117.139999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3400",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "4577",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "3400",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1550,
            "s_seats": 2797,
            "j_seats": 1495,
            "f_seats": 348,
            "r_seats": 156,
            "cargo_demand": 14491
        }
    },
    {
        "iata": "MYG",
        "icao": "MYMM",
        "airportName": "Mayaguana Airport",
        "location_country": "Bahamas",
        "location_city": "Mayaguana",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "22.379499",
        "longitude": "-73.013494",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7297",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89310,
            "s_seats": 38147,
            "j_seats": 25234,
            "f_seats": 2344,
            "r_seats": 4695,
            "cargo_demand": 176012
        }
    },
    {
        "iata": "MYJ",
        "icao": "RJOM",
        "airportName": "Matsuyama Airport",
        "location_country": "Japan",
        "location_city": "Shikoku",
        "location_state": "Matsuyama",
        "time_zone": "UTC+09:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.82720184",
        "longitude": "132.6999969",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124203,
            "s_seats": 47009,
            "j_seats": 56968,
            "f_seats": 16925,
            "r_seats": 9402,
            "cargo_demand": 237023
        }
    },
    {
        "iata": "MYL",
        "icao": "KMYL",
        "airportName": "McCall Municipal Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "McCall",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "44.88970184",
        "longitude": "-116.1009979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6107",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149092,
            "s_seats": 31258,
            "j_seats": 27767,
            "f_seats": 2578,
            "r_seats": 9032,
            "cargo_demand": 135821
        }
    },
    {
        "iata": "MYN",
        "icao": "OYMB",
        "airportName": "Marib Airport",
        "location_country": "Yemen",
        "location_city": "Ma'rib",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "15.46920013",
        "longitude": "45.32690048",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9843",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1638,
            "s_seats": 1922,
            "j_seats": 1230,
            "f_seats": 161,
            "r_seats": 179,
            "cargo_demand": 84640
        }
    },
    {
        "iata": "MYO",
        "icao": "YMYR",
        "airportName": "Myroodah Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Myroodah Station",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.11669922",
        "longitude": "124.2669983",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3443,
            "s_seats": 2149,
            "j_seats": 1494,
            "f_seats": 263,
            "r_seats": 29,
            "cargo_demand": 83637
        }
    },
    {
        "iata": "MYP",
        "icao": "UTAM",
        "airportName": "Mary International Airport",
        "location_country": "Turkmenistan",
        "location_city": "Mary",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.62353",
        "longitude": "61.895668",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "9107",
                "runway_width": "147",
                "runway_material": "CON"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "7994",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 65630,
            "s_seats": 26713,
            "j_seats": 35936,
            "f_seats": 1747,
            "r_seats": 5913,
            "cargo_demand": 321592
        }
    },
    {
        "iata": "MYQ",
        "icao": "VOMY",
        "airportName": "Mysore Airport",
        "location_country": "India",
        "location_city": "Karnataka",
        "location_state": "Mysore",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "12.23",
        "longitude": "76.655833",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5709",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2606,
            "s_seats": 2290,
            "j_seats": 1882,
            "f_seats": 226,
            "r_seats": 120,
            "cargo_demand": 83241
        }
    },
    {
        "iata": "MYR",
        "icao": "KMYR",
        "airportName": "Myrtle Beach International Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Myrtle Beach",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "33.67969894",
        "longitude": "-78.92829895",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9503",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 107977,
            "s_seats": 29379,
            "j_seats": 10763,
            "f_seats": 2807,
            "r_seats": 3610,
            "cargo_demand": 483411
        }
    },
    {
        "iata": "MYT",
        "icao": "VYMK",
        "airportName": "Myitkyina Airport",
        "location_country": "Myanmar",
        "location_city": "Myitkyina",
        "location_state": "",
        "time_zone": "UTC+06:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.38360023",
        "longitude": "97.35189819",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6100",
                "runway_width": "100",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 83533,
            "s_seats": 26228,
            "j_seats": 46134,
            "f_seats": 1796,
            "r_seats": 4082,
            "cargo_demand": 125959
        }
    },
    {
        "iata": "MYU",
        "icao": "PAMY",
        "airportName": "Mekoryuk Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Mekoryuk",
        "time_zone": "UTC+09:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "60.37139893",
        "longitude": "-166.2709961",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3070",
                "runway_width": "75",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 188252,
            "s_seats": 42507,
            "j_seats": 11140,
            "f_seats": 13574,
            "r_seats": 5268,
            "cargo_demand": 48346
        }
    },
    {
        "iata": "MYV",
        "icao": "KMYV",
        "airportName": "Yuba County Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Marysville",
        "time_zone": "UTC+08:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "39.09780121",
        "longitude": "-121.5699997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3281",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6006",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138813,
            "s_seats": 43183,
            "j_seats": 34961,
            "f_seats": 1820,
            "r_seats": 6375,
            "cargo_demand": 170309
        }
    },
    {
        "iata": "MYW",
        "icao": "HTMT",
        "airportName": "Mtwara Airport",
        "location_country": "Tanzania",
        "location_city": "Mtwara",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-10.336204",
        "longitude": "40.181997",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7410",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3815",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 50717,
            "s_seats": 42954,
            "j_seats": 36308,
            "f_seats": 5799,
            "r_seats": 6023,
            "cargo_demand": 330979
        }
    },
    {
        "iata": "MYY",
        "icao": "WBGR",
        "airportName": "Miri Airport",
        "location_country": "Malaysia",
        "location_city": "Sarawak",
        "location_state": "Miri",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.32201004",
        "longitude": "113.9869995",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9006",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116840,
            "s_seats": 40495,
            "j_seats": 8601,
            "f_seats": 19205,
            "r_seats": 4773,
            "cargo_demand": 457794
        }
    },
    {
        "iata": "MZA",
        "icao": "SPMF",
        "airportName": "Manuel Prado Ugarteche Airport",
        "location_country": "Peru",
        "location_city": "Mazamari",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.3254",
        "longitude": "-74.535598",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5775",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2301,
            "s_seats": 1975,
            "j_seats": 1938,
            "f_seats": 338,
            "r_seats": 91,
            "cargo_demand": 6984
        }
    },
    {
        "iata": "MZB",
        "icao": "FQMP",
        "airportName": "Mocimboa da Praia Airport",
        "location_country": "Mozambique",
        "location_city": "Mocimboa da Praia",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.36180019",
        "longitude": "40.35490036",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6562",
                "runway_width": "88",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78625,
            "s_seats": 27167,
            "j_seats": 12459,
            "f_seats": 3924,
            "r_seats": 9992,
            "cargo_demand": 99509
        }
    },
    {
        "iata": "MZG",
        "icao": "RCQC",
        "airportName": "Magong Airport",
        "location_country": "Taiwan",
        "location_city": "Magong",
        "location_state": "",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.568701",
        "longitude": "119.627998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 168353,
            "s_seats": 25500,
            "j_seats": 29374,
            "f_seats": 10625,
            "r_seats": 8627,
            "cargo_demand": 269880
        }
    },
    {
        "iata": "MZH",
        "icao": "LTAP",
        "airportName": "Amasya Merzifon Airport",
        "location_country": "Turkey",
        "location_city": "Amasya",
        "location_state": "",
        "time_zone": "UTC+03:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.829399",
        "longitude": "35.521999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9600",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1057,
            "s_seats": 2586,
            "j_seats": 1913,
            "f_seats": 255,
            "r_seats": 138,
            "cargo_demand": 53125
        }
    },
    {
        "iata": "MZI",
        "icao": "GAMB",
        "airportName": "Mopti Airport",
        "location_country": "Mali",
        "location_city": "Mopti",
        "location_state": "",
        "time_zone": "UTC\u00c2\u00b100:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.51280022",
        "longitude": "-4.079559803",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8340",
                "runway_width": "94",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176105,
            "s_seats": 48891,
            "j_seats": 14439,
            "f_seats": 6726,
            "r_seats": 7402,
            "cargo_demand": 391762
        }
    },
    {
        "iata": "MZJ",
        "icao": "KMZJ",
        "airportName": "Pinal Airpark",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Marana",
        "time_zone": "UTC+07:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.51060104",
        "longitude": "-111.3280029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6690",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6849",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1631,
            "s_seats": 2824,
            "j_seats": 1073,
            "f_seats": 34,
            "r_seats": 152,
            "cargo_demand": 88447
        }
    },
    {
        "iata": "MZL",
        "icao": "SKMZ",
        "airportName": "La Nubia Airport",
        "location_country": "Colombia",
        "location_city": "Manizales",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.0296",
        "longitude": "-75.4647",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4835",
                "runway_width": "65",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136517,
            "s_seats": 26811,
            "j_seats": 57602,
            "f_seats": 2510,
            "r_seats": 6507,
            "cargo_demand": 127221
        }
    },
    {
        "iata": "MZO",
        "icao": "MUMZ",
        "airportName": "Sierra Maestra Airport",
        "location_country": "Cuba",
        "location_city": "Manzanillo",
        "location_state": "",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "medium_airport",
        "latitude": "20.28809929",
        "longitude": "-77.08920288",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7875",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185872,
            "s_seats": 48735,
            "j_seats": 33983,
            "f_seats": 8338,
            "r_seats": 3090,
            "cargo_demand": 486219
        }
    },
    {
        "iata": "MZP",
        "icao": "NZMK",
        "airportName": "Motueka Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Motueka",
        "location_state": "",
        "time_zone": "UTC+12:00",
        "daylight_saving": "Sep-Apr",
        "airport_size": "small_airport",
        "latitude": "-41.12329865",
        "longitude": "172.9889984",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2562",
                "runway_width": "36",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "2405",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1788,
            "s_seats": 2233,
            "j_seats": 1561,
            "f_seats": 238,
            "r_seats": 42,
            "cargo_demand": 85820
        }
    },
    {
        "iata": "MZQ",
        "icao": "FAMU",
        "airportName": "Mkuze Airport",
        "location_country": "South Africa",
        "location_city": "Mkuze",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.62610054",
        "longitude": "32.04430008",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6059",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 65387,
            "s_seats": 43523,
            "j_seats": 41453,
            "f_seats": 2264,
            "r_seats": 9693,
            "cargo_demand": 121266
        }
    },
    {
        "iata": "MZR",
        "icao": "OAMS",
        "airportName": "Mazar-e Sharif International Airport",
        "location_country": "Afghanistan",
        "location_city": "Mazar-i-Sharif",
        "location_state": "",
        "time_zone": "UTC+04:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.706902",
        "longitude": "67.209702",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "10437",
                "runway_width": "146",
                "runway_material": "CONCRETE AND ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "9843",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132811,
            "s_seats": 26551,
            "j_seats": 19023,
            "f_seats": 8760,
            "r_seats": 7877,
            "cargo_demand": 402395
        }
    },
    {
        "iata": "MZT",
        "icao": "MMMZ",
        "airportName": "General Rafael Buelna International Airport",
        "location_country": "Mexico",
        "location_city": "Sinaloa",
        "location_state": "Mazatlan",
        "time_zone": "UTC+07:00",
        "daylight_saving": "Apr-Oct",
        "airport_size": "large_airport",
        "latitude": "23.1614",
        "longitude": "-106.265999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8858",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 448780,
            "s_seats": 86671,
            "j_seats": 26881,
            "f_seats": 57153,
            "r_seats": 10000,
            "cargo_demand": 26719372
        }
    },
    {
        "iata": "MZU",
        "icao": "VEMZ",
        "airportName": "Muzaffarpur Airport",
        "location_country": "India",
        "location_city": "Bihar",
        "location_state": "Muzaffarpur",
        "time_zone": "UTC+05:30",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.11910057",
        "longitude": "85.31369781",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3990",
                "runway_width": "90",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 141014,
            "s_seats": 33305,
            "j_seats": 35516,
            "f_seats": 17439,
            "r_seats": 3981,
            "cargo_demand": 259409
        }
    },
    {
        "iata": "MZV",
        "icao": "WBMU",
        "airportName": "Mulu Airport",
        "location_country": "Malaysia",
        "location_city": "Sarawak",
        "location_state": "Mulu",
        "time_zone": "UTC+08:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.04832983",
        "longitude": "114.8050003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4921",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173291,
            "s_seats": 42677,
            "j_seats": 44946,
            "f_seats": 18491,
            "r_seats": 9063,
            "cargo_demand": 274027
        }
    },
    {
        "iata": "MZW",
        "icao": "DAAY",
        "airportName": "Mecheria Airport",
        "location_country": "Algeria",
        "location_city": "Mecheria",
        "location_state": "",
        "time_zone": "UTC+01:00",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.53590012",
        "longitude": "-0.242353007",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "11780",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "9436",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112424,
            "s_seats": 45460,
            "j_seats": 51062,
            "f_seats": 6475,
            "r_seats": 9291,
            "cargo_demand": 182672
        }
    },
    {
        "iata": "MZY",
        "icao": "FAMO",
        "airportName": "Mossel Bay Airport",
        "location_country": "South Africa",
        "location_city": "Mossel Bay",
        "location_state": "",
        "time_zone": "UTC+02:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-34.158298",
        "longitude": "22.058599",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3694",
                "runway_width": "",
                "runway_material": "Tar"
            }
        ],
        "demand": {
            "y_seats": 1524,
            "s_seats": 2313,
            "j_seats": 1112,
            "f_seats": 220,
            "r_seats": 146,
            "cargo_demand": 96205
        }
    },
    {
        "iata": "MZZ",
        "icao": "KMZZ",
        "airportName": "Marion Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Marion",
        "time_zone": "UTC+05:00",
        "daylight_saving": "Mar-Nov",
        "airport_size": "small_airport",
        "latitude": "40.48989868",
        "longitude": "-85.67970276",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3596",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1695,
            "s_seats": 1615,
            "j_seats": 1229,
            "f_seats": 127,
            "r_seats": 6,
            "cargo_demand": 18608
        }
    },
    {
        "iata": "NAA",
        "icao": "YNBR",
        "airportName": "Narrabri Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Narrabri",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-30.31920052",
        "longitude": "149.8269959",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3980",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72083,
            "s_seats": 34395,
            "j_seats": 16112,
            "f_seats": 7347,
            "r_seats": 4872,
            "cargo_demand": 484006
        }
    },
    {
        "iata": "NAC",
        "icao": "YNRC",
        "airportName": "Naracoorte Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Naracoorte",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-36.98529816",
        "longitude": "140.7250061",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3668",
                "runway_width": "98",
                "runway_material": "GRE"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3438",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1319,
            "s_seats": 1968,
            "j_seats": 1484,
            "f_seats": 7,
            "r_seats": 125,
            "cargo_demand": 55776
        }
    },
    {
        "iata": "NAG",
        "icao": "VANP",
        "airportName": "Dr. Babasaheb Ambedkar International Airport",
        "location_country": "India",
        "location_city": "Maharashtra",
        "location_state": "Nagpur",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.092199",
        "longitude": "79.047203",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6358",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "10500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182650,
            "s_seats": 49914,
            "j_seats": 49443,
            "f_seats": 13010,
            "r_seats": 7720,
            "cargo_demand": 353960
        }
    },
    {
        "iata": "NAH",
        "icao": "WAMH",
        "airportName": "Naha Airport",
        "location_country": "Indonesia",
        "location_city": "Tahuna",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.683209896",
        "longitude": "125.5279999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3597",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140094,
            "s_seats": 47855,
            "j_seats": 32374,
            "f_seats": 2546,
            "r_seats": 3292,
            "cargo_demand": 216197
        }
    },
    {
        "iata": "NAJ",
        "icao": "UBBN",
        "airportName": "Nakhchivan International Airport",
        "location_country": "Azerbaijan",
        "location_city": "Nakhchivan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.18880081",
        "longitude": "45.45840073",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "10826",
                "runway_width": "138",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "10826",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 87066,
            "s_seats": 34014,
            "j_seats": 38446,
            "f_seats": 2548,
            "r_seats": 9783,
            "cargo_demand": 402369
        }
    },
    {
        "iata": "NAK",
        "icao": "VTUQ",
        "airportName": "Nakhon Ratchasima Airport",
        "location_country": "Thailand",
        "location_city": "Nakhon Ratchasima",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.9495",
        "longitude": "102.313004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69454,
            "s_seats": 33053,
            "j_seats": 33905,
            "f_seats": 2007,
            "r_seats": 3728,
            "cargo_demand": 157901
        }
    },
    {
        "iata": "NAL",
        "icao": "URMN",
        "airportName": "Nalchik Airport",
        "location_country": "Russia",
        "location_city": "Kabardino-Balkaria",
        "location_state": "Nalchik",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.51290131",
        "longitude": "43.63660049",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7218",
                "runway_width": "138",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103898,
            "s_seats": 40436,
            "j_seats": 16282,
            "f_seats": 5225,
            "r_seats": 8775,
            "cargo_demand": 352481
        }
    },
    {
        "iata": "NAM",
        "icao": "WAPR",
        "airportName": "Namlea Airport",
        "location_country": "Indonesia",
        "location_city": "Namlea",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.235569954",
        "longitude": "127.0999985",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2460",
                "runway_width": "75",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 2508,
            "s_seats": 2488,
            "j_seats": 1549,
            "f_seats": 321,
            "r_seats": 143,
            "cargo_demand": 45814
        }
    },
    {
        "iata": "NAN",
        "icao": "NFFN",
        "airportName": "Nadi International Airport",
        "location_country": "Fiji",
        "location_city": "Nadi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.7553997",
        "longitude": "177.4429932",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "10739",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "7007",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78250,
            "s_seats": 44022,
            "j_seats": 19086,
            "f_seats": 16841,
            "r_seats": 4291,
            "cargo_demand": 303830
        }
    },
    {
        "iata": "NAP",
        "icao": "LIRN",
        "airportName": "Naples International Airport",
        "location_country": "Italy",
        "location_city": "Campania",
        "location_state": "Naples",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.886002",
        "longitude": "14.2908",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8622",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 477786,
            "s_seats": 95372,
            "j_seats": 110150,
            "f_seats": 52862,
            "r_seats": 10000,
            "cargo_demand": 11162251
        }
    },
    {
        "iata": "NAQ",
        "icao": "BGQQ",
        "airportName": "Qaanaaq Airport",
        "location_country": "Greenland",
        "location_city": "Qaanaaq",
        "location_state": "",
        "time_zone": "UTC-04:00",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "77.48860168",
        "longitude": "-69.38870239",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "2953",
                "runway_width": "",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 3679,
            "s_seats": 2672,
            "j_seats": 1962,
            "f_seats": 44,
            "r_seats": 52,
            "cargo_demand": 28751
        }
    },
    {
        "iata": "NAR",
        "icao": "SKPN",
        "airportName": "Puerto Nare Airport",
        "location_country": "Colombia",
        "location_city": "Puerto Nare",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "6.21002",
        "longitude": "-74.5906",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3310",
                "runway_width": "35",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3062,
            "s_seats": 2081,
            "j_seats": 1900,
            "f_seats": 260,
            "r_seats": 110,
            "cargo_demand": 84951
        }
    },
    {
        "iata": "NAS",
        "icao": "MYNN",
        "airportName": "Lynden Pindling International Airport",
        "location_country": "Bahamas",
        "location_city": "New Providence",
        "location_state": "Nassau",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "25.039",
        "longitude": "-77.466202",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8273",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "11353",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 234161,
            "s_seats": 92501,
            "j_seats": 169122,
            "f_seats": 58300,
            "r_seats": 10000,
            "cargo_demand": 6838244
        }
    },
    {
        "iata": "NAT",
        "icao": "SBNT",
        "airportName": "Augusto Severo International Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Norte",
        "location_state": "Natal",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.91142",
        "longitude": "-35.2477",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5987",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "5905",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64116,
            "s_seats": 38034,
            "j_seats": 55954,
            "f_seats": 1093,
            "r_seats": 4432,
            "cargo_demand": 419797
        }
    },
    {
        "iata": "NAV",
        "icao": "LTAZ",
        "airportName": "Nevsehir Kapadokya Airport",
        "location_country": "Turkey",
        "location_city": "Nevsehir",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.7719",
        "longitude": "34.5345",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1718,
            "s_seats": 1866,
            "j_seats": 1498,
            "f_seats": 299,
            "r_seats": 61,
            "cargo_demand": 82530
        }
    },
    {
        "iata": "NAW",
        "icao": "VTSC",
        "airportName": "Narathiwat Airport",
        "location_country": "Thailand",
        "location_city": "Narathiwat",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.519919872",
        "longitude": "101.7429962",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61509,
            "s_seats": 45810,
            "j_seats": 50324,
            "f_seats": 14759,
            "r_seats": 5719,
            "cargo_demand": 321605
        }
    },
    {
        "iata": "NAZ",
        "icao": "AGNA",
        "airportName": "Nana Airport",
        "location_country": "Solomon Islands",
        "location_city": "Star Harbour",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.6758",
        "longitude": "162.2049",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2657",
                "runway_width": "66",
                "runway_material": "Soil and Grass"
            }
        ],
        "demand": {
            "y_seats": 3675,
            "s_seats": 2312,
            "j_seats": 1648,
            "f_seats": 34,
            "r_seats": 109,
            "cargo_demand": 97789
        }
    },
    {
        "iata": "NBC",
        "icao": "UWKE",
        "airportName": "Begishevo Airport",
        "location_country": "Russia",
        "location_city": "Tatarstan",
        "location_state": "Nizhnekamsk / Naberezhnye Chelny",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.56470108",
        "longitude": "52.09249878",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8209",
                "runway_width": "138",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04/22",
                "runway_length": "5620",
                "runway_width": "190",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 115976,
            "s_seats": 48303,
            "j_seats": 14624,
            "f_seats": 14160,
            "r_seats": 6384,
            "cargo_demand": 247058
        }
    },
    {
        "iata": "NBG",
        "icao": "KNBG",
        "airportName": "NAS JRB New Orleans",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "New Orleans",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.82530022",
        "longitude": "-90.03500366",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9999",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 91442,
            "s_seats": 37664,
            "j_seats": 39093,
            "f_seats": 11057,
            "r_seats": 4385,
            "cargo_demand": 127485
        }
    },
    {
        "iata": "NBH",
        "icao": "YNHS",
        "airportName": "Nambucca Heads Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Nambucca Heads",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-30.673058",
        "longitude": "152.984233",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2952",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 1074,
            "s_seats": 2105,
            "j_seats": 1323,
            "f_seats": 239,
            "r_seats": 5,
            "cargo_demand": 57815
        }
    },
    {
        "iata": "NBN",
        "icao": "FGAB",
        "airportName": "Annobon Airport",
        "location_country": "Equatorial Guinea",
        "location_city": "Annobon",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.410277",
        "longitude": "5.621944",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6177",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3657,
            "s_seats": 1513,
            "j_seats": 1123,
            "f_seats": 474,
            "r_seats": 22,
            "cargo_demand": 60344
        }
    },
    {
        "iata": "NBO",
        "icao": "HKJK",
        "airportName": "Jomo Kenyatta International Airport",
        "location_country": "Kenya",
        "location_city": "Nairobi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-1.319239974",
        "longitude": "36.92779922",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "13507",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 432415,
            "s_seats": 79289,
            "j_seats": 110614,
            "f_seats": 29986,
            "r_seats": 10000,
            "cargo_demand": 5921505
        }
    },
    {
        "iata": "NBX",
        "icao": "WABI",
        "airportName": "Nabire Airport",
        "location_country": "Indonesia",
        "location_city": "Nabire",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.36818",
        "longitude": "135.496002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105672,
            "s_seats": 48677,
            "j_seats": 48403,
            "f_seats": 3183,
            "r_seats": 9131,
            "cargo_demand": 467554
        }
    },
    {
        "iata": "NCA",
        "icao": "MBNC",
        "airportName": "North Caicos Airport",
        "location_country": "British Overseas Territory of Turks and Caicos Islands",
        "location_city": "North Caicos",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.9175",
        "longitude": "-71.939598",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4245",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70037,
            "s_seats": 29924,
            "j_seats": 49422,
            "f_seats": 19005,
            "r_seats": 8732,
            "cargo_demand": 309963
        }
    },
    {
        "iata": "NCE",
        "icao": "LFMN",
        "airportName": "Nice Cote d'Azur Airport",
        "location_country": "France",
        "location_city": "Provence-Alpes-Cote d'Azur",
        "location_state": "Nice",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.65840149",
        "longitude": "7.215869904",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "8622",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "9721",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181606,
            "s_seats": 81518,
            "j_seats": 57667,
            "f_seats": 62144,
            "r_seats": 10000,
            "cargo_demand": 12384251
        }
    },
    {
        "iata": "NCG",
        "icao": "MMCG",
        "airportName": "Nuevo Casas Grandes Municipal Airport",
        "location_country": "Mexico",
        "location_city": "Chihuahua",
        "location_state": "Nuevo Casas Grandes",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "30.3974",
        "longitude": "-107.875",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2350",
                "runway_width": "95",
                "runway_material": "GRE"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6075",
                "runway_width": "65",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3069,
            "s_seats": 1580,
            "j_seats": 1563,
            "f_seats": 397,
            "r_seats": 81,
            "cargo_demand": 12258
        }
    },
    {
        "iata": "NCL",
        "icao": "EGNT",
        "airportName": "Newcastle Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Newcastle upon Tyne",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "55.03749847",
        "longitude": "-1.691669941",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7642",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127644,
            "s_seats": 99104,
            "j_seats": 102498,
            "f_seats": 39507,
            "r_seats": 10000,
            "cargo_demand": 21465799
        }
    },
    {
        "iata": "NCN",
        "icao": "PFCB",
        "airportName": "Chenega Bay Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Chenega",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "60.077602",
        "longitude": "-147.99468",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 2310,
            "s_seats": 2998,
            "j_seats": 1888,
            "f_seats": 212,
            "r_seats": 12,
            "cargo_demand": 85547
        }
    },
    {
        "iata": "NCO",
        "icao": "KOQU",
        "airportName": "Quonset State Airport",
        "location_country": "United States",
        "location_city": "Rhode Island",
        "location_state": "North Kingstown",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.5970993",
        "longitude": "-71.41210175",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2963",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7504",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 69331,
            "s_seats": 31820,
            "j_seats": 41389,
            "f_seats": 7222,
            "r_seats": 9630,
            "cargo_demand": 413929
        }
    },
    {
        "iata": "NCS",
        "icao": "FANC",
        "airportName": "Newcastle Airport",
        "location_country": "South Africa",
        "location_city": "Newcastle",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.77059937",
        "longitude": "29.9769001",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4921",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128658,
            "s_seats": 31416,
            "j_seats": 35912,
            "f_seats": 4290,
            "r_seats": 6127,
            "cargo_demand": 138002
        }
    },
    {
        "iata": "NCU",
        "icao": "UTNN",
        "airportName": "Nukus Airport",
        "location_country": "Uzbekistan",
        "location_city": "Nukus",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.48839951",
        "longitude": "59.62329865",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "1985",
                "runway_width": "65",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "9175",
                "runway_width": "160",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1738,
            "s_seats": 1979,
            "j_seats": 1519,
            "f_seats": 206,
            "r_seats": 96,
            "cargo_demand": 77833
        }
    },
    {
        "iata": "NCY",
        "icao": "LFLP",
        "airportName": "Annecy - Haute-Savoie - Mont Blanc Airport",
        "location_country": "France",
        "location_city": "Rhone-Alpes",
        "location_state": "Annecy",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.9308333",
        "longitude": "6.1063889",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5348",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175453,
            "s_seats": 30908,
            "j_seats": 31720,
            "f_seats": 3106,
            "r_seats": 7287,
            "cargo_demand": 398601
        }
    },
    {
        "iata": "NDA",
        "icao": "WAPC",
        "airportName": "Bandanaira Airport",
        "location_country": "Indonesia",
        "location_city": "Banda Islands",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-4.5214",
        "longitude": "129.9054",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2865,
            "s_seats": 1888,
            "j_seats": 1208,
            "f_seats": 347,
            "r_seats": 47,
            "cargo_demand": 59451
        }
    },
    {
        "iata": "NDB",
        "icao": "GQPP",
        "airportName": "Nouadhibou International Airport",
        "location_country": "Mauritania",
        "location_city": "Nouadhibou",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.93309975",
        "longitude": "-17.03000069",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7961",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68500,
            "s_seats": 27544,
            "j_seats": 19213,
            "f_seats": 9065,
            "r_seats": 5941,
            "cargo_demand": 296010
        }
    },
    {
        "iata": "NDC",
        "icao": "VAND",
        "airportName": "Shri Guru Gobind Singh Ji Airport",
        "location_country": "India",
        "location_city": "Maharashtra",
        "location_state": "Nanded",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.18330002",
        "longitude": "77.3167038",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128322,
            "s_seats": 40263,
            "j_seats": 7660,
            "f_seats": 3126,
            "r_seats": 3201,
            "cargo_demand": 492732
        }
    },
    {
        "iata": "NDD",
        "icao": "FNSU",
        "airportName": "Sumbe Airport",
        "location_country": "Angola",
        "location_city": "Sumbe",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.16790009",
        "longitude": "13.84749985",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3115",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156946,
            "s_seats": 43687,
            "j_seats": 27082,
            "f_seats": 12150,
            "r_seats": 4339,
            "cargo_demand": 304859
        }
    },
    {
        "iata": "NDE",
        "icao": "HKMA",
        "airportName": "Mandera Airport",
        "location_country": "Kenya",
        "location_city": "Mandera",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "3.933000088",
        "longitude": "41.84999847",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3609",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3629,
            "s_seats": 2896,
            "j_seats": 1235,
            "f_seats": 231,
            "r_seats": 115,
            "cargo_demand": 90087
        }
    },
    {
        "iata": "NDG",
        "icao": "ZYQQ",
        "airportName": "Qiqihar Sanjiazi Airport",
        "location_country": "China",
        "location_city": "Heilongjiang",
        "location_state": "Qiqihar",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.23960114",
        "longitude": "123.9179993",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 80275,
            "s_seats": 37359,
            "j_seats": 14713,
            "f_seats": 15200,
            "r_seats": 9569,
            "cargo_demand": 326519
        }
    },
    {
        "iata": "NDJ",
        "icao": "FTTJ",
        "airportName": "N'Djamena International Airport",
        "location_country": "Chad",
        "location_city": "N'Djamena",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "12.1337",
        "longitude": "15.034",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 366316,
            "s_seats": 94958,
            "j_seats": 132430,
            "f_seats": 44643,
            "r_seats": 10000,
            "cargo_demand": 29366930
        }
    },
    {
        "iata": "NDL",
        "icao": "FEFN",
        "airportName": "N'Dele Airport",
        "location_country": "Central African Republic",
        "location_city": "N'Dele (Ndele)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "8.427206039",
        "longitude": "20.63515663",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2802,
            "s_seats": 2433,
            "j_seats": 1442,
            "f_seats": 377,
            "r_seats": 23,
            "cargo_demand": 67445
        }
    },
    {
        "iata": "NDR",
        "icao": "GMMW",
        "airportName": "Nador International Airport",
        "location_country": "Morocco",
        "location_city": "Nador",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.98880005",
        "longitude": "-3.028209925",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129015,
            "s_seats": 45474,
            "j_seats": 52486,
            "f_seats": 19403,
            "r_seats": 6960,
            "cargo_demand": 197357
        }
    },
    {
        "iata": "NDS",
        "icao": "YSAN",
        "airportName": "Sandstone Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Sandstone",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.97999954",
        "longitude": "119.2969971",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2993,
            "s_seats": 1775,
            "j_seats": 1498,
            "f_seats": 410,
            "r_seats": 79,
            "cargo_demand": 43794
        }
    },
    {
        "iata": "NDU",
        "icao": "FYRU",
        "airportName": "Rundu Airport",
        "location_country": "Namibia",
        "location_city": "Rundu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.9564991",
        "longitude": "19.71940041",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11004",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4806",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 105809,
            "s_seats": 25335,
            "j_seats": 8035,
            "f_seats": 6603,
            "r_seats": 6247,
            "cargo_demand": 408330
        }
    },
    {
        "iata": "NDY",
        "icao": "EGES",
        "airportName": "Sanday Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Sanday",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "59.25030136",
        "longitude": "-2.576669931",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1532",
                "runway_width": "59",
                "runway_material": "Graded Hardcore"
            },
            {
                "runway_name": "11/29",
                "runway_length": "1397",
                "runway_width": "98",
                "runway_material": "Grass"
            },
            {
                "runway_name": "17/35",
                "runway_length": "1266",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1650,
            "s_seats": 1594,
            "j_seats": 1939,
            "f_seats": 156,
            "r_seats": 193,
            "cargo_demand": 76674
        }
    },
    {
        "iata": "NEC",
        "icao": "SAZO",
        "airportName": "Necochea Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "Necochea",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.4831",
        "longitude": "-58.8172",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2621",
                "runway_width": "59",
                "runway_material": "GRE"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116554,
            "s_seats": 43740,
            "j_seats": 5031,
            "f_seats": 5420,
            "r_seats": 7213,
            "cargo_demand": 241644
        }
    },
    {
        "iata": "NEL",
        "icao": "KNEL",
        "airportName": "NAES Lakehurst",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Lakehurst",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.03329849",
        "longitude": "-74.353302",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55581,
            "s_seats": 32834,
            "j_seats": 59857,
            "f_seats": 16914,
            "r_seats": 4278,
            "cargo_demand": 133016
        }
    },
    {
        "iata": "NEN",
        "icao": "KNEN",
        "airportName": "NOLF Whitehouse",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Jacksonville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "30.35390091",
        "longitude": "-81.87190247",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 1557,
            "s_seats": 2575,
            "j_seats": 1192,
            "f_seats": 241,
            "r_seats": 132,
            "cargo_demand": 25818
        }
    },
    {
        "iata": "NER",
        "icao": "UELL",
        "airportName": "Chulman Neryungri Airport",
        "location_country": "Russia",
        "location_city": "Yakutia",
        "location_state": "Neryungri",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.91389847",
        "longitude": "124.9140015",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 131212,
            "s_seats": 45488,
            "j_seats": 19237,
            "f_seats": 7216,
            "r_seats": 3101,
            "cargo_demand": 88076
        }
    },
    {
        "iata": "NEU",
        "icao": "VLSN",
        "airportName": "Nathong Airport",
        "location_country": "Laos",
        "location_city": "Xam Neua",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.41839981",
        "longitude": "104.0670013",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3715",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 96589,
            "s_seats": 32876,
            "j_seats": 50703,
            "f_seats": 12083,
            "r_seats": 6758,
            "cargo_demand": 320738
        }
    },
    {
        "iata": "NEV",
        "icao": "TKPN",
        "airportName": "Vance W. Amory International Airport",
        "location_country": "Saint Kitts and Nevis",
        "location_city": "Nevis",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.20569992",
        "longitude": "-62.58990097",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3996",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169534,
            "s_seats": 27659,
            "j_seats": 23520,
            "f_seats": 5474,
            "r_seats": 3968,
            "cargo_demand": 77752
        }
    },
    {
        "iata": "NEW",
        "icao": "KNEW",
        "airportName": "Lakefront Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "New Orleans",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.04240036",
        "longitude": "-90.02829742",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3113",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "3697",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "6880",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58549,
            "s_seats": 34610,
            "j_seats": 57231,
            "f_seats": 6234,
            "r_seats": 5984,
            "cargo_demand": 431773
        }
    },
    {
        "iata": "NFL",
        "icao": "KNFL",
        "airportName": "NAS Fallon",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Fallon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.41659927",
        "longitude": "-118.7009964",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7004",
                "runway_width": "154",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "11079",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "14005",
                "runway_width": "201",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 156154,
            "s_seats": 39433,
            "j_seats": 25049,
            "f_seats": 7281,
            "r_seats": 6939,
            "cargo_demand": 235891
        }
    },
    {
        "iata": "NFO",
        "icao": "NFTO",
        "airportName": "Niuafo'ou Airport",
        "location_country": "Tonga",
        "location_city": "Niuafo'ou",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.57079983",
        "longitude": "-175.6329956",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1414,
            "s_seats": 2148,
            "j_seats": 1975,
            "f_seats": 205,
            "r_seats": 131,
            "cargo_demand": 64815
        }
    },
    {
        "iata": "NFR",
        "icao": "HLNR",
        "airportName": "Nafoora Airport",
        "location_country": "Libya",
        "location_city": "Nafoora",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "29.2132",
        "longitude": "21.5924",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "9910",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3590,
            "s_seats": 2248,
            "j_seats": 1355,
            "f_seats": 210,
            "r_seats": 182,
            "cargo_demand": 92636
        }
    },
    {
        "iata": "NGA",
        "icao": "YYNG",
        "airportName": "Young Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Young",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.25559998",
        "longitude": "148.2480011",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4003",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 126897,
            "s_seats": 46752,
            "j_seats": 56302,
            "f_seats": 15007,
            "r_seats": 7990,
            "cargo_demand": 52671
        }
    },
    {
        "iata": "NGB",
        "icao": "ZSNB",
        "airportName": "Ningbo Lishe International Airport",
        "location_country": "China",
        "location_city": "Zhejiang",
        "location_state": "Ningbo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "29.82670021",
        "longitude": "121.461998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 322523,
            "s_seats": 82044,
            "j_seats": 139410,
            "f_seats": 62500,
            "r_seats": 10000,
            "cargo_demand": 5438758
        }
    },
    {
        "iata": "NGD",
        "icao": "TUPA",
        "airportName": "Auguste George Airport",
        "location_country": "British Overseas Territory of Virgin Islands",
        "location_city": "Anegada",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.72719955",
        "longitude": "-64.32969666",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2500",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2989,
            "s_seats": 2720,
            "j_seats": 1612,
            "f_seats": 48,
            "r_seats": 155,
            "cargo_demand": 31115
        }
    },
    {
        "iata": "NGE",
        "icao": "FKKN",
        "airportName": "Ngaoundere Airport",
        "location_country": "Cameroon",
        "location_city": "Ngaoundere",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.357009888",
        "longitude": "13.55920029",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 195192,
            "s_seats": 48644,
            "j_seats": 44544,
            "f_seats": 10886,
            "r_seats": 6505,
            "cargo_demand": 146461
        }
    },
    {
        "iata": "NGF",
        "icao": "PHNG",
        "airportName": "MCAS Kaneohe Bay",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Kaneohe",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.45050049",
        "longitude": "-157.7680054",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7771",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102899,
            "s_seats": 30258,
            "j_seats": 54003,
            "f_seats": 11579,
            "r_seats": 9158,
            "cargo_demand": 169002
        }
    },
    {
        "iata": "NGL",
        "icao": "FANG",
        "airportName": "Ngala Airfield",
        "location_country": "South Africa",
        "location_city": "Ngala",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.389",
        "longitude": "31.326",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3658",
                "runway_width": "30",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2310,
            "s_seats": 1776,
            "j_seats": 1513,
            "f_seats": 447,
            "r_seats": 44,
            "cargo_demand": 46935
        }
    },
    {
        "iata": "NGO",
        "icao": "RJGG",
        "airportName": "Chubu Centrair International Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Nagoya",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "34.85839844",
        "longitude": "136.8049927",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "11483",
                "runway_width": "197",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 352912,
            "s_seats": 74100,
            "j_seats": 154219,
            "f_seats": 60248,
            "r_seats": 10000,
            "cargo_demand": 27331110
        }
    },
    {
        "iata": "NGP",
        "icao": "KNGP",
        "airportName": "NAS Corpus Christi",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Corpus Christi",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.69260025",
        "longitude": "-97.29109955",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4997",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "4998",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "8003",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5003",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 126057,
            "s_seats": 31193,
            "j_seats": 31685,
            "f_seats": 16876,
            "r_seats": 7440,
            "cargo_demand": 472481
        }
    },
    {
        "iata": "NGS",
        "icao": "RJFU",
        "airportName": "Nagasaki Airport",
        "location_country": "Japan",
        "location_city": "Kyushu",
        "location_state": "Nagasaki",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.91690063",
        "longitude": "129.9140015",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9840",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4350",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62143,
            "s_seats": 30639,
            "j_seats": 27244,
            "f_seats": 12162,
            "r_seats": 6761,
            "cargo_demand": 179415
        }
    },
    {
        "iata": "NGU",
        "icao": "KNGU",
        "airportName": "NS Norfolk",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Norfolk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.937599",
        "longitude": "-76.289299",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8369",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166338,
            "s_seats": 47533,
            "j_seats": 15835,
            "f_seats": 5028,
            "r_seats": 4053,
            "cargo_demand": 201926
        }
    },
    {
        "iata": "NGW",
        "icao": "KNGW",
        "airportName": "NOLF Cabaniss Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Corpus Christi",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.704426",
        "longitude": "-97.440578",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1757,
            "s_seats": 2128,
            "j_seats": 1799,
            "f_seats": 68,
            "r_seats": 27,
            "cargo_demand": 52419
        }
    },
    {
        "iata": "NGX",
        "icao": "VNMA",
        "airportName": "Manang Airport",
        "location_country": "Nepal",
        "location_city": "Manang",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.641399",
        "longitude": "84.089203",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2133",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3362,
            "s_seats": 2047,
            "j_seats": 1784,
            "f_seats": 332,
            "r_seats": 86,
            "cargo_demand": 89844
        }
    },
    {
        "iata": "NHD",
        "icao": "OMDM",
        "airportName": "Al Minhad Air Base",
        "location_country": "United Arab Emirates",
        "location_city": "Dubai",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.02680016",
        "longitude": "55.36619949",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "12970",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198880,
            "s_seats": 25950,
            "j_seats": 5752,
            "f_seats": 15115,
            "r_seats": 7929,
            "cargo_demand": 431898
        }
    },
    {
        "iata": "NHK",
        "icao": "KNHK",
        "airportName": "NAS Patuxent River",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Patuxent River",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.285999",
        "longitude": "-76.411797",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5021",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "11807",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "14/32",
                "runway_length": "9742",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 120985,
            "s_seats": 42242,
            "j_seats": 57646,
            "f_seats": 7042,
            "r_seats": 9638,
            "cargo_demand": 202787
        }
    },
    {
        "iata": "NHT",
        "icao": "EGWU",
        "airportName": "RAF Northolt",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Northolt",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.5530014",
        "longitude": "-0.418166995",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5545",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171069,
            "s_seats": 43823,
            "j_seats": 27251,
            "f_seats": 10235,
            "r_seats": 4630,
            "cargo_demand": 338623
        }
    },
    {
        "iata": "NHV",
        "icao": "NTMD",
        "airportName": "Nuku Hiva Airport",
        "location_country": "French Polynesia",
        "location_city": "Marquesas Islands",
        "location_state": "Nuku Hiva",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.795599937",
        "longitude": "-140.2290039",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5578",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114273,
            "s_seats": 40171,
            "j_seats": 30307,
            "f_seats": 8036,
            "r_seats": 9584,
            "cargo_demand": 222363
        }
    },
    {
        "iata": "NHX",
        "icao": "KNBJ",
        "airportName": "NOLF Barin",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Foley",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "30.38909912",
        "longitude": "-87.63529968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2719,
            "s_seats": 2157,
            "j_seats": 1447,
            "f_seats": 294,
            "r_seats": 60,
            "cargo_demand": 76208
        }
    },
    {
        "iata": "NIB",
        "icao": "PAFS",
        "airportName": "Nikolai Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Nikolai",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "63.0186",
        "longitude": "-154.358002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4003",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 2295,
            "s_seats": 2154,
            "j_seats": 1430,
            "f_seats": 60,
            "r_seats": 174,
            "cargo_demand": 81201
        }
    },
    {
        "iata": "NIF",
        "icao": "YCNF",
        "airportName": "Nifty Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Telfer",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-21.67169952",
        "longitude": "121.586998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5577",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3001,
            "s_seats": 2731,
            "j_seats": 1441,
            "f_seats": 113,
            "r_seats": 93,
            "cargo_demand": 50870
        }
    },
    {
        "iata": "NIM",
        "icao": "DRRN",
        "airportName": "Diori Hamani International Airport",
        "location_country": "Niger",
        "location_city": "Niamey",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "13.4815",
        "longitude": "2.18361",
        "region": "AF",
        "runway": [
            {
                "runway_name": "/",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "5315",
                "runway_width": "131",
                "runway_material": "LAT"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 133343,
            "s_seats": 97403,
            "j_seats": 44277,
            "f_seats": 55107,
            "r_seats": 10000,
            "cargo_demand": 18721922
        }
    },
    {
        "iata": "NIP",
        "icao": "KNIP",
        "airportName": "NAS Jacksonville",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Jacksonville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.2358",
        "longitude": "-81.680603",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8002",
                "runway_width": "200",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5984",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70527,
            "s_seats": 32918,
            "j_seats": 26279,
            "f_seats": 7496,
            "r_seats": 3678,
            "cargo_demand": 400419
        }
    },
    {
        "iata": "NIT",
        "icao": "LFBN",
        "airportName": "Niort - Souche Airport",
        "location_country": "France",
        "location_city": "Poitou-Charentes",
        "location_state": "Niort",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.313477",
        "longitude": "-0.394529",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5774",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83894,
            "s_seats": 47634,
            "j_seats": 59646,
            "f_seats": 7238,
            "r_seats": 7734,
            "cargo_demand": 157288
        }
    },
    {
        "iata": "NIX",
        "icao": "GANR",
        "airportName": "Nioro Airport",
        "location_country": "Mali",
        "location_city": "Nioro du Sahel",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "15.23810005",
        "longitude": "-9.576109886",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1630,
            "s_seats": 2471,
            "j_seats": 1093,
            "f_seats": 390,
            "r_seats": 51,
            "cargo_demand": 47672
        }
    },
    {
        "iata": "NJA",
        "icao": "RJTA",
        "airportName": "Naval Air Facility Atsugi",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Atsugi",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.454601",
        "longitude": "139.449997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 55831,
            "s_seats": 31979,
            "j_seats": 39770,
            "f_seats": 5115,
            "r_seats": 5180,
            "cargo_demand": 325047
        }
    },
    {
        "iata": "NJC",
        "icao": "USNN",
        "airportName": "Nizhnevartovsk Airport",
        "location_country": "Russia",
        "location_city": "Khanty-Mansi Autonomous Okrug",
        "location_state": "Nizhnevartovsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.94929886",
        "longitude": "76.4835968",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10499",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53772,
            "s_seats": 48676,
            "j_seats": 7214,
            "f_seats": 7436,
            "r_seats": 9524,
            "cargo_demand": 227814
        }
    },
    {
        "iata": "NJF",
        "icao": "ORNI",
        "airportName": "Al Najaf International Airport",
        "location_country": "Iraq",
        "location_city": "Al Najaf",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.989853",
        "longitude": "44.404317",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1491,
            "s_seats": 2267,
            "j_seats": 1090,
            "f_seats": 493,
            "r_seats": 69,
            "cargo_demand": 84538
        }
    },
    {
        "iata": "NJK",
        "icao": "KNJK",
        "airportName": "NAF El Centro",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "El Centro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.829201",
        "longitude": "-115.671996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9503",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6823",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 97357,
            "s_seats": 46486,
            "j_seats": 34362,
            "f_seats": 10898,
            "r_seats": 5336,
            "cargo_demand": 244787
        }
    },
    {
        "iata": "NKC",
        "icao": "GQNO",
        "airportName": "Nouakchott-Oumtounsy International Airport",
        "location_country": "Mauritania",
        "location_city": "Nouakchott",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "18.31",
        "longitude": "-15.9697222",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7875",
                "runway_width": "148",
                "runway_material": "concrete"
            },
            {
                "runway_name": "16/34",
                "runway_length": "11155",
                "runway_width": "197",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 314768,
            "s_seats": 78289,
            "j_seats": 100064,
            "f_seats": 68662,
            "r_seats": 10000,
            "cargo_demand": 27797796
        }
    },
    {
        "iata": "NKG",
        "icao": "ZSNJ",
        "airportName": "Nanjing Lukou International Airport",
        "location_country": "China",
        "location_city": "Jiangsu",
        "location_state": "Nanjing",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "31.74200058",
        "longitude": "118.8619995",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "07/25",
                "runway_length": "11810",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 192774,
            "s_seats": 85379,
            "j_seats": 77329,
            "f_seats": 62712,
            "r_seats": 10000,
            "cargo_demand": 8363519
        }
    },
    {
        "iata": "NKL",
        "icao": "FZAR",
        "airportName": "Nkolo-Fuma Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Kolo Fuma",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.421",
        "longitude": "14.8169",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3700",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2424,
            "s_seats": 2128,
            "j_seats": 1132,
            "f_seats": 88,
            "r_seats": 74,
            "cargo_demand": 82754
        }
    },
    {
        "iata": "NKM",
        "icao": "RJNA",
        "airportName": "Nagoya Airfield",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Nagoya",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.255759",
        "longitude": "136.924095",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8990",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108084,
            "s_seats": 26757,
            "j_seats": 21190,
            "f_seats": 17758,
            "r_seats": 9190,
            "cargo_demand": 219141
        }
    },
    {
        "iata": "NKT",
        "icao": "LTCV",
        "airportName": "o\u017eirnak Airport",
        "location_country": "Turkey",
        "location_city": "o\u017eirnak / Cizre",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.3647",
        "longitude": "42.0582",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CONC"
            }
        ],
        "demand": {
            "y_seats": 99635,
            "s_seats": 39176,
            "j_seats": 55758,
            "f_seats": 13722,
            "r_seats": 6889,
            "cargo_demand": 268155
        }
    },
    {
        "iata": "NKX",
        "icao": "KNKX",
        "airportName": "MCAS Miramar",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "San Diego",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.86840057",
        "longitude": "-117.1429977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "12000",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2800",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 120491,
            "s_seats": 25862,
            "j_seats": 9750,
            "f_seats": 17011,
            "r_seats": 7718,
            "cargo_demand": 363780
        }
    },
    {
        "iata": "NLA",
        "icao": "FLND",
        "airportName": "Simon Mwansa Kapwepwe International Airport",
        "location_country": "Zambia",
        "location_city": "Ndola",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.99810028",
        "longitude": "28.66489983",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "8250",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 74600,
            "s_seats": 47241,
            "j_seats": 13768,
            "f_seats": 17498,
            "r_seats": 3198,
            "cargo_demand": 258264
        }
    },
    {
        "iata": "NLC",
        "icao": "KNLC",
        "airportName": "NAS Lemoore",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Lemoore",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.33300018",
        "longitude": "-119.9520035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "13502",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "13502",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 119625,
            "s_seats": 41345,
            "j_seats": 19512,
            "f_seats": 12135,
            "r_seats": 9461,
            "cargo_demand": 444707
        }
    },
    {
        "iata": "NLD",
        "icao": "MMNL",
        "airportName": "Quetzalcoatl International Airport",
        "location_country": "Mexico",
        "location_city": "Tamaulipas",
        "location_state": "Nuevo Laredo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.44389915",
        "longitude": "-99.57050323",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197686,
            "s_seats": 33848,
            "j_seats": 28902,
            "f_seats": 4125,
            "r_seats": 4046,
            "cargo_demand": 439150
        }
    },
    {
        "iata": "NLF",
        "icao": "YDNI",
        "airportName": "Darnley Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Darnley Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.583330154",
        "longitude": "143.7669983",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "1640",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 2011,
            "s_seats": 2902,
            "j_seats": 1280,
            "f_seats": 368,
            "r_seats": 94,
            "cargo_demand": 64615
        }
    },
    {
        "iata": "NLG",
        "icao": "PAOU",
        "airportName": "Nelson Lagoon Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Nelson Lagoon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "56.00749969",
        "longitude": "-161.1600037",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 1989,
            "s_seats": 2261,
            "j_seats": 1260,
            "f_seats": 488,
            "r_seats": 173,
            "cargo_demand": 34523
        }
    },
    {
        "iata": "NLK",
        "icao": "YSNF",
        "airportName": "Norfolk Island Airport",
        "location_country": "Australia",
        "location_city": "Norfolk Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.041887",
        "longitude": "167.939616",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4708",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6398",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97751,
            "s_seats": 36703,
            "j_seats": 53414,
            "f_seats": 11924,
            "r_seats": 8390,
            "cargo_demand": 461653
        }
    },
    {
        "iata": "NLL",
        "icao": "YNUL",
        "airportName": "Nullagine Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Nullagine",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-21.91329956",
        "longitude": "120.197998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4002",
                "runway_width": "",
                "runway_material": "SU"
            }
        ],
        "demand": {
            "y_seats": 3326,
            "s_seats": 2056,
            "j_seats": 1356,
            "f_seats": 413,
            "r_seats": 122,
            "cargo_demand": 83453
        }
    },
    {
        "iata": "NLO",
        "icao": "FZAB",
        "airportName": "N'Dolo Airport",
        "location_country": "Democratic Republic of the Congo",
        "location_city": "Kinshasa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-4.326660156",
        "longitude": "15.32750034",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5526",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172080,
            "s_seats": 41911,
            "j_seats": 27919,
            "f_seats": 8142,
            "r_seats": 9473,
            "cargo_demand": 481485
        }
    },
    {
        "iata": "NLP",
        "icao": "FANS",
        "airportName": "Nelspruit Airport",
        "location_country": "South Africa",
        "location_city": "Mbombela",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.5",
        "longitude": "30.91379929",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3419",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1858,
            "s_seats": 2802,
            "j_seats": 1956,
            "f_seats": 196,
            "r_seats": 81,
            "cargo_demand": 83237
        }
    },
    {
        "iata": "NLT",
        "icao": "ZWNL",
        "airportName": "Xinyuan Nalati Airport",
        "location_country": "China",
        "location_city": "Xinjiang",
        "location_state": "Xinyuan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.4318",
        "longitude": "83.3786",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 182141,
            "s_seats": 31304,
            "j_seats": 6506,
            "f_seats": 8379,
            "r_seats": 3529,
            "cargo_demand": 366019
        }
    },
    {
        "iata": "NLV",
        "icao": "UKON",
        "airportName": "Mykolaiv International Airport",
        "location_country": "Ukraine",
        "location_city": "Mykolaiv",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.05789948",
        "longitude": "31.9197998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8438",
                "runway_width": "144",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05L/23R",
                "runway_length": "5905",
                "runway_width": "250",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 151489,
            "s_seats": 31137,
            "j_seats": 58480,
            "f_seats": 16384,
            "r_seats": 3658,
            "cargo_demand": 416815
        }
    },
    {
        "iata": "NMB",
        "icao": "VADN",
        "airportName": "Daman Airport",
        "location_country": "India",
        "location_city": "Daman and Diu",
        "location_state": "Daman",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.434401",
        "longitude": "72.843201",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5909",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3282",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196699,
            "s_seats": 36238,
            "j_seats": 29256,
            "f_seats": 19514,
            "r_seats": 7181,
            "cargo_demand": 340763
        }
    },
    {
        "iata": "NMC",
        "icao": "MYEN",
        "airportName": "Norman's Cay Airport",
        "location_country": "Bahamas",
        "location_city": "Exuma Islands",
        "location_state": "Norman's Cay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.594299",
        "longitude": "-76.820198",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197763,
            "s_seats": 31971,
            "j_seats": 30000,
            "f_seats": 18451,
            "r_seats": 5828,
            "cargo_demand": 261751
        }
    },
    {
        "iata": "NME",
        "icao": "PAGT",
        "airportName": "Nightmute Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Nightmute",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "60.47100067",
        "longitude": "-164.701004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1600",
                "runway_width": "50",
                "runway_material": "GRVL-P"
            }
        ],
        "demand": {
            "y_seats": 2058,
            "s_seats": 2383,
            "j_seats": 1463,
            "f_seats": 173,
            "r_seats": 178,
            "cargo_demand": 59022
        }
    },
    {
        "iata": "NMF",
        "icao": "VRDA",
        "airportName": "Maafaru International Airport",
        "location_country": "Maldives",
        "location_city": "Noonu",
        "location_state": "Maafaru",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.818394",
        "longitude": "73.469181",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 64333,
            "s_seats": 49995,
            "j_seats": 44049,
            "f_seats": 1142,
            "r_seats": 3566,
            "cargo_demand": 469517
        }
    },
    {
        "iata": "NMR",
        "icao": "YNAP",
        "airportName": "Nappa Merrie Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Nappa Merrie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.55830002",
        "longitude": "141.1329956",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3474",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 2060,
            "s_seats": 1786,
            "j_seats": 1606,
            "f_seats": 39,
            "r_seats": 178,
            "cargo_demand": 59302
        }
    },
    {
        "iata": "NMS",
        "icao": "VYNS",
        "airportName": "Nansang Airport",
        "location_country": "Myanmar",
        "location_city": "Nansang (Namsang)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.89049911",
        "longitude": "97.73590088",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "12005",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 65845,
            "s_seats": 49510,
            "j_seats": 54508,
            "f_seats": 5191,
            "r_seats": 9020,
            "cargo_demand": 419453
        }
    },
    {
        "iata": "NNA",
        "icao": "GMMY",
        "airportName": "Kenitra Air Base",
        "location_country": "Morocco",
        "location_city": "Kenitra",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.298901",
        "longitude": "-6.59588",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6035",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "7995",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 124527,
            "s_seats": 35313,
            "j_seats": 54560,
            "f_seats": 11026,
            "r_seats": 5705,
            "cargo_demand": 262597
        }
    },
    {
        "iata": "NNB",
        "icao": "AGGT",
        "airportName": "Santa Ana Airport",
        "location_country": "Solomon Islands",
        "location_city": "Santa Ana Island (Owaraha)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.847994",
        "longitude": "162.454108",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2655",
                "runway_width": "75",
                "runway_material": "Coral"
            }
        ],
        "demand": {
            "y_seats": 1381,
            "s_seats": 2948,
            "j_seats": 1426,
            "f_seats": 259,
            "r_seats": 36,
            "cargo_demand": 48783
        }
    },
    {
        "iata": "NNG",
        "icao": "ZGNN",
        "airportName": "Nanning Wuxu International Airport",
        "location_country": "China",
        "location_city": "Guangxi",
        "location_state": "Nanning",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "22.608299",
        "longitude": "108.171997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 181984,
            "s_seats": 88180,
            "j_seats": 33919,
            "f_seats": 29491,
            "r_seats": 10000,
            "cargo_demand": 28884644
        }
    },
    {
        "iata": "NNL",
        "icao": "PANO",
        "airportName": "Nondalton Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Nondalton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "59.98020172",
        "longitude": "-154.8390045",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2800",
                "runway_width": "75",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 1866,
            "s_seats": 1929,
            "j_seats": 1477,
            "f_seats": 261,
            "r_seats": 113,
            "cargo_demand": 20402
        }
    },
    {
        "iata": "NNR",
        "icao": "EICA",
        "airportName": "Connemara Airport",
        "location_country": "Ireland",
        "location_city": "Spiddal",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "53.2303009",
        "longitude": "-9.467780113",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "1968",
                "runway_width": "59",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1090,
            "s_seats": 2758,
            "j_seats": 1995,
            "f_seats": 45,
            "r_seats": 134,
            "cargo_demand": 3651
        }
    },
    {
        "iata": "NNT",
        "icao": "VTCN",
        "airportName": "Nan Nakhon Airport",
        "location_country": "Thailand",
        "location_city": "Nan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.80789948",
        "longitude": "100.7829971",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114675,
            "s_seats": 25438,
            "j_seats": 8265,
            "f_seats": 7381,
            "r_seats": 5641,
            "cargo_demand": 139530
        }
    },
    {
        "iata": "NNU",
        "icao": "SNNU",
        "airportName": "Nanuque Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Nanuque",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.82329941",
        "longitude": "-40.32989883",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1285,
            "s_seats": 2509,
            "j_seats": 1661,
            "f_seats": 11,
            "r_seats": 89,
            "cargo_demand": 80794
        }
    },
    {
        "iata": "NNX",
        "icao": "WRLF",
        "airportName": "Nunukan Airport",
        "location_country": "Indonesia",
        "location_city": "Nunukan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "4.133333333",
        "longitude": "117.6666667",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3609",
                "runway_width": "75",
                "runway_material": "ASPHALT"
            }
        ],
        "demand": {
            "y_seats": 3319,
            "s_seats": 2480,
            "j_seats": 1597,
            "f_seats": 21,
            "r_seats": 65,
            "cargo_demand": 35196
        }
    },
    {
        "iata": "NOA",
        "icao": "YSNW",
        "airportName": "NAS Nowra",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Nowra",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.94889832",
        "longitude": "150.5370026",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6713",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6870",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142685,
            "s_seats": 46044,
            "j_seats": 5515,
            "f_seats": 9693,
            "r_seats": 9704,
            "cargo_demand": 483809
        }
    },
    {
        "iata": "NOB",
        "icao": "MRNS",
        "airportName": "Nosara Airport",
        "location_country": "Costa Rica",
        "location_city": "Nosara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.976490021",
        "longitude": "-85.65299988",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3281",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118776,
            "s_seats": 35241,
            "j_seats": 51564,
            "f_seats": 4586,
            "r_seats": 4718,
            "cargo_demand": 170096
        }
    },
    {
        "iata": "NOC",
        "icao": "EIKN",
        "airportName": "Ireland West Airport Knock",
        "location_country": "Ireland",
        "location_city": "Knock",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.910301",
        "longitude": "-8.81849",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61022,
            "s_seats": 26378,
            "j_seats": 19871,
            "f_seats": 4694,
            "r_seats": 3452,
            "cargo_demand": 461495
        }
    },
    {
        "iata": "NOG",
        "icao": "MMNG",
        "airportName": "Nogales International Airport",
        "location_country": "Mexico",
        "location_city": "Sonora",
        "location_state": "Nogales",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.225756",
        "longitude": "-110.976934",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1344,
            "s_seats": 2319,
            "j_seats": 1886,
            "f_seats": 251,
            "r_seats": 90,
            "cargo_demand": 1473
        }
    },
    {
        "iata": "NOK",
        "icao": "SWXV",
        "airportName": "Nova Xavantina Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Nova Xavantina",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.69830036",
        "longitude": "-52.34640121",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "4888",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3509,
            "s_seats": 2599,
            "j_seats": 1388,
            "f_seats": 388,
            "r_seats": 115,
            "cargo_demand": 11826
        }
    },
    {
        "iata": "NOR",
        "icao": "BINF",
        "airportName": "Nor\u00f0fjor\u00f0ur Airport",
        "location_country": "Iceland",
        "location_city": "Nordfjordur",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.13189697",
        "longitude": "-13.74639988",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3648",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2667,
            "s_seats": 2572,
            "j_seats": 1248,
            "f_seats": 437,
            "r_seats": 70,
            "cargo_demand": 87665
        }
    },
    {
        "iata": "NOS",
        "icao": "FMNN",
        "airportName": "Fascene Airport",
        "location_country": "Madagascar",
        "location_city": "Nosy Be",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-13.31210041",
        "longitude": "48.31480026",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7185",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136233,
            "s_seats": 36374,
            "j_seats": 57281,
            "f_seats": 6417,
            "r_seats": 8657,
            "cargo_demand": 205628
        }
    },
    {
        "iata": "NOT",
        "icao": "KDVO",
        "airportName": "Marin County Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Novato",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.14360046",
        "longitude": "-122.5559998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3300",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3464,
            "s_seats": 2679,
            "j_seats": 1555,
            "f_seats": 47,
            "r_seats": 179,
            "cargo_demand": 724
        }
    },
    {
        "iata": "NOU",
        "icao": "NWWW",
        "airportName": "La Tontouta International Airport",
        "location_country": "New Caledonia",
        "location_city": "Noumea",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.01460075",
        "longitude": "166.2129974",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10663",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77869,
            "s_seats": 40696,
            "j_seats": 31704,
            "f_seats": 1888,
            "r_seats": 5799,
            "cargo_demand": 70274
        }
    },
    {
        "iata": "NOV",
        "icao": "FNHU",
        "airportName": "Albano Machado Airport",
        "location_country": "Angola",
        "location_city": "Huambo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.80889988",
        "longitude": "15.76049995",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3281",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "11/29",
                "runway_length": "8727",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102599,
            "s_seats": 27601,
            "j_seats": 46317,
            "f_seats": 14799,
            "r_seats": 5739,
            "cargo_demand": 403178
        }
    },
    {
        "iata": "NOZ",
        "icao": "UNWW",
        "airportName": "Spichenkovo Airport",
        "location_country": "Russia",
        "location_city": "Kemerovo Oblast",
        "location_state": "Novokuznetsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.811401",
        "longitude": "86.877197",
        "region": "EU",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "8765",
                "runway_width": "136",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 151264,
            "s_seats": 40613,
            "j_seats": 45391,
            "f_seats": 1534,
            "r_seats": 5981,
            "cargo_demand": 364386
        }
    },
    {
        "iata": "NPA",
        "icao": "KNPA",
        "airportName": "NAS Pensacola",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Pensacola",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.35269928",
        "longitude": "-87.31860352",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7137",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "8002",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "8001",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83250,
            "s_seats": 48268,
            "j_seats": 16365,
            "f_seats": 8133,
            "r_seats": 4146,
            "cargo_demand": 475134
        }
    },
    {
        "iata": "NPE",
        "icao": "NZNR",
        "airportName": "Hawke's Bay Airport",
        "location_country": "New Zealand",
        "location_city": "Napier / Hastings",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-39.465801",
        "longitude": "176.869995",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4000",
                "runway_width": "98",
                "runway_material": "COM"
            },
            {
                "runway_name": "10/28",
                "runway_length": "1837",
                "runway_width": "115",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "2513",
                "runway_width": "200",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "5740",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 158297,
            "s_seats": 35323,
            "j_seats": 25098,
            "f_seats": 16278,
            "r_seats": 8811,
            "cargo_demand": 344670
        }
    },
    {
        "iata": "NPL",
        "icao": "NZNP",
        "airportName": "New Plymouth Airport",
        "location_country": "New Zealand",
        "location_city": "New Plymouth",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-39.00859833",
        "longitude": "174.1790009",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "4298",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "3281",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3937",
                "runway_width": "131",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 85555,
            "s_seats": 29234,
            "j_seats": 37200,
            "f_seats": 15631,
            "r_seats": 7763,
            "cargo_demand": 150431
        }
    },
    {
        "iata": "NPO",
        "icao": "WIOG",
        "airportName": "Nanga Pinoh Airport",
        "location_country": "Indonesia",
        "location_city": "Nanga Pinoh",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.348868996",
        "longitude": "111.7480011",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3272",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80283,
            "s_seats": 28732,
            "j_seats": 36621,
            "f_seats": 2627,
            "r_seats": 6220,
            "cargo_demand": 282019
        }
    },
    {
        "iata": "NPR",
        "icao": "SJNP",
        "airportName": "Novo Progresso Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Novo Progresso",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.125833",
        "longitude": "-55.400833",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3445",
                "runway_width": "92",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1628,
            "s_seats": 2246,
            "j_seats": 1669,
            "f_seats": 258,
            "r_seats": 159,
            "cargo_demand": 19999
        }
    },
    {
        "iata": "NPT",
        "icao": "KUUU",
        "airportName": "Newport State Airport",
        "location_country": "United States",
        "location_city": "Rhode Island",
        "location_state": "Newport",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.532398",
        "longitude": "-71.281502",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2999",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2623",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3512,
            "s_seats": 2595,
            "j_seats": 1011,
            "f_seats": 238,
            "r_seats": 9,
            "cargo_demand": 27104
        }
    },
    {
        "iata": "NQA",
        "icao": "KNQA",
        "airportName": "Millington Regional Jetport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Millington",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.356701",
        "longitude": "-89.8703",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4850",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4250",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 98102,
            "s_seats": 34805,
            "j_seats": 44964,
            "f_seats": 4841,
            "r_seats": 7326,
            "cargo_demand": 45262
        }
    },
    {
        "iata": "NQI",
        "icao": "KNQI",
        "airportName": "NAS Kingsville",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Kingsville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.50720024",
        "longitude": "-97.80970001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 144222,
            "s_seats": 48068,
            "j_seats": 38773,
            "f_seats": 1185,
            "r_seats": 9194,
            "cargo_demand": 203293
        }
    },
    {
        "iata": "NQL",
        "icao": "SWNQ",
        "airportName": "Niquel\u00e2ndia Airport",
        "location_country": "Brazil",
        "location_city": "Goias",
        "location_state": "Niquel\u00e2ndia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.43490028",
        "longitude": "-48.49150085",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "4593",
                "runway_width": "164",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2888,
            "s_seats": 1730,
            "j_seats": 1851,
            "f_seats": 75,
            "r_seats": 138,
            "cargo_demand": 14822
        }
    },
    {
        "iata": "NQN",
        "icao": "SAZN",
        "airportName": "Presidente Peron International Airport",
        "location_country": "Argentina",
        "location_city": "Neuquen",
        "location_state": "Neuquen",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.949001",
        "longitude": "-68.155701",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "8432",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106713,
            "s_seats": 32634,
            "j_seats": 51540,
            "f_seats": 18295,
            "r_seats": 3910,
            "cargo_demand": 322851
        }
    },
    {
        "iata": "NQT",
        "icao": "EGBN",
        "airportName": "Nottingham Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Nottingham",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.919998",
        "longitude": "-1.07917",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2693",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3445",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73209,
            "s_seats": 38509,
            "j_seats": 19878,
            "f_seats": 13556,
            "r_seats": 7427,
            "cargo_demand": 238861
        }
    },
    {
        "iata": "NQX",
        "icao": "KNQX",
        "airportName": "NAS Key West",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Key West",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.57579994",
        "longitude": "-81.68890381",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "07/25",
                "runway_length": "10000",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 147477,
            "s_seats": 31022,
            "j_seats": 50573,
            "f_seats": 19393,
            "r_seats": 7867,
            "cargo_demand": 249497
        }
    },
    {
        "iata": "NQY",
        "icao": "EGHQ",
        "airportName": "Newquay Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Newquay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.44060135",
        "longitude": "-4.995409966",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9006",
                "runway_width": "285",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61276,
            "s_seats": 25920,
            "j_seats": 6284,
            "f_seats": 13539,
            "r_seats": 4104,
            "cargo_demand": 77137
        }
    },
    {
        "iata": "NQZ",
        "icao": "UACC",
        "airportName": "Nursultan Nazarbayev International Airport",
        "location_country": "Kazakhstan",
        "location_city": "Nur-Sultan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "51.022202",
        "longitude": "71.466904",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "11484",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129269,
            "s_seats": 73146,
            "j_seats": 146220,
            "f_seats": 39484,
            "r_seats": 10000,
            "cargo_demand": 17464741
        }
    },
    {
        "iata": "NRA",
        "icao": "YNAR",
        "airportName": "Narrandera Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Narrandera",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.70220184",
        "longitude": "146.5119934",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3346",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5302",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175277,
            "s_seats": 40511,
            "j_seats": 12512,
            "f_seats": 8391,
            "r_seats": 9547,
            "cargo_demand": 371135
        }
    },
    {
        "iata": "NRB",
        "icao": "KNRB",
        "airportName": "NS Mayport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Mayport",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.39109993",
        "longitude": "-81.42469788",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8001",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117328,
            "s_seats": 48143,
            "j_seats": 33753,
            "f_seats": 12099,
            "r_seats": 3285,
            "cargo_demand": 181251
        }
    },
    {
        "iata": "NRD",
        "icao": "EDWY",
        "airportName": "Norderney Airport",
        "location_country": "Germany",
        "location_city": "Lower Saxony",
        "location_state": "Norderney",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "53.706944",
        "longitude": "7.23",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3281",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3319,
            "s_seats": 2882,
            "j_seats": 1296,
            "f_seats": 106,
            "r_seats": 90,
            "cargo_demand": 42852
        }
    },
    {
        "iata": "NRE",
        "icao": "WAPG",
        "airportName": "Namrole Airport",
        "location_country": "Indonesia",
        "location_city": "Namrole",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.8548",
        "longitude": "126.7012",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2953",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2148,
            "s_seats": 1530,
            "j_seats": 1555,
            "f_seats": 154,
            "r_seats": 63,
            "cargo_demand": 99271
        }
    },
    {
        "iata": "NRG",
        "icao": "YNRG",
        "airportName": "Narrogin Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Narrogin",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-32.93000031",
        "longitude": "117.0800018",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4199",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4757",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1344,
            "s_seats": 1780,
            "j_seats": 1682,
            "f_seats": 327,
            "r_seats": 188,
            "cargo_demand": 2595
        }
    },
    {
        "iata": "NRK",
        "icao": "ESSP",
        "airportName": "Norrkoping Airport",
        "location_country": "Sweden",
        "location_city": "Norrkoping",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.5862999",
        "longitude": "16.25060081",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7228",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "1948",
                "runway_width": "115",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 183835,
            "s_seats": 27034,
            "j_seats": 8740,
            "f_seats": 2296,
            "r_seats": 5656,
            "cargo_demand": 76021
        }
    },
    {
        "iata": "NRL",
        "icao": "EGEN",
        "airportName": "North Ronaldsay Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "North Ronaldsay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "59.36750031",
        "longitude": "-2.434439898",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1082",
                "runway_width": "159",
                "runway_material": "Graded Hardcore"
            },
            {
                "runway_name": "10/28",
                "runway_length": "1729",
                "runway_width": "59",
                "runway_material": "Graded Hardcore"
            },
            {
                "runway_name": "14/32",
                "runway_length": "1240",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1756,
            "s_seats": 1538,
            "j_seats": 1318,
            "f_seats": 308,
            "r_seats": 191,
            "cargo_demand": 53244
        }
    },
    {
        "iata": "NRM",
        "icao": "GANK",
        "airportName": "Keibane Airport",
        "location_country": "Mali",
        "location_city": "Nara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "15.21700001",
        "longitude": "-7.267000198",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1299",
                "runway_width": "98",
                "runway_material": "Brown clay gravel"
            }
        ],
        "demand": {
            "y_seats": 3477,
            "s_seats": 2195,
            "j_seats": 1944,
            "f_seats": 382,
            "r_seats": 127,
            "cargo_demand": 58020
        }
    },
    {
        "iata": "NRN",
        "icao": "EDLV",
        "airportName": "Weeze Airport",
        "location_country": "Germany",
        "location_city": "North Rhine-Westphalia",
        "location_state": "Weeze",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.60240173",
        "longitude": "6.142169952",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8005",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 159967,
            "s_seats": 39859,
            "j_seats": 51852,
            "f_seats": 4130,
            "r_seats": 8949,
            "cargo_demand": 99763
        }
    },
    {
        "iata": "NRR",
        "icao": "TJRV",
        "airportName": "Jose Aponte de la Torre Airport ",
        "location_country": "United States",
        "location_city": "Puerto Rico",
        "location_state": "Ceiba",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.2453",
        "longitude": "-65.643402",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "11000",
                "runway_width": "150",
                "runway_material": "ASPH-CONC"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5800",
                "runway_width": "100",
                "runway_material": "ASPH-CONC"
            }
        ],
        "demand": {
            "y_seats": 1843,
            "s_seats": 1700,
            "j_seats": 1491,
            "f_seats": 231,
            "r_seats": 13,
            "cargo_demand": 12478
        }
    },
    {
        "iata": "NRS",
        "icao": "KNRS",
        "airportName": "NOLF Imperial Beach",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Imperial Beach",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.5667",
        "longitude": "-117.116997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2241",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4999",
                "runway_width": "340",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 3126,
            "s_seats": 2346,
            "j_seats": 1082,
            "f_seats": 152,
            "r_seats": 90,
            "cargo_demand": 68292
        }
    },
    {
        "iata": "NRT",
        "icao": "RJAA",
        "airportName": "Narita International Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Tokyo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "35.764702",
        "longitude": "140.386002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "8202",
                "runway_width": "196",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "13123",
                "runway_width": "196",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 470071,
            "s_seats": 74277,
            "j_seats": 138476,
            "f_seats": 23322,
            "r_seats": 10000,
            "cargo_demand": 18868034
        }
    },
    {
        "iata": "NSE",
        "icao": "KNSE",
        "airportName": "NAS Whiting Field - North",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Milton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.7241993",
        "longitude": "-87.02189636",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "6000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110542,
            "s_seats": 47586,
            "j_seats": 14237,
            "f_seats": 18094,
            "r_seats": 4748,
            "cargo_demand": 371043
        }
    },
    {
        "iata": "NSH",
        "icao": "OINN",
        "airportName": "Noshahr Airport",
        "location_country": "Iran",
        "location_city": "Nowshahr",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.66329956",
        "longitude": "51.46469879",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6182",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82326,
            "s_seats": 27088,
            "j_seats": 37527,
            "f_seats": 4692,
            "r_seats": 3989,
            "cargo_demand": 421720
        }
    },
    {
        "iata": "NSI",
        "icao": "FKYS",
        "airportName": "Yaounde Nsimalen International Airport",
        "location_country": "Cameroon",
        "location_city": "Yaounde",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.722559929",
        "longitude": "11.5532999",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166843,
            "s_seats": 32187,
            "j_seats": 20361,
            "f_seats": 1127,
            "r_seats": 6631,
            "cargo_demand": 172977
        }
    },
    {
        "iata": "NSK",
        "icao": "UOOO",
        "airportName": "Alykel Airport",
        "location_country": "Russia",
        "location_city": "Krasnoyarsk Krai",
        "location_state": "Norilsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "69.31109619",
        "longitude": "87.3321991",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9255",
                "runway_width": "147",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 119846,
            "s_seats": 49210,
            "j_seats": 21100,
            "f_seats": 2167,
            "r_seats": 6335,
            "cargo_demand": 248863
        }
    },
    {
        "iata": "NSL",
        "icao": "KDVP",
        "airportName": "Slayton Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Slayton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.98680115",
        "longitude": "-95.7826004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "2773",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3096,
            "s_seats": 2617,
            "j_seats": 1195,
            "f_seats": 139,
            "r_seats": 63,
            "cargo_demand": 66560
        }
    },
    {
        "iata": "NSM",
        "icao": "YNSM",
        "airportName": "Norseman Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Norseman",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-32.20999908",
        "longitude": "121.7549973",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4960",
                "runway_width": "",
                "runway_material": "C"
            },
            {
                "runway_name": "04/22",
                "runway_length": "3405",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1458,
            "s_seats": 2406,
            "j_seats": 1684,
            "f_seats": 421,
            "r_seats": 39,
            "cargo_demand": 34682
        }
    },
    {
        "iata": "NSN",
        "icao": "NZNS",
        "airportName": "Nelson Airport",
        "location_country": "New Zealand",
        "location_city": "Nelson",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-41.2983017",
        "longitude": "173.220993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "02L/20R",
                "runway_length": "2398",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "4420",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "2200",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "1970",
                "runway_width": "197",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 184825,
            "s_seats": 49668,
            "j_seats": 8619,
            "f_seats": 8325,
            "r_seats": 9746,
            "cargo_demand": 312155
        }
    },
    {
        "iata": "NSO",
        "icao": "YSCO",
        "airportName": "Scone Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Scone",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-32.03720093",
        "longitude": "150.8320007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4606",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1769,
            "s_seats": 2726,
            "j_seats": 1323,
            "f_seats": 316,
            "r_seats": 142,
            "cargo_demand": 22131
        }
    },
    {
        "iata": "NST",
        "icao": "VTSF",
        "airportName": "Nakhon Si Thammarat Airport",
        "location_country": "Thailand",
        "location_city": "Nakhon Si Thammarat",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.539620399",
        "longitude": "99.94470215",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193251,
            "s_seats": 47464,
            "j_seats": 19086,
            "f_seats": 19017,
            "r_seats": 3181,
            "cargo_demand": 260737
        }
    },
    {
        "iata": "NSV",
        "icao": "YNSH",
        "airportName": "Noosa Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Noosaville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.42329979",
        "longitude": "153.0630035",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3508,
            "s_seats": 2164,
            "j_seats": 1377,
            "f_seats": 31,
            "r_seats": 99,
            "cargo_demand": 86692
        }
    },
    {
        "iata": "NSY",
        "icao": "LICZ",
        "airportName": "Naval Air Station Sigonella",
        "location_country": "Italy",
        "location_city": "Sicily",
        "location_state": "Catania",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.401699",
        "longitude": "14.9224",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "8012",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "8077",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117043,
            "s_seats": 27177,
            "j_seats": 47387,
            "f_seats": 6275,
            "r_seats": 6296,
            "cargo_demand": 352746
        }
    },
    {
        "iata": "NTB",
        "icao": "ENNO",
        "airportName": "Notodden Airport",
        "location_country": "Norway",
        "location_city": "Notodden",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "59.565701",
        "longitude": "9.21222",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5745",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127084,
            "s_seats": 35069,
            "j_seats": 37842,
            "f_seats": 12303,
            "r_seats": 9119,
            "cargo_demand": 145369
        }
    },
    {
        "iata": "NTD",
        "icao": "KNTD",
        "airportName": "NAS Point Mugu",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Point Mugu",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.12030029",
        "longitude": "-119.1210022",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "11102",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5504",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94726,
            "s_seats": 48859,
            "j_seats": 49677,
            "f_seats": 12921,
            "r_seats": 5564,
            "cargo_demand": 433130
        }
    },
    {
        "iata": "NTE",
        "icao": "LFRS",
        "airportName": "Nantes Atlantique Airport",
        "location_country": "France",
        "location_city": "Pays de la Loire",
        "location_state": "Nantes",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.15319824",
        "longitude": "-1.610730052",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9514",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72599,
            "s_seats": 32527,
            "j_seats": 11797,
            "f_seats": 3658,
            "r_seats": 4140,
            "cargo_demand": 141615
        }
    },
    {
        "iata": "NTL",
        "icao": "YWLM",
        "airportName": "Newcastle Airport / RAAF Base Williamtown",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Newcastle",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-32.79499817",
        "longitude": "151.8339996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7999",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140155,
            "s_seats": 37671,
            "j_seats": 50179,
            "f_seats": 1466,
            "r_seats": 5298,
            "cargo_demand": 160092
        }
    },
    {
        "iata": "NTM",
        "icao": "SNRW",
        "airportName": "Miracema do Tocantins Airport",
        "location_country": "Brazil",
        "location_city": "Tocantins",
        "location_state": "Miracema do Tocantins",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.907221794",
        "longitude": "-48.63499832",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "2192",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3263,
            "s_seats": 2698,
            "j_seats": 1105,
            "f_seats": 181,
            "r_seats": 100,
            "cargo_demand": 60168
        }
    },
    {
        "iata": "NTN",
        "icao": "YNTN",
        "airportName": "Normanton Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Normanton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.68409",
        "longitude": "141.069664",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5499",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161312,
            "s_seats": 46858,
            "j_seats": 20395,
            "f_seats": 4736,
            "r_seats": 9273,
            "cargo_demand": 422586
        }
    },
    {
        "iata": "NTO",
        "icao": "GVAN",
        "airportName": "Agostinho Neto Airport",
        "location_country": "Cape Verde",
        "location_city": "Santo Antio",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "17.20280075",
        "longitude": "-25.09059906",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "1476",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3144,
            "s_seats": 2344,
            "j_seats": 1078,
            "f_seats": 41,
            "r_seats": 195,
            "cargo_demand": 21457
        }
    },
    {
        "iata": "NTQ",
        "icao": "RJNW",
        "airportName": "Noto Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Wajima",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.293098",
        "longitude": "136.962006",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104215,
            "s_seats": 35470,
            "j_seats": 18952,
            "f_seats": 16825,
            "r_seats": 5343,
            "cargo_demand": 134923
        }
    },
    {
        "iata": "NTR",
        "icao": "MMAN",
        "airportName": "Del Norte International Airport",
        "location_country": "Mexico",
        "location_city": "Nuevo Leon",
        "location_state": "Monterrey",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.865601",
        "longitude": "-100.237",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6598",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5049",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50915,
            "s_seats": 35293,
            "j_seats": 53276,
            "f_seats": 11027,
            "r_seats": 8596,
            "cargo_demand": 123336
        }
    },
    {
        "iata": "NTU",
        "icao": "KNTU",
        "airportName": "NAS Oceana",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Virginia Beach",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.820702",
        "longitude": "-76.033501",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "11997",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "7999",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 177231,
            "s_seats": 32285,
            "j_seats": 29533,
            "f_seats": 2742,
            "r_seats": 5909,
            "cargo_demand": 358965
        }
    },
    {
        "iata": "NTX",
        "icao": "WION",
        "airportName": "Ranai Airport",
        "location_country": "Indonesia",
        "location_city": "Natuna Islands",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.90871",
        "longitude": "108.388",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8410",
                "runway_width": "105",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97162,
            "s_seats": 29676,
            "j_seats": 33588,
            "f_seats": 14051,
            "r_seats": 3338,
            "cargo_demand": 208337
        }
    },
    {
        "iata": "NTY",
        "icao": "FAPN",
        "airportName": "Pilanesberg International Airport",
        "location_country": "South Africa",
        "location_city": "Sun City",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.333799",
        "longitude": "27.173401",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9022",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3332,
            "s_seats": 2535,
            "j_seats": 1669,
            "f_seats": 51,
            "r_seats": 116,
            "cargo_demand": 56085
        }
    },
    {
        "iata": "NUB",
        "icao": "YNUM",
        "airportName": "Numbulwar Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Numbulwar",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.27169991",
        "longitude": "135.7169952",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4331",
                "runway_width": "59",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 2377,
            "s_seats": 1629,
            "j_seats": 1118,
            "f_seats": 256,
            "r_seats": 0,
            "cargo_demand": 10351
        }
    },
    {
        "iata": "NUE",
        "icao": "EDDN",
        "airportName": "Nuremberg Airport",
        "location_country": "Germany",
        "location_city": "Bavaria",
        "location_state": "Nuremberg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "49.498699",
        "longitude": "11.078056",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193419,
            "s_seats": 91004,
            "j_seats": 142888,
            "f_seats": 20069,
            "r_seats": 10000,
            "cargo_demand": 22535601
        }
    },
    {
        "iata": "NUI",
        "icao": "PAQT",
        "airportName": "Nuiqsut Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Nuiqsut",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "70.209999",
        "longitude": "-151.005998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4343",
                "runway_width": "90",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 173410,
            "s_seats": 47406,
            "j_seats": 21723,
            "f_seats": 3990,
            "r_seats": 5110,
            "cargo_demand": 126336
        }
    },
    {
        "iata": "NUJ",
        "icao": "OIHS",
        "airportName": "Hamedan Air Base",
        "location_country": "Iran",
        "location_city": "Hamadan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.21160126",
        "longitude": "48.65340042",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "12915",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "14625",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "14300",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69017,
            "s_seats": 27974,
            "j_seats": 47018,
            "f_seats": 6384,
            "r_seats": 4506,
            "cargo_demand": 374894
        }
    },
    {
        "iata": "NUL",
        "icao": "PANU",
        "airportName": "Nulato Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Nulato",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "64.729301",
        "longitude": "-158.074005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 1219,
            "s_seats": 2751,
            "j_seats": 1947,
            "f_seats": 341,
            "r_seats": 196,
            "cargo_demand": 6252
        }
    },
    {
        "iata": "NUM",
        "icao": "OENN",
        "airportName": "Neom Bay Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Neom",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.927598",
        "longitude": "35.28874",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "12326",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97347,
            "s_seats": 36923,
            "j_seats": 52914,
            "f_seats": 10983,
            "r_seats": 9012,
            "cargo_demand": 191495
        }
    },
    {
        "iata": "NUQ",
        "icao": "KNUQ",
        "airportName": "Moffett Federal Airfield",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Mountain View",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.4161",
        "longitude": "-122.049004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "9202",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "8127",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105465,
            "s_seats": 32361,
            "j_seats": 23690,
            "f_seats": 9991,
            "r_seats": 4251,
            "cargo_demand": 234145
        }
    },
    {
        "iata": "NUR",
        "icao": "YNUB",
        "airportName": "Nullabor Motel Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Nullarbor",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31.44169998",
        "longitude": "130.9019928",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3608",
                "runway_width": "",
                "runway_material": "L"
            }
        ],
        "demand": {
            "y_seats": 1409,
            "s_seats": 2640,
            "j_seats": 1993,
            "f_seats": 357,
            "r_seats": 105,
            "cargo_demand": 89358
        }
    },
    {
        "iata": "NUS",
        "icao": "NVSP",
        "airportName": "Norsup Airport",
        "location_country": "Vanuatu",
        "location_city": "Malakula",
        "location_state": "Norsup",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.0797",
        "longitude": "167.401001",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2972",
                "runway_width": "49",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 2990,
            "s_seats": 2614,
            "j_seats": 1557,
            "f_seats": 321,
            "r_seats": 111,
            "cargo_demand": 26951
        }
    },
    {
        "iata": "NUW",
        "icao": "KNUW",
        "airportName": "NAS Whidbey Island",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Oak Harbor",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.351799",
        "longitude": "-122.655998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8001",
                "runway_width": "200",
                "runway_material": "CONC"
            }
        ],
        "demand": {
            "y_seats": 196250,
            "s_seats": 49702,
            "j_seats": 42288,
            "f_seats": 10234,
            "r_seats": 7392,
            "cargo_demand": 221144
        }
    },
    {
        "iata": "NVA",
        "icao": "SKNV",
        "airportName": "Benito Salas Airport",
        "location_country": "Colombia",
        "location_city": "Neiva",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.95015",
        "longitude": "-75.294",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5880",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57459,
            "s_seats": 35575,
            "j_seats": 56401,
            "f_seats": 14984,
            "r_seats": 6196,
            "cargo_demand": 297372
        }
    },
    {
        "iata": "NVD",
        "icao": "KNVD",
        "airportName": "Nevada Municipal Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Nevada",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.85210037",
        "longitude": "-94.30490112",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5901",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2581",
                "runway_width": "40",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2441,
            "s_seats": 2430,
            "j_seats": 1267,
            "f_seats": 249,
            "r_seats": 84,
            "cargo_demand": 33765
        }
    },
    {
        "iata": "NVI",
        "icao": "UTSA",
        "airportName": "Navoi International Airport",
        "location_country": "Uzbekistan",
        "location_city": "Navoiy",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.11719894",
        "longitude": "65.17079926",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 1932,
            "s_seats": 2454,
            "j_seats": 1561,
            "f_seats": 426,
            "r_seats": 25,
            "cargo_demand": 21751
        }
    },
    {
        "iata": "NVS",
        "icao": "LFQG",
        "airportName": "Nevers - Fourchambault Airport",
        "location_country": "France",
        "location_city": "Burgundy",
        "location_state": "Nevers",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.00260162",
        "longitude": "3.113329887",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5348",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74177,
            "s_seats": 49421,
            "j_seats": 33205,
            "f_seats": 14527,
            "r_seats": 9684,
            "cargo_demand": 62075
        }
    },
    {
        "iata": "NVT",
        "icao": "SBNF",
        "airportName": "Navegantes-Ministro Victor Konder International Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "Navegantes",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.879999",
        "longitude": "-48.651402",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "5581",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70018,
            "s_seats": 37265,
            "j_seats": 40194,
            "f_seats": 1609,
            "r_seats": 4567,
            "cargo_demand": 325226
        }
    },
    {
        "iata": "NWA",
        "icao": "FMCI",
        "airportName": "Moheli Bandar Es Eslam Airport",
        "location_country": "Comoros",
        "location_city": "Moheli",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.2981",
        "longitude": "43.766399",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66083,
            "s_seats": 30640,
            "j_seats": 9137,
            "f_seats": 14430,
            "r_seats": 8196,
            "cargo_demand": 404907
        }
    },
    {
        "iata": "NWI",
        "icao": "EGSH",
        "airportName": "Norwich International Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Norwich",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.6758",
        "longitude": "1.28278",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4154",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "6040",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147301,
            "s_seats": 29089,
            "j_seats": 57087,
            "f_seats": 1523,
            "r_seats": 6893,
            "cargo_demand": 122207
        }
    },
    {
        "iata": "NYG",
        "icao": "KNYG",
        "airportName": "MCAF Quantico",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Quantico",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.50170135",
        "longitude": "-77.30529785",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4279",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111183,
            "s_seats": 34881,
            "j_seats": 5414,
            "f_seats": 2820,
            "r_seats": 4737,
            "cargo_demand": 206702
        }
    },
    {
        "iata": "NYI",
        "icao": "DGSN",
        "airportName": "Sunyani Airport",
        "location_country": "Ghana",
        "location_city": "Sunyani",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.361830235",
        "longitude": "-2.328759909",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4227",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127902,
            "s_seats": 48565,
            "j_seats": 32859,
            "f_seats": 19728,
            "r_seats": 9139,
            "cargo_demand": 436456
        }
    },
    {
        "iata": "NYN",
        "icao": "YNYN",
        "airportName": "Nyngan Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Nyngan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31.55109978",
        "longitude": "147.2030029",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5390",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3484",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1140,
            "s_seats": 1918,
            "j_seats": 1555,
            "f_seats": 328,
            "r_seats": 10,
            "cargo_demand": 75401
        }
    },
    {
        "iata": "NYO",
        "icao": "ESKN",
        "airportName": "Stockholm Skavsta Airport",
        "location_country": "Sweden",
        "location_city": "Stockholm / Nykoping",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.788601",
        "longitude": "16.912201",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9442",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6690",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135091,
            "s_seats": 45733,
            "j_seats": 43661,
            "f_seats": 4011,
            "r_seats": 9788,
            "cargo_demand": 239425
        }
    },
    {
        "iata": "NYR",
        "icao": "UENN",
        "airportName": "Nyurba Airport",
        "location_country": "Russia",
        "location_city": "Yakutia",
        "location_state": "Nyurba",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "63.294998",
        "longitude": "118.336998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4265",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1470,
            "s_seats": 2932,
            "j_seats": 1588,
            "f_seats": 475,
            "r_seats": 193,
            "cargo_demand": 93746
        }
    },
    {
        "iata": "NYU",
        "icao": "VYBG",
        "airportName": "Nyaung U Airport",
        "location_country": "Myanmar",
        "location_city": "Bagan / Nyaung-U",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.17880058",
        "longitude": "94.93019867",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1994,
            "s_seats": 2141,
            "j_seats": 1309,
            "f_seats": 391,
            "r_seats": 27,
            "cargo_demand": 7745
        }
    },
    {
        "iata": "NYW",
        "icao": "VYMY",
        "airportName": "Monywa Airport",
        "location_country": "Myanmar",
        "location_city": "Monywa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "22.221638",
        "longitude": "95.093479",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8530",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1800,
            "s_seats": 1996,
            "j_seats": 1999,
            "f_seats": 444,
            "r_seats": 58,
            "cargo_demand": 29027
        }
    },
    {
        "iata": "NZA",
        "icao": "FNZG",
        "airportName": "Nzagi Airport",
        "location_country": "Angola",
        "location_city": "Nzagi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.716939926",
        "longitude": "21.35820007",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5906",
                "runway_width": "68",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2151,
            "s_seats": 1689,
            "j_seats": 1907,
            "f_seats": 347,
            "r_seats": 189,
            "cargo_demand": 67138
        }
    },
    {
        "iata": "NZC",
        "icao": "SPZA",
        "airportName": "Maria Reiche Neuman Airport",
        "location_country": "Peru",
        "location_city": "Nazca",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.85400009",
        "longitude": "-74.96150208",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3355",
                "runway_width": "65",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178330,
            "s_seats": 35541,
            "j_seats": 46727,
            "f_seats": 4691,
            "r_seats": 3536,
            "cargo_demand": 301325
        }
    },
    {
        "iata": "NZE",
        "icao": "GUNZ",
        "airportName": "Nzerekore Airport",
        "location_country": "Guinea",
        "location_city": "Nzerekore",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "7.806019306",
        "longitude": "-8.701797485",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2657,
            "s_seats": 2013,
            "j_seats": 1374,
            "f_seats": 31,
            "r_seats": 147,
            "cargo_demand": 8931
        }
    },
    {
        "iata": "NZY",
        "icao": "KNZY",
        "airportName": "NAS North Island",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "San Diego",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.69919968",
        "longitude": "-117.2149963",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7500",
                "runway_width": "300",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 128785,
            "s_seats": 37658,
            "j_seats": 31088,
            "f_seats": 6173,
            "r_seats": 6256,
            "cargo_demand": 490286
        }
    },
    {
        "iata": "OAG",
        "icao": "YORG",
        "airportName": "Orange Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Orange",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.38169861",
        "longitude": "149.1329956",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2559",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5499",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62980,
            "s_seats": 43975,
            "j_seats": 15513,
            "f_seats": 2238,
            "r_seats": 7150,
            "cargo_demand": 321573
        }
    },
    {
        "iata": "OAH",
        "icao": "OASD",
        "airportName": "Shindand Air Base",
        "location_country": "Afghanistan",
        "location_city": "Shindand",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.3913",
        "longitude": "62.261002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9140",
                "runway_width": "160",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1382,
            "s_seats": 2415,
            "j_seats": 1469,
            "f_seats": 195,
            "r_seats": 174,
            "cargo_demand": 17330
        }
    },
    {
        "iata": "OAI",
        "icao": "OAIX",
        "airportName": "Bagram Airfield",
        "location_country": "Afghanistan",
        "location_city": "Bagram",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.946098",
        "longitude": "69.264999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "9687",
                "runway_width": "85",
                "runway_material": "CON"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "11820",
                "runway_width": "151",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 61824,
            "s_seats": 48172,
            "j_seats": 28219,
            "f_seats": 11840,
            "r_seats": 3170,
            "cargo_demand": 441819
        }
    },
    {
        "iata": "OAJ",
        "icao": "KOAJ",
        "airportName": "Albert J. Ellis Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Jacksonville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.829201",
        "longitude": "-77.612099",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7100",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95267,
            "s_seats": 39377,
            "j_seats": 47485,
            "f_seats": 6347,
            "r_seats": 5040,
            "cargo_demand": 360985
        }
    },
    {
        "iata": "OAK",
        "icao": "KOAK",
        "airportName": "Oakland International Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Oakland",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.721298",
        "longitude": "-122.221001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "5458",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "6213",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3376",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140467,
            "s_seats": 87256,
            "j_seats": 90206,
            "f_seats": 44345,
            "r_seats": 10000,
            "cargo_demand": 10715535
        }
    },
    {
        "iata": "OAL",
        "icao": "SSKW",
        "airportName": "Capital do Cafe Airport",
        "location_country": "Brazil",
        "location_city": "Rondonia",
        "location_state": "Cacoal",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.496",
        "longitude": "-61.4508",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 3027,
            "s_seats": 2484,
            "j_seats": 1596,
            "f_seats": 376,
            "r_seats": 147,
            "cargo_demand": 60421
        }
    },
    {
        "iata": "OAM",
        "icao": "NZOU",
        "airportName": "Oamaru Airport",
        "location_country": "New Zealand",
        "location_city": "Oamaru",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-44.97000122",
        "longitude": "171.0820007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2775",
                "runway_width": "164",
                "runway_material": "GRE"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3107",
                "runway_width": "164",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4210",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118120,
            "s_seats": 38624,
            "j_seats": 40716,
            "f_seats": 14269,
            "r_seats": 4178,
            "cargo_demand": 367729
        }
    },
    {
        "iata": "OAR",
        "icao": "KOAR",
        "airportName": "Marina Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Monterey / Marina",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.68190002",
        "longitude": "-121.762001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3507,
            "s_seats": 1821,
            "j_seats": 1807,
            "f_seats": 448,
            "r_seats": 111,
            "cargo_demand": 64268
        }
    },
    {
        "iata": "OAX",
        "icao": "MMOX",
        "airportName": "Xoxocotlan International Airport",
        "location_country": "Mexico",
        "location_city": "Oaxaca",
        "location_state": "Oaxaca de Juarez",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.99990082",
        "longitude": "-96.72660065",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8038",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160282,
            "s_seats": 25625,
            "j_seats": 47183,
            "f_seats": 16730,
            "r_seats": 3710,
            "cargo_demand": 421341
        }
    },
    {
        "iata": "OBE",
        "icao": "KOBE",
        "airportName": "Okeechobee County Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Okeechobee",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.26280022",
        "longitude": "-80.84980011",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1079,
            "s_seats": 2835,
            "j_seats": 1651,
            "f_seats": 6,
            "r_seats": 41,
            "cargo_demand": 93559
        }
    },
    {
        "iata": "OBF",
        "icao": "EDMO",
        "airportName": "Oberpfaffenhofen Airport",
        "location_country": "Germany",
        "location_city": "Bavaria",
        "location_state": "Oberpfaffenhofen",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.081402",
        "longitude": "11.2831",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7500",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102025,
            "s_seats": 35855,
            "j_seats": 50060,
            "f_seats": 15843,
            "r_seats": 8080,
            "cargo_demand": 320808
        }
    },
    {
        "iata": "OBL",
        "icao": "EBZR",
        "airportName": "Oostmalle Airfield",
        "location_country": "Belgium",
        "location_city": "Zoersel",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "51.264702",
        "longitude": "4.75333",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9777",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2984,
            "s_seats": 2822,
            "j_seats": 1093,
            "f_seats": 153,
            "r_seats": 98,
            "cargo_demand": 29534
        }
    },
    {
        "iata": "OBN",
        "icao": "EGEO",
        "airportName": "Oban Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Oban",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "56.46350098",
        "longitude": "-5.399670124",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4146",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04/22",
                "runway_length": "2953",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1939,
            "s_seats": 2254,
            "j_seats": 1420,
            "f_seats": 215,
            "r_seats": 174,
            "cargo_demand": 9661
        }
    },
    {
        "iata": "OBO",
        "icao": "RJCB",
        "airportName": "Tokachi-Obihiro Airport",
        "location_country": "Japan",
        "location_city": "Hokkaido",
        "location_state": "Obihiro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.73329926",
        "longitude": "143.2169952",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8202",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188259,
            "s_seats": 32254,
            "j_seats": 29853,
            "f_seats": 4280,
            "r_seats": 5411,
            "cargo_demand": 197401
        }
    },
    {
        "iata": "OBS",
        "icao": "LFHO",
        "airportName": "Aubenas Aerodrome",
        "location_country": "France",
        "location_city": "Rhone-Alpes",
        "location_state": "Aubenas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.544203",
        "longitude": "4.372192",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4675",
                "runway_width": "98",
                "runway_material": "Paved"
            }
        ],
        "demand": {
            "y_seats": 165915,
            "s_seats": 41590,
            "j_seats": 20640,
            "f_seats": 16215,
            "r_seats": 8867,
            "cargo_demand": 350007
        }
    },
    {
        "iata": "OBU",
        "icao": "PAOB",
        "airportName": "Kobuk Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kobuk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "66.91230011",
        "longitude": "-156.8970032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 1774,
            "s_seats": 1503,
            "j_seats": 1557,
            "f_seats": 19,
            "r_seats": 85,
            "cargo_demand": 85628
        }
    },
    {
        "iata": "OCC",
        "icao": "SECO",
        "airportName": "Francisco de Orellana Airport",
        "location_country": "Ecuador",
        "location_city": "Puerto Francisco de Orellana (Coca)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.462886006",
        "longitude": "-76.98680115",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6760",
                "runway_width": "90",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180917,
            "s_seats": 45909,
            "j_seats": 20301,
            "f_seats": 18356,
            "r_seats": 5230,
            "cargo_demand": 424731
        }
    },
    {
        "iata": "OCE",
        "icao": "KOXB",
        "airportName": "Ocean City Municipal Airport",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Ocean City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.3103981",
        "longitude": "-75.12400055",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3201",
                "runway_width": "75",
                "runway_material": "PEM"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4072",
                "runway_width": "75",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 2723,
            "s_seats": 2175,
            "j_seats": 1660,
            "f_seats": 110,
            "r_seats": 126,
            "cargo_demand": 63502
        }
    },
    {
        "iata": "OCF",
        "icao": "KOCF",
        "airportName": "Ocala International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Ocala",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "29.17259979",
        "longitude": "-82.22419739",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3010",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6907",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1724,
            "s_seats": 1843,
            "j_seats": 1765,
            "f_seats": 121,
            "r_seats": 184,
            "cargo_demand": 79675
        }
    },
    {
        "iata": "OCH",
        "icao": "KOCH",
        "airportName": "A.L. Mangham Jr. Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Nacogdoches",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.57799911",
        "longitude": "-94.70950317",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3610",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1547,
            "s_seats": 2113,
            "j_seats": 1709,
            "f_seats": 327,
            "r_seats": 71,
            "cargo_demand": 27441
        }
    },
    {
        "iata": "OCJ",
        "icao": "MKBS",
        "airportName": "Ian Fleming International Airport",
        "location_country": "Jamaica",
        "location_city": "Ocho Rios",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.404079",
        "longitude": "-76.969754",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179078,
            "s_seats": 35876,
            "j_seats": 6381,
            "f_seats": 9188,
            "r_seats": 9344,
            "cargo_demand": 62726
        }
    },
    {
        "iata": "OCN",
        "icao": "KOKB",
        "airportName": "Oceanside Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Oceanside",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.21730042",
        "longitude": "-117.3539963",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2712",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2631,
            "s_seats": 1668,
            "j_seats": 1883,
            "f_seats": 185,
            "r_seats": 47,
            "cargo_demand": 30968
        }
    },
    {
        "iata": "OCV",
        "icao": "SKOC",
        "airportName": "Aguas Claras Airport",
        "location_country": "Colombia",
        "location_city": "Ocana",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.31506",
        "longitude": "-73.3583",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172369,
            "s_seats": 29181,
            "j_seats": 12520,
            "f_seats": 5852,
            "r_seats": 6378,
            "cargo_demand": 268783
        }
    },
    {
        "iata": "OCW",
        "icao": "KOCW",
        "airportName": "Washington-Warren Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Washington",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.57049942",
        "longitude": "-77.04979706",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3766,
            "s_seats": 2172,
            "j_seats": 1341,
            "f_seats": 288,
            "r_seats": 62,
            "cargo_demand": 39371
        }
    },
    {
        "iata": "ODB",
        "icao": "LEBA",
        "airportName": "Cordoba Airport",
        "location_country": "Spain",
        "location_city": "Andalusia",
        "location_state": "Cordoba",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.84199905",
        "longitude": "-4.848879814",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4527",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117799,
            "s_seats": 27270,
            "j_seats": 48261,
            "f_seats": 17851,
            "r_seats": 4441,
            "cargo_demand": 345474
        }
    },
    {
        "iata": "ODD",
        "icao": "YOOD",
        "airportName": "Oodnadatta Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Oodnadatta",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.56170082",
        "longitude": "135.4470062",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4488",
                "runway_width": "",
                "runway_material": "N"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4215",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 3162,
            "s_seats": 2147,
            "j_seats": 1366,
            "f_seats": 139,
            "r_seats": 61,
            "cargo_demand": 4170
        }
    },
    {
        "iata": "ODE",
        "icao": "EKOD",
        "airportName": "Hans Christian Andersen Airport",
        "location_country": "Denmark",
        "location_city": "Odense",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.47669983",
        "longitude": "10.33090019",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6053",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2280",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 161860,
            "s_seats": 32874,
            "j_seats": 12676,
            "f_seats": 8868,
            "r_seats": 6463,
            "cargo_demand": 178027
        }
    },
    {
        "iata": "ODH",
        "icao": "EGVO",
        "airportName": "RAF Odiham",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Odiham",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.23410034",
        "longitude": "-0.942825019",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6030",
                "runway_width": "141",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90923,
            "s_seats": 49781,
            "j_seats": 32146,
            "f_seats": 15833,
            "r_seats": 9280,
            "cargo_demand": 292892
        }
    },
    {
        "iata": "ODR",
        "icao": "YORV",
        "airportName": "Ord River Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Ord River",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.34079933",
        "longitude": "128.9120026",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3198",
                "runway_width": "",
                "runway_material": "G"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3503",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 2463,
            "s_seats": 2075,
            "j_seats": 1247,
            "f_seats": 444,
            "r_seats": 66,
            "cargo_demand": 67420
        }
    },
    {
        "iata": "ODS",
        "icao": "UKOO",
        "airportName": "Odesa International Airport",
        "location_country": "Ukraine",
        "location_city": "Odesa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "46.42679977",
        "longitude": "30.67650032",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "1815",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2950",
                "runway_width": "190",
                "runway_material": "BRI"
            }
        ],
        "demand": {
            "y_seats": 387137,
            "s_seats": 75875,
            "j_seats": 49136,
            "f_seats": 20332,
            "r_seats": 10000,
            "cargo_demand": 24699166
        }
    },
    {
        "iata": "ODW",
        "icao": "KOKH",
        "airportName": "A.J. Eisenberg Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Oak Harbor",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "48.25149918",
        "longitude": "-122.6740036",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3244",
                "runway_width": "25",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3477,
            "s_seats": 2033,
            "j_seats": 1940,
            "f_seats": 146,
            "r_seats": 182,
            "cargo_demand": 25314
        }
    },
    {
        "iata": "OEC",
        "icao": "WPOC",
        "airportName": "Oecusse Airport",
        "location_country": "East Timor",
        "location_city": "Oecussi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.19806",
        "longitude": "124.343002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3609",
                "runway_width": "85",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 180607,
            "s_seats": 37664,
            "j_seats": 27583,
            "f_seats": 11835,
            "r_seats": 7407,
            "cargo_demand": 81527
        }
    },
    {
        "iata": "OEO",
        "icao": "KOEO",
        "airportName": "L.O. Simenstad Municipal Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Osceola",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "45.31000137",
        "longitude": "-92.69190216",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2270",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3862",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3934,
            "s_seats": 1538,
            "j_seats": 1340,
            "f_seats": 223,
            "r_seats": 92,
            "cargo_demand": 87912
        }
    },
    {
        "iata": "OER",
        "icao": "ESNO",
        "airportName": "ornskoldsvik Airport",
        "location_country": "Sweden",
        "location_city": "ornskoldsvik",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "63.40829849",
        "longitude": "18.98999977",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6607",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157299,
            "s_seats": 41429,
            "j_seats": 47899,
            "f_seats": 2310,
            "r_seats": 5966,
            "cargo_demand": 400480
        }
    },
    {
        "iata": "OES",
        "icao": "SAVN",
        "airportName": "Antoine de Saint Exupery Airport",
        "location_country": "Argentina",
        "location_city": "Rio Negro",
        "location_state": "San Antonio Oeste",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-40.7512",
        "longitude": "-65.0343",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1468,
            "s_seats": 1962,
            "j_seats": 1194,
            "f_seats": 275,
            "r_seats": 168,
            "cargo_demand": 61726
        }
    },
    {
        "iata": "OFF",
        "icao": "KOFF",
        "airportName": "Offutt Air Force Base",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Omaha",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.11830139",
        "longitude": "-95.91249847",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "11700",
                "runway_width": "300",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 71057,
            "s_seats": 46633,
            "j_seats": 55883,
            "f_seats": 3530,
            "r_seats": 8559,
            "cargo_demand": 362568
        }
    },
    {
        "iata": "OFK",
        "icao": "KOFK",
        "airportName": "Norfolk Regional Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Norfolk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.98550034",
        "longitude": "-97.43509674",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5800",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5800",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55279,
            "s_seats": 29911,
            "j_seats": 25202,
            "f_seats": 7830,
            "r_seats": 8771,
            "cargo_demand": 477051
        }
    },
    {
        "iata": "OFU",
        "icao": "NSAS",
        "airportName": "Ofu Airport",
        "location_country": "American Samoa",
        "location_city": "Ofu Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.1844",
        "longitude": "-169.669998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2000",
                "runway_width": "60",
                "runway_material": "CONC-G"
            }
        ],
        "demand": {
            "y_seats": 2387,
            "s_seats": 2259,
            "j_seats": 1030,
            "f_seats": 219,
            "r_seats": 7,
            "cargo_demand": 18946
        }
    },
    {
        "iata": "OGA",
        "icao": "KOGA",
        "airportName": "Searle Field",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Ogallala",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.11949921",
        "longitude": "-101.7699966",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5100",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3698",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1675,
            "s_seats": 2715,
            "j_seats": 1004,
            "f_seats": 102,
            "r_seats": 9,
            "cargo_demand": 37378
        }
    },
    {
        "iata": "OGB",
        "icao": "KOGB",
        "airportName": "Orangeburg Municipal Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Orangeburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.45679855",
        "longitude": "-80.85949707",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2800",
                "runway_width": "165",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5401",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 195532,
            "s_seats": 25195,
            "j_seats": 13343,
            "f_seats": 9235,
            "r_seats": 8688,
            "cargo_demand": 435542
        }
    },
    {
        "iata": "OGD",
        "icao": "KOGD",
        "airportName": "Ogden-Hinckley Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Ogden",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.19589996",
        "longitude": "-112.012001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8103",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "5600",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5195",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146308,
            "s_seats": 26935,
            "j_seats": 33360,
            "f_seats": 7322,
            "r_seats": 5153,
            "cargo_demand": 284735
        }
    },
    {
        "iata": "OGG",
        "icao": "PHOG",
        "airportName": "Kahului Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Kahului",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.8986",
        "longitude": "-156.429993",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6995",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "4990",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118727,
            "s_seats": 42601,
            "j_seats": 30002,
            "f_seats": 19729,
            "r_seats": 4145,
            "cargo_demand": 205971
        }
    },
    {
        "iata": "OGL",
        "icao": "SYGO",
        "airportName": "Ogle Airport",
        "location_country": "Guyana",
        "location_city": "Georgetown",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.80628",
        "longitude": "-58.1059",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4201",
                "runway_width": "100",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 163917,
            "s_seats": 44854,
            "j_seats": 56823,
            "f_seats": 1714,
            "r_seats": 8370,
            "cargo_demand": 451331
        }
    },
    {
        "iata": "OGN",
        "icao": "ROYN",
        "airportName": "Yonaguni Airport",
        "location_country": "Japan",
        "location_city": "Yaeyama Islands",
        "location_state": "Yonaguni",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.467298",
        "longitude": "122.979827",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4920",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 75522,
            "s_seats": 43845,
            "j_seats": 43603,
            "f_seats": 7064,
            "r_seats": 3552,
            "cargo_demand": 188807
        }
    },
    {
        "iata": "OGS",
        "icao": "KOGS",
        "airportName": "Ogdensburg International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Ogdensburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.68190002",
        "longitude": "-75.46549988",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1856,
            "s_seats": 2943,
            "j_seats": 1394,
            "f_seats": 56,
            "r_seats": 106,
            "cargo_demand": 39601
        }
    },
    {
        "iata": "OGX",
        "icao": "DAUU",
        "airportName": "Ain Beida Airport",
        "location_country": "Algeria",
        "location_city": "Ouargla",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.9172",
        "longitude": "5.41278",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97268,
            "s_seats": 37162,
            "j_seats": 38796,
            "f_seats": 12306,
            "r_seats": 5310,
            "cargo_demand": 249576
        }
    },
    {
        "iata": "OGZ",
        "icao": "URMO",
        "airportName": "Beslan Airport",
        "location_country": "Russia",
        "location_city": "North Ossetia-Alania",
        "location_state": "Vladikavkaz",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.20510101",
        "longitude": "44.60660172",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 63653,
            "s_seats": 49696,
            "j_seats": 10454,
            "f_seats": 10962,
            "r_seats": 3424,
            "cargo_demand": 360218
        }
    },
    {
        "iata": "OHA",
        "icao": "NZOH",
        "airportName": "RNZAF Base Ohakea",
        "location_country": "New Zealand",
        "location_city": "Bulls",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-40.20600128",
        "longitude": "175.3880005",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8028",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "1887",
                "runway_width": "89",
                "runway_material": "GRS"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6998",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74023,
            "s_seats": 31788,
            "j_seats": 57793,
            "f_seats": 16084,
            "r_seats": 5273,
            "cargo_demand": 106489
        }
    },
    {
        "iata": "OHD",
        "icao": "LWOH",
        "airportName": "\"Ohrid \"\"St. Paul the Apostle\"\" Airport\"",
        "location_country": "North Macedonia",
        "location_city": "Ohrid",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.18",
        "longitude": "20.7423",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8366",
                "runway_width": "140",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 60230,
            "s_seats": 35269,
            "j_seats": 23869,
            "f_seats": 9412,
            "r_seats": 5311,
            "cargo_demand": 460653
        }
    },
    {
        "iata": "OHH",
        "icao": "UHSH",
        "airportName": "Okha Airport",
        "location_country": "Russia",
        "location_city": "Sakhalin Oblast",
        "location_state": "Okha",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "53.517656",
        "longitude": "142.879772",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4265",
                "runway_width": "138",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1545,
            "s_seats": 2287,
            "j_seats": 1370,
            "f_seats": 488,
            "r_seats": 2,
            "cargo_demand": 64412
        }
    },
    {
        "iata": "OHI",
        "icao": "FYOS",
        "airportName": "Oshakati Airport",
        "location_country": "Namibia",
        "location_city": "Oshakati",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.797",
        "longitude": "15.6993",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4921",
                "runway_width": "82",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 1405,
            "s_seats": 2275,
            "j_seats": 1620,
            "f_seats": 452,
            "r_seats": 64,
            "cargo_demand": 91228
        }
    },
    {
        "iata": "OHR",
        "icao": "EDXY",
        "airportName": "Wyk auf Fohr Airport",
        "location_country": "Germany",
        "location_city": "Schleswig-Holstein",
        "location_state": "Wyk auf Fohr",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "54.68444443",
        "longitude": "8.52833271",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2165",
                "runway_width": "131",
                "runway_material": "Grass"
            },
            {
                "runway_name": "10/28",
                "runway_length": "1984",
                "runway_width": "131",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3180,
            "s_seats": 2239,
            "j_seats": 1873,
            "f_seats": 58,
            "r_seats": 70,
            "cargo_demand": 6851
        }
    },
    {
        "iata": "OHS",
        "icao": "OOSH",
        "airportName": "Sohar Airport",
        "location_country": "Oman",
        "location_city": "Sohar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "24.38604",
        "longitude": "56.62541",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "13100",
                "runway_width": "194",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1782,
            "s_seats": 2439,
            "j_seats": 1362,
            "f_seats": 172,
            "r_seats": 33,
            "cargo_demand": 70120
        }
    },
    {
        "iata": "OIA",
        "icao": "SDOW",
        "airportName": "Ouril\u00e2ndia do Norte Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Ouril\u00e2ndia do Norte",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.763100147",
        "longitude": "-51.04990005",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2256,
            "s_seats": 2807,
            "j_seats": 1241,
            "f_seats": 275,
            "r_seats": 75,
            "cargo_demand": 21063
        }
    },
    {
        "iata": "OIC",
        "icao": "KOIC",
        "airportName": "Lt. Warren Eaton Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Norwich",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.5666008",
        "longitude": "-75.52410126",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4724",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2484,
            "s_seats": 2650,
            "j_seats": 1284,
            "f_seats": 240,
            "r_seats": 150,
            "cargo_demand": 51807
        }
    },
    {
        "iata": "OIM",
        "icao": "RJTO",
        "airportName": "Oshima Airport",
        "location_country": "Japan",
        "location_city": "Izu Islands",
        "location_state": "o\u0152shima",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.7820015",
        "longitude": "139.3600006",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5905",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161030,
            "s_seats": 25985,
            "j_seats": 30406,
            "f_seats": 2960,
            "r_seats": 6397,
            "cargo_demand": 152755
        }
    },
    {
        "iata": "OIR",
        "icao": "RJEO",
        "airportName": "Okushiri Airport",
        "location_country": "Japan",
        "location_city": "Okushiri Island",
        "location_state": "Okushiri",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.07170105",
        "longitude": "139.4329987",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4922",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67681,
            "s_seats": 46960,
            "j_seats": 49401,
            "f_seats": 6839,
            "r_seats": 9019,
            "cargo_demand": 207782
        }
    },
    {
        "iata": "OIT",
        "icao": "RJFO",
        "airportName": "Oita Airport",
        "location_country": "Japan",
        "location_city": "Kyushu",
        "location_state": "Oita",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.47940063",
        "longitude": "131.7369995",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9840",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198592,
            "s_seats": 27336,
            "j_seats": 32101,
            "f_seats": 1053,
            "r_seats": 4091,
            "cargo_demand": 197585
        }
    },
    {
        "iata": "OJC",
        "icao": "KOJC",
        "airportName": "Johnson County Executive Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Olathe",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.84759903",
        "longitude": "-94.73760223",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4098",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2283,
            "s_seats": 2008,
            "j_seats": 1467,
            "f_seats": 295,
            "r_seats": 66,
            "cargo_demand": 14503
        }
    },
    {
        "iata": "OKA",
        "icao": "ROAH",
        "airportName": "Naha Airport",
        "location_country": "Japan",
        "location_city": "Ryukyu Islands",
        "location_state": "Okinawa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "26.195801",
        "longitude": "127.646004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "9840",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "8858",
                "runway_width": "197",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 488168,
            "s_seats": 82087,
            "j_seats": 105731,
            "f_seats": 35762,
            "r_seats": 10000,
            "cargo_demand": 16175103
        }
    },
    {
        "iata": "OKC",
        "icao": "KOKC",
        "airportName": "Will Rogers World Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Oklahoma City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "35.39310074",
        "longitude": "-97.60070038",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7800",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "9802",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "9800",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3079",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 229388,
            "s_seats": 77821,
            "j_seats": 165421,
            "f_seats": 49523,
            "r_seats": 10000,
            "cargo_demand": 20294363
        }
    },
    {
        "iata": "OKD",
        "icao": "RJCO",
        "airportName": "Okadama Airport",
        "location_country": "Japan",
        "location_city": "Hokkaido",
        "location_state": "Sapporo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.117447",
        "longitude": "141.38134",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4920",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 98157,
            "s_seats": 33534,
            "j_seats": 59707,
            "f_seats": 2635,
            "r_seats": 6633,
            "cargo_demand": 199948
        }
    },
    {
        "iata": "OKE",
        "icao": "RJKB",
        "airportName": "Okinoerabu Airport",
        "location_country": "Japan",
        "location_city": "Amami Islands",
        "location_state": "Okinoerabujima",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.425501",
        "longitude": "128.701004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4430",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138374,
            "s_seats": 44125,
            "j_seats": 29115,
            "f_seats": 1054,
            "r_seats": 6649,
            "cargo_demand": 402234
        }
    },
    {
        "iata": "OKI",
        "icao": "RJNO",
        "airportName": "Oki Airport",
        "location_country": "Japan",
        "location_city": "Oki Islands",
        "location_state": "Dogo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.178388",
        "longitude": "133.323566",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6531",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130243,
            "s_seats": 46748,
            "j_seats": 29279,
            "f_seats": 1931,
            "r_seats": 7760,
            "cargo_demand": 222625
        }
    },
    {
        "iata": "OKJ",
        "icao": "RJOB",
        "airportName": "Okayama Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Okayama",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.756901",
        "longitude": "133.854996",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9843",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155815,
            "s_seats": 36970,
            "j_seats": 40424,
            "f_seats": 6397,
            "r_seats": 8540,
            "cargo_demand": 45788
        }
    },
    {
        "iata": "OKK",
        "icao": "KOKK",
        "airportName": "Kokomo Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Kokomo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.52819824",
        "longitude": "-86.05899811",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5201",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3083,
            "s_seats": 2336,
            "j_seats": 1737,
            "f_seats": 476,
            "r_seats": 136,
            "cargo_demand": 54201
        }
    },
    {
        "iata": "OKL",
        "icao": "WAJO",
        "airportName": "Gunung Bintang Airport",
        "location_country": "Indonesia",
        "location_city": "Oksibil",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-4.9071",
        "longitude": "140.6277",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2854",
                "runway_width": "59",
                "runway_material": ""
            },
            {
                "runway_name": "error/error",
                "runway_length": "",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2716,
            "s_seats": 1689,
            "j_seats": 1460,
            "f_seats": 265,
            "r_seats": 117,
            "cargo_demand": 73166
        }
    },
    {
        "iata": "OKM",
        "icao": "KOKM",
        "airportName": "Okmulgee Regional Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Okmulgee",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.66809845",
        "longitude": "-95.94869995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4300",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5150",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1105,
            "s_seats": 1681,
            "j_seats": 1782,
            "f_seats": 198,
            "r_seats": 75,
            "cargo_demand": 97013
        }
    },
    {
        "iata": "OKN",
        "icao": "FOGQ",
        "airportName": "Okondja Airport",
        "location_country": "Gabon",
        "location_city": "Okondja",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.665214002",
        "longitude": "13.67310047",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89984,
            "s_seats": 40324,
            "j_seats": 57790,
            "f_seats": 14314,
            "r_seats": 9128,
            "cargo_demand": 204729
        }
    },
    {
        "iata": "OKO",
        "icao": "RJTY",
        "airportName": "Yokota Air Base",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Fussa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "35.74850082",
        "longitude": "139.3480072",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "11000",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 423262,
            "s_seats": 77017,
            "j_seats": 168978,
            "f_seats": 53380,
            "r_seats": 10000,
            "cargo_demand": 14244006
        }
    },
    {
        "iata": "OKP",
        "icao": "AYOJ",
        "airportName": "Oksapmin Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Oksapmin",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.2261",
        "longitude": "142.2259",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2215",
                "runway_width": "98",
                "runway_material": "Grassed yellow clay"
            }
        ],
        "demand": {
            "y_seats": 1476,
            "s_seats": 1636,
            "j_seats": 1672,
            "f_seats": 479,
            "r_seats": 51,
            "cargo_demand": 61379
        }
    },
    {
        "iata": "OKQ",
        "icao": "WAKO",
        "airportName": "Okaba Airport",
        "location_country": "Indonesia",
        "location_city": "Okaba",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.0946",
        "longitude": "139.7233",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "1968",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1720,
            "s_seats": 1916,
            "j_seats": 1851,
            "f_seats": 168,
            "r_seats": 61,
            "cargo_demand": 43151
        }
    },
    {
        "iata": "OKR",
        "icao": "YYKI",
        "airportName": "Yorke Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Yorke Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.752801",
        "longitude": "143.405673",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3281",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3858,
            "s_seats": 2994,
            "j_seats": 1732,
            "f_seats": 80,
            "r_seats": 136,
            "cargo_demand": 92386
        }
    },
    {
        "iata": "OKS",
        "icao": "KOKS",
        "airportName": "Garden County Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Oshkosh",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.401001",
        "longitude": "-102.355003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4701",
                "runway_width": "60",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1793,
            "s_seats": 2912,
            "j_seats": 1338,
            "f_seats": 403,
            "r_seats": 144,
            "cargo_demand": 54640
        }
    },
    {
        "iata": "OKY",
        "icao": "YBOK",
        "airportName": "Oakey Army Aviation Centre",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Oakey",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.41139984",
        "longitude": "151.7350006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2999",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3278",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5410",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95766,
            "s_seats": 43595,
            "j_seats": 19560,
            "f_seats": 17342,
            "r_seats": 6012,
            "cargo_demand": 468427
        }
    },
    {
        "iata": "OLA",
        "icao": "ENOL",
        "airportName": "orland Airport",
        "location_country": "Norway",
        "location_city": "orland",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "63.69889832",
        "longitude": "9.604000092",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "8904",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 84219,
            "s_seats": 40616,
            "j_seats": 23569,
            "f_seats": 19189,
            "r_seats": 3171,
            "cargo_demand": 353755
        }
    },
    {
        "iata": "OLB",
        "icao": "LIEO",
        "airportName": "Olbia Costa Smeralda Airport",
        "location_country": "Italy",
        "location_city": "Sardinia",
        "location_state": "Olbia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.898701",
        "longitude": "9.51763",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9006",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167960,
            "s_seats": 37245,
            "j_seats": 23966,
            "f_seats": 6900,
            "r_seats": 6298,
            "cargo_demand": 322071
        }
    },
    {
        "iata": "OLC",
        "icao": "SDCG",
        "airportName": "Senadora Eunice Michiles Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Sio Paulo de OliveniCa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.467929508",
        "longitude": "-68.92041206",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "3937",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3562,
            "s_seats": 2654,
            "j_seats": 1651,
            "f_seats": 58,
            "r_seats": 24,
            "cargo_demand": 40331
        }
    },
    {
        "iata": "OLD",
        "icao": "KOLD",
        "airportName": "Old Town Municipal Airport and Seaplane Base",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Old Town",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.952801",
        "longitude": "-68.674301",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3199",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3999",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17W/35W",
                "runway_length": "8400",
                "runway_width": "100",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 1332,
            "s_seats": 2050,
            "j_seats": 1498,
            "f_seats": 176,
            "r_seats": 11,
            "cargo_demand": 91775
        }
    },
    {
        "iata": "OLE",
        "icao": "KOLE",
        "airportName": "Cattaraugus County-Olean Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Olean",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.24119949",
        "longitude": "-78.37139893",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4800",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2117",
                "runway_width": "105",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1067,
            "s_seats": 1588,
            "j_seats": 1206,
            "f_seats": 434,
            "r_seats": 124,
            "cargo_demand": 59537
        }
    },
    {
        "iata": "OLF",
        "icao": "KOLF",
        "airportName": "L. M. Clayton Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Wolf Point",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.0945015",
        "longitude": "-105.5749969",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5089",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74872,
            "s_seats": 25322,
            "j_seats": 28894,
            "f_seats": 19466,
            "r_seats": 6456,
            "cargo_demand": 177810
        }
    },
    {
        "iata": "OLJ",
        "icao": "NVSZ",
        "airportName": "Olpoi Airport",
        "location_country": "Vanuatu",
        "location_city": "Olpoi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.88169956",
        "longitude": "166.5579987",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2362",
                "runway_width": "82",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3223,
            "s_seats": 1769,
            "j_seats": 1862,
            "f_seats": 133,
            "r_seats": 178,
            "cargo_demand": 6837
        }
    },
    {
        "iata": "OLM",
        "icao": "KOLM",
        "airportName": "Olympia Regional Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Olympia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.9693985",
        "longitude": "-122.9029999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4157",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5501",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58716,
            "s_seats": 25374,
            "j_seats": 40064,
            "f_seats": 13992,
            "r_seats": 5351,
            "cargo_demand": 489659
        }
    },
    {
        "iata": "OLN",
        "icao": "SAVM",
        "airportName": "Lago Musters Airport",
        "location_country": "Argentina",
        "location_city": "Chubut",
        "location_state": "Sarmiento",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-45.5752",
        "longitude": "-69.077",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3219",
                "runway_width": "98",
                "runway_material": "GRE"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 3900,
            "s_seats": 2160,
            "j_seats": 1863,
            "f_seats": 5,
            "r_seats": 146,
            "cargo_demand": 28005
        }
    },
    {
        "iata": "OLO",
        "icao": "LKOL",
        "airportName": "Olomouc Airport",
        "location_country": "Czech Republic",
        "location_city": "Olomouc",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "49.587799",
        "longitude": "17.2108",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "2493",
                "runway_width": "98",
                "runway_material": "GRASS"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "2493",
                "runway_width": "98",
                "runway_material": "GRASS"
            },
            {
                "runway_name": "10/28",
                "runway_length": "1378",
                "runway_width": "131",
                "runway_material": "ASPHALT"
            }
        ],
        "demand": {
            "y_seats": 2474,
            "s_seats": 2517,
            "j_seats": 1263,
            "f_seats": 150,
            "r_seats": 12,
            "cargo_demand": 76596
        }
    },
    {
        "iata": "OLP",
        "icao": "YOLD",
        "airportName": "Olympic Dam Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Olympic Dam",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-30.48500061",
        "longitude": "136.8769989",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5220",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3133,
            "s_seats": 2222,
            "j_seats": 1463,
            "f_seats": 464,
            "r_seats": 74,
            "cargo_demand": 47028
        }
    },
    {
        "iata": "OLS",
        "icao": "KOLS",
        "airportName": "Nogales International Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Nogales",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.4177",
        "longitude": "-110.848",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7199",
                "runway_width": "90",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57904,
            "s_seats": 36197,
            "j_seats": 35971,
            "f_seats": 19034,
            "r_seats": 7287,
            "cargo_demand": 86003
        }
    },
    {
        "iata": "OLU",
        "icao": "KOLU",
        "airportName": "Columbus Municipal Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Columbus",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.44800186",
        "longitude": "-97.34259796",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4135",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6800",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 138072,
            "s_seats": 29037,
            "j_seats": 10208,
            "f_seats": 15642,
            "r_seats": 5788,
            "cargo_demand": 276115
        }
    },
    {
        "iata": "OLV",
        "icao": "KOLV",
        "airportName": "Olive Branch Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Olive Branch",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.97869873",
        "longitude": "-89.78690338",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3655,
            "s_seats": 1725,
            "j_seats": 1486,
            "f_seats": 292,
            "r_seats": 159,
            "cargo_demand": 91082
        }
    },
    {
        "iata": "OLY",
        "icao": "KOLY",
        "airportName": "Olney-Noble Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Olney / Noble",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.72180176",
        "longitude": "-88.17639923",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3599",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4100",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2988,
            "s_seats": 2359,
            "j_seats": 1587,
            "f_seats": 80,
            "r_seats": 186,
            "cargo_demand": 88035
        }
    },
    {
        "iata": "OLZ",
        "icao": "UEMO",
        "airportName": "Olyokminsk Airport",
        "location_country": "Russia",
        "location_city": "Yakutia",
        "location_state": "Olyokminsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.397499",
        "longitude": "120.471001",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4265",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134046,
            "s_seats": 44533,
            "j_seats": 10816,
            "f_seats": 12362,
            "r_seats": 4243,
            "cargo_demand": 382585
        }
    },
    {
        "iata": "OMA",
        "icao": "KOMA",
        "airportName": "Eppley Airfield",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Omaha",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "41.3032",
        "longitude": "-95.894096",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "8500",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "9502",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8153",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 208365,
            "s_seats": 80914,
            "j_seats": 93366,
            "f_seats": 66930,
            "r_seats": 10000,
            "cargo_demand": 24041400
        }
    },
    {
        "iata": "OMB",
        "icao": "FOOH",
        "airportName": "Omboue Hospital Airport",
        "location_country": "Gabon",
        "location_city": "Omboue",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.57473",
        "longitude": "9.26269",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5285",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157258,
            "s_seats": 28691,
            "j_seats": 26331,
            "f_seats": 16848,
            "r_seats": 5173,
            "cargo_demand": 181635
        }
    },
    {
        "iata": "OMC",
        "icao": "RPVO",
        "airportName": "Ormoc Airport",
        "location_country": "Philippines",
        "location_city": "Ormoc",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.05799961",
        "longitude": "124.5650024",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6120",
                "runway_width": "118",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 182865,
            "s_seats": 46370,
            "j_seats": 23937,
            "f_seats": 6161,
            "r_seats": 3148,
            "cargo_demand": 178623
        }
    },
    {
        "iata": "OMD",
        "icao": "FYOG",
        "airportName": "Oranjemund Airport",
        "location_country": "Namibia",
        "location_city": "Oranjemund",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.5847",
        "longitude": "16.446699",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5252",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "3000",
                "runway_width": "47",
                "runway_material": "GVL"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3500",
                "runway_width": "60",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 68183,
            "s_seats": 38119,
            "j_seats": 23291,
            "f_seats": 4628,
            "r_seats": 4554,
            "cargo_demand": 79239
        }
    },
    {
        "iata": "OME",
        "icao": "PAOM",
        "airportName": "Nome Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Nome",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.5121994",
        "longitude": "-165.4450073",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5576",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "6001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88646,
            "s_seats": 29351,
            "j_seats": 38699,
            "f_seats": 16862,
            "r_seats": 3348,
            "cargo_demand": 186160
        }
    },
    {
        "iata": "OMF",
        "icao": "OJMF",
        "airportName": "King Hussein Air Base",
        "location_country": "Jordan",
        "location_city": "Mafraq",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.3564",
        "longitude": "36.259201",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9819",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2219,
            "s_seats": 1723,
            "j_seats": 1524,
            "f_seats": 364,
            "r_seats": 120,
            "cargo_demand": 50216
        }
    },
    {
        "iata": "OMH",
        "icao": "OITR",
        "airportName": "Urmia Airport",
        "location_country": "Iran",
        "location_city": "Urmia (Urumiyeh)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.66809845",
        "longitude": "45.06869888",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10658",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182636,
            "s_seats": 43897,
            "j_seats": 45495,
            "f_seats": 13842,
            "r_seats": 8630,
            "cargo_demand": 481857
        }
    },
    {
        "iata": "OMI",
        "icao": "OIAJ",
        "airportName": "Omidiyeh Air Base",
        "location_country": "Iran",
        "location_city": "Omidiyeh",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "30.83519936",
        "longitude": "49.53490067",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12L/30R",
                "runway_length": "11500",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "13500",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2275,
            "s_seats": 1794,
            "j_seats": 1455,
            "f_seats": 370,
            "r_seats": 136,
            "cargo_demand": 65170
        }
    },
    {
        "iata": "OMK",
        "icao": "KOMK",
        "airportName": "Omak Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Omak",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "48.46440125",
        "longitude": "-119.5179977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4667",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2654,
            "s_seats": 2796,
            "j_seats": 1677,
            "f_seats": 39,
            "r_seats": 47,
            "cargo_demand": 86215
        }
    },
    {
        "iata": "OMM",
        "icao": "OOMX",
        "airportName": "Marmul Airport",
        "location_country": "Oman",
        "location_city": "Marmul",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.13599968",
        "longitude": "55.18209839",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5782",
                "runway_width": "175",
                "runway_material": "GVL"
            },
            {
                "runway_name": "13/31",
                "runway_length": "8635",
                "runway_width": "190",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 3973,
            "s_seats": 2915,
            "j_seats": 1238,
            "f_seats": 442,
            "r_seats": 156,
            "cargo_demand": 10426
        }
    },
    {
        "iata": "OMO",
        "icao": "LQMO",
        "airportName": "Mostar Airport",
        "location_country": "Bosnia and Herzegovina",
        "location_city": "Mostar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.28290176",
        "longitude": "17.84589958",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7874",
                "runway_width": "158",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116464,
            "s_seats": 27282,
            "j_seats": 42265,
            "f_seats": 10968,
            "r_seats": 9484,
            "cargo_demand": 384840
        }
    },
    {
        "iata": "OMR",
        "icao": "LROD",
        "airportName": "Oradea International Airport",
        "location_country": "Romania",
        "location_city": "Oradea",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.02529907",
        "longitude": "21.90250015",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 52555,
            "s_seats": 38913,
            "j_seats": 15975,
            "f_seats": 16418,
            "r_seats": 8590,
            "cargo_demand": 51211
        }
    },
    {
        "iata": "OMS",
        "icao": "UNOO",
        "airportName": "Omsk Tsentralny Airport",
        "location_country": "Russia",
        "location_city": "Omsk Oblast",
        "location_state": "Omsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.96699905",
        "longitude": "73.3105011",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9435",
                "runway_width": "270",
                "runway_material": "GRS"
            },
            {
                "runway_name": "07/25",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2380",
                "runway_width": "250",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 71086,
            "s_seats": 44130,
            "j_seats": 27999,
            "f_seats": 18562,
            "r_seats": 4949,
            "cargo_demand": 218192
        }
    },
    {
        "iata": "ONA",
        "icao": "KONA",
        "airportName": "Winona Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Winona",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.07720184",
        "longitude": "-91.70829773",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5199",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2553",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2984,
            "s_seats": 2640,
            "j_seats": 1818,
            "f_seats": 49,
            "r_seats": 21,
            "cargo_demand": 7990
        }
    },
    {
        "iata": "OND",
        "icao": "FYOA",
        "airportName": "Ondangwa Airport",
        "location_country": "Namibia",
        "location_city": "Ondangwa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.878201",
        "longitude": "15.9526",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9800",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4423",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 171042,
            "s_seats": 29885,
            "j_seats": 7607,
            "f_seats": 19639,
            "r_seats": 5366,
            "cargo_demand": 413821
        }
    },
    {
        "iata": "ONG",
        "icao": "YMTI",
        "airportName": "Mornington Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Mornington Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.66250038",
        "longitude": "139.1779938",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4987",
                "runway_width": "98",
                "runway_material": "PER"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2480",
                "runway_width": "59",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 3371,
            "s_seats": 2398,
            "j_seats": 1364,
            "f_seats": 201,
            "r_seats": 53,
            "cargo_demand": 49957
        }
    },
    {
        "iata": "ONJ",
        "icao": "RJSR",
        "airportName": "Odate-Noshiro Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "o\u0152date / Noshiro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.191898",
        "longitude": "140.371002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186439,
            "s_seats": 37005,
            "j_seats": 53275,
            "f_seats": 15232,
            "r_seats": 9850,
            "cargo_demand": 272597
        }
    },
    {
        "iata": "ONK",
        "icao": "UERO",
        "airportName": "Olenyok Airport",
        "location_country": "Russia",
        "location_city": "Yakutia",
        "location_state": "Olenyok",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "68.514999",
        "longitude": "112.480003",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7874",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2601,
            "s_seats": 2865,
            "j_seats": 1826,
            "f_seats": 447,
            "r_seats": 105,
            "cargo_demand": 22048
        }
    },
    {
        "iata": "ONL",
        "icao": "KONL",
        "airportName": "O'Neill Municipal Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "O'Neill",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.469898",
        "longitude": "-98.688103",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3030",
                "runway_width": "120",
                "runway_material": "GRE"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4409",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3403,
            "s_seats": 2439,
            "j_seats": 1366,
            "f_seats": 149,
            "r_seats": 144,
            "cargo_demand": 86290
        }
    },
    {
        "iata": "ONM",
        "icao": "KONM",
        "airportName": "Socorro Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Socorro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.02249908",
        "longitude": "-106.9029999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4590",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5841",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2964,
            "s_seats": 1645,
            "j_seats": 1283,
            "f_seats": 318,
            "r_seats": 40,
            "cargo_demand": 6699
        }
    },
    {
        "iata": "ONO",
        "icao": "KONO",
        "airportName": "Ontario Municipal Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Ontario",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.02050018",
        "longitude": "-117.0139999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4307",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3117,
            "s_seats": 1998,
            "j_seats": 1591,
            "f_seats": 476,
            "r_seats": 182,
            "cargo_demand": 78208
        }
    },
    {
        "iata": "ONP",
        "icao": "KONP",
        "airportName": "Newport Municipal Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Newport",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.580399",
        "longitude": "-124.057999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5398",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95605,
            "s_seats": 36004,
            "j_seats": 47577,
            "f_seats": 10268,
            "r_seats": 6782,
            "cargo_demand": 483431
        }
    },
    {
        "iata": "ONQ",
        "icao": "LTAS",
        "airportName": "Zonguldak Airport",
        "location_country": "Turkey",
        "location_city": "Zonguldak",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.506401",
        "longitude": "32.0886",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6171",
                "runway_width": "98",
                "runway_material": "CONC"
            }
        ],
        "demand": {
            "y_seats": 1168,
            "s_seats": 1836,
            "j_seats": 1858,
            "f_seats": 197,
            "r_seats": 68,
            "cargo_demand": 45272
        }
    },
    {
        "iata": "ONR",
        "icao": "YMNK",
        "airportName": "Monkira Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Monkira",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.81669998",
        "longitude": "140.5330048",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "2690",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2245,
            "s_seats": 2642,
            "j_seats": 1101,
            "f_seats": 279,
            "r_seats": 134,
            "cargo_demand": 20831
        }
    },
    {
        "iata": "ONS",
        "icao": "YOLW",
        "airportName": "Onslow Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Onslow",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-21.66830063",
        "longitude": "115.112999",
        "region": "OC",
        "runway": [
            {
                "runway_name": "0/",
                "runway_length": "4494",
                "runway_width": "",
                "runway_material": "Sealed"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3257",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3530,
            "s_seats": 2371,
            "j_seats": 1826,
            "f_seats": 260,
            "r_seats": 127,
            "cargo_demand": 26316
        }
    },
    {
        "iata": "ONT",
        "icao": "KONT",
        "airportName": "Ontario International Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Ontario",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "34.05599976",
        "longitude": "-117.6009979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "12198",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "10200",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 270078,
            "s_seats": 84941,
            "j_seats": 43925,
            "f_seats": 24969,
            "r_seats": 10000,
            "cargo_demand": 23015023
        }
    },
    {
        "iata": "ONX",
        "icao": "MPEJ",
        "airportName": "Enrique Adolfo Jimenez Airport",
        "location_country": "Panama",
        "location_city": "Colon",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.356639862",
        "longitude": "-79.86740112",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 122682,
            "s_seats": 43097,
            "j_seats": 12210,
            "f_seats": 9126,
            "r_seats": 8595,
            "cargo_demand": 421025
        }
    },
    {
        "iata": "ONY",
        "icao": "KONY",
        "airportName": "Olney Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Olney",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.35089874",
        "longitude": "-98.81919861",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5098",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5096",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5101",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2721,
            "s_seats": 2829,
            "j_seats": 1738,
            "f_seats": 415,
            "r_seats": 75,
            "cargo_demand": 51140
        }
    },
    {
        "iata": "OOA",
        "icao": "KOOA",
        "airportName": "Oskaloosa Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Oskaloosa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.2262001",
        "longitude": "-92.49389648",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "1975",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4003",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3821,
            "s_seats": 2381,
            "j_seats": 1060,
            "f_seats": 483,
            "r_seats": 189,
            "cargo_demand": 98839
        }
    },
    {
        "iata": "OOK",
        "icao": "PAOO",
        "airportName": "Toksook Bay Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Toksook Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "60.54140091",
        "longitude": "-165.0870056",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3218",
                "runway_width": "60",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 2540,
            "s_seats": 2882,
            "j_seats": 1058,
            "f_seats": 273,
            "r_seats": 32,
            "cargo_demand": 55335
        }
    },
    {
        "iata": "OOL",
        "icao": "YBCG",
        "airportName": "Gold Coast Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Gold Coast",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.1644001",
        "longitude": "153.5050049",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6699",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "1909",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 98267,
            "s_seats": 28211,
            "j_seats": 6355,
            "f_seats": 17641,
            "r_seats": 5701,
            "cargo_demand": 497355
        }
    },
    {
        "iata": "OOM",
        "icao": "YCOM",
        "airportName": "Cooma-Snowy Mountains Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Cooma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-36.30059814",
        "longitude": "148.973999",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2041",
                "runway_width": "59",
                "runway_material": "GVL"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6955",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132152,
            "s_seats": 34563,
            "j_seats": 55447,
            "f_seats": 3324,
            "r_seats": 8087,
            "cargo_demand": 80994
        }
    },
    {
        "iata": "OOR",
        "icao": "YMOO",
        "airportName": "Mooraberree Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Mooraberree",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.25",
        "longitude": "140.9830017",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4724",
                "runway_width": "",
                "runway_material": "C"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2887",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1290,
            "s_seats": 2412,
            "j_seats": 1005,
            "f_seats": 47,
            "r_seats": 112,
            "cargo_demand": 5437
        }
    },
    {
        "iata": "OPF",
        "icao": "KOPF",
        "airportName": "Miami-Opa Locka Executive Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Miami",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.907",
        "longitude": "-80.278397",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "8002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "4306",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6800",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188449,
            "s_seats": 40599,
            "j_seats": 22016,
            "f_seats": 14466,
            "r_seats": 5790,
            "cargo_demand": 105603
        }
    },
    {
        "iata": "OPI",
        "icao": "YOEN",
        "airportName": "Oenpelli Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Gunbalanya (Oenpelli)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.32499981",
        "longitude": "133.0059967",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4331",
                "runway_width": "98",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 2348,
            "s_seats": 2462,
            "j_seats": 1344,
            "f_seats": 233,
            "r_seats": 94,
            "cargo_demand": 24235
        }
    },
    {
        "iata": "OPL",
        "icao": "KOPL",
        "airportName": "St. Landry Parish Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Opelousas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "30.558399",
        "longitude": "-92.099403",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4051",
                "runway_width": "100",
                "runway_material": "CONC-F"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4550",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5849",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2579,
            "s_seats": 1575,
            "j_seats": 1322,
            "f_seats": 45,
            "r_seats": 108,
            "cargo_demand": 72909
        }
    },
    {
        "iata": "OPO",
        "icao": "LPPR",
        "airportName": "Francisco de Sa Carneiro Airport",
        "location_country": "Portugal",
        "location_city": "Porto",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "41.24810028",
        "longitude": "-8.681389809",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "11417",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 221430,
            "s_seats": 85059,
            "j_seats": 21048,
            "f_seats": 46031,
            "r_seats": 10000,
            "cargo_demand": 26626131
        }
    },
    {
        "iata": "OPS",
        "icao": "SWSI",
        "airportName": "Presidente Joio Figueiredo Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Sinop",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.885001",
        "longitude": "-55.586109",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "5348",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1586,
            "s_seats": 2946,
            "j_seats": 1971,
            "f_seats": 167,
            "r_seats": 130,
            "cargo_demand": 24781
        }
    },
    {
        "iata": "ORA",
        "icao": "SASO",
        "airportName": "Oran Airport",
        "location_country": "Argentina",
        "location_city": "Salta",
        "location_state": "Oran",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.15279961",
        "longitude": "-64.32920074",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "4839",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76774,
            "s_seats": 35844,
            "j_seats": 39386,
            "f_seats": 14324,
            "r_seats": 8352,
            "cargo_demand": 352195
        }
    },
    {
        "iata": "ORB",
        "icao": "ESOE",
        "airportName": "orebro Airport",
        "location_country": "Sweden",
        "location_city": "orebro",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "59.22370148",
        "longitude": "15.03800011",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10728",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53195,
            "s_seats": 27209,
            "j_seats": 23064,
            "f_seats": 12334,
            "r_seats": 4861,
            "cargo_demand": 164642
        }
    },
    {
        "iata": "ORD",
        "icao": "KORD",
        "airportName": "O'Hare International Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Chicago",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "41.9786",
        "longitude": "-87.9048",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "7500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "8075",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09C/27C",
                "runway_length": "11245",
                "runway_width": "200",
                "runway_material": "concrete"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "7500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "7967",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10C/28C",
                "runway_length": "10801",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "13000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "10005",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "9686",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5332",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 441100,
            "s_seats": 75222,
            "j_seats": 160162,
            "f_seats": 25189,
            "r_seats": 10000,
            "cargo_demand": 10947558
        }
    },
    {
        "iata": "ORE",
        "icao": "LFOZ",
        "airportName": "Orleans - Saint-Denis-de-l'Hotel Airport",
        "location_country": "France",
        "location_city": "Centre-Val de Loire",
        "location_state": "Orleans",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "47.8969",
        "longitude": "2.16333",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5250",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3951,
            "s_seats": 2545,
            "j_seats": 1043,
            "f_seats": 1,
            "r_seats": 162,
            "cargo_demand": 61650
        }
    },
    {
        "iata": "ORF",
        "icao": "KORF",
        "airportName": "Norfolk International Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Norfolk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.89459991",
        "longitude": "-76.20120239",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4875",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179454,
            "s_seats": 94256,
            "j_seats": 25078,
            "f_seats": 51868,
            "r_seats": 10000,
            "cargo_demand": 18354746
        }
    },
    {
        "iata": "ORH",
        "icao": "KORH",
        "airportName": "Worcester Regional Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Worcester",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.26729965",
        "longitude": "-71.8757019",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137227,
            "s_seats": 31658,
            "j_seats": 15576,
            "f_seats": 7095,
            "r_seats": 4974,
            "cargo_demand": 177645
        }
    },
    {
        "iata": "ORK",
        "icao": "EICK",
        "airportName": "Cork Airport",
        "location_country": "Ireland",
        "location_city": "Cork",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "51.84130096",
        "longitude": "-8.491109848",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4298",
                "runway_width": "148",
                "runway_material": "PEM"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6998",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 307895,
            "s_seats": 88883,
            "j_seats": 68731,
            "f_seats": 36860,
            "r_seats": 10000,
            "cargo_demand": 27624087
        }
    },
    {
        "iata": "ORL",
        "icao": "KORL",
        "airportName": "Orlando Executive Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Orlando",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.5455",
        "longitude": "-81.332901",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6003",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4638",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1533,
            "s_seats": 1794,
            "j_seats": 1683,
            "f_seats": 450,
            "r_seats": 145,
            "cargo_demand": 85088
        }
    },
    {
        "iata": "ORM",
        "icao": "EGBK",
        "airportName": "Sywell Aerodrome",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Northampton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "52.30530167",
        "longitude": "-0.793056011",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "4160",
                "runway_width": "98",
                "runway_material": "Concrete"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "2201",
                "runway_width": "98",
                "runway_material": "Grass"
            },
            {
                "runway_name": "05/23",
                "runway_length": "1975",
                "runway_width": "98",
                "runway_material": "Grass"
            },
            {
                "runway_name": "15/33",
                "runway_length": "1476",
                "runway_width": "59",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 2381,
            "s_seats": 2866,
            "j_seats": 1323,
            "f_seats": 37,
            "r_seats": 59,
            "cargo_demand": 21431
        }
    },
    {
        "iata": "ORN",
        "icao": "DAOO",
        "airportName": "Oran Es Senia Airport",
        "location_country": "Algeria",
        "location_city": "Oran",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.623901",
        "longitude": "-0.621183",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10039",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149692,
            "s_seats": 42653,
            "j_seats": 37534,
            "f_seats": 8416,
            "r_seats": 4697,
            "cargo_demand": 84021
        }
    },
    {
        "iata": "ORR",
        "icao": "YYOR",
        "airportName": "Yorketown Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Yorketown",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-35",
        "longitude": "137.6170044",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3740",
                "runway_width": "295",
                "runway_material": "Gravel"
            }
        ],
        "demand": {
            "y_seats": 1423,
            "s_seats": 2487,
            "j_seats": 1819,
            "f_seats": 494,
            "r_seats": 114,
            "cargo_demand": 89900
        }
    },
    {
        "iata": "ORT",
        "icao": "PAOR",
        "airportName": "Northway Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Northway",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.9612999",
        "longitude": "-141.9290009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5100",
                "runway_width": "100",
                "runway_material": "GRVL-DIRT-G"
            }
        ],
        "demand": {
            "y_seats": 107193,
            "s_seats": 33283,
            "j_seats": 54528,
            "f_seats": 12057,
            "r_seats": 5412,
            "cargo_demand": 376267
        }
    },
    {
        "iata": "ORU",
        "icao": "SLOR",
        "airportName": "Juan Mendoza Airport",
        "location_country": "Bolivia",
        "location_city": "Oruro",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.96260071",
        "longitude": "-67.07620239",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6125",
                "runway_width": "120",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8075",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188627,
            "s_seats": 27290,
            "j_seats": 43168,
            "f_seats": 4595,
            "r_seats": 3729,
            "cargo_demand": 261225
        }
    },
    {
        "iata": "ORV",
        "icao": "PFNO",
        "airportName": "Robert (Bob) Curtis Memorial Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Noorvik",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "66.81790161",
        "longitude": "-161.0189972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GRVL-E"
            }
        ],
        "demand": {
            "y_seats": 3949,
            "s_seats": 1909,
            "j_seats": 1431,
            "f_seats": 237,
            "r_seats": 107,
            "cargo_demand": 12125
        }
    },
    {
        "iata": "ORW",
        "icao": "OPOR",
        "airportName": "Ormara Airport",
        "location_country": "Pakistan",
        "location_city": "Ormara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "25.27470016",
        "longitude": "64.58599854",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5000",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1823,
            "s_seats": 1584,
            "j_seats": 1884,
            "f_seats": 21,
            "r_seats": 142,
            "cargo_demand": 36171
        }
    },
    {
        "iata": "ORX",
        "icao": "SNOX",
        "airportName": "Oriximina Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Oriximina",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.714079976",
        "longitude": "-55.83620071",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5249",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1708,
            "s_seats": 1767,
            "j_seats": 1074,
            "f_seats": 498,
            "r_seats": 88,
            "cargo_demand": 76970
        }
    },
    {
        "iata": "ORY",
        "icao": "LFPO",
        "airportName": "Orly Airport",
        "location_country": "France",
        "location_city": "Ile-de-France",
        "location_state": "Paris",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "48.7233333",
        "longitude": "2.3794444",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7874",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "06/24",
                "runway_length": "11975",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "10892",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 302386,
            "s_seats": 96058,
            "j_seats": 86396,
            "f_seats": 38922,
            "r_seats": 10000,
            "cargo_demand": 9851664
        }
    },
    {
        "iata": "OSC",
        "icao": "KOSC",
        "airportName": "Oscoda-Wurtsmith Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Oscoda",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.451599",
        "longitude": "-83.394096",
        "region": "NA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "11800",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2981,
            "s_seats": 2207,
            "j_seats": 1732,
            "f_seats": 394,
            "r_seats": 160,
            "cargo_demand": 96224
        }
    },
    {
        "iata": "OSD",
        "icao": "ESNZ",
        "airportName": "\u00c5re ostersund Airport",
        "location_country": "Sweden",
        "location_city": "ostersund",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "63.19440079",
        "longitude": "14.50030041",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "4034",
                "runway_width": "54",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71549,
            "s_seats": 43472,
            "j_seats": 9333,
            "f_seats": 1554,
            "r_seats": 3328,
            "cargo_demand": 114807
        }
    },
    {
        "iata": "OSF",
        "icao": "UUMO",
        "airportName": "Ostafyevo International Airport",
        "location_country": "Russia",
        "location_city": "Moscow Oblast",
        "location_state": "Moscow / Podolsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.511667",
        "longitude": "37.507222",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6729",
                "runway_width": "157",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 60205,
            "s_seats": 30467,
            "j_seats": 34848,
            "f_seats": 3809,
            "r_seats": 9142,
            "cargo_demand": 243805
        }
    },
    {
        "iata": "OSH",
        "icao": "KOSH",
        "airportName": "Wittman Regional Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Oshkosh",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.9844017",
        "longitude": "-88.55699921",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3424",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "6178",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3060",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8002",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 149909,
            "s_seats": 35926,
            "j_seats": 10019,
            "f_seats": 2230,
            "r_seats": 4764,
            "cargo_demand": 334306
        }
    },
    {
        "iata": "OSI",
        "icao": "LDOS",
        "airportName": "Osijek Airport",
        "location_country": "Croatia",
        "location_city": "Osijek",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.4627",
        "longitude": "18.8102",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8199",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121869,
            "s_seats": 40982,
            "j_seats": 25834,
            "f_seats": 7602,
            "r_seats": 3503,
            "cargo_demand": 114764
        }
    },
    {
        "iata": "OSK",
        "icao": "ESMO",
        "airportName": "Oskarshamn Airport",
        "location_country": "Sweden",
        "location_city": "Oskarshamn",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "57.350498",
        "longitude": "16.497999",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3537,
            "s_seats": 2007,
            "j_seats": 1779,
            "f_seats": 411,
            "r_seats": 11,
            "cargo_demand": 7841
        }
    },
    {
        "iata": "OSL",
        "icao": "ENGM",
        "airportName": "Oslo Airport",
        "location_country": "Norway",
        "location_city": "Oslo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "60.19390106",
        "longitude": "11.10039997",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "9678",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115437,
            "s_seats": 84246,
            "j_seats": 84592,
            "f_seats": 48323,
            "r_seats": 10000,
            "cargo_demand": 28298500
        }
    },
    {
        "iata": "OSM",
        "icao": "ORBM",
        "airportName": "Mosul International Airport",
        "location_country": "Iraq",
        "location_city": "Mosul",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.305801",
        "longitude": "43.1474",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "8695",
                "runway_width": "147",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 89711,
            "s_seats": 30509,
            "j_seats": 13917,
            "f_seats": 2181,
            "r_seats": 6301,
            "cargo_demand": 138636
        }
    },
    {
        "iata": "OSN",
        "icao": "RKSO",
        "airportName": "Osan Air Base",
        "location_country": "South Korea",
        "location_city": "Osan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.090599",
        "longitude": "127.029999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9004",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 442982,
            "s_seats": 88984,
            "j_seats": 84753,
            "f_seats": 57715,
            "r_seats": 10000,
            "cargo_demand": 9213550
        }
    },
    {
        "iata": "OSO",
        "icao": "YOSB",
        "airportName": "Osborne Mine Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Osborne Mine",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.08169937",
        "longitude": "140.5549927",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6550",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2908,
            "s_seats": 2247,
            "j_seats": 1710,
            "f_seats": 484,
            "r_seats": 61,
            "cargo_demand": 55823
        }
    },
    {
        "iata": "OSR",
        "icao": "LKMT",
        "airportName": "Leoo\u00a1 Janacek Airport Ostrava",
        "location_country": "Czech Republic",
        "location_city": "Ostrava",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.69630051",
        "longitude": "18.11109924",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "11484",
                "runway_width": "207",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 144667,
            "s_seats": 27601,
            "j_seats": 30019,
            "f_seats": 5218,
            "r_seats": 8172,
            "cargo_demand": 437352
        }
    },
    {
        "iata": "OSS",
        "icao": "UAFO",
        "airportName": "Osh Airport",
        "location_country": "Kyrgyzstan",
        "location_city": "Osh",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.60900116",
        "longitude": "72.79329681",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "10538",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136512,
            "s_seats": 49689,
            "j_seats": 48151,
            "f_seats": 7351,
            "r_seats": 6276,
            "cargo_demand": 178980
        }
    },
    {
        "iata": "OST",
        "icao": "EBOS",
        "airportName": "Ostend-Bruges International Airport",
        "location_country": "Belgium",
        "location_city": "Ostend / Bruges",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.19889832",
        "longitude": "2.862220049",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2057",
                "runway_width": "148",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 156243,
            "s_seats": 39313,
            "j_seats": 13831,
            "f_seats": 9544,
            "r_seats": 6423,
            "cargo_demand": 354865
        }
    },
    {
        "iata": "OSU",
        "icao": "KOSU",
        "airportName": "Ohio State University Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Columbus",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.0798",
        "longitude": "-83.072998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3555",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "2994",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "5004",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3438",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179246,
            "s_seats": 47456,
            "j_seats": 39411,
            "f_seats": 8243,
            "r_seats": 3387,
            "cargo_demand": 375670
        }
    },
    {
        "iata": "OSW",
        "icao": "UWOR",
        "airportName": "Orsk Airport",
        "location_country": "Russia",
        "location_city": "Orenburg Oblast",
        "location_state": "Orsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.07249832",
        "longitude": "58.59560013",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "1980",
                "runway_width": "160",
                "runway_material": "GRS"
            },
            {
                "runway_name": "07/25",
                "runway_length": "9550",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "5840",
                "runway_width": "300",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2030",
                "runway_width": "170",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 90839,
            "s_seats": 47283,
            "j_seats": 17026,
            "f_seats": 17235,
            "r_seats": 6461,
            "cargo_demand": 499288
        }
    },
    {
        "iata": "OSX",
        "icao": "KOSX",
        "airportName": "Kosciusko-Attala County Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Kosciusko",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.09030151",
        "longitude": "-89.54199982",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1293,
            "s_seats": 2536,
            "j_seats": 1221,
            "f_seats": 112,
            "r_seats": 66,
            "cargo_demand": 49106
        }
    },
    {
        "iata": "OSY",
        "icao": "ENNM",
        "airportName": "Namsos Airport",
        "location_country": "Norway",
        "location_city": "Namsos",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "64.47219849",
        "longitude": "11.57859993",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2749",
                "runway_width": "98",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3625,
            "s_seats": 1816,
            "j_seats": 1507,
            "f_seats": 215,
            "r_seats": 37,
            "cargo_demand": 38913
        }
    },
    {
        "iata": "OTG",
        "icao": "KOTG",
        "airportName": "Worthington Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Worthington",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.65510178",
        "longitude": "-95.57920074",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5506",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4201",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1542,
            "s_seats": 2279,
            "j_seats": 1140,
            "f_seats": 292,
            "r_seats": 108,
            "cargo_demand": 79900
        }
    },
    {
        "iata": "OTH",
        "icao": "KOTH",
        "airportName": "Southwest Oregon Regional Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "North Bend",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.417099",
        "longitude": "-124.2460022",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5980",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4470",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123109,
            "s_seats": 38842,
            "j_seats": 34118,
            "f_seats": 11892,
            "r_seats": 8275,
            "cargo_demand": 448165
        }
    },
    {
        "iata": "OTI",
        "icao": "WAMR",
        "airportName": "Pitu Airport",
        "location_country": "Indonesia",
        "location_city": "Morotai Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.04598999",
        "longitude": "128.3249969",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7880",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154867,
            "s_seats": 40713,
            "j_seats": 31303,
            "f_seats": 8233,
            "r_seats": 8648,
            "cargo_demand": 297658
        }
    },
    {
        "iata": "OTK",
        "icao": "KTMK",
        "airportName": "Tillamook Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Tillamook",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "45.41820145",
        "longitude": "-123.814003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2910",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 1268,
            "s_seats": 2202,
            "j_seats": 1230,
            "f_seats": 210,
            "r_seats": 157,
            "cargo_demand": 89901
        }
    },
    {
        "iata": "OTM",
        "icao": "KOTM",
        "airportName": "Ottumwa Regional Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Ottumwa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.10660172",
        "longitude": "-92.44789886",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5178",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5885",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 84978,
            "s_seats": 43367,
            "j_seats": 51398,
            "f_seats": 10140,
            "r_seats": 6791,
            "cargo_demand": 385214
        }
    },
    {
        "iata": "OTP",
        "icao": "LROP",
        "airportName": "Henri Coand\u0103 International Airport",
        "location_country": "Romania",
        "location_city": "Bucharest",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "44.5711111",
        "longitude": "26.085",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "11484",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "11484",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 432573,
            "s_seats": 86265,
            "j_seats": 56042,
            "f_seats": 47476,
            "r_seats": 10000,
            "cargo_demand": 3619404
        }
    },
    {
        "iata": "OTR",
        "icao": "MRCC",
        "airportName": "Coto 47 Airport",
        "location_country": "Costa Rica",
        "location_city": "Coto 47",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.601559639",
        "longitude": "-82.96859741",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3281",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69342,
            "s_seats": 42764,
            "j_seats": 55566,
            "f_seats": 11376,
            "r_seats": 5934,
            "cargo_demand": 307241
        }
    },
    {
        "iata": "OTZ",
        "icao": "PAOT",
        "airportName": "Ralph Wien Memorial Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kotzebue",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "66.88469696",
        "longitude": "-162.598999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5900",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3800",
                "runway_width": "90",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 148744,
            "s_seats": 26056,
            "j_seats": 40116,
            "f_seats": 1679,
            "r_seats": 7497,
            "cargo_demand": 397388
        }
    },
    {
        "iata": "OUA",
        "icao": "DFFD",
        "airportName": "Ouagadougou Airport",
        "location_country": "Burkina Faso",
        "location_city": "Ouagadougou",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "12.3532",
        "longitude": "-1.51242",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "9934",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "6269",
                "runway_width": "98",
                "runway_material": "LAT"
            }
        ],
        "demand": {
            "y_seats": 120675,
            "s_seats": 71316,
            "j_seats": 145604,
            "f_seats": 47728,
            "r_seats": 10000,
            "cargo_demand": 3793505
        }
    },
    {
        "iata": "OUD",
        "icao": "GMFO",
        "airportName": "Angads Airport",
        "location_country": "Morocco",
        "location_city": "Oujda",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.78720093",
        "longitude": "-1.923990011",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "BIT"
            },
            {
                "runway_name": "13/31",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72474,
            "s_seats": 32834,
            "j_seats": 22176,
            "f_seats": 7311,
            "r_seats": 9887,
            "cargo_demand": 220404
        }
    },
    {
        "iata": "OUE",
        "icao": "FCOU",
        "airportName": "Ouesso Airport",
        "location_country": "Republic of the Congo",
        "location_city": "Ouesso",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.615990043",
        "longitude": "16.03790092",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6726",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114100,
            "s_seats": 49333,
            "j_seats": 48510,
            "f_seats": 4246,
            "r_seats": 8335,
            "cargo_demand": 362393
        }
    },
    {
        "iata": "OUH",
        "icao": "FAOH",
        "airportName": "Oudtshoorn Airport",
        "location_country": "South Africa",
        "location_city": "Oudtshoorn",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.60699844",
        "longitude": "22.18899918",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5577",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55769,
            "s_seats": 37955,
            "j_seats": 35704,
            "f_seats": 15997,
            "r_seats": 3951,
            "cargo_demand": 352565
        }
    },
    {
        "iata": "OUL",
        "icao": "EFOU",
        "airportName": "Oulu Airport",
        "location_country": "Finland",
        "location_city": "Oulu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.930099",
        "longitude": "25.354601",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8205",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64594,
            "s_seats": 46723,
            "j_seats": 11341,
            "f_seats": 1698,
            "r_seats": 3995,
            "cargo_demand": 158299
        }
    },
    {
        "iata": "OUN",
        "icao": "KOUN",
        "airportName": "University of Oklahoma Westheimer Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Norman",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.2456",
        "longitude": "-97.472099",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4747",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5200",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1312,
            "s_seats": 2156,
            "j_seats": 1013,
            "f_seats": 245,
            "r_seats": 71,
            "cargo_demand": 72675
        }
    },
    {
        "iata": "OUR",
        "icao": "FKKI",
        "airportName": "Batouri Airport",
        "location_country": "Cameroon",
        "location_city": "Batouri",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "4.474999905",
        "longitude": "14.36250019",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6234",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3290,
            "s_seats": 1588,
            "j_seats": 1938,
            "f_seats": 134,
            "r_seats": 9,
            "cargo_demand": 67506
        }
    },
    {
        "iata": "OUS",
        "icao": "SDOU",
        "airportName": "Jornalista Benedito Pimentel-Ourinhos State Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Ourinhos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.96649933",
        "longitude": "-49.91329956",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3643,
            "s_seats": 2893,
            "j_seats": 1839,
            "f_seats": 383,
            "r_seats": 174,
            "cargo_demand": 82020
        }
    },
    {
        "iata": "OUZ",
        "icao": "GQPZ",
        "airportName": "Tazadit Airport",
        "location_country": "Mauritania",
        "location_city": "Zouerate",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "22.756399",
        "longitude": "-12.4836",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8202",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3783,
            "s_seats": 2901,
            "j_seats": 1514,
            "f_seats": 9,
            "r_seats": 22,
            "cargo_demand": 25276
        }
    },
    {
        "iata": "OVB",
        "icao": "UNNT",
        "airportName": "Tolmachevo Airport",
        "location_country": "Russia",
        "location_city": "Novosibirsk Oblast",
        "location_state": "Novosibirsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "55.01259995",
        "longitude": "82.65070343",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "11801",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "11818",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 266651,
            "s_seats": 90808,
            "j_seats": 148127,
            "f_seats": 37370,
            "r_seats": 10000,
            "cargo_demand": 27481416
        }
    },
    {
        "iata": "OVD",
        "icao": "LEAS",
        "airportName": "Asturias Airport",
        "location_country": "Spain",
        "location_city": "Asturias",
        "location_state": "Oviedo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.56359863",
        "longitude": "-6.034619808",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103225,
            "s_seats": 40681,
            "j_seats": 28696,
            "f_seats": 2916,
            "r_seats": 9635,
            "cargo_demand": 164860
        }
    },
    {
        "iata": "OVE",
        "icao": "KOVE",
        "airportName": "Oroville Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Oroville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "39.4878006",
        "longitude": "-121.6220016",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6020",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3540",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3199,
            "s_seats": 2207,
            "j_seats": 1473,
            "f_seats": 188,
            "r_seats": 136,
            "cargo_demand": 13510
        }
    },
    {
        "iata": "OVG",
        "icao": "FAOB",
        "airportName": "Air Force Base Overberg",
        "location_country": "South Africa",
        "location_city": "Bredasdorp",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-34.55490112",
        "longitude": "20.2507",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6926",
                "runway_width": "131",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "10220",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3312,
            "s_seats": 1800,
            "j_seats": 1865,
            "f_seats": 204,
            "r_seats": 194,
            "cargo_demand": 87224
        }
    },
    {
        "iata": "OVL",
        "icao": "SCOV",
        "airportName": "El Tuqui Airport",
        "location_country": "Chile",
        "location_city": "Ovalle",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-30.55920029",
        "longitude": "-71.17559814",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "2618",
                "runway_width": "75",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2324,
            "s_seats": 1960,
            "j_seats": 1560,
            "f_seats": 463,
            "r_seats": 44,
            "cargo_demand": 33854
        }
    },
    {
        "iata": "OVR",
        "icao": "SAZF",
        "airportName": "Olavarria Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "Olavarria",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-36.88999939",
        "longitude": "-60.21659851",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3478",
                "runway_width": "148",
                "runway_material": "GRE"
            },
            {
                "runway_name": "4/22",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1114,
            "s_seats": 2163,
            "j_seats": 1837,
            "f_seats": 337,
            "r_seats": 183,
            "cargo_demand": 67926
        }
    },
    {
        "iata": "OWA",
        "icao": "KOWA",
        "airportName": "Owatonna Degner Regional Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Owatonna",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.12340164",
        "longitude": "-93.26059723",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2510,
            "s_seats": 2708,
            "j_seats": 1381,
            "f_seats": 429,
            "r_seats": 176,
            "cargo_demand": 85238
        }
    },
    {
        "iata": "OWB",
        "icao": "KOWB",
        "airportName": "Owensboro-Daviess County Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Owensboro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.74010086",
        "longitude": "-87.16680145",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6494",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185990,
            "s_seats": 30503,
            "j_seats": 37471,
            "f_seats": 9790,
            "r_seats": 4382,
            "cargo_demand": 266927
        }
    },
    {
        "iata": "OWD",
        "icao": "KOWD",
        "airportName": "Norwood Memorial Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Norwood",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.19049835",
        "longitude": "-71.17289734",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3995",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4008",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 133815,
            "s_seats": 33850,
            "j_seats": 23228,
            "f_seats": 15148,
            "r_seats": 7487,
            "cargo_demand": 396300
        }
    },
    {
        "iata": "OWK",
        "icao": "KOWK",
        "airportName": "Central Maine Airport of Norridgewock",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Norridgewock",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.71549988",
        "longitude": "-69.86650085",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3999",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3999",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1236,
            "s_seats": 2626,
            "j_seats": 1140,
            "f_seats": 16,
            "r_seats": 147,
            "cargo_demand": 96630
        }
    },
    {
        "iata": "OXB",
        "icao": "GGOV",
        "airportName": "Osvaldo Vieira International Airport",
        "location_country": "Guinea-Bissau",
        "location_city": "Bissau",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.89480019",
        "longitude": "-15.65369987",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 60437,
            "s_seats": 49488,
            "j_seats": 17968,
            "f_seats": 15168,
            "r_seats": 8460,
            "cargo_demand": 363683
        }
    },
    {
        "iata": "OXC",
        "icao": "KOXC",
        "airportName": "Waterbury-Oxford Airport",
        "location_country": "United States",
        "location_city": "Connecticut",
        "location_state": "Oxford",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.47859955",
        "longitude": "-73.1352005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5800",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1624,
            "s_seats": 1676,
            "j_seats": 1325,
            "f_seats": 232,
            "r_seats": 170,
            "cargo_demand": 70850
        }
    },
    {
        "iata": "OXD",
        "icao": "KOXD",
        "airportName": "Miami University Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Oxford",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "39.50230026",
        "longitude": "-84.78440094",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4011",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2208,
            "s_seats": 2625,
            "j_seats": 1232,
            "f_seats": 339,
            "r_seats": 136,
            "cargo_demand": 70837
        }
    },
    {
        "iata": "OXF",
        "icao": "EGTK",
        "airportName": "Oxford Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Oxford",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.8368988",
        "longitude": "-1.320000052",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4327",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03/21",
                "runway_length": "2887",
                "runway_width": "154",
                "runway_material": "GRS"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2887",
                "runway_width": "148",
                "runway_material": "GRS"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2493",
                "runway_width": "92",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 84790,
            "s_seats": 48856,
            "j_seats": 14323,
            "f_seats": 3754,
            "r_seats": 5185,
            "cargo_demand": 421433
        }
    },
    {
        "iata": "OXR",
        "icao": "KOXR",
        "airportName": "Oxnard Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Oxnard",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.20080185",
        "longitude": "-119.2070007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5953",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50092,
            "s_seats": 35887,
            "j_seats": 41256,
            "f_seats": 19164,
            "r_seats": 4906,
            "cargo_demand": 392560
        }
    },
    {
        "iata": "OXY",
        "icao": "YMNY",
        "airportName": "Morney Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Morney",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.35829926",
        "longitude": "141.4329987",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3608",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1610,
            "s_seats": 1920,
            "j_seats": 1450,
            "f_seats": 175,
            "r_seats": 72,
            "cargo_demand": 26184
        }
    },
    {
        "iata": "OYA",
        "icao": "SATG",
        "airportName": "Goya Airport",
        "location_country": "Argentina",
        "location_city": "Corrientes",
        "location_state": "Goya",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.1058",
        "longitude": "-59.2189",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "7415",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62274,
            "s_seats": 48286,
            "j_seats": 25195,
            "f_seats": 11977,
            "r_seats": 8327,
            "cargo_demand": 388143
        }
    },
    {
        "iata": "OYE",
        "icao": "FOGO",
        "airportName": "Oyem Airport",
        "location_country": "Gabon",
        "location_city": "Oyem",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.543110013",
        "longitude": "11.58139992",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125011,
            "s_seats": 31389,
            "j_seats": 44345,
            "f_seats": 10024,
            "r_seats": 8513,
            "cargo_demand": 465191
        }
    },
    {
        "iata": "OYK",
        "icao": "SBOI",
        "airportName": "Oiapoque Airport",
        "location_country": "Brazil",
        "location_city": "Amapa",
        "location_state": "Oiapoque",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.85412",
        "longitude": "-51.797056",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56548,
            "s_seats": 28840,
            "j_seats": 56573,
            "f_seats": 19862,
            "r_seats": 5552,
            "cargo_demand": 370630
        }
    },
    {
        "iata": "OYL",
        "icao": "HKMY",
        "airportName": "Moyale Airport",
        "location_country": "Kenya",
        "location_city": "Moyale",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "3.46972",
        "longitude": "39.101398",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4265",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3856,
            "s_seats": 1713,
            "j_seats": 1439,
            "f_seats": 437,
            "r_seats": 90,
            "cargo_demand": 16131
        }
    },
    {
        "iata": "OYN",
        "icao": "YOUY",
        "airportName": "Ouyen Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Ouyen",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-35.08901243",
        "longitude": "142.3544884",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2679,
            "s_seats": 2138,
            "j_seats": 1081,
            "f_seats": 370,
            "r_seats": 116,
            "cargo_demand": 92770
        }
    },
    {
        "iata": "OYO",
        "icao": "SAZH",
        "airportName": "Tres Arroyos Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "Tres Arroyos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.3869",
        "longitude": "-60.3297",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2953",
                "runway_width": "75",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3934",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "4/22",
                "runway_length": "2461",
                "runway_width": "75",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 103482,
            "s_seats": 29856,
            "j_seats": 52351,
            "f_seats": 12220,
            "r_seats": 7489,
            "cargo_demand": 47343
        }
    },
    {
        "iata": "OYP",
        "icao": "SOOG",
        "airportName": "Saint-Georges-de-l'Oyapock Airport",
        "location_country": "French Guiana",
        "location_city": "Saint-Georges-de-l'Oyapock",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.897599936",
        "longitude": "-51.80410004",
        "region": "SA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3937",
                "runway_width": "49",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 170420,
            "s_seats": 45809,
            "j_seats": 38802,
            "f_seats": 14293,
            "r_seats": 3023,
            "cargo_demand": 288104
        }
    },
    {
        "iata": "OZA",
        "icao": "KOZA",
        "airportName": "Ozona Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Ozona",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "30.73530006",
        "longitude": "-101.2030029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3963,
            "s_seats": 2573,
            "j_seats": 1841,
            "f_seats": 43,
            "r_seats": 0,
            "cargo_demand": 65221
        }
    },
    {
        "iata": "OZC",
        "icao": "RPMO",
        "airportName": "Labo Airport",
        "location_country": "Philippines",
        "location_city": "Ozamiz",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.178509712",
        "longitude": "123.8420029",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5720",
                "runway_width": "95",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 77681,
            "s_seats": 28842,
            "j_seats": 35004,
            "f_seats": 4077,
            "r_seats": 5452,
            "cargo_demand": 488678
        }
    },
    {
        "iata": "OZG",
        "icao": "GMAZ",
        "airportName": "Zagora Airport",
        "location_country": "Morocco",
        "location_city": "Zagora",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "30.32029915",
        "longitude": "-5.866670132",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9843",
                "runway_width": "98",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 1469,
            "s_seats": 2992,
            "j_seats": 1011,
            "f_seats": 419,
            "r_seats": 114,
            "cargo_demand": 12995
        }
    },
    {
        "iata": "OZH",
        "icao": "UKDE",
        "airportName": "Zaporizhzhia International Airport",
        "location_country": "Ukraine",
        "location_city": "Zaporizhzhia",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.86700058",
        "longitude": "35.31570053",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8210",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 138444,
            "s_seats": 26141,
            "j_seats": 8817,
            "f_seats": 18862,
            "r_seats": 7380,
            "cargo_demand": 143390
        }
    },
    {
        "iata": "OZP",
        "icao": "LEMO",
        "airportName": "Moron Air Base",
        "location_country": "Spain",
        "location_city": "Andalusia",
        "location_state": "Moron de la Frontera",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.17490005",
        "longitude": "-5.615940094",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "11801",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194733,
            "s_seats": 29671,
            "j_seats": 7554,
            "f_seats": 2800,
            "r_seats": 4824,
            "cargo_demand": 411712
        }
    },
    {
        "iata": "OZR",
        "icao": "KOZR",
        "airportName": "Cairns Army Airfield",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Fort Rucker / Ozark",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.27569962",
        "longitude": "-85.71340179",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131863,
            "s_seats": 37123,
            "j_seats": 42938,
            "f_seats": 12640,
            "r_seats": 7092,
            "cargo_demand": 113697
        }
    },
    {
        "iata": "OZZ",
        "icao": "GMMZ",
        "airportName": "Ouarzazate Airport",
        "location_country": "Morocco",
        "location_city": "Ouarzazate",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.93910027",
        "longitude": "-6.909430027",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170244,
            "s_seats": 28273,
            "j_seats": 56841,
            "f_seats": 5753,
            "r_seats": 3516,
            "cargo_demand": 136845
        }
    },
    {
        "iata": "PAA",
        "icao": "VYPA",
        "airportName": "Hpa-An Airport",
        "location_country": "Myanmar",
        "location_city": "Hpa-An",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "16.89369965",
        "longitude": "97.67459869",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4502",
                "runway_width": "102",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 1662,
            "s_seats": 2849,
            "j_seats": 1012,
            "f_seats": 404,
            "r_seats": 99,
            "cargo_demand": 71728
        }
    },
    {
        "iata": "PAC",
        "icao": "MPMG",
        "airportName": "\"Albrook \"\"Marcos A. Gelabert\"\" International Airport\"",
        "location_country": "Panama",
        "location_city": "Panama City",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.973340034",
        "longitude": "-79.55560303",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123344,
            "s_seats": 39222,
            "j_seats": 11049,
            "f_seats": 15093,
            "r_seats": 8707,
            "cargo_demand": 278750
        }
    },
    {
        "iata": "PAD",
        "icao": "EDLP",
        "airportName": "Paderborn Lippstadt Airport",
        "location_country": "Germany",
        "location_city": "North Rhine-Westphalia",
        "location_state": "Paderborn / Lippstadt",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.61410141",
        "longitude": "8.616319656",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7152",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100142,
            "s_seats": 35593,
            "j_seats": 22701,
            "f_seats": 4796,
            "r_seats": 6520,
            "cargo_demand": 393538
        }
    },
    {
        "iata": "PAE",
        "icao": "KPAE",
        "airportName": "Paine Field",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Everett",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.9063",
        "longitude": "-122.281998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4514",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "9010",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111593,
            "s_seats": 42773,
            "j_seats": 43325,
            "f_seats": 17941,
            "r_seats": 5133,
            "cargo_demand": 199772
        }
    },
    {
        "iata": "PAF",
        "icao": "HUPA",
        "airportName": "Pakuba Airfield",
        "location_country": "Uganda",
        "location_city": "Pakuba",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "2.202788",
        "longitude": "31.554341",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5055",
                "runway_width": "",
                "runway_material": "Gravel"
            }
        ],
        "demand": {
            "y_seats": 3507,
            "s_seats": 2780,
            "j_seats": 1823,
            "f_seats": 135,
            "r_seats": 53,
            "cargo_demand": 84422
        }
    },
    {
        "iata": "PAG",
        "icao": "RPMP",
        "airportName": "Pagadian Airport",
        "location_country": "Philippines",
        "location_city": "Pagadian",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.830731448",
        "longitude": "123.4611797",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6574",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 75335,
            "s_seats": 33749,
            "j_seats": 21009,
            "f_seats": 17503,
            "r_seats": 9423,
            "cargo_demand": 113431
        }
    },
    {
        "iata": "PAH",
        "icao": "KPAH",
        "airportName": "Barkley Regional Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Paducah",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.06079865",
        "longitude": "-88.77380371",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6499",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160273,
            "s_seats": 27018,
            "j_seats": 31004,
            "f_seats": 19869,
            "r_seats": 4212,
            "cargo_demand": 390420
        }
    },
    {
        "iata": "PAJ",
        "icao": "OPPC",
        "airportName": "Parachinar Airport",
        "location_country": "Pakistan",
        "location_city": "Parachinar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.90209961",
        "longitude": "70.07160187",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3999",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3450,
            "s_seats": 2971,
            "j_seats": 1005,
            "f_seats": 97,
            "r_seats": 150,
            "cargo_demand": 20972
        }
    },
    {
        "iata": "PAK",
        "icao": "PHPA",
        "airportName": "Port Allen Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Hanapepe",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.89690018",
        "longitude": "-159.6029968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2450",
                "runway_width": "60",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 2440,
            "s_seats": 1534,
            "j_seats": 1439,
            "f_seats": 282,
            "r_seats": 148,
            "cargo_demand": 91019
        }
    },
    {
        "iata": "PAL",
        "icao": "SKPQ",
        "airportName": "Captain German Olano Moreno Air Base",
        "location_country": "Colombia",
        "location_city": "Palanquero",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.48361",
        "longitude": "-74.6574",
        "region": "SA",
        "runway": [
            {
                "runway_name": "19/01",
                "runway_length": "9987",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190869,
            "s_seats": 39840,
            "j_seats": 29790,
            "f_seats": 7172,
            "r_seats": 4673,
            "cargo_demand": 374644
        }
    },
    {
        "iata": "PAM",
        "icao": "KPAM",
        "airportName": "Tyndall Air Force Base",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Panama City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "30.06959915",
        "longitude": "-85.57540131",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "10004",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "9135",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 403008,
            "s_seats": 79492,
            "j_seats": 48459,
            "f_seats": 46446,
            "r_seats": 10000,
            "cargo_demand": 19167169
        }
    },
    {
        "iata": "PAN",
        "icao": "VTSK",
        "airportName": "Pattani Airport",
        "location_country": "Thailand",
        "location_city": "Pattani",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.785459995",
        "longitude": "101.1539993",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4593",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53722,
            "s_seats": 32530,
            "j_seats": 57251,
            "f_seats": 12428,
            "r_seats": 4437,
            "cargo_demand": 87338
        }
    },
    {
        "iata": "PAO",
        "icao": "KPAO",
        "airportName": "Palo Alto Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Palo Alto",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.46110153",
        "longitude": "-122.1149979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2443",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1714,
            "s_seats": 1527,
            "j_seats": 1908,
            "f_seats": 349,
            "r_seats": 116,
            "cargo_demand": 93161
        }
    },
    {
        "iata": "PAP",
        "icao": "MTPP",
        "airportName": "Toussaint L'Ouverture International Airport",
        "location_country": "Haiti",
        "location_city": "Port-au-Prince",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.57999992",
        "longitude": "-72.29250336",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9974",
                "runway_width": "141",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197687,
            "s_seats": 32356,
            "j_seats": 14080,
            "f_seats": 3886,
            "r_seats": 7968,
            "cargo_demand": 407297
        }
    },
    {
        "iata": "PAQ",
        "icao": "PAAQ",
        "airportName": "Palmer Municipal Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Palmer",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.594898",
        "longitude": "-149.08901",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3617",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6008",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16S/34S",
                "runway_length": "1560",
                "runway_width": "60",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 197919,
            "s_seats": 25296,
            "j_seats": 30202,
            "f_seats": 3479,
            "r_seats": 8014,
            "cargo_demand": 304001
        }
    },
    {
        "iata": "PAS",
        "icao": "LGPA",
        "airportName": "Paros National Airport",
        "location_country": "Greece",
        "location_city": "Paros",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.020495",
        "longitude": "25.113195",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2126,
            "s_seats": 1505,
            "j_seats": 1035,
            "f_seats": 227,
            "r_seats": 98,
            "cargo_demand": 6109
        }
    },
    {
        "iata": "PAT",
        "icao": "VEPT",
        "airportName": "Lok Nayak Jayaprakash Airport",
        "location_country": "India",
        "location_city": "Bihar",
        "location_state": "Patna",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.59129906",
        "longitude": "85.08799744",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6410",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152854,
            "s_seats": 32334,
            "j_seats": 52682,
            "f_seats": 9404,
            "r_seats": 3982,
            "cargo_demand": 85646
        }
    },
    {
        "iata": "PAV",
        "icao": "SBUF",
        "airportName": "Paulo Afonso Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Paulo Afonso",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.40087986",
        "longitude": "-38.25059891",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5906",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177243,
            "s_seats": 43253,
            "j_seats": 38252,
            "f_seats": 7618,
            "r_seats": 8981,
            "cargo_demand": 457492
        }
    },
    {
        "iata": "PAZ",
        "icao": "MMPA",
        "airportName": "El Tajin National Airport",
        "location_country": "Mexico",
        "location_city": "Veracruz",
        "location_state": "Poza Rica",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.60269928",
        "longitude": "-97.46080017",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5906",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152931,
            "s_seats": 43678,
            "j_seats": 20672,
            "f_seats": 2556,
            "r_seats": 4386,
            "cargo_demand": 423551
        }
    },
    {
        "iata": "PBB",
        "icao": "SSPN",
        "airportName": "Paranaiba Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso do Sul",
        "location_state": "Paranaiba",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-19.65119934",
        "longitude": "-51.19940186",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4331",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3084,
            "s_seats": 2441,
            "j_seats": 1468,
            "f_seats": 247,
            "r_seats": 1,
            "cargo_demand": 52462
        }
    },
    {
        "iata": "PBC",
        "icao": "MMPB",
        "airportName": "Hermanos Serdan International Airport",
        "location_country": "Mexico",
        "location_city": "Puebla",
        "location_state": "Puebla City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.15810013",
        "longitude": "-98.37139893",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66747,
            "s_seats": 32517,
            "j_seats": 20256,
            "f_seats": 18701,
            "r_seats": 7592,
            "cargo_demand": 499438
        }
    },
    {
        "iata": "PBD",
        "icao": "VAPR",
        "airportName": "Porbandar Airport",
        "location_country": "India",
        "location_city": "Gujarat",
        "location_state": "Porbandar",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.649524",
        "longitude": "69.656405",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 126919,
            "s_seats": 48724,
            "j_seats": 42082,
            "f_seats": 9384,
            "r_seats": 6203,
            "cargo_demand": 303615
        }
    },
    {
        "iata": "PBE",
        "icao": "SKPR",
        "airportName": "Morela Airport",
        "location_country": "Colombia",
        "location_city": "Puerto Berrio",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "6.46034",
        "longitude": "-74.4105",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4230",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2518,
            "s_seats": 2666,
            "j_seats": 1185,
            "f_seats": 474,
            "r_seats": 13,
            "cargo_demand": 42580
        }
    },
    {
        "iata": "PBF",
        "icao": "KPBF",
        "airportName": "Grider Field",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Pine Bluff",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "\" Grider Field\"\"\"",
        "longitude": "34.1731",
        "region": "206",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5998",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 107186,
            "s_seats": 27059,
            "j_seats": 19367,
            "f_seats": 16317,
            "r_seats": 7340,
            "cargo_demand": 100446
        }
    },
    {
        "iata": "PBG",
        "icao": "KPBG",
        "airportName": "Plattsburgh International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Plattsburgh",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.65090179",
        "longitude": "-73.4681015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "11758",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 84398,
            "s_seats": 26312,
            "j_seats": 9328,
            "f_seats": 3585,
            "r_seats": 7702,
            "cargo_demand": 174166
        }
    },
    {
        "iata": "PBH",
        "icao": "VQPR",
        "airportName": "Paro International Airport",
        "location_country": "Bhutan",
        "location_city": "Paro",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.4032",
        "longitude": "89.424599",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7431",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163678,
            "s_seats": 46427,
            "j_seats": 53989,
            "f_seats": 13480,
            "r_seats": 4340,
            "cargo_demand": 80582
        }
    },
    {
        "iata": "PBI",
        "icao": "KPBI",
        "airportName": "Palm Beach International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "West Palm Beach",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "26.68320084",
        "longitude": "-80.09559631",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "10008",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "3213",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6932",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 140115,
            "s_seats": 96098,
            "j_seats": 120512,
            "f_seats": 34192,
            "r_seats": 10000,
            "cargo_demand": 21981570
        }
    },
    {
        "iata": "PBJ",
        "icao": "NVSI",
        "airportName": "Paama Airport",
        "location_country": "Vanuatu",
        "location_city": "Paama",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.43899918",
        "longitude": "168.2570038",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "1968",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1145,
            "s_seats": 2196,
            "j_seats": 1870,
            "f_seats": 283,
            "r_seats": 95,
            "cargo_demand": 83814
        }
    },
    {
        "iata": "PBL",
        "icao": "SVPC",
        "airportName": "Bartolome Salom Airport",
        "location_country": "Venezuela",
        "location_city": "Puerto Cabello",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.48050022",
        "longitude": "-68.07299805",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6970",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78219,
            "s_seats": 35389,
            "j_seats": 41908,
            "f_seats": 2242,
            "r_seats": 7917,
            "cargo_demand": 216336
        }
    },
    {
        "iata": "PBM",
        "icao": "SMJP",
        "airportName": "Johan Adolf Pengel International Airport",
        "location_country": "Suriname",
        "location_city": "Paramaribo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "5.45283",
        "longitude": "-55.187801",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "11417",
                "runway_width": "148",
                "runway_material": "ASPHALT"
            }
        ],
        "demand": {
            "y_seats": 455613,
            "s_seats": 84852,
            "j_seats": 85953,
            "f_seats": 65639,
            "r_seats": 10000,
            "cargo_demand": 7948613
        }
    },
    {
        "iata": "PBN",
        "icao": "FNPA",
        "airportName": "Porto Amboim Airport",
        "location_country": "Angola",
        "location_city": "Porto Amboim",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-10.72200012",
        "longitude": "13.76550007",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3379",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153018,
            "s_seats": 41311,
            "j_seats": 20385,
            "f_seats": 3112,
            "r_seats": 5521,
            "cargo_demand": 58484
        }
    },
    {
        "iata": "PBO",
        "icao": "YPBO",
        "airportName": "Paraburdoo Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Paraburdoo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.17110062",
        "longitude": "117.7450027",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6995",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94842,
            "s_seats": 29427,
            "j_seats": 11175,
            "f_seats": 17749,
            "r_seats": 3437,
            "cargo_demand": 362964
        }
    },
    {
        "iata": "PBP",
        "icao": "MRIA",
        "airportName": "Punta Islita Airport",
        "location_country": "Costa Rica",
        "location_city": "Punta Islita",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "9.856109619",
        "longitude": "-85.3707962",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2625",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1873,
            "s_seats": 2848,
            "j_seats": 1774,
            "f_seats": 105,
            "r_seats": 103,
            "cargo_demand": 46933
        }
    },
    {
        "iata": "PBQ",
        "icao": "SWPM",
        "airportName": "Pimenta Bueno Airport",
        "location_country": "Brazil",
        "location_city": "Rondonia",
        "location_state": "Pimenta Bueno",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.64159966",
        "longitude": "-61.17910004",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "4265",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3946,
            "s_seats": 1795,
            "j_seats": 1793,
            "f_seats": 165,
            "r_seats": 24,
            "cargo_demand": 68485
        }
    },
    {
        "iata": "PBR",
        "icao": "MGPB",
        "airportName": "Puerto Barrios Airport",
        "location_country": "Guatemala",
        "location_city": "Puerto Barrios",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.7309",
        "longitude": "-88.583801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8880",
                "runway_width": "110",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 137447,
            "s_seats": 34319,
            "j_seats": 12723,
            "f_seats": 5529,
            "r_seats": 3983,
            "cargo_demand": 207163
        }
    },
    {
        "iata": "PBU",
        "icao": "VYPT",
        "airportName": "Putao Airport",
        "location_country": "Myanmar",
        "location_city": "Putao",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.32990074",
        "longitude": "97.42630005",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7002",
                "runway_width": "102",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 141759,
            "s_seats": 31795,
            "j_seats": 7921,
            "f_seats": 1903,
            "r_seats": 5709,
            "cargo_demand": 392137
        }
    },
    {
        "iata": "PBV",
        "icao": "SWPG",
        "airportName": "Porto dos Gauchos Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Porto dos Gauchos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.5404",
        "longitude": "-57.3782",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "4921",
                "runway_width": "197",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2216,
            "s_seats": 2938,
            "j_seats": 1376,
            "f_seats": 390,
            "r_seats": 163,
            "cargo_demand": 58561
        }
    },
    {
        "iata": "PBX",
        "icao": "SWPQ",
        "airportName": "Fazenda Piraguassu Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Porto Alegre do Norte",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.86111069",
        "longitude": "-51.68500137",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3330",
                "runway_width": "62",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1521,
            "s_seats": 2755,
            "j_seats": 1463,
            "f_seats": 424,
            "r_seats": 129,
            "cargo_demand": 88202
        }
    },
    {
        "iata": "PBZ",
        "icao": "FAPG",
        "airportName": "Plettenberg Bay Airport",
        "location_country": "South Africa",
        "location_city": "Plettenberg Bay",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.08816",
        "longitude": "23.328723",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4003",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151119,
            "s_seats": 37554,
            "j_seats": 49482,
            "f_seats": 19513,
            "r_seats": 9556,
            "cargo_demand": 296743
        }
    },
    {
        "iata": "PCA",
        "icao": "PAOC",
        "airportName": "Portage Creek Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Portage Creek",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "58.9065",
        "longitude": "-157.714",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "1470",
                "runway_width": "60",
                "runway_material": "GRVL-P"
            },
            {
                "runway_name": "09/27",
                "runway_length": "1920",
                "runway_width": "60",
                "runway_material": "GRVL-P"
            }
        ],
        "demand": {
            "y_seats": 2959,
            "s_seats": 1662,
            "j_seats": 1439,
            "f_seats": 66,
            "r_seats": 195,
            "cargo_demand": 20259
        }
    },
    {
        "iata": "PCB",
        "icao": "WIHP",
        "airportName": "Pondok Cabe Airport",
        "location_country": "Indonesia",
        "location_city": "South Tangerang",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.336959839",
        "longitude": "106.7649994",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6516",
                "runway_width": "146",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3218,
            "s_seats": 2750,
            "j_seats": 1414,
            "f_seats": 461,
            "r_seats": 120,
            "cargo_demand": 52442
        }
    },
    {
        "iata": "PCD",
        "icao": "KPDC",
        "airportName": "Prairie du Chien Municipal Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Prairie du Chien",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.01929855",
        "longitude": "-91.123703",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3999",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1140,
            "s_seats": 2901,
            "j_seats": 1816,
            "f_seats": 272,
            "r_seats": 82,
            "cargo_demand": 65085
        }
    },
    {
        "iata": "PCF",
        "icao": "FAPS",
        "airportName": "Potchefstroom Airport",
        "location_country": "South Africa",
        "location_city": "Potchefstroom",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.67099953",
        "longitude": "27.08189964",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4885",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3281",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 109760,
            "s_seats": 49627,
            "j_seats": 46898,
            "f_seats": 15641,
            "r_seats": 5563,
            "cargo_demand": 330520
        }
    },
    {
        "iata": "PCL",
        "icao": "SPCL",
        "airportName": "FAP Captain David Abensur Rengifo International Airport",
        "location_country": "Peru",
        "location_city": "Pucallpa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.377940178",
        "longitude": "-74.57430267",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59233,
            "s_seats": 35338,
            "j_seats": 19390,
            "f_seats": 14861,
            "r_seats": 8872,
            "cargo_demand": 379924
        }
    },
    {
        "iata": "PCP",
        "icao": "FPPR",
        "airportName": "Principe Airport",
        "location_country": "Sio Tome and Principe",
        "location_city": "Principe Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.66294",
        "longitude": "7.41174",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4331",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 195398,
            "s_seats": 32357,
            "j_seats": 37323,
            "f_seats": 8438,
            "r_seats": 3405,
            "cargo_demand": 143858
        }
    },
    {
        "iata": "PCR",
        "icao": "SKPC",
        "airportName": "German Olano Airport",
        "location_country": "Colombia",
        "location_city": "Puerto Carreno",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.18472",
        "longitude": "-67.4932",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5907",
                "runway_width": "69",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 85843,
            "s_seats": 46208,
            "j_seats": 57349,
            "f_seats": 12644,
            "r_seats": 9067,
            "cargo_demand": 199428
        }
    },
    {
        "iata": "PCS",
        "icao": "SNPC",
        "airportName": "Picos Airport",
        "location_country": "Brazil",
        "location_city": "Piaui",
        "location_state": "Picos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.062059879",
        "longitude": "-41.52370071",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3937",
                "runway_width": "79",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1128,
            "s_seats": 2225,
            "j_seats": 1970,
            "f_seats": 278,
            "r_seats": 191,
            "cargo_demand": 42765
        }
    },
    {
        "iata": "PDA",
        "icao": "SKPD",
        "airportName": "Obando Airport",
        "location_country": "Colombia",
        "location_city": "Puerto Inirida",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.85353",
        "longitude": "-67.9062",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5910",
                "runway_width": "85",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79165,
            "s_seats": 41539,
            "j_seats": 24345,
            "f_seats": 7631,
            "r_seats": 9980,
            "cargo_demand": 131993
        }
    },
    {
        "iata": "PDE",
        "icao": "YPDI",
        "airportName": "Pandie Pandie Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Pandie Pandie Station",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.11669922",
        "longitude": "139.3999939",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3608",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 3909,
            "s_seats": 1989,
            "j_seats": 1215,
            "f_seats": 396,
            "r_seats": 142,
            "cargo_demand": 6890
        }
    },
    {
        "iata": "PDG",
        "icao": "WIPT",
        "airportName": "Minangkabau International Airport",
        "location_country": "Indonesia",
        "location_city": "Padang",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-0.786917",
        "longitude": "100.280998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "9020",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 366071,
            "s_seats": 76148,
            "j_seats": 128998,
            "f_seats": 32339,
            "r_seats": 10000,
            "cargo_demand": 28234154
        }
    },
    {
        "iata": "PDK",
        "icao": "KPDK",
        "airportName": "DeKalb-Peachtree Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Atlanta",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.87559891",
        "longitude": "-84.30200195",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3383",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3967",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "3L/21R",
                "runway_length": "3746",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "3R/21L",
                "runway_length": "6001",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 135721,
            "s_seats": 32737,
            "j_seats": 39376,
            "f_seats": 13384,
            "r_seats": 6934,
            "cargo_demand": 377300
        }
    },
    {
        "iata": "PDL",
        "icao": "LPPD",
        "airportName": "Joio Paulo II Airport",
        "location_country": "Portugal",
        "location_city": "Azores",
        "location_state": "Ponta Delgada",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.74119949",
        "longitude": "-25.69790077",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8192",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 453969,
            "s_seats": 92587,
            "j_seats": 64853,
            "f_seats": 22430,
            "r_seats": 10000,
            "cargo_demand": 13368282
        }
    },
    {
        "iata": "PDO",
        "icao": "WIPQ",
        "airportName": "Pendopo Airport",
        "location_country": "Indonesia",
        "location_city": "Pendopo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.286070108",
        "longitude": "103.8799973",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4265",
                "runway_width": "66",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 121247,
            "s_seats": 26971,
            "j_seats": 39376,
            "f_seats": 11767,
            "r_seats": 5125,
            "cargo_demand": 378102
        }
    },
    {
        "iata": "PDP",
        "icao": "SULS",
        "airportName": "Capitan de Corbeta Carlos A. Curbelo International Airport",
        "location_country": "Uruguay",
        "location_city": "Punta del Este",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.85509872",
        "longitude": "-55.09429932",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5249",
                "runway_width": "125",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6998",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174898,
            "s_seats": 34860,
            "j_seats": 32390,
            "f_seats": 19831,
            "r_seats": 4647,
            "cargo_demand": 259213
        }
    },
    {
        "iata": "PDS",
        "icao": "MMPG",
        "airportName": "Piedras Negras International Airport",
        "location_country": "Mexico",
        "location_city": "Coahuila",
        "location_state": "Piedras Negras",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.627399",
        "longitude": "-100.535004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6655",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172295,
            "s_seats": 31856,
            "j_seats": 46860,
            "f_seats": 8270,
            "r_seats": 3629,
            "cargo_demand": 68578
        }
    },
    {
        "iata": "PDT",
        "icao": "KPDT",
        "airportName": "Eastern Oregon Regional Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Pendleton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.69509888",
        "longitude": "-118.8410034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6300",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5581",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4341",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180453,
            "s_seats": 39544,
            "j_seats": 34217,
            "f_seats": 19147,
            "r_seats": 9981,
            "cargo_demand": 315030
        }
    },
    {
        "iata": "PDU",
        "icao": "SUPU",
        "airportName": "Tydeo Larre Borges International Airport",
        "location_country": "Uruguay",
        "location_city": "Paysandu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-32.36330032",
        "longitude": "-58.06190109",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2953",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2844,
            "s_seats": 1537,
            "j_seats": 1821,
            "f_seats": 249,
            "r_seats": 20,
            "cargo_demand": 81129
        }
    },
    {
        "iata": "PDV",
        "icao": "LBPD",
        "airportName": "Plovdiv Airport",
        "location_country": "Bulgaria",
        "location_city": "Plovdiv",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.067799",
        "longitude": "24.8508",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 73897,
            "s_seats": 46807,
            "j_seats": 30607,
            "f_seats": 2955,
            "r_seats": 3020,
            "cargo_demand": 413370
        }
    },
    {
        "iata": "PDX",
        "icao": "KPDX",
        "airportName": "Portland International Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Portland",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "45.58869934",
        "longitude": "-122.5979996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "11000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104452,
            "s_seats": 71466,
            "j_seats": 32615,
            "f_seats": 30160,
            "r_seats": 10000,
            "cargo_demand": 8585646
        }
    },
    {
        "iata": "PDZ",
        "icao": "SVPE",
        "airportName": "Pedernales Airport",
        "location_country": "Venezuela",
        "location_city": "Pedernales",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "9.979240417",
        "longitude": "-62.22859955",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4180",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1652,
            "s_seats": 2061,
            "j_seats": 1743,
            "f_seats": 464,
            "r_seats": 9,
            "cargo_demand": 29289
        }
    },
    {
        "iata": "PEA",
        "icao": "YPSH",
        "airportName": "Penneshaw Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Penneshaw",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-35.75584629",
        "longitude": "137.9628754",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2985",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3580,
            "s_seats": 1517,
            "j_seats": 1218,
            "f_seats": 322,
            "r_seats": 27,
            "cargo_demand": 5720
        }
    },
    {
        "iata": "PED",
        "icao": "LKPD",
        "airportName": "Pardubice Airport",
        "location_country": "Czech Republic",
        "location_city": "Pardubice",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.01340103",
        "longitude": "15.73859978",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8203",
                "runway_width": "246",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 140692,
            "s_seats": 48630,
            "j_seats": 23649,
            "f_seats": 6684,
            "r_seats": 6738,
            "cargo_demand": 319114
        }
    },
    {
        "iata": "PEE",
        "icao": "USPP",
        "airportName": "Perm International Airport",
        "location_country": "Russia",
        "location_city": "Perm Krai",
        "location_state": "Perm",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "57.914501",
        "longitude": "56.021198",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10520",
                "runway_width": "161",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 89284,
            "s_seats": 48620,
            "j_seats": 40793,
            "f_seats": 2521,
            "r_seats": 7306,
            "cargo_demand": 448078
        }
    },
    {
        "iata": "PEF",
        "icao": "EDCP",
        "airportName": "Peenemunde Airfield",
        "location_country": "Germany",
        "location_city": "Mecklenburg-Vorpommern",
        "location_state": "Peenemunde",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "54.15777969",
        "longitude": "13.77444363",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7874",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3389,
            "s_seats": 2862,
            "j_seats": 1488,
            "f_seats": 431,
            "r_seats": 55,
            "cargo_demand": 87733
        }
    },
    {
        "iata": "PEG",
        "icao": "LIRZ",
        "airportName": "Perugia San Francesco d'Assisi - Umbria International Airport",
        "location_country": "Italy",
        "location_city": "Umbria",
        "location_state": "Perugia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.095901",
        "longitude": "12.5132",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5564",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174935,
            "s_seats": 30888,
            "j_seats": 33093,
            "f_seats": 10832,
            "r_seats": 6429,
            "cargo_demand": 449241
        }
    },
    {
        "iata": "PEH",
        "icao": "SAZP",
        "airportName": "Comodoro Pedro Zanni Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "Pehuajo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-35.8446",
        "longitude": "-61.8576",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50123,
            "s_seats": 46842,
            "j_seats": 39277,
            "f_seats": 8511,
            "r_seats": 5506,
            "cargo_demand": 136045
        }
    },
    {
        "iata": "PEI",
        "icao": "SKPE",
        "airportName": "Matecana International Airport",
        "location_country": "Colombia",
        "location_city": "Pereira",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.81267",
        "longitude": "-75.7395",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6627",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128026,
            "s_seats": 25224,
            "j_seats": 55178,
            "f_seats": 11491,
            "r_seats": 6590,
            "cargo_demand": 494938
        }
    },
    {
        "iata": "PEK",
        "icao": "ZBAA",
        "airportName": "Beijing Capital International Airport",
        "location_country": "China",
        "location_city": "Beijing",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.08010101",
        "longitude": "116.5849991",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "12468",
                "runway_width": "197",
                "runway_material": "concrete"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "12468",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "10499",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 441519,
            "s_seats": 85933,
            "j_seats": 66124,
            "f_seats": 37684,
            "r_seats": 10000,
            "cargo_demand": 9207810
        }
    },
    {
        "iata": "PEM",
        "icao": "SPTU",
        "airportName": "Padre Aldamiz International Airport",
        "location_country": "Peru",
        "location_city": "Puerto Maldonado",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.61359978",
        "longitude": "-69.22859955",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "11482",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 75661,
            "s_seats": 43679,
            "j_seats": 6511,
            "f_seats": 14060,
            "r_seats": 4392,
            "cargo_demand": 192118
        }
    },
    {
        "iata": "PEN",
        "icao": "WMKP",
        "airportName": "Penang International Airport",
        "location_country": "Malaysia",
        "location_city": "Penang",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.297140121",
        "longitude": "100.2770004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10997",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173878,
            "s_seats": 26656,
            "j_seats": 24596,
            "f_seats": 1757,
            "r_seats": 7276,
            "cargo_demand": 286165
        }
    },
    {
        "iata": "PEQ",
        "icao": "KPEQ",
        "airportName": "Pecos Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Pecos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.38240051",
        "longitude": "-103.5110016",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5954",
                "runway_width": "80",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6237",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1220,
            "s_seats": 2703,
            "j_seats": 1019,
            "f_seats": 70,
            "r_seats": 70,
            "cargo_demand": 79834
        }
    },
    {
        "iata": "PER",
        "icao": "YPPH",
        "airportName": "Perth Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Perth",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-31.94029999",
        "longitude": "115.9670029",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "11299",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "7096",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 299222,
            "s_seats": 90144,
            "j_seats": 141863,
            "f_seats": 53390,
            "r_seats": 10000,
            "cargo_demand": 13465429
        }
    },
    {
        "iata": "PES",
        "icao": "ULPB",
        "airportName": "Petrozavodsk Airport",
        "location_country": "Russia",
        "location_city": "Karelia",
        "location_state": "Petrozavodsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.8852005",
        "longitude": "34.15470123",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8202",
                "runway_width": "157",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 85174,
            "s_seats": 42745,
            "j_seats": 40408,
            "f_seats": 11930,
            "r_seats": 8482,
            "cargo_demand": 376789
        }
    },
    {
        "iata": "PET",
        "icao": "SBPK",
        "airportName": "Joio Simi\u00b5es Lopes Neto International Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Pelotas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.718399",
        "longitude": "-52.327702",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4035",
                "runway_width": "125",
                "runway_material": "GRE"
            },
            {
                "runway_name": "6/24",
                "runway_length": "6496",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 154097,
            "s_seats": 38845,
            "j_seats": 30558,
            "f_seats": 4278,
            "r_seats": 6831,
            "cargo_demand": 172174
        }
    },
    {
        "iata": "PEV",
        "icao": "LHPP",
        "airportName": "Pecs-Pogany International Airport",
        "location_country": "Hungary",
        "location_city": "Pecs",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.990898",
        "longitude": "18.240996",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4922",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172886,
            "s_seats": 39222,
            "j_seats": 11312,
            "f_seats": 17173,
            "r_seats": 7139,
            "cargo_demand": 118249
        }
    },
    {
        "iata": "PEW",
        "icao": "OPPS",
        "airportName": "Bacha Khan International Airport",
        "location_country": "Pakistan",
        "location_city": "Peshawar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.9939003",
        "longitude": "71.51460266",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61024,
            "s_seats": 40559,
            "j_seats": 46411,
            "f_seats": 7342,
            "r_seats": 5353,
            "cargo_demand": 143600
        }
    },
    {
        "iata": "PEZ",
        "icao": "UWPP",
        "airportName": "Penza Airport",
        "location_country": "Russia",
        "location_city": "Penza Oblast",
        "location_state": "Penza",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.11059952",
        "longitude": "45.02109909",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9155",
                "runway_width": "145",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 159554,
            "s_seats": 37379,
            "j_seats": 10435,
            "f_seats": 1145,
            "r_seats": 6494,
            "cargo_demand": 112412
        }
    },
    {
        "iata": "PFB",
        "icao": "SBPF",
        "airportName": "Lauro Kurtz Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Passo Fundo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.243976",
        "longitude": "-52.32777",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "5577",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111468,
            "s_seats": 47701,
            "j_seats": 47816,
            "f_seats": 15799,
            "r_seats": 4255,
            "cargo_demand": 308043
        }
    },
    {
        "iata": "PFJ",
        "icao": "BIPA",
        "airportName": "Patreksfjor\u00f0ur Airport",
        "location_country": "Iceland",
        "location_city": "Patreksfjor\u00f0ur",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.555801",
        "longitude": "-23.965",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4593",
                "runway_width": "148",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 2060,
            "s_seats": 1791,
            "j_seats": 1252,
            "f_seats": 340,
            "r_seats": 96,
            "cargo_demand": 54724
        }
    },
    {
        "iata": "PFO",
        "icao": "LCPH",
        "airportName": "Paphos International Airport",
        "location_country": "Cyprus",
        "location_city": "Paphos",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "34.7179985",
        "longitude": "32.4856987",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 436940,
            "s_seats": 92991,
            "j_seats": 117865,
            "f_seats": 23769,
            "r_seats": 10000,
            "cargo_demand": 18500530
        }
    },
    {
        "iata": "PFQ",
        "icao": "OITP",
        "airportName": "Parsabad-Moghan Airport",
        "location_country": "Iran",
        "location_city": "Parsabad",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "39.60359955",
        "longitude": "47.88150024",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8515",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1789,
            "s_seats": 2969,
            "j_seats": 1377,
            "f_seats": 432,
            "r_seats": 5,
            "cargo_demand": 23839
        }
    },
    {
        "iata": "PGA",
        "icao": "KPGA",
        "airportName": "Page Municipal Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Page",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.92610168",
        "longitude": "-111.447998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2201",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5950",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146717,
            "s_seats": 43432,
            "j_seats": 7820,
            "f_seats": 7307,
            "r_seats": 7015,
            "cargo_demand": 477366
        }
    },
    {
        "iata": "PGD",
        "icao": "KPGD",
        "airportName": "Punta Gorda Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Punta Gorda",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "26.92020035",
        "longitude": "-81.9905014",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6695",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4591",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4743",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3247,
            "s_seats": 2526,
            "j_seats": 1688,
            "f_seats": 432,
            "r_seats": 47,
            "cargo_demand": 13067
        }
    },
    {
        "iata": "PGF",
        "icao": "LFMP",
        "airportName": "Perpignan-Rivesaltes Airport",
        "location_country": "France",
        "location_city": "Languedoc-Roussillon",
        "location_state": "Perpignan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.74039841",
        "longitude": "2.87067008",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3560",
                "runway_width": "66",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78242,
            "s_seats": 47138,
            "j_seats": 13376,
            "f_seats": 9015,
            "r_seats": 4659,
            "cargo_demand": 102727
        }
    },
    {
        "iata": "PGH",
        "icao": "VIPT",
        "airportName": "Pantnagar Airport",
        "location_country": "India",
        "location_city": "Uttarakhand",
        "location_state": "Pantnagar",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.0334",
        "longitude": "79.473701",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83094,
            "s_seats": 29614,
            "j_seats": 6189,
            "f_seats": 13863,
            "r_seats": 7476,
            "cargo_demand": 314395
        }
    },
    {
        "iata": "PGK",
        "icao": "WIPK",
        "airportName": "Depati Amir Airport",
        "location_country": "Indonesia",
        "location_city": "Pangkal Pinang (Pangkalpinang)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.162199974",
        "longitude": "106.1389999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6550",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3529,
            "s_seats": 2004,
            "j_seats": 1304,
            "f_seats": 9,
            "r_seats": 162,
            "cargo_demand": 37066
        }
    },
    {
        "iata": "PGL",
        "icao": "KPQL",
        "airportName": "Trent Lott International Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Pascagoula",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "30.46279907",
        "longitude": "-88.52919769",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2492,
            "s_seats": 2550,
            "j_seats": 1855,
            "f_seats": 46,
            "r_seats": 140,
            "cargo_demand": 81399
        }
    },
    {
        "iata": "PGO",
        "icao": "KPSO",
        "airportName": "Stevens Field",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Pagosa Springs",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.28630066",
        "longitude": "-107.0559998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8098",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3976,
            "s_seats": 2644,
            "j_seats": 1481,
            "f_seats": 60,
            "r_seats": 61,
            "cargo_demand": 2418
        }
    },
    {
        "iata": "PGR",
        "icao": "KPGR",
        "airportName": "Kirk Field",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Paragould",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.06290054",
        "longitude": "-90.50779724",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4500",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "2800",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2999,
            "s_seats": 2659,
            "j_seats": 1816,
            "f_seats": 221,
            "r_seats": 157,
            "cargo_demand": 42669
        }
    },
    {
        "iata": "PGU",
        "icao": "OIBP",
        "airportName": "Persian Gulf Airport",
        "location_country": "Iran",
        "location_city": "Asaluyeh (Asaloyeh)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.379601",
        "longitude": "52.737701",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "13115",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151732,
            "s_seats": 35435,
            "j_seats": 9014,
            "f_seats": 5568,
            "r_seats": 9622,
            "cargo_demand": 475482
        }
    },
    {
        "iata": "PGV",
        "icao": "KPGV",
        "airportName": "Pitt-Greenville Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Greenville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.6352005",
        "longitude": "-77.38529968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "4998",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2687",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142339,
            "s_seats": 43226,
            "j_seats": 33789,
            "f_seats": 13779,
            "r_seats": 6166,
            "cargo_demand": 152355
        }
    },
    {
        "iata": "PGX",
        "icao": "LFBX",
        "airportName": "Perigueux Bassillac Airport",
        "location_country": "France",
        "location_city": "Aquitaine",
        "location_state": "Perigueux",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.19810104",
        "longitude": "0.81555599",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5741",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141384,
            "s_seats": 40242,
            "j_seats": 49136,
            "f_seats": 14252,
            "r_seats": 5433,
            "cargo_demand": 447280
        }
    },
    {
        "iata": "PGZ",
        "icao": "SSZW",
        "airportName": "Comte. Antonio Amilton Beraldo Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Ponta Grossa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.1847",
        "longitude": "-50.1441",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4692",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188127,
            "s_seats": 29718,
            "j_seats": 35226,
            "f_seats": 9803,
            "r_seats": 5235,
            "cargo_demand": 220479
        }
    },
    {
        "iata": "PHA",
        "icao": "VVPR",
        "airportName": "Phan Rang Air Base",
        "location_country": "Vietnam",
        "location_city": "Phan Rang-Thap Chi\u00a0m",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "11.6335001",
        "longitude": "108.9520035",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9990",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2981,
            "s_seats": 2974,
            "j_seats": 1518,
            "f_seats": 57,
            "r_seats": 44,
            "cargo_demand": 26410
        }
    },
    {
        "iata": "PHB",
        "icao": "SBPB",
        "airportName": "Parnaiba-Prefeito Dr. Joio Silva Filho International Airport",
        "location_country": "Brazil",
        "location_city": "Piaui",
        "location_state": "Parnaiba",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.89375",
        "longitude": "-41.731998",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1502,
            "s_seats": 2540,
            "j_seats": 1886,
            "f_seats": 250,
            "r_seats": 97,
            "cargo_demand": 6202
        }
    },
    {
        "iata": "PHC",
        "icao": "DNPO",
        "airportName": "Port Harcourt International Airport",
        "location_country": "Nigeria",
        "location_city": "Port Harcourt",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.015490055",
        "longitude": "6.949590206",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9846",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155812,
            "s_seats": 41314,
            "j_seats": 32480,
            "f_seats": 16366,
            "r_seats": 9631,
            "cargo_demand": 480090
        }
    },
    {
        "iata": "PHD",
        "icao": "KPHD",
        "airportName": "Harry Clever Field",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "New Philadelphia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.47090149",
        "longitude": "-81.41970062",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2050",
                "runway_width": "100",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3950",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3725,
            "s_seats": 2211,
            "j_seats": 1183,
            "f_seats": 425,
            "r_seats": 83,
            "cargo_demand": 12
        }
    },
    {
        "iata": "PHE",
        "icao": "YPPD",
        "airportName": "Port Hedland International Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Port Hedland",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.37779999",
        "longitude": "118.6259995",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3281",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121651,
            "s_seats": 36600,
            "j_seats": 52571,
            "f_seats": 15224,
            "r_seats": 5279,
            "cargo_demand": 411475
        }
    },
    {
        "iata": "PHF",
        "icao": "KPHF",
        "airportName": "Newport News/Williamsburg International Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Newport News / Williamsburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.13190079",
        "longitude": "-76.49299622",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6526",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "07/25",
                "runway_length": "8003",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 369839,
            "s_seats": 87112,
            "j_seats": 168542,
            "f_seats": 40922,
            "r_seats": 10000,
            "cargo_demand": 11365930
        }
    },
    {
        "iata": "PHI",
        "icao": "SNYE",
        "airportName": "Pinheiro Airport",
        "location_country": "Brazil",
        "location_city": "Maranhio",
        "location_state": "Pinheiro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.477426",
        "longitude": "-45.105003",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "5709",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2630,
            "s_seats": 2064,
            "j_seats": 1131,
            "f_seats": 119,
            "r_seats": 61,
            "cargo_demand": 97106
        }
    },
    {
        "iata": "PHK",
        "icao": "KPHK",
        "airportName": "Palm Beach County Glades Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Pahokee",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "26.78499985",
        "longitude": "-80.69339752",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "1617",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4116",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2573,
            "s_seats": 2618,
            "j_seats": 1850,
            "f_seats": 310,
            "r_seats": 197,
            "cargo_demand": 2657
        }
    },
    {
        "iata": "PHL",
        "icao": "KPHL",
        "airportName": "Philadelphia International Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Philadelphia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "39.87189865",
        "longitude": "-75.2410965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "9500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "10506",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5460",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134424,
            "s_seats": 85367,
            "j_seats": 66643,
            "f_seats": 55038,
            "r_seats": 10000,
            "cargo_demand": 26195722
        }
    },
    {
        "iata": "PHN",
        "icao": "KPHN",
        "airportName": "St. Clair County International Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Port Huron",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.9109993",
        "longitude": "-82.52890015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5103",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1260,
            "s_seats": 2410,
            "j_seats": 1204,
            "f_seats": 55,
            "r_seats": 188,
            "cargo_demand": 42683
        }
    },
    {
        "iata": "PHO",
        "icao": "PAPO",
        "airportName": "Point Hope Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Point Hope",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "68.34880066",
        "longitude": "-166.798996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASPH-F"
            }
        ],
        "demand": {
            "y_seats": 1842,
            "s_seats": 1618,
            "j_seats": 1008,
            "f_seats": 189,
            "r_seats": 192,
            "cargo_demand": 37097
        }
    },
    {
        "iata": "PHP",
        "icao": "KPHP",
        "airportName": "Philip Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Philip",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.04859924",
        "longitude": "-101.598999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3600",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3283,
            "s_seats": 2328,
            "j_seats": 1816,
            "f_seats": 227,
            "r_seats": 91,
            "cargo_demand": 1434
        }
    },
    {
        "iata": "PHQ",
        "icao": "YTMO",
        "airportName": "The Monument Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Phosphate Hill",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-21.81110001",
        "longitude": "139.923996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6233",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1368,
            "s_seats": 2609,
            "j_seats": 1582,
            "f_seats": 256,
            "r_seats": 103,
            "cargo_demand": 34860
        }
    },
    {
        "iata": "PHS",
        "icao": "VTPP",
        "airportName": "Phitsanulok Airport",
        "location_country": "Thailand",
        "location_city": "Phitsanulok",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.78289986",
        "longitude": "100.2789993",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102734,
            "s_seats": 45921,
            "j_seats": 21210,
            "f_seats": 17907,
            "r_seats": 4721,
            "cargo_demand": 81407
        }
    },
    {
        "iata": "PHT",
        "icao": "KPHT",
        "airportName": "Henry County Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Paris",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.33819962",
        "longitude": "-88.38289642",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1439,
            "s_seats": 2672,
            "j_seats": 1783,
            "f_seats": 470,
            "r_seats": 145,
            "cargo_demand": 71197
        }
    },
    {
        "iata": "PHW",
        "icao": "FAPH",
        "airportName": "Hendrik Van Eck Airport",
        "location_country": "South Africa",
        "location_city": "Phalaborwa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.93720055",
        "longitude": "31.15539932",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4491",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124434,
            "s_seats": 44546,
            "j_seats": 18301,
            "f_seats": 8155,
            "r_seats": 9561,
            "cargo_demand": 444030
        }
    },
    {
        "iata": "PHX",
        "icao": "KPHX",
        "airportName": "Phoenix Sky Harbor International Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Phoenix",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "33.435302",
        "longitude": "-112.005905",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "10300",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "7800",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "08/26",
                "runway_length": "11489",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 161696,
            "s_seats": 75300,
            "j_seats": 71898,
            "f_seats": 41285,
            "r_seats": 10000,
            "cargo_demand": 20417941
        }
    },
    {
        "iata": "PHY",
        "icao": "VTPB",
        "airportName": "Phetchabun Airport",
        "location_country": "Thailand",
        "location_city": "Phetchabun",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.6760006",
        "longitude": "101.1949997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163192,
            "s_seats": 27170,
            "j_seats": 46399,
            "f_seats": 14527,
            "r_seats": 3706,
            "cargo_demand": 117722
        }
    },
    {
        "iata": "PIA",
        "icao": "KPIA",
        "airportName": "General Wayne A. Downing Peoria International Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Peoria",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.66419983",
        "longitude": "-89.69329834",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8003",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "10104",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 229500,
            "s_seats": 87581,
            "j_seats": 36606,
            "f_seats": 33451,
            "r_seats": 10000,
            "cargo_demand": 3305563
        }
    },
    {
        "iata": "PIB",
        "icao": "KPIB",
        "airportName": "Hattiesburg-Laurel Regional Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Hattiesburg / Laurel",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.46710014",
        "longitude": "-89.33709717",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6501",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192673,
            "s_seats": 44030,
            "j_seats": 12625,
            "f_seats": 11566,
            "r_seats": 5011,
            "cargo_demand": 399625
        }
    },
    {
        "iata": "PIC",
        "icao": "MBPI",
        "airportName": "Pine Cay Airport",
        "location_country": "British Overseas Territory of Turks and Caicos Islands",
        "location_city": "Pine Cay",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.874948",
        "longitude": "-72.092344",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2533",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1681,
            "s_seats": 2184,
            "j_seats": 1770,
            "f_seats": 417,
            "r_seats": 107,
            "cargo_demand": 24872
        }
    },
    {
        "iata": "PIE",
        "icao": "KPIE",
        "airportName": "St. Pete-Clearwater International Airport",
        "location_country": "United States",
        "location_city": "St. Petersburg / Clearwater",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.91020012",
        "longitude": "-82.68740082",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5903",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5165",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "8800",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "9730",
                "runway_width": "150",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 169062,
            "s_seats": 45717,
            "j_seats": 52373,
            "f_seats": 2780,
            "r_seats": 4408,
            "cargo_demand": 268892
        }
    },
    {
        "iata": "PIF",
        "icao": "RCDC",
        "airportName": "Pingtung South Airport",
        "location_country": "Taiwan",
        "location_city": "Pingtung City",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.67239952",
        "longitude": "120.461998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7828",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 185047,
            "s_seats": 35199,
            "j_seats": 9122,
            "f_seats": 10235,
            "r_seats": 7758,
            "cargo_demand": 482814
        }
    },
    {
        "iata": "PIH",
        "icao": "KPIH",
        "airportName": "Pocatello Regional Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Pocatello",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.90980148",
        "longitude": "-112.5960007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9060",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7150",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159362,
            "s_seats": 45970,
            "j_seats": 32445,
            "f_seats": 17573,
            "r_seats": 9445,
            "cargo_demand": 255726
        }
    },
    {
        "iata": "PIK",
        "icao": "EGPK",
        "airportName": "Glasgow Prestwick Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Glasgow",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.50939941",
        "longitude": "-4.586669922",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6250",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "9797",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 176282,
            "s_seats": 39960,
            "j_seats": 12024,
            "f_seats": 17027,
            "r_seats": 9727,
            "cargo_demand": 387188
        }
    },
    {
        "iata": "PIL",
        "icao": "SGPI",
        "airportName": "Carlos Miguel Jimenez Airport",
        "location_country": "Paraguay",
        "location_city": "Pilar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.881224",
        "longitude": "-58.318026",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4920",
                "runway_width": "62",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 143934,
            "s_seats": 33659,
            "j_seats": 28151,
            "f_seats": 19141,
            "r_seats": 6006,
            "cargo_demand": 476044
        }
    },
    {
        "iata": "PIM",
        "icao": "KPIM",
        "airportName": "Harris County Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Pine Mountain",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.84069824",
        "longitude": "-84.88240051",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5002",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1423,
            "s_seats": 2453,
            "j_seats": 1544,
            "f_seats": 161,
            "r_seats": 50,
            "cargo_demand": 24296
        }
    },
    {
        "iata": "PIN",
        "icao": "SWPI",
        "airportName": "Julio Belem Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Parintins",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.673019886",
        "longitude": "-56.77719879",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1229,
            "s_seats": 2119,
            "j_seats": 1222,
            "f_seats": 182,
            "r_seats": 115,
            "cargo_demand": 73904
        }
    },
    {
        "iata": "PIO",
        "icao": "SPSO",
        "airportName": "Capitan FAP Renan Elias Olivera Airport",
        "location_country": "Peru",
        "location_city": "Pisco",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-13.74489975",
        "longitude": "-76.22029877",
        "region": "SA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9908",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106653,
            "s_seats": 27809,
            "j_seats": 11911,
            "f_seats": 9220,
            "r_seats": 9771,
            "cargo_demand": 225892
        }
    },
    {
        "iata": "PIP",
        "icao": "PAPN",
        "airportName": "Pilot Point Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Pilot Point",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "57.58039856",
        "longitude": "-157.5720062",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3280",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 1106,
            "s_seats": 2623,
            "j_seats": 1872,
            "f_seats": 44,
            "r_seats": 96,
            "cargo_demand": 19016
        }
    },
    {
        "iata": "PIR",
        "icao": "KPIR",
        "airportName": "Pierre Regional Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Pierre",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.38270187",
        "longitude": "-100.2860031",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6887",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6900",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194183,
            "s_seats": 48291,
            "j_seats": 27268,
            "f_seats": 11804,
            "r_seats": 4017,
            "cargo_demand": 132629
        }
    },
    {
        "iata": "PIS",
        "icao": "LFBI",
        "airportName": "Poitiers-Biard Airport",
        "location_country": "France",
        "location_city": "Poitou-Charentes",
        "location_state": "Poitiers",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.58769989",
        "longitude": "0.306665987",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7710",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123113,
            "s_seats": 36143,
            "j_seats": 46498,
            "f_seats": 14744,
            "r_seats": 7108,
            "cargo_demand": 193154
        }
    },
    {
        "iata": "PIT",
        "icao": "KPIT",
        "airportName": "Pittsburgh International Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Pittsburgh",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.49150085",
        "longitude": "-80.23290253",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10C/28C",
                "runway_length": "9708",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "10502",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "11500",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8101",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 106764,
            "s_seats": 84605,
            "j_seats": 107754,
            "f_seats": 42884,
            "r_seats": 10000,
            "cargo_demand": 22809097
        }
    },
    {
        "iata": "PIU",
        "icao": "SPUR",
        "airportName": "FAP Captain Guillermo Concha Iberico International Airport",
        "location_country": "Peru",
        "location_city": "Piura",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.205749989",
        "longitude": "-80.61640167",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150486,
            "s_seats": 46577,
            "j_seats": 27459,
            "f_seats": 10595,
            "r_seats": 9842,
            "cargo_demand": 71454
        }
    },
    {
        "iata": "PIV",
        "icao": "SNPX",
        "airportName": "Pirapora Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Pirapora",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.31690025",
        "longitude": "-44.86029816",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4856",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2215,
            "s_seats": 1964,
            "j_seats": 1461,
            "f_seats": 18,
            "r_seats": 142,
            "cargo_demand": 35946
        }
    },
    {
        "iata": "PIW",
        "icao": "CZMN",
        "airportName": "Pikwitonei Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Pikwitonei",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.58890152",
        "longitude": "-97.16419983",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2200",
                "runway_width": "75",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 93511,
            "s_seats": 35042,
            "j_seats": 42617,
            "f_seats": 10287,
            "r_seats": 7165,
            "cargo_demand": 406814
        }
    },
    {
        "iata": "PIX",
        "icao": "LPPI",
        "airportName": "Pico Airport",
        "location_country": "Portugal",
        "location_city": "Azores",
        "location_state": "Pico Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.554298",
        "longitude": "-28.441299",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5725",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128199,
            "s_seats": 49580,
            "j_seats": 18320,
            "f_seats": 5491,
            "r_seats": 4429,
            "cargo_demand": 419533
        }
    },
    {
        "iata": "PIZ",
        "icao": "PPIZ",
        "airportName": "Point Lay LRRS Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Point Lay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "69.73290253",
        "longitude": "-163.0050049",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3519",
                "runway_width": "80",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 173786,
            "s_seats": 30612,
            "j_seats": 29496,
            "f_seats": 19774,
            "r_seats": 5898,
            "cargo_demand": 238756
        }
    },
    {
        "iata": "PJA",
        "icao": "ESUP",
        "airportName": "Pajala Airport",
        "location_country": "Sweden",
        "location_city": "Pajala",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "67.24559784",
        "longitude": "23.06889915",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7552",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153678,
            "s_seats": 39652,
            "j_seats": 44134,
            "f_seats": 16951,
            "r_seats": 5121,
            "cargo_demand": 66738
        }
    },
    {
        "iata": "PJB",
        "icao": "KPAN",
        "airportName": "Payson Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Payson",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.25680161",
        "longitude": "-111.3389969",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5500",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1103,
            "s_seats": 1909,
            "j_seats": 1554,
            "f_seats": 89,
            "r_seats": 50,
            "cargo_demand": 8121
        }
    },
    {
        "iata": "PJC",
        "icao": "SGPJ",
        "airportName": "Dr. Augusto Roberto Fuster International Airport",
        "location_country": "Paraguay",
        "location_city": "Pedro Juan Caballero",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.63999939",
        "longitude": "-55.83000183",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5908",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1902,
            "s_seats": 2260,
            "j_seats": 1164,
            "f_seats": 55,
            "r_seats": 97,
            "cargo_demand": 809
        }
    },
    {
        "iata": "PJG",
        "icao": "OPPG",
        "airportName": "Panjgur Airport",
        "location_country": "Pakistan",
        "location_city": "Panjgur",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.9545002",
        "longitude": "64.13249969",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 159000,
            "s_seats": 25798,
            "j_seats": 34034,
            "f_seats": 18347,
            "r_seats": 7092,
            "cargo_demand": 188577
        }
    },
    {
        "iata": "PJM",
        "icao": "MRPJ",
        "airportName": "Puerto Jimenez Airport",
        "location_country": "Costa Rica",
        "location_city": "Puerto Jimenez",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.533329964",
        "longitude": "-83.30000305",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "2707",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 192141,
            "s_seats": 30229,
            "j_seats": 30177,
            "f_seats": 6099,
            "r_seats": 8171,
            "cargo_demand": 319291
        }
    },
    {
        "iata": "PKA",
        "icao": "PAPK",
        "airportName": "Napaskiak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Napaskiak",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "60.70289993",
        "longitude": "-161.7779999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "GVL"
            },
            {
                "runway_name": "09W/27W",
                "runway_length": "15000",
                "runway_width": "2000",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 1209,
            "s_seats": 2677,
            "j_seats": 1045,
            "f_seats": 273,
            "r_seats": 65,
            "cargo_demand": 68730
        }
    },
    {
        "iata": "PKB",
        "icao": "KPKB",
        "airportName": "Mid-Ohio Valley Regional Airport",
        "location_country": "United States",
        "location_city": "West Virginia",
        "location_state": "Parkersburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.3451004",
        "longitude": "-81.43920135",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6781",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101796,
            "s_seats": 26176,
            "j_seats": 42425,
            "f_seats": 5214,
            "r_seats": 3070,
            "cargo_demand": 73741
        }
    },
    {
        "iata": "PKC",
        "icao": "UHPP",
        "airportName": "Petropavlovsk-Kamchatsky Airport",
        "location_country": "Russia",
        "location_city": "Kamchatka Krai",
        "location_state": "Petropavlovsk-Kamchatsky",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.16790009",
        "longitude": "158.4539948",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "735",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "11155",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "8235",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 176922,
            "s_seats": 47620,
            "j_seats": 48449,
            "f_seats": 19352,
            "r_seats": 8202,
            "cargo_demand": 367334
        }
    },
    {
        "iata": "PKD",
        "icao": "KPKD",
        "airportName": "Park Rapids Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Park Rapids",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "46.9006",
        "longitude": "-95.073095",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5498",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3190",
                "runway_width": "140",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3593,
            "s_seats": 1782,
            "j_seats": 1387,
            "f_seats": 215,
            "r_seats": 141,
            "cargo_demand": 56545
        }
    },
    {
        "iata": "PKE",
        "icao": "YPKS",
        "airportName": "Parkes Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Parkes",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.13140106",
        "longitude": "148.2389984",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5525",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5322",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113148,
            "s_seats": 34220,
            "j_seats": 27141,
            "f_seats": 19586,
            "r_seats": 8243,
            "cargo_demand": 468215
        }
    },
    {
        "iata": "PKF",
        "icao": "KPKF",
        "airportName": "Park Falls Municipal Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Park Falls",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "45.95500183",
        "longitude": "-90.42440033",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3200",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3927,
            "s_seats": 1562,
            "j_seats": 1781,
            "f_seats": 286,
            "r_seats": 63,
            "cargo_demand": 17919
        }
    },
    {
        "iata": "PKN",
        "icao": "WAOI",
        "airportName": "Iskandar Airport",
        "location_country": "Indonesia",
        "location_city": "Pangkalan Bun (Pangkalanbuun)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.7052",
        "longitude": "111.672997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5415",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1602,
            "s_seats": 2407,
            "j_seats": 1810,
            "f_seats": 290,
            "r_seats": 94,
            "cargo_demand": 95140
        }
    },
    {
        "iata": "PKO",
        "icao": "DBBP",
        "airportName": "Parakou Airport",
        "location_country": "Benin",
        "location_city": "Parakou",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "9.357689857",
        "longitude": "2.609679937",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5249",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2205,
            "s_seats": 1819,
            "j_seats": 1106,
            "f_seats": 6,
            "r_seats": 7,
            "cargo_demand": 20374
        }
    },
    {
        "iata": "PKP",
        "icao": "NTGP",
        "airportName": "Puka-Puka Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Puka-Puka",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.80949974",
        "longitude": "-138.8130035",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3051",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80720,
            "s_seats": 35930,
            "j_seats": 14676,
            "f_seats": 13753,
            "r_seats": 9600,
            "cargo_demand": 440111
        }
    },
    {
        "iata": "PKR",
        "icao": "VNPK",
        "airportName": "Pokhara Airport",
        "location_country": "Nepal",
        "location_city": "Pokhara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.20089912",
        "longitude": "83.98210144",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4720",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66218,
            "s_seats": 26347,
            "j_seats": 40697,
            "f_seats": 8758,
            "r_seats": 4881,
            "cargo_demand": 308974
        }
    },
    {
        "iata": "PKU",
        "icao": "WIBB",
        "airportName": "Sultan Syarif Kasim II International Airport",
        "location_country": "Indonesia",
        "location_city": "Pekanbaru",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.460786015",
        "longitude": "101.4449997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7360",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151152,
            "s_seats": 44113,
            "j_seats": 57879,
            "f_seats": 9282,
            "r_seats": 4910,
            "cargo_demand": 107280
        }
    },
    {
        "iata": "PKV",
        "icao": "ULOO",
        "airportName": "Pskov Airport",
        "location_country": "Russia",
        "location_city": "Pskov Oblast",
        "location_state": "Pskov",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "57.78390121",
        "longitude": "28.39559937",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8281",
                "runway_width": "144",
                "runway_material": "CON"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "5578",
                "runway_width": "144",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 91447,
            "s_seats": 32920,
            "j_seats": 27888,
            "f_seats": 5948,
            "r_seats": 5405,
            "cargo_demand": 400546
        }
    },
    {
        "iata": "PKW",
        "icao": "FBSP",
        "airportName": "Selebi-Phikwe Airport",
        "location_country": "Botswana",
        "location_city": "Selebi-Phikwe",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.0583",
        "longitude": "27.8288",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5840",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197732,
            "s_seats": 31957,
            "j_seats": 55062,
            "f_seats": 12856,
            "r_seats": 7206,
            "cargo_demand": 57519
        }
    },
    {
        "iata": "PKX",
        "icao": "ZBAD",
        "airportName": "Beijing Daxing International Airport",
        "location_country": "China",
        "location_city": "Beijing",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "39.509945",
        "longitude": "116.41092",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "11155",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "11L/29R",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "12467",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 474622,
            "s_seats": 70313,
            "j_seats": 137031,
            "f_seats": 38826,
            "r_seats": 10000,
            "cargo_demand": 2430926
        }
    },
    {
        "iata": "PKZ",
        "icao": "VLPS",
        "airportName": "Pakse International Airport",
        "location_country": "Laos",
        "location_city": "Pakse",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.13210011",
        "longitude": "105.7809982",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5332",
                "runway_width": "118",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 183544,
            "s_seats": 48320,
            "j_seats": 10389,
            "f_seats": 18776,
            "r_seats": 8479,
            "cargo_demand": 265057
        }
    },
    {
        "iata": "PLK",
        "icao": "KPLK",
        "airportName": "M. Graham Clark Downtown Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Point Lookout",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.62590027",
        "longitude": "-93.22889709",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3739",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2962,
            "s_seats": 2083,
            "j_seats": 1709,
            "f_seats": 444,
            "r_seats": 189,
            "cargo_demand": 90296
        }
    },
    {
        "iata": "PLL",
        "icao": "SBMN",
        "airportName": "Manaus Air Force Base",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Manaus",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.14604",
        "longitude": "-59.986301",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "7119",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136056,
            "s_seats": 44342,
            "j_seats": 11856,
            "f_seats": 2224,
            "r_seats": 6726,
            "cargo_demand": 461426
        }
    },
    {
        "iata": "PLM",
        "icao": "WIPP",
        "airportName": "Sultan Mahmud Badaruddin II International Airport",
        "location_country": "Indonesia",
        "location_city": "Palembang",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.898250103",
        "longitude": "104.6999969",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124123,
            "s_seats": 31150,
            "j_seats": 29098,
            "f_seats": 2938,
            "r_seats": 6549,
            "cargo_demand": 198411
        }
    },
    {
        "iata": "PLN",
        "icao": "KPLN",
        "airportName": "Pellston Regional Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Pellston",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.57089996",
        "longitude": "-84.79669952",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5395",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6512",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111511,
            "s_seats": 25656,
            "j_seats": 14253,
            "f_seats": 4832,
            "r_seats": 8180,
            "cargo_demand": 65336
        }
    },
    {
        "iata": "PLO",
        "icao": "YPLC",
        "airportName": "Port Lincoln Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Port Lincoln",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.6053009",
        "longitude": "135.8800049",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4918",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "4183",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4757",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 172516,
            "s_seats": 48921,
            "j_seats": 8257,
            "f_seats": 4792,
            "r_seats": 8124,
            "cargo_demand": 250059
        }
    },
    {
        "iata": "PLQ",
        "icao": "EYPA",
        "airportName": "Palanga International Airport",
        "location_country": "Lithuania",
        "location_city": "Palanga",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.97320175",
        "longitude": "21.09390068",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6562",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87737,
            "s_seats": 36212,
            "j_seats": 44136,
            "f_seats": 18471,
            "r_seats": 4285,
            "cargo_demand": 136650
        }
    },
    {
        "iata": "PLR",
        "icao": "KPLR",
        "airportName": "St. Clair County Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Pell City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.55879974",
        "longitude": "-86.24909973",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5001",
                "runway_width": "80",
                "runway_material": "ASPH-E"
            }
        ],
        "demand": {
            "y_seats": 3785,
            "s_seats": 1678,
            "j_seats": 1465,
            "f_seats": 186,
            "r_seats": 27,
            "cargo_demand": 78805
        }
    },
    {
        "iata": "PLS",
        "icao": "MBPV",
        "airportName": "Providenciales International Airport",
        "location_country": "British Overseas Territory of Turks and Caicos Islands",
        "location_city": "Providenciales",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.77359962",
        "longitude": "-72.26589966",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7598",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132443,
            "s_seats": 31508,
            "j_seats": 46950,
            "f_seats": 3438,
            "r_seats": 5475,
            "cargo_demand": 468470
        }
    },
    {
        "iata": "PLU",
        "icao": "SBBH",
        "airportName": "Carlos Drummond de Andrade Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Belo Horizonte",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.8512",
        "longitude": "-43.9506",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8333",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155722,
            "s_seats": 45439,
            "j_seats": 50805,
            "f_seats": 9912,
            "r_seats": 6980,
            "cargo_demand": 476571
        }
    },
    {
        "iata": "PLX",
        "icao": "UASS",
        "airportName": "Semey Airport",
        "location_country": "Kazakhstan",
        "location_city": "Semey",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.351295",
        "longitude": "80.234398",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3270",
                "runway_width": "253",
                "runway_material": "CON"
            },
            {
                "runway_name": "08/26",
                "runway_length": "10159",
                "runway_width": "145",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 168626,
            "s_seats": 34810,
            "j_seats": 35272,
            "f_seats": 19403,
            "r_seats": 8655,
            "cargo_demand": 68541
        }
    },
    {
        "iata": "PLZ",
        "icao": "FAPE",
        "airportName": "Port Elizabeth Airport",
        "location_country": "South Africa",
        "location_city": "Port Elizabeth",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.984901",
        "longitude": "25.6173",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6496",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3865",
                "runway_width": "59",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5502",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191920,
            "s_seats": 26590,
            "j_seats": 40662,
            "f_seats": 19243,
            "r_seats": 3303,
            "cargo_demand": 466087
        }
    },
    {
        "iata": "PMA",
        "icao": "HTPE",
        "airportName": "Pemba Airport",
        "location_country": "Tanzania",
        "location_city": "Pemba Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.25726",
        "longitude": "39.811401",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5003",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 126464,
            "s_seats": 46279,
            "j_seats": 15128,
            "f_seats": 1187,
            "r_seats": 8667,
            "cargo_demand": 277010
        }
    },
    {
        "iata": "PMB",
        "icao": "KPMB",
        "airportName": "Pembina Municipal Airport",
        "location_country": "United States",
        "location_city": "North Dakota",
        "location_state": "Pembina",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "48.94250107",
        "longitude": "-97.24079895",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3800",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3926,
            "s_seats": 2988,
            "j_seats": 1869,
            "f_seats": 329,
            "r_seats": 128,
            "cargo_demand": 42381
        }
    },
    {
        "iata": "PMC",
        "icao": "SCTE",
        "airportName": "El Tepual Airport",
        "location_country": "Chile",
        "location_city": "Puerto Montt",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-41.43889999",
        "longitude": "-73.09400177",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8694",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 199672,
            "s_seats": 46660,
            "j_seats": 14331,
            "f_seats": 11401,
            "r_seats": 5223,
            "cargo_demand": 144447
        }
    },
    {
        "iata": "PMD",
        "icao": "KPMD",
        "airportName": "Palmdale Regional Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Palmdale",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.629398",
        "longitude": "-118.084999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "12001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "07/25",
                "runway_length": "12002",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "072/252",
                "runway_length": "6000",
                "runway_width": "75",
                "runway_material": "CONC-E"
            }
        ],
        "demand": {
            "y_seats": 150538,
            "s_seats": 28332,
            "j_seats": 36507,
            "f_seats": 10478,
            "r_seats": 8075,
            "cargo_demand": 45376
        }
    },
    {
        "iata": "PMF",
        "icao": "LIMP",
        "airportName": "Parma Airport",
        "location_country": "Italy",
        "location_city": "Emilia-Romagna",
        "location_state": "Parma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.824501",
        "longitude": "10.2964",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6962",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103232,
            "s_seats": 42002,
            "j_seats": 13100,
            "f_seats": 6108,
            "r_seats": 9882,
            "cargo_demand": 498338
        }
    },
    {
        "iata": "PMG",
        "icao": "SBPP",
        "airportName": "Ponta Pori International Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso do Sul",
        "location_state": "Ponta Pori",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.5496006",
        "longitude": "-55.70259857",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150821,
            "s_seats": 45577,
            "j_seats": 10567,
            "f_seats": 17550,
            "r_seats": 4272,
            "cargo_demand": 435554
        }
    },
    {
        "iata": "PMH",
        "icao": "KPMH",
        "airportName": "Greater Portsmouth Regional Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Portsmouth",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.84049988",
        "longitude": "-82.84729767",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5006",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3922,
            "s_seats": 2864,
            "j_seats": 1519,
            "f_seats": 79,
            "r_seats": 111,
            "cargo_demand": 43451
        }
    },
    {
        "iata": "PMI",
        "icao": "LEPA",
        "airportName": "Palma de Mallorca Airport",
        "location_country": "Spain",
        "location_city": "Balearic Islands",
        "location_state": "Palma de Mallorca",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "39.55170059",
        "longitude": "2.738810062",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "10728",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 312633,
            "s_seats": 80169,
            "j_seats": 138674,
            "f_seats": 56484,
            "r_seats": 10000,
            "cargo_demand": 27841437
        }
    },
    {
        "iata": "PMK",
        "icao": "YPAM",
        "airportName": "Palm Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Palm Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.75530052",
        "longitude": "146.5809937",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3730",
                "runway_width": "59",
                "runway_material": "COP"
            }
        ],
        "demand": {
            "y_seats": 2522,
            "s_seats": 1845,
            "j_seats": 1883,
            "f_seats": 365,
            "r_seats": 42,
            "cargo_demand": 10350
        }
    },
    {
        "iata": "PML",
        "icao": "PAAL",
        "airportName": "Port Moller Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Cold Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "56.00600052",
        "longitude": "-160.5610046",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 2828,
            "s_seats": 2878,
            "j_seats": 1316,
            "f_seats": 377,
            "r_seats": 66,
            "cargo_demand": 29828
        }
    },
    {
        "iata": "PMO",
        "icao": "LICJ",
        "airportName": "Falcone-Borsellino Airport",
        "location_country": "Italy",
        "location_city": "Sicily",
        "location_state": "Palermo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "38.175999",
        "longitude": "13.091",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6804",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "10912",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 361975,
            "s_seats": 91692,
            "j_seats": 37704,
            "f_seats": 55356,
            "r_seats": 10000,
            "cargo_demand": 20212332
        }
    },
    {
        "iata": "PMQ",
        "icao": "SAWP",
        "airportName": "Perito Moreno Airport",
        "location_country": "Argentina",
        "location_city": "Santa Cruz",
        "location_state": "Perito Moreno",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-46.537899",
        "longitude": "-70.978699",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2790",
                "runway_width": "130",
                "runway_material": "GRE"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5577",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4405",
                "runway_width": "100",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 87037,
            "s_seats": 49765,
            "j_seats": 20198,
            "f_seats": 19545,
            "r_seats": 9751,
            "cargo_demand": 296783
        }
    },
    {
        "iata": "PMR",
        "icao": "NZPM",
        "airportName": "Palmerston North Airport",
        "location_country": "New Zealand",
        "location_city": "Palmerston North",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-40.3205986",
        "longitude": "175.6170044",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "6240",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "1995",
                "runway_width": "197",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 57670,
            "s_seats": 34300,
            "j_seats": 17131,
            "f_seats": 6335,
            "r_seats": 7961,
            "cargo_demand": 372970
        }
    },
    {
        "iata": "PMS",
        "icao": "OSPR",
        "airportName": "Palmyra Airport",
        "location_country": "Syria",
        "location_city": "Palmyra (Tadmur)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.5574",
        "longitude": "38.316898",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9449",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70972,
            "s_seats": 26298,
            "j_seats": 8201,
            "f_seats": 8029,
            "r_seats": 8835,
            "cargo_demand": 116824
        }
    },
    {
        "iata": "PMV",
        "icao": "SVMG",
        "airportName": "Santiago Marino Caribbean International Airport",
        "location_country": "Venezuela",
        "location_city": "Porlamar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.91260338",
        "longitude": "-63.96659851",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10499",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "9958",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168002,
            "s_seats": 49686,
            "j_seats": 36608,
            "f_seats": 15041,
            "r_seats": 8126,
            "cargo_demand": 283991
        }
    },
    {
        "iata": "PMW",
        "icao": "SBPJ",
        "airportName": "Palmas-Brigadeiro Lysias Rodrigues Airport",
        "location_country": "Brazil",
        "location_city": "Tocantins",
        "location_state": "Palmas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-10.29150009",
        "longitude": "-48.35699844",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108950,
            "s_seats": 38994,
            "j_seats": 28914,
            "f_seats": 14563,
            "r_seats": 6507,
            "cargo_demand": 172200
        }
    },
    {
        "iata": "PMY",
        "icao": "SAVY",
        "airportName": "El Tehuelche Airport",
        "location_country": "Argentina",
        "location_city": "Chubut",
        "location_state": "Puerto Madryn",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-42.7592",
        "longitude": "-65.1027",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160447,
            "s_seats": 35091,
            "j_seats": 18794,
            "f_seats": 7290,
            "r_seats": 9221,
            "cargo_demand": 439010
        }
    },
    {
        "iata": "PMZ",
        "icao": "MRPM",
        "airportName": "Palmar Sur Airport",
        "location_country": "Costa Rica",
        "location_city": "Palmar Sur",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.951029778",
        "longitude": "-83.46859741",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4593",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163517,
            "s_seats": 26286,
            "j_seats": 36995,
            "f_seats": 11255,
            "r_seats": 4633,
            "cargo_demand": 495293
        }
    },
    {
        "iata": "PNA",
        "icao": "LEPP",
        "airportName": "Pamplona Airport",
        "location_country": "Spain",
        "location_city": "Navarre",
        "location_state": "Pamplona",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.77000046",
        "longitude": "-1.646329999",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "15/33",
                "runway_length": "7241",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59331,
            "s_seats": 27665,
            "j_seats": 30946,
            "f_seats": 11172,
            "r_seats": 4451,
            "cargo_demand": 71658
        }
    },
    {
        "iata": "PNB",
        "icao": "SBPN",
        "airportName": "Porto Nacional Airport",
        "location_country": "Brazil",
        "location_city": "Tocantins",
        "location_state": "Porto Nacional",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-10.719402",
        "longitude": "-48.3997",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "5577",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50666,
            "s_seats": 43401,
            "j_seats": 40744,
            "f_seats": 19645,
            "r_seats": 3199,
            "cargo_demand": 203273
        }
    },
    {
        "iata": "PNC",
        "icao": "KPNC",
        "airportName": "Ponca City Regional Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Ponca City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.73199844",
        "longitude": "-97.09980011",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7201",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 119874,
            "s_seats": 28116,
            "j_seats": 37687,
            "f_seats": 4407,
            "r_seats": 7104,
            "cargo_demand": 265568
        }
    },
    {
        "iata": "PNE",
        "icao": "KPNE",
        "airportName": "Northeast Philadelphia Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Philadelphia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.081902",
        "longitude": "-75.010597",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150330,
            "s_seats": 38118,
            "j_seats": 35022,
            "f_seats": 9914,
            "r_seats": 6358,
            "cargo_demand": 339961
        }
    },
    {
        "iata": "PNG",
        "icao": "SSPG",
        "airportName": "Santos Dumont Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Paranagua",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.5401001",
        "longitude": "-48.53120041",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "4774",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1894,
            "s_seats": 1930,
            "j_seats": 1820,
            "f_seats": 326,
            "r_seats": 160,
            "cargo_demand": 15146
        }
    },
    {
        "iata": "PNH",
        "icao": "VDPP",
        "airportName": "Phnom Penh International Airport",
        "location_country": "Cambodia",
        "location_city": "Phnom Penh",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "11.5466",
        "longitude": "104.844002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9843",
                "runway_width": "144",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171367,
            "s_seats": 94999,
            "j_seats": 53218,
            "f_seats": 46156,
            "r_seats": 10000,
            "cargo_demand": 17474992
        }
    },
    {
        "iata": "PNI",
        "icao": "PTPN",
        "airportName": "Pohnpei International Airport",
        "location_country": "Federated States of Micronesia",
        "location_city": "Pohnpei",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.985099792",
        "longitude": "158.2089996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108092,
            "s_seats": 41872,
            "j_seats": 28655,
            "f_seats": 2464,
            "r_seats": 3193,
            "cargo_demand": 99141
        }
    },
    {
        "iata": "PNK",
        "icao": "WIOO",
        "airportName": "Supadio Airport",
        "location_country": "Indonesia",
        "location_city": "Pontianak",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.150711",
        "longitude": "109.4039993",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7380",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169881,
            "s_seats": 49009,
            "j_seats": 7888,
            "f_seats": 18288,
            "r_seats": 4845,
            "cargo_demand": 378063
        }
    },
    {
        "iata": "PNL",
        "icao": "LICG",
        "airportName": "Pantelleria Airport",
        "location_country": "Italy",
        "location_city": "Sicily",
        "location_state": "Pantelleria",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.816502",
        "longitude": "11.9689",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4003",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "5495",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67988,
            "s_seats": 48309,
            "j_seats": 5474,
            "f_seats": 18241,
            "r_seats": 6298,
            "cargo_demand": 183084
        }
    },
    {
        "iata": "PNN",
        "icao": "KPNN",
        "airportName": "Princeton Municipal Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Princeton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "45.20069885",
        "longitude": "-67.56439972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3999",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4004",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3928,
            "s_seats": 1799,
            "j_seats": 1722,
            "f_seats": 122,
            "r_seats": 134,
            "cargo_demand": 57991
        }
    },
    {
        "iata": "PNP",
        "icao": "AYGR",
        "airportName": "Girua Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Popondetta",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.80453968",
        "longitude": "148.3090057",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5485",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187555,
            "s_seats": 48317,
            "j_seats": 12149,
            "f_seats": 19621,
            "r_seats": 7772,
            "cargo_demand": 92418
        }
    },
    {
        "iata": "PNQ",
        "icao": "VAPO",
        "airportName": "Pune Airport",
        "location_country": "India",
        "location_city": "Maharashtra",
        "location_state": "Pune",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.5821",
        "longitude": "73.919701",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8329",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5893",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152791,
            "s_seats": 38378,
            "j_seats": 30165,
            "f_seats": 13663,
            "r_seats": 8127,
            "cargo_demand": 220280
        }
    },
    {
        "iata": "PNR",
        "icao": "FCPP",
        "airportName": "Pointe Noire Airport",
        "location_country": "Republic of the Congo",
        "location_city": "Pointe-Noire",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-4.81603",
        "longitude": "11.8866",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50816,
            "s_seats": 26067,
            "j_seats": 51260,
            "f_seats": 6693,
            "r_seats": 9819,
            "cargo_demand": 416012
        }
    },
    {
        "iata": "PNS",
        "icao": "KPNS",
        "airportName": "Pensacola International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Pensacola",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.4734",
        "longitude": "-87.1866",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7004",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70886,
            "s_seats": 26231,
            "j_seats": 39739,
            "f_seats": 2383,
            "r_seats": 9890,
            "cargo_demand": 326944
        }
    },
    {
        "iata": "PNT",
        "icao": "SCNT",
        "airportName": "Teniente Julio Gallardo Airport",
        "location_country": "Chile",
        "location_city": "Puerto Natales",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-51.67150116",
        "longitude": "-72.52839661",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5786",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118999,
            "s_seats": 45781,
            "j_seats": 5438,
            "f_seats": 18730,
            "r_seats": 7010,
            "cargo_demand": 374293
        }
    },
    {
        "iata": "PNV",
        "icao": "EYPP",
        "airportName": "Panev\u0117\u017eys Air Base",
        "location_country": "Lithuania",
        "location_city": "Panev\u0117\u017eys",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.72940063",
        "longitude": "24.46080017",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6562",
                "runway_width": "131",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 141438,
            "s_seats": 36226,
            "j_seats": 12332,
            "f_seats": 2147,
            "r_seats": 5747,
            "cargo_demand": 484535
        }
    },
    {
        "iata": "PNX",
        "icao": "KGYI",
        "airportName": "North Texas Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Sherman / Denison",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.71409988",
        "longitude": "-96.67369843",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2300",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1154,
            "s_seats": 2022,
            "j_seats": 1504,
            "f_seats": 160,
            "r_seats": 110,
            "cargo_demand": 65076
        }
    },
    {
        "iata": "PNZ",
        "icao": "SBPL",
        "airportName": "Senador Nilo Coelho Airport",
        "location_country": "Brazil",
        "location_city": "Pernambuco",
        "location_state": "Petrolina",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.362409592",
        "longitude": "-40.56909943",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9055",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82917,
            "s_seats": 36005,
            "j_seats": 28859,
            "f_seats": 8927,
            "r_seats": 7510,
            "cargo_demand": 168440
        }
    },
    {
        "iata": "POA",
        "icao": "SBPA",
        "airportName": "Salgado Filho International Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Porto Alegre",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.994712",
        "longitude": "-51.166592",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6496",
                "runway_width": "138",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57381,
            "s_seats": 35253,
            "j_seats": 10338,
            "f_seats": 2819,
            "r_seats": 4632,
            "cargo_demand": 409543
        }
    },
    {
        "iata": "POB",
        "icao": "KPOB",
        "airportName": "Pope Field",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Fort Bragg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.17089844",
        "longitude": "-79.01450348",
        "region": "NA",
        "runway": [
            {
                "runway_name": "049/229",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "05/23",
                "runway_length": "7501",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 78352,
            "s_seats": 27389,
            "j_seats": 7850,
            "f_seats": 15558,
            "r_seats": 9882,
            "cargo_demand": 152447
        }
    },
    {
        "iata": "POC",
        "icao": "KPOC",
        "airportName": "Brackett Field",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "La Verne",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.09159851",
        "longitude": "-117.7819977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "3661",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "4839",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3214,
            "s_seats": 2056,
            "j_seats": 1529,
            "f_seats": 312,
            "r_seats": 73,
            "cargo_demand": 46223
        }
    },
    {
        "iata": "POE",
        "icao": "KPOE",
        "airportName": "Polk Army Airfield",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Fort Polk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.0447998",
        "longitude": "-93.1917038",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4109",
                "runway_width": "125",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87340,
            "s_seats": 38848,
            "j_seats": 27628,
            "f_seats": 7461,
            "r_seats": 4719,
            "cargo_demand": 69249
        }
    },
    {
        "iata": "POF",
        "icao": "KPOF",
        "airportName": "Poplar Bluff Municipal Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Poplar Bluff",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.77389908",
        "longitude": "-90.32489777",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5008",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 2359,
            "s_seats": 2323,
            "j_seats": 1628,
            "f_seats": 394,
            "r_seats": 40,
            "cargo_demand": 23529
        }
    },
    {
        "iata": "POG",
        "icao": "FOOG",
        "airportName": "Port-Gentil International Airport",
        "location_country": "Gabon",
        "location_city": "Port-Gentil",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.711739004",
        "longitude": "8.754380226",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6234",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159583,
            "s_seats": 26835,
            "j_seats": 33968,
            "f_seats": 2599,
            "r_seats": 8238,
            "cargo_demand": 102279
        }
    },
    {
        "iata": "POH",
        "icao": "KPOH",
        "airportName": "Pocahontas Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Pocahontas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.74280167",
        "longitude": "-94.64730072",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4100",
                "runway_width": "60",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1950",
                "runway_width": "135",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2090,
            "s_seats": 2170,
            "j_seats": 1407,
            "f_seats": 313,
            "r_seats": 22,
            "cargo_demand": 32695
        }
    },
    {
        "iata": "POI",
        "icao": "SLPO",
        "airportName": "Captain Nicolas Rojas Airport",
        "location_country": "Bolivia",
        "location_city": "Potosi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.543331",
        "longitude": "-65.723734",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9130",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59886,
            "s_seats": 30972,
            "j_seats": 29859,
            "f_seats": 2707,
            "r_seats": 9119,
            "cargo_demand": 119939
        }
    },
    {
        "iata": "POJ",
        "icao": "SNPD",
        "airportName": "Pedro Pereira dos Santos Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Patos de Minas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.67280006",
        "longitude": "-46.49119949",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "5577",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2155,
            "s_seats": 2576,
            "j_seats": 1967,
            "f_seats": 415,
            "r_seats": 138,
            "cargo_demand": 43998
        }
    },
    {
        "iata": "POL",
        "icao": "FQPB",
        "airportName": "Pemba Airport",
        "location_country": "Mozambique",
        "location_city": "Pemba",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.99176216",
        "longitude": "40.52401352",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2625",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5905",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92296,
            "s_seats": 31570,
            "j_seats": 44639,
            "f_seats": 17329,
            "r_seats": 8554,
            "cargo_demand": 442581
        }
    },
    {
        "iata": "POM",
        "icao": "AYPY",
        "airportName": "Jacksons International Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Port Moresby",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-9.443380356",
        "longitude": "147.2200012",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "9022",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "4625",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 498931,
            "s_seats": 88394,
            "j_seats": 28496,
            "f_seats": 43526,
            "r_seats": 10000,
            "cargo_demand": 27635232
        }
    },
    {
        "iata": "PON",
        "icao": "MGPP",
        "airportName": "Poptun Airport",
        "location_country": "Guatemala",
        "location_city": "Poptun",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "16.32579994",
        "longitude": "-89.41609955",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8000",
                "runway_width": "",
                "runway_material": "Dirt/Gravel"
            }
        ],
        "demand": {
            "y_seats": 3624,
            "s_seats": 1885,
            "j_seats": 1230,
            "f_seats": 361,
            "r_seats": 13,
            "cargo_demand": 49974
        }
    },
    {
        "iata": "POO",
        "icao": "SBPC",
        "airportName": "PoiCos de Caldas Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "PoiCos de Caldas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.842529",
        "longitude": "-46.569768",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "4970",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116538,
            "s_seats": 29611,
            "j_seats": 11603,
            "f_seats": 14267,
            "r_seats": 8376,
            "cargo_demand": 322246
        }
    },
    {
        "iata": "POP",
        "icao": "MDPP",
        "airportName": "Gregorio Luperon International Airport",
        "location_country": "Dominican Republic",
        "location_city": "San Felipe de Puerto Plata",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.75790024",
        "longitude": "-70.56999969",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10108",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171802,
            "s_seats": 28237,
            "j_seats": 42607,
            "f_seats": 2869,
            "r_seats": 8496,
            "cargo_demand": 263768
        }
    },
    {
        "iata": "POR",
        "icao": "EFPO",
        "airportName": "Pori Airport",
        "location_country": "Finland",
        "location_city": "Pori",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.4617",
        "longitude": "21.799999",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7713",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2628",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105978,
            "s_seats": 46886,
            "j_seats": 11711,
            "f_seats": 10485,
            "r_seats": 6399,
            "cargo_demand": 176519
        }
    },
    {
        "iata": "POS",
        "icao": "TTPP",
        "airportName": "Piarco International Airport",
        "location_country": "Trinidad and Tobago",
        "location_city": "Port of Spain",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.59539986",
        "longitude": "-61.33720016",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182198,
            "s_seats": 41671,
            "j_seats": 43525,
            "f_seats": 15603,
            "r_seats": 5427,
            "cargo_demand": 405411
        }
    },
    {
        "iata": "POT",
        "icao": "MKKJ",
        "airportName": "Ken Jones Aerodrome",
        "location_country": "Jamaica",
        "location_city": "Port Antonio",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.19879913",
        "longitude": "-76.53450012",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3412",
                "runway_width": "76",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50785,
            "s_seats": 46474,
            "j_seats": 49169,
            "f_seats": 14947,
            "r_seats": 6297,
            "cargo_demand": 237998
        }
    },
    {
        "iata": "POU",
        "icao": "KPOU",
        "airportName": "Dutchess County Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Poughkeepsie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.62659836",
        "longitude": "-73.88420105",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "1358",
                "runway_width": "100",
                "runway_material": "GRS"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2743",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 69947,
            "s_seats": 44262,
            "j_seats": 22484,
            "f_seats": 19369,
            "r_seats": 7617,
            "cargo_demand": 406474
        }
    },
    {
        "iata": "POW",
        "icao": "LJPZ",
        "airportName": "Portoro\u017e Airport",
        "location_country": "Slovenia",
        "location_city": "Portoro\u017e",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.4734",
        "longitude": "13.615",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3939",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111324,
            "s_seats": 39565,
            "j_seats": 37361,
            "f_seats": 19639,
            "r_seats": 5819,
            "cargo_demand": 431857
        }
    },
    {
        "iata": "POX",
        "icao": "LFPT",
        "airportName": "Pontoise - Cormeilles Aerodrome",
        "location_country": "France",
        "location_city": "Ile-de-France",
        "location_state": "Paris",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.096667",
        "longitude": "2.040833",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5541",
                "runway_width": "164",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5413",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56437,
            "s_seats": 36538,
            "j_seats": 10641,
            "f_seats": 9428,
            "r_seats": 5812,
            "cargo_demand": 305603
        }
    },
    {
        "iata": "POY",
        "icao": "KPOY",
        "airportName": "Powell Municipal Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Powell / Lovell",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.86719894",
        "longitude": "-108.7929993",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2176",
                "runway_width": "100",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6205",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2400",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1940,
            "s_seats": 2523,
            "j_seats": 1395,
            "f_seats": 347,
            "r_seats": 72,
            "cargo_demand": 22458
        }
    },
    {
        "iata": "POZ",
        "icao": "EPPO",
        "airportName": "Pozna\u0144- \u0141awica Airport",
        "location_country": "Poland",
        "location_city": "Pozna\u0144",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "52.42100143",
        "longitude": "16.82629967",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2296",
                "runway_width": "984",
                "runway_material": "grass"
            },
            {
                "runway_name": "10/28",
                "runway_length": "8215",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 408514,
            "s_seats": 84865,
            "j_seats": 141708,
            "f_seats": 64917,
            "r_seats": 10000,
            "cargo_demand": 26327713
        }
    },
    {
        "iata": "PPA",
        "icao": "KPPA",
        "airportName": "Perry Lefors Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Pampa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.61299896",
        "longitude": "-100.9960022",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4500",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5866",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1463,
            "s_seats": 2298,
            "j_seats": 1858,
            "f_seats": 377,
            "r_seats": 18,
            "cargo_demand": 62280
        }
    },
    {
        "iata": "PPB",
        "icao": "SBDN",
        "airportName": "Presidente Prudente Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Presidente Prudente",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.17510033",
        "longitude": "-51.42459869",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6923",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118163,
            "s_seats": 32521,
            "j_seats": 9467,
            "f_seats": 3414,
            "r_seats": 3668,
            "cargo_demand": 267755
        }
    },
    {
        "iata": "PPC",
        "icao": "PAPR",
        "airportName": "Prospect Creek Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Prospect Creek",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "66.81410217",
        "longitude": "-150.6439972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4968",
                "runway_width": "150",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 3946,
            "s_seats": 2676,
            "j_seats": 1322,
            "f_seats": 309,
            "r_seats": 118,
            "cargo_demand": 33545
        }
    },
    {
        "iata": "PPF",
        "icao": "KPPF",
        "airportName": "Tri-City Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Parsons",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.32989883",
        "longitude": "-95.5062027",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4007",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5687",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 3262,
            "s_seats": 2913,
            "j_seats": 1595,
            "f_seats": 263,
            "r_seats": 97,
            "cargo_demand": 27696
        }
    },
    {
        "iata": "PPG",
        "icao": "NSTU",
        "airportName": "Pago Pago International Airport",
        "location_country": "American Samoa",
        "location_city": "Pago Pago",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.33100033",
        "longitude": "-170.7100067",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3800",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64376,
            "s_seats": 30339,
            "j_seats": 9457,
            "f_seats": 9807,
            "r_seats": 4285,
            "cargo_demand": 178962
        }
    },
    {
        "iata": "PPI",
        "icao": "YPIR",
        "airportName": "Port Pirie Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Port Pirie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.23889923",
        "longitude": "137.9949951",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2205",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3422",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3507",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 188099,
            "s_seats": 38940,
            "j_seats": 8038,
            "f_seats": 9363,
            "r_seats": 9831,
            "cargo_demand": 93861
        }
    },
    {
        "iata": "PPK",
        "icao": "UACP",
        "airportName": "Petropavl Airport",
        "location_country": "Kazakhstan",
        "location_city": "Petropavl (Petropavlovsk)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.774702",
        "longitude": "69.183273",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8190",
                "runway_width": "128",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149545,
            "s_seats": 39191,
            "j_seats": 15444,
            "f_seats": 3436,
            "r_seats": 7917,
            "cargo_demand": 256579
        }
    },
    {
        "iata": "PPL",
        "icao": "VNPL",
        "airportName": "Phaplu Airport",
        "location_country": "Nepal",
        "location_city": "Phaphlu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.517787",
        "longitude": "86.584454",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2201",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3347,
            "s_seats": 1864,
            "j_seats": 1878,
            "f_seats": 64,
            "r_seats": 169,
            "cargo_demand": 70888
        }
    },
    {
        "iata": "PPM",
        "icao": "KPMP",
        "airportName": "Pompano Beach Airpark",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Pompano Beach",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "26.24710083",
        "longitude": "-80.11109924",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3502",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4418",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1270,
            "s_seats": 2946,
            "j_seats": 1321,
            "f_seats": 429,
            "r_seats": 95,
            "cargo_demand": 31401
        }
    },
    {
        "iata": "PPN",
        "icao": "SKPP",
        "airportName": "Guillermo Leon Valencia Airport",
        "location_country": "Colombia",
        "location_city": "Popayan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.4544",
        "longitude": "-76.6093",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6266",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127268,
            "s_seats": 25005,
            "j_seats": 51818,
            "f_seats": 14397,
            "r_seats": 8300,
            "cargo_demand": 392286
        }
    },
    {
        "iata": "PPP",
        "icao": "YBPN",
        "airportName": "Whitsunday Coast Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Proserpine",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.49500084",
        "longitude": "148.552002",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6801",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82744,
            "s_seats": 40241,
            "j_seats": 46982,
            "f_seats": 17233,
            "r_seats": 6677,
            "cargo_demand": 191542
        }
    },
    {
        "iata": "PPQ",
        "icao": "NZPP",
        "airportName": "Kapiti Coast Airport",
        "location_country": "New Zealand",
        "location_city": "Paraparaumu Beach",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-40.90470123",
        "longitude": "174.9889984",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11L/29R",
                "runway_length": "3366",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "1345",
                "runway_width": "295",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "2001",
                "runway_width": "295",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "4429",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159693,
            "s_seats": 33340,
            "j_seats": 7456,
            "f_seats": 15399,
            "r_seats": 4469,
            "cargo_demand": 188864
        }
    },
    {
        "iata": "PPR",
        "icao": "WIDE",
        "airportName": "Tuanku Tambusai Airport",
        "location_country": "Indonesia",
        "location_city": "Pasir Pangaraian (Pasir Pangarayan)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.84543103",
        "longitude": "100.3700027",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4240",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1666,
            "s_seats": 2735,
            "j_seats": 1539,
            "f_seats": 2,
            "r_seats": 114,
            "cargo_demand": 19776
        }
    },
    {
        "iata": "PPS",
        "icao": "RPVP",
        "airportName": "Puerto Princesa International Airport",
        "location_country": "Philippines",
        "location_city": "Puerto Princesa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.742119789",
        "longitude": "118.7590027",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 192796,
            "s_seats": 29205,
            "j_seats": 11607,
            "f_seats": 16870,
            "r_seats": 4316,
            "cargo_demand": 61503
        }
    },
    {
        "iata": "PPT",
        "icao": "NTAA",
        "airportName": "Fa'a'\u0101 International Airport",
        "location_country": "French Polynesia",
        "location_city": "Tahiti",
        "location_state": "Papeete",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-17.553699",
        "longitude": "-149.606995",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "11360",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 455794,
            "s_seats": 90236,
            "j_seats": 124252,
            "f_seats": 67517,
            "r_seats": 10000,
            "cargo_demand": 9391988
        }
    },
    {
        "iata": "PPW",
        "icao": "EGEP",
        "airportName": "Papa Westray Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Papa Westray",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "59.35169983",
        "longitude": "-2.900279999",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "1729",
                "runway_width": "59",
                "runway_material": "Graded Hardcore"
            },
            {
                "runway_name": "07/25",
                "runway_length": "1096",
                "runway_width": "59",
                "runway_material": "Grass/Graded Hardcore"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1125",
                "runway_width": "101",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3061,
            "s_seats": 2466,
            "j_seats": 1285,
            "f_seats": 301,
            "r_seats": 142,
            "cargo_demand": 82075
        }
    },
    {
        "iata": "PPY",
        "icao": "SNZA",
        "airportName": "Pouso Alegre Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Pouso Alegre",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.2892",
        "longitude": "-45.919102",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "4199",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2985,
            "s_seats": 2321,
            "j_seats": 1328,
            "f_seats": 306,
            "r_seats": 49,
            "cargo_demand": 60587
        }
    },
    {
        "iata": "PQC",
        "icao": "VVPQ",
        "airportName": "Phu Quoc International Airport",
        "location_country": "Vietnam",
        "location_city": "Phu Quoc Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.1698",
        "longitude": "103.9931",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9843",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73276,
            "s_seats": 34518,
            "j_seats": 46675,
            "f_seats": 13538,
            "r_seats": 8901,
            "cargo_demand": 438198
        }
    },
    {
        "iata": "PQI",
        "icao": "KPQI",
        "airportName": "Northern Maine Regional Airport at Presque Isle",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Presque Isle",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.688999",
        "longitude": "-68.0448",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7440",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5994",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178198,
            "s_seats": 31452,
            "j_seats": 5177,
            "f_seats": 3591,
            "r_seats": 5156,
            "cargo_demand": 127237
        }
    },
    {
        "iata": "PQM",
        "icao": "MMPQ",
        "airportName": "Palenque International Airport",
        "location_country": "Mexico",
        "location_city": "Chiapas",
        "location_state": "Palenque",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "17.533153",
        "longitude": "-92.015484",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4888",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3970,
            "s_seats": 2617,
            "j_seats": 1751,
            "f_seats": 309,
            "r_seats": 89,
            "cargo_demand": 26459
        }
    },
    {
        "iata": "PQQ",
        "icao": "YPMQ",
        "airportName": "Port Macquarie Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Port Macquarie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.43580055",
        "longitude": "152.8630066",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5203",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2283",
                "runway_width": "60",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 92060,
            "s_seats": 28638,
            "j_seats": 55100,
            "f_seats": 12081,
            "r_seats": 9398,
            "cargo_demand": 323138
        }
    },
    {
        "iata": "PRA",
        "icao": "SAAP",
        "airportName": "General Justo Jose de Urquiza Airport",
        "location_country": "Argentina",
        "location_city": "Entre Rios",
        "location_state": "Parana",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.7948",
        "longitude": "-60.4804",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177218,
            "s_seats": 31489,
            "j_seats": 57450,
            "f_seats": 5072,
            "r_seats": 7114,
            "cargo_demand": 473037
        }
    },
    {
        "iata": "PRB",
        "icao": "KPRB",
        "airportName": "Paso Robles Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Paso Robles",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.67290115",
        "longitude": "-120.6269989",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6009",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4700",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148947,
            "s_seats": 42330,
            "j_seats": 28579,
            "f_seats": 3939,
            "r_seats": 7848,
            "cargo_demand": 213058
        }
    },
    {
        "iata": "PRC",
        "icao": "KPRC",
        "airportName": "Ernest A. Love Field",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Prescott",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "34.654499",
        "longitude": "-112.419998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "4846",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "7550",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4408",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139501,
            "s_seats": 87924,
            "j_seats": 110100,
            "f_seats": 36066,
            "r_seats": 10000,
            "cargo_demand": 25962887
        }
    },
    {
        "iata": "PRD",
        "icao": "YPDO",
        "airportName": "Pardoo Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Pardoo Station",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.11750031",
        "longitude": "119.5899963",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "L"
            }
        ],
        "demand": {
            "y_seats": 3688,
            "s_seats": 2897,
            "j_seats": 1580,
            "f_seats": 499,
            "r_seats": 104,
            "cargo_demand": 11492
        }
    },
    {
        "iata": "PRG",
        "icao": "LKPR",
        "airportName": "Vaclav Havel Airport Prague",
        "location_country": "Czech Republic",
        "location_city": "Prague",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "50.1008",
        "longitude": "14.26",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6955",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "12189",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "12/30",
                "runway_length": "10663",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 322069,
            "s_seats": 70013,
            "j_seats": 129844,
            "f_seats": 34870,
            "r_seats": 10000,
            "cargo_demand": 21107744
        }
    },
    {
        "iata": "PRH",
        "icao": "VTCP",
        "airportName": "Phrae Airport",
        "location_country": "Thailand",
        "location_city": "Phrae",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.13220024",
        "longitude": "100.1650009",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197562,
            "s_seats": 49826,
            "j_seats": 13512,
            "f_seats": 19945,
            "r_seats": 8906,
            "cargo_demand": 159021
        }
    },
    {
        "iata": "PRI",
        "icao": "FSPP",
        "airportName": "Praslin Island Airport",
        "location_country": "Seychelles",
        "location_city": "Praslin Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-4.31929",
        "longitude": "55.691399",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4318",
                "runway_width": "104",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160956,
            "s_seats": 43657,
            "j_seats": 12665,
            "f_seats": 4787,
            "r_seats": 3189,
            "cargo_demand": 201501
        }
    },
    {
        "iata": "PRM",
        "icao": "LPPM",
        "airportName": "Portimio Airport",
        "location_country": "Portugal",
        "location_city": "Portimio",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.1493",
        "longitude": "-8.58396",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2822",
                "runway_width": "89",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191003,
            "s_seats": 41339,
            "j_seats": 59683,
            "f_seats": 8920,
            "r_seats": 6189,
            "cargo_demand": 372410
        }
    },
    {
        "iata": "PRN",
        "icao": "BKPR",
        "airportName": "Pristina International Airport Adem Jashari",
        "location_country": "Kosovo",
        "location_city": "Pristina",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "42.5728",
        "longitude": "21.035801",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8165",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 291135,
            "s_seats": 81851,
            "j_seats": 66891,
            "f_seats": 65599,
            "r_seats": 10000,
            "cargo_demand": 13847092
        }
    },
    {
        "iata": "PRO",
        "icao": "KPRO",
        "airportName": "Perry Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Perry",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.82799911",
        "longitude": "-94.15989685",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2280",
                "runway_width": "235",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2342,
            "s_seats": 2998,
            "j_seats": 1981,
            "f_seats": 421,
            "r_seats": 128,
            "cargo_demand": 39927
        }
    },
    {
        "iata": "PRP",
        "icao": "LFKO",
        "airportName": "Propriano Airport",
        "location_country": "France",
        "location_city": "Corsica",
        "location_state": "Propriano",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.66059875",
        "longitude": "8.889749527",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1070,
            "s_seats": 2417,
            "j_seats": 1071,
            "f_seats": 343,
            "r_seats": 66,
            "cargo_demand": 84004
        }
    },
    {
        "iata": "PRS",
        "icao": "AGGP",
        "airportName": "Parasi Airport",
        "location_country": "Solomon Islands",
        "location_city": "Parasi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.641670227",
        "longitude": "161.4250031",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2156",
                "runway_width": "79",
                "runway_material": "Coral"
            }
        ],
        "demand": {
            "y_seats": 1786,
            "s_seats": 2167,
            "j_seats": 1453,
            "f_seats": 210,
            "r_seats": 123,
            "cargo_demand": 16421
        }
    },
    {
        "iata": "PRU",
        "icao": "VYPY",
        "airportName": "Pyay Airport",
        "location_country": "Myanmar",
        "location_city": "Pyay (Prome)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.82449913",
        "longitude": "95.26599884",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4502",
                "runway_width": "131",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 2078,
            "s_seats": 2359,
            "j_seats": 1267,
            "f_seats": 290,
            "r_seats": 105,
            "cargo_demand": 47503
        }
    },
    {
        "iata": "PRV",
        "icao": "LKPO",
        "airportName": "P\u0159erov Airport",
        "location_country": "Czech Republic",
        "location_city": "P\u0159erov",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.42580032",
        "longitude": "17.40469933",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8123",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 153840,
            "s_seats": 49331,
            "j_seats": 48231,
            "f_seats": 7903,
            "r_seats": 3715,
            "cargo_demand": 283897
        }
    },
    {
        "iata": "PRX",
        "icao": "KPRX",
        "airportName": "Cox Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Paris",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.63660049",
        "longitude": "-95.45079803",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4624",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4624",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6002",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148691,
            "s_seats": 28208,
            "j_seats": 31789,
            "f_seats": 5415,
            "r_seats": 3756,
            "cargo_demand": 467932
        }
    },
    {
        "iata": "PRY",
        "icao": "FAWB",
        "airportName": "Wonderboom Airport",
        "location_country": "South Africa",
        "location_city": "Pretoria",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.6539",
        "longitude": "28.224199",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4200",
                "runway_width": "72",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5998",
                "runway_width": "98",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 134634,
            "s_seats": 25038,
            "j_seats": 34027,
            "f_seats": 16450,
            "r_seats": 9415,
            "cargo_demand": 85580
        }
    },
    {
        "iata": "PSA",
        "icao": "LIRP",
        "airportName": "Pisa International Airport",
        "location_country": "Italy",
        "location_city": "Tuscany",
        "location_state": "Pisa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.683899",
        "longitude": "10.3927",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "9160",
                "runway_width": "141",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "9820",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 402225,
            "s_seats": 96341,
            "j_seats": 33461,
            "f_seats": 42208,
            "r_seats": 10000,
            "cargo_demand": 25228362
        }
    },
    {
        "iata": "PSB",
        "icao": "KPSB",
        "airportName": "Mid-State Regional Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Centre County",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.88439941",
        "longitude": "-78.08730316",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5006",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5711",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2762,
            "s_seats": 2057,
            "j_seats": 1044,
            "f_seats": 165,
            "r_seats": 145,
            "cargo_demand": 78625
        }
    },
    {
        "iata": "PSC",
        "icao": "KPSC",
        "airportName": "Tri-Cities Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Pasco",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.26470184",
        "longitude": "-119.1190033",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "7711",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "4423",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7703",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141214,
            "s_seats": 32710,
            "j_seats": 20873,
            "f_seats": 16547,
            "r_seats": 5964,
            "cargo_demand": 199230
        }
    },
    {
        "iata": "PSD",
        "icao": "HEPS",
        "airportName": "Port Said Airport",
        "location_country": "Egypt",
        "location_city": "Port Said",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.27939987",
        "longitude": "32.24000168",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7707",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187871,
            "s_seats": 25017,
            "j_seats": 21469,
            "f_seats": 9905,
            "r_seats": 4268,
            "cargo_demand": 221117
        }
    },
    {
        "iata": "PSE",
        "icao": "TJPS",
        "airportName": "Mercedita Airport",
        "location_country": "Puerto Rico",
        "location_city": "Ponce",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.00830078",
        "longitude": "-66.56300354",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6904",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199177,
            "s_seats": 38406,
            "j_seats": 18033,
            "f_seats": 8419,
            "r_seats": 7797,
            "cargo_demand": 48185
        }
    },
    {
        "iata": "PSF",
        "icao": "KPSF",
        "airportName": "Pittsfield Municipal Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Pittsfield",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.4268",
        "longitude": "-73.2929",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3496",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3385,
            "s_seats": 1810,
            "j_seats": 1639,
            "f_seats": 179,
            "r_seats": 78,
            "cargo_demand": 63454
        }
    },
    {
        "iata": "PSG",
        "icao": "PAPG",
        "airportName": "Petersburg James A. Johnson Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Petersburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.80170059",
        "longitude": "-132.9450073",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137519,
            "s_seats": 38618,
            "j_seats": 28677,
            "f_seats": 14027,
            "r_seats": 7933,
            "cargo_demand": 429207
        }
    },
    {
        "iata": "PSH",
        "icao": "EDXO",
        "airportName": "Sankt Peter-Ording Airport",
        "location_country": "Germany",
        "location_city": "Schleswig-Holstein",
        "location_state": "Sankt Peter-Ording",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "54.30888748",
        "longitude": "8.686944008",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2198",
                "runway_width": "98",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 3940,
            "s_seats": 1964,
            "j_seats": 1032,
            "f_seats": 4,
            "r_seats": 109,
            "cargo_demand": 6289
        }
    },
    {
        "iata": "PSI",
        "icao": "OPPI",
        "airportName": "Pasni Airport",
        "location_country": "Pakistan",
        "location_city": "Pasni",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.29050064",
        "longitude": "63.3451004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8999",
                "runway_width": "151",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 96152,
            "s_seats": 31950,
            "j_seats": 9645,
            "f_seats": 8575,
            "r_seats": 5102,
            "cargo_demand": 317622
        }
    },
    {
        "iata": "PSJ",
        "icao": "WAMP",
        "airportName": "Kasiguncu Airport",
        "location_country": "Indonesia",
        "location_city": "Poso",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.416749954",
        "longitude": "120.6579971",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3650",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168114,
            "s_seats": 43209,
            "j_seats": 56641,
            "f_seats": 15886,
            "r_seats": 7457,
            "cargo_demand": 161965
        }
    },
    {
        "iata": "PSK",
        "icao": "KPSK",
        "airportName": "New River Valley Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Dublin",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.13729858",
        "longitude": "-80.67849731",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6201",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1480,
            "s_seats": 1901,
            "j_seats": 1827,
            "f_seats": 480,
            "r_seats": 196,
            "cargo_demand": 21448
        }
    },
    {
        "iata": "PSL",
        "icao": "EGPT",
        "airportName": "Perth Airport (Scone Airport)",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Perth",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "56.43920135",
        "longitude": "-3.372220039",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2798",
                "runway_width": "88",
                "runway_material": "Asphalt"
            },
            {
                "runway_name": "09/27",
                "runway_length": "1998",
                "runway_width": "72",
                "runway_material": "Asphalt"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2034",
                "runway_width": "118",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1723,
            "s_seats": 1729,
            "j_seats": 1576,
            "f_seats": 382,
            "r_seats": 60,
            "cargo_demand": 7730
        }
    },
    {
        "iata": "PSM",
        "icao": "KPSM",
        "airportName": "Portsmouth International Airport at Pease",
        "location_country": "United States",
        "location_city": "New Hampshire",
        "location_state": "Portsmouth",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.07789993",
        "longitude": "-70.82330322",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "11321",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 82312,
            "s_seats": 47116,
            "j_seats": 23358,
            "f_seats": 13993,
            "r_seats": 7685,
            "cargo_demand": 458902
        }
    },
    {
        "iata": "PSN",
        "icao": "KPSN",
        "airportName": "Palestine Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Palestine",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.77969933",
        "longitude": "-95.70629883",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4003",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5005",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3763,
            "s_seats": 1825,
            "j_seats": 1105,
            "f_seats": 312,
            "r_seats": 175,
            "cargo_demand": 42175
        }
    },
    {
        "iata": "PSO",
        "icao": "SKPS",
        "airportName": "Antonio Narino Airport",
        "location_country": "Colombia",
        "location_city": "Pasto",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.39625",
        "longitude": "-77.2915",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7585",
                "runway_width": "130",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196573,
            "s_seats": 30916,
            "j_seats": 39966,
            "f_seats": 8723,
            "r_seats": 7292,
            "cargo_demand": 77963
        }
    },
    {
        "iata": "PSP",
        "icao": "KPSP",
        "airportName": "Palm Springs International Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Palm Springs",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.8297",
        "longitude": "-116.507004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "4952",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140082,
            "s_seats": 35710,
            "j_seats": 10016,
            "f_seats": 16348,
            "r_seats": 8174,
            "cargo_demand": 125931
        }
    },
    {
        "iata": "PSR",
        "icao": "LIBP",
        "airportName": "Abruzzo Airport",
        "location_country": "Italy",
        "location_city": "Abruzzo",
        "location_state": "Pescara",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.431702",
        "longitude": "14.1811",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7933",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62197,
            "s_seats": 40991,
            "j_seats": 15631,
            "f_seats": 4354,
            "r_seats": 5600,
            "cargo_demand": 315868
        }
    },
    {
        "iata": "PSS",
        "icao": "SARP",
        "airportName": "Libertador General Jose de San Martin Airport",
        "location_country": "Argentina",
        "location_city": "Misiones",
        "location_state": "Posadas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.3858",
        "longitude": "-55.9707",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "7218",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86095,
            "s_seats": 32467,
            "j_seats": 8834,
            "f_seats": 1754,
            "r_seats": 6238,
            "cargo_demand": 433011
        }
    },
    {
        "iata": "PSU",
        "icao": "WIOP",
        "airportName": "Pangsuma Airport",
        "location_country": "Indonesia",
        "location_city": "Putussibau",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.835578024",
        "longitude": "112.9369965",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3294",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67141,
            "s_seats": 32502,
            "j_seats": 32743,
            "f_seats": 1677,
            "r_seats": 8154,
            "cargo_demand": 396021
        }
    },
    {
        "iata": "PSW",
        "icao": "SNOS",
        "airportName": "Passos Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Passos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.73220062",
        "longitude": "-46.66180038",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4921",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1142,
            "s_seats": 2574,
            "j_seats": 1757,
            "f_seats": 473,
            "r_seats": 169,
            "cargo_demand": 28318
        }
    },
    {
        "iata": "PSX",
        "icao": "KPSX",
        "airportName": "Palacios Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Palacios",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.72750092",
        "longitude": "-96.25099945",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3708,
            "s_seats": 2877,
            "j_seats": 1047,
            "f_seats": 8,
            "r_seats": 95,
            "cargo_demand": 93693
        }
    },
    {
        "iata": "PSY",
        "icao": "SFAL",
        "airportName": "Port Stanley Airport",
        "location_country": "British Overseas Territory of Falkland Islands",
        "location_city": "Stanley",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-51.68569946",
        "longitude": "-57.77759933",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3013",
                "runway_width": "63",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1110",
                "runway_width": "42",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64119,
            "s_seats": 31804,
            "j_seats": 35194,
            "f_seats": 13869,
            "r_seats": 4374,
            "cargo_demand": 260013
        }
    },
    {
        "iata": "PSZ",
        "icao": "SLPS",
        "airportName": "Puerto Suarez International Airport",
        "location_country": "Bolivia",
        "location_city": "Puerto Suarez",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-18.975301",
        "longitude": "-57.820599",
        "region": "SA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6562",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72271,
            "s_seats": 25493,
            "j_seats": 23481,
            "f_seats": 13259,
            "r_seats": 3107,
            "cargo_demand": 465139
        }
    },
    {
        "iata": "PTB",
        "icao": "KPTB",
        "airportName": "Dinwiddie County Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Petersburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.18379974",
        "longitude": "-77.50740051",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3061",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1158,
            "s_seats": 2062,
            "j_seats": 1769,
            "f_seats": 2,
            "r_seats": 32,
            "cargo_demand": 58524
        }
    },
    {
        "iata": "PTG",
        "icao": "FAPP",
        "airportName": "Polokwane International Airport",
        "location_country": "South Africa",
        "location_city": "Polokwane",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.845269",
        "longitude": "29.458615",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8400",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "7612",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52344,
            "s_seats": 49733,
            "j_seats": 49668,
            "f_seats": 14404,
            "r_seats": 9086,
            "cargo_demand": 311100
        }
    },
    {
        "iata": "PTH",
        "icao": "PAPH",
        "airportName": "Port Heiden Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Port Heiden",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.959359",
        "longitude": "-158.62106",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "GVL"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 155490,
            "s_seats": 32455,
            "j_seats": 19957,
            "f_seats": 12606,
            "r_seats": 5532,
            "cargo_demand": 407024
        }
    },
    {
        "iata": "PTJ",
        "icao": "YPOD",
        "airportName": "Portland Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Portland",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.31809998",
        "longitude": "141.470993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5302",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3871",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 74091,
            "s_seats": 39848,
            "j_seats": 19333,
            "f_seats": 17523,
            "r_seats": 9375,
            "cargo_demand": 232121
        }
    },
    {
        "iata": "PTK",
        "icao": "KPTK",
        "airportName": "Oakland County International Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Pontiac",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.66550064",
        "longitude": "-83.42009735",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2451",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "6200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1856",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179498,
            "s_seats": 39240,
            "j_seats": 22316,
            "f_seats": 12682,
            "r_seats": 6478,
            "cargo_demand": 401409
        }
    },
    {
        "iata": "PTM",
        "icao": "SVPT",
        "airportName": "Palmarito Airport",
        "location_country": "Venezuela",
        "location_city": "Palmarito",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.566669941",
        "longitude": "-70.1832962",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3775",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54223,
            "s_seats": 30802,
            "j_seats": 25557,
            "f_seats": 7727,
            "r_seats": 8476,
            "cargo_demand": 444244
        }
    },
    {
        "iata": "PTN",
        "icao": "KPTN",
        "airportName": "Harry P. Williams Memorial Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Patterson",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "29.70949936",
        "longitude": "-91.33899689",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04W/22W",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "WATER"
            },
            {
                "runway_name": "06/24",
                "runway_length": "5399",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3614,
            "s_seats": 1899,
            "j_seats": 1654,
            "f_seats": 460,
            "r_seats": 26,
            "cargo_demand": 93054
        }
    },
    {
        "iata": "PTO",
        "icao": "SSPB",
        "airportName": "Juvenal Loureiro Cardoso Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Pato Branco",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.217184",
        "longitude": "-52.694463",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1870,
            "s_seats": 2150,
            "j_seats": 1824,
            "f_seats": 39,
            "r_seats": 191,
            "cargo_demand": 33943
        }
    },
    {
        "iata": "PTP",
        "icao": "TFFR",
        "airportName": "Pointe-i\u00a0-Pitre International Airport",
        "location_country": "Guadeloupe",
        "location_city": "Pointe-i\u00a0-Pitre",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "16.265301",
        "longitude": "-61.531799",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "11499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 353868,
            "s_seats": 73138,
            "j_seats": 145240,
            "f_seats": 54183,
            "r_seats": 10000,
            "cargo_demand": 12009206
        }
    },
    {
        "iata": "PTQ",
        "icao": "SNMZ",
        "airportName": "Porto de Moz Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Porto de Moz",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.741449952",
        "longitude": "-52.23609924",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "4298",
                "runway_width": "92",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3655,
            "s_seats": 1572,
            "j_seats": 1301,
            "f_seats": 92,
            "r_seats": 80,
            "cargo_demand": 42742
        }
    },
    {
        "iata": "PTS",
        "icao": "KPTS",
        "airportName": "Atkinson Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Pittsburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.44950104",
        "longitude": "-94.73110199",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3008",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1359,
            "s_seats": 1671,
            "j_seats": 1710,
            "f_seats": 275,
            "r_seats": 71,
            "cargo_demand": 35773
        }
    },
    {
        "iata": "PTT",
        "icao": "KPTT",
        "airportName": "Pratt Regional Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Pratt",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.70159912",
        "longitude": "-98.74690247",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2639,
            "s_seats": 1707,
            "j_seats": 1305,
            "f_seats": 395,
            "r_seats": 194,
            "cargo_demand": 65489
        }
    },
    {
        "iata": "PTU",
        "icao": "PAPM",
        "airportName": "Platinum Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Platinum",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "59.01139832",
        "longitude": "-161.8200073",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "1924",
                "runway_width": "50",
                "runway_material": "GVL"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3304",
                "runway_width": "50",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 132201,
            "s_seats": 32892,
            "j_seats": 51299,
            "f_seats": 2429,
            "r_seats": 4886,
            "cargo_demand": 452974
        }
    },
    {
        "iata": "PTV",
        "icao": "KPTV",
        "airportName": "Porterville Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Porterville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.02959824",
        "longitude": "-119.0630035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5908",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3295,
            "s_seats": 2406,
            "j_seats": 1385,
            "f_seats": 314,
            "r_seats": 158,
            "cargo_demand": 13788
        }
    },
    {
        "iata": "PTW",
        "icao": "KPTW",
        "airportName": "Heritage Field",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Pottstown",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.239601",
        "longitude": "-75.556702",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3371",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2153,
            "s_seats": 1593,
            "j_seats": 1735,
            "f_seats": 179,
            "r_seats": 8,
            "cargo_demand": 95694
        }
    },
    {
        "iata": "PTX",
        "icao": "SKPI",
        "airportName": "Contador Airport",
        "location_country": "Colombia",
        "location_city": "Pitalito",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.85777",
        "longitude": "-76.0857",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4912",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171641,
            "s_seats": 40928,
            "j_seats": 42618,
            "f_seats": 4254,
            "r_seats": 3553,
            "cargo_demand": 245924
        }
    },
    {
        "iata": "PTY",
        "icao": "MPTO",
        "airportName": "Tocumen International Airport",
        "location_country": "Panama",
        "location_city": "Panama City",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "9.071359634",
        "longitude": "-79.38349915",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "8800",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "10006",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 128962,
            "s_seats": 82423,
            "j_seats": 92018,
            "f_seats": 65082,
            "r_seats": 10000,
            "cargo_demand": 8289612
        }
    },
    {
        "iata": "PUB",
        "icao": "KPUB",
        "airportName": "Pueblo Memorial Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Pueblo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.289101",
        "longitude": "-104.497002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "10496",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "4073",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8268",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "8308",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192543,
            "s_seats": 26592,
            "j_seats": 13518,
            "f_seats": 17577,
            "r_seats": 4288,
            "cargo_demand": 187038
        }
    },
    {
        "iata": "PUC",
        "icao": "KPUC",
        "airportName": "Carbon County Regional Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Price",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "39.61389923",
        "longitude": "-110.7509995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3631",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4520",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8319",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2988,
            "s_seats": 1552,
            "j_seats": 1572,
            "f_seats": 156,
            "r_seats": 115,
            "cargo_demand": 32541
        }
    },
    {
        "iata": "PUD",
        "icao": "SAWD",
        "airportName": "Puerto Deseado Airport",
        "location_country": "Argentina",
        "location_city": "Santa Cruz",
        "location_state": "Puerto Deseado",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-47.7353",
        "longitude": "-65.9041",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132005,
            "s_seats": 32181,
            "j_seats": 5530,
            "f_seats": 12913,
            "r_seats": 5099,
            "cargo_demand": 213488
        }
    },
    {
        "iata": "PUF",
        "icao": "LFBP",
        "airportName": "Pau Pyrenees Airport",
        "location_country": "France",
        "location_city": "Aquitaine",
        "location_state": "Pau",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.38000107",
        "longitude": "-0.41861099",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142277,
            "s_seats": 38803,
            "j_seats": 28299,
            "f_seats": 14818,
            "r_seats": 5834,
            "cargo_demand": 266474
        }
    },
    {
        "iata": "PUG",
        "icao": "YPAG",
        "airportName": "Port Augusta Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Port Augusta",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-32.50690079",
        "longitude": "137.7169952",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5413",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52144,
            "s_seats": 25481,
            "j_seats": 57496,
            "f_seats": 3934,
            "r_seats": 8297,
            "cargo_demand": 268540
        }
    },
    {
        "iata": "PUJ",
        "icao": "MDPC",
        "airportName": "Punta Cana International Airport",
        "location_country": "Dominican Republic",
        "location_city": "Punta Cana",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "18.56739998",
        "longitude": "-68.36340332",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 303960,
            "s_seats": 99863,
            "j_seats": 91154,
            "f_seats": 42251,
            "r_seats": 10000,
            "cargo_demand": 13628248
        }
    },
    {
        "iata": "PUQ",
        "icao": "SCCI",
        "airportName": "Presidente Carlos Ibanez del Campo International Airport",
        "location_country": "Chile",
        "location_city": "Punta Arenas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-53.002602",
        "longitude": "-70.854599",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5504",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "9154",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7875",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57337,
            "s_seats": 44336,
            "j_seats": 35562,
            "f_seats": 4364,
            "r_seats": 8654,
            "cargo_demand": 100710
        }
    },
    {
        "iata": "PUS",
        "icao": "RKPK",
        "airportName": "Gimhae International Airport",
        "location_country": "South Korea",
        "location_city": "Busan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "35.17950058",
        "longitude": "128.9380035",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "9007",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "10499",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 442711,
            "s_seats": 77812,
            "j_seats": 65334,
            "f_seats": 63273,
            "r_seats": 10000,
            "cargo_demand": 19231483
        }
    },
    {
        "iata": "PUT",
        "icao": "VOPN",
        "airportName": "Sri Sathya Sai Airport",
        "location_country": "India",
        "location_city": "Andhra Pradesh",
        "location_state": "Puttaparthi",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.14929962",
        "longitude": "77.79109955",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7315",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150885,
            "s_seats": 28652,
            "j_seats": 7804,
            "f_seats": 2875,
            "r_seats": 9557,
            "cargo_demand": 203674
        }
    },
    {
        "iata": "PUU",
        "icao": "SKAS",
        "airportName": "Tres de Mayo Airport",
        "location_country": "Colombia",
        "location_city": "Puerto Asis",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.505228",
        "longitude": "-76.5008",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5331",
                "runway_width": "131",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02/20",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 139148,
            "s_seats": 44708,
            "j_seats": 24004,
            "f_seats": 7200,
            "r_seats": 7036,
            "cargo_demand": 234467
        }
    },
    {
        "iata": "PUW",
        "icao": "KPUW",
        "airportName": "Pullman-Moscow Regional Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Pullman / Moscow (ID)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.7439",
        "longitude": "-117.110001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6730",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86844,
            "s_seats": 34140,
            "j_seats": 22460,
            "f_seats": 19739,
            "r_seats": 9581,
            "cargo_demand": 147660
        }
    },
    {
        "iata": "PUX",
        "icao": "SCPV",
        "airportName": "El Mirador Airport",
        "location_country": "Chile",
        "location_city": "Puerto Varas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-41.34939957",
        "longitude": "-72.94670105",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2559",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2220,
            "s_seats": 2235,
            "j_seats": 1827,
            "f_seats": 500,
            "r_seats": 144,
            "cargo_demand": 70432
        }
    },
    {
        "iata": "PUY",
        "icao": "LDPL",
        "airportName": "Pula Airport",
        "location_country": "Croatia",
        "location_city": "Pula",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.89350128",
        "longitude": "13.9222002",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9678",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148473,
            "s_seats": 44697,
            "j_seats": 39034,
            "f_seats": 18804,
            "r_seats": 8104,
            "cargo_demand": 217913
        }
    },
    {
        "iata": "PUZ",
        "icao": "MNPC",
        "airportName": "Puerto Cabezas Airport",
        "location_country": "Nicaragua",
        "location_city": "Puerto Cabezas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.0472002",
        "longitude": "-83.38670349",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8130",
                "runway_width": "167",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 119485,
            "s_seats": 32512,
            "j_seats": 40431,
            "f_seats": 5523,
            "r_seats": 7512,
            "cargo_demand": 318542
        }
    },
    {
        "iata": "PVA",
        "icao": "SKPV",
        "airportName": "El Embrujo Airport",
        "location_country": "Colombia",
        "location_city": "Providencia Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.3569",
        "longitude": "-81.3583",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3832",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188370,
            "s_seats": 32465,
            "j_seats": 16872,
            "f_seats": 5249,
            "r_seats": 3061,
            "cargo_demand": 358231
        }
    },
    {
        "iata": "PVC",
        "icao": "KPVC",
        "airportName": "Provincetown Municipal Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Provincetown",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.07189941",
        "longitude": "-70.2213974",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2623,
            "s_seats": 2678,
            "j_seats": 1726,
            "f_seats": 264,
            "r_seats": 89,
            "cargo_demand": 47081
        }
    },
    {
        "iata": "PVD",
        "icao": "KPVD",
        "airportName": "Theodore Francis Green State Airport",
        "location_country": "United States",
        "location_city": "Rhode Island",
        "location_state": "Providence",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "41.732601",
        "longitude": "-71.420403",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8700",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6081",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 382830,
            "s_seats": 93263,
            "j_seats": 88532,
            "f_seats": 43939,
            "r_seats": 10000,
            "cargo_demand": 16001333
        }
    },
    {
        "iata": "PVF",
        "icao": "KPVF",
        "airportName": "Placerville Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Placerville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.7242012",
        "longitude": "-120.7529984",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4201",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1460,
            "s_seats": 1861,
            "j_seats": 1084,
            "f_seats": 59,
            "r_seats": 70,
            "cargo_demand": 53799
        }
    },
    {
        "iata": "PVG",
        "icao": "ZSPD",
        "airportName": "Shanghai Pudong International Airport",
        "location_country": "China",
        "location_city": "Shanghai",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "31.1434",
        "longitude": "121.805",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "11154",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 475974,
            "s_seats": 74721,
            "j_seats": 123694,
            "f_seats": 59810,
            "r_seats": 10000,
            "cargo_demand": 15326844
        }
    },
    {
        "iata": "PVH",
        "icao": "SBPV",
        "airportName": "Governador Jorge Teixeira de Oliveira International Airport",
        "location_country": "Brazil",
        "location_city": "Rondonia",
        "location_state": "Porto Velho",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.709289551",
        "longitude": "-63.90230179",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190311,
            "s_seats": 29328,
            "j_seats": 13241,
            "f_seats": 14205,
            "r_seats": 4473,
            "cargo_demand": 315369
        }
    },
    {
        "iata": "PVI",
        "icao": "SSPI",
        "airportName": "Edu Chaves Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Paranavai",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-23.08989906",
        "longitude": "-52.48849869",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1581,
            "s_seats": 1847,
            "j_seats": 1614,
            "f_seats": 96,
            "r_seats": 173,
            "cargo_demand": 68952
        }
    },
    {
        "iata": "PVK",
        "icao": "LGPZ",
        "airportName": "Aktion National Airport",
        "location_country": "Greece",
        "location_city": "Preveza / Lefkada",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.92549896",
        "longitude": "20.76530075",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "9419",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177090,
            "s_seats": 42922,
            "j_seats": 39237,
            "f_seats": 19061,
            "r_seats": 8352,
            "cargo_demand": 318933
        }
    },
    {
        "iata": "PVL",
        "icao": "KPBX",
        "airportName": "Pike County Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Pikeville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.5617981",
        "longitude": "-82.56639862",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3600",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5350",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3772,
            "s_seats": 2477,
            "j_seats": 1413,
            "f_seats": 258,
            "r_seats": 165,
            "cargo_demand": 4370
        }
    },
    {
        "iata": "PVO",
        "icao": "SEPV",
        "airportName": "Reales Tamarindos Airport",
        "location_country": "Ecuador",
        "location_city": "Portoviejo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.041650057",
        "longitude": "-80.47219849",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7451",
                "runway_width": "89",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69572,
            "s_seats": 38595,
            "j_seats": 18175,
            "f_seats": 1601,
            "r_seats": 6595,
            "cargo_demand": 419718
        }
    },
    {
        "iata": "PVR",
        "icao": "MMPR",
        "airportName": "Licenciado Gustavo Diaz Ordaz International Airport",
        "location_country": "Mexico",
        "location_city": "Jalisco",
        "location_state": "Puerto Vallarta",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "20.68009949",
        "longitude": "-105.2539978",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150041,
            "s_seats": 80749,
            "j_seats": 154522,
            "f_seats": 25176,
            "r_seats": 10000,
            "cargo_demand": 14414151
        }
    },
    {
        "iata": "PVS",
        "icao": "UHMD",
        "airportName": "Provideniya Bay Airport",
        "location_country": "Russia",
        "location_city": "Chukotka",
        "location_state": "Provideniya",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.37809753",
        "longitude": "-173.2429962",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7190",
                "runway_width": "240",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 191375,
            "s_seats": 35925,
            "j_seats": 23145,
            "f_seats": 4109,
            "r_seats": 6437,
            "cargo_demand": 191490
        }
    },
    {
        "iata": "PVU",
        "icao": "KPVU",
        "airportName": "Provo Municipal Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Provo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.2192",
        "longitude": "-111.723",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8599",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6614",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197406,
            "s_seats": 46916,
            "j_seats": 46626,
            "f_seats": 1144,
            "r_seats": 5040,
            "cargo_demand": 442659
        }
    },
    {
        "iata": "PVW",
        "icao": "KPVW",
        "airportName": "Hale County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Plainview",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.16809845",
        "longitude": "-101.7170029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5997",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2164,
            "s_seats": 2358,
            "j_seats": 1643,
            "f_seats": 298,
            "r_seats": 192,
            "cargo_demand": 24539
        }
    },
    {
        "iata": "PWA",
        "icao": "KPWA",
        "airportName": "Wiley Post Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Oklahoma City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.53419876",
        "longitude": "-97.64710236",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4213",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "7198",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1808,
            "s_seats": 2554,
            "j_seats": 1356,
            "f_seats": 419,
            "r_seats": 7,
            "cargo_demand": 53885
        }
    },
    {
        "iata": "PWD",
        "icao": "KPWD",
        "airportName": "Sher-Wood Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Plentywood",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "48.79029846",
        "longitude": "-104.5339966",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2950",
                "runway_width": "60",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3900",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "1600",
                "runway_width": "83",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2031,
            "s_seats": 1609,
            "j_seats": 1317,
            "f_seats": 335,
            "r_seats": 177,
            "cargo_demand": 77582
        }
    },
    {
        "iata": "PWE",
        "icao": "UHMP",
        "airportName": "Pevek Airport",
        "location_country": "Russia",
        "location_city": "Chukotka",
        "location_state": "Pevek",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "69.783302",
        "longitude": "170.597",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8202",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 53501,
            "s_seats": 35124,
            "j_seats": 46260,
            "f_seats": 16388,
            "r_seats": 5212,
            "cargo_demand": 456294
        }
    },
    {
        "iata": "PWK",
        "icao": "KPWK",
        "airportName": "Chicago Executive Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Chicago",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.114222",
        "longitude": "-87.901494",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3660",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4386",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 80777,
            "s_seats": 26489,
            "j_seats": 30369,
            "f_seats": 16631,
            "r_seats": 7883,
            "cargo_demand": 105935
        }
    },
    {
        "iata": "PWM",
        "icao": "KPWM",
        "airportName": "Portland International Jetport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Portland",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.646198",
        "longitude": "-70.309303",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 470143,
            "s_seats": 82044,
            "j_seats": 126899,
            "f_seats": 50789,
            "r_seats": 10000,
            "cargo_demand": 11038214
        }
    },
    {
        "iata": "PWQ",
        "icao": "UASP",
        "airportName": "Pavlodar Airport",
        "location_country": "Kazakhstan",
        "location_city": "Pavlodar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.19499969",
        "longitude": "77.07389832",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 157900,
            "s_seats": 30296,
            "j_seats": 49946,
            "f_seats": 3590,
            "r_seats": 9473,
            "cargo_demand": 386438
        }
    },
    {
        "iata": "PWT",
        "icao": "KPWT",
        "airportName": "Bremerton National Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Bremerton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.49020004",
        "longitude": "-122.7649994",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5122",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148660,
            "s_seats": 42846,
            "j_seats": 12947,
            "f_seats": 7672,
            "r_seats": 9328,
            "cargo_demand": 60113
        }
    },
    {
        "iata": "PWY",
        "icao": "KPNA",
        "airportName": "Ralph Wenz Field",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Pinedale",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.79550171",
        "longitude": "-109.8069992",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7100",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101829,
            "s_seats": 45378,
            "j_seats": 5660,
            "f_seats": 11787,
            "r_seats": 7578,
            "cargo_demand": 438021
        }
    },
    {
        "iata": "PXH",
        "icao": "YPMH",
        "airportName": "Prominent Hill Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Prominent Hill",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-29.716",
        "longitude": "135.5244",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "Gravel"
            }
        ],
        "demand": {
            "y_seats": 1161,
            "s_seats": 1579,
            "j_seats": 1964,
            "f_seats": 181,
            "r_seats": 162,
            "cargo_demand": 22956
        }
    },
    {
        "iata": "PXM",
        "icao": "MMPS",
        "airportName": "Puerto Escondido International Airport",
        "location_country": "Mexico",
        "location_city": "Oaxaca",
        "location_state": "Puerto Escondido",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.8769",
        "longitude": "-97.089103",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123679,
            "s_seats": 30011,
            "j_seats": 9110,
            "f_seats": 12208,
            "r_seats": 6363,
            "cargo_demand": 319005
        }
    },
    {
        "iata": "PXO",
        "icao": "LPPS",
        "airportName": "Porto Santo Airport",
        "location_country": "Portugal",
        "location_city": "Madeira",
        "location_state": "Porto Santo Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.07339859",
        "longitude": "-16.35000038",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "18/36",
                "runway_length": "9861",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 178660,
            "s_seats": 36142,
            "j_seats": 27137,
            "f_seats": 7381,
            "r_seats": 6856,
            "cargo_demand": 192459
        }
    },
    {
        "iata": "PXR",
        "icao": "VTUJ",
        "airportName": "Surin Airport",
        "location_country": "Thailand",
        "location_city": "Surin",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.86830044",
        "longitude": "103.4980011",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5053",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89196,
            "s_seats": 25570,
            "j_seats": 33066,
            "f_seats": 6947,
            "r_seats": 4450,
            "cargo_demand": 60417
        }
    },
    {
        "iata": "PXU",
        "icao": "VVPK",
        "airportName": "Pleiku Airport",
        "location_country": "Vietnam",
        "location_city": "Pleiku",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.00450039",
        "longitude": "108.0169983",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5960",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121815,
            "s_seats": 34636,
            "j_seats": 27228,
            "f_seats": 12760,
            "r_seats": 7322,
            "cargo_demand": 479734
        }
    },
    {
        "iata": "PYA",
        "icao": "SKVL",
        "airportName": "Velasquez Airport",
        "location_country": "Colombia",
        "location_city": "Puerto Boyaca",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "5.93904",
        "longitude": "-74.457",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5520",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1388,
            "s_seats": 2099,
            "j_seats": 1472,
            "f_seats": 259,
            "r_seats": 154,
            "cargo_demand": 80364
        }
    },
    {
        "iata": "PYB",
        "icao": "VEJP",
        "airportName": "Jeypore Airport",
        "location_country": "India",
        "location_city": "Odisha",
        "location_state": "Jeypore",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.87999916",
        "longitude": "82.55200195",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3000",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3738,
            "s_seats": 1596,
            "j_seats": 1005,
            "f_seats": 443,
            "r_seats": 84,
            "cargo_demand": 28487
        }
    },
    {
        "iata": "PYE",
        "icao": "NCPY",
        "airportName": "Tongareva Airport",
        "location_country": "Cook Islands",
        "location_city": "Penrhyn Atoll",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.014369965",
        "longitude": "-158.0324097",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7530",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1388,
            "s_seats": 2822,
            "j_seats": 1973,
            "f_seats": 121,
            "r_seats": 21,
            "cargo_demand": 75426
        }
    },
    {
        "iata": "PYH",
        "icao": "SVPA",
        "airportName": "Cacique Aramare Airport",
        "location_country": "Venezuela",
        "location_city": "Puerto Ayacucho",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.619989872",
        "longitude": "-67.60610199",
        "region": "SA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8272",
                "runway_width": "140",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 107521,
            "s_seats": 32468,
            "j_seats": 16669,
            "f_seats": 8012,
            "r_seats": 4077,
            "cargo_demand": 224222
        }
    },
    {
        "iata": "PYJ",
        "icao": "UERP",
        "airportName": "Polyarny Airport",
        "location_country": "Russia",
        "location_city": "Yakutia",
        "location_state": "Polyarny",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "66.40039825",
        "longitude": "112.0299988",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "10170",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 180462,
            "s_seats": 42160,
            "j_seats": 56959,
            "f_seats": 2806,
            "r_seats": 7858,
            "cargo_demand": 101296
        }
    },
    {
        "iata": "PYK",
        "icao": "OIIP",
        "airportName": "Payam International Airport",
        "location_country": "Iran",
        "location_city": "Karaj",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.7761",
        "longitude": "50.826698",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "12005",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141386,
            "s_seats": 47067,
            "j_seats": 19957,
            "f_seats": 7817,
            "r_seats": 4714,
            "cargo_demand": 492545
        }
    },
    {
        "iata": "PYM",
        "icao": "KPYM",
        "airportName": "Plymouth Municipal Airport",
        "location_country": "United States",
        "location_city": "Massachusetts",
        "location_state": "Plymouth",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.909",
        "longitude": "-70.728798",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4349",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3351",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3467,
            "s_seats": 2115,
            "j_seats": 1607,
            "f_seats": 296,
            "r_seats": 184,
            "cargo_demand": 10024
        }
    },
    {
        "iata": "PYR",
        "icao": "LGAD",
        "airportName": "Andravida Air Base",
        "location_country": "Greece",
        "location_city": "Pyrgos",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.9207",
        "longitude": "21.292601",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "10299",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191801,
            "s_seats": 43803,
            "j_seats": 40780,
            "f_seats": 3006,
            "r_seats": 3652,
            "cargo_demand": 403447
        }
    },
    {
        "iata": "PZA",
        "icao": "SKPZ",
        "airportName": "Paz de Ariporo Airport",
        "location_country": "Colombia",
        "location_city": "Paz de Ariporo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.87615",
        "longitude": "-71.8866",
        "region": "SA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4935",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185048,
            "s_seats": 25041,
            "j_seats": 19502,
            "f_seats": 15217,
            "r_seats": 6482,
            "cargo_demand": 459336
        }
    },
    {
        "iata": "PZB",
        "icao": "FAPM",
        "airportName": "Pietermaritzburg Airport",
        "location_country": "South Africa",
        "location_city": "Pietermaritzburg",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.64900017",
        "longitude": "30.39870071",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5043",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194068,
            "s_seats": 31915,
            "j_seats": 12294,
            "f_seats": 15019,
            "r_seats": 9141,
            "cargo_demand": 366300
        }
    },
    {
        "iata": "PZH",
        "icao": "OPZB",
        "airportName": "Zhob Airport",
        "location_country": "Pakistan",
        "location_city": "Zhob",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.35840034",
        "longitude": "69.46360016",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6001",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181428,
            "s_seats": 41321,
            "j_seats": 52949,
            "f_seats": 8094,
            "r_seats": 5495,
            "cargo_demand": 471642
        }
    },
    {
        "iata": "PZO",
        "icao": "SVPR",
        "airportName": "Manuel Carlos Piar Guayana Airport",
        "location_country": "Venezuela",
        "location_city": "Ciudad Guayana / Puerto Ordaz",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.28853035",
        "longitude": "-62.76039886",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6726",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127239,
            "s_seats": 35929,
            "j_seats": 18785,
            "f_seats": 1690,
            "r_seats": 5848,
            "cargo_demand": 141838
        }
    },
    {
        "iata": "PZS",
        "icao": "SCTC",
        "airportName": "Maquehue Airport",
        "location_country": "Chile",
        "location_city": "Temuco",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.76679993",
        "longitude": "-72.63710022",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5577",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69665,
            "s_seats": 33131,
            "j_seats": 29343,
            "f_seats": 7408,
            "r_seats": 4233,
            "cargo_demand": 418818
        }
    },
    {
        "iata": "PZU",
        "icao": "HSPN",
        "airportName": "Port Sudan New International Airport",
        "location_country": "Sudan",
        "location_city": "Port Sudan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.43359947",
        "longitude": "37.23410034",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146188,
            "s_seats": 39561,
            "j_seats": 34425,
            "f_seats": 18733,
            "r_seats": 8815,
            "cargo_demand": 251676
        }
    },
    {
        "iata": "PZY",
        "icao": "LZPP",
        "airportName": "Pieo\u00a1ooany Airport",
        "location_country": "Slovakia",
        "location_city": "Pieo\u00a1ooany",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.62519836",
        "longitude": "17.82839966",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187591,
            "s_seats": 29645,
            "j_seats": 10406,
            "f_seats": 3405,
            "r_seats": 6389,
            "cargo_demand": 441186
        }
    },
    {
        "iata": "QAQ",
        "icao": "LIAP",
        "airportName": "L'Aquila-Preturo Airport",
        "location_country": "Italy",
        "location_city": "Abruzzo",
        "location_state": "L'Aquila",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.379902",
        "longitude": "13.3092",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4623",
                "runway_width": "85",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1317,
            "s_seats": 2012,
            "j_seats": 1921,
            "f_seats": 12,
            "r_seats": 131,
            "cargo_demand": 30566
        }
    },
    {
        "iata": "QBC",
        "icao": "CYBD",
        "airportName": "Bella Coola Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Bella Coola",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.387501",
        "longitude": "-126.596001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4200",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67208,
            "s_seats": 27586,
            "j_seats": 25967,
            "f_seats": 11885,
            "r_seats": 9188,
            "cargo_demand": 494073
        }
    },
    {
        "iata": "QCY",
        "icao": "EGXC",
        "airportName": "RAF Coningsby",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Coningsby",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.0929985",
        "longitude": "-0.166014001",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9003",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 144249,
            "s_seats": 28325,
            "j_seats": 57443,
            "f_seats": 15144,
            "r_seats": 9781,
            "cargo_demand": 166917
        }
    },
    {
        "iata": "QHU",
        "icao": "EDXJ",
        "airportName": "Husum Schwesing Airport",
        "location_country": "Germany",
        "location_city": "Schleswig-Holstein",
        "location_state": "Husum",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "54.50999832",
        "longitude": "9.138333321",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4757",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2466,
            "s_seats": 2859,
            "j_seats": 1074,
            "f_seats": 439,
            "r_seats": 171,
            "cargo_demand": 5891
        }
    },
    {
        "iata": "QLR",
        "icao": "LPMR",
        "airportName": "Monte Real Air Base",
        "location_country": "Portugal",
        "location_city": "Monte Real",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.828335",
        "longitude": "-8.8875",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9816",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 179800,
            "s_seats": 49155,
            "j_seats": 39732,
            "f_seats": 1940,
            "r_seats": 9426,
            "cargo_demand": 461189
        }
    },
    {
        "iata": "QOW",
        "icao": "DNIM",
        "airportName": "Sam Mbakwe Airport",
        "location_country": "Nigeria",
        "location_city": "Owerri",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.427060127",
        "longitude": "7.206029892",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50848,
            "s_seats": 48666,
            "j_seats": 18990,
            "f_seats": 5133,
            "r_seats": 4407,
            "cargo_demand": 248983
        }
    },
    {
        "iata": "QPG",
        "icao": "WSAP",
        "airportName": "Paya Lebar Air Base",
        "location_country": "Singapore",
        "location_city": "",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.360419989",
        "longitude": "103.9100037",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "12401",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123701,
            "s_seats": 33735,
            "j_seats": 47862,
            "f_seats": 11097,
            "r_seats": 9755,
            "cargo_demand": 421508
        }
    },
    {
        "iata": "QRA",
        "icao": "FAGM",
        "airportName": "Rand Airport",
        "location_country": "South Africa",
        "location_city": "Johannesburg",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.2425",
        "longitude": "28.151199",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11R/29L",
                "runway_length": "5617",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "4898",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90601,
            "s_seats": 39363,
            "j_seats": 53447,
            "f_seats": 17869,
            "r_seats": 9540,
            "cargo_demand": 436962
        }
    },
    {
        "iata": "QRC",
        "icao": "SCRG",
        "airportName": "Rancagua de la Independencia Airport",
        "location_country": "Chile",
        "location_city": "Rancagua",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.17369843",
        "longitude": "-70.77570343",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5414",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197509,
            "s_seats": 45352,
            "j_seats": 59782,
            "f_seats": 5947,
            "r_seats": 3758,
            "cargo_demand": 198061
        }
    },
    {
        "iata": "QRO",
        "icao": "MMQT",
        "airportName": "Queretaro Intercontinental Airport",
        "location_country": "Mexico",
        "location_city": "Queretaro",
        "location_state": "Queretaro City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.6173",
        "longitude": "-100.185997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 58437,
            "s_seats": 27134,
            "j_seats": 43129,
            "f_seats": 17190,
            "r_seats": 4272,
            "cargo_demand": 171976
        }
    },
    {
        "iata": "QSC",
        "icao": "SDSC",
        "airportName": "Mario Pereira Lopes Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Sio Carlos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-21.875401",
        "longitude": "-47.903703",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "4790",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2802,
            "s_seats": 2743,
            "j_seats": 1091,
            "f_seats": 267,
            "r_seats": 89,
            "cargo_demand": 61784
        }
    },
    {
        "iata": "QSF",
        "icao": "DAAS",
        "airportName": "Ain Arnat Airport",
        "location_country": "Algeria",
        "location_city": "Setif",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.17810059",
        "longitude": "5.32449007",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9498",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162130,
            "s_seats": 26477,
            "j_seats": 31019,
            "f_seats": 17511,
            "r_seats": 3256,
            "cargo_demand": 274309
        }
    },
    {
        "iata": "QSR",
        "icao": "LIRI",
        "airportName": "Salerno Costa d'Amalfi Airport",
        "location_country": "Italy",
        "location_city": "Campania",
        "location_state": "Salerno",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.620399",
        "longitude": "14.9113",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5427",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169233,
            "s_seats": 49675,
            "j_seats": 35419,
            "f_seats": 14824,
            "r_seats": 4617,
            "cargo_demand": 179057
        }
    },
    {
        "iata": "QUG",
        "icao": "EGHR",
        "airportName": "Chichester/Goodwood Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Chichester",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "50.859402",
        "longitude": "-0.759167",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2805",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2297",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4249",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "2382",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2064,
            "s_seats": 2947,
            "j_seats": 1100,
            "f_seats": 418,
            "r_seats": 60,
            "cargo_demand": 38915
        }
    },
    {
        "iata": "QUO",
        "icao": "DNAI",
        "airportName": "Akwa Ibom International Airport",
        "location_country": "Nigeria",
        "location_city": "Uyo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "4.8725",
        "longitude": "8.093",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "11811",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 322043,
            "s_seats": 71482,
            "j_seats": 116505,
            "f_seats": 27097,
            "r_seats": 10000,
            "cargo_demand": 10735577
        }
    },
    {
        "iata": "QUY",
        "icao": "EGUY",
        "airportName": "RAF Wyton",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Wyton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.35720062",
        "longitude": "-0.107832998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8255",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2500",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139897,
            "s_seats": 49178,
            "j_seats": 34459,
            "f_seats": 18395,
            "r_seats": 5998,
            "cargo_demand": 394094
        }
    },
    {
        "iata": "RAB",
        "icao": "AYTK",
        "airportName": "Rabaul Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Rabaul",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-4.34046",
        "longitude": "152.380005",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5643",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109723,
            "s_seats": 36186,
            "j_seats": 43773,
            "f_seats": 1252,
            "r_seats": 5841,
            "cargo_demand": 491478
        }
    },
    {
        "iata": "RAC",
        "icao": "KRAC",
        "airportName": "John H. Batten Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Racine",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.76060104",
        "longitude": "-87.81520081",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6556",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4423",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2442,
            "s_seats": 2105,
            "j_seats": 1220,
            "f_seats": 381,
            "r_seats": 77,
            "cargo_demand": 59805
        }
    },
    {
        "iata": "RAE",
        "icao": "OERR",
        "airportName": "Arar Domestic Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Arar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.90660095",
        "longitude": "41.13819885",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110097,
            "s_seats": 45264,
            "j_seats": 56752,
            "f_seats": 12834,
            "r_seats": 6187,
            "cargo_demand": 227704
        }
    },
    {
        "iata": "RAF",
        "icao": "SAFR",
        "airportName": "Rafaela Airport",
        "location_country": "Argentina",
        "location_city": "Santa Fe",
        "location_state": "Rafaela",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31.2825",
        "longitude": "-61.5017",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "3632",
                "runway_width": "144",
                "runway_material": "GRE"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "3691",
                "runway_width": "75",
                "runway_material": "CON"
            },
            {
                "runway_name": "6/24",
                "runway_length": "3606",
                "runway_width": "138",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1888,
            "s_seats": 2922,
            "j_seats": 1065,
            "f_seats": 143,
            "r_seats": 117,
            "cargo_demand": 30884
        }
    },
    {
        "iata": "RAH",
        "icao": "OERF",
        "airportName": "Rafha Domestic Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Rafha",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.62639999",
        "longitude": "43.49060059",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9834",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105297,
            "s_seats": 42959,
            "j_seats": 36805,
            "f_seats": 15216,
            "r_seats": 3111,
            "cargo_demand": 279814
        }
    },
    {
        "iata": "RAI",
        "icao": "GVNP",
        "airportName": "Nelson Mandela International Airport",
        "location_country": "Cape Verde",
        "location_city": "Praia",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.92450047",
        "longitude": "-23.49349976",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "03L/21R",
                "runway_length": "4209",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "6876",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180703,
            "s_seats": 49062,
            "j_seats": 40007,
            "f_seats": 2499,
            "r_seats": 8547,
            "cargo_demand": 70337
        }
    },
    {
        "iata": "RAJ",
        "icao": "VARK",
        "airportName": "Rajkot Airport",
        "location_country": "India",
        "location_city": "Gujarat",
        "location_state": "Rajkot",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.30920029",
        "longitude": "70.77950287",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6040",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165079,
            "s_seats": 41211,
            "j_seats": 38314,
            "f_seats": 8959,
            "r_seats": 6666,
            "cargo_demand": 329282
        }
    },
    {
        "iata": "RAK",
        "icao": "GMMX",
        "airportName": "Marrakesh Menara Airport",
        "location_country": "Morocco",
        "location_city": "Marrakesh",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.60689926",
        "longitude": "-8.036299706",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10170",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 164081,
            "s_seats": 32622,
            "j_seats": 27776,
            "f_seats": 4116,
            "r_seats": 8107,
            "cargo_demand": 235915
        }
    },
    {
        "iata": "RAL",
        "icao": "KRAL",
        "airportName": "Riverside Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Riverside",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.9519",
        "longitude": "-117.445",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5401",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2851",
                "runway_width": "48",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101639,
            "s_seats": 40348,
            "j_seats": 10679,
            "f_seats": 8082,
            "r_seats": 5108,
            "cargo_demand": 275856
        }
    },
    {
        "iata": "RAM",
        "icao": "YRNG",
        "airportName": "Ramingining Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Ramingining",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.35639954",
        "longitude": "134.897995",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4825",
                "runway_width": "59",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 3232,
            "s_seats": 1626,
            "j_seats": 1423,
            "f_seats": 472,
            "r_seats": 180,
            "cargo_demand": 45706
        }
    },
    {
        "iata": "RAN",
        "icao": "LIDR",
        "airportName": "Ravenna Airport",
        "location_country": "Italy",
        "location_city": "Emilia-Romagna",
        "location_state": "Ravenna",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.36391",
        "longitude": "12.224978",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2625",
                "runway_width": "164",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 3271,
            "s_seats": 2510,
            "j_seats": 1869,
            "f_seats": 207,
            "r_seats": 164,
            "cargo_demand": 4324
        }
    },
    {
        "iata": "RAO",
        "icao": "SBRP",
        "airportName": "Leite Lopes Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Ribeirio Preto",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.13638878",
        "longitude": "-47.77666855",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168642,
            "s_seats": 40606,
            "j_seats": 34570,
            "f_seats": 5008,
            "r_seats": 4686,
            "cargo_demand": 172827
        }
    },
    {
        "iata": "RAP",
        "icao": "KRAP",
        "airportName": "Rapid City Regional Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Rapid City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.04529953",
        "longitude": "-103.0569992",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3601",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8701",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 75876,
            "s_seats": 31262,
            "j_seats": 28546,
            "f_seats": 8277,
            "r_seats": 5074,
            "cargo_demand": 394872
        }
    },
    {
        "iata": "RAR",
        "icao": "NCRG",
        "airportName": "Rarotonga International Airport",
        "location_country": "Cook Islands",
        "location_city": "Rarotonga",
        "location_state": "Avarua",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-21.20269966",
        "longitude": "-159.8059998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7638",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 102753,
            "s_seats": 74980,
            "j_seats": 75709,
            "f_seats": 47483,
            "r_seats": 10000,
            "cargo_demand": 27851992
        }
    },
    {
        "iata": "RAS",
        "icao": "OIGG",
        "airportName": "Rasht Airport",
        "location_country": "Iran",
        "location_city": "Rasht",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.323333",
        "longitude": "49.617778",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9571",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 91829,
            "s_seats": 37416,
            "j_seats": 52094,
            "f_seats": 3988,
            "r_seats": 8432,
            "cargo_demand": 180328
        }
    },
    {
        "iata": "RAV",
        "icao": "SKCN",
        "airportName": "Cravo Norte Airport",
        "location_country": "Colombia",
        "location_city": "Cravo Norte",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "6.31684",
        "longitude": "-70.2107",
        "region": "SA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3675",
                "runway_width": "45",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2794,
            "s_seats": 2639,
            "j_seats": 1590,
            "f_seats": 158,
            "r_seats": 146,
            "cargo_demand": 78634
        }
    },
    {
        "iata": "RAZ",
        "icao": "OPRT",
        "airportName": "Rawalakot Airport",
        "location_country": "Pakistan",
        "location_city": "Rawalakot",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.84970093",
        "longitude": "73.79810333",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2958",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50170,
            "s_seats": 39993,
            "j_seats": 40758,
            "f_seats": 15720,
            "r_seats": 7069,
            "cargo_demand": 152707
        }
    },
    {
        "iata": "RBA",
        "icao": "GMME",
        "airportName": "Rabat-Sale Airport",
        "location_country": "Morocco",
        "location_city": "Rabat",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.051498",
        "longitude": "-6.75152",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108038,
            "s_seats": 39832,
            "j_seats": 25127,
            "f_seats": 15262,
            "r_seats": 4224,
            "cargo_demand": 149691
        }
    },
    {
        "iata": "RBB",
        "icao": "SWBR",
        "airportName": "Borba Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Borba",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-4.406340122",
        "longitude": "-59.60240173",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1962,
            "s_seats": 2959,
            "j_seats": 1890,
            "f_seats": 9,
            "r_seats": 195,
            "cargo_demand": 23942
        }
    },
    {
        "iata": "RBC",
        "icao": "YROI",
        "airportName": "Robinvale Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Robinvale",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-34.65000153",
        "longitude": "142.7830048",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3740",
                "runway_width": "",
                "runway_material": "B"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3854",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 1974,
            "s_seats": 2325,
            "j_seats": 1784,
            "f_seats": 419,
            "r_seats": 129,
            "cargo_demand": 81278
        }
    },
    {
        "iata": "RBD",
        "icao": "KRBD",
        "airportName": "Dallas Executive Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Dallas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.68090057",
        "longitude": "-96.86820221",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6451",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3800",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 2403,
            "s_seats": 2520,
            "j_seats": 1158,
            "f_seats": 69,
            "r_seats": 65,
            "cargo_demand": 13690
        }
    },
    {
        "iata": "RBE",
        "icao": "VDRK",
        "airportName": "Ratanakiri Airport",
        "location_country": "Cambodia",
        "location_city": "Ratanakiri",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.72999954",
        "longitude": "106.9869995",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 174419,
            "s_seats": 34304,
            "j_seats": 48173,
            "f_seats": 16210,
            "r_seats": 9113,
            "cargo_demand": 321965
        }
    },
    {
        "iata": "RBG",
        "icao": "KRBG",
        "airportName": "Roseburg Regional Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Roseburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.23880005",
        "longitude": "-123.3560028",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4601",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2315,
            "s_seats": 2672,
            "j_seats": 1449,
            "f_seats": 233,
            "r_seats": 21,
            "cargo_demand": 79709
        }
    },
    {
        "iata": "RBJ",
        "icao": "RJCR",
        "airportName": "Rebun Airport",
        "location_country": "Japan",
        "location_city": "Rebun Island",
        "location_state": "Rebun",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "45.45500183",
        "longitude": "141.0390015",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2625",
                "runway_width": "",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 1743,
            "s_seats": 2149,
            "j_seats": 1804,
            "f_seats": 305,
            "r_seats": 27,
            "cargo_demand": 19573
        }
    },
    {
        "iata": "RBL",
        "icao": "KRBL",
        "airportName": "Red Bluff Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Red Bluff",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.15069962",
        "longitude": "-122.2519989",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5700",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199022,
            "s_seats": 27333,
            "j_seats": 35223,
            "f_seats": 11860,
            "r_seats": 9163,
            "cargo_demand": 347392
        }
    },
    {
        "iata": "RBM",
        "icao": "EDMS",
        "airportName": "Straubing Wallmuhle Airport",
        "location_country": "Germany",
        "location_city": "Bavaria",
        "location_state": "Straubing",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "48.90083313",
        "longitude": "12.51666737",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4429",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3775,
            "s_seats": 2743,
            "j_seats": 1420,
            "f_seats": 452,
            "r_seats": 40,
            "cargo_demand": 21676
        }
    },
    {
        "iata": "RBR",
        "icao": "SBRB",
        "airportName": "Placido de Castro International Airport",
        "location_country": "Brazil",
        "location_city": "Acre",
        "location_state": "Rio Branco",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.869031",
        "longitude": "-67.893984",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "7080",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182114,
            "s_seats": 29716,
            "j_seats": 30682,
            "f_seats": 4126,
            "r_seats": 8633,
            "cargo_demand": 339656
        }
    },
    {
        "iata": "RBS",
        "icao": "YORB",
        "airportName": "Orbost Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Orbost",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-37.79000092",
        "longitude": "148.6100006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3740",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 3878,
            "s_seats": 3000,
            "j_seats": 1087,
            "f_seats": 236,
            "r_seats": 11,
            "cargo_demand": 51120
        }
    },
    {
        "iata": "RBU",
        "icao": "YROE",
        "airportName": "Roebourne Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Roebourne",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.76169968",
        "longitude": "117.1569977",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5137",
                "runway_width": "",
                "runway_material": "dirt"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5666",
                "runway_width": "",
                "runway_material": "dirt"
            }
        ],
        "demand": {
            "y_seats": 3977,
            "s_seats": 2557,
            "j_seats": 1921,
            "f_seats": 362,
            "r_seats": 20,
            "cargo_demand": 92595
        }
    },
    {
        "iata": "RBV",
        "icao": "AGRM",
        "airportName": "Ramata Airport",
        "location_country": "Solomon Islands",
        "location_city": "Ramata Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.168060303",
        "longitude": "157.6430054",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2500",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2462,
            "s_seats": 2003,
            "j_seats": 1803,
            "f_seats": 78,
            "r_seats": 145,
            "cargo_demand": 7056
        }
    },
    {
        "iata": "RBW",
        "icao": "KRBW",
        "airportName": "Lowcountry Regional Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Walterboro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.92100143",
        "longitude": "-80.64060211",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6002",
                "runway_width": "100",
                "runway_material": "PEM"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5408",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5705",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 2690,
            "s_seats": 2603,
            "j_seats": 1001,
            "f_seats": 284,
            "r_seats": 181,
            "cargo_demand": 99518
        }
    },
    {
        "iata": "RBY",
        "icao": "PARY",
        "airportName": "Ruby Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Ruby",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.72720337",
        "longitude": "-155.4700012",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 197382,
            "s_seats": 49674,
            "j_seats": 52573,
            "f_seats": 7476,
            "r_seats": 8430,
            "cargo_demand": 376500
        }
    },
    {
        "iata": "RCA",
        "icao": "KRCA",
        "airportName": "Ellsworth Air Force Base",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Rapid City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.14500046",
        "longitude": "-103.1039963",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "13497",
                "runway_width": "300",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 134567,
            "s_seats": 30337,
            "j_seats": 50641,
            "f_seats": 2409,
            "r_seats": 4433,
            "cargo_demand": 447177
        }
    },
    {
        "iata": "RCB",
        "icao": "FARB",
        "airportName": "Richards Bay Airport",
        "location_country": "South Africa",
        "location_city": "Richards Bay",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.74099922",
        "longitude": "32.09209824",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4265",
                "runway_width": "69",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132396,
            "s_seats": 41733,
            "j_seats": 44127,
            "f_seats": 16882,
            "r_seats": 5712,
            "cargo_demand": 327530
        }
    },
    {
        "iata": "RCH",
        "icao": "SKRH",
        "airportName": "Almirante Padilla Airport",
        "location_country": "Colombia",
        "location_city": "Riohacha",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.5262",
        "longitude": "-72.926",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5413",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 151909,
            "s_seats": 45249,
            "j_seats": 21964,
            "f_seats": 5180,
            "r_seats": 7729,
            "cargo_demand": 413405
        }
    },
    {
        "iata": "RCK",
        "icao": "KRCK",
        "airportName": "H. H. Coffield Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Rockdale",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "30.63159943",
        "longitude": "-96.98970032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3084",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2116,
            "s_seats": 1711,
            "j_seats": 1576,
            "f_seats": 63,
            "r_seats": 103,
            "cargo_demand": 41360
        }
    },
    {
        "iata": "RCL",
        "icao": "NVSR",
        "airportName": "Redcliffe Airport",
        "location_country": "Vanuatu",
        "location_city": "Aoba Island",
        "location_state": "Redcliffe",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.47200012",
        "longitude": "167.8350067",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2165",
                "runway_width": "75",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3514,
            "s_seats": 2541,
            "j_seats": 1198,
            "f_seats": 116,
            "r_seats": 34,
            "cargo_demand": 41110
        }
    },
    {
        "iata": "RCM",
        "icao": "YRMD",
        "airportName": "Richmond Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Richmond",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.70190048",
        "longitude": "143.1150055",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2530,
            "s_seats": 2616,
            "j_seats": 1934,
            "f_seats": 426,
            "r_seats": 97,
            "cargo_demand": 29254
        }
    },
    {
        "iata": "RCO",
        "icao": "LFDN",
        "airportName": "Rochefort - Saint-Agnant Airport",
        "location_country": "France",
        "location_city": "Poitou-Charentes",
        "location_state": "Rochefort",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.88779831",
        "longitude": "-0.983056009",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7480",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160409,
            "s_seats": 27351,
            "j_seats": 32775,
            "f_seats": 17504,
            "r_seats": 6540,
            "cargo_demand": 45990
        }
    },
    {
        "iata": "RCQ",
        "icao": "SATR",
        "airportName": "Reconquista Airport",
        "location_country": "Argentina",
        "location_city": "Santa Fe",
        "location_state": "Reconquista",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.2103",
        "longitude": "-59.68",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9186",
                "runway_width": "164",
                "runway_material": "CON"
            },
            {
                "runway_name": "2/20",
                "runway_length": "4101",
                "runway_width": "112",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 133680,
            "s_seats": 31392,
            "j_seats": 25308,
            "f_seats": 13537,
            "r_seats": 9241,
            "cargo_demand": 149061
        }
    },
    {
        "iata": "RCR",
        "icao": "KRCR",
        "airportName": "Fulton County Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Rochester",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.06560135",
        "longitude": "-86.18170166",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3228,
            "s_seats": 1727,
            "j_seats": 1132,
            "f_seats": 494,
            "r_seats": 38,
            "cargo_demand": 8293
        }
    },
    {
        "iata": "RCS",
        "icao": "EGTO",
        "airportName": "Rochester Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Rochester",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "51.35189819",
        "longitude": "0.503332973",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02L/20R",
                "runway_length": "2713",
                "runway_width": "105",
                "runway_material": "Grass"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "2264",
                "runway_width": "69",
                "runway_material": "Grass"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3159",
                "runway_width": "115",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1087,
            "s_seats": 2608,
            "j_seats": 1430,
            "f_seats": 127,
            "r_seats": 125,
            "cargo_demand": 97852
        }
    },
    {
        "iata": "RCT",
        "icao": "KRCT",
        "airportName": "Nartron Field",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Reed City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.90000153",
        "longitude": "-85.51670074",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4506",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1830,
            "s_seats": 2891,
            "j_seats": 1325,
            "f_seats": 494,
            "r_seats": 102,
            "cargo_demand": 35124
        }
    },
    {
        "iata": "RCU",
        "icao": "SAOC",
        "airportName": "Las Higueras Airport",
        "location_country": "Argentina",
        "location_city": "Cordoba",
        "location_state": "Rio Cuarto",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.08509827",
        "longitude": "-64.26129913",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6808",
                "runway_width": "131",
                "runway_material": "ASP"
            },
            {
                "runway_name": "5/23",
                "runway_length": "7431",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124771,
            "s_seats": 39240,
            "j_seats": 49042,
            "f_seats": 17844,
            "r_seats": 6677,
            "cargo_demand": 380773
        }
    },
    {
        "iata": "RCY",
        "icao": "MYRP",
        "airportName": "Port Nelson Airport",
        "location_country": "Bahamas",
        "location_city": "Rum Cay Island",
        "location_state": "Port Nelson",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "23.68440056",
        "longitude": "-74.8361969",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2281,
            "s_seats": 2019,
            "j_seats": 1335,
            "f_seats": 118,
            "r_seats": 83,
            "cargo_demand": 42887
        }
    },
    {
        "iata": "RDB",
        "icao": "PADG",
        "airportName": "Red Dog Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Red Dog",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "68.03209686",
        "longitude": "-162.8990021",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6312",
                "runway_width": "100",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 3395,
            "s_seats": 2582,
            "j_seats": 1541,
            "f_seats": 488,
            "r_seats": 164,
            "cargo_demand": 79868
        }
    },
    {
        "iata": "RDC",
        "icao": "SNDC",
        "airportName": "RedeniCio Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "RedeniCio",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.033289909",
        "longitude": "-49.97990036",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "3150",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1471,
            "s_seats": 1508,
            "j_seats": 1187,
            "f_seats": 296,
            "r_seats": 83,
            "cargo_demand": 62019
        }
    },
    {
        "iata": "RDD",
        "icao": "KRDD",
        "airportName": "Redding Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Redding",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.50899887",
        "longitude": "-122.2929993",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5067",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7003",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99587,
            "s_seats": 33664,
            "j_seats": 24617,
            "f_seats": 3103,
            "r_seats": 7847,
            "cargo_demand": 162597
        }
    },
    {
        "iata": "RDG",
        "icao": "KRDG",
        "airportName": "Reading Regional Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Reading",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.378502",
        "longitude": "-75.965202",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6350",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5151",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165103,
            "s_seats": 42111,
            "j_seats": 5260,
            "f_seats": 4429,
            "r_seats": 8858,
            "cargo_demand": 199700
        }
    },
    {
        "iata": "RDM",
        "icao": "KRDM",
        "airportName": "Roberts Field",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Redmond / Bend",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.2541008",
        "longitude": "-121.1500015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7040",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "6998",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63646,
            "s_seats": 31491,
            "j_seats": 56996,
            "f_seats": 17846,
            "r_seats": 7905,
            "cargo_demand": 397975
        }
    },
    {
        "iata": "RDO",
        "icao": "EPRA",
        "airportName": "Radom Airport",
        "location_country": "Poland",
        "location_city": "Radom",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.3891983",
        "longitude": "21.21330071",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6544",
                "runway_width": "203",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123846,
            "s_seats": 48506,
            "j_seats": 16421,
            "f_seats": 5561,
            "r_seats": 7328,
            "cargo_demand": 433246
        }
    },
    {
        "iata": "RDP",
        "icao": "VEDG",
        "airportName": "Kazi Nazrul Islam Airport",
        "location_country": "India",
        "location_city": "West Bengal",
        "location_state": "Durgapur",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.6225",
        "longitude": "87.243",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9186",
                "runway_width": "138",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61130,
            "s_seats": 44230,
            "j_seats": 32124,
            "f_seats": 6682,
            "r_seats": 3560,
            "cargo_demand": 420254
        }
    },
    {
        "iata": "RDR",
        "icao": "KRDR",
        "airportName": "Grand Forks Air Force Base",
        "location_country": "United States",
        "location_city": "North Dakota",
        "location_state": "Grand Forks",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.96110153",
        "longitude": "-97.40119934",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "12350",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105950,
            "s_seats": 30685,
            "j_seats": 23737,
            "f_seats": 13895,
            "r_seats": 7354,
            "cargo_demand": 221859
        }
    },
    {
        "iata": "RDS",
        "icao": "SAHS",
        "airportName": "Rincon de los Sauces Airport",
        "location_country": "Argentina",
        "location_city": "Neuquen",
        "location_state": "Rincon de los Sauces",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-37.3905983",
        "longitude": "-68.90419769",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188414,
            "s_seats": 31865,
            "j_seats": 22291,
            "f_seats": 5721,
            "r_seats": 5541,
            "cargo_demand": 235658
        }
    },
    {
        "iata": "RDU",
        "icao": "KRDU",
        "airportName": "Raleigh-Durham International Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Raleigh / Durham",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "35.87760162",
        "longitude": "-78.78749847",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "7500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3570",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 444233,
            "s_seats": 84859,
            "j_seats": 31958,
            "f_seats": 32734,
            "r_seats": 10000,
            "cargo_demand": 15091294
        }
    },
    {
        "iata": "RDZ",
        "icao": "LFCR",
        "airportName": "Rodez-Marcillac Airport",
        "location_country": "France",
        "location_city": "Midi-Pyrenees",
        "location_state": "Rodez",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.40790176",
        "longitude": "2.482670069",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6693",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87319,
            "s_seats": 30378,
            "j_seats": 59646,
            "f_seats": 7930,
            "r_seats": 3077,
            "cargo_demand": 63151
        }
    },
    {
        "iata": "REA",
        "icao": "NTGE",
        "airportName": "Reao Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Reao",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-18.46590042",
        "longitude": "-136.4400024",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3008",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90872,
            "s_seats": 26768,
            "j_seats": 9128,
            "f_seats": 10956,
            "r_seats": 3628,
            "cargo_demand": 172508
        }
    },
    {
        "iata": "REB",
        "icao": "EDAX",
        "airportName": "Rechlin-LArz Airfield",
        "location_country": "Germany",
        "location_city": "Mecklenburg-Vorpommern",
        "location_state": "Rechlin",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "53.30638886",
        "longitude": "12.75222206",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7808",
                "runway_width": "164",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2076,
            "s_seats": 2389,
            "j_seats": 1779,
            "f_seats": 487,
            "r_seats": 125,
            "cargo_demand": 95796
        }
    },
    {
        "iata": "REC",
        "icao": "SBRF",
        "airportName": "Recife/Guararapes-Gilberto Freyre International Airport",
        "location_country": "Brazil",
        "location_city": "Pernambuco",
        "location_state": "Recife",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.126489639",
        "longitude": "-34.92359924",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9865",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197445,
            "s_seats": 32992,
            "j_seats": 38103,
            "f_seats": 14385,
            "r_seats": 4172,
            "cargo_demand": 295223
        }
    },
    {
        "iata": "RED",
        "icao": "KRVL",
        "airportName": "Mifflin County Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Reedsville / Lewistown",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.67739868",
        "longitude": "-77.62680054",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1315,
            "s_seats": 1824,
            "j_seats": 1229,
            "f_seats": 51,
            "r_seats": 67,
            "cargo_demand": 33554
        }
    },
    {
        "iata": "REG",
        "icao": "LICR",
        "airportName": "Reggio di Calabria Airport",
        "location_country": "Italy",
        "location_city": "Calabria",
        "location_state": "Reggio di Calabria",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.071201",
        "longitude": "15.6516",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5574",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6549",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123318,
            "s_seats": 28923,
            "j_seats": 47928,
            "f_seats": 1164,
            "r_seats": 6935,
            "cargo_demand": 253470
        }
    },
    {
        "iata": "REI",
        "icao": "SOOR",
        "airportName": "Regina Airport",
        "location_country": "French Guiana",
        "location_city": "Regina",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "4.31472",
        "longitude": "-52.131699",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2625",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2919,
            "s_seats": 2541,
            "j_seats": 1618,
            "f_seats": 473,
            "r_seats": 111,
            "cargo_demand": 72641
        }
    },
    {
        "iata": "REL",
        "icao": "SAVT",
        "airportName": "Almirante Marcos A. Zar Airport",
        "location_country": "Argentina",
        "location_city": "Chubut",
        "location_state": "Trelew",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-43.2105",
        "longitude": "-65.2703",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "8399",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 82310,
            "s_seats": 46622,
            "j_seats": 19692,
            "f_seats": 14474,
            "r_seats": 3507,
            "cargo_demand": 226584
        }
    },
    {
        "iata": "REN",
        "icao": "UWOO",
        "airportName": "Orenburg Tsentralny Airport",
        "location_country": "Russia",
        "location_city": "Orenburg Oblast",
        "location_state": "Orenburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.79579926",
        "longitude": "55.45669937",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8212",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 78161,
            "s_seats": 39124,
            "j_seats": 17744,
            "f_seats": 5124,
            "r_seats": 7449,
            "cargo_demand": 300045
        }
    },
    {
        "iata": "REO",
        "icao": "KREO",
        "airportName": "Rome State Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Rome",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.57770157",
        "longitude": "-117.8850021",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 1990,
            "s_seats": 1945,
            "j_seats": 1442,
            "f_seats": 472,
            "r_seats": 157,
            "cargo_demand": 15961
        }
    },
    {
        "iata": "REP",
        "icao": "VDSR",
        "airportName": "Siem Reap International Airport",
        "location_country": "Cambodia",
        "location_city": "Siem Reap",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "13.41155",
        "longitude": "103.813044",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8366",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 155643,
            "s_seats": 73747,
            "j_seats": 121060,
            "f_seats": 58303,
            "r_seats": 10000,
            "cargo_demand": 8286079
        }
    },
    {
        "iata": "RER",
        "icao": "MGRT",
        "airportName": "Retalhuleu Airport",
        "location_country": "Guatemala",
        "location_city": "Retalhuleu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.52099991",
        "longitude": "-91.69730377",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5065",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101309,
            "s_seats": 47348,
            "j_seats": 16984,
            "f_seats": 18512,
            "r_seats": 5481,
            "cargo_demand": 449587
        }
    },
    {
        "iata": "RES",
        "icao": "SARE",
        "airportName": "Resistencia International Airport",
        "location_country": "Argentina",
        "location_city": "Chaco",
        "location_state": "Resistencia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.45",
        "longitude": "-59.0561",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "9088",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146448,
            "s_seats": 36485,
            "j_seats": 52922,
            "f_seats": 3461,
            "r_seats": 8429,
            "cargo_demand": 384259
        }
    },
    {
        "iata": "RET",
        "icao": "ENRS",
        "airportName": "Rost Airport",
        "location_country": "Norway",
        "location_city": "Rost",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "67.52780151",
        "longitude": "12.10330009",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2887",
                "runway_width": "98",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2319,
            "s_seats": 2953,
            "j_seats": 1603,
            "f_seats": 43,
            "r_seats": 105,
            "cargo_demand": 27004
        }
    },
    {
        "iata": "REU",
        "icao": "LERS",
        "airportName": "Reus Airport",
        "location_country": "Spain",
        "location_city": "Catalonia",
        "location_state": "Reus",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.1473999",
        "longitude": "1.167170048",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8054",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "1117",
                "runway_width": "115",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 91336,
            "s_seats": 32261,
            "j_seats": 46195,
            "f_seats": 11671,
            "r_seats": 8348,
            "cargo_demand": 85052
        }
    },
    {
        "iata": "REX",
        "icao": "MMRX",
        "airportName": "General Lucio Blanco International Airport",
        "location_country": "Mexico",
        "location_city": "Tamaulipas",
        "location_state": "Reynosa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.0089",
        "longitude": "-98.2285",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6243",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181231,
            "s_seats": 31723,
            "j_seats": 23799,
            "f_seats": 12204,
            "r_seats": 8674,
            "cargo_demand": 253519
        }
    },
    {
        "iata": "REY",
        "icao": "SLRY",
        "airportName": "Reyes Airport",
        "location_country": "Bolivia",
        "location_city": "Reyes",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.3044",
        "longitude": "-67.353401",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4921",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3205,
            "s_seats": 2697,
            "j_seats": 1457,
            "f_seats": 377,
            "r_seats": 39,
            "cargo_demand": 37758
        }
    },
    {
        "iata": "REZ",
        "icao": "SDRS",
        "airportName": "Resende Airport",
        "location_country": "Brazil",
        "location_city": "Rio de Janeiro",
        "location_state": "Resende",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.47850037",
        "longitude": "-44.4803009",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3111,
            "s_seats": 1648,
            "j_seats": 1100,
            "f_seats": 253,
            "r_seats": 160,
            "cargo_demand": 50866
        }
    },
    {
        "iata": "RFD",
        "icao": "KRFD",
        "airportName": "Chicago Rockford International Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Rockford",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "42.19540024",
        "longitude": "-89.09719849",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8199",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "10004",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 461681,
            "s_seats": 71218,
            "j_seats": 139438,
            "f_seats": 54664,
            "r_seats": 10000,
            "cargo_demand": 11632864
        }
    },
    {
        "iata": "RFG",
        "icao": "KRFG",
        "airportName": "Rooke Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Refugio",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.2936",
        "longitude": "-97.322998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2650",
                "runway_width": "40",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4361",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2941,
            "s_seats": 2415,
            "j_seats": 1460,
            "f_seats": 379,
            "r_seats": 157,
            "cargo_demand": 53705
        }
    },
    {
        "iata": "RFN",
        "icao": "BIRG",
        "airportName": "Raufarhofn Airport",
        "location_country": "Iceland",
        "location_city": "Raufarhofn",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "66.40640259",
        "longitude": "-15.91829967",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3533",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1910,
            "s_seats": 2054,
            "j_seats": 1338,
            "f_seats": 58,
            "r_seats": 63,
            "cargo_demand": 52815
        }
    },
    {
        "iata": "RFP",
        "icao": "NTTR",
        "airportName": "Raiatea Airport",
        "location_country": "French Polynesia",
        "location_city": "Society Islands",
        "location_state": "Raiatea",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.7229",
        "longitude": "-151.466003",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179783,
            "s_seats": 47836,
            "j_seats": 19502,
            "f_seats": 15777,
            "r_seats": 3617,
            "cargo_demand": 269137
        }
    },
    {
        "iata": "RGA",
        "icao": "SAWE",
        "airportName": "Hermes Quijada International Airport",
        "location_country": "Argentina",
        "location_city": "Tierra del Fuego",
        "location_state": "Rio Grande",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-53.7777",
        "longitude": "-67.7494",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "6562",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142495,
            "s_seats": 32724,
            "j_seats": 23711,
            "f_seats": 9239,
            "r_seats": 7094,
            "cargo_demand": 277389
        }
    },
    {
        "iata": "RGH",
        "icao": "VEBG",
        "airportName": "Balurghat Airport",
        "location_country": "India",
        "location_city": "West Bengal",
        "location_state": "Balurghat",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "25.26169968",
        "longitude": "88.79560089",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4906",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3718,
            "s_seats": 1896,
            "j_seats": 1716,
            "f_seats": 110,
            "r_seats": 147,
            "cargo_demand": 30273
        }
    },
    {
        "iata": "RGI",
        "icao": "NTTG",
        "airportName": "Rangiroa Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Rangiroa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.95429993",
        "longitude": "-147.6609955",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6890",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191813,
            "s_seats": 29182,
            "j_seats": 11684,
            "f_seats": 11377,
            "r_seats": 5912,
            "cargo_demand": 408775
        }
    },
    {
        "iata": "RGL",
        "icao": "SAWG",
        "airportName": "Piloto Civil Norberto Fernandez International Airport",
        "location_country": "Argentina",
        "location_city": "Santa Cruz",
        "location_state": "Rio Gallegos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-51.6089",
        "longitude": "-69.3126",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "11644",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 125458,
            "s_seats": 38029,
            "j_seats": 6162,
            "f_seats": 9927,
            "r_seats": 3444,
            "cargo_demand": 455786
        }
    },
    {
        "iata": "RGN",
        "icao": "VYYY",
        "airportName": "Yangon International Airport",
        "location_country": "Myanmar",
        "location_city": "Yangon",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "16.90730095",
        "longitude": "96.1332016",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "11200",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 200659,
            "s_seats": 95646,
            "j_seats": 29726,
            "f_seats": 39280,
            "r_seats": 10000,
            "cargo_demand": 28603846
        }
    },
    {
        "iata": "RGS",
        "icao": "LEBG",
        "airportName": "Burgos Airport",
        "location_country": "Spain",
        "location_city": "Castile and Leon",
        "location_state": "Burgos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.357601",
        "longitude": "-3.62076",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "4393",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 164753,
            "s_seats": 35348,
            "j_seats": 11156,
            "f_seats": 4713,
            "r_seats": 8148,
            "cargo_demand": 91360
        }
    },
    {
        "iata": "RGT",
        "icao": "WIPR",
        "airportName": "Japura Airport",
        "location_country": "Indonesia",
        "location_city": "Rengat",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.352807999",
        "longitude": "102.3349991",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109707,
            "s_seats": 48169,
            "j_seats": 43841,
            "f_seats": 14495,
            "r_seats": 5197,
            "cargo_demand": 483694
        }
    },
    {
        "iata": "RHG",
        "icao": "HRYU",
        "airportName": "Ruhengeri Airport",
        "location_country": "Rwanda",
        "location_city": "Ruhengeri",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.496873",
        "longitude": "29.631343",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "grass"
            }
        ],
        "demand": {
            "y_seats": 2065,
            "s_seats": 1778,
            "j_seats": 1517,
            "f_seats": 22,
            "r_seats": 49,
            "cargo_demand": 8795
        }
    },
    {
        "iata": "RHI",
        "icao": "KRHI",
        "airportName": "Rhinelander-Oneida County Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Rhinelander",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.63119888",
        "longitude": "-89.46749878",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5600",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "6800",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4504",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81720,
            "s_seats": 30581,
            "j_seats": 22928,
            "f_seats": 11205,
            "r_seats": 3217,
            "cargo_demand": 97374
        }
    },
    {
        "iata": "RHL",
        "icao": "YRYH",
        "airportName": "Roy Hill Station Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Roy Hill Station",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.62581589",
        "longitude": "119.9590302",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "S"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3116",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1772,
            "s_seats": 2283,
            "j_seats": 1933,
            "f_seats": 372,
            "r_seats": 35,
            "cargo_demand": 70426
        }
    },
    {
        "iata": "RHN",
        "icao": "FYSA",
        "airportName": "Skorpion Mine Airport",
        "location_country": "Namibia",
        "location_city": "Rosh Pinah",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.87639999",
        "longitude": "16.64780045",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5741",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2707,
            "s_seats": 1580,
            "j_seats": 1158,
            "f_seats": 233,
            "r_seats": 93,
            "cargo_demand": 75045
        }
    },
    {
        "iata": "RHO",
        "icao": "LGRP",
        "airportName": "Rhodes International Airport",
        "location_country": "Greece",
        "location_city": "Rhodes",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.40539932",
        "longitude": "28.08620071",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10846",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59546,
            "s_seats": 30989,
            "j_seats": 38772,
            "f_seats": 4381,
            "r_seats": 8996,
            "cargo_demand": 225267
        }
    },
    {
        "iata": "RHV",
        "icao": "KRHV",
        "airportName": "Reid-Hillview Airport of Santa Clara County",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "San Jose",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.332901",
        "longitude": "-121.8190002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "3100",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "3099",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1242,
            "s_seats": 1997,
            "j_seats": 1728,
            "f_seats": 11,
            "r_seats": 110,
            "cargo_demand": 50384
        }
    },
    {
        "iata": "RIA",
        "icao": "SBSM",
        "airportName": "Santa Maria Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Santa Maria",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.711399",
        "longitude": "-53.688202",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "2/20",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103975,
            "s_seats": 37076,
            "j_seats": 35545,
            "f_seats": 1689,
            "r_seats": 6553,
            "cargo_demand": 488759
        }
    },
    {
        "iata": "RIB",
        "icao": "SLRI",
        "airportName": "Riberalta Airport",
        "location_country": "Bolivia",
        "location_city": "Riberalta",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11",
        "longitude": "-66",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5906",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3923,
            "s_seats": 1765,
            "j_seats": 1764,
            "f_seats": 246,
            "r_seats": 105,
            "cargo_demand": 28808
        }
    },
    {
        "iata": "RIC",
        "icao": "KRIC",
        "airportName": "Richmond International Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Richmond",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.50519943",
        "longitude": "-77.31970215",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6607",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "5326",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "9003",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 440682,
            "s_seats": 91870,
            "j_seats": 74919,
            "f_seats": 50768,
            "r_seats": 10000,
            "cargo_demand": 23829239
        }
    },
    {
        "iata": "RID",
        "icao": "KRID",
        "airportName": "Richmond Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Richmond",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "39.75719833",
        "longitude": "-84.84279633",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3560",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1794,
            "s_seats": 1724,
            "j_seats": 1804,
            "f_seats": 263,
            "r_seats": 145,
            "cargo_demand": 37782
        }
    },
    {
        "iata": "RIE",
        "icao": "KRPD",
        "airportName": "Rice Lake Regional Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Rice Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "45.418999",
        "longitude": "-91.773499",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5700",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3500",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2475,
            "s_seats": 2583,
            "j_seats": 1493,
            "f_seats": 404,
            "r_seats": 116,
            "cargo_demand": 93549
        }
    },
    {
        "iata": "RIF",
        "icao": "KRIF",
        "airportName": "Richfield Municipal Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Richfield",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.7364006",
        "longitude": "-112.098999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6600",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1387,
            "s_seats": 2261,
            "j_seats": 1205,
            "f_seats": 41,
            "r_seats": 149,
            "cargo_demand": 6610
        }
    },
    {
        "iata": "RIJ",
        "icao": "SPJA",
        "airportName": "Juan Simons Vela Airport",
        "location_country": "Peru",
        "location_city": "Rioja",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.067860126",
        "longitude": "-77.16000366",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6168",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76349,
            "s_seats": 44139,
            "j_seats": 43229,
            "f_seats": 9907,
            "r_seats": 8359,
            "cargo_demand": 196462
        }
    },
    {
        "iata": "RIL",
        "icao": "KRIL",
        "airportName": "Garfield County Regional Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Rifle",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.526299",
        "longitude": "-107.726997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105788,
            "s_seats": 32874,
            "j_seats": 36843,
            "f_seats": 12024,
            "r_seats": 7229,
            "cargo_demand": 300556
        }
    },
    {
        "iata": "RIM",
        "icao": "SPLN",
        "airportName": "San Nicolas Airport",
        "location_country": "Peru",
        "location_city": "Rodriguez de Mendoza",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.392310143",
        "longitude": "-77.50119781",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5915",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1449,
            "s_seats": 2335,
            "j_seats": 1485,
            "f_seats": 319,
            "r_seats": 28,
            "cargo_demand": 21536
        }
    },
    {
        "iata": "RIN",
        "icao": "AGRC",
        "airportName": "Ringgi Cove Airport",
        "location_country": "Solomon Islands",
        "location_city": "Ringgi Cove",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.126390457",
        "longitude": "157.1430054",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2461",
                "runway_width": "75",
                "runway_material": "Coral grass"
            }
        ],
        "demand": {
            "y_seats": 1588,
            "s_seats": 2784,
            "j_seats": 1949,
            "f_seats": 417,
            "r_seats": 190,
            "cargo_demand": 65848
        }
    },
    {
        "iata": "RIR",
        "icao": "KRIR",
        "airportName": "Flabob Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Riverside",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.9897",
        "longitude": "-117.411003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3200",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3800,
            "s_seats": 2680,
            "j_seats": 1741,
            "f_seats": 344,
            "r_seats": 75,
            "cargo_demand": 91482
        }
    },
    {
        "iata": "RIS",
        "icao": "RJER",
        "airportName": "Rishiri Airport",
        "location_country": "Japan",
        "location_city": "Rishiri Island",
        "location_state": "Rishiri",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.24200058",
        "longitude": "141.1860046",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5906",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99085,
            "s_seats": 48920,
            "j_seats": 54230,
            "f_seats": 9828,
            "r_seats": 5217,
            "cargo_demand": 277182
        }
    },
    {
        "iata": "RIV",
        "icao": "KRIV",
        "airportName": "March Air Reserve Base",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Riverside",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.880699",
        "longitude": "-117.259003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3010",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "13300",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 51925,
            "s_seats": 44816,
            "j_seats": 34579,
            "f_seats": 2602,
            "r_seats": 4480,
            "cargo_demand": 208689
        }
    },
    {
        "iata": "RIW",
        "icao": "KRIW",
        "airportName": "Central Wyoming Regional Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Riverton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.06420136",
        "longitude": "-108.4599991",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4800",
                "runway_width": "70",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "8203",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64174,
            "s_seats": 46052,
            "j_seats": 9510,
            "f_seats": 5003,
            "r_seats": 6845,
            "cargo_demand": 79702
        }
    },
    {
        "iata": "RIX",
        "icao": "EVRA",
        "airportName": "Riga International Airport",
        "location_country": "Latvia",
        "location_city": "Riga",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "56.92359924",
        "longitude": "23.97109985",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 217740,
            "s_seats": 78223,
            "j_seats": 158913,
            "f_seats": 42010,
            "r_seats": 10000,
            "cargo_demand": 26463987
        }
    },
    {
        "iata": "RIY",
        "icao": "OYRN",
        "airportName": "Riyan Airport",
        "location_country": "Yemen",
        "location_city": "Mukalla",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.6626",
        "longitude": "49.375",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9846",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100088,
            "s_seats": 28831,
            "j_seats": 44001,
            "f_seats": 19244,
            "r_seats": 5998,
            "cargo_demand": 49238
        }
    },
    {
        "iata": "RJA",
        "icao": "VORY",
        "airportName": "Rajahmundry Airport",
        "location_country": "India",
        "location_city": "Andhra Pradesh",
        "location_state": "Rajahmundry",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.11039925",
        "longitude": "81.81819916",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10384",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56381,
            "s_seats": 40451,
            "j_seats": 17408,
            "f_seats": 12439,
            "r_seats": 5476,
            "cargo_demand": 201573
        }
    },
    {
        "iata": "RJH",
        "icao": "VGRJ",
        "airportName": "Shah Makhdum Airport",
        "location_country": "Bangladesh",
        "location_city": "Rajshahi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.43720055",
        "longitude": "88.61650085",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63255,
            "s_seats": 48235,
            "j_seats": 14543,
            "f_seats": 5876,
            "r_seats": 8352,
            "cargo_demand": 368236
        }
    },
    {
        "iata": "RJK",
        "icao": "LDRI",
        "airportName": "Rijeka Airport",
        "location_country": "Croatia",
        "location_city": "Rijeka",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.21689987",
        "longitude": "14.5703001",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8164",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 169172,
            "s_seats": 42055,
            "j_seats": 34029,
            "f_seats": 19794,
            "r_seats": 5480,
            "cargo_demand": 102111
        }
    },
    {
        "iata": "RJL",
        "icao": "LELO",
        "airportName": "Logrono-Agoncillo Airport",
        "location_country": "Spain",
        "location_city": "La Rioja",
        "location_state": "Logrono",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.460953",
        "longitude": "-2.322235",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6562",
                "runway_width": "",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 53179,
            "s_seats": 42408,
            "j_seats": 30830,
            "f_seats": 4175,
            "r_seats": 9931,
            "cargo_demand": 193611
        }
    },
    {
        "iata": "RJN",
        "icao": "OIKR",
        "airportName": "Rafsanjan Airport",
        "location_country": "Iran",
        "location_city": "Rafsanjan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.29770088",
        "longitude": "56.05110168",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9814",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61966,
            "s_seats": 38430,
            "j_seats": 36260,
            "f_seats": 13836,
            "r_seats": 7339,
            "cargo_demand": 408513
        }
    },
    {
        "iata": "RKA",
        "icao": "NTKK",
        "airportName": "Aratika-Nord Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Aratika",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.48530006",
        "longitude": "-145.4700012",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4150",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1480,
            "s_seats": 1692,
            "j_seats": 1110,
            "f_seats": 126,
            "r_seats": 158,
            "cargo_demand": 22002
        }
    },
    {
        "iata": "RKD",
        "icao": "KRKD",
        "airportName": "Knox County Regional Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Rockland",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.06010056",
        "longitude": "-69.09919739",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3900",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5007",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2592,
            "s_seats": 2309,
            "j_seats": 1006,
            "f_seats": 358,
            "r_seats": 87,
            "cargo_demand": 9554
        }
    },
    {
        "iata": "RKE",
        "icao": "EKRK",
        "airportName": "Roskilde Airport",
        "location_country": "Denmark",
        "location_city": "Copenhagen",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.58560181",
        "longitude": "12.13140011",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4921",
                "runway_width": "105",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5709",
                "runway_width": "105",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184685,
            "s_seats": 41695,
            "j_seats": 15217,
            "f_seats": 9117,
            "r_seats": 8886,
            "cargo_demand": 347027
        }
    },
    {
        "iata": "RKH",
        "icao": "KUZA",
        "airportName": "Rock Hill/York County Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Rock Hill",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.9878006",
        "longitude": "-81.05719757",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2060,
            "s_seats": 2456,
            "j_seats": 1515,
            "f_seats": 370,
            "r_seats": 131,
            "cargo_demand": 22238
        }
    },
    {
        "iata": "RKP",
        "icao": "KRKP",
        "airportName": "Aransas County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Rockport",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.08679962",
        "longitude": "-97.04460144",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4350",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5608",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4498",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2334,
            "s_seats": 2772,
            "j_seats": 1565,
            "f_seats": 363,
            "r_seats": 186,
            "cargo_demand": 99445
        }
    },
    {
        "iata": "RKR",
        "icao": "KRKR",
        "airportName": "Robert S. Kerr Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Poteau",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.02159882",
        "longitude": "-94.62129974",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4006",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1547,
            "s_seats": 1740,
            "j_seats": 1215,
            "f_seats": 127,
            "r_seats": 124,
            "cargo_demand": 66288
        }
    },
    {
        "iata": "RKS",
        "icao": "KRKS",
        "airportName": "Rock Springs-Sweetwater County Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Rock Springs",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.5942",
        "longitude": "-109.065001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5223",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184612,
            "s_seats": 35471,
            "j_seats": 46352,
            "f_seats": 5881,
            "r_seats": 6309,
            "cargo_demand": 371362
        }
    },
    {
        "iata": "RKT",
        "icao": "OMRK",
        "airportName": "Ras Al Khaimah International Airport",
        "location_country": "United Arab Emirates",
        "location_city": "Ras al-Khaimah",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.6135006",
        "longitude": "55.93880081",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "12336",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124565,
            "s_seats": 39316,
            "j_seats": 54109,
            "f_seats": 11797,
            "r_seats": 8796,
            "cargo_demand": 166970
        }
    },
    {
        "iata": "RKV",
        "icao": "BIRK",
        "airportName": "Reykjavik Airport",
        "location_country": "Iceland",
        "location_city": "Reykjavik",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.12999725",
        "longitude": "-21.94059944",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5141",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "3150",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4035",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183349,
            "s_seats": 39981,
            "j_seats": 40358,
            "f_seats": 7662,
            "r_seats": 7802,
            "cargo_demand": 270689
        }
    },
    {
        "iata": "RKW",
        "icao": "KRKW",
        "airportName": "Rockwood Municipal Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Rockwood",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.92229843",
        "longitude": "-84.68969727",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4995",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1723,
            "s_seats": 1682,
            "j_seats": 1387,
            "f_seats": 247,
            "r_seats": 105,
            "cargo_demand": 61918
        }
    },
    {
        "iata": "RLD",
        "icao": "KRLD",
        "airportName": "Richland Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Richland",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "46.30559921",
        "longitude": "-119.3040009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4009",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3995",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2638,
            "s_seats": 2559,
            "j_seats": 1438,
            "f_seats": 278,
            "r_seats": 112,
            "cargo_demand": 96287
        }
    },
    {
        "iata": "RLG",
        "icao": "ETNL",
        "airportName": "Rostock-Laage Airport",
        "location_country": "Germany",
        "location_city": "Mecklenburg-Vorpommern",
        "location_state": "Rostock",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.91820145",
        "longitude": "12.27830029",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 156444,
            "s_seats": 34169,
            "j_seats": 49564,
            "f_seats": 17393,
            "r_seats": 4264,
            "cargo_demand": 379074
        }
    },
    {
        "iata": "RLO",
        "icao": "SAOS",
        "airportName": "Valle del Conlara Airport",
        "location_country": "Argentina",
        "location_city": "San Luis",
        "location_state": "Villa de Merlo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-32.38470078",
        "longitude": "-65.18650055",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "8366",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2973,
            "s_seats": 2078,
            "j_seats": 1928,
            "f_seats": 98,
            "r_seats": 84,
            "cargo_demand": 86377
        }
    },
    {
        "iata": "RMA",
        "icao": "YROM",
        "airportName": "Roma Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Roma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.54500008",
        "longitude": "148.7749939",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2628",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4934",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52275,
            "s_seats": 38370,
            "j_seats": 40970,
            "f_seats": 19604,
            "r_seats": 3123,
            "cargo_demand": 461202
        }
    },
    {
        "iata": "RME",
        "icao": "KRME",
        "airportName": "Griffiss International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Rome",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.23379898",
        "longitude": "-75.40699768",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "11820",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 114311,
            "s_seats": 32537,
            "j_seats": 38419,
            "f_seats": 9388,
            "r_seats": 7962,
            "cargo_demand": 327462
        }
    },
    {
        "iata": "RMF",
        "icao": "HEMA",
        "airportName": "Marsa Alam International Airport",
        "location_country": "Egypt",
        "location_city": "Marsa Alam",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.5571003",
        "longitude": "34.58369827",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52707,
            "s_seats": 40798,
            "j_seats": 8221,
            "f_seats": 11343,
            "r_seats": 4638,
            "cargo_demand": 197199
        }
    },
    {
        "iata": "RMG",
        "icao": "KRMG",
        "airportName": "Richard B. Russell Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Rome",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.3506012",
        "longitude": "-85.15799713",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "4497",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3596",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115040,
            "s_seats": 39120,
            "j_seats": 8087,
            "f_seats": 14141,
            "r_seats": 7715,
            "cargo_demand": 216259
        }
    },
    {
        "iata": "RMI",
        "icao": "LIPR",
        "airportName": "Federico Fellini International Airport",
        "location_country": "Italy",
        "location_city": "Emilia-Romagna",
        "location_state": "Rimini",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.020302",
        "longitude": "12.6117",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9828",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193081,
            "s_seats": 44707,
            "j_seats": 20393,
            "f_seats": 5415,
            "r_seats": 7028,
            "cargo_demand": 131111
        }
    },
    {
        "iata": "RMK",
        "icao": "YREN",
        "airportName": "Renmark Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Renmark",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.19639969",
        "longitude": "140.673996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5709",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3360",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 90636,
            "s_seats": 47959,
            "j_seats": 21380,
            "f_seats": 1684,
            "r_seats": 5514,
            "cargo_demand": 142597
        }
    },
    {
        "iata": "RML",
        "icao": "VCCC",
        "airportName": "Ratmalana Airport",
        "location_country": "Sri Lanka",
        "location_city": "Colombo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.821990013",
        "longitude": "79.88619995",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6013",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197071,
            "s_seats": 29220,
            "j_seats": 8858,
            "f_seats": 14158,
            "r_seats": 6705,
            "cargo_demand": 125497
        }
    },
    {
        "iata": "RMQ",
        "icao": "RCMQ",
        "airportName": "Taichung Airport",
        "location_country": "Taiwan",
        "location_city": "Taichung",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.2647",
        "longitude": "120.621002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "12000",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 144278,
            "s_seats": 40866,
            "j_seats": 57421,
            "f_seats": 2996,
            "r_seats": 8087,
            "cargo_demand": 191151
        }
    },
    {
        "iata": "RMS",
        "icao": "ETAR",
        "airportName": "Ramstein Air Base",
        "location_country": "Germany",
        "location_city": "Rhineland-Palatinate",
        "location_state": "Ramstein",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.436901",
        "longitude": "7.60028",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10498",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "9278",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115214,
            "s_seats": 41270,
            "j_seats": 37400,
            "f_seats": 2211,
            "r_seats": 7956,
            "cargo_demand": 290513
        }
    },
    {
        "iata": "RMT",
        "icao": "NTAM",
        "airportName": "Rimatara Airport",
        "location_country": "French Polynesia",
        "location_city": "Austral Islands",
        "location_state": "Rimatara",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.63725",
        "longitude": "-152.8059",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4565",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1518,
            "s_seats": 2462,
            "j_seats": 1208,
            "f_seats": 305,
            "r_seats": 37,
            "cargo_demand": 22998
        }
    },
    {
        "iata": "RMY",
        "icao": "KMPI",
        "airportName": "Mariposa-Yosemite Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Mariposa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.51089859",
        "longitude": "-120.0400009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3306",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3227,
            "s_seats": 1698,
            "j_seats": 1715,
            "f_seats": 169,
            "r_seats": 109,
            "cargo_demand": 11823
        }
    },
    {
        "iata": "RNA",
        "icao": "AGAR",
        "airportName": "Ulawa Airport",
        "location_country": "Solomon Islands",
        "location_city": "Ulawa Island",
        "location_state": "Arona",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.860543583",
        "longitude": "161.9795465",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2822",
                "runway_width": "66",
                "runway_material": "Coral"
            }
        ],
        "demand": {
            "y_seats": 2057,
            "s_seats": 2860,
            "j_seats": 1868,
            "f_seats": 232,
            "r_seats": 167,
            "cargo_demand": 53681
        }
    },
    {
        "iata": "RNB",
        "icao": "ESDF",
        "airportName": "Ronneby Airport",
        "location_country": "Sweden",
        "location_city": "Ronneby / Karlskrona",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.266701",
        "longitude": "15.265",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7648",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198775,
            "s_seats": 42104,
            "j_seats": 15723,
            "f_seats": 6032,
            "r_seats": 3320,
            "cargo_demand": 285267
        }
    },
    {
        "iata": "RNC",
        "icao": "KRNC",
        "airportName": "Warren County Memorial Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "McMinnville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.69869995",
        "longitude": "-85.84380341",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3955,
            "s_seats": 2480,
            "j_seats": 1426,
            "f_seats": 130,
            "r_seats": 191,
            "cargo_demand": 19326
        }
    },
    {
        "iata": "RND",
        "icao": "KRND",
        "airportName": "Randolph Air Force Base",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "San Antonio",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "29.52969933",
        "longitude": "-98.27890015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "8351",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "8353",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 106442,
            "s_seats": 87491,
            "j_seats": 159473,
            "f_seats": 34283,
            "r_seats": 10000,
            "cargo_demand": 29333036
        }
    },
    {
        "iata": "RNE",
        "icao": "LFLO",
        "airportName": "Roanne Renaison Airport",
        "location_country": "France",
        "location_city": "Rhone-Alpes",
        "location_state": "Roanne",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.05830002",
        "longitude": "4.00138998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4790",
                "runway_width": "98",
                "runway_material": "Paved"
            }
        ],
        "demand": {
            "y_seats": 69693,
            "s_seats": 40848,
            "j_seats": 41257,
            "f_seats": 2343,
            "r_seats": 4977,
            "cargo_demand": 136755
        }
    },
    {
        "iata": "RNH",
        "icao": "KRNH",
        "airportName": "New Richmond Regional Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "New Richmond",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.14830017",
        "longitude": "-92.5381012",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2110",
                "runway_width": "75",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4003",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68934,
            "s_seats": 45784,
            "j_seats": 22230,
            "f_seats": 16759,
            "r_seats": 7574,
            "cargo_demand": 482049
        }
    },
    {
        "iata": "RNJ",
        "icao": "RORY",
        "airportName": "Yoron Airport",
        "location_country": "Japan",
        "location_city": "Amami Islands",
        "location_state": "Yoronjima",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.044001",
        "longitude": "128.401993",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4340",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117330,
            "s_seats": 35460,
            "j_seats": 33440,
            "f_seats": 15404,
            "r_seats": 9847,
            "cargo_demand": 446132
        }
    },
    {
        "iata": "RNL",
        "icao": "AGGR",
        "airportName": "Rennell/Tingoa Airport",
        "location_country": "Solomon Islands",
        "location_city": "Rennell Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.53390026",
        "longitude": "160.0630035",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2206",
                "runway_width": "66",
                "runway_material": "Coral"
            }
        ],
        "demand": {
            "y_seats": 3551,
            "s_seats": 2128,
            "j_seats": 1857,
            "f_seats": 453,
            "r_seats": 93,
            "cargo_demand": 66004
        }
    },
    {
        "iata": "RNN",
        "icao": "EKRN",
        "airportName": "Bornholm Airport",
        "location_country": "Denmark",
        "location_city": "Ronne",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.06330109",
        "longitude": "14.75959969",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6568",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112187,
            "s_seats": 47050,
            "j_seats": 48332,
            "f_seats": 19374,
            "r_seats": 4382,
            "cargo_demand": 319426
        }
    },
    {
        "iata": "RNO",
        "icao": "KRNO",
        "airportName": "Reno-Tahoe International Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Reno",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "39.49909973",
        "longitude": "-119.7679977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6102",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "11002",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 342368,
            "s_seats": 95189,
            "j_seats": 153991,
            "f_seats": 62029,
            "r_seats": 10000,
            "cargo_demand": 8358193
        }
    },
    {
        "iata": "RNS",
        "icao": "LFRN",
        "airportName": "Rennes-Saint-Jacques Airport",
        "location_country": "France",
        "location_city": "Brittany",
        "location_state": "Rennes",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.0695",
        "longitude": "-1.73479",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2788",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99149,
            "s_seats": 35120,
            "j_seats": 40913,
            "f_seats": 19551,
            "r_seats": 5582,
            "cargo_demand": 129433
        }
    },
    {
        "iata": "RNT",
        "icao": "KRNT",
        "airportName": "Renton Municipal Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Renton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "47.49309921",
        "longitude": "-122.2160034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5382",
                "runway_width": "200",
                "runway_material": "ASPH-CONC-G"
            }
        ],
        "demand": {
            "y_seats": 3668,
            "s_seats": 2145,
            "j_seats": 1474,
            "f_seats": 74,
            "r_seats": 38,
            "cargo_demand": 25733
        }
    },
    {
        "iata": "RNZ",
        "icao": "KRZL",
        "airportName": "Jasper County Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Rensselaer",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.94789886",
        "longitude": "-87.18260193",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "1930",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4001",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1679,
            "s_seats": 2170,
            "j_seats": 1223,
            "f_seats": 284,
            "r_seats": 33,
            "cargo_demand": 93337
        }
    },
    {
        "iata": "ROA",
        "icao": "KROA",
        "airportName": "Roanoke-Blacksburg Regional Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Roanoke",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.3255",
        "longitude": "-79.975403",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6800",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5810",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 361779,
            "s_seats": 89453,
            "j_seats": 72913,
            "f_seats": 51605,
            "r_seats": 10000,
            "cargo_demand": 4332566
        }
    },
    {
        "iata": "ROB",
        "icao": "GLRB",
        "airportName": "Roberts International Airport",
        "location_country": "Liberia",
        "location_city": "Monrovia",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "6.23379",
        "longitude": "-10.3623",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "11000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 432801,
            "s_seats": 74215,
            "j_seats": 128556,
            "f_seats": 24190,
            "r_seats": 10000,
            "cargo_demand": 4293226
        }
    },
    {
        "iata": "ROC",
        "icao": "KROC",
        "airportName": "Greater Rochester International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Rochester",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.1189",
        "longitude": "-77.672401",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "6402",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115482,
            "s_seats": 26927,
            "j_seats": 36867,
            "f_seats": 10462,
            "r_seats": 5895,
            "cargo_demand": 407384
        }
    },
    {
        "iata": "ROD",
        "icao": "FARS",
        "airportName": "Robertson Airfield",
        "location_country": "South Africa",
        "location_city": "Robertson",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.81219864",
        "longitude": "19.90279961",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4921",
                "runway_width": "49",
                "runway_material": "ASP. Avgas available."
            },
            {
                "runway_name": "14/32",
                "runway_length": "2395",
                "runway_width": "59",
                "runway_material": "Grass - caution moles"
            }
        ],
        "demand": {
            "y_seats": 140651,
            "s_seats": 27814,
            "j_seats": 54872,
            "f_seats": 7228,
            "r_seats": 8772,
            "cargo_demand": 105332
        }
    },
    {
        "iata": "ROG",
        "icao": "KROG",
        "airportName": "Rogers Municipal Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Rogers",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.37229919",
        "longitude": "-94.10690308",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6011",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3482,
            "s_seats": 1672,
            "j_seats": 1199,
            "f_seats": 134,
            "r_seats": 64,
            "cargo_demand": 81195
        }
    },
    {
        "iata": "ROH",
        "icao": "YROB",
        "airportName": "Robinhood Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Robinhood",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.84499931",
        "longitude": "143.7100067",
        "region": "OC",
        "runway": [
            {
                "runway_name": "34/11",
                "runway_length": "0",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 3659,
            "s_seats": 2200,
            "j_seats": 1433,
            "f_seats": 316,
            "r_seats": 145,
            "cargo_demand": 9851
        }
    },
    {
        "iata": "ROI",
        "icao": "VTUV",
        "airportName": "Roi Et Airport",
        "location_country": "Thailand",
        "location_city": "Roi Et",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.11680031",
        "longitude": "103.7740021",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76273,
            "s_seats": 43688,
            "j_seats": 16510,
            "f_seats": 16849,
            "r_seats": 9194,
            "cargo_demand": 134939
        }
    },
    {
        "iata": "ROK",
        "icao": "YBRK",
        "airportName": "Rockhampton Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Rockhampton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.38190079",
        "longitude": "150.4750061",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5397",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "8622",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50658,
            "s_seats": 38015,
            "j_seats": 51823,
            "f_seats": 1512,
            "r_seats": 8832,
            "cargo_demand": 445243
        }
    },
    {
        "iata": "RON",
        "icao": "SKPA",
        "airportName": "Juan Jose Rondon Airport",
        "location_country": "Colombia",
        "location_city": "Paipa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "5.76454",
        "longitude": "-73.1054",
        "region": "SA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5382",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3174,
            "s_seats": 1930,
            "j_seats": 1876,
            "f_seats": 74,
            "r_seats": 71,
            "cargo_demand": 56201
        }
    },
    {
        "iata": "ROO",
        "icao": "SWRD",
        "airportName": "Maestro Marinho Franco Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Rondonopolis",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.586",
        "longitude": "-54.7248",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "6070",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 158081,
            "s_seats": 46731,
            "j_seats": 49997,
            "f_seats": 16482,
            "r_seats": 9438,
            "cargo_demand": 474070
        }
    },
    {
        "iata": "ROP",
        "icao": "PGRO",
        "airportName": "Rota International Airport",
        "location_country": "Northern Mariana Islands",
        "location_city": "Rota",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.1743",
        "longitude": "145.242996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 93703,
            "s_seats": 35924,
            "j_seats": 20417,
            "f_seats": 14814,
            "r_seats": 3988,
            "cargo_demand": 199122
        }
    },
    {
        "iata": "ROR",
        "icao": "PTRO",
        "airportName": "Roman Tmetuchl International Airport",
        "location_country": "Palau",
        "location_city": "Koror",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.36731",
        "longitude": "134.544236",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187467,
            "s_seats": 25059,
            "j_seats": 24905,
            "f_seats": 2676,
            "r_seats": 5936,
            "cargo_demand": 368512
        }
    },
    {
        "iata": "ROS",
        "icao": "SAAR",
        "airportName": "Rosario - Islas Malvinas International Airport",
        "location_country": "Argentina",
        "location_city": "Santa Fe",
        "location_state": "Rosario",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-32.9036",
        "longitude": "-60.785",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 170328,
            "s_seats": 38282,
            "j_seats": 24997,
            "f_seats": 10887,
            "r_seats": 8731,
            "cargo_demand": 201920
        }
    },
    {
        "iata": "ROT",
        "icao": "NZRO",
        "airportName": "Rotorua Regional Airport",
        "location_country": "New Zealand",
        "location_city": "Rotorua",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.10919952",
        "longitude": "176.3170013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "2732",
                "runway_width": "164",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "5321",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121788,
            "s_seats": 32088,
            "j_seats": 24486,
            "f_seats": 7343,
            "r_seats": 6521,
            "cargo_demand": 96620
        }
    },
    {
        "iata": "ROU",
        "icao": "LBRS",
        "airportName": "Ruse Airport",
        "location_country": "Bulgaria",
        "location_city": "Ruse",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.694801",
        "longitude": "26.0567",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8210",
                "runway_width": "165",
                "runway_material": "CON"
            },
            {
                "runway_name": "05L/23R",
                "runway_length": "5784",
                "runway_width": "250",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 3032,
            "s_seats": 1734,
            "j_seats": 1063,
            "f_seats": 435,
            "r_seats": 134,
            "cargo_demand": 88055
        }
    },
    {
        "iata": "ROV",
        "icao": "URRP",
        "airportName": "Platov International Airport",
        "location_country": "Russia",
        "location_city": "Rostov Oblast",
        "location_state": "Novocherkassk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "47.493888",
        "longitude": "39.924722",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 199044,
            "s_seats": 87486,
            "j_seats": 135980,
            "f_seats": 69304,
            "r_seats": 10000,
            "cargo_demand": 7451377
        }
    },
    {
        "iata": "ROW",
        "icao": "KROW",
        "airportName": "Roswell International Air Center",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Roswell",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.301601",
        "longitude": "-104.530998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "13001",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7425",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17/35",
                "runway_length": "9999",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163860,
            "s_seats": 33307,
            "j_seats": 21398,
            "f_seats": 17246,
            "r_seats": 9508,
            "cargo_demand": 155802
        }
    },
    {
        "iata": "ROX",
        "icao": "KROX",
        "airportName": "Roseau Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Roseau",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "48.85599899",
        "longitude": "-95.6969986",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2504",
                "runway_width": "250",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4400",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3110,
            "s_seats": 1932,
            "j_seats": 1002,
            "f_seats": 487,
            "r_seats": 109,
            "cargo_demand": 89842
        }
    },
    {
        "iata": "ROY",
        "icao": "SAWM",
        "airportName": "Rio Mayo Airport",
        "location_country": "Argentina",
        "location_city": "Chubut",
        "location_state": "Rio Mayo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-45.70389938",
        "longitude": "-70.24559784",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1413,
            "s_seats": 2410,
            "j_seats": 1070,
            "f_seats": 497,
            "r_seats": 167,
            "cargo_demand": 18480
        }
    },
    {
        "iata": "ROZ",
        "icao": "LERT",
        "airportName": "US Naval Station Rota",
        "location_country": "Spain",
        "location_city": "Andalusia",
        "location_state": "Rota",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.64519882",
        "longitude": "-6.349460125",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "12106",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67324,
            "s_seats": 43270,
            "j_seats": 35945,
            "f_seats": 14840,
            "r_seats": 6073,
            "cargo_demand": 285862
        }
    },
    {
        "iata": "RPB",
        "icao": "YRRB",
        "airportName": "Roper Bar Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Roper Bar",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.734814",
        "longitude": "134.525485",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4107",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3319,
            "s_seats": 2737,
            "j_seats": 1003,
            "f_seats": 174,
            "r_seats": 20,
            "cargo_demand": 15826
        }
    },
    {
        "iata": "RPM",
        "icao": "YNGU",
        "airportName": "Ngukurr Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Ngukurr",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.72280025",
        "longitude": "134.746994",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5020",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187573,
            "s_seats": 47248,
            "j_seats": 17659,
            "f_seats": 18696,
            "r_seats": 6337,
            "cargo_demand": 307463
        }
    },
    {
        "iata": "RPN",
        "icao": "LLIB",
        "airportName": "Rosh Pina Airport",
        "location_country": "Israel",
        "location_city": "Rosh Pinna",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.98099899",
        "longitude": "35.57189941",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3190",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3655",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112300,
            "s_seats": 41542,
            "j_seats": 43513,
            "f_seats": 8073,
            "r_seats": 9382,
            "cargo_demand": 96268
        }
    },
    {
        "iata": "RPR",
        "icao": "VARP",
        "airportName": "Swami Vivekananda Airport",
        "location_country": "India",
        "location_city": "Chhattisgarh",
        "location_state": "Raipur",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.180401",
        "longitude": "81.7388",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6414",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1112,
            "s_seats": 2787,
            "j_seats": 1081,
            "f_seats": 44,
            "r_seats": 91,
            "cargo_demand": 86165
        }
    },
    {
        "iata": "RPX",
        "icao": "KRPX",
        "airportName": "Roundup Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Roundup",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "46.475095",
        "longitude": "-108.541497",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5098",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2458",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2924,
            "s_seats": 2722,
            "j_seats": 1070,
            "f_seats": 319,
            "r_seats": 90,
            "cargo_demand": 87238
        }
    },
    {
        "iata": "RRG",
        "icao": "FIMR",
        "airportName": "Sir Gaetan Duval Airport",
        "location_country": "Mauritius",
        "location_city": "Rodrigues Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.7577",
        "longitude": "63.361",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4223",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196807,
            "s_seats": 44225,
            "j_seats": 5296,
            "f_seats": 2210,
            "r_seats": 7841,
            "cargo_demand": 307072
        }
    },
    {
        "iata": "RRJ",
        "icao": "SBJR",
        "airportName": "Jacarepagua Airport",
        "location_country": "Brazil",
        "location_city": "Rio de Janeiro",
        "location_state": "Barra da Tijuca",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.986773",
        "longitude": "-43.372194",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2952",
                "runway_width": "96",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2050,
            "s_seats": 2939,
            "j_seats": 1597,
            "f_seats": 363,
            "r_seats": 96,
            "cargo_demand": 90941
        }
    },
    {
        "iata": "RRK",
        "icao": "VERK",
        "airportName": "Rourkela Airport",
        "location_country": "India",
        "location_city": "Odisha",
        "location_state": "Rourkela",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.256701",
        "longitude": "84.814598",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5987",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112393,
            "s_seats": 43038,
            "j_seats": 53935,
            "f_seats": 9191,
            "r_seats": 4793,
            "cargo_demand": 52139
        }
    },
    {
        "iata": "RRL",
        "icao": "KRRL",
        "airportName": "Merrill Municipal Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Merrill",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "45.19889832",
        "longitude": "-89.71289825",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5100",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2997",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2123,
            "s_seats": 2971,
            "j_seats": 1881,
            "f_seats": 194,
            "r_seats": 200,
            "cargo_demand": 28814
        }
    },
    {
        "iata": "RRS",
        "icao": "ENRO",
        "airportName": "Roros Airport",
        "location_country": "Norway",
        "location_city": "Roros",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.57839966",
        "longitude": "11.34230042",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5643",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146003,
            "s_seats": 25913,
            "j_seats": 14365,
            "f_seats": 17439,
            "r_seats": 7424,
            "cargo_demand": 473004
        }
    },
    {
        "iata": "RRT",
        "icao": "KRRT",
        "airportName": "Warroad International Memorial Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Warroad",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "48.94139862",
        "longitude": "-95.3483963",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3000",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5400",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2945,
            "s_seats": 1898,
            "j_seats": 1627,
            "f_seats": 498,
            "r_seats": 124,
            "cargo_demand": 4864
        }
    },
    {
        "iata": "RSA",
        "icao": "SAZR",
        "airportName": "Santa Rosa Airport",
        "location_country": "Argentina",
        "location_city": "La Pampa",
        "location_state": "Santa Rosa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-36.588299",
        "longitude": "-64.275703",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "7546",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86646,
            "s_seats": 44675,
            "j_seats": 21590,
            "f_seats": 13294,
            "r_seats": 4413,
            "cargo_demand": 174317
        }
    },
    {
        "iata": "RSD",
        "icao": "MYER",
        "airportName": "Rock Sound International Airport",
        "location_country": "Bahamas",
        "location_city": "Eleuthera Island",
        "location_state": "Rock Sound",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.89507873",
        "longitude": "-76.17688179",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7213",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146529,
            "s_seats": 45917,
            "j_seats": 58316,
            "f_seats": 3222,
            "r_seats": 4298,
            "cargo_demand": 111774
        }
    },
    {
        "iata": "RSH",
        "icao": "PARS",
        "airportName": "Russian Mission Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Russian Mission",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "61.77888489",
        "longitude": "-161.319458",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3600",
                "runway_width": "100",
                "runway_material": "GRAVEL-G"
            },
            {
                "runway_name": "18W/36W",
                "runway_length": "3000",
                "runway_width": "500",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 3631,
            "s_seats": 1697,
            "j_seats": 1858,
            "f_seats": 319,
            "r_seats": 114,
            "cargo_demand": 68466
        }
    },
    {
        "iata": "RSK",
        "icao": "WASC",
        "airportName": "Abresso Airport",
        "location_country": "Indonesia",
        "location_city": "Ransiki",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.496771",
        "longitude": "134.175",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3445",
                "runway_width": "98",
                "runway_material": "Turf/Grass"
            }
        ],
        "demand": {
            "y_seats": 1182,
            "s_seats": 2141,
            "j_seats": 1698,
            "f_seats": 267,
            "r_seats": 119,
            "cargo_demand": 54934
        }
    },
    {
        "iata": "RSL",
        "icao": "KRSL",
        "airportName": "Russell Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Russell",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.87210083",
        "longitude": "-98.8117981",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1602",
                "runway_width": "300",
                "runway_material": "GRE"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 177329,
            "s_seats": 43894,
            "j_seats": 59027,
            "f_seats": 19241,
            "r_seats": 6372,
            "cargo_demand": 301080
        }
    },
    {
        "iata": "RSN",
        "icao": "KRSN",
        "airportName": "Ruston Regional Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Ruston",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.51440048",
        "longitude": "-92.59169769",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5003",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1489,
            "s_seats": 1585,
            "j_seats": 1232,
            "f_seats": 438,
            "r_seats": 88,
            "cargo_demand": 94555
        }
    },
    {
        "iata": "RSS",
        "icao": "HSDZ",
        "airportName": "Damazin Airport",
        "location_country": "Sudan",
        "location_city": "Ad-Damazin",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "11.7859",
        "longitude": "34.3367",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9020",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2082,
            "s_seats": 1964,
            "j_seats": 1102,
            "f_seats": 10,
            "r_seats": 16,
            "cargo_demand": 91144
        }
    },
    {
        "iata": "RST",
        "icao": "KRST",
        "airportName": "Rochester International Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Rochester",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.90829849",
        "longitude": "-92.5",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7300",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "9033",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 187466,
            "s_seats": 91611,
            "j_seats": 25639,
            "f_seats": 57956,
            "r_seats": 10000,
            "cargo_demand": 3304267
        }
    },
    {
        "iata": "RSU",
        "icao": "RKJY",
        "airportName": "Yeosu/Suncheon Airport",
        "location_country": "South Korea",
        "location_city": "Yeosu / Suncheon",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.84230042",
        "longitude": "127.6169968",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181580,
            "s_seats": 29927,
            "j_seats": 20707,
            "f_seats": 1788,
            "r_seats": 3405,
            "cargo_demand": 235745
        }
    },
    {
        "iata": "RSW",
        "icao": "KRSW",
        "airportName": "Southwest Florida International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Fort Myers",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "26.53619957",
        "longitude": "-81.75520325",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "12000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 255323,
            "s_seats": 80293,
            "j_seats": 165184,
            "f_seats": 43348,
            "r_seats": 10000,
            "cargo_demand": 15372538
        }
    },
    {
        "iata": "RTA",
        "icao": "NFNR",
        "airportName": "Rotuma Airport",
        "location_country": "Fiji",
        "location_city": "Rotuma Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.48250008",
        "longitude": "177.0709991",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4806",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1131,
            "s_seats": 2948,
            "j_seats": 1631,
            "f_seats": 94,
            "r_seats": 149,
            "cargo_demand": 1724
        }
    },
    {
        "iata": "RTB",
        "icao": "MHRO",
        "airportName": "Juan Manuel Galvez International Airport",
        "location_country": "Honduras",
        "location_city": "Roatan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.316799",
        "longitude": "-86.523003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7349",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191715,
            "s_seats": 43367,
            "j_seats": 43860,
            "f_seats": 12978,
            "r_seats": 8623,
            "cargo_demand": 220379
        }
    },
    {
        "iata": "RTC",
        "icao": "VARG",
        "airportName": "Ratnagiri Airport",
        "location_country": "India",
        "location_city": "Maharashtra",
        "location_state": "Ratnagiri",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.013599",
        "longitude": "73.327797",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4500",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182750,
            "s_seats": 48594,
            "j_seats": 57154,
            "f_seats": 16424,
            "r_seats": 6131,
            "cargo_demand": 235116
        }
    },
    {
        "iata": "RTG",
        "icao": "WATG",
        "airportName": "Frans Sales Lega Airport",
        "location_country": "Indonesia",
        "location_city": "Ruteng",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.597009659",
        "longitude": "120.4769974",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4186",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3780,
            "s_seats": 1716,
            "j_seats": 1597,
            "f_seats": 263,
            "r_seats": 182,
            "cargo_demand": 9741
        }
    },
    {
        "iata": "RTM",
        "icao": "EHRD",
        "airportName": "Rotterdam The Hague Airport",
        "location_country": "Netherlands",
        "location_city": "Rotterdam",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.956902",
        "longitude": "4.43722",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185500,
            "s_seats": 30448,
            "j_seats": 14315,
            "f_seats": 11819,
            "r_seats": 6568,
            "cargo_demand": 97267
        }
    },
    {
        "iata": "RTN",
        "icao": "KRTN",
        "airportName": "Raton Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Raton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.741501",
        "longitude": "-104.501999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6328",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "4404",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1982,
            "s_seats": 1820,
            "j_seats": 1796,
            "f_seats": 321,
            "r_seats": 64,
            "cargo_demand": 83991
        }
    },
    {
        "iata": "RTP",
        "icao": "YRTP",
        "airportName": "Rutland Plains Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Rutland Plains",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.64330006",
        "longitude": "141.8430023",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3205",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 3443,
            "s_seats": 2356,
            "j_seats": 1159,
            "f_seats": 253,
            "r_seats": 141,
            "cargo_demand": 59170
        }
    },
    {
        "iata": "RTS",
        "icao": "YRTI",
        "airportName": "Rottnest Island Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Rottnest Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-32.006699",
        "longitude": "115.540001",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4242",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1597,
            "s_seats": 2375,
            "j_seats": 1631,
            "f_seats": 124,
            "r_seats": 159,
            "cargo_demand": 20605
        }
    },
    {
        "iata": "RTY",
        "icao": "YMYT",
        "airportName": "Merty Merty Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Merty Merty",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.58329964",
        "longitude": "140.3170013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4035",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 2549,
            "s_seats": 2398,
            "j_seats": 1548,
            "f_seats": 57,
            "r_seats": 101,
            "cargo_demand": 27356
        }
    },
    {
        "iata": "RUD",
        "icao": "OIMJ",
        "airportName": "Shahroud Airport",
        "location_country": "Iran",
        "location_city": "Shahrud",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.4253006",
        "longitude": "55.10419846",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9157",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2363,
            "s_seats": 1652,
            "j_seats": 1646,
            "f_seats": 106,
            "r_seats": 92,
            "cargo_demand": 55123
        }
    },
    {
        "iata": "RUH",
        "icao": "OERK",
        "airportName": "King Khalid International Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Riyadh",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "24.95759964",
        "longitude": "46.69879913",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15L/33R",
                "runway_length": "13796",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "13796",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 479418,
            "s_seats": 77819,
            "j_seats": 112496,
            "f_seats": 50207,
            "r_seats": 10000,
            "cargo_demand": 28399852
        }
    },
    {
        "iata": "RUI",
        "icao": "KSRR",
        "airportName": "Sierra Blanca Regional Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Ruidoso",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.46279907",
        "longitude": "-105.5350037",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8099",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6500",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128196,
            "s_seats": 26515,
            "j_seats": 49393,
            "f_seats": 15276,
            "r_seats": 8138,
            "cargo_demand": 417092
        }
    },
    {
        "iata": "RUK",
        "icao": "VNRK",
        "airportName": "Chaurjahari Airport",
        "location_country": "Nepal",
        "location_city": "Rukumkot",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.627001",
        "longitude": "82.194021",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "1969",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1526,
            "s_seats": 1631,
            "j_seats": 1383,
            "f_seats": 180,
            "r_seats": 19,
            "cargo_demand": 2619
        }
    },
    {
        "iata": "RUN",
        "icao": "FMEE",
        "airportName": "Roland Garros Airport",
        "location_country": "France",
        "location_city": "Reunion",
        "location_state": "St-Denis",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-20.8871",
        "longitude": "55.5103",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8760",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 449619,
            "s_seats": 73955,
            "j_seats": 165013,
            "f_seats": 30379,
            "r_seats": 10000,
            "cargo_demand": 28376600
        }
    },
    {
        "iata": "RUR",
        "icao": "NTAR",
        "airportName": "Rurutu Airport",
        "location_country": "French Polynesia",
        "location_city": "Rurutu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.4340992",
        "longitude": "-151.3609924",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4757",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145222,
            "s_seats": 41600,
            "j_seats": 33293,
            "f_seats": 8506,
            "r_seats": 3832,
            "cargo_demand": 337687
        }
    },
    {
        "iata": "RUS",
        "icao": "AGGU",
        "airportName": "Marau Airport",
        "location_country": "Solomon Islands",
        "location_city": "Marau",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.86166954",
        "longitude": "160.8249969",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2001",
                "runway_width": "98",
                "runway_material": "Coral"
            }
        ],
        "demand": {
            "y_seats": 2635,
            "s_seats": 2583,
            "j_seats": 1341,
            "f_seats": 138,
            "r_seats": 171,
            "cargo_demand": 83115
        }
    },
    {
        "iata": "RUT",
        "icao": "KRUT",
        "airportName": "Rutland - Southern Vermont Regional Airport",
        "location_country": "United States",
        "location_city": "Vermont",
        "location_state": "Rutland",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.52939987",
        "longitude": "-72.94960022",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3170",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132879,
            "s_seats": 29405,
            "j_seats": 27362,
            "f_seats": 6907,
            "r_seats": 3230,
            "cargo_demand": 155378
        }
    },
    {
        "iata": "RUV",
        "icao": "MGRB",
        "airportName": "Rubelsanto Airport",
        "location_country": "Guatemala",
        "location_city": "Rubelsanto",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.99199963",
        "longitude": "-90.44529724",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4690",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160911,
            "s_seats": 35340,
            "j_seats": 40096,
            "f_seats": 2695,
            "r_seats": 5622,
            "cargo_demand": 86185
        }
    },
    {
        "iata": "RVA",
        "icao": "FMSG",
        "airportName": "Farafangana Airport",
        "location_country": "Madagascar",
        "location_city": "Farafangana",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.8053",
        "longitude": "47.820599",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3445",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2108,
            "s_seats": 1958,
            "j_seats": 1001,
            "f_seats": 451,
            "r_seats": 121,
            "cargo_demand": 21233
        }
    },
    {
        "iata": "RVD",
        "icao": "SWLC",
        "airportName": "General Leite de Castro Airport",
        "location_country": "Brazil",
        "location_city": "Goias",
        "location_state": "Rio Verde",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.83472252",
        "longitude": "-50.95611191",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1392,
            "s_seats": 1789,
            "j_seats": 1335,
            "f_seats": 302,
            "r_seats": 149,
            "cargo_demand": 23811
        }
    },
    {
        "iata": "RVE",
        "icao": "SKSA",
        "airportName": "Los Colonizadores Airport",
        "location_country": "Colombia",
        "location_city": "Saravena",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "6.951868",
        "longitude": "-71.857179",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3714,
            "s_seats": 1563,
            "j_seats": 1593,
            "f_seats": 84,
            "r_seats": 189,
            "cargo_demand": 5991
        }
    },
    {
        "iata": "RVK",
        "icao": "ENRM",
        "airportName": "Rorvik Airport",
        "location_country": "Norway",
        "location_city": "Rorvik",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "\" Ryum\"\"\"",
        "longitude": "64.83830261",
        "region": "14",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2887",
                "runway_width": "98",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 152640,
            "s_seats": 49940,
            "j_seats": 51883,
            "f_seats": 1082,
            "r_seats": 9895,
            "cargo_demand": 499981
        }
    },
    {
        "iata": "RVN",
        "icao": "EFRO",
        "airportName": "Rovaniemi Airport",
        "location_country": "Finland",
        "location_city": "Rovaniemi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "66.56479645",
        "longitude": "25.83040047",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9849",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 93750,
            "s_seats": 49696,
            "j_seats": 41477,
            "f_seats": 7534,
            "r_seats": 4377,
            "cargo_demand": 480008
        }
    },
    {
        "iata": "RVS",
        "icao": "KRVS",
        "airportName": "Richard Lloyd Jones Jr. Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Tulsa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.03960037",
        "longitude": "-95.98459625",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "5103",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "4212",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2807",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156687,
            "s_seats": 36541,
            "j_seats": 57986,
            "f_seats": 6627,
            "r_seats": 6288,
            "cargo_demand": 106239
        }
    },
    {
        "iata": "RVT",
        "icao": "YNRV",
        "airportName": "Ravensthorpe Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Ravensthorpe",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-33.79719925",
        "longitude": "120.2080002",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5512",
                "runway_width": "98",
                "runway_material": "PER"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3927",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 1963,
            "s_seats": 1633,
            "j_seats": 1916,
            "f_seats": 265,
            "r_seats": 70,
            "cargo_demand": 42649
        }
    },
    {
        "iata": "RVV",
        "icao": "NTAV",
        "airportName": "Raivavae Airport",
        "location_country": "French Polynesia",
        "location_city": "Austral Islands",
        "location_state": "Raivavae",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-23.8852005",
        "longitude": "-147.6620026",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4592",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1718,
            "s_seats": 1733,
            "j_seats": 1377,
            "f_seats": 489,
            "r_seats": 147,
            "cargo_demand": 30233
        }
    },
    {
        "iata": "RVY",
        "icao": "SURV",
        "airportName": "Pres. Gral. oscar D. Gestido International Airport",
        "location_country": "Uruguay",
        "location_city": "Rivera",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-30.97459984",
        "longitude": "-55.4762001",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6004",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3488,
            "s_seats": 1843,
            "j_seats": 1231,
            "f_seats": 81,
            "r_seats": 141,
            "cargo_demand": 50367
        }
    },
    {
        "iata": "RWF",
        "icao": "KRWF",
        "airportName": "Redwood Falls Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Redwood Falls",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.54719925",
        "longitude": "-95.08229828",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2050",
                "runway_width": "200",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177329,
            "s_seats": 41289,
            "j_seats": 54067,
            "f_seats": 17137,
            "r_seats": 5959,
            "cargo_demand": 276430
        }
    },
    {
        "iata": "RWI",
        "icao": "KRWI",
        "airportName": "Rocky Mount-Wilson Regional Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Rocky Mount",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.85630035",
        "longitude": "-77.89189911",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7100",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63708,
            "s_seats": 31015,
            "j_seats": 12367,
            "f_seats": 12872,
            "r_seats": 8109,
            "cargo_demand": 331203
        }
    },
    {
        "iata": "RWL",
        "icao": "KRWL",
        "airportName": "Rawlins Municipal Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Rawlins",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.80559921",
        "longitude": "-107.1999969",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7008",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4118",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112868,
            "s_seats": 32190,
            "j_seats": 50583,
            "f_seats": 14151,
            "r_seats": 5762,
            "cargo_demand": 288617
        }
    },
    {
        "iata": "RWN",
        "icao": "UKLR",
        "airportName": "Rivne International Airport",
        "location_country": "Ukraine",
        "location_city": "Rivne",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.60710144",
        "longitude": "26.14159966",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2150",
                "runway_width": "65",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8615",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 183416,
            "s_seats": 30075,
            "j_seats": 32644,
            "f_seats": 15883,
            "r_seats": 4767,
            "cargo_demand": 100298
        }
    },
    {
        "iata": "RXE",
        "icao": "KRXE",
        "airportName": "Rexburg-Madison County Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Rexburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.8339",
        "longitude": "-111.805002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4200",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3771,
            "s_seats": 1741,
            "j_seats": 1730,
            "f_seats": 323,
            "r_seats": 29,
            "cargo_demand": 80484
        }
    },
    {
        "iata": "RXS",
        "icao": "RPVR",
        "airportName": "Roxas Airport",
        "location_country": "Philippines",
        "location_city": "Roxas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.59770012",
        "longitude": "122.7519989",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6201",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 182145,
            "s_seats": 34101,
            "j_seats": 9759,
            "f_seats": 8637,
            "r_seats": 7561,
            "cargo_demand": 276923
        }
    },
    {
        "iata": "RYG",
        "icao": "ENRY",
        "airportName": "Moss Airport",
        "location_country": "Norway",
        "location_city": "Oslo / Moss",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "\" Rygge\"\"\"",
        "longitude": "59.378817",
        "region": "174",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8012",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99855,
            "s_seats": 25784,
            "j_seats": 34232,
            "f_seats": 1973,
            "r_seats": 7922,
            "cargo_demand": 89060
        }
    },
    {
        "iata": "RYK",
        "icao": "OPRK",
        "airportName": "Shaikh Zayed International Airport",
        "location_country": "Pakistan",
        "location_city": "Rahim Yar Khan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.38389969",
        "longitude": "70.27960205",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173132,
            "s_seats": 48351,
            "j_seats": 10857,
            "f_seats": 12499,
            "r_seats": 7678,
            "cargo_demand": 479202
        }
    },
    {
        "iata": "RYN",
        "icao": "LFCY",
        "airportName": "Royan - Medis Aerodrome",
        "location_country": "France",
        "location_city": "Poitou-Charentes",
        "location_state": "Royan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.62810135",
        "longitude": "-0.972500026",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4117",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 91116,
            "s_seats": 47652,
            "j_seats": 7152,
            "f_seats": 11137,
            "r_seats": 3383,
            "cargo_demand": 250554
        }
    },
    {
        "iata": "RYO",
        "icao": "SAWT",
        "airportName": "Rio Turbio Airport",
        "location_country": "Argentina",
        "location_city": "Santa Cruz",
        "location_state": "Rio Turbio",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-51.605",
        "longitude": "-72.2203",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6340",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 3174,
            "s_seats": 2409,
            "j_seats": 1454,
            "f_seats": 481,
            "r_seats": 55,
            "cargo_demand": 50001
        }
    },
    {
        "iata": "RZA",
        "icao": "SAWU",
        "airportName": "Santa Cruz Airport",
        "location_country": "Argentina",
        "location_city": "Santa Cruz",
        "location_state": "Puerto Santa Cruz",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-50.0165",
        "longitude": "-68.5792",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6561",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3960",
                "runway_width": "164",
                "runway_material": "GRE"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2977",
                "runway_width": "150",
                "runway_material": "GRE"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4227",
                "runway_width": "166",
                "runway_material": "GRE"
            },
            {
                "runway_name": "7/25",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83322,
            "s_seats": 28348,
            "j_seats": 22704,
            "f_seats": 8840,
            "r_seats": 5470,
            "cargo_demand": 135019
        }
    },
    {
        "iata": "RZE",
        "icao": "EPRZ",
        "airportName": "Rzeszow-Jasionka Airport",
        "location_country": "Poland",
        "location_city": "Rzeszow",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.110001",
        "longitude": "22.018999",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10498",
                "runway_width": "147",
                "runway_material": "CON"
            },
            {
                "runway_name": "09ES/27ES",
                "runway_length": "6840",
                "runway_width": "328",
                "runway_material": "GRS Emergency Strip"
            }
        ],
        "demand": {
            "y_seats": 72549,
            "s_seats": 36438,
            "j_seats": 21952,
            "f_seats": 14644,
            "r_seats": 3106,
            "cargo_demand": 137778
        }
    },
    {
        "iata": "RZR",
        "icao": "OINR",
        "airportName": "Ramsar International Airport",
        "location_country": "Iran",
        "location_city": "Ramsar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.90990067",
        "longitude": "50.67959976",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4920",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83520,
            "s_seats": 30275,
            "j_seats": 48359,
            "f_seats": 17594,
            "r_seats": 7255,
            "cargo_demand": 407698
        }
    },
    {
        "iata": "SAA",
        "icao": "KSAA",
        "airportName": "Shively Field",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Saratoga",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.444901",
        "longitude": "-106.823997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8800",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2511,
            "s_seats": 2974,
            "j_seats": 1415,
            "f_seats": 217,
            "r_seats": 112,
            "cargo_demand": 23843
        }
    },
    {
        "iata": "SAB",
        "icao": "TNCS",
        "airportName": "Juancho E. Yrausquin Airport",
        "location_country": "Caribbean Netherlands",
        "location_city": "Saba",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "17.64500046",
        "longitude": "-63.22000122",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "1300",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3514,
            "s_seats": 1665,
            "j_seats": 1521,
            "f_seats": 270,
            "r_seats": 66,
            "cargo_demand": 17162
        }
    },
    {
        "iata": "SAC",
        "icao": "KSAC",
        "airportName": "Sacramento Executive Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Sacramento",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.51250076",
        "longitude": "-121.4929962",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5503",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3836",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3485",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184859,
            "s_seats": 33774,
            "j_seats": 30279,
            "f_seats": 4562,
            "r_seats": 3705,
            "cargo_demand": 70256
        }
    },
    {
        "iata": "SAD",
        "icao": "KSAD",
        "airportName": "Safford Regional Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Safford",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.85480118",
        "longitude": "-109.6350021",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4800",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6015",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2846,
            "s_seats": 2610,
            "j_seats": 1736,
            "f_seats": 107,
            "r_seats": 130,
            "cargo_demand": 21282
        }
    },
    {
        "iata": "SAF",
        "icao": "KSAF",
        "airportName": "Santa Fe Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Santa Fe",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.61709976",
        "longitude": "-106.0889969",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8342",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "6300",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6307",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145553,
            "s_seats": 26517,
            "j_seats": 44875,
            "f_seats": 9576,
            "r_seats": 3151,
            "cargo_demand": 273609
        }
    },
    {
        "iata": "SAH",
        "icao": "OYSN",
        "airportName": "Sana'a International Airport",
        "location_country": "Yemen",
        "location_city": "Sana'a",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.47630024",
        "longitude": "44.21969986",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "10669",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169532,
            "s_seats": 33206,
            "j_seats": 38686,
            "f_seats": 9017,
            "r_seats": 9534,
            "cargo_demand": 494055
        }
    },
    {
        "iata": "SAK",
        "icao": "BIKR",
        "airportName": "Sau\u00f0arkrokur Airport",
        "location_country": "Iceland",
        "location_city": "Sau\u00f0arkrokur",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.73169708",
        "longitude": "-19.57279968",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6191",
                "runway_width": "90",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 2791,
            "s_seats": 2837,
            "j_seats": 1454,
            "f_seats": 347,
            "r_seats": 155,
            "cargo_demand": 95461
        }
    },
    {
        "iata": "SAL",
        "icao": "MSLP",
        "airportName": "Monsenor oscar Arnulfo Romero International Airport",
        "location_country": "El Salvador",
        "location_city": "San Salvador",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "13.4409",
        "longitude": "-89.055702",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10500",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2625",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 400399,
            "s_seats": 72775,
            "j_seats": 40274,
            "f_seats": 48074,
            "r_seats": 10000,
            "cargo_demand": 18273544
        }
    },
    {
        "iata": "SAN",
        "icao": "KSAN",
        "airportName": "San Diego International Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "San Diego",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "32.73360062",
        "longitude": "-117.1900024",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9401",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 499857,
            "s_seats": 73024,
            "j_seats": 88728,
            "f_seats": 42518,
            "r_seats": 10000,
            "cargo_demand": 8819746
        }
    },
    {
        "iata": "SAP",
        "icao": "MHLM",
        "airportName": "Ramon Villeda Morales International Airport",
        "location_country": "Honduras",
        "location_city": "San Pedro Sula",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.4526",
        "longitude": "-87.923599",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9203",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 124756,
            "s_seats": 48593,
            "j_seats": 7479,
            "f_seats": 19936,
            "r_seats": 8679,
            "cargo_demand": 402767
        }
    },
    {
        "iata": "SAQ",
        "icao": "MYAN",
        "airportName": "San Andros Airport",
        "location_country": "Bahamas",
        "location_city": "Andros Island",
        "location_state": "Nicholls Town",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.05380058",
        "longitude": "-78.0490036",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5025",
                "runway_width": "75",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 195145,
            "s_seats": 37670,
            "j_seats": 33338,
            "f_seats": 5710,
            "r_seats": 7259,
            "cargo_demand": 324178
        }
    },
    {
        "iata": "SAR",
        "icao": "KSAR",
        "airportName": "Sparta Community Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Sparta",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.14889908",
        "longitude": "-89.69869995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2450",
                "runway_width": "135",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4001",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3999,
            "s_seats": 2034,
            "j_seats": 1594,
            "f_seats": 387,
            "r_seats": 100,
            "cargo_demand": 5416
        }
    },
    {
        "iata": "SAT",
        "icao": "KSAT",
        "airportName": "San Antonio International Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "San Antonio",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "29.533701",
        "longitude": "-98.469803",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8505",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "5519",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "8502",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 233394,
            "s_seats": 87580,
            "j_seats": 55104,
            "f_seats": 32259,
            "r_seats": 10000,
            "cargo_demand": 2455792
        }
    },
    {
        "iata": "SAU",
        "icao": "WATS",
        "airportName": "Tardamu Airport",
        "location_country": "Indonesia",
        "location_city": "Savu (Sawu)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.4924",
        "longitude": "121.8482",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2577",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3991,
            "s_seats": 1774,
            "j_seats": 1637,
            "f_seats": 397,
            "r_seats": 12,
            "cargo_demand": 53947
        }
    },
    {
        "iata": "SAV",
        "icao": "KSAV",
        "airportName": "Savannah/Hilton Head International Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Savannah",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "32.12760162",
        "longitude": "-81.20210266",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7002",
                "runway_width": "150",
                "runway_material": "CONC-G"
            },
            {
                "runway_name": "10/28",
                "runway_length": "9351",
                "runway_width": "150",
                "runway_material": "CONC-G"
            }
        ],
        "demand": {
            "y_seats": 435430,
            "s_seats": 75382,
            "j_seats": 51669,
            "f_seats": 24330,
            "r_seats": 10000,
            "cargo_demand": 9786509
        }
    },
    {
        "iata": "SAW",
        "icao": "LTFJ",
        "airportName": "Sabiha GokiCen International Airport",
        "location_country": "Turkey",
        "location_city": "Istanbul",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.898602",
        "longitude": "29.3092",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 133183,
            "s_seats": 82186,
            "j_seats": 23117,
            "f_seats": 62776,
            "r_seats": 10000,
            "cargo_demand": 7267235
        }
    },
    {
        "iata": "SAY",
        "icao": "LIQS",
        "airportName": "Siena-Ampugnano Airport",
        "location_country": "Italy",
        "location_city": "Tuscany",
        "location_state": "Siena",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.256302",
        "longitude": "11.255",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4570",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175198,
            "s_seats": 44520,
            "j_seats": 31409,
            "f_seats": 5782,
            "r_seats": 4178,
            "cargo_demand": 90406
        }
    },
    {
        "iata": "SBA",
        "icao": "KSBA",
        "airportName": "Santa Barbara Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Santa Barbara",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.42620087",
        "longitude": "-119.8399963",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6052",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15L/33R",
                "runway_length": "4179",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "4183",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142115,
            "s_seats": 48261,
            "j_seats": 44709,
            "f_seats": 7703,
            "r_seats": 6967,
            "cargo_demand": 184166
        }
    },
    {
        "iata": "SBB",
        "icao": "SVSB",
        "airportName": "Santa Barbara de Barinas Airport",
        "location_country": "Venezuela",
        "location_city": "Santa Barbara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "7.803514004",
        "longitude": "-71.16571808",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5560",
                "runway_width": "131",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5560",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2176,
            "s_seats": 1505,
            "j_seats": 1794,
            "f_seats": 381,
            "r_seats": 11,
            "cargo_demand": 92438
        }
    },
    {
        "iata": "SBD",
        "icao": "KSBD",
        "airportName": "San Bernardino International Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "San Bernardino",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.095402",
        "longitude": "-117.235001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10001",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 164008,
            "s_seats": 29387,
            "j_seats": 19403,
            "f_seats": 17944,
            "r_seats": 3137,
            "cargo_demand": 418763
        }
    },
    {
        "iata": "SBH",
        "icao": "TFFJ",
        "airportName": "Gustaf III Airport",
        "location_country": "Saint Barthelemy",
        "location_city": "Gustavia",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.9044",
        "longitude": "-62.843601",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2133",
                "runway_width": "49",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 53880,
            "s_seats": 35458,
            "j_seats": 19958,
            "f_seats": 5150,
            "r_seats": 4971,
            "cargo_demand": 367131
        }
    },
    {
        "iata": "SBJ",
        "icao": "SNMX",
        "airportName": "Sio Mateus Airport",
        "location_country": "Brazil",
        "location_city": "Espirito Santo",
        "location_state": "Sio Mateus",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.72130013",
        "longitude": "-39.83369827",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "89",
                "runway_width": "89",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3676,
            "s_seats": 2656,
            "j_seats": 1954,
            "f_seats": 260,
            "r_seats": 11,
            "cargo_demand": 64553
        }
    },
    {
        "iata": "SBK",
        "icao": "LFRT",
        "airportName": "Saint-Brieuc - Armor Airport",
        "location_country": "France",
        "location_city": "Brittany",
        "location_state": "Saint-Brieuc",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.5378",
        "longitude": "-2.85444",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132473,
            "s_seats": 32730,
            "j_seats": 7627,
            "f_seats": 18699,
            "r_seats": 6393,
            "cargo_demand": 357124
        }
    },
    {
        "iata": "SBL",
        "icao": "SLSA",
        "airportName": "Santa Ana del Yacuma Airport",
        "location_country": "Bolivia",
        "location_city": "Santa Ana del Yacuma",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-13.76220036",
        "longitude": "-65.43520355",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5013",
                "runway_width": "69",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125612,
            "s_seats": 28467,
            "j_seats": 24082,
            "f_seats": 4874,
            "r_seats": 4284,
            "cargo_demand": 128482
        }
    },
    {
        "iata": "SBM",
        "icao": "KSBM",
        "airportName": "Sheboygan County Memorial Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Sheboygan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.76959991",
        "longitude": "-87.85140228",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5402",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2703,
            "s_seats": 2038,
            "j_seats": 1710,
            "f_seats": 346,
            "r_seats": 187,
            "cargo_demand": 22373
        }
    },
    {
        "iata": "SBN",
        "icao": "KSBN",
        "airportName": "South Bend International Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "South Bend",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.708698",
        "longitude": "-86.317299",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "4300",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "8412",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68506,
            "s_seats": 30245,
            "j_seats": 50541,
            "f_seats": 1973,
            "r_seats": 4754,
            "cargo_demand": 310801
        }
    },
    {
        "iata": "SBP",
        "icao": "KSBP",
        "airportName": "San Luis Obispo County Regional Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "San Luis Obispo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.23680115",
        "longitude": "-120.6419983",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2500",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6100",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 126079,
            "s_seats": 45732,
            "j_seats": 6918,
            "f_seats": 6718,
            "r_seats": 6402,
            "cargo_demand": 261448
        }
    },
    {
        "iata": "SBR",
        "icao": "YSII",
        "airportName": "Saibai Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Saibai Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.378330231",
        "longitude": "142.625",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2099",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1400,
            "s_seats": 1648,
            "j_seats": 1911,
            "f_seats": 464,
            "r_seats": 12,
            "cargo_demand": 93019
        }
    },
    {
        "iata": "SBS",
        "icao": "KSBS",
        "airportName": "Steamboat Springs Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Steamboat Springs",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.5163002",
        "longitude": "-106.8659973",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4452",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2182,
            "s_seats": 1578,
            "j_seats": 1849,
            "f_seats": 191,
            "r_seats": 114,
            "cargo_demand": 61101
        }
    },
    {
        "iata": "SBT",
        "icao": "USDA",
        "airportName": "Sabetta International Airport",
        "location_country": "Russia",
        "location_city": "Yamalo-Nenets Autonomous Okrug",
        "location_state": "Sabetta",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "71.219167",
        "longitude": "72.052222",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8858",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 57474,
            "s_seats": 45447,
            "j_seats": 52527,
            "f_seats": 8190,
            "r_seats": 3665,
            "cargo_demand": 287116
        }
    },
    {
        "iata": "SBU",
        "icao": "FASB",
        "airportName": "Springbok Airport",
        "location_country": "South Africa",
        "location_city": "Springbok",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.68930054",
        "longitude": "17.93959999",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5213",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 133470,
            "s_seats": 36185,
            "j_seats": 43586,
            "f_seats": 2551,
            "r_seats": 8139,
            "cargo_demand": 413670
        }
    },
    {
        "iata": "SBW",
        "icao": "WBGS",
        "airportName": "Sibu Airport",
        "location_country": "Malaysia",
        "location_city": "Sarawak",
        "location_state": "Sibu",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.261600018",
        "longitude": "111.9850006",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9036",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135430,
            "s_seats": 35331,
            "j_seats": 15065,
            "f_seats": 17210,
            "r_seats": 8432,
            "cargo_demand": 212727
        }
    },
    {
        "iata": "SBX",
        "icao": "KSBX",
        "airportName": "Shelby Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Shelby",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "48.54069901",
        "longitude": "-111.8710022",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5004",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3701",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3920,
            "s_seats": 1935,
            "j_seats": 1228,
            "f_seats": 402,
            "r_seats": 66,
            "cargo_demand": 6806
        }
    },
    {
        "iata": "SBY",
        "icao": "KSBY",
        "airportName": "Salisbury-Ocean City-Wicomico Regional Airport",
        "location_country": "United States",
        "location_city": "Maryland",
        "location_state": "Salisbury / Ocean City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.34049988",
        "longitude": "-75.51029968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5500",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 140526,
            "s_seats": 47948,
            "j_seats": 21018,
            "f_seats": 14506,
            "r_seats": 6845,
            "cargo_demand": 124953
        }
    },
    {
        "iata": "SBZ",
        "icao": "LRSB",
        "airportName": "Sibiu International Airport",
        "location_country": "Romania",
        "location_city": "Sibiu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.78559875",
        "longitude": "24.09129906",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 70329,
            "s_seats": 45677,
            "j_seats": 44944,
            "f_seats": 10680,
            "r_seats": 8327,
            "cargo_demand": 158661
        }
    },
    {
        "iata": "SCB",
        "icao": "KSCB",
        "airportName": "Scribner State Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Scribner",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.61029816",
        "longitude": "-96.62989807",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3199",
                "runway_width": "60",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4200",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1294,
            "s_seats": 2125,
            "j_seats": 1551,
            "f_seats": 344,
            "r_seats": 106,
            "cargo_demand": 41658
        }
    },
    {
        "iata": "SCC",
        "icao": "PASC",
        "airportName": "Deadhorse Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Prudhoe Bay / Deadhorse",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "70.19470215",
        "longitude": "-148.4649963",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 148838,
            "s_seats": 41766,
            "j_seats": 14205,
            "f_seats": 1502,
            "r_seats": 8187,
            "cargo_demand": 309340
        }
    },
    {
        "iata": "SCE",
        "icao": "KUNV",
        "airportName": "University Park Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "State College",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.84930038",
        "longitude": "-77.84870148",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6701",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161586,
            "s_seats": 36533,
            "j_seats": 20744,
            "f_seats": 2947,
            "r_seats": 4191,
            "cargo_demand": 499521
        }
    },
    {
        "iata": "SCF",
        "icao": "KSDL",
        "airportName": "Scottsdale Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Phoenix / Scottsdale",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.6228981",
        "longitude": "-111.9110031",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8249",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2376,
            "s_seats": 2254,
            "j_seats": 1644,
            "f_seats": 81,
            "r_seats": 182,
            "cargo_demand": 59412
        }
    },
    {
        "iata": "SCG",
        "icao": "YSPK",
        "airportName": "Spring Creek Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Conjuboy",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.63330078",
        "longitude": "144.5670013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3248",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 2806,
            "s_seats": 1711,
            "j_seats": 1976,
            "f_seats": 181,
            "r_seats": 176,
            "cargo_demand": 6583
        }
    },
    {
        "iata": "SCH",
        "icao": "KSCH",
        "airportName": "Schenectady County Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Schenectady",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.85250092",
        "longitude": "-73.92890167",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4840",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2639",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166749,
            "s_seats": 27295,
            "j_seats": 29990,
            "f_seats": 10369,
            "r_seats": 5719,
            "cargo_demand": 433600
        }
    },
    {
        "iata": "SCI",
        "icao": "SVPM",
        "airportName": "Paramillo Airport",
        "location_country": "Venezuela",
        "location_city": "San Cristobal",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.801320076",
        "longitude": "-72.20290375",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3571",
                "runway_width": "129",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111440,
            "s_seats": 28692,
            "j_seats": 51596,
            "f_seats": 11468,
            "r_seats": 8463,
            "cargo_demand": 366091
        }
    },
    {
        "iata": "SCK",
        "icao": "KSCK",
        "airportName": "Stockton Metropolitan Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Stockton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.89419937",
        "longitude": "-121.237999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11L/29R",
                "runway_length": "10650",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "4454",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82392,
            "s_seats": 35501,
            "j_seats": 52032,
            "f_seats": 10296,
            "r_seats": 9587,
            "cargo_demand": 291200
        }
    },
    {
        "iata": "SCL",
        "icao": "SCEL",
        "airportName": "Comodoro Arturo Merino Benitez International Airport",
        "location_country": "Chile",
        "location_city": "Santiago",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-33.39300156",
        "longitude": "-70.78579712",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "12303",
                "runway_width": "180",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "12303",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 233984,
            "s_seats": 81643,
            "j_seats": 137779,
            "f_seats": 37090,
            "r_seats": 10000,
            "cargo_demand": 321286
        }
    },
    {
        "iata": "SCM",
        "icao": "PACM",
        "airportName": "Scammon Bay Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Scammon Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.84529877",
        "longitude": "-165.5709991",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04W/22W",
                "runway_length": "10000",
                "runway_width": "500",
                "runway_material": "WATER-E"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 104729,
            "s_seats": 27997,
            "j_seats": 37592,
            "f_seats": 13714,
            "r_seats": 7076,
            "cargo_demand": 256538
        }
    },
    {
        "iata": "SCN",
        "icao": "EDDR",
        "airportName": "Saarbrucken Airport",
        "location_country": "Germany",
        "location_city": "Saarland",
        "location_state": "Saarbrucken",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.21459961",
        "longitude": "7.109509945",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "1788",
                "runway_width": "164",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 96189,
            "s_seats": 26451,
            "j_seats": 20217,
            "f_seats": 13273,
            "r_seats": 4385,
            "cargo_demand": 330475
        }
    },
    {
        "iata": "SCO",
        "icao": "UATE",
        "airportName": "Aktau Airport",
        "location_country": "Kazakhstan",
        "location_city": "Aktau",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.860093",
        "longitude": "51.09086",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "10013",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "9630",
                "runway_width": "150",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 59187,
            "s_seats": 25961,
            "j_seats": 57127,
            "f_seats": 4019,
            "r_seats": 3580,
            "cargo_demand": 400483
        }
    },
    {
        "iata": "SCP",
        "icao": "LFNC",
        "airportName": "Mont-Dauphin - Saint-Crepin Airport",
        "location_country": "France",
        "location_city": "Provence-Alpes-Cote d'Azur",
        "location_state": "Mont-Dauphin",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.701698",
        "longitude": "6.60028",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "2854",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1416,
            "s_seats": 2119,
            "j_seats": 1379,
            "f_seats": 418,
            "r_seats": 79,
            "cargo_demand": 2729
        }
    },
    {
        "iata": "SCQ",
        "icao": "LEST",
        "airportName": "Santiago de Compostela Airport",
        "location_country": "Spain",
        "location_city": "Galicia",
        "location_state": "Santiago de Compostela",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "42.89630127",
        "longitude": "-8.415140152",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 336322,
            "s_seats": 75132,
            "j_seats": 127911,
            "f_seats": 49441,
            "r_seats": 10000,
            "cargo_demand": 3984462
        }
    },
    {
        "iata": "SCS",
        "icao": "EGPM",
        "airportName": "Scatsta Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Shetland Islands",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.43280029",
        "longitude": "-1.296110034",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4462",
                "runway_width": "102",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191597,
            "s_seats": 32891,
            "j_seats": 39126,
            "f_seats": 17618,
            "r_seats": 9563,
            "cargo_demand": 172863
        }
    },
    {
        "iata": "SCT",
        "icao": "OYSQ",
        "airportName": "Socotra Airport",
        "location_country": "Yemen",
        "location_city": "Socotra",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.63070011",
        "longitude": "53.90579987",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118023,
            "s_seats": 36888,
            "j_seats": 31479,
            "f_seats": 13071,
            "r_seats": 6398,
            "cargo_demand": 187124
        }
    },
    {
        "iata": "SCU",
        "icao": "MUCU",
        "airportName": "Antonio Maceo International Airport",
        "location_country": "Cuba",
        "location_city": "Santiago de Cuba",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.96980095",
        "longitude": "-75.83540344",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "13130",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "19/01",
                "runway_length": "4593",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189470,
            "s_seats": 38929,
            "j_seats": 25060,
            "f_seats": 14373,
            "r_seats": 7608,
            "cargo_demand": 384345
        }
    },
    {
        "iata": "SCV",
        "icao": "LRSV",
        "airportName": "Suceava International Airport",
        "location_country": "Romania",
        "location_city": "Suceava",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.6875",
        "longitude": "26.35409927",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8070",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 116445,
            "s_seats": 25158,
            "j_seats": 5305,
            "f_seats": 7689,
            "r_seats": 4261,
            "cargo_demand": 122613
        }
    },
    {
        "iata": "SCW",
        "icao": "UUYY",
        "airportName": "Syktyvkar Airport",
        "location_country": "Russia",
        "location_city": "Komi Republic",
        "location_state": "Syktyvkar",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.64699936",
        "longitude": "50.8451004",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8203",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199848,
            "s_seats": 29112,
            "j_seats": 5738,
            "f_seats": 13723,
            "r_seats": 6133,
            "cargo_demand": 204291
        }
    },
    {
        "iata": "SCY",
        "icao": "SEST",
        "airportName": "San Cristobal Airport",
        "location_country": "Ecuador",
        "location_city": "Galapagos Islands",
        "location_state": "San Cristobal Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.910206",
        "longitude": "-89.617401",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6214",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3348,
            "s_seats": 2263,
            "j_seats": 1390,
            "f_seats": 307,
            "r_seats": 57,
            "cargo_demand": 887
        }
    },
    {
        "iata": "SCZ",
        "icao": "AGGL",
        "airportName": "Santa Cruz/Graciosa Bay/Luova Airport",
        "location_country": "Solomon Islands",
        "location_city": "Santa Cruz Islands",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.72029972",
        "longitude": "165.7949982",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2999",
                "runway_width": "",
                "runway_material": "Coral"
            }
        ],
        "demand": {
            "y_seats": 3160,
            "s_seats": 1984,
            "j_seats": 1997,
            "f_seats": 478,
            "r_seats": 9,
            "cargo_demand": 30898
        }
    },
    {
        "iata": "SDB",
        "icao": "FALW",
        "airportName": "Air Force Base Langebaanweg",
        "location_country": "South Africa",
        "location_city": "Saldanha Bay",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-32.96889877",
        "longitude": "18.1602993",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02L/20R",
                "runway_length": "7680",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "7689",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "4935",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6533",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82193,
            "s_seats": 45329,
            "j_seats": 19050,
            "f_seats": 19837,
            "r_seats": 3591,
            "cargo_demand": 286669
        }
    },
    {
        "iata": "SDD",
        "icao": "FNUB",
        "airportName": "Lubango Mukanka Airport",
        "location_country": "Angola",
        "location_city": "Lubango",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.92469978",
        "longitude": "13.57499981",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9570",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118372,
            "s_seats": 42398,
            "j_seats": 20802,
            "f_seats": 5635,
            "r_seats": 5814,
            "cargo_demand": 143051
        }
    },
    {
        "iata": "SDE",
        "icao": "SANE",
        "airportName": "Vicecomodoro angel de la Paz Aragones Airport",
        "location_country": "Argentina",
        "location_city": "Santiago del Estero",
        "location_state": "Santiago del Estero",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.76555634",
        "longitude": "-64.30999756",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "7946",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142616,
            "s_seats": 44750,
            "j_seats": 16146,
            "f_seats": 7334,
            "r_seats": 8911,
            "cargo_demand": 66076
        }
    },
    {
        "iata": "SDF",
        "icao": "KSDF",
        "airportName": "Louisville International Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Louisville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "38.1744",
        "longitude": "-85.736",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7250",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "8579",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "10850",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 178337,
            "s_seats": 91616,
            "j_seats": 91376,
            "f_seats": 53269,
            "r_seats": 10000,
            "cargo_demand": 29090602
        }
    },
    {
        "iata": "SDG",
        "icao": "OICS",
        "airportName": "Sanandaj Airport",
        "location_country": "Iran",
        "location_city": "Sanandaj",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.2458992",
        "longitude": "47.00920105",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8660",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64088,
            "s_seats": 37417,
            "j_seats": 27089,
            "f_seats": 5818,
            "r_seats": 6904,
            "cargo_demand": 456715
        }
    },
    {
        "iata": "SDJ",
        "icao": "RJSS",
        "airportName": "Sendai Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Sendai",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "38.139702",
        "longitude": "140.917007",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9842",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3940",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160005,
            "s_seats": 72581,
            "j_seats": 66332,
            "f_seats": 67423,
            "r_seats": 10000,
            "cargo_demand": 14116344
        }
    },
    {
        "iata": "SDK",
        "icao": "WBKS",
        "airportName": "Sandakan Airport",
        "location_country": "Malaysia",
        "location_city": "Sabah",
        "location_state": "Sandakan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.900899887",
        "longitude": "118.0589981",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7000",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153550,
            "s_seats": 49547,
            "j_seats": 40306,
            "f_seats": 9095,
            "r_seats": 9133,
            "cargo_demand": 209799
        }
    },
    {
        "iata": "SDL",
        "icao": "ESNN",
        "airportName": "Sundsvall-Timrio Airport",
        "location_country": "Sweden",
        "location_city": "Sundsvall / HArnosand",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.52809906",
        "longitude": "17.44389915",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6857",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131631,
            "s_seats": 37611,
            "j_seats": 45088,
            "f_seats": 14842,
            "r_seats": 8163,
            "cargo_demand": 249237
        }
    },
    {
        "iata": "SDM",
        "icao": "KSDM",
        "airportName": "Brown Field Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "San Diego",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.57229996",
        "longitude": "-116.9800034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "7972",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "3180",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122008,
            "s_seats": 44301,
            "j_seats": 49206,
            "f_seats": 2315,
            "r_seats": 4211,
            "cargo_demand": 162075
        }
    },
    {
        "iata": "SDN",
        "icao": "ENSD",
        "airportName": "Sandane Airport",
        "location_country": "Norway",
        "location_city": "Sandane",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "61.83000183",
        "longitude": "6.105830193",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3182",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2929,
            "s_seats": 2891,
            "j_seats": 1714,
            "f_seats": 90,
            "r_seats": 137,
            "cargo_demand": 53428
        }
    },
    {
        "iata": "SDP",
        "icao": "PASD",
        "airportName": "Sand Point Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Sand Point",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.31499863",
        "longitude": "-160.522995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179003,
            "s_seats": 25655,
            "j_seats": 22535,
            "f_seats": 17613,
            "r_seats": 7385,
            "cargo_demand": 199456
        }
    },
    {
        "iata": "SDQ",
        "icao": "MDSD",
        "airportName": "Las Americas International Airport",
        "location_country": "Dominican Republic",
        "location_city": "Santo Domingo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "18.42970085",
        "longitude": "-69.66889954",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "11000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 463494,
            "s_seats": 88214,
            "j_seats": 69437,
            "f_seats": 57918,
            "r_seats": 10000,
            "cargo_demand": 15221335
        }
    },
    {
        "iata": "SDR",
        "icao": "LEXJ",
        "airportName": "Santander Airport",
        "location_country": "Spain",
        "location_city": "Cantabria",
        "location_state": "Santander",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.42710114",
        "longitude": "-3.820009947",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7612",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197807,
            "s_seats": 49337,
            "j_seats": 58677,
            "f_seats": 1547,
            "r_seats": 6600,
            "cargo_demand": 291499
        }
    },
    {
        "iata": "SDS",
        "icao": "RJSD",
        "airportName": "Sado Airport",
        "location_country": "Japan",
        "location_city": "Niigata",
        "location_state": "Sado",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.06019974",
        "longitude": "138.4140015",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3390",
                "runway_width": "85",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79069,
            "s_seats": 36528,
            "j_seats": 52343,
            "f_seats": 13143,
            "r_seats": 6162,
            "cargo_demand": 321290
        }
    },
    {
        "iata": "SDT",
        "icao": "OPSS",
        "airportName": "Saidu Sharif Airport",
        "location_country": "Pakistan",
        "location_city": "Saidu Sharif",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.81359863",
        "longitude": "72.35279846",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5745",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 119377,
            "s_seats": 35747,
            "j_seats": 58684,
            "f_seats": 13756,
            "r_seats": 5558,
            "cargo_demand": 220434
        }
    },
    {
        "iata": "SDU",
        "icao": "SBRJ",
        "airportName": "Santos Dumont Airport",
        "location_country": "Brazil",
        "location_city": "Rio de Janeiro",
        "location_state": "Rio de Janeiro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.91049957",
        "longitude": "-43.1631012",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02L/20R",
                "runway_length": "4134",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "4341",
                "runway_width": "138",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174732,
            "s_seats": 47803,
            "j_seats": 6596,
            "f_seats": 9977,
            "r_seats": 4003,
            "cargo_demand": 447545
        }
    },
    {
        "iata": "SDX",
        "icao": "KSEZ",
        "airportName": "Sedona Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Sedona",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.84859848",
        "longitude": "-111.788002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5129",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3507,
            "s_seats": 1906,
            "j_seats": 1197,
            "f_seats": 389,
            "r_seats": 100,
            "cargo_demand": 67480
        }
    },
    {
        "iata": "SDY",
        "icao": "KSDY",
        "airportName": "Sidney-Richland Municipal Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Sidney",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.706902",
        "longitude": "-104.193001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5705",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4022",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167708,
            "s_seats": 42628,
            "j_seats": 47876,
            "f_seats": 13748,
            "r_seats": 3569,
            "cargo_demand": 162782
        }
    },
    {
        "iata": "SEA",
        "icao": "KSEA",
        "airportName": "Seattle-Tacoma International Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Seattle",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "47.449001",
        "longitude": "-122.308998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16C/34C",
                "runway_length": "9426",
                "runway_width": "150",
                "runway_material": "CONC-F"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "11900",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "9426",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "lower/high",
                "runway_length": "",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 200854,
            "s_seats": 73617,
            "j_seats": 97631,
            "f_seats": 27202,
            "r_seats": 10000,
            "cargo_demand": 7491820
        }
    },
    {
        "iata": "SEB",
        "icao": "HLLS",
        "airportName": "Sabha Airport",
        "location_country": "Libya",
        "location_city": "Sabha (Sebha)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.98699951",
        "longitude": "14.47249985",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5596",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "11778",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70702,
            "s_seats": 31746,
            "j_seats": 51472,
            "f_seats": 17970,
            "r_seats": 6079,
            "cargo_demand": 108364
        }
    },
    {
        "iata": "SEE",
        "icao": "KSEE",
        "airportName": "Gillespie Field",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "San Diego",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.82619858",
        "longitude": "-116.9720001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "5342",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "2738",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4145",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1681,
            "s_seats": 2313,
            "j_seats": 1421,
            "f_seats": 200,
            "r_seats": 178,
            "cargo_demand": 33727
        }
    },
    {
        "iata": "SEF",
        "icao": "KSEF",
        "airportName": "Sebring Regional Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Sebring",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.45639992",
        "longitude": "-81.3423996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5106",
                "runway_width": "300",
                "runway_material": "CON"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4928",
                "runway_width": "300",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5190",
                "runway_width": "300",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5190",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3053,
            "s_seats": 2685,
            "j_seats": 1957,
            "f_seats": 245,
            "r_seats": 78,
            "cargo_demand": 73907
        }
    },
    {
        "iata": "SEG",
        "icao": "KSEG",
        "airportName": "Penn Valley Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Selinsgrove",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.8205986",
        "longitude": "-76.86389923",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4760",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3357,
            "s_seats": 2351,
            "j_seats": 1243,
            "f_seats": 90,
            "r_seats": 23,
            "cargo_demand": 40842
        }
    },
    {
        "iata": "SEK",
        "icao": "UESK",
        "airportName": "Srednekolymsk Airport",
        "location_country": "Russia",
        "location_city": "Yakutia",
        "location_state": "Srednekolymsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "67.4805",
        "longitude": "153.7364",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5906",
                "runway_width": "197",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 79220,
            "s_seats": 31568,
            "j_seats": 47275,
            "f_seats": 6024,
            "r_seats": 8365,
            "cargo_demand": 317012
        }
    },
    {
        "iata": "SEM",
        "icao": "KSEM",
        "airportName": "Craig Field",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Selma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.34389877",
        "longitude": "-86.9878006",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "8002",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 1938,
            "s_seats": 2366,
            "j_seats": 1319,
            "f_seats": 85,
            "r_seats": 63,
            "cargo_demand": 11415
        }
    },
    {
        "iata": "SEN",
        "icao": "EGMC",
        "airportName": "London Southend Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "London / Southend",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.5714",
        "longitude": "0.695556",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6089",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138352,
            "s_seats": 41590,
            "j_seats": 39175,
            "f_seats": 18730,
            "r_seats": 3338,
            "cargo_demand": 275305
        }
    },
    {
        "iata": "SEP",
        "icao": "KSEP",
        "airportName": "Stephenville Clark Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Stephenville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.21530151",
        "longitude": "-98.17769623",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2502",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4200",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1355,
            "s_seats": 1986,
            "j_seats": 1903,
            "f_seats": 56,
            "r_seats": 52,
            "cargo_demand": 53287
        }
    },
    {
        "iata": "SEQ",
        "icao": "WIBS",
        "airportName": "Sei Pakning Airport",
        "location_country": "Indonesia",
        "location_city": "Bengkalis",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "1.370000005",
        "longitude": "102.1399994",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "2626",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1620,
            "s_seats": 2097,
            "j_seats": 1990,
            "f_seats": 410,
            "r_seats": 8,
            "cargo_demand": 66316
        }
    },
    {
        "iata": "SER",
        "icao": "KSER",
        "airportName": "Freeman Municipal Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Seymour",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.92359924",
        "longitude": "-85.90740204",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09U/27U",
                "runway_length": "1000",
                "runway_width": "100",
                "runway_material": "TURF-E"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5502",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18U/36U",
                "runway_length": "1200",
                "runway_width": "100",
                "runway_material": "TURF-F"
            }
        ],
        "demand": {
            "y_seats": 1690,
            "s_seats": 2091,
            "j_seats": 1183,
            "f_seats": 300,
            "r_seats": 150,
            "cargo_demand": 6614
        }
    },
    {
        "iata": "SEU",
        "icao": "HTSN",
        "airportName": "Seronera Airstrip",
        "location_country": "Tanzania",
        "location_city": "Seronera",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.45806",
        "longitude": "34.822498",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7480",
                "runway_width": "",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 1368,
            "s_seats": 1950,
            "j_seats": 1516,
            "f_seats": 332,
            "r_seats": 46,
            "cargo_demand": 13826
        }
    },
    {
        "iata": "SEY",
        "icao": "GQNS",
        "airportName": "Selibaby Airport",
        "location_country": "Mauritania",
        "location_city": "Selibaby",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "15.1796999",
        "longitude": "-12.20730019",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5249",
                "runway_width": "98",
                "runway_material": "MAC"
            }
        ],
        "demand": {
            "y_seats": 3706,
            "s_seats": 2975,
            "j_seats": 1631,
            "f_seats": 259,
            "r_seats": 166,
            "cargo_demand": 17202
        }
    },
    {
        "iata": "SEZ",
        "icao": "FSIA",
        "airportName": "Seychelles International Airport",
        "location_country": "Seychelles",
        "location_city": "Mahe",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-4.67434",
        "longitude": "55.521801",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9800",
                "runway_width": "151",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 246616,
            "s_seats": 72002,
            "j_seats": 133992,
            "f_seats": 48932,
            "r_seats": 10000,
            "cargo_demand": 15485218
        }
    },
    {
        "iata": "SFA",
        "icao": "DTTX",
        "airportName": "Sfax-Thyna International Airport",
        "location_country": "Tunisia",
        "location_city": "Sfax",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.7179985",
        "longitude": "10.69099998",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 165981,
            "s_seats": 39988,
            "j_seats": 5956,
            "f_seats": 19074,
            "r_seats": 5695,
            "cargo_demand": 257216
        }
    },
    {
        "iata": "SFB",
        "icao": "KSFB",
        "airportName": "Orlando Sanford International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Orlando",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "28.77759933",
        "longitude": "-81.23750305",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09C/27C",
                "runway_length": "3578",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "9600",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "3500",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6003",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 103879,
            "s_seats": 70988,
            "j_seats": 104789,
            "f_seats": 24456,
            "r_seats": 10000,
            "cargo_demand": 14978353
        }
    },
    {
        "iata": "SFD",
        "icao": "SVSR",
        "airportName": "Las Flecheras Airport",
        "location_country": "Venezuela",
        "location_city": "San Fernando de Apure",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.883319855",
        "longitude": "-67.44400024",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6420",
                "runway_width": "160",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70321,
            "s_seats": 38764,
            "j_seats": 51549,
            "f_seats": 16730,
            "r_seats": 6648,
            "cargo_demand": 354516
        }
    },
    {
        "iata": "SFE",
        "icao": "RPUS",
        "airportName": "San Fernando Airport",
        "location_country": "Philippines",
        "location_city": "San Fernando",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.59560013",
        "longitude": "120.3030014",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 59145,
            "s_seats": 31328,
            "j_seats": 57936,
            "f_seats": 13083,
            "r_seats": 8211,
            "cargo_demand": 170521
        }
    },
    {
        "iata": "SFF",
        "icao": "KSFF",
        "airportName": "Felts Field",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Spokane",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.68280029",
        "longitude": "-117.322998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03W/21W",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "WATER"
            },
            {
                "runway_name": "04L/22R",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "3059",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157387,
            "s_seats": 39898,
            "j_seats": 12216,
            "f_seats": 3443,
            "r_seats": 9814,
            "cargo_demand": 267720
        }
    },
    {
        "iata": "SFG",
        "icao": "TFFG",
        "airportName": "L'Esperance Airport",
        "location_country": "Saint Martin",
        "location_city": "Grand Case",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.099899",
        "longitude": "-63.047199",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121371,
            "s_seats": 39027,
            "j_seats": 35238,
            "f_seats": 11022,
            "r_seats": 9192,
            "cargo_demand": 122167
        }
    },
    {
        "iata": "SFH",
        "icao": "MMSF",
        "airportName": "San Felipe International Airport",
        "location_country": "Mexico",
        "location_city": "Baja California",
        "location_state": "San Felipe",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "30.93020058",
        "longitude": "-114.8089981",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1573,
            "s_seats": 1633,
            "j_seats": 1833,
            "f_seats": 104,
            "r_seats": 7,
            "cargo_demand": 48875
        }
    },
    {
        "iata": "SFJ",
        "icao": "BGSF",
        "airportName": "Kangerlussuaq Airport",
        "location_country": "Greenland",
        "location_city": "Kangerlussuaq",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "67.0122219",
        "longitude": "-50.71160316",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9219",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 164133,
            "s_seats": 25024,
            "j_seats": 42765,
            "f_seats": 11305,
            "r_seats": 7873,
            "cargo_demand": 328917
        }
    },
    {
        "iata": "SFK",
        "icao": "SNSW",
        "airportName": "Soure Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Soure",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.699431002",
        "longitude": "-48.52099991",
        "region": "SA",
        "runway": [
            {
                "runway_name": "6/24",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1433,
            "s_seats": 1994,
            "j_seats": 1282,
            "f_seats": 44,
            "r_seats": 179,
            "cargo_demand": 43435
        }
    },
    {
        "iata": "SFL",
        "icao": "GVSF",
        "airportName": "Sio Filipe Airport",
        "location_country": "Cape Verde",
        "location_city": "Fogo",
        "location_state": "Sio Filipe",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "14.88500023",
        "longitude": "-24.47999954",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3937",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1798,
            "s_seats": 2667,
            "j_seats": 1910,
            "f_seats": 376,
            "r_seats": 165,
            "cargo_demand": 84224
        }
    },
    {
        "iata": "SFM",
        "icao": "KSFM",
        "airportName": "Sanford Seacoast Regional Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Sanford",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.39390182",
        "longitude": "-70.70800018",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4999",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1273,
            "s_seats": 2990,
            "j_seats": 1866,
            "f_seats": 19,
            "r_seats": 95,
            "cargo_demand": 60735
        }
    },
    {
        "iata": "SFN",
        "icao": "SAAV",
        "airportName": "Sauce Viejo Airport",
        "location_country": "Argentina",
        "location_city": "Santa Fe",
        "location_state": "Santa Fe de la Vera Cruz",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.7117",
        "longitude": "-60.8117",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "7628",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128281,
            "s_seats": 31470,
            "j_seats": 48699,
            "f_seats": 6113,
            "r_seats": 6997,
            "cargo_demand": 122213
        }
    },
    {
        "iata": "SFO",
        "icao": "KSFO",
        "airportName": "San Francisco International Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "San Francisco",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.61899948",
        "longitude": "-122.375",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01L/19R",
                "runway_length": "7500",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "8648",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10L/28R",
                "runway_length": "11870",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "10602",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117862,
            "s_seats": 99444,
            "j_seats": 136807,
            "f_seats": 64884,
            "r_seats": 10000,
            "cargo_demand": 6092796
        }
    },
    {
        "iata": "SFQ",
        "icao": "LTCH",
        "airportName": "o\u017eanliurfa Airport",
        "location_country": "Turkey",
        "location_city": "o\u017eanliurfa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.09429932",
        "longitude": "38.8470993",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7103",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97877,
            "s_seats": 26166,
            "j_seats": 13267,
            "f_seats": 13787,
            "r_seats": 5951,
            "cargo_demand": 219982
        }
    },
    {
        "iata": "SFS",
        "icao": "RPLB",
        "airportName": "Subic Bay International Airport",
        "location_country": "Philippines",
        "location_city": "Subic Bay",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.7944",
        "longitude": "120.271004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9003",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186440,
            "s_seats": 35205,
            "j_seats": 53307,
            "f_seats": 4260,
            "r_seats": 9796,
            "cargo_demand": 297097
        }
    },
    {
        "iata": "SFT",
        "icao": "ESNS",
        "airportName": "Skellefteio Airport",
        "location_country": "Sweden",
        "location_city": "Skellefteio",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.62480164",
        "longitude": "21.07690048",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8268",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 85604,
            "s_seats": 27417,
            "j_seats": 30934,
            "f_seats": 8288,
            "r_seats": 6011,
            "cargo_demand": 230587
        }
    },
    {
        "iata": "SFZ",
        "icao": "KSFZ",
        "airportName": "North Central State Airport",
        "location_country": "United States",
        "location_city": "Rhode Island",
        "location_state": "Pawtucket",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.92079926",
        "longitude": "-71.49140167",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3210",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3496,
            "s_seats": 2925,
            "j_seats": 1608,
            "f_seats": 420,
            "r_seats": 100,
            "cargo_demand": 72378
        }
    },
    {
        "iata": "SGC",
        "icao": "USRR",
        "airportName": "Surgut International Airport",
        "location_country": "Russia",
        "location_city": "Khanty-Mansi Autonomous Okrug",
        "location_state": "Surgut",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.34370041",
        "longitude": "73.40180206",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9154",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100952,
            "s_seats": 35967,
            "j_seats": 37765,
            "f_seats": 2506,
            "r_seats": 6947,
            "cargo_demand": 312808
        }
    },
    {
        "iata": "SGD",
        "icao": "EKSB",
        "airportName": "Sonderborg Airport",
        "location_country": "Denmark",
        "location_city": "Sonderborg",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.96440125",
        "longitude": "9.791729927",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5895",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100963,
            "s_seats": 32179,
            "j_seats": 59545,
            "f_seats": 9790,
            "r_seats": 6557,
            "cargo_demand": 199578
        }
    },
    {
        "iata": "SGE",
        "icao": "EDGS",
        "airportName": "Siegerland Airport",
        "location_country": "Germany",
        "location_city": "North Rhine-Westphalia",
        "location_state": "Siegen",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.70769882",
        "longitude": "8.082969666",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "1640",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5315",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "1969",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 143046,
            "s_seats": 40320,
            "j_seats": 12734,
            "f_seats": 12897,
            "r_seats": 7418,
            "cargo_demand": 435521
        }
    },
    {
        "iata": "SGF",
        "icao": "KSGF",
        "airportName": "Springfield-Branson National Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Springfield",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.24570084",
        "longitude": "-93.38860321",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7003",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 181654,
            "s_seats": 91657,
            "j_seats": 84512,
            "f_seats": 39549,
            "r_seats": 10000,
            "cargo_demand": 5927524
        }
    },
    {
        "iata": "SGH",
        "icao": "KSGH",
        "airportName": "Springfield-Beckley Municipal Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Springfield",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.84030151",
        "longitude": "-83.84020233",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9009",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5499",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184235,
            "s_seats": 28404,
            "j_seats": 58203,
            "f_seats": 11976,
            "r_seats": 5480,
            "cargo_demand": 266854
        }
    },
    {
        "iata": "SGL",
        "icao": "RPLS",
        "airportName": "Sangley Point Airport",
        "location_country": "Philippines",
        "location_city": "Manila",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "14.4954",
        "longitude": "120.903999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7769",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2357,
            "s_seats": 2947,
            "j_seats": 1598,
            "f_seats": 203,
            "r_seats": 68,
            "cargo_demand": 36672
        }
    },
    {
        "iata": "SGN",
        "icao": "VVTS",
        "airportName": "Tan Son Nhat International Airport",
        "location_country": "Vietnam",
        "location_city": "Ho Chi Minh City",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "10.8188",
        "longitude": "106.652",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "10000",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "12468",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 166357,
            "s_seats": 81934,
            "j_seats": 27324,
            "f_seats": 22882,
            "r_seats": 10000,
            "cargo_demand": 15815985
        }
    },
    {
        "iata": "SGO",
        "icao": "YSGE",
        "airportName": "St George Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "St George",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.04969978",
        "longitude": "148.5950012",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4987",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2074,
            "s_seats": 2007,
            "j_seats": 1514,
            "f_seats": 396,
            "r_seats": 134,
            "cargo_demand": 731
        }
    },
    {
        "iata": "SGP",
        "icao": "YSHG",
        "airportName": "Shay Gap Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Shay Gap",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.42469978",
        "longitude": "120.1409988",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4757",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1940,
            "s_seats": 1853,
            "j_seats": 1965,
            "f_seats": 86,
            "r_seats": 58,
            "cargo_demand": 85475
        }
    },
    {
        "iata": "SGQ",
        "icao": "WRLA",
        "airportName": "Sangkimah Airport",
        "location_country": "Indonesia",
        "location_city": "Sangatta",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.3847",
        "longitude": "117.543",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2585",
                "runway_width": "75",
                "runway_material": "Asphalt/Grass"
            }
        ],
        "demand": {
            "y_seats": 2727,
            "s_seats": 2602,
            "j_seats": 1905,
            "f_seats": 83,
            "r_seats": 132,
            "cargo_demand": 15703
        }
    },
    {
        "iata": "SGR",
        "icao": "KSGR",
        "airportName": "Sugar Land Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Houston / Sugar Land",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.62229919",
        "longitude": "-95.65650177",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8000",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 137684,
            "s_seats": 30748,
            "j_seats": 20597,
            "f_seats": 14378,
            "r_seats": 5670,
            "cargo_demand": 171787
        }
    },
    {
        "iata": "SGT",
        "icao": "KSGT",
        "airportName": "Stuttgart Municipal Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Stuttgart",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.599499",
        "longitude": "-91.574997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5002",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6015",
                "runway_width": "100",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 1561,
            "s_seats": 2961,
            "j_seats": 1896,
            "f_seats": 436,
            "r_seats": 43,
            "cargo_demand": 47982
        }
    },
    {
        "iata": "SGU",
        "icao": "KSGU",
        "airportName": "St. George Regional Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "St. George",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.036389",
        "longitude": "-113.510306",
        "region": "NA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "9300",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 394190,
            "s_seats": 75871,
            "j_seats": 118777,
            "f_seats": 42834,
            "r_seats": 10000,
            "cargo_demand": 11640492
        }
    },
    {
        "iata": "SGV",
        "icao": "SAVS",
        "airportName": "Sierra Grande Airport",
        "location_country": "Argentina",
        "location_city": "Rio Negro",
        "location_state": "Sierra Grande",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-41.59170151",
        "longitude": "-65.33940125",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1527,
            "s_seats": 2519,
            "j_seats": 1904,
            "f_seats": 438,
            "r_seats": 179,
            "cargo_demand": 41747
        }
    },
    {
        "iata": "SGY",
        "icao": "PAGY",
        "airportName": "Skagway Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Skagway",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "59.46009827",
        "longitude": "-135.3159943",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3550",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 3444,
            "s_seats": 2393,
            "j_seats": 1728,
            "f_seats": 230,
            "r_seats": 183,
            "cargo_demand": 44675
        }
    },
    {
        "iata": "SGZ",
        "icao": "VTSH",
        "airportName": "Songkhla Airport",
        "location_country": "Thailand",
        "location_city": "Songkhla",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.186560154",
        "longitude": "100.6080017",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4954",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199714,
            "s_seats": 35910,
            "j_seats": 35076,
            "f_seats": 9865,
            "r_seats": 7328,
            "cargo_demand": 211707
        }
    },
    {
        "iata": "SHA",
        "icao": "ZSSS",
        "airportName": "Shanghai Hongqiao International Airport",
        "location_country": "China",
        "location_city": "Shanghai",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "31.198104",
        "longitude": "121.33426",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "11154",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "10826",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 119378,
            "s_seats": 79986,
            "j_seats": 29061,
            "f_seats": 58306,
            "r_seats": 10000,
            "cargo_demand": 3846026
        }
    },
    {
        "iata": "SHB",
        "icao": "RJCN",
        "airportName": "Nakashibetsu Airport",
        "location_country": "Japan",
        "location_city": "Hokkaido",
        "location_state": "Nakashibetsu",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.57749939",
        "longitude": "144.9600067",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6560",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 84535,
            "s_seats": 35396,
            "j_seats": 50408,
            "f_seats": 8771,
            "r_seats": 8337,
            "cargo_demand": 102441
        }
    },
    {
        "iata": "SHD",
        "icao": "KSHD",
        "airportName": "Shenandoah Valley Regional Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Staunton / Waynesboro / Harrisonburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.26380157",
        "longitude": "-78.89640045",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6002",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1320,
            "s_seats": 2872,
            "j_seats": 1284,
            "f_seats": 49,
            "r_seats": 62,
            "cargo_demand": 49147
        }
    },
    {
        "iata": "SHE",
        "icao": "ZYTX",
        "airportName": "Shenyang Taoxian International Airport",
        "location_country": "China",
        "location_city": "Liaoning",
        "location_state": "Shenyang",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "41.639801",
        "longitude": "123.483002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 257740,
            "s_seats": 85777,
            "j_seats": 86928,
            "f_seats": 65433,
            "r_seats": 10000,
            "cargo_demand": 27594068
        }
    },
    {
        "iata": "SHH",
        "icao": "PASH",
        "airportName": "Shishmaref Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Shishmaref",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "66.249604",
        "longitude": "-166.089112",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142294,
            "s_seats": 36378,
            "j_seats": 11377,
            "f_seats": 15631,
            "r_seats": 9363,
            "cargo_demand": 143250
        }
    },
    {
        "iata": "SHI",
        "icao": "RORS",
        "airportName": "Shimojishima Airport",
        "location_country": "Japan",
        "location_city": "Miyako Islands",
        "location_state": "Shimoji-shima",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.8267",
        "longitude": "125.144997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9842",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136751,
            "s_seats": 28868,
            "j_seats": 8345,
            "f_seats": 15173,
            "r_seats": 9922,
            "cargo_demand": 169099
        }
    },
    {
        "iata": "SHJ",
        "icao": "OMSJ",
        "airportName": "Sharjah International Airport",
        "location_country": "United Arab Emirates",
        "location_city": "Sharjah",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "25.32859993",
        "longitude": "55.51720047",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "13320",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 385562,
            "s_seats": 82549,
            "j_seats": 67122,
            "f_seats": 21584,
            "r_seats": 10000,
            "cargo_demand": 11582130
        }
    },
    {
        "iata": "SHL",
        "icao": "VEBI",
        "airportName": "Shillong Airport",
        "location_country": "India",
        "location_city": "Meghalaya",
        "location_state": "Shillong",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.70359993",
        "longitude": "91.97869873",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 130912,
            "s_seats": 38694,
            "j_seats": 32984,
            "f_seats": 1011,
            "r_seats": 9461,
            "cargo_demand": 285162
        }
    },
    {
        "iata": "SHM",
        "icao": "RJBD",
        "airportName": "Nanki-Shirahama Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Shirahama",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.66220093",
        "longitude": "135.3639984",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6560",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4327",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112190,
            "s_seats": 43915,
            "j_seats": 58861,
            "f_seats": 10511,
            "r_seats": 5386,
            "cargo_demand": 186255
        }
    },
    {
        "iata": "SHN",
        "icao": "KSHN",
        "airportName": "Sanderson Field",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Shelton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "47.23360062",
        "longitude": "-123.1480026",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5005",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1731,
            "s_seats": 2743,
            "j_seats": 1707,
            "f_seats": 145,
            "r_seats": 110,
            "cargo_demand": 5491
        }
    },
    {
        "iata": "SHO",
        "icao": "FDSK",
        "airportName": "King Mswati III International Airport",
        "location_country": "Swaziland",
        "location_city": "Manzini",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-26.358611",
        "longitude": "31.716944",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "11811",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 133712,
            "s_seats": 90011,
            "j_seats": 21159,
            "f_seats": 38247,
            "r_seats": 10000,
            "cargo_demand": 20406933
        }
    },
    {
        "iata": "SHQ",
        "icao": "YSPT",
        "airportName": "Southport Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Southport",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.922054",
        "longitude": "153.372143",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2871",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2754,
            "s_seats": 1549,
            "j_seats": 1563,
            "f_seats": 450,
            "r_seats": 34,
            "cargo_demand": 48987
        }
    },
    {
        "iata": "SHR",
        "icao": "KSHR",
        "airportName": "Sheridan County Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Sheridan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.76919937",
        "longitude": "-106.9800034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5039",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6648",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74813,
            "s_seats": 45538,
            "j_seats": 9877,
            "f_seats": 19578,
            "r_seats": 4932,
            "cargo_demand": 375333
        }
    },
    {
        "iata": "SHT",
        "icao": "YSHT",
        "airportName": "Shepparton Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Shepparton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-36.42890167",
        "longitude": "145.3930054",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "1388",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4521",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97232,
            "s_seats": 36698,
            "j_seats": 22439,
            "f_seats": 4170,
            "r_seats": 9944,
            "cargo_demand": 386778
        }
    },
    {
        "iata": "SHV",
        "icao": "KSHV",
        "airportName": "Shreveport Regional Airport",
        "location_country": "United States",
        "location_city": "Louisiana",
        "location_state": "Shreveport",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.446602",
        "longitude": "-93.8256",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6202",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8351",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86521,
            "s_seats": 32324,
            "j_seats": 44566,
            "f_seats": 10598,
            "r_seats": 7589,
            "cargo_demand": 66118
        }
    },
    {
        "iata": "SHW",
        "icao": "OESH",
        "airportName": "Sharurah Domestic Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Sharurah",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.46689987",
        "longitude": "47.12139893",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11975",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53508,
            "s_seats": 41777,
            "j_seats": 21948,
            "f_seats": 2666,
            "r_seats": 7075,
            "cargo_demand": 175440
        }
    },
    {
        "iata": "SHX",
        "icao": "PAHX",
        "airportName": "Shageluk Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Shageluk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "62.69229889",
        "longitude": "-159.5690002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3400",
                "runway_width": "50",
                "runway_material": "GRVL-F"
            },
            {
                "runway_name": "18W/36W",
                "runway_length": "5000",
                "runway_width": "1000",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 3544,
            "s_seats": 2923,
            "j_seats": 1261,
            "f_seats": 274,
            "r_seats": 181,
            "cargo_demand": 29483
        }
    },
    {
        "iata": "SID",
        "icao": "GVAC",
        "airportName": "Amilcar Cabral International Airport",
        "location_country": "Cape Verde",
        "location_city": "Sal",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "16.7414",
        "longitude": "-22.9494",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10735",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "4921",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 335455,
            "s_seats": 83281,
            "j_seats": 95487,
            "f_seats": 37169,
            "r_seats": 10000,
            "cargo_demand": 19007365
        }
    },
    {
        "iata": "SIF",
        "icao": "VNSI",
        "airportName": "Simara Airport",
        "location_country": "Nepal",
        "location_city": "Simara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.159803",
        "longitude": "84.980021",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3911",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2961,
            "s_seats": 2168,
            "j_seats": 1200,
            "f_seats": 22,
            "r_seats": 166,
            "cargo_demand": 97185
        }
    },
    {
        "iata": "SIG",
        "icao": "TJIG",
        "airportName": "Fernando Luis Ribas Dominicci Airport",
        "location_country": "Puerto Rico",
        "location_city": "San Juan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.45680046",
        "longitude": "-66.09809875",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5317",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 195128,
            "s_seats": 27689,
            "j_seats": 49099,
            "f_seats": 3694,
            "r_seats": 6643,
            "cargo_demand": 87314
        }
    },
    {
        "iata": "SIJ",
        "icao": "BISI",
        "airportName": "Siglufjor\u00f0ur Airport",
        "location_country": "Iceland",
        "location_city": "Siglufjor\u00f0ur",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "66.137847",
        "longitude": "-18.908157",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3556",
                "runway_width": "105",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 173702,
            "s_seats": 29519,
            "j_seats": 31161,
            "f_seats": 4406,
            "r_seats": 5506,
            "cargo_demand": 333657
        }
    },
    {
        "iata": "SIK",
        "icao": "KSIK",
        "airportName": "Sikeston Memorial Municipal Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Sikeston",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.89889908",
        "longitude": "-89.5617981",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5502",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3962,
            "s_seats": 1849,
            "j_seats": 1967,
            "f_seats": 445,
            "r_seats": 164,
            "cargo_demand": 38153
        }
    },
    {
        "iata": "SIN",
        "icao": "WSSS",
        "airportName": "Singapore Changi Airport",
        "location_country": "Singapore",
        "location_city": "",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "1.35019",
        "longitude": "103.994003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02C/20C",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02L/20R",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 282515,
            "s_seats": 71166,
            "j_seats": 147945,
            "f_seats": 33418,
            "r_seats": 10000,
            "cargo_demand": 15236574
        }
    },
    {
        "iata": "SIO",
        "icao": "YSMI",
        "airportName": "Smithton Airport",
        "location_country": "Australia",
        "location_city": "Tasmania",
        "location_state": "Smithton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-40.83499908",
        "longitude": "145.0839996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5246",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2034",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 54789,
            "s_seats": 26257,
            "j_seats": 15924,
            "f_seats": 11140,
            "r_seats": 8855,
            "cargo_demand": 167120
        }
    },
    {
        "iata": "SIP",
        "icao": "UKFF",
        "airportName": "Simferopol International Airport",
        "location_country": "Simferopol",
        "location_city": "",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "45.0522",
        "longitude": "33.975101",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "01L/19R",
                "runway_length": "12142",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 167559,
            "s_seats": 74460,
            "j_seats": 73735,
            "f_seats": 50665,
            "r_seats": 10000,
            "cargo_demand": 4041335
        }
    },
    {
        "iata": "SIQ",
        "icao": "WIDS",
        "airportName": "Dabo Singkep Airport",
        "location_country": "Indonesia",
        "location_city": "Singkep",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-0.479189008",
        "longitude": "104.5790024",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3839",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2699,
            "s_seats": 2152,
            "j_seats": 1969,
            "f_seats": 489,
            "r_seats": 96,
            "cargo_demand": 2253
        }
    },
    {
        "iata": "SIR",
        "icao": "LSGS",
        "airportName": "Sion Airport",
        "location_country": "Switzerland",
        "location_city": "Sion",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.219166",
        "longitude": "7.326944",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6562",
                "runway_width": "131",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "1837",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "6562",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174801,
            "s_seats": 29585,
            "j_seats": 7171,
            "f_seats": 18284,
            "r_seats": 9782,
            "cargo_demand": 98632
        }
    },
    {
        "iata": "SIS",
        "icao": "FASS",
        "airportName": "Sishen Airport",
        "location_country": "South Africa",
        "location_city": "Dingleton",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.64859962",
        "longitude": "22.9993",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5709",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124257,
            "s_seats": 46115,
            "j_seats": 27675,
            "f_seats": 2272,
            "r_seats": 7922,
            "cargo_demand": 134063
        }
    },
    {
        "iata": "SIT",
        "icao": "PASI",
        "airportName": "Sitka Rocky Gutierrez Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Sitka",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "57.04710007",
        "longitude": "-135.3619995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127142,
            "s_seats": 27241,
            "j_seats": 47042,
            "f_seats": 13601,
            "r_seats": 9619,
            "cargo_demand": 354826
        }
    },
    {
        "iata": "SIV",
        "icao": "KSIV",
        "airportName": "Sullivan County Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Sullivan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "39.11470032",
        "longitude": "-87.44830322",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4360",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2953,
            "s_seats": 1859,
            "j_seats": 1199,
            "f_seats": 232,
            "r_seats": 83,
            "cargo_demand": 59279
        }
    },
    {
        "iata": "SIX",
        "icao": "YSGT",
        "airportName": "Singleton Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Singleton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-32.600832",
        "longitude": "151.193056",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3608",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 3618,
            "s_seats": 2382,
            "j_seats": 1336,
            "f_seats": 233,
            "r_seats": 106,
            "cargo_demand": 20664
        }
    },
    {
        "iata": "SIY",
        "icao": "KSIY",
        "airportName": "Siskiyou County Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Montague / Yreka",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.78139877",
        "longitude": "-122.4680023",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7484",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3680,
            "s_seats": 2746,
            "j_seats": 1678,
            "f_seats": 235,
            "r_seats": 165,
            "cargo_demand": 82139
        }
    },
    {
        "iata": "SJC",
        "icao": "KSJC",
        "airportName": "San Jose International Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "San Jose",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.362598",
        "longitude": "-121.929001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4599",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "11000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "11000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 475832,
            "s_seats": 98778,
            "j_seats": 76791,
            "f_seats": 25535,
            "r_seats": 10000,
            "cargo_demand": 10624472
        }
    },
    {
        "iata": "SJD",
        "icao": "MMSD",
        "airportName": "Los Cabos International Airport",
        "location_country": "Mexico",
        "location_city": "Baja California Sur",
        "location_state": "San Jose del Cabo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "23.15180016",
        "longitude": "-109.7210007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 472354,
            "s_seats": 90149,
            "j_seats": 155125,
            "f_seats": 37041,
            "r_seats": 10000,
            "cargo_demand": 27598767
        }
    },
    {
        "iata": "SJE",
        "icao": "SKSJ",
        "airportName": "Jorge Enrique Gonzalez Torres Airport",
        "location_country": "Colombia",
        "location_city": "San Jose del Guaviare",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.57969",
        "longitude": "-72.6394",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4897",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196380,
            "s_seats": 37652,
            "j_seats": 29176,
            "f_seats": 3159,
            "r_seats": 8790,
            "cargo_demand": 70001
        }
    },
    {
        "iata": "SJI",
        "icao": "RPUH",
        "airportName": "San Jose Airport",
        "location_country": "Philippines",
        "location_city": "San Jose",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.36149979",
        "longitude": "121.0469971",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6024",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 54736,
            "s_seats": 44777,
            "j_seats": 14394,
            "f_seats": 14482,
            "r_seats": 6754,
            "cargo_demand": 218164
        }
    },
    {
        "iata": "SJJ",
        "icao": "LQSA",
        "airportName": "Sarajevo International Airport",
        "location_country": "Bosnia and Herzegovina",
        "location_city": "Sarajevo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.82460022",
        "longitude": "18.3314991",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8666",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 484974,
            "s_seats": 79554,
            "j_seats": 54357,
            "f_seats": 63413,
            "r_seats": 10000,
            "cargo_demand": 14499072
        }
    },
    {
        "iata": "SJK",
        "icao": "SBSJ",
        "airportName": "Professor Urbano Ernesto Stumpf Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Sio Jose dos Campos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.22920036",
        "longitude": "-45.86149979",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8780",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90409,
            "s_seats": 29459,
            "j_seats": 55977,
            "f_seats": 1014,
            "r_seats": 5890,
            "cargo_demand": 211287
        }
    },
    {
        "iata": "SJL",
        "icao": "SBUA",
        "airportName": "Sio Gabriel da Cachoeira Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Sio Gabriel da Cachoeira",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.14835",
        "longitude": "-66.9855",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163406,
            "s_seats": 41794,
            "j_seats": 32203,
            "f_seats": 18416,
            "r_seats": 7624,
            "cargo_demand": 309778
        }
    },
    {
        "iata": "SJN",
        "icao": "KSJN",
        "airportName": "St. Johns Industrial Air Park",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "St. Johns",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.51860046",
        "longitude": "-109.3789978",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3400",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5322",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1928,
            "s_seats": 2910,
            "j_seats": 1338,
            "f_seats": 361,
            "r_seats": 118,
            "cargo_demand": 53929
        }
    },
    {
        "iata": "SJO",
        "icao": "MROC",
        "airportName": "Juan Santamaria International Airport",
        "location_country": "Costa Rica",
        "location_city": "San Jose",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.99386",
        "longitude": "-84.208801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9882",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162720,
            "s_seats": 40857,
            "j_seats": 47334,
            "f_seats": 1393,
            "r_seats": 4256,
            "cargo_demand": 196481
        }
    },
    {
        "iata": "SJP",
        "icao": "SBSR",
        "airportName": "Prof. Eribelto Manoel Reino State Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Sio Jose do Rio Preto",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.8166008",
        "longitude": "-49.40650177",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "5381",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150854,
            "s_seats": 39191,
            "j_seats": 55790,
            "f_seats": 12247,
            "r_seats": 4756,
            "cargo_demand": 200648
        }
    },
    {
        "iata": "SJT",
        "icao": "KSJT",
        "airportName": "San Angelo Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "San Angelo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.35770035",
        "longitude": "-100.4960022",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5939",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4402",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8049",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88000,
            "s_seats": 32929,
            "j_seats": 17425,
            "f_seats": 18798,
            "r_seats": 9945,
            "cargo_demand": 406932
        }
    },
    {
        "iata": "SJU",
        "icao": "TJSJ",
        "airportName": "Luis Munoz Marin International Airport",
        "location_country": "Puerto Rico",
        "location_city": "San Juan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "18.43939972",
        "longitude": "-66.00180054",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10002",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "8016",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 141637,
            "s_seats": 91806,
            "j_seats": 144230,
            "f_seats": 62064,
            "r_seats": 10000,
            "cargo_demand": 21338154
        }
    },
    {
        "iata": "SJW",
        "icao": "ZBSJ",
        "airportName": "Shijiazhuang Zhengding International Airport",
        "location_country": "China",
        "location_city": "Hebei",
        "location_state": "Shijiazhuang",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.28070068",
        "longitude": "114.6969986",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 150992,
            "s_seats": 41132,
            "j_seats": 7491,
            "f_seats": 9800,
            "r_seats": 7575,
            "cargo_demand": 126764
        }
    },
    {
        "iata": "SJY",
        "icao": "EFSI",
        "airportName": "SeinAjoki Airport",
        "location_country": "Finland",
        "location_city": "SeinAjoki",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.692101",
        "longitude": "22.8323",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159636,
            "s_seats": 45420,
            "j_seats": 29336,
            "f_seats": 9663,
            "r_seats": 7630,
            "cargo_demand": 308378
        }
    },
    {
        "iata": "SJZ",
        "icao": "LPSJ",
        "airportName": "Sio Jorge Airport",
        "location_country": "Portugal",
        "location_city": "Azores",
        "location_state": "Sio Jorge Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.665501",
        "longitude": "-28.1758",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4633",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159565,
            "s_seats": 44391,
            "j_seats": 8232,
            "f_seats": 1146,
            "r_seats": 9667,
            "cargo_demand": 166002
        }
    },
    {
        "iata": "SKA",
        "icao": "KSKA",
        "airportName": "Fairchild Air Force Base",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Spokane",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "47.61510086",
        "longitude": "-117.6559982",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "13901",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 376990,
            "s_seats": 83394,
            "j_seats": 65826,
            "f_seats": 30305,
            "r_seats": 10000,
            "cargo_demand": 25158275
        }
    },
    {
        "iata": "SKB",
        "icao": "TKPK",
        "airportName": "Robert L. Bradshaw International Airport",
        "location_country": "Saint Kitts and Nevis",
        "location_city": "Saint Kitts",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.31119919",
        "longitude": "-62.71870041",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7602",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64287,
            "s_seats": 46236,
            "j_seats": 53215,
            "f_seats": 17095,
            "r_seats": 3281,
            "cargo_demand": 146293
        }
    },
    {
        "iata": "SKD",
        "icao": "UTSS",
        "airportName": "Samarkand International Airport",
        "location_country": "Uzbekistan",
        "location_city": "Samarkand",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.70050049",
        "longitude": "66.9838028",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10170",
                "runway_width": "161",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153380,
            "s_seats": 26699,
            "j_seats": 10774,
            "f_seats": 2474,
            "r_seats": 5700,
            "cargo_demand": 317144
        }
    },
    {
        "iata": "SKE",
        "icao": "ENSN",
        "airportName": "Skien Airport",
        "location_country": "Norway",
        "location_city": "Skien",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "59.18500137",
        "longitude": "9.566940308",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4596",
                "runway_width": "105",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101867,
            "s_seats": 39822,
            "j_seats": 43284,
            "f_seats": 9519,
            "r_seats": 5482,
            "cargo_demand": 390532
        }
    },
    {
        "iata": "SKF",
        "icao": "KSKF",
        "airportName": "Kelly Field Annex / Lackland Air Force Base",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "San Antonio",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.38419914",
        "longitude": "-98.58110046",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "11550",
                "runway_width": "300",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 59250,
            "s_seats": 41082,
            "j_seats": 14378,
            "f_seats": 17834,
            "r_seats": 5523,
            "cargo_demand": 227095
        }
    },
    {
        "iata": "SKG",
        "icao": "LGTS",
        "airportName": "Thessaloniki Airport",
        "location_country": "Greece",
        "location_city": "Thessaloniki",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.5196991",
        "longitude": "22.97089958",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "11286",
                "runway_width": "164",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7907",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 254249,
            "s_seats": 86749,
            "j_seats": 111626,
            "f_seats": 24310,
            "r_seats": 10000,
            "cargo_demand": 22242779
        }
    },
    {
        "iata": "SKH",
        "icao": "VNSK",
        "airportName": "Surkhet Airport",
        "location_country": "Nepal",
        "location_city": "Surkhet",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.586",
        "longitude": "81.636002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3400",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1501,
            "s_seats": 2025,
            "j_seats": 1305,
            "f_seats": 402,
            "r_seats": 198,
            "cargo_demand": 65762
        }
    },
    {
        "iata": "SKK",
        "icao": "PFSH",
        "airportName": "Shaktoolik Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Shaktoolik",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "64.37110138",
        "longitude": "-161.223999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 3342,
            "s_seats": 2022,
            "j_seats": 1659,
            "f_seats": 216,
            "r_seats": 29,
            "cargo_demand": 51638
        }
    },
    {
        "iata": "SKN",
        "icao": "ENSK",
        "airportName": "Stokmarknes Airport",
        "location_country": "Norway",
        "location_city": "Stokmarknes",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "68.5788269",
        "longitude": "15.03341675",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3015",
                "runway_width": "98",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 166908,
            "s_seats": 30464,
            "j_seats": 56591,
            "f_seats": 13623,
            "r_seats": 9427,
            "cargo_demand": 345727
        }
    },
    {
        "iata": "SKO",
        "icao": "DNSO",
        "airportName": "Sadiq Abubakar III International Airport",
        "location_country": "Nigeria",
        "location_city": "Sokoto",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.91629982",
        "longitude": "5.207190037",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9844",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95427,
            "s_seats": 42268,
            "j_seats": 24573,
            "f_seats": 5093,
            "r_seats": 6825,
            "cargo_demand": 374228
        }
    },
    {
        "iata": "SKP",
        "icao": "LWSK",
        "airportName": "\"Skopje \"\"Alexander the Great\"\" Airport\"",
        "location_country": "North Macedonia",
        "location_city": "Skopje",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "41.961601",
        "longitude": "21.621401",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9678",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 351709,
            "s_seats": 74024,
            "j_seats": 159760,
            "f_seats": 69569,
            "r_seats": 10000,
            "cargo_demand": 13585612
        }
    },
    {
        "iata": "SKS",
        "icao": "EKSP",
        "airportName": "Vojens Airport",
        "location_country": "Denmark",
        "location_city": "Vojens",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.221048",
        "longitude": "9.26702",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "9863",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "9750",
                "runway_width": "80",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 124367,
            "s_seats": 48058,
            "j_seats": 18603,
            "f_seats": 15846,
            "r_seats": 8894,
            "cargo_demand": 195810
        }
    },
    {
        "iata": "SKT",
        "icao": "OPST",
        "airportName": "Sialkot International Airport",
        "location_country": "Pakistan",
        "location_city": "Sialkot",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "32.535557",
        "longitude": "74.363892",
        "region": "AS",
        "runway": [
            {
                "runway_name": "22L/04R",
                "runway_length": "11811",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 393756,
            "s_seats": 86315,
            "j_seats": 24722,
            "f_seats": 68401,
            "r_seats": 10000,
            "cargo_demand": 18476701
        }
    },
    {
        "iata": "SKU",
        "icao": "LGSY",
        "airportName": "Skyros Island National Airport",
        "location_country": "Greece",
        "location_city": "Skyros",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.96760178",
        "longitude": "24.48719978",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9849",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1928,
            "s_seats": 2851,
            "j_seats": 1849,
            "f_seats": 297,
            "r_seats": 48,
            "cargo_demand": 11027
        }
    },
    {
        "iata": "SKV",
        "icao": "HESC",
        "airportName": "St. Catherine International Airport",
        "location_country": "Egypt",
        "location_city": "Saint Catherine",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.682729",
        "longitude": "34.060503",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6939",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156232,
            "s_seats": 39950,
            "j_seats": 37777,
            "f_seats": 6138,
            "r_seats": 9708,
            "cargo_demand": 420070
        }
    },
    {
        "iata": "SKW",
        "icao": "PASW",
        "airportName": "Skwentna Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Skwentna",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "61.96530151",
        "longitude": "-151.1909943",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3400",
                "runway_width": "75",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 2355,
            "s_seats": 2610,
            "j_seats": 1270,
            "f_seats": 174,
            "r_seats": 127,
            "cargo_demand": 9672
        }
    },
    {
        "iata": "SKZ",
        "icao": "OPSK",
        "airportName": "Sukkur Airport",
        "location_country": "Pakistan",
        "location_city": "Sukkur",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.72200012",
        "longitude": "68.79170227",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9000",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180539,
            "s_seats": 42420,
            "j_seats": 59302,
            "f_seats": 10895,
            "r_seats": 8878,
            "cargo_demand": 408692
        }
    },
    {
        "iata": "SLA",
        "icao": "SASA",
        "airportName": "Martin Miguel de Guemes International Airport",
        "location_country": "Argentina",
        "location_city": "Salta",
        "location_state": "Salta",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-24.8560009",
        "longitude": "-65.48619843",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "6/24",
                "runway_length": "7874",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121761,
            "s_seats": 38482,
            "j_seats": 11618,
            "f_seats": 17343,
            "r_seats": 5366,
            "cargo_demand": 55194
        }
    },
    {
        "iata": "SLB",
        "icao": "KSLB",
        "airportName": "Storm Lake Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Storm Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.59730148",
        "longitude": "-95.24069977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2037",
                "runway_width": "90",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3034",
                "runway_width": "50",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2271,
            "s_seats": 2276,
            "j_seats": 1756,
            "f_seats": 166,
            "r_seats": 3,
            "cargo_demand": 58705
        }
    },
    {
        "iata": "SLC",
        "icao": "KSLC",
        "airportName": "Salt Lake City International Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Salt Lake City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.785749",
        "longitude": "-111.979746",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4892",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "12004",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "12000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "9596",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 217359,
            "s_seats": 82379,
            "j_seats": 71868,
            "f_seats": 38266,
            "r_seats": 10000,
            "cargo_demand": 22620277
        }
    },
    {
        "iata": "SLD",
        "icao": "LZSL",
        "airportName": "Sliac Airport",
        "location_country": "Slovakia",
        "location_city": "Sliac",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.63779831",
        "longitude": "19.13409996",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7874",
                "runway_width": "187",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 87301,
            "s_seats": 49386,
            "j_seats": 47458,
            "f_seats": 4232,
            "r_seats": 6595,
            "cargo_demand": 265137
        }
    },
    {
        "iata": "SLE",
        "icao": "KSLE",
        "airportName": "McNary Field",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Salem",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.90950012",
        "longitude": "-123.0029984",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5811",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5145",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157318,
            "s_seats": 37135,
            "j_seats": 9389,
            "f_seats": 5954,
            "r_seats": 9227,
            "cargo_demand": 300551
        }
    },
    {
        "iata": "SLF",
        "icao": "OESL",
        "airportName": "Sulayel Airport",
        "location_country": "Saudi Arabia",
        "location_city": "As Sulayyil (Sulayel)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "20.4647007",
        "longitude": "45.61959839",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9860",
                "runway_width": "148",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 2956,
            "s_seats": 2468,
            "j_seats": 1606,
            "f_seats": 436,
            "r_seats": 8,
            "cargo_demand": 87142
        }
    },
    {
        "iata": "SLG",
        "icao": "KSLG",
        "airportName": "Smith Field",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Siloam Springs",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.19189835",
        "longitude": "-94.48999786",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4999",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2679,
            "s_seats": 2109,
            "j_seats": 1024,
            "f_seats": 146,
            "r_seats": 121,
            "cargo_demand": 70171
        }
    },
    {
        "iata": "SLH",
        "icao": "NVSC",
        "airportName": "Vanua Lava Airport",
        "location_country": "Vanuatu",
        "location_city": "Vanua Lava",
        "location_state": "Sola",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.85169983",
        "longitude": "167.5370026",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2723",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1875,
            "s_seats": 1583,
            "j_seats": 1441,
            "f_seats": 500,
            "r_seats": 180,
            "cargo_demand": 20997
        }
    },
    {
        "iata": "SLI",
        "icao": "FLSW",
        "airportName": "Solwezi Airport",
        "location_country": "Zambia",
        "location_city": "Solwezi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-12.17370033",
        "longitude": "26.36510086",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8858",
                "runway_width": "120",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2528,
            "s_seats": 2020,
            "j_seats": 1229,
            "f_seats": 399,
            "r_seats": 10,
            "cargo_demand": 11117
        }
    },
    {
        "iata": "SLK",
        "icao": "KSLK",
        "airportName": "Adirondack Regional Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Saranac Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.38529968",
        "longitude": "-74.20619965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6573",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3998",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171606,
            "s_seats": 45887,
            "j_seats": 7111,
            "f_seats": 11517,
            "r_seats": 3744,
            "cargo_demand": 317433
        }
    },
    {
        "iata": "SLL",
        "icao": "OOSA",
        "airportName": "Salalah International Airport",
        "location_country": "Oman",
        "location_city": "Salalah",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.0387001",
        "longitude": "54.09130096",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10965",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4196",
                "runway_width": "59",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 93275,
            "s_seats": 46347,
            "j_seats": 54230,
            "f_seats": 10370,
            "r_seats": 8090,
            "cargo_demand": 356231
        }
    },
    {
        "iata": "SLM",
        "icao": "LESA",
        "airportName": "Salamanca Airport",
        "location_country": "Spain",
        "location_city": "Castile and Leon",
        "location_state": "Salamanca",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.95209885",
        "longitude": "-5.501989841",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8202",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6594",
                "runway_width": "400",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 151781,
            "s_seats": 49169,
            "j_seats": 10840,
            "f_seats": 8812,
            "r_seats": 3281,
            "cargo_demand": 308915
        }
    },
    {
        "iata": "SLN",
        "icao": "KSLN",
        "airportName": "Salina Regional Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Salina",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.79100037",
        "longitude": "-97.65219879",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3648",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6510",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "12300",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4300",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 164224,
            "s_seats": 44848,
            "j_seats": 58002,
            "f_seats": 1304,
            "r_seats": 3733,
            "cargo_demand": 357531
        }
    },
    {
        "iata": "SLO",
        "icao": "KSLO",
        "airportName": "Salem-Leckrone Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Salem",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.64289856",
        "longitude": "-88.96420288",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4098",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2971,
            "s_seats": 2882,
            "j_seats": 1322,
            "f_seats": 321,
            "r_seats": 25,
            "cargo_demand": 59059
        }
    },
    {
        "iata": "SLP",
        "icao": "MMSP",
        "airportName": "Ponciano Arriaga International Airport",
        "location_country": "Mexico",
        "location_city": "San Luis Potosi",
        "location_state": "San Luis Potosi City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.25429916",
        "longitude": "-100.9309998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3259",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "9867",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 93871,
            "s_seats": 27221,
            "j_seats": 15312,
            "f_seats": 4329,
            "r_seats": 7347,
            "cargo_demand": 322751
        }
    },
    {
        "iata": "SLQ",
        "icao": "PASL",
        "airportName": "Sleetmute Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Sleetmute",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "61.70050049",
        "longitude": "-157.1660004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3100",
                "runway_width": "60",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 2727,
            "s_seats": 2357,
            "j_seats": 1428,
            "f_seats": 130,
            "r_seats": 8,
            "cargo_demand": 16454
        }
    },
    {
        "iata": "SLR",
        "icao": "KSLR",
        "airportName": "Sulphur Springs Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Sulphur Springs",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.15980148",
        "longitude": "-95.62110138",
        "region": "NA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "5001",
                "runway_width": "75",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 3184,
            "s_seats": 1802,
            "j_seats": 1515,
            "f_seats": 144,
            "r_seats": 133,
            "cargo_demand": 48488
        }
    },
    {
        "iata": "SLT",
        "icao": "KANK",
        "airportName": "Harriet Alexander Field",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Salida",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.5383",
        "longitude": "-106.049004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7347",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2990,
            "s_seats": 1614,
            "j_seats": 1187,
            "f_seats": 197,
            "r_seats": 150,
            "cargo_demand": 18546
        }
    },
    {
        "iata": "SLU",
        "icao": "TLPC",
        "airportName": "George F. L. Charles Airport",
        "location_country": "Saint Lucia",
        "location_city": "Castries",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.0202",
        "longitude": "-60.992901",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5735",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176664,
            "s_seats": 35073,
            "j_seats": 27410,
            "f_seats": 5897,
            "r_seats": 9632,
            "cargo_demand": 365425
        }
    },
    {
        "iata": "SLV",
        "icao": "VISM",
        "airportName": "Shimla Airport",
        "location_country": "India",
        "location_city": "Himachal Pradesh",
        "location_state": "Simla",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.0818",
        "longitude": "77.068001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4035",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3837,
            "s_seats": 1633,
            "j_seats": 1345,
            "f_seats": 414,
            "r_seats": 196,
            "cargo_demand": 27961
        }
    },
    {
        "iata": "SLW",
        "icao": "MMIO",
        "airportName": "Plan de Guadalupe International Airport",
        "location_country": "Mexico",
        "location_city": "Coahuila",
        "location_state": "Saltillo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.54949951",
        "longitude": "-100.9290009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3471",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "9506",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149342,
            "s_seats": 28841,
            "j_seats": 51196,
            "f_seats": 15511,
            "r_seats": 9013,
            "cargo_demand": 94831
        }
    },
    {
        "iata": "SLY",
        "icao": "USDD",
        "airportName": "Salekhard Airport",
        "location_country": "Russia",
        "location_city": "Yamalo-Nenets Autonomous Okrug",
        "location_state": "Salekhard",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "66.59079742",
        "longitude": "66.61100006",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8917",
                "runway_width": "151",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 70212,
            "s_seats": 42443,
            "j_seats": 7251,
            "f_seats": 9718,
            "r_seats": 8641,
            "cargo_demand": 75453
        }
    },
    {
        "iata": "SLZ",
        "icao": "SBSL",
        "airportName": "Marechal Cunha Machado International Airport",
        "location_country": "Brazil",
        "location_city": "Maranhio",
        "location_state": "Sio Luis",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.58536005",
        "longitude": "-44.23410034",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7828",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4803",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52040,
            "s_seats": 28384,
            "j_seats": 34131,
            "f_seats": 18076,
            "r_seats": 5360,
            "cargo_demand": 289833
        }
    },
    {
        "iata": "SMA",
        "icao": "LPAZ",
        "airportName": "Santa Maria Airport",
        "location_country": "Portugal",
        "location_city": "Azores",
        "location_state": "Santa Maria Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.97140121",
        "longitude": "-25.17060089",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "334",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "1004",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "10000",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 136915,
            "s_seats": 29864,
            "j_seats": 49482,
            "f_seats": 6910,
            "r_seats": 4074,
            "cargo_demand": 364895
        }
    },
    {
        "iata": "SMB",
        "icao": "SCSB",
        "airportName": "Franco Bianco Airport",
        "location_country": "Chile",
        "location_city": "Cerro Sombrero",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-52.73669815",
        "longitude": "-69.33360291",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "GRE"
            },
            {
                "runway_name": "07/25",
                "runway_length": "4921",
                "runway_width": "131",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 3406,
            "s_seats": 1982,
            "j_seats": 1125,
            "f_seats": 173,
            "r_seats": 162,
            "cargo_demand": 18464
        }
    },
    {
        "iata": "SMD",
        "icao": "KSMD",
        "airportName": "Smith Field",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Fort Wayne",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.14339828",
        "longitude": "-85.15280151",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3110",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2910",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3592,
            "s_seats": 2998,
            "j_seats": 1832,
            "f_seats": 311,
            "r_seats": 3,
            "cargo_demand": 83735
        }
    },
    {
        "iata": "SME",
        "icao": "KSME",
        "airportName": "Lake Cumberland Regional Airport",
        "location_country": "United States",
        "location_city": "Kentucky",
        "location_state": "Somerset",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.05339813",
        "longitude": "-84.61589813",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5800",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122658,
            "s_seats": 29796,
            "j_seats": 16014,
            "f_seats": 13960,
            "r_seats": 6456,
            "cargo_demand": 277443
        }
    },
    {
        "iata": "SMF",
        "icao": "KSMF",
        "airportName": "Sacramento International Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Sacramento",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "38.69540024",
        "longitude": "-121.5910034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "8601",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "8598",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 186068,
            "s_seats": 81915,
            "j_seats": 84151,
            "f_seats": 49302,
            "r_seats": 10000,
            "cargo_demand": 24468285
        }
    },
    {
        "iata": "SMI",
        "icao": "LGSM",
        "airportName": "Samos International Airport",
        "location_country": "Greece",
        "location_city": "Samos",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.68999863",
        "longitude": "26.9116993",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6706",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106701,
            "s_seats": 45755,
            "j_seats": 59737,
            "f_seats": 2867,
            "r_seats": 5840,
            "cargo_demand": 322151
        }
    },
    {
        "iata": "SMK",
        "icao": "PAMK",
        "airportName": "St. Michael Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "St. Michael",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "63.49010086",
        "longitude": "-162.1100006",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4001",
                "runway_width": "75",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 1463,
            "s_seats": 2289,
            "j_seats": 1521,
            "f_seats": 332,
            "r_seats": 198,
            "cargo_demand": 75543
        }
    },
    {
        "iata": "SML",
        "icao": "MYLS",
        "airportName": "Stella Maris Airport",
        "location_country": "Bahamas",
        "location_city": "Long Island",
        "location_state": "Stella Maris",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.582317",
        "longitude": "-75.268621",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122849,
            "s_seats": 29445,
            "j_seats": 9862,
            "f_seats": 9412,
            "r_seats": 9897,
            "cargo_demand": 174413
        }
    },
    {
        "iata": "SMN",
        "icao": "KSMN",
        "airportName": "Lemhi County Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Salmon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.12379837",
        "longitude": "-113.8809967",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5150",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86593,
            "s_seats": 26846,
            "j_seats": 14176,
            "f_seats": 10578,
            "r_seats": 7399,
            "cargo_demand": 392404
        }
    },
    {
        "iata": "SMO",
        "icao": "KSMO",
        "airportName": "Santa Monica Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Santa Monica",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.0158",
        "longitude": "-118.450996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3500",
                "runway_width": "150",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 1101,
            "s_seats": 1809,
            "j_seats": 1322,
            "f_seats": 377,
            "r_seats": 94,
            "cargo_demand": 88389
        }
    },
    {
        "iata": "SMQ",
        "icao": "WAOS",
        "airportName": "H. Asan Airport",
        "location_country": "Indonesia",
        "location_city": "Sampit",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.501389",
        "longitude": "112.975555",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6060",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1422,
            "s_seats": 2799,
            "j_seats": 1753,
            "f_seats": 228,
            "r_seats": 185,
            "cargo_demand": 70837
        }
    },
    {
        "iata": "SMR",
        "icao": "SKSM",
        "airportName": "Simon Bolivar International Airport",
        "location_country": "Colombia",
        "location_city": "Santa Marta",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.1196",
        "longitude": "-74.2306",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5577",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157791,
            "s_seats": 46093,
            "j_seats": 21825,
            "f_seats": 19941,
            "r_seats": 6940,
            "cargo_demand": 74055
        }
    },
    {
        "iata": "SMS",
        "icao": "FMMS",
        "airportName": "Sainte Marie Airport",
        "location_country": "Madagascar",
        "location_city": "Ile Sainte-Marie",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.093901",
        "longitude": "49.8158",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3451",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154607,
            "s_seats": 46316,
            "j_seats": 55401,
            "f_seats": 14570,
            "r_seats": 6460,
            "cargo_demand": 242282
        }
    },
    {
        "iata": "SMU",
        "icao": "PASP",
        "airportName": "Sheep Mountain Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Sheep Mountain",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "61.81200027",
        "longitude": "-147.5070038",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2270",
                "runway_width": "10",
                "runway_material": "GRVL-DIRT-P"
            }
        ],
        "demand": {
            "y_seats": 1846,
            "s_seats": 2511,
            "j_seats": 1575,
            "f_seats": 30,
            "r_seats": 124,
            "cargo_demand": 84472
        }
    },
    {
        "iata": "SMV",
        "icao": "LSZS",
        "airportName": "Samedan Airport",
        "location_country": "Switzerland",
        "location_city": "St. Moritz",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.53409958",
        "longitude": "9.884110451",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5905",
                "runway_width": "130",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134416,
            "s_seats": 30354,
            "j_seats": 53150,
            "f_seats": 15202,
            "r_seats": 9449,
            "cargo_demand": 261117
        }
    },
    {
        "iata": "SMW",
        "icao": "GMMA",
        "airportName": "Smara Airport",
        "location_country": "Morocco",
        "location_city": "Smara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.731987",
        "longitude": "-11.683655",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9850",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57570,
            "s_seats": 31240,
            "j_seats": 36457,
            "f_seats": 3252,
            "r_seats": 8596,
            "cargo_demand": 85237
        }
    },
    {
        "iata": "SMX",
        "icao": "KSMX",
        "airportName": "Santa Maria Public Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Santa Maria",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.89889908",
        "longitude": "-120.4570007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5130",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6304",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135482,
            "s_seats": 41557,
            "j_seats": 16825,
            "f_seats": 16632,
            "r_seats": 6619,
            "cargo_demand": 134558
        }
    },
    {
        "iata": "SNA",
        "icao": "KSNA",
        "airportName": "John Wayne Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Santa Ana",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.675701",
        "longitude": "-117.867996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02L/20R",
                "runway_length": "5700",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "2887",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182324,
            "s_seats": 26334,
            "j_seats": 52507,
            "f_seats": 1299,
            "r_seats": 3654,
            "cargo_demand": 213871
        }
    },
    {
        "iata": "SNB",
        "icao": "YSNB",
        "airportName": "Snake Bay Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Milikapiti",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.42280006",
        "longitude": "130.654007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4734",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118505,
            "s_seats": 26200,
            "j_seats": 17906,
            "f_seats": 16544,
            "r_seats": 3576,
            "cargo_demand": 227448
        }
    },
    {
        "iata": "SNC",
        "icao": "SESA",
        "airportName": "General Ulpiano Paez Airport",
        "location_country": "Ecuador",
        "location_city": "Salinas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.20499",
        "longitude": "-80.988899",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3641",
                "runway_width": "131",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7995",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101534,
            "s_seats": 41394,
            "j_seats": 32400,
            "f_seats": 6688,
            "r_seats": 9356,
            "cargo_demand": 134481
        }
    },
    {
        "iata": "SNE",
        "icao": "GVSN",
        "airportName": "PreguiiCa Airport",
        "location_country": "Cape Verde",
        "location_city": "Sio Nicolau",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.58839989",
        "longitude": "-24.28470039",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163352,
            "s_seats": 36510,
            "j_seats": 56999,
            "f_seats": 12385,
            "r_seats": 6825,
            "cargo_demand": 388857
        }
    },
    {
        "iata": "SNF",
        "icao": "SVSP",
        "airportName": "Sub Teniente Nestor Arias Airport",
        "location_country": "Venezuela",
        "location_city": "San Felipe",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.2787",
        "longitude": "-68.755203",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4921",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105139,
            "s_seats": 39628,
            "j_seats": 56042,
            "f_seats": 16669,
            "r_seats": 5100,
            "cargo_demand": 355996
        }
    },
    {
        "iata": "SNG",
        "icao": "SLSI",
        "airportName": "Capitan Av. Juan Cochamanidis Airport",
        "location_country": "Bolivia",
        "location_city": "San Ignacio de Velasco",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.3836",
        "longitude": "-60.962799",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3936",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1424,
            "s_seats": 1875,
            "j_seats": 1917,
            "f_seats": 415,
            "r_seats": 175,
            "cargo_demand": 40523
        }
    },
    {
        "iata": "SNH",
        "icao": "YSPE",
        "airportName": "Stanthorpe Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Stanthorpe",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.62030029",
        "longitude": "151.9909973",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5597",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2842,
            "s_seats": 2258,
            "j_seats": 1901,
            "f_seats": 318,
            "r_seats": 70,
            "cargo_demand": 2032
        }
    },
    {
        "iata": "SNK",
        "icao": "KSNK",
        "airportName": "Winston Field Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Snyder",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.690212",
        "longitude": "-100.948918",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4200",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5600",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3202,
            "s_seats": 2854,
            "j_seats": 1827,
            "f_seats": 346,
            "r_seats": 22,
            "cargo_demand": 56951
        }
    },
    {
        "iata": "SNL",
        "icao": "KSNL",
        "airportName": "Shawnee Regional Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Shawnee",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.35789871",
        "longitude": "-96.94280243",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5600",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1599,
            "s_seats": 2970,
            "j_seats": 1793,
            "f_seats": 167,
            "r_seats": 26,
            "cargo_demand": 63170
        }
    },
    {
        "iata": "SNN",
        "icao": "EINN",
        "airportName": "Shannon Airport",
        "location_country": "Ireland",
        "location_city": "Shannon",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "52.702",
        "longitude": "-8.92482",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "463",
                "runway_width": "198",
                "runway_material": "PEM"
            },
            {
                "runway_name": "06/24",
                "runway_length": "10495",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "805",
                "runway_width": "148",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13/31",
                "runway_length": "543",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "23",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 368881,
            "s_seats": 74489,
            "j_seats": 127234,
            "f_seats": 46311,
            "r_seats": 10000,
            "cargo_demand": 26526294
        }
    },
    {
        "iata": "SNO",
        "icao": "VTUI",
        "airportName": "Sakon Nakhon Airport",
        "location_country": "Thailand",
        "location_city": "Sakon Nakhon",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.19510078",
        "longitude": "104.1190033",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 96119,
            "s_seats": 48172,
            "j_seats": 54169,
            "f_seats": 3540,
            "r_seats": 5469,
            "cargo_demand": 263135
        }
    },
    {
        "iata": "SNP",
        "icao": "PASN",
        "airportName": "St. Paul Island Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "St. Paul Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "57.166311",
        "longitude": "-170.222555",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51195,
            "s_seats": 34149,
            "j_seats": 30399,
            "f_seats": 9408,
            "r_seats": 6373,
            "cargo_demand": 253193
        }
    },
    {
        "iata": "SNR",
        "icao": "LFRZ",
        "airportName": "Saint-Nazaire Montoir Airport",
        "location_country": "France",
        "location_city": "Pays de la Loire",
        "location_state": "Saint-Nazaire",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.3105556",
        "longitude": "-2.1566667",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50048,
            "s_seats": 29542,
            "j_seats": 30971,
            "f_seats": 14988,
            "r_seats": 4662,
            "cargo_demand": 293132
        }
    },
    {
        "iata": "SNS",
        "icao": "KSNS",
        "airportName": "Salinas Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Salinas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.66279984",
        "longitude": "-121.6060028",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6004",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4825",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "1900",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142666,
            "s_seats": 47536,
            "j_seats": 10473,
            "f_seats": 11105,
            "r_seats": 4330,
            "cargo_demand": 239633
        }
    },
    {
        "iata": "SNU",
        "icao": "MUSC",
        "airportName": "Abel Santamaria Airport",
        "location_country": "Cuba",
        "location_city": "Santa Clara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.49220085",
        "longitude": "-79.94360352",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9898",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83387,
            "s_seats": 42476,
            "j_seats": 50799,
            "f_seats": 4188,
            "r_seats": 4222,
            "cargo_demand": 122272
        }
    },
    {
        "iata": "SNV",
        "icao": "SVSE",
        "airportName": "Santa Elena de Uairen Airport",
        "location_country": "Venezuela",
        "location_city": "Santa Elena de Uairen",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.554999828",
        "longitude": "-61.15000153",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5445",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51103,
            "s_seats": 34892,
            "j_seats": 27412,
            "f_seats": 2326,
            "r_seats": 7523,
            "cargo_demand": 69824
        }
    },
    {
        "iata": "SNW",
        "icao": "VYTD",
        "airportName": "Thandwe Airport",
        "location_country": "Myanmar",
        "location_city": "Thandwe",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.46069908",
        "longitude": "94.30010223",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5502",
                "runway_width": "102",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 179129,
            "s_seats": 37245,
            "j_seats": 38398,
            "f_seats": 17457,
            "r_seats": 5018,
            "cargo_demand": 431043
        }
    },
    {
        "iata": "SNY",
        "icao": "KSNY",
        "airportName": "Sidney Municipal Airport",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Sidney",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.10129929",
        "longitude": "-102.9850006",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4700",
                "runway_width": "75",
                "runway_material": "TURF-G"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6600",
                "runway_width": "100",
                "runway_material": "CONC-G"
            }
        ],
        "demand": {
            "y_seats": 164513,
            "s_seats": 30080,
            "j_seats": 14660,
            "f_seats": 12084,
            "r_seats": 3712,
            "cargo_demand": 389028
        }
    },
    {
        "iata": "SNZ",
        "icao": "SBSC",
        "airportName": "Santa Cruz Air Force Base",
        "location_country": "Brazil",
        "location_city": "Rio de Janeiro",
        "location_state": "Rio de Janeiro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.9324",
        "longitude": "-43.719101",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "8986",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53950,
            "s_seats": 27382,
            "j_seats": 44270,
            "f_seats": 18375,
            "r_seats": 9867,
            "cargo_demand": 190806
        }
    },
    {
        "iata": "SOB",
        "icao": "LHSM",
        "airportName": "Heviz-Balaton Airport",
        "location_country": "Hungary",
        "location_city": "Heviz",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.686391",
        "longitude": "17.159084",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8202",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "3100",
                "runway_width": "212",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 56305,
            "s_seats": 26204,
            "j_seats": 31238,
            "f_seats": 9147,
            "r_seats": 4880,
            "cargo_demand": 403082
        }
    },
    {
        "iata": "SOC",
        "icao": "WARQ",
        "airportName": "Adisumarmo International Airport",
        "location_country": "Indonesia",
        "location_city": "Surakarta (Solo)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.513564",
        "longitude": "110.750494",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1790,
            "s_seats": 2847,
            "j_seats": 1163,
            "f_seats": 396,
            "r_seats": 131,
            "cargo_demand": 12144
        }
    },
    {
        "iata": "SOD",
        "icao": "SDCO",
        "airportName": "Bertram Luiz Leupolz-Sorocaba Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Sorocaba",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-23.478001",
        "longitude": "-47.490002",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4856",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1328,
            "s_seats": 1986,
            "j_seats": 1962,
            "f_seats": 467,
            "r_seats": 127,
            "cargo_demand": 78185
        }
    },
    {
        "iata": "SOF",
        "icao": "LBSF",
        "airportName": "Sofia Airport",
        "location_country": "Bulgaria",
        "location_city": "Sofia",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "42.69669342",
        "longitude": "23.41143608",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "11811",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102660,
            "s_seats": 99699,
            "j_seats": 116020,
            "f_seats": 46081,
            "r_seats": 10000,
            "cargo_demand": 11509063
        }
    },
    {
        "iata": "SOG",
        "icao": "ENSG",
        "airportName": "Sogndal Airport",
        "location_country": "Norway",
        "location_city": "Sogndal",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "61.156101",
        "longitude": "7.13778",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3150",
                "runway_width": "98",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3491,
            "s_seats": 2566,
            "j_seats": 1295,
            "f_seats": 464,
            "r_seats": 4,
            "cargo_demand": 56508
        }
    },
    {
        "iata": "SOJ",
        "icao": "ENSR",
        "airportName": "Sorkjosen Airport",
        "location_country": "Norway",
        "location_city": "Sorkjosen",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "69.78679657",
        "longitude": "20.95940018",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3015",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66819,
            "s_seats": 44542,
            "j_seats": 42941,
            "f_seats": 15338,
            "r_seats": 5933,
            "cargo_demand": 279328
        }
    },
    {
        "iata": "SOM",
        "icao": "SVST",
        "airportName": "San Tome Airport",
        "location_country": "Venezuela",
        "location_city": "San Tome",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.945146561",
        "longitude": "-64.1510849",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6299",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "1785",
                "runway_width": "90",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79465,
            "s_seats": 29000,
            "j_seats": 40560,
            "f_seats": 2507,
            "r_seats": 4798,
            "cargo_demand": 375636
        }
    },
    {
        "iata": "SON",
        "icao": "NVSS",
        "airportName": "Santo-Pekoa International Airport",
        "location_country": "Vanuatu",
        "location_city": "Luganville",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.50500011",
        "longitude": "167.2200012",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6523",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138223,
            "s_seats": 42090,
            "j_seats": 28986,
            "f_seats": 11418,
            "r_seats": 7006,
            "cargo_demand": 253942
        }
    },
    {
        "iata": "SOO",
        "icao": "ESNY",
        "airportName": "Soderhamn Airport",
        "location_country": "Sweden",
        "location_city": "Soderhamn",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.26150131",
        "longitude": "17.09910011",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2625",
                "runway_width": "56",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8281",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92134,
            "s_seats": 26225,
            "j_seats": 18897,
            "f_seats": 9731,
            "r_seats": 9578,
            "cargo_demand": 468286
        }
    },
    {
        "iata": "SOP",
        "icao": "KSOP",
        "airportName": "Moore County Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Pinehurst / Southern Pines",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.23740005",
        "longitude": "-79.3911972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5503",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "1999",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2129,
            "s_seats": 2958,
            "j_seats": 1571,
            "f_seats": 119,
            "r_seats": 89,
            "cargo_demand": 12389
        }
    },
    {
        "iata": "SOT",
        "icao": "EFSO",
        "airportName": "SodankylA Airfield",
        "location_country": "Finland",
        "location_city": "SodankylA",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "67.39499664",
        "longitude": "26.61910057",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178634,
            "s_seats": 34668,
            "j_seats": 43137,
            "f_seats": 5976,
            "r_seats": 4997,
            "cargo_demand": 87963
        }
    },
    {
        "iata": "SOU",
        "icao": "EGHI",
        "airportName": "Southampton Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Southampton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "50.95029831",
        "longitude": "-1.35679996",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5653",
                "runway_width": "121",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185449,
            "s_seats": 94396,
            "j_seats": 26486,
            "f_seats": 49864,
            "r_seats": 10000,
            "cargo_demand": 25467384
        }
    },
    {
        "iata": "SOV",
        "icao": "PASO",
        "airportName": "Seldovia Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Seldovia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "59.44240189",
        "longitude": "-151.7039948",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "1845",
                "runway_width": "60",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 2864,
            "s_seats": 2367,
            "j_seats": 1211,
            "f_seats": 208,
            "r_seats": 74,
            "cargo_demand": 40735
        }
    },
    {
        "iata": "SOW",
        "icao": "KSOW",
        "airportName": "Show Low Regional Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Show Low",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.265049",
        "longitude": "-110.007084",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3937",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "7200",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2702,
            "s_seats": 2845,
            "j_seats": 1484,
            "f_seats": 80,
            "r_seats": 121,
            "cargo_demand": 43266
        }
    },
    {
        "iata": "SOX",
        "icao": "SKSO",
        "airportName": "Alberto Lleras Camargo Airport",
        "location_country": "Colombia",
        "location_city": "Sogamoso",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "5.67732",
        "longitude": "-72.9703",
        "region": "SA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6171",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1107,
            "s_seats": 2183,
            "j_seats": 1130,
            "f_seats": 1,
            "r_seats": 0,
            "cargo_demand": 73371
        }
    },
    {
        "iata": "SOY",
        "icao": "EGER",
        "airportName": "Stronsay Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Stronsay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "59.15530014",
        "longitude": "-2.641390085",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "1689",
                "runway_width": "59",
                "runway_material": "Graded Hardcore"
            },
            {
                "runway_name": "06/24",
                "runway_length": "1348",
                "runway_width": "98",
                "runway_material": "Grass"
            },
            {
                "runway_name": "10/28",
                "runway_length": "1325",
                "runway_width": "59",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3931,
            "s_seats": 1788,
            "j_seats": 1274,
            "f_seats": 396,
            "r_seats": 179,
            "cargo_demand": 87157
        }
    },
    {
        "iata": "SOZ",
        "icao": "LFKS",
        "airportName": "Solenzara Air Base",
        "location_country": "France",
        "location_city": "Corsica",
        "location_state": "Sari-Solenzara",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.92440033",
        "longitude": "9.406000137",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8619",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 182185,
            "s_seats": 49517,
            "j_seats": 59570,
            "f_seats": 11075,
            "r_seats": 6925,
            "cargo_demand": 87301
        }
    },
    {
        "iata": "SPA",
        "icao": "KSPA",
        "airportName": "Spartanburg Downtown Memorial Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Spartanburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.91569901",
        "longitude": "-81.95649719",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5202",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2810,
            "s_seats": 1726,
            "j_seats": 1705,
            "f_seats": 253,
            "r_seats": 1,
            "cargo_demand": 23157
        }
    },
    {
        "iata": "SPC",
        "icao": "GCLA",
        "airportName": "La Palma Airport",
        "location_country": "Spain",
        "location_city": "Canary Islands",
        "location_state": "La Palma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.626499",
        "longitude": "-17.7556",
        "region": "EU",
        "runway": [
            {
                "runway_name": "36/18",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177668,
            "s_seats": 26752,
            "j_seats": 43405,
            "f_seats": 17540,
            "r_seats": 6132,
            "cargo_demand": 374711
        }
    },
    {
        "iata": "SPD",
        "icao": "VGSD",
        "airportName": "Saidpur Airport",
        "location_country": "Bangladesh",
        "location_city": "Saidpur",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.75919914",
        "longitude": "88.9088974",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161479,
            "s_seats": 36713,
            "j_seats": 42560,
            "f_seats": 12046,
            "r_seats": 4630,
            "cargo_demand": 353840
        }
    },
    {
        "iata": "SPF",
        "icao": "KSPF",
        "airportName": "Black Hills Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Spearfish",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.4803009",
        "longitude": "-103.7829971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2023",
                "runway_width": "150",
                "runway_material": "TURF-G"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3975",
                "runway_width": "100",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6400",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 3630,
            "s_seats": 2375,
            "j_seats": 1583,
            "f_seats": 135,
            "r_seats": 69,
            "cargo_demand": 46748
        }
    },
    {
        "iata": "SPG",
        "icao": "KSPG",
        "airportName": "Albert Whitted Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "St. Petersburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.7651",
        "longitude": "-82.626999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3677",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2864",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3971,
            "s_seats": 1866,
            "j_seats": 1968,
            "f_seats": 418,
            "r_seats": 146,
            "cargo_demand": 28394
        }
    },
    {
        "iata": "SPI",
        "icao": "KSPI",
        "airportName": "Abraham Lincoln Capital Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Springfield",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "39.84410095",
        "longitude": "-89.67790222",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7999",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5300",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 179597,
            "s_seats": 70266,
            "j_seats": 96015,
            "f_seats": 63340,
            "r_seats": 10000,
            "cargo_demand": 13786481
        }
    },
    {
        "iata": "SPJ",
        "icao": "LGSP",
        "airportName": "Sparti Airport",
        "location_country": "Greece",
        "location_city": "Sparti (Sparta)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.97389984",
        "longitude": "22.52630043",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3280",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1736,
            "s_seats": 1856,
            "j_seats": 1525,
            "f_seats": 58,
            "r_seats": 31,
            "cargo_demand": 92824
        }
    },
    {
        "iata": "SPM",
        "icao": "ETAD",
        "airportName": "Spangdahlem Air Base",
        "location_country": "Germany",
        "location_city": "Rhineland-Palatinate",
        "location_state": "Spangdahlem",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.97269821",
        "longitude": "6.692500114",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116032,
            "s_seats": 38108,
            "j_seats": 43443,
            "f_seats": 19736,
            "r_seats": 7591,
            "cargo_demand": 383531
        }
    },
    {
        "iata": "SPN",
        "icao": "PGSN",
        "airportName": "Saipan International Airport",
        "location_country": "Northern Mariana Islands",
        "location_city": "Saipan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.119",
        "longitude": "145.729004",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8700",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70062,
            "s_seats": 30126,
            "j_seats": 52136,
            "f_seats": 8815,
            "r_seats": 8354,
            "cargo_demand": 112870
        }
    },
    {
        "iata": "SPP",
        "icao": "FNME",
        "airportName": "Menongue Airport",
        "location_country": "Angola",
        "location_city": "Menongue",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.6576004",
        "longitude": "17.71980095",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "11483",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78506,
            "s_seats": 41416,
            "j_seats": 13999,
            "f_seats": 14171,
            "r_seats": 9352,
            "cargo_demand": 86361
        }
    },
    {
        "iata": "SPS",
        "icao": "KSPS",
        "airportName": "Wichita Falls Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Wichita Falls",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "33.9888",
        "longitude": "-98.491898",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15C/33C",
                "runway_length": "10003",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "15L/33R",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "13101",
                "runway_width": "300",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7021",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 439199,
            "s_seats": 74678,
            "j_seats": 149326,
            "f_seats": 29719,
            "r_seats": 10000,
            "cargo_demand": 4109830
        }
    },
    {
        "iata": "SPU",
        "icao": "LDSP",
        "airportName": "Split Airport",
        "location_country": "Croatia",
        "location_city": "Split",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.53889847",
        "longitude": "16.29800034",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8366",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105448,
            "s_seats": 46688,
            "j_seats": 38058,
            "f_seats": 17420,
            "r_seats": 3535,
            "cargo_demand": 404844
        }
    },
    {
        "iata": "SPW",
        "icao": "KSPW",
        "airportName": "Spencer Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Spencer",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.16550064",
        "longitude": "-95.20279694",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5100",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1828,
            "s_seats": 1876,
            "j_seats": 1331,
            "f_seats": 57,
            "r_seats": 145,
            "cargo_demand": 77181
        }
    },
    {
        "iata": "SPY",
        "icao": "DISP",
        "airportName": "San Pedro Airport",
        "location_country": "Ivory Coast",
        "location_city": "San-Pedro",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.746719837",
        "longitude": "-6.660820007",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6234",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113190,
            "s_seats": 25749,
            "j_seats": 6775,
            "f_seats": 8154,
            "r_seats": 4036,
            "cargo_demand": 483134
        }
    },
    {
        "iata": "SPZ",
        "icao": "KASG",
        "airportName": "Springdale Municipal Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Springdale",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.17639923",
        "longitude": "-94.11930084",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5302",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3006,
            "s_seats": 1909,
            "j_seats": 1693,
            "f_seats": 447,
            "r_seats": 194,
            "cargo_demand": 63700
        }
    },
    {
        "iata": "SQA",
        "icao": "KIZA",
        "airportName": "Santa Ynez Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Santa Ynez",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.60680008",
        "longitude": "-120.0759964",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2804",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3674,
            "s_seats": 1685,
            "j_seats": 1236,
            "f_seats": 126,
            "r_seats": 174,
            "cargo_demand": 95594
        }
    },
    {
        "iata": "SQC",
        "icao": "YSCR",
        "airportName": "Southern Cross Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Southern Cross",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31.23999977",
        "longitude": "119.3600006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4337",
                "runway_width": "",
                "runway_material": "S"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4609",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2239,
            "s_seats": 2423,
            "j_seats": 1758,
            "f_seats": 57,
            "r_seats": 22,
            "cargo_demand": 86527
        }
    },
    {
        "iata": "SQG",
        "icao": "WIOS",
        "airportName": "Tebelian Airport",
        "location_country": "Indonesia",
        "location_city": "Sintang",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.063619003",
        "longitude": "111.4729996",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4256",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118780,
            "s_seats": 27459,
            "j_seats": 24759,
            "f_seats": 16005,
            "r_seats": 7014,
            "cargo_demand": 272350
        }
    },
    {
        "iata": "SQH",
        "icao": "VVNS",
        "airportName": "Na\u00a0 San Airport",
        "location_country": "Vietnam",
        "location_city": "Son La",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.21699905",
        "longitude": "104.0329971",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7874",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1450,
            "s_seats": 1949,
            "j_seats": 1598,
            "f_seats": 147,
            "r_seats": 102,
            "cargo_demand": 24210
        }
    },
    {
        "iata": "SQI",
        "icao": "KSQI",
        "airportName": "Whiteside County Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Sterling / Rock Falls",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.74280167",
        "longitude": "-89.67630005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3899",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1716,
            "s_seats": 2761,
            "j_seats": 1577,
            "f_seats": 18,
            "r_seats": 88,
            "cargo_demand": 29466
        }
    },
    {
        "iata": "SQL",
        "icao": "KSQL",
        "airportName": "San Carlos Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "San Carlos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.51190186",
        "longitude": "-122.25",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2600",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3237,
            "s_seats": 2447,
            "j_seats": 1093,
            "f_seats": 355,
            "r_seats": 100,
            "cargo_demand": 75339
        }
    },
    {
        "iata": "SQM",
        "icao": "SWUA",
        "airportName": "Sio Miguel do Araguaia Airport",
        "location_country": "Brazil",
        "location_city": "Goias",
        "location_state": "Sio Miguel do Araguaia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.33129978",
        "longitude": "-50.19760132",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2610,
            "s_seats": 2797,
            "j_seats": 1614,
            "f_seats": 215,
            "r_seats": 94,
            "cargo_demand": 68225
        }
    },
    {
        "iata": "SQO",
        "icao": "ESUD",
        "airportName": "Storuman Airport",
        "location_country": "Sweden",
        "location_city": "Storuman",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.960899",
        "longitude": "17.6966",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7490",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115360,
            "s_seats": 25083,
            "j_seats": 33275,
            "f_seats": 4583,
            "r_seats": 3351,
            "cargo_demand": 482617
        }
    },
    {
        "iata": "SQQ",
        "icao": "EYSA",
        "airportName": "\u0160iauliai International Airport",
        "location_country": "Lithuania",
        "location_city": "\u0160iauliai",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.89390182",
        "longitude": "23.39500046",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "10761",
                "runway_width": "105",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 158980,
            "s_seats": 38170,
            "j_seats": 30669,
            "f_seats": 1904,
            "r_seats": 6648,
            "cargo_demand": 332716
        }
    },
    {
        "iata": "SQR",
        "icao": "WAWS",
        "airportName": "Soroako Airport",
        "location_country": "Indonesia",
        "location_city": "Soroako",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.531199932",
        "longitude": "121.3580017",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3340",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3480,
            "s_seats": 1791,
            "j_seats": 1912,
            "f_seats": 111,
            "r_seats": 124,
            "cargo_demand": 83860
        }
    },
    {
        "iata": "SQW",
        "icao": "EKSV",
        "airportName": "Skive Airport",
        "location_country": "Denmark",
        "location_city": "Skive",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.55020142",
        "longitude": "9.172980309",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3933",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81418,
            "s_seats": 25644,
            "j_seats": 15137,
            "f_seats": 2197,
            "r_seats": 7696,
            "cargo_demand": 184917
        }
    },
    {
        "iata": "SQX",
        "icao": "SSOE",
        "airportName": "Helio Wasum Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "Sio Miguel do Oeste",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.78160095",
        "longitude": "-53.50350189",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4134",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3207,
            "s_seats": 2801,
            "j_seats": 1723,
            "f_seats": 63,
            "r_seats": 8,
            "cargo_demand": 5435
        }
    },
    {
        "iata": "SQY",
        "icao": "SSRU",
        "airportName": "Sio LoureniCo do Sul Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Sio LoureniCo do Sul",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31.38330078",
        "longitude": "-52.03279877",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "3609",
                "runway_width": "59",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2592,
            "s_seats": 2503,
            "j_seats": 1601,
            "f_seats": 457,
            "r_seats": 163,
            "cargo_demand": 98273
        }
    },
    {
        "iata": "SQZ",
        "icao": "EGXP",
        "airportName": "RAF Scampton",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Scampton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.30780029",
        "longitude": "-0.550832987",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8990",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97800,
            "s_seats": 43069,
            "j_seats": 7419,
            "f_seats": 16344,
            "r_seats": 9259,
            "cargo_demand": 356557
        }
    },
    {
        "iata": "SRA",
        "icao": "SSZR",
        "airportName": "Santa Rosa Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Santa Rosa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.9067",
        "longitude": "-54.520401",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1398,
            "s_seats": 2920,
            "j_seats": 1727,
            "f_seats": 500,
            "r_seats": 58,
            "cargo_demand": 65810
        }
    },
    {
        "iata": "SRC",
        "icao": "KSRC",
        "airportName": "Searcy Municipal Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Searcy",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.21060181",
        "longitude": "-91.73750305",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6008",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1120,
            "s_seats": 2139,
            "j_seats": 1325,
            "f_seats": 210,
            "r_seats": 46,
            "cargo_demand": 22437
        }
    },
    {
        "iata": "SRE",
        "icao": "SLAL",
        "airportName": "Alcantari Airport",
        "location_country": "Bolivia",
        "location_city": "Sucre",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.246835",
        "longitude": "-65.149611",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "11811",
                "runway_width": "",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 133141,
            "s_seats": 33207,
            "j_seats": 29186,
            "f_seats": 2369,
            "r_seats": 5765,
            "cargo_demand": 226100
        }
    },
    {
        "iata": "SRG",
        "icao": "WARS",
        "airportName": "Achmad Yani International Airport",
        "location_country": "Indonesia",
        "location_city": "Semarang",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.97273",
        "longitude": "110.375",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6070",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2828,
            "s_seats": 2495,
            "j_seats": 1262,
            "f_seats": 146,
            "r_seats": 197,
            "cargo_demand": 74864
        }
    },
    {
        "iata": "SRH",
        "icao": "FTTA",
        "airportName": "Sarh Airport",
        "location_country": "Chad",
        "location_city": "Sarh",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "9.14444",
        "longitude": "18.374399",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5906",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3057,
            "s_seats": 2549,
            "j_seats": 1192,
            "f_seats": 67,
            "r_seats": 152,
            "cargo_demand": 63567
        }
    },
    {
        "iata": "SRJ",
        "icao": "SLSB",
        "airportName": "Capitan German Quiroga Guardia Airport",
        "location_country": "Bolivia",
        "location_city": "San Borja",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.85919952",
        "longitude": "-66.73750305",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5906",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2767,
            "s_seats": 2353,
            "j_seats": 1817,
            "f_seats": 174,
            "r_seats": 62,
            "cargo_demand": 35415
        }
    },
    {
        "iata": "SRN",
        "icao": "YSRN",
        "airportName": "Strahan Airport",
        "location_country": "Australia",
        "location_city": "Tasmania",
        "location_state": "Strahan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-42.15499878",
        "longitude": "145.2920074",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4002",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3143,
            "s_seats": 1610,
            "j_seats": 1064,
            "f_seats": 379,
            "r_seats": 26,
            "cargo_demand": 33288
        }
    },
    {
        "iata": "SRQ",
        "icao": "KSRQ",
        "airportName": "Sarasota-Bradenton International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Sarasota / Bradenton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "27.39539909",
        "longitude": "-82.55439758",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5009",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "9500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 379283,
            "s_seats": 95950,
            "j_seats": 143301,
            "f_seats": 54230,
            "r_seats": 10000,
            "cargo_demand": 11777217
        }
    },
    {
        "iata": "SRT",
        "icao": "HUSO",
        "airportName": "Soroti Airport",
        "location_country": "Uganda",
        "location_city": "Soroti",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.727689981",
        "longitude": "33.62279892",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6100",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2525",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 186410,
            "s_seats": 31717,
            "j_seats": 28733,
            "f_seats": 16635,
            "r_seats": 9137,
            "cargo_demand": 115391
        }
    },
    {
        "iata": "SRW",
        "icao": "KRUQ",
        "airportName": "Rowan County Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Salisbury",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.645901",
        "longitude": "-80.520302",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2459,
            "s_seats": 2659,
            "j_seats": 1808,
            "f_seats": 151,
            "r_seats": 60,
            "cargo_demand": 49010
        }
    },
    {
        "iata": "SRX",
        "icao": "HLGD",
        "airportName": "Gardabya Airport",
        "location_country": "Libya",
        "location_city": "Sirte",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.06349945",
        "longitude": "16.59499931",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "11807",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "11807",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191481,
            "s_seats": 47941,
            "j_seats": 43120,
            "f_seats": 15067,
            "r_seats": 5537,
            "cargo_demand": 95464
        }
    },
    {
        "iata": "SRY",
        "icao": "OINZ",
        "airportName": "Dasht-e Naz Airport",
        "location_country": "Iran",
        "location_city": "Sari",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.644408",
        "longitude": "53.188761",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8688",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88050,
            "s_seats": 37037,
            "j_seats": 56082,
            "f_seats": 19212,
            "r_seats": 7116,
            "cargo_demand": 238497
        }
    },
    {
        "iata": "SRZ",
        "icao": "SLET",
        "airportName": "El Trompillo Airport",
        "location_country": "Bolivia",
        "location_city": "Santa Cruz de la Sierra",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.81159973",
        "longitude": "-63.17150116",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "9098",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191554,
            "s_seats": 25167,
            "j_seats": 59992,
            "f_seats": 10945,
            "r_seats": 8165,
            "cargo_demand": 71783
        }
    },
    {
        "iata": "SSA",
        "icao": "SBSV",
        "airportName": "Deputado Luis Eduardo Magalhies International Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Salvador",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-12.9086113",
        "longitude": "-38.32249832",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9859",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4987",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 338443,
            "s_seats": 83070,
            "j_seats": 28785,
            "f_seats": 51701,
            "r_seats": 10000,
            "cargo_demand": 14661148
        }
    },
    {
        "iata": "SSC",
        "icao": "KSSC",
        "airportName": "Shaw Air Force Base",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Sumter",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "33.97269821",
        "longitude": "-80.47059631",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "10016",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 485998,
            "s_seats": 93608,
            "j_seats": 108419,
            "f_seats": 23812,
            "r_seats": 10000,
            "cargo_demand": 27607996
        }
    },
    {
        "iata": "SSE",
        "icao": "VASL",
        "airportName": "Solapur Airport",
        "location_country": "India",
        "location_city": "Maharashtra",
        "location_state": "Solapur",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.62800026",
        "longitude": "75.93479919",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6591",
                "runway_width": "145",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 158667,
            "s_seats": 37656,
            "j_seats": 48545,
            "f_seats": 5367,
            "r_seats": 6337,
            "cargo_demand": 281841
        }
    },
    {
        "iata": "SSF",
        "icao": "KSSF",
        "airportName": "Stinson Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "San Antonio",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.33699989",
        "longitude": "-98.47109985",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4835",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4128",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77220,
            "s_seats": 48673,
            "j_seats": 10697,
            "f_seats": 13742,
            "r_seats": 9230,
            "cargo_demand": 225552
        }
    },
    {
        "iata": "SSG",
        "icao": "FGSL",
        "airportName": "Malabo International Airport",
        "location_country": "Equatorial Guinea",
        "location_city": "Malabo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.755270004",
        "longitude": "8.708720207",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9647",
                "runway_width": "151",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 180268,
            "s_seats": 38194,
            "j_seats": 51215,
            "f_seats": 13677,
            "r_seats": 9070,
            "cargo_demand": 58568
        }
    },
    {
        "iata": "SSH",
        "icao": "HESH",
        "airportName": "Sharm el-Sheikh International Airport",
        "location_country": "Egypt",
        "location_city": "Sharm el-Sheikh",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "27.977301",
        "longitude": "34.395",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "10108",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "10108",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143739,
            "s_seats": 96366,
            "j_seats": 49394,
            "f_seats": 46946,
            "r_seats": 10000,
            "cargo_demand": 17821721
        }
    },
    {
        "iata": "SSI",
        "icao": "KSSI",
        "airportName": "Malcolm McKinnon Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Brunswick",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.1518",
        "longitude": "-81.391296",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5800",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3313",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166190,
            "s_seats": 32016,
            "j_seats": 48435,
            "f_seats": 17195,
            "r_seats": 3372,
            "cargo_demand": 131726
        }
    },
    {
        "iata": "SSJ",
        "icao": "ENST",
        "airportName": "Sandnessjoen Airport",
        "location_country": "Norway",
        "location_city": "Sandnessjoen",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "65.95680237",
        "longitude": "12.46889973",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3563",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 52907,
            "s_seats": 29090,
            "j_seats": 42641,
            "f_seats": 14329,
            "r_seats": 5477,
            "cargo_demand": 200878
        }
    },
    {
        "iata": "SSN",
        "icao": "RKSM",
        "airportName": "Seoul Air Base",
        "location_country": "South Korea",
        "location_city": "Seoul",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.444744",
        "longitude": "127.112718",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "02/20",
                "runway_length": "9700",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 72151,
            "s_seats": 39149,
            "j_seats": 57888,
            "f_seats": 19582,
            "r_seats": 4299,
            "cargo_demand": 247588
        }
    },
    {
        "iata": "SSO",
        "icao": "SNLO",
        "airportName": "Sio LoureniCo Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Sio LoureniCo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.09090042",
        "longitude": "-45.04449844",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "3510",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3018,
            "s_seats": 2738,
            "j_seats": 1145,
            "f_seats": 343,
            "r_seats": 94,
            "cargo_demand": 41949
        }
    },
    {
        "iata": "SSR",
        "icao": "NVSH",
        "airportName": "Sara Airport",
        "location_country": "Vanuatu",
        "location_city": "Sara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.4708004",
        "longitude": "168.1519928",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "1969",
                "runway_width": "98",
                "runway_material": "Coral"
            }
        ],
        "demand": {
            "y_seats": 3729,
            "s_seats": 1736,
            "j_seats": 1101,
            "f_seats": 17,
            "r_seats": 59,
            "cargo_demand": 47120
        }
    },
    {
        "iata": "SST",
        "icao": "SAZL",
        "airportName": "Santa Teresita Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "Santa Teresita",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-36.5423",
        "longitude": "-56.7218",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104582,
            "s_seats": 44528,
            "j_seats": 55521,
            "f_seats": 15287,
            "r_seats": 9976,
            "cargo_demand": 329035
        }
    },
    {
        "iata": "SSY",
        "icao": "FNBC",
        "airportName": "Mbanza Congo Airport",
        "location_country": "Angola",
        "location_city": "M'banza-Kongo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.269899845",
        "longitude": "14.24699974",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62634,
            "s_seats": 36852,
            "j_seats": 18459,
            "f_seats": 10689,
            "r_seats": 5504,
            "cargo_demand": 250787
        }
    },
    {
        "iata": "SSZ",
        "icao": "SBST",
        "airportName": "Santos Air Force Base",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Santos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.928192",
        "longitude": "-46.300195",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4560",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162226,
            "s_seats": 42231,
            "j_seats": 19649,
            "f_seats": 14799,
            "r_seats": 3257,
            "cargo_demand": 364032
        }
    },
    {
        "iata": "STA",
        "icao": "EKVJ",
        "airportName": "Stauning Vestjylland Airport",
        "location_country": "Denmark",
        "location_city": "Skjern",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.99010086",
        "longitude": "8.353910446",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4757",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178813,
            "s_seats": 33042,
            "j_seats": 10103,
            "f_seats": 6214,
            "r_seats": 4129,
            "cargo_demand": 485179
        }
    },
    {
        "iata": "STB",
        "icao": "SVSZ",
        "airportName": "Miguel Urdaneta Fernandez Airport",
        "location_country": "Venezuela",
        "location_city": "Santa Barbara del Zulia",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.974550247",
        "longitude": "-71.94325256",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8835",
                "runway_width": "130",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189002,
            "s_seats": 31248,
            "j_seats": 27024,
            "f_seats": 19323,
            "r_seats": 6574,
            "cargo_demand": 297203
        }
    },
    {
        "iata": "STC",
        "icao": "KSTC",
        "airportName": "St. Cloud Regional Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "St. Cloud",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.5466",
        "longitude": "-94.059898",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 193673,
            "s_seats": 28447,
            "j_seats": 14292,
            "f_seats": 2194,
            "r_seats": 9716,
            "cargo_demand": 70130
        }
    },
    {
        "iata": "STD",
        "icao": "SVSO",
        "airportName": "Mayor Buenaventura Vivas Airport",
        "location_country": "Venezuela",
        "location_city": "Tachira",
        "location_state": "Santo Domingo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.56538",
        "longitude": "-72.035103",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9990",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188993,
            "s_seats": 49187,
            "j_seats": 6701,
            "f_seats": 4244,
            "r_seats": 9961,
            "cargo_demand": 361774
        }
    },
    {
        "iata": "STE",
        "icao": "KSTE",
        "airportName": "Stevens Point Municipal Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Stevens Point",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.54520035",
        "longitude": "-89.53029633",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6028",
                "runway_width": "120",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3635",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1270,
            "s_seats": 1547,
            "j_seats": 1051,
            "f_seats": 75,
            "r_seats": 189,
            "cargo_demand": 85945
        }
    },
    {
        "iata": "STG",
        "icao": "PAPB",
        "airportName": "St. George Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "St. George",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.5783",
        "longitude": "-169.662003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 178144,
            "s_seats": 41505,
            "j_seats": 41634,
            "f_seats": 15237,
            "r_seats": 9916,
            "cargo_demand": 392964
        }
    },
    {
        "iata": "STH",
        "icao": "YSMR",
        "airportName": "Strathmore Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Strathmore Station",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.85000038",
        "longitude": "142.5670013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 2136,
            "s_seats": 2510,
            "j_seats": 1881,
            "f_seats": 470,
            "r_seats": 65,
            "cargo_demand": 15674
        }
    },
    {
        "iata": "STI",
        "icao": "MDST",
        "airportName": "Cibao International Airport",
        "location_country": "Dominican Republic",
        "location_city": "Santiago de los Caballeros",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.40609932",
        "longitude": "-70.60469818",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8595",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159056,
            "s_seats": 32309,
            "j_seats": 32812,
            "f_seats": 4516,
            "r_seats": 5949,
            "cargo_demand": 442335
        }
    },
    {
        "iata": "STJ",
        "icao": "KSTJ",
        "airportName": "Rosecrans Memorial Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "St. Joseph",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.77190018",
        "longitude": "-94.90969849",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4797",
                "runway_width": "75",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17/35",
                "runway_length": "8059",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 73014,
            "s_seats": 27125,
            "j_seats": 43194,
            "f_seats": 17188,
            "r_seats": 9948,
            "cargo_demand": 196009
        }
    },
    {
        "iata": "STK",
        "icao": "KSTK",
        "airportName": "Sterling Municipal Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Sterling",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.61529922",
        "longitude": "-103.2649994",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2500",
                "runway_width": "150",
                "runway_material": "GVL"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4692",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1644,
            "s_seats": 1537,
            "j_seats": 1859,
            "f_seats": 396,
            "r_seats": 164,
            "cargo_demand": 3498
        }
    },
    {
        "iata": "STL",
        "icao": "KSTL",
        "airportName": "Lambert-St. Louis International Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "St. Louis",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "38.748697",
        "longitude": "-90.370003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7602",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "11/29",
                "runway_length": "9001",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "9003",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "11019",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 125079,
            "s_seats": 81290,
            "j_seats": 59572,
            "f_seats": 57315,
            "r_seats": 10000,
            "cargo_demand": 21063412
        }
    },
    {
        "iata": "STM",
        "icao": "SBSN",
        "airportName": "Santarem-Maestro Wilson Fonseca Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Santarem",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.424721956",
        "longitude": "-54.78583145",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128324,
            "s_seats": 25500,
            "j_seats": 13228,
            "f_seats": 7883,
            "r_seats": 6405,
            "cargo_demand": 124507
        }
    },
    {
        "iata": "STN",
        "icao": "EGSS",
        "airportName": "London Stansted Airport",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "London",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "51.88499832",
        "longitude": "0.234999999",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10003",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 452225,
            "s_seats": 70130,
            "j_seats": 67391,
            "f_seats": 22062,
            "r_seats": 10000,
            "cargo_demand": 27436195
        }
    },
    {
        "iata": "STP",
        "icao": "KSTP",
        "airportName": "St. Paul Downtown Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "St. Paul / Minneapolis",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.93450165",
        "longitude": "-93.05999756",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3657",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4115",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6711",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51580,
            "s_seats": 49688,
            "j_seats": 27808,
            "f_seats": 10388,
            "r_seats": 8559,
            "cargo_demand": 352846
        }
    },
    {
        "iata": "STQ",
        "icao": "KOYM",
        "airportName": "St. Marys Municipal Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "St. Marys",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.41249847",
        "longitude": "-78.50260162",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4300",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3195,
            "s_seats": 2705,
            "j_seats": 1684,
            "f_seats": 400,
            "r_seats": 85,
            "cargo_demand": 45030
        }
    },
    {
        "iata": "STR",
        "icao": "EDDS",
        "airportName": "Stuttgart Airport",
        "location_country": "Germany",
        "location_city": "Baden-Wurttemberg",
        "location_state": "Stuttgart",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "48.68989944",
        "longitude": "9.221960068",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10974",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 167382,
            "s_seats": 99381,
            "j_seats": 112767,
            "f_seats": 69163,
            "r_seats": 10000,
            "cargo_demand": 27782569
        }
    },
    {
        "iata": "STS",
        "icao": "KSTS",
        "airportName": "Charles M. Schulz-Sonoma County Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Santa Rosa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.50899887",
        "longitude": "-122.8130035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5202",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192298,
            "s_seats": 31993,
            "j_seats": 21938,
            "f_seats": 16084,
            "r_seats": 3334,
            "cargo_demand": 375889
        }
    },
    {
        "iata": "STT",
        "icao": "TIST",
        "airportName": "Cyril E. King Airport",
        "location_country": "United States",
        "location_city": "St. Thomas Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.33729935",
        "longitude": "-64.9733963",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 107471,
            "s_seats": 34736,
            "j_seats": 50668,
            "f_seats": 9970,
            "r_seats": 5243,
            "cargo_demand": 278119
        }
    },
    {
        "iata": "STV",
        "icao": "VASU",
        "airportName": "Surat Airport",
        "location_country": "India",
        "location_city": "Gujarat",
        "location_state": "Surat",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.1140995",
        "longitude": "72.7417984",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9530",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124078,
            "s_seats": 32636,
            "j_seats": 46839,
            "f_seats": 5168,
            "r_seats": 3955,
            "cargo_demand": 108672
        }
    },
    {
        "iata": "STW",
        "icao": "URMT",
        "airportName": "Stavropol Shpakovskoye Airport",
        "location_country": "Russia",
        "location_city": "Stavropol Krai",
        "location_state": "Stavropol",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.10919952",
        "longitude": "42.1128006",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8631",
                "runway_width": "157",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159349,
            "s_seats": 48473,
            "j_seats": 36531,
            "f_seats": 17190,
            "r_seats": 9216,
            "cargo_demand": 436267
        }
    },
    {
        "iata": "STX",
        "icao": "TISX",
        "airportName": "Henry E. Rohlsen Airport",
        "location_country": "United States",
        "location_city": "Saint Croix",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.70190048",
        "longitude": "-64.79859924",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10004",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139366,
            "s_seats": 31902,
            "j_seats": 59643,
            "f_seats": 18559,
            "r_seats": 3582,
            "cargo_demand": 181853
        }
    },
    {
        "iata": "STY",
        "icao": "SUSO",
        "airportName": "Nueva Hesperides International Airport",
        "location_country": "Uruguay",
        "location_city": "Salto",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.43849945",
        "longitude": "-57.98529816",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5210",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2461",
                "runway_width": "164",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 115219,
            "s_seats": 41635,
            "j_seats": 56944,
            "f_seats": 19632,
            "r_seats": 4227,
            "cargo_demand": 309632
        }
    },
    {
        "iata": "STZ",
        "icao": "SWST",
        "airportName": "Santa Terezinha Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Santa Terezinha",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.46472168",
        "longitude": "-50.51861191",
        "region": "SA",
        "runway": [
            {
                "runway_name": "8/26",
                "runway_length": "4921",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3153,
            "s_seats": 2207,
            "j_seats": 1685,
            "f_seats": 107,
            "r_seats": 84,
            "cargo_demand": 24114
        }
    },
    {
        "iata": "SUA",
        "icao": "KSUA",
        "airportName": "Witham Field",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Stuart",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.18169975",
        "longitude": "-80.22109985",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4652",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5826",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2678,
            "s_seats": 2390,
            "j_seats": 1475,
            "f_seats": 7,
            "r_seats": 155,
            "cargo_demand": 61376
        }
    },
    {
        "iata": "SUB",
        "icao": "WARR",
        "airportName": "Juanda International Airport",
        "location_country": "Indonesia",
        "location_city": "Surabaya",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-7.379829884",
        "longitude": "112.7870026",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 424825,
            "s_seats": 76335,
            "j_seats": 33077,
            "f_seats": 45249,
            "r_seats": 10000,
            "cargo_demand": 1310842
        }
    },
    {
        "iata": "SUD",
        "icao": "KSUD",
        "airportName": "Stroud Municipal Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Stroud",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.78960037",
        "longitude": "-96.65570068",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1902,
            "s_seats": 1721,
            "j_seats": 1677,
            "f_seats": 91,
            "r_seats": 106,
            "cargo_demand": 44653
        }
    },
    {
        "iata": "SUE",
        "icao": "KSUE",
        "airportName": "Door County Cherryland Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Sturgeon Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.84370041",
        "longitude": "-87.42150116",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4600",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3200",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1259,
            "s_seats": 2966,
            "j_seats": 1928,
            "f_seats": 211,
            "r_seats": 18,
            "cargo_demand": 22620
        }
    },
    {
        "iata": "SUF",
        "icao": "LICA",
        "airportName": "Lamezia Terme International Airport",
        "location_country": "Italy",
        "location_city": "Calabria",
        "location_state": "Lamezia Terme",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.905399",
        "longitude": "16.2423",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9898",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138611,
            "s_seats": 48742,
            "j_seats": 51088,
            "f_seats": 8351,
            "r_seats": 5043,
            "cargo_demand": 190211
        }
    },
    {
        "iata": "SUG",
        "icao": "RPMS",
        "airportName": "Surigao Airport",
        "location_country": "Philippines",
        "location_city": "Surigao City",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.755838326",
        "longitude": "125.4809475",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5603",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 72080,
            "s_seats": 33749,
            "j_seats": 17423,
            "f_seats": 2149,
            "r_seats": 3556,
            "cargo_demand": 110795
        }
    },
    {
        "iata": "SUI",
        "icao": "UGSS",
        "airportName": "Sukhumi Babushara Airport",
        "location_country": "Georgia",
        "location_city": "Sukhumi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.8582",
        "longitude": "41.128101",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "12012",
                "runway_width": "172",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 78095,
            "s_seats": 27554,
            "j_seats": 8460,
            "f_seats": 12281,
            "r_seats": 3508,
            "cargo_demand": 255271
        }
    },
    {
        "iata": "SUJ",
        "icao": "LRSM",
        "airportName": "Satu Mare International Airport",
        "location_country": "Romania",
        "location_city": "Satu Mare",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.70330048",
        "longitude": "22.88570023",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8160",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 75946,
            "s_seats": 38142,
            "j_seats": 18039,
            "f_seats": 16537,
            "r_seats": 9598,
            "cargo_demand": 378268
        }
    },
    {
        "iata": "SUL",
        "icao": "OPSU",
        "airportName": "Sui Airport",
        "location_country": "Pakistan",
        "location_city": "Sui",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.64509964",
        "longitude": "69.17690277",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4975",
                "runway_width": "150",
                "runway_material": "SAN"
            },
            {
                "runway_name": "01L/19R",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170531,
            "s_seats": 34712,
            "j_seats": 35853,
            "f_seats": 15215,
            "r_seats": 5257,
            "cargo_demand": 69744
        }
    },
    {
        "iata": "SUM",
        "icao": "KSMS",
        "airportName": "Sumter Airport",
        "location_country": "United States",
        "location_city": "South Carolina",
        "location_state": "Sumter",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.99499893",
        "longitude": "-80.36129761",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5501",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3081",
                "runway_width": "120",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3904,
            "s_seats": 2471,
            "j_seats": 1161,
            "f_seats": 230,
            "r_seats": 53,
            "cargo_demand": 85691
        }
    },
    {
        "iata": "SUN",
        "icao": "KSUN",
        "airportName": "Friedman Memorial Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Hailey / Sun Valley",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.50439835",
        "longitude": "-114.2959976",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6952",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100828,
            "s_seats": 46918,
            "j_seats": 46970,
            "f_seats": 19044,
            "r_seats": 7844,
            "cargo_demand": 51406
        }
    },
    {
        "iata": "SUP",
        "icao": "WART",
        "airportName": "Trunojoyo Airport",
        "location_country": "Indonesia",
        "location_city": "Sumenep",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.0242",
        "longitude": "113.89023",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3816",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1498,
            "s_seats": 2983,
            "j_seats": 1881,
            "f_seats": 370,
            "r_seats": 91,
            "cargo_demand": 13213
        }
    },
    {
        "iata": "SUS",
        "icao": "KSUS",
        "airportName": "Spirit of St. Louis Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "St. Louis",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "38.66210175",
        "longitude": "-90.65200043",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "7485",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 219362,
            "s_seats": 86938,
            "j_seats": 31982,
            "f_seats": 63662,
            "r_seats": 10000,
            "cargo_demand": 9855746
        }
    },
    {
        "iata": "SUT",
        "icao": "HTSU",
        "airportName": "Sumbawanga Airport",
        "location_country": "Tanzania",
        "location_city": "Sumbawanga",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.948889",
        "longitude": "31.610278",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2928,
            "s_seats": 1594,
            "j_seats": 1885,
            "f_seats": 173,
            "r_seats": 88,
            "cargo_demand": 15530
        }
    },
    {
        "iata": "SUU",
        "icao": "KSUU",
        "airportName": "Travis Air Force Base",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Fairfield",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.262699",
        "longitude": "-121.927002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "11001",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "10992",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 154345,
            "s_seats": 49676,
            "j_seats": 8691,
            "f_seats": 11151,
            "r_seats": 5357,
            "cargo_demand": 466464
        }
    },
    {
        "iata": "SUV",
        "icao": "NFNA",
        "airportName": "Nausori International Airport",
        "location_country": "Fiji",
        "location_city": "Suva",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-18.04330063",
        "longitude": "178.5590057",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6129",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77387,
            "s_seats": 37478,
            "j_seats": 46200,
            "f_seats": 16470,
            "r_seats": 6599,
            "cargo_demand": 356614
        }
    },
    {
        "iata": "SUW",
        "icao": "KSUW",
        "airportName": "Richard I. Bong Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Superior",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "46.68970108",
        "longitude": "-92.09470367",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5100",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1602,
            "s_seats": 2931,
            "j_seats": 1067,
            "f_seats": 492,
            "r_seats": 101,
            "cargo_demand": 10025
        }
    },
    {
        "iata": "SUX",
        "icao": "KSUX",
        "airportName": "Sioux Gateway Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Sioux City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "42.397605",
        "longitude": "-96.382237",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9002",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6600",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186893,
            "s_seats": 82674,
            "j_seats": 164433,
            "f_seats": 25921,
            "r_seats": 10000,
            "cargo_demand": 22831674
        }
    },
    {
        "iata": "SVA",
        "icao": "PASA",
        "airportName": "Savoonga Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Savoonga",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "63.68640137",
        "longitude": "-170.4929962",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4400",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 136457,
            "s_seats": 36103,
            "j_seats": 54047,
            "f_seats": 18296,
            "r_seats": 9316,
            "cargo_demand": 178509
        }
    },
    {
        "iata": "SVB",
        "icao": "FMNS",
        "airportName": "Sambava Airport",
        "location_country": "Madagascar",
        "location_city": "Sambava",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.2786",
        "longitude": "50.174702",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4577",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115570,
            "s_seats": 35527,
            "j_seats": 37681,
            "f_seats": 12107,
            "r_seats": 6072,
            "cargo_demand": 419323
        }
    },
    {
        "iata": "SVC",
        "icao": "KSVC",
        "airportName": "Grant County Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Silver City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.632293",
        "longitude": "-108.154263",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4637",
                "runway_width": "80",
                "runway_material": "GRE"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6802",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4675",
                "runway_width": "75",
                "runway_material": "GRE"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5473",
                "runway_width": "75",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2652,
            "s_seats": 2732,
            "j_seats": 1731,
            "f_seats": 453,
            "r_seats": 82,
            "cargo_demand": 21631
        }
    },
    {
        "iata": "SVD",
        "icao": "TVSA",
        "airportName": "Argyle International Airport",
        "location_country": "Saint Vincent and the Grenadines",
        "location_city": "Saint Vincent",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.156695",
        "longitude": "-61.149945",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9000",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189231,
            "s_seats": 44664,
            "j_seats": 48938,
            "f_seats": 4040,
            "r_seats": 4086,
            "cargo_demand": 384609
        }
    },
    {
        "iata": "SVE",
        "icao": "KSVE",
        "airportName": "Susanville Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Susanville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.3757019",
        "longitude": "-120.572998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2179",
                "runway_width": "60",
                "runway_material": "GRE"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4049",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1516,
            "s_seats": 2864,
            "j_seats": 1631,
            "f_seats": 177,
            "r_seats": 171,
            "cargo_demand": 30940
        }
    },
    {
        "iata": "SVG",
        "icao": "ENZV",
        "airportName": "Stavanger Airport",
        "location_country": "Norway",
        "location_city": "Stavanger",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "58.87670135",
        "longitude": "5.63778019",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8035",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "9369",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 347368,
            "s_seats": 94713,
            "j_seats": 94805,
            "f_seats": 48029,
            "r_seats": 10000,
            "cargo_demand": 16672649
        }
    },
    {
        "iata": "SVH",
        "icao": "KSVH",
        "airportName": "Statesville Regional Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Statesville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.76530075",
        "longitude": "-80.9539032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7006",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1902,
            "s_seats": 2669,
            "j_seats": 1266,
            "f_seats": 287,
            "r_seats": 140,
            "cargo_demand": 44126
        }
    },
    {
        "iata": "SVI",
        "icao": "SKSV",
        "airportName": "Eduardo Falla Solano Airport",
        "location_country": "Colombia",
        "location_city": "San Vicente del Caguan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.15217",
        "longitude": "-74.7663",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4921",
                "runway_width": "85",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99260,
            "s_seats": 49087,
            "j_seats": 25896,
            "f_seats": 2065,
            "r_seats": 6617,
            "cargo_demand": 482823
        }
    },
    {
        "iata": "SVJ",
        "icao": "ENSH",
        "airportName": "Svolvi\u00e6r Airport",
        "location_country": "Norway",
        "location_city": "Svolvi\u00e6r",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "68.24330139",
        "longitude": "14.66919994",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3104",
                "runway_width": "98",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 92613,
            "s_seats": 26138,
            "j_seats": 24289,
            "f_seats": 2562,
            "r_seats": 5952,
            "cargo_demand": 216333
        }
    },
    {
        "iata": "SVL",
        "icao": "EFSA",
        "airportName": "Savonlinna Airport",
        "location_country": "Finland",
        "location_city": "Savonlinna",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.9431",
        "longitude": "28.945101",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160597,
            "s_seats": 45649,
            "j_seats": 50446,
            "f_seats": 17768,
            "r_seats": 7051,
            "cargo_demand": 456702
        }
    },
    {
        "iata": "SVN",
        "icao": "KSVN",
        "airportName": "Hunter Army Airfield",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Savannah",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.00999832",
        "longitude": "-81.14569855",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "11375",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66953,
            "s_seats": 48060,
            "j_seats": 54027,
            "f_seats": 18485,
            "r_seats": 9537,
            "cargo_demand": 414117
        }
    },
    {
        "iata": "SVO",
        "icao": "UUEE",
        "airportName": "Sheremetyevo International Airport",
        "location_country": "Russia",
        "location_city": "Moscow",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "55.972599",
        "longitude": "37.4146",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06C/24C",
                "runway_length": "11647",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "06L/24R",
                "runway_length": "10498",
                "runway_width": "197",
                "runway_material": "paved"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "12139",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 122551,
            "s_seats": 99999,
            "j_seats": 34216,
            "f_seats": 65408,
            "r_seats": 10000,
            "cargo_demand": 15020459
        }
    },
    {
        "iata": "SVP",
        "icao": "FNKU",
        "airportName": "Kuito Airport",
        "location_country": "Angola",
        "location_city": "Kuito",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.40460014",
        "longitude": "16.94739914",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8202",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135819,
            "s_seats": 34790,
            "j_seats": 15582,
            "f_seats": 16121,
            "r_seats": 3021,
            "cargo_demand": 366712
        }
    },
    {
        "iata": "SVQ",
        "icao": "LEZL",
        "airportName": "Seville Airport",
        "location_country": "Spain",
        "location_city": "Andalusia",
        "location_state": "Seville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.41799927",
        "longitude": "-5.893109798",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "11030",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161548,
            "s_seats": 28287,
            "j_seats": 13726,
            "f_seats": 16734,
            "r_seats": 8919,
            "cargo_demand": 96111
        }
    },
    {
        "iata": "SVT",
        "icao": "FBSV",
        "airportName": "Savuti Airport",
        "location_country": "Botswana",
        "location_city": "Savuti",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.52059937",
        "longitude": "24.07670021",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7218",
                "runway_width": "66",
                "runway_material": "Dirt"
            }
        ],
        "demand": {
            "y_seats": 2344,
            "s_seats": 2861,
            "j_seats": 1972,
            "f_seats": 221,
            "r_seats": 130,
            "cargo_demand": 82358
        }
    },
    {
        "iata": "SVU",
        "icao": "NFNS",
        "airportName": "Savusavu Airport",
        "location_country": "Fiji",
        "location_city": "Savusavu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.80279922",
        "longitude": "179.3410034",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3300",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1955,
            "s_seats": 1718,
            "j_seats": 1058,
            "f_seats": 480,
            "r_seats": 162,
            "cargo_demand": 67005
        }
    },
    {
        "iata": "SVW",
        "icao": "PASV",
        "airportName": "Sparrevohn LRRS Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Sparrevohn",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.09740067",
        "longitude": "-155.5740051",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4100",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 68963,
            "s_seats": 39428,
            "j_seats": 55242,
            "f_seats": 4772,
            "r_seats": 5566,
            "cargo_demand": 92075
        }
    },
    {
        "iata": "SVX",
        "icao": "USSS",
        "airportName": "Koltsovo International Airport",
        "location_country": "Russia",
        "location_city": "Sverdlovsk Oblast",
        "location_state": "Yekaterinburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "56.74309921",
        "longitude": "60.80270004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "8192",
                "runway_width": "140",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "9925",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 454278,
            "s_seats": 99736,
            "j_seats": 41888,
            "f_seats": 68871,
            "r_seats": 10000,
            "cargo_demand": 25092129
        }
    },
    {
        "iata": "SVZ",
        "icao": "SVSA",
        "airportName": "Juan Vicente Gomez International Airport",
        "location_country": "Venezuela",
        "location_city": "San Antonio del Tachira",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.840829849",
        "longitude": "-72.43969727",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6135",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63840,
            "s_seats": 38321,
            "j_seats": 37886,
            "f_seats": 18255,
            "r_seats": 4524,
            "cargo_demand": 184366
        }
    },
    {
        "iata": "SWA",
        "icao": "ZGOW",
        "airportName": "Jieyang Chaoshan International Airport",
        "location_country": "China",
        "location_city": "Guangdong",
        "location_state": "Shantou / Jieyang / Chaozhou",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.552",
        "longitude": "116.5033",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 136252,
            "s_seats": 36286,
            "j_seats": 20577,
            "f_seats": 12198,
            "r_seats": 5822,
            "cargo_demand": 329988
        }
    },
    {
        "iata": "SWC",
        "icao": "YSWL",
        "airportName": "Stawell Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Stawell",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-37.07170105",
        "longitude": "142.7409973",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4603",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2802",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51704,
            "s_seats": 39653,
            "j_seats": 33690,
            "f_seats": 5975,
            "r_seats": 4633,
            "cargo_demand": 216718
        }
    },
    {
        "iata": "SWD",
        "icao": "PAWD",
        "airportName": "Seward Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Seward",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.130478",
        "longitude": "-149.418612",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4240",
                "runway_width": "100",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2279",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 168024,
            "s_seats": 29447,
            "j_seats": 58614,
            "f_seats": 9977,
            "r_seats": 7757,
            "cargo_demand": 153774
        }
    },
    {
        "iata": "SWF",
        "icao": "KSWF",
        "airportName": "Stewart International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Newburgh",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.504101",
        "longitude": "-74.104797",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "11818",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "6006",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116214,
            "s_seats": 32344,
            "j_seats": 7585,
            "f_seats": 3880,
            "r_seats": 8655,
            "cargo_demand": 153764
        }
    },
    {
        "iata": "SWH",
        "icao": "YSWH",
        "airportName": "Swan Hill Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Swan Hill",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-35.37580109",
        "longitude": "143.5330048",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3166",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "08/26",
                "runway_length": "4905",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2329",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 105709,
            "s_seats": 47941,
            "j_seats": 5880,
            "f_seats": 15521,
            "r_seats": 8298,
            "cargo_demand": 292382
        }
    },
    {
        "iata": "SWJ",
        "icao": "NVSX",
        "airportName": "South West Bay Airport",
        "location_country": "Vanuatu",
        "location_city": "South West Bay",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.4864",
        "longitude": "167.4472",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2674",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 2395,
            "s_seats": 1933,
            "j_seats": 1648,
            "f_seats": 329,
            "r_seats": 150,
            "cargo_demand": 85748
        }
    },
    {
        "iata": "SWO",
        "icao": "KSWO",
        "airportName": "Stillwater Regional Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Stillwater",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.16120148",
        "longitude": "-97.08570099",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5002",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5003",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7401",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53479,
            "s_seats": 39352,
            "j_seats": 50610,
            "f_seats": 6154,
            "r_seats": 9089,
            "cargo_demand": 66927
        }
    },
    {
        "iata": "SWQ",
        "icao": "WADS",
        "airportName": "Sultan Muhammad Kaharuddin III Airport",
        "location_country": "Indonesia",
        "location_city": "Sumbawa Besar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-8.489040375",
        "longitude": "117.4120026",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4754",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2911,
            "s_seats": 1856,
            "j_seats": 1982,
            "f_seats": 50,
            "r_seats": 193,
            "cargo_demand": 94253
        }
    },
    {
        "iata": "SWS",
        "icao": "EGFH",
        "airportName": "Swansea Airport",
        "location_country": "United Kingdom",
        "location_city": "Wales",
        "location_state": "Swansea",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.6053009",
        "longitude": "-4.067830086",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4429",
                "runway_width": "151",
                "runway_material": "CON"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2812",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166022,
            "s_seats": 32162,
            "j_seats": 29111,
            "f_seats": 8931,
            "r_seats": 6859,
            "cargo_demand": 68956
        }
    },
    {
        "iata": "SWU",
        "icao": "RKSW",
        "airportName": "Suwon Air Base",
        "location_country": "South Korea",
        "location_city": "Suwon",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.23939896",
        "longitude": "127.0070038",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15L/33R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7535",
                "runway_width": "143",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108195,
            "s_seats": 25626,
            "j_seats": 46595,
            "f_seats": 3905,
            "r_seats": 8612,
            "cargo_demand": 221147
        }
    },
    {
        "iata": "SWW",
        "icao": "KSWW",
        "airportName": "Avenger Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Sweetwater",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.4673996",
        "longitude": "-100.4670029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5659",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "1675",
                "runway_width": "175",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5840",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3555,
            "s_seats": 1622,
            "j_seats": 1943,
            "f_seats": 482,
            "r_seats": 160,
            "cargo_demand": 34295
        }
    },
    {
        "iata": "SXB",
        "icao": "LFST",
        "airportName": "Strasbourg Airport",
        "location_country": "France",
        "location_city": "Alsace",
        "location_state": "Strasbourg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.53829956",
        "longitude": "7.628230095",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56524,
            "s_seats": 36794,
            "j_seats": 21628,
            "f_seats": 2951,
            "r_seats": 7030,
            "cargo_demand": 432662
        }
    },
    {
        "iata": "SXE",
        "icao": "YWSL",
        "airportName": "West Sale Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Sale",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.090827",
        "longitude": "146.965335",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "1640",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5010",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2293",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 180281,
            "s_seats": 31506,
            "j_seats": 40890,
            "f_seats": 19209,
            "r_seats": 6705,
            "cargo_demand": 223317
        }
    },
    {
        "iata": "SXI",
        "icao": "OIBS",
        "airportName": "Sirri Island Airport",
        "location_country": "Iran",
        "location_city": "Sirri Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.90889931",
        "longitude": "54.53939819",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8345",
                "runway_width": "175",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68623,
            "s_seats": 34153,
            "j_seats": 30068,
            "f_seats": 6616,
            "r_seats": 8787,
            "cargo_demand": 148122
        }
    },
    {
        "iata": "SXK",
        "icao": "WAPI",
        "airportName": "Saumlaki Airport",
        "location_country": "Indonesia",
        "location_city": "Saumlaki",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.988609791",
        "longitude": "131.3059998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2953",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1447,
            "s_seats": 2545,
            "j_seats": 1715,
            "f_seats": 248,
            "r_seats": 198,
            "cargo_demand": 89223
        }
    },
    {
        "iata": "SXL",
        "icao": "EISG",
        "airportName": "Sligo Airport",
        "location_country": "Ireland",
        "location_city": "Sligo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.28020096",
        "longitude": "-8.599209785",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3517",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180994,
            "s_seats": 42921,
            "j_seats": 48936,
            "f_seats": 6816,
            "r_seats": 4627,
            "cargo_demand": 78546
        }
    },
    {
        "iata": "SXM",
        "icao": "TNCM",
        "airportName": "Princess Juliana International Airport",
        "location_country": "Kingdom of the Netherlands",
        "location_city": "Sint Maarten",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "18.04100037",
        "longitude": "-63.10889816",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7708",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "7152",
                "runway_width": "148",
                "runway_material": "ASPH-CONC"
            }
        ],
        "demand": {
            "y_seats": 212691,
            "s_seats": 88630,
            "j_seats": 93155,
            "f_seats": 37058,
            "r_seats": 10000,
            "cargo_demand": 9083873
        }
    },
    {
        "iata": "SXN",
        "icao": "FBSN",
        "airportName": "Sua Pan Airport",
        "location_country": "Botswana",
        "location_city": "Sua Pan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.5534",
        "longitude": "26.115801",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5381",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101391,
            "s_seats": 43267,
            "j_seats": 23275,
            "f_seats": 7596,
            "r_seats": 4654,
            "cargo_demand": 194334
        }
    },
    {
        "iata": "SXO",
        "icao": "SWFX",
        "airportName": "Sio Felix do Araguaia Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Sio Felix do Araguaia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-11.63239956",
        "longitude": "-50.6896019",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4757",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1848,
            "s_seats": 2239,
            "j_seats": 1575,
            "f_seats": 350,
            "r_seats": 174,
            "cargo_demand": 740
        }
    },
    {
        "iata": "SXQ",
        "icao": "PASX",
        "airportName": "Soldotna Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Soldotna",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.474935",
        "longitude": "-151.038471",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5000",
                "runway_width": "132",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198864,
            "s_seats": 38911,
            "j_seats": 47056,
            "f_seats": 19491,
            "r_seats": 9491,
            "cargo_demand": 372767
        }
    },
    {
        "iata": "SXR",
        "icao": "VISR",
        "airportName": "Sheikh ul-Alam International Airport",
        "location_country": "India",
        "location_city": "Jammu and Kashmir",
        "location_state": "Srinagar",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.98709869",
        "longitude": "74.77420044",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "12090",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68932,
            "s_seats": 37018,
            "j_seats": 29867,
            "f_seats": 15705,
            "r_seats": 4030,
            "cargo_demand": 187200
        }
    },
    {
        "iata": "SXV",
        "icao": "VOSM",
        "airportName": "Salem Airport",
        "location_country": "India",
        "location_city": "Tamil Nadu",
        "location_state": "Salem",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.7833004",
        "longitude": "78.06559753",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5925",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144145,
            "s_seats": 29998,
            "j_seats": 46201,
            "f_seats": 6384,
            "r_seats": 4150,
            "cargo_demand": 437217
        }
    },
    {
        "iata": "SXX",
        "icao": "SNFX",
        "airportName": "Sio Felix do Xingu Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Sio Felix do Xingu",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.6413",
        "longitude": "-51.9523",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5249",
                "runway_width": "108",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3665,
            "s_seats": 1542,
            "j_seats": 1936,
            "f_seats": 334,
            "r_seats": 89,
            "cargo_demand": 34429
        }
    },
    {
        "iata": "SXZ",
        "icao": "LTCL",
        "airportName": "Siirt Airport",
        "location_country": "Turkey",
        "location_city": "Siirt",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.97890091",
        "longitude": "41.8404007",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 197297,
            "s_seats": 25632,
            "j_seats": 17894,
            "f_seats": 18773,
            "r_seats": 6130,
            "cargo_demand": 86675
        }
    },
    {
        "iata": "SYA",
        "icao": "PASY",
        "airportName": "Eareckson Air Station",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Shemya",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.71229935",
        "longitude": "174.1139984",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134944,
            "s_seats": 46155,
            "j_seats": 49874,
            "f_seats": 17721,
            "r_seats": 3129,
            "cargo_demand": 394666
        }
    },
    {
        "iata": "SYD",
        "icao": "YSSY",
        "airportName": "Sydney Kingsford Smith Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Sydney",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-33.94609833",
        "longitude": "151.177002",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8300",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16L/34R",
                "runway_length": "7999",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "12999",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166084,
            "s_seats": 87489,
            "j_seats": 80748,
            "f_seats": 34122,
            "r_seats": 10000,
            "cargo_demand": 22507062
        }
    },
    {
        "iata": "SYE",
        "icao": "OYSH",
        "airportName": "Saadah Airport",
        "location_country": "Yemen",
        "location_city": "Sa'dah",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "16.967542",
        "longitude": "43.728103",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "11483",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3386,
            "s_seats": 1593,
            "j_seats": 1772,
            "f_seats": 466,
            "r_seats": 173,
            "cargo_demand": 88923
        }
    },
    {
        "iata": "SYI",
        "icao": "KSYI",
        "airportName": "Shelbyville Municipal Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Shelbyville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.56010056",
        "longitude": "-86.44249725",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5003",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1508,
            "s_seats": 1872,
            "j_seats": 1551,
            "f_seats": 179,
            "r_seats": 76,
            "cargo_demand": 22147
        }
    },
    {
        "iata": "SYJ",
        "icao": "OIKY",
        "airportName": "Sirjan Airport",
        "location_country": "Iran",
        "location_city": "Sirjan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.55089951",
        "longitude": "55.67269897",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "12356",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127677,
            "s_seats": 29226,
            "j_seats": 53483,
            "f_seats": 19127,
            "r_seats": 6332,
            "cargo_demand": 386928
        }
    },
    {
        "iata": "SYK",
        "icao": "BIST",
        "airportName": "Stykkisholmur Airport",
        "location_country": "Iceland",
        "location_city": "Stykkisholmur",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.05809784",
        "longitude": "-22.7942009",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3675",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2117,
            "s_seats": 1771,
            "j_seats": 1620,
            "f_seats": 241,
            "r_seats": 200,
            "cargo_demand": 80446
        }
    },
    {
        "iata": "SYO",
        "icao": "RJSY",
        "airportName": "Shonai Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Sho\u008dnai",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.81219864",
        "longitude": "139.7870026",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6560",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178049,
            "s_seats": 43243,
            "j_seats": 8987,
            "f_seats": 5257,
            "r_seats": 9012,
            "cargo_demand": 261091
        }
    },
    {
        "iata": "SYP",
        "icao": "MPSA",
        "airportName": "Ruben Cantu Airport",
        "location_country": "Panama",
        "location_city": "Santiago de Veraguas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.085599899",
        "longitude": "-80.94529724",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3937",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 164148,
            "s_seats": 28232,
            "j_seats": 25999,
            "f_seats": 1613,
            "r_seats": 8935,
            "cargo_demand": 419828
        }
    },
    {
        "iata": "SYQ",
        "icao": "MRPV",
        "airportName": "Tobias Bolanos International Airport",
        "location_country": "Costa Rica",
        "location_city": "San Jose",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.95705",
        "longitude": "-84.139801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5250",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165010,
            "s_seats": 35429,
            "j_seats": 6719,
            "f_seats": 5708,
            "r_seats": 5200,
            "cargo_demand": 213635
        }
    },
    {
        "iata": "SYR",
        "icao": "KSYR",
        "airportName": "Syracuse Hancock International Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Syracuse",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.11119843",
        "longitude": "-76.10630035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3261",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "10/28",
                "runway_length": "9003",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "7500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 366719,
            "s_seats": 77824,
            "j_seats": 160209,
            "f_seats": 55501,
            "r_seats": 10000,
            "cargo_demand": 20259018
        }
    },
    {
        "iata": "SYT",
        "icao": "LFLN",
        "airportName": "Saint-Yan Airport",
        "location_country": "France",
        "location_city": "Burgundy",
        "location_state": "Saint-Yan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.41249847",
        "longitude": "4.013259888",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15L/33R",
                "runway_length": "6660",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 60381,
            "s_seats": 33887,
            "j_seats": 14712,
            "f_seats": 15385,
            "r_seats": 5203,
            "cargo_demand": 147900
        }
    },
    {
        "iata": "SYU",
        "icao": "YWBS",
        "airportName": "Warraber Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Sue Islet",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.20829964",
        "longitude": "142.8249969",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2362",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1030,
            "s_seats": 1794,
            "j_seats": 1720,
            "f_seats": 62,
            "r_seats": 150,
            "cargo_demand": 1952
        }
    },
    {
        "iata": "SYV",
        "icao": "KSYV",
        "airportName": "Sylvester Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Sylvester",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.55850029",
        "longitude": "-83.89569855",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3293",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3114,
            "s_seats": 2445,
            "j_seats": 1384,
            "f_seats": 457,
            "r_seats": 114,
            "cargo_demand": 1244
        }
    },
    {
        "iata": "SYX",
        "icao": "ZJSY",
        "airportName": "Sanya Phoenix International Airport",
        "location_country": "China",
        "location_city": "Hainan",
        "location_state": "Sanya",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "18.3029",
        "longitude": "109.412003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 433327,
            "s_seats": 81129,
            "j_seats": 41826,
            "f_seats": 28999,
            "r_seats": 10000,
            "cargo_demand": 4880453
        }
    },
    {
        "iata": "SYY",
        "icao": "EGPO",
        "airportName": "Stornoway Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Stornoway",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.21559906",
        "longitude": "-6.331110001",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3281",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7218",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140644,
            "s_seats": 40776,
            "j_seats": 5577,
            "f_seats": 16550,
            "r_seats": 5430,
            "cargo_demand": 255363
        }
    },
    {
        "iata": "SYZ",
        "icao": "OISS",
        "airportName": "Shiraz International Airport",
        "location_country": "Iran",
        "location_city": "Shiraz",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "29.5392",
        "longitude": "52.589802",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11L/29R",
                "runway_length": "14345",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "14051",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 402430,
            "s_seats": 92823,
            "j_seats": 26129,
            "f_seats": 61723,
            "r_seats": 10000,
            "cargo_demand": 18793254
        }
    },
    {
        "iata": "SZA",
        "icao": "FNSO",
        "airportName": "Soyo Airport",
        "location_country": "Angola",
        "location_city": "Soyo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.141089916",
        "longitude": "12.37180042",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6857",
                "runway_width": "174",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111987,
            "s_seats": 31961,
            "j_seats": 33821,
            "f_seats": 4789,
            "r_seats": 7833,
            "cargo_demand": 293262
        }
    },
    {
        "iata": "SZB",
        "icao": "WMSA",
        "airportName": "Sultan Abdul Aziz Shah Airport",
        "location_country": "Malaysia",
        "location_city": "Kuala Lumpur",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.130579948",
        "longitude": "101.5490036",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "12401",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163914,
            "s_seats": 30822,
            "j_seats": 50076,
            "f_seats": 17159,
            "r_seats": 9590,
            "cargo_demand": 478611
        }
    },
    {
        "iata": "SZF",
        "icao": "LTFH",
        "airportName": "Samsun-Carsamba Airport",
        "location_country": "Turkey",
        "location_city": "Samsun",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.254501",
        "longitude": "36.567101",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3485,
            "s_seats": 2225,
            "j_seats": 1673,
            "f_seats": 423,
            "r_seats": 145,
            "cargo_demand": 8930
        }
    },
    {
        "iata": "SZG",
        "icao": "LOWS",
        "airportName": "Salzburg Airport",
        "location_country": "Austria",
        "location_city": "Salzburg",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.79330063",
        "longitude": "13.00430012",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "9022",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 132508,
            "s_seats": 38507,
            "j_seats": 34760,
            "f_seats": 11377,
            "r_seats": 6088,
            "cargo_demand": 49065
        }
    },
    {
        "iata": "SZJ",
        "icao": "MUSN",
        "airportName": "Siguanea Airport",
        "location_country": "Cuba",
        "location_city": "Isla de la Juventud",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.64249992",
        "longitude": "-82.95510101",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83165,
            "s_seats": 28112,
            "j_seats": 38837,
            "f_seats": 16259,
            "r_seats": 8365,
            "cargo_demand": 128274
        }
    },
    {
        "iata": "SZK",
        "icao": "FASZ",
        "airportName": "Skukuza Airport",
        "location_country": "South Africa",
        "location_city": "Skukuza",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-24.96089935",
        "longitude": "31.58869934",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5085",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121838,
            "s_seats": 45396,
            "j_seats": 20679,
            "f_seats": 4798,
            "r_seats": 9247,
            "cargo_demand": 277103
        }
    },
    {
        "iata": "SZL",
        "icao": "KSZL",
        "airportName": "Whiteman Air Force Base",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Knob Noster",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "38.7303009",
        "longitude": "-93.54789734",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "12400",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 414611,
            "s_seats": 85081,
            "j_seats": 80211,
            "f_seats": 62840,
            "r_seats": 10000,
            "cargo_demand": 6448218
        }
    },
    {
        "iata": "SZR",
        "icao": "LBSZ",
        "airportName": "Stara Zagora Airport",
        "location_country": "Bulgaria",
        "location_city": "Stara Zagora",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.3766667",
        "longitude": "25.655",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8200",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3136,
            "s_seats": 2369,
            "j_seats": 1215,
            "f_seats": 284,
            "r_seats": 96,
            "cargo_demand": 10094
        }
    },
    {
        "iata": "SZS",
        "icao": "NZRC",
        "airportName": "Ryan's Creek Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Stewart Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-46.8997",
        "longitude": "168.100998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2625",
                "runway_width": "52",
                "runway_material": "bitumen/gravel"
            }
        ],
        "demand": {
            "y_seats": 3788,
            "s_seats": 1749,
            "j_seats": 1333,
            "f_seats": 12,
            "r_seats": 67,
            "cargo_demand": 76156
        }
    },
    {
        "iata": "SZW",
        "icao": "EDOP",
        "airportName": "Schwerin-Parchim International Airport",
        "location_country": "Germany",
        "location_city": "Mecklenburg-Vorpommern",
        "location_state": "Schwerin",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.426998",
        "longitude": "11.7834",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9843",
                "runway_width": "180",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 190337,
            "s_seats": 28767,
            "j_seats": 59821,
            "f_seats": 10002,
            "r_seats": 8602,
            "cargo_demand": 336974
        }
    },
    {
        "iata": "SZX",
        "icao": "ZGSZ",
        "airportName": "Shenzhen Bao'an International Airport",
        "location_country": "China",
        "location_city": "Guangdong",
        "location_state": "Shenzhen",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "22.639299",
        "longitude": "113.810997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "16/34",
                "runway_length": "12467",
                "runway_width": "197",
                "runway_material": "CONC"
            }
        ],
        "demand": {
            "y_seats": 218268,
            "s_seats": 98797,
            "j_seats": 98599,
            "f_seats": 54327,
            "r_seats": 10000,
            "cargo_demand": 7368890
        }
    },
    {
        "iata": "SZY",
        "icao": "EPSY",
        "airportName": "Olsztyn-Mazury Regional Airport",
        "location_country": "Poland",
        "location_city": "Szczytno / Olsztyn",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.481899",
        "longitude": "20.9377",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8202",
                "runway_width": "147",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 110681,
            "s_seats": 27156,
            "j_seats": 28813,
            "f_seats": 1626,
            "r_seats": 6872,
            "cargo_demand": 273581
        }
    },
    {
        "iata": "SZZ",
        "icao": "EPSC",
        "airportName": "Szczecin-Goleniow Airport",
        "location_country": "Poland",
        "location_city": "Szczecin",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "53.584702",
        "longitude": "14.9022",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8202",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 344634,
            "s_seats": 92706,
            "j_seats": 34072,
            "f_seats": 42202,
            "r_seats": 10000,
            "cargo_demand": 14867483
        }
    },
    {
        "iata": "TAB",
        "icao": "TTCP",
        "airportName": "Arthur Napoleon Raymond Robinson International Airport",
        "location_country": "Trinidad and Tobago",
        "location_city": "Tobago",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.14970016",
        "longitude": "-60.8321991",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9002",
                "runway_width": "159",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81353,
            "s_seats": 31537,
            "j_seats": 55295,
            "f_seats": 3290,
            "r_seats": 5134,
            "cargo_demand": 363527
        }
    },
    {
        "iata": "TAC",
        "icao": "RPVA",
        "airportName": "Daniel Z. Romualdez Airport",
        "location_country": "Philippines",
        "location_city": "Tacloban",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.228035",
        "longitude": "125.027761",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7014",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150263,
            "s_seats": 44840,
            "j_seats": 54096,
            "f_seats": 1533,
            "r_seats": 5611,
            "cargo_demand": 238602
        }
    },
    {
        "iata": "TAD",
        "icao": "KTAD",
        "airportName": "Perry Stokes Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Trinidad",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.25939941",
        "longitude": "-104.3410034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5498",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 3967,
            "s_seats": 1851,
            "j_seats": 1654,
            "f_seats": 488,
            "r_seats": 60,
            "cargo_demand": 88840
        }
    },
    {
        "iata": "TAE",
        "icao": "RKTN",
        "airportName": "Daegu International Airport",
        "location_country": "South Korea",
        "location_city": "Daegu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.896872",
        "longitude": "128.65531",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "9039",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 144090,
            "s_seats": 35128,
            "j_seats": 21344,
            "f_seats": 9497,
            "r_seats": 4488,
            "cargo_demand": 243732
        }
    },
    {
        "iata": "TAF",
        "icao": "DAOL",
        "airportName": "Oran Tafaraoui Airport",
        "location_country": "Algeria",
        "location_city": "Oran",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.5424",
        "longitude": "-0.532278",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9037",
                "runway_width": "140",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104875,
            "s_seats": 29581,
            "j_seats": 18126,
            "f_seats": 2190,
            "r_seats": 5802,
            "cargo_demand": 423396
        }
    },
    {
        "iata": "TAH",
        "icao": "NVVW",
        "airportName": "Whitegrass Airport",
        "location_country": "Vanuatu",
        "location_city": "Tanna Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.45509911",
        "longitude": "169.223999",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4035",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196665,
            "s_seats": 43363,
            "j_seats": 46371,
            "f_seats": 11685,
            "r_seats": 4442,
            "cargo_demand": 292842
        }
    },
    {
        "iata": "TAI",
        "icao": "OYTZ",
        "airportName": "Taiz International Airport",
        "location_country": "Yemen",
        "location_city": "Taiz (Ta'izz)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.68599987",
        "longitude": "44.13909912",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10040",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162295,
            "s_seats": 36959,
            "j_seats": 51054,
            "f_seats": 9415,
            "r_seats": 8841,
            "cargo_demand": 454550
        }
    },
    {
        "iata": "TAK",
        "icao": "RJOT",
        "airportName": "Takamatsu Airport",
        "location_country": "Japan",
        "location_city": "Shikoku",
        "location_state": "Takamatsu",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.21419907",
        "longitude": "134.0160065",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8200",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94014,
            "s_seats": 40497,
            "j_seats": 32109,
            "f_seats": 6770,
            "r_seats": 9871,
            "cargo_demand": 185502
        }
    },
    {
        "iata": "TAL",
        "icao": "PATA",
        "airportName": "Ralph M. Calhoun Memorial Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Tanana",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.17440033",
        "longitude": "-152.1089935",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4400",
                "runway_width": "150",
                "runway_material": "GRVL-P"
            }
        ],
        "demand": {
            "y_seats": 3539,
            "s_seats": 1811,
            "j_seats": 1710,
            "f_seats": 210,
            "r_seats": 139,
            "cargo_demand": 30441
        }
    },
    {
        "iata": "TAM",
        "icao": "MMTM",
        "airportName": "General Francisco Javier Mina International Airport",
        "location_country": "Mexico",
        "location_city": "Tamaulipas",
        "location_state": "Tampico",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.29640007",
        "longitude": "-97.86589813",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "8366",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114828,
            "s_seats": 30342,
            "j_seats": 27855,
            "f_seats": 9700,
            "r_seats": 4378,
            "cargo_demand": 238424
        }
    },
    {
        "iata": "TAN",
        "icao": "YTGA",
        "airportName": "Tangalooma Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Tangalooma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.12999916",
        "longitude": "153.3630066",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "1968",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 1055,
            "s_seats": 2047,
            "j_seats": 1369,
            "f_seats": 344,
            "r_seats": 157,
            "cargo_demand": 6947
        }
    },
    {
        "iata": "TAO",
        "icao": "ZSQD",
        "airportName": "Qingdao Liuting International Airport",
        "location_country": "China",
        "location_city": "Shandong",
        "location_state": "Qingdao",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.26610184",
        "longitude": "120.3740005",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 168879,
            "s_seats": 28335,
            "j_seats": 53366,
            "f_seats": 10480,
            "r_seats": 9984,
            "cargo_demand": 150487
        }
    },
    {
        "iata": "TAP",
        "icao": "MMTP",
        "airportName": "Tapachula International Airport",
        "location_country": "Mexico",
        "location_city": "Chiapas",
        "location_state": "Tapachula",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.79430008",
        "longitude": "-92.37000275",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 133499,
            "s_seats": 37008,
            "j_seats": 21711,
            "f_seats": 11743,
            "r_seats": 3777,
            "cargo_demand": 322777
        }
    },
    {
        "iata": "TAR",
        "icao": "LIBG",
        "airportName": "Taranto-Grottaglie Airport",
        "location_country": "Italy",
        "location_city": "Apulia",
        "location_state": "Taranto",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.517502",
        "longitude": "17.4032",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71005,
            "s_seats": 40951,
            "j_seats": 10339,
            "f_seats": 5463,
            "r_seats": 8316,
            "cargo_demand": 167653
        }
    },
    {
        "iata": "TAS",
        "icao": "UTTT",
        "airportName": "Tashkent International Airport",
        "location_country": "Uzbekistan",
        "location_city": "Tashkent",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "41.25790024",
        "longitude": "69.28119659",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "13123",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "12812",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196369,
            "s_seats": 96881,
            "j_seats": 167346,
            "f_seats": 68492,
            "r_seats": 10000,
            "cargo_demand": 716199
        }
    },
    {
        "iata": "TAT",
        "icao": "LZTT",
        "airportName": "Poprad-Tatry Airport",
        "location_country": "Slovakia",
        "location_city": "Poprad",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.07360077",
        "longitude": "20.24110031",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 158420,
            "s_seats": 40096,
            "j_seats": 59898,
            "f_seats": 1368,
            "r_seats": 9378,
            "cargo_demand": 78349
        }
    },
    {
        "iata": "TAW",
        "icao": "SUTB",
        "airportName": "Tacuarembo Airport",
        "location_country": "Uruguay",
        "location_city": "Tacuarembo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31.749001",
        "longitude": "-55.925801",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3806",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1867,
            "s_seats": 1864,
            "j_seats": 1004,
            "f_seats": 474,
            "r_seats": 169,
            "cargo_demand": 6386
        }
    },
    {
        "iata": "TAY",
        "icao": "EETU",
        "airportName": "Tartu Airport",
        "location_country": "Estonia",
        "location_city": "Tartu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.307499",
        "longitude": "26.690399",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5905",
                "runway_width": "101",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104992,
            "s_seats": 25423,
            "j_seats": 49445,
            "f_seats": 19770,
            "r_seats": 6052,
            "cargo_demand": 112391
        }
    },
    {
        "iata": "TAZ",
        "icao": "UTAT",
        "airportName": "Dasoguz Airport",
        "location_country": "Turkmenistan",
        "location_city": "Dasoguz (Dashoguz)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.759853",
        "longitude": "59.836149",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "8858",
                "runway_width": "138",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50304,
            "s_seats": 41474,
            "j_seats": 36917,
            "f_seats": 9152,
            "r_seats": 3537,
            "cargo_demand": 270206
        }
    },
    {
        "iata": "TBB",
        "icao": "VVTH",
        "airportName": "Dong Tac Airport",
        "location_country": "Vietnam",
        "location_city": "Tuy Hi\u00b2a",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.04959965",
        "longitude": "109.3339996",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2770",
                "runway_width": "90",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03L/21R",
                "runway_length": "9520",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "9300",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 182671,
            "s_seats": 40590,
            "j_seats": 6840,
            "f_seats": 3435,
            "r_seats": 6332,
            "cargo_demand": 294959
        }
    },
    {
        "iata": "TBF",
        "icao": "NGTE",
        "airportName": "Tabiteuea North Airport",
        "location_country": "Kiribati",
        "location_city": "Tabiteuea North",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.224470019",
        "longitude": "174.776001",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3365",
                "runway_width": "103",
                "runway_material": "COR"
            }
        ],
        "demand": {
            "y_seats": 195011,
            "s_seats": 43689,
            "j_seats": 5987,
            "f_seats": 11511,
            "r_seats": 9347,
            "cargo_demand": 426859
        }
    },
    {
        "iata": "TBG",
        "icao": "AYTB",
        "airportName": "Tabubil Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Tabubil",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.27861",
        "longitude": "141.225998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4232",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2130,
            "s_seats": 1811,
            "j_seats": 1692,
            "f_seats": 28,
            "r_seats": 60,
            "cargo_demand": 32280
        }
    },
    {
        "iata": "TBH",
        "icao": "RPVU",
        "airportName": "Tugdan Airport",
        "location_country": "Philippines",
        "location_city": "Tablas Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.31099987",
        "longitude": "122.0849991",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4560",
                "runway_width": "98",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 104961,
            "s_seats": 47473,
            "j_seats": 37290,
            "f_seats": 19161,
            "r_seats": 9123,
            "cargo_demand": 350601
        }
    },
    {
        "iata": "TBI",
        "icao": "MYCB",
        "airportName": "New Bight Airport",
        "location_country": "Bahamas",
        "location_city": "Cat Island",
        "location_state": "New Bight",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.3153",
        "longitude": "-75.452301",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5050",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182360,
            "s_seats": 49386,
            "j_seats": 34940,
            "f_seats": 6692,
            "r_seats": 5960,
            "cargo_demand": 303708
        }
    },
    {
        "iata": "TBJ",
        "icao": "DTKA",
        "airportName": "Tabarka-Ain Draham International Airport",
        "location_country": "Tunisia",
        "location_city": "Tabarka",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.97999954",
        "longitude": "8.876939774",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9416",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83041,
            "s_seats": 43702,
            "j_seats": 5095,
            "f_seats": 14760,
            "r_seats": 3286,
            "cargo_demand": 299912
        }
    },
    {
        "iata": "TBK",
        "icao": "YTBR",
        "airportName": "Timber Creek Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Timber Creek",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.61999989",
        "longitude": "130.4450073",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3408",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3677,
            "s_seats": 1539,
            "j_seats": 1148,
            "f_seats": 215,
            "r_seats": 83,
            "cargo_demand": 1653
        }
    },
    {
        "iata": "TBL",
        "icao": "YTAB",
        "airportName": "Tableland Homestead Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Tableland",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.2833004",
        "longitude": "126.9000015",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3444",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 3073,
            "s_seats": 1848,
            "j_seats": 1949,
            "f_seats": 269,
            "r_seats": 167,
            "cargo_demand": 14978
        }
    },
    {
        "iata": "TBN",
        "icao": "KTBN",
        "airportName": "Waynesville-St. Robert Regional Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Fort Leonard Wood",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.74160004",
        "longitude": "-92.14070129",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6038",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81578,
            "s_seats": 44232,
            "j_seats": 10230,
            "f_seats": 16256,
            "r_seats": 3511,
            "cargo_demand": 350065
        }
    },
    {
        "iata": "TBP",
        "icao": "SPME",
        "airportName": "Cap. FAP Pedro Canga Rodriguez Airport",
        "location_country": "Peru",
        "location_city": "Tumbes",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.55253005",
        "longitude": "-80.38140106",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188835,
            "s_seats": 34111,
            "j_seats": 25886,
            "f_seats": 12652,
            "r_seats": 7914,
            "cargo_demand": 390567
        }
    },
    {
        "iata": "TBR",
        "icao": "KTBR",
        "airportName": "Statesboro-Bulloch County Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Statesboro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.48270035",
        "longitude": "-81.73690033",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4383",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4350",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2901,
            "s_seats": 2333,
            "j_seats": 1212,
            "f_seats": 78,
            "r_seats": 188,
            "cargo_demand": 99114
        }
    },
    {
        "iata": "TBS",
        "icao": "UGTB",
        "airportName": "Tbilisi International Airport",
        "location_country": "Georgia",
        "location_city": "Tbilisi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "41.6692009",
        "longitude": "44.95470047",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13L/31R",
                "runway_length": "8202",
                "runway_width": "196",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "9843",
                "runway_width": "147",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 266041,
            "s_seats": 80759,
            "j_seats": 53897,
            "f_seats": 48965,
            "r_seats": 10000,
            "cargo_demand": 21198834
        }
    },
    {
        "iata": "TBT",
        "icao": "SBTT",
        "airportName": "Tabatinga International Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Tabatinga",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-4.255670071",
        "longitude": "-69.93579865",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7054",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70199,
            "s_seats": 36902,
            "j_seats": 38131,
            "f_seats": 6010,
            "r_seats": 8560,
            "cargo_demand": 195543
        }
    },
    {
        "iata": "TBU",
        "icao": "NFTF",
        "airportName": "Fua'amotu International Airport",
        "location_country": "Tonga",
        "location_city": "Nuku'alofa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.24119949",
        "longitude": "-175.1499939",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8795",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4951",
                "runway_width": "195",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 154495,
            "s_seats": 41229,
            "j_seats": 13657,
            "f_seats": 19803,
            "r_seats": 4380,
            "cargo_demand": 395347
        }
    },
    {
        "iata": "TBZ",
        "icao": "OITT",
        "airportName": "Tabriz International Airport",
        "location_country": "Iran",
        "location_city": "Tabriz",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "38.13389969",
        "longitude": "46.23500061",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12L/30R",
                "runway_length": "11825",
                "runway_width": "148",
                "runway_material": "CONC"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "11537",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 332792,
            "s_seats": 92150,
            "j_seats": 101977,
            "f_seats": 55565,
            "r_seats": 10000,
            "cargo_demand": 7350506
        }
    },
    {
        "iata": "TCA",
        "icao": "YTNK",
        "airportName": "Tennant Creek Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Tennant Creek",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.63439941",
        "longitude": "134.1829987",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6427",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3468",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 105527,
            "s_seats": 48717,
            "j_seats": 10600,
            "f_seats": 3519,
            "r_seats": 8073,
            "cargo_demand": 133738
        }
    },
    {
        "iata": "TCB",
        "icao": "MYAT",
        "airportName": "Treasure Cay Airport",
        "location_country": "Bahamas",
        "location_city": "Abaco Islands",
        "location_state": "Treasure Cay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.74530029",
        "longitude": "-77.39129639",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 133725,
            "s_seats": 26484,
            "j_seats": 19163,
            "f_seats": 12318,
            "r_seats": 5816,
            "cargo_demand": 318748
        }
    },
    {
        "iata": "TCC",
        "icao": "KTCC",
        "airportName": "Tucumcari Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Tucumcari",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.18280029",
        "longitude": "-103.6029968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7102",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "4599",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159938,
            "s_seats": 39062,
            "j_seats": 46109,
            "f_seats": 1388,
            "r_seats": 8991,
            "cargo_demand": 499097
        }
    },
    {
        "iata": "TCE",
        "icao": "LRTC",
        "airportName": "Tulcea Danube Delta Airport",
        "location_country": "Romania",
        "location_city": "Tulcea",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.0625",
        "longitude": "28.7143",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6517",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 71038,
            "s_seats": 48086,
            "j_seats": 9690,
            "f_seats": 7357,
            "r_seats": 6797,
            "cargo_demand": 418307
        }
    },
    {
        "iata": "TCG",
        "icao": "ZWTC",
        "airportName": "Tacheng Airport",
        "location_country": "China",
        "location_city": "Xinjiang",
        "location_state": "Tacheng",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "46.67250061",
        "longitude": "83.34079742",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7808",
                "runway_width": "144",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1600,
            "s_seats": 2195,
            "j_seats": 1451,
            "f_seats": 236,
            "r_seats": 139,
            "cargo_demand": 14630
        }
    },
    {
        "iata": "TCH",
        "icao": "FOOT",
        "airportName": "Tchibanga Airport",
        "location_country": "Gabon",
        "location_city": "Tchibanga",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.849999905",
        "longitude": "11.0170002",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5906",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2195,
            "s_seats": 1660,
            "j_seats": 1497,
            "f_seats": 405,
            "r_seats": 76,
            "cargo_demand": 1206
        }
    },
    {
        "iata": "TCL",
        "icao": "KTCL",
        "airportName": "Tuscaloosa Regional Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Tuscaloosa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.22060013",
        "longitude": "-87.61139679",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6499",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 126974,
            "s_seats": 44211,
            "j_seats": 44719,
            "f_seats": 6708,
            "r_seats": 9651,
            "cargo_demand": 231187
        }
    },
    {
        "iata": "TCM",
        "icao": "KTCM",
        "airportName": "McChord Field",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Tacoma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "47.13769913",
        "longitude": "-122.4759979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "10108",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "160/340",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 204475,
            "s_seats": 95220,
            "j_seats": 64936,
            "f_seats": 28052,
            "r_seats": 10000,
            "cargo_demand": 20702291
        }
    },
    {
        "iata": "TCN",
        "icao": "MMHC",
        "airportName": "Tehuacan Airport",
        "location_country": "Mexico",
        "location_city": "Puebla",
        "location_state": "Tehuacan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.49720001",
        "longitude": "-97.41989899",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6530",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2221,
            "s_seats": 1656,
            "j_seats": 1614,
            "f_seats": 367,
            "r_seats": 47,
            "cargo_demand": 72672
        }
    },
    {
        "iata": "TCO",
        "icao": "SKCO",
        "airportName": "La Florida Airport",
        "location_country": "Colombia",
        "location_city": "Tumaco",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.81442",
        "longitude": "-78.7492",
        "region": "SA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5249",
                "runway_width": "90",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122132,
            "s_seats": 43059,
            "j_seats": 55267,
            "f_seats": 2162,
            "r_seats": 9908,
            "cargo_demand": 350631
        }
    },
    {
        "iata": "TCP",
        "icao": "HETB",
        "airportName": "Taba International Airport",
        "location_country": "Egypt",
        "location_city": "Taba",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.587799",
        "longitude": "34.778099",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "04L/22R",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62900,
            "s_seats": 41487,
            "j_seats": 37280,
            "f_seats": 7782,
            "r_seats": 4145,
            "cargo_demand": 343763
        }
    },
    {
        "iata": "TCQ",
        "icao": "SPTN",
        "airportName": "Coronel FAP Carlos Ciriani Santa Rosa International Airport",
        "location_country": "Peru",
        "location_city": "Tacna",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-18.05330086",
        "longitude": "-70.27580261",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178925,
            "s_seats": 38954,
            "j_seats": 28650,
            "f_seats": 6078,
            "r_seats": 7242,
            "cargo_demand": 106197
        }
    },
    {
        "iata": "TCS",
        "icao": "KTCS",
        "airportName": "Truth or Consequences Municipal Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Truth or Consequences",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.2369",
        "longitude": "-107.272003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3301",
                "runway_width": "130",
                "runway_material": "GVL"
            },
            {
                "runway_name": "07/25",
                "runway_length": "2932",
                "runway_width": "130",
                "runway_material": "GVL"
            },
            {
                "runway_name": "11/29",
                "runway_length": "7108",
                "runway_width": "150",
                "runway_material": "GVL"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7200",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2900",
                "runway_width": "140",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 71717,
            "s_seats": 29183,
            "j_seats": 46991,
            "f_seats": 4775,
            "r_seats": 4946,
            "cargo_demand": 59798
        }
    },
    {
        "iata": "TCW",
        "icao": "YTOC",
        "airportName": "Tocumwal Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Tocumwal",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-35.81169891",
        "longitude": "145.6080017",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4596",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2733,
            "s_seats": 2831,
            "j_seats": 1850,
            "f_seats": 251,
            "r_seats": 57,
            "cargo_demand": 27259
        }
    },
    {
        "iata": "TCX",
        "icao": "OIMT",
        "airportName": "Tabas Airport",
        "location_country": "Iran",
        "location_city": "Tabas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.6678009",
        "longitude": "56.8927002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "9977",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116037,
            "s_seats": 40780,
            "j_seats": 11584,
            "f_seats": 1105,
            "r_seats": 9921,
            "cargo_demand": 334347
        }
    },
    {
        "iata": "TDA",
        "icao": "SKTD",
        "airportName": "Trinidad Airport",
        "location_country": "Colombia",
        "location_city": "Trinidad",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "5.43278",
        "longitude": "-71.6625",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3609",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2546,
            "s_seats": 1976,
            "j_seats": 1796,
            "f_seats": 301,
            "r_seats": 160,
            "cargo_demand": 88927
        }
    },
    {
        "iata": "TDD",
        "icao": "SLTR",
        "airportName": "Teniente Jorge Henrich Arauz Airport",
        "location_country": "Bolivia",
        "location_city": "Trinidad",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.81869984",
        "longitude": "-64.91799927",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7874",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68414,
            "s_seats": 42474,
            "j_seats": 27421,
            "f_seats": 3208,
            "r_seats": 3624,
            "cargo_demand": 309889
        }
    },
    {
        "iata": "TDG",
        "icao": "RPMW",
        "airportName": "Tandag Airport",
        "location_country": "Philippines",
        "location_city": "Tandag",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.072110176",
        "longitude": "126.1709976",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4765",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 182749,
            "s_seats": 43861,
            "j_seats": 20192,
            "f_seats": 14783,
            "r_seats": 6544,
            "cargo_demand": 181367
        }
    },
    {
        "iata": "TDL",
        "icao": "SAZT",
        "airportName": "Tandil Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "Tandil",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-37.23740005",
        "longitude": "-59.22790146",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "8366",
                "runway_width": "157",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 125552,
            "s_seats": 41084,
            "j_seats": 19366,
            "f_seats": 10616,
            "r_seats": 5034,
            "cargo_demand": 495734
        }
    },
    {
        "iata": "TDN",
        "icao": "YTHD",
        "airportName": "Theda Station Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Theda Station",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.78810024",
        "longitude": "126.4960022",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3215",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 2215,
            "s_seats": 2069,
            "j_seats": 1039,
            "f_seats": 220,
            "r_seats": 198,
            "cargo_demand": 99693
        }
    },
    {
        "iata": "TDO",
        "icao": "KTDO",
        "airportName": "South Lewis County Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Toledo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "46.47719955",
        "longitude": "-122.8059998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4480",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1695,
            "s_seats": 2951,
            "j_seats": 1800,
            "f_seats": 197,
            "r_seats": 87,
            "cargo_demand": 47566
        }
    },
    {
        "iata": "TDP",
        "icao": "SPDR",
        "airportName": "Trompeteros Airport",
        "location_country": "Peru",
        "location_city": "Trompeteros",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.806010008",
        "longitude": "-75.03929901",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6075",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3744,
            "s_seats": 2747,
            "j_seats": 1112,
            "f_seats": 67,
            "r_seats": 41,
            "cargo_demand": 76399
        }
    },
    {
        "iata": "TDR",
        "icao": "YTDR",
        "airportName": "Theodore Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Theodore",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.99329948",
        "longitude": "150.0930023",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4406",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1794,
            "s_seats": 1641,
            "j_seats": 1724,
            "f_seats": 141,
            "r_seats": 171,
            "cargo_demand": 49804
        }
    },
    {
        "iata": "TDW",
        "icao": "KTDW",
        "airportName": "Tradewind Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Amarillo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.16989899",
        "longitude": "-101.8259964",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5099",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1279,
            "s_seats": 1828,
            "j_seats": 1745,
            "f_seats": 349,
            "r_seats": 0,
            "cargo_demand": 79946
        }
    },
    {
        "iata": "TDX",
        "icao": "VTBO",
        "airportName": "Trat Airport",
        "location_country": "Thailand",
        "location_city": "Trat",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.2746",
        "longitude": "102.319",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4950",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120803,
            "s_seats": 41461,
            "j_seats": 44245,
            "f_seats": 8099,
            "r_seats": 3630,
            "cargo_demand": 489901
        }
    },
    {
        "iata": "TDZ",
        "icao": "KTDZ",
        "airportName": "Toledo Executive Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Toledo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.56489944",
        "longitude": "-83.4822998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3664",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5830",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3535,
            "s_seats": 2731,
            "j_seats": 1959,
            "f_seats": 444,
            "r_seats": 151,
            "cargo_demand": 76954
        }
    },
    {
        "iata": "TEA",
        "icao": "MHTE",
        "airportName": "Tela Airport",
        "location_country": "Honduras",
        "location_city": "Tela",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.7759",
        "longitude": "-87.4758",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4415",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117732,
            "s_seats": 29980,
            "j_seats": 21550,
            "f_seats": 6509,
            "r_seats": 3008,
            "cargo_demand": 88880
        }
    },
    {
        "iata": "TEB",
        "icao": "KTEB",
        "airportName": "Teterboro Airport",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Teterboro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.85010147",
        "longitude": "-74.06079865",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "6013",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109211,
            "s_seats": 33757,
            "j_seats": 44007,
            "f_seats": 10272,
            "r_seats": 5883,
            "cargo_demand": 290941
        }
    },
    {
        "iata": "TEC",
        "icao": "SBTL",
        "airportName": "Telemaco Borba Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Telemaco Borba",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-24.317801",
        "longitude": "-50.6516",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178512,
            "s_seats": 49767,
            "j_seats": 5065,
            "f_seats": 12324,
            "r_seats": 3186,
            "cargo_demand": 91019
        }
    },
    {
        "iata": "TED",
        "icao": "EKTS",
        "airportName": "Thisted Airport",
        "location_country": "Denmark",
        "location_city": "Thisted",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "57.06880188",
        "longitude": "8.705220222",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5249",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169218,
            "s_seats": 40071,
            "j_seats": 49896,
            "f_seats": 14423,
            "r_seats": 6647,
            "cargo_demand": 195603
        }
    },
    {
        "iata": "TEE",
        "icao": "DABS",
        "airportName": "Cheikh Larbi Tebessi Airport",
        "location_country": "Algeria",
        "location_city": "Tebessa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.43159866",
        "longitude": "8.12071991",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "7874",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120956,
            "s_seats": 29996,
            "j_seats": 23186,
            "f_seats": 13866,
            "r_seats": 7617,
            "cargo_demand": 303311
        }
    },
    {
        "iata": "TEF",
        "icao": "YTEF",
        "airportName": "Telfer Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Telfer",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.71500015",
        "longitude": "122.2289963",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154141,
            "s_seats": 45916,
            "j_seats": 16840,
            "f_seats": 16563,
            "r_seats": 8524,
            "cargo_demand": 447566
        }
    },
    {
        "iata": "TEM",
        "icao": "YTEM",
        "airportName": "Temora Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Temora",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.421398",
        "longitude": "147.511993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6454",
                "runway_width": "98",
                "runway_material": "PEM"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2674",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4816",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179479,
            "s_seats": 26250,
            "j_seats": 35861,
            "f_seats": 15415,
            "r_seats": 4633,
            "cargo_demand": 400152
        }
    },
    {
        "iata": "TEQ",
        "icao": "LTBU",
        "airportName": "Tekirdag Corlu Airport",
        "location_country": "Turkey",
        "location_city": "Tekirdag",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.13819885",
        "longitude": "27.91909981",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9844",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 66368,
            "s_seats": 36203,
            "j_seats": 18277,
            "f_seats": 3762,
            "r_seats": 8656,
            "cargo_demand": 105337
        }
    },
    {
        "iata": "TER",
        "icao": "LPLA",
        "airportName": "Lajes Airport",
        "location_country": "Portugal",
        "location_city": "Azores",
        "location_state": "Terceira Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.761799",
        "longitude": "-27.090799",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "10870",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82709,
            "s_seats": 26768,
            "j_seats": 24213,
            "f_seats": 1989,
            "r_seats": 8296,
            "cargo_demand": 183930
        }
    },
    {
        "iata": "TET",
        "icao": "FQTT",
        "airportName": "Chingozi Airport",
        "location_country": "Mozambique",
        "location_city": "Tete",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.10479927",
        "longitude": "33.64020157",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8225",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192262,
            "s_seats": 43229,
            "j_seats": 10686,
            "f_seats": 15137,
            "r_seats": 7705,
            "cargo_demand": 466357
        }
    },
    {
        "iata": "TEU",
        "icao": "NZMO",
        "airportName": "Te Anau Airport ",
        "location_country": "New Zealand",
        "location_city": "Te Anau",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-45.53310013",
        "longitude": "167.6499939",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3547",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190385,
            "s_seats": 41654,
            "j_seats": 23669,
            "f_seats": 4921,
            "r_seats": 8601,
            "cargo_demand": 147041
        }
    },
    {
        "iata": "TEV",
        "icao": "LETL",
        "airportName": "Teruel Airport",
        "location_country": "Spain",
        "location_city": "Aragon",
        "location_state": "Teruel",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.403",
        "longitude": "-1.2183",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9268",
                "runway_width": "148",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 54007,
            "s_seats": 31262,
            "j_seats": 56758,
            "f_seats": 8964,
            "r_seats": 3190,
            "cargo_demand": 47291
        }
    },
    {
        "iata": "TEX",
        "icao": "KTEX",
        "airportName": "Telluride Regional Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Telluride",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.9538002",
        "longitude": "-107.9079971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6870",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3609,
            "s_seats": 1806,
            "j_seats": 1418,
            "f_seats": 48,
            "r_seats": 197,
            "cargo_demand": 58885
        }
    },
    {
        "iata": "TEY",
        "icao": "BITE",
        "airportName": "Thingeyri Airport",
        "location_country": "Iceland",
        "location_city": "Thingeyri (Iingeyri)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.87030029",
        "longitude": "-23.55999947",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3117",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1485,
            "s_seats": 1664,
            "j_seats": 1016,
            "f_seats": 124,
            "r_seats": 130,
            "cargo_demand": 33905
        }
    },
    {
        "iata": "TEZ",
        "icao": "VETZ",
        "airportName": "Tezpur Airport",
        "location_country": "India",
        "location_city": "Assam",
        "location_state": "Tezpur",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.70910072",
        "longitude": "92.78469849",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9010",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146602,
            "s_seats": 36425,
            "j_seats": 56060,
            "f_seats": 11014,
            "r_seats": 9632,
            "cargo_demand": 366939
        }
    },
    {
        "iata": "TFF",
        "icao": "SBTF",
        "airportName": "Tefe Airport",
        "location_country": "Brazil",
        "location_city": "Amazonas",
        "location_state": "Tefe",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.382940054",
        "longitude": "-64.72409821",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179868,
            "s_seats": 45325,
            "j_seats": 59812,
            "f_seats": 12713,
            "r_seats": 8451,
            "cargo_demand": 411827
        }
    },
    {
        "iata": "TFL",
        "icao": "SNTO",
        "airportName": "Juscelino Kubitscheck Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Teofilo Otoni",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.89229965",
        "longitude": "-41.5135994",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3904",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2340,
            "s_seats": 2762,
            "j_seats": 1116,
            "f_seats": 198,
            "r_seats": 137,
            "cargo_demand": 50356
        }
    },
    {
        "iata": "TFN",
        "icao": "GCXO",
        "airportName": "Los Rodeos Airport",
        "location_country": "Spain",
        "location_city": "Canary Islands",
        "location_state": "Tenerife",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "28.4827",
        "longitude": "-16.341499",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 460525,
            "s_seats": 70484,
            "j_seats": 136949,
            "f_seats": 66643,
            "r_seats": 10000,
            "cargo_demand": 9663356
        }
    },
    {
        "iata": "TFS",
        "icao": "GCTS",
        "airportName": "Tenerife-South Reina Sofia Airport",
        "location_country": "Spain",
        "location_city": "Canary Islands",
        "location_state": "Tenerife",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "28.0445",
        "longitude": "-16.5725",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104215,
            "s_seats": 77748,
            "j_seats": 69383,
            "f_seats": 59668,
            "r_seats": 10000,
            "cargo_demand": 18427658
        }
    },
    {
        "iata": "TGA",
        "icao": "WSAT",
        "airportName": "Tengah Air Base",
        "location_country": "Singapore",
        "location_city": "",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.387561",
        "longitude": "103.708291",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2258",
                "runway_width": "66",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8999",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "1155",
                "runway_width": "236",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 75770,
            "s_seats": 28615,
            "j_seats": 46021,
            "f_seats": 3952,
            "r_seats": 3026,
            "cargo_demand": 391298
        }
    },
    {
        "iata": "TGD",
        "icao": "LYPG",
        "airportName": "Podgorica Airport",
        "location_country": "Montenegro",
        "location_city": "Podgorica",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "42.359402",
        "longitude": "19.2519",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7145",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127643,
            "s_seats": 88179,
            "j_seats": 127710,
            "f_seats": 27567,
            "r_seats": 10000,
            "cargo_demand": 5993287
        }
    },
    {
        "iata": "TGG",
        "icao": "WMKN",
        "airportName": "Sultan Mahmud Airport",
        "location_country": "Malaysia",
        "location_city": "Terengganu",
        "location_state": "Kuala Terengganu",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.382639885",
        "longitude": "103.1029968",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "11417",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163097,
            "s_seats": 39623,
            "j_seats": 46760,
            "f_seats": 10905,
            "r_seats": 7866,
            "cargo_demand": 449109
        }
    },
    {
        "iata": "TGH",
        "icao": "NVST",
        "airportName": "Tongoa Airport",
        "location_country": "Vanuatu",
        "location_city": "Shefa",
        "location_state": "Tongoa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.89109993",
        "longitude": "168.5509949",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2329",
                "runway_width": "75",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3297,
            "s_seats": 2977,
            "j_seats": 1219,
            "f_seats": 151,
            "r_seats": 21,
            "cargo_demand": 69903
        }
    },
    {
        "iata": "TGK",
        "icao": "URRT",
        "airportName": "Taganrog Airport",
        "location_country": "Russia",
        "location_city": "Rostov Oblast",
        "location_state": "Taganrog",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.1983333",
        "longitude": "38.8491667",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9052",
                "runway_width": "131",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 74329,
            "s_seats": 31625,
            "j_seats": 42668,
            "f_seats": 15595,
            "r_seats": 4192,
            "cargo_demand": 243229
        }
    },
    {
        "iata": "TGM",
        "icao": "LRTM",
        "airportName": "T\u00e2rgu Mure\u0219 International Airport",
        "location_country": "Romania",
        "location_city": "T\u00e2rgu Mure\u0219",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.46770096",
        "longitude": "24.41250038",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 72544,
            "s_seats": 38722,
            "j_seats": 22850,
            "f_seats": 3128,
            "r_seats": 7498,
            "cargo_demand": 156358
        }
    },
    {
        "iata": "TGN",
        "icao": "YLTV",
        "airportName": "Latrobe Regional Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Traralgon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.207199",
        "longitude": "146.470001",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4692",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03L/21R",
                "runway_length": "1529",
                "runway_width": "164",
                "runway_material": "Turf/Grass"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3015",
                "runway_width": "60",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 171011,
            "s_seats": 31101,
            "j_seats": 7143,
            "f_seats": 2498,
            "r_seats": 5512,
            "cargo_demand": 409691
        }
    },
    {
        "iata": "TGP",
        "icao": "UNIP",
        "airportName": "Podkamennaya Tunguska Airport",
        "location_country": "Russia",
        "location_city": "Krasnoyarsk Krai",
        "location_state": "Bor",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.589699",
        "longitude": "89.994003",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5597",
                "runway_width": "92",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 59110,
            "s_seats": 49259,
            "j_seats": 19056,
            "f_seats": 15517,
            "r_seats": 3450,
            "cargo_demand": 471689
        }
    },
    {
        "iata": "TGQ",
        "icao": "SWTS",
        "airportName": "Tangara da Serra Airport",
        "location_country": "Brazil",
        "location_city": "Mato Grosso",
        "location_state": "Tangara da Serra",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.662",
        "longitude": "-57.443501",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3031,
            "s_seats": 2462,
            "j_seats": 1450,
            "f_seats": 422,
            "r_seats": 84,
            "cargo_demand": 21088
        }
    },
    {
        "iata": "TGR",
        "icao": "DAUK",
        "airportName": "Sidi Mahdi Airport",
        "location_country": "Algeria",
        "location_city": "Touggourt",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.06779861",
        "longitude": "6.088669777",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157119,
            "s_seats": 37670,
            "j_seats": 32954,
            "f_seats": 14076,
            "r_seats": 9042,
            "cargo_demand": 476385
        }
    },
    {
        "iata": "TGT",
        "icao": "HTTG",
        "airportName": "Tanga Airport",
        "location_country": "Tanzania",
        "location_city": "Tanga",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.09236002",
        "longitude": "39.07120132",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4160",
                "runway_width": "102",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135716,
            "s_seats": 39025,
            "j_seats": 36678,
            "f_seats": 13534,
            "r_seats": 3344,
            "cargo_demand": 492174
        }
    },
    {
        "iata": "TGU",
        "icao": "MHTG",
        "airportName": "Toncontin International Airport",
        "location_country": "Honduras",
        "location_city": "Tegucigalpa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.06089973",
        "longitude": "-87.21720123",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6112",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193034,
            "s_seats": 46673,
            "j_seats": 13367,
            "f_seats": 17937,
            "r_seats": 9585,
            "cargo_demand": 352791
        }
    },
    {
        "iata": "TGV",
        "icao": "LBTG",
        "airportName": "Targovishte Airport",
        "location_country": "Bulgaria",
        "location_city": "Targovishte",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.306599",
        "longitude": "26.700899",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7260",
                "runway_width": "140",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3622,
            "s_seats": 2829,
            "j_seats": 1555,
            "f_seats": 180,
            "r_seats": 48,
            "cargo_demand": 86788
        }
    },
    {
        "iata": "TGZ",
        "icao": "MMTG",
        "airportName": "angel Albino Corzo International Airport",
        "location_country": "Mexico",
        "location_city": "Chiapas",
        "location_state": "Tuxtla Gutierrez",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.56360054",
        "longitude": "-93.02249908",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "14/32",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 74811,
            "s_seats": 48764,
            "j_seats": 18500,
            "f_seats": 19255,
            "r_seats": 7573,
            "cargo_demand": 352691
        }
    },
    {
        "iata": "THA",
        "icao": "KTHA",
        "airportName": "Tullahoma Regional Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Tullahoma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.38010025",
        "longitude": "-86.24639893",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2693",
                "runway_width": "100",
                "runway_material": "GRE"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5002",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1472,
            "s_seats": 2314,
            "j_seats": 1441,
            "f_seats": 498,
            "r_seats": 198,
            "cargo_demand": 3621
        }
    },
    {
        "iata": "THE",
        "icao": "SBTE",
        "airportName": "Teresina-Senador Petronio Portella Airport",
        "location_country": "Brazil",
        "location_city": "Piaui",
        "location_state": "Teresina",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.059939861",
        "longitude": "-42.82350159",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113683,
            "s_seats": 28965,
            "j_seats": 29570,
            "f_seats": 17777,
            "r_seats": 5230,
            "cargo_demand": 374631
        }
    },
    {
        "iata": "THG",
        "icao": "YTNG",
        "airportName": "Thangool Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Thangool",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-24.4939003",
        "longitude": "150.576004",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4993",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2605",
                "runway_width": "98",
                "runway_material": "CLA"
            }
        ],
        "demand": {
            "y_seats": 182175,
            "s_seats": 43837,
            "j_seats": 45065,
            "f_seats": 1835,
            "r_seats": 6097,
            "cargo_demand": 290466
        }
    },
    {
        "iata": "THL",
        "icao": "VYTL",
        "airportName": "Tachilek Airport",
        "location_country": "Myanmar",
        "location_city": "Tachileik (Tachilek)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.48380089",
        "longitude": "99.93540192",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7002",
                "runway_width": "102",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 90320,
            "s_seats": 25640,
            "j_seats": 44122,
            "f_seats": 2546,
            "r_seats": 8730,
            "cargo_demand": 63980
        }
    },
    {
        "iata": "THM",
        "icao": "KTHM",
        "airportName": "Thompson Falls Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "Thompson Falls",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "47.57350159",
        "longitude": "-115.2809982",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4200",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3816,
            "s_seats": 2519,
            "j_seats": 1360,
            "f_seats": 328,
            "r_seats": 160,
            "cargo_demand": 89861
        }
    },
    {
        "iata": "THN",
        "icao": "ESGT",
        "airportName": "TrollhAttan-VAnersborg Airport",
        "location_country": "Sweden",
        "location_city": "TrollhAttan / VAnersborg",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.31809998",
        "longitude": "12.34500027",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5610",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106685,
            "s_seats": 35203,
            "j_seats": 16196,
            "f_seats": 14993,
            "r_seats": 7719,
            "cargo_demand": 410733
        }
    },
    {
        "iata": "THO",
        "icao": "BITN",
        "airportName": "Thorshofn Airport",
        "location_country": "Iceland",
        "location_city": "Thorshofn (Iorshofn)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "66.218498",
        "longitude": "-15.3356",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3934",
                "runway_width": "98",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 2338,
            "s_seats": 2580,
            "j_seats": 1040,
            "f_seats": 288,
            "r_seats": 180,
            "cargo_demand": 545
        }
    },
    {
        "iata": "THR",
        "icao": "OIII",
        "airportName": "Mehrabad International Airport",
        "location_country": "Iran",
        "location_city": "Tehran",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "35.68920135",
        "longitude": "51.31340027",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "1554",
                "runway_width": "130",
                "runway_material": "CON"
            },
            {
                "runway_name": "11L/29R",
                "runway_length": "13098",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "13248",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184803,
            "s_seats": 90632,
            "j_seats": 36442,
            "f_seats": 57242,
            "r_seats": 10000,
            "cargo_demand": 23733866
        }
    },
    {
        "iata": "THS",
        "icao": "VTPO",
        "airportName": "Sukhothai Airport",
        "location_country": "Thailand",
        "location_city": "Sukhothai",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.23800087",
        "longitude": "99.81819916",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193250,
            "s_seats": 31221,
            "j_seats": 19652,
            "f_seats": 17992,
            "r_seats": 4191,
            "cargo_demand": 98431
        }
    },
    {
        "iata": "THU",
        "icao": "BGTL",
        "airportName": "Thule Air Base",
        "location_country": "Greenland",
        "location_city": "Pituffik",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "76.53119659",
        "longitude": "-68.70320129",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9997",
                "runway_width": "140",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "08T/26T",
                "runway_length": "9997",
                "runway_width": "140",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192489,
            "s_seats": 47307,
            "j_seats": 45243,
            "f_seats": 16827,
            "r_seats": 7176,
            "cargo_demand": 173859
        }
    },
    {
        "iata": "THV",
        "icao": "KTHV",
        "airportName": "York Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "York",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "39.917",
        "longitude": "-76.873001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5188",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2633,
            "s_seats": 2141,
            "j_seats": 1237,
            "f_seats": 451,
            "r_seats": 168,
            "cargo_demand": 74207
        }
    },
    {
        "iata": "THZ",
        "icao": "DRRT",
        "airportName": "Tahoua Airport",
        "location_country": "Niger",
        "location_city": "Tahoua",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.8757",
        "longitude": "5.265359879",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7053",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53483,
            "s_seats": 44823,
            "j_seats": 36487,
            "f_seats": 12788,
            "r_seats": 3551,
            "cargo_demand": 490192
        }
    },
    {
        "iata": "TIA",
        "icao": "LATI",
        "airportName": "Tirana International Airport Nene Tereza",
        "location_country": "Albania",
        "location_city": "Tirana",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "41.41469955",
        "longitude": "19.72060013",
        "region": "EU",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "9022",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161108,
            "s_seats": 94333,
            "j_seats": 133888,
            "f_seats": 63549,
            "r_seats": 10000,
            "cargo_demand": 11801823
        }
    },
    {
        "iata": "TIB",
        "icao": "SKTB",
        "airportName": "Tibu Airport",
        "location_country": "Colombia",
        "location_city": "Tibu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "8.63152",
        "longitude": "-72.7304",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4921",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2720,
            "s_seats": 1651,
            "j_seats": 1726,
            "f_seats": 96,
            "r_seats": 134,
            "cargo_demand": 92018
        }
    },
    {
        "iata": "TID",
        "icao": "DAOB",
        "airportName": "Abdelhafid Boussouf Bou Chekif Airport",
        "location_country": "Algeria",
        "location_city": "Tiaret",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.341099",
        "longitude": "1.46315",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128632,
            "s_seats": 25820,
            "j_seats": 16946,
            "f_seats": 4036,
            "r_seats": 5616,
            "cargo_demand": 361272
        }
    },
    {
        "iata": "TIF",
        "icao": "OETF",
        "airportName": "Ta'if Regional Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Ta'if",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.483001",
        "longitude": "40.543442",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "12254",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "10991",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117082,
            "s_seats": 49706,
            "j_seats": 25526,
            "f_seats": 17112,
            "r_seats": 5920,
            "cargo_demand": 437669
        }
    },
    {
        "iata": "TIH",
        "icao": "NTGC",
        "airportName": "Tikehau Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Tikehau",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.1196003",
        "longitude": "-148.2310028",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167580,
            "s_seats": 36276,
            "j_seats": 52515,
            "f_seats": 12226,
            "r_seats": 4737,
            "cargo_demand": 173577
        }
    },
    {
        "iata": "TII",
        "icao": "OATN",
        "airportName": "Tarinkot Airport",
        "location_country": "Afghanistan",
        "location_city": "Tarinkot (Tarin Kowt)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.604198",
        "longitude": "65.865799",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7300",
                "runway_width": "90",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 3841,
            "s_seats": 2182,
            "j_seats": 1277,
            "f_seats": 32,
            "r_seats": 190,
            "cargo_demand": 21909
        }
    },
    {
        "iata": "TIJ",
        "icao": "MMTJ",
        "airportName": "Tijuana International Airport",
        "location_country": "Mexico",
        "location_city": "Baja California",
        "location_state": "Tijuana",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "32.54109955",
        "longitude": "-116.9700012",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9711",
                "runway_width": "144",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 191908,
            "s_seats": 89719,
            "j_seats": 107457,
            "f_seats": 53530,
            "r_seats": 10000,
            "cargo_demand": 26997093
        }
    },
    {
        "iata": "TIK",
        "icao": "KTIK",
        "airportName": "Tinker Air Force Base",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Oklahoma City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "35.41469955",
        "longitude": "-97.38659668",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "10000",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17/35",
                "runway_length": "11100",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 233258,
            "s_seats": 74833,
            "j_seats": 150237,
            "f_seats": 27248,
            "r_seats": 10000,
            "cargo_demand": 19221932
        }
    },
    {
        "iata": "TIM",
        "icao": "WABP",
        "airportName": "Mozes Kilangin Airport",
        "location_country": "Indonesia",
        "location_city": "Tembagapura / Timika",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-4.52828",
        "longitude": "136.886993",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7841",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147387,
            "s_seats": 38197,
            "j_seats": 27570,
            "f_seats": 12594,
            "r_seats": 6990,
            "cargo_demand": 289250
        }
    },
    {
        "iata": "TIN",
        "icao": "DAOF",
        "airportName": "Tindouf Airport",
        "location_country": "Algeria",
        "location_city": "Tindouf",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.7003994",
        "longitude": "-8.167099953",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9840",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "9840",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191133,
            "s_seats": 37215,
            "j_seats": 28789,
            "f_seats": 14698,
            "r_seats": 7910,
            "cargo_demand": 179489
        }
    },
    {
        "iata": "TIP",
        "icao": "HLLT",
        "airportName": "Tripoli International Airport",
        "location_country": "Libya",
        "location_city": "Tripoli",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "32.663502",
        "longitude": "13.159",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "11815",
                "runway_width": "145",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "8650",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 464333,
            "s_seats": 70333,
            "j_seats": 160596,
            "f_seats": 67512,
            "r_seats": 10000,
            "cargo_demand": 29060761
        }
    },
    {
        "iata": "TIQ",
        "icao": "PGWT",
        "airportName": "Tinian International Airport",
        "location_country": "Northern Mariana Islands",
        "location_city": "Tinian",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.9992",
        "longitude": "145.619003",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8600",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 188113,
            "s_seats": 40269,
            "j_seats": 12385,
            "f_seats": 11119,
            "r_seats": 7199,
            "cargo_demand": 148858
        }
    },
    {
        "iata": "TIR",
        "icao": "VOTP",
        "airportName": "Tirupati Airport",
        "location_country": "India",
        "location_city": "Andhra Pradesh",
        "location_state": "Tirupati",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "13.63249969",
        "longitude": "79.54329681",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7500",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3686,
            "s_seats": 1939,
            "j_seats": 1823,
            "f_seats": 191,
            "r_seats": 39,
            "cargo_demand": 23476
        }
    },
    {
        "iata": "TIU",
        "icao": "NZTU",
        "airportName": "Richard Pearse Airport",
        "location_country": "New Zealand",
        "location_city": "Timaru",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-44.30279922",
        "longitude": "171.2250061",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4200",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02L/20R",
                "runway_length": "3898",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3652",
                "runway_width": "295",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 107714,
            "s_seats": 47787,
            "j_seats": 15094,
            "f_seats": 17289,
            "r_seats": 4771,
            "cargo_demand": 457387
        }
    },
    {
        "iata": "TIV",
        "icao": "LYTV",
        "airportName": "Tivat Airport",
        "location_country": "Montenegro",
        "location_city": "Tivat",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.40470123",
        "longitude": "18.72330093",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8208",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101756,
            "s_seats": 38939,
            "j_seats": 28037,
            "f_seats": 6373,
            "r_seats": 9269,
            "cargo_demand": 341800
        }
    },
    {
        "iata": "TIW",
        "icao": "KTIW",
        "airportName": "Tacoma Narrows Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Tacoma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.26789856",
        "longitude": "-122.5780029",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5002",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 75350,
            "s_seats": 31462,
            "j_seats": 45031,
            "f_seats": 11558,
            "r_seats": 5192,
            "cargo_demand": 112750
        }
    },
    {
        "iata": "TIX",
        "icao": "KTIX",
        "airportName": "Space Coast Regional Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Titusville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.51479912",
        "longitude": "-80.79920197",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7319",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191647,
            "s_seats": 34562,
            "j_seats": 19146,
            "f_seats": 17814,
            "r_seats": 4180,
            "cargo_demand": 463837
        }
    },
    {
        "iata": "TIY",
        "icao": "GQND",
        "airportName": "Tidjikja Airport",
        "location_country": "Mauritania",
        "location_city": "Tidjikja",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.570101",
        "longitude": "-11.4235",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5249",
                "runway_width": "96",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3310,
            "s_seats": 2513,
            "j_seats": 1326,
            "f_seats": 70,
            "r_seats": 98,
            "cargo_demand": 43950
        }
    },
    {
        "iata": "TIZ",
        "icao": "AYTA",
        "airportName": "Tari Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Tari",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.84499979",
        "longitude": "142.947998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5197",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3105,
            "s_seats": 1962,
            "j_seats": 1163,
            "f_seats": 284,
            "r_seats": 76,
            "cargo_demand": 75453
        }
    },
    {
        "iata": "TJA",
        "icao": "SLTJ",
        "airportName": "Capitan Oriel Lea Plaza Airport",
        "location_country": "Bolivia",
        "location_city": "Tarija",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.5557003",
        "longitude": "-64.70130157",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174622,
            "s_seats": 27821,
            "j_seats": 18359,
            "f_seats": 2205,
            "r_seats": 6414,
            "cargo_demand": 175009
        }
    },
    {
        "iata": "TJB",
        "icao": "WIBT",
        "airportName": "Sei Bati Airport",
        "location_country": "Indonesia",
        "location_city": "Tanjung Balai",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "1.0527",
        "longitude": "103.3931",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2953",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1099,
            "s_seats": 2037,
            "j_seats": 1504,
            "f_seats": 336,
            "r_seats": 69,
            "cargo_demand": 77611
        }
    },
    {
        "iata": "TJG",
        "icao": "WAON",
        "airportName": "Warukin Airport",
        "location_country": "Indonesia",
        "location_city": "Tanjung",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.216559887",
        "longitude": "115.435997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4601",
                "runway_width": "94",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 157289,
            "s_seats": 32387,
            "j_seats": 17890,
            "f_seats": 8344,
            "r_seats": 4781,
            "cargo_demand": 171038
        }
    },
    {
        "iata": "TJH",
        "icao": "RJBT",
        "airportName": "Tajima Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Toyooka",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.512798",
        "longitude": "134.787003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109908,
            "s_seats": 31030,
            "j_seats": 42300,
            "f_seats": 3683,
            "r_seats": 8572,
            "cargo_demand": 182885
        }
    },
    {
        "iata": "TJI",
        "icao": "MHTJ",
        "airportName": "Trujillo Airport",
        "location_country": "Honduras",
        "location_city": "Trujillo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.9268",
        "longitude": "-85.938202",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3515",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62823,
            "s_seats": 35473,
            "j_seats": 21649,
            "f_seats": 18482,
            "r_seats": 7371,
            "cargo_demand": 152107
        }
    },
    {
        "iata": "TJK",
        "icao": "LTAW",
        "airportName": "Tokat Airport",
        "location_country": "Turkey",
        "location_city": "Tokat",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.30743027",
        "longitude": "36.36740875",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5298",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188306,
            "s_seats": 48969,
            "j_seats": 57842,
            "f_seats": 15168,
            "r_seats": 8897,
            "cargo_demand": 268018
        }
    },
    {
        "iata": "TJM",
        "icao": "USTR",
        "airportName": "Roshchino International Airport",
        "location_country": "Russia",
        "location_city": "Tyumen Oblast",
        "location_state": "Tyumen",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "57.1896019",
        "longitude": "65.32430267",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9852",
                "runway_width": "148",
                "runway_material": "PEM"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8871",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181555,
            "s_seats": 38633,
            "j_seats": 11420,
            "f_seats": 16531,
            "r_seats": 4992,
            "cargo_demand": 273294
        }
    },
    {
        "iata": "TJU",
        "icao": "UTDK",
        "airportName": "Kulob Airport",
        "location_country": "Tajikistan",
        "location_city": "Kulob",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.98809814",
        "longitude": "69.80500031",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "9843",
                "runway_width": "137",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173175,
            "s_seats": 29803,
            "j_seats": 15852,
            "f_seats": 13233,
            "r_seats": 8169,
            "cargo_demand": 319222
        }
    },
    {
        "iata": "TJV",
        "icao": "VOTJ",
        "airportName": "Thanjavur Air Force Station",
        "location_country": "India",
        "location_city": "Tamil Nadu",
        "location_state": "Thanjavur",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "10.7224",
        "longitude": "79.101601",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5680",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4757",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2447,
            "s_seats": 2738,
            "j_seats": 1442,
            "f_seats": 169,
            "r_seats": 177,
            "cargo_demand": 35682
        }
    },
    {
        "iata": "TKA",
        "icao": "PATK",
        "airportName": "Talkeetna Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Talkeetna",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.32049942",
        "longitude": "-150.0939941",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3500",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 186644,
            "s_seats": 46514,
            "j_seats": 29753,
            "f_seats": 16610,
            "r_seats": 7398,
            "cargo_demand": 310382
        }
    },
    {
        "iata": "TKC",
        "icao": "FKKC",
        "airportName": "Tiko Airport",
        "location_country": "Cameroon",
        "location_city": "Tiko",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.089190006",
        "longitude": "9.3605299",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4512",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57102,
            "s_seats": 34412,
            "j_seats": 19262,
            "f_seats": 9489,
            "r_seats": 8953,
            "cargo_demand": 303640
        }
    },
    {
        "iata": "TKD",
        "icao": "DGTK",
        "airportName": "Takoradi Airport",
        "location_country": "Ghana",
        "location_city": "Sekondi-Takoradi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.89605999",
        "longitude": "-1.774760008",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5745",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196053,
            "s_seats": 25160,
            "j_seats": 56405,
            "f_seats": 6805,
            "r_seats": 6227,
            "cargo_demand": 374571
        }
    },
    {
        "iata": "TKF",
        "icao": "KTRK",
        "airportName": "Truckee Tahoe Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Truckee",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.31999969",
        "longitude": "-120.1399994",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4650",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "7000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156249,
            "s_seats": 25100,
            "j_seats": 22420,
            "f_seats": 12979,
            "r_seats": 3613,
            "cargo_demand": 209455
        }
    },
    {
        "iata": "TKG",
        "icao": "WILL",
        "airportName": "Radin Inten II Airport",
        "location_country": "Indonesia",
        "location_city": "Bandar Lampung",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.240556",
        "longitude": "105.175556",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "8202",
                "runway_width": "98",
                "runway_material": "ASPH/CONC"
            }
        ],
        "demand": {
            "y_seats": 72457,
            "s_seats": 44240,
            "j_seats": 38147,
            "f_seats": 1430,
            "r_seats": 3471,
            "cargo_demand": 90539
        }
    },
    {
        "iata": "TKH",
        "icao": "VTPI",
        "airportName": "Takhli Royal Thai Air Force Base",
        "location_country": "Thailand",
        "location_city": "Takhli",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.27729988",
        "longitude": "100.2959976",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 183031,
            "s_seats": 29508,
            "j_seats": 33048,
            "f_seats": 13529,
            "r_seats": 3480,
            "cargo_demand": 413921
        }
    },
    {
        "iata": "TKJ",
        "icao": "PFTO",
        "airportName": "Tok Junction Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Tok",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "63.32949829",
        "longitude": "-142.9539948",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2509",
                "runway_width": "50",
                "runway_material": "ASPH-P"
            }
        ],
        "demand": {
            "y_seats": 2006,
            "s_seats": 1588,
            "j_seats": 1343,
            "f_seats": 472,
            "r_seats": 75,
            "cargo_demand": 58884
        }
    },
    {
        "iata": "TKK",
        "icao": "PTKK",
        "airportName": "Chuuk International Airport",
        "location_country": "Federated States of Micronesia",
        "location_city": "Chuuk",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.461870193",
        "longitude": "151.8430023",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6006",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114418,
            "s_seats": 38004,
            "j_seats": 10985,
            "f_seats": 13348,
            "r_seats": 6016,
            "cargo_demand": 228538
        }
    },
    {
        "iata": "TKN",
        "icao": "RJKN",
        "airportName": "Tokunoshima Airport",
        "location_country": "Japan",
        "location_city": "Amami Islands",
        "location_state": "Tokunoshima",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.836399",
        "longitude": "128.880997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6561",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179381,
            "s_seats": 28083,
            "j_seats": 27543,
            "f_seats": 14700,
            "r_seats": 9528,
            "cargo_demand": 204743
        }
    },
    {
        "iata": "TKP",
        "icao": "NTGT",
        "airportName": "Takapoto Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Takapoto",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.70950031",
        "longitude": "-145.2460022",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3018",
                "runway_width": "65",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183096,
            "s_seats": 38643,
            "j_seats": 21396,
            "f_seats": 10745,
            "r_seats": 3257,
            "cargo_demand": 175421
        }
    },
    {
        "iata": "TKQ",
        "icao": "HTKA",
        "airportName": "Kigoma Airport",
        "location_country": "Tanzania",
        "location_city": "Kigoma",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-4.8862",
        "longitude": "29.6709",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5797",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3298,
            "s_seats": 1848,
            "j_seats": 1818,
            "f_seats": 358,
            "r_seats": 154,
            "cargo_demand": 20689
        }
    },
    {
        "iata": "TKS",
        "icao": "RJOS",
        "airportName": "Tokushima Airport",
        "location_country": "Japan",
        "location_city": "Shikoku",
        "location_state": "Tokushima",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.132801",
        "longitude": "134.606995",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6560",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87120,
            "s_seats": 30594,
            "j_seats": 18502,
            "f_seats": 10763,
            "r_seats": 8131,
            "cargo_demand": 476554
        }
    },
    {
        "iata": "TKT",
        "icao": "VTPT",
        "airportName": "Tak Airport",
        "location_country": "Thailand",
        "location_city": "Tak",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.89599991",
        "longitude": "99.25330353",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110652,
            "s_seats": 44722,
            "j_seats": 20623,
            "f_seats": 7759,
            "r_seats": 7460,
            "cargo_demand": 105103
        }
    },
    {
        "iata": "TKU",
        "icao": "EFTU",
        "airportName": "Turku Airport",
        "location_country": "Finland",
        "location_city": "Turku",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.514099",
        "longitude": "22.2628",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8202",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81034,
            "s_seats": 26093,
            "j_seats": 25169,
            "f_seats": 1642,
            "r_seats": 4931,
            "cargo_demand": 309053
        }
    },
    {
        "iata": "TKX",
        "icao": "NTKR",
        "airportName": "Takaroa Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Takaroa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.45580006",
        "longitude": "-145.0249939",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3452",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151798,
            "s_seats": 43342,
            "j_seats": 19990,
            "f_seats": 17827,
            "r_seats": 6643,
            "cargo_demand": 227399
        }
    },
    {
        "iata": "TKY",
        "icao": "YTKY",
        "airportName": "Turkey Creek Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Warmun (Turkey Creek)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.04080009",
        "longitude": "128.2059937",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4593",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 3636,
            "s_seats": 2390,
            "j_seats": 1958,
            "f_seats": 324,
            "r_seats": 120,
            "cargo_demand": 96244
        }
    },
    {
        "iata": "TKZ",
        "icao": "NZTO",
        "airportName": "Tokoroa Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Tokoroa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-38.23669815",
        "longitude": "175.8919983",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2789",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1184,
            "s_seats": 2816,
            "j_seats": 1167,
            "f_seats": 225,
            "r_seats": 153,
            "cargo_demand": 54038
        }
    },
    {
        "iata": "TLA",
        "icao": "PATE",
        "airportName": "Teller Airport ",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Teller",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.24040222",
        "longitude": "-166.3390045",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 2934,
            "s_seats": 1697,
            "j_seats": 1361,
            "f_seats": 43,
            "r_seats": 139,
            "cargo_demand": 82531
        }
    },
    {
        "iata": "TLC",
        "icao": "MMTO",
        "airportName": "Licenciado Adolfo Lopez Mateos International Airport",
        "location_country": "Mexico",
        "location_city": "State of Mexico",
        "location_state": "Toluca",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.33709908",
        "longitude": "-99.56600189",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "13780",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54495,
            "s_seats": 47020,
            "j_seats": 24027,
            "f_seats": 4636,
            "r_seats": 9470,
            "cargo_demand": 404731
        }
    },
    {
        "iata": "TLE",
        "icao": "FMST",
        "airportName": "Toliara Airport",
        "location_country": "Madagascar",
        "location_city": "Toliara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.3834",
        "longitude": "43.7285",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2625",
                "runway_width": "42",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87230,
            "s_seats": 38913,
            "j_seats": 16186,
            "f_seats": 9492,
            "r_seats": 7705,
            "cargo_demand": 447573
        }
    },
    {
        "iata": "TLH",
        "icao": "KTLH",
        "airportName": "Tallahassee International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Tallahassee",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "30.39649963",
        "longitude": "-84.35030365",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6076",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 486158,
            "s_seats": 78567,
            "j_seats": 121918,
            "f_seats": 23080,
            "r_seats": 10000,
            "cargo_demand": 4100960
        }
    },
    {
        "iata": "TLI",
        "icao": "WAMI",
        "airportName": "Sultan Bantilan Airport",
        "location_country": "Indonesia",
        "location_city": "Tolitoli",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "1.123428",
        "longitude": "120.793658",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2687,
            "s_seats": 1674,
            "j_seats": 1773,
            "f_seats": 177,
            "r_seats": 47,
            "cargo_demand": 27296
        }
    },
    {
        "iata": "TLJ",
        "icao": "PATL",
        "airportName": "Tatalina LRRS Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Tatalina",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.89440155",
        "longitude": "-155.977005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3800",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 74919,
            "s_seats": 37217,
            "j_seats": 46344,
            "f_seats": 14725,
            "r_seats": 4865,
            "cargo_demand": 52151
        }
    },
    {
        "iata": "TLK",
        "icao": "UECT",
        "airportName": "Talakan Airport",
        "location_country": "Russia",
        "location_city": "Yakutia",
        "location_state": "Talakan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "59.876389",
        "longitude": "111.044444",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10170",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2330,
            "s_seats": 2054,
            "j_seats": 1334,
            "f_seats": 128,
            "r_seats": 2,
            "cargo_demand": 26828
        }
    },
    {
        "iata": "TLL",
        "icao": "EETN",
        "airportName": "Tallinn Airport",
        "location_country": "Estonia",
        "location_city": "Tallinn",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "59.41329956",
        "longitude": "24.83279991",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11417",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110577,
            "s_seats": 80286,
            "j_seats": 151132,
            "f_seats": 43249,
            "r_seats": 10000,
            "cargo_demand": 4865167
        }
    },
    {
        "iata": "TLM",
        "icao": "DAON",
        "airportName": "Zenata - Messali El Hadj Airport",
        "location_country": "Algeria",
        "location_city": "Tlemcen",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.01670074",
        "longitude": "-1.450000048",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90207,
            "s_seats": 32984,
            "j_seats": 26806,
            "f_seats": 8882,
            "r_seats": 6973,
            "cargo_demand": 102374
        }
    },
    {
        "iata": "TLN",
        "icao": "LFTH",
        "airportName": "Toulon-Hyeres Airport",
        "location_country": "France",
        "location_city": "Provence-Alpes-Cote d'Azur",
        "location_state": "Toulon / Hyeres",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.09730148",
        "longitude": "6.146029949",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6955",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6240",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69981,
            "s_seats": 34122,
            "j_seats": 59408,
            "f_seats": 6342,
            "r_seats": 7877,
            "cargo_demand": 363481
        }
    },
    {
        "iata": "TLR",
        "icao": "KTLR",
        "airportName": "Mefford Field Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Tulare",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.15629959",
        "longitude": "-119.3259964",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3914",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2878,
            "s_seats": 2301,
            "j_seats": 1044,
            "f_seats": 34,
            "r_seats": 95,
            "cargo_demand": 85281
        }
    },
    {
        "iata": "TLS",
        "icao": "LFBO",
        "airportName": "Toulouse-Blagnac Airport",
        "location_country": "France",
        "location_city": "Midi-Pyrenees",
        "location_state": "Toulouse",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.629101",
        "longitude": "1.36382",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14L/32R",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150286,
            "s_seats": 86180,
            "j_seats": 119851,
            "f_seats": 36644,
            "r_seats": 10000,
            "cargo_demand": 28812796
        }
    },
    {
        "iata": "TLV",
        "icao": "LLBG",
        "airportName": "Ben Gurion Airport",
        "location_country": "Israel",
        "location_city": "Tel Aviv",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "32.01139832",
        "longitude": "34.88669968",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9094",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "13327",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "10209",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 324788,
            "s_seats": 94350,
            "j_seats": 132943,
            "f_seats": 67495,
            "r_seats": 10000,
            "cargo_demand": 26877293
        }
    },
    {
        "iata": "TLW",
        "icao": "AYVL",
        "airportName": "Talasea Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Talasea",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.2726",
        "longitude": "150.0089",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5250",
                "runway_width": "82",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 3153,
            "s_seats": 1569,
            "j_seats": 1609,
            "f_seats": 297,
            "r_seats": 193,
            "cargo_demand": 68020
        }
    },
    {
        "iata": "TLX",
        "icao": "SCTL",
        "airportName": "Panguilemo Airport",
        "location_country": "Chile",
        "location_city": "Talca",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-35.37779999",
        "longitude": "-71.60169983",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3660",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188504,
            "s_seats": 49551,
            "j_seats": 16529,
            "f_seats": 11132,
            "r_seats": 6735,
            "cargo_demand": 97479
        }
    },
    {
        "iata": "TLZ",
        "icao": "SWKT",
        "airportName": "Catalio Airport",
        "location_country": "Brazil",
        "location_city": "Goias",
        "location_state": "Catalio",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.21680069",
        "longitude": "-47.89970016",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1697,
            "s_seats": 2276,
            "j_seats": 1920,
            "f_seats": 361,
            "r_seats": 94,
            "cargo_demand": 12241
        }
    },
    {
        "iata": "TMA",
        "icao": "KTMA",
        "airportName": "Henry Tift Myers Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Tifton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.42900085",
        "longitude": "-83.4885025",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3389",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3805",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5504",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1191,
            "s_seats": 2317,
            "j_seats": 1066,
            "f_seats": 80,
            "r_seats": 110,
            "cargo_demand": 18104
        }
    },
    {
        "iata": "TMB",
        "icao": "KTMB",
        "airportName": "Miami Executive Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Miami",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.6479",
        "longitude": "-80.4328",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "4999",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128378,
            "s_seats": 40148,
            "j_seats": 22132,
            "f_seats": 9707,
            "r_seats": 3563,
            "cargo_demand": 333538
        }
    },
    {
        "iata": "TMC",
        "icao": "WADT",
        "airportName": "Tambolaka Airport",
        "location_country": "Indonesia",
        "location_city": "Tambolaka",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.40972",
        "longitude": "119.244003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1696,
            "s_seats": 1652,
            "j_seats": 1793,
            "f_seats": 377,
            "r_seats": 27,
            "cargo_demand": 98070
        }
    },
    {
        "iata": "TME",
        "icao": "SKTM",
        "airportName": "Gabriel Vargas Santos Airport",
        "location_country": "Colombia",
        "location_city": "Tame",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.45108",
        "longitude": "-71.7603",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6561",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90817,
            "s_seats": 49485,
            "j_seats": 6909,
            "f_seats": 11749,
            "r_seats": 3693,
            "cargo_demand": 231926
        }
    },
    {
        "iata": "TMI",
        "icao": "VNTR",
        "airportName": "Tumlingtar Airport",
        "location_country": "Nepal",
        "location_city": "Tumlingtar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.315001",
        "longitude": "87.193298",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4000",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1123,
            "s_seats": 1809,
            "j_seats": 1197,
            "f_seats": 483,
            "r_seats": 68,
            "cargo_demand": 54202
        }
    },
    {
        "iata": "TMJ",
        "icao": "UTST",
        "airportName": "Termez Airport",
        "location_country": "Uzbekistan",
        "location_city": "Termez",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.287261",
        "longitude": "67.311869",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9843",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 104886,
            "s_seats": 31914,
            "j_seats": 26123,
            "f_seats": 6898,
            "r_seats": 6349,
            "cargo_demand": 167140
        }
    },
    {
        "iata": "TML",
        "icao": "DGLE",
        "airportName": "Tamale Airport",
        "location_country": "Ghana",
        "location_city": "Tamale",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.557189941",
        "longitude": "-0.863214016",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7999",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111044,
            "s_seats": 47549,
            "j_seats": 54431,
            "f_seats": 2449,
            "r_seats": 5611,
            "cargo_demand": 286268
        }
    },
    {
        "iata": "TMM",
        "icao": "FMMT",
        "airportName": "Toamasina Airport",
        "location_country": "Madagascar",
        "location_city": "Toamasina",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-18.109501",
        "longitude": "49.392502",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7218",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70322,
            "s_seats": 32980,
            "j_seats": 26253,
            "f_seats": 10477,
            "r_seats": 8637,
            "cargo_demand": 93696
        }
    },
    {
        "iata": "TMO",
        "icao": "SVTM",
        "airportName": "Tumeremo Airport",
        "location_country": "Venezuela",
        "location_city": "Tumeremo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.24938",
        "longitude": "-61.52893",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9765",
                "runway_width": "125",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101102,
            "s_seats": 28304,
            "j_seats": 53407,
            "f_seats": 10995,
            "r_seats": 5640,
            "cargo_demand": 155707
        }
    },
    {
        "iata": "TMP",
        "icao": "EFTP",
        "airportName": "Tampere-Pirkkala Airport",
        "location_country": "Finland",
        "location_city": "Tampere",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.414101",
        "longitude": "23.604401",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73224,
            "s_seats": 35682,
            "j_seats": 52862,
            "f_seats": 5485,
            "r_seats": 7187,
            "cargo_demand": 192326
        }
    },
    {
        "iata": "TMR",
        "icao": "DAAT",
        "airportName": "Aguenar - Hadj Bey Akhamok Airport",
        "location_country": "Algeria",
        "location_city": "Tamanrasset",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.81150055",
        "longitude": "5.451079845",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114345,
            "s_seats": 46870,
            "j_seats": 28915,
            "f_seats": 17947,
            "r_seats": 5355,
            "cargo_demand": 235473
        }
    },
    {
        "iata": "TMS",
        "icao": "FPST",
        "airportName": "Sio Tome International Airport",
        "location_country": "Sio Tome and Principe",
        "location_city": "Sio Tome",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.37817499",
        "longitude": "6.712150097",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7283",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78869,
            "s_seats": 27768,
            "j_seats": 31866,
            "f_seats": 17849,
            "r_seats": 3843,
            "cargo_demand": 88963
        }
    },
    {
        "iata": "TMT",
        "icao": "SBTB",
        "airportName": "Porto Trombetas Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Porto Trombetas / Oriximina",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.489599943",
        "longitude": "-56.39680099",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "5249",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161386,
            "s_seats": 47815,
            "j_seats": 46458,
            "f_seats": 3997,
            "r_seats": 7263,
            "cargo_demand": 62088
        }
    },
    {
        "iata": "TMU",
        "icao": "MRTR",
        "airportName": "Tambor Airport",
        "location_country": "Costa Rica",
        "location_city": "Tambor",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "9.739307",
        "longitude": "-85.016199",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2723",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2442,
            "s_seats": 2786,
            "j_seats": 1913,
            "f_seats": 15,
            "r_seats": 64,
            "cargo_demand": 44390
        }
    },
    {
        "iata": "TMW",
        "icao": "YSTW",
        "airportName": "Tamworth Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Tamworth",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.08390045",
        "longitude": "150.8470001",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2762",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "3642",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3346",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 134079,
            "s_seats": 27965,
            "j_seats": 35911,
            "f_seats": 7260,
            "r_seats": 6692,
            "cargo_demand": 480770
        }
    },
    {
        "iata": "TMX",
        "icao": "DAUT",
        "airportName": "Timimoun Airport",
        "location_country": "Algeria",
        "location_city": "Timimoun",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.2371006",
        "longitude": "0.276033014",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50305,
            "s_seats": 42056,
            "j_seats": 6449,
            "f_seats": 3394,
            "r_seats": 8667,
            "cargo_demand": 490986
        }
    },
    {
        "iata": "TNA",
        "icao": "ZSJN",
        "airportName": "Jinan Yaoqiang International Airport",
        "location_country": "China",
        "location_city": "Shandong",
        "location_state": "Jinan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.85720062",
        "longitude": "117.2160034",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "11812",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 273083,
            "s_seats": 89136,
            "j_seats": 60948,
            "f_seats": 36584,
            "r_seats": 10000,
            "cargo_demand": 13273772
        }
    },
    {
        "iata": "TNC",
        "icao": "PATC",
        "airportName": "Tin City LRRS Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Tin City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.56310272",
        "longitude": "-167.9219971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4700",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 1902,
            "s_seats": 2837,
            "j_seats": 1345,
            "f_seats": 137,
            "r_seats": 100,
            "cargo_demand": 3459
        }
    },
    {
        "iata": "TND",
        "icao": "MUTD",
        "airportName": "Alberto Delgado Airport",
        "location_country": "Cuba",
        "location_city": "Trinidad",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.78829956",
        "longitude": "-79.99720001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5909",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78579,
            "s_seats": 43052,
            "j_seats": 12597,
            "f_seats": 17094,
            "r_seats": 9088,
            "cargo_demand": 364768
        }
    },
    {
        "iata": "TNE",
        "icao": "RJFG",
        "airportName": "New Tanegashima Airport",
        "location_country": "Japan",
        "location_city": "o\u0152sumi Islands",
        "location_state": "Tanegashima",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.60510063",
        "longitude": "130.9909973",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6544",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117124,
            "s_seats": 28079,
            "j_seats": 37785,
            "f_seats": 13445,
            "r_seats": 3514,
            "cargo_demand": 440998
        }
    },
    {
        "iata": "TNF",
        "icao": "LFPN",
        "airportName": "Toussus-le-Noble Airport",
        "location_country": "France",
        "location_city": "Ile-de-France",
        "location_state": "Toussus-le-Noble",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.75189972",
        "longitude": "2.106189966",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "3445",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 71114,
            "s_seats": 41265,
            "j_seats": 7192,
            "f_seats": 12781,
            "r_seats": 5903,
            "cargo_demand": 102643
        }
    },
    {
        "iata": "TNG",
        "icao": "GMTT",
        "airportName": "Tangier Ibn Battouta Airport",
        "location_country": "Morocco",
        "location_city": "Tangier",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.726898",
        "longitude": "-5.91689",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67408,
            "s_seats": 47296,
            "j_seats": 47840,
            "f_seats": 10962,
            "r_seats": 7775,
            "cargo_demand": 110742
        }
    },
    {
        "iata": "TNI",
        "icao": "VIST",
        "airportName": "Satna Airport",
        "location_country": "India",
        "location_city": "Madhya Pradesh",
        "location_state": "Satna",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "24.56262",
        "longitude": "80.852927",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3476",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2353,
            "s_seats": 2640,
            "j_seats": 1603,
            "f_seats": 366,
            "r_seats": 157,
            "cargo_demand": 73737
        }
    },
    {
        "iata": "TNJ",
        "icao": "WIDN",
        "airportName": "Raja Haji Fisabilillah Airport",
        "location_country": "Indonesia",
        "location_city": "Tanjung Pinang",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.922683001",
        "longitude": "104.5319977",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7380",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3408,
            "s_seats": 2032,
            "j_seats": 1915,
            "f_seats": 116,
            "r_seats": 76,
            "cargo_demand": 64035
        }
    },
    {
        "iata": "TNL",
        "icao": "UKLT",
        "airportName": "Ternopil International Airport",
        "location_country": "Ukraine",
        "location_city": "Ternopil",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "49.5242",
        "longitude": "25.7001",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6465",
                "runway_width": "140",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3814,
            "s_seats": 2398,
            "j_seats": 1386,
            "f_seats": 249,
            "r_seats": 190,
            "cargo_demand": 75102
        }
    },
    {
        "iata": "TNM",
        "icao": "SCRM",
        "airportName": "Teniente R. Marsh Airport",
        "location_country": "Antarctica",
        "location_city": "King George Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-62.191026",
        "longitude": "-58.98669",
        "region": "AN",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4238",
                "runway_width": "125",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 2721,
            "s_seats": 2866,
            "j_seats": 1405,
            "f_seats": 17,
            "r_seats": 114,
            "cargo_demand": 7489
        }
    },
    {
        "iata": "TNN",
        "icao": "RCNN",
        "airportName": "Tainan Airport",
        "location_country": "Taiwan",
        "location_city": "Tainan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.950399",
        "longitude": "120.206001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 77364,
            "s_seats": 43578,
            "j_seats": 57321,
            "f_seats": 4269,
            "r_seats": 6429,
            "cargo_demand": 219880
        }
    },
    {
        "iata": "TNO",
        "icao": "MRTM",
        "airportName": "Tamarindo Airport",
        "location_country": "Costa Rica",
        "location_city": "Tamarindo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "10.3135004",
        "longitude": "-85.81549835",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2625",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2496,
            "s_seats": 2456,
            "j_seats": 1771,
            "f_seats": 261,
            "r_seats": 150,
            "cargo_demand": 35731
        }
    },
    {
        "iata": "TNP",
        "icao": "KTNP",
        "airportName": "Twentynine Palms Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Twentynine Palms",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.133901",
        "longitude": "-115.947347",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5531",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "3800",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2879,
            "s_seats": 1930,
            "j_seats": 1763,
            "f_seats": 130,
            "r_seats": 166,
            "cargo_demand": 64211
        }
    },
    {
        "iata": "TNR",
        "icao": "FMMI",
        "airportName": "Ivato International Airport",
        "location_country": "Madagascar",
        "location_city": "Antananarivo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-18.7969",
        "longitude": "47.478802",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 314737,
            "s_seats": 86483,
            "j_seats": 155480,
            "f_seats": 51648,
            "r_seats": 10000,
            "cargo_demand": 14911002
        }
    },
    {
        "iata": "TNT",
        "icao": "KTNT",
        "airportName": "Dade-Collier Training and Transition Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Miami",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "25.86179924",
        "longitude": "-80.89700317",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10499",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1938,
            "s_seats": 1634,
            "j_seats": 1586,
            "f_seats": 337,
            "r_seats": 115,
            "cargo_demand": 53857
        }
    },
    {
        "iata": "TNU",
        "icao": "KTNU",
        "airportName": "Newton Municipal Airport",
        "location_country": "United States",
        "location_city": "Iowa",
        "location_state": "Newton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.67440033",
        "longitude": "-93.021698",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5599",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1914,
            "s_seats": 2194,
            "j_seats": 1598,
            "f_seats": 339,
            "r_seats": 103,
            "cargo_demand": 50245
        }
    },
    {
        "iata": "TOA",
        "icao": "KTOA",
        "airportName": "Zamperini Field",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Torrance",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.80339813",
        "longitude": "-118.3399963",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11L/29R",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1946,
            "s_seats": 2779,
            "j_seats": 1847,
            "f_seats": 247,
            "r_seats": 62,
            "cargo_demand": 36931
        }
    },
    {
        "iata": "TOB",
        "icao": "HLGN",
        "airportName": "Tobruk Airport",
        "location_country": "Libya",
        "location_city": "Tobruk",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.861",
        "longitude": "23.907",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9895",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "9835",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "9865",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2139,
            "s_seats": 1550,
            "j_seats": 1964,
            "f_seats": 479,
            "r_seats": 97,
            "cargo_demand": 95262
        }
    },
    {
        "iata": "TOC",
        "icao": "KTOC",
        "airportName": "Toccoa Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Toccoa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.59379959",
        "longitude": "-83.29579926",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4003",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2951",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1793,
            "s_seats": 2301,
            "j_seats": 1837,
            "f_seats": 292,
            "r_seats": 133,
            "cargo_demand": 41302
        }
    },
    {
        "iata": "TOD",
        "icao": "WMBT",
        "airportName": "Tioman Airport",
        "location_country": "Malaysia",
        "location_city": "Pahang",
        "location_state": "Tioman Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.81818",
        "longitude": "104.160004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3255",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143541,
            "s_seats": 25944,
            "j_seats": 24437,
            "f_seats": 3388,
            "r_seats": 5230,
            "cargo_demand": 65976
        }
    },
    {
        "iata": "TOE",
        "icao": "DTTZ",
        "airportName": "Tozeur-Nefta International Airport",
        "location_country": "Tunisia",
        "location_city": "Tozeur",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.93970108",
        "longitude": "8.110560417",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10581",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61824,
            "s_seats": 35192,
            "j_seats": 43488,
            "f_seats": 8650,
            "r_seats": 5309,
            "cargo_demand": 141277
        }
    },
    {
        "iata": "TOF",
        "icao": "UNTT",
        "airportName": "Bogashevo Airport",
        "location_country": "Russia",
        "location_city": "Tomsk Oblast",
        "location_state": "Tomsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.38029861",
        "longitude": "85.20829773",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8202",
                "runway_width": "164",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 142396,
            "s_seats": 35027,
            "j_seats": 12825,
            "f_seats": 8276,
            "r_seats": 5664,
            "cargo_demand": 348924
        }
    },
    {
        "iata": "TOG",
        "icao": "PATG",
        "airportName": "Togiak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Togiak",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "59.05279922",
        "longitude": "-160.3970032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4400",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "10/28",
                "runway_length": "1200",
                "runway_width": "49",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 101399,
            "s_seats": 35041,
            "j_seats": 58415,
            "f_seats": 2741,
            "r_seats": 5505,
            "cargo_demand": 342343
        }
    },
    {
        "iata": "TOH",
        "icao": "NVSD",
        "airportName": "Torres Airport",
        "location_country": "Vanuatu",
        "location_city": "Torres Islands",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.32800007",
        "longitude": "166.6380005",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2789",
                "runway_width": "82",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3243,
            "s_seats": 2806,
            "j_seats": 1282,
            "f_seats": 305,
            "r_seats": 18,
            "cargo_demand": 96194
        }
    },
    {
        "iata": "TOI",
        "icao": "KTOI",
        "airportName": "Troy Municipal Airport",
        "location_country": "United States",
        "location_city": "Alabama",
        "location_state": "Troy",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.860399",
        "longitude": "-86.012101",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5009",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5022",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160034,
            "s_seats": 49160,
            "j_seats": 35539,
            "f_seats": 18775,
            "r_seats": 7854,
            "cargo_demand": 294986
        }
    },
    {
        "iata": "TOJ",
        "icao": "LETO",
        "airportName": "Madrid-Torrejon Airport",
        "location_country": "Spain",
        "location_city": "Community of Madrid",
        "location_state": "Madrid",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.487875",
        "longitude": "-3.456808",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "12001",
                "runway_width": "217",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54172,
            "s_seats": 43704,
            "j_seats": 55147,
            "f_seats": 6840,
            "r_seats": 9545,
            "cargo_demand": 161215
        }
    },
    {
        "iata": "TOL",
        "icao": "KTOL",
        "airportName": "Toledo Express Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Toledo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.5868",
        "longitude": "-83.8078",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "10600",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5599",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 164903,
            "s_seats": 26132,
            "j_seats": 11705,
            "f_seats": 7918,
            "r_seats": 4714,
            "cargo_demand": 478632
        }
    },
    {
        "iata": "TOM",
        "icao": "GATB",
        "airportName": "Timbuktu Airport",
        "location_country": "Mali",
        "location_city": "Timbuktu (Tombouctou)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.73049927",
        "longitude": "-3.007580042",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6923",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62028,
            "s_seats": 42019,
            "j_seats": 29866,
            "f_seats": 3423,
            "r_seats": 4673,
            "cargo_demand": 232928
        }
    },
    {
        "iata": "TOO",
        "icao": "MRSV",
        "airportName": "San Vito de Java Airport",
        "location_country": "Costa Rica",
        "location_city": "San Vito",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.826109886",
        "longitude": "-82.95890045",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3215",
                "runway_width": "36",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114202,
            "s_seats": 46586,
            "j_seats": 29509,
            "f_seats": 11485,
            "r_seats": 6634,
            "cargo_demand": 347272
        }
    },
    {
        "iata": "TOP",
        "icao": "KTOP",
        "airportName": "Philip Billard Municipal Airport",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Topeka",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.069899",
        "longitude": "-95.622606",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3002",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5099",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4331",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53425,
            "s_seats": 28559,
            "j_seats": 17762,
            "f_seats": 5767,
            "r_seats": 7146,
            "cargo_demand": 419981
        }
    },
    {
        "iata": "TOQ",
        "icao": "SCBE",
        "airportName": "Barriles Airport",
        "location_country": "Chile",
        "location_city": "Tocopilla",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.14109993",
        "longitude": "-70.06289673",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5380",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77478,
            "s_seats": 28716,
            "j_seats": 10537,
            "f_seats": 10292,
            "r_seats": 3926,
            "cargo_demand": 142248
        }
    },
    {
        "iata": "TOR",
        "icao": "KTOR",
        "airportName": "Torrington Municipal Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Torrington",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.0644989",
        "longitude": "-104.1529999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5701",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3230,
            "s_seats": 2192,
            "j_seats": 1854,
            "f_seats": 3,
            "r_seats": 153,
            "cargo_demand": 7004
        }
    },
    {
        "iata": "TOS",
        "icao": "ENTC",
        "airportName": "Tromso Airport",
        "location_country": "Norway",
        "location_city": "Tromso",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "69.6832962",
        "longitude": "18.91889954",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7848",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 433788,
            "s_seats": 73808,
            "j_seats": 141324,
            "f_seats": 59453,
            "r_seats": 10000,
            "cargo_demand": 29666839
        }
    },
    {
        "iata": "TOU",
        "icao": "NWWU",
        "airportName": "Touho Airport",
        "location_country": "New Caledonia",
        "location_city": "Touho",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.79000092",
        "longitude": "165.2590027",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179832,
            "s_seats": 38504,
            "j_seats": 13144,
            "f_seats": 12654,
            "r_seats": 7977,
            "cargo_demand": 228329
        }
    },
    {
        "iata": "TOW",
        "icao": "SBTD",
        "airportName": "Luiz dal Canalle Filho Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Toledo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-24.6863",
        "longitude": "-53.697498",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "5479",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2823,
            "s_seats": 2467,
            "j_seats": 1912,
            "f_seats": 228,
            "r_seats": 18,
            "cargo_demand": 8278
        }
    },
    {
        "iata": "TOY",
        "icao": "RJNT",
        "airportName": "Toyama Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Toyama",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.64830017",
        "longitude": "137.1880035",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6562",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196819,
            "s_seats": 30262,
            "j_seats": 49786,
            "f_seats": 13725,
            "r_seats": 7820,
            "cargo_demand": 111360
        }
    },
    {
        "iata": "TPA",
        "icao": "KTPA",
        "airportName": "Tampa International Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Tampa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "27.97550011",
        "longitude": "-82.53320313",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6999",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "19L/01R",
                "runway_length": "8300",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "19R/01L",
                "runway_length": "11002",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 439787,
            "s_seats": 85566,
            "j_seats": 119248,
            "f_seats": 66042,
            "r_seats": 10000,
            "cargo_demand": 28705344
        }
    },
    {
        "iata": "TPC",
        "icao": "SETR",
        "airportName": "Tarapoa Airport",
        "location_country": "Ecuador",
        "location_city": "Tarapoa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-0.122956",
        "longitude": "-76.33779907",
        "region": "SA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5151",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171623,
            "s_seats": 48940,
            "j_seats": 26088,
            "f_seats": 14794,
            "r_seats": 3049,
            "cargo_demand": 115578
        }
    },
    {
        "iata": "TPE",
        "icao": "RCTP",
        "airportName": "Taiwan Taoyuan International Airport",
        "location_country": "Taiwan",
        "location_city": "Taoyuan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "25.0777",
        "longitude": "121.233002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "12008",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "10991",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 247014,
            "s_seats": 76020,
            "j_seats": 116449,
            "f_seats": 62154,
            "r_seats": 10000,
            "cargo_demand": 4829135
        }
    },
    {
        "iata": "TPF",
        "icao": "KTPF",
        "airportName": "Peter O. Knight Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Tampa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.91559982",
        "longitude": "-82.44930267",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3405",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2688",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1192,
            "s_seats": 1683,
            "j_seats": 1975,
            "f_seats": 122,
            "r_seats": 99,
            "cargo_demand": 82880
        }
    },
    {
        "iata": "TPH",
        "icao": "KTPH",
        "airportName": "Tonopah Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Tonopah",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.06019974",
        "longitude": "-117.086998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5464",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "7057",
                "runway_width": "80",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62798,
            "s_seats": 41110,
            "j_seats": 40224,
            "f_seats": 19613,
            "r_seats": 8135,
            "cargo_demand": 86318
        }
    },
    {
        "iata": "TPJ",
        "icao": "VNTJ",
        "airportName": "Taplejung Airport",
        "location_country": "Nepal",
        "location_city": "Taplejung",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.3509",
        "longitude": "87.69525",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2313",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 141059,
            "s_seats": 44156,
            "j_seats": 47688,
            "f_seats": 7055,
            "r_seats": 6578,
            "cargo_demand": 278582
        }
    },
    {
        "iata": "TPK",
        "icao": "WITA",
        "airportName": "Teuku Cut Ali Airport",
        "location_country": "Indonesia",
        "location_city": "Tapaktuan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "3.16904",
        "longitude": "97.288299",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4170",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1867,
            "s_seats": 1851,
            "j_seats": 1115,
            "f_seats": 318,
            "r_seats": 177,
            "cargo_demand": 22422
        }
    },
    {
        "iata": "TPL",
        "icao": "KTPL",
        "airportName": "Draughon-Miller Central Texas Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Temple",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.15250015",
        "longitude": "-97.40779877",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4740",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6301",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115121,
            "s_seats": 28347,
            "j_seats": 14138,
            "f_seats": 8839,
            "r_seats": 8657,
            "cargo_demand": 484928
        }
    },
    {
        "iata": "TPP",
        "icao": "SPST",
        "airportName": "Cad. FAP Guillermo del Castillo Paredes Airport",
        "location_country": "Peru",
        "location_city": "Tarapoto",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-6.508739948",
        "longitude": "-76.37319946",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199802,
            "s_seats": 29065,
            "j_seats": 47941,
            "f_seats": 18798,
            "r_seats": 5052,
            "cargo_demand": 255598
        }
    },
    {
        "iata": "TPQ",
        "icao": "MMEP",
        "airportName": "Amado Nervo International Airport",
        "location_country": "Mexico",
        "location_city": "Nayarit",
        "location_state": "Tepic",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.4195",
        "longitude": "-104.843002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163482,
            "s_seats": 37747,
            "j_seats": 23765,
            "f_seats": 19165,
            "r_seats": 5141,
            "cargo_demand": 64179
        }
    },
    {
        "iata": "TPS",
        "icao": "LICT",
        "airportName": "Vincenzo Florio Airport Trapani-Birgi",
        "location_country": "Italy",
        "location_city": "Sicily",
        "location_state": "Trapani",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.9114",
        "longitude": "12.488",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8852",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135473,
            "s_seats": 30359,
            "j_seats": 35981,
            "f_seats": 12020,
            "r_seats": 6046,
            "cargo_demand": 285862
        }
    },
    {
        "iata": "TQD",
        "icao": "ORAT",
        "airportName": "Al-Taqaddum Air Base",
        "location_country": "Iraq",
        "location_city": "Fallujah",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.33810043",
        "longitude": "43.5970993",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12L/30R",
                "runway_length": "12087",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "13186",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 190859,
            "s_seats": 37180,
            "j_seats": 51830,
            "f_seats": 19212,
            "r_seats": 7630,
            "cargo_demand": 293086
        }
    },
    {
        "iata": "TQP",
        "icao": "YTEE",
        "airportName": "Trepell Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Trepell",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-21.83499908",
        "longitude": "140.8880005",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5902",
                "runway_width": "98",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 1227,
            "s_seats": 2916,
            "j_seats": 1067,
            "f_seats": 187,
            "r_seats": 70,
            "cargo_demand": 19570
        }
    },
    {
        "iata": "TQS",
        "icao": "SKTQ",
        "airportName": "Captain Ernesto Esguerra Cubides Air Base",
        "location_country": "Colombia",
        "location_city": "Tres Esquinas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.7459",
        "longitude": "-75.234",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6220",
                "runway_width": "79",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 162563,
            "s_seats": 49342,
            "j_seats": 22569,
            "f_seats": 16512,
            "r_seats": 5611,
            "cargo_demand": 291288
        }
    },
    {
        "iata": "TRA",
        "icao": "RORT",
        "airportName": "Tarama Airport",
        "location_country": "Japan",
        "location_city": "Miyako Islands",
        "location_state": "Tarama",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.65390015",
        "longitude": "124.6750031",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4921",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64540,
            "s_seats": 27089,
            "j_seats": 26317,
            "f_seats": 11321,
            "r_seats": 5981,
            "cargo_demand": 275040
        }
    },
    {
        "iata": "TRC",
        "icao": "MMTC",
        "airportName": "Francisco Sarabia International Airport",
        "location_country": "Mexico",
        "location_city": "Coahuila",
        "location_state": "Torreon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.56830025",
        "longitude": "-103.4110031",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4813",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "9039",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115113,
            "s_seats": 46198,
            "j_seats": 18450,
            "f_seats": 8166,
            "r_seats": 5147,
            "cargo_demand": 318713
        }
    },
    {
        "iata": "TRD",
        "icao": "ENVA",
        "airportName": "Trondheim Airport",
        "location_country": "Norway",
        "location_city": "Trondheim",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "63.4578018",
        "longitude": "10.9239998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9052",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "1771",
                "runway_width": "118",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "1117",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100345,
            "s_seats": 93445,
            "j_seats": 146507,
            "f_seats": 44728,
            "r_seats": 10000,
            "cargo_demand": 26618944
        }
    },
    {
        "iata": "TRE",
        "icao": "EGPU",
        "airportName": "Tiree Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Tiree",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.49919891",
        "longitude": "-6.869170189",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4600",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2690",
                "runway_width": "62",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2598",
                "runway_width": "59",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 188355,
            "s_seats": 35160,
            "j_seats": 10794,
            "f_seats": 3663,
            "r_seats": 6723,
            "cargo_demand": 469726
        }
    },
    {
        "iata": "TRF",
        "icao": "ENTO",
        "airportName": "Sandefjord Airport",
        "location_country": "Norway",
        "location_city": "Sandefjord / Oslo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "\" Torp\"\"\"",
        "longitude": "59.18669891",
        "region": "286",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9675",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57786,
            "s_seats": 46396,
            "j_seats": 37293,
            "f_seats": 5369,
            "r_seats": 8365,
            "cargo_demand": 72727
        }
    },
    {
        "iata": "TRG",
        "icao": "NZTG",
        "airportName": "Tauranga Airport",
        "location_country": "New Zealand",
        "location_city": "Tauranga",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-37.6719017",
        "longitude": "176.1959991",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2100",
                "runway_width": "197",
                "runway_material": "GRE"
            },
            {
                "runway_name": "07/25",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "2543",
                "runway_width": "197",
                "runway_material": "GRE"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "5988",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2297",
                "runway_width": "148",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 150710,
            "s_seats": 27124,
            "j_seats": 49565,
            "f_seats": 18489,
            "r_seats": 4992,
            "cargo_demand": 246563
        }
    },
    {
        "iata": "TRI",
        "icao": "KTRI",
        "airportName": "Tri-Cities Regional Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Bristol / Johnson City / Kingsport",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.475201",
        "longitude": "-82.407401",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8030",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "4442",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 417745,
            "s_seats": 92286,
            "j_seats": 91189,
            "f_seats": 35240,
            "r_seats": 10000,
            "cargo_demand": 6399118
        }
    },
    {
        "iata": "TRJ",
        "icao": "AYTT",
        "airportName": "Tarakbits Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Tarakbits",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-5.614",
        "longitude": "141.0421",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "1909",
                "runway_width": "98",
                "runway_material": "Red clay"
            }
        ],
        "demand": {
            "y_seats": 3598,
            "s_seats": 1630,
            "j_seats": 1109,
            "f_seats": 443,
            "r_seats": 99,
            "cargo_demand": 83200
        }
    },
    {
        "iata": "TRK",
        "icao": "WALR",
        "airportName": "Juwata International Airport",
        "location_country": "Indonesia",
        "location_city": "Tarakan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.326667",
        "longitude": "117.569444",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7382",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50291,
            "s_seats": 43242,
            "j_seats": 37750,
            "f_seats": 9259,
            "r_seats": 7913,
            "cargo_demand": 353199
        }
    },
    {
        "iata": "TRL",
        "icao": "KTRL",
        "airportName": "Terrell Municipal Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Terrell",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.70920181",
        "longitude": "-96.26740265",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3014",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5006",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1320,
            "s_seats": 1591,
            "j_seats": 1255,
            "f_seats": 30,
            "r_seats": 173,
            "cargo_demand": 50970
        }
    },
    {
        "iata": "TRM",
        "icao": "KTRM",
        "airportName": "Jacqueline Cochran Regional Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Thermal",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.62670135",
        "longitude": "-116.1600037",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4995",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "8500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110022,
            "s_seats": 44000,
            "j_seats": 23995,
            "f_seats": 13802,
            "r_seats": 3147,
            "cargo_demand": 279559
        }
    },
    {
        "iata": "TRN",
        "icao": "LIMF",
        "airportName": "Turin Airport",
        "location_country": "Italy",
        "location_city": "Piedmont",
        "location_state": "Turin",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "45.200802",
        "longitude": "7.64963",
        "region": "EU",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "10827",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128715,
            "s_seats": 72265,
            "j_seats": 66038,
            "f_seats": 63708,
            "r_seats": 10000,
            "cargo_demand": 27368576
        }
    },
    {
        "iata": "TRO",
        "icao": "YTRE",
        "airportName": "Taree Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Taree",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.8885994",
        "longitude": "152.5140076",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4934",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "1850",
                "runway_width": "60",
                "runway_material": "CLA"
            }
        ],
        "demand": {
            "y_seats": 166388,
            "s_seats": 37935,
            "j_seats": 44083,
            "f_seats": 2140,
            "r_seats": 9660,
            "cargo_demand": 337704
        }
    },
    {
        "iata": "TRQ",
        "icao": "SBTK",
        "airportName": "Jose Galera dos Santos Airport",
        "location_country": "Brazil",
        "location_city": "Acre",
        "location_state": "Tarauaca",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.155534",
        "longitude": "-70.782985",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3707",
                "runway_width": "46",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88389,
            "s_seats": 49514,
            "j_seats": 38890,
            "f_seats": 1383,
            "r_seats": 6183,
            "cargo_demand": 329910
        }
    },
    {
        "iata": "TRR",
        "icao": "VCCT",
        "airportName": "China Bay Airport",
        "location_country": "Sri Lanka",
        "location_city": "Trincomalee",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.538510323",
        "longitude": "81.18190002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7850",
                "runway_width": "85",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79237,
            "s_seats": 49792,
            "j_seats": 23241,
            "f_seats": 19941,
            "r_seats": 6537,
            "cargo_demand": 134103
        }
    },
    {
        "iata": "TRS",
        "icao": "LIPQ",
        "airportName": "Trieste - Friuli Venezia Giulia Airport",
        "location_country": "Italy",
        "location_city": "Friuli-Venezia Giulia",
        "location_state": "Trieste",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.827499",
        "longitude": "13.4722",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175029,
            "s_seats": 25912,
            "j_seats": 48448,
            "f_seats": 14977,
            "r_seats": 3820,
            "cargo_demand": 466134
        }
    },
    {
        "iata": "TRU",
        "icao": "SPRU",
        "airportName": "FAP Captain Carlos Martinez de Pinillos International Airport",
        "location_country": "Peru",
        "location_city": "Trujillo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-8.081410408",
        "longitude": "-79.1088028",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9920",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146544,
            "s_seats": 36362,
            "j_seats": 37824,
            "f_seats": 5512,
            "r_seats": 9762,
            "cargo_demand": 389565
        }
    },
    {
        "iata": "TRV",
        "icao": "VOTV",
        "airportName": "Trivandrum International Airport",
        "location_country": "India",
        "location_city": "Kerala",
        "location_state": "Thiruvananthapuram",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "8.48211956",
        "longitude": "76.92009735",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "11148",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 308454,
            "s_seats": 70903,
            "j_seats": 37635,
            "f_seats": 20311,
            "r_seats": 10000,
            "cargo_demand": 13557684
        }
    },
    {
        "iata": "TRW",
        "icao": "NGTA",
        "airportName": "Bonriki International Airport",
        "location_country": "Kiribati",
        "location_city": "Tarawa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.38164",
        "longitude": "173.147003",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6598",
                "runway_width": "135",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 57165,
            "s_seats": 36194,
            "j_seats": 37456,
            "f_seats": 2843,
            "r_seats": 4439,
            "cargo_demand": 60242
        }
    },
    {
        "iata": "TRX",
        "icao": "KTRX",
        "airportName": "Trenton Municipal Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Trenton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.08349991",
        "longitude": "-93.59059906",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4310",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3997,
            "s_seats": 1665,
            "j_seats": 1282,
            "f_seats": 184,
            "r_seats": 175,
            "cargo_demand": 25504
        }
    },
    {
        "iata": "TRZ",
        "icao": "VOTR",
        "airportName": "Tiruchirappalli International Airport",
        "location_country": "India",
        "location_city": "Tamil Nadu",
        "location_state": "Tiruchirappalli (Tiruchchirappalli)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.7654",
        "longitude": "78.709702",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6115",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4776",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58047,
            "s_seats": 46195,
            "j_seats": 51207,
            "f_seats": 8613,
            "r_seats": 3175,
            "cargo_demand": 110822
        }
    },
    {
        "iata": "TSA",
        "icao": "RCSS",
        "airportName": "Taipei Songshan Airport",
        "location_country": "Taiwan",
        "location_city": "Taipei",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.06940079",
        "longitude": "121.552002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8547",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110291,
            "s_seats": 44706,
            "j_seats": 46526,
            "f_seats": 11043,
            "r_seats": 4804,
            "cargo_demand": 379233
        }
    },
    {
        "iata": "TSB",
        "icao": "FYTM",
        "airportName": "Tsumeb Airport",
        "location_country": "Namibia",
        "location_city": "Tsumeb",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.26189995",
        "longitude": "17.73250008",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4823",
                "runway_width": "56",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175438,
            "s_seats": 33339,
            "j_seats": 11716,
            "f_seats": 6670,
            "r_seats": 9398,
            "cargo_demand": 427975
        }
    },
    {
        "iata": "TSE",
        "icao": "UACC",
        "airportName": "Astana International Airport",
        "location_country": "Kazakhstan",
        "location_city": "Astana",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "51.022202",
        "longitude": "71.466904",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "11484",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124087,
            "s_seats": 81313,
            "j_seats": 102074,
            "f_seats": 27150,
            "r_seats": 10000,
            "cargo_demand": 8621673
        }
    },
    {
        "iata": "TSF",
        "icao": "LIPH",
        "airportName": "Treviso-Sant'Angelo Airport",
        "location_country": "Italy",
        "location_city": "Veneto",
        "location_state": "Treviso / Venice",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.648399",
        "longitude": "12.1944",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7941",
                "runway_width": "144",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151084,
            "s_seats": 27640,
            "j_seats": 50049,
            "f_seats": 3393,
            "r_seats": 9308,
            "cargo_demand": 254918
        }
    },
    {
        "iata": "TSJ",
        "icao": "RJDT",
        "airportName": "Tsushima Airport",
        "location_country": "Japan",
        "location_city": "Tsushima Island",
        "location_state": "Tsushima",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.28490067",
        "longitude": "129.3309937",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6234",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 60991,
            "s_seats": 37264,
            "j_seats": 26890,
            "f_seats": 6460,
            "r_seats": 9845,
            "cargo_demand": 477056
        }
    },
    {
        "iata": "TSL",
        "icao": "MMTN",
        "airportName": "Tamuin National Airport",
        "location_country": "Mexico",
        "location_city": "San Luis Potosi",
        "location_state": "Tamuin",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "22.0383",
        "longitude": "-98.806502",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4735",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3700,
            "s_seats": 2582,
            "j_seats": 1880,
            "f_seats": 57,
            "r_seats": 16,
            "cargo_demand": 62260
        }
    },
    {
        "iata": "TSM",
        "icao": "KSKX",
        "airportName": "Taos Regional Airport",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "Taos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.45819855",
        "longitude": "-105.6719971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5798",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2901,
            "s_seats": 2662,
            "j_seats": 1897,
            "f_seats": 341,
            "r_seats": 184,
            "cargo_demand": 88258
        }
    },
    {
        "iata": "TSN",
        "icao": "ZBTJ",
        "airportName": "Tianjin Binhai International Airport",
        "location_country": "China",
        "location_city": "Tianjin",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "39.12440109",
        "longitude": "117.3460007",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "11811",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 461476,
            "s_seats": 71162,
            "j_seats": 89019,
            "f_seats": 33588,
            "r_seats": 10000,
            "cargo_demand": 28788768
        }
    },
    {
        "iata": "TSP",
        "icao": "KTSP",
        "airportName": "Tehachapi Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Tehachapi",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.13499832",
        "longitude": "-118.439003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4031",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2886,
            "s_seats": 2963,
            "j_seats": 1901,
            "f_seats": 86,
            "r_seats": 125,
            "cargo_demand": 54317
        }
    },
    {
        "iata": "TSQ",
        "icao": "SBTR",
        "airportName": "Torres Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Torres",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-29.4149",
        "longitude": "-49.810001",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4944",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2991,
            "s_seats": 1623,
            "j_seats": 1324,
            "f_seats": 414,
            "r_seats": 128,
            "cargo_demand": 83815
        }
    },
    {
        "iata": "TSR",
        "icao": "LRTR",
        "airportName": "Timi\u0219oara Traian Vuia International Airport",
        "location_country": "Romania",
        "location_city": "Timi\u0219oara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.80989838",
        "longitude": "21.33790016",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 194876,
            "s_seats": 37491,
            "j_seats": 49093,
            "f_seats": 7161,
            "r_seats": 7956,
            "cargo_demand": 235010
        }
    },
    {
        "iata": "TST",
        "icao": "VTST",
        "airportName": "Trang Airport",
        "location_country": "Thailand",
        "location_city": "Trang",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.50874",
        "longitude": "99.6166",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55437,
            "s_seats": 48091,
            "j_seats": 14363,
            "f_seats": 2889,
            "r_seats": 6420,
            "cargo_demand": 267215
        }
    },
    {
        "iata": "TSV",
        "icao": "YBTL",
        "airportName": "Townsville Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Townsville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.252904",
        "longitude": "146.766512",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7999",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72605,
            "s_seats": 41635,
            "j_seats": 40734,
            "f_seats": 18685,
            "r_seats": 8972,
            "cargo_demand": 439146
        }
    },
    {
        "iata": "TSY",
        "icao": "WICM",
        "airportName": "Tasikmalaya Airport",
        "location_country": "Indonesia",
        "location_city": "West Java",
        "location_state": "Tasikmalaya",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.346600056",
        "longitude": "108.2460022",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3590",
                "runway_width": "64",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3969,
            "s_seats": 2336,
            "j_seats": 1648,
            "f_seats": 389,
            "r_seats": 81,
            "cargo_demand": 54687
        }
    },
    {
        "iata": "TTA",
        "icao": "GMAT",
        "airportName": "Tan Tan Airport",
        "location_country": "Morocco",
        "location_city": "Tan-Tan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.44820023",
        "longitude": "-11.16129971",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127607,
            "s_seats": 44477,
            "j_seats": 23174,
            "f_seats": 11089,
            "r_seats": 4399,
            "cargo_demand": 352921
        }
    },
    {
        "iata": "TTB",
        "icao": "LIET",
        "airportName": "Tortol\u00ec Airport",
        "location_country": "Italy",
        "location_city": "Sardinia",
        "location_state": "Tortol\u00ec",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.9188",
        "longitude": "9.68298",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3898",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 129111,
            "s_seats": 39679,
            "j_seats": 44985,
            "f_seats": 16885,
            "r_seats": 8430,
            "cargo_demand": 297472
        }
    },
    {
        "iata": "TTC",
        "icao": "SCTT",
        "airportName": "Las Breas Airport",
        "location_country": "Chile",
        "location_city": "Taltal",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.56430054",
        "longitude": "-70.37590027",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3920",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81159,
            "s_seats": 27383,
            "j_seats": 14357,
            "f_seats": 2887,
            "r_seats": 8741,
            "cargo_demand": 253264
        }
    },
    {
        "iata": "TTD",
        "icao": "KTTD",
        "airportName": "Portland-Troutdale Airport",
        "location_country": "United States",
        "location_city": "Oregon",
        "location_state": "Portland",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.54940033",
        "longitude": "-122.401001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5399",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193994,
            "s_seats": 39919,
            "j_seats": 43709,
            "f_seats": 15812,
            "r_seats": 8227,
            "cargo_demand": 148149
        }
    },
    {
        "iata": "TTG",
        "icao": "SAST",
        "airportName": "\"Tartagal \"\"General Enrique Mosconi\"\" Airport\"",
        "location_country": "Argentina",
        "location_city": "Salta",
        "location_state": "Tartagal",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.6196003",
        "longitude": "-63.79370117",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127736,
            "s_seats": 43696,
            "j_seats": 29118,
            "f_seats": 5348,
            "r_seats": 9489,
            "cargo_demand": 317458
        }
    },
    {
        "iata": "TTH",
        "icao": "OOTH",
        "airportName": "RAFO Thumrait",
        "location_country": "Oman",
        "location_city": "Thumrait",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.66600037",
        "longitude": "54.02460098",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "13123",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172540,
            "s_seats": 30800,
            "j_seats": 49559,
            "f_seats": 16143,
            "r_seats": 3986,
            "cargo_demand": 298211
        }
    },
    {
        "iata": "TTJ",
        "icao": "RJOR",
        "airportName": "Tottori Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Tottori",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.530102",
        "longitude": "134.167007",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6562",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167125,
            "s_seats": 34297,
            "j_seats": 57263,
            "f_seats": 2709,
            "r_seats": 3735,
            "cargo_demand": 139351
        }
    },
    {
        "iata": "TTN",
        "icao": "KTTN",
        "airportName": "Trenton-Mercer Airport",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Trenton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.27669907",
        "longitude": "-74.81349945",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6006",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4800",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185439,
            "s_seats": 44608,
            "j_seats": 23429,
            "f_seats": 13108,
            "r_seats": 9129,
            "cargo_demand": 139302
        }
    },
    {
        "iata": "TTO",
        "icao": "KBTN",
        "airportName": "Britton Municipal Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Britton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "45.81520081",
        "longitude": "-97.74310303",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2300",
                "runway_width": "120",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4200",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1301,
            "s_seats": 1568,
            "j_seats": 1367,
            "f_seats": 378,
            "r_seats": 127,
            "cargo_demand": 39091
        }
    },
    {
        "iata": "TTR",
        "icao": "WAWT",
        "airportName": "Pongtiku Airport",
        "location_country": "Indonesia",
        "location_city": "Tana Toraja",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.044739962",
        "longitude": "119.8219986",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3440",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1366,
            "s_seats": 2308,
            "j_seats": 1264,
            "f_seats": 18,
            "r_seats": 28,
            "cargo_demand": 32156
        }
    },
    {
        "iata": "TTT",
        "icao": "RCFN",
        "airportName": "Taitung Airport",
        "location_country": "Taiwan",
        "location_city": "Taitung",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.75499916",
        "longitude": "121.1019974",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7999",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 80758,
            "s_seats": 35486,
            "j_seats": 33610,
            "f_seats": 17206,
            "r_seats": 5780,
            "cargo_demand": 102465
        }
    },
    {
        "iata": "TTX",
        "icao": "YTST",
        "airportName": "Truscott-Mungalalu Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Mungalalu",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.08969975",
        "longitude": "126.3809967",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5643",
                "runway_width": "98",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 2082,
            "s_seats": 1985,
            "j_seats": 1433,
            "f_seats": 314,
            "r_seats": 49,
            "cargo_demand": 41134
        }
    },
    {
        "iata": "TUA",
        "icao": "SETU",
        "airportName": "Teniente Coronel Luis a Mantilla International Airport",
        "location_country": "Ecuador",
        "location_city": "Tulcan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "0.809505999",
        "longitude": "-77.70809937",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8071",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172763,
            "s_seats": 40214,
            "j_seats": 59694,
            "f_seats": 10525,
            "r_seats": 6093,
            "cargo_demand": 301945
        }
    },
    {
        "iata": "TUB",
        "icao": "NTAT",
        "airportName": "Tubuai - Mataura Airport",
        "location_country": "French Polynesia",
        "location_city": "Tubuai",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.36540031",
        "longitude": "-149.5240021",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4921",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77317,
            "s_seats": 27753,
            "j_seats": 54816,
            "f_seats": 5031,
            "r_seats": 8414,
            "cargo_demand": 470149
        }
    },
    {
        "iata": "TUC",
        "icao": "SANT",
        "airportName": "Teniente General Benjamin Matienzo International Airport",
        "location_country": "Argentina",
        "location_city": "Tucuman",
        "location_state": "San Miguel de Tucuman",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.8409",
        "longitude": "-65.104897",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 53296,
            "s_seats": 39625,
            "j_seats": 41842,
            "f_seats": 3939,
            "r_seats": 9272,
            "cargo_demand": 324755
        }
    },
    {
        "iata": "TUD",
        "icao": "GOTT",
        "airportName": "Tambacounda Airport",
        "location_country": "Senegal",
        "location_city": "Tambacounda",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.73680019",
        "longitude": "-13.65310001",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101697,
            "s_seats": 32949,
            "j_seats": 41231,
            "f_seats": 12466,
            "r_seats": 4593,
            "cargo_demand": 233821
        }
    },
    {
        "iata": "TUF",
        "icao": "LFOT",
        "airportName": "Tours Val de Loire Airport",
        "location_country": "France",
        "location_city": "Centre-Val de Loire",
        "location_state": "Tours",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.43220139",
        "longitude": "0.727605999",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7887",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 86613,
            "s_seats": 29437,
            "j_seats": 33789,
            "f_seats": 12976,
            "r_seats": 6419,
            "cargo_demand": 344575
        }
    },
    {
        "iata": "TUG",
        "icao": "RPUT",
        "airportName": "Tuguegarao Airport",
        "location_country": "Philippines",
        "location_city": "Tuguegarao",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.64336768",
        "longitude": "121.7331505",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6455",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 167086,
            "s_seats": 45252,
            "j_seats": 10602,
            "f_seats": 15024,
            "r_seats": 7663,
            "cargo_demand": 267921
        }
    },
    {
        "iata": "TUI",
        "icao": "OETR",
        "airportName": "Turaif Domestic Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Turaif",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.692188",
        "longitude": "38.731544",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168207,
            "s_seats": 36576,
            "j_seats": 51198,
            "f_seats": 18770,
            "r_seats": 4576,
            "cargo_demand": 322451
        }
    },
    {
        "iata": "TUK",
        "icao": "OPTU",
        "airportName": "Turbat International Airport",
        "location_country": "Pakistan",
        "location_city": "Turbat",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.9864006",
        "longitude": "63.03020096",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68423,
            "s_seats": 48159,
            "j_seats": 47199,
            "f_seats": 12983,
            "r_seats": 8645,
            "cargo_demand": 87732
        }
    },
    {
        "iata": "TUL",
        "icao": "KTUL",
        "airportName": "Tulsa International Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Tulsa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.19839859",
        "longitude": "-95.88809967",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7372",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "9999",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "6101",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 305541,
            "s_seats": 83689,
            "j_seats": 120401,
            "f_seats": 54406,
            "r_seats": 10000,
            "cargo_demand": 16556307
        }
    },
    {
        "iata": "TUM",
        "icao": "YTMU",
        "airportName": "Tumut Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Tumut",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-35.26279831",
        "longitude": "148.2409973",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3478",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51410,
            "s_seats": 37135,
            "j_seats": 50237,
            "f_seats": 13025,
            "r_seats": 4293,
            "cargo_demand": 234628
        }
    },
    {
        "iata": "TUN",
        "icao": "DTTA",
        "airportName": "Tunis-Carthage International Airport",
        "location_country": "Tunisia",
        "location_city": "Tunis",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.85100174",
        "longitude": "10.22719955",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "9318",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 255489,
            "s_seats": 82576,
            "j_seats": 126335,
            "f_seats": 24310,
            "r_seats": 10000,
            "cargo_demand": 6539544
        }
    },
    {
        "iata": "TUO",
        "icao": "NZAP",
        "airportName": "Taupo Airport",
        "location_country": "New Zealand",
        "location_city": "Taupo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.73970032",
        "longitude": "176.0839996",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2398",
                "runway_width": "197",
                "runway_material": "GRS"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4547",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90302,
            "s_seats": 40897,
            "j_seats": 34790,
            "f_seats": 5150,
            "r_seats": 9811,
            "cargo_demand": 439457
        }
    },
    {
        "iata": "TUP",
        "icao": "KTUP",
        "airportName": "Tupelo Regional Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Tupelo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.26810074",
        "longitude": "-88.76989746",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134375,
            "s_seats": 46825,
            "j_seats": 27340,
            "f_seats": 4425,
            "r_seats": 4694,
            "cargo_demand": 307821
        }
    },
    {
        "iata": "TUR",
        "icao": "SBTU",
        "airportName": "Tucurui Airport",
        "location_country": "Brazil",
        "location_city": "Para",
        "location_state": "Tucurui",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.786010027",
        "longitude": "-49.72029877",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "CON/ASP"
            }
        ],
        "demand": {
            "y_seats": 58599,
            "s_seats": 29125,
            "j_seats": 13999,
            "f_seats": 5585,
            "r_seats": 6173,
            "cargo_demand": 231566
        }
    },
    {
        "iata": "TUS",
        "icao": "KTUS",
        "airportName": "Tucson International Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Tucson",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "32.115004",
        "longitude": "-110.938053",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11L/29R",
                "runway_length": "10996",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "8408",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 443256,
            "s_seats": 74473,
            "j_seats": 37179,
            "f_seats": 58536,
            "r_seats": 10000,
            "cargo_demand": 24616819
        }
    },
    {
        "iata": "TUU",
        "icao": "OETB",
        "airportName": "Tabuk Regional Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Tabuk",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.3654",
        "longitude": "36.6189",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10991",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "10007",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82057,
            "s_seats": 31101,
            "j_seats": 18284,
            "f_seats": 11750,
            "r_seats": 6323,
            "cargo_demand": 256246
        }
    },
    {
        "iata": "TUV",
        "icao": "SVTC",
        "airportName": "San Rafael Airport",
        "location_country": "Venezuela",
        "location_city": "Tucupita",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.088994026",
        "longitude": "-62.09417343",
        "region": "SA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5512",
                "runway_width": "131",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 60413,
            "s_seats": 27427,
            "j_seats": 14955,
            "f_seats": 1346,
            "r_seats": 9261,
            "cargo_demand": 112531
        }
    },
    {
        "iata": "TVC",
        "icao": "KTVC",
        "airportName": "Cherry Capital Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Traverse City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.74140167",
        "longitude": "-85.5821991",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3204",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "6501",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5379",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81720,
            "s_seats": 45736,
            "j_seats": 20106,
            "f_seats": 3398,
            "r_seats": 9021,
            "cargo_demand": 128133
        }
    },
    {
        "iata": "TVF",
        "icao": "KTVF",
        "airportName": "Thief River Falls Regional Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "Thief River Falls",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "48.06570053",
        "longitude": "-96.18499756",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4997",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6503",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2365,
            "s_seats": 1600,
            "j_seats": 1636,
            "f_seats": 441,
            "r_seats": 4,
            "cargo_demand": 36405
        }
    },
    {
        "iata": "TVI",
        "icao": "KTVI",
        "airportName": "Thomasville Regional Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Thomasville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "30.90159988",
        "longitude": "-83.88130188",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3739,
            "s_seats": 2656,
            "j_seats": 1543,
            "f_seats": 50,
            "r_seats": 163,
            "cargo_demand": 86689
        }
    },
    {
        "iata": "TVL",
        "icao": "KTVL",
        "airportName": "Lake Tahoe Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "South Lake Tahoe",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.89390182",
        "longitude": "-119.9950027",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8544",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 60332,
            "s_seats": 46420,
            "j_seats": 44115,
            "f_seats": 9176,
            "r_seats": 9407,
            "cargo_demand": 141400
        }
    },
    {
        "iata": "TVU",
        "icao": "NFNM",
        "airportName": "Matei Airport",
        "location_country": "Fiji",
        "location_city": "Taveuni",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.690599",
        "longitude": "-179.876999",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3281",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3639,
            "s_seats": 2696,
            "j_seats": 1119,
            "f_seats": 444,
            "r_seats": 24,
            "cargo_demand": 79992
        }
    },
    {
        "iata": "TVY",
        "icao": "VYDW",
        "airportName": "Dawei Airport",
        "location_country": "Myanmar",
        "location_city": "Dawei",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.10389996",
        "longitude": "98.20359802",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7005",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 144272,
            "s_seats": 34181,
            "j_seats": 50169,
            "f_seats": 12517,
            "r_seats": 6836,
            "cargo_demand": 499674
        }
    },
    {
        "iata": "TWB",
        "icao": "YTWB",
        "airportName": "Toowoomba City Aerodrome",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Toowoomba",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.542801",
        "longitude": "151.916",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2165",
                "runway_width": "58",
                "runway_material": "GRS"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3678",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2178,
            "s_seats": 1853,
            "j_seats": 1851,
            "f_seats": 11,
            "r_seats": 143,
            "cargo_demand": 32293
        }
    },
    {
        "iata": "TWF",
        "icao": "KTWF",
        "airportName": "Magic Valley Regional Airport",
        "location_country": "United States",
        "location_city": "Idaho",
        "location_state": "Twin Falls",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.4818",
        "longitude": "-114.487999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8703",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3224",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94519,
            "s_seats": 26462,
            "j_seats": 55204,
            "f_seats": 1807,
            "r_seats": 3166,
            "cargo_demand": 341785
        }
    },
    {
        "iata": "TWT",
        "icao": "RPMN",
        "airportName": "Sanga-Sanga Airport",
        "location_country": "Philippines",
        "location_city": "Tawitawi Island (Tawi-Tawi)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.046991",
        "longitude": "119.742996",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5100",
                "runway_width": "98",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 94299,
            "s_seats": 33980,
            "j_seats": 23150,
            "f_seats": 17902,
            "r_seats": 3449,
            "cargo_demand": 430205
        }
    },
    {
        "iata": "TWU",
        "icao": "WBKW",
        "airportName": "Tawau Airport",
        "location_country": "Malaysia",
        "location_city": "Sabah",
        "location_state": "Tawau",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.320159912",
        "longitude": "118.1279984",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8800",
                "runway_width": "155",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 76823,
            "s_seats": 43316,
            "j_seats": 19838,
            "f_seats": 8654,
            "r_seats": 8822,
            "cargo_demand": 455581
        }
    },
    {
        "iata": "TWZ",
        "icao": "NZUK",
        "airportName": "Pukaki Airport",
        "location_country": "New Zealand",
        "location_city": "Twizel",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-44.23500061",
        "longitude": "170.1179962",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3550",
                "runway_width": "51",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151771,
            "s_seats": 38762,
            "j_seats": 42527,
            "f_seats": 11549,
            "r_seats": 7601,
            "cargo_demand": 351564
        }
    },
    {
        "iata": "TXE",
        "icao": "WITK",
        "airportName": "Rembele Airport",
        "location_country": "Indonesia",
        "location_city": "Sumatra",
        "location_state": "Takengon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.72113",
        "longitude": "96.851943",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3837",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63338,
            "s_seats": 33120,
            "j_seats": 55067,
            "f_seats": 19588,
            "r_seats": 7017,
            "cargo_demand": 481407
        }
    },
    {
        "iata": "TXF",
        "icao": "SNTF",
        "airportName": "Teixeira de Freitas Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Teixeira de Freitas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.52449989",
        "longitude": "-39.66849899",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4790",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3830,
            "s_seats": 2525,
            "j_seats": 1446,
            "f_seats": 4,
            "r_seats": 25,
            "cargo_demand": 65159
        }
    },
    {
        "iata": "TXK",
        "icao": "KTXK",
        "airportName": "Texarkana Regional Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Texarkana",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.45370102",
        "longitude": "-93.99099731",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6601",
                "runway_width": "144",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5200",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114563,
            "s_seats": 42591,
            "j_seats": 38083,
            "f_seats": 13622,
            "r_seats": 3978,
            "cargo_demand": 441018
        }
    },
    {
        "iata": "TXM",
        "icao": "WAST",
        "airportName": "Teminabuan Airport",
        "location_country": "Indonesia",
        "location_city": "Teminabuan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.44472003",
        "longitude": "132.0209961",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "1929",
                "runway_width": "49",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1466,
            "s_seats": 2586,
            "j_seats": 1055,
            "f_seats": 392,
            "r_seats": 101,
            "cargo_demand": 61024
        }
    },
    {
        "iata": "TYB",
        "icao": "YTIB",
        "airportName": "Tibooburra Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Tibooburra",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.4510994",
        "longitude": "142.0579987",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3202",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5026",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 174512,
            "s_seats": 42156,
            "j_seats": 50007,
            "f_seats": 12010,
            "r_seats": 9226,
            "cargo_demand": 220490
        }
    },
    {
        "iata": "TYF",
        "icao": "ESST",
        "airportName": "Torsby Airport",
        "location_country": "Sweden",
        "location_city": "Torsby",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.1576",
        "longitude": "12.9913",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5219",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179461,
            "s_seats": 48730,
            "j_seats": 20640,
            "f_seats": 13391,
            "r_seats": 3017,
            "cargo_demand": 167362
        }
    },
    {
        "iata": "TYL",
        "icao": "SPYL",
        "airportName": "Capitan FAP Victor Montes Arias International Airport",
        "location_country": "Peru",
        "location_city": "Talara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-4.576640129",
        "longitude": "-81.25409698",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8038",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88458,
            "s_seats": 44369,
            "j_seats": 25612,
            "f_seats": 7246,
            "r_seats": 4737,
            "cargo_demand": 98742
        }
    },
    {
        "iata": "TYM",
        "icao": "MYES",
        "airportName": "Staniel Cay Airport",
        "location_country": "Bahamas",
        "location_city": "Exuma Islands",
        "location_state": "Staniel Cay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.16909981",
        "longitude": "-76.43910217",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3030",
                "runway_width": "75",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 198636,
            "s_seats": 31841,
            "j_seats": 27725,
            "f_seats": 1517,
            "r_seats": 7239,
            "cargo_demand": 125146
        }
    },
    {
        "iata": "TYN",
        "icao": "ZBYN",
        "airportName": "Taiyuan Wusu International Airport",
        "location_country": "China",
        "location_city": "Shanxi",
        "location_state": "Taiyuan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "37.74689865",
        "longitude": "112.6279984",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10500",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 399644,
            "s_seats": 93201,
            "j_seats": 59400,
            "f_seats": 22015,
            "r_seats": 10000,
            "cargo_demand": 14393073
        }
    },
    {
        "iata": "TYP",
        "icao": "YTMY",
        "airportName": "Tobermorey Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Tobermorey",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.25580025",
        "longitude": "137.9530029",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2952",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3407,
            "s_seats": 1915,
            "j_seats": 1105,
            "f_seats": 230,
            "r_seats": 22,
            "cargo_demand": 89198
        }
    },
    {
        "iata": "TYR",
        "icao": "KTYR",
        "airportName": "Tyler Pounds Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Tyler",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.35409927",
        "longitude": "-95.40239716",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4850",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 180585,
            "s_seats": 38476,
            "j_seats": 25230,
            "f_seats": 11423,
            "r_seats": 8463,
            "cargo_demand": 447507
        }
    },
    {
        "iata": "TYS",
        "icao": "KTYS",
        "airportName": "McGhee Tyson Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Knoxville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "35.81100082",
        "longitude": "-83.9940033",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "9005",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187923,
            "s_seats": 78330,
            "j_seats": 124414,
            "f_seats": 57996,
            "r_seats": 10000,
            "cargo_demand": 24880928
        }
    },
    {
        "iata": "TYT",
        "icao": "SUTR",
        "airportName": "Treinta y Tres Airport",
        "location_country": "Uruguay",
        "location_city": "Treinta y Tres",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-33.195714",
        "longitude": "-54.347246",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3510",
                "runway_width": "125",
                "runway_material": "GRS"
            },
            {
                "runway_name": "10/28",
                "runway_length": "1970",
                "runway_width": "80",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1261,
            "s_seats": 1980,
            "j_seats": 1596,
            "f_seats": 204,
            "r_seats": 24,
            "cargo_demand": 74194
        }
    },
    {
        "iata": "TYZ",
        "icao": "KTYL",
        "airportName": "Taylor Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Taylor",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.45280075",
        "longitude": "-110.1149979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7200",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3888,
            "s_seats": 1651,
            "j_seats": 1314,
            "f_seats": 283,
            "r_seats": 32,
            "cargo_demand": 95717
        }
    },
    {
        "iata": "TZC",
        "icao": "KCFS",
        "airportName": "Tuscola Area Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Caro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.45880127",
        "longitude": "-83.44550323",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4300",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "2300",
                "runway_width": "110",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3162,
            "s_seats": 2026,
            "j_seats": 1643,
            "f_seats": 245,
            "r_seats": 149,
            "cargo_demand": 12547
        }
    },
    {
        "iata": "TZL",
        "icao": "LQTZ",
        "airportName": "Tuzla International Airport",
        "location_country": "Bosnia and Herzegovina",
        "location_city": "Tuzla",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.458698",
        "longitude": "18.7248",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8152",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57790,
            "s_seats": 32057,
            "j_seats": 57889,
            "f_seats": 3221,
            "r_seats": 8254,
            "cargo_demand": 134763
        }
    },
    {
        "iata": "TZN",
        "icao": "MYAK",
        "airportName": "South Andros Airport",
        "location_country": "Bahamas",
        "location_city": "Andros Island",
        "location_state": "South Andros",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.158701",
        "longitude": "-77.589798",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57551,
            "s_seats": 27773,
            "j_seats": 5359,
            "f_seats": 13352,
            "r_seats": 3850,
            "cargo_demand": 309238
        }
    },
    {
        "iata": "TZX",
        "icao": "LTCG",
        "airportName": "Trabzon Airport",
        "location_country": "Turkey",
        "location_city": "Trabzon",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "40.99509811",
        "longitude": "39.78969955",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8661",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 341783,
            "s_seats": 78052,
            "j_seats": 163694,
            "f_seats": 54765,
            "r_seats": 10000,
            "cargo_demand": 13479325
        }
    },
    {
        "iata": "UAB",
        "icao": "LTAG",
        "airportName": "Incirlik Air Base",
        "location_country": "Turkey",
        "location_city": "Adana",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.002102",
        "longitude": "35.4259",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10000",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 179627,
            "s_seats": 33141,
            "j_seats": 42453,
            "f_seats": 4082,
            "r_seats": 6554,
            "cargo_demand": 76405
        }
    },
    {
        "iata": "UAI",
        "icao": "WPDB",
        "airportName": "Suai Airport",
        "location_country": "East Timor",
        "location_city": "Suai",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.303310394",
        "longitude": "125.2870026",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3448",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109966,
            "s_seats": 30138,
            "j_seats": 15921,
            "f_seats": 1765,
            "r_seats": 4356,
            "cargo_demand": 224891
        }
    },
    {
        "iata": "UAK",
        "icao": "BGBW",
        "airportName": "Narsarsuaq Airport",
        "location_country": "Greenland",
        "location_city": "Narsarsuaq",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.16049957",
        "longitude": "-45.42599869",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6004",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 135272,
            "s_seats": 28882,
            "j_seats": 29809,
            "f_seats": 11479,
            "r_seats": 3789,
            "cargo_demand": 157426
        }
    },
    {
        "iata": "UAL",
        "icao": "FNUA",
        "airportName": "Luau Airport",
        "location_country": "Angola",
        "location_city": "Luau",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-10.71580029",
        "longitude": "22.23110008",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5112",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3772,
            "s_seats": 2105,
            "j_seats": 1488,
            "f_seats": 293,
            "r_seats": 166,
            "cargo_demand": 50208
        }
    },
    {
        "iata": "UAM",
        "icao": "PGUA",
        "airportName": "Andersen Air Force Base",
        "location_country": "Guam",
        "location_city": "Hagiotna (Agana)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.584",
        "longitude": "144.929998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "10558",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "11185",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 193392,
            "s_seats": 48582,
            "j_seats": 20759,
            "f_seats": 18194,
            "r_seats": 6066,
            "cargo_demand": 282015
        }
    },
    {
        "iata": "UAQ",
        "icao": "SANU",
        "airportName": "Domingo Faustino Sarmiento Airport",
        "location_country": "Argentina",
        "location_city": "San Juan",
        "location_state": "San Juan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.571501",
        "longitude": "-68.418198",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8071",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184877,
            "s_seats": 40235,
            "j_seats": 53438,
            "f_seats": 13828,
            "r_seats": 7768,
            "cargo_demand": 60644
        }
    },
    {
        "iata": "UAS",
        "icao": "HKSB",
        "airportName": "Samburu Airport",
        "location_country": "Kenya",
        "location_city": "Samburu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.530583",
        "longitude": "37.534195",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "2650",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3019,
            "s_seats": 2655,
            "j_seats": 1110,
            "f_seats": 255,
            "r_seats": 48,
            "cargo_demand": 19769
        }
    },
    {
        "iata": "UBA",
        "icao": "SBUR",
        "airportName": "Mario de Almeida Franco Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Uberaba",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.76472282",
        "longitude": "-47.96611023",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5771",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120593,
            "s_seats": 35283,
            "j_seats": 13560,
            "f_seats": 13776,
            "r_seats": 9468,
            "cargo_demand": 288804
        }
    },
    {
        "iata": "UBB",
        "icao": "YMAA",
        "airportName": "Mabuiag Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Mabuiag Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.949999809",
        "longitude": "142.1829987",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "1312",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 2682,
            "s_seats": 2385,
            "j_seats": 1369,
            "f_seats": 212,
            "r_seats": 148,
            "cargo_demand": 21496
        }
    },
    {
        "iata": "UBJ",
        "icao": "RJDC",
        "airportName": "Yamaguchi Ube Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Ube",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.93000031",
        "longitude": "131.279007",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87000,
            "s_seats": 29176,
            "j_seats": 29490,
            "f_seats": 15265,
            "r_seats": 7739,
            "cargo_demand": 145909
        }
    },
    {
        "iata": "UBP",
        "icao": "VTUU",
        "airportName": "Ubon Ratchathani Airport",
        "location_country": "Thailand",
        "location_city": "Ubon Ratchathani",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "15.25129986",
        "longitude": "104.8700027",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9848",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193340,
            "s_seats": 42260,
            "j_seats": 51512,
            "f_seats": 18026,
            "r_seats": 5778,
            "cargo_demand": 73726
        }
    },
    {
        "iata": "UBR",
        "icao": "WAJU",
        "airportName": "Ubrub Airport",
        "location_country": "Indonesia",
        "location_city": "Ubrub",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.67565",
        "longitude": "140.8838",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "1595",
                "runway_width": "66",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 1448,
            "s_seats": 2992,
            "j_seats": 1697,
            "f_seats": 338,
            "r_seats": 86,
            "cargo_demand": 89875
        }
    },
    {
        "iata": "UBS",
        "icao": "KUBS",
        "airportName": "Columbus-Lowndes County Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Columbus",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.4654007",
        "longitude": "-88.38030243",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1308,
            "s_seats": 2875,
            "j_seats": 1541,
            "f_seats": 245,
            "r_seats": 113,
            "cargo_demand": 63471
        }
    },
    {
        "iata": "UBT",
        "icao": "SDUB",
        "airportName": "Ubatuba Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Ubatuba",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-23.44109917",
        "longitude": "-45.07559967",
        "region": "SA",
        "runway": [
            {
                "runway_name": "9/27",
                "runway_length": "3084",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1763,
            "s_seats": 1809,
            "j_seats": 1448,
            "f_seats": 39,
            "r_seats": 143,
            "cargo_demand": 7718
        }
    },
    {
        "iata": "UBU",
        "icao": "YKAL",
        "airportName": "Kalumburu Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Kalumburu",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-14.28829956",
        "longitude": "126.6320038",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3418",
                "runway_width": "",
                "runway_material": "S"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3126",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1866,
            "s_seats": 2399,
            "j_seats": 1572,
            "f_seats": 21,
            "r_seats": 78,
            "cargo_demand": 30063
        }
    },
    {
        "iata": "UCK",
        "icao": "UKLC",
        "airportName": "Lutsk Airport",
        "location_country": "Ukraine",
        "location_city": "Lutsk",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "50.678404",
        "longitude": "25.487165",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5446",
                "runway_width": "",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2917,
            "s_seats": 1508,
            "j_seats": 1642,
            "f_seats": 78,
            "r_seats": 125,
            "cargo_demand": 17540
        }
    },
    {
        "iata": "UCY",
        "icao": "KUCY",
        "airportName": "Everett-Stewart Regional Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Union City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.38180161",
        "longitude": "-88.98539734",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1880,
            "s_seats": 2306,
            "j_seats": 1284,
            "f_seats": 490,
            "r_seats": 58,
            "cargo_demand": 55959
        }
    },
    {
        "iata": "UDD",
        "icao": "KUDD",
        "airportName": "Bermuda Dunes Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Palm Springs",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.74840164",
        "longitude": "-116.2750015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5002",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1193,
            "s_seats": 2784,
            "j_seats": 1744,
            "f_seats": 241,
            "r_seats": 12,
            "cargo_demand": 83905
        }
    },
    {
        "iata": "UDE",
        "icao": "EHVK",
        "airportName": "Volkel Air Base",
        "location_country": "Netherlands",
        "location_city": "Uden",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.657222",
        "longitude": "5.7077778",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "9922",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "9931",
                "runway_width": "74",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61554,
            "s_seats": 27633,
            "j_seats": 13758,
            "f_seats": 13059,
            "r_seats": 6600,
            "cargo_demand": 480358
        }
    },
    {
        "iata": "UDI",
        "icao": "SBUL",
        "airportName": "Ten. Cel. Av. Cesar Bombonato Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Uberl\u00e2ndia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-18.883612",
        "longitude": "-48.225277",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "6398",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 184486,
            "s_seats": 48918,
            "j_seats": 50084,
            "f_seats": 12232,
            "r_seats": 3860,
            "cargo_demand": 412212
        }
    },
    {
        "iata": "UDJ",
        "icao": "UKLU",
        "airportName": "Uzhhorod International Airport",
        "location_country": "Ukraine",
        "location_city": "Uzhhorod",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.63430023",
        "longitude": "22.26339912",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6686",
                "runway_width": "131",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 184343,
            "s_seats": 49078,
            "j_seats": 37371,
            "f_seats": 1988,
            "r_seats": 9408,
            "cargo_demand": 71555
        }
    },
    {
        "iata": "UDN",
        "icao": "LIPD",
        "airportName": "Campoformido Airport",
        "location_country": "Italy",
        "location_city": "Friuli-Venezia Giulia",
        "location_state": "Udine",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "46.0322",
        "longitude": "13.1868",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2395",
                "runway_width": "131",
                "runway_material": "grass"
            }
        ],
        "demand": {
            "y_seats": 1501,
            "s_seats": 2486,
            "j_seats": 1820,
            "f_seats": 144,
            "r_seats": 200,
            "cargo_demand": 45432
        }
    },
    {
        "iata": "UDR",
        "icao": "VAUD",
        "airportName": "Maharana Pratap Airport",
        "location_country": "India",
        "location_city": "Rajasthan",
        "location_state": "Udaipur",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.61770058",
        "longitude": "73.89610291",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7484",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78144,
            "s_seats": 25971,
            "j_seats": 33401,
            "f_seats": 4222,
            "r_seats": 7409,
            "cargo_demand": 60508
        }
    },
    {
        "iata": "UEE",
        "icao": "YQNS",
        "airportName": "Queenstown Airport",
        "location_country": "Australia",
        "location_city": "Tasmania",
        "location_state": "Queenstown",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-42.075001",
        "longitude": "145.531998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4101",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3182,
            "s_seats": 2803,
            "j_seats": 1382,
            "f_seats": 374,
            "r_seats": 125,
            "cargo_demand": 5616
        }
    },
    {
        "iata": "UEL",
        "icao": "FQQL",
        "airportName": "Quelimane Airport",
        "location_country": "Mozambique",
        "location_city": "Quelimane",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.85549927",
        "longitude": "36.86909866",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2980",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5905",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61434,
            "s_seats": 41311,
            "j_seats": 45128,
            "f_seats": 6357,
            "r_seats": 3939,
            "cargo_demand": 381945
        }
    },
    {
        "iata": "UEO",
        "icao": "ROKJ",
        "airportName": "Kumejima Airport",
        "location_country": "Japan",
        "location_city": "Okinawa Islands",
        "location_state": "Kumejima",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.363445",
        "longitude": "126.71384",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115349,
            "s_seats": 38773,
            "j_seats": 45731,
            "f_seats": 1638,
            "r_seats": 9918,
            "cargo_demand": 341374
        }
    },
    {
        "iata": "UES",
        "icao": "KUES",
        "airportName": "Waukesha County Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Waukesha",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.04100037",
        "longitude": "-88.23709869",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5848",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3598",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1600,
            "s_seats": 1960,
            "j_seats": 1523,
            "f_seats": 104,
            "r_seats": 186,
            "cargo_demand": 87446
        }
    },
    {
        "iata": "UET",
        "icao": "OPQT",
        "airportName": "Quetta International Airport",
        "location_country": "Pakistan",
        "location_city": "Quetta",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.25139999",
        "longitude": "66.93779755",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "12000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "11970",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 119783,
            "s_seats": 38826,
            "j_seats": 11122,
            "f_seats": 15665,
            "r_seats": 7797,
            "cargo_demand": 144459
        }
    },
    {
        "iata": "UFA",
        "icao": "UWUU",
        "airportName": "Ufa International Airport",
        "location_country": "Russia",
        "location_city": "Bashkortostan",
        "location_state": "Ufa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "54.55749893",
        "longitude": "55.87440109",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2215",
                "runway_width": "345",
                "runway_material": "GRE"
            },
            {
                "runway_name": "05/23",
                "runway_length": "2380",
                "runway_width": "300",
                "runway_material": "GRE"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2115",
                "runway_width": "315",
                "runway_material": "GRE"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "8245",
                "runway_width": "164",
                "runway_material": "CON"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "12339",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 495864,
            "s_seats": 90117,
            "j_seats": 125541,
            "f_seats": 57929,
            "r_seats": 10000,
            "cargo_demand": 22362335
        }
    },
    {
        "iata": "UGA",
        "icao": "ZMBN",
        "airportName": "Bulgan Airport",
        "location_country": "Mongolia",
        "location_city": "Bulgan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.85499954",
        "longitude": "103.4759979",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6234",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 76633,
            "s_seats": 30602,
            "j_seats": 39736,
            "f_seats": 11267,
            "r_seats": 9000,
            "cargo_demand": 393940
        }
    },
    {
        "iata": "UGC",
        "icao": "UTNU",
        "airportName": "Urgench International Airport",
        "location_country": "Uzbekistan",
        "location_city": "Urgench",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.58430099",
        "longitude": "60.64170074",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "11065",
                "runway_width": "165",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182587,
            "s_seats": 31507,
            "j_seats": 30152,
            "f_seats": 15667,
            "r_seats": 3284,
            "cargo_demand": 263619
        }
    },
    {
        "iata": "UGL",
        "icao": "SCGC",
        "airportName": "Union Glacier Blue-Ice Runway",
        "location_country": "Antarctica",
        "location_city": "Ellsworth Mountains",
        "location_state": "Heritage Range",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-79.777778",
        "longitude": "-83.320833",
        "region": "AN",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9843",
                "runway_width": "164",
                "runway_material": "Ice"
            }
        ],
        "demand": {
            "y_seats": 3898,
            "s_seats": 2348,
            "j_seats": 1098,
            "f_seats": 463,
            "r_seats": 178,
            "cargo_demand": 44243
        }
    },
    {
        "iata": "UGN",
        "icao": "KUGN",
        "airportName": "Waukegan National Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Waukegan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.42219925",
        "longitude": "-87.86789703",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3751",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3914,
            "s_seats": 2877,
            "j_seats": 1260,
            "f_seats": 126,
            "r_seats": 2,
            "cargo_demand": 51371
        }
    },
    {
        "iata": "UGO",
        "icao": "FNUG",
        "airportName": "Uige Airport",
        "location_country": "Angola",
        "location_city": "Uige",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.603069782",
        "longitude": "15.02779961",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6562",
                "runway_width": "55",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111400,
            "s_seats": 46341,
            "j_seats": 27243,
            "f_seats": 4923,
            "r_seats": 4546,
            "cargo_demand": 243974
        }
    },
    {
        "iata": "UHE",
        "icao": "LKKU",
        "airportName": "Kunovice Airport",
        "location_country": "Czech Republic",
        "location_city": "Uherske Hradio\u00a1t\u011b",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.02939987",
        "longitude": "17.43969917",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02C/20C",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "CON"
            },
            {
                "runway_name": "02L/20R",
                "runway_length": "4855",
                "runway_width": "262",
                "runway_material": "GRS"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "5544",
                "runway_width": "197",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 124977,
            "s_seats": 39957,
            "j_seats": 53160,
            "f_seats": 15809,
            "r_seats": 4963,
            "cargo_demand": 332682
        }
    },
    {
        "iata": "UIB",
        "icao": "SKUI",
        "airportName": "El Carano Airport",
        "location_country": "Colombia",
        "location_city": "Quibdo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "5.69076",
        "longitude": "-76.6412",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 119939,
            "s_seats": 47619,
            "j_seats": 17500,
            "f_seats": 6525,
            "r_seats": 7575,
            "cargo_demand": 263999
        }
    },
    {
        "iata": "UIH",
        "icao": "VVPC",
        "airportName": "Phu Cat Airport",
        "location_country": "Vietnam",
        "location_city": "Qui Nh\u00c6\u00a1n",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.955",
        "longitude": "109.042",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "10010",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 160254,
            "s_seats": 26675,
            "j_seats": 20323,
            "f_seats": 3968,
            "r_seats": 8017,
            "cargo_demand": 328722
        }
    },
    {
        "iata": "UIL",
        "icao": "KUIL",
        "airportName": "Quillayute Airport",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Forks",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "47.93659973",
        "longitude": "-124.5630035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4210",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3363,
            "s_seats": 2311,
            "j_seats": 1236,
            "f_seats": 143,
            "r_seats": 83,
            "cargo_demand": 91175
        }
    },
    {
        "iata": "UIN",
        "icao": "KUIN",
        "airportName": "Quincy Regional Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Quincy",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.94269943",
        "longitude": "-91.19460297",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7098",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5125",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5399",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 182447,
            "s_seats": 32241,
            "j_seats": 15091,
            "f_seats": 19261,
            "r_seats": 5276,
            "cargo_demand": 307939
        }
    },
    {
        "iata": "UIO",
        "icao": "SEQM",
        "airportName": "Mariscal Sucre International Airport",
        "location_country": "Ecuador",
        "location_city": "Quito",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-0.129166667",
        "longitude": "-78.3575",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "13445",
                "runway_width": "148",
                "runway_material": "Paviment hard"
            }
        ],
        "demand": {
            "y_seats": 279239,
            "s_seats": 84849,
            "j_seats": 139874,
            "f_seats": 26280,
            "r_seats": 10000,
            "cargo_demand": 8638789
        }
    },
    {
        "iata": "UIP",
        "icao": "LFRQ",
        "airportName": "Quimper-Cornouaille Airport",
        "location_country": "France",
        "location_city": "Brittany",
        "location_state": "Quimper",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.97499847",
        "longitude": "-4.167789936",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2657",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "7054",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120314,
            "s_seats": 30415,
            "j_seats": 38507,
            "f_seats": 6606,
            "r_seats": 7599,
            "cargo_demand": 259444
        }
    },
    {
        "iata": "UIR",
        "icao": "YQDI",
        "airportName": "Quirindi Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Quirindi",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31.49060059",
        "longitude": "150.5140076",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3629",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5807",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 1830,
            "s_seats": 1792,
            "j_seats": 1808,
            "f_seats": 335,
            "r_seats": 9,
            "cargo_demand": 66453
        }
    },
    {
        "iata": "UKA",
        "icao": "HKUK",
        "airportName": "Ukunda Airport",
        "location_country": "Kenya",
        "location_city": "Ukunda",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-4.29333",
        "longitude": "39.571098",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3851",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3348,
            "s_seats": 2005,
            "j_seats": 1580,
            "f_seats": 141,
            "r_seats": 153,
            "cargo_demand": 88338
        }
    },
    {
        "iata": "UKB",
        "icao": "RJBE",
        "airportName": "Kobe Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Kobe / Osaka",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.63280106",
        "longitude": "135.223999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8202",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122542,
            "s_seats": 25340,
            "j_seats": 49834,
            "f_seats": 6071,
            "r_seats": 4315,
            "cargo_demand": 482801
        }
    },
    {
        "iata": "UKI",
        "icao": "KUKI",
        "airportName": "Ukiah Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Ukiah",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.12599945",
        "longitude": "-123.2009964",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4415",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88646,
            "s_seats": 40116,
            "j_seats": 11956,
            "f_seats": 11209,
            "r_seats": 5562,
            "cargo_demand": 495834
        }
    },
    {
        "iata": "UKK",
        "icao": "UASK",
        "airportName": "Oskemen Airport",
        "location_country": "Kazakhstan",
        "location_city": "Oskemen (Ust-Kamenogorsk)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.03659821",
        "longitude": "82.49420166",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8234",
                "runway_width": "138",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "6088",
                "runway_width": "200",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 168352,
            "s_seats": 46224,
            "j_seats": 12688,
            "f_seats": 12081,
            "r_seats": 4381,
            "cargo_demand": 493939
        }
    },
    {
        "iata": "UKT",
        "icao": "KUKT",
        "airportName": "Quakertown Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Quakertown",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.43519974",
        "longitude": "-75.38189697",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3201",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1822,
            "s_seats": 2709,
            "j_seats": 1622,
            "f_seats": 128,
            "r_seats": 34,
            "cargo_demand": 77577
        }
    },
    {
        "iata": "ULA",
        "icao": "SAWJ",
        "airportName": "Capitan Jose Daniel Vazquez Airport",
        "location_country": "Argentina",
        "location_city": "Santa Cruz",
        "location_state": "Puerto San Julian",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-49.3068",
        "longitude": "-67.8026",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "6562",
                "runway_width": "131",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 188981,
            "s_seats": 35239,
            "j_seats": 19870,
            "f_seats": 6265,
            "r_seats": 3054,
            "cargo_demand": 361358
        }
    },
    {
        "iata": "ULB",
        "icao": "NVSU",
        "airportName": "Ulei Airport",
        "location_country": "Vanuatu",
        "location_city": "Ulei",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.3297",
        "longitude": "168.3011",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2789",
                "runway_width": "98",
                "runway_material": "Volcanic ash/soil"
            }
        ],
        "demand": {
            "y_seats": 1253,
            "s_seats": 2196,
            "j_seats": 1784,
            "f_seats": 438,
            "r_seats": 106,
            "cargo_demand": 42356
        }
    },
    {
        "iata": "ULD",
        "icao": "FAUL",
        "airportName": "Ulundi Airport",
        "location_country": "South Africa",
        "location_city": "Ulundi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.32060051",
        "longitude": "31.41650009",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3395",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "5381",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66201,
            "s_seats": 41795,
            "j_seats": 58779,
            "f_seats": 18531,
            "r_seats": 7337,
            "cargo_demand": 387502
        }
    },
    {
        "iata": "ULG",
        "icao": "ZMUL",
        "airportName": "olgii Airport",
        "location_country": "Mongolia",
        "location_city": "olgii",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "48.993301",
        "longitude": "89.922501",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "7874",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2473,
            "s_seats": 2758,
            "j_seats": 1424,
            "f_seats": 472,
            "r_seats": 6,
            "cargo_demand": 19025
        }
    },
    {
        "iata": "ULM",
        "icao": "KULM",
        "airportName": "New Ulm Municipal Airport",
        "location_country": "United States",
        "location_city": "Minnesota",
        "location_state": "New Ulm",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.31959915",
        "longitude": "-94.50229645",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2825",
                "runway_width": "160",
                "runway_material": "GRS"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4401",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3240,
            "s_seats": 2613,
            "j_seats": 1683,
            "f_seats": 127,
            "r_seats": 134,
            "cargo_demand": 79859
        }
    },
    {
        "iata": "ULN",
        "icao": "ZMUB",
        "airportName": "Buyant-Ukhaa International Airport",
        "location_country": "Mongolia",
        "location_city": "Ulaanbaatar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.843102",
        "longitude": "106.766998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "10170",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6560",
                "runway_width": "131",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 101235,
            "s_seats": 45297,
            "j_seats": 18511,
            "f_seats": 13651,
            "r_seats": 4842,
            "cargo_demand": 212957
        }
    },
    {
        "iata": "ULP",
        "icao": "YQLP",
        "airportName": "Quilpie Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Quilpie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.61219978",
        "longitude": "144.253006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4898",
                "runway_width": "98",
                "runway_material": "COP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3510",
                "runway_width": "98",
                "runway_material": "CLA"
            }
        ],
        "demand": {
            "y_seats": 172509,
            "s_seats": 49715,
            "j_seats": 19332,
            "f_seats": 12816,
            "r_seats": 4616,
            "cargo_demand": 269643
        }
    },
    {
        "iata": "ULQ",
        "icao": "SKUL",
        "airportName": "Heriberto Gil Martinez Airport",
        "location_country": "Colombia",
        "location_city": "Tulua",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.08836",
        "longitude": "-76.2351",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3495",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 60966,
            "s_seats": 26895,
            "j_seats": 40664,
            "f_seats": 16509,
            "r_seats": 4357,
            "cargo_demand": 137423
        }
    },
    {
        "iata": "ULU",
        "icao": "HUGU",
        "airportName": "Gulu Airport",
        "location_country": "Uganda",
        "location_city": "Gulu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "2.805560112",
        "longitude": "32.27180099",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "10314",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58556,
            "s_seats": 34862,
            "j_seats": 38724,
            "f_seats": 1746,
            "r_seats": 7059,
            "cargo_demand": 381224
        }
    },
    {
        "iata": "ULV",
        "icao": "UWLL",
        "airportName": "Ulyanovsk Baratayevka Airport",
        "location_country": "Russia",
        "location_city": "Ulyanovsk Oblast",
        "location_state": "Ulyanovsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.2682991",
        "longitude": "48.22669983",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "12533",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 82010,
            "s_seats": 29143,
            "j_seats": 15539,
            "f_seats": 11658,
            "r_seats": 9565,
            "cargo_demand": 432361
        }
    },
    {
        "iata": "ULY",
        "icao": "UWLW",
        "airportName": "Ulyanovsk Vostochny Airport",
        "location_country": "Russia",
        "location_city": "Ulyanovsk Oblast",
        "location_state": "Ulyanovsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.401001",
        "longitude": "48.8027",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "16404",
                "runway_width": "323",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 135897,
            "s_seats": 40262,
            "j_seats": 57617,
            "f_seats": 7471,
            "r_seats": 5287,
            "cargo_demand": 474201
        }
    },
    {
        "iata": "UME",
        "icao": "ESNU",
        "airportName": "Umeio Airport",
        "location_country": "Sweden",
        "location_city": "Umeio",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "63.79180145",
        "longitude": "20.28280067",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "7551",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167902,
            "s_seats": 32396,
            "j_seats": 35186,
            "f_seats": 14174,
            "r_seats": 8890,
            "cargo_demand": 420295
        }
    },
    {
        "iata": "UMM",
        "icao": "PAST",
        "airportName": "Summit Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Summit",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "63.33150101",
        "longitude": "-149.1269989",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3840",
                "runway_width": "80",
                "runway_material": "GRAVEL-P"
            }
        ],
        "demand": {
            "y_seats": 3608,
            "s_seats": 1827,
            "j_seats": 1580,
            "f_seats": 120,
            "r_seats": 57,
            "cargo_demand": 90627
        }
    },
    {
        "iata": "UMR",
        "icao": "YPWR",
        "airportName": "RAAF Woomera Airfield",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Woomera",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31.14419937",
        "longitude": "136.8170013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5295",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7782",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1125,
            "s_seats": 1525,
            "j_seats": 1057,
            "f_seats": 408,
            "r_seats": 18,
            "cargo_demand": 51680
        }
    },
    {
        "iata": "UMT",
        "icao": "PAUM",
        "airportName": "Umiat Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Umiat",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "69.37110138",
        "longitude": "-152.1360016",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5583",
                "runway_width": "100",
                "runway_material": "GRVL-P"
            }
        ],
        "demand": {
            "y_seats": 2815,
            "s_seats": 2094,
            "j_seats": 1554,
            "f_seats": 174,
            "r_seats": 11,
            "cargo_demand": 74456
        }
    },
    {
        "iata": "UMU",
        "icao": "SSUM",
        "airportName": "Orlando de Carvalho Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Umuarama",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-23.79870033",
        "longitude": "-53.31380081",
        "region": "SA",
        "runway": [
            {
                "runway_name": "3/21",
                "runway_length": "4593",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1015,
            "s_seats": 2377,
            "j_seats": 1814,
            "f_seats": 394,
            "r_seats": 33,
            "cargo_demand": 92736
        }
    },
    {
        "iata": "UMZ",
        "icao": "KMEZ",
        "airportName": "Mena Intermountain Municipal Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Mena",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.545399",
        "longitude": "-94.202698",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2529,
            "s_seats": 1526,
            "j_seats": 1924,
            "f_seats": 377,
            "r_seats": 178,
            "cargo_demand": 14073
        }
    },
    {
        "iata": "UNA",
        "icao": "SBTC",
        "airportName": "Una-Comandatuba Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Una / Comandatuba Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.35519981",
        "longitude": "-38.99900055",
        "region": "SA",
        "runway": [
            {
                "runway_name": "2/20",
                "runway_length": "6234",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1348,
            "s_seats": 1616,
            "j_seats": 1916,
            "f_seats": 205,
            "r_seats": 45,
            "cargo_demand": 53490
        }
    },
    {
        "iata": "UND",
        "icao": "OAUZ",
        "airportName": "Kunduz Airport",
        "location_country": "Afghanistan",
        "location_city": "Kunduz",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.6651",
        "longitude": "68.910797",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6558",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74910,
            "s_seats": 41956,
            "j_seats": 19102,
            "f_seats": 19271,
            "r_seats": 4510,
            "cargo_demand": 109326
        }
    },
    {
        "iata": "UNG",
        "icao": "AYKI",
        "airportName": "Kiunga Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Kiunga",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.125710011",
        "longitude": "141.2819977",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3691",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3205,
            "s_seats": 1740,
            "j_seats": 1058,
            "f_seats": 262,
            "r_seats": 197,
            "cargo_demand": 31954
        }
    },
    {
        "iata": "UNK",
        "icao": "PAUN",
        "airportName": "Unalakleet Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Unalakleet",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "63.88840103",
        "longitude": "-160.798996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2000",
                "runway_width": "80",
                "runway_material": "GVL"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6004",
                "runway_width": "150",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 3141,
            "s_seats": 2230,
            "j_seats": 1380,
            "f_seats": 390,
            "r_seats": 170,
            "cargo_demand": 88355
        }
    },
    {
        "iata": "UNN",
        "icao": "VTSR",
        "airportName": "Ranong Airport",
        "location_country": "Thailand",
        "location_city": "Ranong",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.777620316",
        "longitude": "98.58550262",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 66838,
            "s_seats": 36887,
            "j_seats": 20279,
            "f_seats": 12328,
            "r_seats": 6814,
            "cargo_demand": 51980
        }
    },
    {
        "iata": "UNU",
        "icao": "KUNU",
        "airportName": "Dodge County Airport",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Juneau",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.42660141",
        "longitude": "-88.70320129",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4027",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "5060",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3992,
            "s_seats": 2739,
            "j_seats": 1970,
            "f_seats": 500,
            "r_seats": 89,
            "cargo_demand": 47776
        }
    },
    {
        "iata": "UOL",
        "icao": "WAMY",
        "airportName": "Pogogul Airport",
        "location_country": "Indonesia",
        "location_city": "Buol",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "1.1027",
        "longitude": "121.4141",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2461",
                "runway_width": "69",
                "runway_material": "Compacted coral and sand"
            }
        ],
        "demand": {
            "y_seats": 2462,
            "s_seats": 1526,
            "j_seats": 1914,
            "f_seats": 260,
            "r_seats": 5,
            "cargo_demand": 22736
        }
    },
    {
        "iata": "UOS",
        "icao": "KUOS",
        "airportName": "Franklin County Airport",
        "location_country": "United States",
        "location_city": "Tennessee",
        "location_state": "Sewanee",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "35.20510101",
        "longitude": "-85.89810181",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3699",
                "runway_width": "50",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3916,
            "s_seats": 2214,
            "j_seats": 1761,
            "f_seats": 35,
            "r_seats": 152,
            "cargo_demand": 7374
        }
    },
    {
        "iata": "UOX",
        "icao": "KUOX",
        "airportName": "University-Oxford Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Oxford",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.38430023",
        "longitude": "-89.53679657",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5600",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160048,
            "s_seats": 44014,
            "j_seats": 51105,
            "f_seats": 15945,
            "r_seats": 9689,
            "cargo_demand": 281174
        }
    },
    {
        "iata": "UPB",
        "icao": "MUPB",
        "airportName": "Playa Baracoa Airport",
        "location_country": "Cuba",
        "location_city": "Havana",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.03280067",
        "longitude": "-82.57939911",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7563",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145359,
            "s_seats": 27609,
            "j_seats": 26091,
            "f_seats": 9419,
            "r_seats": 8933,
            "cargo_demand": 210840
        }
    },
    {
        "iata": "UPG",
        "icao": "WAAA",
        "airportName": "Sultan Hasanuddin International Airport",
        "location_country": "Indonesia",
        "location_city": "Makassar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-5.061629772",
        "longitude": "119.5540009",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10171",
                "runway_width": "148",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "13/31",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149629,
            "s_seats": 85886,
            "j_seats": 85289,
            "f_seats": 36136,
            "r_seats": 10000,
            "cargo_demand": 13171253
        }
    },
    {
        "iata": "UPL",
        "icao": "MRUP",
        "airportName": "Upala Airport",
        "location_country": "Costa Rica",
        "location_city": "Upala",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.8922",
        "longitude": "-85.016197",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3281",
                "runway_width": "39",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171130,
            "s_seats": 47872,
            "j_seats": 18520,
            "f_seats": 13995,
            "r_seats": 9177,
            "cargo_demand": 180120
        }
    },
    {
        "iata": "UPN",
        "icao": "MMPN",
        "airportName": "Ignacio Lopez Rayon International Airport",
        "location_country": "Mexico",
        "location_city": "Michoacan",
        "location_state": "Uruapan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.39669991",
        "longitude": "-102.0390015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197639,
            "s_seats": 33079,
            "j_seats": 44384,
            "f_seats": 8060,
            "r_seats": 9175,
            "cargo_demand": 462358
        }
    },
    {
        "iata": "UPP",
        "icao": "PHUP",
        "airportName": "Upolu Airport",
        "location_country": "United States",
        "location_city": "Hawaii",
        "location_state": "Hawi",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.26530075",
        "longitude": "-155.8600006",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3800",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 77644,
            "s_seats": 41956,
            "j_seats": 36965,
            "f_seats": 3417,
            "r_seats": 9887,
            "cargo_demand": 402386
        }
    },
    {
        "iata": "UPV",
        "icao": "EGDJ",
        "airportName": "RAF Upavon",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Upavon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "51.28620148",
        "longitude": "-1.782019973",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3635,
            "s_seats": 2460,
            "j_seats": 1531,
            "f_seats": 190,
            "r_seats": 184,
            "cargo_demand": 15677
        }
    },
    {
        "iata": "URA",
        "icao": "UARR",
        "airportName": "Oral Ak Zhol Airport",
        "location_country": "Kazakhstan",
        "location_city": "Oral (Uralsk)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.1507988",
        "longitude": "51.54309845",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "7874",
                "runway_width": "138",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 87685,
            "s_seats": 31339,
            "j_seats": 29889,
            "f_seats": 1325,
            "r_seats": 9018,
            "cargo_demand": 159368
        }
    },
    {
        "iata": "URC",
        "icao": "ZWWW",
        "airportName": "i\u0153rumqi Diwopu International Airport",
        "location_country": "China",
        "location_city": "Xinjiang",
        "location_state": "i\u0153rumqi",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.90710068",
        "longitude": "87.47419739",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 403513,
            "s_seats": 96364,
            "j_seats": 138649,
            "f_seats": 62647,
            "r_seats": 10000,
            "cargo_demand": 7262564
        }
    },
    {
        "iata": "URD",
        "icao": "EDQE",
        "airportName": "Burg Feuerstein Airport",
        "location_country": "Germany",
        "location_city": "Bavaria",
        "location_state": "Burg Feuerstein",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "49.79416656",
        "longitude": "11.13361073",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3190",
                "runway_width": "49",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08L/26R",
                "runway_length": "2317",
                "runway_width": "85",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3997,
            "s_seats": 2169,
            "j_seats": 1093,
            "f_seats": 312,
            "r_seats": 151,
            "cargo_demand": 78403
        }
    },
    {
        "iata": "URE",
        "icao": "EEKE",
        "airportName": "Kuressaare Airport",
        "location_country": "Estonia",
        "location_city": "Kuressaare",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.22990036",
        "longitude": "22.5095005",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2621",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4980",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166184,
            "s_seats": 27951,
            "j_seats": 29514,
            "f_seats": 11884,
            "r_seats": 7063,
            "cargo_demand": 73401
        }
    },
    {
        "iata": "URG",
        "icao": "SBUG",
        "airportName": "Rubem Berta International Airport",
        "location_country": "Brazil",
        "location_city": "Rio Grande do Sul",
        "location_state": "Uruguaiana",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.78219986",
        "longitude": "-57.03820038",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "2625",
                "runway_width": "164",
                "runway_material": "GRS"
            },
            {
                "runway_name": "9/27",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131395,
            "s_seats": 48612,
            "j_seats": 57913,
            "f_seats": 10368,
            "r_seats": 4525,
            "cargo_demand": 96661
        }
    },
    {
        "iata": "URO",
        "icao": "LFOP",
        "airportName": "Rouen Airport",
        "location_country": "France",
        "location_city": "Upper Normandy",
        "location_state": "Rouen",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.38420105",
        "longitude": "1.174800038",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5577",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05/23",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 94286,
            "s_seats": 40793,
            "j_seats": 14705,
            "f_seats": 6575,
            "r_seats": 3380,
            "cargo_demand": 305924
        }
    },
    {
        "iata": "URS",
        "icao": "UUOK",
        "airportName": "Kursk Vostochny Airport",
        "location_country": "Russia",
        "location_city": "Kursk Oblast",
        "location_state": "Kursk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.75059891",
        "longitude": "36.29560089",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "8202",
                "runway_width": "131",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 169250,
            "s_seats": 40945,
            "j_seats": 21558,
            "f_seats": 15643,
            "r_seats": 7965,
            "cargo_demand": 309225
        }
    },
    {
        "iata": "URT",
        "icao": "VTSB",
        "airportName": "Surat Thani Airport",
        "location_country": "Thailand",
        "location_city": "Surat Thani",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.132599831",
        "longitude": "99.13559723",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64976,
            "s_seats": 33479,
            "j_seats": 27214,
            "f_seats": 11818,
            "r_seats": 6767,
            "cargo_demand": 196018
        }
    },
    {
        "iata": "URY",
        "icao": "OEGT",
        "airportName": "Gurayat Domestic Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Qurayyat (Gurayat)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.412413",
        "longitude": "37.278898",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10007",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138689,
            "s_seats": 43797,
            "j_seats": 42356,
            "f_seats": 11359,
            "r_seats": 5410,
            "cargo_demand": 466690
        }
    },
    {
        "iata": "USA",
        "icao": "KJQF",
        "airportName": "Concord Regional Airport",
        "location_country": "United States",
        "location_city": "North Carolina",
        "location_state": "Concord",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.387798",
        "longitude": "-80.709099",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7403",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100369,
            "s_seats": 39021,
            "j_seats": 50686,
            "f_seats": 3163,
            "r_seats": 8457,
            "cargo_demand": 345366
        }
    },
    {
        "iata": "USH",
        "icao": "SAWH",
        "airportName": "Ushuaia - Malvinas Argentinas International Airport",
        "location_country": "Argentina",
        "location_city": "Tierra del Fuego",
        "location_state": "Ushuaia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-54.8433",
        "longitude": "-68.2958",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "9186",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 63958,
            "s_seats": 39744,
            "j_seats": 53597,
            "f_seats": 6712,
            "r_seats": 6379,
            "cargo_demand": 300028
        }
    },
    {
        "iata": "USL",
        "icao": "YUSL",
        "airportName": "Useless Loop Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Useless Loop",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.157923",
        "longitude": "113.394817",
        "region": "OC",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3280",
                "runway_width": "",
                "runway_material": "NW"
            }
        ],
        "demand": {
            "y_seats": 1701,
            "s_seats": 2748,
            "j_seats": 1699,
            "f_seats": 220,
            "r_seats": 23,
            "cargo_demand": 60873
        }
    },
    {
        "iata": "USM",
        "icao": "VTSM",
        "airportName": "Samui Airport",
        "location_country": "Thailand",
        "location_city": "Ko Samui",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.547789574",
        "longitude": "100.0619965",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6759",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101570,
            "s_seats": 49182,
            "j_seats": 41007,
            "f_seats": 10341,
            "r_seats": 7742,
            "cargo_demand": 323702
        }
    },
    {
        "iata": "USN",
        "icao": "RKPU",
        "airportName": "Ulsan Airport",
        "location_country": "South Korea",
        "location_city": "Ulsan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.59349823",
        "longitude": "129.352005",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6561",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92492,
            "s_seats": 30058,
            "j_seats": 8255,
            "f_seats": 14253,
            "r_seats": 5305,
            "cargo_demand": 284164
        }
    },
    {
        "iata": "USQ",
        "icao": "LTBO",
        "airportName": "Usak Airport",
        "location_country": "Turkey",
        "location_city": "Usak",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.68149948",
        "longitude": "29.47170067",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8385",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3419,
            "s_seats": 1891,
            "j_seats": 1473,
            "f_seats": 279,
            "r_seats": 66,
            "cargo_demand": 36457
        }
    },
    {
        "iata": "USS",
        "icao": "MUSS",
        "airportName": "Sancti Spiritus Airport",
        "location_country": "Cuba",
        "location_city": "Sancti Spiritus",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.9704",
        "longitude": "-79.442703",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5905",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1372,
            "s_seats": 1974,
            "j_seats": 1562,
            "f_seats": 398,
            "r_seats": 59,
            "cargo_demand": 89918
        }
    },
    {
        "iata": "UST",
        "icao": "KSGJ",
        "airportName": "Northeast Florida Regional Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "St. Augustine",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.9592",
        "longitude": "-81.339798",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2614",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "2701",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12W/30W",
                "runway_length": "5000",
                "runway_width": "1000",
                "runway_material": "WATER"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7996",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4001",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17W/35W",
                "runway_length": "12000",
                "runway_width": "1000",
                "runway_material": "WATER"
            },
            {
                "runway_name": "18W/36W",
                "runway_length": "12000",
                "runway_width": "500",
                "runway_material": "WATER"
            }
        ],
        "demand": {
            "y_seats": 102584,
            "s_seats": 40863,
            "j_seats": 51452,
            "f_seats": 6433,
            "r_seats": 6033,
            "cargo_demand": 430476
        }
    },
    {
        "iata": "USU",
        "icao": "RPVV",
        "airportName": "Francisco B. Reyes Airport",
        "location_country": "Philippines",
        "location_city": "Busuanga",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.12150002",
        "longitude": "120.0999985",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3300",
                "runway_width": "98",
                "runway_material": "COP"
            }
        ],
        "demand": {
            "y_seats": 103600,
            "s_seats": 41860,
            "j_seats": 22655,
            "f_seats": 5661,
            "r_seats": 8306,
            "cargo_demand": 302539
        }
    },
    {
        "iata": "UTA",
        "icao": "FVMU",
        "airportName": "Mutare Airport",
        "location_country": "Zimbabwe",
        "location_city": "Mutare",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.99749947",
        "longitude": "32.62720108",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3117",
                "runway_width": "60",
                "runway_material": "MAC"
            }
        ],
        "demand": {
            "y_seats": 3009,
            "s_seats": 2252,
            "j_seats": 1174,
            "f_seats": 476,
            "r_seats": 58,
            "cargo_demand": 22900
        }
    },
    {
        "iata": "UTB",
        "icao": "YMTB",
        "airportName": "Muttaburra Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Muttaburra",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.58329964",
        "longitude": "144.5330048",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3608",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2941,
            "s_seats": 2083,
            "j_seats": 1969,
            "f_seats": 349,
            "r_seats": 173,
            "cargo_demand": 95226
        }
    },
    {
        "iata": "UTH",
        "icao": "VTUD",
        "airportName": "Udon Thani International Airport",
        "location_country": "Thailand",
        "location_city": "Udon Thani",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.38640022",
        "longitude": "102.788002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "10000",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162249,
            "s_seats": 42036,
            "j_seats": 6682,
            "f_seats": 17559,
            "r_seats": 4758,
            "cargo_demand": 217100
        }
    },
    {
        "iata": "UTI",
        "icao": "EFUT",
        "airportName": "Utti Airport",
        "location_country": "Finland",
        "location_city": "Utti",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.8964",
        "longitude": "26.9384",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110188,
            "s_seats": 46711,
            "j_seats": 54390,
            "f_seats": 1370,
            "r_seats": 3368,
            "cargo_demand": 222006
        }
    },
    {
        "iata": "UTM",
        "icao": "KUTA",
        "airportName": "Tunica Municipal Airport ",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Tunica",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.68099976",
        "longitude": "-90.34670258",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1638,
            "s_seats": 1965,
            "j_seats": 1347,
            "f_seats": 467,
            "r_seats": 122,
            "cargo_demand": 74881
        }
    },
    {
        "iata": "UTN",
        "icao": "FAUP",
        "airportName": "Upington Airport",
        "location_country": "South Africa",
        "location_city": "Upington",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.39909935",
        "longitude": "21.2602005",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2736",
                "runway_width": "98",
                "runway_material": "COM"
            },
            {
                "runway_name": "17/35",
                "runway_length": "16076",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108285,
            "s_seats": 32580,
            "j_seats": 20671,
            "f_seats": 16554,
            "r_seats": 8331,
            "cargo_demand": 302762
        }
    },
    {
        "iata": "UTO",
        "icao": "PAIM",
        "airportName": "Indian Mountain LRRS Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Utopia Creek",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "65.99279785",
        "longitude": "-153.7039948",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4100",
                "runway_width": "120",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 83795,
            "s_seats": 46848,
            "j_seats": 16842,
            "f_seats": 2013,
            "r_seats": 3551,
            "cargo_demand": 426694
        }
    },
    {
        "iata": "UTP",
        "icao": "VTBU",
        "airportName": "U-Tapao International Airport",
        "location_country": "Thailand",
        "location_city": "Rayong",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.67990017",
        "longitude": "101.0049973",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "11500",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117970,
            "s_seats": 44643,
            "j_seats": 15394,
            "f_seats": 1777,
            "r_seats": 6994,
            "cargo_demand": 220942
        }
    },
    {
        "iata": "UTT",
        "icao": "FAUT",
        "airportName": "Mthatha Airport",
        "location_country": "South Africa",
        "location_city": "Mthatha (Umtata)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.54636318",
        "longitude": "28.6733551",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "GRE"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6562",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58911,
            "s_seats": 29430,
            "j_seats": 59479,
            "f_seats": 19067,
            "r_seats": 8989,
            "cargo_demand": 230024
        }
    },
    {
        "iata": "UTW",
        "icao": "FAQT",
        "airportName": "Queenstown Airport",
        "location_country": "South Africa",
        "location_city": "Queenstown",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.92020035",
        "longitude": "26.88220024",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3937",
                "runway_width": "66",
                "runway_material": "Tar - lights 5 clicks on 124.8"
            }
        ],
        "demand": {
            "y_seats": 173047,
            "s_seats": 42404,
            "j_seats": 22738,
            "f_seats": 17737,
            "r_seats": 3495,
            "cargo_demand": 203012
        }
    },
    {
        "iata": "UUA",
        "icao": "UWKB",
        "airportName": "Bugulma Airport",
        "location_country": "Russia",
        "location_city": "Tatarstan",
        "location_state": "Bugulma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.63999939",
        "longitude": "52.80170059",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6561",
                "runway_width": "131",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 172388,
            "s_seats": 41592,
            "j_seats": 42270,
            "f_seats": 15857,
            "r_seats": 7487,
            "cargo_demand": 182597
        }
    },
    {
        "iata": "UUD",
        "icao": "UIUU",
        "airportName": "Baikal International Airport",
        "location_country": "Russia",
        "location_city": "Buryatia",
        "location_state": "Ulan-Ude",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.80780029",
        "longitude": "107.4380035",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "08L/26R",
                "runway_length": "6700",
                "runway_width": "340",
                "runway_material": "GRS"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4630",
                "runway_width": "315",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 60788,
            "s_seats": 45010,
            "j_seats": 34529,
            "f_seats": 18583,
            "r_seats": 6927,
            "cargo_demand": 315465
        }
    },
    {
        "iata": "UUK",
        "icao": "PAKU",
        "airportName": "Ugnu-Kuparuk Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Kuparuk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "70.33080292",
        "longitude": "-149.5980072",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6000",
                "runway_width": "130",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 1355,
            "s_seats": 2943,
            "j_seats": 1511,
            "f_seats": 118,
            "r_seats": 181,
            "cargo_demand": 66231
        }
    },
    {
        "iata": "UUN",
        "icao": "ZMBU",
        "airportName": "Baruun-Urt Airport",
        "location_country": "Mongolia",
        "location_city": "Baruun-Urt",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.66030121",
        "longitude": "113.2850037",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "7217",
                "runway_width": "164",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 178661,
            "s_seats": 45373,
            "j_seats": 51104,
            "f_seats": 5001,
            "r_seats": 9469,
            "cargo_demand": 224791
        }
    },
    {
        "iata": "UUS",
        "icao": "UHSS",
        "airportName": "Yuzhno-Sakhalinsk Airport",
        "location_country": "Russia",
        "location_city": "Sakhalin Oblast",
        "location_state": "Yuzhno-Sakhalinsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.88869858",
        "longitude": "142.7180023",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 145295,
            "s_seats": 33563,
            "j_seats": 56657,
            "f_seats": 10176,
            "r_seats": 9466,
            "cargo_demand": 446362
        }
    },
    {
        "iata": "UVA",
        "icao": "KUVA",
        "airportName": "Garner Field",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Uvalde",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "29.2112999",
        "longitude": "-99.74359894",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5255",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2324,
            "s_seats": 1550,
            "j_seats": 1278,
            "f_seats": 140,
            "r_seats": 185,
            "cargo_demand": 19888
        }
    },
    {
        "iata": "UVE",
        "icao": "NWWV",
        "airportName": "Ouvea Airport",
        "location_country": "New Caledonia",
        "location_city": "Ouvea",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.6406002",
        "longitude": "166.572998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179630,
            "s_seats": 36581,
            "j_seats": 17087,
            "f_seats": 17444,
            "r_seats": 8416,
            "cargo_demand": 381998
        }
    },
    {
        "iata": "UVF",
        "icao": "TLPL",
        "airportName": "Hewanorra International Airport",
        "location_country": "Saint Lucia",
        "location_city": "Vieux Fort",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "13.7332",
        "longitude": "-60.952599",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9003",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159539,
            "s_seats": 95008,
            "j_seats": 131025,
            "f_seats": 35716,
            "r_seats": 10000,
            "cargo_demand": 5379662
        }
    },
    {
        "iata": "UVI",
        "icao": "SSUV",
        "airportName": "Uniio da Vitoria Airport",
        "location_country": "Brazil",
        "location_city": "Parana",
        "location_state": "Uniio da Vitoria",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.2334",
        "longitude": "-51.067797",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3281",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3583,
            "s_seats": 1524,
            "j_seats": 1288,
            "f_seats": 460,
            "r_seats": 115,
            "cargo_demand": 7382
        }
    },
    {
        "iata": "UVL",
        "icao": "HEKG",
        "airportName": "El Kharga Airport",
        "location_country": "Egypt",
        "location_city": "Kharga Oasis",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "25.47360039",
        "longitude": "30.59070015",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1333,
            "s_seats": 1978,
            "j_seats": 1215,
            "f_seats": 223,
            "r_seats": 194,
            "cargo_demand": 95399
        }
    },
    {
        "iata": "UYL",
        "icao": "HSNN",
        "airportName": "Nyala Airport",
        "location_country": "Sudan",
        "location_city": "Nyala",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "12.05350018",
        "longitude": "24.95619965",
        "region": "AF",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "9880",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1411,
            "s_seats": 2147,
            "j_seats": 1383,
            "f_seats": 209,
            "r_seats": 52,
            "cargo_demand": 12090
        }
    },
    {
        "iata": "UZU",
        "icao": "SATU",
        "airportName": "Curuzu Cuatia Airport",
        "location_country": "Argentina",
        "location_city": "Corrientes",
        "location_state": "Curuzu Cuatia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.7706",
        "longitude": "-57.9789",
        "region": "SA",
        "runway": [
            {
                "runway_name": "1/19",
                "runway_length": "7054",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70188,
            "s_seats": 31603,
            "j_seats": 42201,
            "f_seats": 14773,
            "r_seats": 5689,
            "cargo_demand": 410482
        }
    },
    {
        "iata": "VAA",
        "icao": "EFVA",
        "airportName": "Vaasa Airport",
        "location_country": "Finland",
        "location_city": "Vaasa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "63.050701",
        "longitude": "21.762199",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8727",
                "runway_width": "157",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175152,
            "s_seats": 38217,
            "j_seats": 37915,
            "f_seats": 6373,
            "r_seats": 4598,
            "cargo_demand": 377447
        }
    },
    {
        "iata": "VAC",
        "icao": "EDWU",
        "airportName": "Varrelbusch Airport",
        "location_country": "Germany",
        "location_city": "Lower Saxony",
        "location_state": "Cloppenburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "52.908333",
        "longitude": "8.040556",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3051",
                "runway_width": "105",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3399,
            "s_seats": 1781,
            "j_seats": 1118,
            "f_seats": 122,
            "r_seats": 78,
            "cargo_demand": 96985
        }
    },
    {
        "iata": "VAD",
        "icao": "KVAD",
        "airportName": "Moody Air Force Base",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Valdosta",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.96780014",
        "longitude": "-83.19300079",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18L/36R",
                "runway_length": "9300",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 50225,
            "s_seats": 30774,
            "j_seats": 46889,
            "f_seats": 7166,
            "r_seats": 5867,
            "cargo_demand": 116571
        }
    },
    {
        "iata": "VAF",
        "icao": "LFLU",
        "airportName": "Valence-Chabeuil Airport",
        "location_country": "France",
        "location_city": "Rhone-Alpes",
        "location_state": "Valence",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.9216",
        "longitude": "4.9699",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01L/19R",
                "runway_length": "3914",
                "runway_width": "164",
                "runway_material": "GRASS"
            },
            {
                "runway_name": "01R/19L",
                "runway_length": "1322",
                "runway_width": "196",
                "runway_material": "GRASS"
            }
        ],
        "demand": {
            "y_seats": 120450,
            "s_seats": 32940,
            "j_seats": 31987,
            "f_seats": 7828,
            "r_seats": 6058,
            "cargo_demand": 94389
        }
    },
    {
        "iata": "VAG",
        "icao": "SBVG",
        "airportName": "Major Brigadeiro Trompowsky Airport",
        "location_country": "Brazil",
        "location_city": "Minas Gerais",
        "location_state": "Varginha",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.59009933",
        "longitude": "-45.47330093",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50264,
            "s_seats": 48598,
            "j_seats": 5706,
            "f_seats": 16718,
            "r_seats": 5143,
            "cargo_demand": 177297
        }
    },
    {
        "iata": "VAI",
        "icao": "AYVN",
        "airportName": "Vanimo Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Vanimo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.6926",
        "longitude": "141.3028",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5775",
                "runway_width": "85",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189768,
            "s_seats": 36816,
            "j_seats": 12785,
            "f_seats": 6489,
            "r_seats": 3128,
            "cargo_demand": 340549
        }
    },
    {
        "iata": "VAK",
        "icao": "PAVA",
        "airportName": "Chevak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Chevak",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "61.5409",
        "longitude": "-165.6005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3200",
                "runway_width": "75",
                "runway_material": "GRVL-G"
            },
            {
                "runway_name": "18W/36W",
                "runway_length": "2000",
                "runway_width": "400",
                "runway_material": "WATER-E"
            }
        ],
        "demand": {
            "y_seats": 3758,
            "s_seats": 2887,
            "j_seats": 1817,
            "f_seats": 17,
            "r_seats": 152,
            "cargo_demand": 98283
        }
    },
    {
        "iata": "VAL",
        "icao": "SNVB",
        "airportName": "ValeniCa Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "ValeniCa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-13.2965",
        "longitude": "-38.992401",
        "region": "SA",
        "runway": [
            {
                "runway_name": "4/22",
                "runway_length": "5906",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1524,
            "s_seats": 1624,
            "j_seats": 1000,
            "f_seats": 4,
            "r_seats": 115,
            "cargo_demand": 84009
        }
    },
    {
        "iata": "VAM",
        "icao": "VRMV",
        "airportName": "Villa International Airport",
        "location_country": "Maldives",
        "location_city": "Maamigili",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.470555556",
        "longitude": "72.83583333",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5905",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148711,
            "s_seats": 30992,
            "j_seats": 11839,
            "f_seats": 8458,
            "r_seats": 4067,
            "cargo_demand": 196446
        }
    },
    {
        "iata": "VAN",
        "icao": "LTCI",
        "airportName": "Van Ferit Melen Airport",
        "location_country": "Turkey",
        "location_city": "Van",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.46820068",
        "longitude": "43.33229828",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9022",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110452,
            "s_seats": 38356,
            "j_seats": 29110,
            "f_seats": 13637,
            "r_seats": 9898,
            "cargo_demand": 235741
        }
    },
    {
        "iata": "VAO",
        "icao": "AGGV",
        "airportName": "Suavanao Airport",
        "location_country": "Solomon Islands",
        "location_city": "Suavanao",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-7.585559845",
        "longitude": "158.7310028",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2789",
                "runway_width": "79",
                "runway_material": "Sand grass"
            }
        ],
        "demand": {
            "y_seats": 3352,
            "s_seats": 2696,
            "j_seats": 1822,
            "f_seats": 203,
            "r_seats": 137,
            "cargo_demand": 52261
        }
    },
    {
        "iata": "VAP",
        "icao": "SCRD",
        "airportName": "Rodelillo Airfield",
        "location_country": "Chile",
        "location_city": "Valparaiso",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-33.06809998",
        "longitude": "-71.55750275",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "2789",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1198,
            "s_seats": 2052,
            "j_seats": 1954,
            "f_seats": 494,
            "r_seats": 109,
            "cargo_demand": 1920
        }
    },
    {
        "iata": "VAR",
        "icao": "LBWN",
        "airportName": "Varna Airport",
        "location_country": "Bulgaria",
        "location_city": "Varna",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.232101",
        "longitude": "27.8251",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8258",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 137554,
            "s_seats": 72080,
            "j_seats": 101788,
            "f_seats": 26272,
            "r_seats": 10000,
            "cargo_demand": 2555661
        }
    },
    {
        "iata": "VAS",
        "icao": "LTAR",
        "airportName": "Sivas Airport",
        "location_country": "Turkey",
        "location_city": "Sivas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "39.813801",
        "longitude": "36.9035",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "12503",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1427,
            "s_seats": 1871,
            "j_seats": 1832,
            "f_seats": 55,
            "r_seats": 134,
            "cargo_demand": 70529
        }
    },
    {
        "iata": "VAV",
        "icao": "NFTV",
        "airportName": "Vava'u International Airport",
        "location_country": "Tonga",
        "location_city": "Vava'u",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-18.58530045",
        "longitude": "-173.9620056",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5593",
                "runway_width": "98",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 90308,
            "s_seats": 38546,
            "j_seats": 23228,
            "f_seats": 6172,
            "r_seats": 4727,
            "cargo_demand": 376250
        }
    },
    {
        "iata": "VAW",
        "icao": "ENSS",
        "airportName": "Vardo Airport/c/ Svartnes",
        "location_country": "Norway",
        "location_city": "Vardo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "\" Svartnes\"\"\"",
        "longitude": "70.35540009",
        "region": "42",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3707",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53120,
            "s_seats": 46165,
            "j_seats": 44319,
            "f_seats": 12436,
            "r_seats": 5055,
            "cargo_demand": 176780
        }
    },
    {
        "iata": "VBA",
        "icao": "VYAN",
        "airportName": "Ann Airport",
        "location_country": "Myanmar",
        "location_city": "Ann",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "19.769199",
        "longitude": "94.0261",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "8500",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2613,
            "s_seats": 2475,
            "j_seats": 1260,
            "f_seats": 365,
            "r_seats": 31,
            "cargo_demand": 36616
        }
    },
    {
        "iata": "VBC",
        "icao": "VYCZ",
        "airportName": "Mandalay Chanmyathazi Airport",
        "location_country": "Myanmar",
        "location_city": "Mandalay",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.94050026",
        "longitude": "96.08959961",
        "region": "AS",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3953,
            "s_seats": 1761,
            "j_seats": 1364,
            "f_seats": 67,
            "r_seats": 153,
            "cargo_demand": 10389
        }
    },
    {
        "iata": "VBG",
        "icao": "KVBG",
        "airportName": "Vandenberg Air Force Base",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Lompoc",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.737301",
        "longitude": "-120.584",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "15000",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 89383,
            "s_seats": 38203,
            "j_seats": 28537,
            "f_seats": 11220,
            "r_seats": 3909,
            "cargo_demand": 196694
        }
    },
    {
        "iata": "VBP",
        "icao": "VYBP",
        "airportName": "Bokpyin Airport",
        "location_country": "Myanmar",
        "location_city": "Bokpyin",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "11.1494",
        "longitude": "98.735901",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "10024",
                "runway_width": "102",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3867,
            "s_seats": 2640,
            "j_seats": 1142,
            "f_seats": 119,
            "r_seats": 78,
            "cargo_demand": 14800
        }
    },
    {
        "iata": "VBS",
        "icao": "LIPO",
        "airportName": "Brescia Airport",
        "location_country": "Italy",
        "location_city": "Lombardy",
        "location_state": "Brescia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.428902",
        "longitude": "10.3306",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "9810",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197116,
            "s_seats": 31293,
            "j_seats": 14530,
            "f_seats": 7046,
            "r_seats": 8080,
            "cargo_demand": 216002
        }
    },
    {
        "iata": "VBY",
        "icao": "ESSV",
        "airportName": "Visby Airport",
        "location_country": "Sweden",
        "location_city": "Visby",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "57.66279984",
        "longitude": "18.34620094",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "3611",
                "runway_width": "131",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 84727,
            "s_seats": 35352,
            "j_seats": 35750,
            "f_seats": 9247,
            "r_seats": 4479,
            "cargo_demand": 372919
        }
    },
    {
        "iata": "VCA",
        "icao": "VVCT",
        "airportName": "Can Tho International Airport",
        "location_country": "Vietnam",
        "location_city": "Can Tho",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.08510017",
        "longitude": "105.711998",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7886",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155160,
            "s_seats": 40643,
            "j_seats": 29952,
            "f_seats": 4678,
            "r_seats": 9125,
            "cargo_demand": 470875
        }
    },
    {
        "iata": "VCD",
        "icao": "YVRD",
        "airportName": "Victoria River Downs Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Victoria River Downs Station",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.4021244",
        "longitude": "131.0049744",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3589",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1159,
            "s_seats": 2675,
            "j_seats": 1218,
            "f_seats": 110,
            "r_seats": 62,
            "cargo_demand": 43335
        }
    },
    {
        "iata": "VCE",
        "icao": "LIPZ",
        "airportName": "Venice Marco Polo Airport",
        "location_country": "Italy",
        "location_city": "Veneto",
        "location_state": "Venice",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "45.505299",
        "longitude": "12.3519",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "9121",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "10827",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 224504,
            "s_seats": 78633,
            "j_seats": 156668,
            "f_seats": 41084,
            "r_seats": 10000,
            "cargo_demand": 15862055
        }
    },
    {
        "iata": "VCL",
        "icao": "VVCA",
        "airportName": "Chu Lai International Airport",
        "location_country": "Vietnam",
        "location_city": "Chu Lai",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "15.4033",
        "longitude": "108.706001",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10300",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3145,
            "s_seats": 2412,
            "j_seats": 1366,
            "f_seats": 448,
            "r_seats": 97,
            "cargo_demand": 3494
        }
    },
    {
        "iata": "VCP",
        "icao": "SBKP",
        "airportName": "Viracopos/Campinas International Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Sio Paulo / Campinas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.00740051",
        "longitude": "-47.1344986",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "10630",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115478,
            "s_seats": 41005,
            "j_seats": 56568,
            "f_seats": 19499,
            "r_seats": 6859,
            "cargo_demand": 197805
        }
    },
    {
        "iata": "VCR",
        "icao": "SVCO",
        "airportName": "Carora Airport",
        "location_country": "Venezuela",
        "location_city": "Carora",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.17560291",
        "longitude": "-70.06521606",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4860",
                "runway_width": "135",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 83165,
            "s_seats": 29037,
            "j_seats": 27531,
            "f_seats": 11367,
            "r_seats": 9093,
            "cargo_demand": 155877
        }
    },
    {
        "iata": "VCS",
        "icao": "VVCS",
        "airportName": "Con Dao Airport",
        "location_country": "Vietnam",
        "location_city": "Con Dao Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.73183",
        "longitude": "106.633003",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6046",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 143407,
            "s_seats": 25284,
            "j_seats": 28845,
            "f_seats": 3898,
            "r_seats": 8577,
            "cargo_demand": 301528
        }
    },
    {
        "iata": "VCT",
        "icao": "KVCT",
        "airportName": "Victoria Regional Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Victoria",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "28.8526001",
        "longitude": "-96.91850281",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4200",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "9101",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "4643",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4899",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 163228,
            "s_seats": 46854,
            "j_seats": 37926,
            "f_seats": 7260,
            "r_seats": 3808,
            "cargo_demand": 100610
        }
    },
    {
        "iata": "VCV",
        "icao": "KVCV",
        "airportName": "Southern California Logistics Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Victorville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.59749985",
        "longitude": "-117.3830032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9138",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "17/35",
                "runway_length": "15050",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 1322,
            "s_seats": 2616,
            "j_seats": 1069,
            "f_seats": 0,
            "r_seats": 23,
            "cargo_demand": 45611
        }
    },
    {
        "iata": "VDA",
        "icao": "LLOV",
        "airportName": "Ovda Airport",
        "location_country": "Israel",
        "location_city": "Ovda (Uvda)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "29.9403",
        "longitude": "34.935799",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02L/20R",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "02R/20L",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2337,
            "s_seats": 1876,
            "j_seats": 1413,
            "f_seats": 228,
            "r_seats": 41,
            "cargo_demand": 24996
        }
    },
    {
        "iata": "VDB",
        "icao": "ENFG",
        "airportName": "Fagernes Airport",
        "location_country": "Norway",
        "location_city": "Fagernes",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "\" Leirin\"\"\"",
        "longitude": "61.015598",
        "region": "2697",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6722",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182633,
            "s_seats": 39150,
            "j_seats": 23342,
            "f_seats": 1036,
            "r_seats": 7364,
            "cargo_demand": 69182
        }
    },
    {
        "iata": "VDC",
        "icao": "SSVC",
        "airportName": "Glauber Rocha Airport",
        "location_country": "Brazil",
        "location_city": "Bahia",
        "location_state": "Vitoria da Conquista",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.50972176",
        "longitude": "-50.91833115",
        "region": "SA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3543",
                "runway_width": "98",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 1014,
            "s_seats": 2460,
            "j_seats": 1505,
            "f_seats": 28,
            "r_seats": 31,
            "cargo_demand": 54707
        }
    },
    {
        "iata": "VDE",
        "icao": "GCHI",
        "airportName": "El Hierro Airport",
        "location_country": "Spain",
        "location_city": "Canary Islands",
        "location_state": "Valverde",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.81480026",
        "longitude": "-17.88710022",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4101",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100952,
            "s_seats": 39481,
            "j_seats": 17764,
            "f_seats": 15736,
            "r_seats": 3157,
            "cargo_demand": 305789
        }
    },
    {
        "iata": "VDI",
        "icao": "KVDI",
        "airportName": "Vidalia Regional Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Vidalia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.19269943",
        "longitude": "-82.37120056",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6003",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2046,
            "s_seats": 2175,
            "j_seats": 1736,
            "f_seats": 251,
            "r_seats": 191,
            "cargo_demand": 80407
        }
    },
    {
        "iata": "VDM",
        "icao": "SAVV",
        "airportName": "Gobernador Edgardo Castello Airport",
        "location_country": "Argentina",
        "location_city": "Rio Negro",
        "location_state": "Viedma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-40.8692",
        "longitude": "-63.0004",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8366",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "2/20",
                "runway_length": "4954",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190262,
            "s_seats": 33059,
            "j_seats": 12267,
            "f_seats": 16670,
            "r_seats": 9375,
            "cargo_demand": 148943
        }
    },
    {
        "iata": "VDO",
        "icao": "VVVD",
        "airportName": "Van Don International Airport",
        "location_country": "Vietnam",
        "location_city": "Quang Ninh (Qua\u00ba\u00a3ng Ninh)",
        "location_state": "Ha Long",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.117778",
        "longitude": "107.414167",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "CONC"
            }
        ],
        "demand": {
            "y_seats": 158675,
            "s_seats": 30310,
            "j_seats": 39783,
            "f_seats": 11683,
            "r_seats": 4220,
            "cargo_demand": 291644
        }
    },
    {
        "iata": "VDP",
        "icao": "SVVP",
        "airportName": "Valle de la Pascua Airport",
        "location_country": "Venezuela",
        "location_city": "Valle de la Pascua",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.222027779",
        "longitude": "-65.99358368",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4921",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113937,
            "s_seats": 31646,
            "j_seats": 21256,
            "f_seats": 11011,
            "r_seats": 5692,
            "cargo_demand": 244621
        }
    },
    {
        "iata": "VDR",
        "icao": "SAOD",
        "airportName": "Villa Dolores Airport",
        "location_country": "Argentina",
        "location_city": "Cordoba",
        "location_state": "Villa Dolores",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.94519997",
        "longitude": "-65.14630127",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3122",
                "runway_width": "131",
                "runway_material": "GRE"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4593",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146393,
            "s_seats": 44505,
            "j_seats": 54855,
            "f_seats": 3866,
            "r_seats": 6182,
            "cargo_demand": 326740
        }
    },
    {
        "iata": "VDS",
        "icao": "ENVD",
        "airportName": "Vadso Airport",
        "location_country": "Norway",
        "location_city": "Vadso",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "70.06529999",
        "longitude": "29.84469986",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3271",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108789,
            "s_seats": 43444,
            "j_seats": 45898,
            "f_seats": 4025,
            "r_seats": 9239,
            "cargo_demand": 52970
        }
    },
    {
        "iata": "VDZ",
        "icao": "PAVD",
        "airportName": "Valdez Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Valdez",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.1339",
        "longitude": "-146.248001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6508",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58067,
            "s_seats": 44597,
            "j_seats": 37253,
            "f_seats": 3744,
            "r_seats": 5034,
            "cargo_demand": 302755
        }
    },
    {
        "iata": "VEE",
        "icao": "PAVE",
        "airportName": "Venetie Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Venetie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "67.00869751",
        "longitude": "-146.3659973",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "GRVL-DIRT-E"
            }
        ],
        "demand": {
            "y_seats": 1646,
            "s_seats": 1671,
            "j_seats": 1060,
            "f_seats": 319,
            "r_seats": 156,
            "cargo_demand": 90963
        }
    },
    {
        "iata": "VEL",
        "icao": "KVEL",
        "airportName": "Vernal Regional Airport",
        "location_country": "United States",
        "location_city": "Utah",
        "location_state": "Vernal",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.4408989",
        "longitude": "-109.5100021",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181779,
            "s_seats": 27443,
            "j_seats": 42609,
            "f_seats": 4803,
            "r_seats": 8328,
            "cargo_demand": 496279
        }
    },
    {
        "iata": "VER",
        "icao": "MMVR",
        "airportName": "General Heriberto Jara International Airport",
        "location_country": "Mexico",
        "location_city": "Veracruz",
        "location_state": "Veracruz",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.14590073",
        "longitude": "-96.18730164",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4997",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "19/01",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156409,
            "s_seats": 38046,
            "j_seats": 27428,
            "f_seats": 13848,
            "r_seats": 6827,
            "cargo_demand": 349979
        }
    },
    {
        "iata": "VEY",
        "icao": "BIVM",
        "airportName": "Vestmannaeyjar Airport",
        "location_country": "Iceland",
        "location_city": "Vestmannaeyjar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "63.42430115",
        "longitude": "-20.27890015",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3806",
                "runway_width": "148",
                "runway_material": "BIT"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3934",
                "runway_width": "148",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 51534,
            "s_seats": 32018,
            "j_seats": 52128,
            "f_seats": 12277,
            "r_seats": 5831,
            "cargo_demand": 490519
        }
    },
    {
        "iata": "VFA",
        "icao": "FVFA",
        "airportName": "Victoria Falls Airport",
        "location_country": "Zimbabwe",
        "location_city": "Victoria Falls",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-18.09589958",
        "longitude": "25.8390007",
        "region": "AF",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7708",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128489,
            "s_seats": 32588,
            "j_seats": 21299,
            "f_seats": 2196,
            "r_seats": 8036,
            "cargo_demand": 393013
        }
    },
    {
        "iata": "VGA",
        "icao": "VOBZ",
        "airportName": "Vijayawada Airport",
        "location_country": "India",
        "location_city": "Andhra Pradesh",
        "location_state": "Vijayawada",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.530399",
        "longitude": "80.796799",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7900",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74906,
            "s_seats": 27528,
            "j_seats": 33939,
            "f_seats": 5335,
            "r_seats": 3011,
            "cargo_demand": 181054
        }
    },
    {
        "iata": "VGO",
        "icao": "LEVX",
        "airportName": "Vigo-Peinador Airport",
        "location_country": "Spain",
        "location_city": "Galicia",
        "location_state": "Vigo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.23180008",
        "longitude": "-8.62677002",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69519,
            "s_seats": 31823,
            "j_seats": 51265,
            "f_seats": 6123,
            "r_seats": 9922,
            "cargo_demand": 494591
        }
    },
    {
        "iata": "VGT",
        "icao": "KVGT",
        "airportName": "North Las Vegas Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Las Vegas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.210701",
        "longitude": "-115.194",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "5004",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "4202",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 351353,
            "s_seats": 84074,
            "j_seats": 111956,
            "f_seats": 37505,
            "r_seats": 10000,
            "cargo_demand": 1333651
        }
    },
    {
        "iata": "VGZ",
        "icao": "SKVG",
        "airportName": "Villa Garzon Airport",
        "location_country": "Colombia",
        "location_city": "Villagarzon",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "0.978767",
        "longitude": "-76.6056",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "4320",
                "runway_width": "40",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1716,
            "s_seats": 2945,
            "j_seats": 1196,
            "f_seats": 416,
            "r_seats": 178,
            "cargo_demand": 66150
        }
    },
    {
        "iata": "VHC",
        "icao": "FNSA",
        "airportName": "Henrique de Carvalho Airport",
        "location_country": "Angola",
        "location_city": "Saurimo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-9.689069748",
        "longitude": "20.43190002",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79321,
            "s_seats": 32633,
            "j_seats": 7399,
            "f_seats": 18666,
            "r_seats": 9381,
            "cargo_demand": 369210
        }
    },
    {
        "iata": "VHM",
        "icao": "ESNV",
        "airportName": "Vilhelmina Airport",
        "location_country": "Sweden",
        "location_city": "Vilhelmina",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.579102",
        "longitude": "16.833599",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4928",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123400,
            "s_seats": 25933,
            "j_seats": 56723,
            "f_seats": 14978,
            "r_seats": 7728,
            "cargo_demand": 74409
        }
    },
    {
        "iata": "VHN",
        "icao": "KVHN",
        "airportName": "Culberson County Airport",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Van Horn",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "31.05780029",
        "longitude": "-104.7839966",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "5353",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3435,
            "s_seats": 2905,
            "j_seats": 1328,
            "f_seats": 220,
            "r_seats": 35,
            "cargo_demand": 24951
        }
    },
    {
        "iata": "VHY",
        "icao": "LFLV",
        "airportName": "Vichy - Charmeil Airport",
        "location_country": "France",
        "location_city": "Auvergne",
        "location_state": "Vichy",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.16970062",
        "longitude": "3.403739929",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127852,
            "s_seats": 35624,
            "j_seats": 8389,
            "f_seats": 3053,
            "r_seats": 6859,
            "cargo_demand": 415865
        }
    },
    {
        "iata": "VIA",
        "icao": "SSVI",
        "airportName": "Angelo Ponzoni Municipal Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "Videira",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.99970055",
        "longitude": "-51.14189911",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4790",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2747,
            "s_seats": 2537,
            "j_seats": 1264,
            "f_seats": 140,
            "r_seats": 49,
            "cargo_demand": 48209
        }
    },
    {
        "iata": "VIE",
        "icao": "LOWW",
        "airportName": "Vienna International Airport",
        "location_country": "Austria",
        "location_city": "Vienna",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "48.110298",
        "longitude": "16.5697",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "11811",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 248948,
            "s_seats": 74434,
            "j_seats": 36960,
            "f_seats": 32379,
            "r_seats": 10000,
            "cargo_demand": 27094191
        }
    },
    {
        "iata": "VIG",
        "icao": "SVVG",
        "airportName": "Juan Pablo Perez Alfonzo Airport",
        "location_country": "Venezuela",
        "location_city": "El Vigia",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "8.624139",
        "longitude": "-71.672668",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "10645",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 161622,
            "s_seats": 39524,
            "j_seats": 21159,
            "f_seats": 15781,
            "r_seats": 9498,
            "cargo_demand": 341543
        }
    },
    {
        "iata": "VIH",
        "icao": "KVIH",
        "airportName": "Rolla National Airport",
        "location_country": "United States",
        "location_city": "Missouri",
        "location_state": "Rolla / Vichy",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.12739944",
        "longitude": "-91.76950073",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "U"
            }
        ],
        "demand": {
            "y_seats": 3174,
            "s_seats": 2699,
            "j_seats": 1014,
            "f_seats": 150,
            "r_seats": 29,
            "cargo_demand": 7300
        }
    },
    {
        "iata": "VII",
        "icao": "VVVH",
        "airportName": "Vinh International Airport",
        "location_country": "Vietnam",
        "location_city": "Vinh",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "18.73760033",
        "longitude": "105.6709976",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "7875",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168379,
            "s_seats": 48653,
            "j_seats": 10384,
            "f_seats": 7040,
            "r_seats": 9401,
            "cargo_demand": 246648
        }
    },
    {
        "iata": "VIJ",
        "icao": "TUPW",
        "airportName": "Virgin Gorda Airport",
        "location_country": "British Overseas Territory of Virgin Islands",
        "location_city": "Virgin Gorda",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.44639969",
        "longitude": "-64.42749786",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3100",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 1766,
            "s_seats": 2687,
            "j_seats": 1059,
            "f_seats": 12,
            "r_seats": 35,
            "cargo_demand": 41772
        }
    },
    {
        "iata": "VIL",
        "icao": "GMMH",
        "airportName": "Dakhla Airport",
        "location_country": "Morocco",
        "location_city": "Dakhla",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.7183",
        "longitude": "-15.932",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9842",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127995,
            "s_seats": 42800,
            "j_seats": 48018,
            "f_seats": 5342,
            "r_seats": 4981,
            "cargo_demand": 89616
        }
    },
    {
        "iata": "VIN",
        "icao": "UKWW",
        "airportName": "Havryshivka Vinnytsia International Airport",
        "location_country": "Ukraine",
        "location_city": "Vinnytsia",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "49.242531",
        "longitude": "28.613778",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8175",
                "runway_width": "117",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 2493,
            "s_seats": 2884,
            "j_seats": 1046,
            "f_seats": 169,
            "r_seats": 24,
            "cargo_demand": 96528
        }
    },
    {
        "iata": "VIR",
        "icao": "FAVG",
        "airportName": "Virginia Airport",
        "location_country": "South Africa",
        "location_city": "Durban",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-29.77059937",
        "longitude": "31.0583992",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3035",
                "runway_width": "72",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114212,
            "s_seats": 35837,
            "j_seats": 10361,
            "f_seats": 15721,
            "r_seats": 3265,
            "cargo_demand": 71274
        }
    },
    {
        "iata": "VIS",
        "icao": "KVIS",
        "airportName": "Visalia Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Visalia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "36.318699",
        "longitude": "-119.392998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6559",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 216804,
            "s_seats": 75674,
            "j_seats": 29346,
            "f_seats": 36270,
            "r_seats": 10000,
            "cargo_demand": 24807703
        }
    },
    {
        "iata": "VIT",
        "icao": "LEVT",
        "airportName": "Vitoria Airport",
        "location_country": "Spain",
        "location_city": "Basque Country",
        "location_state": "Vitoria-Gasteiz",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.88280106",
        "longitude": "-2.7244699",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 147986,
            "s_seats": 26890,
            "j_seats": 17972,
            "f_seats": 2325,
            "r_seats": 6224,
            "cargo_demand": 65772
        }
    },
    {
        "iata": "VIX",
        "icao": "SBVT",
        "airportName": "Eurico de Aguiar Salles Airport",
        "location_country": "Brazil",
        "location_city": "Espirito Santo",
        "location_state": "Vitoria",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-20.258057",
        "longitude": "-40.286388",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6752",
                "runway_width": "148",
                "runway_material": "ASPH"
            },
            {
                "runway_name": "06/24",
                "runway_length": "5741",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123517,
            "s_seats": 31831,
            "j_seats": 43909,
            "f_seats": 7042,
            "r_seats": 6740,
            "cargo_demand": 222560
        }
    },
    {
        "iata": "VIY",
        "icao": "LFPV",
        "airportName": "Velizy - Villacoublay Air Base",
        "location_country": "France",
        "location_city": "Ile-de-France",
        "location_state": "Paris",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.7741667",
        "longitude": "2.1916667",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5948",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168619,
            "s_seats": 27245,
            "j_seats": 17533,
            "f_seats": 17990,
            "r_seats": 9899,
            "cargo_demand": 318541
        }
    },
    {
        "iata": "VJI",
        "icao": "KVJI",
        "airportName": "Virginia Highlands Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Abingdon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.687099",
        "longitude": "-82.033302",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4471",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1022,
            "s_seats": 2081,
            "j_seats": 1344,
            "f_seats": 489,
            "r_seats": 178,
            "cargo_demand": 98962
        }
    },
    {
        "iata": "VKG",
        "icao": "VVRG",
        "airportName": "Rach Gia Airport",
        "location_country": "Vietnam",
        "location_city": "Rach Gia (Ra\u00ba\u00a1ch Gia)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.958029972",
        "longitude": "105.1323795",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182629,
            "s_seats": 26231,
            "j_seats": 55751,
            "f_seats": 8809,
            "r_seats": 8455,
            "cargo_demand": 455802
        }
    },
    {
        "iata": "VKO",
        "icao": "UUWW",
        "airportName": "Vnukovo International Airport",
        "location_country": "Russia",
        "location_city": "Moscow",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "55.59149933",
        "longitude": "37.26150131",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10039",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "11483",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 438980,
            "s_seats": 82416,
            "j_seats": 50404,
            "f_seats": 36768,
            "r_seats": 10000,
            "cargo_demand": 26596267
        }
    },
    {
        "iata": "VKS",
        "icao": "KVKS",
        "airportName": "Vicksburg Municipal Airport",
        "location_country": "United States",
        "location_city": "Mississippi",
        "location_state": "Vicksburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.23929977",
        "longitude": "-90.92839813",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1298,
            "s_seats": 1763,
            "j_seats": 1884,
            "f_seats": 498,
            "r_seats": 28,
            "cargo_demand": 54950
        }
    },
    {
        "iata": "VLA",
        "icao": "KVLA",
        "airportName": "Vandalia Municipal Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Vandalia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "38.99150085",
        "longitude": "-89.16619873",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3001",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3752",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2407,
            "s_seats": 1646,
            "j_seats": 1907,
            "f_seats": 140,
            "r_seats": 32,
            "cargo_demand": 20687
        }
    },
    {
        "iata": "VLC",
        "icao": "LEVC",
        "airportName": "Valencia Airport",
        "location_country": "Spain",
        "location_city": "Valencian Community",
        "location_state": "Valencia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.4893",
        "longitude": "-0.481625",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5394",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8858",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123426,
            "s_seats": 34023,
            "j_seats": 49412,
            "f_seats": 2166,
            "r_seats": 7617,
            "cargo_demand": 272167
        }
    },
    {
        "iata": "VLD",
        "icao": "KVLD",
        "airportName": "Valdosta Regional Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Valdosta",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.78249931",
        "longitude": "-83.27670288",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5598",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3636",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "8002",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162966,
            "s_seats": 40596,
            "j_seats": 25770,
            "f_seats": 16270,
            "r_seats": 6482,
            "cargo_demand": 63815
        }
    },
    {
        "iata": "VLG",
        "icao": "SAZV",
        "airportName": "Villa Gesell Airport",
        "location_country": "Argentina",
        "location_city": "Buenos Aires",
        "location_state": "Villa Gesell",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-37.2354",
        "longitude": "-57.0292",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5709",
                "runway_width": "92",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181624,
            "s_seats": 45319,
            "j_seats": 44798,
            "f_seats": 2815,
            "r_seats": 7622,
            "cargo_demand": 271235
        }
    },
    {
        "iata": "VLI",
        "icao": "NVVV",
        "airportName": "Bauerfield International Airport",
        "location_country": "Vanuatu",
        "location_city": "Port Vila",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-17.699301",
        "longitude": "168.320007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "3310",
                "runway_width": "105",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 239192,
            "s_seats": 79288,
            "j_seats": 64052,
            "f_seats": 45955,
            "r_seats": 10000,
            "cargo_demand": 25920072
        }
    },
    {
        "iata": "VLL",
        "icao": "LEVD",
        "airportName": "Valladolid Airport",
        "location_country": "Spain",
        "location_city": "Castile and Leon",
        "location_state": "Valladolid",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.70610046",
        "longitude": "-4.851940155",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9843",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2976",
                "runway_width": "197",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 135964,
            "s_seats": 36788,
            "j_seats": 59526,
            "f_seats": 18206,
            "r_seats": 5497,
            "cargo_demand": 424078
        }
    },
    {
        "iata": "VLM",
        "icao": "SLVM",
        "airportName": "Lieutenant Colonel Rafael Pabon Airport",
        "location_country": "Bolivia",
        "location_city": "Villamontes",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.25519943",
        "longitude": "-63.4056015",
        "region": "SA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4918",
                "runway_width": "115",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131554,
            "s_seats": 28484,
            "j_seats": 17095,
            "f_seats": 11258,
            "r_seats": 5992,
            "cargo_demand": 461226
        }
    },
    {
        "iata": "VLN",
        "icao": "SVVA",
        "airportName": "Arturo Michelena International Airport",
        "location_country": "Venezuela",
        "location_city": "Valencia",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.14973259",
        "longitude": "-67.92839813",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9842",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101299,
            "s_seats": 32861,
            "j_seats": 52311,
            "f_seats": 5104,
            "r_seats": 8979,
            "cargo_demand": 102877
        }
    },
    {
        "iata": "VLR",
        "icao": "SCLL",
        "airportName": "Vallenar Airport",
        "location_country": "Chile",
        "location_city": "Vallenar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-28.59639931",
        "longitude": "-70.7559967",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4624",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90076,
            "s_seats": 35011,
            "j_seats": 59545,
            "f_seats": 15073,
            "r_seats": 5785,
            "cargo_demand": 101658
        }
    },
    {
        "iata": "VLS",
        "icao": "NVSV",
        "airportName": "Valesdir Airport",
        "location_country": "Vanuatu",
        "location_city": "Valesdir",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.79610062",
        "longitude": "168.177002",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2625",
                "runway_width": "98",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3565,
            "s_seats": 2581,
            "j_seats": 1940,
            "f_seats": 376,
            "r_seats": 41,
            "cargo_demand": 3820
        }
    },
    {
        "iata": "VLV",
        "icao": "SVVL",
        "airportName": "Dr. Antonio Nicolas Briceno Airport",
        "location_country": "Venezuela",
        "location_city": "Valera",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.340477943",
        "longitude": "-70.58406067",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6791",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194196,
            "s_seats": 34104,
            "j_seats": 32209,
            "f_seats": 18947,
            "r_seats": 5814,
            "cargo_demand": 261445
        }
    },
    {
        "iata": "VLY",
        "icao": "EGOV",
        "airportName": "Anglesey Airport / RAF Valley",
        "location_country": "United Kingdom",
        "location_city": "Wales",
        "location_state": "Anglesey",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.24810028",
        "longitude": "-4.535339832",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5377",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "2200",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7513",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 162126,
            "s_seats": 38793,
            "j_seats": 47542,
            "f_seats": 7750,
            "r_seats": 8646,
            "cargo_demand": 283673
        }
    },
    {
        "iata": "VME",
        "icao": "SAOR",
        "airportName": "Villa Reynolds Airport",
        "location_country": "Argentina",
        "location_city": "San Luis",
        "location_state": "Villa Mercedes",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.72990036",
        "longitude": "-65.38739777",
        "region": "SA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "6/24",
                "runway_length": "7874",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191707,
            "s_seats": 43732,
            "j_seats": 35336,
            "f_seats": 19547,
            "r_seats": 8447,
            "cargo_demand": 365710
        }
    },
    {
        "iata": "VNC",
        "icao": "KVNC",
        "airportName": "Venice Municipal Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Venice",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "27.07159996",
        "longitude": "-82.44029999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4999",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2531,
            "s_seats": 2794,
            "j_seats": 1883,
            "f_seats": 105,
            "r_seats": 153,
            "cargo_demand": 32338
        }
    },
    {
        "iata": "VNE",
        "icao": "LFRV",
        "airportName": "Meucon Airport",
        "location_country": "France",
        "location_city": "Brittany",
        "location_state": "Vannes",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.72330093",
        "longitude": "-2.71855998",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5020",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3363",
                "runway_width": "197",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 117146,
            "s_seats": 42445,
            "j_seats": 45944,
            "f_seats": 11873,
            "r_seats": 9538,
            "cargo_demand": 117316
        }
    },
    {
        "iata": "VNO",
        "icao": "EYVI",
        "airportName": "Vilnius Airport",
        "location_country": "Lithuania",
        "location_city": "Vilnius",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "54.634102",
        "longitude": "25.285801",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8251",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118416,
            "s_seats": 72993,
            "j_seats": 78985,
            "f_seats": 24487,
            "r_seats": 10000,
            "cargo_demand": 2560610
        }
    },
    {
        "iata": "VNT",
        "icao": "EVVA",
        "airportName": "Ventspils International Airport",
        "location_country": "Latvia",
        "location_city": "Ventspils",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "57.35779953",
        "longitude": "21.5442009",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4259",
                "runway_width": "105",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 163593,
            "s_seats": 25648,
            "j_seats": 7216,
            "f_seats": 11064,
            "r_seats": 5684,
            "cargo_demand": 66102
        }
    },
    {
        "iata": "VNX",
        "icao": "FQVL",
        "airportName": "Vilankulo Airport",
        "location_country": "Mozambique",
        "location_city": "Vilankulo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.01840019",
        "longitude": "35.31330109",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2461",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4823",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110826,
            "s_seats": 27545,
            "j_seats": 50247,
            "f_seats": 13568,
            "r_seats": 9831,
            "cargo_demand": 406110
        }
    },
    {
        "iata": "VNY",
        "icao": "KVNY",
        "airportName": "Van Nuys Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Los Angeles",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.20980072",
        "longitude": "-118.4899979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16L/34R",
                "runway_length": "4011",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16R/34L",
                "runway_length": "8001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 95838,
            "s_seats": 38701,
            "j_seats": 40356,
            "f_seats": 19374,
            "r_seats": 5142,
            "cargo_demand": 493874
        }
    },
    {
        "iata": "VOD",
        "icao": "LKVO",
        "airportName": "Vodochody Airport",
        "location_country": "Czech Republic",
        "location_city": "Prague",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.216599",
        "longitude": "14.3958",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8203",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5905",
                "runway_width": "164",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 77993,
            "s_seats": 47020,
            "j_seats": 30743,
            "f_seats": 9290,
            "r_seats": 9170,
            "cargo_demand": 179628
        }
    },
    {
        "iata": "VOG",
        "icao": "URWW",
        "airportName": "Volgograd International Airport",
        "location_country": "Russia",
        "location_city": "Volgograd Oblast",
        "location_state": "Volgograd",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.78250122",
        "longitude": "44.34550095",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9186",
                "runway_width": "",
                "runway_material": "CON"
            },
            {
                "runway_name": "08/26",
                "runway_length": "1595",
                "runway_width": "350",
                "runway_material": "GRS"
            },
            {
                "runway_name": "11/29",
                "runway_length": "8101",
                "runway_width": "161",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "5575",
                "runway_width": "330",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 190837,
            "s_seats": 45745,
            "j_seats": 25088,
            "f_seats": 9911,
            "r_seats": 6244,
            "cargo_demand": 78238
        }
    },
    {
        "iata": "VOH",
        "icao": "FMNV",
        "airportName": "Vohemar Airport",
        "location_country": "Madagascar",
        "location_city": "Vohemar (Vohimarina)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-13.3758",
        "longitude": "50.0028",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4236",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55584,
            "s_seats": 30054,
            "j_seats": 27789,
            "f_seats": 17847,
            "r_seats": 9814,
            "cargo_demand": 404455
        }
    },
    {
        "iata": "VOK",
        "icao": "KVOK",
        "airportName": "Volk Field Air National Guard Base",
        "location_country": "United States",
        "location_city": "Wisconsin",
        "location_state": "Camp Douglas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.93899918",
        "longitude": "-90.25340271",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 197918,
            "s_seats": 33604,
            "j_seats": 52394,
            "f_seats": 17168,
            "r_seats": 8813,
            "cargo_demand": 225386
        }
    },
    {
        "iata": "VOL",
        "icao": "LGBL",
        "airportName": "Nea Anchialos National Airport",
        "location_country": "Greece",
        "location_city": "Volos",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.219601",
        "longitude": "22.7943",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "9052",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 60835,
            "s_seats": 44476,
            "j_seats": 39878,
            "f_seats": 19648,
            "r_seats": 6867,
            "cargo_demand": 160547
        }
    },
    {
        "iata": "VOT",
        "icao": "SDVG",
        "airportName": "Votuporanga Airport",
        "location_country": "Brazil",
        "location_city": "Sio Paulo",
        "location_state": "Votuporanga",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.46319962",
        "longitude": "-50.00450134",
        "region": "SA",
        "runway": [
            {
                "runway_name": "5/23",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1046,
            "s_seats": 1918,
            "j_seats": 1926,
            "f_seats": 265,
            "r_seats": 103,
            "cargo_demand": 44179
        }
    },
    {
        "iata": "VOZ",
        "icao": "UUOO",
        "airportName": "Voronezh International Airport",
        "location_country": "Russia",
        "location_city": "Voronezh Oblast",
        "location_state": "Voronezh",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.81420135",
        "longitude": "39.229599",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "7546",
                "runway_width": "161",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 60444,
            "s_seats": 33556,
            "j_seats": 55055,
            "f_seats": 8457,
            "r_seats": 5011,
            "cargo_demand": 264265
        }
    },
    {
        "iata": "VPE",
        "icao": "FNGI",
        "airportName": "Ondjiva Pereira Airport",
        "location_country": "Angola",
        "location_city": "Ondjiva",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-17.0435009",
        "longitude": "15.68379974",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "10640",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160517,
            "s_seats": 43403,
            "j_seats": 50746,
            "f_seats": 8915,
            "r_seats": 5345,
            "cargo_demand": 106609
        }
    },
    {
        "iata": "VPN",
        "icao": "BIVO",
        "airportName": "Vopnafjor\u00f0ur Airport",
        "location_country": "Iceland",
        "location_city": "Vopnafjor\u00f0ur",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.72059631",
        "longitude": "-14.85060024",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3061",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 3111,
            "s_seats": 1955,
            "j_seats": 1130,
            "f_seats": 466,
            "r_seats": 63,
            "cargo_demand": 43453
        }
    },
    {
        "iata": "VPS",
        "icao": "KVPS",
        "airportName": "Destin-Fort Walton Beach Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Destin / Fort Walton Beach",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "30.4832",
        "longitude": "-86.525398",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "10012",
                "runway_width": "300",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "12005",
                "runway_width": "300",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190851,
            "s_seats": 78445,
            "j_seats": 122400,
            "f_seats": 68642,
            "r_seats": 10000,
            "cargo_demand": 4084894
        }
    },
    {
        "iata": "VPY",
        "icao": "FQCH",
        "airportName": "Chimoio Airport",
        "location_country": "Mozambique",
        "location_city": "Chimoio",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.15130043",
        "longitude": "33.42900085",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "7874",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182904,
            "s_seats": 33506,
            "j_seats": 39229,
            "f_seats": 6888,
            "r_seats": 5060,
            "cargo_demand": 158144
        }
    },
    {
        "iata": "VPZ",
        "icao": "KVPZ",
        "airportName": "Porter County Regional Airport",
        "location_country": "United States",
        "location_city": "Indiana",
        "location_state": "Valparaiso",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.45399857",
        "longitude": "-87.00710297",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90860,
            "s_seats": 44838,
            "j_seats": 58351,
            "f_seats": 19243,
            "r_seats": 9610,
            "cargo_demand": 128443
        }
    },
    {
        "iata": "VQQ",
        "icao": "KVQQ",
        "airportName": "Cecil Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Jacksonville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "30.21870041",
        "longitude": "-81.87670136",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "8002",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "8003",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18L/36R",
                "runway_length": "12504",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18R/36L",
                "runway_length": "8003",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 1827,
            "s_seats": 1841,
            "j_seats": 1221,
            "f_seats": 261,
            "r_seats": 179,
            "cargo_demand": 49470
        }
    },
    {
        "iata": "VQS",
        "icao": "TJVQ",
        "airportName": "Antonio Rivera Rodriguez Airport",
        "location_country": "United States",
        "location_city": "Puerto Rico",
        "location_state": "Vieques",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "18.13479996",
        "longitude": "-65.49359894",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4301",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1612,
            "s_seats": 2349,
            "j_seats": 1590,
            "f_seats": 29,
            "r_seats": 129,
            "cargo_demand": 33973
        }
    },
    {
        "iata": "VRA",
        "icao": "MUVR",
        "airportName": "Juan Gualberto Gomez Airport",
        "location_country": "Cuba",
        "location_city": "Varadero",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "23.03440094",
        "longitude": "-81.43530273",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "11490",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 460740,
            "s_seats": 89768,
            "j_seats": 78591,
            "f_seats": 20051,
            "r_seats": 10000,
            "cargo_demand": 16949727
        }
    },
    {
        "iata": "VRB",
        "icao": "KVRB",
        "airportName": "Vero Beach Regional Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Vero Beach",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.6556",
        "longitude": "-80.417901",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4974",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12L/30R",
                "runway_length": "3504",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "7314",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 53724,
            "s_seats": 42836,
            "j_seats": 50507,
            "f_seats": 8393,
            "r_seats": 4694,
            "cargo_demand": 300878
        }
    },
    {
        "iata": "VRC",
        "icao": "RPUV",
        "airportName": "Virac Airport",
        "location_country": "Philippines",
        "location_city": "Virac",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.5763998",
        "longitude": "124.2060013",
        "region": "AS",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5118",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 134782,
            "s_seats": 41497,
            "j_seats": 15885,
            "f_seats": 6848,
            "r_seats": 9776,
            "cargo_demand": 91310
        }
    },
    {
        "iata": "VRE",
        "icao": "FAVR",
        "airportName": "Vredendal Airport",
        "location_country": "South Africa",
        "location_city": "Vredendal",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-31.64100075",
        "longitude": "18.5447998",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4265",
                "runway_width": "59",
                "runway_material": "COM"
            }
        ],
        "demand": {
            "y_seats": 92171,
            "s_seats": 42008,
            "j_seats": 11602,
            "f_seats": 19013,
            "r_seats": 9741,
            "cargo_demand": 481512
        }
    },
    {
        "iata": "VRK",
        "icao": "EFVR",
        "airportName": "Varkaus Airport",
        "location_country": "Finland",
        "location_city": "Varkaus",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.171101",
        "longitude": "27.868601",
        "region": "EU",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6562",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151383,
            "s_seats": 42599,
            "j_seats": 35815,
            "f_seats": 7426,
            "r_seats": 9207,
            "cargo_demand": 403663
        }
    },
    {
        "iata": "VRL",
        "icao": "LPVR",
        "airportName": "Vila Real Airport",
        "location_country": "Portugal",
        "location_city": "Vila Real",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.2743",
        "longitude": "-7.72047",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3107",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 144800,
            "s_seats": 43043,
            "j_seats": 49354,
            "f_seats": 2897,
            "r_seats": 7283,
            "cargo_demand": 256149
        }
    },
    {
        "iata": "VRN",
        "icao": "LIPX",
        "airportName": "Verona Villafranca Airport",
        "location_country": "Italy",
        "location_city": "Veneto",
        "location_state": "Verona",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "45.395699",
        "longitude": "10.8885",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10064",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 428054,
            "s_seats": 92184,
            "j_seats": 115891,
            "f_seats": 35319,
            "r_seats": 10000,
            "cargo_demand": 18447093
        }
    },
    {
        "iata": "VRO",
        "icao": "MUKW",
        "airportName": "Kawama Airport",
        "location_country": "Cuba",
        "location_city": "Varadero",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "23.124001",
        "longitude": "-81.301598",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4850",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134077,
            "s_seats": 27771,
            "j_seats": 15570,
            "f_seats": 8413,
            "r_seats": 5528,
            "cargo_demand": 171532
        }
    },
    {
        "iata": "VRU",
        "icao": "FAVB",
        "airportName": "Vryburg Airport",
        "location_country": "South Africa",
        "location_city": "Vryburg",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.98240089",
        "longitude": "24.72879982",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3937",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197169,
            "s_seats": 42412,
            "j_seats": 59063,
            "f_seats": 2340,
            "r_seats": 6012,
            "cargo_demand": 278175
        }
    },
    {
        "iata": "VSA",
        "icao": "MMVA",
        "airportName": "Carlos Rovirosa Perez International Airport",
        "location_country": "Mexico",
        "location_city": "Tabasco",
        "location_state": "Villahermosa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.99699974",
        "longitude": "-92.81739807",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81894,
            "s_seats": 41456,
            "j_seats": 41909,
            "f_seats": 10129,
            "r_seats": 7608,
            "cargo_demand": 152379
        }
    },
    {
        "iata": "VSE",
        "icao": "LPVZ",
        "airportName": "Viseu Airport",
        "location_country": "Portugal",
        "location_city": "Viseu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.725498",
        "longitude": "-7.88899",
        "region": "EU",
        "runway": [
            {
                "runway_name": "36/19",
                "runway_length": "4035",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134335,
            "s_seats": 42799,
            "j_seats": 57317,
            "f_seats": 2894,
            "r_seats": 9569,
            "cargo_demand": 58954
        }
    },
    {
        "iata": "VSF",
        "icao": "KVSF",
        "airportName": "Hartness State Airport",
        "location_country": "United States",
        "location_city": "Vermont",
        "location_state": "Springfield",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.34360123",
        "longitude": "-72.51730347",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5498",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1723,
            "s_seats": 2353,
            "j_seats": 1157,
            "f_seats": 196,
            "r_seats": 75,
            "cargo_demand": 19171
        }
    },
    {
        "iata": "VSG",
        "icao": "UKCW",
        "airportName": "Luhansk International Airport",
        "location_country": "Ukraine",
        "location_city": "Luhansk",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.41740036",
        "longitude": "39.37409973",
        "region": "EU",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9450",
                "runway_width": "140",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 60033,
            "s_seats": 29006,
            "j_seats": 29263,
            "f_seats": 16233,
            "r_seats": 3780,
            "cargo_demand": 100905
        }
    },
    {
        "iata": "VST",
        "icao": "ESOW",
        "airportName": "Stockholm VAsterios Airport",
        "location_country": "Sweden",
        "location_city": "Stockholm / VAsterios",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "59.58940125",
        "longitude": "16.63360023",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8468",
                "runway_width": "164",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127484,
            "s_seats": 45985,
            "j_seats": 49974,
            "f_seats": 16021,
            "r_seats": 6754,
            "cargo_demand": 210734
        }
    },
    {
        "iata": "VTB",
        "icao": "UMII",
        "airportName": "Vitebsk Vostochny Airport",
        "location_country": "Belarus",
        "location_city": "Vitebsk",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.12649918",
        "longitude": "30.34959984",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8550",
                "runway_width": "138",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131481,
            "s_seats": 37818,
            "j_seats": 44759,
            "f_seats": 13634,
            "r_seats": 3043,
            "cargo_demand": 438842
        }
    },
    {
        "iata": "VTE",
        "icao": "VLVT",
        "airportName": "Wattay International Airport",
        "location_country": "Laos",
        "location_city": "Vientiane",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.98830032",
        "longitude": "102.5630035",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112541,
            "s_seats": 42571,
            "j_seats": 7814,
            "f_seats": 8876,
            "r_seats": 3493,
            "cargo_demand": 228335
        }
    },
    {
        "iata": "VTM",
        "icao": "LLNV",
        "airportName": "Nevatim Airbase",
        "location_country": "Israel",
        "location_city": "Nevatim",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.20829964",
        "longitude": "35.01229858",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08C/26C",
                "runway_length": "10991",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08L/26R",
                "runway_length": "8530",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "8395",
                "runway_width": "70",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2806",
                "runway_width": "70",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194026,
            "s_seats": 46997,
            "j_seats": 34362,
            "f_seats": 10984,
            "r_seats": 6312,
            "cargo_demand": 166407
        }
    },
    {
        "iata": "VTN",
        "icao": "KVTN",
        "airportName": "Miller Field",
        "location_country": "United States",
        "location_city": "Nebraska",
        "location_state": "Valentine",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.85779953",
        "longitude": "-100.5479965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2807",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4700",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156083,
            "s_seats": 26784,
            "j_seats": 26922,
            "f_seats": 13249,
            "r_seats": 4463,
            "cargo_demand": 365685
        }
    },
    {
        "iata": "VTU",
        "icao": "MUVT",
        "airportName": "Hermanos Ameijeiras Airport",
        "location_country": "Cuba",
        "location_city": "Las Tunas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.98760033",
        "longitude": "-76.93579865",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5971",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68312,
            "s_seats": 37505,
            "j_seats": 24477,
            "f_seats": 18369,
            "r_seats": 4184,
            "cargo_demand": 254173
        }
    },
    {
        "iata": "VTZ",
        "icao": "VEVZ",
        "airportName": "Visakhapatnam Airport",
        "location_country": "India",
        "location_city": "Andhra Pradesh",
        "location_state": "Visakhapatnam (Vishakhapatnam)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "17.721201",
        "longitude": "83.224503",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1539,
            "s_seats": 2951,
            "j_seats": 1329,
            "f_seats": 308,
            "r_seats": 181,
            "cargo_demand": 76727
        }
    },
    {
        "iata": "VUP",
        "icao": "SKVP",
        "airportName": "Alfonso Lopez Pumarejo Airport",
        "location_country": "Colombia",
        "location_city": "Valledupar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "10.435",
        "longitude": "-73.2495",
        "region": "SA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6890",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116888,
            "s_seats": 38175,
            "j_seats": 9409,
            "f_seats": 8408,
            "r_seats": 4374,
            "cargo_demand": 121042
        }
    },
    {
        "iata": "VVC",
        "icao": "SKVV",
        "airportName": "La Vanguardia Airport",
        "location_country": "Colombia",
        "location_city": "Villavicencio",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "4.16787",
        "longitude": "-73.6138",
        "region": "SA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5616",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89784,
            "s_seats": 32082,
            "j_seats": 38409,
            "f_seats": 2127,
            "r_seats": 9282,
            "cargo_demand": 107305
        }
    },
    {
        "iata": "VVI",
        "icao": "SLVR",
        "airportName": "Viru Viru International Airport",
        "location_country": "Bolivia",
        "location_city": "Santa Cruz de la Sierra",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-17.6448",
        "longitude": "-63.135399",
        "region": "SA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "11483",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 213775,
            "s_seats": 91399,
            "j_seats": 149658,
            "f_seats": 61356,
            "r_seats": 10000,
            "cargo_demand": 3860070
        }
    },
    {
        "iata": "VVO",
        "icao": "UHWW",
        "airportName": "Vladivostok International Airport",
        "location_country": "Russia",
        "location_city": "Primorsky Krai",
        "location_state": "Vladivostok",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.396256",
        "longitude": "132.148155",
        "region": "EU",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3191",
                "runway_width": "65",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07L/25R",
                "runway_length": "11483",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "11483",
                "runway_width": "197",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "1975",
                "runway_width": "65",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 384157,
            "s_seats": 83866,
            "j_seats": 62756,
            "f_seats": 56058,
            "r_seats": 10000,
            "cargo_demand": 17807684
        }
    },
    {
        "iata": "VVZ",
        "icao": "DAAP",
        "airportName": "Takhamalt Airport",
        "location_country": "Algeria",
        "location_city": "Illizi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.7234993",
        "longitude": "8.622650146",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104362,
            "s_seats": 42298,
            "j_seats": 57737,
            "f_seats": 11457,
            "r_seats": 3774,
            "cargo_demand": 103953
        }
    },
    {
        "iata": "VXC",
        "icao": "FQLC",
        "airportName": "Lichinga Airport",
        "location_country": "Mozambique",
        "location_city": "Lichinga",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-13.274",
        "longitude": "35.2663",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8300",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127142,
            "s_seats": 46771,
            "j_seats": 28479,
            "f_seats": 3761,
            "r_seats": 5576,
            "cargo_demand": 52443
        }
    },
    {
        "iata": "VXE",
        "icao": "GVSV",
        "airportName": "Cesaria evora Airport",
        "location_country": "Cape Verde",
        "location_city": "Sio Vicente",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.8332",
        "longitude": "-25.0553",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6561",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 183974,
            "s_seats": 32100,
            "j_seats": 37072,
            "f_seats": 18385,
            "r_seats": 4722,
            "cargo_demand": 174635
        }
    },
    {
        "iata": "VXO",
        "icao": "ESMX",
        "airportName": "VAxjo/Kronoberg Airport",
        "location_country": "Sweden",
        "location_city": "VAxjo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.92910004",
        "longitude": "14.72799969",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6900",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163083,
            "s_seats": 26114,
            "j_seats": 22941,
            "f_seats": 18071,
            "r_seats": 4473,
            "cargo_demand": 304287
        }
    },
    {
        "iata": "VYS",
        "icao": "KVYS",
        "airportName": "Illinois Valley Regional Airport",
        "location_country": "United States",
        "location_city": "Illinois",
        "location_state": "Peru",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.351898",
        "longitude": "-89.153099",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3698,
            "s_seats": 2930,
            "j_seats": 1150,
            "f_seats": 50,
            "r_seats": 43,
            "cargo_demand": 17158
        }
    },
    {
        "iata": "WAA",
        "icao": "PAIW",
        "airportName": "Wales Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Wales",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "65.622593",
        "longitude": "-168.095",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 2011,
            "s_seats": 1616,
            "j_seats": 1028,
            "f_seats": 200,
            "r_seats": 93,
            "cargo_demand": 83107
        }
    },
    {
        "iata": "WAE",
        "icao": "OEWD",
        "airportName": "Wadi al-Dawasir Domestic Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Wadi ad-Dawasir (Wadi al-Dawaser)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "20.50429916",
        "longitude": "45.19960022",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10007",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70386,
            "s_seats": 47029,
            "j_seats": 8011,
            "f_seats": 9655,
            "r_seats": 3251,
            "cargo_demand": 243196
        }
    },
    {
        "iata": "WAF",
        "icao": "OPWN",
        "airportName": "Wana Airport",
        "location_country": "Pakistan",
        "location_city": "Wana",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.30469894",
        "longitude": "69.57039642",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2993",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2692,
            "s_seats": 2103,
            "j_seats": 1492,
            "f_seats": 72,
            "r_seats": 68,
            "cargo_demand": 81531
        }
    },
    {
        "iata": "WAG",
        "icao": "NZWU",
        "airportName": "Whanganui Airport",
        "location_country": "New Zealand",
        "location_city": "Whanganui",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-39.96220016",
        "longitude": "175.0249939",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2877",
                "runway_width": "197",
                "runway_material": "GRE"
            },
            {
                "runway_name": "11/29",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "11C/29C",
                "runway_length": "4521",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11L/29R",
                "runway_length": "1654",
                "runway_width": "1",
                "runway_material": "GRE"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "2756",
                "runway_width": "279",
                "runway_material": "GRE"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2959",
                "runway_width": "197",
                "runway_material": "GRE"
            }
        ],
        "demand": {
            "y_seats": 56977,
            "s_seats": 27764,
            "j_seats": 33156,
            "f_seats": 19074,
            "r_seats": 9717,
            "cargo_demand": 146598
        }
    },
    {
        "iata": "WAH",
        "icao": "KBWP",
        "airportName": "Harry Stern Airport",
        "location_country": "United States",
        "location_city": "North Dakota",
        "location_state": "Wahpeton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "46.24409866",
        "longitude": "-96.60739899",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3280",
                "runway_width": "150",
                "runway_material": "GRS"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5100",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2535,
            "s_seats": 1596,
            "j_seats": 1931,
            "f_seats": 124,
            "r_seats": 182,
            "cargo_demand": 17908
        }
    },
    {
        "iata": "WAI",
        "icao": "FMNW",
        "airportName": "Ambalabe Airport",
        "location_country": "Madagascar",
        "location_city": "Antsohihy",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.8987999",
        "longitude": "47.9939003",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50732,
            "s_seats": 37649,
            "j_seats": 23318,
            "f_seats": 6306,
            "r_seats": 8281,
            "cargo_demand": 216215
        }
    },
    {
        "iata": "WAL",
        "icao": "KWAL",
        "airportName": "Wallops Flight Facility",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Wallops Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.94020081",
        "longitude": "-75.46640015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8749",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "10/28",
                "runway_length": "8005",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "4808",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3803,
            "s_seats": 2936,
            "j_seats": 1329,
            "f_seats": 105,
            "r_seats": 35,
            "cargo_demand": 87302
        }
    },
    {
        "iata": "WAO",
        "icao": "AYWB",
        "airportName": "Wabo Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Wabo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-6.989444444",
        "longitude": "145.0751111",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2379",
                "runway_width": "98",
                "runway_material": "Grassed brown gravel"
            }
        ],
        "demand": {
            "y_seats": 2141,
            "s_seats": 1864,
            "j_seats": 1604,
            "f_seats": 209,
            "r_seats": 30,
            "cargo_demand": 10267
        }
    },
    {
        "iata": "WAP",
        "icao": "SCAP",
        "airportName": "Alto Palena Airfield",
        "location_country": "Chile",
        "location_city": "Palena",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-43.61190033",
        "longitude": "-71.80609894",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "2986",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3954,
            "s_seats": 1672,
            "j_seats": 1591,
            "f_seats": 114,
            "r_seats": 164,
            "cargo_demand": 40890
        }
    },
    {
        "iata": "WAT",
        "icao": "EIWF",
        "airportName": "Waterford Airport",
        "location_country": "Ireland",
        "location_city": "Waterford",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.18719864",
        "longitude": "-7.086959839",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4701",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182644,
            "s_seats": 27540,
            "j_seats": 57524,
            "f_seats": 17512,
            "r_seats": 8611,
            "cargo_demand": 491830
        }
    },
    {
        "iata": "WAV",
        "icao": "YWAV",
        "airportName": "Wave Hill Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Kalkarindji (Wave Hill)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.3932991",
        "longitude": "131.1179962",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3805",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 2123,
            "s_seats": 1887,
            "j_seats": 1953,
            "f_seats": 187,
            "r_seats": 7,
            "cargo_demand": 81423
        }
    },
    {
        "iata": "WAW",
        "icao": "EPWA",
        "airportName": "Warsaw Chopin Airport",
        "location_country": "Poland",
        "location_city": "Warsaw",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "52.16569901",
        "longitude": "20.96710014",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9186",
                "runway_width": "164",
                "runway_material": "CON"
            },
            {
                "runway_name": "15/33",
                "runway_length": "12106",
                "runway_width": "196",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 167464,
            "s_seats": 97851,
            "j_seats": 139693,
            "f_seats": 24654,
            "r_seats": 10000,
            "cargo_demand": 2449673
        }
    },
    {
        "iata": "WAX",
        "icao": "HLZW",
        "airportName": "Zuwarah Airport",
        "location_country": "Libya",
        "location_city": "Zuwarah (Zuara)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.952301",
        "longitude": "12.0155",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5906",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1652,
            "s_seats": 2452,
            "j_seats": 1635,
            "f_seats": 62,
            "r_seats": 188,
            "cargo_demand": 75680
        }
    },
    {
        "iata": "WAY",
        "icao": "KWAY",
        "airportName": "Greene County Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Waynesburg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "39.90010071",
        "longitude": "-80.13310242",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3500",
                "runway_width": "75",
                "runway_material": "ASPH-F"
            }
        ],
        "demand": {
            "y_seats": 2403,
            "s_seats": 2921,
            "j_seats": 1306,
            "f_seats": 157,
            "r_seats": 182,
            "cargo_demand": 35073
        }
    },
    {
        "iata": "WAZ",
        "icao": "YWCK",
        "airportName": "Warwick Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Warwick",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.14940071",
        "longitude": "151.9429932",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4922",
                "runway_width": "59",
                "runway_material": "PER"
            }
        ],
        "demand": {
            "y_seats": 3801,
            "s_seats": 2375,
            "j_seats": 1280,
            "f_seats": 22,
            "r_seats": 60,
            "cargo_demand": 98148
        }
    },
    {
        "iata": "WBA",
        "icao": "WAPV",
        "airportName": "Wahai Airport",
        "location_country": "Indonesia",
        "location_city": "Seram Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "\"Seram Island\"\"\"",
        "longitude": "-2.8114",
        "region": "558",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2461",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3103,
            "s_seats": 2162,
            "j_seats": 1643,
            "f_seats": 161,
            "r_seats": 10,
            "cargo_demand": 22786
        }
    },
    {
        "iata": "WBG",
        "icao": "ETNS",
        "airportName": "Schleswig Air Base",
        "location_country": "Germany",
        "location_city": "Schleswig-Holstein",
        "location_state": "Schleswig",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.459301",
        "longitude": "9.51633",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8003",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "6554",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100836,
            "s_seats": 31101,
            "j_seats": 31483,
            "f_seats": 11905,
            "r_seats": 6006,
            "cargo_demand": 409978
        }
    },
    {
        "iata": "WBM",
        "icao": "AYWD",
        "airportName": "Wapenamanda Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Wapenamanda",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.6433",
        "longitude": "143.895004",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5052",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 75763,
            "s_seats": 38309,
            "j_seats": 10286,
            "f_seats": 17446,
            "r_seats": 5789,
            "cargo_demand": 107261
        }
    },
    {
        "iata": "WBQ",
        "icao": "PAWB",
        "airportName": "Beaver Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Beaver",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "66.36219788",
        "longitude": "-147.4069977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3954",
                "runway_width": "75",
                "runway_material": "GRVL-F"
            }
        ],
        "demand": {
            "y_seats": 1971,
            "s_seats": 2011,
            "j_seats": 1049,
            "f_seats": 445,
            "r_seats": 12,
            "cargo_demand": 81324
        }
    },
    {
        "iata": "WBR",
        "icao": "KRQB",
        "airportName": "Roben-Hood Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Big Rapids",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.72259903",
        "longitude": "-85.50409698",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4300",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2700",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3633,
            "s_seats": 1688,
            "j_seats": 1813,
            "f_seats": 309,
            "r_seats": 20,
            "cargo_demand": 22177
        }
    },
    {
        "iata": "WBU",
        "icao": "KBDU",
        "airportName": "Boulder Municipal Airport",
        "location_country": "United States",
        "location_city": "Colorado",
        "location_state": "Boulder",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.03939819",
        "longitude": "-105.2259979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4100",
                "runway_width": "75",
                "runway_material": "ASPH-G"
            },
            {
                "runway_name": "08G/26G",
                "runway_length": "4100",
                "runway_width": "20",
                "runway_material": "ASPH-TURF-F"
            }
        ],
        "demand": {
            "y_seats": 3263,
            "s_seats": 2999,
            "j_seats": 1967,
            "f_seats": 400,
            "r_seats": 131,
            "cargo_demand": 6431
        }
    },
    {
        "iata": "WBW",
        "icao": "KWBW",
        "airportName": "Wilkes-Barre Wyoming Valley Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Wilkes-Barre",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.29719925",
        "longitude": "-75.85119629",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3376",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2200",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 3374,
            "s_seats": 2192,
            "j_seats": 1803,
            "f_seats": 353,
            "r_seats": 41,
            "cargo_demand": 33247
        }
    },
    {
        "iata": "WCA",
        "icao": "SCST",
        "airportName": "Gamboa Airport",
        "location_country": "Chile",
        "location_city": "Castro",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-42.49029922",
        "longitude": "-73.77279663",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3281",
                "runway_width": "59",
                "runway_material": "GRASS/PAD"
            }
        ],
        "demand": {
            "y_seats": 1343,
            "s_seats": 2843,
            "j_seats": 1781,
            "f_seats": 340,
            "r_seats": 98,
            "cargo_demand": 41701
        }
    },
    {
        "iata": "WCH",
        "icao": "SCTN",
        "airportName": "Nuevo Chaiten Airport",
        "location_country": "Chile",
        "location_city": "Chaiten",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-42.93280029",
        "longitude": "-72.69909668",
        "region": "SA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4670",
                "runway_width": "65",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111468,
            "s_seats": 29165,
            "j_seats": 43930,
            "f_seats": 11937,
            "r_seats": 6178,
            "cargo_demand": 98367
        }
    },
    {
        "iata": "WCR",
        "icao": "PALR",
        "airportName": "Chandalar Lake Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Chandalar Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "67.50450134",
        "longitude": "-148.4830017",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "GRVL-F"
            }
        ],
        "demand": {
            "y_seats": 2405,
            "s_seats": 1682,
            "j_seats": 1807,
            "f_seats": 311,
            "r_seats": 43,
            "cargo_demand": 40081
        }
    },
    {
        "iata": "WDG",
        "icao": "KWDG",
        "airportName": "Enid Woodring Regional Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Enid",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.37919998",
        "longitude": "-97.79109955",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3149",
                "runway_width": "108",
                "runway_material": "ASPH-P"
            },
            {
                "runway_name": "17/35",
                "runway_length": "6389",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3204,
            "s_seats": 1951,
            "j_seats": 1442,
            "f_seats": 306,
            "r_seats": 12,
            "cargo_demand": 65147
        }
    },
    {
        "iata": "WDH",
        "icao": "FYWH",
        "airportName": "Hosea Kutako International Airport",
        "location_country": "Namibia",
        "location_city": "Windhoek",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-22.4799",
        "longitude": "17.4709",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "15010",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5000",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 438495,
            "s_seats": 92131,
            "j_seats": 156111,
            "f_seats": 61022,
            "r_seats": 10000,
            "cargo_demand": 4544688
        }
    },
    {
        "iata": "WDI",
        "icao": "YWND",
        "airportName": "Wondai Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Wondai",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.2833004",
        "longitude": "151.8580017",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4606",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 3904,
            "s_seats": 2605,
            "j_seats": 1029,
            "f_seats": 3,
            "r_seats": 68,
            "cargo_demand": 62606
        }
    },
    {
        "iata": "WDR",
        "icao": "KWDR",
        "airportName": "Barrow County Airport",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Winder",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "33.98289871",
        "longitude": "-83.66739655",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3610",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1453,
            "s_seats": 2983,
            "j_seats": 1456,
            "f_seats": 26,
            "r_seats": 100,
            "cargo_demand": 59168
        }
    },
    {
        "iata": "WEH",
        "icao": "ZSWH",
        "airportName": "Weihai Dashuibo Airport",
        "location_country": "China",
        "location_city": "Shandong",
        "location_state": "Weihai",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.18709946",
        "longitude": "122.2289963",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 76715,
            "s_seats": 39292,
            "j_seats": 5286,
            "f_seats": 1926,
            "r_seats": 4961,
            "cargo_demand": 460318
        }
    },
    {
        "iata": "WEI",
        "icao": "YBWP",
        "airportName": "Weipa Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Weipa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-12.67860031",
        "longitude": "141.9250031",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5397",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116520,
            "s_seats": 38232,
            "j_seats": 38331,
            "f_seats": 15420,
            "r_seats": 9474,
            "cargo_demand": 378319
        }
    },
    {
        "iata": "WEL",
        "icao": "FAWM",
        "airportName": "Welkom Airport",
        "location_country": "South Africa",
        "location_city": "Welkom",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.99682451",
        "longitude": "26.66333389",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6578",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1086,
            "s_seats": 2809,
            "j_seats": 1806,
            "f_seats": 364,
            "r_seats": 32,
            "cargo_demand": 55569
        }
    },
    {
        "iata": "WEW",
        "icao": "YWWA",
        "airportName": "Wee Waa Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Wee Waa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-30.25830078",
        "longitude": "149.4080048",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3730",
                "runway_width": "",
                "runway_material": "B"
            }
        ],
        "demand": {
            "y_seats": 2070,
            "s_seats": 2804,
            "j_seats": 1070,
            "f_seats": 165,
            "r_seats": 183,
            "cargo_demand": 29955
        }
    },
    {
        "iata": "WFI",
        "icao": "FMSF",
        "airportName": "Fianarantsoa Airport",
        "location_country": "Madagascar",
        "location_city": "Fianarantsoa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.441601",
        "longitude": "47.111698",
        "region": "AF",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4101",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168394,
            "s_seats": 44401,
            "j_seats": 33322,
            "f_seats": 13030,
            "r_seats": 6626,
            "cargo_demand": 447447
        }
    },
    {
        "iata": "WFK",
        "icao": "KFVE",
        "airportName": "Northern Aroostook Regional Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Frenchville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "47.28549957",
        "longitude": "-68.31279755",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4600",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1458,
            "s_seats": 1618,
            "j_seats": 1925,
            "f_seats": 94,
            "r_seats": 12,
            "cargo_demand": 27057
        }
    },
    {
        "iata": "WGA",
        "icao": "YSWG",
        "airportName": "Wagga Wagga Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Wagga Wagga",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-35.16529846",
        "longitude": "147.4660034",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5801",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5007",
                "runway_width": "98",
                "runway_material": "CLA"
            }
        ],
        "demand": {
            "y_seats": 158443,
            "s_seats": 28698,
            "j_seats": 19028,
            "f_seats": 2886,
            "r_seats": 6797,
            "cargo_demand": 189187
        }
    },
    {
        "iata": "WGC",
        "icao": "VOWA",
        "airportName": "Warangal Airport",
        "location_country": "India",
        "location_city": "Telangana",
        "location_state": "Warangal",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "17.9144",
        "longitude": "79.602203",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6000",
                "runway_width": "",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3376,
            "s_seats": 2745,
            "j_seats": 1792,
            "f_seats": 99,
            "r_seats": 157,
            "cargo_demand": 2874
        }
    },
    {
        "iata": "WGE",
        "icao": "YWLG",
        "airportName": "Walgett Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Walgett",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-30.03280067",
        "longitude": "148.1260071",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5335",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3783",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 164693,
            "s_seats": 45709,
            "j_seats": 18653,
            "f_seats": 8614,
            "r_seats": 6375,
            "cargo_demand": 224582
        }
    },
    {
        "iata": "WGN",
        "icao": "ZGSY",
        "airportName": "Shaoyang Wugang Airport",
        "location_country": "China",
        "location_city": "Hunan",
        "location_state": "Shaoyang",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.802",
        "longitude": "110.642",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 60225,
            "s_seats": 27428,
            "j_seats": 28665,
            "f_seats": 17792,
            "r_seats": 4656,
            "cargo_demand": 248906
        }
    },
    {
        "iata": "WGO",
        "icao": "KOKV",
        "airportName": "Winchester Regional Airport",
        "location_country": "United States",
        "location_city": "Virginia",
        "location_state": "Winchester",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "39.14350128",
        "longitude": "-78.14440155",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3726,
            "s_seats": 2223,
            "j_seats": 1871,
            "f_seats": 4,
            "r_seats": 187,
            "cargo_demand": 61439
        }
    },
    {
        "iata": "WGP",
        "icao": "WADW",
        "airportName": "Mau Hau Airport",
        "location_country": "Indonesia",
        "location_city": "Waingapu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.66922",
        "longitude": "120.302002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5415",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1384,
            "s_seats": 2714,
            "j_seats": 1863,
            "f_seats": 387,
            "r_seats": 112,
            "cargo_demand": 16142
        }
    },
    {
        "iata": "WGT",
        "icao": "YWGT",
        "airportName": "Wangaratta Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Wangaratta",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-36.41579819",
        "longitude": "146.3070068",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "1739",
                "runway_width": "60",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5381",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134006,
            "s_seats": 40181,
            "j_seats": 17789,
            "f_seats": 9781,
            "r_seats": 5944,
            "cargo_demand": 289881
        }
    },
    {
        "iata": "WHF",
        "icao": "HSSW",
        "airportName": "Wadi Halfa Airport",
        "location_country": "Sudan",
        "location_city": "Wadi Halfa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "21.802698",
        "longitude": "31.521578",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6562",
                "runway_width": "",
                "runway_material": "gravel"
            }
        ],
        "demand": {
            "y_seats": 2367,
            "s_seats": 1702,
            "j_seats": 1380,
            "f_seats": 458,
            "r_seats": 189,
            "cargo_demand": 87555
        }
    },
    {
        "iata": "WHK",
        "icao": "NZWK",
        "airportName": "Whakatane Airport",
        "location_country": "New Zealand",
        "location_city": "Whakatane",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-37.92060089",
        "longitude": "176.9140015",
        "region": "OC",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "4200",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "2461",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 81646,
            "s_seats": 33786,
            "j_seats": 19378,
            "f_seats": 3315,
            "r_seats": 3084,
            "cargo_demand": 117739
        }
    },
    {
        "iata": "WHP",
        "icao": "KWHP",
        "airportName": "Whiteman Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Los Angeles",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "34.25930023",
        "longitude": "-118.413002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4120",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3971,
            "s_seats": 2733,
            "j_seats": 1657,
            "f_seats": 359,
            "r_seats": 88,
            "cargo_demand": 76078
        }
    },
    {
        "iata": "WHT",
        "icao": "KARM",
        "airportName": "Wharton Regional Airport ",
        "location_country": "United States",
        "location_city": "Texas",
        "location_state": "Wharton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "29.254299",
        "longitude": "-96.154404",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5004",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1143,
            "s_seats": 1528,
            "j_seats": 1455,
            "f_seats": 115,
            "r_seats": 117,
            "cargo_demand": 49690
        }
    },
    {
        "iata": "WIC",
        "icao": "EGPC",
        "airportName": "Wick Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Wick",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.45890045",
        "longitude": "-3.093060017",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3399",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5988",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 114379,
            "s_seats": 38944,
            "j_seats": 57828,
            "f_seats": 15548,
            "r_seats": 6754,
            "cargo_demand": 58622
        }
    },
    {
        "iata": "WIE",
        "icao": "ETOU",
        "airportName": "Wiesbaden Army Airfield",
        "location_country": "Germany",
        "location_city": "Hesse",
        "location_state": "Wiesbaden",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.04980087",
        "longitude": "8.325400352",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7065",
                "runway_width": "120",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169311,
            "s_seats": 41705,
            "j_seats": 21843,
            "f_seats": 1768,
            "r_seats": 5756,
            "cargo_demand": 247034
        }
    },
    {
        "iata": "WIL",
        "icao": "HKNW",
        "airportName": "Wilson Airport",
        "location_country": "Kenya",
        "location_city": "Nairobi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-1.321720004",
        "longitude": "36.81480026",
        "region": "AF",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4798",
                "runway_width": "72",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5052",
                "runway_width": "76",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137688,
            "s_seats": 35095,
            "j_seats": 19082,
            "f_seats": 2335,
            "r_seats": 8675,
            "cargo_demand": 78928
        }
    },
    {
        "iata": "WIN",
        "icao": "YWTN",
        "airportName": "Winton Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Winton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.36359978",
        "longitude": "143.0859985",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2920",
                "runway_width": "59",
                "runway_material": "CLA"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4600",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 73331,
            "s_seats": 47461,
            "j_seats": 29333,
            "f_seats": 10805,
            "r_seats": 7228,
            "cargo_demand": 250548
        }
    },
    {
        "iata": "WIO",
        "icao": "YWCA",
        "airportName": "Wilcannia Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Wilcannia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31.52639961",
        "longitude": "143.375",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3051",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3701",
                "runway_width": "98",
                "runway_material": "CLA"
            }
        ],
        "demand": {
            "y_seats": 2009,
            "s_seats": 2206,
            "j_seats": 1922,
            "f_seats": 39,
            "r_seats": 63,
            "cargo_demand": 2753
        }
    },
    {
        "iata": "WIR",
        "icao": "NZWO",
        "airportName": "Wairoa Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Wairoa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-39.00690079",
        "longitude": "177.4069977",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3000",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103629,
            "s_seats": 47176,
            "j_seats": 5965,
            "f_seats": 14229,
            "r_seats": 9161,
            "cargo_demand": 439630
        }
    },
    {
        "iata": "WJF",
        "icao": "KWJF",
        "airportName": "General William J. Fox Airfield",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Lancaster",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.74110031",
        "longitude": "-118.2190018",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7201",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 108376,
            "s_seats": 27998,
            "j_seats": 46946,
            "f_seats": 8206,
            "r_seats": 4871,
            "cargo_demand": 499340
        }
    },
    {
        "iata": "WJR",
        "icao": "HKWJ",
        "airportName": "Wajir Airport",
        "location_country": "Kenya",
        "location_city": "Wajir",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.73324",
        "longitude": "40.091599",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "9193",
                "runway_width": "104",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 133658,
            "s_seats": 40905,
            "j_seats": 8747,
            "f_seats": 1015,
            "r_seats": 5092,
            "cargo_demand": 377012
        }
    },
    {
        "iata": "WJU",
        "icao": "RKNW",
        "airportName": "Wonju Airport",
        "location_country": "South Korea",
        "location_city": "Wonju",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.441201",
        "longitude": "127.963858",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 127322,
            "s_seats": 28979,
            "j_seats": 19935,
            "f_seats": 11360,
            "r_seats": 9587,
            "cargo_demand": 196306
        }
    },
    {
        "iata": "WKA",
        "icao": "NZWF",
        "airportName": "Wanaka Airport",
        "location_country": "New Zealand",
        "location_city": "Wanaka",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-44.72219849",
        "longitude": "169.2460022",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190962,
            "s_seats": 44446,
            "j_seats": 33259,
            "f_seats": 4785,
            "r_seats": 5302,
            "cargo_demand": 430246
        }
    },
    {
        "iata": "WKB",
        "icao": "YWKB",
        "airportName": "Warracknabeal Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Warracknabeal",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-36.32109833",
        "longitude": "142.4190063",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4501",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2503",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 184721,
            "s_seats": 47183,
            "j_seats": 16897,
            "f_seats": 17456,
            "r_seats": 4195,
            "cargo_demand": 95394
        }
    },
    {
        "iata": "WKF",
        "icao": "FAWK",
        "airportName": "Air Force Base Waterkloof",
        "location_country": "South Africa",
        "location_city": "Pretoria",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.82999992",
        "longitude": "28.22249985",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "11001",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "6299",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 195380,
            "s_seats": 27505,
            "j_seats": 58559,
            "f_seats": 11657,
            "r_seats": 4799,
            "cargo_demand": 430706
        }
    },
    {
        "iata": "WKJ",
        "icao": "RJCW",
        "airportName": "Wakkanai Airport",
        "location_country": "Japan",
        "location_city": "Hokkaido",
        "location_state": "Wakkanai",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.40420151",
        "longitude": "141.8009949",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6560",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90120,
            "s_seats": 38731,
            "j_seats": 11978,
            "f_seats": 4040,
            "r_seats": 9735,
            "cargo_demand": 151634
        }
    },
    {
        "iata": "WLA",
        "icao": "YWAL",
        "airportName": "Wallal Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Wallal (Wallal Downs)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-19.77359962",
        "longitude": "120.6490021",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2952",
                "runway_width": "",
                "runway_material": "N"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4265",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1072,
            "s_seats": 2246,
            "j_seats": 1977,
            "f_seats": 228,
            "r_seats": 122,
            "cargo_demand": 43728
        }
    },
    {
        "iata": "WLC",
        "icao": "YWCH",
        "airportName": "Walcha Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Walcha",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-31",
        "longitude": "151.5670013",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5167",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2801",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 3193,
            "s_seats": 1538,
            "j_seats": 1192,
            "f_seats": 312,
            "r_seats": 77,
            "cargo_demand": 36277
        }
    },
    {
        "iata": "WLD",
        "icao": "KWLD",
        "airportName": "Strother Field",
        "location_country": "United States",
        "location_city": "Kansas",
        "location_state": "Winfield / Arkansas City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.168598",
        "longitude": "-97.037598",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3137",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5505",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1905,
            "s_seats": 2757,
            "j_seats": 1392,
            "f_seats": 481,
            "r_seats": 78,
            "cargo_demand": 20047
        }
    },
    {
        "iata": "WLE",
        "icao": "YMLS",
        "airportName": "Miles Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Miles",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-26.80830002",
        "longitude": "150.1750031",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5217",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1446,
            "s_seats": 2145,
            "j_seats": 1302,
            "f_seats": 388,
            "r_seats": 95,
            "cargo_demand": 54408
        }
    },
    {
        "iata": "WLG",
        "icao": "NZWN",
        "airportName": "Wellington International Airport",
        "location_country": "New Zealand",
        "location_city": "Wellington",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-41.32720184",
        "longitude": "174.8049927",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6352",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 479446,
            "s_seats": 97675,
            "j_seats": 136426,
            "f_seats": 42221,
            "r_seats": 10000,
            "cargo_demand": 2459938
        }
    },
    {
        "iata": "WLH",
        "icao": "NVSW",
        "airportName": "Walaha Airport",
        "location_country": "Vanuatu",
        "location_city": "Walaha",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.4119997",
        "longitude": "167.6909943",
        "region": "OC",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "2379",
                "runway_width": "115",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 3731,
            "s_seats": 1566,
            "j_seats": 1916,
            "f_seats": 408,
            "r_seats": 105,
            "cargo_demand": 43157
        }
    },
    {
        "iata": "WLK",
        "icao": "PASK",
        "airportName": "Selawik Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Selawik",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "66.60009766",
        "longitude": "-159.9859924",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3002",
                "runway_width": "60",
                "runway_material": "GRAVEL-G"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2659",
                "runway_width": "60",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 143271,
            "s_seats": 33602,
            "j_seats": 17615,
            "f_seats": 6225,
            "r_seats": 6602,
            "cargo_demand": 127646
        }
    },
    {
        "iata": "WLL",
        "icao": "YWOR",
        "airportName": "Wollogorang Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Wollogorang Station",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-17.2199",
        "longitude": "137.93453",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4199",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 1965,
            "s_seats": 2660,
            "j_seats": 1953,
            "f_seats": 170,
            "r_seats": 135,
            "cargo_demand": 29293
        }
    },
    {
        "iata": "WLO",
        "icao": "YWTL",
        "airportName": "Waterloo Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Waterloo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.62999916",
        "longitude": "129.3200073",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3182",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1679,
            "s_seats": 1546,
            "j_seats": 1273,
            "f_seats": 412,
            "r_seats": 161,
            "cargo_demand": 56596
        }
    },
    {
        "iata": "WLP",
        "icao": "YANG",
        "airportName": "West Angelas Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "West Angelas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-23.13555556",
        "longitude": "118.7072222",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5906",
                "runway_width": "131",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 2508,
            "s_seats": 2205,
            "j_seats": 1791,
            "f_seats": 436,
            "r_seats": 57,
            "cargo_demand": 61520
        }
    },
    {
        "iata": "WLS",
        "icao": "NLWW",
        "airportName": "Hihifo Airport",
        "location_country": "Wallis and Futuna",
        "location_city": "Wallis Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-13.23830032",
        "longitude": "-176.1990051",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6890",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170467,
            "s_seats": 47438,
            "j_seats": 37852,
            "f_seats": 9138,
            "r_seats": 4261,
            "cargo_demand": 198691
        }
    },
    {
        "iata": "WLW",
        "icao": "KWLW",
        "airportName": "Willows-Glenn County Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Willows",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "39.51639938",
        "longitude": "-122.2180023",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4210",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4506",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1331,
            "s_seats": 2352,
            "j_seats": 1777,
            "f_seats": 125,
            "r_seats": 70,
            "cargo_demand": 47123
        }
    },
    {
        "iata": "WMB",
        "icao": "YWBL",
        "airportName": "Warrnambool Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Warrnambool",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-38.29529953",
        "longitude": "142.4470062",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3507",
                "runway_width": "98",
                "runway_material": "GRS"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4501",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2799,
            "s_seats": 2983,
            "j_seats": 1069,
            "f_seats": 33,
            "r_seats": 97,
            "cargo_demand": 89820
        }
    },
    {
        "iata": "WMC",
        "icao": "KWMC",
        "airportName": "Winnemucca Municipal Airport",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Winnemucca",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.89659882",
        "longitude": "-117.8059998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4800",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "7000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199666,
            "s_seats": 41575,
            "j_seats": 24984,
            "f_seats": 14783,
            "r_seats": 6000,
            "cargo_demand": 460177
        }
    },
    {
        "iata": "WME",
        "icao": "YMNE",
        "airportName": "Mount Keith Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Mount Keith",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.28639984",
        "longitude": "120.5550003",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5896",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169560,
            "s_seats": 39959,
            "j_seats": 54219,
            "f_seats": 11239,
            "r_seats": 8930,
            "cargo_demand": 408545
        }
    },
    {
        "iata": "WMH",
        "icao": "KBPK",
        "airportName": "Ozark Regional Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Mountain Home",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.3689003",
        "longitude": "-92.47049713",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5001",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64855,
            "s_seats": 49714,
            "j_seats": 45097,
            "f_seats": 12987,
            "r_seats": 4592,
            "cargo_demand": 444319
        }
    },
    {
        "iata": "WMI",
        "icao": "EPMO",
        "airportName": "Warsaw Modlin Airport",
        "location_country": "Poland",
        "location_city": "Warsaw",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.451099",
        "longitude": "20.6518",
        "region": "EU",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8202",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 113000,
            "s_seats": 29638,
            "j_seats": 58183,
            "f_seats": 16796,
            "r_seats": 7653,
            "cargo_demand": 195727
        }
    },
    {
        "iata": "WMN",
        "icao": "FMNR",
        "airportName": "Maroantsetra Airport",
        "location_country": "Madagascar",
        "location_city": "Maroantsetra",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-15.437742",
        "longitude": "49.689081",
        "region": "AF",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4265",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169718,
            "s_seats": 29489,
            "j_seats": 19667,
            "f_seats": 13831,
            "r_seats": 9071,
            "cargo_demand": 240695
        }
    },
    {
        "iata": "WMO",
        "icao": "PAWM",
        "airportName": "White Mountain Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "White Mountain",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "64.68920136",
        "longitude": "-163.4129944",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 2758,
            "s_seats": 2354,
            "j_seats": 1076,
            "f_seats": 378,
            "r_seats": 173,
            "cargo_demand": 82774
        }
    },
    {
        "iata": "WMR",
        "icao": "FMNC",
        "airportName": "Mananara Nord Airport",
        "location_country": "Madagascar",
        "location_city": "Mananara Nord (Mananara Avaratra)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.16390038",
        "longitude": "49.7737999",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4101",
                "runway_width": "82",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120422,
            "s_seats": 46542,
            "j_seats": 6642,
            "f_seats": 3012,
            "r_seats": 8576,
            "cargo_demand": 56552
        }
    },
    {
        "iata": "WMT",
        "icao": "ZUMT",
        "airportName": "Zunyi Maotai Airport",
        "location_country": "China",
        "location_city": "Renhuai",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.81638",
        "longitude": "106.33268",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 75343,
            "s_seats": 41879,
            "j_seats": 51596,
            "f_seats": 3053,
            "r_seats": 3422,
            "cargo_demand": 161218
        }
    },
    {
        "iata": "WNA",
        "icao": "PANA",
        "airportName": "Napakiak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Napakiak",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "60.69029999",
        "longitude": "-161.9790039",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3248",
                "runway_width": "60",
                "runway_material": "GRAVEL-F"
            }
        ],
        "demand": {
            "y_seats": 1849,
            "s_seats": 2713,
            "j_seats": 1592,
            "f_seats": 186,
            "r_seats": 173,
            "cargo_demand": 18490
        }
    },
    {
        "iata": "WNP",
        "icao": "RPUN",
        "airportName": "Naga Airport",
        "location_country": "Philippines",
        "location_city": "Naga",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.5848999",
        "longitude": "123.2699966",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4599",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 175852,
            "s_seats": 35489,
            "j_seats": 53204,
            "f_seats": 12496,
            "r_seats": 4735,
            "cargo_demand": 136859
        }
    },
    {
        "iata": "WNR",
        "icao": "YWDH",
        "airportName": "Windorah Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Windorah",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-25.41309929",
        "longitude": "142.6670074",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4508",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197380,
            "s_seats": 30045,
            "j_seats": 16271,
            "f_seats": 11176,
            "r_seats": 5454,
            "cargo_demand": 206305
        }
    },
    {
        "iata": "WNS",
        "icao": "OPNH",
        "airportName": "Nawabshah Airport",
        "location_country": "Pakistan",
        "location_city": "Nawabshah (Shaheed Benazirabad)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "26.2194",
        "longitude": "68.390099",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "8999",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 110652,
            "s_seats": 47013,
            "j_seats": 46166,
            "f_seats": 11820,
            "r_seats": 9159,
            "cargo_demand": 173248
        }
    },
    {
        "iata": "WNZ",
        "icao": "ZSWZ",
        "airportName": "Wenzhou Longwan International Airport",
        "location_country": "China",
        "location_city": "Zhejiang",
        "location_state": "Wenzhou",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "27.912201",
        "longitude": "120.851997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 175584,
            "s_seats": 74455,
            "j_seats": 74243,
            "f_seats": 30752,
            "r_seats": 10000,
            "cargo_demand": 12035521
        }
    },
    {
        "iata": "WOE",
        "icao": "EHWO",
        "airportName": "Woensdrecht Air Base",
        "location_country": "Netherlands",
        "location_city": "Bergen op Zoom",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.4491",
        "longitude": "4.34203",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7996",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121385,
            "s_seats": 45043,
            "j_seats": 6424,
            "f_seats": 15680,
            "r_seats": 7570,
            "cargo_demand": 396869
        }
    },
    {
        "iata": "WOL",
        "icao": "YWOL",
        "airportName": "Shellharbour Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Shellharbour",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-34.5611",
        "longitude": "150.789001",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4367",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5967",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 148092,
            "s_seats": 38651,
            "j_seats": 36905,
            "f_seats": 11818,
            "r_seats": 8941,
            "cargo_demand": 488052
        }
    },
    {
        "iata": "WON",
        "icao": "YWDL",
        "airportName": "Wondoola Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Wondoola",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-18.57500076",
        "longitude": "140.8919983",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4035",
                "runway_width": "",
                "runway_material": "C"
            }
        ],
        "demand": {
            "y_seats": 1028,
            "s_seats": 1501,
            "j_seats": 1608,
            "f_seats": 319,
            "r_seats": 119,
            "cargo_demand": 83036
        }
    },
    {
        "iata": "WOT",
        "icao": "RCWA",
        "airportName": "Wang-an Airport",
        "location_country": "Taiwan",
        "location_city": "Wang'an",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "23.369108",
        "longitude": "119.503598",
        "region": "AS",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3051",
                "runway_width": "76",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 1562,
            "s_seats": 2917,
            "j_seats": 1081,
            "f_seats": 364,
            "r_seats": 58,
            "cargo_demand": 63291
        }
    },
    {
        "iata": "WOW",
        "icao": "PAUO",
        "airportName": "Willow Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Willow",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "61.75419998",
        "longitude": "-150.052002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4400",
                "runway_width": "75",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 3276,
            "s_seats": 1972,
            "j_seats": 1985,
            "f_seats": 446,
            "r_seats": 162,
            "cargo_demand": 7891
        }
    },
    {
        "iata": "WPA",
        "icao": "SCAS",
        "airportName": "Cabo Juan Roman Airfield",
        "location_country": "Chile",
        "location_city": "Puerto Aisen",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-45.39920044",
        "longitude": "-72.67030334",
        "region": "SA",
        "runway": [
            {
                "runway_name": "7/25",
                "runway_length": "4167",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1867,
            "s_seats": 2479,
            "j_seats": 1900,
            "f_seats": 486,
            "r_seats": 86,
            "cargo_demand": 60171
        }
    },
    {
        "iata": "WPC",
        "icao": "CZPC",
        "airportName": "Pincher Creek Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Pincher Creek",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.52059937",
        "longitude": "-113.9970016",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6600",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 199770,
            "s_seats": 44275,
            "j_seats": 51201,
            "f_seats": 11759,
            "r_seats": 8822,
            "cargo_demand": 103972
        }
    },
    {
        "iata": "WPK",
        "icao": "YWMP",
        "airportName": "Wrotham Park Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Wrotham Park",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-16.6583004",
        "longitude": "144.0019989",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3001",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2294,
            "s_seats": 2282,
            "j_seats": 1626,
            "f_seats": 53,
            "r_seats": 93,
            "cargo_demand": 64608
        }
    },
    {
        "iata": "WPR",
        "icao": "SCFM",
        "airportName": "Capitan Fuentes Martinez Airport",
        "location_country": "Chile",
        "location_city": "Porvenir",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-53.25370026",
        "longitude": "-70.31919861",
        "region": "SA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2614",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "09/27",
                "runway_length": "8202",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55236,
            "s_seats": 40230,
            "j_seats": 16962,
            "f_seats": 5250,
            "r_seats": 4061,
            "cargo_demand": 56576
        }
    },
    {
        "iata": "WPU",
        "icao": "SCGZ",
        "airportName": "Guardiamarina Zanartu Airport",
        "location_country": "Chile",
        "location_city": "Puerto Williams",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-54.93109894",
        "longitude": "-67.626297",
        "region": "SA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4724",
                "runway_width": "95",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69388,
            "s_seats": 46037,
            "j_seats": 31059,
            "f_seats": 6898,
            "r_seats": 3817,
            "cargo_demand": 304432
        }
    },
    {
        "iata": "WRB",
        "icao": "KWRB",
        "airportName": "Robins Air Force Base",
        "location_country": "United States",
        "location_city": "Georgia",
        "location_state": "Warner Robins",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "32.64009857",
        "longitude": "-83.59190369",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "12001",
                "runway_width": "300",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 409139,
            "s_seats": 85158,
            "j_seats": 112803,
            "f_seats": 49249,
            "r_seats": 10000,
            "cargo_demand": 20260838
        }
    },
    {
        "iata": "WRE",
        "icao": "NZWR",
        "airportName": "Whangarei Airport",
        "location_country": "New Zealand",
        "location_city": "Whangarei",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-35.7682991",
        "longitude": "174.3650055",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3599",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "1558",
                "runway_width": "197",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 176463,
            "s_seats": 34037,
            "j_seats": 15486,
            "f_seats": 18939,
            "r_seats": 8873,
            "cargo_demand": 310588
        }
    },
    {
        "iata": "WRG",
        "icao": "PAWG",
        "airportName": "Wrangell Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Wrangell",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.48429871",
        "longitude": "-132.3699951",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5999",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109656,
            "s_seats": 49138,
            "j_seats": 20792,
            "f_seats": 5328,
            "r_seats": 6619,
            "cargo_demand": 404852
        }
    },
    {
        "iata": "WRI",
        "icao": "KWRI",
        "airportName": "McGuire Air Force Base",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Wrightstown",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.0155983",
        "longitude": "-74.59169769",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10001",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "7140",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63359,
            "s_seats": 38730,
            "j_seats": 45964,
            "f_seats": 12407,
            "r_seats": 8273,
            "cargo_demand": 433838
        }
    },
    {
        "iata": "WRL",
        "icao": "KWRL",
        "airportName": "Worland Municipal Airport",
        "location_country": "United States",
        "location_city": "Wyoming",
        "location_state": "Worland",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.965698",
        "longitude": "-107.950996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2240",
                "runway_width": "60",
                "runway_material": "GRS"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2501",
                "runway_width": "60",
                "runway_material": "GRS"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7005",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79498,
            "s_seats": 30165,
            "j_seats": 27398,
            "f_seats": 3155,
            "r_seats": 4476,
            "cargo_demand": 309421
        }
    },
    {
        "iata": "WRN",
        "icao": "YWDG",
        "airportName": "Windarling Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Windarling",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-30.031667",
        "longitude": "119.39",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6070",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2177,
            "s_seats": 1972,
            "j_seats": 1224,
            "f_seats": 278,
            "r_seats": 155,
            "cargo_demand": 54690
        }
    },
    {
        "iata": "WRO",
        "icao": "EPWR",
        "airportName": "Copernicus Airport Wroc\u0142aw",
        "location_country": "Poland",
        "location_city": "Wroc\u0142aw",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "51.10269928",
        "longitude": "16.88579941",
        "region": "EU",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "8212",
                "runway_width": "147",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 383687,
            "s_seats": 81647,
            "j_seats": 34046,
            "f_seats": 47163,
            "r_seats": 10000,
            "cargo_demand": 19056467
        }
    },
    {
        "iata": "WRT",
        "icao": "EGNO",
        "airportName": "Warton Aerodrome",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Warton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.745098",
        "longitude": "-2.88306",
        "region": "EU",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7946",
                "runway_width": "151",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4189",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116080,
            "s_seats": 25702,
            "j_seats": 22972,
            "f_seats": 19672,
            "r_seats": 4077,
            "cargo_demand": 151663
        }
    },
    {
        "iata": "WRW",
        "icao": "YWWG",
        "airportName": "Warrawagine Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Warrawagine Station",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.84420013",
        "longitude": "120.7020035",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6561",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6561",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1843,
            "s_seats": 2397,
            "j_seats": 1793,
            "f_seats": 482,
            "r_seats": 76,
            "cargo_demand": 61369
        }
    },
    {
        "iata": "WRY",
        "icao": "EGEW",
        "airportName": "Westray Airport",
        "location_country": "United Kingdom",
        "location_city": "Scotland",
        "location_state": "Westray",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "59.35029984",
        "longitude": "-2.950000048",
        "region": "EU",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "954",
                "runway_width": "59",
                "runway_material": "Grass"
            },
            {
                "runway_name": "09/27",
                "runway_length": "1729",
                "runway_width": "59",
                "runway_material": "Graded Hardcore"
            },
            {
                "runway_name": "13/31",
                "runway_length": "1381",
                "runway_width": "59",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 1417,
            "s_seats": 2885,
            "j_seats": 1269,
            "f_seats": 213,
            "r_seats": 50,
            "cargo_demand": 73262
        }
    },
    {
        "iata": "WRZ",
        "icao": "VCCW",
        "airportName": "Weerawila Airport",
        "location_country": "Sri Lanka",
        "location_city": "Weerawila",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "6.254489899",
        "longitude": "81.23519897",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4000",
                "runway_width": "93",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3785,
            "s_seats": 1555,
            "j_seats": 1275,
            "f_seats": 64,
            "r_seats": 58,
            "cargo_demand": 90420
        }
    },
    {
        "iata": "WSD",
        "icao": "KWSD",
        "airportName": "Condron Army Airfield",
        "location_country": "United States",
        "location_city": "New Mexico",
        "location_state": "White Sands",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "32.34149933",
        "longitude": "-106.4029999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6125",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1569,
            "s_seats": 1934,
            "j_seats": 1678,
            "f_seats": 44,
            "r_seats": 162,
            "cargo_demand": 79660
        }
    },
    {
        "iata": "WSF",
        "icao": "PACS",
        "airportName": "Cape Sarichef Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Cape Sarichef",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "54.583934",
        "longitude": "-164.905727",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "1900",
                "runway_width": "90",
                "runway_material": "GRAVEL"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3500",
                "runway_width": "120",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 2487,
            "s_seats": 2108,
            "j_seats": 1103,
            "f_seats": 228,
            "r_seats": 26,
            "cargo_demand": 14825
        }
    },
    {
        "iata": "WSG",
        "icao": "KAFJ",
        "airportName": "Washington County Airport",
        "location_country": "United States",
        "location_city": "Pennsylvania",
        "location_state": "Washington",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.13650131",
        "longitude": "-80.29019928",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2657,
            "s_seats": 2225,
            "j_seats": 1172,
            "f_seats": 164,
            "r_seats": 196,
            "cargo_demand": 9393
        }
    },
    {
        "iata": "WSH",
        "icao": "KHWV",
        "airportName": "Brookhaven Airport",
        "location_country": "United States",
        "location_city": "New York",
        "location_state": "Shirley",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "40.82189941",
        "longitude": "-72.86940002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4200",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "4224",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 2557,
            "s_seats": 2897,
            "j_seats": 1806,
            "f_seats": 202,
            "r_seats": 148,
            "cargo_demand": 72690
        }
    },
    {
        "iata": "WSN",
        "icao": "PFWS",
        "airportName": "South Naknek Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "South Naknek",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "58.703202",
        "longitude": "-157.006373",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2264",
                "runway_width": "60",
                "runway_material": "GRVL-F"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3314",
                "runway_width": "60",
                "runway_material": "GRVL-DIRT-F"
            }
        ],
        "demand": {
            "y_seats": 1582,
            "s_seats": 2679,
            "j_seats": 1449,
            "f_seats": 458,
            "r_seats": 175,
            "cargo_demand": 51201
        }
    },
    {
        "iata": "WSR",
        "icao": "WASW",
        "airportName": "Wasior Airport",
        "location_country": "Indonesia",
        "location_city": "Wasior",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-2.721",
        "longitude": "134.5061",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "1755",
                "runway_width": "98",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 2867,
            "s_seats": 1684,
            "j_seats": 1258,
            "f_seats": 278,
            "r_seats": 70,
            "cargo_demand": 40251
        }
    },
    {
        "iata": "WST",
        "icao": "KWST",
        "airportName": "Westerly State Airport",
        "location_country": "United States",
        "location_city": "Rhode Island",
        "location_state": "Westerly",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "41.34960175",
        "longitude": "-71.80339813",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4010",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3960",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1238,
            "s_seats": 1747,
            "j_seats": 1038,
            "f_seats": 208,
            "r_seats": 123,
            "cargo_demand": 65581
        }
    },
    {
        "iata": "WSY",
        "icao": "YSHR",
        "airportName": "Whitsunday Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Airlie Beach / Shute Harbour",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.277221",
        "longitude": "148.755556",
        "region": "OC",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3608",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 2493,
            "s_seats": 2025,
            "j_seats": 1075,
            "f_seats": 41,
            "r_seats": 156,
            "cargo_demand": 25771
        }
    },
    {
        "iata": "WSZ",
        "icao": "NZWS",
        "airportName": "Westport Airport",
        "location_country": "New Zealand",
        "location_city": "Westport",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-41.73809814",
        "longitude": "171.5809937",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4200",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172817,
            "s_seats": 43415,
            "j_seats": 24402,
            "f_seats": 17815,
            "r_seats": 9158,
            "cargo_demand": 492178
        }
    },
    {
        "iata": "WTB",
        "icao": "YBWW",
        "airportName": "Toowoomba Wellcamp Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Toowoomba",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.558332",
        "longitude": "151.793335",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "9416",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68637,
            "s_seats": 26922,
            "j_seats": 23775,
            "f_seats": 16393,
            "r_seats": 6073,
            "cargo_demand": 117559
        }
    },
    {
        "iata": "WTK",
        "icao": "PAWN",
        "airportName": "Noatak Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Noatak",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "67.56610107",
        "longitude": "-162.9750061",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4000",
                "runway_width": "60",
                "runway_material": "GRAVEL-G"
            }
        ],
        "demand": {
            "y_seats": 150209,
            "s_seats": 31890,
            "j_seats": 25323,
            "f_seats": 17944,
            "r_seats": 5676,
            "cargo_demand": 58095
        }
    },
    {
        "iata": "WTN",
        "icao": "EGXW",
        "airportName": "RAF Waddington",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Waddington",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.16619873",
        "longitude": "-0.523810983",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122888,
            "s_seats": 32347,
            "j_seats": 13711,
            "f_seats": 13754,
            "r_seats": 6701,
            "cargo_demand": 302808
        }
    },
    {
        "iata": "WTZ",
        "icao": "NZWT",
        "airportName": "Whitianga Aerodrome",
        "location_country": "New Zealand",
        "location_city": "Whitianga",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-36.83169937",
        "longitude": "175.6790009",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3822",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 2784,
            "s_seats": 2022,
            "j_seats": 1163,
            "f_seats": 245,
            "r_seats": 122,
            "cargo_demand": 16180
        }
    },
    {
        "iata": "WUD",
        "icao": "YWUD",
        "airportName": "Wudinna Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Wudinna",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-33.04330063",
        "longitude": "135.4470062",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3081",
                "runway_width": "59",
                "runway_material": "GVL"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4954",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2375,
            "s_seats": 2057,
            "j_seats": 1231,
            "f_seats": 405,
            "r_seats": 171,
            "cargo_demand": 97588
        }
    },
    {
        "iata": "WUH",
        "icao": "ZHHH",
        "airportName": "Wuhan Tianhe International Airport",
        "location_country": "China",
        "location_city": "Hubei",
        "location_state": "Wuhan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "30.7838",
        "longitude": "114.208",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04L/22R",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "04R/22L",
                "runway_length": "11811",
                "runway_width": "197",
                "runway_material": "CONC"
            }
        ],
        "demand": {
            "y_seats": 378259,
            "s_seats": 81236,
            "j_seats": 160580,
            "f_seats": 43930,
            "r_seats": 10000,
            "cargo_demand": 21900459
        }
    },
    {
        "iata": "WUI",
        "icao": "YMMI",
        "airportName": "Murrin Murrin Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Murrin Murrin",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.705299",
        "longitude": "121.89099",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6562",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1497,
            "s_seats": 2785,
            "j_seats": 1416,
            "f_seats": 129,
            "r_seats": 94,
            "cargo_demand": 83523
        }
    },
    {
        "iata": "WUN",
        "icao": "YWLU",
        "airportName": "Wiluna Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Wiluna",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.62919998",
        "longitude": "120.2210007",
        "region": "OC",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3999",
                "runway_width": "98",
                "runway_material": "SAN"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5942",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 98782,
            "s_seats": 48759,
            "j_seats": 58611,
            "f_seats": 8157,
            "r_seats": 8719,
            "cargo_demand": 217330
        }
    },
    {
        "iata": "WUU",
        "icao": "HSWW",
        "airportName": "Wau Airport",
        "location_country": "South Sudan",
        "location_city": "Wau",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "7.725830078",
        "longitude": "27.97500038",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7644",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 116644,
            "s_seats": 33274,
            "j_seats": 37231,
            "f_seats": 9701,
            "r_seats": 3270,
            "cargo_demand": 96870
        }
    },
    {
        "iata": "WUX",
        "icao": "ZSWX",
        "airportName": "Sunan Shuofang International Airport",
        "location_country": "China",
        "location_city": "Jiangsu",
        "location_state": "Wuxi / Suzhou",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "31.49440002",
        "longitude": "120.4290009",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10499",
                "runway_width": "164",
                "runway_material": "asphalt"
            }
        ],
        "demand": {
            "y_seats": 92313,
            "s_seats": 33637,
            "j_seats": 37410,
            "f_seats": 9607,
            "r_seats": 9474,
            "cargo_demand": 224469
        }
    },
    {
        "iata": "WVB",
        "icao": "FYWB",
        "airportName": "Walvis Bay Airport",
        "location_country": "Namibia",
        "location_city": "Walvis Bay",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.9799",
        "longitude": "14.6453",
        "region": "AF",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7001",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177328,
            "s_seats": 36201,
            "j_seats": 47225,
            "f_seats": 18050,
            "r_seats": 5408,
            "cargo_demand": 336912
        }
    },
    {
        "iata": "WVI",
        "icao": "KWVI",
        "airportName": "Watsonville Municipal Airport",
        "location_country": "United States",
        "location_city": "California",
        "location_state": "Watsonville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "36.93569946",
        "longitude": "-121.7900009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4501",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3999",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2445,
            "s_seats": 2298,
            "j_seats": 1027,
            "f_seats": 202,
            "r_seats": 63,
            "cargo_demand": 1964
        }
    },
    {
        "iata": "WVK",
        "icao": "FMSK",
        "airportName": "Manakara Airport",
        "location_country": "Madagascar",
        "location_city": "Manakara",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-22.119699",
        "longitude": "48.021702",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3886",
                "runway_width": "79",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 82050,
            "s_seats": 34005,
            "j_seats": 25387,
            "f_seats": 7093,
            "r_seats": 4431,
            "cargo_demand": 337673
        }
    },
    {
        "iata": "WVL",
        "icao": "KWVL",
        "airportName": "Waterville Robert LaFleur Airport",
        "location_country": "United States",
        "location_city": "Maine",
        "location_state": "Waterville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.53319931",
        "longitude": "-69.67549896",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2301",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2698,
            "s_seats": 1983,
            "j_seats": 1473,
            "f_seats": 213,
            "r_seats": 145,
            "cargo_demand": 59125
        }
    },
    {
        "iata": "WVN",
        "icao": "EDWI",
        "airportName": "JadeWeserAirport",
        "location_country": "Germany",
        "location_city": "Lower Saxony",
        "location_state": "Wilhelmshaven",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "53.50222015",
        "longitude": "8.052222252",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4787",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2018",
                "runway_width": "49",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3573,
            "s_seats": 2093,
            "j_seats": 1004,
            "f_seats": 254,
            "r_seats": 152,
            "cargo_demand": 16638
        }
    },
    {
        "iata": "WWA",
        "icao": "PAWS",
        "airportName": "Wasilla Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Wasilla",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.57170105",
        "longitude": "-149.5399933",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3700",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "03S/21S",
                "runway_length": "1690",
                "runway_width": "60",
                "runway_material": "TURF-GRVL-G"
            }
        ],
        "demand": {
            "y_seats": 178102,
            "s_seats": 29128,
            "j_seats": 51699,
            "f_seats": 15135,
            "r_seats": 7209,
            "cargo_demand": 274418
        }
    },
    {
        "iata": "WWD",
        "icao": "KWWD",
        "airportName": "Cape May Airport",
        "location_country": "United States",
        "location_city": "New Jersey",
        "location_state": "Wildwood",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.00849915",
        "longitude": "-74.90830231",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4998",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4998",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160664,
            "s_seats": 46218,
            "j_seats": 37183,
            "f_seats": 9864,
            "r_seats": 3673,
            "cargo_demand": 246966
        }
    },
    {
        "iata": "WWI",
        "icao": "YWWI",
        "airportName": "Woodie Woodie Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Woodie Woodie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-21.66279984",
        "longitude": "121.2340012",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4593",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 1427,
            "s_seats": 2915,
            "j_seats": 1960,
            "f_seats": 489,
            "r_seats": 60,
            "cargo_demand": 75362
        }
    },
    {
        "iata": "WWK",
        "icao": "AYWK",
        "airportName": "Wewak Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Wewak",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-3.583830118",
        "longitude": "143.6690063",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5234",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63272,
            "s_seats": 34717,
            "j_seats": 55133,
            "f_seats": 16205,
            "r_seats": 7407,
            "cargo_demand": 421008
        }
    },
    {
        "iata": "WWR",
        "icao": "KWWR",
        "airportName": "West Woodward Airport",
        "location_country": "United States",
        "location_city": "Oklahoma",
        "location_state": "Woodward",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.438",
        "longitude": "-99.5226667",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2501",
                "runway_width": "60",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5500",
                "runway_width": "150",
                "runway_material": "PER"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5502",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 187552,
            "s_seats": 34335,
            "j_seats": 46276,
            "f_seats": 8444,
            "r_seats": 5243,
            "cargo_demand": 150112
        }
    },
    {
        "iata": "WWT",
        "icao": "PAEW",
        "airportName": "Newtok Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Newtok",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "60.93909836",
        "longitude": "-164.6410065",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "2202",
                "runway_width": "35",
                "runway_material": "GRVL-F"
            }
        ],
        "demand": {
            "y_seats": 1593,
            "s_seats": 2178,
            "j_seats": 1100,
            "f_seats": 367,
            "r_seats": 30,
            "cargo_demand": 79266
        }
    },
    {
        "iata": "WWY",
        "icao": "YWWL",
        "airportName": "West Wyalong Airport",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "West Wyalong",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.93719864",
        "longitude": "147.1909943",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2559",
                "runway_width": "60",
                "runway_material": "GVL"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5200",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102587,
            "s_seats": 37635,
            "j_seats": 10896,
            "f_seats": 19398,
            "r_seats": 7659,
            "cargo_demand": 476702
        }
    },
    {
        "iata": "WYA",
        "icao": "YWHA",
        "airportName": "Whyalla Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Whyalla",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.05889893",
        "longitude": "137.5140076",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4619",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5531",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 120449,
            "s_seats": 38149,
            "j_seats": 50956,
            "f_seats": 6752,
            "r_seats": 5939,
            "cargo_demand": 382616
        }
    },
    {
        "iata": "WYN",
        "icao": "YWYM",
        "airportName": "Wyndham Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Wyndham",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-15.51140022",
        "longitude": "128.1529999",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5272",
                "runway_width": "59",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3287",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 1979,
            "s_seats": 2038,
            "j_seats": 1762,
            "f_seats": 345,
            "r_seats": 39,
            "cargo_demand": 84241
        }
    },
    {
        "iata": "WYS",
        "icao": "KWYS",
        "airportName": "Yellowstone Airport",
        "location_country": "United States",
        "location_city": "Montana",
        "location_state": "West Yellowstone",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.68840027",
        "longitude": "-111.1179962",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8399",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177468,
            "s_seats": 33362,
            "j_seats": 34195,
            "f_seats": 9103,
            "r_seats": 8964,
            "cargo_demand": 225748
        }
    },
    {
        "iata": "WZA",
        "icao": "DGLW",
        "airportName": "Wa Airport",
        "location_country": "Ghana",
        "location_city": "Upper West Region",
        "location_state": "Wa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "10.08269978",
        "longitude": "-2.507689953",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6612",
                "runway_width": "145",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3606,
            "s_seats": 2269,
            "j_seats": 1010,
            "f_seats": 291,
            "r_seats": 106,
            "cargo_demand": 99476
        }
    },
    {
        "iata": "XAP",
        "icao": "SBCH",
        "airportName": "Serafin Enoss Bertaso Airport",
        "location_country": "Brazil",
        "location_city": "Santa Catarina",
        "location_state": "Chapeco",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.13419914",
        "longitude": "-52.65660095",
        "region": "SA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "6768",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181023,
            "s_seats": 38471,
            "j_seats": 49993,
            "f_seats": 3953,
            "r_seats": 7055,
            "cargo_demand": 303976
        }
    },
    {
        "iata": "XBJ",
        "icao": "OIMB",
        "airportName": "Birjand International Airport",
        "location_country": "Iran",
        "location_city": "Birjand",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.89810181",
        "longitude": "59.26610184",
        "region": "AS",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7093",
                "runway_width": "82",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "9521",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56474,
            "s_seats": 36440,
            "j_seats": 18791,
            "f_seats": 18065,
            "r_seats": 7950,
            "cargo_demand": 199588
        }
    },
    {
        "iata": "XCH",
        "icao": "YPXM",
        "airportName": "Christmas Island Airport",
        "location_country": "Australia",
        "location_city": "Christmas Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-10.4506",
        "longitude": "105.690002",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6900",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 160113,
            "s_seats": 28006,
            "j_seats": 52904,
            "f_seats": 19716,
            "r_seats": 7929,
            "cargo_demand": 444100
        }
    },
    {
        "iata": "XCM",
        "icao": "CYCK",
        "airportName": "Chatham-Kent Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Chatham-Kent",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "42.3064003",
        "longitude": "-82.08190155",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2089,
            "s_seats": 2315,
            "j_seats": 1590,
            "f_seats": 47,
            "r_seats": 56,
            "cargo_demand": 66299
        }
    },
    {
        "iata": "XCR",
        "icao": "LFOK",
        "airportName": "Ch\u00e2lons Vatry Airport",
        "location_country": "France",
        "location_city": "Champagne-Ardenne",
        "location_state": "Ch\u00e2lons-en-Champagne",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.773333",
        "longitude": "4.206111",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "12664",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 166811,
            "s_seats": 37328,
            "j_seats": 31870,
            "f_seats": 15428,
            "r_seats": 7471,
            "cargo_demand": 71288
        }
    },
    {
        "iata": "XFW",
        "icao": "EDHI",
        "airportName": "Hamburg Finkenwerder Airport",
        "location_country": "Germany",
        "location_city": "Hamburg",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.53527832",
        "longitude": "9.83555603",
        "region": "EU",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "8629",
                "runway_width": "148",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 179960,
            "s_seats": 39724,
            "j_seats": 28859,
            "f_seats": 4825,
            "r_seats": 3092,
            "cargo_demand": 158492
        }
    },
    {
        "iata": "XGN",
        "icao": "FNXA",
        "airportName": "Xangongo Airport",
        "location_country": "Angola",
        "location_city": "Xangongo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-16.7553997",
        "longitude": "14.96529961",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7400",
                "runway_width": "97",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 141666,
            "s_seats": 49651,
            "j_seats": 59790,
            "f_seats": 16950,
            "r_seats": 3408,
            "cargo_demand": 168645
        }
    },
    {
        "iata": "XGR",
        "icao": "CYLU",
        "airportName": "Kangiqsualujjuaq (Georges River) Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Kangiqsualujjuaq",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.71139908",
        "longitude": "-65.99279785",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3521",
                "runway_width": "100",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 103119,
            "s_seats": 44420,
            "j_seats": 44708,
            "f_seats": 5498,
            "r_seats": 8783,
            "cargo_demand": 369112
        }
    },
    {
        "iata": "XIC",
        "icao": "ZUXC",
        "airportName": "Xichang Qingshan Airport",
        "location_country": "China",
        "location_city": "Sichuan",
        "location_state": "Xichang",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.9890995",
        "longitude": "102.1839981",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "11811",
                "runway_width": "164",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 176760,
            "s_seats": 43036,
            "j_seats": 25848,
            "f_seats": 5979,
            "r_seats": 4091,
            "cargo_demand": 314617
        }
    },
    {
        "iata": "XIY",
        "icao": "ZLXY",
        "airportName": "Xi'an Xianyang International Airport",
        "location_country": "China",
        "location_city": "Shaanxi",
        "location_state": "Xi'an",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "34.447102",
        "longitude": "108.751999",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "12470",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 171345,
            "s_seats": 82523,
            "j_seats": 144438,
            "f_seats": 21860,
            "r_seats": 10000,
            "cargo_demand": 12883481
        }
    },
    {
        "iata": "XJD",
        "icao": "OTBH",
        "airportName": "Al Udeid Air Base",
        "location_country": "Qatar",
        "location_city": "Al Rayyan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.11730003",
        "longitude": "51.31499863",
        "region": "AS",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "12303",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 103762,
            "s_seats": 36562,
            "j_seats": 31120,
            "f_seats": 12864,
            "r_seats": 7723,
            "cargo_demand": 46044
        }
    },
    {
        "iata": "XJM",
        "icao": "OPMA",
        "airportName": "Mangla Airport",
        "location_country": "Pakistan",
        "location_city": "Mangla",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "33.05009842",
        "longitude": "73.63839722",
        "region": "AS",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142329,
            "s_seats": 32010,
            "j_seats": 38566,
            "f_seats": 10997,
            "r_seats": 6640,
            "cargo_demand": 447686
        }
    },
    {
        "iata": "XKH",
        "icao": "VLXK",
        "airportName": "Xieng Khouang Airport",
        "location_country": "Laos",
        "location_city": "Phonsavan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "19.450001",
        "longitude": "103.157997",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8555",
                "runway_width": "130",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2920,
            "s_seats": 1519,
            "j_seats": 1151,
            "f_seats": 469,
            "r_seats": 180,
            "cargo_demand": 6398
        }
    },
    {
        "iata": "XKS",
        "icao": "CYAQ",
        "airportName": "Kasabonika Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Kasabonika",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.52470016",
        "longitude": "-88.64279938",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 191799,
            "s_seats": 42879,
            "j_seats": 43095,
            "f_seats": 6500,
            "r_seats": 7134,
            "cargo_demand": 53440
        }
    },
    {
        "iata": "XLB",
        "icao": "CZWH",
        "airportName": "Lac Brochet Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Lac Brochet",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.61750031",
        "longitude": "-101.4690018",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 141122,
            "s_seats": 47259,
            "j_seats": 7546,
            "f_seats": 4203,
            "r_seats": 9416,
            "cargo_demand": 279734
        }
    },
    {
        "iata": "XLS",
        "icao": "GOSS",
        "airportName": "Saint-Louis Airport",
        "location_country": "Senegal",
        "location_city": "Saint-Louis",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.049814",
        "longitude": "-16.461039",
        "region": "AF",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4300",
                "runway_width": "125",
                "runway_material": "GRE"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6230",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139145,
            "s_seats": 33801,
            "j_seats": 50323,
            "f_seats": 15859,
            "r_seats": 3245,
            "cargo_demand": 132229
        }
    },
    {
        "iata": "XMC",
        "icao": "YMCO",
        "airportName": "Mallacoota Airport",
        "location_country": "Australia",
        "location_city": "Victoria",
        "location_state": "Mallacoota",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-37.59830093",
        "longitude": "149.7200012",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2890",
                "runway_width": "",
                "runway_material": "X"
            },
            {
                "runway_name": "18/36",
                "runway_length": "3372",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2634,
            "s_seats": 2560,
            "j_seats": 1665,
            "f_seats": 103,
            "r_seats": 101,
            "cargo_demand": 22283
        }
    },
    {
        "iata": "XMD",
        "icao": "KMDS",
        "airportName": "Madison Municipal Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Madison",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.01599884",
        "longitude": "-97.08589935",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2600",
                "runway_width": "200",
                "runway_material": "GRS"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2984,
            "s_seats": 2499,
            "j_seats": 1973,
            "f_seats": 245,
            "r_seats": 37,
            "cargo_demand": 50234
        }
    },
    {
        "iata": "XMG",
        "icao": "VNMN",
        "airportName": "Mahendranagar Airport",
        "location_country": "Nepal",
        "location_city": "Bhimdatta (Mahendranagar)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.96319962",
        "longitude": "80.14790344",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "2900",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 1342,
            "s_seats": 1509,
            "j_seats": 1262,
            "f_seats": 187,
            "r_seats": 189,
            "cargo_demand": 3319
        }
    },
    {
        "iata": "XMH",
        "icao": "NTGI",
        "airportName": "Manihi Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Manihi",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.4368",
        "longitude": "-146.0700073",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3051",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 116752,
            "s_seats": 49873,
            "j_seats": 47693,
            "f_seats": 4563,
            "r_seats": 3841,
            "cargo_demand": 216834
        }
    },
    {
        "iata": "XML",
        "icao": "YMIN",
        "airportName": "Minlaton Airport",
        "location_country": "Australia",
        "location_city": "South Australia",
        "location_state": "Minlaton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-34.75",
        "longitude": "137.5330048",
        "region": "OC",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4560",
                "runway_width": "",
                "runway_material": "N"
            }
        ],
        "demand": {
            "y_seats": 1183,
            "s_seats": 2853,
            "j_seats": 1119,
            "f_seats": 248,
            "r_seats": 102,
            "cargo_demand": 5444
        }
    },
    {
        "iata": "XMN",
        "icao": "ZSAM",
        "airportName": "Xiamen Gaoqi International Airport",
        "location_country": "China",
        "location_city": "Fujian",
        "location_state": "Xiamen",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "24.54400063",
        "longitude": "118.1279984",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11155",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 406004,
            "s_seats": 84654,
            "j_seats": 100434,
            "f_seats": 35937,
            "r_seats": 10000,
            "cargo_demand": 11010883
        }
    },
    {
        "iata": "XMS",
        "icao": "SEMC",
        "airportName": "Edmundo Carvajal Airport",
        "location_country": "Ecuador",
        "location_city": "Macas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-2.299170017",
        "longitude": "-78.1207962",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "8202",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159014,
            "s_seats": 43475,
            "j_seats": 43643,
            "f_seats": 15247,
            "r_seats": 5295,
            "cargo_demand": 133373
        }
    },
    {
        "iata": "XMY",
        "icao": "YYMI",
        "airportName": "Yam Island Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Yam Island",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-9.901109695",
        "longitude": "142.776001",
        "region": "OC",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2329",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 2972,
            "s_seats": 1675,
            "j_seats": 1210,
            "f_seats": 132,
            "r_seats": 145,
            "cargo_demand": 81121
        }
    },
    {
        "iata": "XNA",
        "icao": "KXNA",
        "airportName": "Northwest Arkansas Regional Airport",
        "location_country": "United States",
        "location_city": "Arkansas",
        "location_state": "Fayetteville / Springdale",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.281898",
        "longitude": "-94.306801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8800",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 114164,
            "s_seats": 27144,
            "j_seats": 46845,
            "f_seats": 4907,
            "r_seats": 7260,
            "cargo_demand": 305130
        }
    },
    {
        "iata": "XNH",
        "icao": "ORTL",
        "airportName": "Ali Air Base",
        "location_country": "Iraq",
        "location_city": "Nasiriyah",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.935801",
        "longitude": "46.090099",
        "region": "AS",
        "runway": [
            {
                "runway_name": "12L/30R",
                "runway_length": "10935",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "9991",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 125769,
            "s_seats": 34046,
            "j_seats": 16835,
            "f_seats": 18154,
            "r_seats": 6699,
            "cargo_demand": 277514
        }
    },
    {
        "iata": "XPK",
        "icao": "CZFG",
        "airportName": "Pukatawagan Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Pukatawagan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.74919891",
        "longitude": "-101.2659988",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3000",
                "runway_width": "85",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 104325,
            "s_seats": 34377,
            "j_seats": 20808,
            "f_seats": 15389,
            "r_seats": 7380,
            "cargo_demand": 292831
        }
    },
    {
        "iata": "XPL",
        "icao": "MHSC",
        "airportName": "Soto Cano Air Base",
        "location_country": "Honduras",
        "location_city": "Comayagua",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "14.3824",
        "longitude": "-87.621201",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "8008",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92146,
            "s_seats": 44580,
            "j_seats": 43310,
            "f_seats": 6475,
            "r_seats": 7254,
            "cargo_demand": 61657
        }
    },
    {
        "iata": "XPP",
        "icao": "CZNG",
        "airportName": "Poplar River Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Poplar River",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.99652588",
        "longitude": "-97.27419376",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2500",
                "runway_width": "60",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 172142,
            "s_seats": 42491,
            "j_seats": 58495,
            "f_seats": 8014,
            "r_seats": 9921,
            "cargo_demand": 215898
        }
    },
    {
        "iata": "XPR",
        "icao": "KIEN",
        "airportName": "Pine Ridge Airport ",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Pine Ridge",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "43.02249908",
        "longitude": "-102.5110016",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3003",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5000",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3179,
            "s_seats": 1824,
            "j_seats": 1992,
            "f_seats": 163,
            "r_seats": 111,
            "cargo_demand": 56765
        }
    },
    {
        "iata": "XQP",
        "icao": "MRQP",
        "airportName": "Quepos La Managua Airport",
        "location_country": "Costa Rica",
        "location_city": "Quepos",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.443160057",
        "longitude": "-84.12979889",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3609",
                "runway_width": "36",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 98189,
            "s_seats": 31441,
            "j_seats": 18936,
            "f_seats": 12740,
            "r_seats": 5825,
            "cargo_demand": 485713
        }
    },
    {
        "iata": "XRH",
        "icao": "YSRI",
        "airportName": "RAAF Base Richmond",
        "location_country": "Australia",
        "location_city": "New South Wales",
        "location_state": "Richmond",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-33.600601",
        "longitude": "150.781006",
        "region": "OC",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7001",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186209,
            "s_seats": 37354,
            "j_seats": 19490,
            "f_seats": 10816,
            "r_seats": 6439,
            "cargo_demand": 160408
        }
    },
    {
        "iata": "XRR",
        "icao": "CYDM",
        "airportName": "Ross River Airport",
        "location_country": "Canada",
        "location_city": "Yukon",
        "location_state": "Ross River",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.97060013",
        "longitude": "-132.4230042",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 173059,
            "s_seats": 39881,
            "j_seats": 19657,
            "f_seats": 18588,
            "r_seats": 3928,
            "cargo_demand": 202207
        }
    },
    {
        "iata": "XRY",
        "icao": "LEJR",
        "airportName": "Jerez Airport",
        "location_country": "Spain",
        "location_city": "Andalusia",
        "location_state": "Jerez de la Frontera",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.744598",
        "longitude": "-6.06011",
        "region": "EU",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "7546",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131176,
            "s_seats": 41964,
            "j_seats": 14448,
            "f_seats": 5907,
            "r_seats": 8414,
            "cargo_demand": 108026
        }
    },
    {
        "iata": "XSB",
        "icao": "OMBY",
        "airportName": "Sir Bani Yas Airport",
        "location_country": "United Arab Emirates",
        "location_city": "Sir Bani Yas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.283611",
        "longitude": "52.580278",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8760",
                "runway_width": "",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155244,
            "s_seats": 30251,
            "j_seats": 9070,
            "f_seats": 13417,
            "r_seats": 8826,
            "cargo_demand": 252453
        }
    },
    {
        "iata": "XSC",
        "icao": "MBSC",
        "airportName": "South Caicos Airport",
        "location_country": "British Overseas Territory of Turks and Caicos Islands",
        "location_city": "South Caicos",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "21.51569939",
        "longitude": "-71.52850342",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5991",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190622,
            "s_seats": 28883,
            "j_seats": 14244,
            "f_seats": 15453,
            "r_seats": 5852,
            "cargo_demand": 335206
        }
    },
    {
        "iata": "XSD",
        "icao": "KTNX",
        "airportName": "Tonopah Test Range Airport ",
        "location_country": "United States",
        "location_city": "Nevada",
        "location_state": "Tonopah",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "37.79880142",
        "longitude": "-116.7809982",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "12002",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 2774,
            "s_seats": 1941,
            "j_seats": 1243,
            "f_seats": 445,
            "r_seats": 89,
            "cargo_demand": 30659
        }
    },
    {
        "iata": "XSI",
        "icao": "CZSN",
        "airportName": "South Indian Lake Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "South Indian Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.7928009",
        "longitude": "-98.90720367",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3509",
                "runway_width": "100",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 174492,
            "s_seats": 41524,
            "j_seats": 18507,
            "f_seats": 3598,
            "r_seats": 4376,
            "cargo_demand": 188495
        }
    },
    {
        "iata": "XSP",
        "icao": "WSSL",
        "airportName": "Seletar Airport",
        "location_country": "Singapore",
        "location_city": "",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "1.416949987",
        "longitude": "103.8679962",
        "region": "AS",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6023",
                "runway_width": "151",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125617,
            "s_seats": 27178,
            "j_seats": 21357,
            "f_seats": 2534,
            "r_seats": 5840,
            "cargo_demand": 276481
        }
    },
    {
        "iata": "XTA",
        "icao": "KXTA",
        "airportName": "Homey Airport",
        "location_country": "United States",
        "location_city": "Southern Nevada",
        "location_state": "Groom Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.23500061",
        "longitude": "-115.810997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "5420",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "14/32",
                "runway_length": "12000",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 108446,
            "s_seats": 33823,
            "j_seats": 5488,
            "f_seats": 18710,
            "r_seats": 4561,
            "cargo_demand": 194712
        }
    },
    {
        "iata": "XTG",
        "icao": "YTGM",
        "airportName": "Thargomindah Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Thargomindah",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-27.9864006",
        "longitude": "143.8110046",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2776",
                "runway_width": "98",
                "runway_material": "GVL"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4800",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121225,
            "s_seats": 46740,
            "j_seats": 42144,
            "f_seats": 9890,
            "r_seats": 8812,
            "cargo_demand": 476097
        }
    },
    {
        "iata": "XTL",
        "icao": "CYBQ",
        "airportName": "Tadoule Lake Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Tadoule Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.7061",
        "longitude": "-98.512199",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3200",
                "runway_width": "100",
                "runway_material": "CRUSHED ROCK"
            },
            {
                "runway_name": "07/25",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 112056,
            "s_seats": 37610,
            "j_seats": 49391,
            "f_seats": 3183,
            "r_seats": 9357,
            "cargo_demand": 191846
        }
    },
    {
        "iata": "XTO",
        "icao": "YTAM",
        "airportName": "Taroom Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Taroom",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.80170059",
        "longitude": "149.8999939",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3579",
                "runway_width": "",
                "runway_material": "C"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3608",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 2534,
            "s_seats": 1663,
            "j_seats": 1523,
            "f_seats": 112,
            "r_seats": 28,
            "cargo_demand": 71917
        }
    },
    {
        "iata": "XTR",
        "icao": "YTAA",
        "airportName": "Tara Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Tara",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-27.15670013",
        "longitude": "150.477005",
        "region": "OC",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4803",
                "runway_width": "",
                "runway_material": "X"
            }
        ],
        "demand": {
            "y_seats": 3655,
            "s_seats": 2111,
            "j_seats": 1307,
            "f_seats": 376,
            "r_seats": 64,
            "cargo_demand": 73238
        }
    },
    {
        "iata": "XUZ",
        "icao": "ZSXZ",
        "airportName": "Xuzhou Guanyin Airport",
        "location_country": "China",
        "location_city": "Jiangsu",
        "location_state": "Xuzhou",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "34.059056",
        "longitude": "117.555278",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "11548",
                "runway_width": "147",
                "runway_material": "concrete"
            }
        ],
        "demand": {
            "y_seats": 67653,
            "s_seats": 29309,
            "j_seats": 58865,
            "f_seats": 3372,
            "r_seats": 7636,
            "cargo_demand": 218648
        }
    },
    {
        "iata": "XYR",
        "icao": "AYED",
        "airportName": "Edwaki Airport",
        "location_country": "Papua New Guinea",
        "location_city": "Yellow River",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-3.883986",
        "longitude": "141.792234",
        "region": "OC",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2415",
                "runway_width": "98",
                "runway_material": "Grassed yellow clay"
            }
        ],
        "demand": {
            "y_seats": 1405,
            "s_seats": 2739,
            "j_seats": 1159,
            "f_seats": 465,
            "r_seats": 113,
            "cargo_demand": 16410
        }
    },
    {
        "iata": "YAB",
        "icao": "CYAB",
        "airportName": "Arctic Bay Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Arctic Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "73.006101",
        "longitude": "-85.04616",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3935",
                "runway_width": "98",
                "runway_material": "Gravel"
            }
        ],
        "demand": {
            "y_seats": 2572,
            "s_seats": 2515,
            "j_seats": 1827,
            "f_seats": 151,
            "r_seats": 137,
            "cargo_demand": 10668
        }
    },
    {
        "iata": "YAC",
        "icao": "CYAC",
        "airportName": "Cat Lake Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Cat Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.72719955",
        "longitude": "-91.82440186",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3900",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 58036,
            "s_seats": 37315,
            "j_seats": 21164,
            "f_seats": 15977,
            "r_seats": 6237,
            "cargo_demand": 72698
        }
    },
    {
        "iata": "YAG",
        "icao": "CYAG",
        "airportName": "Fort Frances Municipal Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Fort Frances",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.65420151",
        "longitude": "-93.43969727",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2600",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 128439,
            "s_seats": 46415,
            "j_seats": 8894,
            "f_seats": 5159,
            "r_seats": 5712,
            "cargo_demand": 280773
        }
    },
    {
        "iata": "YAH",
        "icao": "CYAH",
        "airportName": "La Grande-4 Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "La Grande-4",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.75469971",
        "longitude": "-73.6753006",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 176518,
            "s_seats": 33700,
            "j_seats": 23321,
            "f_seats": 2089,
            "r_seats": 9886,
            "cargo_demand": 190446
        }
    },
    {
        "iata": "YAI",
        "icao": "SCCH",
        "airportName": "General Bernardo O'Higgins Airport",
        "location_country": "Chile",
        "location_city": "Chillan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-36.58250046",
        "longitude": "-72.03140259",
        "region": "SA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5740",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 127632,
            "s_seats": 41997,
            "j_seats": 14255,
            "f_seats": 13471,
            "r_seats": 7050,
            "cargo_demand": 86026
        }
    },
    {
        "iata": "YAK",
        "icao": "PAYA",
        "airportName": "Yakutat Airport",
        "location_country": "United States",
        "location_city": "Alaska",
        "location_state": "Yakutat",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "59.508717",
        "longitude": "-139.660435",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6475",
                "runway_width": "150",
                "runway_material": "CONC-F"
            },
            {
                "runway_name": "11/29",
                "runway_length": "7745",
                "runway_width": "150",
                "runway_material": "ASPH-G"
            }
        ],
        "demand": {
            "y_seats": 138677,
            "s_seats": 34959,
            "j_seats": 27714,
            "f_seats": 6320,
            "r_seats": 9048,
            "cargo_demand": 440766
        }
    },
    {
        "iata": "YAL",
        "icao": "CYAL",
        "airportName": "Alert Bay Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Alert Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.5821991",
        "longitude": "-126.9160004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2900",
                "runway_width": "75",
                "runway_material": "ASPHALT"
            }
        ],
        "demand": {
            "y_seats": 56846,
            "s_seats": 38668,
            "j_seats": 16598,
            "f_seats": 18043,
            "r_seats": 6606,
            "cargo_demand": 490758
        }
    },
    {
        "iata": "YAM",
        "icao": "CYAM",
        "airportName": "Sault Ste. Marie Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Sault Ste. Marie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.485001",
        "longitude": "-84.509399",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56256,
            "s_seats": 42383,
            "j_seats": 13862,
            "f_seats": 12926,
            "r_seats": 4729,
            "cargo_demand": 183167
        }
    },
    {
        "iata": "YAO",
        "icao": "FKKY",
        "airportName": "Yaounde Airport",
        "location_country": "Cameroon",
        "location_city": "Yaounde",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "3.83604002",
        "longitude": "11.52350044",
        "region": "AF",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6519",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 86736,
            "s_seats": 32903,
            "j_seats": 37783,
            "f_seats": 16324,
            "r_seats": 6430,
            "cargo_demand": 256030
        }
    },
    {
        "iata": "YAP",
        "icao": "PTYA",
        "airportName": "Yap International Airport",
        "location_country": "Federated States of Micronesia",
        "location_city": "Yap",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.49891",
        "longitude": "138.082993",
        "region": "OC",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138256,
            "s_seats": 36420,
            "j_seats": 12172,
            "f_seats": 1556,
            "r_seats": 9289,
            "cargo_demand": 108814
        }
    },
    {
        "iata": "YAR",
        "icao": "CYAD",
        "airportName": "La Grande-3 Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "La Grande-3",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "53.57170105",
        "longitude": "-76.1964035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 2088,
            "s_seats": 2931,
            "j_seats": 1532,
            "f_seats": 201,
            "r_seats": 5,
            "cargo_demand": 96434
        }
    },
    {
        "iata": "YAT",
        "icao": "CYAT",
        "airportName": "Attawapiskat Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Attawapiskat",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.92750168",
        "longitude": "-82.43190002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 94622,
            "s_seats": 25870,
            "j_seats": 28986,
            "f_seats": 8038,
            "r_seats": 5212,
            "cargo_demand": 113811
        }
    },
    {
        "iata": "YAY",
        "icao": "CYAY",
        "airportName": "St. Anthony Airport",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "St. Anthony",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.39189911",
        "longitude": "-56.08309937",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101920,
            "s_seats": 31125,
            "j_seats": 9245,
            "f_seats": 13762,
            "r_seats": 6191,
            "cargo_demand": 472111
        }
    },
    {
        "iata": "YAZ",
        "icao": "CYAZ",
        "airportName": "Tofino/Long Beach Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Tofino",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.079833",
        "longitude": "-125.775583",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4999",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4997",
                "runway_width": "100",
                "runway_material": "CON"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 89133,
            "s_seats": 34666,
            "j_seats": 59029,
            "f_seats": 3919,
            "r_seats": 3396,
            "cargo_demand": 102671
        }
    },
    {
        "iata": "YBA",
        "icao": "CYBA",
        "airportName": "Banff Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Banff",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "51.208222",
        "longitude": "-115.540624",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3000",
                "runway_width": "190",
                "runway_material": "TURF/SNOW"
            }
        ],
        "demand": {
            "y_seats": 2942,
            "s_seats": 2298,
            "j_seats": 1277,
            "f_seats": 429,
            "r_seats": 8,
            "cargo_demand": 48851
        }
    },
    {
        "iata": "YBB",
        "icao": "CYBB",
        "airportName": "Kugaaruk Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Kugaaruk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "68.534401",
        "longitude": "-89.808098",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 125104,
            "s_seats": 44083,
            "j_seats": 21577,
            "f_seats": 3956,
            "r_seats": 9190,
            "cargo_demand": 489929
        }
    },
    {
        "iata": "YBC",
        "icao": "CYBC",
        "airportName": "Baie-Comeau Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Baie-Comeau",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.1325",
        "longitude": "-68.204399",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154934,
            "s_seats": 48568,
            "j_seats": 32439,
            "f_seats": 8508,
            "r_seats": 9513,
            "cargo_demand": 406290
        }
    },
    {
        "iata": "YBE",
        "icao": "CYBE",
        "airportName": "Uranium City Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Uranium City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "59.56140137",
        "longitude": "-108.4810028",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "06/24",
                "runway_length": "3930",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 139316,
            "s_seats": 35202,
            "j_seats": 22883,
            "f_seats": 8367,
            "r_seats": 3681,
            "cargo_demand": 50943
        }
    },
    {
        "iata": "YBG",
        "icao": "CYBG",
        "airportName": "Canadian Forces Base Bagotville",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "La Baie (Bagotville)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.33060074",
        "longitude": "-70.99639893",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "CON"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166228,
            "s_seats": 44776,
            "j_seats": 46926,
            "f_seats": 7036,
            "r_seats": 7963,
            "cargo_demand": 207834
        }
    },
    {
        "iata": "YBK",
        "icao": "CYBK",
        "airportName": "Baker Lake Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Baker Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.29889679",
        "longitude": "-96.07779694",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16T/34T",
                "runway_length": "4200",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 51776,
            "s_seats": 48859,
            "j_seats": 10594,
            "f_seats": 17700,
            "r_seats": 5535,
            "cargo_demand": 73942
        }
    },
    {
        "iata": "YBL",
        "icao": "CYBL",
        "airportName": "Campbell River Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Campbell River",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.950802",
        "longitude": "-125.271004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6499",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193857,
            "s_seats": 49889,
            "j_seats": 40731,
            "f_seats": 11332,
            "r_seats": 9906,
            "cargo_demand": 164972
        }
    },
    {
        "iata": "YBR",
        "icao": "CYBR",
        "airportName": "Brandon Municipal Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Brandon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.91",
        "longitude": "-99.951897",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2795",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3000",
                "runway_width": "60",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 198542,
            "s_seats": 25234,
            "j_seats": 33451,
            "f_seats": 14437,
            "r_seats": 8239,
            "cargo_demand": 367155
        }
    },
    {
        "iata": "YBT",
        "icao": "CYBT",
        "airportName": "Brochet Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Brochet",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "57.8894",
        "longitude": "-101.679001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3500",
                "runway_width": "90",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 146948,
            "s_seats": 28958,
            "j_seats": 9690,
            "f_seats": 11418,
            "r_seats": 5476,
            "cargo_demand": 299887
        }
    },
    {
        "iata": "YBV",
        "icao": "CYBV",
        "airportName": "Berens River Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Berens River",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.358898",
        "longitude": "-97.018303",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2900",
                "runway_width": "75",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 168095,
            "s_seats": 26929,
            "j_seats": 57948,
            "f_seats": 19125,
            "r_seats": 4769,
            "cargo_demand": 441767
        }
    },
    {
        "iata": "YBX",
        "icao": "CYBX",
        "airportName": "Lourdes-de-Blanc-Sablon Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Blanc-Sablon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.4435997",
        "longitude": "-57.18529892",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 60732,
            "s_seats": 29242,
            "j_seats": 38734,
            "f_seats": 10430,
            "r_seats": 8986,
            "cargo_demand": 299889
        }
    },
    {
        "iata": "YBY",
        "icao": "CYBF",
        "airportName": "Bonnyville Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Bonnyville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.304199",
        "longitude": "-110.744003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4433",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187637,
            "s_seats": 39145,
            "j_seats": 52120,
            "f_seats": 16261,
            "r_seats": 5952,
            "cargo_demand": 259215
        }
    },
    {
        "iata": "YCB",
        "icao": "CYCB",
        "airportName": "Cambridge Bay Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Cambridge Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "69.10810089",
        "longitude": "-105.1380005",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13T/31T",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 94749,
            "s_seats": 47426,
            "j_seats": 7756,
            "f_seats": 4052,
            "r_seats": 4846,
            "cargo_demand": 374916
        }
    },
    {
        "iata": "YCC",
        "icao": "CYCC",
        "airportName": "Cornwall Regional Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Cornwall",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.09280014",
        "longitude": "-74.56330109",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118559,
            "s_seats": 44311,
            "j_seats": 11257,
            "f_seats": 11066,
            "r_seats": 8775,
            "cargo_demand": 188284
        }
    },
    {
        "iata": "YCD",
        "icao": "CYCD",
        "airportName": "Nanaimo Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Nanaimo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "49.05497",
        "longitude": "-123.869863",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6602",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 488022,
            "s_seats": 82996,
            "j_seats": 103113,
            "f_seats": 64187,
            "r_seats": 10000,
            "cargo_demand": 18031682
        }
    },
    {
        "iata": "YCE",
        "icao": "CYCE",
        "airportName": "Centralia/James T. Field Memorial Aerodrome",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Centralia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.285599",
        "longitude": "-81.508301",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5012",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4400",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 79680,
            "s_seats": 48608,
            "j_seats": 49120,
            "f_seats": 7577,
            "r_seats": 8755,
            "cargo_demand": 494285
        }
    },
    {
        "iata": "YCG",
        "icao": "CYCG",
        "airportName": "West Kootenay Regional Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Castlegar",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.29639816",
        "longitude": "-117.6320038",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5300",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 169162,
            "s_seats": 34484,
            "j_seats": 24286,
            "f_seats": 8934,
            "r_seats": 4110,
            "cargo_demand": 202817
        }
    },
    {
        "iata": "YCH",
        "icao": "CYCH",
        "airportName": "Miramichi Airport",
        "location_country": "Canada",
        "location_city": "New Brunswick",
        "location_state": "Miramichi",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.007801",
        "longitude": "-65.449203",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "10006",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "7500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5513",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87800,
            "s_seats": 28736,
            "j_seats": 13367,
            "f_seats": 19868,
            "r_seats": 4128,
            "cargo_demand": 236935
        }
    },
    {
        "iata": "YCL",
        "icao": "CYCL",
        "airportName": "Charlo Airport",
        "location_country": "Canada",
        "location_city": "New Brunswick",
        "location_state": "Charlo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.990799",
        "longitude": "-66.330299",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72988,
            "s_seats": 26698,
            "j_seats": 37186,
            "f_seats": 6730,
            "r_seats": 8326,
            "cargo_demand": 55690
        }
    },
    {
        "iata": "YCM",
        "icao": "CYSN",
        "airportName": "St. Catharines/Niagara District Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "St. Catharines",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.19169998",
        "longitude": "-79.17169952",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2500",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "2000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125648,
            "s_seats": 42497,
            "j_seats": 47937,
            "f_seats": 3898,
            "r_seats": 3620,
            "cargo_demand": 224277
        }
    },
    {
        "iata": "YCN",
        "icao": "CYCN",
        "airportName": "Cochrane Aerodrome",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Cochrane",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.10559845",
        "longitude": "-81.01360321",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100112,
            "s_seats": 37924,
            "j_seats": 46452,
            "f_seats": 4409,
            "r_seats": 3338,
            "cargo_demand": 132769
        }
    },
    {
        "iata": "YCO",
        "icao": "CYCO",
        "airportName": "Kugluktuk Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Kugluktuk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "67.816704",
        "longitude": "-115.143997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12T/30T",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 149498,
            "s_seats": 47494,
            "j_seats": 48704,
            "f_seats": 12713,
            "r_seats": 8038,
            "cargo_demand": 404984
        }
    },
    {
        "iata": "YCQ",
        "icao": "CYCQ",
        "airportName": "Chetwynd Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Chetwynd",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.68719864",
        "longitude": "-121.6269989",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4484",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72304,
            "s_seats": 43243,
            "j_seats": 35861,
            "f_seats": 19904,
            "r_seats": 4049,
            "cargo_demand": 299046
        }
    },
    {
        "iata": "YCR",
        "icao": "CYCR",
        "airportName": "Cross Lake (Charlie Sinclair Memorial) Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Cross Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.61059952",
        "longitude": "-97.76080322",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3990",
                "runway_width": "95",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 76953,
            "s_seats": 48531,
            "j_seats": 23967,
            "f_seats": 11150,
            "r_seats": 9045,
            "cargo_demand": 216462
        }
    },
    {
        "iata": "YCS",
        "icao": "CYCS",
        "airportName": "Chesterfield Inlet Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Chesterfield Inlet",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "63.34690094",
        "longitude": "-90.73110199",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12T/30T",
                "runway_length": "3600",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 173408,
            "s_seats": 30683,
            "j_seats": 42164,
            "f_seats": 3292,
            "r_seats": 8043,
            "cargo_demand": 205593
        }
    },
    {
        "iata": "YCT",
        "icao": "CYCT",
        "airportName": "Coronation Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Coronation",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "52.07500076",
        "longitude": "-111.4449997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2534,
            "s_seats": 1926,
            "j_seats": 1758,
            "f_seats": 209,
            "r_seats": 171,
            "cargo_demand": 65966
        }
    },
    {
        "iata": "YCW",
        "icao": "CYCW",
        "airportName": "Chilliwack Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Chilliwack",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "49.15280151",
        "longitude": "-121.939003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3990",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1550,
            "s_seats": 2649,
            "j_seats": 1741,
            "f_seats": 78,
            "r_seats": 2,
            "cargo_demand": 17555
        }
    },
    {
        "iata": "YCY",
        "icao": "CYCY",
        "airportName": "Clyde River Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Clyde River",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "70.48609924",
        "longitude": "-68.51670074",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02T/20T",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 140026,
            "s_seats": 31557,
            "j_seats": 57833,
            "f_seats": 4716,
            "r_seats": 7028,
            "cargo_demand": 65049
        }
    },
    {
        "iata": "YCZ",
        "icao": "CYCZ",
        "airportName": "Fairmont Hot Springs Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Fairmont Hot Springs",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "50.331052",
        "longitude": "-115.873739",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6003",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2646,
            "s_seats": 2992,
            "j_seats": 1120,
            "f_seats": 477,
            "r_seats": 22,
            "cargo_demand": 87436
        }
    },
    {
        "iata": "YDA",
        "icao": "CYDA",
        "airportName": "Dawson City Airport",
        "location_country": "Canada",
        "location_city": "Yukon",
        "location_state": "Dawson City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.04309845",
        "longitude": "-139.128006",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 155457,
            "s_seats": 37222,
            "j_seats": 45885,
            "f_seats": 11805,
            "r_seats": 8014,
            "cargo_demand": 348006
        }
    },
    {
        "iata": "YDB",
        "icao": "CYDB",
        "airportName": "Burwash Airport",
        "location_country": "Canada",
        "location_city": "Yukon",
        "location_state": "Burwash Landing",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.37110138",
        "longitude": "-139.0410004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 158544,
            "s_seats": 43895,
            "j_seats": 5785,
            "f_seats": 5876,
            "r_seats": 3207,
            "cargo_demand": 446543
        }
    },
    {
        "iata": "YDF",
        "icao": "CYDF",
        "airportName": "Deer Lake Regional Airport",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "Deer Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.21080017",
        "longitude": "-57.39139938",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8005",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99042,
            "s_seats": 32492,
            "j_seats": 54965,
            "f_seats": 17455,
            "r_seats": 7954,
            "cargo_demand": 130032
        }
    },
    {
        "iata": "YDG",
        "icao": "CYID",
        "airportName": "Digby/Annapolis Regional Airport",
        "location_country": "Canada",
        "location_city": "Nova Scotia",
        "location_state": "Digby",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.54584504",
        "longitude": "-65.78542471",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3950",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 75750,
            "s_seats": 36813,
            "j_seats": 56655,
            "f_seats": 2298,
            "r_seats": 3035,
            "cargo_demand": 331939
        }
    },
    {
        "iata": "YDL",
        "icao": "CYDL",
        "airportName": "Dease Lake Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Dease Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "58.42219925",
        "longitude": "-130.0319977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6000",
                "runway_width": "90",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3048,
            "s_seats": 1978,
            "j_seats": 1856,
            "f_seats": 478,
            "r_seats": 88,
            "cargo_demand": 61593
        }
    },
    {
        "iata": "YDN",
        "icao": "CYDN",
        "airportName": "Lt. Col W.G. (Billy) Barker VC Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Dauphin",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.10079956",
        "longitude": "-100.052002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2000",
                "runway_width": "60",
                "runway_material": "GVL"
            },
            {
                "runway_name": "08/26",
                "runway_length": "2700",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176006,
            "s_seats": 28014,
            "j_seats": 33947,
            "f_seats": 9172,
            "r_seats": 9785,
            "cargo_demand": 78543
        }
    },
    {
        "iata": "YDO",
        "icao": "CYDO",
        "airportName": "Dolbeau-Saint-Felicien Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Dolbeau-Mistassini",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.7784996",
        "longitude": "-72.375",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4971",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173465,
            "s_seats": 41026,
            "j_seats": 53470,
            "f_seats": 16919,
            "r_seats": 9898,
            "cargo_demand": 444073
        }
    },
    {
        "iata": "YDP",
        "icao": "CYDP",
        "airportName": "Nain Airport",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "Nain",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.54919815",
        "longitude": "-61.68030167",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2000",
                "runway_width": "75",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 141136,
            "s_seats": 27324,
            "j_seats": 32707,
            "f_seats": 9510,
            "r_seats": 4252,
            "cargo_demand": 310252
        }
    },
    {
        "iata": "YDQ",
        "icao": "CYDQ",
        "airportName": "Dawson Creek Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Dawson Creek",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.74229813",
        "longitude": "-120.1829987",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 187759,
            "s_seats": 38228,
            "j_seats": 53349,
            "f_seats": 4452,
            "r_seats": 8681,
            "cargo_demand": 236620
        }
    },
    {
        "iata": "YDT",
        "icao": "CZBB",
        "airportName": "Boundary Bay Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Vancouver",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.0742",
        "longitude": "-123.012001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4865",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "5606",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5605",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112673,
            "s_seats": 43384,
            "j_seats": 50796,
            "f_seats": 14518,
            "r_seats": 3598,
            "cargo_demand": 440449
        }
    },
    {
        "iata": "YDV",
        "icao": "CZTA",
        "airportName": "Bloodvein River Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Bloodvein",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "51.784568",
        "longitude": "-96.692305",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 3586,
            "s_seats": 2148,
            "j_seats": 1129,
            "f_seats": 321,
            "r_seats": 153,
            "cargo_demand": 53406
        }
    },
    {
        "iata": "YEC",
        "icao": "RKTY",
        "airportName": "Yecheon Air Base",
        "location_country": "South Korea",
        "location_city": "Yecheon",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "36.630373",
        "longitude": "128.34971",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 160178,
            "s_seats": 32960,
            "j_seats": 13606,
            "f_seats": 5103,
            "r_seats": 8894,
            "cargo_demand": 79330
        }
    },
    {
        "iata": "YEG",
        "icao": "CYEG",
        "airportName": "Edmonton International Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Edmonton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "53.30970001",
        "longitude": "-113.5800018",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "11000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "10200",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 255074,
            "s_seats": 99099,
            "j_seats": 157360,
            "f_seats": 44428,
            "r_seats": 10000,
            "cargo_demand": 3926004
        }
    },
    {
        "iata": "YEI",
        "icao": "LTBR",
        "airportName": "Yenisehir Airport",
        "location_country": "Turkey",
        "location_city": "Bursa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "40.25519943",
        "longitude": "29.56259918",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07L/25R",
                "runway_length": "9818",
                "runway_width": "148",
                "runway_material": "CON"
            },
            {
                "runway_name": "07R/25L",
                "runway_length": "9818",
                "runway_width": "98",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 118134,
            "s_seats": 45470,
            "j_seats": 55956,
            "f_seats": 18212,
            "r_seats": 6247,
            "cargo_demand": 263439
        }
    },
    {
        "iata": "YEK",
        "icao": "CYEK",
        "airportName": "Arviat Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Arviat",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.09420013",
        "longitude": "-94.07080078",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15T/33T",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 135169,
            "s_seats": 28528,
            "j_seats": 17393,
            "f_seats": 13264,
            "r_seats": 7318,
            "cargo_demand": 313272
        }
    },
    {
        "iata": "YEL",
        "icao": "CYEL",
        "airportName": "Elliot Lake Municipal Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Elliot Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.35139847",
        "longitude": "-82.56140137",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121153,
            "s_seats": 32628,
            "j_seats": 32476,
            "f_seats": 17622,
            "r_seats": 5089,
            "cargo_demand": 379934
        }
    },
    {
        "iata": "YEM",
        "icao": "CYEM",
        "airportName": "Manitowaning/Manitoulin East Municipal Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Manitowaning",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.842435",
        "longitude": "-81.857595",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134448,
            "s_seats": 49353,
            "j_seats": 44381,
            "f_seats": 16499,
            "r_seats": 9269,
            "cargo_demand": 127731
        }
    },
    {
        "iata": "YEN",
        "icao": "CYEN",
        "airportName": "Estevan Regional Aerodrome",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Estevan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.21030045",
        "longitude": "-102.9660034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51341,
            "s_seats": 26612,
            "j_seats": 54202,
            "f_seats": 1054,
            "r_seats": 7887,
            "cargo_demand": 438342
        }
    },
    {
        "iata": "YEO",
        "icao": "EGDY",
        "airportName": "Royal Naval Air Station Yeovilton",
        "location_country": "United Kingdom",
        "location_city": "England",
        "location_state": "Yeovilton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.00939941",
        "longitude": "-2.638819933",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4797",
                "runway_width": "151",
                "runway_material": "CON"
            },
            {
                "runway_name": "08/26",
                "runway_length": "7580",
                "runway_width": "151",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 102210,
            "s_seats": 39722,
            "j_seats": 49472,
            "f_seats": 15418,
            "r_seats": 6742,
            "cargo_demand": 139271
        }
    },
    {
        "iata": "YER",
        "icao": "CYER",
        "airportName": "Fort Severn Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Fort Severn",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.01890182",
        "longitude": "-87.67610168",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 82748,
            "s_seats": 31462,
            "j_seats": 51245,
            "f_seats": 3949,
            "r_seats": 6096,
            "cargo_demand": 138902
        }
    },
    {
        "iata": "YES",
        "icao": "OISY",
        "airportName": "Yasuj Airport",
        "location_country": "Iran",
        "location_city": "Yasuj (Yasouj)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "30.70050049",
        "longitude": "51.54510117",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "8522",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55955,
            "s_seats": 25738,
            "j_seats": 40575,
            "f_seats": 16762,
            "r_seats": 6675,
            "cargo_demand": 480659
        }
    },
    {
        "iata": "YET",
        "icao": "CYET",
        "airportName": "Edson Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Edson",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.57889938",
        "longitude": "-116.4649963",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 166549,
            "s_seats": 36903,
            "j_seats": 28358,
            "f_seats": 6160,
            "r_seats": 3495,
            "cargo_demand": 133089
        }
    },
    {
        "iata": "YEU",
        "icao": "CYEU",
        "airportName": "Eureka Aerodrome",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Eureka",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "79.99469757",
        "longitude": "-85.81420136",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10T/28T",
                "runway_length": "4836",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 3024,
            "s_seats": 2399,
            "j_seats": 1226,
            "f_seats": 73,
            "r_seats": 106,
            "cargo_demand": 61120
        }
    },
    {
        "iata": "YEV",
        "icao": "CYEV",
        "airportName": "Inuvik (Mike Zubko) Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Inuvik",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "68.30419922",
        "longitude": "-133.4830017",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112242,
            "s_seats": 49470,
            "j_seats": 15214,
            "f_seats": 1021,
            "r_seats": 8587,
            "cargo_demand": 367478
        }
    },
    {
        "iata": "YEY",
        "icao": "CYEY",
        "airportName": "Amos/Magny Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Amos",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.563903",
        "longitude": "-78.249702",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 90797,
            "s_seats": 26473,
            "j_seats": 14612,
            "f_seats": 17523,
            "r_seats": 6581,
            "cargo_demand": 53613
        }
    },
    {
        "iata": "YFA",
        "icao": "CYFA",
        "airportName": "Fort Albany Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Fort Albany",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.20140076",
        "longitude": "-81.69689941",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 86507,
            "s_seats": 26613,
            "j_seats": 23438,
            "f_seats": 19816,
            "r_seats": 3953,
            "cargo_demand": 335989
        }
    },
    {
        "iata": "YFB",
        "icao": "CYFB",
        "airportName": "Iqaluit Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Iqaluit",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "63.756402",
        "longitude": "-68.555801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8605",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 125814,
            "s_seats": 49373,
            "j_seats": 21254,
            "f_seats": 7483,
            "r_seats": 9809,
            "cargo_demand": 379930
        }
    },
    {
        "iata": "YFC",
        "icao": "CYFC",
        "airportName": "Fredericton International Airport",
        "location_country": "Canada",
        "location_city": "New Brunswick",
        "location_state": "Fredericton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.8689003",
        "longitude": "-66.53720093",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8005",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 156689,
            "s_seats": 40719,
            "j_seats": 26286,
            "f_seats": 12829,
            "r_seats": 6633,
            "cargo_demand": 296480
        }
    },
    {
        "iata": "YFE",
        "icao": "CYFE",
        "airportName": "Forestville Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Forestville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.74610138",
        "longitude": "-69.09719849",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51297,
            "s_seats": 39605,
            "j_seats": 31038,
            "f_seats": 11558,
            "r_seats": 8481,
            "cargo_demand": 288716
        }
    },
    {
        "iata": "YFH",
        "icao": "CYFH",
        "airportName": "Fort Hope Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Eabametoong (Fort Hope)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.56190109",
        "longitude": "-87.90779877",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 98074,
            "s_seats": 42779,
            "j_seats": 23596,
            "f_seats": 5781,
            "r_seats": 4228,
            "cargo_demand": 464346
        }
    },
    {
        "iata": "YFO",
        "icao": "CYFO",
        "airportName": "Flin Flon Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Flin Flon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.67810059",
        "longitude": "-101.6819992",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152981,
            "s_seats": 37715,
            "j_seats": 37909,
            "f_seats": 14390,
            "r_seats": 7375,
            "cargo_demand": 320117
        }
    },
    {
        "iata": "YFR",
        "icao": "CYFR",
        "airportName": "Fort Resolution Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Fort Resolution",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.18080139",
        "longitude": "-113.6900024",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 159148,
            "s_seats": 44852,
            "j_seats": 41181,
            "f_seats": 14750,
            "r_seats": 3998,
            "cargo_demand": 409128
        }
    },
    {
        "iata": "YFS",
        "icao": "CYFS",
        "airportName": "Fort Simpson Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Fort Simpson",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.7602005",
        "longitude": "-121.2369995",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 64576,
            "s_seats": 45794,
            "j_seats": 5836,
            "f_seats": 9295,
            "r_seats": 5870,
            "cargo_demand": 337978
        }
    },
    {
        "iata": "YGB",
        "icao": "CYGB",
        "airportName": "Texada/Gillies Bay Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Gillies Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.69419861",
        "longitude": "-124.5179977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50174,
            "s_seats": 28834,
            "j_seats": 33632,
            "f_seats": 19360,
            "r_seats": 3272,
            "cargo_demand": 320643
        }
    },
    {
        "iata": "YGH",
        "icao": "CYGH",
        "airportName": "Fort Good Hope Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Fort Good Hope",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "66.24079895",
        "longitude": "-128.651001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3000",
                "runway_width": "98",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 51284,
            "s_seats": 25132,
            "j_seats": 23005,
            "f_seats": 19916,
            "r_seats": 3631,
            "cargo_demand": 345792
        }
    },
    {
        "iata": "YGJ",
        "icao": "RJOH",
        "airportName": "Miho-Yonago Airport",
        "location_country": "Japan",
        "location_city": "Honshu",
        "location_state": "Yonago",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "35.492199",
        "longitude": "133.235992",
        "region": "AS",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "8202",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193989,
            "s_seats": 43154,
            "j_seats": 54018,
            "f_seats": 13536,
            "r_seats": 5764,
            "cargo_demand": 229469
        }
    },
    {
        "iata": "YGK",
        "icao": "CYGK",
        "airportName": "Kingston/Norman Rogers Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Kingston",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.22529984",
        "longitude": "-76.59690094",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "3933",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104354,
            "s_seats": 28261,
            "j_seats": 35925,
            "f_seats": 7073,
            "r_seats": 3524,
            "cargo_demand": 109756
        }
    },
    {
        "iata": "YGL",
        "icao": "CYGL",
        "airportName": "La Grande Riviere Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Radisson",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.62530136",
        "longitude": "-77.70420074",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 117203,
            "s_seats": 46950,
            "j_seats": 39665,
            "f_seats": 17110,
            "r_seats": 6474,
            "cargo_demand": 100465
        }
    },
    {
        "iata": "YGM",
        "icao": "CYGM",
        "airportName": "Gimli Industrial Park Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Gimli",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.62810135",
        "longitude": "-97.04329681",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6800",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50774,
            "s_seats": 35556,
            "j_seats": 50753,
            "f_seats": 9966,
            "r_seats": 5383,
            "cargo_demand": 325134
        }
    },
    {
        "iata": "YGO",
        "icao": "CYGO",
        "airportName": "Gods Lake Narrows Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Gods Lake Narrows",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.55889893",
        "longitude": "-94.49140167",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3810",
                "runway_width": "100",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 156269,
            "s_seats": 48084,
            "j_seats": 52998,
            "f_seats": 16967,
            "r_seats": 6426,
            "cargo_demand": 499032
        }
    },
    {
        "iata": "YGP",
        "icao": "CYGP",
        "airportName": "Michel-Pouliot Gaspe Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Gaspe",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.77529907",
        "longitude": "-64.47859955",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118194,
            "s_seats": 45420,
            "j_seats": 16298,
            "f_seats": 5562,
            "r_seats": 4831,
            "cargo_demand": 485789
        }
    },
    {
        "iata": "YGQ",
        "icao": "CYGQ",
        "airportName": "Geraldton (Greenstone Regional) Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Greenstone (Geraldton)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.77830124",
        "longitude": "-86.93939972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 140499,
            "s_seats": 39098,
            "j_seats": 23221,
            "f_seats": 14479,
            "r_seats": 4280,
            "cargo_demand": 380152
        }
    },
    {
        "iata": "YGR",
        "icao": "CYGR",
        "airportName": "Iles-de-la-Madeleine Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Les Iles-de-la-Madeleine",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.42470169",
        "longitude": "-61.77809906",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3600",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56293,
            "s_seats": 36921,
            "j_seats": 36475,
            "f_seats": 6611,
            "r_seats": 6635,
            "cargo_demand": 449195
        }
    },
    {
        "iata": "YGT",
        "icao": "CYGT",
        "airportName": "Igloolik Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Igloolik",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "69.36470032",
        "longitude": "-81.81610107",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15T/33T",
                "runway_length": "3800",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 141700,
            "s_seats": 26363,
            "j_seats": 19007,
            "f_seats": 8398,
            "r_seats": 7819,
            "cargo_demand": 371511
        }
    },
    {
        "iata": "YGV",
        "icao": "CYGV",
        "airportName": "Havre Saint-Pierre Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Havre-Saint-Pierre",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.281898",
        "longitude": "-63.611401",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54185,
            "s_seats": 45520,
            "j_seats": 8097,
            "f_seats": 12343,
            "r_seats": 8121,
            "cargo_demand": 88429
        }
    },
    {
        "iata": "YGW",
        "icao": "CYGW",
        "airportName": "Kuujjuarapik Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Kuujjuarapik",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.2818985",
        "longitude": "-77.76529694",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5052",
                "runway_width": "150",
                "runway_material": "SAND/GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 128715,
            "s_seats": 49221,
            "j_seats": 17102,
            "f_seats": 10157,
            "r_seats": 7715,
            "cargo_demand": 396155
        }
    },
    {
        "iata": "YGX",
        "icao": "CYGX",
        "airportName": "Gillam Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Gillam",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.35749817",
        "longitude": "-94.71060181",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 167241,
            "s_seats": 49532,
            "j_seats": 8161,
            "f_seats": 1525,
            "r_seats": 4375,
            "cargo_demand": 367810
        }
    },
    {
        "iata": "YHB",
        "icao": "CYHB",
        "airportName": "Hudson Bay Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Hudson Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "52.81669998",
        "longitude": "-102.310997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1280,
            "s_seats": 1593,
            "j_seats": 1994,
            "f_seats": 264,
            "r_seats": 80,
            "cargo_demand": 13192
        }
    },
    {
        "iata": "YHD",
        "icao": "CYHD",
        "airportName": "Dryden Regional Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Dryden",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.831699",
        "longitude": "-92.744202",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2000",
                "runway_width": "75",
                "runway_material": "SAN"
            },
            {
                "runway_name": "12/30",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 88265,
            "s_seats": 25812,
            "j_seats": 18788,
            "f_seats": 12088,
            "r_seats": 7399,
            "cargo_demand": 153471
        }
    },
    {
        "iata": "YHE",
        "icao": "CYHE",
        "airportName": "Hope Aerodrome",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Hope",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.36830139",
        "longitude": "-121.4980011",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3960",
                "runway_width": "250",
                "runway_material": "TURF"
            }
        ],
        "demand": {
            "y_seats": 144843,
            "s_seats": 44463,
            "j_seats": 48387,
            "f_seats": 14993,
            "r_seats": 5130,
            "cargo_demand": 482967
        }
    },
    {
        "iata": "YHF",
        "icao": "CYHF",
        "airportName": "Hearst (Rene Fontaine) Municipal Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Hearst",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.71419907",
        "longitude": "-83.68609619",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66982,
            "s_seats": 27888,
            "j_seats": 50341,
            "f_seats": 5004,
            "r_seats": 9113,
            "cargo_demand": 67901
        }
    },
    {
        "iata": "YHI",
        "icao": "CYHI",
        "airportName": "Ulukhaktok/Holman Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Ulukhaktok",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "70.76280212",
        "longitude": "-117.8059998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06T/24T",
                "runway_length": "4300",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 89506,
            "s_seats": 35261,
            "j_seats": 37800,
            "f_seats": 1328,
            "r_seats": 8413,
            "cargo_demand": 239102
        }
    },
    {
        "iata": "YHK",
        "icao": "CYHK",
        "airportName": "Gjoa Haven Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Gjoa Haven",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "68.63559723",
        "longitude": "-95.84970093",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13T/31T",
                "runway_length": "4400",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 57108,
            "s_seats": 28689,
            "j_seats": 21071,
            "f_seats": 7181,
            "r_seats": 5806,
            "cargo_demand": 80970
        }
    },
    {
        "iata": "YHM",
        "icao": "CYHM",
        "airportName": "John C. Munro Hamilton International Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Hamilton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.17359924",
        "longitude": "-79.93499756",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6010",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "10006",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 89477,
            "s_seats": 33868,
            "j_seats": 42114,
            "f_seats": 12830,
            "r_seats": 8568,
            "cargo_demand": 296460
        }
    },
    {
        "iata": "YHN",
        "icao": "CYHN",
        "airportName": "Hornepayne Municipal Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Hornepayne",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.19309998",
        "longitude": "-84.7589035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3500",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54629,
            "s_seats": 47861,
            "j_seats": 49157,
            "f_seats": 16420,
            "r_seats": 5672,
            "cargo_demand": 396719
        }
    },
    {
        "iata": "YHO",
        "icao": "CYHO",
        "airportName": "Hopedale Airport",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "Hopedale",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.44829941",
        "longitude": "-60.22859955",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "2500",
                "runway_width": "75",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 108512,
            "s_seats": 38053,
            "j_seats": 20137,
            "f_seats": 13835,
            "r_seats": 6843,
            "cargo_demand": 119970
        }
    },
    {
        "iata": "YHR",
        "icao": "CYHR",
        "airportName": "Chevery Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Chevery",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.46889877",
        "longitude": "-59.63669968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78427,
            "s_seats": 43113,
            "j_seats": 40286,
            "f_seats": 17709,
            "r_seats": 9426,
            "cargo_demand": 413558
        }
    },
    {
        "iata": "YHT",
        "icao": "CYHT",
        "airportName": "Haines Junction Airport",
        "location_country": "Canada",
        "location_city": "Yukon",
        "location_state": "Haines Junction",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.78919983",
        "longitude": "-137.5460052",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 166360,
            "s_seats": 38868,
            "j_seats": 58089,
            "f_seats": 11419,
            "r_seats": 4474,
            "cargo_demand": 406426
        }
    },
    {
        "iata": "YHU",
        "icao": "CYHU",
        "airportName": "Montreal/Saint-Hubert Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Montreal",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.51750183",
        "longitude": "-73.41690063",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "7840",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "3920",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "2800",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 81256,
            "s_seats": 30900,
            "j_seats": 53709,
            "f_seats": 3715,
            "r_seats": 4740,
            "cargo_demand": 104385
        }
    },
    {
        "iata": "YHY",
        "icao": "CYHY",
        "airportName": "Hay River/Merlyn Carter Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Hay River",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.83969879",
        "longitude": "-115.7829971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "4001",
                "runway_width": "150",
                "runway_material": "COM"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168883,
            "s_seats": 44985,
            "j_seats": 50756,
            "f_seats": 7713,
            "r_seats": 6608,
            "cargo_demand": 252603
        }
    },
    {
        "iata": "YHZ",
        "icao": "CYHZ",
        "airportName": "Halifax Stanfield International Airport",
        "location_country": "Canada",
        "location_city": "Nova Scotia",
        "location_state": "Halifax",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "44.88079834",
        "longitude": "-63.50859833",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "10500",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "7700",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146140,
            "s_seats": 82370,
            "j_seats": 29431,
            "f_seats": 51461,
            "r_seats": 10000,
            "cargo_demand": 26358487
        }
    },
    {
        "iata": "YIA",
        "icao": "WAHI",
        "airportName": "Yogyakarta International Airport",
        "location_country": "Indonesia",
        "location_city": "Special Region of Yogyakarta",
        "location_state": "Kulon Progo Regency",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-7.905338",
        "longitude": "110.057264",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10663",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 197131,
            "s_seats": 39333,
            "j_seats": 33086,
            "f_seats": 9419,
            "r_seats": 7161,
            "cargo_demand": 333773
        }
    },
    {
        "iata": "YIB",
        "icao": "CYIB",
        "airportName": "Atikokan Municipal Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Atikokan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.77389908",
        "longitude": "-91.63860321",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 179005,
            "s_seats": 35308,
            "j_seats": 16045,
            "f_seats": 19179,
            "r_seats": 8523,
            "cargo_demand": 134720
        }
    },
    {
        "iata": "YIF",
        "icao": "CYIF",
        "airportName": "Saint-Augustin Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Saint-Augustin / Pakuashipi",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.21170044",
        "longitude": "-58.65829849",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4590",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 181442,
            "s_seats": 36475,
            "j_seats": 38651,
            "f_seats": 16222,
            "r_seats": 3059,
            "cargo_demand": 474497
        }
    },
    {
        "iata": "YIK",
        "icao": "CYIK",
        "airportName": "Ivujivik Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Ivujivik",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.41730118",
        "longitude": "-77.9253006",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3521",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 119410,
            "s_seats": 28535,
            "j_seats": 35533,
            "f_seats": 4829,
            "r_seats": 4925,
            "cargo_demand": 375600
        }
    },
    {
        "iata": "YIO",
        "icao": "CYIO",
        "airportName": "Pond Inlet Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Pond Inlet",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "72.6832962",
        "longitude": "-77.96669769",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02T/20T",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 182999,
            "s_seats": 46323,
            "j_seats": 40633,
            "f_seats": 13296,
            "r_seats": 6385,
            "cargo_demand": 441178
        }
    },
    {
        "iata": "YIP",
        "icao": "KYIP",
        "airportName": "Willow Run Airport",
        "location_country": "United States",
        "location_city": "Michigan",
        "location_state": "Detroit",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.23789978",
        "longitude": "-83.53040314",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "6655",
                "runway_width": "160",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "7526",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09L/27R",
                "runway_length": "7294",
                "runway_width": "160",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "6511",
                "runway_width": "160",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6914",
                "runway_width": "160",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57264,
            "s_seats": 44660,
            "j_seats": 46882,
            "f_seats": 1695,
            "r_seats": 3067,
            "cargo_demand": 230024
        }
    },
    {
        "iata": "YIV",
        "icao": "CYIV",
        "airportName": "Island Lake Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Island Lake / Garden Hill",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.85720062",
        "longitude": "-94.6536026",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 71805,
            "s_seats": 44316,
            "j_seats": 17183,
            "f_seats": 13139,
            "r_seats": 7454,
            "cargo_demand": 350194
        }
    },
    {
        "iata": "YJA",
        "icao": "CYJA",
        "airportName": "Jasper Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Jasper",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "52.996418",
        "longitude": "-118.060233",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3990",
                "runway_width": "150",
                "runway_material": "TURF"
            }
        ],
        "demand": {
            "y_seats": 3161,
            "s_seats": 2821,
            "j_seats": 1595,
            "f_seats": 170,
            "r_seats": 167,
            "cargo_demand": 66381
        }
    },
    {
        "iata": "YJF",
        "icao": "CYJF",
        "airportName": "Fort Liard Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Fort Liard",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.2358017",
        "longitude": "-123.4690018",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3000",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 59936,
            "s_seats": 37060,
            "j_seats": 38813,
            "f_seats": 5111,
            "r_seats": 9586,
            "cargo_demand": 250037
        }
    },
    {
        "iata": "YJN",
        "icao": "CYJN",
        "airportName": "Saint-Jean Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Saint-Jean-sur-Richelieu",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.29439926",
        "longitude": "-73.28109741",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "2474",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "2765",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172118,
            "s_seats": 32919,
            "j_seats": 15338,
            "f_seats": 2094,
            "r_seats": 3023,
            "cargo_demand": 453474
        }
    },
    {
        "iata": "YJT",
        "icao": "CYJT",
        "airportName": "Stephenville International Airport",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "Stephenville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.5442009",
        "longitude": "-58.54999924",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3900",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "09/27",
                "runway_length": "10000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54191,
            "s_seats": 32718,
            "j_seats": 52600,
            "f_seats": 16867,
            "r_seats": 8213,
            "cargo_demand": 81004
        }
    },
    {
        "iata": "YKA",
        "icao": "CYKA",
        "airportName": "Kamloops Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Kamloops",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.70220184",
        "longitude": "-120.4440002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2074",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "8000",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 130386,
            "s_seats": 31778,
            "j_seats": 13508,
            "f_seats": 7664,
            "r_seats": 6461,
            "cargo_demand": 251808
        }
    },
    {
        "iata": "YKC",
        "icao": "CYKC",
        "airportName": "Collins Bay Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Collins Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "58.23609924",
        "longitude": "-103.6780014",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5200",
                "runway_width": "100",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 1756,
            "s_seats": 2103,
            "j_seats": 1750,
            "f_seats": 447,
            "r_seats": 15,
            "cargo_demand": 73072
        }
    },
    {
        "iata": "YKD",
        "icao": "CYKM",
        "airportName": "Kincardine Municipal Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Kincardine",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.201401",
        "longitude": "-81.606697",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2087",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "4085",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3117,
            "s_seats": 2426,
            "j_seats": 1605,
            "f_seats": 168,
            "r_seats": 76,
            "cargo_demand": 80952
        }
    },
    {
        "iata": "YKF",
        "icao": "CYKF",
        "airportName": "Region of Waterloo International Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Kitchener / Cambridge / Waterloo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.46080017",
        "longitude": "-80.37860107",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "4100",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194982,
            "s_seats": 31463,
            "j_seats": 49261,
            "f_seats": 14331,
            "r_seats": 7351,
            "cargo_demand": 90117
        }
    },
    {
        "iata": "YKG",
        "icao": "CYAS",
        "airportName": "Kangirsuk Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Kangirsuk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.02719879",
        "longitude": "-69.99919891",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3521",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 150136,
            "s_seats": 27438,
            "j_seats": 49733,
            "f_seats": 13481,
            "r_seats": 7654,
            "cargo_demand": 120305
        }
    },
    {
        "iata": "YKJ",
        "icao": "CYKJ",
        "airportName": "Key Lake Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Key Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "57.2560997",
        "longitude": "-105.6179962",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5200",
                "runway_width": "180",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 162474,
            "s_seats": 38584,
            "j_seats": 42876,
            "f_seats": 18334,
            "r_seats": 9648,
            "cargo_demand": 267150
        }
    },
    {
        "iata": "YKL",
        "icao": "CYKL",
        "airportName": "Schefferville Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Schefferville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.80530167",
        "longitude": "-66.80529785",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198140,
            "s_seats": 41234,
            "j_seats": 55177,
            "f_seats": 17464,
            "r_seats": 8454,
            "cargo_demand": 143082
        }
    },
    {
        "iata": "YKM",
        "icao": "KYKM",
        "airportName": "Yakima Air Terminal",
        "location_country": "United States",
        "location_city": "Washington",
        "location_state": "Yakima",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.56819916",
        "longitude": "-120.5439987",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3835",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "7603",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 167312,
            "s_seats": 39145,
            "j_seats": 55905,
            "f_seats": 19738,
            "r_seats": 8754,
            "cargo_demand": 158487
        }
    },
    {
        "iata": "YKN",
        "icao": "KYKN",
        "airportName": "Chan Gurney Municipal Airport",
        "location_country": "United States",
        "location_city": "South Dakota",
        "location_state": "Yankton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.91669846",
        "longitude": "-97.3859024",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3380",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "6095",
                "runway_width": "100",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 73482,
            "s_seats": 48902,
            "j_seats": 37156,
            "f_seats": 12090,
            "r_seats": 8482,
            "cargo_demand": 265022
        }
    },
    {
        "iata": "YKO",
        "icao": "LTCW",
        "airportName": "Hakkari Yuksekova Airport",
        "location_country": "Turkey",
        "location_city": "Hakk\u00e2ri / Yuksekova",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.5497",
        "longitude": "44.2381",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "10499",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 116631,
            "s_seats": 46807,
            "j_seats": 17221,
            "f_seats": 12412,
            "r_seats": 3287,
            "cargo_demand": 423509
        }
    },
    {
        "iata": "YKQ",
        "icao": "CYKQ",
        "airportName": "Waskaganish Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Waskaganish",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.47330093",
        "longitude": "-78.75830078",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 112613,
            "s_seats": 46066,
            "j_seats": 23897,
            "f_seats": 2821,
            "r_seats": 3738,
            "cargo_demand": 198076
        }
    },
    {
        "iata": "YKS",
        "icao": "UEEE",
        "airportName": "Yakutsk Airport",
        "location_country": "Russia",
        "location_city": "Yakutia",
        "location_state": "Yakutsk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.09329987",
        "longitude": "129.7709961",
        "region": "AS",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "8202",
                "runway_width": "161",
                "runway_material": "CON"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "11155",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 50520,
            "s_seats": 44416,
            "j_seats": 54687,
            "f_seats": 7356,
            "r_seats": 7774,
            "cargo_demand": 300836
        }
    },
    {
        "iata": "YKX",
        "icao": "CYKX",
        "airportName": "Kirkland Lake Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Kirkland Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.21030045",
        "longitude": "-79.98139954",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 158967,
            "s_seats": 40790,
            "j_seats": 9489,
            "f_seats": 9532,
            "r_seats": 5310,
            "cargo_demand": 172003
        }
    },
    {
        "iata": "YKY",
        "icao": "CYKY",
        "airportName": "Kindersley Regional Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Kindersley",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.51750183",
        "longitude": "-109.1809998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3507",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2281",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 183642,
            "s_seats": 48178,
            "j_seats": 59042,
            "f_seats": 10362,
            "r_seats": 3543,
            "cargo_demand": 97023
        }
    },
    {
        "iata": "YKZ",
        "icao": "CYKZ",
        "airportName": "Buttonville Municipal Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Toronto",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.86220169",
        "longitude": "-79.37000275",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2694",
                "runway_width": "80",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3897",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173768,
            "s_seats": 30048,
            "j_seats": 6754,
            "f_seats": 15750,
            "r_seats": 8234,
            "cargo_demand": 450934
        }
    },
    {
        "iata": "YLB",
        "icao": "CYLB",
        "airportName": "Lac La Biche Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Lac La Biche",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "54.77030182",
        "longitude": "-112.0319977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5700",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2520,
            "s_seats": 2030,
            "j_seats": 1862,
            "f_seats": 173,
            "r_seats": 13,
            "cargo_demand": 36017
        }
    },
    {
        "iata": "YLD",
        "icao": "CYLD",
        "airportName": "Chapleau Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Chapleau",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.81999969",
        "longitude": "-83.34670258",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131331,
            "s_seats": 41026,
            "j_seats": 38173,
            "f_seats": 12566,
            "r_seats": 4893,
            "cargo_demand": 285643
        }
    },
    {
        "iata": "YLG",
        "icao": "YYAL",
        "airportName": "Yalgoo Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Yalgoo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-28.3553009",
        "longitude": "116.6839981",
        "region": "OC",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3608",
                "runway_width": "",
                "runway_material": "S"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4593",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 1225,
            "s_seats": 1662,
            "j_seats": 1870,
            "f_seats": 305,
            "r_seats": 177,
            "cargo_demand": 69099
        }
    },
    {
        "iata": "YLH",
        "icao": "CYLH",
        "airportName": "Lansdowne House Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Neskantaga (Lansdowne House Indian Band)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.1955986",
        "longitude": "-87.93419647",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 164138,
            "s_seats": 31978,
            "j_seats": 48753,
            "f_seats": 7121,
            "r_seats": 8170,
            "cargo_demand": 289196
        }
    },
    {
        "iata": "YLI",
        "icao": "EFYL",
        "airportName": "Ylivieska Airfield",
        "location_country": "Finland",
        "location_city": "Ylivieska",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.054722",
        "longitude": "24.725278",
        "region": "EU",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59058,
            "s_seats": 32064,
            "j_seats": 36713,
            "f_seats": 1685,
            "r_seats": 3802,
            "cargo_demand": 447344
        }
    },
    {
        "iata": "YLJ",
        "icao": "CYLJ",
        "airportName": "Meadow Lake Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Meadow Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.12530136",
        "longitude": "-108.5230026",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "2290",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 54502,
            "s_seats": 42739,
            "j_seats": 34066,
            "f_seats": 17125,
            "r_seats": 4927,
            "cargo_demand": 461467
        }
    },
    {
        "iata": "YLK",
        "icao": "CYLS",
        "airportName": "Lake Simcoe Regional Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Barrie / Orillia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "44.48529816",
        "longitude": "-79.55560303",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "6001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2716,
            "s_seats": 1589,
            "j_seats": 1453,
            "f_seats": 397,
            "r_seats": 196,
            "cargo_demand": 61599
        }
    },
    {
        "iata": "YLL",
        "icao": "CYLL",
        "airportName": "Lloydminster Airport",
        "location_country": "Canada",
        "location_city": "Alberta/Saskatchewan",
        "location_state": "Lloydminster",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.30920029",
        "longitude": "-110.072998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5577",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "1500",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 165764,
            "s_seats": 34690,
            "j_seats": 14243,
            "f_seats": 7228,
            "r_seats": 4808,
            "cargo_demand": 485194
        }
    },
    {
        "iata": "YLQ",
        "icao": "CYLQ",
        "airportName": "La Tuque Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "La Tuque",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "47.40969849",
        "longitude": "-72.78890228",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1886,
            "s_seats": 2236,
            "j_seats": 1810,
            "f_seats": 194,
            "r_seats": 20,
            "cargo_demand": 26108
        }
    },
    {
        "iata": "YLR",
        "icao": "CYLR",
        "airportName": "Leaf Rapids Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Leaf Rapids",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.51330185",
        "longitude": "-99.98529816",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111515,
            "s_seats": 43498,
            "j_seats": 52094,
            "f_seats": 10868,
            "r_seats": 4964,
            "cargo_demand": 453113
        }
    },
    {
        "iata": "YLT",
        "icao": "CYLT",
        "airportName": "Alert Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Alert",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "82.51779938",
        "longitude": "-62.2806015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05T/23T",
                "runway_length": "5500",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 133569,
            "s_seats": 30239,
            "j_seats": 35060,
            "f_seats": 8050,
            "r_seats": 9262,
            "cargo_demand": 132811
        }
    },
    {
        "iata": "YLW",
        "icao": "CYLW",
        "airportName": "Kelowna International Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Kelowna",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "49.9561",
        "longitude": "-119.377998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "8900",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 387523,
            "s_seats": 73314,
            "j_seats": 74076,
            "f_seats": 22617,
            "r_seats": 10000,
            "cargo_demand": 25064394
        }
    },
    {
        "iata": "YLY",
        "icao": "CYNJ",
        "airportName": "Langley Regional Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Langley",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "49.1008",
        "longitude": "-122.630997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2100",
                "runway_width": "75",
                "runway_material": "ASPHALT"
            },
            {
                "runway_name": "07/25",
                "runway_length": "2743",
                "runway_width": "75",
                "runway_material": "Asphalt"
            }
        ],
        "demand": {
            "y_seats": 1553,
            "s_seats": 2701,
            "j_seats": 1660,
            "f_seats": 63,
            "r_seats": 99,
            "cargo_demand": 24489
        }
    },
    {
        "iata": "YMA",
        "icao": "CYMA",
        "airportName": "Mayo Airport",
        "location_country": "Canada",
        "location_city": "Yukon",
        "location_state": "Mayo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "63.61640167",
        "longitude": "-135.8679962",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4856",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 154424,
            "s_seats": 27754,
            "j_seats": 33871,
            "f_seats": 9566,
            "r_seats": 6136,
            "cargo_demand": 105453
        }
    },
    {
        "iata": "YME",
        "icao": "CYME",
        "airportName": "Matane Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Matane",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.85689926",
        "longitude": "-67.45330048",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182124,
            "s_seats": 35843,
            "j_seats": 5894,
            "f_seats": 3852,
            "r_seats": 5080,
            "cargo_demand": 201305
        }
    },
    {
        "iata": "YMG",
        "icao": "CYMG",
        "airportName": "Manitouwadge Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Manitouwadge",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.08390045",
        "longitude": "-85.86060333",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3600",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 165171,
            "s_seats": 34249,
            "j_seats": 49839,
            "f_seats": 6426,
            "r_seats": 5848,
            "cargo_demand": 368512
        }
    },
    {
        "iata": "YMH",
        "icao": "CYMH",
        "airportName": "Mary's Harbour Airport",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "Mary's Harbour",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.30279922",
        "longitude": "-55.84719849",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2500",
                "runway_width": "75",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 117461,
            "s_seats": 27346,
            "j_seats": 45288,
            "f_seats": 19730,
            "r_seats": 9822,
            "cargo_demand": 324775
        }
    },
    {
        "iata": "YMJ",
        "icao": "CYMJ",
        "airportName": "CFB Moose Jaw",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Moose Jaw",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.33029938",
        "longitude": "-105.5589981",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3400",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11L/29R",
                "runway_length": "8326",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11R/29L",
                "runway_length": "7280",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 185332,
            "s_seats": 42888,
            "j_seats": 53271,
            "f_seats": 6929,
            "r_seats": 5127,
            "cargo_demand": 275431
        }
    },
    {
        "iata": "YML",
        "icao": "CYML",
        "airportName": "Charlevoix Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "La Malbaie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.59749985",
        "longitude": "-70.22389984",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "4500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 154471,
            "s_seats": 36717,
            "j_seats": 17528,
            "f_seats": 2730,
            "r_seats": 5140,
            "cargo_demand": 280682
        }
    },
    {
        "iata": "YMM",
        "icao": "CYMM",
        "airportName": "Fort McMurray International Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Fort McMurray",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.65330124",
        "longitude": "-111.2220001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7503",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68219,
            "s_seats": 28300,
            "j_seats": 6335,
            "f_seats": 3197,
            "r_seats": 7782,
            "cargo_demand": 408636
        }
    },
    {
        "iata": "YMN",
        "icao": "CYFT",
        "airportName": "Makkovik Airport",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "Makkovik",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.07690048",
        "longitude": "-59.18640137",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2500",
                "runway_width": "75",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 132276,
            "s_seats": 40882,
            "j_seats": 25822,
            "f_seats": 8160,
            "r_seats": 9570,
            "cargo_demand": 449740
        }
    },
    {
        "iata": "YMO",
        "icao": "CYMO",
        "airportName": "Moosonee Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Moosonee",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.29109955",
        "longitude": "-80.60780334",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "COM"
            }
        ],
        "demand": {
            "y_seats": 151234,
            "s_seats": 29479,
            "j_seats": 26722,
            "f_seats": 5099,
            "r_seats": 9090,
            "cargo_demand": 151920
        }
    },
    {
        "iata": "YMS",
        "icao": "SPMS",
        "airportName": "Moises Benzaquen Rengifo Airport",
        "location_country": "Peru",
        "location_city": "Yurimaguas",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-5.893770218",
        "longitude": "-76.11820221",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5912",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 110072,
            "s_seats": 39327,
            "j_seats": 49695,
            "f_seats": 17954,
            "r_seats": 5099,
            "cargo_demand": 279463
        }
    },
    {
        "iata": "YMT",
        "icao": "CYMT",
        "airportName": "Chibougamau/Chapais Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Chibougamau",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.77190018",
        "longitude": "-74.52809906",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6496",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61650,
            "s_seats": 31234,
            "j_seats": 22738,
            "f_seats": 9523,
            "r_seats": 7001,
            "cargo_demand": 76372
        }
    },
    {
        "iata": "YMW",
        "icao": "CYMW",
        "airportName": "Maniwaki Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Maniwaki",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "46.27280045",
        "longitude": "-75.99060059",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4920",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3738,
            "s_seats": 1632,
            "j_seats": 1674,
            "f_seats": 204,
            "r_seats": 118,
            "cargo_demand": 86000
        }
    },
    {
        "iata": "YMX",
        "icao": "CYMX",
        "airportName": "Montreal-Mirabel International Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Montreal",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.679501",
        "longitude": "-74.038696",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "12000",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "11/29",
                "runway_length": "8852",
                "runway_width": "200",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 88191,
            "s_seats": 25277,
            "j_seats": 24091,
            "f_seats": 16194,
            "r_seats": 9295,
            "cargo_demand": 204299
        }
    },
    {
        "iata": "YNA",
        "icao": "CYNA",
        "airportName": "Natashquan Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Natashquan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.18999863",
        "longitude": "-61.78919983",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4494",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135110,
            "s_seats": 35178,
            "j_seats": 34313,
            "f_seats": 1751,
            "r_seats": 8463,
            "cargo_demand": 262407
        }
    },
    {
        "iata": "YNB",
        "icao": "OEYN",
        "airportName": "Yanbu Airport",
        "location_country": "Saudi Arabia",
        "location_city": "Yanbu al Bahr (Yenbo)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.144199",
        "longitude": "38.0634",
        "region": "AS",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "10532",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 91833,
            "s_seats": 38598,
            "j_seats": 45159,
            "f_seats": 19371,
            "r_seats": 4461,
            "cargo_demand": 46381
        }
    },
    {
        "iata": "YNC",
        "icao": "CYNC",
        "airportName": "Wemindji Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Wemindji",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.01060104",
        "longitude": "-78.83110046",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3510",
                "runway_width": "100",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 104297,
            "s_seats": 44146,
            "j_seats": 56858,
            "f_seats": 1023,
            "r_seats": 4453,
            "cargo_demand": 182424
        }
    },
    {
        "iata": "YND",
        "icao": "CYND",
        "airportName": "Gatineau-Ottawa Executive Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Gatineau / Ottawa (ON)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.521702",
        "longitude": "-75.563599",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131604,
            "s_seats": 25996,
            "j_seats": 29481,
            "f_seats": 15451,
            "r_seats": 5239,
            "cargo_demand": 465939
        }
    },
    {
        "iata": "YNE",
        "icao": "CYNE",
        "airportName": "Norway House Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Norway House",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.95830154",
        "longitude": "-97.84420013",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3922",
                "runway_width": "100",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 197651,
            "s_seats": 27711,
            "j_seats": 6613,
            "f_seats": 14130,
            "r_seats": 7675,
            "cargo_demand": 210998
        }
    },
    {
        "iata": "YNG",
        "icao": "KYNG",
        "airportName": "Youngstown-Warren Regional Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Youngstown / Warren",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.26070023",
        "longitude": "-80.67910004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "9003",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "143/323",
                "runway_length": "3500",
                "runway_width": "60",
                "runway_material": "ASPH-F"
            }
        ],
        "demand": {
            "y_seats": 56908,
            "s_seats": 43032,
            "j_seats": 19534,
            "f_seats": 1737,
            "r_seats": 7002,
            "cargo_demand": 463788
        }
    },
    {
        "iata": "YNH",
        "icao": "CYNH",
        "airportName": "Hudson's Hope Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Hudson's Hope",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "56.03559875",
        "longitude": "-121.9759979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5200",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3976,
            "s_seats": 2112,
            "j_seats": 1776,
            "f_seats": 482,
            "r_seats": 82,
            "cargo_demand": 74652
        }
    },
    {
        "iata": "YNJ",
        "icao": "ZYYJ",
        "airportName": "Yanji Chaoyangchuan Airport",
        "location_country": "China",
        "location_city": "Jilin",
        "location_state": "Yanji",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.88280106",
        "longitude": "129.451004",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8530",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 55884,
            "s_seats": 25470,
            "j_seats": 58903,
            "f_seats": 7644,
            "r_seats": 9481,
            "cargo_demand": 350384
        }
    },
    {
        "iata": "YNL",
        "icao": "CYNL",
        "airportName": "Points North Landing Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Points North Landing",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.27669907",
        "longitude": "-104.0820007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6000",
                "runway_width": "100",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 122436,
            "s_seats": 45853,
            "j_seats": 33776,
            "f_seats": 8299,
            "r_seats": 7532,
            "cargo_demand": 53065
        }
    },
    {
        "iata": "YNM",
        "icao": "CYNM",
        "airportName": "Matagami Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Matagami",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.76169968",
        "longitude": "-77.80280304",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51113,
            "s_seats": 34911,
            "j_seats": 23145,
            "f_seats": 19369,
            "r_seats": 4347,
            "cargo_demand": 473738
        }
    },
    {
        "iata": "YNS",
        "icao": "CYHH",
        "airportName": "Nemiscau Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Nemiscau",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.69110107",
        "longitude": "-76.13559723",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 101789,
            "s_seats": 44483,
            "j_seats": 28421,
            "f_seats": 13256,
            "r_seats": 5042,
            "cargo_demand": 248162
        }
    },
    {
        "iata": "YNY",
        "icao": "RKNY",
        "airportName": "Yangyang International Airport",
        "location_country": "South Korea",
        "location_city": "Yangyang",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "38.061298",
        "longitude": "128.669006",
        "region": "AS",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 136404,
            "s_seats": 46892,
            "j_seats": 18031,
            "f_seats": 5960,
            "r_seats": 6515,
            "cargo_demand": 173944
        }
    },
    {
        "iata": "YOA",
        "icao": "CYOA",
        "airportName": "Ekati Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Ekati",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.69889832",
        "longitude": "-110.6149979",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02T/20T",
                "runway_length": "6411",
                "runway_width": "148",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 150257,
            "s_seats": 47307,
            "j_seats": 28068,
            "f_seats": 9206,
            "r_seats": 9669,
            "cargo_demand": 52527
        }
    },
    {
        "iata": "YOC",
        "icao": "CYOC",
        "airportName": "Old Crow Airport",
        "location_country": "Canada",
        "location_city": "Yukon",
        "location_state": "Old Crow",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "67.57060242",
        "longitude": "-139.8390045",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4900",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 182209,
            "s_seats": 43893,
            "j_seats": 48817,
            "f_seats": 18213,
            "r_seats": 8020,
            "cargo_demand": 268156
        }
    },
    {
        "iata": "YOD",
        "icao": "CYOD",
        "airportName": "CFB Cold Lake",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Cold Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.40499878",
        "longitude": "-110.2789993",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8270",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "12600",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 135057,
            "s_seats": 44353,
            "j_seats": 46295,
            "f_seats": 17276,
            "r_seats": 3764,
            "cargo_demand": 301254
        }
    },
    {
        "iata": "YOH",
        "icao": "CYOH",
        "airportName": "Oxford House Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Oxford House",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.93330002",
        "longitude": "-95.27890015",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3828",
                "runway_width": "75",
                "runway_material": "CRUSHED ROCK"
            },
            {
                "runway_name": "05/23",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 69127,
            "s_seats": 44356,
            "j_seats": 27679,
            "f_seats": 19130,
            "r_seats": 4126,
            "cargo_demand": 337432
        }
    },
    {
        "iata": "YOJ",
        "icao": "CYOJ",
        "airportName": "High Level Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "High Level",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.62139893",
        "longitude": "-117.1650009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146800,
            "s_seats": 31355,
            "j_seats": 5256,
            "f_seats": 3512,
            "r_seats": 4125,
            "cargo_demand": 317459
        }
    },
    {
        "iata": "YOL",
        "icao": "DNYO",
        "airportName": "Yola Airport",
        "location_country": "Nigeria",
        "location_city": "Yola",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "9.25755024",
        "longitude": "12.43039989",
        "region": "AF",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5160",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "9840",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 170035,
            "s_seats": 35282,
            "j_seats": 38264,
            "f_seats": 12697,
            "r_seats": 9056,
            "cargo_demand": 176773
        }
    },
    {
        "iata": "YOO",
        "icao": "CYOO",
        "airportName": "Oshawa Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Oshawa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.922798",
        "longitude": "-78.894997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2654",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 172170,
            "s_seats": 29786,
            "j_seats": 25289,
            "f_seats": 9390,
            "r_seats": 3851,
            "cargo_demand": 51857
        }
    },
    {
        "iata": "YOP",
        "icao": "CYOP",
        "airportName": "Rainbow Lake Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Rainbow Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.49140167",
        "longitude": "-119.4079971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4539",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102398,
            "s_seats": 40967,
            "j_seats": 54979,
            "f_seats": 9189,
            "r_seats": 8857,
            "cargo_demand": 261128
        }
    },
    {
        "iata": "YOS",
        "icao": "CYOS",
        "airportName": "Billy Bishop Regional Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Owen Sound",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.59030151",
        "longitude": "-80.83750153",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3932",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191192,
            "s_seats": 44695,
            "j_seats": 34819,
            "f_seats": 2049,
            "r_seats": 4522,
            "cargo_demand": 292503
        }
    },
    {
        "iata": "YOW",
        "icao": "CYOW",
        "airportName": "Ottawa Macdonald-Cartier International Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Ottawa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "45.32249832",
        "longitude": "-75.66919708",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3300",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "10000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 297646,
            "s_seats": 97476,
            "j_seats": 165398,
            "f_seats": 36300,
            "r_seats": 10000,
            "cargo_demand": 8895668
        }
    },
    {
        "iata": "YPA",
        "icao": "CYPA",
        "airportName": "Prince Albert (Glass Field) Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Prince Albert",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.21419907",
        "longitude": "-105.6729965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "2500",
                "runway_width": "100",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 170859,
            "s_seats": 49703,
            "j_seats": 49645,
            "f_seats": 18584,
            "r_seats": 8885,
            "cargo_demand": 313848
        }
    },
    {
        "iata": "YPC",
        "icao": "CYPC",
        "airportName": "Nora Aliqatchialuk Ruben Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Paulatuk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "69.36083812",
        "longitude": "-124.07547",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02T/20T",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 107022,
            "s_seats": 27787,
            "j_seats": 45702,
            "f_seats": 10837,
            "r_seats": 5717,
            "cargo_demand": 185976
        }
    },
    {
        "iata": "YPE",
        "icao": "CYPE",
        "airportName": "Peace River Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Peace River",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.226898",
        "longitude": "-117.446999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "1900",
                "runway_width": "150",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 154495,
            "s_seats": 39367,
            "j_seats": 57100,
            "f_seats": 17018,
            "r_seats": 7724,
            "cargo_demand": 60629
        }
    },
    {
        "iata": "YPG",
        "icao": "CYPG",
        "airportName": "Portage la Prairie/Southport Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Portage la Prairie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.903099",
        "longitude": "-98.273817",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3045",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2670",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13L/31R",
                "runway_length": "3130",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13R/31L",
                "runway_length": "7000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 178186,
            "s_seats": 33258,
            "j_seats": 52861,
            "f_seats": 5891,
            "r_seats": 3907,
            "cargo_demand": 296161
        }
    },
    {
        "iata": "YPH",
        "icao": "CYPH",
        "airportName": "Inukjuak Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Inukjuak",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.47190094",
        "longitude": "-78.07689667",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 149878,
            "s_seats": 38153,
            "j_seats": 54033,
            "f_seats": 18342,
            "r_seats": 8000,
            "cargo_demand": 237254
        }
    },
    {
        "iata": "YPJ",
        "icao": "CYLA",
        "airportName": "Aupaluk Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Aupaluk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "59.29669952",
        "longitude": "-69.59970093",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            },
            {
                "runway_name": "05/23",
                "runway_length": "3521",
                "runway_width": "100",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 121066,
            "s_seats": 49916,
            "j_seats": 8608,
            "f_seats": 7081,
            "r_seats": 3227,
            "cargo_demand": 458404
        }
    },
    {
        "iata": "YPL",
        "icao": "CYPL",
        "airportName": "Pickle Lake Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Pickle Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.44639969",
        "longitude": "-90.21420288",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4921",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146751,
            "s_seats": 39171,
            "j_seats": 52882,
            "f_seats": 15945,
            "r_seats": 7219,
            "cargo_demand": 114067
        }
    },
    {
        "iata": "YPM",
        "icao": "CYPM",
        "airportName": "Pikangikum Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Pikangikum",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.81969833",
        "longitude": "-93.97329712",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 131217,
            "s_seats": 31151,
            "j_seats": 35507,
            "f_seats": 16759,
            "r_seats": 3756,
            "cargo_demand": 401304
        }
    },
    {
        "iata": "YPN",
        "icao": "CYPN",
        "airportName": "Port-Menier Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Port-Menier",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.83639908",
        "longitude": "-64.28859711",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4886",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67675,
            "s_seats": 41728,
            "j_seats": 36736,
            "f_seats": 6455,
            "r_seats": 7583,
            "cargo_demand": 405787
        }
    },
    {
        "iata": "YPQ",
        "icao": "CYPQ",
        "airportName": "Peterborough Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Peterborough",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.22999954",
        "longitude": "-78.36329651",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "7000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2000",
                "runway_width": "49",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 158497,
            "s_seats": 33445,
            "j_seats": 24689,
            "f_seats": 1610,
            "r_seats": 9893,
            "cargo_demand": 372187
        }
    },
    {
        "iata": "YPR",
        "icao": "CYPR",
        "airportName": "Prince Rupert Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Prince Rupert",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.28609848",
        "longitude": "-130.4450073",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 96921,
            "s_seats": 34687,
            "j_seats": 55764,
            "f_seats": 10434,
            "r_seats": 6637,
            "cargo_demand": 82376
        }
    },
    {
        "iata": "YPS",
        "icao": "CYPD",
        "airportName": "Port Hawkesbury Airport",
        "location_country": "Canada",
        "location_city": "Nova Scotia",
        "location_state": "Port Hawkesbury",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.65670013",
        "longitude": "-61.36809921",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57602,
            "s_seats": 35234,
            "j_seats": 6384,
            "f_seats": 14736,
            "r_seats": 6099,
            "cargo_demand": 350285
        }
    },
    {
        "iata": "YPW",
        "icao": "CYPW",
        "airportName": "Powell River Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Powell River",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.83420181",
        "longitude": "-124.5",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3627",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57530,
            "s_seats": 44122,
            "j_seats": 47226,
            "f_seats": 17983,
            "r_seats": 9892,
            "cargo_demand": 246259
        }
    },
    {
        "iata": "YPX",
        "icao": "CYPX",
        "airportName": "Puvirnituq Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Puvirnituq",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.05059814",
        "longitude": "-77.28690338",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "6299",
                "runway_width": "148",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 83152,
            "s_seats": 29999,
            "j_seats": 6687,
            "f_seats": 2755,
            "r_seats": 4849,
            "cargo_demand": 448270
        }
    },
    {
        "iata": "YPY",
        "icao": "CYPY",
        "airportName": "Fort Chipewyan Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Fort Chipewyan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.76720047",
        "longitude": "-111.1169968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 51204,
            "s_seats": 26522,
            "j_seats": 30223,
            "f_seats": 17295,
            "r_seats": 8016,
            "cargo_demand": 327526
        }
    },
    {
        "iata": "YPZ",
        "icao": "CYPZ",
        "airportName": "Burns Lake Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Burns Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "54.37639999",
        "longitude": "-125.9509964",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3325,
            "s_seats": 2632,
            "j_seats": 1270,
            "f_seats": 243,
            "r_seats": 140,
            "cargo_demand": 72764
        }
    },
    {
        "iata": "YQA",
        "icao": "CYQA",
        "airportName": "Muskoka Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Muskoka",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.97470093",
        "longitude": "-79.30329895",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2180",
                "runway_width": "100",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102140,
            "s_seats": 33305,
            "j_seats": 52518,
            "f_seats": 6981,
            "r_seats": 7679,
            "cargo_demand": 271158
        }
    },
    {
        "iata": "YQB",
        "icao": "CYQB",
        "airportName": "Quebec City Jean Lesage International Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Quebec City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.7911",
        "longitude": "-71.393303",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "9000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5700",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 112023,
            "s_seats": 35106,
            "j_seats": 39909,
            "f_seats": 16361,
            "r_seats": 5706,
            "cargo_demand": 146263
        }
    },
    {
        "iata": "YQC",
        "icao": "CYHA",
        "airportName": "Quaqtaq Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Quaqtaq",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.04639816",
        "longitude": "-69.61779785",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3520",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 61648,
            "s_seats": 43653,
            "j_seats": 38440,
            "f_seats": 4684,
            "r_seats": 7600,
            "cargo_demand": 192363
        }
    },
    {
        "iata": "YQD",
        "icao": "CYQD",
        "airportName": "The Pas Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "The Pas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.97140121",
        "longitude": "-101.0910034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5901",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 99780,
            "s_seats": 35990,
            "j_seats": 55338,
            "f_seats": 10271,
            "r_seats": 8942,
            "cargo_demand": 343180
        }
    },
    {
        "iata": "YQF",
        "icao": "CYQF",
        "airportName": "Red Deer Regional Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Red Deer",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.18220139",
        "longitude": "-113.8939972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "3449",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "7500",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 56518,
            "s_seats": 30814,
            "j_seats": 14804,
            "f_seats": 18076,
            "r_seats": 5695,
            "cargo_demand": 89665
        }
    },
    {
        "iata": "YQG",
        "icao": "CYQG",
        "airportName": "Windsor International Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Windsor",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.27560043",
        "longitude": "-82.95559692",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "9000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5150",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 124815,
            "s_seats": 38882,
            "j_seats": 12797,
            "f_seats": 1130,
            "r_seats": 7469,
            "cargo_demand": 449066
        }
    },
    {
        "iata": "YQH",
        "icao": "CYQH",
        "airportName": "Watson Lake Airport",
        "location_country": "Canada",
        "location_city": "Yukon",
        "location_state": "Watson Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.11640167",
        "longitude": "-128.8220062",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61565,
            "s_seats": 47107,
            "j_seats": 36227,
            "f_seats": 15544,
            "r_seats": 5046,
            "cargo_demand": 453071
        }
    },
    {
        "iata": "YQI",
        "icao": "CYQI",
        "airportName": "Yarmouth Airport",
        "location_country": "Canada",
        "location_city": "Nova Scotia",
        "location_state": "Yarmouth",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.82690048",
        "longitude": "-66.08809662",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147855,
            "s_seats": 37623,
            "j_seats": 7222,
            "f_seats": 4503,
            "r_seats": 7240,
            "cargo_demand": 272918
        }
    },
    {
        "iata": "YQK",
        "icao": "CYQK",
        "airportName": "Kenora Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Kenora",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.78829956",
        "longitude": "-94.36309814",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5800",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101499,
            "s_seats": 35790,
            "j_seats": 6702,
            "f_seats": 1681,
            "r_seats": 4224,
            "cargo_demand": 457916
        }
    },
    {
        "iata": "YQL",
        "icao": "CYQL",
        "airportName": "Lethbridge Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Lethbridge",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.63029861",
        "longitude": "-112.8000031",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6500",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196123,
            "s_seats": 44991,
            "j_seats": 6430,
            "f_seats": 15172,
            "r_seats": 4417,
            "cargo_demand": 373635
        }
    },
    {
        "iata": "YQM",
        "icao": "CYQM",
        "airportName": "Greater Moncton International Airport",
        "location_country": "Canada",
        "location_city": "New Brunswick",
        "location_state": "Moncton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.112202",
        "longitude": "-64.678596",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10001",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 155834,
            "s_seats": 40371,
            "j_seats": 25887,
            "f_seats": 10585,
            "r_seats": 5404,
            "cargo_demand": 438578
        }
    },
    {
        "iata": "YQN",
        "icao": "CYQN",
        "airportName": "Nakina Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Greenstone (Nakina)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.18280029",
        "longitude": "-86.6964035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3200",
                "runway_width": "300",
                "runway_material": "GRS"
            },
            {
                "runway_name": "09/27",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3200",
                "runway_width": "300",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 123907,
            "s_seats": 30703,
            "j_seats": 16547,
            "f_seats": 15303,
            "r_seats": 5182,
            "cargo_demand": 109081
        }
    },
    {
        "iata": "YQQ",
        "icao": "CYQQ",
        "airportName": "CFB Comox",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Comox",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.7108",
        "longitude": "-124.887001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "10000",
                "runway_width": "200",
                "runway_material": "PEM"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 50951,
            "s_seats": 44615,
            "j_seats": 19748,
            "f_seats": 18759,
            "r_seats": 9084,
            "cargo_demand": 422676
        }
    },
    {
        "iata": "YQR",
        "icao": "CYQR",
        "airportName": "Regina International Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Regina",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.43190002",
        "longitude": "-104.6660004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7900",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 84142,
            "s_seats": 38825,
            "j_seats": 43667,
            "f_seats": 11130,
            "r_seats": 6153,
            "cargo_demand": 417432
        }
    },
    {
        "iata": "YQS",
        "icao": "CYQS",
        "airportName": "St. Thomas Municipal Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "St. Thomas",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.77000046",
        "longitude": "-81.1108017",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2607",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "5013",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2610",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173827,
            "s_seats": 34224,
            "j_seats": 37749,
            "f_seats": 12124,
            "r_seats": 9935,
            "cargo_demand": 111283
        }
    },
    {
        "iata": "YQT",
        "icao": "CYQT",
        "airportName": "Thunder Bay International Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Thunder Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.37189865",
        "longitude": "-89.32389832",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "7318",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5297",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 152102,
            "s_seats": 36515,
            "j_seats": 21222,
            "f_seats": 14972,
            "r_seats": 6016,
            "cargo_demand": 301463
        }
    },
    {
        "iata": "YQU",
        "icao": "CYQU",
        "airportName": "Grande Prairie Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Grande Prairie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.17969894",
        "longitude": "-118.8850021",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6200",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8502",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 139476,
            "s_seats": 27626,
            "j_seats": 59256,
            "f_seats": 17393,
            "r_seats": 9412,
            "cargo_demand": 428806
        }
    },
    {
        "iata": "YQV",
        "icao": "CYQV",
        "airportName": "Yorkton Municipal Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Yorkton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.26470184",
        "longitude": "-102.461998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4800",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3000",
                "runway_width": "100",
                "runway_material": "COM"
            }
        ],
        "demand": {
            "y_seats": 83023,
            "s_seats": 35070,
            "j_seats": 7301,
            "f_seats": 14668,
            "r_seats": 3879,
            "cargo_demand": 80538
        }
    },
    {
        "iata": "YQW",
        "icao": "CYQW",
        "airportName": "North Battleford Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "North Battleford",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.76919937",
        "longitude": "-108.2440033",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2565",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 118187,
            "s_seats": 35310,
            "j_seats": 41229,
            "f_seats": 12533,
            "r_seats": 5253,
            "cargo_demand": 449384
        }
    },
    {
        "iata": "YQX",
        "icao": "CYQX",
        "airportName": "Gander International Airport / CFB Gander",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "Gander",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.93690109",
        "longitude": "-54.56809998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "10200",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "1875",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "8900",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 109718,
            "s_seats": 38108,
            "j_seats": 15095,
            "f_seats": 13382,
            "r_seats": 6113,
            "cargo_demand": 465506
        }
    },
    {
        "iata": "YQY",
        "icao": "CYQY",
        "airportName": "Sydney/J.A. Douglas McCurdy Airport",
        "location_country": "Canada",
        "location_city": "Nova Scotia",
        "location_state": "Sydney",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.1614",
        "longitude": "-60.047798",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "7070",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5997",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 67184,
            "s_seats": 46944,
            "j_seats": 22399,
            "f_seats": 6602,
            "r_seats": 8996,
            "cargo_demand": 448358
        }
    },
    {
        "iata": "YQZ",
        "icao": "CYQZ",
        "airportName": "Quesnel Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Quesnel",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.02610016",
        "longitude": "-122.5100021",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5500",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 138911,
            "s_seats": 28991,
            "j_seats": 35925,
            "f_seats": 5128,
            "r_seats": 3668,
            "cargo_demand": 206541
        }
    },
    {
        "iata": "YRB",
        "icao": "CYRB",
        "airportName": "Resolute Bay Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Resolute",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "74.71690369",
        "longitude": "-94.9693985",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17T/35T",
                "runway_length": "6500",
                "runway_width": "200",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 102910,
            "s_seats": 42217,
            "j_seats": 34252,
            "f_seats": 19734,
            "r_seats": 4393,
            "cargo_demand": 271164
        }
    },
    {
        "iata": "YRF",
        "icao": "CYCA",
        "airportName": "Cartwright Airport",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "Cartwright",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.68280029",
        "longitude": "-57.04190063",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "2500",
                "runway_width": "75",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 59828,
            "s_seats": 45617,
            "j_seats": 32693,
            "f_seats": 19894,
            "r_seats": 9656,
            "cargo_demand": 92667
        }
    },
    {
        "iata": "YRI",
        "icao": "CYRI",
        "airportName": "Riviere-du-Loup Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Riviere-du-Loup",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.76440048",
        "longitude": "-69.58470154",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 69297,
            "s_seats": 27850,
            "j_seats": 15143,
            "f_seats": 18281,
            "r_seats": 4430,
            "cargo_demand": 64315
        }
    },
    {
        "iata": "YRJ",
        "icao": "CYRJ",
        "airportName": "Roberval Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Roberval",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.52000046",
        "longitude": "-72.26560211",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186137,
            "s_seats": 30441,
            "j_seats": 45802,
            "f_seats": 17684,
            "r_seats": 3066,
            "cargo_demand": 283994
        }
    },
    {
        "iata": "YRL",
        "icao": "CYRL",
        "airportName": "Red Lake Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Red Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "51.06689835",
        "longitude": "-93.79309845",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5001",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 94281,
            "s_seats": 30464,
            "j_seats": 48324,
            "f_seats": 16746,
            "r_seats": 4395,
            "cargo_demand": 406141
        }
    },
    {
        "iata": "YRM",
        "icao": "CYRM",
        "airportName": "Rocky Mountain House Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Rocky Mountain House",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "52.42969894",
        "longitude": "-114.9039993",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2045,
            "s_seats": 2354,
            "j_seats": 1064,
            "f_seats": 275,
            "r_seats": 86,
            "cargo_demand": 60772
        }
    },
    {
        "iata": "YRO",
        "icao": "CYRO",
        "airportName": "Ottawa/Rockcliffe Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Ottawa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.46030045",
        "longitude": "-75.64610291",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121445,
            "s_seats": 33815,
            "j_seats": 7376,
            "f_seats": 3057,
            "r_seats": 4544,
            "cargo_demand": 178868
        }
    },
    {
        "iata": "YRQ",
        "icao": "CYRQ",
        "airportName": "Trois-Rivieres Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Trois-Rivieres",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.35279846",
        "longitude": "-72.67939758",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "9006",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 188801,
            "s_seats": 38779,
            "j_seats": 6646,
            "f_seats": 18995,
            "r_seats": 5939,
            "cargo_demand": 178382
        }
    },
    {
        "iata": "YRS",
        "icao": "CYRS",
        "airportName": "Red Sucker Lake Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Red Sucker Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.16719818",
        "longitude": "-93.55719757",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3555",
                "runway_width": "85",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 84239,
            "s_seats": 28448,
            "j_seats": 35083,
            "f_seats": 1702,
            "r_seats": 3311,
            "cargo_demand": 474360
        }
    },
    {
        "iata": "YRT",
        "icao": "CYRT",
        "airportName": "Rankin Inlet Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Rankin Inlet",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.81140137",
        "longitude": "-92.11579895",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13T/31T",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 96706,
            "s_seats": 42321,
            "j_seats": 24595,
            "f_seats": 9644,
            "r_seats": 4642,
            "cargo_demand": 429721
        }
    },
    {
        "iata": "YRV",
        "icao": "CYRV",
        "airportName": "Revelstoke Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Revelstoke",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.962245",
        "longitude": "-118.184258",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4800",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 97056,
            "s_seats": 27845,
            "j_seats": 58238,
            "f_seats": 10406,
            "r_seats": 4813,
            "cargo_demand": 394493
        }
    },
    {
        "iata": "YSB",
        "icao": "CYSB",
        "airportName": "Sudbury Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Greater Sudbury",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.625",
        "longitude": "-80.79889679",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6600",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 196257,
            "s_seats": 37264,
            "j_seats": 21025,
            "f_seats": 5649,
            "r_seats": 9738,
            "cargo_demand": 92239
        }
    },
    {
        "iata": "YSC",
        "icao": "CYSC",
        "airportName": "Sherbrooke Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Sherbrooke",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.43859863",
        "longitude": "-71.69139862",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASPH"
            }
        ],
        "demand": {
            "y_seats": 54746,
            "s_seats": 31285,
            "j_seats": 55863,
            "f_seats": 9951,
            "r_seats": 8780,
            "cargo_demand": 265770
        }
    },
    {
        "iata": "YSE",
        "icao": "CYSE",
        "airportName": "Squamish Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Squamish",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "49.78170013",
        "longitude": "-123.1620026",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "2400",
                "runway_width": "75",
                "runway_material": "ASPHALT"
            }
        ],
        "demand": {
            "y_seats": 1328,
            "s_seats": 2607,
            "j_seats": 1819,
            "f_seats": 274,
            "r_seats": 174,
            "cargo_demand": 1445
        }
    },
    {
        "iata": "YSF",
        "icao": "CYSF",
        "airportName": "Stony Rapids Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Stony Rapids",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "59.25030136",
        "longitude": "-105.8410034",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5050",
                "runway_width": "100",
                "runway_material": "TREATED GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 107877,
            "s_seats": 46616,
            "j_seats": 57754,
            "f_seats": 3943,
            "r_seats": 6813,
            "cargo_demand": 446552
        }
    },
    {
        "iata": "YSG",
        "icao": "CYLK",
        "airportName": "Lutselk'e Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Lutselk'e",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.418303",
        "longitude": "-110.681998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08T/26T",
                "runway_length": "2996",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 132621,
            "s_seats": 30326,
            "j_seats": 41732,
            "f_seats": 12197,
            "r_seats": 8818,
            "cargo_demand": 264349
        }
    },
    {
        "iata": "YSH",
        "icao": "CYSH",
        "airportName": "Smiths Falls-Montague Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Smiths Falls",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.94580078",
        "longitude": "-75.94059753",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "4000",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 149026,
            "s_seats": 25420,
            "j_seats": 44177,
            "f_seats": 13159,
            "r_seats": 4213,
            "cargo_demand": 115441
        }
    },
    {
        "iata": "YSJ",
        "icao": "CYSJ",
        "airportName": "Saint John Airport",
        "location_country": "Canada",
        "location_city": "New Brunswick",
        "location_state": "Saint John",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.31610107",
        "longitude": "-65.89029694",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "7000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5100",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 100266,
            "s_seats": 43056,
            "j_seats": 26803,
            "f_seats": 7558,
            "r_seats": 8707,
            "cargo_demand": 491104
        }
    },
    {
        "iata": "YSK",
        "icao": "CYSK",
        "airportName": "Sanikiluaq Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Sanikiluaq",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.53779984",
        "longitude": "-79.24669647",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3800",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 163790,
            "s_seats": 46889,
            "j_seats": 5847,
            "f_seats": 3448,
            "r_seats": 3046,
            "cargo_demand": 312424
        }
    },
    {
        "iata": "YSL",
        "icao": "CYSL",
        "airportName": "Saint-Leonard Aerodrome",
        "location_country": "Canada",
        "location_city": "New Brunswick",
        "location_state": "Saint-Leonard",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.15750122",
        "longitude": "-67.83470154",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 193703,
            "s_seats": 38044,
            "j_seats": 48547,
            "f_seats": 10091,
            "r_seats": 4612,
            "cargo_demand": 409053
        }
    },
    {
        "iata": "YSM",
        "icao": "CYSM",
        "airportName": "Fort Smith Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Fort Smith",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.02030182",
        "longitude": "-111.961998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "1800",
                "runway_width": "100",
                "runway_material": "COM"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74973,
            "s_seats": 43549,
            "j_seats": 9872,
            "f_seats": 1028,
            "r_seats": 6071,
            "cargo_demand": 357825
        }
    },
    {
        "iata": "YSN",
        "icao": "CZAM",
        "airportName": "Salmon Arm Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Salmon Arm",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.682802",
        "longitude": "-119.228996",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "4260",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 70279,
            "s_seats": 38560,
            "j_seats": 13233,
            "f_seats": 3239,
            "r_seats": 8098,
            "cargo_demand": 472359
        }
    },
    {
        "iata": "YSP",
        "icao": "CYSP",
        "airportName": "Marathon Aerodrome",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Marathon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.75529861",
        "longitude": "-86.3443985",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3933",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 194760,
            "s_seats": 26698,
            "j_seats": 31122,
            "f_seats": 10908,
            "r_seats": 7685,
            "cargo_demand": 363525
        }
    },
    {
        "iata": "YSQ",
        "icao": "ZYSQ",
        "airportName": "Songyuan Chaganhu Airport",
        "location_country": "China",
        "location_city": "Jilin",
        "location_state": "Songyuan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.938114",
        "longitude": "124.550178",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "8202",
                "runway_width": "148",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 180260,
            "s_seats": 37985,
            "j_seats": 29653,
            "f_seats": 5989,
            "r_seats": 7009,
            "cargo_demand": 476022
        }
    },
    {
        "iata": "YST",
        "icao": "CYST",
        "airportName": "St. Theresa Point Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "St. Theresa Point",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.84560013",
        "longitude": "-94.85189819",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3400",
                "runway_width": "75",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 141642,
            "s_seats": 48357,
            "j_seats": 40833,
            "f_seats": 9439,
            "r_seats": 6492,
            "cargo_demand": 471004
        }
    },
    {
        "iata": "YSU",
        "icao": "CYSU",
        "airportName": "Summerside Airport",
        "location_country": "Canada",
        "location_city": "Prince Edward Island",
        "location_state": "Summerside",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.440601",
        "longitude": "-63.833599",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5300",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5460",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 164735,
            "s_seats": 29966,
            "j_seats": 27145,
            "f_seats": 15641,
            "r_seats": 7332,
            "cargo_demand": 449307
        }
    },
    {
        "iata": "YSY",
        "icao": "CYSY",
        "airportName": "Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Sachs Harbour",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "71.99389648",
        "longitude": "-125.2429962",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08T/26T",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 134911,
            "s_seats": 37060,
            "j_seats": 57595,
            "f_seats": 9870,
            "r_seats": 3267,
            "cargo_demand": 87860
        }
    },
    {
        "iata": "YTA",
        "icao": "CYTA",
        "airportName": "Pembroke Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Pembroke",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.86439896",
        "longitude": "-77.25170135",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168736,
            "s_seats": 40313,
            "j_seats": 19379,
            "f_seats": 14818,
            "r_seats": 8084,
            "cargo_demand": 146147
        }
    },
    {
        "iata": "YTD",
        "icao": "CZLQ",
        "airportName": "Thicket Portage Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Thicket Portage",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.31890106",
        "longitude": "-97.70780182",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "2220",
                "runway_width": "75",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 59404,
            "s_seats": 41492,
            "j_seats": 21701,
            "f_seats": 9005,
            "r_seats": 9360,
            "cargo_demand": 264000
        }
    },
    {
        "iata": "YTE",
        "icao": "CYTE",
        "airportName": "Cape Dorset Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Cape Dorset",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.23000336",
        "longitude": "-76.52670288",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13T/31T",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 160064,
            "s_seats": 31956,
            "j_seats": 59435,
            "f_seats": 19891,
            "r_seats": 9453,
            "cargo_demand": 313674
        }
    },
    {
        "iata": "YTF",
        "icao": "CYTF",
        "airportName": "Alma Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Alma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.50889969",
        "longitude": "-71.64189911",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 189953,
            "s_seats": 32687,
            "j_seats": 34736,
            "f_seats": 16563,
            "r_seats": 5846,
            "cargo_demand": 393620
        }
    },
    {
        "iata": "YTH",
        "icao": "CYTH",
        "airportName": "Thompson Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Thompson",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.80110168",
        "longitude": "-97.86419678",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5800",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "2500",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 155710,
            "s_seats": 38125,
            "j_seats": 27624,
            "f_seats": 9009,
            "r_seats": 5337,
            "cargo_demand": 175511
        }
    },
    {
        "iata": "YTL",
        "icao": "CYTL",
        "airportName": "Big Trout Lake Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Kitchenuhmaykoosib Inninuwug (Big Trout Lake)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.81779861",
        "longitude": "-89.89689636",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3900",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 140175,
            "s_seats": 37780,
            "j_seats": 14081,
            "f_seats": 12944,
            "r_seats": 5322,
            "cargo_demand": 150367
        }
    },
    {
        "iata": "YTM",
        "icao": "CYFJ",
        "airportName": "Mont Tremblant International Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Mont-Tremblant",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.409401",
        "longitude": "-74.779999",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5587",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 159796,
            "s_seats": 42836,
            "j_seats": 14207,
            "f_seats": 11721,
            "r_seats": 9179,
            "cargo_demand": 466776
        }
    },
    {
        "iata": "YTQ",
        "icao": "CYTQ",
        "airportName": "Tasiujaq Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Tasiujaq",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.6678009",
        "longitude": "-69.95580292",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3519",
                "runway_width": "100",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 71513,
            "s_seats": 38718,
            "j_seats": 21845,
            "f_seats": 8985,
            "r_seats": 3144,
            "cargo_demand": 462652
        }
    },
    {
        "iata": "YTR",
        "icao": "CYTR",
        "airportName": "CFB Trenton",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Trenton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.1189003",
        "longitude": "-77.52809906",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "10000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "3025",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68599,
            "s_seats": 35872,
            "j_seats": 42691,
            "f_seats": 16770,
            "r_seats": 8229,
            "cargo_demand": 271335
        }
    },
    {
        "iata": "YTS",
        "icao": "CYTS",
        "airportName": "Timmins/Victor M. Power Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Timmins",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.56969833",
        "longitude": "-81.37670136",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "4903",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 55600,
            "s_seats": 36810,
            "j_seats": 44421,
            "f_seats": 17392,
            "r_seats": 8432,
            "cargo_demand": 364986
        }
    },
    {
        "iata": "YTY",
        "icao": "ZSYA",
        "airportName": "Yangzhou Taizhou Airport",
        "location_country": "China",
        "location_city": "Jiangsu",
        "location_state": "Yangzhou / Taizhou",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "32.5634",
        "longitude": "119.7198",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "7874",
                "runway_width": "",
                "runway_material": "Concrete"
            }
        ],
        "demand": {
            "y_seats": 90794,
            "s_seats": 40154,
            "j_seats": 44737,
            "f_seats": 6947,
            "r_seats": 8896,
            "cargo_demand": 181312
        }
    },
    {
        "iata": "YTZ",
        "icao": "CYTZ",
        "airportName": "Billy Bishop Toronto City Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Toronto",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.627499",
        "longitude": "-79.396202",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2933",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3988",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "2780",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121764,
            "s_seats": 35110,
            "j_seats": 40949,
            "f_seats": 7291,
            "r_seats": 5730,
            "cargo_demand": 200955
        }
    },
    {
        "iata": "YUB",
        "icao": "CYUB",
        "airportName": "Tuktoyaktuk/James Gruben Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Tuktoyaktuk",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "69.4332962",
        "longitude": "-133.026001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 166424,
            "s_seats": 42024,
            "j_seats": 33835,
            "f_seats": 11288,
            "r_seats": 3755,
            "cargo_demand": 107223
        }
    },
    {
        "iata": "YUD",
        "icao": "CYMU",
        "airportName": "Umiujaq Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Umiujaq",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.53609848",
        "longitude": "-76.51830292",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 54527,
            "s_seats": 43083,
            "j_seats": 55571,
            "f_seats": 14816,
            "r_seats": 3520,
            "cargo_demand": 429839
        }
    },
    {
        "iata": "YUE",
        "icao": "YYND",
        "airportName": "Yuendumu Airport",
        "location_country": "Australia",
        "location_city": "Northern Territory",
        "location_state": "Yuendumu",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-22.25419998",
        "longitude": "131.7819977",
        "region": "OC",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4724",
                "runway_width": "60",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3709,
            "s_seats": 2421,
            "j_seats": 1560,
            "f_seats": 14,
            "r_seats": 101,
            "cargo_demand": 61747
        }
    },
    {
        "iata": "YUL",
        "icao": "CYUL",
        "airportName": "Montreal-Trudeau International Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Montreal",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "45.47060013",
        "longitude": "-73.74079895",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06L/24R",
                "runway_length": "11000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "9600",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "7000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 221718,
            "s_seats": 88426,
            "j_seats": 34277,
            "f_seats": 26172,
            "r_seats": 10000,
            "cargo_demand": 3793508
        }
    },
    {
        "iata": "YUM",
        "icao": "KNYL",
        "airportName": "Yuma International Airport",
        "location_country": "United States",
        "location_city": "Arizona",
        "location_state": "Yuma",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "32.656601",
        "longitude": "-114.606003",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03L/21R",
                "runway_length": "13299",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "03R/21L",
                "runway_length": "9239",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "6145",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5710",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 492076,
            "s_seats": 81686,
            "j_seats": 103507,
            "f_seats": 31249,
            "r_seats": 10000,
            "cargo_demand": 23559055
        }
    },
    {
        "iata": "YUT",
        "icao": "CYUT",
        "airportName": "Repulse Bay Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Naujaat (Repulse Bay)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "66.5214",
        "longitude": "-86.224701",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16T/34T",
                "runway_length": "3400",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 164385,
            "s_seats": 39680,
            "j_seats": 45258,
            "f_seats": 12826,
            "r_seats": 7848,
            "cargo_demand": 154481
        }
    },
    {
        "iata": "YUX",
        "icao": "CYUX",
        "airportName": "Hall Beach Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Hall Beach",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "68.77610016",
        "longitude": "-81.2425",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12T/30T",
                "runway_length": "5410",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 187361,
            "s_seats": 42155,
            "j_seats": 24197,
            "f_seats": 12269,
            "r_seats": 6110,
            "cargo_demand": 387976
        }
    },
    {
        "iata": "YUY",
        "icao": "CYUY",
        "airportName": "Rouyn-Noranda Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Rouyn-Noranda",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.20610046",
        "longitude": "-78.83560181",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "7485",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 63290,
            "s_seats": 36090,
            "j_seats": 36856,
            "f_seats": 14401,
            "r_seats": 8937,
            "cargo_demand": 386877
        }
    },
    {
        "iata": "YVB",
        "icao": "CYVB",
        "airportName": "Bonaventure Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Bonaventure",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.07109833",
        "longitude": "-65.46029663",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5985",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59071,
            "s_seats": 40136,
            "j_seats": 37832,
            "f_seats": 13511,
            "r_seats": 5332,
            "cargo_demand": 306632
        }
    },
    {
        "iata": "YVC",
        "icao": "CYVC",
        "airportName": "La Ronge (Barber Field) Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "La Ronge",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.15140152",
        "longitude": "-105.262001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "2396",
                "runway_width": "50",
                "runway_material": "GVL"
            },
            {
                "runway_name": "18/36",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 158527,
            "s_seats": 40084,
            "j_seats": 34984,
            "f_seats": 16688,
            "r_seats": 9942,
            "cargo_demand": 335119
        }
    },
    {
        "iata": "YVE",
        "icao": "CYVK",
        "airportName": "Vernon Regional Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Vernon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.24810028",
        "longitude": "-119.3310013",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3360",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174813,
            "s_seats": 49175,
            "j_seats": 24166,
            "f_seats": 19690,
            "r_seats": 7956,
            "cargo_demand": 476139
        }
    },
    {
        "iata": "YVG",
        "icao": "CYVG",
        "airportName": "Vermilion Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Vermilion",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "53.35580063",
        "longitude": "-110.8239975",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3300",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2140,
            "s_seats": 1808,
            "j_seats": 1039,
            "f_seats": 280,
            "r_seats": 60,
            "cargo_demand": 9319
        }
    },
    {
        "iata": "YVM",
        "icao": "CYVM",
        "airportName": "Qikiqtarjuaq Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Qikiqtarjuaq",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "67.54579926",
        "longitude": "-64.03140259",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03T/21T",
                "runway_length": "3800",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 109950,
            "s_seats": 38396,
            "j_seats": 33703,
            "f_seats": 7368,
            "r_seats": 6655,
            "cargo_demand": 326331
        }
    },
    {
        "iata": "YVO",
        "icao": "CYVO",
        "airportName": "Val-d'Or Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Val-d'Or",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.05329895",
        "longitude": "-77.78279877",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "10000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 198735,
            "s_seats": 42832,
            "j_seats": 28597,
            "f_seats": 9365,
            "r_seats": 5063,
            "cargo_demand": 120711
        }
    },
    {
        "iata": "YVP",
        "icao": "CYVP",
        "airportName": "Kuujjuaq Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Kuujjuaq",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.09609985",
        "longitude": "-68.42690277",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 59126,
            "s_seats": 49894,
            "j_seats": 41104,
            "f_seats": 17455,
            "r_seats": 7746,
            "cargo_demand": 145702
        }
    },
    {
        "iata": "YVQ",
        "icao": "CYVQ",
        "airportName": "Norman Wells Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Norman Wells",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "65.28160095",
        "longitude": "-126.7979965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5997",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 123337,
            "s_seats": 44604,
            "j_seats": 18518,
            "f_seats": 15328,
            "r_seats": 9469,
            "cargo_demand": 184431
        }
    },
    {
        "iata": "YVR",
        "icao": "CYVR",
        "airportName": "Vancouver International Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Vancouver",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "49.19390106",
        "longitude": "-123.1839981",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08L/26R",
                "runway_length": "9940",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "08R/26L",
                "runway_length": "11500",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "7300",
                "runway_width": "200",
                "runway_material": "CON"
            },
            {
                "runway_name": "XX/26A",
                "runway_length": "3500",
                "runway_width": "75",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 298345,
            "s_seats": 96543,
            "j_seats": 66840,
            "f_seats": 25058,
            "r_seats": 10000,
            "cargo_demand": 13654660
        }
    },
    {
        "iata": "YVT",
        "icao": "CYVT",
        "airportName": "Buffalo Narrows Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Buffalo Narrows",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "55.84189987",
        "longitude": "-108.4179993",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "2297",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "5006",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3348,
            "s_seats": 1843,
            "j_seats": 1594,
            "f_seats": 97,
            "r_seats": 129,
            "cargo_demand": 35209
        }
    },
    {
        "iata": "YVV",
        "icao": "CYVV",
        "airportName": "Wiarton Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Wiarton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.7458",
        "longitude": "-81.107201",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5021",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "3100",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 162386,
            "s_seats": 46185,
            "j_seats": 24190,
            "f_seats": 4237,
            "r_seats": 6000,
            "cargo_demand": 287800
        }
    },
    {
        "iata": "YVZ",
        "icao": "CYVZ",
        "airportName": "Deer Lake Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Deer Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.65579987",
        "longitude": "-94.06140137",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 148014,
            "s_seats": 41009,
            "j_seats": 13669,
            "f_seats": 4219,
            "r_seats": 3411,
            "cargo_demand": 120788
        }
    },
    {
        "iata": "YWA",
        "icao": "CYWA",
        "airportName": "Petawawa Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Petawawa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.95220184",
        "longitude": "-77.31919861",
        "region": "NA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3800",
                "runway_width": "75",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 192482,
            "s_seats": 46583,
            "j_seats": 5418,
            "f_seats": 10251,
            "r_seats": 6145,
            "cargo_demand": 223106
        }
    },
    {
        "iata": "YWB",
        "icao": "CYKG",
        "airportName": "Kangiqsujuaq (Wakeham Bay) Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Kangiqsujuaq",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "61.58860016",
        "longitude": "-71.92939758",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "3511",
                "runway_width": "100",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 112824,
            "s_seats": 28544,
            "j_seats": 58528,
            "f_seats": 16308,
            "r_seats": 4861,
            "cargo_demand": 151715
        }
    },
    {
        "iata": "YWG",
        "icao": "CYWG",
        "airportName": "Winnipeg James Armstrong Richardson International Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Winnipeg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "49.90999985",
        "longitude": "-97.23989868",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4500",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "8700",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "11000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 201138,
            "s_seats": 74579,
            "j_seats": 151232,
            "f_seats": 51147,
            "r_seats": 10000,
            "cargo_demand": 28817063
        }
    },
    {
        "iata": "YWJ",
        "icao": "CYWJ",
        "airportName": "Deline Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Deline",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "65.21109772",
        "longitude": "-123.435997",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3933",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 99207,
            "s_seats": 33485,
            "j_seats": 43526,
            "f_seats": 18526,
            "r_seats": 7965,
            "cargo_demand": 443922
        }
    },
    {
        "iata": "YWK",
        "icao": "CYWK",
        "airportName": "Wabush Airport",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "Wabush",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.9219017",
        "longitude": "-66.86440277",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "6002",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 137455,
            "s_seats": 47779,
            "j_seats": 14984,
            "f_seats": 2131,
            "r_seats": 6313,
            "cargo_demand": 287185
        }
    },
    {
        "iata": "YWL",
        "icao": "CYWL",
        "airportName": "Williams Lake Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Williams Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.18310165",
        "longitude": "-122.0540009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 122021,
            "s_seats": 40263,
            "j_seats": 33108,
            "f_seats": 9819,
            "r_seats": 9378,
            "cargo_demand": 66481
        }
    },
    {
        "iata": "YWP",
        "icao": "CYWP",
        "airportName": "Webequie Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Webequie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.9593934",
        "longitude": "-87.37486839",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 52652,
            "s_seats": 37389,
            "j_seats": 11548,
            "f_seats": 19174,
            "r_seats": 4029,
            "cargo_demand": 229530
        }
    },
    {
        "iata": "YWY",
        "icao": "CYWY",
        "airportName": "Wrigley Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Wrigley",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "63.20940018",
        "longitude": "-123.4369965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 135320,
            "s_seats": 31820,
            "j_seats": 27159,
            "f_seats": 18890,
            "r_seats": 9875,
            "cargo_demand": 220538
        }
    },
    {
        "iata": "YXC",
        "icao": "CYXC",
        "airportName": "Cranbrook/Canadian Rockies International Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Cranbrook",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.6108017",
        "longitude": "-115.7819977",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57005,
            "s_seats": 33410,
            "j_seats": 22418,
            "f_seats": 5787,
            "r_seats": 7512,
            "cargo_demand": 491036
        }
    },
    {
        "iata": "YXE",
        "icao": "CYXE",
        "airportName": "Saskatoon John G. Diefenbaker International Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Saskatoon",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.17079926",
        "longitude": "-106.6999969",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8300",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "6200",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 173533,
            "s_seats": 38227,
            "j_seats": 49543,
            "f_seats": 13360,
            "r_seats": 5285,
            "cargo_demand": 244589
        }
    },
    {
        "iata": "YXH",
        "icao": "CYXH",
        "airportName": "Medicine Hat Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Medicine Hat",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.01890182",
        "longitude": "-110.7210007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "2820",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121662,
            "s_seats": 38142,
            "j_seats": 35833,
            "f_seats": 17066,
            "r_seats": 6528,
            "cargo_demand": 166940
        }
    },
    {
        "iata": "YXJ",
        "icao": "CYXJ",
        "airportName": "Fort St. John Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Fort St. John",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.238098",
        "longitude": "-120.739998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "6700",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "6900",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 119774,
            "s_seats": 28782,
            "j_seats": 17449,
            "f_seats": 1016,
            "r_seats": 7407,
            "cargo_demand": 85233
        }
    },
    {
        "iata": "YXK",
        "icao": "CYXK",
        "airportName": "Rimouski Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Rimouski",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.47809982",
        "longitude": "-68.49690247",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4600",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 192675,
            "s_seats": 43354,
            "j_seats": 45840,
            "f_seats": 18639,
            "r_seats": 5038,
            "cargo_demand": 208341
        }
    },
    {
        "iata": "YXL",
        "icao": "CYXL",
        "airportName": "Sioux Lookout Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Sioux Lookout",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.11389923",
        "longitude": "-91.90529633",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "2200",
                "runway_width": "50",
                "runway_material": "GVL"
            },
            {
                "runway_name": "16/34",
                "runway_length": "5300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176730,
            "s_seats": 42131,
            "j_seats": 31161,
            "f_seats": 7730,
            "r_seats": 6260,
            "cargo_demand": 266620
        }
    },
    {
        "iata": "YXN",
        "icao": "CYXN",
        "airportName": "Whale Cove Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Whale Cove",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.24000168",
        "longitude": "-92.59809875",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15T/33T",
                "runway_length": "4000",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 70225,
            "s_seats": 41041,
            "j_seats": 46664,
            "f_seats": 18394,
            "r_seats": 6102,
            "cargo_demand": 414804
        }
    },
    {
        "iata": "YXP",
        "icao": "CYXP",
        "airportName": "Pangnirtung Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Pangnirtung",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "66.14499664",
        "longitude": "-65.71360016",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06T/24T",
                "runway_length": "2920",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 87527,
            "s_seats": 39597,
            "j_seats": 48595,
            "f_seats": 13780,
            "r_seats": 8109,
            "cargo_demand": 370574
        }
    },
    {
        "iata": "YXQ",
        "icao": "CYXQ",
        "airportName": "Beaver Creek Airport",
        "location_country": "Canada",
        "location_city": "Yukon",
        "location_state": "Beaver Creek",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.41030121",
        "longitude": "-140.8670044",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3370",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 145614,
            "s_seats": 32383,
            "j_seats": 10506,
            "f_seats": 15101,
            "r_seats": 9918,
            "cargo_demand": 315375
        }
    },
    {
        "iata": "YXR",
        "icao": "CYXR",
        "airportName": "Earlton (Timiskaming Regional) Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Armstrong (Earlton)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.69740065",
        "longitude": "-79.84734535",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5998",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "3019",
                "runway_width": "150",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 140192,
            "s_seats": 41086,
            "j_seats": 37959,
            "f_seats": 7222,
            "r_seats": 7916,
            "cargo_demand": 419750
        }
    },
    {
        "iata": "YXS",
        "icao": "CYXS",
        "airportName": "Prince George Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Prince George",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.88940048",
        "longitude": "-122.6790009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3769",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06/24",
                "runway_length": "5628",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "11450",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 168487,
            "s_seats": 37136,
            "j_seats": 15226,
            "f_seats": 19547,
            "r_seats": 6220,
            "cargo_demand": 312636
        }
    },
    {
        "iata": "YXT",
        "icao": "CYXT",
        "airportName": "Northwest Regional Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Terrace",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.468498",
        "longitude": "-128.576009",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5373",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "7497",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78766,
            "s_seats": 28074,
            "j_seats": 35336,
            "f_seats": 17787,
            "r_seats": 5852,
            "cargo_demand": 419757
        }
    },
    {
        "iata": "YXU",
        "icao": "CYXU",
        "airportName": "London International Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "London",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "43.035599",
        "longitude": "-81.1539",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "6300",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "8800",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 132849,
            "s_seats": 36350,
            "j_seats": 37868,
            "f_seats": 8984,
            "r_seats": 9868,
            "cargo_demand": 391993
        }
    },
    {
        "iata": "YXX",
        "icao": "CYXX",
        "airportName": "Abbotsford International Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Abbotsford",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "49.025299",
        "longitude": "-122.361",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "5328",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "01L/19R",
                "runway_length": "1500",
                "runway_width": "100",
                "runway_material": "GRS"
            },
            {
                "runway_name": "07/25",
                "runway_length": "9600",
                "runway_width": "200",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 104127,
            "s_seats": 91679,
            "j_seats": 97933,
            "f_seats": 61299,
            "r_seats": 10000,
            "cargo_demand": 28616236
        }
    },
    {
        "iata": "YXY",
        "icao": "CYXY",
        "airportName": "Erik Nielsen Whitehorse International Airport",
        "location_country": "Canada",
        "location_city": "Yukon",
        "location_state": "Whitehorse",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "60.709599",
        "longitude": "-135.067001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "2075",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14L/32R",
                "runway_length": "4000",
                "runway_width": "80",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14R/32L",
                "runway_length": "9497",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121868,
            "s_seats": 90441,
            "j_seats": 30900,
            "f_seats": 55194,
            "r_seats": 10000,
            "cargo_demand": 11099123
        }
    },
    {
        "iata": "YXZ",
        "icao": "CYXZ",
        "airportName": "Wawa Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Wawa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.96670151",
        "longitude": "-84.78669739",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "4429",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 61586,
            "s_seats": 44016,
            "j_seats": 43337,
            "f_seats": 2721,
            "r_seats": 7694,
            "cargo_demand": 110445
        }
    },
    {
        "iata": "YYB",
        "icao": "CYYB",
        "airportName": "North Bay/Jack Garland Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "North Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.363602",
        "longitude": "-79.422798",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "10000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "2500",
                "runway_width": "75",
                "runway_material": "GRS"
            },
            {
                "runway_name": "18/36",
                "runway_length": "4474",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 101195,
            "s_seats": 32529,
            "j_seats": 30353,
            "f_seats": 3940,
            "r_seats": 7633,
            "cargo_demand": 410603
        }
    },
    {
        "iata": "YYC",
        "icao": "CYYC",
        "airportName": "Calgary International Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Calgary",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "51.11389923",
        "longitude": "-114.0199966",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "6200",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17L/35R",
                "runway_length": "14000",
                "runway_width": "200",
                "runway_material": "Concrete"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "12675",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 285680,
            "s_seats": 82624,
            "j_seats": 87774,
            "f_seats": 20256,
            "r_seats": 10000,
            "cargo_demand": 18318870
        }
    },
    {
        "iata": "YYD",
        "icao": "CYYD",
        "airportName": "Smithers Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Smithers",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.8246994",
        "longitude": "-127.1829987",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 142715,
            "s_seats": 32540,
            "j_seats": 10632,
            "f_seats": 1551,
            "r_seats": 3123,
            "cargo_demand": 221768
        }
    },
    {
        "iata": "YYE",
        "icao": "CYYE",
        "airportName": "Northern Rockies Regional Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Fort Nelson",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.83639908",
        "longitude": "-122.5970001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6400",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "07/25",
                "runway_length": "4068",
                "runway_width": "77",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 77716,
            "s_seats": 44713,
            "j_seats": 36833,
            "f_seats": 18468,
            "r_seats": 6647,
            "cargo_demand": 109630
        }
    },
    {
        "iata": "YYF",
        "icao": "CYYF",
        "airportName": "Penticton Regional Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Penticton",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.46310043",
        "longitude": "-119.6019974",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "6000",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 87191,
            "s_seats": 32502,
            "j_seats": 29006,
            "f_seats": 19580,
            "r_seats": 9587,
            "cargo_demand": 69065
        }
    },
    {
        "iata": "YYG",
        "icao": "CYYG",
        "airportName": "Charlottetown Airport",
        "location_country": "Canada",
        "location_city": "Prince Edward Island",
        "location_state": "Charlottetown",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "46.29000092",
        "longitude": "-63.12110138",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "7002",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "10/28",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 153081,
            "s_seats": 26896,
            "j_seats": 21924,
            "f_seats": 1041,
            "r_seats": 5856,
            "cargo_demand": 487586
        }
    },
    {
        "iata": "YYH",
        "icao": "CYYH",
        "airportName": "Taloyoak Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Taloyoak",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "69.5467",
        "longitude": "-93.576698",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15T/33T",
                "runway_length": "4020",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 119285,
            "s_seats": 33264,
            "j_seats": 14157,
            "f_seats": 16784,
            "r_seats": 4096,
            "cargo_demand": 314845
        }
    },
    {
        "iata": "YYJ",
        "icao": "CYYJ",
        "airportName": "Victoria International Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Victoria",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "48.64690018",
        "longitude": "-123.4260025",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "5027",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "7000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5001",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 273286,
            "s_seats": 97103,
            "j_seats": 162458,
            "f_seats": 53465,
            "r_seats": 10000,
            "cargo_demand": 18659803
        }
    },
    {
        "iata": "YYL",
        "icao": "CYYL",
        "airportName": "Lynn Lake Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Lynn Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.86389923",
        "longitude": "-101.0759964",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "2520",
                "runway_width": "80",
                "runway_material": "GVL"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 131143,
            "s_seats": 42801,
            "j_seats": 35450,
            "f_seats": 9704,
            "r_seats": 6017,
            "cargo_demand": 187714
        }
    },
    {
        "iata": "YYM",
        "icao": "CYYM",
        "airportName": "Cowley Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Cowley",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "49.63639832",
        "longitude": "-114.0940018",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3600",
                "runway_width": "300",
                "runway_material": "TURF"
            },
            {
                "runway_name": "11/29",
                "runway_length": "4330",
                "runway_width": "300",
                "runway_material": "TURF"
            }
        ],
        "demand": {
            "y_seats": 2676,
            "s_seats": 2319,
            "j_seats": 1665,
            "f_seats": 29,
            "r_seats": 120,
            "cargo_demand": 29799
        }
    },
    {
        "iata": "YYN",
        "icao": "CYYN",
        "airportName": "Swift Current Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Swift Current",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.29190063",
        "longitude": "-107.6910019",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "2500",
                "runway_width": "50",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "4250",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 186086,
            "s_seats": 34724,
            "j_seats": 54905,
            "f_seats": 8043,
            "r_seats": 5600,
            "cargo_demand": 227134
        }
    },
    {
        "iata": "YYQ",
        "icao": "CYYQ",
        "airportName": "Churchill Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Churchill",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.73920059",
        "longitude": "-94.06500244",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GVL"
            },
            {
                "runway_name": "15/33",
                "runway_length": "9200",
                "runway_width": "160",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 190111,
            "s_seats": 42358,
            "j_seats": 17409,
            "f_seats": 9201,
            "r_seats": 6614,
            "cargo_demand": 343294
        }
    },
    {
        "iata": "YYR",
        "icao": "CYYR",
        "airportName": "CFB Goose Bay",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "Happy Valley-Goose Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.31919861",
        "longitude": "-60.42580032",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "11046",
                "runway_width": "209",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "9580",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62616,
            "s_seats": 36586,
            "j_seats": 38156,
            "f_seats": 13037,
            "r_seats": 7804,
            "cargo_demand": 329236
        }
    },
    {
        "iata": "YYT",
        "icao": "CYYT",
        "airportName": "St. John's International Airport",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "St. John's",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "47.61859894",
        "longitude": "-52.75189972",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "5028",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "8502",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7005",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 211343,
            "s_seats": 76413,
            "j_seats": 61191,
            "f_seats": 62204,
            "r_seats": 10000,
            "cargo_demand": 10955455
        }
    },
    {
        "iata": "YYU",
        "icao": "CYYU",
        "airportName": "Kapuskasing Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Kapuskasing",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.41389847",
        "longitude": "-82.46749878",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3590",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17/35",
                "runway_length": "5500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115160,
            "s_seats": 44583,
            "j_seats": 59537,
            "f_seats": 8965,
            "r_seats": 7731,
            "cargo_demand": 299720
        }
    },
    {
        "iata": "YYW",
        "icao": "CYYW",
        "airportName": "Armstrong Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Armstrong",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.29029846",
        "longitude": "-88.90969849",
        "region": "NA",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "18/36",
                "runway_length": "2940",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 78144,
            "s_seats": 29331,
            "j_seats": 11236,
            "f_seats": 17948,
            "r_seats": 9114,
            "cargo_demand": 64219
        }
    },
    {
        "iata": "YYY",
        "icao": "CYYY",
        "airportName": "Mont-Joli Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Mont-Joli",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "48.60860062",
        "longitude": "-68.20809937",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "6000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15/33",
                "runway_length": "3934",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 92949,
            "s_seats": 46967,
            "j_seats": 22371,
            "f_seats": 17132,
            "r_seats": 5504,
            "cargo_demand": 189252
        }
    },
    {
        "iata": "YYZ",
        "icao": "CYYZ",
        "airportName": "Toronto Pearson International Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Toronto",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "43.67720032",
        "longitude": "-79.63059998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "11120",
                "runway_width": "200",
                "runway_material": "ASP/CONC"
            },
            {
                "runway_name": "06L/24R",
                "runway_length": "9697",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "06R/24L",
                "runway_length": "9000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15L/33R",
                "runway_length": "11050",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "15R/33L",
                "runway_length": "9088",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 263731,
            "s_seats": 83395,
            "j_seats": 99274,
            "f_seats": 46685,
            "r_seats": 10000,
            "cargo_demand": 1610521
        }
    },
    {
        "iata": "YZE",
        "icao": "CYZE",
        "airportName": "Gore Bay-Manitoulin Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Gore Bay",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.8853",
        "longitude": "-82.567802",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "4900",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177529,
            "s_seats": 25540,
            "j_seats": 20355,
            "f_seats": 17407,
            "r_seats": 9420,
            "cargo_demand": 496449
        }
    },
    {
        "iata": "YZF",
        "icao": "CYZF",
        "airportName": "Yellowknife Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Yellowknife",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "62.462799",
        "longitude": "-114.440002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "7500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 252773,
            "s_seats": 91213,
            "j_seats": 61445,
            "f_seats": 62191,
            "r_seats": 10000,
            "cargo_demand": 11481105
        }
    },
    {
        "iata": "YZG",
        "icao": "CYZG",
        "airportName": "Salluit Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Salluit",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.1794014",
        "longitude": "-75.66719818",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "3500",
                "runway_width": "",
                "runway_material": "UNK"
            }
        ],
        "demand": {
            "y_seats": 87872,
            "s_seats": 39729,
            "j_seats": 10419,
            "f_seats": 6564,
            "r_seats": 3328,
            "cargo_demand": 277130
        }
    },
    {
        "iata": "YZH",
        "icao": "CYZH",
        "airportName": "Slave Lake Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Slave Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.29309845",
        "longitude": "-114.7770004",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5561",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 176007,
            "s_seats": 45636,
            "j_seats": 47161,
            "f_seats": 8456,
            "r_seats": 8421,
            "cargo_demand": 239869
        }
    },
    {
        "iata": "YZP",
        "icao": "CYZP",
        "airportName": "Sandspit Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Sandspit",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.25429916",
        "longitude": "-131.8139954",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5120",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 145205,
            "s_seats": 48719,
            "j_seats": 51683,
            "f_seats": 5713,
            "r_seats": 8119,
            "cargo_demand": 321144
        }
    },
    {
        "iata": "YZR",
        "icao": "CYZR",
        "airportName": "Sarnia Chris Hadfield Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Sarnia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "42.99940109",
        "longitude": "-82.30889893",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "2989",
                "runway_width": "75",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "5100",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 59330,
            "s_seats": 35560,
            "j_seats": 23295,
            "f_seats": 10077,
            "r_seats": 3548,
            "cargo_demand": 459968
        }
    },
    {
        "iata": "YZS",
        "icao": "CYZS",
        "airportName": "Coral Harbour Airport",
        "location_country": "Canada",
        "location_city": "Nunavut",
        "location_state": "Coral Harbour",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.19329834",
        "longitude": "-83.35939789",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16T/34T",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 199084,
            "s_seats": 47933,
            "j_seats": 57269,
            "f_seats": 15523,
            "r_seats": 7711,
            "cargo_demand": 292599
        }
    },
    {
        "iata": "YZT",
        "icao": "CYZT",
        "airportName": "Port Hardy Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Port Hardy",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.68059921",
        "longitude": "-127.3669968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "11/29",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 57987,
            "s_seats": 46306,
            "j_seats": 6829,
            "f_seats": 19549,
            "r_seats": 5430,
            "cargo_demand": 64516
        }
    },
    {
        "iata": "YZU",
        "icao": "CYZU",
        "airportName": "Whitecourt Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "Whitecourt",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.14390182",
        "longitude": "-115.7870026",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "5800",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 68303,
            "s_seats": 38573,
            "j_seats": 17269,
            "f_seats": 6352,
            "r_seats": 6180,
            "cargo_demand": 390262
        }
    },
    {
        "iata": "YZV",
        "icao": "CYZV",
        "airportName": "Sept-Iles Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Sept-Iles",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "50.22330093",
        "longitude": "-66.26560211",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5900",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09/27",
                "runway_length": "6552",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "5771",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 134768,
            "s_seats": 27725,
            "j_seats": 12779,
            "f_seats": 7782,
            "r_seats": 9439,
            "cargo_demand": 127657
        }
    },
    {
        "iata": "YZW",
        "icao": "CYZW",
        "airportName": "Teslin Airport",
        "location_country": "Canada",
        "location_city": "Yukon",
        "location_state": "Teslin",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "60.17279816",
        "longitude": "-132.7429962",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 126942,
            "s_seats": 48935,
            "j_seats": 51923,
            "f_seats": 17901,
            "r_seats": 4582,
            "cargo_demand": 445976
        }
    },
    {
        "iata": "YZX",
        "icao": "CYZX",
        "airportName": "CFB Greenwood",
        "location_country": "Canada",
        "location_city": "Nova Scotia",
        "location_state": "Greenwood",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.9844017",
        "longitude": "-64.91690063",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12/30",
                "runway_length": "8000",
                "runway_width": "200",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62733,
            "s_seats": 34392,
            "j_seats": 17878,
            "f_seats": 12944,
            "r_seats": 8233,
            "cargo_demand": 365706
        }
    },
    {
        "iata": "ZAC",
        "icao": "CZAC",
        "airportName": "York Landing Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "York Landing",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "56.08940125",
        "longitude": "-96.08920288",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3395",
                "runway_width": "85",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 100421,
            "s_seats": 26466,
            "j_seats": 46860,
            "f_seats": 5133,
            "r_seats": 3879,
            "cargo_demand": 453439
        }
    },
    {
        "iata": "ZAD",
        "icao": "LDZD",
        "airportName": "Zadar Airport",
        "location_country": "Croatia",
        "location_city": "Zadar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.108299",
        "longitude": "15.3467",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "6561",
                "runway_width": "147",
                "runway_material": "ASP"
            },
            {
                "runway_name": "13/31",
                "runway_length": "8202",
                "runway_width": "147",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 136180,
            "s_seats": 44621,
            "j_seats": 23625,
            "f_seats": 5594,
            "r_seats": 8992,
            "cargo_demand": 81711
        }
    },
    {
        "iata": "ZAG",
        "icao": "LDZA",
        "airportName": "Franjo Tu\u0111man Airport",
        "location_country": "Croatia",
        "location_city": "Zagreb",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "45.74290085",
        "longitude": "16.06879997",
        "region": "EU",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "10669",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 497703,
            "s_seats": 94770,
            "j_seats": 20909,
            "f_seats": 63751,
            "r_seats": 10000,
            "cargo_demand": 2493931
        }
    },
    {
        "iata": "ZAH",
        "icao": "OIZH",
        "airportName": "Zahedan Airport",
        "location_country": "Iran",
        "location_city": "Zahedan",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "29.47570038",
        "longitude": "60.90620041",
        "region": "AS",
        "runway": [
            {
                "runway_name": "17L/35R",
                "runway_length": "13993",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "17R/35L",
                "runway_length": "13996",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 72103,
            "s_seats": 26258,
            "j_seats": 24634,
            "f_seats": 7011,
            "r_seats": 4884,
            "cargo_demand": 376632
        }
    },
    {
        "iata": "ZAL",
        "icao": "SCVD",
        "airportName": "Pichoy Airport",
        "location_country": "Chile",
        "location_city": "Valdivia",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-39.65000153",
        "longitude": "-73.08609772",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "6870",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 126184,
            "s_seats": 29218,
            "j_seats": 38896,
            "f_seats": 14596,
            "r_seats": 6639,
            "cargo_demand": 120090
        }
    },
    {
        "iata": "ZAM",
        "icao": "RPMZ",
        "airportName": "Zamboanga International Airport",
        "location_country": "Philippines",
        "location_city": "Zamboanga City",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "6.922420025",
        "longitude": "122.0599976",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "8560",
                "runway_width": "144",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 163082,
            "s_seats": 45685,
            "j_seats": 37323,
            "f_seats": 10867,
            "r_seats": 4941,
            "cargo_demand": 260602
        }
    },
    {
        "iata": "ZAO",
        "icao": "LFCC",
        "airportName": "Cahors - Lalbenque Airport",
        "location_country": "France",
        "location_city": "Midi-Pyrenees",
        "location_state": "Cahors",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "44.351398",
        "longitude": "1.47528",
        "region": "EU",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "4921",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 151322,
            "s_seats": 39033,
            "j_seats": 15014,
            "f_seats": 10767,
            "r_seats": 8308,
            "cargo_demand": 356027
        }
    },
    {
        "iata": "ZAR",
        "icao": "DNZA",
        "airportName": "Zaria Airport",
        "location_country": "Nigeria",
        "location_city": "Zaria",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "11.1302",
        "longitude": "7.68581",
        "region": "AF",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "5462",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147094,
            "s_seats": 30763,
            "j_seats": 36908,
            "f_seats": 5248,
            "r_seats": 5886,
            "cargo_demand": 241544
        }
    },
    {
        "iata": "ZAZ",
        "icao": "LEZG",
        "airportName": "Zaragoza Airport",
        "location_country": "Spain",
        "location_city": "Aragon",
        "location_state": "Zaragoza",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "41.66619873",
        "longitude": "-1.04155004",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12L/30R",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "12R/30L",
                "runway_length": "12198",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 107714,
            "s_seats": 34118,
            "j_seats": 8554,
            "f_seats": 14492,
            "r_seats": 7728,
            "cargo_demand": 291537
        }
    },
    {
        "iata": "ZBE",
        "icao": "LKZA",
        "airportName": "Zab\u0159eh Airport",
        "location_country": "Czech Republic",
        "location_city": "Dolni Beneo\u00a1ov",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "49.92829895",
        "longitude": "18.07830048",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10L/28R",
                "runway_length": "6397",
                "runway_width": "98",
                "runway_material": ""
            },
            {
                "runway_name": "10R/28L",
                "runway_length": "2952",
                "runway_width": "295",
                "runway_material": ""
            }
        ],
        "demand": {
            "y_seats": 3728,
            "s_seats": 2084,
            "j_seats": 1850,
            "f_seats": 66,
            "r_seats": 110,
            "cargo_demand": 26459
        }
    },
    {
        "iata": "ZBF",
        "icao": "CZBF",
        "airportName": "Bathurst Airport",
        "location_country": "Canada",
        "location_city": "New Brunswick",
        "location_state": "Bathurst",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "47.62969971",
        "longitude": "-65.73889923",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "4500",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 74732,
            "s_seats": 25374,
            "j_seats": 10870,
            "f_seats": 13426,
            "r_seats": 4049,
            "cargo_demand": 315217
        }
    },
    {
        "iata": "ZBM",
        "icao": "CZBM",
        "airportName": "Roland-Desourdy Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Bromont",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "45.29079819",
        "longitude": "-72.74140167",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05L/23R",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "05R/23L",
                "runway_length": "3200",
                "runway_width": "320",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 118754,
            "s_seats": 26305,
            "j_seats": 55307,
            "f_seats": 10220,
            "r_seats": 6656,
            "cargo_demand": 247485
        }
    },
    {
        "iata": "ZBO",
        "icao": "YBWN",
        "airportName": "Bowen Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "Bowen",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.017999",
        "longitude": "148.215245",
        "region": "OC",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "4399",
                "runway_width": "",
                "runway_material": "G"
            },
            {
                "runway_name": "12/30",
                "runway_length": "3290",
                "runway_width": "",
                "runway_material": "G"
            }
        ],
        "demand": {
            "y_seats": 1938,
            "s_seats": 2106,
            "j_seats": 1687,
            "f_seats": 123,
            "r_seats": 26,
            "cargo_demand": 44565
        }
    },
    {
        "iata": "ZBR",
        "icao": "OIZC",
        "airportName": "Konarak Airport",
        "location_country": "Iran",
        "location_city": "Chabahar (Chah Bahar)",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "25.44330025",
        "longitude": "60.38209915",
        "region": "AS",
        "runway": [
            {
                "runway_name": "09L/27R",
                "runway_length": "12514",
                "runway_width": "150",
                "runway_material": "ASP"
            },
            {
                "runway_name": "09R/27L",
                "runway_length": "9844",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 104481,
            "s_seats": 45206,
            "j_seats": 41360,
            "f_seats": 6773,
            "r_seats": 4492,
            "cargo_demand": 195983
        }
    },
    {
        "iata": "ZCL",
        "icao": "MMZC",
        "airportName": "General Leobardo C. Ruiz International Airport",
        "location_country": "Mexico",
        "location_city": "Zacatecas",
        "location_state": "Zacatecas City",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "22.89710045",
        "longitude": "-102.6869965",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "9843",
                "runway_width": "148",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "3281",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150441,
            "s_seats": 35191,
            "j_seats": 46866,
            "f_seats": 12780,
            "r_seats": 3870,
            "cargo_demand": 298254
        }
    },
    {
        "iata": "ZEC",
        "icao": "FASC",
        "airportName": "Secunda Airport",
        "location_country": "South Africa",
        "location_city": "Secunda",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-26.52409935",
        "longitude": "29.17009926",
        "region": "AF",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3609",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 133256,
            "s_seats": 41270,
            "j_seats": 58976,
            "f_seats": 14486,
            "r_seats": 7261,
            "cargo_demand": 242584
        }
    },
    {
        "iata": "ZEL",
        "icao": "CBBC",
        "airportName": "Bella Bella (Campbell Island) Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Bella Bella",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "52.185001",
        "longitude": "-128.156994",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "3700",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1957,
            "s_seats": 1967,
            "j_seats": 1569,
            "f_seats": 303,
            "r_seats": 122,
            "cargo_demand": 97514
        }
    },
    {
        "iata": "ZEM",
        "icao": "CZEM",
        "airportName": "Eastmain River Airport",
        "location_country": "Canada",
        "location_city": "Quebec",
        "location_state": "Eastmain",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.22639847",
        "longitude": "-78.52249908",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3510",
                "runway_width": "100",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 172240,
            "s_seats": 41465,
            "j_seats": 31795,
            "f_seats": 5561,
            "r_seats": 4271,
            "cargo_demand": 415197
        }
    },
    {
        "iata": "ZER",
        "icao": "VEZO",
        "airportName": "Zero Airport",
        "location_country": "India",
        "location_city": "Arunachal Pradesh",
        "location_state": "Ziro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "27.588301",
        "longitude": "93.828102",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4010",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 62331,
            "s_seats": 27417,
            "j_seats": 42464,
            "f_seats": 4107,
            "r_seats": 3209,
            "cargo_demand": 435894
        }
    },
    {
        "iata": "ZFA",
        "icao": "CZFA",
        "airportName": "Faro Airport",
        "location_country": "Canada",
        "location_city": "Yukon",
        "location_state": "Faro",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "62.20750046",
        "longitude": "-133.3760071",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "4000",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 136084,
            "s_seats": 46077,
            "j_seats": 11279,
            "f_seats": 18333,
            "r_seats": 3501,
            "cargo_demand": 202651
        }
    },
    {
        "iata": "ZFD",
        "icao": "CZFD",
        "airportName": "Fond-du-Lac Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Fond-du-Lac",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "59.33440018",
        "longitude": "-107.1819992",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3800",
                "runway_width": "75",
                "runway_material": "TREATED GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 189044,
            "s_seats": 35406,
            "j_seats": 40978,
            "f_seats": 1079,
            "r_seats": 6313,
            "cargo_demand": 387488
        }
    },
    {
        "iata": "ZFM",
        "icao": "CZFM",
        "airportName": "Fort McPherson Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Fort McPherson",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "67.40750122",
        "longitude": "-134.8609924",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GVL"
            }
        ],
        "demand": {
            "y_seats": 137653,
            "s_seats": 49214,
            "j_seats": 58535,
            "f_seats": 13663,
            "r_seats": 8439,
            "cargo_demand": 441822
        }
    },
    {
        "iata": "ZFN",
        "icao": "CZFN",
        "airportName": "Tulita Airport",
        "location_country": "Canada",
        "location_city": "Northwest Territories",
        "location_state": "Tulita",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "64.909697",
        "longitude": "-125.572998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "06/24",
                "runway_length": "3935",
                "runway_width": "100",
                "runway_material": "gravel"
            }
        ],
        "demand": {
            "y_seats": 82106,
            "s_seats": 47286,
            "j_seats": 54039,
            "f_seats": 18327,
            "r_seats": 4658,
            "cargo_demand": 76915
        }
    },
    {
        "iata": "ZGF",
        "icao": "CZGF",
        "airportName": "Grand Forks Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Grand Forks",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.0155983",
        "longitude": "-118.4309998",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "4300",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 115335,
            "s_seats": 27665,
            "j_seats": 20569,
            "f_seats": 19191,
            "r_seats": 6348,
            "cargo_demand": 399455
        }
    },
    {
        "iata": "ZGI",
        "icao": "CZGI",
        "airportName": "Gods River Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Gods River",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.83969879",
        "longitude": "-94.07859802",
        "region": "NA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "3540",
                "runway_width": "80",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 99731,
            "s_seats": 34910,
            "j_seats": 24587,
            "f_seats": 19129,
            "r_seats": 7538,
            "cargo_demand": 390583
        }
    },
    {
        "iata": "ZGL",
        "icao": "YSGW",
        "airportName": "South Galway Airport",
        "location_country": "Australia",
        "location_city": "Queensland",
        "location_state": "South Galway Station",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-25.68330002",
        "longitude": "142.1080017",
        "region": "OC",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "3572",
                "runway_width": "",
                "runway_material": "S"
            }
        ],
        "demand": {
            "y_seats": 3603,
            "s_seats": 2727,
            "j_seats": 1699,
            "f_seats": 484,
            "r_seats": 147,
            "cargo_demand": 23993
        }
    },
    {
        "iata": "ZGR",
        "icao": "CZGR",
        "airportName": "Little Grand Rapids Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Little Grand Rapids",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.04560089",
        "longitude": "-95.46579742",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "2800",
                "runway_width": "75",
                "runway_material": "CRUSHED ROCK"
            }
        ],
        "demand": {
            "y_seats": 117436,
            "s_seats": 38119,
            "j_seats": 22513,
            "f_seats": 7145,
            "r_seats": 8063,
            "cargo_demand": 88222
        }
    },
    {
        "iata": "ZGU",
        "icao": "NVSQ",
        "airportName": "Gaua Airport",
        "location_country": "Vanuatu",
        "location_city": "Gaua",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.21809959",
        "longitude": "167.5870056",
        "region": "OC",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2802",
                "runway_width": "59",
                "runway_material": "Grass"
            }
        ],
        "demand": {
            "y_seats": 87584,
            "s_seats": 33585,
            "j_seats": 14750,
            "f_seats": 19038,
            "r_seats": 3083,
            "cargo_demand": 333250
        }
    },
    {
        "iata": "ZHP",
        "icao": "CZHP",
        "airportName": "High Prairie Airport",
        "location_country": "Canada",
        "location_city": "Alberta",
        "location_state": "High Prairie",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "55.39360046",
        "longitude": "-116.4749985",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3933",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3996,
            "s_seats": 2452,
            "j_seats": 1796,
            "f_seats": 401,
            "r_seats": 175,
            "cargo_demand": 30108
        }
    },
    {
        "iata": "ZIA",
        "icao": "UUBW",
        "airportName": "Ramenskoye Airport",
        "location_country": "Russia",
        "location_city": "Moscow Oblast",
        "location_state": "Zhukovsky",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "55.553299",
        "longitude": "38.150002",
        "region": "EU",
        "runway": [
            {
                "runway_name": "12/30",
                "runway_length": "15092",
                "runway_width": "230",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 445299,
            "s_seats": 87704,
            "j_seats": 52752,
            "f_seats": 44053,
            "r_seats": 10000,
            "cargo_demand": 27738743
        }
    },
    {
        "iata": "ZIC",
        "icao": "SCTO",
        "airportName": "Victoria Airport",
        "location_country": "Chile",
        "location_city": "Victoria",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-38.24560165",
        "longitude": "-72.34860229",
        "region": "SA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "3478",
                "runway_width": "59",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 174624,
            "s_seats": 29965,
            "j_seats": 17171,
            "f_seats": 10363,
            "r_seats": 7360,
            "cargo_demand": 443728
        }
    },
    {
        "iata": "ZIG",
        "icao": "GOGG",
        "airportName": "Ziguinchor Airport",
        "location_country": "Senegal",
        "location_city": "Ziguinchor",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "12.5556",
        "longitude": "-16.281799",
        "region": "AF",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "5069",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 175280,
            "s_seats": 48410,
            "j_seats": 43886,
            "f_seats": 17306,
            "r_seats": 3484,
            "cargo_demand": 262727
        }
    },
    {
        "iata": "ZIH",
        "icao": "MMZH",
        "airportName": "Ixtapa-Zihuatanejo International Airport",
        "location_country": "Mexico",
        "location_city": "Guerrero",
        "location_state": "Ixtapa / Zihuatanejo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "17.60160065",
        "longitude": "-101.4609985",
        "region": "NA",
        "runway": [
            {
                "runway_name": "08/26",
                "runway_length": "8202",
                "runway_width": "197",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 150963,
            "s_seats": 48848,
            "j_seats": 54929,
            "f_seats": 3258,
            "r_seats": 5250,
            "cargo_demand": 147428
        }
    },
    {
        "iata": "ZJG",
        "icao": "CZJG",
        "airportName": "Jenpeg Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Jenpeg",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.51890182",
        "longitude": "-98.0460968",
        "region": "NA",
        "runway": [
            {
                "runway_name": "14/32",
                "runway_length": "3800",
                "runway_width": "100",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 81435,
            "s_seats": 49161,
            "j_seats": 32647,
            "f_seats": 3489,
            "r_seats": 3426,
            "cargo_demand": 317829
        }
    },
    {
        "iata": "ZJN",
        "icao": "CZJN",
        "airportName": "Swan River Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Swan River",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.12060165",
        "longitude": "-101.2360001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "3933",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "08/26",
                "runway_length": "1950",
                "runway_width": "75",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 162835,
            "s_seats": 45451,
            "j_seats": 39344,
            "f_seats": 6293,
            "r_seats": 6764,
            "cargo_demand": 270347
        }
    },
    {
        "iata": "ZKE",
        "icao": "CZKE",
        "airportName": "Kashechewan Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Kashechewan",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.28250122",
        "longitude": "-81.67780304",
        "region": "NA",
        "runway": [
            {
                "runway_name": "07/25",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 169698,
            "s_seats": 27838,
            "j_seats": 40115,
            "f_seats": 3318,
            "r_seats": 6160,
            "cargo_demand": 204281
        }
    },
    {
        "iata": "ZLO",
        "icao": "MMZO",
        "airportName": "Playa de Oro International Airport",
        "location_country": "Mexico",
        "location_city": "Colima",
        "location_state": "Manzanillo",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "19.14480019",
        "longitude": "-104.5589981",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "7218",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 102889,
            "s_seats": 39839,
            "j_seats": 22013,
            "f_seats": 12880,
            "r_seats": 6924,
            "cargo_demand": 105323
        }
    },
    {
        "iata": "ZMH",
        "icao": "CZML",
        "airportName": "South Cariboo Regional Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "108 Mile Ranch",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "51.73609924",
        "longitude": "-121.3330002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "5293",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 1945,
            "s_seats": 2306,
            "j_seats": 1320,
            "f_seats": 388,
            "r_seats": 184,
            "cargo_demand": 67371
        }
    },
    {
        "iata": "ZMM",
        "icao": "MMZM",
        "airportName": "Zamora National Airport",
        "location_country": "Mexico",
        "location_city": "Michoacan",
        "location_state": "Zamora",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "20.045",
        "longitude": "-102.276001",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "4921",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2692,
            "s_seats": 1871,
            "j_seats": 1862,
            "f_seats": 232,
            "r_seats": 21,
            "cargo_demand": 3854
        }
    },
    {
        "iata": "ZMT",
        "icao": "CZMT",
        "airportName": "Masset Airport",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Masset",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "54.02750015",
        "longitude": "-132.125",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5000",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 111762,
            "s_seats": 35248,
            "j_seats": 36845,
            "f_seats": 7411,
            "r_seats": 6481,
            "cargo_demand": 263733
        }
    },
    {
        "iata": "ZND",
        "icao": "DRZR",
        "airportName": "Zinder Airport",
        "location_country": "Niger",
        "location_city": "Zinder",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "13.77900028",
        "longitude": "8.98375988",
        "region": "AF",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "5988",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 58295,
            "s_seats": 49598,
            "j_seats": 43605,
            "f_seats": 11687,
            "r_seats": 5447,
            "cargo_demand": 254575
        }
    },
    {
        "iata": "ZNE",
        "icao": "YNWN",
        "airportName": "Newman Airport",
        "location_country": "Australia",
        "location_city": "Western Australia",
        "location_state": "Newman",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-23.4178009",
        "longitude": "119.8030014",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6798",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 146494,
            "s_seats": 35458,
            "j_seats": 14413,
            "f_seats": 1139,
            "r_seats": 3506,
            "cargo_demand": 426120
        }
    },
    {
        "iata": "ZNZ",
        "icao": "HTZA",
        "airportName": "Abeid Amani Karume International Airport",
        "location_country": "Tanzania",
        "location_city": "Zanzibar",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "-6.22202",
        "longitude": "39.224899",
        "region": "AF",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "9915",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 182508,
            "s_seats": 77085,
            "j_seats": 52257,
            "f_seats": 22598,
            "r_seats": 10000,
            "cargo_demand": 15957872
        }
    },
    {
        "iata": "ZOS",
        "icao": "SCJO",
        "airportName": "Canal Bajo Carlos Hott Siebert Airport",
        "location_country": "Chile",
        "location_city": "Osorno",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-40.611198",
        "longitude": "-73.060997",
        "region": "SA",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "6398",
                "runway_width": "118",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 106928,
            "s_seats": 26639,
            "j_seats": 52401,
            "f_seats": 8025,
            "r_seats": 5238,
            "cargo_demand": 459657
        }
    },
    {
        "iata": "ZPB",
        "icao": "CZPB",
        "airportName": "Sachigo Lake Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Sachigo Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.89110184",
        "longitude": "-92.1964035",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 182873,
            "s_seats": 40914,
            "j_seats": 14914,
            "f_seats": 6295,
            "r_seats": 4583,
            "cargo_demand": 293806
        }
    },
    {
        "iata": "ZPC",
        "icao": "SCPC",
        "airportName": "Pucon Airport",
        "location_country": "Chile",
        "location_city": "Pucon",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-39.2928009",
        "longitude": "-71.91590118",
        "region": "SA",
        "runway": [
            {
                "runway_name": "09/27",
                "runway_length": "5576",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2311,
            "s_seats": 2076,
            "j_seats": 1867,
            "f_seats": 36,
            "r_seats": 176,
            "cargo_demand": 89480
        }
    },
    {
        "iata": "ZPH",
        "icao": "KZPH",
        "airportName": "Zephyrhills Municipal Airport",
        "location_country": "United States",
        "location_city": "Florida",
        "location_state": "Zephyrhills",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "28.22820091",
        "longitude": "-82.15589905",
        "region": "NA",
        "runway": [
            {
                "runway_name": "19/1",
                "runway_length": "5072",
                "runway_width": "100",
                "runway_material": "ASP"
            },
            {
                "runway_name": "5/23",
                "runway_length": "5001",
                "runway_width": "100",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3095,
            "s_seats": 1923,
            "j_seats": 1134,
            "f_seats": 198,
            "r_seats": 14,
            "cargo_demand": 97668
        }
    },
    {
        "iata": "ZPO",
        "icao": "CZPO",
        "airportName": "Pinehouse Lake Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Pinehouse",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "55.52809906",
        "longitude": "-106.5820007",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3000",
                "runway_width": "65",
                "runway_material": "GRAVEL/CLAY"
            }
        ],
        "demand": {
            "y_seats": 3745,
            "s_seats": 2536,
            "j_seats": 1322,
            "f_seats": 446,
            "r_seats": 65,
            "cargo_demand": 38314
        }
    },
    {
        "iata": "ZQN",
        "icao": "NZQN",
        "airportName": "Queenstown Airport",
        "location_country": "New Zealand",
        "location_city": "Queenstown",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-45.021099",
        "longitude": "168.738998",
        "region": "OC",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "6204",
                "runway_width": "98",
                "runway_material": "ASP"
            },
            {
                "runway_name": "14/32",
                "runway_length": "3097",
                "runway_width": "197",
                "runway_material": "GRS"
            }
        ],
        "demand": {
            "y_seats": 112022,
            "s_seats": 46920,
            "j_seats": 41671,
            "f_seats": 3226,
            "r_seats": 6911,
            "cargo_demand": 175877
        }
    },
    {
        "iata": "ZQW",
        "icao": "EDRZ",
        "airportName": "Zweibrucken Airport",
        "location_country": "Germany",
        "location_city": "Rhineland-Palatinate",
        "location_state": "Zweibrucken",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "49.2094",
        "longitude": "7.40056",
        "region": "EU",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "9677",
                "runway_width": "148",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 3699,
            "s_seats": 2028,
            "j_seats": 1817,
            "f_seats": 78,
            "r_seats": 168,
            "cargo_demand": 16899
        }
    },
    {
        "iata": "ZRH",
        "icao": "LSZH",
        "airportName": "Zurich Airport",
        "location_country": "Switzerland",
        "location_city": "Zurich",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "large_airport",
        "latitude": "47.458056",
        "longitude": "8.548056",
        "region": "EU",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8202",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "14/32",
                "runway_length": "10827",
                "runway_width": "197",
                "runway_material": "CON"
            },
            {
                "runway_name": "16/34",
                "runway_length": "12139",
                "runway_width": "197",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 417535,
            "s_seats": 89122,
            "j_seats": 67297,
            "f_seats": 52575,
            "r_seats": 10000,
            "cargo_demand": 615044
        }
    },
    {
        "iata": "ZRI",
        "icao": "WABO",
        "airportName": "Serui Airport",
        "location_country": "Indonesia",
        "location_city": "Serui",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.875579953",
        "longitude": "136.2409973",
        "region": "AS",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "2133",
                "runway_width": "66",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 3133,
            "s_seats": 2836,
            "j_seats": 1396,
            "f_seats": 300,
            "r_seats": 133,
            "cargo_demand": 44273
        }
    },
    {
        "iata": "ZRJ",
        "icao": "CZRJ",
        "airportName": "Round Lake (Weagamow Lake) Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "North Caribou Lake (Round Lake)",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "52.9435997",
        "longitude": "-91.31279755",
        "region": "NA",
        "runway": [
            {
                "runway_name": "03/21",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 176538,
            "s_seats": 48651,
            "j_seats": 32945,
            "f_seats": 2265,
            "r_seats": 4030,
            "cargo_demand": 187744
        }
    },
    {
        "iata": "ZRM",
        "icao": "WAJI",
        "airportName": "Sarmi Orai Airport",
        "location_country": "Indonesia",
        "location_city": "Sarmi",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-1.86954999",
        "longitude": "138.75",
        "region": "AS",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "2451",
                "runway_width": "72",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2578,
            "s_seats": 1851,
            "j_seats": 1962,
            "f_seats": 15,
            "r_seats": 87,
            "cargo_demand": 89845
        }
    },
    {
        "iata": "ZSA",
        "icao": "MYSM",
        "airportName": "San Salvador Airport",
        "location_country": "Bahamas",
        "location_city": "San Salvador Island",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.063299",
        "longitude": "-74.524002",
        "region": "NA",
        "runway": [
            {
                "runway_name": "10/28",
                "runway_length": "8000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 195126,
            "s_seats": 41913,
            "j_seats": 20669,
            "f_seats": 5066,
            "r_seats": 9891,
            "cargo_demand": 109793
        }
    },
    {
        "iata": "ZSE",
        "icao": "FMEP",
        "airportName": "Pierrefonds Airport",
        "location_country": "France",
        "location_city": "Reunion",
        "location_state": "Saint-Pierre",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-21.32089996",
        "longitude": "55.42499924",
        "region": "AF",
        "runway": [
            {
                "runway_name": "15/33",
                "runway_length": "7000",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 177168,
            "s_seats": 46783,
            "j_seats": 45807,
            "f_seats": 15166,
            "r_seats": 5730,
            "cargo_demand": 61048
        }
    },
    {
        "iata": "ZSJ",
        "icao": "CZSJ",
        "airportName": "Sandy Lake Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Sandy Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "53.06420135",
        "longitude": "-93.3443985",
        "region": "NA",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "3500",
                "runway_width": "100",
                "runway_material": "GRVL"
            }
        ],
        "demand": {
            "y_seats": 112626,
            "s_seats": 48831,
            "j_seats": 9082,
            "f_seats": 4952,
            "r_seats": 3569,
            "cargo_demand": 82273
        }
    },
    {
        "iata": "ZST",
        "icao": "CZST",
        "airportName": "Stewart Aerodrome",
        "location_country": "Canada",
        "location_city": "British Columbia",
        "location_state": "Stewart",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.93541045",
        "longitude": "-129.9824345",
        "region": "NA",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "3900",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 147155,
            "s_seats": 35399,
            "j_seats": 16544,
            "f_seats": 16401,
            "r_seats": 9118,
            "cargo_demand": 258832
        }
    },
    {
        "iata": "ZTA",
        "icao": "NTGY",
        "airportName": "Tureia Airport",
        "location_country": "French Polynesia",
        "location_city": "Tuamotus",
        "location_state": "Tureia",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-20.78969955",
        "longitude": "-138.5700073",
        "region": "OC",
        "runway": [
            {
                "runway_name": "18/36",
                "runway_length": "2949",
                "runway_width": "60",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 3835,
            "s_seats": 2496,
            "j_seats": 1483,
            "f_seats": 11,
            "r_seats": 56,
            "cargo_demand": 95042
        }
    },
    {
        "iata": "ZTH",
        "icao": "LGZA",
        "airportName": "Zakynthos International Airport",
        "location_country": "Greece",
        "location_city": "Zakynthos",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "37.7509",
        "longitude": "20.8843",
        "region": "EU",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "7310",
                "runway_width": "148",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 121423,
            "s_seats": 25535,
            "j_seats": 57477,
            "f_seats": 11172,
            "r_seats": 4592,
            "cargo_demand": 229624
        }
    },
    {
        "iata": "ZTM",
        "icao": "CZTM",
        "airportName": "Shamattawa Airport",
        "location_country": "Canada",
        "location_city": "Manitoba",
        "location_state": "Shamattawa",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "55.86560059",
        "longitude": "-92.08139801",
        "region": "NA",
        "runway": [
            {
                "runway_name": "01/19",
                "runway_length": "4006",
                "runway_width": "90",
                "runway_material": "GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 100225,
            "s_seats": 25701,
            "j_seats": 46100,
            "f_seats": 13382,
            "r_seats": 7991,
            "cargo_demand": 82116
        }
    },
    {
        "iata": "ZUC",
        "icao": "CZUC",
        "airportName": "Ignace Municipal Airport",
        "location_country": "Canada",
        "location_city": "Ontario",
        "location_state": "Ignace",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "49.42969894",
        "longitude": "-91.71779633",
        "region": "NA",
        "runway": [
            {
                "runway_name": "05/23",
                "runway_length": "3512",
                "runway_width": "75",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 191821,
            "s_seats": 44487,
            "j_seats": 58081,
            "f_seats": 3039,
            "r_seats": 5592,
            "cargo_demand": 88589
        }
    },
    {
        "iata": "ZUD",
        "icao": "SCAC",
        "airportName": "Pupelde Airfield",
        "location_country": "Chile",
        "location_city": "Ancud",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "-41.904251",
        "longitude": "-73.796571",
        "region": "SA",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "3937",
                "runway_width": "98",
                "runway_material": "CON/PAD"
            }
        ],
        "demand": {
            "y_seats": 3672,
            "s_seats": 1672,
            "j_seats": 1748,
            "f_seats": 203,
            "r_seats": 75,
            "cargo_demand": 88012
        }
    },
    {
        "iata": "ZUM",
        "icao": "CZUM",
        "airportName": "Churchill Falls Airport",
        "location_country": "Canada",
        "location_city": "Newfoundland and Labrador",
        "location_state": "Churchill Falls",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "small_airport",
        "latitude": "53.56190109",
        "longitude": "-64.10639954",
        "region": "NA",
        "runway": [
            {
                "runway_name": "13/31",
                "runway_length": "5500",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 2967,
            "s_seats": 1592,
            "j_seats": 1675,
            "f_seats": 7,
            "r_seats": 173,
            "cargo_demand": 38488
        }
    },
    {
        "iata": "ZVA",
        "icao": "FMMN",
        "airportName": "Miandrivazo Airport",
        "location_country": "Madagascar",
        "location_city": "Miandrivazo",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-19.562799",
        "longitude": "45.450802",
        "region": "AF",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3609",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 96087,
            "s_seats": 49253,
            "j_seats": 19857,
            "f_seats": 10909,
            "r_seats": 8954,
            "cargo_demand": 122327
        }
    },
    {
        "iata": "ZVK",
        "icao": "VLSK",
        "airportName": "Savannakhet Airport",
        "location_country": "Laos",
        "location_city": "Savannakhet",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "16.55660057",
        "longitude": "104.7600021",
        "region": "AS",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5358",
                "runway_width": "125",
                "runway_material": "CON"
            }
        ],
        "demand": {
            "y_seats": 197565,
            "s_seats": 27225,
            "j_seats": 9103,
            "f_seats": 19662,
            "r_seats": 8547,
            "cargo_demand": 408470
        }
    },
    {
        "iata": "ZWA",
        "icao": "FMND",
        "airportName": "Andapa Airport",
        "location_country": "Madagascar",
        "location_city": "Andapa",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-14.65170002",
        "longitude": "49.62060165",
        "region": "AF",
        "runway": [
            {
                "runway_name": "02/20",
                "runway_length": "4153",
                "runway_width": "98",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 66102,
            "s_seats": 37315,
            "j_seats": 57350,
            "f_seats": 19152,
            "r_seats": 8564,
            "cargo_demand": 187808
        }
    },
    {
        "iata": "ZWL",
        "icao": "CZWL",
        "airportName": "Wollaston Lake Airport",
        "location_country": "Canada",
        "location_city": "Saskatchewan",
        "location_state": "Wollaston Lake",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "58.10689926",
        "longitude": "-103.1719971",
        "region": "NA",
        "runway": [
            {
                "runway_name": "16/34",
                "runway_length": "3800",
                "runway_width": "75",
                "runway_material": "TREATED GRAVEL"
            }
        ],
        "demand": {
            "y_seats": 107248,
            "s_seats": 30670,
            "j_seats": 6248,
            "f_seats": 5640,
            "r_seats": 6211,
            "cargo_demand": 242555
        }
    },
    {
        "iata": "ZYL",
        "icao": "VGSY",
        "airportName": "Osmani International Airport",
        "location_country": "Bangladesh",
        "location_city": "Sylhet",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "24.96319962",
        "longitude": "91.8667984",
        "region": "AS",
        "runway": [
            {
                "runway_name": "11/29",
                "runway_length": "9478",
                "runway_width": "150",
                "runway_material": "ASP"
            }
        ],
        "demand": {
            "y_seats": 171611,
            "s_seats": 25386,
            "j_seats": 24911,
            "f_seats": 12620,
            "r_seats": 7362,
            "cargo_demand": 199282
        }
    },
    {
        "iata": "ZZU",
        "icao": "FWUU",
        "airportName": "Mzuzu Airport",
        "location_country": "Malawi",
        "location_city": "Mzuzu",
        "location_state": "",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "-11.44470024",
        "longitude": "34.01179886",
        "region": "AF",
        "runway": [
            {
                "runway_name": "17/35",
                "runway_length": "4291",
                "runway_width": "62",
                "runway_material": "BIT"
            }
        ],
        "demand": {
            "y_seats": 190141,
            "s_seats": 49840,
            "j_seats": 44822,
            "f_seats": 4413,
            "r_seats": 9320,
            "cargo_demand": 495117
        }
    },
    {
        "iata": "ZZV",
        "icao": "KZZV",
        "airportName": "Zanesville Municipal Airport",
        "location_country": "United States",
        "location_city": "Ohio",
        "location_state": "Zanesville",
        "time_zone": "",
        "daylight_saving": "",
        "airport_size": "medium_airport",
        "latitude": "39.94440079",
        "longitude": "-81.89209747",
        "region": "NA",
        "runway": [
            {
                "runway_name": "04/22",
                "runway_length": "5000",
                "runway_width": "150",
                "runway_material": "PEM"
            },
            {
                "runway_name": "16/34",
                "runway_length": "4999",
                "runway_width": "150",
                "runway_material": "PEM"
            }
        ],
        "demand": {
            "y_seats": 73411,
            "s_seats": 28624,
            "j_seats": 50424,
            "f_seats": 10901,
            "r_seats": 9435,
            "cargo_demand": 70697
        }
    }
]
 