import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { tidyDate } from '../../utils/helper'
import notify from '../../utils/IANotification'

export default function ClubReimbursementPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)


  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [data, setData] = useState([])

  const fetchClubReimbursement = async () => {
    let endpoint = config.endpoint + "/reimbursement/club/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (response.status === 200) {
      setData(data.data?.reverse())
    }
  }

  const get_invoice_link = async (payment_id) => {
    let endpoint = config.endpoint + "/payments/invoice/link/id/" + payment_id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 201) {
      window.location.href = data?.data
    } else {
      fetchClubReimbursement()
      notify({ type: "error", message: data?.message })
    }
  }


  useEffect(() => {
    fetchClubReimbursement()
  }, [])

  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Dashboard"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header">
              <h1>Club Payments</h1>
            </div>
          </div>
          <div className="container">
            <div className="inner-container">
              {data.map((item, index) => {
                return (
                  <div className='card mb-4 rounded-md shadow-md p-4'>
                    <div className='row gap-4 items-center '>
                      <p style={{ color: "gray", letterSpacing: "1", whiteSpace: "nowrap" }}>{(item?.is_paid == "1" || item?.staff_id) ? `PAID at ${tidyDate(item?.paidAt)}` : `PENDING PAYMENT`}</p>
                      <div style={{ height: 1, backgroundColor: "gray", width: "100%" }} />
                    </div>
                    {/* <p className="card-title">{item?.user_data?.first_name} {item?.user_data?.last_name} - ${item?.amount / 100} SGD</p> */}
                    <div className="row justify-between items-center">
                      <p className='card-title'>{item?.user_data?.first_name} {item?.user_data?.last_name} - ${item?.amount / 100} SGD</p>
                      <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2"
                        onClick={() => {
                          navigate(`/club/accounting/reimbursement/${item?.id}`)
                        }}
                      >View Request</button>
                    </div>
                    {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
