import React, { useState, useEffect, useLayoutEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'
import { airport_database } from '../../database/Civil-Airport-Database'
import { convertPilotLicenseType } from '../../utils/helper'

export default function FlightBookingDetailPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  let booking_id = useParams().booking_id
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  const [bookingDetails, setBookingDetails] = useState({
    flight_departure_datetime: "",
    flight_arrival_datetime: "",
    aerial_resource_id: "",
    instructor_id: "",
    examiner_id: "",
    booking_status: "pending", // "pending", "cancelled", "completed"
    is_paid: 0,
    flight_type: "training", // "training", "examination", "leisure"
    flight_charges: [], // {"charge_name", "value"}
    payment_id: "",
    manifest: [], // {"name", "email", "passport_details"
    remarks: "",
  })

  const [aerialResourceData, setAerialResourceData] = useState([])
  const [instructorData, setInstructorData] = useState([])
  const [examinerData, setExaminerData] = useState([])

  const fetchClubResource = async () => {
    let endpoint = config.endpoint + "/resources/club/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      // setAerialResourceData([...data.data,...data.data,...data.data,...data.data,...data.data,...data.data,...data.data,...data.data,...data.data,...data.data,...data.data,...data.data,])
      setAerialResourceData(data.data)
    }
  }

  const fetchClubInstructors = async () => {
    let endpoint = config.endpoint + "/users/instructors/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setInstructorData(data.data)
    }
  }

  const fetchClubExaminer = async () => {
    let endpoint = config.endpoint + "/users/examiners/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setExaminerData(data.data)
    }
  }



  const createBooking = async () => {
    let endpoint = config.endpoint + "/bookings/club/"
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bookingDetails)
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate(-1)
      notify({ message: "Booking created successfully!", type: "success" })
    } else {
      notify({ message: `${data.message}`, type: "error" })
    }
  }

  const updateBooking = async () => {
    let endpoint = config.endpoint + "/bookings/club/" + booking_id
    let response = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bookingDetails)
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate(-1)
      notify({ message: "Booking updated successfully!", type: "success" })
    } else {
      notify({ message: `${data.message}`, type: "error" })
    }
  }

  const fetchBookingById = async () => {
    if (!booking_id || booking_id === "new") return
    let endpoint = config.endpoint + "/bookings/id/" + booking_id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setBookingDetails(data.data)
    }
  }

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)

  }, [header])

  useEffect(() => {
    fetchClubInstructors()
    fetchClubResource()
    fetchBookingById()
    fetchClubExaminer()
  }, [])

  const acceptFlight = async () => {
    let endpoint = config.endpoint + "/bookings/club/" + booking_id + "/accept"
    let response = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate(-1)
      notify({ message: "Booking accepted successfully!", type: "success" })
    } else {
      notify({ message: `${data.message}`, type: "error" })
    }
  }


  const rejectFlight = async () => {
    let endpoint = config.endpoint + "/bookings/club/" + booking_id + "/reject"
    let response = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate(-1)
      notify({ message: "Booking rejected successfully!", type: "success" })
    } else {
      notify({ message: `${data.message}`, type: "error" })
    }
  }

  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"My Flights"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header">
              <h1>{booking_id === "new" ? "New Booking" : "Booking #" + booking_id}</h1>
            </div>
          </div>
          <div className="container">
            <div className="inner-container">
              {booking_id != "new" && <div className="card mt-4 rounded-md shadow-md p-4">
                <p className="card-title">Pilot Detail</p>
                <div className="row gap-4 items-center">
                  <div className="input-container">
                    <label className="input-label">Name</label>
                    <label className="text-input">{bookingDetails?.user_data?.first_name} {bookingDetails?.user_data?.last_name}</label>
                  </div>
                  <div className="input-container">
                    <label className="input-label">Email</label>
                    <label className="text-input">{bookingDetails?.user_data?.email}</label>
                  </div>
                  <div className="input-container">
                    <label className="input-label">Contact Number</label>
                    <label className="text-input">{bookingDetails?.user_data?.contact_number}</label>
                  </div>
                </div>
                <div className="row gap-4 items-center">
                  <div className="input-container">
                    <label className="input-label">Title</label>
                    <label className="text-input">{convertPilotLicenseType(bookingDetails?.user_data?.license_type)}</label>
                  </div>
                  <div className="input-container">
                    <label className="input-label">Flight Cycle</label>
                    <label className="text-input">{bookingDetails?.user_data?.flying_cycles} cycles</label>
                  </div>
                  <div className="input-container">
                    <label className="input-label">Total Flight Hours</label>
                    <label className="text-input">{bookingDetails?.user_data?.flying_hours} hours</label>
                  </div>
                </div>
              </div>}
              <div className="card mt-4 rounded-md shadow-md p-4">
                <p className="card-title">Booking Details</p>
                <div className="row gap-4 alignTop">
                  <div className="input-container">
                    <label className="input-label">Departing Aerodrome (ICAO)</label>
                    <input className="text-input" disabled={booking_id !== "new"} type="text" value={bookingDetails?.departing_aerodrome_id?.toUpperCase()} onChange={(e) => setBookingDetails({ ...bookingDetails, departing_aerodrome_id: e.target.value })} placeholder='e.g. WSSL' />
                    <p style={{ fontStyle: "italic", fontSize: 10, marginTop: 2 }}>{airport_database?.find((airport) => airport.icao?.toLowerCase() === bookingDetails.departing_aerodrome_id?.toLowerCase())?.airportName}</p>
                  </div>
                  <div className="input-container">
                    <label className="input-label">Arriving Aerodrome (ICAO)</label>
                    <input className="text-input" disabled={booking_id !== "new"} type="text" value={bookingDetails?.arriving_aerodrome_id?.toUpperCase()} onChange={(e) => setBookingDetails({ ...bookingDetails, arriving_aerodrome_id: e.target.value })} placeholder='e.g. WSSS' />
                    <p style={{ fontStyle: "italic", fontSize: 10, marginTop: 2 }}>{airport_database?.find((airport) => airport.icao?.toLowerCase() === bookingDetails.arriving_aerodrome_id?.toLowerCase())?.airportName}</p>
                  </div>
                </div>
                <div className="row gap-4 items-center">
                  <div className="input-container">
                    <label className="input-label">Departure Date & Time</label>
                    <input className="text-input" type="datetime-local" value={bookingDetails.flight_departure_datetime} disabled={booking_id !== "new"} onChange={(e) => setBookingDetails({ ...bookingDetails, flight_departure_datetime: e.target.value })} />
                  </div>
                  <div className="input-container">
                    <label className="input-label">Arrival Date & Time</label>
                    <input className="text-input" type="datetime-local" value={bookingDetails.flight_arrival_datetime} disabled={booking_id !== "new"} onChange={(e) => setBookingDetails({ ...bookingDetails, flight_arrival_datetime: e.target.value })} />
                  </div>
                </div>
                <div className="row gap-4 items-center">
                  <div className="input-container">
                    <label className="input-label">Flight Type</label>
                    <select className="select-input h-11 px-2" value={bookingDetails.flight_type} disabled={booking_id !== "new"} onChange={(e) => setBookingDetails({ ...bookingDetails, flight_type: e.target.value })}>
                      <option value="training">Training</option>
                      <option value="examination">Examination</option>
                      <option value="leisure">Leisure</option>
                    </select>
                  </div>
                  {/* <div className="input-container">
                    <label className="input-label">Select Aerial Resource</label>
                    <select className="select-input h-11 px-2"
                      value={bookingDetails.aerial_resource_id}
                      disabled={booking_id !== "new"}
                      onChange={(e) => setBookingDetails({ ...bookingDetails, aerial_resource_id: e.target.value })}
                    >
                      <option value="">Select Aerial Resource</option>
                      {aerialResourceData.map((resource, index) => {
                        return (
                          <option key={index} value={resource.id}>{resource.nickname} ({resource?.registration})</option>
                        )
                      })}
                    </select>
                  </div> */}

                </div>
                <p className="card-title">Aircraft</p>
                {booking_id === "new" ? <div className="row alignStart mb-30 hide-scrollbars" style={{ overflowY: "scroll", }}>
                  {aerialResourceData?.length > 0 ? aerialResourceData?.map((resource, index) => {
                    if (resource?.aerial_status !== "active") return null
                    return (
                      <div key={index} className="card mt-4 rounded-md shadow-md mr-10" style={{ minWidth: 250, maxWidth: 250, padding: 0, border: bookingDetails?.aerial_resource_id === resource.id ? "1px solid #000" : "1px solid transparent" }} onClick={() => {
                        if (booking_id !== "new") return
                        if (bookingDetails?.aerial_resource_id === resource.id) {
                          setBookingDetails({ ...bookingDetails, aerial_resource_id: "" })
                        } else {
                          setBookingDetails({ ...bookingDetails, aerial_resource_id: resource.id })
                        }
                      }}>
                        <img src={resource.file_data?.ext_file_path ? `${config.endpoint}${resource.file_data?.ext_file_path}` : "placeholder_aircraft.jpg"} alt={`${resource?.registration}`} style={{ width: "100%", height: 100, objectFit: "cover", borderRadius: 0 }} />
                        <div className="column left pl-10 pb-20">
                          <label className="" style={{ fontWeight: "900" }}>{resource.registration}</label>
                          <label className="" style={{ marginTop: 0, fontSize: 12, color: "gray" }}>{resource.nickname}</label>
                        </div>
                      </div>
                    )
                  })
                    :
                    <div className="">
                      <p style={{ color: "gray" }}>No Aircraft available, please contact your club adminsitrator</p>
                    </div>
                  }
                </div>
                  :
                  <div className="card mt-4 rounded-md shadow-md mr-10" style={{ minWidth: 250, maxWidth: 250, padding: 0, border: "1px solid #000" }} >
                    <img src={bookingDetails?.aerial_resource_data?.file_data?.ext_file_path ? `${config.endpoint}${bookingDetails?.aerial_resource_data?.file_data?.ext_file_path}` : "placeholder_aircraft.jpg"} alt={`${bookingDetails?.aerial_resource_data?.registration}`} style={{ width: "100%", height: 100, objectFit: "cover", borderRadius: 0 }} />
                    <div className="column left pl-10 pb-20">
                      <label className="" style={{ fontWeight: "900" }}>{bookingDetails?.aerial_resource_data?.registration}</label>
                      <label className="" style={{ marginTop: 0, fontSize: 12, color: "gray" }}>{bookingDetails?.aerial_resource_data?.nickname}</label>
                    </div>
                  </div>
                }


                <div className="row gap-4 items-center">
                  {bookingDetails?.flight_type === "training" && <div className="input-container">
                    <label className="input-label">Instructor</label>
                    <select className="select-input h-11 px-2"
                      value={bookingDetails.instructor_id}
                      disabled={booking_id !== "new"}
                      onChange={(e) => setBookingDetails({ ...bookingDetails, instructor_id: e.target.value })}
                    >
                      <option value="">Select Instructor</option>
                      {instructorData.map((instructor, index) => {
                        return (
                          <option key={index} value={instructor.id}>{instructor.first_name} {instructor.last_name}</option>
                        )
                      })}
                    </select>
                  </div>}
                  {bookingDetails?.flight_type === "examination" && <div className="input-container">
                    <label className="input-label">Examiner</label>
                    <select className="select-input h-11 px-2"
                      value={bookingDetails.examiner_id}
                      disabled={booking_id !== "new"}
                      onChange={(e) => setBookingDetails({ ...bookingDetails, examiner_id: e.target.value })}
                    >
                      <option value="">Select Examiner</option>
                      {instructorData.map((examiner, index) => {
                        return (
                          <option key={index} value={examiner.id}>{examiner.first_name} {examiner.last_name}</option>
                        )
                      })}
                    </select>
                  </div>}
                </div>
                <div className="input-container mt-4">
                  <label htmlFor="" className="input-label">Remarks</label>
                  <textarea className="textarea-input" value={bookingDetails.remarks} onChange={(e) => setBookingDetails({ ...bookingDetails, remarks: e.target.value })} />
                </div>
                <div className="button-container justifyRight">
                  <button className="primary button" onClick={() => {
                    if (booking_id === "new") {
                      createBooking()
                    } else {
                      updateBooking()
                    }
                  }}>{booking_id === "new" ? "Create" : "Update"}</button>

                  {bookingDetails?.booking_status === "confirmed" && user_data?.id == bookingDetails?.user_data?.id && <button className="primary button" onClick={() => {
                    navigate("/flights/bookings/" + booking_id + "/complete")
                  }}>Complete Flight</button>}
                </div>
              </div>

              {
                (user_data?.id == bookingDetails?.instructor_id || user_data?.id == bookingDetails?.examiner_id) && ["pending", "confirmed"].includes(bookingDetails?.booking_status) && booking_id !== "new" && <div className="row mt-4 items-center gap-4" style={{ width: "100%" }}>
                  <button className="button"
                    onClick={() => {
                      acceptFlight()
                    }}
                  >Accept</button>
                  <button className="button"
                    onClick={() => {
                      rejectFlight()
                    }}
                  >Reject</button>
                </div>
              }


              {/* <pre>{JSON.stringify(examinerData,null,2)}</pre>
              <pre>{JSON.stringify(instructorData,null,2)}</pre>
              <pre>{JSON.stringify(aerialResourceData,null,2)}</pre> */}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
