import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'
import { MdEditDocument } from 'react-icons/md'
import MainLayout from '../../layouts/MainLayout'

export default function DocumentPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])


  const [documentsData, setDocumentsData] = useState([])

  useEffect(() => {
    fetchClubDocuments()
  }, [])

  const fetchClubDocuments = async () => {
    let endpoint = config.endpoint + "/documents/user"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let result = await fetchResponse.json()
    if (result.status == 200) {
      setDocumentsData(result.data.reverse())
    } else {
      notify({ message: result.message, type: "error" })
    }
  }

  return (
    <MainLayout
      navSelected={"Approval"}
      componentName={"My Documents"}
    >
      {documentsData?.length > 0 ? documentsData.map((item, index) => {
        let color = ""
        if (item.status == "pending") {
          color = "#FFB800"
        } else if (item.status == "approved") {
          color = "#00B800"
        } else if (item.status == "rejected") {
          color = "#B80000"
        }
        return (
          <div className="card row justify-between items-center mb-4 p-4" key={index}>
            <div className="">
              {/* <p className='card-title'>{item.user_data?.first_name} {item.user_data?.last_name}</p> */}
              <p className='card-subtitle' style={{ fontSize: 12, marginBottom: 0 }}>{item.description || "No Description"}</p>
            </div>
            <p style={{ fontSize: 12, color: "white", fontWeight: "bold", borderRadius: 25, padding: "5px 15px", backgroundColor: color }}>{item.status}</p>
          </div>
        )
      })
        :
        <div className="card rounded-md shadow-md p-4">
          <MdEditDocument size={50} color={"gray"} />
          <p className="card-title mt-4" style={{ color: "gray" }}>No documents found. To do so, you may upload a document at the "Approval" section</p>
        </div>
      }

    </MainLayout>
  )
}
