import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export default function MainLayout({ children, componentName, navSelected, componentButtons, centeredContents, componentChildren }) {
    const navigate = useNavigate()
    const { isAuthorised, userRole } = useFetchAuthorisation("user")
    let user_data = useFetchUserData()
    const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
    const [componentHeader, setComponentHeader] = useState(document.getElementById("component p-4")?.clientHeight)

    useLayoutEffect(() => {
        setHeader(document.getElementById("user-header")?.clientHeight)
        setComponentHeader(document.getElementById("component p-4")?.clientHeight)
    }, [header])


    return (
        <div style={{ width: "100vw", }}>
            <ToastContainer />
            <div className='row' style={{ width: "100%" }}>
                <Navbar selected={navSelected || "Dashboard"} />
                <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
                    <UserHeader />
                    <div className="component row items-center justify-between gap-4 p-4" style={{ top: header }}
                        id="component p-4"
                    >
                        <div className="component-header" id='component-header'>
                            {!componentChildren ? <h1 style={{whiteSpace:"nowrap"}}>{componentName}</h1>
                                :
                                componentChildren
                            }
                        </div>
                        {componentButtons}
                    </div>
                    {centeredContents ? <div className="container"
                        style={{ maxHeight: `calc(100dvh - ${header}px - ${componentHeader}px)`, overflowY: "scroll", }}
                    >
                        <div className="inner-container p-4">
                            {children}
                        </div>
                    </div>
                        :
                        <div className="reg-container p-4"
                            style={{ maxHeight: `calc(100dvh - ${header}px - ${componentHeader}px)`, overflowY: "scroll", }}
                        >
                            {children}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
