import React, { useState, useEffect, useLayoutEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import notify from '../../utils/IANotification'
import 'react-toastify/dist/ReactToastify.css';
import MainLayout from '../../layouts/MainLayout'

export default function ClubResourceSharingForm() {
  const navigate = useNavigate()
  const resource_id = useParams().resource_id
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [resourceData, setResourceData] = useState({
    registration: "",
    remarks: "",
  })

  const submitRequest = async () => {
    let endpoint = config.endpoint + "/resources/request/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...resourceData,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate("/club/resources")
      notify({ type: "success", message: data.message })
    } else {
      notify({ type: "error", message: data.message })
    }
  }



  return (
    <MainLayout
      navSelected={"Club"}
      componentName={"Resource Sharing Request"}
    >
      <div className="card mb-4 p-4">
        <p className="card-title mb-4">Request Form</p>
        <div className="column gap-4">
          <div className="input-container">
            <label className='input-label'>Registration</label>
            <input type="text" className='text-input' placeholder='e.g. 9V-AAA' value={resourceData.registration?.toUpperCase()} onChange={(e) => setResourceData({ ...resourceData, registration: e.target.value?.toUpperCase() })} />
          </div>
          <div className="input-container">
            <label className='input-label'>Remarks</label>
            <textarea className='text-input' value={resourceData.remarks} onChange={(e) => setResourceData({ ...resourceData, remarks: e.target.value })} />
          </div>
        </div>
      </div>
      <div className="mt-4 button-container">
        <button className="button" onClick={() => {
          submitRequest()
        }}>Submit Request</button>
      </div>
    </MainLayout>
  )
}
