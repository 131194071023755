import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';
import { tidyDate, currency } from '../../utils/helper'
import { DateRangePicker } from 'react-date-range';
import { MdAccountBalance, MdCalendarMonth, MdClose, MdDownload, MdFilter, MdFilterListOff, MdPercent, MdQueryStats } from 'react-icons/md'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default function ClubAccountingPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [accountData, setAccountData] = useState([])
  const [search, setSearch] = useState("")
  const [showDateRange, setShowDateRange] = useState(false)
  const [dateRange, setDateRange] = useState()
  const [startDateRange, setStartDateRange] = useState(null)
  const [endDateRange, setEndDateRange] = useState(null)

  const [isOpen, setIsOpen] = useState(false)

  const [filterByIncome, setFilterByIncome] = useState(true)
  const [filterByExpense, setFilterByExpense] = useState(true)
  const [filterByPayables, setFilterByPayables] = useState(true)
  const [filterByReceivables, setFilterByReceivables] = useState(true)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])


  const COL = [
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      editable: false,
      sortable: false,
      flex: 1
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "income",
      headerName: "Income",
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      field: "expense",
      headerName: "Expense",
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      field: "payables",
      headerName: "Payables",
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      field: "receivables",
      headerName: "Receivables",
      width: 100,
      editable: false,
      sortable: false,
    },
  ]

  const fetchAccountData = async () => {
    let endpoint = config.endpoint + "/accounting?" + (startDateRange ? `start_date=${startDateRange}&` : "") + (endDateRange ? `end_date=${endDateRange}` : "")
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })

    let result = await fetchResponse.json()
    if (result.status === 200) {
      let data = result.data.total_accounts
      setAccountData(data)
    }
  }

  const downloadAccountData = async () => {
    let endpoint = config.endpoint + "/accounting?" + (startDateRange ? `start_date=${startDateRange}&` : "") + (endDateRange ? `end_date=${endDateRange}` : "")
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })

    let result = await fetchResponse.json()
    if (result.status === 200) {

    }
  }

  useEffect(() => {
    fetchAccountData()
  }, [startDateRange, endDateRange])

  const processData = () => {

    let data = accountData

    // filter data based on search input
    if (search) {
      data = data.filter((item) => {
        return JSON.stringify(item).toLowerCase().includes(search.toLowerCase())
      })
    }

    if (!filterByIncome) {
      data = data.filter((item) => {
        return item.type !== "income"
      })
    }

    if (!filterByExpense) {
      data = data.filter((item) => {
        return item.type !== "expense"
      })
    }

    if (!filterByPayables) {
      data = data.filter((item) => {
        return item.type !== "payable"
      })
    }

    if (!filterByReceivables) {
      data = data.filter((item) => {
        return item.type !== "receivable"
      })
    }

    // process data to fit into the table
    let temp = []
    for (let i = 0; i < data.length; i++) {
      let item = data[i]
      let date = item.date ? tidyDate(item.date) : "-"
      temp.push({
        id: i,
        name: item.name,
        date: date,
        income: item.type === "income" ? `$${currency(item.amount)}` : undefined,
        expense: item.type === "expense" ? `$(${currency(item.amount)})` : undefined,
        payables: item.type === "payable" ? `$${currency(item.amount)}` : undefined,
        receivables: item.type === "receivable" ? `$${currency(item.amount)}` : undefined,
        transaction_id: item.transaction_id,
        payment_id: item.payment_id,
        reimbursement_id: item.reimbursement_id,
      })
    }
    temp.push(calculateTotalRow(data))
    return temp
  }

  const calculateTotalRow = (data) => {
    // filter data based on search input
    if (search) {
      data = data.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase())
      })
    }

    // calculate total_income, total_expense, total_payable, total_receivable
    const total_income = data.reduce((acc, item) => {
      if (item.type === "income") {
        return acc + item.amount
      }
      return acc
    }, 0)

    const total_expense = data.reduce((acc, item) => {
      if (item.type === "expense") {
        return acc + item.amount
      }
      return acc
    }, 0)

    const total_payable = data.reduce((acc, item) => {
      if (item.type === "payable") {
        return acc + item.amount
      }
      return acc
    }, 0)

    const total_receivable = data.reduce((acc, item) => {
      if (item.type === "receivable") {
        return acc + item.amount
      }
      return acc
    }, 0)

    return {
      id: data.length,
      name: "Total",
      date: "",
      income: `$${currency(total_income)}`,
      expense: `$(${currency(total_expense)})`,
      payables: `$${currency(total_payable)}`,
      receivables: `$${currency(total_receivable)}`,
    }
  }


  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Accounting"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header row justify-between items-center">
              <h1>Club Accounts</h1>
              <div className="row gap-4 items-center">
                <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2"
                  onClick={() => {
                    navigate("/club/accounting/transaction/new")
                  }}
                >Add Transaction</button>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="w-full">
              <input
                type="text"
                className="text-input w-full"
                placeholder="Search accounts by name..."
                style={{ marginBottom: "20px" }}
                onChange={(e) => setSearch(e.target.value)}
              />

              <div>
                <div className="row gap-4 items-center">
                  <div className="row gap-4 items-center text-input button-clubaccounts" style={{ backgroundColor: "white", fontSize: 13, fontWeight: "normal", width: "auto" }} id="date-range">
                    <input type="date" name="" value={startDateRange} onChange={(e) => setStartDateRange(e.target.value)} />
                    <MdCalendarMonth style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => setShowDateRange(!showDateRange)} color='black' size={20} />
                  </div>
                  <div className="row gap-4 items-center text-input button-clubaccounts" style={{ backgroundColor: "white", fontSize: 13, fontWeight: "normal", width: "auto" }}>
                    <input type="date" name="" value={endDateRange} onChange={(e) => setEndDateRange(e.target.value)} />
                    <MdCalendarMonth style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => setShowDateRange(!showDateRange)} color='black' size={20} />
                  </div>


                  {/* <div className="row gap-4 items-center text-input button-clubaccounts" style={{ backgroundColor: "white", fontSize: 13, fontWeight: "normal", width: "auto" }} id="date-range">
                    <p style={{ margin: 0, padding: 0, whiteSpace: "nowrap" }}>{startDateRange ? tidyDate(startDateRange) : "Start Date"}</p>
                    <MdCalendarMonth style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => setShowDateRange(!showDateRange)} color='black' size={20} />
                  </div>
                  <div className="row gap-4 items-center text-input button-clubaccounts" style={{ backgroundColor: "white", fontSize: 13, fontWeight: "normal", width: "auto" }}>
                    <p style={{ margin: 0, padding: 0, whiteSpace: "nowrap" }}>{endDateRange ? tidyDate(endDateRange) : "End Date"}</p>
                    <MdCalendarMonth style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => setShowDateRange(!showDateRange)} color='black' size={20} />
                  </div> */}

                  <CSVDownloader data={processData(accountData)} filename="club_accounts.csv" className="text-input cursor-pointer button-clubaccounts row" style={{ fontSize: 13, fontWeight: "normal", width: "auto" }} />

                  {showDateRange &&
                    <div
                      style={{ position: "absolute", zIndex: 1000, top: dateRange?.top - 150, left: dateRange?.left, }}
                    >
                      <DateRangePicker
                        ranges={[
                          {
                            startDate: new Date(startDateRange || Date.now()),
                            endDate: new Date(endDateRange || Date.now()),
                            key: 'selection',
                          }
                        ]}

                        showPreview={false}
                        showDateDisplay={false}
                        direction='horizontal'
                        maxDate={new Date(Date.now())}
                        months={2}
                        locale={{
                          code: 'en',
                          localize: {
                            month: n => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][n],
                            day: n => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][n]

                          },

                          formatLong: {}

                        }}

                        onChange={(item) => {

                          setStartDateRange(new Date(item.selection.startDate).toISOString())
                          setEndDateRange(new Date(item.selection.endDate).toISOString())
                          if (item.selection.startDate !== item.selection.endDate) {
                            setShowDateRange(false)
                            return
                          }
                        }}

                      />
                    </div>
                  }
                </div>
                <div className="wrap-row w-full">
                  {/* <button class="button-cta button-clubaccounts" onClick={toggleDropdown}>Filter by...</button>
                {isOpen && (
                  <div className="dropdown">
                    <ul className="dropdown-content">
                      <li onClick={() => setFilterByIncome(val => !val)} className="dropdown-selection">
                        <input type="checkbox" checked={filterByIncome} readOnly />
                      <span className="dropdown-label">Income</span>
                      </li>
                      <li onClick={() => setFilterByExpense(val => !val)} className="dropdown-selection">
                        <input type="checkbox" checked={filterByExpense} readOnly />
                        <span className="dropdown-label">Expense</span>
                      </li>
                      <li onClick={() => setFilterByPayables(val => !val)} className="dropdown-selection">
                        <input type="checkbox" checked={filterByPayables} readOnly />
                        <span className="dropdown-label">Payables</span>
                      </li>
                      <li onClick={() => setFilterByReceivables(val => !val)} className="dropdown-selection">
                        <input type="checkbox" checked={filterByReceivables} readOnly />
                        <span className="dropdown-label">Receivables</span>
                      </li>                 
                    </ul>
                  </div>
                )} */}

                </div>


                {/* Quick Stats */}
                <div className="row gap-4 alignStart mb-4">
                  <div className="card row gap-4 items-center p-2" style={{ borderRadius: 10 }}>
                    <MdQueryStats color='green' size={30} />
                    <div>
                      <h3 className="card-title">Profit/Loss</h3>
                      <p className="card-value">$({currency(accountData.reduce((acc, item) => {
                        if (item.type === "income") {
                          return acc + item.amount
                        }
                        if (item.type === "expense") {
                          return acc - item.amount
                        }
                        return acc
                      }, 0))})</p>
                    </div>
                  </div>
                  <div className="card row gap-4 items-center p-2" style={{ borderRadius: 10 }}>
                    <MdPercent color='green' size={30} />
                    <div>
                      <h3 className="card-title">Profit Margin</h3>
                      <p className="card-value">{currency(accountData.reduce((acc, item) => {
                        if (item.type === "income") {
                          return acc + item.amount
                        }
                        if (item.type === "expense") {
                          return acc - item.amount
                        }
                        return acc
                      }, 0) / accountData.reduce((acc, item) => {
                        if (item.type === "income") {
                          return acc + item.amount
                        }
                        return acc
                      }, 0))}%</p>
                    </div>
                  </div>
                  <div className="card row gap-4 items-center p-2" style={{ borderRadius: 10 }}>
                    <MdAccountBalance color='green' size={30} />
                    <div>
                      <h3 className="card-title">Total Transactions</h3>
                      <p className="card-value">{accountData.length}</p>
                    </div>
                  </div>
                </div>
                <DataGrid
                  rows={processData(accountData)}
                  columns={COL}
                  // pageSizeOptions={[5]}
                  // checkboxSelection
                  // disableRowSelectionOnClick
                  hideFooter
                  disableColumnMenu
                  sx={{
                    fontFamily: "Poppins",
                    //   width: "100%",
                    //   border: '1px solid #333333',
                    //   borderRadius: "10px",
                    //   backgroundColor: '#DDDDDD',
                    '& .MuiDataGrid-row:nth-of-type(odd)': {
                      backgroundColor: 'white',
                      cursor: 'pointer',
                    },
                    '& .MuiDataGrid-row:nth-of-type(even)': {
                      backgroundColor: 'rgba(255,255,255,0.4)',
                      cursor: 'pointer',
                    },
                    '& .MuiDataGrid-row:hover': {
                      backgroundColor: 'rgba(255,255,255,0.6)',
                    },
                  }}

                  onCellClick={(params, event) => {
                    if (params.row?.transaction_id) {
                      navigate(`/club/accounting/transaction/${params.row.transaction_id}`)
                    }
                    if (params.row?.payment_id) {
                      navigate(`/club/accounting/payment`)
                    }
                    if (params.row?.reimbursement_id) {
                      navigate(`/club/accounting/reimbursement/${params.row.reimbursement_id}`)
                    }
                  }}
                />
                {/* <pre>{JSON.stringify(accountData,null,2)}</pre> */}
                {/* <pre>{JSON.stringify(accountData, null, 2)}</pre> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function CSVDownloader({ data, filename, className, style }) {

  const convertArrayToCSV = () => {
    console.log(data)
    const header = Object.keys(data[0]).join(',');
    const rows = Object.keys(data).map(key => {
      return Object.values(data[key])
        .map(val => !val ? "" : JSON.stringify(val).replace(/,/g, ''))
        .join(',')
    });
    return `${header}\n${rows.join('\n')}`;
  };

  const removeUnrequiredColumns = () => {
    data.map((item) => {
      delete item.transaction_id
      delete item.payment_id
      delete item.reimbursement_id
    })
  }

  const downloadCSV = () => {
    removeUnrequiredColumns()
    const csv = convertArrayToCSV();
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename || 'data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <button className={className} style={style} onClick={downloadCSV}>
      <p style={{ marginRight: "5px" }}>Download CSV</p>
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path d="M5 20h14v-2H5m14-9h-4V3H9v6H5l7 7 7-7z" /></svg> */}
      <MdDownload color='black' size={20} />
    </button>
  );
}
