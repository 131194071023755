import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { getAgeByDate, tidyDate } from '../../utils/helper'
import { MdCalendarToday, MdGpsFixed, MdLocationCity, MdOutlineEvent } from 'react-icons/md'

export default function EventsPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [eventsData, setEventsData] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [midHeader, setMidHeader] = useState(document.getElementById("mid-header")?.clientHeight)



  const fetchEvents = async () => {
    let endpoint = config.endpoint + "/events/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await response.json()
    if (data.status === 200) {
      setEventsData(data.data?.reverse())
    }
  }

  useEffect(() => {
    fetchEvents()
  }, [])



  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Events"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4 row justify-between items-center" style={{ top: header }}>
            <h1>Club Events</h1>
            {authorisationConverter({ requirement: "staff", current_role: userRole }) && <button className="button-cta"
              onClick={() => {
                navigate("/events/details/create")
              }}
            >
              Create Event
            </button>}
          </div>
          <div className="container">
            <div className="p-all" style={{ width: "100%", paddingBottom: 20, position: "sticky", top: midHeader, }}>
              <input type="text" className="text-input" style={{ width: "100%" }} placeholder="Search" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value) }} />
            </div>
            {eventsData.length > 0 ? eventsData.map((event, index) => {
              if (searchQuery && !JSON.stringify(event).toLowerCase().includes(searchQuery.toLowerCase())) {
                return null
              }
              return (
                <div className="card cursor-pointer mb-4 rounded-md shadow-md"
                  onClick={() => {
                    if (authorisationConverter({ requirement: "staff", current_role: userRole })) {
                      navigate("/events/details/" + event?.id)
                    }
                  }}
                >
                  <div className="row items-center gap-4">
                    <img src={event?.file_data?.ext_file_path ? `${config.endpoint}${event?.file_data?.ext_file_path}` : "/placeholder_aircraft.jpg"} alt="event" style={{ objectFit: "cover", width: 150, height: 150, borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }} />
                    <div className='p-4 '>
                      <p className="card-title">{event?.name}</p>
                      <div className="row gap-4 items-center">
                        <div className="row items-center gap-2">
                          <MdCalendarToday size={12} color={"gray"} />
                          <p style={{ fontSize: 12, color: "gray" }}>{tidyDate(event?.start_datetime)}</p>
                        </div>
                        <div className="row items-center gap-2">
                          <MdCalendarToday size={12} color={"gray"} />
                          <p style={{ fontSize: 12, color: "gray" }}>{tidyDate(event?.end_datetime)}</p>
                        </div>
                        <div className="row items-center gap-2">
                          <MdGpsFixed size={12} color={"gray"} />
                          <p style={{ fontSize: 12, color: "gray" }}>{event?.location}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <pre>{JSON.stringify(eventsData,null,2)}</pre> */}
                </div>
              )
            })
              :
              <div className="card rounded-md shadow-md p-4">
                <MdOutlineEvent size={50} color={"gray"} />
                <p className="card-title mt-4" style={{ color: "gray" }}>No events found</p>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
