import React, { useEffect, useLayoutEffect, useState } from 'react'
import { MdExitToApp, MdPerson, MdSettings } from 'react-icons/md'
import { authorisationConverter, logout, useFetchUserData } from '../utils/AppFunctions'
import { useNavigate } from 'react-router'

export default function ProfileMenu({ color }) {
    const navigate = useNavigate()

    const [showProfileModal, setShowProfileModal] = useState(false)
    const [buttonPos, setButtonPos] = useState(document?.getElementById("home-button"))
    let userData = useFetchUserData()

    useLayoutEffect(() => {
        setButtonPos(document?.getElementById("home-button"))
    }, [document?.getElementById("home-button")])

    window.addEventListener("scroll", () => {
        setShowProfileModal(false)
    })


    // useEffect(() => {
    //   if (showProfileModal) {
    //     document.getElementById("home-button").style.display = "normal"
    //   } else {
    //     document.getElementById("home-button").style.display = "none"
    //   }
    // }, [showProfileModal])


    const items = [
        {
            name: "Profile",
            icon: <MdPerson size={20} color={"gray"} />,
            onClick: () => {
                setShowProfileModal(false)
                navigate("/profile")
            },
            authorisation: "student",
        },
        // {
        //     name: "Settings",
        //     icon: <MdSettings size={25} color={"gray"} />,
        //     onClick: () => {
        //         setShowProfileModal(false)
        //         navigate("/settings")
        //     },
        //     authorisation: "student",
        // },
        {
            name: "Logout",
            icon: <MdExitToApp size={20} color={"gray"} />,
            onClick: () => {
                setShowProfileModal(false)
                logout()
            },
        }
    ]

    const ProfileModal = () => {

        return (
            <div className="rounded-md shadow-md" style={{
                position: "absolute",
                top: buttonPos?.getClientRects()[0]?.bottom + 20,
                right: 10,
                zIndex: 100,
                width: 200,
                backgroundColor: "white",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                
            }}>
                {items.map((item, index) => {
                    if (authorisationConverter({ requirement: item.authorisation, current_role: userData?.account_type })) {
                        return (
                            <div className="profile-hover" style={{zIndex:1000, position:"sticky"}}>
                                <div className="row items-center gap-4 p-2" style={{ cursor: "pointer" }} onClick={item.onClick}>
                                    {item.icon}
                                    <p className="text-sm text-slate-500">{item.name}</p>
                                </div>
                            </div>
                        )
                    }
                })}

            </div>
        )
    }



    return (
        <>
            {showProfileModal && <ProfileModal />}
            <div className="row gap-2 items-center cursor-pointer" id='home-button' style={{ zIndex: 1004 }}
                onClick={() => {
                    setShowProfileModal(!showProfileModal)
                }}
            >
                <div style={{ backgroundColor: "#DDD", borderRadius: "50%" }} className="p-2">
                    <MdPerson size={16} color='gray' />
                </div>
                <p style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", color: color }} className="text-sm">{`${userData?.first_name} ${userData?.last_name}` === " " ? "User" : `${userData?.first_name} ${userData?.last_name}`}</p>
            </div>
        </>
    )
}