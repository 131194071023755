import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import MainLayout from '../../layouts/MainLayout'
import { isMobile } from '../../utils/helper'

export default function ClubDocumentsFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [midHeader, setMidHeader] = useState(document.getElementById("mid-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
    setMidHeader(document.getElementById("mid-header")?.clientHeight + document.getElementById("user-header")?.clientHeight)
  }, [document.getElementById("user-header")?.clientHeight, document.getElementById("mid-header")?.clientHeight])
  const { document_id } = useParams()
  const [documentsData, setDocumentsData] = useState()
  const [selectedDocument, setSelectedDocument] = useState(0)
  const [memberData, setMemberData] = useState({})

  useEffect(() => {
    fetchClubDocumentsById()
  }, [])

  const fetchClubDocumentsById = async () => {
    if (!document_id) {
      return
    }
    let endpoint = config.endpoint + "/documents/club/" + document_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let result = await fetchResponse.json()
    if (result.status == 200) {
      setDocumentsData(result.data)
      setMemberData(result.data?.user_data)
    } else {
      notify({ message: result.message, type: "error" })
    }
  }

  const updateClubDocuments = async () => {
    if (!document_id) {
      return
    }
    let endpoint = config.endpoint + "/documents/club/" + document_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(documentsData)
    })
    let result = await fetchResponse.json()
    if (result.status == 200) {
      notify({ message: result.message, type: "success" })
      navigate("/club/documents")
    } else {
      notify({ message: result.message, type: "error" })
    }
  }

  const updateMember = async () => {
    if (!memberData?.id) {
      return
    }
    let endpoint = config.endpoint + "/users/id/" + memberData?.id
    let response = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(memberData)
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate(-1)
      notify({ type: "success", message: "User updated successfully!" })
    } else {
      notify({ type: "error", message: `${data.message}` })
    }
  }

  const fetchUserById = async () => {
    if (!memberData?.id) {
      return
    }
    let endpoint = config.endpoint + "/users/id/" + memberData?.id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setMemberData(data.data)
    }
  }

  useEffect(() => {
    fetchUserById()
  }, [memberData?.id])

  return (
    <MainLayout
      navSelected={"Club"}
      componentName={"Submitted Documents"}
    >
      <div className={`${isMobile(window.innerWidth) ? "column" : "row"} alignStart gap-4`}>
        <div className={`row gap-4 items-center`} style={{ position: !isMobile(window.innerWidth) && "sticky", top: midHeader + 20, zIndex: 0, background: "white", padding: "10px 0", justifyContent: "center" }}>
          <MdChevronLeft className='cursor-pointer' size={30} color={selectedDocument > 0 ? "black" : "gray"}
            onClick={() => {
              if (selectedDocument > 0) {
                setSelectedDocument(selectedDocument - 1)
              }
            }} />
          <div>
            {documentsData?.file_data?.[selectedDocument]?.mime_type?.includes("image") &&
              <img src={`${config.endpoint}${documentsData?.file_data?.[selectedDocument]?.ext_file_path}`} style={{ maxWidth: !isMobile(window.innerWidth) && 500, maxHeight: !isMobile(window.innerWidth) && 500, objectFit: "contain" }} />
            }
            {documentsData?.file_data?.[selectedDocument]?.mime_type?.includes("video") &&
              <video src={`${config.endpoint}${documentsData?.file_data?.[selectedDocument]?.ext_file_path}`} style={{ maxWidth: !isMobile(window.innerWidth) && 500, maxHeight: !isMobile(window.innerWidth) && 500 }} controls />
            }
            {documentsData?.file_data?.[selectedDocument]?.mime_type?.includes("audio") &&
              <audio src={`${config.endpoint}${documentsData?.file_data?.[selectedDocument]?.ext_file_path}`} style={{ maxWidth: !isMobile(window.innerWidth) && 500, maxHeight: !isMobile(window.innerWidth) && 500 }} controls />
            }
            {documentsData?.file_data?.[selectedDocument]?.mime_type?.includes("pdf") &&
              <iframe src={`${config.endpoint}${documentsData?.file_data?.[selectedDocument]?.ext_file_path}`} style={{ width: !isMobile(window.innerWidth) && 500, height: !isMobile(window.innerWidth) && 700 }} />
            }
            {/* <p>{documentsData?.file_data?.[selectedDocument]?.mime_type}</p> */}
          </div>

          <MdChevronRight className='cursor-pointer' size={30} color={selectedDocument < documentsData?.file_data?.length - 1 ? "black" : "gray"}
            onClick={() => {
              if (selectedDocument < documentsData?.file_data?.length - 1) {
                setSelectedDocument(selectedDocument + 1)
              }
            }} />
        </div>
        {/* <pre>{JSON.stringify(documentsData?.file_data, null, 2)}</pre> */}
        <div className={`${!isMobile(window.innerWidth) ? "w-full" : "w-80"} `}>
          <div className="card w-full mb-4 p-4">
            <p className="card-title">Administrative Actions</p>
            <div className="input-container">
              <label htmlFor="" className="input-label">Member's Remarks</label>
              <textarea style={{ color: "gray" }} className="textarea-input" value={documentsData?.description} disabled onFocus={() => {
                notify({ message: "You cannot edit this field", type: "error" })
              }} />
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Status</label>
              <select name="" id="" className="select-input h-11 px-2" value={documentsData?.status}
                onChange={(e) => {
                  let temp = { ...documentsData }
                  temp.status = e.target.value
                  setDocumentsData(temp)
                }}
              >
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Internal Remarks</label>
              <textarea className="textarea-input" value={documentsData?.internal_remarks} onChange={(e) => {
                let temp = { ...documentsData }
                temp.internal_remarks = e.target.value
                setDocumentsData(temp)
              }} />
            </div>
            <div className="row right">
              <button className="button" onClick={() => {
                updateClubDocuments()
              }}>Update</button>
            </div>
          </div>
          <div className="card rounded-md shadow-md p-4">
            <p className="card-title">Member's Details</p>
            <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4`}>
              <div className="input-container">
                <label htmlFor="" className="input-label">First Name</label>
                <input type="text" className="text-input" value={memberData?.first_name} placeholder='First Name' onChange={(e) => setMemberData({ ...memberData, first_name: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Last Name</label>
                <input type="text" className="text-input" value={memberData?.last_name} placeholder='Last Name' onChange={(e) => setMemberData({ ...memberData, last_name: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Username</label>
                <input type="text" className="text-input" value={memberData?.username} placeholder='Username' onChange={(e) => setMemberData({ ...memberData, username: e.target.value })} />
              </div>
            </div>
            <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 mt-4`}>
              <div className="input-container">
                <label htmlFor="" className="input-label">Email</label>
                <input type="text" className="text-input" value={memberData?.email} placeholder='Email' onChange={(e) => setMemberData({ ...memberData, email: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Contact Number</label>
                <input type="text" className="text-input" value={memberData?.contact_number} placeholder='Contact Number' onChange={(e) => setMemberData({ ...memberData, contact_number: e.target.value })} />
              </div>
            </div>
            <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 mt-4`}>
              <div className="input-container">
                <label htmlFor="" className="input-label">Date of Birth</label>
                <input type="date" className="text-input" value={memberData?.date_of_birth?.split("T")?.[0]} placeholder='Date of Birth' onChange={(e) => setMemberData({ ...memberData, date_of_birth: e.target.value })} />
              </div>
            </div>
          </div>
          {/* <div className="card mt-4 rounded-md shadow-md">
                <p className="card-title">User Account</p>
                <div className="row gap-4 items-center">
                  <div className="input-container">
                    <label htmlFor="" className="input-label">Account Type</label>
                    <select className="select-input h-11 px-2"
                      value={memberData?.account_type}
                      onChange={(e) => setMemberData({ ...memberData, account_type: e.target.value })}
                    >
                      <option value="member">Member</option>
                      <option value="staff">Staff</option>
                      <option value="manager">Manager</option>
                      <option value="manager">Admin</option>
                      <option value="superadmin">Super Admin</option>
                    </select>
                  </div>
                  <div className="input-container">
                    <label htmlFor="" className="input-label">Club Joined Date</label>
                    <input type="date" className="text-input" value={memberData?.date_joined?.split("T")?.[0]} placeholder='Club Joined Date' onChange={(e) => setMemberData({ ...memberData, date_joined: e.target.value })} />
                  </div>
                  <div className="row gap-4 items-center">
                    <input type="checkbox" className="checkbox-input" checked={memberData?.is_instructor == 1} placeholder='Instructor Rated' onChange={(e) => setMemberData({ ...memberData, is_instructor: e.target.checked ? 1 : 0 })} />
                    <label htmlFor="" className="input-label" style={{ whiteSpace: "nowrap" }}>Instructor Rated</label>
                  </div>
                  <div className="row gap-4 items-center">
                    <input type="checkbox" className="checkbox-input" checked={memberData?.is_examiner == 1} placeholder='Examiner Rated' onChange={(e) => setMemberData({ ...memberData, is_examiner: e.target.checked ? 1 : 0 })} />
                    <label htmlFor="" className="input-label" style={{ whiteSpace: "nowrap" }}>Examiner Rated</label>
                  </div>
                </div>
                {user_id !== "new" && <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 mt-4`}>
                  <button className="button">Send Password Reset</button>
                  {authorisationConverter({ requirement: "manager", current_role: userRole }) && <button className="button"
                    disabled={memberData?.is_locked == "1"}
                    onClick={() => {
                      unlockAccount()
                    }}
                  >Unlock Account</button>}
                </div>}
              </div> */}
          <div className="card mt-4 rounded-md shadow-md p-4">
            <p className="card-title">Aviation Details</p>
            <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4`}>
              <div className="input-container">
                <label htmlFor="" className="input-label">Aviation Start Date</label>
                <input type="date" className="text-input" value={memberData?.aviation_start_date?.split("T")?.[0]} placeholder='User ' onChange={(e) => setMemberData({ ...memberData, aviation_start_date: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Flying Cycles</label>
                <input type="text" className="text-input" value={memberData?.flying_cycles} placeholder='Flight Cycle' onChange={(e) => setMemberData({ ...memberData, flying_cycles: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Flying Hours</label>
                <input type="text" className="text-input" value={memberData?.flying_hours} placeholder='Flight Hours' onChange={(e) => setMemberData({ ...memberData, flying_hours: e.target.value })} />
              </div>
            </div>
            <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 mt-4`}>
              <div className="input-container">
                <label htmlFor="" className="input-label">License Type</label>
                <select className="select-input h-11 px-2"
                  value={memberData?.license_type}
                  onChange={(e) => setMemberData({ ...memberData, license_type: e.target.value })}
                >
                  <option value="none">Unclassified</option>
                  <option value="student">Student License</option>
                  <option value="private">Private License</option>
                  <option value="commercial">Commercial Pilot License</option>
                  <option value="airline_transport">Airline Transport Pilot License</option>
                  <option value="multicrew">Multi-Crew Pilot License</option>
                </select>
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">License Number</label>
                <input type="number" className="text-input" value={memberData?.license_number} placeholder='License Number' onChange={(e) => setMemberData({ ...memberData, license_number: e.target.value })} />
              </div>
            </div>
            <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 mt-4`}>
              <div className="input-container">
                <label htmlFor="" className="input-label">Medical Due Date</label>
                <input type="date" className="text-input" value={memberData?.medical_date?.split("T")?.[0]} placeholder='User ' onChange={(e) => setMemberData({ ...memberData, medical_date: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Certificate of Expiry Date</label>
                <input type="date" className="text-input" value={memberData?.certificate_of_expiry_date?.split("T")?.[0]} placeholder='User ' onChange={(e) => setMemberData({ ...memberData, certificate_of_expiry_date: e.target.value })} />
              </div>
            </div>
          </div>
          <div className="card mt-4 rounded-md shadow-md p-4">
            <p className="card-title">Internal Action</p>
            <div className="input-container">
              <label htmlFor="" className="input-label">Internal Remarks</label>
              <textarea className="textarea-input" value={memberData?.account_remarks} placeholder='Remarks' onChange={(e) => setMemberData({ ...memberData, account_remarks: e.target.value })} />
            </div>
          </div>

          <div className='button-container mt-4'>
            <button className="button" onClick={() => {
              updateMember()
            }}>Save</button>
          </div>

          {/* <pre>{JSON.stringify(memberData, null, 2)}</pre> */}
        </div >
      </div >
    </MainLayout >
  )
}
