import React, { useEffect, useLayoutEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'
import MainLayout from '../../layouts/MainLayout'
import { isMobile, tidyDate } from '../../utils/helper'
import { MdAdd } from 'react-icons/md'

export default function MemberFormPage() {
  const modal = {
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    date_of_birth: new Date().toLocaleDateString(),
    account_type: "member",
    date_joined: new Date().toLocaleDateString(),
    is_instructor: 0,
    is_examiner: 0,
    aviation_start_date: new Date().toLocaleDateString(),
    flying_cycles: 0,
    flying_hours: 0,
    license_type: "none",
    license_number: "",
    medical_date: new Date().toLocaleDateString(),
    certificate_of_expiry_date: new Date().toLocaleDateString(),
    account_remarks: "",
    username: "",
  }


  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  let user_id = useParams().user_id
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [memberData, setMemberData] = useState(user_id === "new" ? modal : {})

  const [membershipData, setMembershipData] = useState([])
  const [selectedMilestoneData, setSelectedMilestoneData] = useState({})
  const [milestoneData, setMilestoneData] = useState([])


  const fetchOragnisationData = async () => {
    let endpoint = config.endpoint + "/organisation/details"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await response.json()
    if (data.status === 200) {
      setMembershipData(data.data?.membership_data)
    }
  }

  useEffect(() => {
    fetchOragnisationData()
  }, [])

  const createMember = async () => {
    let endpoint = config.endpoint + "/users/club/"
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(memberData)
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate(-1)
      notify({ type: "success", message: "User created successfully!" })
    } else {
      notify({ type: "error", message: `${data.message}` })
    }
  }

  const unlockAccount = async () => {
    let endpoint = config.endpoint + "/auth/account/unlock"
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "User account has been unlocked" })
    } else {
      notify({ type: "error", message: `${data.message}` })
    }
  }

  const sendPasswordLink = async () => {
    let endpoint = config.endpoint + "/auth/password/forgot"
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: memberData?.email
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Password reset link has been sent to the user" })
    } else {
      notify({ type: "error", message: `${data.message}` })
    }
  }

  const updateMember = async () => {
    let endpoint = config.endpoint + "/users/id/" + user_id
    let response = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(memberData)
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate(-1)
      notify({ type: "success", message: "User updated successfully!" })
    } else {
      notify({ type: "error", message: `${data.message}` })
    }
  }

  const deleteMember = async () => {
    let endpoint = config.endpoint + "/users/id/" + user_id
    let response = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(memberData)
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate(-1)
      notify({ type: "success", message: "User deleted successfully!" })
    } else {
      notify({ type: "error", message: `${data.message}` })
    }
  }

  const fetchUserById = async () => {
    if (!user_id || user_id === "new") return
    let endpoint = config.endpoint + "/users/id/" + user_id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setMemberData(data.data)
    }
  }

  useEffect(() => {
    fetchUserById()
    fetchMilestone()
  }, [])


  // Milestone
  const fetchMilestone = async () => {
    let endpoint = config.endpoint + "/milestone/user/id/" + user_id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setMilestoneData(data.data)
    }
  }

  const createMilestone = async () => {
    let endpoint = config.endpoint + "/milestone/"
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedMilestoneData)
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchMilestone()
      setSelectedMilestoneData({})
      notify({ type: "success", message: "Milestone created successfully!" })
    } else {
      notify({ type: "error", message: `${data.message}` })
    }
  }

  const updateMilestone = async () => {
    if (!selectedMilestoneData?.id) return notify({ message: "Unable to update milestone", type: "error" })
    let endpoint = config.endpoint + "/milestone/id/" + selectedMilestoneData?.id
    let response = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedMilestoneData)
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchMilestone()
      setSelectedMilestoneData({})
      notify({ type: "success", message: "Milestone updated successfully!" })
    } else {
      notify({ type: "error", message: `${data.message}` })
    }
  }

  const deleteMilestone = async () => {
    if (!selectedMilestoneData?.id) return notify({ message: "Unable to delete milestone", type: "error" })
    let endpoint = config.endpoint + "/milestone/id/" + selectedMilestoneData?.id
    let response = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchMilestone()
      setSelectedMilestoneData({})
      notify({ type: "success", message: "Milestone deleted successfully!" })
    } else {
      notify({ type: "error", message: `${data.message}` })
    }
  }

  return (
    <MainLayout
      navSelected={"Club"}
      componentName={user_id === "new" ? "Create Member" : "Edit Member"}
    >
      <div className="card rounded-md shadow-md p-4">
        <p className="card-title">Member's Details</p>
        <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4`}>
          <div className="input-container">
            <label htmlFor="" className="input-label">First Name</label>
            <input type="text" className="text-input" value={memberData?.first_name} placeholder='First Name' onChange={(e) => setMemberData({ ...memberData, first_name: e.target.value })} />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">Last Name</label>
            <input type="text" className="text-input" value={memberData?.last_name} placeholder='Last Name' onChange={(e) => setMemberData({ ...memberData, last_name: e.target.value })} />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">Username</label>
            <input type="text" className="text-input" value={memberData?.username} placeholder='Username' onChange={(e) => setMemberData({ ...memberData, username: e.target.value })} />
          </div>
        </div>
        <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4`}>
          <div className="input-container">
            <label htmlFor="" className="input-label">Email</label>
            <input type="text" className="text-input" value={memberData?.email} placeholder='Email' onChange={(e) => setMemberData({ ...memberData, email: e.target.value })} />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">Contact Number</label>
            <input type="text" className="text-input" value={memberData?.contact_number} placeholder='Contact Number' onChange={(e) => setMemberData({ ...memberData, contact_number: e.target.value })} />
          </div>
        </div>
        <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4`}>
          <div className="input-container">
            <label htmlFor="" className="input-label">Date of Birth</label>
            <input type="date" className="text-input" value={memberData?.date_of_birth?.split("T")?.[0]} placeholder='Date of Birth' onChange={(e) => setMemberData({ ...memberData, date_of_birth: e.target.value })} />
          </div>
        </div>
      </div>

      <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 alignStart mt-4`}>
        <div style={{ width: isMobile(window.innerWidth) ? "100%" : "70%" }}>
          {/* User Account */}
          <div className="card rounded-md shadow-md p-4">
            <p className="card-title">User Account</p>
            <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 items-center`}>
              <div className="input-container" style={{ width: "100%" }}>
                <label htmlFor="" className="input-label">Account Type</label>
                <select className="select-input h-11 px-2" style={{ width: "100%" }}
                  value={memberData?.account_type}
                  onChange={(e) => setMemberData({ ...memberData, account_type: e.target.value })}
                >
                  <option value="member">Member</option>
                  <option value="staff">Staff</option>
                  <option value="manager">Manager</option>
                  <option value="manager">Admin</option>
                  <option value="superadmin">Super Admin</option>
                </select>
              </div>
              <div className="input-container" style={{ width: "100%" }}>
                <label htmlFor="" className="input-label">Membership Type</label>
                <select className="select-input h-11 px-2" style={{ width: "100%" }}
                  value={memberData?.membership_id}
                  onChange={(e) => setMemberData({ ...memberData, membership_id: e.target.value })}
                >
                  {membershipData?.length > 0 && membershipData?.map((item, index) => {
                    return <option key={index} value={item.id}>{item.name}</option>
                  })}
                </select>
              </div>
              <div className="input-container" style={{ width: "100%" }}>
                <label htmlFor="" className="input-label">Club Joined Date</label>
                <input type="date" className="text-input" style={{ width: "100%" }} value={memberData?.date_joined?.split("T")?.[0]} placeholder='Club Joined Date' onChange={(e) => setMemberData({ ...memberData, date_joined: e.target.value })} />
              </div>
              <div className="row gap-4 items-center" style={{ width: "100%", justifyContent: isMobile(window.innerWidth) && "space-between" }}>
                <input type="checkbox" className="checkbox-input" checked={memberData?.is_instructor == 1} placeholder='Instructor Rated' onChange={(e) => setMemberData({ ...memberData, is_instructor: e.target.checked ? 1 : 0 })} />
                <label htmlFor="" className="input-label" style={{ whiteSpace: "nowrap" }}>Instructor Rated</label>
              </div>
              <div className="row gap-4 items-center" style={{ width: "100%", justifyContent: isMobile(window.innerWidth) && "space-between" }}>
                <input type="checkbox" className="checkbox-input" checked={memberData?.is_examiner == 1} placeholder='Examiner Rated' onChange={(e) => setMemberData({ ...memberData, is_examiner: e.target.checked ? 1 : 0 })} />
                <label htmlFor="" className="input-label" style={{ whiteSpace: "nowrap" }}>Examiner Rated</label>
              </div>
            </div>
            {user_id !== "new" && <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 mt-4`}>
              <button className="button"
                onClick={() => {
                  sendPasswordLink()
                }}
              >Send Password Reset</button>
              {authorisationConverter({ requirement: "manager", current_role: userRole }) && <button className="button"
                disabled={memberData?.is_locked == "0"}
                onClick={() => {
                  unlockAccount()
                }}
              >Unlock Account</button>}
            </div>}
          </div>

          {/* Aviation Details */}
          <div className="card mt-4 rounded-md shadow-md p-4">
            <p className="card-title">Aviation Details</p>
            <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4`}>
              <div className="input-container" style={{ width: "100%" }}>
                <label htmlFor="" className="input-label">Aviation Start Date</label>
                <input type="date" className="text-input" style={{ width: "100%" }} value={memberData?.aviation_start_date?.split("T")?.[0]} placeholder='User ' onChange={(e) => setMemberData({ ...memberData, aviation_start_date: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Flying Cycles</label>
                <input type="text" className="text-input" value={memberData?.flying_cycles} placeholder='Flight Cycle' onChange={(e) => setMemberData({ ...memberData, flying_cycles: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Flying Hours</label>
                <input type="text" className="text-input" value={memberData?.flying_hours} placeholder='Flight Hours' onChange={(e) => setMemberData({ ...memberData, flying_hours: e.target.value })} />
              </div>
            </div>
            <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4`}>
              <div className="input-container">
                <label htmlFor="" className="input-label">License Type</label>
                <select className="select-input h-11 px-2 text" 
                  value={memberData?.license_type}
                  onChange={(e) => setMemberData({ ...memberData, license_type: e.target.value })}
                >
                  <option value="none">Unclassified</option>
                  <option value="student">Student License</option>
                  <option value="private">Private License</option>
                  <option value="commercial">Commercial Pilot License</option>
                  <option value="airline_transport">Airline Transport Pilot License</option>
                  <option value="multicrew">Multi-Crew Pilot License</option>
                </select>
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">License Number</label>
                <input type="number" className="text-input" value={memberData?.license_number} placeholder='License Number' onChange={(e) => setMemberData({ ...memberData, license_number: e.target.value })} />
              </div>
            </div>
            <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4`}>
              <div className="input-container">
                <label htmlFor="" className="input-label">Medical Due Date</label>
                <input type="date" className="text-input" value={memberData?.medical_date?.split("T")?.[0]} placeholder='User ' onChange={(e) => setMemberData({ ...memberData, medical_date: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Certificate of Expiry Date</label>
                <input type="date" className="text-input" value={memberData?.certificate_of_expiry_date?.split("T")?.[0]} placeholder='User ' onChange={(e) => setMemberData({ ...memberData, certificate_of_expiry_date: e.target.value })} />
              </div>
            </div>
          </div>

          {/* Internal Action */}
          <div className="card mt-4 rounded-md shadow-md p-4">
            <p className="card-title">Internal Action</p>
            <div className="input-container">
              <label htmlFor="" className="input-label">Internal Remarks</label>
              <textarea className="textarea-input" value={memberData?.account_remarks} placeholder='Remarks' onChange={(e) => setMemberData({ ...memberData, account_remarks: e.target.value })} />
            </div>
          </div>
        </div>
        <div style={{ width: isMobile(window.innerWidth) ? "100%" : "30%" }}>
          <div className="card rounded-md shadow-md p-4">
            <div className="row items-center justify-between">
              <p className="card-title">Milestone</p>
              <button className="backgroundLess gap-4 items-center row"
                onClick={() => {
                  setSelectedMilestoneData({
                    date: new Date().toLocaleDateString(),
                    description: "",
                    user_id: user_id
                  })
                }}
              >
                <MdAdd />
                <p style={{ fontSize: 12 }}>Add Milestone</p>
              </button>
            </div>

            {Object.keys(selectedMilestoneData).length > 0 && <div className="mt-4 pb-10" style={{ borderBottom: "1px solid gray" }}>
              <div className="input-container">
                <label htmlFor="" className="input-label">Date</label>
                <input type="date" className="text-input" value={selectedMilestoneData?.date} placeholder='Date' onChange={(e) => setSelectedMilestoneData({ ...selectedMilestoneData, date: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Description</label>
                <input type="text" className="text-input" value={selectedMilestoneData?.description} placeholder='Description' onChange={(e) => setSelectedMilestoneData({ ...selectedMilestoneData, description: e.target.value })} />
              </div>
              <div className="column right alignEnd">
                <div className="row gap-4 items-center">
                  {selectedMilestoneData?.id && <button className="delete-button" onClick={() => {
                    if (window.prompt("Please type 'DELETE' to confirm deletion") !== "DELETE") return
                    deleteMilestone()
                  }}>Delete</button>}
                  <button className="button" onClick={() => {
                    if (selectedMilestoneData?.id) {
                      updateMilestone()
                    } else {
                      createMilestone()
                    }
                  }}>{selectedMilestoneData?.id ? "Update" : "Create"}</button>
                </div>
              </div>
            </div>}

            {milestoneData?.length > 0 ? milestoneData?.map((item, index) => {
              return (
                <div className="row gap-4 items-center justify-between cursor-pointer" onClick={() => {
                  setSelectedMilestoneData(item)
                }}>
                  <div style={{ width: 150 }}>
                    {index % 2 == 0 && <>
                      <p style={{ color: "gray", fontSize: 12 }}>{tidyDate(item.date)}</p>
                      <p>{item.description}</p>
                    </>}
                  </div>
                  {/* line */}
                  <div className='column center items-center' style={{ backgroundColor: "transparent" }}>
                    <div style={{ height: 30, width: "2px", backgroundColor: "gray" }} />
                    <div style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: "black" }} />
                    <div style={{ height: 30, width: "2px", backgroundColor: "gray" }} />
                  </div>
                  <div style={{ width: 150 }}>
                    {index % 2 == 1 && <>
                      <p style={{ color: "gray", fontSize: 12 }}>{tidyDate(item.date)}</p>
                      <p>{item.description}</p>
                    </>}
                  </div>
                </div>
              )
            })
              : <p style={{ color: "gray", fontSize: 12 }}>No Milestone found</p>}
          </div>
        </div>
      </div>

      <div className='button-container mt-4'>
        {user_id !== "new" && <button className="delete-button" onClick={() => {
          if (window.prompt("Please type 'DELETE' to confirm deletion") !== "DELETE") return
          deleteMember()
        }}>Delete</button>}
        <button className="button" onClick={() => {
          if (user_id === "new") {
            createMember()
          } else {
            updateMember()
          }
        }}>Save</button>


      </div>
    </MainLayout>
  )
}
