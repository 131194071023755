import React, { useState, useEffect, useLayoutEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import notify from '../../utils/IANotification'
import { FaPlane } from 'react-icons/fa'
import MainLayout from '../../layouts/MainLayout'
import { isMobile } from '../../utils/helper'

export default function ClubResourcePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [resourceData, setResourceData] = useState([])
  const [sharingResourceData, setSharingResourceData] = useState([
    // {
    //   "id": 1,
    //   "registration": "VT-ABC",
    //   "aerial_model": "Boeing 737",
    //   "aerial_age": 12,
    //   "flight_hours": 2000,
    //   "flight_cycle": 1000,
    //   "file_data": {
    //     "ext_file_path": "/uploads/aircrafts/VT-ABC.jpg"
    //   },
    //   "oragnisation_data": {
    //     "organisation_name": "Indian Airlines"
    //   }
    // }
  ])

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  useEffect(() => {
    fetchClubResources()
    fetchClubResourceSharingRequest()
  }, [])

  const fetchClubResources = async () => {
    let endpoint = config.endpoint + "/resources/club/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setResourceData(data.data)
    }
  }

  const fetchClubResourceSharingRequest = async () => {
    let endpoint = config.endpoint + "/resources/request/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setSharingResourceData(data.data)
    }
  }


  const acceptRequest = async (request_id) => {
    let endpoint = config.endpoint + "/resources/request/accept/" + request_id
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data?.message })
      fetchClubResourceSharingRequest()
    } else {
      notify({ type: "error", message: data?.message })
    }
  }

  const rejectRequest = async (request_id) => {
    let endpoint = config.endpoint + "/resources/request/reject/" + request_id
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data?.message })
      fetchClubResourceSharingRequest()
    } else {
      notify({ type: "error", message: data?.message })
    }
  }


  return (
    <MainLayout
      navSelected={"Club"}
      componentName={"Fleet"}
      componentButtons={
        <div className="row center gap-4">
          <button className="button-cta font-['Poppins'] text-sm"
            onClick={() => {
              navigate("/club/resources/sharing")
            }}
          >Resource Sharing</button>
          <button className="button-cta font-['Poppins'] text-sm"
            onClick={() => {
              navigate("/club/resources/new")
            }}
          >Add Aircraft</button>
        </div>
      }
    >
      {sharingResourceData.length > 0 ? <>
        <div className="card rounded-md shadow-md p-4">
          <p className="card-title mb-4">Resource Sharing Request</p>
          {/* <pre>{JSON.stringify(sharingResourceData,null,2)}</pre> */}
          {(sharingResourceData.length > 0) && sharingResourceData.map((resource, index) => {
            return (
              <div className="mb-4" key={index}
                style={{ borderWidth: 0 }}
              >
                <div className='row gap-4 items-center ' style={{ borderWidth: 0 }}>
                  <p style={{ color: "gray", letterSpacing: "1", whiteSpace: "nowrap" }}>from {resource?.oragnisation_data?.organisation_name.toUpperCase()}</p>
                  <div style={{ height: 1, backgroundColor: "gray", width: "100%" }} />
                </div>
                <p className="card-title">{resource?.registration}</p>
                <div className="row gap-4" style={{ width: "100%", justifyContent: "right" }}>
                  <button className="button-cta p-2 p-10"
                    onClick={() => {
                      rejectRequest(resource?.id)
                    }}
                  >Resource Sharing</button>
                  <button className="button-cta p-2 p-10"
                    onClick={() => {
                      acceptRequest(resource?.id)
                    }}
                  >Accept</button>
                </div>
              </div>
            )
          })}
        </div>
        <div className="mt-20"></div>
      </>
        : null
      }
      {resourceData.length > 0 ? resourceData.map((resource, index) => {
        return (
          <div className={`card mb-4 ${isMobile(window.innerWidth) ? "column" : "row"} items-center`} key={index}
            style={{ padding: 0 }}
            onClick={() => {
              navigate("/club/resources/" + resource?.id)
            }}
          >
            {/* <pre>{JSON.stringify(resource, null, 2)}</pre> */}
            <img src={resource?.file_data?.ext_file_path ? `${config.endpoint}${resource?.file_data?.ext_file_path}` : "/placeholder_aircraft.jpg"} alt="" style={{ width: isMobile(window.innerWidth) ? "100%" : 150, height: 100, objectFit: "cover" }} />
            <div className="p-all pl-10">
              <p className="card-title" style={{ fontWeight: "normal" }}>{resource?.registration}</p>
              <p className="card-title" style={{ color: "gray" }}>{resource?.aerial_model}</p>
              <p className="card-title" style={{ color: "gray" }}>{resource?.aerial_age} months • {resource?.flight_hours} hours • {resource?.flight_cycle} cycles</p>
            </div>
          </div>
        )
      })
        :
        <div className="card rounded-md shadow-md p-4">
          <FaPlane size={50} color={"gray"} style={{ transform: "rotate(315deg)" }} />
          <p className="card-title" style={{ color: "gray" }}>No Aircraft Found, to add a new aircraft click on the "Add Aircraft" button above</p>
          {/* <div className="reg-container p-4">
            {sharingResourceData.length > 0 ? <>
              <div className="card p-4 rounded-md shadow-md overflow-hidden">
                <p className="card-title mb-4">Resource Sharing Request</p>
                {sharingResourceData.length > 0 && sharingResourceData.map((resource, index) => {
                  return (
                    <div className="card mb-4" key={index}

                    >
                      <div className='row gap-4 items-center '>
                        <p style={{ color: "gray", letterSpacing: "1", whiteSpace: "nowrap" }}>from {resource?.oragnisation_data?.organisation_name.toUpperCase()}</p>
                        <div style={{ height: 1, backgroundColor: "gray", width: "100%" }} />
                      </div>
                      <p className="card-title">{resource?.registration}</p>
                      <div className="row gap-4" style={{ width: "100%", justifyContent: "right" }}>
                        <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2"
                          onClick={() => {
                            rejectRequest(resource?.id)
                          }}
                        >Reject</button>
                        <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2"
                          onClick={() => {
                            acceptRequest(resource?.id)
                          }}
                        >Accept</button>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="mt-4"></div>
            </>
              : null
            }
            {resourceData.length > 0 ? resourceData.map((resource, index) => {
              return (
                <div className="card mb-4 row items-center rounded-md shadow-md overflow-hidden cursor-pointer" key={index}
                  style={{ padding: 0 }}
                  onClick={() => {
                    navigate("/club/resources/" + resource?.id)
                  }}
                >
                  <img src={resource?.file_data?.ext_file_path ? `${config.endpoint}${resource?.file_data?.ext_file_path}` : "/placeholder_aircraft.jpg"} alt="" style={{ width: 150, height: 100, objectFit: "cover" }} />
                  <div className="p-all pl-4">
                    <p className="card-title" style={{ fontWeight: "normal" }}>{resource?.registration}</p>
                    <p className="card-title" style={{ color: "gray" }}>{resource?.aerial_model}</p>
                    <p className="card-title" style={{ color: "gray" }}>{resource?.aerial_age} months • {resource?.flight_hours} hours • {resource?.flight_cycle} cycles</p>
                  </div>
                </div>
              )
            })
              :
              <>
               
              </>
            }
          </div> */}
        </div>
      }
    </MainLayout>
  )
}
