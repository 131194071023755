import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import MainLayout from '../../layouts/MainLayout'

export default function EmailTemplatesPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const templates_types = [
    {
      name: "Welcome Email",
      code: "welcome_email",
      description: "Will be sent to new users as they join the club"
    },
    {
      name: "Account Recovery Email",
      code: "account_recovery_email",
      description: "Will be sent to users to recovery their account"
    },
    {
      name: "Booking Status Email",
      code: "booking_status_email",
      description: "Will be sent to whenever there is a change in the booking status"
    },
    {
      name: "Announcement Email",
      code: "announcement_email",
      description: "Template to send announcements to the users"
    },
    {
      name: "Membership Renewal Email",
      code: "membership_renewal_email",
      description: "Will be sent to users to renew their membership"
    }
  ]

  return (
    <MainLayout
      navSelected={"Announcements"}
      componentName={"Email Templates"}
    >
      {templates_types.map((template, index) => {
        return (
          <div className="card p-2 rounded-md mb-2 shadow-md flex flex-row justify-between items-center cursor-pointer" key={index}
            onClick={()=>{
              navigate(`/email/templates/${template.code}`)
            }}
          >
            <div>
              <h2 className='text-sm font-extrabold'>{template.name}</h2>
              <p>{template.description}</p>
            </div>
          </div>
        )
      })}
    </MainLayout>
  )
}
