import React, { useState, useEffect, useLayoutEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import FileUploadField from '../../components/FileUploadField'
import { HexColorPicker } from "react-colorful";
import notify from '../../utils/IANotification'
import { MdAdd, MdRemove } from 'react-icons/md'
import Switch from "react-switch";
import { isMobile } from '../../utils/helper'
import MainLayout from '../../layouts/MainLayout'

export default function SettingsPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [selectedPolicy, setSelectedPolicy] = useState()

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])


  const [organisationData, setOrganisationData] = useState({
    organisation_name: "",
    organisation_colours: "#000000",
    organisation_bio: "",
    policy: [],
    base_aerodrome: "",
    email: "",
    phone: "",
    address: "",
    website: ""
  })

  const [membershipData, setMembershipData] = useState([])
  const [enrolmentLinkData, setEnrolmentLinkData] = useState({})
  const [quickLinksData, setQuickLinksData] = useState([])

  let membership_model = {
    name: "",
    fee: "",
    validity: "",
    is_default: 0 //only one can be default
  }

  let links_model = {
    name: "",
    link: "",
  }

  const fetchOrganisationData = async () => {
    let endpoint = config.endpoint + "/organisation/details"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await response.json()
    if (data.status === 200) {
      setOrganisationData(data.data?.organisation_data)
      setEnrolmentLinkData(data.data?.enrolment_link_data || {})
      setMembershipData(data.data?.membership_data || [])
      setQuickLinksData(data.data?.link_data || [])
    }
  }

  useEffect(() => {
    fetchOrganisationData()
  }, [])


  const submitOrganisationData = async () => {
    let endpoint = config.endpoint + "/organisation/"
    let response = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...organisationData,
        membership_data: membershipData,
        link_data: quickLinksData,
        enrolment_link_data: enrolmentLinkData,
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      // success
      notify({ message: "Organisation updated successfully", type: "success" })
      fetchOrganisationData()
    } else {
      // error
      notify({ message: data.message, type: "error" })
    }
  }

  const getEnrolmentLink = async () => {
    let endpoint = config.endpoint + "/organisation/enrolment"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchOrganisationData()
      notify({ message: "Enrolment link generated successfully", type: "success" })
    }
  }

  return (
    <MainLayout
      navSelected={"Club"}
      componentName={"Settings"}
    >
      <div>
        <div className="card rounded-md shadow-md p-4" style={{ marginBottom: 0, }}>
          <p className="card-title mb-4">Customisation</p>
          <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 w-full`}>
            <div style={{ minWidth: !isMobile(window.innerWidth) && 300 }}>
              <FileUploadField item_index={0} max_file_size={10000000} max_files={1} onChange={(data) => setOrganisationData({ ...organisationData, organisation_colours: data })} upload_url={config.endpoint + "/organisation/logo"} text={"Upload Logo"} subtext={"Drag and drop file here"} />
            </div>
            <div className='w-full'>
              <div className="input-container">
                <label htmlFor="" className="input-label">Name</label>
                <input type="text" className="text-input" value={organisationData?.organisation_name} onChange={(e) => setOrganisationData({ ...organisationData, organisation_name: e.target.value })} />
              </div>
              <div className='input-container'>
                <label htmlFor="" className="input-label">Theme</label>
                <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 alignStart`}>
                  <div style={{ width: !isMobile(window.innerWidth) ? 300 : "100%", height: 50, borderRadius: 10, border: "2px solid black", backgroundColor: organisationData?.organisation_colours?.toLowerCase() }}></div>
                  <HexColorPicker color={organisationData?.organisation_colours?.toLowerCase()}
                    style={{ width: "100%", height: 100, borderRadius: 10 }}
                    onChange={
                      (color) => setOrganisationData({ ...organisationData, organisation_colours: color })
                    } />
                </div>
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Club Base Aerodrome (ICAO)</label>
                <input type="text" className="text-input" value={organisationData?.base_aerodrome} placeholder='e.g. WSSS' onChange={(e) => setOrganisationData({ ...organisationData, base_aerodrome: e.target.value })} />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Bio</label>
                <textarea type="text" className="textarea-input" value={organisationData?.organisation_bio} onChange={(e) => setOrganisationData({ ...organisationData, organisation_bio: e.target.value })} />
              </div>
            </div>
          </div>
          {/* <pre>{JSON.stringify(organisationData, null, 2)}</pre> */}
          <p className="card-title">Contact</p>
          <div className={`${isMobile(window.innerWidth) ? "column" : "row"} justify-between items-center  mt-4 w-full gap-4`} style={{ width: "100%" }}>
            <div className="input-container" style={{ minWidth: 0 }}>
              <label htmlFor="" className="input-label">Email</label>
              <input type="text" className="text-input" value={organisationData?.email} onChange={(e) => setOrganisationData({ ...organisationData, email: e.target.value })} />
            </div>
            <div className="input-container" style={{ minWidth: 0 }}>
              <label htmlFor="" className="input-label">Phone</label>
              <input type="text" className="text-input" value={organisationData?.phone} onChange={(e) => setOrganisationData({ ...organisationData, phone: e.target.value })} />
            </div>
            <div className="input-container" style={{ minWidth: 0 }}>
              <label htmlFor="" className="input-label">Website</label>
              <input type="text" className="text-input" value={organisationData?.website} onChange={(e) => setOrganisationData({ ...organisationData, website: e.target.value })} />
            </div>
          </div>
          <div className="input-container mb-40" style={{ minWidth: 0, marginBottom: 40 }}>
            <label htmlFor="" className="input-label">Address</label>
            <textarea type="text" className="textarea-input" value={organisationData?.address} onChange={(e) => setOrganisationData({ ...organisationData, address: e.target.value })} />
          </div>

          {/* Club Enrolment */}
          <div className="row justify-between items-center mb-4 mt-4 w-full" style={{ width: "100%" }}>
            <p className="card-title" style={{ whiteSpace: "nowrap" }}>Club Enrolment</p>
          </div>
          {enrolmentLinkData?.link ?
            <>
              <div className="flex flex-row items-center gap-2 mb-2">
                <Switch checked={enrolmentLinkData?.is_active == 1} onChange={(e) => {
                  setEnrolmentLinkData({ ...enrolmentLinkData, is_active: e ? 1 : 0 })
                }} />
                <label htmlFor="" className="input-label" style={{ whiteSpace: "nowrap",  }}>Allow Enrolment and Login</label>
              </div>
              <div className="flex flex-row items-start gap-4">
                <div style={{ width: "100%" }}>
                  <div className="input-container" style={{ minWidth: 0 }}>
                    <label htmlFor="" className="input-label">Registration Link</label>
                    <input type="text" className="text-input cursor-copy" disabled value={`${config.local_url}/auth/registration/user?organisation_url=${enrolmentLinkData?.link}`} onClick={() => {
                      navigator.clipboard.writeText(`${config.local_url}/auth/registration/user?organisation_url=${enrolmentLinkData?.link}`)
                      notify({ message: "Link copied to clipboard", type: "success" })
                    }} />
                  </div>
                  <div className="input-container" style={{ minWidth: 0 }}>
                    <label htmlFor="" className="input-label">Organisation Login</label>
                    <input type="text" className="text-input cursor-copy" disabled value={`${config.local_url}/auth/login?organisation_url=${enrolmentLinkData?.link}`} onClick={() => {
                      navigator.clipboard.writeText(`${config.local_url}/auth/login?organisation_url=${enrolmentLinkData?.link}`)
                      notify({ message: "Link copied to clipboard", type: "success" })
                    }} />
                  </div>
                </div>
              </div>
            </>
            :
            <>
              <button className="button row gap-4 items-center"
                style={{ fontSize: 12, whiteSpace: "nowrap" }}
                onClick={() => {
                  getEnrolmentLink()
                }}
              >
                <MdAdd size={20} />
                Enrolment Link
              </button>
              <p className='text-xs mt-2 text-gray-400'>Generate a link for your members to register</p>
            </>
          }

          {/* Club Membership */}
          <div className="row justify-between items-center mb-4 mt-4 w-full" style={{ width: "100%" }}>
            <p className="card-title" style={{ whiteSpace: "nowrap" }}>Club Membership</p>
            <button className="backgroundLess row gap-4 items-center"
              style={{ fontSize: 12, whiteSpace: "nowrap" }}
              onClick={() => {
                setMembershipData([...membershipData, { ...membership_model }])
              }}
            >
              <MdAdd size={20} color='black' />
              Add Membership
            </button>
          </div>
          {membershipData?.length > 0 ? membershipData?.map((item, index) => {
            return (
              <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 w-full`} key={index}
                style={{ width: "100%", marginBottom: 20, borderBottom: "1px solid #e0e0e0", paddingBottom: 20 }}
              >
                <div className="input-container" style={{ minWidth: 0 }}>
                  <label htmlFor="" className="input-label">Name</label>
                  <input type="text" className="text-input" value={item?.name} onChange={(e) => {
                    let temp = [...membershipData]
                    temp[index].name = e.target.value
                    setMembershipData(temp)
                  }} />
                </div>
                <div className="input-container" style={{ minWidth: 0 }}>
                  <label htmlFor="" className="input-label">Fee</label>
                  <input type="text" className="text-input" value={item?.fee} onChange={(e) => {
                    let temp = [...membershipData]
                    temp[index].fee = e.target.value
                    setMembershipData(temp)
                  }} />
                </div>
                <div className="input-container" style={{ minWidth: 0 }}>
                  <label htmlFor="" className="input-label">Validity</label>
                  <input type="text" className="text-input" value={item?.validity} onChange={(e) => {
                    let temp = [...membershipData]
                    temp[index].validity = e.target.value
                    setMembershipData(temp)
                  }} />
                </div>
                <div className="input-container" style={{ minWidth: 0 }}>
                  <label htmlFor="" className="input-label">Default</label>
                  <Switch checked={item?.is_default == 1} onChange={(e) => {
                    let temp = [...membershipData]
                    if (e) {
                      temp.forEach((item, i) => {
                        if (i !== index) {
                          temp[i].is_default = 0
                        }
                      })
                    }
                    temp[index].is_default = e ? 1 : 0
                    setMembershipData(temp)

                  }} />
                </div>

                <button className="backgroundLess row gap-4 items-center"
                  onClick={() => {
                    let temp = [...membershipData]
                    temp.splice(index, 1)
                    setMembershipData(temp)

                  }}
                >
                  <MdRemove size={20} color='black' />
                  Remove
                </button>
              </div>
            )
          }) :
            <p className="text-center">No Membership Setup 💳</p>
          }


          <div className="row items-center justify-between w-full mt-20">
            <p className="card-title">Club Policy</p>
            <button className="backgroundLess row gap-4 items-center"
              style={{ fontSize: 12 }}
              onClick={() => {
                setSelectedPolicy(organisationData?.policy?.length)
                setOrganisationData({
                  ...organisationData, policy: [...organisationData?.policy, {
                    name: "New Policy",
                    content: "Policy Content"
                  }]
                })
              }}
            >
              <MdAdd size={20} color='black' />
              Add Policy
            </button>
          </div>
          <div className="row gap-4 items-center hide-scrollbars mt-4 mb-4" style={{ overflowY: "scroll" }}>
            {organisationData?.policy?.length > 0 && organisationData?.policy?.map((item, index) => {
              return (
                <div>
                  <button
                    className='button'
                    style={{ backgroundColor: selectedPolicy === index ? "#0283EF" : "white", color: selectedPolicy === index ? "white" : "#0283EF", border: "2px solid #0283EF", fontSize: 13, borderRadius: 10, padding: "5px 10px", fontWeight: "normal" }}
                    onClick={() => {
                      setSelectedPolicy(index)
                    }}
                  >
                    {item?.name}
                  </button>
                </div>
              )
            })}
          </div>

          {selectedPolicy !== "" ?
            <div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Name</label>
                <input type="text" className="text-input" value={organisationData?.policy[selectedPolicy]?.name}
                  onChange={(e) => {
                    let temp = [...organisationData?.policy]
                    temp[selectedPolicy].name = e.target.value
                    setOrganisationData({ ...organisationData, policy: temp })
                  }
                  } />
              </div>
              <div className="input-container">
                <label htmlFor="" className="input-label">Content</label>
                <textarea className="textarea-input" value={organisationData?.policy[selectedPolicy]?.content}
                  onChange={(e) => {
                    let temp = [...organisationData?.policy]
                    temp[selectedPolicy].content = e.target.value
                    setOrganisationData({ ...organisationData, policy: temp })
                  }}
                ></textarea>
              </div>
            </div>
            :
            <p className="text-center">Select a policy to edit</p>
          }

          <div className="row items-center justify-between w-full mt-20">
            <p className="card-title">Quick Links</p>
            <button className="backgroundLess row gap-4 items-center"
              style={{ fontSize: 12 }}
              onClick={() => {
                setQuickLinksData([...quickLinksData, { ...links_model }])
              }}
            >
              <MdAdd size={20} color='black' />
              Add Link
            </button>
          </div>
          {quickLinksData?.length > 0 ? quickLinksData?.map((item, index) => {
            return (
              <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 w-full`} key={index}
                style={{ width: "100%", marginBottom: 20, borderBottom: "1px solid #e0e0e0", paddingBottom: 20 }}
              >
                <div className="input-container" style={{ minWidth: 0 }}>
                  <label htmlFor="" className="input-label">Name</label>
                  <input type="text" className="text-input" value={item?.name} onChange={(e) => {
                    let temp = [...quickLinksData]
                    temp[index].name = e.target.value
                    setQuickLinksData(temp)
                  }} />
                </div>
                <div className="input-container" style={{ minWidth: 0 }}>
                  <label htmlFor="" className="input-label">Link</label>
                  <input type="text" className="text-input" value={item?.link} onChange={(e) => {
                    let temp = [...quickLinksData]
                    temp[index].link = e.target.value
                    setQuickLinksData(temp)
                  }} />
                </div>
                <button className="backgroundLess row gap-4 items-center"
                  onClick={() => {
                    let temp = [...quickLinksData]
                    temp.splice(index, 1)
                    setQuickLinksData(temp)

                  }}
                >
                  <MdRemove size={20} color='black' />
                  Remove
                </button>
              </div>
            )
          })
            :
            <p className="text-center">No Quick Links Setup 🌐</p>
          }

          {/* Save Button */}
          <div className="button-container mt-40">
            <button className="button font-['Poppins'] text-sm"
              onClick={() => {
                submitOrganisationData()
              }}
            >Save</button>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
