import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { MdAdd, MdCopyAll, MdError } from 'react-icons/md'
import { tidyDate } from '../../utils/helper'

export default function APIPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  useEffect(() => {
    fetchApiKeys()
  }, [])

  const [apiData, setApiData] = useState([])

  const fetchApiKeys = async () => {
    let endpoint = `${config.endpoint}/api-manager/keys/`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setApiData(data.data)
    } else {
      toast.error(data.message)
    }
  }

  const createApiKey = async () => {
    let endpoint = `${config.endpoint}/api-manager/key`
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchApiKeys()
      toast.success(data.message)
    } else {
      toast.error(data.message)
    }
  }

  const revokeApiKey = async (key) => {
    let endpoint = `${config.endpoint}/api-manager/revoke`
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        api_key: key
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      fetchApiKeys()
      toast.success(data.message)
    } else {
      toast.error(data.message)
    }
  }


  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Dashboard"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header">
              <h1>API Access</h1>
            </div>
          </div>
          <div className="container">
            <div className="inner-container">
              <div className="row justify-between items-center mb-5">
                <h1>Keys</h1>
                <button className="button row gap-2 items-center"
                  onClick={() => createApiKey()}
                >
                  <MdAdd size={20} />
                  Key
                </button>
              </div>
              {apiData.length > 0 ? (
                apiData?.map((item, index) => {
                  return (
                    <div className="card rounded-md p-4">
                      <div className="row justify-between items-center">
                        <div className="row items-center gap-4">
                          <h2>{item.key}</h2>
                          <button className="backgroundLess"
                            onClick={() => {navigator.clipboard.writeText(item.key);toast.success("Copied to clipboard!")}}
                          >
                            <MdCopyAll size={20} />
                          </button>
                        </div>
                        <button className="button-cta"
                          onClick={() => {
                            if (window.confirm("Are you sure you want to revoke this key?")) revokeApiKey(item.key)
                          }}
                        >
                          Revoke
                        </button>
                      </div>
                      <div className="row justify-between items-center">
                        <p className="gray text-xs">Used: {item?.usage?.total} times • Last Used: {item?.usage?.total == 0 ? "Never Used" : tidyDate(item?.usage?.last_used)}</p>
                      </div>
                    </div>
                  )
                })
              ) :
                <div className="component p-4">
                  <MdError size={50} />
                  <h2 className='gray' style={{ color: "gray" }}>No API Keys Found</h2>
                </div>
              }
              {/* <pre>{JSON.stringify(apiData, null, 2)}</pre> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
