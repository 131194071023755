import React from 'react'
import { MdClose } from 'react-icons/md'

export default function ModalLayout({ children, title, show, setShow }) {
    return (
        <div>
            <div className={`fixed inset-0 z-50 bg-black bg-opacity-50 ${show ? 'block' : 'hidden'}`} onClick={() => setShow(false)}></div>
            <div className={`fixed inset-0 z-50 flex justify-center items-center ${show ? 'block' : 'hidden'}`}>
                <div className="bg-white p-4 rounded-md shadow-md w-96">
                    <div className="flex justify-between items-center">
                        <h1 className="text-lg font-semibold">{title}</h1>
                        <button onClick={() => setShow(false)} className="text-red-500"><MdClose /></button>
                    </div>
                    <div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}
