import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router'
import { IoAdd, IoCloudDownload, IoCloudUpload, IoPersonAdd, IoTrash } from 'react-icons/io5'
import { MdImportExport } from 'react-icons/md'
import { FaFileExport, FaFileImport } from 'react-icons/fa'
import Paper from '@mui/material/Paper';
import { ViewState, EditingState, IntegratedEditing, } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  WeekView,
  Toolbar,
  DateNavigator,
  Appointments,
  AppointmentTooltip,
  TodayButton,
  MonthView,
} from '@devexpress/dx-react-scheduler-material-ui';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { IoClose } from 'react-icons/io5'
import Select from 'react-select'
import { captialise, isMobile, tidyDate } from '../../utils/helper'
import notify from '../../utils/IANotification'
import MainLayout from '../../layouts/MainLayout'

export default function FleetSchedulePage() {
  const currentDate = '2018-11-01'

  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [component, setComponent] = useState(document.getElementById("component-header")?.clientHeight)



  useLayoutEffect(() => {
    setHeader(document?.getElementById("user-header")?.clientHeight)
    setComponent(document?.getElementById("component-header")?.clientHeight)
  }, [header, component])


  const [heightOfArea, setHeightOfArea] = useState(window.innerHeight - document.getElementById("admin-header")?.clientHeight - document.getElementById("mid-header")?.clientHeight - 100)
  const [selectedDate, setSelectedDate] = useState(tidy_date(new Date()))
  const [selectedView, setSelectedView] = useState("day") // day, week, month, custom
  const [selectedResource, setSelectedResource] = useState()
  let scheduleModel = {
    name: "",
    description: "",
    start_datetime: "",
    end_datetime: "",
    schedule_type: "schedule", // schedule, booking
  }


  useEffect(() => {
    setHeightOfArea(window.innerHeight - document.getElementById("user-header")?.clientHeight - document.getElementById("mid-header")?.clientHeight - 100)
    setHeader(document.getElementById("user-header")?.clientHeight)
    setComponent(document?.getElementById("component-header")?.clientHeight)
  }, [document.getElementById("mid-header")?.clientHeight, document.getElementById("user-header")?.clientHeight, document?.getElementById("component-header")?.clientHeight])

  function tidy_date(date) {
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    if (month < 10) {
      month = `0${month}`
    }
    if (day < 10) {
      day = `0${day}`
    }

    return `${year}-${month}-${day}`
  }


  const [scheduleData, setScheduleData] = useState([
    // { startDate: '2023-05-10T12:00', endDate: '2023-05-10T13:30', title: 'Go to a gym', payload: "hi" },
    // { startDate: '2023-05-10T12:00', endDate: '2023-05-10T13:30', title: 'Go to a gym', payload: "hi" },
    // { startDate: '2023-05-10T09:45', endDate: '2023-05-10T11:00', title: 'Meeting', payload: "hi" },
    // { startDate: '2023-05-10T12:00', endDate: '2023-05-10T13:30', title: 'Go to a gym', payload: "hi" },
  ])
  const [resourceData, setResourceData] = useState([])
  const [selectedSchedule, setSelectedSchedule] = useState()

  useEffect(() => {
    fetchClubResources()
  }, [])

  const fetchClubResources = async () => {
    let endpoint = config.endpoint + "/resources/club/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setResourceData(data.data)
    }
  }


  const fetchBookingByAerialId = async (aerial_id) => {
    setScheduleData([])
    let endpoint = config.endpoint + "/schedule/resource/id/" + aerial_id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      let schedule_data = data.data.map((item) => {
        if (item?.schedule_type === "schedule") {
          return {
            ...item,
            startDate: item?.start_datetime,
            endDate: item?.end_datetime,
            title: `[SCHEDULE]: ${item?.name}`
          }
        }
        return {
          ...item,
          startDate: item?.flight_departure_datetime,
          endDate: item?.flight_arrival_datetime,
          title: `[FLIGHT]: ${captialise(item?.flight_type)} - ${item?.user_data?.first_name} ${item?.user_data?.last_name}`
        }
      })
      setScheduleData(schedule_data)
    }
  }


  const submitSchedule = async () => {
    let endpoint = config.endpoint + "/schedule/"
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...selectedSchedule,
        aerial_resource_id: selectedResource?.id
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data?.message })
      setSelectedSchedule(null)
      fetchBookingByAerialId(selectedSchedule?.aerial_resource_id)
    } else {
      notify({ type: "error", message: data?.message })
    }
  }

  const editSchedule = async () => {
    let endpoint = config.endpoint + "/schedule/id/" + selectedSchedule?.id
    let response = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...selectedSchedule,
        aerial_resource_id: selectedResource?.id
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data?.message })
      setSelectedSchedule(null)
      fetchBookingByAerialId(selectedSchedule?.aerial_resource_id)
    } else {
      notify({ type: "error", message: data?.message })
    }
  }

  const deleteSchedule = async () => {
    let endpoint = config.endpoint + "/schedule/id/" + selectedSchedule?.id
    let response = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ type: "success", message: data?.message })
      setSelectedSchedule(null)
      fetchBookingByAerialId(selectedSchedule?.aerial_resource_id)
    } else {
      notify({ type: "error", message: data?.message })
    }
  }


  function uiDate(date) { //day, Date Month Year
    let date_day = date.split("-")[2]
    let date_month = date.split("-")[1]
    let date_year = date.split("-")[0]

    let dateObj = new Date(date_year, date_month - 1, date_day)

    let year = dateObj.getFullYear()
    let month = dateObj.getMonth() + 1
    let day = dateObj.getDate()
    let dayOfWeek = dateObj.getDay()
    let monthName = dateObj.toLocaleString('default', { month: 'long' })
    let dayName = dateObj.toLocaleString('default', { weekday: 'long' })
    return `${dayName}, ${day} ${monthName} ${year}`
  }

  const MyAppointment = ({ children, style, ...restProps }) => {
    return (
      <Appointments.Appointment
        {...restProps}
        style={{
          ...style,
          backgroundColor: "#12344d",
          borderRadius: "10px",
          opacity: selectedSchedule?.id === restProps?.data?.id ? "1" : "0.5",
        }}
        onClick={() => {
          if (selectedSchedule?.id === restProps?.data?.id) {
            setSelectedSchedule(null)
            return
          }
          setSelectedSchedule(restProps.data)

        }}
      >
        {children}
      </Appointments.Appointment>
    );
  }



  return (
    <MainLayout
      navSelected={"Club"}
      componentName={"Fleet Schedule"}
    // centeredContents={true}
    >
      <div className={`${isMobile(window.innerWidth) ? "column" : "row"} alignStart items-start`}>
        {!selectedSchedule && <button className="button gap items-center" style={{ position: "fixed", bottom: 30, right: 30, zIndex: 6, display: "flex", padding: "10px 15px", borderRadius: "20px" }}
          onClick={() => {
            setSelectedSchedule({
              ...scheduleModel,
            })
          }}
        >
          <IoAdd />
          <span>Schedule</span>
        </button>}
        <div className="w-full hide-scrollbars" style={{ marginLeft: !isMobile(window.innerWidth) && 20, marginTop: !isMobile(window.innerWidth) && 20, minWidth: !isMobile(window.innerWidth) && 400, width: "100%", overflowY: selectedSchedule && "scroll", maxHeight: heightOfArea, position: "sticky", top: `${component}px` }}>
          <div className="card rounded-md shadow-md p-4">
            <p className="card-title mb-4">Select Aircraft</p>
            <Select
              options={resourceData?.map((item) => {
                return {
                  value: item,
                  label: `[${item?.aerial_type?.toUpperCase()}] ${item?.registration}`
                }
              })}

              styles={{

              }}

              onChange={(e) => {
                setScheduleData(e?.value?.schedule)
                fetchBookingByAerialId(e?.value?.id)
                setSelectedResource(e?.value)
              }}

            />
          </div>

          {/* Booking View */}
          {selectedSchedule && selectedSchedule?.schedule_type === "booking" && <div className="mt-4 card rounded-md shadow-md p-4"
            style={isMobile(window.innerWidth) ? {
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100dvw",
              zIndex: 5,
            } : {}}
          >
            <div className="row items-center justify-between">
              <p className="card-title mb-4">Schedule</p>
              <button className="backgroundLess"
                onClick={() => {
                  setSelectedSchedule(null)
                }}
              >
                <IoClose size={20} />
              </button>
            </div>
            <p style={{ fontSize: 13, marginBottom: 10 }}>To edit or view more details, you may View the Booking</p>
            {/* <pre>{JSON.stringify(selectedSchedule, null, 2)}</pre> */}
            <div className="input-container">
              <p className="input-label">Type</p>
              <input type="text" className="text-input" value={captialise(selectedSchedule?.flight_type)} disabled />
            </div>
            <div className="input-container">
              <p className="input-label">Departure</p>
              <input type="text" className="text-input" value={tidyDate(selectedSchedule?.flight_departure_datetime)} disabled />
            </div>

            <div className="input-container">
              <p className="input-label">Arrival</p>
              <input type="text" className="text-input" value={tidyDate(selectedSchedule?.flight_arrival_datetime)} disabled />
            </div>
            <div className="row gap-4 items-center">
              <div className="input-container" style={{ minWidth: "0px" }}>
                <p className="input-label">Departure Airport</p>
                <input type="text" className="text-input" value={selectedSchedule?.departing_aerodrome_id} disabled />
              </div>
              <div className="input-container" style={{ minWidth: "0px" }}>
                <p className="input-label">Arrival Airport</p>
                <input type="text" className="text-input" value={selectedSchedule?.arriving_aerodrome_id} disabled />
              </div>
            </div>
            <div className="button-container right column alignEnd"
              onClick={() => {
                navigate("/club/flights/bookings/" + selectedSchedule?.id)
              }}
            >
              <button className='button'>View Booking</button>
            </div>
          </div>}
        </div>

        <div className={`${isMobile(window.innerWidth) ? "" : "reg-container p-4"} `}>
          <div className="component row gap items-center justify-between w-full p-4" style={{ position: "sticky", top: `${component}px` }}>
            <div className="row items-center gap">
              <button className="button-cta"
                onClick={() => {
                  setSelectedDate(tidy_date(new Date()))
                }}
              >Today</button>
            </div>
            <div className="row gap-4 items-center">
              <button className="backgroundLess"
                onClick={() => {
                  let date = selectedDate
                  let date_day = date.split("-")[2]
                  let date_month = date.split("-")[1]
                  let date_year = date.split("-")[0]
                  let dateObj = new Date(date_year, date_month - 1, date_day)
                  dateObj.setDate(dateObj.getDate() - 1)
                  setSelectedDate(tidy_date(dateObj))
                  // setSelectedSchedule({
                  //   start_datetime: dateObj,
                  //   end_datetime: dateObj,
                  // })
                }}
              >
                {<BsChevronLeft size={20} />}
              </button>
              <p>{uiDate(selectedDate)}</p>
              <button className="backgroundLess"
                onClick={() => {
                  let date = selectedDate
                  let date_day = date.split("-")[2]
                  let date_month = date.split("-")[1]
                  let date_year = date.split("-")[0]
                  let dateObj = new Date(date_year, date_month - 1, date_day)
                  dateObj.setDate(dateObj.getDate() + 1)
                  setSelectedDate(tidy_date(dateObj))
                  // setSelectedSchedule({
                  //   start_datetime: dateObj,
                  //   end_datetime: dateObj,
                  // })
                }}
              >
                {<BsChevronRight size={20} />}
              </button>
            </div>

            <div className="row items-center">
              <button className="backgroundLess pr-5 pl-5 pt-1 pb-1" style={selectedView === "day" ? {
                backgroundColor: "#0222FF",
                color: "white",
                border: "1px solid #0222FF",
              } : {
                backgroundColor: "white",
                color: "black",
                border: "1px solid #0222FF"
              }}
                onClick={() => {
                  setSelectedView("day")
                }}
              >
                <p style={{ fontSize: 13 }}>Day</p>
              </button>
              <button className="backgroundLess pr-5 pl-5 pt-1 pb-1" style={selectedView === "week" ? {
                backgroundColor: "#0222FF",
                color: "white",
                border: "1px solid #0222FF",
              } : {
                backgroundColor: "white",
                color: "black",
                border: "1px solid #0222FF"
              }}
                onClick={() => {
                  setSelectedView("week")
                }}
              >
                <p style={{ fontSize: 13 }}>Week</p>
              </button>
              <button className="backgroundLess pr-5 pl-5 pt-1 pb-1" style={selectedView === "month" ? {
                backgroundColor: "#0222FF",
                color: "white",
                border: "1px solid #0222FF",
              } : {
                backgroundColor: "white",
                color: "black",
                border: "1px solid #0222FF"
              }}
                onClick={() => {
                  setSelectedView("month")
                }}
              >
                <p style={{ fontSize: 13 }}>Month</p>
              </button>
              <button className="backgroundLess pr-5 pl-5 pt-1 pb-1" style={selectedView === "custom" ? {
                backgroundColor: "#0222FF",
                color: "white",
                border: "1px solid #0222FF",
              } : {
                backgroundColor: "white",
                color: "black",
                border: "1px solid #0222FF"
              }}
                onClick={() => {
                  setSelectedView("custom")
                }}
              >
                <p style={{ fontSize: 13 }}>Custom</p>
              </button>
              {selectedView === "custom" &&
                <input type="date" className='text-input ml-20'
                  value={selectedDate}
                  onChange={(e) => {

                    let date = e.target.value
                    let date_day = date.split("-")[2]
                    let date_month = date.split("-")[1]
                    let date_year = date.split("-")[0]
                    let dateObj = new Date(date_year, date_month - 1, date_day)
                    setSelectedDate(tidy_date(dateObj))
                  }}
                />
              }
            </div>
          </div>

          <div className="row gap-4 alignStart">
            <Paper
              style={{ width: "100%", height: heightOfArea, }}
            >
              <Scheduler
                data={scheduleData}
                height={heightOfArea}
              >
                <ViewState currentDate={selectedDate ? selectedDate : currentDate} />
                {(selectedView === "day" || selectedView === "custom") && <DayView startDayHour={0} endDayHour={24} />}
                {(selectedView === "month" || selectedView === "custom") && <MonthView />}
                {(selectedView === "week" || selectedView === "custom") && <WeekView startDayHour={0} endDayHour={24} />}

                <Appointments appointmentComponent={MyAppointment} />
              </Scheduler>
            </Paper>
          </div>
        </div>

      </div>

    </MainLayout >
  )
}
