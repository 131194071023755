import React from 'react'
import { Link } from 'react-router-dom'



const ModuleNavigation = ({ selected, module_id }) => {
    return (
        <div className="module-navigation">
            <div className="module-navigation-item">
                <Link to={`/club/modules/${module_id}`} className='hide-navigation'>
                    <p className={`module-navigation-item-text ${selected === "information" ? "module-selected" : ""}`}>Information</p>
                </Link>
            </div>
            <div className="module-navigation-item">
                <Link to={`/club/modules/${module_id}/lessons`} className='hide-navigation'>
                    <p className={`module-navigation-item-text ${selected === "lessons" ? "module-selected" : ""}`}>Lessons</p>
                </Link>
            </div>
            <div className="module-navigation-item">
                <Link to={`/club/modules/${module_id}/members`} className='hide-navigation'>
                    <p className={`module-navigation-item-text ${selected === "members" ? "module-selected" : ""}`}>Members</p>
                </Link>
            </div>
        </div>
    )
}

export default ModuleNavigation