import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { MdAirplaneTicket, MdEditDocument, MdGroup, MdPunchClock, MdSettings } from 'react-icons/md'
import MainLayout from '../../layouts/MainLayout'
import { isMobile } from '../../utils/helper'

export default function ClubPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  return (
    <MainLayout
      navSelected={"Club"}
      componentName={"Club Administration"}
    >
      <div className={`${isMobile(window.innerWidth) ? "column" : "row"} items-center gap-4`}>
        <div className="column center items-center cursor-pointer" style={{ width: "100%", backgroundColor: "#12344d", padding: "20px 50px" }}
          onClick={() => {
            navigate("/club/members")
          }}
        >
          <MdGroup size={50} color="white" />
          <h2 style={{ color: "white", textAlign: "center" }}>Club Members</h2>
        </div>

        <div className="column center items-center cursor-pointer" style={{ width: "100%", backgroundColor: "#12344d", padding: "20px 50px" }}
          onClick={() => {
            navigate("/club/documents")
          }}
        >
          <MdEditDocument size={50} color="white" />
          <h2 style={{ color: "white", textAlign: "center" }}>Submitted Documents</h2>
        </div>
      </div>
      <div className={`${isMobile(window.innerWidth) ? "column" : "row"} items-center gap-4 mt-4`}>
        <div className="column center items-center cursor-pointer" style={{ width: "100%", backgroundColor: "#12344d", padding: "20px 50px" }}
          onClick={() => {
            navigate("/club/settings")
          }}
        >
          <MdSettings size={50} color="white" />
          <h2 style={{ color: "white", textAlign: "center" }}>Settings</h2>
        </div>

        <div className="column center items-center cursor-pointer" style={{ width: "100%", backgroundColor: "#12344d", padding: "20px 50px" }}
          onClick={() => {
            navigate("/club/resources")
          }}
        >
          <MdAirplaneTicket size={50} color="white" />
          <h2 style={{ color: "white", textAlign: "center" }}>Fleet</h2>
        </div>

        <div className="column center items-center cursor-pointer" style={{ width: "100%", backgroundColor: "#12344d", padding: "20px 50px" }}
          onClick={() => {
            navigate("/club/resources/schedule")
          }}
        >
          <MdPunchClock size={50} color="white" />
          <h2 style={{ color: "white", textAlign: "center" }}>Fleet Schedule</h2>
        </div>
      </div>
    </MainLayout>
  )
}
