import React, { useState, useEffect, useLayoutEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'
import { currency, tidyDate } from '../../utils/helper'

export default function AccountPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [paymentData, setPaymentData] = useState([])
  const [unpaidPaymentData, setUnpaidPaymentData] = useState([])
  const [requestData, setRequestData] = useState([])


  const fetchUserUnpaidPayments = async () => {
    let endpoint = config.endpoint + "/payments/user/unpaid"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setUnpaidPaymentData(data?.data)
    }
  }

  const fetchUserPayments = async () => {
    let endpoint = config.endpoint + "/payments/user"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setPaymentData(data?.data)
    }
  }

  const fetchUserRequest = async () => {
    let endpoint = config.endpoint + "/reimbursement/user/id/" + user_data?.id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setRequestData(data?.data?.reverse())
    }
  }

  const get_payment_link = async (payment_id) => {
    let endpoint = config.endpoint + "/payments/link/id/" + payment_id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 201) {
      window.location.href = data?.data
    } else {
      fetchUserPayments()
      notify({ type: "error", message: data?.message })
    }
  }

  const get_invoice_link = async (payment_id) => {
    let endpoint = config.endpoint + "/payments/invoice/link/id/" + payment_id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 201) {
      window.location.href = data?.data
    } else {
      fetchUserPayments()
      notify({ type: "error", message: data?.message })
    }
  }


  useEffect(() => {
    fetchUserUnpaidPayments()
    fetchUserPayments()
    fetchUserRequest()
  }, [])

  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Accounting"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header">
              <h1>My Accounts</h1>
            </div>
          </div>
          <div className="container">
            <div className="inner-container">
              <div className="card rounded-md shadow-md p-4" style={{ borderRadius: 10 }}>
                <p className="card-title">Payment Due</p>
                <div className="row justify-between">
                  <div className="row gap-4">
                    <p className="card-subtitle">Total Amount Due</p>
                    {/* <p className="card-subtitle">$ {unpaidPaymentData?.length > 0 ? unpaidPaymentData?.reduce((acc, curr) => {
                      return parseFloat(acc) + parseFloat(curr?.amount)
                    }) / 100 : 0} SGD</p> */}
                  </div>
                </div>
                {unpaidPaymentData?.length > 0 && <div className="mt-4">
                  {unpaidPaymentData && unpaidPaymentData.map((payment, index) => {
                    return (
                      <div className="row justify-between mb-4">
                        <div>
                          <p className="card-title">{currency((payment?.amount) / 100)} SGD </p>
                          <p>{payment?.remarks}</p>
                        </div>
                        <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2"
                          onClick={() => { get_payment_link(payment?.id) }}
                        >Pay</button>
                      </div>
                    )
                  })}
                </div>}
              </div>
              <div className="mt-4 card rounded-md shadow-md p-4" style={{ borderRadius: 10 }}>
                <p className="card-title">Past Payments</p>
                {paymentData?.length > 0 ? paymentData.map((payment, index) => {
                  if (payment?.is_paid == 0) return
                  return (
                    <div className="row justify-between mt-4" style={{ borderLeft: "5px solid blue", borderRadius: 5, paddingLeft: 10 }}>
                      <div>
                        <p className="card-title">paid {currency((payment?.amount) / 100)} SGD on {tidyDate(payment?.paidAt)}</p>
                        <p>{payment?.remarks}</p>
                      </div>
                      <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2"
                        onClick={() => { get_invoice_link(payment?.id) }}
                      >Invoice</button>
                    </div>
                  )
                })
                  :
                  <div className="row justify-between mt-4">
                    <div>
                      <p style={{ color: "gray" }}>No Payment</p>
                    </div>
                  </div>
                }
              </div>
              <div className="mt-4 card rounded-md shadow-md p-4" style={{ borderRadius: 10 }}>
                <div className="row items-center justify-between">
                  <p className="card-title">Reimbursement Request</p>
                  <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2"
                    onClick={() => { navigate("/accounting/request") }}
                  >Request</button>
                </div>
                {requestData?.length > 0 ? requestData.map((request, index) => {
                  let color = ""
                  if (request?.status === "pending") {
                    color = "orange"
                  } else if (request?.status === "approved") {
                    color = "green"
                  } else if (request?.status === "rejected") {
                    color = "red"
                  } else if (request?.status === "paid") {
                    color = "purple"
                  }
                  return (
                    <div className="row justify-between mt-4 items-center">
                      <div>
                        <p className="card-title">requested {currency((request?.amount/100))} SGD on {tidyDate(request?.createdAt)}</p>
                        <p>{request?.remarks}</p>
                      </div>
                      <p style={{ backgroundColor: color, padding: "5px 15px", color: "white", fontSize: 12, fontWeight: "900" }}>{request.status?.toUpperCase()}</p>
                    </div>
                  )
                }) :
                  <div className="row justify-between mt-4">
                    <div>
                      <p style={{ color: "gray" }}>No Request</p>
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
