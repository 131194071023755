import React, { useEffect, useState, useLayoutEffect, useMemo } from 'react'
import config from '../app-config'
import { MdLogout } from 'react-icons/md'
import ProfileMenu from './ProfileMenu'
import { isMobile } from '../utils/helper'

export default function UserHeader() {
    const [organisationData, setOrganisationData] = useState({})

    const organisationMemo = useMemo(() => organisationData, [organisationData])

    function changeFavicon(src) {
        var link = document.createElement('link'),
            oldLink = document.getElementById('dynamic-favicon');
        link.id = 'dynamic-favicon';
        link.rel = 'shortcut icon';
        link.href = src;
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
    }

    const fetchorganisationData = async () => {
        let endpoint = config.endpoint + "/organisation/details"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await response.json()
        if (data.status === 200) {
            setOrganisationData(data.data?.organisation_data)
            window.sessionStorage.setItem("organisation_data", JSON.stringify(data.data?.organisation_data))
            document.title = data.data?.organisation_data?.organisation_name

            // set the favicon to the organisation logo
            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.head.appendChild(link);
            }
            // changeFavicon(`${config.endpoint}${data?.data?.file_data?.ext_file_path}`)
        }
    }

    useEffect(() => {
        if (window.sessionStorage.getItem("organisation_data")) {
            setOrganisationData(JSON.parse(window.sessionStorage.getItem("organisation_data")))
        }
        fetchorganisationData()
    }, [])

    const [imageHeight, setImageHeight] = useState(document.getElementById("logo-navbar")?.getClientRects()[0].bottom)

    useLayoutEffect(() => {
        setImageHeight(document.getElementById("logo-navbar").getClientRects()[0].bottom)
    }, [imageHeight])

    let color = "black"
    // colour contrast
    if (organisationData?.organisation_colours) {
        let rgb = organisationData?.organisation_colours?.replace("rgb(", "").replace(")", "").split(",")
        let r = rgb[0]
        let g = rgb[1]
        let b = rgb[2]
        let brightness = Math.round(((parseInt(r) * 299) + (parseInt(g) * 587) + (parseInt(b) * 114)) / 1000)
        if (brightness > 125) {
            color = "black"
        } else {
            color = "white"
        }
    }

    return (
        <div
            style={{ width: "100%", backgroundColor: organisationMemo?.organisation_colours || "white",display: "flex", justifyContent: "left", alignItems: "center", flexDirection: "row", position: "sticky", top: 0, zIndex: 1000 }}
            id='user-header'
            className="shadow-md px-4 h-14"
        >
            <div className="row justify-between items-center w-full">
                <p style={{ color: color }} className="text-sm">{organisationMemo?.organisation_name}</p>
                {!isMobile(window.innerWidth) && <ProfileMenu color={color} />}
            </div>
        </div>
    )
}
