import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { authorisationConverter, logout, useFetchAuthorisation } from '../utils/AppFunctions'
import { FaAngleRight } from 'react-icons/fa'
import { AiOutlineDashboard } from 'react-icons/ai'
import { MdAccountBalance, MdAnnouncement, MdCode, MdDeveloperMode, MdEditDocument, MdGroup, MdLogout, MdOutlineAirplanemodeActive, MdOutlineEventNote, MdPerson } from 'react-icons/md'
import { FiAward } from 'react-icons/fi'
import config from '../app-config'
import { isMobile } from '../utils/helper'

export default function Navbar({ selected }) {
    let { isAuthorised, userRole } = useFetchAuthorisation("user")

    const size = 20
    const color = "white"
    const navigate = useNavigate()

    const [hoverItem, setHoverItem] = useState("")

    const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
    const [navbarWidth, setNavbarWidth] = useState(document.getElementById("navbar")?.clientWidth)
    const [selectedTop, setSelectedTop] = useState(0)
    const [organisationData, setOrganisationData] = useState({})
    const organisationMemo = useMemo(() => organisationData, [organisationData])

    const fetchorganisationData = async () => {
        let endpoint = config.endpoint + "/organisation/details"
        let response = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await response.json()
        if (data.status === 200) {
            let logo = data?.data?.file_data?.ext_file_path
            if (logo) {
                // set favicon
                // let favicon = document.getElementById("favicon")
                // favicon.href = `${config.endpoint}${logo}`
            }

            setOrganisationData(data.data?.organisation_data)
        }
    }

    useEffect(() => {
        fetchorganisationData()
    }, [])

    useLayoutEffect(() => {
        setHeader(document.getElementById("user-header")?.clientHeight)
        setNavbarWidth(document.getElementById("navbar")?.clientWidth)
    }, [header, navbarWidth])


    let items = [
        {
            name: "Dashboard",
            icon: <AiOutlineDashboard size={size} />,
            link: "/home",
            authorisation: "user",
            children: []
        },
        {
            name: "My Flights",
            // icon: <MdOutlineAirplanemodeActive size={size} color={color} />,
            icon: <MdOutlineAirplanemodeActive size={size} />,
            link: "/flights/bookings",
            authorisation: "user",
            children: [
                {
                    name: "My Flights",
                    link: "/flights/bookings",
                    authorisation: "user",
                },
                {
                    name: "Flight Logs",
                    link: "/flights/log",
                    authorisation: "user",
                },
                {
                    name: "Club Flight Booking",
                    link: "/club/flights/bookings",
                    authorisation: "admin",
                }
            ]
        },
        // {
        //     name: "Training & Certifications",
        //     icon: <FiAward size={size} color={color} />,
        //     authorisation: "user",
        //     link: "/modules",
        //     children: [
        //         {
        //             name: "My Modules",
        //             link: "/modules",
        //             authorisation: "user",
        //         },
        //         {
        //             name: "View Modules",
        //             link: "/club/modules",
        //             authorisation: "admin",
        //         },
        //         {
        //             name: "Create Module",
        //             link: "/club/modules/create",
        //             authorisation: "admin",
        //         },
        //     ]
        // },
        {
            name: "Accounting",
            // icon: <MdAccountBalance size={size} color={color} />,
            icon: <MdAccountBalance size={size} />,
            authorisation: "user",
            link: "/accounting",
            children: [
                {
                    name: "My Account",
                    link: "/accounting",
                    authorisation: "user",
                },
                {
                    name: "Club Accounts",
                    link: "/club/accounting",
                    authorisation: "admin",
                },
                {
                    name: "Payment Requests",
                    link: "/club/accounting/payment",
                    authorisation: "admin",
                },
                {
                    name: "Reimbursement Requests",
                    link: "/club/accounting/reimbursement",
                    authorisation: "admin",
                },
                // {
                //     name: "Transactions",
                //     link: "/club/accounting/transactions",
                //     authorisation: "admin",
                // },
                {
                    name: "Add Transaction",
                    link: "/club/accounting/transaction/new",
                    authorisation: "admin",
                },
            ]
        },
        {
            name: "Approval",
            // icon: <MdEditDocument size={size} color={color} />,
            icon: <MdEditDocument size={size} />,
            authorisation: "user",
            link: "/documents",
            children: [
                {
                    name: "Submit Documents",
                    link: "/documents/submit",
                    authorisation: "user",
                },
                {
                    name: "My Documents",
                    link: "/documents",
                    authorisation: "user",
                },

            ]
        },
        {
            name: "Events",
            icon: <MdOutlineEventNote size={size} />,
            authorisation: "user",
            link: "/events",
            children: [
                {
                    name: "View Events",
                    link: "/events",
                    authorisation: "user",
                },
                {
                    name: "Create Event",
                    link: "/events/details/create",
                    authorisation: "admin",
                },
            ]
        },
        {
            name: "Announcements",
            // icon: <MdGroup size={size} color={color} />,
            icon: <MdAnnouncement size={size} />,
            authorisation: "user",
            link: "/feed",
            children: [
                {
                    name: "Club News Feed",
                    link: "/feed/",
                    authorisation: "user",
                },
                {
                    name: "Club Announcement",
                    link: "/announcements/",
                    authorisation: "admin",
                },
                {
                    name: "Create Announcement",
                    link: "/announcements/details/create",
                    authorisation: "admin",
                },
                {
                    name: "Email Templates",
                    link: "/email/templates",
                    authorisation: "admin",
                },
            ]
        },
        {
            name: "Club",
            // icon: <MdGroup size={size} color={color} />,
            icon: <MdGroup size={size} />,
            authorisation: "admin",
            link: "/club",
            children: [
                {
                    name: "Members",
                    link: "/club/members",
                    authorisation: "admin",
                },
                {
                    name: "Fleet",
                    link: "/club/resources",
                    authorisation: "admin",
                },
                {
                    name: "Approve Documents",
                    link: "/club/documents",
                    authorisation: "admin",
                },
                {
                    name: "Fleet Schedule",
                    link: "/club/resources/schedule",
                    authorisation: "admin",
                },
                {
                    name: "Settings",
                    link: "/club/settings",
                    authorisation: "admin",
                },
            ]
        },
        {
            name: "Developers",
            icon: <MdCode size={size} />,
            authorisation: "admin",
            link: "/developer/api",
            children: [
                {
                    name: "API",
                    link: "/developer/api",
                    authorisation: "admin",
                },

            ]
        }
    ]

    const bottom_items = [
        {
            name: "Profile",
            icon: <MdPerson size={size} color={color} />,
            onClick: () => { navigate("/profile") },
        },
        {
            name: "Logout",
            icon: <MdLogout size={size} color={color} />,
            onClick: () => { logout() },
        }
    ]

    return (
        <div className='navbar shadow py-4 z-[5000]' id='navbar'
            onMouseLeave={() => {
                setHoverItem("")
            }}
        >
            <div
                // style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} 
                className="flex justify-center items-center"
            >
                <img src={organisationMemo?.file_data?.ext_file_path ? `${config.endpoint}${organisationMemo?.file_data?.ext_file_path}` : "/logoWoText.png"} alt=""
                    // style={{ width: 50, height: 50, objectFit: "contain", borderBottom: !isMobile(window.innerWidth) && "1px solid gray", paddingBottom: 10, marginBottom: 20 }} 
                    id='logo-navbar'
                    className="px-6 py-1 h-8 object-contain"
                    style={{ width: 150, height: 150, objectFit: "contain" }}
                />
            </div>
            {/* <div style={{ height: header - 10 }} /> */}
            <div
                // style={{ flexDirection: "column", alignItems: "center", justifyContent: "space-between", height: "100%" }}
                className="flex flex-col justify-between h-full pt-6"
            >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    {items.map((item, index) => {
                        if (!authorisationConverter({ requirement: item?.authorisation, current_role: userRole })) return
                        return (
                            <div
                                className={`navbarItem w-full !pr-12 ${selected === item.name && " active"}`}
                                key={index}
                                // style={{ backgroundColor: selected === item.name ? "#0283EF" : "" }} 
                                id={`navitem-${index}`}

                                onMouseEnter={() => {
                                    setHoverItem(index)
                                    if (document.getElementById(`navitem-${index}`)?.getBoundingClientRect()?.top) {
                                        setSelectedTop(document.getElementById(`navitem-${index}`)?.getBoundingClientRect()?.top)
                                    }
                                }}
                            >
                                <div className='flex flex-row justify-start items-center grow gap-4'
                                    onClick={() => {
                                        navigate(item.link)
                                    }}
                                >
                                    <div className="navbarItemIcon h-5 w-5 text-slate-300">
                                        {item.icon}
                                    </div>
                                    <div className="font-['Poppins'] text-slate-400 text-sm">
                                        {item?.name}
                                    </div>
                                </div>
                                {hoverItem === index && <div className='navbar-menu z-[2000] py-4 rounded-md border-solid border-[1px] border-slate-700/20' style={{ left: navbarWidth, top: selectedTop }}>
                                    {/* <p style={{ fontSize: 12, color: "black", fontWeight: "bold", }}> */}
                                    <p className="text-xs text-slate-400 font-semibold px-4 p-2">
                                        {item?.name?.toUpperCase()}
                                    </p>
                                    {item.children.map((child, childIndex) => {
                                        if (!authorisationConverter({ requirement: child?.authorisation, current_role: userRole })) return
                                        return (
                                            <div className='navbarItemChild p-2 px-4 hover:bg-slate-100 transition-colors' key={childIndex} id={`navitem-${index}-${childIndex}`}
                                            >
                                                <div className="row gap-2 items-center"
                                                    style={{ cursor: "pointer", zIndex: 3 }}
                                                    onClick={() => {
                                                        navigate(child.link)
                                                    }}
                                                >
                                                    <FaAngleRight color='grey' size={16} />
                                                    <p className="text-black text-md ">{child?.name}</p>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>}
                            </div>
                        )
                    })}
                </div>
                <p className="text-sm text-gray-400 p-2">Built by <span className='hover:underline cursor-pointer' onClick={()=>{window.open("https://www.ourcodelab.com","_blank")}}>OurCodeLab LLP</span> © {new Date().getFullYear()}</p>
            </div>
            {isMobile(window.innerWidth) && <div style={{}}>
                {bottom_items.map((item, index) => {
                    return (
                        <div className='navbarItem' key={index} style={{ backgroundColor: selected === item.name ? "#0283EF" : "" }} id={`navitem-bottom-${index}`}
                        >
                            <div className='navbarItemIcon'
                                onClick={() => {
                                    item.onClick()
                                }}
                            >
                                {item.icon}
                            </div>
                        </div>
                    )
                })}
            </div>}
        </div>
    )
}