import React, { useState, useEffect, useLayoutEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { airport_database } from '../../database/Civil-Airport-Database'
import { convertPilotLicenseType, getAgeByDate, isMobile, tidyDate } from '../../utils/helper'
import MainLayout from '../../layouts/MainLayout'

export default function ClubFlightBookingDetailPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  let booking_id = useParams().booking_id
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  const [bookingDetails, setBookingDetails] = useState({
    flight_departure_datetime: "",
    flight_arrival_datetime: "",
    aerial_resource_id: "",
    instructor_id: "",
    examiner_id: "",
    booking_status: "pending", // "pending", "cancelled", "completed"
    is_paid: 0,
    flight_type: "training", // "training", "examination", "leisure"
    flight_charges: [], // {"charge_name", "value"}
    payment_id: "",
    manifest: [], // {"name", "email", "passport_details"
    remarks: "",
    departing_aerodrome_id: "",
    arriving_aerodrome_id: "",
    flight_name: "",
    actual_departure_datetime: "",
    actual_arrival_datetime: "",
    actual_flight_distance: "",
    internal_remarks: "",
    instructor_confirmed: 0,
    examiner_confirmed: 0,
  })

  const [aerialResourceData, setAerialResourceData] = useState([])
  const [instructorData, setInstructorData] = useState([])
  const [examinerData, setExaminerData] = useState([])

  const fetchClubResource = async () => {
    let endpoint = config.endpoint + "/resources/club/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setAerialResourceData(data.data)
    }
  }

  const fetchClubInstructors = async () => {
    let endpoint = config.endpoint + "/users/instructors/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setInstructorData(data.data)
    }
  }

  const fetchClubExaminer = async () => {
    let endpoint = config.endpoint + "/users/examiners/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setExaminerData(data.data)
    }
  }



  const createBooking = async () => {
    let endpoint = config.endpoint + "/bookings/club/"
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...bookingDetails,
        // flight_departure_datetime: `${new Date(bookingDetails?.flight_departure_datetime)?.getFullYear()}-${new Date(bookingDetails?.flight_departure_datetime)?.getMonth() + 1}-${new Date(bookingDetails?.flight_departure_datetime)?.getDate()} ${new Date(bookingDetails?.flight_departure_datetime)?.getHours()}:${new Date(bookingDetails?.flight_departure_datetime)?.getMinutes()}:${new Date(bookingDetails?.flight_departure_datetime)?.getSeconds()}`,
        // flight_arrival_datetime: `${new Date(bookingDetails?.flight_arrival_datetime)?.getFullYear()}-${new Date(bookingDetails?.flight_arrival_datetime)?.getMonth() + 1}-${new Date(bookingDetails?.flight_arrival_datetime)?.getDate()} ${new Date(bookingDetails?.flight_arrival_datetime)?.getHours()}:${new Date(bookingDetails?.flight_arrival_datetime)?.getMinutes()}:${new Date(bookingDetails?.flight_arrival_datetime)?.getSeconds()}`,
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate(-1)
      notify({ message: "Booking created successfully!", type: "success" })
    } else {
      notify({ message: `${data.message}`, type: "error" })
    }
  }

  const updateBooking = async () => {
    let endpoint = config.endpoint + "/bookings/club/" + booking_id
    let response = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...bookingDetails,
        // flight_departure_datetime: `${bookingDetails?.flight_departure_datetime?.getFullYear()}-${bookingDetails?.flight_departure_datetime?.getMonth() + 1}-${bookingDetails?.flight_departure_datetime?.getDate()} ${bookingDetails?.flight_departure_datetime?.getHours()}:${bookingDetails?.flight_departure_datetime?.getMinutes()}:${bookingDetails?.flight_departure_datetime?.getSeconds()}`,
        // flight_arrival_datetime: `${bookingDetails?.flight_arrival_datetime?.getFullYear()}-${bookingDetails?.flight_arrival_datetime?.getMonth() + 1}-${bookingDetails?.flight_arrival_datetime?.getDate()} ${bookingDetails?.flight_arrival_datetime?.getHours()}:${bookingDetails?.flight_arrival_datetime?.getMinutes()}:${bookingDetails?.flight_arrival_datetime?.getSeconds()}`,
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate(-1)
      notify({ message: "Booking updated successfully!", type: "success" })
    } else {
      notify({ message: `${data.message}`, type: "error" })
    }
  }

  const fetchBookingById = async () => {
    if (!booking_id || booking_id === "new") return
    let endpoint = config.endpoint + "/bookings/id/" + booking_id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setBookingDetails(data.data)
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)

  }, [header])

  useEffect(() => {
    fetchClubInstructors()
    fetchClubResource()
    fetchBookingById()
    fetchClubExaminer()
  }, [])


  return (
    <MainLayout
      navSelected={"My Flights"}
      componentName={booking_id === "new" ? "New Booking" : "Booking #" + booking_id}
    >
      <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4`}>
        <div style={{ width: isMobile(window.innerWidth) ? "100%" : "20%" }}>
          <div className="card rounded-md shadow-md p-4">
            <p className="card-title">Pilot's Information</p>
            <p className="b-600">{bookingDetails?.user_data?.first_name} {bookingDetails?.user_data?.last_name}</p>
            <p>{convertPilotLicenseType(bookingDetails?.user_data?.license_type)}</p>
            <p className='mt-5'>{bookingDetails?.user_data?.flying_hours || 0} hours of flying experience</p>
            <p>{bookingDetails?.user_data?.flying_cycles || 0} completed flight cycles</p>

            <p className="b-600 mt-5">{((bookingDetails?.user_data?.date_of_birth)?.split("T")?.[0])} ({getAgeByDate(bookingDetails?.user_data?.date_of_birth) || 0} years old)</p>
            <p className='mt-5'>{bookingDetails?.user_data?.email}</p>
            <p>{bookingDetails?.user_data?.contact_number}</p>

            <p className="mt-5" style={{ fontWeight: "600", fontSize: 12 }}>Certifications</p>
            <p>{bookingDetails?.user_data?.certificate_of_expiry_date?.split("T")?.[0]} (COE)</p>
            <p>{bookingDetails?.user_data?.medical_date?.split("T")?.[0]} (Medical)</p>
            <div className="button-container right mt-5">
              <button className="button-cta"
                onClick={() => navigate(`/club/members/${bookingDetails?.user_data?.id}`)}
              >View Pilot</button>
            </div>
          </div>
          <div className="card mt-4 rounded-md shadow-md p-4">
            <p className="card-title">Payment Details</p>
            {/* <pre>{JSON.stringify(bookingDetails.payment_data, null, 2)}</pre> */}
            <p className="b-600 mt-5" style={{ color: "white", backgroundColor: bookingDetails?.payment_data?.is_paid == 1 ? "green" : "red", padding: "5px 15px", width: "auto", borderRadius: 20 }}>{bookingDetails?.payment_data?.is_paid == 1 ? `Paid on ${tidyDate(bookingDetails?.payment_data?.paidAt)}` : "Unpaid"}</p>
            {/* <p className="b-600 mt-5">${bookingDetails?.payment_data?.amount/100}</p> */}
            <p className="b-600 gray">
              {bookingDetails?.payment_data?.amount
                ? `$${bookingDetails.payment_data.amount / 100}`
                : "To be calculated"}
            </p>
          </div>
        </div>
        <div style={{ width: isMobile(window.innerWidth) ? "100%" : "80%" }}>
          <div className="card rounded-md shadow-md p-4">
            <p className="card-title">Booking Details</p>
            <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 items-center`}>
              <div className="input-container">
                <label className="input-label">Departure Date & Time</label>
                <input className="text-input" type="datetime-local" value={bookingDetails.flight_departure_datetime} onChange={(e) => setBookingDetails({ ...bookingDetails, flight_departure_datetime: e.target.value })} />
              </div>
              <div className="input-container">
                <label className="input-label">Arrival Date & Time</label>
                <input className="text-input" type="datetime-local" value={bookingDetails.flight_arrival_datetime} onChange={(e) => setBookingDetails({ ...bookingDetails, flight_arrival_datetime: e.target.value })} />
              </div>
            </div>
            <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-4 alignTop`}>
              <div className="input-container">
                <label className="input-label">Departing Aerodrome (ICAO)</label>
                <input className="text-input" type="text" value={bookingDetails?.departing_aerodrome_id?.toUpperCase()} onChange={(e) => setBookingDetails({ ...bookingDetails, departing_aerodrome_id: e.target.value })} />
                <p style={{ fontStyle: "italic", fontSize: 10, marginTop: 2 }}>{airport_database?.find((airport) => airport.icao?.toLowerCase() === bookingDetails.departing_aerodrome_id?.toLowerCase())?.airportName}</p>
              </div>
              <div className="input-container">
                <label className="input-label">Arriving Aerodrome (ICAO)</label>
                <input className="text-input" type="text" value={bookingDetails?.arriving_aerodrome_id?.toUpperCase()} onChange={(e) => setBookingDetails({ ...bookingDetails, arriving_aerodrome_id: e.target.value })} />
                <p style={{ fontStyle: "italic", fontSize: 10, marginTop: 2 }}>{airport_database?.find((airport) => airport.icao?.toLowerCase() === bookingDetails.arriving_aerodrome_id?.toLowerCase())?.airportName}</p>
              </div>
            </div>
            <div className={`${isMobile(window.innerWidth)? "column":"row"} gap-4 items-center`}>
              <div className="input-container">
                <label className="input-label">Flight Type</label>
                <select className="select-input h-11 px-2" value={bookingDetails.flight_type} onChange={(e) => setBookingDetails({ ...bookingDetails, flight_type: e.target.value })}>
                  <option value="training">Training</option>
                  <option value="examination">Examination</option>
                  <option value="leisure">Leisure</option>
                </select>
              </div>
              <div className="input-container">
                <label className="input-label">Aerial Resource</label>
                <select className="select-input h-11 px-2"
                  value={bookingDetails.aerial_resource_id}
                  onChange={(e) => setBookingDetails({ ...bookingDetails, aerial_resource_id: e.target.value })}
                >
                  <option value="">Select Aerial Resource</option>
                  {aerialResourceData.map((resource, index) => {
                    return (
                      <option key={index} value={resource.id}>{resource.nickname} ({resource?.registration})</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="" className="input-label">Remarks</label>
              <textarea className="text-input" value={bookingDetails.remarks} onChange={(e) => setBookingDetails({ ...bookingDetails, remarks: e.target.value })} />
            </div>
          </div>
          <div className="card mt-4 rounded-md shadow-md p-4">
            <p className="card-title">Personnel</p>
            <div className={`${isMobile(window.innerWidth)? "column":"row"} gap-4 items-center`}>
              <div style={{ width: "100%" }}>
                <div className="input-container">
                  <label className="input-label">Instructor</label>
                  <select className="select-input h-11 px-2"
                    value={bookingDetails.instructor_id}
                    onChange={(e) => setBookingDetails({ ...bookingDetails, instructor_id: e.target.value })}
                  >
                    <option value="">Select Instructor</option>
                    {instructorData.map((instructor, index) => {
                      return (
                        <option key={index} value={instructor.id}>{instructor.first_name} {instructor.last_name}</option>
                      )
                    })}
                  </select>
                  <div className="row gap-4 items-center">
                    <input type="checkbox" checked={bookingDetails.instructor_confirmed == 1} onChange={(e) => setBookingDetails({ ...bookingDetails, instructor_confirmed: e.target.checked ? 1 : 0 })} />
                    <p>Confirm Instructor</p>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div className="input-container">
                  <label className="input-label">Examiner</label>
                  <select className="select-input h-11 px-2"
                    value={bookingDetails.examiner_id}
                    onChange={(e) => setBookingDetails({ ...bookingDetails, examiner_id: e.target.value })}
                  >
                    <option value="">Select Examiner</option>
                    {instructorData.map((examiner, index) => {
                      return (
                        <option key={index} value={examiner.id}>{examiner.first_name} {examiner.last_name}</option>
                      )
                    })}
                  </select>
                </div>
                <div className="row gap-4 items-center">
                  <input type="checkbox" checked={bookingDetails.examiner_confirmed == 1} onChange={(e) => setBookingDetails({ ...bookingDetails, examiner_confirmed: e.target.checked ? 1 : 0 })} />
                  <p>Confirm Examiner</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-4 rounded-md shadow-md p-4">
            <p className="card-title">Flight Details</p>
            <div className='row gap-4 items-center'>
              <div className="input-container">
                <label className="input-label">Flight Name</label>
                <input className="text-input" type="text" value={bookingDetails.flight_name} onChange={(e) => setBookingDetails({ ...bookingDetails, flight_name: e.target.value })} />
              </div>
            </div>
            {bookingDetails?.booking_status === "completed" && <div className={`${isMobile(window.innerWidth)? "column":"row"} gap-4 items-center mt-4`}>
              <div className="input-container">
                <label className="input-label">Actual Time of Departure</label>
                <input className="text-input" type="datetime-local" value={bookingDetails.actual_departure_datetime} onChange={(e) => setBookingDetails({ ...bookingDetails, actual_departure_datetime: e.target.value })} />
              </div>
              <div className="input-container">
                <label className="input-label">Actual Time of Arrival</label>
                <input className="text-input" type="datetime-local" value={bookingDetails.actual_arrival_datetime} onChange={(e) => setBookingDetails({ ...bookingDetails, actual_arrival_datetime: e.target.value })} />
              </div>
              <div className="input-container">
                <label className="input-label">Flight Distance</label>
                <input className="text-input" type="number" value={bookingDetails.actual_flight_distance} placeholder='Kilometers' onChange={(e) => setBookingDetails({ ...bookingDetails, actual_flight_distance: e.target.value })} />
              </div>
            </div>}
          </div>

          <div className="card mt-4 rounded-md shadow-md p-4">
            <p className="card-title">Internal Actions</p>
            <div className='row gap-4 items-center'>
              <div className="input-container">
                <label className="input-label">Flight Status</label>
                <select className="select-input h-11 px-2"
                  value={bookingDetails.booking_status}
                  onChange={(e) => setBookingDetails({ ...bookingDetails, booking_status: e.target.value })}
                >
                  <option value="">Select Flight Status</option>
                  <option value="pending">Pending</option>
                  <option value="confirmed">Confirmed</option>
                  <option value="completed">Completed</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </div>
            </div>
            <div className="row gap-4 items-center mt-4">
              <div className="input-container">
                <label className="input-label">Internal Notes</label>
                <textarea className="text-input" value={bookingDetails.internal_remarks} onChange={(e) => setBookingDetails({ ...bookingDetails, internal_remarks: e.target.value })} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="button-container justifyRight mt-4">
        <button className="primary button" onClick={() => {
          if (booking_id === "new") {
            createBooking()
          } else {
            updateBooking()
          }
        }}>{booking_id === "new" ? "Create" : "Update"}</button>
      </div>
      {/* <pre>{JSON.stringify(examinerData,null,2)}</pre>
              <pre>{JSON.stringify(instructorData,null,2)}</pre>
              <pre>{JSON.stringify(aerialResourceData,null,2)}</pre> */}
    </MainLayout>
  )
}
