import React, { useState, useEffect, useLayoutEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { tidyDate } from '../../utils/helper'
import { ToastContainer } from 'react-toastify'
import { FaPlane } from 'react-icons/fa'

export default function FlightsPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  const [selectedNavFlights, setSelectedNavFlights] = useState("my-flights") // my-flights, work-flights, all-flights
  const [filterOption, setFilterOption] = useState("all"); // 'all', 'upcoming', 'completed'

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])


  const [bookingData, setBookingData] = useState([])
  const [workBookingData, setWorkBookingData] = useState([])

  const fetchUserFlights = async () => {
    let endpoint = config.endpoint + "/bookings/user"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      // const filteredBookings = applyFilter(data?.data?.booking_data, filterOption);
      // setBookingData(data?.data?.booking_data)
      // setWorkBookingData(data?.data?.work_booking_data)
      setBookingData(applyFilter(data?.data?.booking_data, filterOption));
      // setWorkBookingData(data?.data?.work_booking_data);
      setWorkBookingData(applyFilter(data?.data?.work_booking_data, filterOption));
    }
  }

  const applyFilter = (bookings, filterOption) => {
    if (filterOption === "all") {
      return bookings;
    } else if (filterOption === "upcoming") {
      return bookings.filter(
        (booking) =>
          (booking.booking_status === "pending" ||
            booking.booking_status === "confirmed") &&
          new Date(booking.flight_departure_datetime) > new Date()
      );
    } else if (filterOption === "completed") {
      return bookings.filter((booking) => booking.booking_status === "completed");
    }
    return bookings;
  };


  useEffect(() => {
    fetchUserFlights()
  }, [filterOption])

  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"My Flights"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component px-4" style={{ top: header, paddingBottom: 0 }}>
            <div className="component-header">
              <div className="row justify-between items-center">
                <div className="row gap-4 mt-4">
                  <p
                    style={{ paddingBottom: 10, borderBottom: selectedNavFlights === "my-flights" ? "2px solid #000" : "none" }}
                    className='text-sm cursor-pointer'
                    onClick={() => {
                      setSelectedNavFlights("my-flights")
                    }}
                  >My Flights</p>
                  {workBookingData?.length > 0 && <p style={{ paddingBottom: 10, borderBottom: selectedNavFlights === "work-flights" ? "2px solid #000" : "none" }}
                    className='text-sm cursor-pointer'
                    onClick={() => {
                      setSelectedNavFlights("work-flights")
                    }}
                  >Work Flights</p>}
                  <p style={{ paddingBottom: 10, borderBottom: selectedNavFlights === "all-flights" ? "2px solid #000" : "none" }}
                    className='text-sm cursor-pointer'
                    onClick={() => {
                      setSelectedNavFlights("all-flights")
                    }}
                  >All Flights</p>
                </div>
                <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2"
                  onClick={() => {
                    navigate("/flights/bookings/new")
                  }}
                >New Booking</button>
              </div>
            </div>
          </div>
          <div className="column right alignEnd p-4 w-full" style={{ width: "100%" }}>
            <select
              className='select-input h-11 px-2'
              style={{ width: 200, }}
              value={filterOption}
              onChange={(e) => setFilterOption(e.target.value)}
            >
              <option value="all">All Flights</option>
              <option value="upcoming">Upcoming Flights</option>
              <option value="completed">Completed Flights</option>
            </select>
          </div>

          <div className="reg-container px-4">
            {selectedNavFlights === "my-flights" && bookingData?.length === 0 && (
              <div className="card rounded-md shadow-md p-4">
                <FaPlane size={50} color={"gray"} style={{ transform: "rotate(315deg)" }} />
                <p className="card-title mt-4" style={{ color: "gray" }}>No flights found. To do so, you may book a flight at the "Book a Flight" section</p>
              </div>
            )}

            {selectedNavFlights === "work-flights" && workBookingData?.length === 0 && (
              <div className="card rounded-md shadow-md p-4">
                <FaPlane size={50} color={"gray"} style={{ transform: "rotate(315deg)" }} />
                <p className="card-title mt-4" style={{ color: "gray" }}>No work flights found.</p>
              </div>
            )}

            {bookingData?.length > 0 && (selectedNavFlights === "my-flights" || selectedNavFlights === "all-flights") && bookingData.map((booking, index) => {
              return (
                <div className="card cursor-pointer mb-4 rounded-md shadow-md p-4"
                  onClick={() => {
                    navigate("/flights/bookings/" + booking?.id)
                  }}
                >
                  <p className="card-title">Flight #{booking?.id}</p>
                  <p>{tidyDate(booking?.flight_departure_datetime)} • {booking?.flight_type} • {booking?.booking_status}</p>
                </div>
              )
            })}

            {workBookingData?.length > 0 && (selectedNavFlights === "work-flights" || selectedNavFlights === "all-flights") && workBookingData.map((booking, index) => {
              return (
                <div className="card cursor-pointer mb-4 row items-center justify-between rounded-md shadow-md p-4"
                  onClick={() => {
                    navigate("/flights/bookings/" + booking?.id)
                  }}
                >
                  <div>
                    <p className="card-title">Flight #{booking?.id}</p>
                    <p className="card-subtitle">{booking.user_data.first_name} {booking.user_data.last_name}</p>
                    <p>{tidyDate(booking?.flight_departure_datetime)} • {booking?.flight_type} • {booking?.booking_status}</p>
                  </div>
                  <p style={{ padding: "5px 10px", fontSize: 12, fontWeight: "bold", color: "white", backgroundColor: "orange" }}>WORK FLIGHT</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
