import React, { useLayoutEffect, useState, useEffect, useRef } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import MainLayout from '../../layouts/MainLayout'

import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import notify from '../../utils/IANotification'
import { MdCheck, MdClose, MdCloud, MdOutlineCloud, MdOutlineKey, MdOutlineTextFields } from 'react-icons/md'
import ModalLayout from '../../layouts/ModalLayout'


export default function EmailTemplateBuilderPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [loaded, setLoaded] = useState(false)
  const [temp, setTemp] = useState({})

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])


  const emailEditorRef = useRef(null);

  const template_type = useParams().template_type

  const [emailData, setEmailData] = useState({})
  const [requiredKeys, setRequiredKeys] = useState([])
  const [generalKeys, setGeneralKeys] = useState([])
  const [recommendedKeys, setRecommendedKeys] = useState([])
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    loadTemplate()
  }, [])

  const loadTemplate = async () => {
    let endpoint = `${config.endpoint}/email/templates/${template_type}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status == 200) {
      setEmailData(data?.data)
      setRequiredKeys(data?.data?.required_keys)
      setGeneralKeys(data?.data?.general_keys)
      setRecommendedKeys(data?.data?.recommended_keys)
    }
    setLoaded(true)
  }


  const saveTemplate = async () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.saveDesign(async (data) => {
      // let {}
      unlayer.exportHtml(async (exportData) => {
        let { html, design } = exportData

        let endpoint = `${config.endpoint}/email/templates/${template_type}`
        let fetchResponse = await fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            content: data,
            html: html,
          }),
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
          notify({ message: "Template Saved", type: "success" })
        } else {
          notify({ message: response.message, type: "error" })
        }
      });
    })
  }

  const onReady = () => {
    const unlayer = emailEditorRef.current?.editor;
    document.querySelectorAll(".blockbuilder-branding").forEach((el) => el.remove());
    unlayer.loadDesign(emailData.content);
  }

  useEffect(() => {
    // export 
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml(async (data) => {
      let { html, design } = data
      console.log(html, design)
      setTemp(data)
    })

  }, [showModal])


  const RequiredModal = () => {
    return (
      <ModalLayout title="Fields" show={showModal} setShow={setShowModal}>
        <div className="flex flex-col gap-4">
          {requiredKeys.length > 0 && <div className="">
            <div className="flex flex-row items-center gap-4 text-sm font-black">
              Required Fields
            </div>
            {requiredKeys.map((key, index) => {
              let included = JSON.stringify(temp)?.includes(key.key)
              return (
                <div key={index} className="flex flex-row items-center gap-4 cursor-pointer hover:bg-gray-100 rounded-md p-1 justify-between"
                  onClick={() => {
                    // Add to clipboard
                    navigator.clipboard.writeText(`${key.key}`)
                    notify({ message: "Copied to clipboard", type: "success" })
                  }}
                >
                  <div className="flex-row flex items-center gap-2">
                    <MdOutlineKey size={20} />
                    {key.key}
                  </div>
                  {/* For checkbox */}
                  {included
                    ? <MdCheck size={20} className="text-green-500" />
                    : <MdClose size={20} className="text-red-500" />
                  }
                </div>
              )
            })}
          </div>}

          {recommendedKeys.length > 0 && <div className="border-t border-gray-200">
            <div className="flex flex-row items-center gap-4 text-sm font-black">
              Recommended Fields
            </div>
            {recommendedKeys.map((key, index) => {
              let included = JSON.stringify(temp)?.includes(key.key)
              return (
                <div key={index} className="flex flex-row items-center gap-4 cursor-pointer hover:bg-gray-100 rounded-md p-1 justify-between"
                  onClick={() => {
                    // Add to clipboard
                    navigator.clipboard.writeText(`${key.key}`)
                    notify({ message: "Copied to clipboard", type: "success" })
                  }}
                >
                  <div className="flex-row flex items-center gap-2">
                    <MdOutlineKey size={20} />
                    {key.key}
                  </div>
                  {/* For checkbox */}
                  {included
                    && <MdCheck size={20} className="text-green-500" />

                  }
                </div>
              )
            })}
          </div>}

          {generalKeys.length > 0 && <div className="border-t border-gray-200">
            <div className="flex flex-row items-center gap-4 text-sm font-black">
              Optional Fields
            </div>
            {generalKeys.map((key, index) => {
              let included = JSON.stringify(temp)?.includes(key.key)
              return (
                <div key={index} className="flex flex-row items-center gap-4 cursor-pointer hover:bg-gray-100 rounded-md p-1 justify-between"
                  onClick={() => {
                    // Add to clipboard
                    navigator.clipboard.writeText(`${key.key}`)
                    notify({ message: "Copied to clipboard", type: "success" })
                  }}
                >
                  <div className="flex-row flex items-center gap-2">
                    <MdOutlineKey size={20} />
                    {key.key}
                  </div>
                  {/* For checkbox */}
                  {included
                    && <MdCheck size={20} className="text-green-500" />

                  }
                </div>
              )
            })}
          </div>}
        </div>
      </ModalLayout>
    )
  }

  return (
    <MainLayout
      navSelected={"Announcements"}
      componentName={"Email Template Builder"}
      componentButtons={
        <>
          <div className="flex flex-row items-center gap-4">
            <button
              className="flex flex-row items-center gap-2"
              onClick={() => setShowModal(true)}
            >
              <MdOutlineKey size={20} />
              Fields</button>
            <button
              className="flex flex-row items-center gap-2"
              onClick={saveTemplate}
            >
              <MdOutlineCloud size={20} />
              Save</button>
          </div>
        </>
      }
    >
      <RequiredModal />
      {loaded && <EmailEditor ref={emailEditorRef} onReady={onReady} minHeight={window.innerHeight - header - 100} />}
    </MainLayout>
  )
}
