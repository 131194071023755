import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'

export default function AccountRequestDetailPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("admin")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  let request_id = useParams()?.request_id

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [data, setData] = useState({
    amount: 0,
    remarks: "",
  })

  const submitRequest = async () => {
    let endpoint = config.endpoint + "/reimbursement/user"
    let response = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: data.amount*100,
        remarks: data.remarks
      })
    })
    let fetchResponse = await response.json()
    if (fetchResponse.status === 200) {
      notify({ type: "success", message: "Request submitted successfully" })
      navigate(-1)
    } else {
      notify({ type: "error", message: "Something went wrong" })
    }
  }


  const fetchReimbursementById = async () => {
    if (!request_id) return
    let endpoint = config.endpoint + `/reimbursement/id/${request_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let fetchResponse = await response.json()
    if (fetchResponse.status === 200) {
      setData({
        ...fetchResponse.data,
        amount: fetchResponse.data?.amount/100
      })
    }
  }

  useEffect(() => {
    fetchReimbursementById()
  }, [])

  const markAsPaid = async () => {
    if (!request_id) return notify({ type: "error", message: "An error has occured" })
    let endpoint = config.endpoint + `/reimbursement/actions/paid/id/${request_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      fetchReimbursementById()
      notify({ type: "success", message: "Reimbursement request has been marked as paid" })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const markAsUnpaid = async () => {
    if (!request_id) return notify({ type: "error", message: "An error has occured" })
    let endpoint = config.endpoint + `/reimbursement/actions/unpaid/id/${request_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      fetchReimbursementById()
      notify({ type: "success", message: "Reimbursement request has been marked as unpaid" })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const denyRequest = async () => {
    if (!request_id) return notify({ type: "error", message: "An error has occured" })
    let endpoint = config.endpoint + `/reimbursement/actions/rejected/id/${request_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      fetchReimbursementById()
      notify({ type: "success", message: "Reimbursement request has been rejected" })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const approveRequest = async () => {
    if (!request_id) return notify({ type: "error", message: "An error has occured" })
    let endpoint = config.endpoint + `/reimbursement/actions/approved/id/${request_id}`
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      fetchReimbursementById()
      notify({ type: "success", message: "Reimbursement request has been marked as approved" })
    } else {
      notify({ type: "error", message: data.message })
    }
  }


  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Dashboard"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header">
              {request_id ?
                <h1>Reimbursement Request</h1>
                : <h1>Submit Reimbursement Request</h1>}
            </div>
          </div>
          <div className="container">
            <div className="inner-container">
              <div className="card rounded-md shadow-md p-4">
                <div className="input-container">
                  <label className="input-label text-xs font-semibold">Amount</label>
                  <input className="text-input" type="number" value={data.amount} onChange={(e) => {
                    setData({ ...data, amount: e.target.value })
                  }} />
                </div>
                <div className="input-container">
                  <label className="input-label text-xs font-semibold">Remarks</label>
                  <textarea className="textarea-input" type="text" value={data.remarks} onChange={(e) => {
                    setData({ ...data, remarks: e.target.value })
                  }} />
                </div>
                <div className="button-container">
                  {!request_id && <button className="button font-['Poppins']" onClick={() => {
                    submitRequest()
                  }}>Submit</button>}
                  {isAuthorised && data?.status === "pending" && <button className="delete-button font-['Poppins']" onClick={() => {
                    denyRequest()
                  }}>Deny Request</button>}
                  {isAuthorised && data?.status === "pending" && <button className="button font-['Poppins']" onClick={() => {
                    approveRequest()
                  }}>Approve Request</button>}
                  {isAuthorised && data?.status === "approved" && <button className="button font-['Poppins']" onClick={() => {
                    markAsPaid()
                  }}>Mark as Paid</button>}
                  {isAuthorised && data?.status === "paid" && <button className="button font-['Poppins']" onClick={() => {
                    markAsUnpaid()
                  }}>Mark as Unpaid</button>}
                  {/* <pre>{JSON.stringify(data,null,2)}</pre> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
