import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'
import { MdEditDocument } from 'react-icons/md'
import MainLayout from '../../layouts/MainLayout'

export default function ClubDocumentsPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [filter, setFilter] = useState("pending")

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])


  const [documentsData, setDocumentsData] = useState([])

  useEffect(() => {
    fetchClubDocuments()
  }, [])

  const fetchClubDocuments = async () => {
    let endpoint = config.endpoint + "/documents/club"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let result = await fetchResponse.json()
    if (result.status == 200) {
      setDocumentsData(result.data.reverse())
    } else {
      notify({ message: result.message, type: "error" })
    }
  }

  return (
    <MainLayout
      navSelected={"Club"}
      componentName={"Club Documents"}
      componentButtons={
        <select name="" id="" className="select-input px-4 py-2"
          onChange={(e) => {
            setFilter(e.target.value)
          }}
          value={filter}
        >
          <option value="all">All</option>
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </select>
      }
    >

      {documentsData?.length > 0 ? documentsData.map((item, index) => {
        if (filter != "all" && item.status != filter) {
          return
        }
        let color = ""
        if (item.status == "pending") {
          color = "var(--yellow)"
        } else if (item.status == "approved") {
          color = "var(--green)"
        } else if (item.status == "rejected") {
          color = "var(--red)"
        }
        return (
          <div className="card row justify-between items-start cursor-pointer mb-4 p-4 rounded-md" key={index} onClick={() => navigate("/club/documents/" + item.id)}>
            <div className="">
              <p className='card-title'>{item.user_data?.first_name} {item.user_data?.last_name}</p>
              <p className='card-subtitle' style={{ fontSize: 12 }}>{item.description}</p>
            </div>
            <p style={{ fontSize: 12, color: "white", fontWeight: "bold", borderRadius: 25, padding: "5px 15px", backgroundColor: color }}>{item.status}</p>
          </div>
        )
      })
        :
        <div className="card p-4 rounded-md">
          <MdEditDocument size={48} color={"gray"} />
          <p className='card-title mt-4' style={{ color: "gray" }}>No Documents Found</p>
        </div>
      }

    </MainLayout>
  )
}
