import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Switch from "react-switch"
import notify from '../../utils/IANotification'
import ModuleNavigation from '../../components/ModuleNavigation'

export default function ClubModulesLessonsPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [subHeader, setSubHeader] = useState(document.getElementById("user-subheader")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
    setSubHeader(document.getElementById("user-subheader")?.clientHeight)
  }, [header])

  let { module_id } = useParams()

  const [moduleFormData, setModuleFormData] = useState({
    name: "",
    description: "",
    is_visible: 1,
    is_required: 1,
    cost: 100,
  })

  const createModule = async () => {
    let endpoint = config.endpoint + "/modules/club/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(moduleFormData)
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate(`/club/modules/${data.data}`)
      notify({ type: "success", message: "Module created successfully" })
    } else {
      notify({ type: "error", message: "Error creating module" })
    }
  }

  const updateModule = async () => {
    if (!module_id) return notify({ type: "error", message: "Error updating module" })

    let endpoint = config.endpoint + "/modules/club/id/" + module_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(moduleFormData)
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Module updated successfully" })
    } else {
      notify({ type: "error", message: "Error updating module" })
    }
  }


  const deleteModule = async () => {
    if (!module_id) return notify({ type: "error", message: "Error deleting module" })

    let endpoint = config.endpoint + "/modules/club/id/" + module_id
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ type: "success", message: "Module deleted successfully" })
    } else {
      notify({ type: "error", message: "Error deleting module" })
    }
  }

  const fetchModuleById = async () => {
    if (!module_id) return notify({ type: "error", message: "Error fetching module" })
    let endpoint = config.endpoint + "/modules/id/" + module_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setModuleFormData(data.data)
    } else {
      notify({ type: "error", message: "Error fetching module" })
    }
  }

  useEffect(() => {
    if (module_id === "create") return
    fetchModuleById()
  }, [])


  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Dashboard"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll", zIndex: 5 }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header, zIndex: 5 }} id='user-subheader'>
            <div className="component-header">
              <h1>{module_id === "create" ? "Create New Module" : "Edit Module"}</h1>
            </div>
          </div>

          <div className="row" style={{ width: "100%" }}>
            {module_id !== "create" && <div style={{ height: `calc(100vh - ${header}px - ${subHeader}px)`, overflowY: "scroll", width: "300px", position: "fixed", zIndex: 0 }}>
              <ModuleNavigation selected={"lessons"} module_id={module_id} />
            </div>}

            <div className="reg-container p-4" style={{ width: "100%", marginLeft: module_id !== "create" ? "300px" : "0px" }}>
              {Array.from(Array(100).keys()).map((item, index) => {
                return (
                  <p>{item}</p>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
