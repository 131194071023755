import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';
import { tidyDate } from '../../utils/helper'

export default function FlightsLogPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  const [flightData, setFlightData] = useState([])

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const COL = [
    {
      field: 'name',
      headerName: 'Flight',
      width: 250,
      editable: false,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      editable: false,
    },
    {
      field: "flight_type",
      headerName: "Flight Type",
      width: 150,
      editable: false,
    },
    {
      field: "aircraft",
      headerName: "Aircraft",
      width: 200,
      editable: false,
    },
    {
      field: "instructor",
      headerName: "Instructor",
      width: 150,
      editable: false,
    },
    {
      field: "examiner",
      headerName: "Examiner",
      width: 150,
      editable: false,
    },
    {
      field: "hours",
      headerName: "Hours",
      width: 100,
      editable: false,
    },
    {
      field: "distance",
      headerName: "Distance",
      width: 100,
      editable: false,
    }
  ]

  const loadFlightLogs = async () => {
    let endpoint = config.endpoint + "/bookings/user/logs"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let result = await fetchResponse.json()
    if (result.status === 200) {
      let data = result.data.flight_logs
      let temp = []
      for (let i = 0; i < data.length; i++) {
        let item = data[i]
        let difference = new Date(item.actual_arrival_datetime) - new Date(item.actual_departure_datetime)
        let hours = (difference / 1000 / 60 / 60)
        temp.push({
          id: i,
          name: item.flight_name,
          date: tidyDate(item.actual_departure_datetime).toUpperCase(),
          flight_type: item.flight_type,
          aircraft: `${item.resource_data?.registration} (${item.resource_data?.nickname})`,
          instructor: item.flight_type === "training" ? `${item.instructor_data?.first_name} ${item.instructor_data?.last_name}` : "-",
          examiner: item.flight_type === "examination" ? `${item.examiner_data?.first_name} ${item.examiner_data?.last_name}` : "-",
          hours: hours,
          distance: item.actual_flight_distance
        })
      }

      temp.push({
        id: temp.length,
        name: `Total ${result.data.cycles} cycle(s)`,
        date: "",
        flight_type: "",
        aircraft: "",
        instructor: "",
        examiner: "",
        hours: result.data.total_hours,
        distance: result.data.total_distance
      })

      setFlightData(temp)
    }
  }


  useEffect(() => {
    loadFlightLogs()
  }, [])



  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"My Flights"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header">
              <h1>My Flight Logs</h1>
            </div>
          </div>
          <div className="reg-container p-4">
          <DataGrid
              rows={flightData}
              columns={COL}
              // pageSizeOptions={[5]}
              // checkboxSelection
              // disableRowSelectionOnClick
              hideFooter
              disableColumnMenu
              disableRowSelectionOnClick
            />
          </div>
        </div>
      </div>
    </div>
  )
}
