import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import './App.css';
import './globals.css'
import 'react-toastify/dist/ReactToastify.css';
import IndexPage from "./pages/IndexPage";
import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/user/HomePage";
import FlightsPage from "./pages/user/FlightsPage";
import ClubResourcePage from "./pages/user/ClubResourcePage";
import ClubResourceFormPage from "./pages/user/ClubResourceFormPage";
import SettingsPage from "./pages/user/SettingsPage";
import FlightBookingDetailPage from "./pages/user/FlightBookingDetailPage";
import MemberPage from "./pages/user/MemberPage";
import MemberFormPage from "./pages/user/MemberFormPage";
import ClubFlightsPage from "./pages/user/ClubFlightsPage";
import ClubFlightBookingDetailPage from "./pages/user/ClubFlightBookingDetailPage";
import ClubFlightCompletePage from "./pages/user/ClubFlightCompletePage";
import AccountPage from "./pages/user/AccountPage";
import AccountRequestDetailPage from "./pages/user/AccountRequestDetailPage";
import ClubAccountingPaymentPage from "./pages/user/ClubAccountingPaymentPage";
import ClubReimbursementPage from "./pages/user/ClubReimbursementPage";
import ModulesPage from "./pages/user/ModulesPage";
import ClubModulesDetailPage from "./pages/user/ClubModulesDetailPage";
import ClubModulesPage from "./pages/user/ClubModulesPage";
import ClubModulesLessonsPage from "./pages/user/ClubModulesLessonsPage";
import ClubModulesLessonsDetailPage from "./pages/user/ClubModulesLessonsDetailPage";
import DocumentSubmissionPage from "./pages/user/DocumentSubmissionPage";
import ClubResourceSharingForm from "./pages/user/ClubResourceSharingForm";
import ClubAccountingPage from "./pages/user/ClubAccountingPage";
import ClubAccountingTransactionFormPage from "./pages/user/ClubAccountingTransactionFormPage";
import FlightsLogPage from "./pages/user/FlightsLogPage";
import ClubDocumentsPage from "./pages/user/ClubDocumentsPage";
import ClubDocumentsFormPage from "./pages/user/ClubDocumentsFormPage";
import DocumentPage from "./pages/user/DocumentPage";
import FleetSchedulePage from "./pages/user/FleetSchedulePage";
import ClubPage from "./pages/user/ClubPage";
import ProfilePage from "./pages/user/ProfilePage";
import EventsPage from "./pages/user/EventsPage";
import EventDetailPage from "./pages/user/EventDetailPage";
import APIPage from "./pages/user/APIPage";
import EmailTemplatesPage from "./pages/user/EmailTemplatesPage";
import EmailTemplateBuilderPage from "./pages/user/EmailTemplateBuilderPage";
import ClubAnnouncementPage from "./pages/user/ClubAnnouncementPage";
import ClubAnnouncementDetailsPage from "./pages/user/ClubAnnouncementDetailsPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/auth/login" element={<AuthPage pageState={"login"} />} />
        <Route path="/auth/forgot-password" element={<AuthPage pageState={"forgot"} />} />
        <Route path="/auth/reset-password" element={<AuthPage pageState={"reset_password"} />} />
        <Route path="/auth/register" element={<AuthPage pageState={"register_organisation"} />} />
        <Route path="/auth/registration/user" element={<AuthPage pageState={"register"} />} />

        <Route path="/home" element={<HomePage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/flights/log" element={<FlightsLogPage />} />
        <Route path="/flights/bookings" element={<FlightsPage />} />
        <Route path="/flights/bookings/:booking_id" element={<FlightBookingDetailPage />} />
        <Route path="/flights/bookings/:booking_id/complete" element={<ClubFlightCompletePage />} />

        <Route path="/accounting" element={<AccountPage />} />
        <Route path="/accounting/request" element={<AccountRequestDetailPage />} />

        <Route path="/events" element={<EventsPage />} />
        <Route path="/events/details/:event_id" element={<EventDetailPage />} />

        <Route path="/club/flights/bookings" element={<ClubFlightsPage />} />
        <Route path="/club/flights/bookings/:booking_id" element={<ClubFlightBookingDetailPage />} />


        <Route path="/club/accounting" element={<ClubAccountingPage />} />
        <Route path="/club/accounting/transaction/:transaction_id" element={<ClubAccountingTransactionFormPage />} />
        <Route path="/club/accounting/payment" element={<ClubAccountingPaymentPage />} />
        <Route path="/club/accounting/reimbursement" element={<ClubReimbursementPage />} />
        <Route path="/club/accounting/reimbursement/:request_id" element={<AccountRequestDetailPage />} />

        <Route path="/documents/submit" element={<DocumentSubmissionPage />} />
        <Route path="/documents" element={<DocumentPage />} />

        <Route path="/developer/api" element={<APIPage />} />

        <Route path="/club/members" element={<MemberPage />} />
        <Route path="/club/members/:user_id" element={<MemberFormPage />} />


        {/* Announcements */}
        <Route path="/announcements" element={<ClubAnnouncementPage />} />
        <Route path="/announcements/details/:announcement_id" element={<ClubAnnouncementDetailsPage />} />


        <Route path="/modules" element={<ModulesPage />} />
        <Route path="/club/modules" element={<ClubModulesPage />} />
        <Route path="/club/modules/:module_id/lessons" element={<ClubModulesLessonsPage />} />
        <Route path="/club/modules/:module_id/lessons/:lesson_id" element={<ClubModulesLessonsDetailPage />} />
        <Route path="/club/modules/:module_id" element={<ClubModulesDetailPage />} />

        <Route path="/club/documents" element={<ClubDocumentsPage />} />
        <Route path="/club/documents/:document_id" element={<ClubDocumentsFormPage />} />

        <Route path="/club" element={<ClubPage />} />
        <Route path="/club/resources" element={<ClubResourcePage />} />
        <Route path="/club/resources/sharing" element={<ClubResourceSharingForm />} />
        <Route path="/club/resources/schedule" element={<FleetSchedulePage />} />
        <Route path="/club/resources/:resource_id" element={<ClubResourceFormPage />} />
        <Route path="/club/settings" element={<SettingsPage />} />
        <Route path="/email/templates" element={<EmailTemplatesPage />} />
        <Route path="/email/templates/:template_type" element={<EmailTemplateBuilderPage />} />
        {/* <Route path="/club/resources/new" element={<ClubResourceFormPage/>} /> */}

        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
