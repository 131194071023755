import React, { useState, useEffect, useLayoutEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import notify from '../../utils/IANotification'
import 'react-toastify/dist/ReactToastify.css';
import FileUploadField from '../../components/FileUploadField'
import { MdGroup } from 'react-icons/md'
import { calculate_time_difference, isMobile } from '../../utils/helper'
import MainLayout from '../../layouts/MainLayout'

export default function ClubResourceFormPage() {
  const navigate = useNavigate()
  const resource_id = useParams().resource_id
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [is_owner, setIs_owner] = useState(false)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [resourceData, setResourceData] = useState({
    aerial_resource_id: "",
    nickname: "",
    aerial_type: "aircraft", // glider, aircraft, helicopter
    registration: "",
    internal_remarks: "",
    flight_cycle: 0,
    flight_hours: 0,
    aerial_age: 0, // months
    aerial_status: "active", // active, inactive, retired, maintenance,
    aerial_capacity: 0,
    aerial_range: 0,
    aerial_speed: 0,
    media_assets_id: "",
    aerial_model: "",
    aerial_manufacturer: "",
  })

  const submitResource = async () => {
    let endpoint = config.endpoint + "/resources/club/"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...resourceData,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate("/club/resources")
      notify({ type: "success", message: data.message })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const updateResource = async () => {
    let endpoint = config.endpoint + "/resources/club/" + resource_id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: resource_id,
        ...resourceData,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate("/club/resources")
      notify({ type: "success", message: data.message })
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const fetchResourceById = async () => {
    if (resource_id === "new" || !resource_id) return
    let endpoint = config.endpoint + "/resources/id/" + resource_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      setResourceData(data.data)
      setIs_owner(data.data?.owner?.is_owner === 1)
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const deleteResource = async () => {
    let endpoint = config.endpoint + "/resources/club/"
    let fetchResponse = await fetch(endpoint, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: resource_id,
      })
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      navigate("/club/resources")
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  const revokeAccess = async (loan_id) => {
    if (!loan_id) return
    let endpoint = config.endpoint + "/resources/sharing/revoke/loan/id/" + loan_id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      notify({ type: "success", message: data.message })
      fetchResourceById()
    } else {
      notify({ type: "error", message: data.message })
    }
  }


  useEffect(() => {
    fetchResourceById()
  }, [])


  return (
    <MainLayout
      navSelected={"Club"}
      componentName={resource_id === "new" ? "Add Aircraft" : "Edit Aircraft"}
    >
      <div className="card mb-4 rounded-md shadow-md p-4" style={{ padding: 0 }}>
        <img src={resourceData?.media_assets_id ? `${config.endpoint}${resourceData?.file_data?.ext_file_path}` : "/placeholder_aircraft.jpg"} alt="" style={{ width: "100%", height: 200, objectFit: "cover", borderRadius: 0 }} />
      </div>
      <div className="card mb-4 rounded-md shadow-md p-4">
        <p className="card-title mb-4">Aircraft Information</p>

        {resource_id !== "new" && is_owner && <FileUploadField upload_url={config.endpoint + `/resources/upload/id/${resource_id}`} onChange={(files) => { }} text={"Upload Aircraft Image"} subtext={"Drag and drop or click to upload"} max_file_size={5} max_files={1} />}
        <div className={`${isMobile(window.innerWidth) ? "column" : "row"} justify-between gap-4`}>
          <div className="input-container">
            <label className='input-label'>Registration</label>
            <input type="text" className='text-input' value={resourceData.registration?.toUpperCase()} onChange={(e) => setResourceData({ ...resourceData, registration: e.target.value?.toUpperCase() })} />
          </div>
          <div className="input-container">
            <label className='input-label'>Nickname</label>
            <input type="text" className='text-input' value={resourceData.nickname} onChange={(e) => setResourceData({ ...resourceData, nickname: e.target.value })} />
          </div>
        </div>
        <div className={`${isMobile(window.innerWidth) ? "column" : "row"} justify-between gap-4`}>
          <div className="input-container">
            <label className='input-label'>Model Name</label>
            <input type="text" className='text-input' value={resourceData.aerial_model} onChange={(e) => setResourceData({ ...resourceData, aerial_model: e.target.value })} />
          </div><div className="input-container">
            <label className='input-label'>Manufacturer</label>
            <input type="text" className='text-input' value={resourceData.aerial_manufacturer} onChange={(e) => setResourceData({ ...resourceData, aerial_manufacturer: e.target.value })} />
          </div>

        </div>
      </div>

      <div className="card rounded-md shadow-md p-4">
        <p className="card-title mb-4">Technical Specifications</p>
        <div className={`${isMobile(window.innerWidth) ? "column" : "row"} justify-between gap-4`}>
          <div className="input-container">
            <label className='input-label'>Flight Cycles</label>
            <input type="number" className='text-input' value={resourceData.flight_cycle} onChange={(e) => setResourceData({ ...resourceData, flight_cycle: e.target.value })} />
          </div>
          <div className="input-container">
            <label className='input-label'>Flight Hours</label>
            <input type="number" className='text-input' value={resourceData.flight_hours} onChange={(e) => setResourceData({ ...resourceData, flight_hours: e.target.value })} />
          </div>
          <div className="input-container">
            <label className='input-label'>Age (in months)</label>
            <input type="number" className='text-input' value={resourceData.aerial_age} onChange={(e) => setResourceData({ ...resourceData, aerial_age: e.target.value })} />
          </div>
        </div>
        <div className={`${isMobile(window.innerWidth) ? "column" : "row"} justify-between gap-4`}>
          <div className="input-container">
            <label className='input-label'>Speed</label>
            <input type="number" className='text-input' value={resourceData.aerial_speed} onChange={(e) => setResourceData({ ...resourceData, aerial_speed: e.target.value })} />
          </div>
          <div className="input-container">
            <label className='input-label'>Range (nm)</label>
            <input type="number" className='text-input' value={resourceData.aerial_range} onChange={(e) => setResourceData({ ...resourceData, aerial_range: e.target.value })} />
          </div>
          <div className="input-container">
            <label className='input-label'>Capacity</label>
            <input type="number" className='text-input' value={resourceData.aerial_capacity} onChange={(e) => setResourceData({ ...resourceData, aerial_capacity: e.target.value })} />
          </div>
        </div>
      </div>

      {resource_id !== "new" && <div className="card mt-4 rounded-md shadow-md p-4">
        <p className="card-title">Ownership</p>
        {/* <pre>{JSON.stringify(resourceData, null, 2)}</pre> */}
        <div className="input-container">
          <label className='input-label'>Owner</label>
          <input type="text" className='text-input' value={resourceData?.owner?.organisation_name} disabled />
        </div>
        <div className="input-container">
          <label htmlFor="" className="input-label">Users</label>
          {resourceData?.users?.length > 0 ? resourceData?.users?.map((user, index) => {
            let difference = calculate_time_difference(new Date(), new Date(user?.date_borrowed))
            return (
              <div className={`${isMobile(window.innerWidth) ? "column" : "row"} justify-between gap-4`}>
                <div key={index} className="row items-center gap-4">
                  <img src={user?.organisation_data?.file_data?.ext_file_path ? `${config.endpoint} ${user?.organisation_data?.file_data?.ext_file_path}` : "/logoWoText.png"} alt="" style={{ width: 30, height: 30, objectFit: "cover", borderRadius: "50%" }} />
                  <div className='ml-10'>
                    <p>{user?.organisation_data?.organisation_name}</p>
                    <p style={{ color: "gray", fontSize: 12 }}>sharing since {difference?.days} days {difference?.hours} hours {difference?.minutes} minutes ago</p>
                  </div>
                </div>
                {resourceData?.owner?.is_owner === 1 && <button className="button-cta" onClick={() => {
                  window.confirm("Are you sure you want to revoke access to this user?") && revokeAccess(user?.loan_id)
                }}>Revoke Access</button>}
              </div>
            )
          })
            :
            <div>
              <MdGroup size={30} color={"gray"} />
              <p style={{ color: "gray", fontSize: 12 }}>No other users using this aircraft</p>
            </div>
          }
        </div>
      </div>}


      <div className="card mt-4 rounded-md shadow-md p-4">
        <p className="card-title mb-4">Internal Classification</p>
        <div>
          <div className="row gap-4 alignStart">
            <div className="input-container">
              <label className='input-label'>Status</label>
              <select className='select-input h-11 px-2' value={resourceData.aerial_status} onChange={(e) => setResourceData({ ...resourceData, aerial_status: e.target.value })}>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="retired">Retired</option>
                <option value="maintenance">Maintenance</option>
              </select>
            </div>
            <div className="input-container">
              <label className='input-label'>Type</label>
              <select className='select-input h-11 px-2' value={resourceData.aerial_type} onChange={(e) => setResourceData({ ...resourceData, aerial_type: e.target.value })}>
                <option value="glider">Glider</option>
                <option value="aircraft">Aircraft</option>
                <option value="helicopter">Helicopter</option>
              </select>
            </div>
          </div>
          <div className="input-container">
            <label className='input-label'>Remarks</label>
            <textarea className='text-input' value={resourceData.internal_remarks} onChange={(e) => setResourceData({ ...resourceData, internal_remarks: e.target.value })} />
          </div>
        </div>
      </div>
      <div className="mt-4 button-container">
        {resource_id !== "new" && resourceData?.owner?.is_owner === 1 && <button className="button font-['Poppins'] text-sm" style={{ marginLeft: 10 }} onClick={() => deleteResource()}>Delete</button>}
        <button className="button font-['Poppins'] text-sm" onClick={() => {
          if (resource_id === "new") {
            submitResource()
          } else {
            updateResource()
          }
        }}>Save</button>
      </div>
    </MainLayout>
  )
}
