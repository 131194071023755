import React, { useEffect, useLayoutEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { getAgeByDate, tidyDate } from '../../utils/helper'

export default function MemberPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [searchQuery, setSearchQuery] = useState("")

  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const [midHeader, setMidHeader] = useState(document.getElementById("mid-header")?.clientHeight)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
    setMidHeader(document.getElementById("mid-header")?.clientHeight + document.getElementById("user-header")?.clientHeight)
  }, [document.getElementById("user-header")?.clientHeight, document.getElementById("mid-header")?.clientHeight])


  const [clubUserData, setClubUserData] = useState([])

  const fetchClubMembers = async () => {
    let endpoint = config.endpoint + "/users/club/"
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    let data = await response.json()
    if (data.status === 200) {
      setClubUserData(data.data)
    }
  }

  useEffect(() => {
    fetchClubMembers()
  }, [])


  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Club"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header">
              <div className="row justify-between items-center">
                <h1 className=''>Club Members</h1>
                <button className="button-cta font-['Poppins'] rounded-md bg-slate-200 hover:bg-slate-300 text-black hover:text-black text-xs px-4 py-2"
                  onClick={() => {
                    navigate("/club/members/new")
                  }}
                >New User</button>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="p-all" style={{ width: "100%", paddingBottom: 20, position: "sticky", top: midHeader, }}>
              <input type="text" className="text-input" style={{ width: "100%" }} placeholder="Search" value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value) }} />
            </div>
            {clubUserData && clubUserData.map((user, index) => {
              if (searchQuery && !JSON.stringify(user).toLowerCase().includes(searchQuery.toLowerCase())) {
                return null
              }
              return (
                <div className="card cursor-pointer mb-4 rounded-md p-4 shadow-md"
                  onClick={() => {
                    navigate("/club/members/" + user?.id)
                  }}
                >
                  <p className="card-title">{user?.first_name} {user?.last_name}</p>
                  <p>{user?.flight_cycles || 0} cycles • {user?.flight_hours || 0} hours • {getAgeByDate(user?.date_of_birth) > 0 ? getAgeByDate(user?.date_of_birth) : 0} years old</p>
                  {/* <pre>{JSON.stringify(clubUserData,null,2)}</pre> */}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
