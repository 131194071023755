const tidyDate = (date) => {
    // if (!date?.includes("Z")) {
    //     date = date + "Z"
    // }

    if (!isNaN(date)) {
        let seconds = date
        date = new Date(date*1000)
    }

    let day = new Date(date).getDate()
    let month = new Date(date).getMonth() + 1
    let year = new Date(date).getFullYear()
    let hour = new Date(date).getHours()
    let minute = new Date(date).getMinutes()

    if (day < 10) {
        day = "0" + day
    }

    if (month < 10) {
        month = "0" + month
    }

    if (hour < 10) {
        hour = "0" + hour
    }

    if (minute < 10) {
        minute = "0" + minute
    }

    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    return `${day} ${MONTHS[month - 1]} ${year} ${hour}:${minute}`
}

const currency = (amount) => {
    amount = Math.round((amount) * 100) / 100

    if (amount.toString().includes(".")) {
        amount = amount.toFixed(2)
    }

    // add commas
    amount = amount.toLocaleString()

    return amount
}

const isMobile = (width) => {
    if (width < 700) {
        return true
    } else {
        return false
    }
}

const getAgeByDate = (date) => {
    let today = new Date()
    let birthDate = new Date(date)
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}

const convertPilotLicenseType = (license_type) => {
    //     none
    // student
    // private
    // commercial
    // airline_transport
    // multicrew
    switch (license_type) {
        case "none":
            return "None"
        case "student":
            return "Student Pilot License"
        case "private":
            return "Private Pilot License"
        case "commercial":
            return "Commercial Pilot License"
        case "airline_transport":
            return "Airline Transport Pilot License"
        case "multicrew":
            return "Multi-Crew Pilot License"
        default:
            return "None"
    }
}

const captialise = (string) => {
    if (!string) {
        return ""
    }
    return string.charAt(0).toUpperCase() + string.slice(1)
}


const calculate_time_difference = (date1, date2) => {
    let difference = date1 - date2
    let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24)
    difference -= daysDifference * 1000 * 60 * 60 * 24
    let hoursDifference = Math.floor(difference / 1000 / 60 / 60)
    difference -= hoursDifference * 1000 * 60 * 60
    let minutesDifference = Math.floor(difference / 1000 / 60)
    difference -= minutesDifference * 1000 * 60
    let secondsDifference = Math.floor(difference / 1000)

    return {
        days: daysDifference,
        hours: hoursDifference,
        minutes: minutesDifference,
        seconds: secondsDifference
    }
}

module.exports = {
    tidyDate,
    currency,
    isMobile,
    getAgeByDate,
    convertPilotLicenseType,
    captialise,
    calculate_time_difference,
}