import React, { useLayoutEffect, useState, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'

export default function ClubFlightCompletePage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)

  const { booking_id } = useParams()

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  const [bookingDetails, setBookingDetails] = useState({})

  const fetchBookingById = async () => {
    if (!booking_id || booking_id === "new") return
    let endpoint = config.endpoint + "/bookings/id/" + booking_id
    let response = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let data = await response.json()
    if (data.status === 200) {
      setBookingDetails(data.data)
      setPayload({
        ...payload,
        remarks: data?.data?.remarks,
        actual_arrival_datetime: data?.data?.flight_arrival_datetime,
        actual_departure_datetime: data?.data?.flight_departure_datetime,
      })
    }
  }


  const [payload, setPayload] = useState({
    actual_departure_datetime: "",
    actual_arrival_datetime: "",
    actual_flight_distance: "",
  })


  const submitFlightComplete = async () => {
    let endpoint = config.endpoint + "/bookings/club/" + booking_id + "/complete"
    let response = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate("/flights/bookings")
    }else{
      notify({ type: "error", message: data.message })
    }
  }


  useEffect(() => {
    fetchBookingById()
  }, [])

  return (
    <div style={{ width: "100vw", }}>
      <ToastContainer />
      <div className='row' style={{ width: "100%" }}>
        <Navbar selected={"Dashboard"} />
        <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
          <UserHeader />
          <div className="component p-4" style={{ top: header }}>
            <div className="component-header">
              <h1>Flight Completed: #{booking_id}</h1>
            </div>
          </div>
          <div className="container">
            <div className="inner-container">
              <div className="card rounded-md shadow-md p-4">
                <p className="card-title">Flight Details</p>
                <div className="input-container">
                  <label>Actual Time of Departure</label>
                  <input type="datetime-local" value={payload.actual_departure_datetime} className='text-input' onChange={(e) => {
                    setPayload({
                      ...payload,
                      actual_departure_datetime: e.target.value,
                    })
                  }} />
                </div>
                <div className="input-container">
                  <label>Actual Time of Arrival</label>
                  <input type="datetime-local" value={payload.actual_arrival_datetime} className='text-input' onChange={(e) => {
                    setPayload({
                      ...payload,
                      actual_arrival_datetime: e.target.value,
                    })
                  }} />
                </div>
                <div className="input-container">
                  <label>Actual Flight Distance (KM)</label>
                  <input type="number" value={payload.actual_flight_distance} className='text-input' onChange={(e) => {
                    setPayload({
                      ...payload,
                      actual_flight_distance: e.target.value,
                    })
                  }} />
                </div>
                <div className="input-container">
                  <label>Remarks</label>
                  <textarea type="number" value={payload.remarks} className='textarea-input' onChange={(e) => {
                    setPayload({
                      ...payload,
                      remarks: e.target.value,
                    })
                  }} />
                </div>
                <div className="mt-4 button-container justfyRight">
                  <button className="button"
                    onClick={() => {
                      submitFlightComplete()
                    }}
                  >Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
