import React, { useLayoutEffect, useState, useEffect, useRef } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter, useFetchUserData } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import MainLayout from '../../layouts/MainLayout'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Switch from "react-switch"
import draftToHtml from 'draftjs-to-html';
import notify from '../../utils/IANotification'
import { convertFromRaw, convertToRaw } from 'draft-js'
import JoditEditor from 'jodit-react';

export default function ClubAnnouncementDetailsPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = useFetchUserData()
  const [header, setHeader] = useState(document.getElementById("user-header")?.clientHeight)
  const editor = useRef(null)

  useLayoutEffect(() => {
    setHeader(document.getElementById("user-header")?.clientHeight)
  }, [header])

  let announcement_id = useParams().announcement_id


  const [announcementData, setAnnouncementData] = useState({
    title: "",
    content: "",
    is_published: 0,
    start_datetime: "",
    end_datetime: "",
    is_forever: 0,
  })
  const [editorState, setEditorState] = useState()


  useEffect(() => {
    fetchAnnouncementById()
  }, [announcement_id])

  const fetchAnnouncementById = async () => {
    if (announcement_id == "create") return
    let endpoint = `${config.endpoint}/announcements/id/${announcement_id}`
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    let data = await response.json()
    if (data.status === 200) {
      setAnnouncementData(data.data)
    }
  }

  const createAnnouncements = async () => {
    let endpoint = `${config.endpoint}/announcements`
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...announcementData,
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      navigate(`/announcements/details/${data.data.id}`)
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const updateAnnouncements = async () => {
    let endpoint = `${config.endpoint}/announcements/id/${announcement_id}`
    let response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...announcementData,
      })
    })
    let data = await response.json()
    if (data.status === 200) {
      notify({ message: data.message, type: "success" })
      fetchAnnouncementById()
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const deleteAnnouncements = async () => {
    let endpoint = `${config.endpoint}/announcements/id/${announcement_id}`
    let response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",

    })
    let data = await response.json()
    if (data.status === 200) {
      navigate(`/announcements`)
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }



  return (
    <MainLayout
      navSelected={"Announcements"}
      componentName={"Club Announcements"}
    >
      <div className="card rounded-md p-4">
        <p className="card-title">Details</p>
        <div className="flex flex-row items-center gap-2">
          <div className="flex flex-row items-center gap-2">
            <div className="input-container">
              <label htmlFor="" className="input-label">Title</label>
              <input type="text" className="text-input" value={announcementData.title} onChange={(e) => setAnnouncementData({ ...announcementData, title: e.target.value })} />
            </div>
            <label htmlFor="" className="input-label">Draft</label>
            <Switch
              checked={announcementData.is_published == 1}
              onChange={(e) => setAnnouncementData({ ...announcementData, is_published: e ? 1 : 0 })}
            />
            <label htmlFor="" className="input-label">Scheduled</label>
          </div>

        </div>
        <div className="flex flex-row items-center gap-2">
          <div className="input-container">
            <label htmlFor="" className="input-label">From</label>
            <input type="date" className="text-input" value={announcementData.start_datetime} onChange={(e) => setAnnouncementData({ ...announcementData, start_datetime: e.target.value })} />
          </div>
          <div className="input-container">
            <label htmlFor="" className="input-label">To</label>
            <input type="date" className="text-input" value={announcementData.end_datetime} onChange={(e) => setAnnouncementData({ ...announcementData, end_datetime: e.target.value })} />
          </div>
          <div className="flex-row flex items-center gap-2">
            <label htmlFor="" className="input-label">Pinned</label>
            <Switch
              checked={announcementData.is_forever == 1}
              onChange={(e) => setAnnouncementData({ ...announcementData, is_forever: e ? 1 : 0 })}
            />
          </div>
        </div>
        <label htmlFor="" className="input-label">Content</label>
        <div className="input-container border-[0.5px] border-gray-500 rounded-md">
          {(announcement_id === "new" || announcementData.content||1) &&

            <JoditEditor
              ref={editor}
              value={announcementData.content}
              config={{
                readonly: false,
                placeholder: "Type here...",  
                style: {
                  height: "300px",
                  width: "100%",
                }
              }}
              
              tabIndex={1} // tabIndex of textarea
              onBlur={newContent => setAnnouncementData({ ...announcementData, content: newContent })}
              // onChange={newContent => setAnnouncementData({ ...announcementData, content: newContent })}
            />

          }
        </div>

        <div className="flex flex-row justify-end gap-2 mt-4">
          {announcement_id != "create" && <button className="button-cta" onClick={() => {
            // Delete announcement
            deleteAnnouncements()
          }}>Delete</button>}
          <button className="button-cta"
            onClick={() => {
              if (announcement_id == "create") {
                // Create new announcement
                createAnnouncements()
              } else {
                // Update announcement
                updateAnnouncements()
              }
            }}
          >Save</button>
        </div>
      </div>
    </MainLayout>
  )
}
